import { useMemo } from "react";
import { useObjectsState } from "../../../../../contexts/ObjectsProvider";
import { useTimeline } from "../../../../../contexts/TimelineProvider/TimelineProvider";
import { useMetaVariableStore } from "../../../store";

export const useAdvancedPanelTimeline = (key: string) => {
  const upsertAnimatedMetaVariable = useMetaVariableStore((s) => s.upsertAnimatedMetaVariable);
  const animatedMetaVariables = useMetaVariableStore((s) => s.animatedMetaVariables);
  const deleteAnimatedMetaVariableFrame = useMetaVariableStore((s) => s.deleteAnimatedMetaVariableFrame);
  const [tl] = useTimeline();
  const currentTime = tl?.scrubbingCurrentTime;
  const { selectedObjects, animatedObjects } = useObjectsState();
  const selectedObjectId = selectedObjects[0]?.objectId;
  const isObjectInTimeline = animatedObjects.find((o) => o.id === selectedObjectId);

  const metaVariableInCurrentTime = useMemo(() => {
    if (!isObjectInTimeline) return null;

    const variable = animatedMetaVariables.find((v) => v.metaVariableKey === key);

    if (!variable) return null;
    if (variable.oneshot?.length === 0 && variable.interpolated?.length === 0) return null;

    const frameOneshot = variable.oneshot?.find((f) => f.timestamp === currentTime);

    if (frameOneshot) {
      return frameOneshot;
    }

    const frameInterpolated = variable.interpolated?.find((f) => f.timestamp === currentTime);

    if (frameInterpolated) {
      return frameInterpolated;
    }

    return null;
  }, [isObjectInTimeline, currentTime, animatedMetaVariables]);

  const handleTimelineClick = (value: string | number | boolean, isInterpolated = false) => {
    if (metaVariableInCurrentTime) {
      deleteAnimatedMetaVariableFrame(key, currentTime);
      return;
    }

    upsertAnimatedMetaVariable(key, currentTime, value, selectedObjectId, isInterpolated);
  };

  return {
    isObjectInTimeline,
    metaVariableInCurrentTime,
    handleTimelineClick,
  };
};
