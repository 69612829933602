import "../fmsDesigner.css";
import React, { useContext, useEffect, useState } from "react";
import {
  IPageContext,
  PageContext,
  LessonPageActionsContext,
  ILessonPageActionsContext,
} from "../../../routes/builderContexts";
import { IFmsPageManifest } from "../Interfaces/IFmsPageManifest";
import _, { sortedLastIndex, before, forEach, initial, update } from "lodash";
import { IFmsContext, FmsContext } from "../Interfaces/fmsContext";

const HoldingDisplayOptions: React.FC = () => {
  const pagesContext: IPageContext = useContext<IPageContext>(PageContext);
  const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);
  const pageManifest: IFmsPageManifest = pagesContext.pageManifest as IFmsPageManifest;
  const lessonPageActionsContext: ILessonPageActionsContext =
    useContext<ILessonPageActionsContext>(LessonPageActionsContext);
  const pageTemplates = lessonPageActionsContext.fmsObject.cduObject.pageTemplates;

  useEffect(() => {}, []);

  const toggleHoldingDisplayEditor = () => {
    fmsContext.holdingDisplayEditorShown
      ? fmsContext.setHoldingDisplayEditorShown(false)
      : fmsContext.setHoldingDisplayEditorShown(true);
  };

  const HoldingDisplay = () => {
    const emptyHoldingDisplay: any = [];
    if (!pageManifest.holdingDisplay) {
      pageManifest.holdingDisplay = emptyHoldingDisplay;
      pagesContext.updatePageManifest(pageManifest);
    }
    return (
      <>
        {pageManifest.cdu.includes("e145") || pageManifest.cdu.includes("q400") ? (
          <label className="instructions show-holding-display-editor">
            show Holding Display Editor
            <span className="check-container fms-key-enable-checkbox cursor-checkbox" style={{ marginLeft: "5px" }}>
              <input
                type="checkbox"
                className="fms-check-input"
                onChange={() => {
                  toggleHoldingDisplayEditor();
                }}
                checked={fmsContext.holdingDisplayEditorShown}
              />
              <span className="cursor-checkmark shaded"></span>
            </span>
          </label>
        ) : null}
      </>
    );
  };

  return <React.Fragment>{HoldingDisplay()}</React.Fragment>;
};

export default HoldingDisplayOptions;
