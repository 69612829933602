// Global Audio Context Provider
import type Wavesurfer from "wavesurfer.js";
import React, { Dispatch } from "react";
import { createLogger } from "../../utils";
import GlobalAudioManager from "./GlobalAudioManager";

const log = createLogger("GlobalAudioProvider", {
  background: "#ff0000",
  color: "#ffffff",
});
interface GlobalAudioState {
  audioLength: number;
  audioContext: AudioContext | null;
  audioElement: HTMLAudioElement | null;
  audioUint8Array: Uint8Array | null;
  wavesurfer: Wavesurfer | null;
  wavesurferContainer: HTMLDivElement | null;
  audioOnPageExists: boolean;
  selectedNarrationIndex: number | null;
  currentAudioUrl: string | null;
  audioBuffer: AudioBuffer | null;
  currentAudioBufferSourceNode: AudioBufferSourceNode | null;
  counterSourceBuffer: AudioBufferSourceNode | null;
  globalAudioManager: GlobalAudioManager;
  loading: boolean;
  lastKnownTime: number | null;
  audioName: string;
  audioPlaying: boolean;
}
interface GlobalAudioAction {
  type: string;
  payload: any;
}
const initialState: GlobalAudioState = {
  audioLength: 0,
  audioContext: null,
  audioElement: null,
  audioUint8Array: null,
  wavesurfer: null,
  wavesurferContainer: null,
  audioOnPageExists: false,
  selectedNarrationIndex: null,
  currentAudioUrl: null,
  audioBuffer: null,
  currentAudioBufferSourceNode: null,
  counterSourceBuffer: null,
  globalAudioManager: new GlobalAudioManager(),
  loading: false,
  lastKnownTime: null,
  audioName: "",
  audioPlaying: false,
};
const GlobalAudioDispatchContext = React.createContext<Dispatch<GlobalAudioAction>>(null as any);
const GlobalAudioStateContext = React.createContext<GlobalAudioState>(null as any);

function globalAudioReducer(state: GlobalAudioState, action: GlobalAudioAction): GlobalAudioState {
  switch (action.type) {
    case "SET_AUDIO_LENGTH": {
      // possible conversion to seconds
      if (action.payload === state.audioLength) {
        // don't update
        return state;
      }
      if (typeof action.payload === "number") {
        return { ...state, audioLength: Math.ceil(action.payload) };
      }
      return state;
    }
    case "SET_AUDIO_PLAYING": {
      return { ...state, audioPlaying: action.payload };
    }
    case "SET_AUDIO_NAME": {
      return { ...state, audioName: action.payload };
    }
    case "SET_LAST_KNOWN_TIME": {
      return { ...state, lastKnownTime: action.payload };
    }
    case "SET_CURRENT_AUDIO_BUFFER_SOURCE_NODE": {
      return { ...state, currentAudioBufferSourceNode: action.payload };
    }
    case "RESET": {
      return {
        ...state,
        // audioOnPageExists: false,
        audioLength: 0,
        audioUint8Array: null,
        wavesurfer: null,
      };
    }
    case "SET_SELECTED_NARRATION_INDEX": {
      return {
        ...state,
        selectedNarrationIndex: action.payload,
      };
    }
    case "SET_AUDIO_BUFFER": {
      //ArrayBuffer to Uint 8
      const audioBuffer: AudioBuffer = action.payload;
      return { ...state, audioBuffer };
    }
    case "SET_AUDIO_CONTEXT": {
      const ac: AudioContext = action.payload;
      return { ...state, audioContext: ac };
    }
    case "SET_LOADING": {
      return { ...state, loading: action.payload };
    }
    case "SET_CURRENT_AUDIO_URL": {
      const audioOnPageExists = !!action.payload;

      log("SET_CURRENT_AUDIO_URL", "audioOnPageExists:", audioOnPageExists);
      log("SET_CURRENT_AUDIO_URL", "action.payload", action.payload);
      return { ...state, currentAudioUrl: action.payload, audioOnPageExists };
    }
    case "SET_COUNTER_SOURCE": {
      return { ...state, counterSourceBuffer: action.payload };
    }
    default:
      return state;
  }
}
const GlobalAudioProvider = ({
  children,
}: // pageManifest
{
  children: React.ReactChild | JSX.IntrinsicElements;
  // pageManifest: any;
}) => {
  const [state, dispatch] = React.useReducer(globalAudioReducer, initialState);
  const { globalAudioManager } = state;
  globalAudioManager.setReactDispatch(dispatch);

  return (
    <GlobalAudioDispatchContext.Provider value={dispatch}>
      <GlobalAudioStateContext.Provider value={state}>{children}</GlobalAudioStateContext.Provider>
    </GlobalAudioDispatchContext.Provider>
  );
};

function useGlobalAudioState() {
  const context = React.useContext(GlobalAudioStateContext);
  if (context === undefined) {
    throw new Error("useGlobalAudioState must be used within a GlobalAudioProvider");
  }
  return context;
}

function useGlobalAudioDispatch() {
  const context = React.useContext(GlobalAudioDispatchContext);
  if (context === undefined) {
    throw new Error("useGlobalAudioDispatch must be used within a GlobalAudioProvider");
  }
  return context;
}

function useGlobalAudioControls() {
  const globalAudioState = useGlobalAudioState();
  const { currentAudioBufferSourceNode, audioBuffer, counterSourceBuffer } = globalAudioState;
  log("currentAudioBufferSourceNode", currentAudioBufferSourceNode);
  const sources = () =>
    currentAudioBufferSourceNode instanceof AudioBufferSourceNode && audioBuffer instanceof AudioBuffer;
  const [started, setStarted] = React.useState(false);
  return {
    async play() {
      if (sources()) {
        if (!started) {
          currentAudioBufferSourceNode!.start();
          counterSourceBuffer?.start();
          setStarted(true);
        }
        if (currentAudioBufferSourceNode!.playbackRate.value === 0) {
          currentAudioBufferSourceNode!.playbackRate.value = 1;
          counterSourceBuffer!.playbackRate.value = 1;
        }
      }
    },
    async pause() {
      if (sources()) {
        if (currentAudioBufferSourceNode!.playbackRate.value === 1) {
          currentAudioBufferSourceNode!.playbackRate.value = 0;
          counterSourceBuffer!.playbackRate.value = 0;
        }
      }
    },
  };
}
export { GlobalAudioProvider, useGlobalAudioState, useGlobalAudioDispatch, useGlobalAudioControls };
