import React from "react";
import { SVGAnnotationProps } from "../index";
import { HighlightAnnotation } from "../HighlightAnnotation";

export function SVGAircraftSymbol1({
  onMouseDown,
  objectId,
  y,
  x,
  width,
  height,
  isDisplayed,
  zIndex,
  borderColor,
  strokeWidth,
  backgroundColor,
  opacity,
  rotate,
}: // transform,
SVGAnnotationProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18.246406 21.190274"
      key={objectId}
      data-objectid={objectId}
      id={`symbol-${objectId}`}
      tabIndex={0}
      className="symbol target"
      name="symbol"
      onMouseDown={(e: React.MouseEvent<SVGSVGElement>) => {
        onMouseDown(e, objectId);
      }}
      // onKeyDown={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyPress(e, kp)}
      // onKeyUp={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyUp(e, kp)}
      preserveAspectRatio="xMidYMid meet"
      style={{
        // top: `${y}px`,
        // left: `${x}px`,
        width: `${width}px`,
        height: `${height}px`,
        zIndex: zIndex,
        transform: `translate(${x}px, ${y}px) rotate(${rotate ?? 0}deg)`,
        opacity: isDisplayed ? opacity : 0.5,
        filter: isDisplayed ? "none" : "grayScale(100%)",
        // `rotate(${attributes?.transform?.rotate}deg) scaleX(${attributes?.transform?.scaleX}) scaleY(${attributes?.transform?.scaleY}) matrix3d(${attributes?.transform?.matrix3d})`
      }}
    >
      <HighlightAnnotation objectId={objectId} />
      <path
        stroke={`${borderColor || "white"}`}
        fill={`${backgroundColor || "transparent"}`}
        strokeWidth={`${strokeWidth / 10 || 0.1}`}
        // className="aircraft_symbol_1"
        d="m 0,0 0.78,3.62 2.68,3.54 -2,10 1.14,1.37 2.85,-6.45 0.88,-0.76 3.72,4.84 v 2 l 1.16,1.69 1.54,-2.91 L 16,16.21 14.7,14.63 12.81,14.11 9.14,9.2 l 1,-0.64 7,-1 -1,-1.47 L 5.93,5.29 3.27,1.75 Z"
      />
    </svg>
  );
}
