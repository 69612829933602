import React from "react";
import { SymbolComponentType } from "../helpers/getSymbol";

const VOR2PointerTail = (props: SymbolComponentType) => {
  const { index, attributes, transformStr } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 7.8601558 15.45927"
      key={index}
      id={`symbol-${index}`}
      className="symbol target"
      name="symbol"
      tabIndex={0}
      preserveAspectRatio="xMidYMid meet"
      style={{
        top: `${attributes.top}%`,
        left: `${attributes.left}%`,
        width: `${attributes.width}%`,
        height: `${attributes.height}%`,
        zIndex: attributes.zIndex,
        // transform:`rotate(${attributes?.transform?.rotate}deg) scaleX(${attributes?.transform?.scaleX}) scaleY(${attributes?.transform?.scaleY}) matrix3d(${attributes?.transform?.matrix3d})`
        transform: transformStr,
      }}
    >
      <path
        // className="vor_2_pointer_tail"
        stroke={`${attributes.borderColor || "white"}`}
        fill={`${attributes?.backgroundColor || "transparent"}`}
        strokeWidth={`${attributes.lineWidth || 0.1}`}
        d="m 3.8808594,0.421875 c -0.5,0.49414063 -1,0.9882813 -1.5,1.4824219 0,3.8183594 0,7.6367187 0,11.4550781 -0.6933594,0 -1.38671877,0 -2.08007815,0 0,0.559896 0,1.119791 0,1.679687 2.38671875,0 4.77343755,0 7.16015625,0 0,-0.55664 0,-1.113281 0,-1.669921 -0.6933594,0 -1.3867187,0 -2.0800781,0 0,-3.8216147 0,-7.6432294 0,-11.4648441 -0.5,-0.4941406 -1,-0.98828127 -1.5,-1.4824219 z"
      />
    </svg>
  );
};

export default VOR2PointerTail;
