import React, { useContext, useState, useEffect, useRef, createRef } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import "../legacy.css";
import { PageContext, AssetContext } from "../../../routes/builderContexts";
import BlobUploaderModal from "../../../components/UploaderModal/AssetUploaderModal";
import genericRepositoryService from "../../../services/genericRepositoryService";
import {
  displayStepSystem,
  setdisplayStyle,
  displayChooseStep,
  displayAddStep,
  displayStepResponsePanel,
  storedCurrentStep,
} from "../../../store/actions/actions";
import addIcon from "../../../assets/icons/ld-add-success-criteria-summary-icon.png";
import deleteIcon from "../../../assets/icons/ld-remove-success-criteria-summary-icon.png";
const StepForm = (props) => {
  const pageContext = useContext(PageContext);
  const assetContext = useContext(AssetContext);
  const updatedManifest = _.cloneDeep(pageContext.pageManifest);
  const [actionType, setActionType] = useState("2");
  const ref = useRef();
  let currentStep = props.currentStep;
  const maxAllowedSteps = 8;
  const [imagesList, setImagesList] = useState([]);
  const [isModalShown, setIsModalShown] = useState(false);
  const [newImage, setNewImage] = useState(-1);
  const [lastStep, setLastStep] = useState(updatedManifest.StepGraphic.length - 1);
  const [displayCanvas, setDisplayCanvas] = useState("notLive");
  const [debugConsole, setDebugConsole] = useState("none");
  const [IL, setIL] = useState(0);
  const [IR, setIR] = useState(0);
  const [IT, setIT] = useState(0);
  const [IB, setIB] = useState(0);
  var [IW, setIW] = useState();
  var [IH, setIH] = useState();
  var [HST, setHST] = useState(0);
  var [HSL, setHSL] = useState(0);
  var [HSB, setHSB] = useState(0);
  var [HSR, setHSR] = useState(0);
  var [FT, setFT] = useState(0);
  var [FL, setFL] = useState(0);
  var [FB, setFB] = useState(0);
  var [FR, setFR] = useState(0);
  var [drag, setDrag] = useState(false);
  const currentStepImage = createRef();
  var CONT = Number.parseFloat(FT / IH);
  var CONL = Number.parseFloat(FL / IW);
  var CONR = Number.parseFloat(FR / IW);
  var CONB = Number.parseFloat(FB / IH);

  const [stepResponseText, setStepResponseText] = useState(updatedManifest.stepResponse[currentStep]);
  if (stepResponseText === null) {
    setStepResponseText(updatedManifest.stepResponse[currentStep]);
  }
  const handleStepResponse = (e) => {
    setStepResponseText(e.target.value);
  };

  const saveChanges = () => {
    let responseText = stepResponseText;
    updatedManifest.stepResponse.splice(currentStep, 1, responseText);
    pageContext.updatePageManifest(updatedManifest);
  };
  const checkForEnter = (e) => {
    if (e.key === "Enter") {
      saveChanges();
    }
  };

  useEffect(() => {
    setStepResponseText(updatedManifest.stepResponse[currentStep]);
  }, [updatedManifest.stepResponse[currentStep]]);

  useEffect(() => {
    if (updatedManifest.hotspot[currentStep] !== undefined && updatedManifest.hotspot[currentStep] !== null) {
      let stepHotSpotArry = updatedManifest.hotspot[currentStep].split(",");
      setHSL(IL + Number.parseInt(stepHotSpotArry[0]));
      setHST(IT + Number.parseInt(stepHotSpotArry[1]));
      setHSR(IL + Number.parseInt(stepHotSpotArry[2]) + Number.parseInt(stepHotSpotArry[0]));
      setHSB(IT + Number.parseInt(stepHotSpotArry[3]) + Number.parseInt(stepHotSpotArry[1]));
    }
  }, [IL, IT, IR, IB, currentStep]);

  useEffect(() => {
    setFL(HSL - IL);
    setFT(HST - IT);
    setFR(HSR - HSL);
    setFB(HSB - HST);
  }, [HSL, HST, HSR, HSB]);

  function HSDebugPanel(e) {
    if (e.key === "~") {
      switch (debugConsole) {
        case "none": {
          setDebugConsole("block");
          break;
        }
        case "block": {
          setDebugConsole("none");
          break;
        }
        default: {
          setDebugConsole("none");
        }
      }
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", HSDebugPanel);
    return () => {
      document.removeEventListener("keydown", HSDebugPanel);
    };
  }, []);

  const closeForm = () => {
    props.setdisplayStyle("none");
    props.displayStepResponsePanel("none");
  };

  const createImageSelector = () => {
    console.log("createImageSelector");
    return (
      <div className="form-group">
        <select className="form-control" size={3} onChange={handleImageSelect}>
          {imagesList.map((image, key) => {
            return (
              <option value={image.assetVersionId} key={key}>
                {image.name}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const handleImageDivClick = (index) => {
    assetContext.setAssetIndex(index);
    assetContext.setAssetTypeId(4);
    assetContext.setAssetManagerController({
      isOpen: true,
      mode: "ImagesOnly",
    });
  };

  const getAssetsToRemove = () => {
    if (pageContext.pageManifest.StepGraphic[currentStep] !== undefined) {
      return pageContext.pageManifest.StepGraphic[currentStep].assetVersionId;
    } else return null;
  };

  const handleImageSelect = (e) => {
    const value = parseInt(e.target.value);
    setNewImage(value);
  };

  const selectImages = async () => {
    const res = await genericRepositoryService.getAllImages();
    setImagesList(res.data.assets);
  };

  const updateImageData = async () => {
    imagesList.forEach((image) => {
      if (image.assetVersionId === newImage) {
        updatedManifest.StepGraphic[0].image = image.blobPath;
        updatedManifest.StepGraphic[0].assetVersionId = image.assetVersionId;
      }
    });
    const assetToRemove = await getAssetsToRemove();
    pageContext.addAssetToCurrentLessonPage(newImage);
    pageContext.removeAssetFromCurrentLessonPage(assetToRemove);
    pageContext.updatePageManifest(updatedManifest);
    setIsModalShown(false);
  };

  const HandleStepOptionChoice = () => {
    let debugConsoleStyle = { display: `${debugConsole}` };

    switch (actionType) {
      case "1": {
        props.displayAddStep(false);
        return null;
      }
      case "2": {
        let imageOne = null;

        if (
          updatedManifest.StepGraphic[currentStep] === undefined ||
          updatedManifest.StepGraphic[currentStep] === null
        ) {
          imageOne = "/api/Asset/" + updatedManifest.StepGraphic[0].image;
        } else {
          imageOne = "/api/Asset/" + updatedManifest.StepGraphic[currentStep].image;
        }

        const splicingHotSpots = (nextStep) => {
          let fullImage = "0,0," + Math.floor(IW) + "," + Math.floor(IH);
          let nothing = "0,0,0,0";
          let upDatedStep = nextStep - 1;
          updatedManifest.hotspot.splice(upDatedStep, 1, fullImage);
          updatedManifest.hotspot.splice(nextStep, 1, nothing);
          if (updatedManifest.StepGraphic.length > 1) {
            updatedManifest.steps = updatedManifest.StepGraphic.length - 1;
          } else {
            updatedManifest.steps = 0;
          }
          pageContext.updatePageManifest(updatedManifest);
        };
        const PlayWithCanvas = () => {
          let holdingHeight = 1;
          let holdingWidth = 1;
          useEffect(() => {
            const myCanvas = ref.current;
            const ctx = myCanvas.getContext("2d");
            ctx.clearRect(0, 0, IW, IH);
            const capture = (myCanvas) => {
              myCanvas.addEventListener("mousedown", mouseDown, false);
              myCanvas.addEventListener("mouseup", mouseUp, false);
              myCanvas.addEventListener("mousemove", mouseMove, false);
            };

            const mouseDown = (e) => {
              setHSL(Math.round(e.pageX));
              setHST(Math.round(e.pageY + 5));
              setHSR(Math.round(e.pageX));
              setHSB(Math.round(e.pageY));
              setDrag(true);
            };

            const mouseUp = (e) => {
              setHSR(Math.round(e.pageX));
              setHSB(Math.round(e.pageY + 5));
              setDrag(false);
            };

            const mouseMove = (e) => {
              if (drag) {
                liveDraw(e);
              }
            };

            const liveDraw = (e) => {
              ctx.fillStyle = "green";
              ctx.strokeStyle = "#FF0000";
              ctx.lineWidth = 1;
              let a = e.pageX - HSL;
              let b = e.pageY - HST;
              ctx.clearRect(0, 0, IW, IH);
              ctx.strokeRect(FL, FT, a, b);
            };

            const draw = () => {
              ctx.fillStyle = "red";
              ctx.strokeStyle = "#FF0000";
              ctx.lineWidth = 3;
              ctx.clearRect(0, 0, IW, IH);
              ctx.strokeRect(FL, FT, FR, FB);
            };

            capture(myCanvas);
            draw();
          }, []);

          switch (displayCanvas) {
            case "live":
              holdingHeight = IH;
              holdingWidth = IW;
              break;
            case "notLive":
              holdingHeight = 1;
              holdingWidth = 1;
              break;
            default:
              return null;
          }

          return (
            <canvas
              className="legacyaddGraphicCanvas"
              ref={ref}
              width={holdingWidth}
              height={holdingHeight}
              top={IT}
              left={IL}
              onPointerLeave={() => leavingCanvas()}
            />
          );
        };

        const selectStep = (i) => {
          if (currentStep <= lastStep) {
            props.storedCurrentStep(i);
          }
        };
        var stepButtons = [];
        const StepSelector = () => {
          for (let i = 1; i <= lastStep; i++) {
            const activeStep = currentStep === i ? "legacystepList2" : "legacystepList";
            stepButtons.push(
              <button className={activeStep} key={i} onClick={() => selectStep(i)}>
                {i}
              </button>,
            );
          }
          const addStepAvailable =
            stepButtons.length < maxAllowedSteps ? (
              <img className="legacystepList" src={addIcon} alt="" onClick={() => addSimpleStep()} />
            ) : null;

          return (
            <div className="legacystepBox">
              {stepButtons}
              {addStepAvailable}
              <div className="legacydeleteBox">
                <img className="legacystepList spacer" src={deleteIcon} alt="" onClick={() => removeStep()} />
                will remove steps {currentStep} and higher.
              </div>
            </div>
          );
        };

        const handleImageLoad = () => {
          let elm = currentStepImage.current;
          let imgBoundingBox1 = elm.getBoundingClientRect();
          setIL(imgBoundingBox1.left);
          setIR(imgBoundingBox1.right);
          setIT(imgBoundingBox1.top);
          setIB(imgBoundingBox1.bottom);
          setIW(imgBoundingBox1.width);
          setIH(imgBoundingBox1.height);
        };

        //####################Primary Functions

        function addSimpleStep() {
          if (lastStep < maxAllowedSteps) {
            let nextStep = lastStep + 1;
            setLastStep(nextStep);
            props.storedCurrentStep(nextStep);
            splicingHotSpots(nextStep);
          }
        }

        function removeStep() {
          updatedManifest.StepGraphic.splice(currentStep, 5);
          updatedManifest.stepResponse.splice(currentStep, 5);
          updatedManifest.hotspot.splice(currentStep - 1, 5, null);
          props.storedCurrentStep(currentStep - 1);
          pageContext.updatePageManifest(updatedManifest);
        }

        const addResponse = () => {
          if (props.stepResponsePanel === "block") {
            props.displayStepResponsePanel("none");
          } else {
            props.displayStepResponsePanel("block");
          }
        };

        const editHotSpot = () => {
          if (displayCanvas === "notLive") {
            setDisplayCanvas("live");
          } else {
            setDisplayCanvas("notLive");
          }
        };

        const canvasState = displayCanvas === "live" ? "on" : "off";
        const stepResponsePanel = props.stepResponsePanel === "block" ? "open" : "closed";
        return (
          <>
            <div className="legacystepHeader">
              <p> Step Actions: Graphic</p>
              <StepSelector />
            </div>
            <div className="legacyaddGraphicImageFrame">
              <PlayWithCanvas />
              <img
                className="legacyaddGraphicImage"
                id="graphicImage"
                src={imageOne}
                ref={currentStepImage}
                alt=""
                onLoad={() => handleImageLoad()}
                onClick={() => handleImageDivClick(currentStep)}
              />
            </div>
            <div className="legacystepControls">
              <button className="legacystepConrolsBTN" onClick={() => editHotSpot()}>
                {" "}
                View/Edit Hot Spot: {canvasState}{" "}
              </button>
              <button className="legacystepConrolsBTN" onClick={() => addResponse()}>
                {" "}
                Task panel is: {stepResponsePanel}{" "}
              </button>
            </div>
            <div className="legacystepResponsePanel">
              Step Response {props.currentStep}
              <p id="legacystepResponseText">
                <textarea
                  type="text"
                  value={stepResponseText}
                  className="editStepResponse"
                  onChange={(e) => handleStepResponse(e)}
                />
              </p>{" "}
            </div>
            <div className="legacycontrolPanel" style={debugConsoleStyle}>
              <p>{displayCanvas} </p>
              <p>
                FL : {FL.toFixed(0)} FT: {FT.toFixed(0)} FR: {FR.toFixed(0)} FB: {FB.toFixed(0)}
              </p>
              <p>
                {" "}
                CONL: {CONL.toFixed(2)} CONT: {CONT.toFixed(2)} CONR: {CONR.toFixed(2)} CONB: {CONB.toFixed(2)}
              </p>
            </div>
          </>
        );
      }
      default:
        return <div> that really didn't work </div>;
    }
  };

  const leavingCanvas = () => {
    let newHotSpot = String(FL + "," + FT + "," + FR + "," + FB);
    updatedManifest.hotspot.splice(currentStep, 1, newHotSpot);
    pageContext.updatePageManifest(updatedManifest);
  };

  return (
    <div>
      <BlobUploaderModal
        showModal={isModalShown}
        imagesList={imagesList}
        addImageToPage={updateImageData}
        createImageSelector={createImageSelector}
        setShowModal={setIsModalShown}
        selectImages={selectImages}
      />

      <div className="legacystepCurtain">
        <div className="legacystepForm">
          <button className="legacyformCloser" onClick={() => closeForm()}>
            x
          </button>
          <HandleStepOptionChoice />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    stepSystemDisplay: state.Current3dPage.stepSystemDisplay,
    openStepResponsePanel: state.Current3dPage.openStepResponsePanel,
    addStep: state.Current3dPage.addStep,
    chooseStep: state.Current3dPage.chooseStep,
    displayStyle: state.Current3dPage.displayStyle,
    currentStep: state.Current3dPage.currentStep,
    stepResponsePanel: state.Current3dPage.openStepResponsePanel,
  };
};

export default connect(mapStateToProps, {
  displayStepSystem,
  displayChooseStep,
  setdisplayStyle,
  displayAddStep,
  displayStepResponsePanel,
  storedCurrentStep,
})(StepForm);
