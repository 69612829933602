import { findLastIndex } from "lodash";
import React, { useEffect, useState } from "react";
import { BASE_PAGES_QUILL_FONT_SIZE_WHITELIST } from "../../../components/Annotation/AnnotationTextMenu/constants";
import RichTextEditor from "../../../components/richTextEditor/NEWrichTextEditor";
import { IRTEModule } from "../BasePageDesigner";

type BaseProps = {
  moduleRTE: IRTEModule[];
  placeholderText: string;
  updatedManifest: any;
  textBlockIndex: number;
  assignNewText: (newText: string) => void;
  displayImage: (imageIndex: number) => JSX.Element;
  handleImageDivClick: (index: number) => void;
  // selectImages: () => Promise<void>,
};

const DoubleGraphicSplitHorizTextBelow: React.FC<BaseProps> = (props: any) => {
  const [secondText, setSecondText]: [string, React.Dispatch<React.SetStateAction<string>>] = useState<string>("");
  const [isLoading, setIsLoading]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState<boolean>(true);
  useEffect(() => {
    const addSecondText = (): string => {
      if (props.updatedManifest.textBlock.length < 2 && !props.updatedManifest.textBlock[1]) {
        props.updatedManifest.textBlock.push({
          text: "<p>Enter text here...</p>",
        });
      } else if (props.updatedManifest.textBlock[1].text && props.updatedManifest.textBlock[1].text.length === 0) {
        props.updatedManifest.textBlock[1].text = "<p>Enter text here...</p>";
      }

      return props.updatedManifest.textBlock[1].text;
    };
    setSecondText(addSecondText());
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <></>;
  } else {
    return (
      <div className="graphic-center-container-row">
        <div className="double-graphic-text-column">
          <div className="double-graphic-center-horiz-image" onClick={() => props.handleImageDivClick(0)} id="0">
            <div className="double-graphic-center-horiz-text-container-img-aspect-container">
              <div className="img-aspect-container-inside">
                <div className="img-aspect-centering">{props.displayImage(0)}</div>
              </div>
            </div>
          </div>

          <div
            className="double-graphic-center-horiz-text-container"
            onFocus={() => props.setTextBlockIndex(0)}
            onBlur={() => props.setTextBlockIndex(0)}
          >
            <RichTextEditor
              displayText={props.updatedManifest.textBlock[0].text}
              assignNewText={props.assignNewText}
              placeholder={props.placeholderText}
              module={props.moduleRTE}
              quillRef={props.quillRef}
              handleSelectionChange={props.handleSelectionChange()}
              theme="bubble"
              version={props.updatedManifest.textBlock[0].version}
            />
          </div>
        </div>

        <div className="double-graphic-text-column">
          <div className="double-graphic-center-horiz-image" onClick={() => props.handleImageDivClick(1)} id="0">
            <div className="double-graphic-center-horiz-text-container-img-aspect-container">
              <div className="img-aspect-container-inside">
                <div className="img-aspect-centering">{props.displayImage(1)}</div>
              </div>
            </div>
          </div>

          <div
            className="double-graphic-center-horiz-text-container"
            onFocus={() => props.setTextBlockIndex(1)}
            onBlur={() => props.setTextBlockIndex(0)}
          >
            <RichTextEditor
              displayText={secondText}
              assignNewText={props.assignNewText}
              placeholder={props.placeholderText}
              module={props.moduleRTE}
              theme="bubble"
              version={props.updatedManifest.textBlock[1].version}
            />
          </div>
        </div>
      </div>
    );
  }
};

export default DoubleGraphicSplitHorizTextBelow;
