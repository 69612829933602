import React, { useEffect, useState } from "react";
import useDebounce from "../../../hooks/useDebounce";
import styles from "./HotspotLister.module.css";

type props = {
  objectiveLabel: string;
  pageObjective: string;
  onTextChange: (val: string) => void;
  onLabelChange: (val: string) => void;
};
const PageObjective = ({ pageObjective, onTextChange, onLabelChange, objectiveLabel }: props) => {
  const [objectivetext, setObjectiveText] = useState<string>(pageObjective);
  const debouncedText = useDebounce(objectivetext, 250);

  useEffect(() => {
    onTextChange(debouncedText);
  }, [debouncedText]);
  return (
    <div className={styles.pageObjectiveSection}>
      <div>
        <select className={styles.objectiveLabelSelect} onChange={(e) => onLabelChange(e.currentTarget.value)}>
          <option hidden>{objectiveLabel}</option>
          <option value="Enabling Objective">Enabling Objective</option>
          <option value="Terminal Objective">Terminal Objective</option>
          <option value="Information">Information</option>
        </select>
      </div>
      <textarea maxLength={300} value={objectivetext} onChange={(e) => setObjectiveText(e.target.value)}></textarea>
    </div>
  );
};

export default PageObjective;
