import React, { ChangeEventHandler, MouseEventHandler, useRef, useState } from "react";
import classes from "../../../../utils/HTML/classes";
import type { TextToSpeechModalProps } from "../../../../components/Modals/TextToSpeechModal/types";
import { EInteractiveAudioType, LanguageType } from "../../../../models/IInteractiveAudio";

import { useTimeline } from "../../../../contexts/TimelineProvider/TimelineProvider";
import { TextToSpeechModal } from "../../../../components/Modals/TextToSpeechModal";
import { useLessonPage } from "../../../../contexts/LessonPagesProvider/LessonPagesProvider";
import { useAudioUpload } from "../../../../hooks/useAudioUpload";
import { useAudioManagerStore } from "../../../../contexts/PageAudioManager";

import { AudioPopoverAction, InsertMode } from "./types";
import "./index.css";
import { OBJECT_ROW_HEIGHT } from "../../TimelinePanel";
import { audioBlobToApi } from "../../helpers";
import { useObjectsUtils } from "../../../../contexts/ObjectsProvider";
import { useNarrationStore } from "./store";
import { useShallow } from "zustand/react/shallow";
import { InteractiveAudioGroup } from "../InteractiveAudioGroup";
import { AddAudioButton } from "./AddAudioButton";
import { nanoid } from "../../../../lib/nanoId";

export const CompositeNarrationRow = () => {
  const audioInputRef = useRef<HTMLInputElement>(null);
  const [timeline, , { clippedSpaceFromUnitSpace }] = useTimeline();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [insertMode, setInsertMode] = useState<InsertMode>(InsertMode.After);
  const { upload } = useAudioUpload();
  const { pageManifest } = useLessonPage();
  const { unselectAll } = useObjectsUtils();
  const isDragging = useNarrationStore((state) => state.isDragging);
  const [selectedAudio, selectAudio, loadNarrationAudio, moveNarrationAudio] = useAudioManagerStore(
    useShallow((state) => [
      state.getSelectedAudio(),
      state.selectAudio,
      state.loadNarrationAudio,
      state.moveNarrationAudio,
    ]),
  );
  const objects = useNarrationStore((state) => state.objects);
  const isEmpty = objects.size <= 0;

  // Helper
  const getAddButtonPosition = (): number => {
    const lastItem = Array.from(objects.values()).pop();

    if (lastItem) {
      return clippedSpaceFromUnitSpace(lastItem?.end ?? 0) + 4;
    }

    return timeline.widthInPx / 2;
  };

  // Handlers
  const handleAudioInputClick: MouseEventHandler<HTMLInputElement> = (event) => {
    event.stopPropagation();
  };
  const handleOnMove = (objectId: string, startTime: number) => {
    moveNarrationAudio(objectId, startTime);
  };
  const handleBrowseFiles: ChangeEventHandler<HTMLInputElement> = async (event) => {
    const file = event.target.files?.[0];

    if (file) {
      void upload(file).then(async (uploadedFile) => {
        if (uploadedFile) {
          await loadNarrationAudio(nanoid(), audioBlobToApi(uploadedFile.blobPath), {
            start: insertMode === InsertMode.Before && selectedAudio ? selectedAudio.start : -1,
            type: EInteractiveAudioType.NARRATION,
            title: `Audio ${objects.size + 1}`,
            savedText: "",
            language: LanguageType.PRIMARY,
          });

          if (audioInputRef.current) {
            audioInputRef.current.value = "";
            audioInputRef.current.files = null;
          }
        }
      });
    }
  };
  const handleTTSAudio: TextToSpeechModalProps["onFinish"] = async ({ generatedAudio, ...ttsProps }) => {
    setIsModalOpen(false);

    if (!generatedAudio) {
      return;
    }

    loadNarrationAudio(generatedAudio.assetId.toString(), audioBlobToApi(generatedAudio.blobPath), {
      start: insertMode === InsertMode.Before && selectedAudio ? selectedAudio.start : -1,
      type: EInteractiveAudioType.NARRATION,
      title: `Text to Speech Audio ${objects.size + 1}`,
      savedText: ttsProps.narrationText,
      language: LanguageType.PRIMARY,
      ttsProps,
    });
  };
  const handleAddAudio = (action: AudioPopoverAction, close: () => void, targetObjectId?: string) => {
    close();

    if (targetObjectId) {
      setInsertMode(InsertMode.Before);
      selectAudio(targetObjectId);
      unselectAll();
    } else {
      setInsertMode(InsertMode.After);
    }

    switch (action) {
      case AudioPopoverAction.TextToSpeech:
        setIsModalOpen(true);
        break;
      case AudioPopoverAction.UploadAudio:
        // Trigger upload field programmatically
        audioInputRef?.current?.click();
        break;
    }
  };

  return (
    <li className="right-obj-row">
      <div
        className="right-obj-wrapper"
        style={{ width: isEmpty ? timeline.widthInPx + "px" : "100%", height: OBJECT_ROW_HEIGHT * 2 }}
      >
        {/* Holds all audios */}
        <div
          className={classes("narration-row-wrapper", "narration-row-wrapper--interactive", {
            "narration-row-wrapper--empty": isEmpty,
            "narration-row-wrapper--dragging": isDragging,
          })}
        >
          <div className="narration-row-divider"></div>
          {/*Render here the audioBars*/}
          {Array.from(objects.values()).map((audioGroup) => {
            return (
              <InteractiveAudioGroup
                // key={audioGroup.primaryId}
                key={`${audioGroup.primaryId}-${audioGroup.start}-${audioGroup.end}`}
                groupObject={audioGroup}
                onClickAdd={(targetObjectId, action, close) => {
                  handleAddAudio(action, close, targetObjectId);
                }}
                onMoved={(newStart) => handleOnMove(audioGroup.primaryId, newStart)}
              />
            );
          })}

          <div
            className={classes("add-button-container", {
              "add-button-container--hidden": isDragging,
            })}
            style={{
              left: getAddButtonPosition(),
            }}
          >
            <AddAudioButton handleAddAudio={handleAddAudio} />
          </div>
        </div>
      </div>
      <input
        ref={audioInputRef}
        type="file"
        onChange={handleBrowseFiles}
        onClick={handleAudioInputClick}
        className="hidden-input"
        accept="audio/*"
      />
      <TextToSpeechModal
        onFinish={handleTTSAudio}
        onClose={() => setIsModalOpen(false)}
        data={{
          narrationText: objects.size <= 0 ? pageManifest?.pageText : "",
        }}
        open={isModalOpen}
      />
    </li>
  );
};
