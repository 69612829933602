import React, { useEffect } from "react";
import { prepareAudiosToManifest } from "../../utils/PageManifestParsing/audioParsing";
import { TimelineDrawer } from "../TimelineDrawer";
import { isTimelineEnabled } from "../../utils/Lesson";
import { useLessonPage, useLessonPagesState } from "../../contexts/LessonPagesProvider/LessonPagesProvider";
import { useAudioManagerStore } from "../../contexts/PageAudioManager";
import { useObjectsUtils } from "../../contexts/ObjectsProvider";
import { useNarrationStore } from "../../panels/TimelinePanel/Right/CompositeNarrationRow/store";
import { useShallow } from "zustand/react/shallow";
import { NarrationAudio } from "../../models/IInteractiveAudio";
import { audioBlobToApi } from "../../panels/TimelinePanel/helpers";
import { getSortedAudios } from "../../contexts/PageAudioManager/helpers";

export const DrawerContainer = () => {
  const lessonPagesState = useLessonPagesState();
  const [loadNarrationAudio, resetNarration, isDirty, setIsDirty] = useAudioManagerStore(
    useShallow((state) => [state.loadNarrationAudio, state.resetNarration, state.isDirty, state.setIsDirty]),
  );
  const narrationAudios = useAudioManagerStore((state) => state.narrationAudios);
  const { pageManifest, updateManifest, currentLessonPage, currentLessonPageIndex } = useLessonPage();
  const { needtoSave } = useObjectsUtils();
  const { setObjects } = useNarrationStore();

  const hasTimeline = isTimelineEnabled(lessonPagesState.currentLessonPage);

  // Initial load
  useEffect(() => {
    // reset Timeline
    resetNarration();

    const audiosToLoad = getSortedAudios([...(pageManifest?.narrationAudios ?? [])]) as NarrationAudio[];

    for (const audio of audiosToLoad) {
      loadNarrationAudio(audio.objectId, audioBlobToApi(audio.input as string), {
        ...audio,
      });
    }
  }, [currentLessonPage?.pageId, currentLessonPageIndex]);

  // Save
  useEffect(() => {
    if (isDirty) {
      needtoSave();
      setIsDirty(false);
    }
  }, [isDirty]);

  useEffect(() => {
    setObjects(narrationAudios);

    updateManifest({
      // audios: prepareAudiosToManifest(audios),
      audios: [],
      narrationAudios: prepareAudiosToManifest(narrationAudios),
    });
  }, [narrationAudios]);

  return <>{hasTimeline && <TimelineDrawer />}</>;
};
