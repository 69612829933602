import React, { useRef } from "react";
import "../CPaT3d_Player.css";

const HSR = (props) => {
  const ref = useRef();
  let hsData = props.hsData;

  if (hsData === undefined || hsData === null) {
    hsData = {};
  }
  if (hsData.key === undefined || hsData.key === null) {
    hsData.key = "default";
  }
  if (hsData.position === undefined || hsData.position === null) {
    hsData.position = [0, 150, 0];
  }
  if (hsData.rotation === undefined || hsData.rotation === null) {
    hsData.rotation = [0, 0, 0];
  }
  if (hsData.geometry === undefined || hsData.geometry === null) {
    hsData.geometry = [30, 30, 30];
  }

  return (
    <>
      <mesh
        key={hsData.key}
        position={hsData.position}
        rotation={hsData.rotation}
        ref={ref}
        onClick={() => props.checkActiveHotSpot(`${hsData.key}`)}
      >
        <boxGeometry attach="geometry" args={hsData.geometry} />
        <meshPhysicalMaterial attach="material" color={"green"} transparent opacity={1} />
      </mesh>
    </>
  );
};

export default HSR;
