import { useMiscUI } from "../../contexts/MiscUI/MiscUIProvider";
import { CutoutShapes } from "../../types";
import { useObjectsDispatch, ObjectActionsType, useObjectsState } from "../../contexts/ObjectsProvider";
import type { CSSProperties } from "react";

type Mask = {
  type: CutoutShapes;
  x: number;
  y: number;
  width: number;
  height: number;
  cutoutId: string;
  rotate: number;
  isVisible: boolean;
};

export type RectangularMask = Mask & {
  type: "rectangular";
};

export type TriangleMask = Mask & {
  type: "triangle";
};

export type CircleMask = Mask & {
  type: "circle";
};

function getTrianglePoints(x: number, y: number, width: number, height: number) {
  return `${x},${y + height} ${x + width / 2},${y} ${x + width},${y + height}`;
}

/**
 *
 * We assume that origin is always top left corner, like css does
 *
 */
export function MasksRenderer({ masks }: { masks: Mask[] }) {
  return (
    <>
      {masks.map((mask) => (
        <Mask key={mask.cutoutId} mask={mask} />
      ))}
    </>
  );
}

function Mask({ mask }: { mask: Mask }) {
  const maskId = mask.cutoutId;
  if (mask.isVisible === false) return null;
  const objectsDispatch = useObjectsDispatch();
  const { selectedObjects } = useObjectsState();
  const cutoutsList = selectedObjects[0]?.blurCutoutShapes;
  const commonSVGStyle: CSSProperties = {
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
    width: "100%",
    zIndex: 1,
  };

  return (
    <div
      onClick={() => {
        cutoutsList.forEach((item) => {
          if (item.id !== mask.cutoutId) {
            objectsDispatch({
              type: ObjectActionsType.UPDATE_BLUR_CUTOUT,
              payload: {
                cutoutId: item.id,
                props: { isOpen: false },
              },
            });
          }
        });
        objectsDispatch({
          type: ObjectActionsType.UPDATE_BLUR_CUTOUT,
          payload: {
            cutoutId: mask.cutoutId,
            props: { isOpen: true },
          },
        });
      }}
    >
      {mask.type === "rectangular" && (
        <svg style={commonSVGStyle}>
          <mask id={maskId}>
            <rect
              x={mask.x}
              y={mask.y}
              width={mask.width}
              height={mask.height}
              transform={`rotate(${mask.rotate} ${mask.x + mask.width / 2} ${mask.y + mask.height / 2})`}
              fill="white"
            />
          </mask>
        </svg>
      )}
      {mask.type === "triangle" && (
        <svg style={commonSVGStyle}>
          <mask id={maskId}>
            <polygon
              points={getTrianglePoints(mask.x, mask.y, mask.width, mask.height)}
              fill="white"
              transform={`rotate(${mask.rotate} ${mask.x + mask.width / 2} ${mask.y + mask.height / 2})`}
            />
          </mask>
        </svg>
      )}
      {mask.type === "circle" && (
        <svg style={commonSVGStyle}>
          <mask id={maskId}>
            <circle
              cx={mask.x + mask.width / 2}
              cy={mask.y + mask.height / 2}
              r={mask.width / 2}
              fill="white"
              transform={`rotate(${mask.rotate} ${mask.x + mask.width / 2} ${mask.y + mask.height / 2})`}
            />
          </mask>
        </svg>
      )}
      <DraggingHandle mask={mask} />
    </div>
  );
}

function DraggingHandle({ mask }: { mask: Mask }) {
  const [, setMiscUI] = useMiscUI();
  const transform = `translate(${mask.x}px, ${mask.y}px) rotate(${mask.rotate}deg)`;
  const commonStyle: CSSProperties = {
    position: "absolute",
    zIndex: 2,
    width: mask.width,
    height: mask.height,
    transform,
  };
  const handleStyle: CSSProperties = {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    cursor: "grab",
  };
  return (
    <>
      <div id={`handle-${mask.cutoutId}`} style={commonStyle}>
        <div
          onClick={() => {
            setMiscUI({
              type: "SET_SELECTED_MASK_ID",
              payload: mask.cutoutId,
            });
          }}
          style={handleStyle}
        />
      </div>
    </>
  );
}
