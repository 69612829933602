export const ThreeD_PAGE = "ThreeDModel";
export const QUIZ_PAGE = "Quiz";
export const BASIC_PAGE = "Base";
export const FMS_PAGE = "FMS";
export const TITLE_PAGE = "Title";
export const EXIT_PAGE = "Exit";
export const SCORE_PAGE = "Score";
export const WALK_AROUND = "Walkaround";
export const CABIN = "Cabin";

export const PAGE_TYPES = [ThreeD_PAGE, QUIZ_PAGE, BASIC_PAGE, FMS_PAGE, WALK_AROUND, CABIN] as const;

export const templatePath = "cpat/manifests/templates/";
export const ThreeDPath = "3dtemplate.json";
export const quizPagePath = "quiztemplate.json";
export const basePagePath = "basetemplate.json";
export const fmsPagePath = "fmstemplate.json";
