import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { CDULayoutProps } from "./ClassicLayout";
import _ from "lodash";
import blobHelper from "../../../../../../components/blobUrlFormatHelper";
import {
  CPAT_LESSON_MODE_INSTRUCTIONAL,
  CPAT_LESSON_MODE_PRACTICE,
  CPAT_LESSON_MODE_TEST,
} from "../../../../helpers/lessonmodetypes";

import SmartObjective from "../../../../components/SmartObjective/SmartObjective";
import {
  IFmsPageManifest,
  IFMSReferenceImage,
} from "../../../../../../pageTypes/FMS_Player/Interfaces/IFmsPageManifest";
import FMSReferenceImage from "../../components/FMSReferenceImage";
import { ISuccessCriteria } from "../../models/IFMS";
import { ICduImage } from "../../../../../../pageTypes/FMS_Player/Interfaces/ICdu";
import FMSReferenceImageModal from "../../components/FMSReferenceImageModal";
//import { editPage } from '../../../../store/actions/actions';
import {
  PageContext,
  IFMSObject,
  IPageContext,
  LessonPageActionsContext,
  ILessonPageActionsContext,
  IAssetManagementContext,
  AssetContext,
} from "../../../../../../routes/builderContexts";
import { instructionsType } from "../../FMSWYSIWYG";
import CduImageOverlays from "../../../../../../pageTypes/FMS_Player/components/cduImageOverlays";
// import { PageContext, IFMSObject, IPageContext, LessonPageActionsContext, ILessonPageActionsContext, IAssetManagementContext, AssetContext } from '../../routes/builderContexts';
/**
 *
 *
 * <FMSReferenceImage
 * pageManifest={props.pageManifest}
 * largeCduModel={props.image?.width > props.image?.height}
 * cduHeigth={props.image?.height || 100}
 * cduWidth={props.image?.width || 100}
 * displayReferenceImageModal={props.displayReferenceImageModal}
 * removeImage={props.removeImage}
 * gridLayout={false}/>
 */

const WideCDULayout = (props: CDULayoutProps) => {
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const lessonPageActionsContext: ILessonPageActionsContext =
    useContext<ILessonPageActionsContext>(LessonPageActionsContext);
  const currentCDU = lessonPageActionsContext.fmsObject.cduObject;
  const pageManifest: IFmsPageManifest = pageContext.pageManifest as IFmsPageManifest;
  return (
    <div style={{ height: "100%" }}>
      <div className="large-fms-row-grid">
        <div className={"fms-wide-image-container"} id={`${props.getWidth()}`}>
          {/* {displayPageAttempts()} */}
          <FMSReferenceImage
            pageManifest={props.pageManifest}
            largeCduModel={true}
            cduHeigth={props.image?.height || 100}
            cduWidth={props.image?.width || 100}
            displayReferenceImageModal={props.displayReferenceImageModal}
            removeImage={props.removeImage}
            gridLayout={true}
          />
        </div>
        <div ref={props.fmsRef} className={"fms-container"}>
          <svg
            className="fms-svg"
            // width="35vw"
            // height="70vh"
            overflow="visible"
            width={`${props.fmsDims.width}`}
            height={`${props.fmsDims.height}`}
            viewBox={`0 0 ${props.image?.width} ${props.image?.height}`}
            preserveAspectRatio={"xMaxYMax meet"}
          >
            <g>
              <image height="100%" width="100%" overflow="visible" href={blobHelper(props.cduPath)} />
              {lessonPageActionsContext.isAnnotationsShown ? (
                <CduImageOverlays
                  overLays={currentCDU.overLays}
                  cduPath={pageManifest.cduPath}
                  assignFmsKeypad={null}
                  pageOverlays={
                    pageManifest.pageLayouts ? pageManifest.pageLayouts[pageManifest.bootPage].overLays : null
                  }
                  pageImage={currentCDU.cduScreen ? currentCDU.cduScreen : null}
                  pageTemplates={currentCDU.pageTemplates}
                  accessCduLineInformation={null}
                  paging={pageManifest.paging}
                />
              ) : (
                <></>
              )}
              {/* <rect fill="black" height={fmsDims.height} width={fmsDims.width} fillOpacity=".5"  />{" "} */}
              <rect fill="black" height={"100%"} width={"100%"} fillOpacity=".5" />{" "}
            </g>
          </svg>
          <div
            className={`CDU-preview-label-center-${
              props.cduPath.includes("a220")
                ? "a220-preview"
                : props.cduPath.includes("b787-8")
                ? "b787-8-preview"
                : props.cduPath.includes("a350")
                ? "a350-preview"
                : props.cduPath.includes("145")
                ? "erj145-preview"
                : "CDU-grid-preview"
            }`}
          >
            <p
              className={
                props.cduPath.includes("a220")
                  ? "a220-preview-label"
                  : props.cduPath.includes("b787-8")
                  ? "b787-8-preview-label"
                  : props.cduPath.includes("a350")
                  ? "a350-preview-label"
                  : props.cduPath.includes("145")
                  ? "erj145-preview-label"
                  : props.cduPath.includes("e190") || props.cduPath.includes("e175") || props.cduPath.includes("e170")
                  ? "embraer-preview-label"
                  : "CDU-grid-preview-label"
              }
            >
              CDU (Preview Only) - Configuration is only available in Edit Mode{" "}
            </p>
          </div>
        </div>
        <SmartObjective
          list={props.stepInstructions}
          enablingObjective={props.pageManifest.instructions.lessonInstructions.replace(/<[^>]*>/g, "")}
          enablingObjectiveTitle={props.pageManifest.instructions.lessonTitle.replace(/<[^>]*>/g, "")}
          listIndex={props.criteriaIndex}
          lessonMode={CPAT_LESSON_MODE_INSTRUCTIONAL}
          // isFeedbackShown={isHelperShown}
          // feedback={fms.getCustomFeedback(stateOfFMS())}
          // disableHint={maxAttempts === 0 ? true : false}
          setIsFeedbackShown={props.setIsHelperShown}
        />
      </div>

      <FMSReferenceImageModal
        imageSource={
          props.pageManifest.pageImage && props.pageManifest.pageImage[0]
            ? (props.pageManifest.pageImage as IFMSReferenceImage[])[0].imagePath
            : ""
        }
        isModalShown={props.isReferenceShown}
        setIsModalShown={props.setIsReferenceShown}
        version={props.version}
        referenceTab={true}
        removeImage={props.removeImage}
        assetVersionId={
          props.pageManifest.pageImage && props.pageManifest.pageImage[0]
            ? (props.pageManifest.pageImage as IFMSReferenceImage[])[0].assetVersionId
            : -1
        }
        newPageImageId={props.newPageImageId}
        setNewPageImageId={props.setNewPageImageId}
      />
    </div>
  );
};

export default WideCDULayout;
