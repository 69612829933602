import React from "react";

const AnnotationMenuArrowSolid = () => {
  return (
    <React.Fragment>
      <span className="icon-Arrow">
        <span className="path1"></span>
        <span className="path2"></span>
        <span className="path3"></span>
        <span className="path4"></span>
      </span>
    </React.Fragment>
  );
};

export default AnnotationMenuArrowSolid;
