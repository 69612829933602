import React, { createContext, Dispatch, PropsWithChildren, useContext, useReducer } from "react";
import {
  TextToSpeechAction,
  TextToSpeechActionsType,
  TextToSpeechState,
  TTSLanguages,
  TTSStatefulAsyncMethod,
  TTSStatefulSyncMethod,
  TTSVoices,
} from "./types";
import genericRepositoryService from "../../services/genericRepositoryService";
import { IGenerateAudioRequest } from "../../models/IGenerateAudioRequest";
import { IUploadAssetResponse } from "../../models/IUploadAssetResponse";
import { ContextUtils } from "../../types/StatefulMethod";

const initialState: TextToSpeechState = {
  open: false,
  audio: null,
  languages: null,
  voices: null,
  isLoading: false,
  isGenerating: false,
  sourceText: "",
  error: undefined,
};

const TextToSpeechContext = createContext<TextToSpeechState>(initialState);
const TextToSpeechDispatch = createContext<unknown>({});

const textToSpeechReducer = (state: TextToSpeechState, action: TextToSpeechAction): TextToSpeechState => {
  switch (action.type) {
    case TextToSpeechActionsType.SET_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
        isGenerating: false,
      };
    }

    case TextToSpeechActionsType.SET_OPEN: {
      return {
        ...state,
        open: action.payload.open,
      };
    }

    case TextToSpeechActionsType.SET_AUDIO: {
      return {
        ...state,
        audio: action.payload.audio,
      };
    }

    case TextToSpeechActionsType.SET_LANGUAGES: {
      return {
        ...state,
        languages: action.payload.languages,
      };
    }

    case TextToSpeechActionsType.SET_VOICES: {
      return {
        ...state,
        voices: action.payload.voices,
      };
    }

    case TextToSpeechActionsType.SET_GENERATING: {
      return {
        ...state,
        isGenerating: action.payload.isGenerating,
      };
    }

    case TextToSpeechActionsType.SET_LOADING: {
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    }

    case TextToSpeechActionsType.RESET: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { languages, voices, ...rest } = initialState;

      return {
        ...state,
        ...rest,
      };
    }

    default:
      return state;
  }
};
export function useTextToSpeechDispatch() {
  const ctx = useContext(TextToSpeechDispatch);
  if (ctx === undefined) {
    throw new Error("Wrap component in TextToSpeechProvider");
  }
  return ctx as Dispatch<TextToSpeechAction>;
}

export function useTextToSpeechState() {
  const ctx = useContext(TextToSpeechContext);
  if (ctx === undefined) {
    throw new Error("Wrap component in TextToSpeechProvider");
  }
  return ctx as TextToSpeechState;
}

export function TextToSpeechProvider({ children }: PropsWithChildren<unknown>) {
  const [state, dispatch] = useReducer(textToSpeechReducer, initialState);

  return (
    <TextToSpeechDispatch.Provider value={dispatch}>
      <TextToSpeechContext.Provider value={state}>{children}</TextToSpeechContext.Provider>
    </TextToSpeechDispatch.Provider>
  );
}
const statefulGetLanguages: TTSStatefulAsyncMethod<TTSLanguages> = async function ({ getState, getDispatcher }) {
  const { languages } = getState();
  const dispatch = getDispatcher();

  if (languages) {
    return languages;
  }

  dispatch({
    type: TextToSpeechActionsType.SET_LOADING,
    payload: {
      isLoading: true,
    },
  });

  return await genericRepositoryService.getLanguages().then((result) => {
    dispatch({
      type: TextToSpeechActionsType.SET_LANGUAGES,
      payload: {
        languages: result.data.supportedVoiceLanguages,
      },
    });

    dispatch({
      type: TextToSpeechActionsType.SET_LOADING,
      payload: {
        isLoading: false,
      },
    });

    return result.data.supportedVoiceLanguages;
  });
};

export type GetVoicesParams = { language: string };
const statefulGetVoices: TTSStatefulAsyncMethod<TTSVoices, GetVoicesParams> = async function (
  { getDispatcher },
  { language },
) {
  const dispatch = getDispatcher();

  dispatch({
    type: TextToSpeechActionsType.SET_LOADING,
    payload: {
      isLoading: true,
    },
  });

  return await genericRepositoryService.getVoices({ localeCode: language }).then((result) => {
    dispatch({
      type: TextToSpeechActionsType.SET_VOICES,
      payload: {
        voices: result.data.supportedVoices,
      },
    });

    dispatch({
      type: TextToSpeechActionsType.SET_LOADING,
      payload: {
        isLoading: false,
      },
    });

    return result.data.supportedVoices;
  });
};

export type GenerateAudioParams = Omit<IGenerateAudioRequest, "tabName">;
const statefulGenerateAudio: TTSStatefulAsyncMethod<IUploadAssetResponse, GenerateAudioParams> = async function (
  { getDispatcher },
  params,
) {
  const dispatch = getDispatcher();

  // Validation can be done here as well, but preferably on the client side...

  dispatch({
    type: TextToSpeechActionsType.SET_GENERATING,
    payload: {
      isGenerating: true,
    },
  });

  return await genericRepositoryService
    .generateAudio({
      ...params,
      tabName: "tts",
    })
    .then((result) => {
      dispatch({
        type: TextToSpeechActionsType.SET_AUDIO,
        payload: {
          audio: result.data,
        },
      });

      dispatch({
        type: TextToSpeechActionsType.SET_GENERATING,
        payload: {
          isGenerating: false,
        },
      });

      return result.data;
    });
};

const statefulReset: TTSStatefulSyncMethod<void, void> = ({ getDispatcher }) => {
  const dispatch = getDispatcher();

  dispatch({
    type: TextToSpeechActionsType.RESET,
    payload: {},
  });
};

export function useTextToSpeech() {
  const state = useTextToSpeechState();
  const dispatch = useTextToSpeechDispatch();
  const wrapWithContext = function <TParams, TReturn>(
    method:
      | ((stateOptions: ContextUtils<TextToSpeechState, TextToSpeechAction>, params: TParams) => TReturn)
      | ((stateOptions: ContextUtils<TextToSpeechState, TextToSpeechAction>) => TReturn),
  ) {
    return function (options: TParams): TReturn {
      return method(
        {
          getState: () => state,
          getDispatcher: () => dispatch,
        },
        options,
      );
    };
  };

  return {
    setOpen: (open: boolean) => {
      dispatch({ type: TextToSpeechActionsType.SET_OPEN, payload: { open } });
    },
    getLanguages: wrapWithContext(statefulGetLanguages),
    getVoices: wrapWithContext(statefulGetVoices),
    generateAudio: wrapWithContext(statefulGenerateAudio),
    reset: wrapWithContext(statefulReset),
  };
}
