import React from "react";
import { Modal } from "react-bootstrap";
import IQuizManifest from "./IQuizInterfaces";

type QuizChoiceType = {
  correctFeedback: string;
  incorrectFeedback: string;
  index: number;
  isCorrect: boolean;
  manifest: IQuizManifest;
  show: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSave: () => void;
  markAsCorrect: (e: any) => void;
  onHide: () => void;
};

const QuizChoiceModal = (props: QuizChoiceType) => {
  return (
    <React.Fragment>
      <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header className="quiz-modal" closeButton>
          Answer {props.index >= 0 ? `#${props.index + 1}` : ""} Feedback
        </Modal.Header>
        <Modal.Body className="quiz-modal">
          <div>
            <p>Enter the requested information to set up response feedback</p>
            <div hidden={props.index >= 0 && !props.isCorrect}>
              <label htmlFor="correct" className="quiz-modal correct">
                <strong>Correct:</strong>
              </label>
              <input
                type="text"
                name="correct"
                id="correct"
                className="quiz-modal text-input"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.handleChange(e)}
                placeholder="Correct Answer Feedback"
                value={props.correctFeedback}
              />
            </div>
            <div hidden={props.index >= 0 && props.isCorrect}>
              <label htmlFor="incorrect" className="quiz-modal incorrect">
                <strong>Incorrect:</strong>
              </label>
              <input
                type="text"
                name="incorrect"
                id="incorrect"
                className="quiz-modal text-input"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.handleChange(e)}
                placeholder="Incorrect Answer Feedback"
                value={props.incorrectFeedback}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="quiz-modal-button" onClick={() => props.handleSave()}>
            Save
          </button>
          <button type="button" className="quiz-modal-button" onClick={() => props.onHide()}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default QuizChoiceModal;
