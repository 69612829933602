import React from "react";
import { AnnotationPropType } from "../AnnotationThumbnail";

const AnnotationPointArrow = (props: AnnotationPropType) => {
  const { annotation, index, ratio } = props;
  // const points: string = `${20 * ratio},${3 * ratio} ${6 * ratio},${10 * ratio} ${20 * ratio},${17 * ratio}`

  // function checkResize() {
  //   let thisSVG = document.getElementById(`lineArrow-${index}`) as any;
  //   thisSVG.firstChild.attributes.points.nodeValue = `${1.2 * thisSVG.clientHeight / 2},${thisSVG.clientHeight - 3} 3,${thisSVG.clientHeight / 2} ${1.2 * thisSVG.clientHeight / 2},3`;
  //   thisSVG.lastChild.attributes.y1.nodeValue = `${thisSVG.clientHeight / 2}`;
  //   thisSVG.lastChild.attributes.y2.nodeValue = `${thisSVG.clientHeight / 2}`;
  // }

  const display = () => {
    const rotateDeg: number | undefined = annotation?.transform?.rotate;
    // const scaleXNum: number | undefined = annotation?.transform?.scaleX
    // const scaleYNum: number | undefined = annotation?.transform?.scaleY
    // const matrix: number[] | undefined = annotation?.transform?.matrix3d;
    // const transformstr = `rotate(${rotateDeg || 0}) scaleX(${scaleXNum || 0}) scaleY(${scaleYNum || 0}) matrix3d(${matrix || []})`
    let point1: number = 18 * ratio;
    let point1b: number = 10 * ratio;
    let point2: number = 3 * ratio;
    const point2b: number = 18 * ratio;
    let point3b: number = 25 * ratio;
    let lineStart: number = 7 * ratio;
    if (annotation.strokeWidth) {
      point2 = annotation.strokeWidth * ratio;
      point1 = (12 + annotation.strokeWidth) * ratio;
      point1b = (12 - annotation.strokeWidth * 0.5) * ratio;
      point3b = (23 + annotation.strokeWidth * 0.5) * ratio;
      switch (annotation.strokeWidth) {
        case 1:
          lineStart = 2 * ratio;
          break;
        case 2:
          lineStart = 4 * ratio;
          break;
        case 3:
          lineStart = 6 * ratio;
          break;
        case 4:
          lineStart = 8 * ratio;
          break;
        case 5:
          lineStart = 10 * ratio;
          break;
        case 6:
          lineStart = 10 * ratio;
          break;
        case 7:
          lineStart = 12 * ratio;
          break;
        case 8:
          lineStart = 14 * ratio;
          break;
        case 9:
          lineStart = 16 * ratio;
          break;

        default:
          lineStart = 6 * ratio;
          break;
      }
    }
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="https://www.w3.org/1999/xlink"
        id={`lineArrow-${index}`}
        tabIndex={0}
        name="lineArrow"
        className="annotation"
        version="1.1"
        // viewBox={`0 0 auto ${20 * ratio}`}
        height={`${(36 * ratio).toFixed(3)}`}
        preserveAspectRatio="xMinYMid meet"
        style={{
          top: `${annotation.top.toFixed(2)}%`,
          left: `${annotation.left.toFixed(2)}%`,
          width: `${annotation.width.toFixed(2)}%`,
          zIndex: annotation.zIndex,
          transform: `rotate(${rotateDeg?.toFixed(2)}deg)`,
        }}
      >
        <svg height="100%">
          <polyline
            // points={points}
            points={`${point1}, ${point1b} ${point2},${point2b} ${point1},${point3b}`}
            stroke={`${annotation.borderColor || "white"}`}
            fill={`transparent`}
            strokeWidth={`${annotation.strokeWidth * ratio || 4 * ratio}`}
            strokeLinejoin="round"
            strokeLinecap="round"
          />
        </svg>
        <line
          className="annotation-arrow-arm"
          x1={`${lineStart * ratio}`}
          x2={`100%`}
          y1={`50%`}
          y2={`50%`}
          style={{
            fill: `${annotation.borderColor || "white"}`,
            stroke: `${annotation.borderColor || "white"}`,
            strokeLinecap: "butt",
            strokeWidth: `${annotation.strokeWidth * ratio || 4 * ratio}`,
          }}
        />
      </svg>
    );
  };

  return <React.Fragment>{display()}</React.Fragment>;
};

export default AnnotationPointArrow;
