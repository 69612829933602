import _ from "lodash";
import { BoundType } from "../../../../../components/react-moveable";
import { IAnnotation } from "../../../../../components/Annotation/models/IAnnotation";
import { ISymbolV2 } from "../../../../../components/Symbol/models/ISymbol";
import { ElementTypes, IBasicPageImageV2, IBasicPageScormV2, IBasicPageTextBlockV2 } from "../../IBasePage";

export class CustomPageElement {
  public newObject: any;
  tempNode: any;
  groupList: IAnnotation[] | IBasicPageImageV2[] | IBasicPageTextBlockV2[] | ISymbolV2[] | IBasicPageScormV2[];
  isDisplayed = true;
  constructor(
    groupList: IAnnotation[] | IBasicPageImageV2[] | IBasicPageTextBlockV2[] | ISymbolV2[] | IBasicPageScormV2[],
    objectId: string,
  ) {
    this.newObject = {
      name: "",
      width: 15,
      height: 15,
      left: 10,
      top: 10,
      zIndex: 12,
      objectId: objectId,
      isDisplayed: true,
    };
    this.groupList = groupList;
    this.tempNode = {};
  }

  protected setInitialPosition(list: any[], index: number, bounds: BoundType) {
    if (index < 0) {
      this.setPositionNoSelection(list, bounds);
    } else {
      this.setPositionWithSelection(list, bounds, index);
    }
    return this.newObject;
  }

  protected setPositionWithSelection(list: any[], bounds: BoundType, index: number) {
    if (!_.isEmpty(list[index])) {
      this.newObject.top = list[index].top;
      this.newObject.left = list[index].left;
    }
    this.getNewPositionIfAlreadyExists(list);
    this.validateNewPosition(bounds);
  }

  protected setPositionNoSelection(list: any[], bounds: BoundType) {
    if (list.length > 0) {
      this.getNewPositionIfAlreadyExists(list);
      this.validateNewPosition(bounds);
    } else {
      this.newObject.top = 50 - this.newObject.height / 2; //middle of top and bottom (height)
      this.newObject.left = 50 - this.newObject.width / 2; //middle of left and right (width)
    }
  }

  protected validateNewPosition(bounds: BoundType) {
    const newTop: number = 50 - this.newObject.height / 2; //in percent
    const newLeft: number = 50 - this.newObject.width / 2; //in percent
    if (!bounds.bottom || !bounds.right) {
      return;
    }
    const potBottom = ((this.newObject.top + this.newObject.height + 1) / 100) * bounds.bottom;
    const potRight = ((this.newObject.left + this.newObject.width + 1) / 100) * bounds.right;

    if (potBottom > bounds.bottom) {
      this.newObject.top = newTop + 4;
    } else {
      // this.newObject.top = this.newObject.top + 2;
    }

    if (potRight > bounds.right) {
      this.newObject.left = newLeft + 4;
    } else {
      // this.newObject.left = this.newObject.left + 1;
    }
  }

  protected getNewPositionIfAlreadyExists(elements: any[]) {
    this.findNewTop(elements);
    this.findNewLeft(elements);
  }

  protected findNewTop(list: any[]) {
    const top = this.newObject.top;
    if (!_.some(list, ["top", top])) return;

    const found = _.filter(list, (item) => {
      return item.top === top;
    });
    this.newObject.top = found[0].top + 4;
    this.findNewTop(list);
  }

  protected findNewLeft(list: any[]) {
    const left = this.newObject.left;
    if (!_.some(list, ["left", left])) return;

    const found = _.filter(list, (item) => {
      return item.left === left;
    });
    this.newObject.left = found[0].left + 3;
    this.findNewLeft(list);
  }

  protected findMaxZIndex(list: any[]) {
    let maxZIndex = 0;
    list.forEach((element) => {
      if (element.zIndex === undefined) {
        return;
      } else if (element.zIndex > maxZIndex) {
        maxZIndex = element.zIndex;
      }
    });
    return maxZIndex;
  }

  getNodeToEdit(index: number, type: ElementTypes) {
    this.getNodeName(index, type);
    return this.groupList[index];
  }

  protected getNodeName(index: number, type: ElementTypes) {
    if (!_.has(this.tempNode, "name") || this.tempNode.name?.length === 0) {
      this.tempNode.name = this.validateNodeName(index, type);
    }
  }

  protected validateNodeName = (index: number, type: ElementTypes) => {
    switch (type) {
      case "pageImage":
        return `image ${index}`;
      case "textBlock":
        return `text box ${index}`;
      case "tables":
        return `tables ${index}`;
      case "annotations":
        return `${(this.groupList as IAnnotation[])[index].type} ${index}`;
      case "symbols":
        return `${this.groupList[index].name} ${index}`;
      case "video":
        return `video ${index}`;
      case "pageScormObject":
        return `Interactive Diagram ${index}`;
      default:
        return;
    }
  };

  getSelectedSymbolHeight(target: SVGSVGElement, pageDims: DOMRect) {
    this.tempNode.height = (target.getBoundingClientRect().height / pageDims.height) * 100;
    return this.tempNode.height;
  }
}
