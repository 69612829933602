export const renderTimeLength = (duration: number, showMilliseconds = false) => {
  const seconds = Math.floor(duration % 60);
  const minutes = Math.floor(duration / 60);
  const milliseconds = Math.floor((duration % 1) * 100);

  const uiMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const uiSeconds = seconds < 10 ? `0${seconds}` : seconds;
  const uiMilliseconds = milliseconds < 10 ? `0${milliseconds}` : milliseconds;

  return `${uiMinutes}:${uiSeconds}${showMilliseconds ? ":" + uiMilliseconds : "s"}`;
};
