import React, { useState, useContext, useEffect, useRef, useCallback, useLayoutEffect } from "react";
import lodash from "lodash";
import { IEditorModeContext, EditorModeContext } from "../../routes/builderContexts";
// import "./textToSpeech.css";
import "../Modals/CognitiveServices/TextToSpeech/textToSpeech.css";

import NarratorAudio from "./NarratorAudio";
import { ReactComponent as TabButtonIcon } from "../../assets/icons/NarratorTab/narrator-text-closed-tab-icon.svg";
import { AssetManagerProvider } from "../AssetManager/AssetManagerContext/AssetManagerProvider";
import "./NarratorDrawer.css";
import {
  NarratorDrawerProvider,
  useNarratorDrawerDispatch,
  useNarratorDrawerState,
} from "../../contexts/NarratorContext/NarratorDrawerProvider";
import { useEffectDebugger } from "../../hooks/useEffectDebugger";
import { ReactComponent as OpenTabIcon } from "../../assets/icons/NarratorTab/Open-drawer-options.svg";
import { useLessonData } from "../../contexts/LessonDataProvider";
import { ReactComponent as TrashIcon } from "../../assets/icons/Common/remove-page-icon.svg";
import { ReactComponent as UploadPlusIcon } from "../../assets/icons/AssetManager/add-gray-version-icon.svg";
import { ReactComponent as ExitIcon } from "../../assets/icons/Common/remove-tag-icon.svg";
import Portal from "../Portal/Portal";
import MachineTranslation from "../Modals/CognitiveServices/MachineTranslation/machineTranslation";
import { useSelector } from "react-redux";
import useSettingsToggle, { Settings, settingType } from "../../hooks/useSettingsToggle";
import _ from "lodash";
import TimelinePanel from "../../panels/TimelinePanel/TimelinePanel";
import { useGlobalAudioDispatch, useGlobalAudioState } from "../../contexts/GlobalAudioProvider/GlobalAudioProvider";
import blobUrlFormatHelper from "../blobUrlFormatHelper";
import { createLogger } from "../../utils";
const log = createLogger("NarratorDrawer");
const renderSettings: Settings = {
  machinetranslations: settingType.customerenabled,
  draggableTimeline: settingType.cpatonly,
};

function NarratorWrapper(props: any) {
  const isCPaTUser: boolean = useSelector(({ authorizedState }: any) => authorizedState.isCpatUser);
  const editorContext: IEditorModeContext = useContext<IEditorModeContext>(EditorModeContext);
  const [direction, setDirection] = useState<string>("ltr");
  const [featuresToRender, setFeaturesToRender, loading] = useSettingsToggle(renderSettings);
  const permissions = useLessonData({ permissions: true });
  const [isWarningDialogShowing, setIsWarningDialogShowing] = useState(false);
  const [warningDialogMessage, setWarningDialogMessage]: [
    {
      [key: string]: string;
      textError: string;
      audioError: string;
      bothError: string;
    },
    any,
  ] = useState({
    textError: "",
    audioError: "",
    bothError: "",
  });
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [modeDrawerIsOpen, setModeDrawerIsOpen] = useState(false);
  const [selectedMode, setSelectedMode] = useState(0);
  const { machinetranslations, draggableTimeline } = featuresToRender;
  //demo tab is also all lesson modes
  const [canSwitchTabs, setCanSwitchTabs] = useState(true);
  const narratorDispatch = useNarratorDrawerDispatch();
  const globalAudioDipatch = useGlobalAudioDispatch();
  const globalAudioState = useGlobalAudioState();
  const narratorState = useNarratorDrawerState();

  const [alternateNarrations, setAlternateNarrations] = React.useState<number[]>([]);
  // const [selectedNarration, setSelectedNarration] = React.useState(1);
  const deleteAlternateAudioResolve = React.useRef<(value: unknown) => void>();
  const [warningShowing, setWarningShowing] = React.useState(false);
  const timelineDraggableActive = draggableTimeline && props?.page?.pageManifest?.basePageType === "freeForm";
  const narrationActive = narratorState.currentPullout === "narration";
  useEffect(() => {
    if (alternateNarrations.length === 1) {
      narratorDispatch({ type: "CHANGE_SELECTED_NARRATION", payload: 0 });
      globalAudioDipatch({ type: "SET_SELECTED_NARRATION_INDEX", payload: 0 });
    }
  }, [alternateNarrations.length]);
  //
  function indexSelector(index: number) {
    const newIndex = narratorState.selectedNarration * 3 + index;
    if (props?.page?.pageManifest?.Audio && props.page.pageManifest.Audio[newIndex]) {
      return newIndex;
    }
    return index;
  }
  function handleAddAlternateAudio() {
    //  const currentAudioList: any[] = narratorState?.pageManifestCopy?.Audio
    //  narratorDispatch({ type: "ADD_ALTERNATE_AUDIO", payload: null });
    const audio = lodash.cloneDeep(narratorState?.pageManifestCopy?.Audio);
    const pageManifest = lodash.cloneDeep(narratorState?.pageManifestCopy);

    for (let i = 0; i < 3; ++i) {
      audio[i].language = props?.lessonMetaData?.additionalSettings?.studentLanguages[0] ?? "";
      audio.push({
        Version: null,
        NarratorText: "",
        File: null,
        language: props?.lessonMetaData?.additionalSettings?.studentLanguages[1] ?? "",
      });
    }
    pageManifest.Audio = audio;
    // setSelectedNarration(alternateNarrations.length + 1)
    narratorDispatch({
      type: "CHANGE_SELECTED_NARRATION",
      payload: alternateNarrations.length - 1,
    });
    globalAudioDipatch({
      type: "SET_SELECTED_NARRATION_INDEX",
      payload: alternateNarrations.length - 1,
    });
    narratorDispatch({
      type: "UPDATE_PAGE_MANIFEST_COPY",
      payload: pageManifest,
    });
    narratorDispatch({ type: "UPDATE_PAGE_MANIFEST", payload: pageManifest });

    props.updatePageManifest(pageManifest);
  }
  function showWarning() {
    setWarningShowing(true);
    return new Promise((resolve) => {
      deleteAlternateAudioResolve.current = resolve;
    });
  }

  async function handleRemoveAlternateAudio() {
    //  const currentAudioList: any[] = narratorState?.pageManifestCopy?.Audio
    //  narratorDispatch({ type: "ADD_ALTERNATE_AUDIO", payload: null });
    const confirmation = await showWarning();
    if (confirmation) {
      const audio = lodash.cloneDeep(narratorState?.pageManifestCopy?.Audio);
      const pageManifest = lodash.cloneDeep(narratorState?.pageManifestCopy);
      audio.splice(audio.length - 3);
      // setSelectedNarration(alternateNarrations.length - 1)
      const newSelected = alternateNarrations.length;
      narratorDispatch({
        type: "CHANGE_SELECTED_NARRATION",
        payload: alternateNarrations.length - 2,
      });
      globalAudioDipatch({
        type: "SET_SELECTED_NARRATION_INDEX",
        payload: alternateNarrations.length - 2,
      });
      pageManifest.Audio = audio;
      narratorDispatch({ type: "UPDATE_PAGE_MANIFEST", payload: pageManifest });
      narratorDispatch({
        type: "UPDATE_PAGE_MANIFEST_COPY",
        payload: pageManifest,
      });
      narratorDispatch({ type: "RESET_INTERNAL_TABS", payload: null });
      props.updatePageManifest(pageManifest);
    } else {
    }
  }

  function countAlternateAudios() {
    const currentAudioList: any[] = narratorState?.pageManifestCopy?.Audio;
    const aaudios = [];
    if (currentAudioList) {
      let { length } = currentAudioList;
      if (length >= 3 && length % 3 === 0) {
        length = length / 3;
        for (let i = 0; i < length; ++i) {
          aaudios.push(i + 1);
        }
      }
    }
    return aaudios;
  }

  useEffect(() => {
    setAlternateNarrations(countAlternateAudios());
  }, [narratorState?.pageManifestCopy?.Audio?.length]);
  useEffect(() => {
    setFeaturesToRender(isCPaTUser); // returns set of features that are allowed for the logged in user
  }, [loading, isCPaTUser]);
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (warningDialogMessage.audioError || warningDialogMessage.textError || warningDialogMessage.bothError) {
      setIsWarningDialogShowing(true);
      timeout = setTimeout(() => {
        setWarningDialogMessage({
          textError: "",
          audioError: "",
          bothError: "",
        });
        setIsWarningDialogShowing(false);
      }, 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [warningDialogMessage.audioError, warningDialogMessage.textError, warningDialogMessage.bothError]);

  const updateManifest = (manifestCopy: any) => {
    props.updatePageManifest(manifestCopy);
  };

  const pageOrder = useRef(props.page && props.page.order);
  pageOrder.current = props.page && props.page.order;

  let time: NodeJS.Timeout;
  //#region [rgba(7, 130, 236,0.35)]
  useEffectDebugger(
    () => {
      //will call the debounced function to updated the page manifest of the lesson.
      if (props.page.pageManifest) {
        document.body.classList.add("remove-pointer-events");
        const manifestCopy = lodash.cloneDeep(props.page.pageManifest);
        if (
          manifestCopy?.Audio?.[indexSelector(0)]?.NarratorText ===
            narratorState.demoTabNarratorText[narratorState.selectedNarration] &&
          manifestCopy?.Audio?.[indexSelector(1)]?.NarratorText ===
            narratorState.practiceTabNarratorText[narratorState.selectedNarration] &&
          manifestCopy?.Audio?.[indexSelector(2)]?.NarratorText ===
            narratorState.performTabNarratorText[narratorState.selectedNarration]
        ) {
          document.body.classList.remove("remove-pointer-events");
        } else {
          clearTimeout(time);
          time = setTimeout(() => {
            updateManifest(narratorState.pageManifestCopy);
            document.body.classList.remove("remove-pointer-events");
          }, 800);
        }
      }

      return () => {
        clearTimeout(time);
      };
    },
    [
      narratorState.demoTabNarratorText.length > 0 ? narratorState.demoTabNarratorText.reduce((x, y) => x + y) : null,
      narratorState.practiceTabNarratorText.length > 0
        ? narratorState.practiceTabNarratorText.reduce((x, y) => x + y)
        : null,
      narratorState.performTabNarratorText.length > 0
        ? narratorState.performTabNarratorText.reduce((x, y) => x + y)
        : null,
    ],
    ["demo", "practice", "perform"],
  );
  //#endregion
  useEffect(() => {
    if (isNarratorDisabled() && drawerIsOpen) {
      handleOpen(drawerIsOpen);
    }
    if (props?.page?.pageManifest?.basePageType !== "freeForm") {
      narratorDispatch({ type: "SET_SELECTED_PULLOUT", payload: "narration" });
    }
    if (props.page && props.page.pageManifest) {
      const {
        pageManifest: { Audio },
      } = props.page;
      //
      if (Audio && Audio.length >= 3) {
        if (
          Audio[indexSelector(0)].NarratorText !== null &&
          Audio[indexSelector(0)].NarratorText !== undefined &&
          Audio[indexSelector(1)].NarratorText !== null &&
          Audio[indexSelector(1)].NarratorText !== undefined &&
          Audio[indexSelector(2)].NarratorText !== null &&
          Audio[indexSelector(2)].NarratorText !== undefined
        ) {
          if (
            Audio[indexSelector(0)].NarratorText !== Audio[indexSelector(1)].NarratorText ||
            Audio[indexSelector(0)].NarratorText !== Audio[indexSelector(2)].NarratorText ||
            Audio[indexSelector(0)].Version !== Audio[indexSelector(1)].Version ||
            Audio[indexSelector(0)].Version !== Audio[indexSelector(2)].Version
          ) {
            setModeDrawerIsOpen(true);
          } else {
            setModeDrawerIsOpen(false);
            setSelectedMode(0);
          }
        }
        if (!narratorHasAllModes() && selectedMode !== 0) {
          setSelectedMode(0);
        }
      }
      // This adds a version to the audio object on previously added audio that doesn't have a version
      if (Audio) {
        _.forEach(Audio, (object, index: number) => {
          if (object.File && !object.Version) {
            const blobArray = object.File.split("/");
            if (blobArray.length > 5) {
              object.Version = parseInt(blobArray[4]);
            }
          }
        });
      }
    }

    //resets state to the one in page manifest
    setAlternateNarrations(countAlternateAudios());
    // if the current page does not have more than 3 audios set the selected one to the first one
    if (narratorState?.pageManifestCopy?.Audio?.length <= 3 * narratorState.selectedNarration - 1) {
      // setSelectedNarration(1)
      narratorDispatch({ type: "CHANGE_SELECTED_NARRATION", payload: 0 });
      globalAudioDipatch({ type: "SET_SELECTED_NARRATION_INDEX", payload: 0 });
    }
  }, [props.page.pageVersionId]);

  useEffect(() => {
    if (props.page && props.page.pageManifest) {
      const {
        pageManifest: { Audio },
      } = props.page;
      if (Audio && Audio.length >= 3) {
        if (
          Audio[indexSelector(0)].NarratorText !== null &&
          Audio[indexSelector(0)].NarratorText !== undefined &&
          Audio[indexSelector(1)].NarratorText !== null &&
          Audio[indexSelector(1)].NarratorText !== undefined &&
          Audio[indexSelector(2)].NarratorText !== null &&
          Audio[indexSelector(2)].NarratorText !== undefined
        ) {
          if (
            Audio[indexSelector(0)].NarratorText !== Audio[indexSelector(1)].NarratorText ||
            Audio[indexSelector(0)].NarratorText !== Audio[indexSelector(2)].NarratorText ||
            Audio[indexSelector(0)].Version !== Audio[indexSelector(1)].Version ||
            Audio[indexSelector(0)].Version !== Audio[indexSelector(2)].Version
          ) {
            setModeDrawerIsOpen(true);
          } else {
            setModeDrawerIsOpen(false);
            setSelectedMode(0);
          }
        }
      }
    }
  }, [narratorState.selectedNarration]);

  useEffect(() => {
    if (props.page && props.page.pageManifest) {
      const {
        pageManifest: { Audio },
      } = props.page;
      if (narratorState.pageManifestCopy) {
        if (modeDrawerIsOpen === false) {
          // this means it should have ALL MODES
          if (
            narratorState.pageManifestCopy.Audio[indexSelector(0)] ===
              narratorState.pageManifestCopy.Audio[indexSelector(1)] && //internal update has happened safe to set the copy to the manifest
            narratorState.pageManifestCopy.Audio[indexSelector(0)] ===
              narratorState.pageManifestCopy.Audio[indexSelector(2)] &&
            ((Audio[indexSelector(0)].NarratorText !== Audio[indexSelector(1)].NarratorText &&
              Audio[indexSelector(0)].NarratorText !== Audio[indexSelector(2)].NarratorText) ||
              (Audio[indexSelector(0)].Version !== Audio[indexSelector(1)].Version &&
                Audio[indexSelector(0)].Version !== Audio[indexSelector(2)].Version))
          ) {
            const demoAudio = [
              narratorState.pageManifestCopy.Audio[indexSelector(0)],
              narratorState.pageManifestCopy.Audio[indexSelector(0)],
              narratorState.pageManifestCopy.Audio[indexSelector(0)],
            ];
            clearTimeout(time);

            updateManifest({
              ...narratorState.pageManifestCopy,
              Audio: demoAudio,
            });
            document.body.classList.remove("remove-pointer-events");
          }
        }
      }
      return () => {
        clearTimeout(time);
      };
    }
  }, [modeDrawerIsOpen]);

  useEffect(() => {
    setSelectedMode(editorContext.selectedEditorLessonModes - 1);
  }, [editorContext.selectedEditorLessonModes]);

  const handleOpen = (open: boolean) => {
    if (!isNarratorDisabled()) {
      fixNullNarratorText();
      setDrawerIsOpen(!open);
    }

    if (isNarratorDisabled() && open) {
      setDrawerIsOpen(false);
    }
  };

  /**
   * Uses Page Manifest And looks at the pagePlayerType
   * @returns boolean indicating wether the Narrator is (true) or is NOT (false) DISABLED
   */
  function isNarratorDisabled() {
    const { page } = props;
    if (page && page.pageManifest) {
      switch (page.pagePlayerType) {
        case "Title":
        case "Quiz":
        case "Score":
        case "Exit":
          return true;
        case "Base":
          if (page.pageManifest.basePageType === "warning") {
            return true;
          } else {
            return false;
          }
        default:
          return false;
      }
    } else {
      return false;
    }
  }

  function audioDisabled() {
    const { page } = props;

    if (page) {
      if ("canEdit" in permissions) {
        return !permissions.canEdit(page.pagePlayerType);
      }
    }
  }
  /**
   *
   * @returns {boolean} indicates wether the selected pagePlayerType can use all Modes
   */
  function narratorHasAllModes() {
    const { page } = props;
    if (page && page.pageManifest) {
      switch (page.pagePlayerType) {
        case "ThreeDModel":
        case "FMS":
        case "Walkaround":
        case "Cabin":
          return true;
        default:
          return false;
      }
    } else {
      return false;
    }
  }

  type DemoTextHandlerEvent = React.ChangeEvent<HTMLTextAreaElement> | React.ClipboardEvent<HTMLTextAreaElement>;
  // let dummy = false;
  const handleDemoText = (e: DemoTextHandlerEvent) => {
    setDirection("ltr");
    //
    //
    // dummy = true
    // switch (e.type) {
    //   case 'paste':
    //     console.dir(e.eventPhase)
    //     console.dir(e.nativeEvent.target)
    //     break;
    //   case 'change':
    //     break;
    //   default:
    //     return undefined
    // }
    if (e.currentTarget.value === narratorState.pageManifestCopy.Audio[indexSelector(0)].NarratorText) return undefined;
    if (permissions.canEdit(props.page.pagePlayerType)) {
      narratorDispatch({
        type: "UPDATE_DEMO_TEXT",
        payload: e.currentTarget.value,
      });
    } else {
    }
  };

  const handlePracticeText = (e: any) => {
    setDirection("ltr");
    if (permissions.canEdit(props.page.pagePlayerType)) {
      narratorDispatch({
        type: "UPDATE_PRACTICE_TEXT",
        payload: e.target.value,
      });
    } else {
    }
  };

  const handlePerformText = (e: any) => {
    setDirection("ltr");
    if (permissions.canEdit(props.page.pagePlayerType)) {
      narratorDispatch({
        type: "UPDATE_PERFORM_TEXT",
        payload: e.target.value,
      });
    } else {
    }
  };

  const handleAllText = (e: any) => {
    setDirection("ltr");
    if (permissions.canEdit(props.page.pagePlayerType)) {
      const text = e.target ? e.target.value : e;
      narratorDispatch({ type: "UPDATE_ALL_TEXT", payload: text });
    } else {
    }
  };

  const checkIfTabHasAudioSrc = (tab: number) => {
    if (
      props.page.pageManifest &&
      "Audio" in props.page.pageManifest &&
      props.page.pageManifest.Audio[indexSelector(tab)] &&
      props.page.pageManifest.Audio[indexSelector(tab)].File
    ) {
      return true;
    } else {
      globalAudioDipatch({ type: "SET_AUDIO_LENGTH", payload: 0 });
      return false;
    }
  };

  const handleAudioSource = () => {
    if (selectedMode === 0) {
      if (checkIfTabHasAudioSrc(0)) {
        const path = blobUrlFormatHelper(props.page.pageManifest.Audio[indexSelector(0)].File);
        return path;
      } else {
        return "";
      }
    } else if (selectedMode === 1) {
      if (checkIfTabHasAudioSrc(1)) {
        return blobUrlFormatHelper(props.page.pageManifest.Audio[indexSelector(1)].File);
      } else return "";
    } else if (selectedMode === 2) {
      if (checkIfTabHasAudioSrc(2)) {
        return blobUrlFormatHelper(props.page.pageManifest.Audio[indexSelector(2)].File);
      } else return "";
    }
  };

  function getNameFromPath(path?: string) {
    if (!path) {
      return "";
    }
    const splitPath = path.split("/");
    const name = splitPath[splitPath.length - 1];
    if (name.length > 0) {
      return name;
    } else {
      return "";
    }
  }

  useEffect(() => {
    const path = handleAudioSource();
    const name = getNameFromPath(path);
    log(name);
    if (name) {
      globalAudioDipatch({ type: "SET_AUDIO_NAME", payload: name });
    }
    log("PATH", path);
    if (path) {
      globalAudioState.globalAudioManager.loadAudio(path);
    } else {
      globalAudioState.globalAudioManager.cleanup();
    }

    return () => {
      log("UNMOUNT CLEANUP", handleAudioSource());
      globalAudioState.globalAudioManager.cleanup();
    };
  }, [handleAudioSource()]);

  const fixNullNarratorText = () => {
    const audio = lodash.cloneDeep(narratorState?.pageManifestCopy?.Audio);
    const pageManifest = lodash.cloneDeep(narratorState?.pageManifestCopy);

    if (audio && audio.length > 0) {
      for (let i = 0; i < audio.length; i++) {
        if (audio[i].NarratorText === null || typeof audio[i].NarratorText === "undefined") {
          log("fixing broken audio narrator text in the page manifest");
          audio[i].NarratorText = "";
        }
      }

      pageManifest.Audio = audio;
      props.updatePageManifest(pageManifest);
    }
  };

  const narratorTextEqual = () => {
    if (
      narratorState &&
      narratorState.pageManifestCopy &&
      "Audio" in narratorState.pageManifestCopy &&
      narratorState.pageManifestCopy.Audio.length >= 3
    ) {
      const {
        pageManifestCopy: { Audio: AudioCopy },
      } = narratorState;

      return (
        AudioCopy[indexSelector(0)].NarratorText.toLowerCase() ===
          AudioCopy[indexSelector(1)].NarratorText.toLowerCase() &&
        AudioCopy[indexSelector(0)].NarratorText.toLowerCase() ===
          AudioCopy[indexSelector(2)].NarratorText.toLowerCase()
      );
    }
  };

  const narratorAudioEqual = () => {
    if (props.page && props.page.pageManifest && "Audio" in props.page.pageManifest) {
      const {
        page: {
          pageManifest: { Audio },
        },
      } = props;

      if (Audio[indexSelector(0)].Version !== Audio[indexSelector(1)].Version) {
        return false;
      }

      if (Audio[indexSelector(0)].Version !== Audio[indexSelector(2)].Version) {
        return false;
      }

      if (Audio[indexSelector(0)].File !== Audio[indexSelector(1)].File) {
        return false;
      }

      if (Audio[indexSelector(0)].File !== Audio[indexSelector(2)].File) {
        return false;
      }

      return (
        Audio[indexSelector(0)].Version === Audio[indexSelector(1)].Version &&
        Audio[indexSelector(0)].Version === Audio[indexSelector(2)].Version &&
        Audio[indexSelector(0)].File === Audio[indexSelector(1)].File &&
        Audio[indexSelector(0)].File === Audio[indexSelector(2)].File
      );
    }
  };

  const handleModesClick = async () => {
    const {
      pageManifestCopy: { Audio: AudioCopy },
    } = narratorState;

    if (modeDrawerIsOpen) {
      if (narratorTextEqual() && narratorAudioEqual()) {
        //can close
        setModeDrawerIsOpen(false);
        handleAllText(AudioCopy[indexSelector(0)].NarratorText);
        const manifestCopy = lodash.cloneDeep(props.page.pageManifest);
        manifestCopy.Audio[indexSelector(0)].File = AudioCopy[indexSelector(0)].File;
        manifestCopy.Audio[indexSelector(1)].File = AudioCopy[indexSelector(0)].File;
        manifestCopy.Audio[indexSelector(2)].File = AudioCopy[indexSelector(0)].File;
        manifestCopy.Audio[indexSelector(0)].Version = AudioCopy[indexSelector(0)].Version;
        manifestCopy.Audio[indexSelector(1)].Version = AudioCopy[indexSelector(0)].Version;
        manifestCopy.Audio[indexSelector(2)].Version = AudioCopy[indexSelector(0)].Version;
        manifestCopy.Audio[indexSelector(0)].NarratorText = AudioCopy[indexSelector(0)].NarratorText;
        manifestCopy.Audio[indexSelector(1)].NarratorText = AudioCopy[indexSelector(0)].NarratorText;
        manifestCopy.Audio[indexSelector(2)].NarratorText = AudioCopy[indexSelector(0)].NarratorText;
        manifestCopy.Audio[indexSelector(0)].PronunciationText = AudioCopy[indexSelector(0)].PronunciationText;
        manifestCopy.Audio[indexSelector(1)].PronunciationText = AudioCopy[indexSelector(0)].PronunciationText;
        manifestCopy.Audio[indexSelector(2)].PronunciationText = AudioCopy[indexSelector(0)].PronunciationText;
        manifestCopy.Audio[indexSelector(0)].Prosody = AudioCopy[indexSelector(0)].Prosody;
        manifestCopy.Audio[indexSelector(1)].Prosody = AudioCopy[indexSelector(0)].Prosody;
        manifestCopy.Audio[indexSelector(2)].Prosody = AudioCopy[indexSelector(0)].Prosody;
        manifestCopy.Audio[indexSelector(0)].Language = AudioCopy[indexSelector(0)].Language;
        manifestCopy.Audio[indexSelector(1)].Language = AudioCopy[indexSelector(0)].Language;
        manifestCopy.Audio[indexSelector(2)].Language = AudioCopy[indexSelector(0)].Language;
        manifestCopy.Audio[indexSelector(0)].Voice = AudioCopy[indexSelector(0)].Voice;
        manifestCopy.Audio[indexSelector(1)].Voice = AudioCopy[indexSelector(0)].Voice;
        manifestCopy.Audio[indexSelector(2)].Voice = AudioCopy[indexSelector(0)].Voice;

        props.updatePageManifest(manifestCopy);
        if (selectedMode !== 0) {
          setSelectedMode(0);
        }
      } else if (!narratorTextEqual() && !narratorAudioEqual()) {
        //set text and audio not equal error
        setWarningDialogMessage({
          textError: "",
          audioError: "",
          bothError: "All Tabs Audio and Text should match.",
        });
      } else if (!narratorTextEqual()) {
        //set text error
        setWarningDialogMessage({
          textError: "All Tabs Text should match.",
          audioError: "",
          bothError: "",
        });
      } else if (!narratorAudioEqual()) {
        //set audio error
        setWarningDialogMessage({
          textError: "",
          audioError: "All Tabs Audio should match.",
          bothError: "",
        });
      }
    } else if (!modeDrawerIsOpen) {
      setModeDrawerIsOpen(true);
    }
  };

  const showTranslation = () => {
    // mode = 0,1,2
    // alternate = 1,2
    if (isCPaTUser || machinetranslations) {
      return (
        <MachineTranslation
          pageManifest={narratorState.pageManifestCopy}
          index={indexSelector(selectedMode)}
          alternate={alternateNarrations.length > 1}
        />
      );
    }
  };

  useEffect(() => {
    if (
      narratorState &&
      narratorState.pageManifestCopy &&
      narratorState.pageManifestCopy.Audio[indexSelector(selectedMode)] &&
      narratorState.pageManifestCopy.Audio[indexSelector(selectedMode)].LanguageDirection
    ) {
      setDirection(narratorState.pageManifestCopy.Audio[indexSelector(selectedMode)].LanguageDirection);
    } else {
      setDirection("ltr");
    }
  }, [indexSelector, narratorState, selectedMode]);

  return (
    <AssetManagerProvider>
      <DeleteAudioWarning show={warningShowing} setShow={setWarningShowing} res={deleteAlternateAudioResolve.current} />
      <div
        style={{ zIndex: 10 }}
        className={`narrator-drawer-container${isNarratorDisabled() ? " narrator-disabled" : ""}`}
      >
        {/* <NarratorOpenTabs /> */}
        {timelineDraggableActive && (
          <div
            style={{
              position: "absolute",
              left: drawerIsOpen ? "8%" : "97%",
              height: "20%",
              top: "-20%",
              background: "var(--primary-gray)",
              width: "3%",
              transition: "left 0.5s ease-in-out",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "4px",
              boxShadow: "-2px 0px 6px -4px black",
              pointerEvents: "all",
              cursor: "pointer",
            }}
            onClick={() => {
              setDrawerIsOpen(!drawerIsOpen);
            }}
          >
            <TabButtonIcon className={drawerIsOpen ? "narrator-button inverted-narrator-button" : "narrator-button"} />
          </div>
        )}
        <div
          className="narration-popout-tab"
          onClick={() => {
            if (drawerIsOpen && narratorState.currentPullout === "timeline") {
            } else {
              handleOpen(drawerIsOpen);
            }
            narratorDispatch({
              type: "SET_SELECTED_PULLOUT",
              payload: "narration",
            });
          }}
          style={{
            left: drawerIsOpen ? "8%" : "97%",
            height: !timelineDraggableActive ? "100%" : "50%",

            transition: "left 0.5s ease-in-out",
            background: isNarratorDisabled() ? "var(--disabled-color)" : "",
          }}
        >
          <div>
            <span
              style={{
                fontSize: !timelineDraggableActive ? "1rem" : "0.9rem",
              }}
            >
              Narration
            </span>
          </div>
        </div>
        {timelineDraggableActive && (
          <div
            className="timeline-popout-tab"
            onClick={() => {
              if (drawerIsOpen && narratorState.currentPullout === "narration") {
              } else {
                setDrawerIsOpen(!drawerIsOpen);
              }
              narratorDispatch({
                type: "SET_SELECTED_PULLOUT",
                payload: "timeline",
              });
            }}
            style={{
              left: drawerIsOpen ? "8%" : "97%",
              transition: "left 0.5s ease-in-out",
              // background: isNarratorDisabled() ? 'var(--disabled-color)' : '',
            }}
          >
            <div>
              <span>Timeline</span>
            </div>
          </div>
        )}
        {narrationActive && (
          <div
            className="narrator-content"
            style={{
              left: drawerIsOpen ? "11%" : "100%",
              pointerEvents: isNarratorDisabled() ? "none" : "none",
              transition: "left 0.5s ease-in-out",
            }}
          >
            <div
              className={
                "narrator-tab-demo" +
                (selectedMode === 0
                  ? " selected-narrator-tab"
                  : narratorHasAllModes()
                  ? " not-selected-narrator-tab"
                  : "")
              }
              style={
                narratorHasAllModes()
                  ? {
                      zIndex: selectedMode === 0 ? 5 : 1,
                      cursor: canSwitchTabs ? "pointer" : "wait",
                    }
                  : { zIndex: 8 }
              }
              onClick={() => {
                canSwitchTabs && setSelectedMode(0);
              }}
            >
              <div className="narrator-tab-name" style={{ pointerEvents: "all" }}>
                {narratorHasAllModes() && modeDrawerIsOpen ? "Demo" : "All Modes"}
              </div>
              <textarea
                dir={"auto"}
                className={"narrator-tab-text-area"}
                value={
                  narratorState.pageManifestCopy
                    ? narratorState.pageManifestCopy!.Audio[indexSelector(0)].NarratorText
                    : ""
                }
                style={
                  isNarratorDisabled()
                    ? {
                        background: "var(--disabled-color)",
                        color: "var(--disabled-color)",
                      }
                    : {
                        overflow: drawerIsOpen ? "auto" : "hidden",
                      }
                }
                // onPasteCapture={(narratorHasAllModes() && modeDrawerIsOpen) ?  handleDemoText : handleAllText}
                onChange={narratorHasAllModes() && modeDrawerIsOpen ? handleDemoText : handleAllText}
              ></textarea>
              <div className="narrator-tab-footer">
                <div className="narrator-tab-footer-buttons-container" style={{ zIndex: 2 }}>
                  {!isNarratorDisabled() &&
                    alternateNarrations.map((audioName: number, index) => {
                      return (
                        <button
                          key={index}
                          className={
                            audioName - 1 === narratorState.selectedNarration
                              ? "alternate-audio-button selected-aa"
                              : "alternate-audio-button"
                          }
                          onClick={() => {
                            //  setSelectedNarration(audioName)
                            narratorDispatch({
                              type: "CHANGE_SELECTED_NARRATION",
                              payload: audioName - 1,
                            });
                            globalAudioDipatch({
                              type: "SET_SELECTED_NARRATION_INDEX",
                              payload: audioName - 1,
                            });
                          }}
                        >
                          {audioName}
                        </button>
                      );
                    })}
                  {!isNarratorDisabled() &&
                    (alternateNarrations.length >= 2 ? (
                      <div className="add-remove-aa" onClick={handleRemoveAlternateAudio}>
                        <TrashIcon />
                        <button>Remove Alternate Text</button>
                      </div>
                    ) : (
                      <div className="add-remove-aa" onClick={handleAddAlternateAudio}>
                        <UploadPlusIcon style={{}} />
                        <button>Add new Alternate Text</button>
                      </div>
                    ))}
                  {showTranslation()}
                </div>
                <div>
                  {true ? (
                    <div className="narrator-warning-text">
                      <p>
                        {(() => {
                          for (const key in warningDialogMessage) {
                            if (warningDialogMessage[key]) {
                              return warningDialogMessage[key];
                            }
                          }
                          return "";
                        })()}
                      </p>
                    </div>
                  ) : null}
                </div>
                <NarratorAudio
                  narratorHasAllModes={narratorHasAllModes()}
                  pageManifest={props.page.pageManifest}
                  tab={0}
                  modeDrawerIsOpen={modeDrawerIsOpen}
                  selectedTab={selectedMode}
                  selectedPage={props.selectedPage}
                  disabled={isNarratorDisabled() || audioDisabled()}
                  selectedNarration={narratorState.selectedNarration}
                  lessonMetaData={props?.lessonMetaData}
                />
              </div>
            </div>

            {narratorHasAllModes() && (
              <React.Fragment>
                <div
                  className={
                    "narrator-tab-practice" +
                    (selectedMode === 1 ? " selected-narrator-tab" : " not-selected-narrator-tab") +
                    (modeDrawerIsOpen ? " narrator-tab-practice-open" : " narrator-tab-practice-closed")
                  }
                  style={{
                    zIndex: selectedMode === 1 ? 5 : 2,
                    cursor: canSwitchTabs ? "pointer" : "wait",
                  }}
                  onClick={() => {
                    // setModeDrawerIsOpen(true)
                    canSwitchTabs && setSelectedMode(1);
                  }}
                >
                  <div className="narrator-tab-name" style={{ pointerEvents: "all" }}>
                    Practice
                  </div>
                  <textarea
                    dir={"auto"}
                    className={"narrator-tab-text-area"}
                    value={
                      narratorState.pageManifestCopy
                        ? narratorState.pageManifestCopy.Audio.length >= 2
                          ? narratorState.pageManifestCopy.Audio[indexSelector(1)].NarratorText
                          : ""
                        : ""
                    }
                    onChange={handlePracticeText}
                    style={{
                      overflow: drawerIsOpen ? "auto" : "hidden",
                    }}
                  ></textarea>
                  <div className="narrator-tab-footer">
                    <div className="narrator-tab-footer-buttons-container" style={{ zIndex: 2 }}>
                      {!isNarratorDisabled() &&
                        alternateNarrations.map((audioName: number, index) => {
                          return (
                            <button
                              key={index}
                              className={
                                audioName - 1 === narratorState.selectedNarration
                                  ? "alternate-audio-button selected-aa"
                                  : "alternate-audio-button"
                              }
                              onClick={() => {
                                //  setSelectedNarration(audioName)
                                narratorDispatch({
                                  type: "CHANGE_SELECTED_NARRATION",
                                  payload: audioName - 1,
                                });
                                globalAudioDipatch({
                                  type: "SET_SELECTED_NARRATION_INDEX",
                                  payload: audioName - 1,
                                });
                              }}
                            >
                              {audioName}
                            </button>
                          );
                        })}
                      {!isNarratorDisabled() &&
                        (alternateNarrations.length >= 2 ? (
                          <div className="add-remove-aa" onClick={handleRemoveAlternateAudio}>
                            <TrashIcon />
                            <button>Remove Alternate Text</button>
                          </div>
                        ) : (
                          <div className="add-remove-aa" onClick={handleAddAlternateAudio}>
                            <UploadPlusIcon style={{}} />
                            <button>Add new Alternate Text</button>
                          </div>
                        ))}
                      {showTranslation()}
                    </div>
                    <div>
                      {isWarningDialogShowing ? (
                        <div className="narrator-warning-text">
                          <p>
                            {(() => {
                              for (const key in warningDialogMessage) {
                                if (warningDialogMessage[key]) {
                                  return warningDialogMessage[key];
                                }
                              }
                              return "";
                            })()}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <NarratorAudio
                      narratorHasAllModes={narratorHasAllModes()}
                      pageManifest={props.page.pageManifest}
                      tab={1}
                      modeDrawerIsOpen={modeDrawerIsOpen}
                      selectedTab={selectedMode}
                      selectedPage={props.selectedPage}
                      disabled={isNarratorDisabled() || audioDisabled()}
                      selectedNarration={narratorState.selectedNarration}
                      lessonMetaData={props?.lessonMetaData}
                    />
                  </div>
                </div>

                <div
                  className={
                    "narrator-tab-perform" +
                    (selectedMode === 2 ? " selected-narrator-tab" : " not-selected-narrator-tab") +
                    (modeDrawerIsOpen ? " narrator-tab-perform-open" : " narrator-tab-perform-closed")
                  }
                  style={{
                    zIndex: selectedMode === 2 ? 5 : 1,
                    cursor: canSwitchTabs ? "pointer" : "wait",
                  }}
                  onClick={() => {
                    canSwitchTabs && setSelectedMode(2);
                  }}
                >
                  <div className="narrator-tab-name" style={{ pointerEvents: "all" }}>
                    Perform
                  </div>
                  <textarea
                    dir={"auto"}
                    className={"narrator-tab-text-area"}
                    value={
                      narratorState.pageManifestCopy
                        ? narratorState.pageManifestCopy.Audio.length >= 3
                          ? narratorState.pageManifestCopy!.Audio[indexSelector(2)].NarratorText
                          : ""
                        : ""
                    }
                    style={{
                      overflow: drawerIsOpen ? "auto" : "hidden",
                    }}
                    onChange={handlePerformText}
                  ></textarea>
                  <div className="narrator-tab-footer">
                    <div className="narrator-tab-footer-buttons-container" style={{ zIndex: 2 }}>
                      {!isNarratorDisabled() &&
                        alternateNarrations.map((audioName: number, index) => {
                          return (
                            <button
                              key={index}
                              className={
                                audioName - 1 === narratorState.selectedNarration
                                  ? "alternate-audio-button selected-aa"
                                  : "alternate-audio-button"
                              }
                              onClick={() => {
                                //  setSelectedNarration(audioName)
                                narratorDispatch({
                                  type: "CHANGE_SELECTED_NARRATION",
                                  payload: audioName - 1,
                                });
                                globalAudioDipatch({
                                  type: "SET_SELECTED_NARRATION_INDEX",
                                  payload: audioName - 1,
                                });
                              }}
                            >
                              {audioName}
                            </button>
                          );
                        })}
                      {!isNarratorDisabled() &&
                        (alternateNarrations.length >= 2 ? (
                          <div className="add-remove-aa" onClick={handleRemoveAlternateAudio}>
                            <TrashIcon />
                            <button>Remove Alternate Text</button>
                          </div>
                        ) : (
                          <div className="add-remove-aa" onClick={handleAddAlternateAudio}>
                            <UploadPlusIcon style={{}} />
                            <button>Add new Alternate Text</button>
                          </div>
                        ))}
                      {showTranslation()}
                    </div>
                    <div>
                      {isWarningDialogShowing ? (
                        <div className="narrator-warning-text">
                          <p>
                            {(() => {
                              for (const key in warningDialogMessage) {
                                if (warningDialogMessage[key]) {
                                  return warningDialogMessage[key];
                                }
                              }
                              return "";
                            })()}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <NarratorAudio
                      narratorHasAllModes={narratorHasAllModes()}
                      pageManifest={props.page.pageManifest}
                      tab={2}
                      modeDrawerIsOpen={modeDrawerIsOpen}
                      selectedTab={selectedMode}
                      selectedPage={props.selectedPage}
                      disabled={isNarratorDisabled() || audioDisabled()}
                      selectedNarration={narratorState.selectedNarration}
                      lessonMetaData={props?.lessonMetaData}
                    />
                  </div>
                </div>

                <div
                  className={"narrator-tab-open-close" + (modeDrawerIsOpen ? " open" : " closed")}
                  onClick={handleModesClick}
                >
                  <div className="narrator-tab-open-close-button">
                    {modeDrawerIsOpen ? (
                      <OpenTabIcon
                        className={`tab-toggle-button tab-toggle-open ${
                          narratorTextEqual() && narratorAudioEqual() ? "" : "tab-toggle-open-disabled"
                        } `}
                      />
                    ) : (
                      <OpenTabIcon className="tab-toggle-button  tab-toggle-closed" />
                    )}
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        )}
        <div
          className="timeline-cont"
          style={{
            left: drawerIsOpen ? "0" : "100%",
            transition: "left 0.5s ease-in-out",
            height: "100%",
            width: "100%",
            position: "relative",
          }}
        >
          {narratorState.currentPullout === "timeline" && <TimelinePanel />}
        </div>
      </div>
    </AssetManagerProvider>
  );
}

export default function NarratorDrawer(props: any) {
  return (
    <NarratorDrawerProvider page={props.page}>
      <NarratorWrapper {...props} />
    </NarratorDrawerProvider>
  );
}

const DeleteAudioWarning = (props: any) => {
  return props.show ? (
    <Portal path="delete-confirm-container">
      <div className="delete-confirm-modal">
        <div className="delete-confirm-header">
          <p>Remove Alternate Text</p>
          <ExitIcon
            onClick={() => {
              props.setShow(false);
              props.res(false);
            }}
          />
        </div>
        <div className="delete-confirm-text">
          <p>Removing alternate Text can not be undone, continue?</p>
        </div>
        <div className="delete-confirm-buttons-container">
          <button
            onClick={() => {
              props.setShow(false);
              props.res(false);
            }}
          >
            Cancel
          </button>
          <button
            onClick={() => {
              props.setShow(false);
              props.res(true);
            }}
          >
            Confirm
          </button>
        </div>
      </div>
    </Portal>
  ) : null;
};
