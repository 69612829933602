import { mapValues } from "lodash";
import React, { PropsWithChildren, useLayoutEffect, useMemo, useRef } from "react";

import {
  useTimeline,
  scaledSpaceFromUnitSpace,
  clippedSpaceToUnitSpace,
  scaledSpaceToUnitSpace,
} from "../../../contexts/TimelineProvider/TimelineProvider";
import { useDomRef } from "../../../hooks/useDomRef";
import { useReceiveVerticalWheelEvent } from "../VerticalScrollContainer";

export default function HorizontallyScrollableArea(props: PropsWithChildren<any>) {
  const [ref, setRef] = useDomRef();
  const [timeline, timelineDispatch] = useTimeline();
  const { widthInPx, clippedSpace } = timeline;
  const unitSpaceToScaledSpaceMultiplier = useMemo(() => {
    return scaledSpaceFromUnitSpace(1, clippedSpace, widthInPx);
  }, [clippedSpace.range.start, clippedSpace.range.end, widthInPx]);
  useHandlePanAndZoom(ref!);
  useUpdateScrollFromClippedSpaceRange(ref!);
  return (
    <div
      ref={setRef}
      className="horizontally-scrollable-area"
      style={{
        pointerEvents: "auto",
        height: props.height,
        width: widthInPx,
        // @ts-expect-error
        "--unitSpaceToScaledSpaceMultiplier": unitSpaceToScaledSpaceMultiplier,
      }}
    >
      {props.children}
    </div>
  );
}

function useHandlePanAndZoom(node: HTMLDivElement) {
  const [timeline, timelineDispatch] = useTimeline();
  const {
    widthInPx,
    clippedSpace,
    scaledSpace: { leftPadding },
    sequenceLength,
  } = timeline;
  const memoScrollRange = useRef<null | { start: number; end: number }>(null);
  function setMemoRange(range: { start: number; end: number }) {
    memoScrollRange.current = range;
  }
  const receiveVerticalWheelEvent = useReceiveVerticalWheelEvent();
  if (Number.isNaN(clippedSpace.range.start)) {
  }
  useLayoutEffect(() => {
    if (!node) return;

    const handleWheel = (e: WheelEvent) => {
      if (e.ctrlKey) {
        e.preventDefault();
        e.stopPropagation();
        const pivotPointInClippedSpace = e.clientX - node.getBoundingClientRect().left;
        const pivotPointInUnitSpace = clippedSpaceToUnitSpace(
          pivotPointInClippedSpace,
          leftPadding,
          clippedSpace,
          widthInPx,
        );
        if (Number.isNaN(pivotPointInUnitSpace)) {
        }
        const oldRange = clippedSpace.range;
        const delta = normalize(e.deltaY, [-50, 50]);
        const scaleFactor = 1 + delta * 0.0075;
        const newRange = mapValues(oldRange, (originalPos) => {
          return (originalPos - pivotPointInUnitSpace) * scaleFactor + pivotPointInUnitSpace;
        });
        // Set maximum scroll points based on the sequence length.
        // This is to avoid zooming out to infinity.
        const maxEnd = sequenceLength + sequenceLength * 0.25;
        timelineDispatch({
          type: "SET_RANGE",
          payload: {
            start: normalize(newRange.start, [0, maxEnd]),
            end: normalize(newRange.end, [0, maxEnd]),
          },
        });
        return;
      } else if (e.shiftKey) {
        e.preventDefault();
        e.stopPropagation();
        const oldRange = memoScrollRange.current ?? clippedSpace.range;
        const windowSize = oldRange.end - oldRange.start;
        const speed = windowSize / sequenceLength;
        // if there's no deltaY, the browser is probably assigning to deltaX because of the shiftKey
        // it appeared that Safari + Chrome continue to use deltaY with shiftKey, while FF on macOS
        // updates the deltaX with deltaY unchanged.
        // this is a little awkward with track pads + shift on macOS FF, but that's not a big deal
        // since scrolling horizontally with macOS track pads is not necessary to hold shift.
        const delta = normalize(e.deltaY || e.deltaX, [-50, 50]);
        const scaleFactor = delta * 0.05 * speed;
        setMemoRange(mapValues(oldRange, (originalPos) => originalPos + scaleFactor));
        timelineDispatch({
          type: "ADD_RANGE",
          payload: {
            start: scaleFactor,
            end: scaleFactor,
          },
        });
        return;
      } else {
        receiveVerticalWheelEvent(e);
        e.preventDefault();
        e.stopPropagation();
        const deltaPos = scaledSpaceToUnitSpace(e.deltaX * 1, clippedSpace, widthInPx);
        const oldRange = clippedSpace.range;
        const newRange = mapValues(oldRange, (p) => p + deltaPos);
        timelineDispatch({
          type: "SET_RANGE",
          payload: {
            start: newRange.start,
            end: newRange.end,
          },
        });
        return;
      }
    };
    const listenerOptions = {
      capture: true,
      passive: false,
    };
    node.addEventListener("wheel", handleWheel, listenerOptions);

    return () => {
      node.removeEventListener("wheel", handleWheel, listenerOptions);
    };
  }, [node, clippedSpace.range.start, clippedSpace.range.end, widthInPx]);
}

function normalize(value: number, [min, max]: [number, number]) {
  return Math.max(Math.min(value, max), min);
}

function useUpdateScrollFromClippedSpaceRange(node: HTMLDivElement | null) {
  const [timeline, timelineDispatch] = useTimeline();
  const { clippedSpace, widthInPx } = timeline;
  const range = timeline.clippedSpace.range;
  const rangeStartInScaledSpace = useMemo(() => {
    return scaledSpaceFromUnitSpace(range.start, clippedSpace, widthInPx);
  }, [range.start, clippedSpace, widthInPx]);
  useLayoutEffect(() => {
    if (!node) return;
    const update = () => {
      node.scrollLeft = rangeStartInScaledSpace;
    };
    update();
    const timeout = setTimeout(update, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, [node, rangeStartInScaledSpace]);
}
