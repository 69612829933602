import * as AssetManagerTypes from "../AssetManager.types";

export const addFilter: AssetManagerTypes.AddFilter = (state, action) => {
  let specificity = "";
  if (action.payload.currentLesson) specificity = "currentLesson";
  else if (action.payload.recentlyUploaded) specificity = "recentlyUploaded";
  else if (action.payload.myUploaded) specificity = "myUploaded";
  else if (action.payload.manufacturerId) specificity = "manufacturerId";
  else if (action.payload.aircraftFamilyId) specificity = "aircraftFamilyId";
  else if (action.payload.aircraftId) specificity = "aircraftId";
  const isAlreadyAFilter = state.assetLibraryFilters.find(
    (filterItem: AssetManagerTypes.FilterListItem, index: number) => {
      return filterItem[specificity] === action.payload[specificity];
    },
  );

  if (isAlreadyAFilter) return state;
  else
    return {
      ...state,
      assetLibraryFilters: [...state.assetLibraryFilters, action.payload],
    };
};

export const removeFilter: AssetManagerTypes.RemoveFilter = (state, action) => {
  const deleteFilterProperty = Object.entries(action.payload).find((filter: any[], index: number) => {
    return typeof filter[1] === "string" || typeof filter[1] === "boolean";
  }) as [string, string | boolean]; // finds the property

  let indexToBeRemoved = 0;
  state.assetLibraryFilters.find((el: any, index: number) => {
    indexToBeRemoved = index;
    return el[deleteFilterProperty[0]] === deleteFilterProperty[1];
  });

  state.assetLibraryFilters.splice(indexToBeRemoved, 1);

  return { ...state };
};

export const changeAssetTypeDisplayed: AssetManagerTypes.ChangeAssetTypeDisplayed = (state, action) => {
  return { ...state, assetLibraryDisplayedAssetType: action.payload };
};

export const setPagination: AssetManagerTypes.setPagination = (state, action) => {
  return { ...state, pagination: { ...state.pagination, ...action.payload } };
};

export const setCurrentKeywords: AssetManagerTypes.setCurrentKeywords = (state, action) => {
  return { ...state, keywords: action.payload };
};

export const setTextQuery: AssetManagerTypes.setTextQuery = (state, action) => {
  return { ...state, textQuery: action.payload };
};
export const removeDownstreamFilters: AssetManagerTypes.removeDownstreamFilters = (state, action) => {
  const filtersToRemove = action.payload;
  // this finds the indexes of the filterItems that should be removed
  const indexesToRemove = filtersToRemove
    // iterate over all filters
    .flatMap((filter) => {
      let index = -1; // baseline is that index is not there
      // if any filterItem object keys has a value that is not null, it should be removed
      state.assetLibraryFilters.forEach((filterItem, i) => {
        if (filterItem[filter] !== null) {
          index = i;
        } // set the index to remove
      });
      return index;
    })
    .filter((item) => item > -1); // only return found indexes
  // create a new filters object without the indexes marked for removal
  const newFilters = state.assetLibraryFilters.filter((_, i) => {
    return !indexesToRemove.includes(i);
  });
  return { ...state, assetLibraryFilters: newFilters };
};

export const setItemsPerPage: AssetManagerTypes.setItemsPerPage = (state, action) => {
  return {
    ...state,
    itemsPerPage: action.payload,
  };
};

export const setIsEditing: AssetManagerTypes.setIsEditing = (state, action) => {
  return {
    ...state,
    isEditing: action.payload,
  };
};
