import React from "react";
import { AnnotationMenuIconType } from "../AnnotationMenu";
import AnnotationCircleLetter from "../icons/AnnotationCircleLetter";
import AnnotationCircleNumber from "../icons/AnnotationMenuCircleNumberIcon";
import AnnotationSquareLetter from "../icons/AnnotationSquareLetter";
import AnnotationSquareNumber from "../icons/AnnotationSquareNumber";

export const AnnotationNumberIcons: AnnotationMenuIconType[] = [
  {
    name: "circledNumber",
    icon: <AnnotationCircleNumber />,
  },
  {
    name: "circledLetter",
    icon: <AnnotationCircleLetter />,
  },
  {
    name: "squareNumber",
    icon: <AnnotationSquareNumber />,
  },
  {
    name: "squareLetter",
    icon: <AnnotationSquareLetter />,
  },
];
