import React, { useEffect, useState } from "react";
import ReactQuill, { Quill } from "react-quill-2";
import buttons from "../Annotation/AnnotationTextMenu/helpers/buttons";
import "./richTextEditor.css";
import { useMiscUI } from "../../contexts/MiscUI/MiscUIProvider";
import {
  OLD_EM_FONT_WHITELIST,
  BASE_PAGES_QUILL_FONT_SIZE_WHITELIST,
  OLD_REM_FONT_WHITELIST,
} from "../Annotation/AnnotationTextMenu/constants";
import { parseSemanticVersioning } from "../../utils/Versioning/versioning";
const StandardFormats = [
  "background",
  "bold",
  "color",
  "font",
  "italic",
  "link",
  "size",
  "strike",
  "script",
  "underline",
  "blockquote",
  "header",
  "indent",
  "list",
  "align",
  "direction",
  "image",
  "video",
];
export const QUILL_BLOCK_CLASS_NAME = "standard-blot-block";
export const QUILL_BLOCK_TAG_NAME = "P";
export const QUILL_BLOCK_CLASS_ATTRIBUTOR_NAME = "ql-custom-size-block";
export const QUILL_INLINE_CLASS_ATTRIBUTOR_NAME = "ql-custom-size-inline";

const Parchment = Quill.import("parchment");
const Block = Quill.import("blots/block");
const TextBlot = Quill.import("blots/text");
// const Break = Quill.import("blots/break");
// const BubbleTheme = Quill.import('themes/bubble');
// class ExtendBubbleTheme extends BubbleTheme {
//   constructor(quill, options) {
//     super(quill, options);

//     quill.on('selection-change', range => {
//       if (range) {
//         quill.theme.tooltip.show();
//         quill.theme.tooltip.position(quill.getBounds(range));
//       }
//     });
//   }
// }

class CustomMainBlock extends Block {}
class CustomTextBlot extends TextBlot {
  static create(value) {
    return document.createTextNode(value);
  }
}
CustomMainBlock.tagName = QUILL_BLOCK_TAG_NAME;
CustomMainBlock.className = QUILL_BLOCK_CLASS_NAME;

let OldCustomSizeEMStyleAttributor = new Parchment.StyleAttributor("size", "font-size", {
  //there are hard coded SCOPES within Parchment.Scope the do various different things.
  scope: Parchment.Scope.INLINE,
  whitelist: [...OLD_EM_FONT_WHITELIST, ...OLD_REM_FONT_WHITELIST],
});

let CustomSizeInlineClassAttributor = new Parchment.ClassAttributor("size", QUILL_INLINE_CLASS_ATTRIBUTOR_NAME, {
  //there are hard coded SCOPES within Parchment.Scope the do various different things.
  scope: Parchment.Scope.INLINE,
  whitelist: BASE_PAGES_QUILL_FONT_SIZE_WHITELIST,
});
let CustomSizeBlockClassAttributor = new Parchment.ClassAttributor("blockSize", QUILL_BLOCK_CLASS_ATTRIBUTOR_NAME, {
  //there are hard coded SCOPES within Parchment.Scope the do various different things.
  scope: Parchment.Scope.BLOCK,
  whitelist: BASE_PAGES_QUILL_FONT_SIZE_WHITELIST,
});
// let ListDataAttribute = new Parchment.Attributor.Attribute("listData", "color", {
//   //there are hard coded SCOPES within Parchment.Scope the do various different things.
//   scope: Parchment.Scope.INLINE,
// });
// let ListItemColorStyleAttributor = new Parchment.Attributor.Style("colorListItem", "color", {
//   //there are hard coded SCOPES within Parchment.Scope the do various different things.
//   scope: Parchment.Scope.BLOCK,
// });
// let ListIndentStyle = new Parchment.Attributor.Attribute("dataList", "bullet", {
//   //there are hard coded SCOPES within Parchment.Scope the do various different things.
//   scope: Parchment.Scope.INLINE,
// });
// let ListItemBackgroundColorStyleAttributor = new Parchment.StyleAttributor(
//   'backgroundColorListItem',
//   'background-color',
//   {
//     //there are hard coded SCOPES within Parchment.Scope the do various different things.
//     scope: Parchment.Scope.BLOCK,
//   },
// );

function createQuillHandlers(options) {
  return {
    // background: function background(value) {

    //   const format = this.quill.getFormat()

    //   if(format.list) {
    //     this.quill.format('backgroundColorListItem', value)
    //   }
    //   this.quill.format('background', value)
    // },
    color: function color(value) {
      const format = this.quill.getFormat();
      if (format.list) {
        this.quill.format("colorListItem", value, "user");
      }
      this.quill.format("color", value, "user");
    },
    size: function size(value) {
      // const format = this.quill.getFormat();
      // const range = this.quill.getSelection();
      // const blockSize = parseInt(format.blockSize, 10);
      // const size = parseInt(format.size, 10);

      const version = parseSemanticVersioning(options.version);
      if (version) {
        const lineHeightEditVersion = version[0] >= 1 && version[1] >= 1;
        if (lineHeightEditVersion || options.isBubble) {
          this.quill.format("blockSize", value, "user");
        }
      }
      this.quill.format("size", value, "user");
    },
    clearFormat: function clearFormat(sumfin) {
      const sel = this.quill.getSelection();
      this.quill.removeFormat(sel.index, sel.length, "user");
      this.quill.format("size", "normal", "user");
      this.quill.format("blockSize", "normal", "user");
    },
    // bold: function bold(value) {
    //   const blotList = new Set();
    //   function goThroughAllChildren(blot) {
    //     if (blot instanceof TextBlot) {
    //       return;
    //     }
    //     if (blot.attributes) {
    //       blotList.add(blot);
    //     }
    //     if (blot.children) {
    //       goThroughAllChildren(blot.children.head);
    //     }
    //     if (blot.next && !(blot instanceof CustomMainBlock)) {
    //       goThroughAllChildren(blot.next);
    //     }
    //   }

    //   const { index, length } = this.quill.getSelection();

    //   const lines = this.quill.getLines(index, length);

    //   const [lineBlot] = this.quill.getLine(this.quill.getSelection().index);
    //   lines.forEach((blockBlot) => {
    //     goThroughAllChildren(blockBlot);
    //   });

    //   const attributeList = new Set();
    //   for (let blot of blotList.values()) {
    //     // if('size' in blot.attributes.attributes) {
    //     //     attributeList.add(blot.attributes.attributes.size);
    //     // }
    //     // if('size' in blot.attributes.attributes) {
    //     //     attributeList.add(blot.attributes.attributes.size);
    //     // }
    //     for (let [key, [val]] of blot.domNode.attributeStyleMap.entries()) {
    //       if (key === "font-size") {
    //         attributeList.add(val.value.toString() + val.unit);
    //       }
    //     }
    //   }
    // },
  };
}

const quillKeyboardBindings = {
  custom: {
    key: "F",
    altKey: true,
    shiftKey: true,
    handler: function (range, context) {
      // Handle shift+b
      const len = this.quill.getLength();
      this.quill.setSelection(0, len);
      this.quill.removeFormat(0, len, "user");
      this.quill.format("size", "normal", "user");
      this.quill.format("blockSize", "normal", "user");
    },
  },
};
const quillHandlers = {
  //plz no delete
  // size: function size(value) {
  //     const format = this.quill.getFormat();
  //     const range = this.quill.getSelection();
  //     const blockSize = parseInt(format.blockSize, 10)
  //     const size = parseInt(format.size, 10);
  //     this.quill.format('size', value, 'user')
  //     this.quill.format('blockSize', value, 'user')
  // },
  // bold: function bold(value) {
  //     const blotList = new Set();
  //     function goThroughAllChildren(blot) {
  //         if(blot instanceof TextBlot) {
  //             return
  //          };
  //         if(blot.attributes) {
  //             blotList.add(blot);
  //         }
  //         if(blot.children) {
  //             goThroughAllChildren(blot.children.head)
  //         }
  //         if(blot.next && !(blot instanceof CustomMainBlock)) {
  //             goThroughAllChildren(blot.next)
  //         }
  //     }
  //     const {index, length} = this.quill.getSelection()
  //     const lines = this.quill.getLines(index, length)
  //     const [lineBlot] = this.quill.getLine(this.quill.getSelection().index);
  //     lines.forEach((blockBlot) => {
  //         goThroughAllChildren(blockBlot)
  //     })
  //     const attributeList = new Set();
  //     for(let blot of blotList.values()) {
  //         // if('size' in blot.attributes.attributes) {
  //         //     attributeList.add(blot.attributes.attributes.size);
  //         // }
  //         // if('size' in blot.attributes.attributes) {
  //         //     attributeList.add(blot.attributes.attributes.size);
  //         // }
  //         for(let [key, [val]] of blot.domNode.attributeStyleMap.entries()) {
  //             if(key === "font-size") {
  //                 attributeList.add(val.value.toString() + val.unit)
  //             }
  //         }
  //     }
  // }
};

const RichTextEditor = (props) => {
  const fontStylingOptions = ["bold", "italic", "underline"];
  const version = parseSemanticVersioning(props.version);
  const vOneOneZero = version && version[0] >= 1 && version[1] >= 1 && version[2] >= 0;
  const fontSizeOptions = vOneOneZero
    ? [{ size: BASE_PAGES_QUILL_FONT_SIZE_WHITELIST }]
    : [{ size: OLD_REM_FONT_WHITELIST }];
  const fontColorOptions = [
    {
      color: [
        "#FFFF00",
        "#FF0000",
        "#FFC0CB",
        "#00FF00",
        "#808080",
        "#00FFFF",
        "#A8B8C8",
        "#FFBF00",
        "#FF9900",
        "#FF00FF",
        "#00B500",
        "#969DA6",
        "#00CCCC",
        "#0000FF",
        "#F2DB18",
        "#CC0000",
        "#990099",
        "#72BE44",
        "#DADBDD",
        "#0091FF",
        "#283D55",
        "#CC7722",
        "#7C0A02",
        "#5E36FF",
        "#9FFF0A",
        "#4B4F58",
        "#FFFFFF",
        "#000000",
      ],
    },
  ]; //6-15 change
  const backgroundColorOptions = [
    {
      background: [
        "#FFFF00",
        "#FF0000",
        "#FFC0CB",
        "#00FF00",
        "#808080",
        "#00FFFF",
        "#A8B8C8",
        "#FFBF00",
        "#FF9900",
        "#FF00FF",
        "#00B500",
        "#969DA6",
        "#00CCCC",
        "#0000FF",
        "#F2DB18",
        "#CC0000",
        "#990099",
        "#72BE44",
        "#DADBDD",
        "#0091FF",
        "#283D55",
        "#CC7722",
        "#7C0A02",
        "#5E36FF",
        "#9FFF0A",
        "#4B4F58",
        "#FFFFFF",
        "#000000",
        "transparent",
      ],
    },
  ]; //6-15 change
  const alignOptions = [{ align: "" }, { align: "center" }, { align: "right" }];
  const listOptions = [{ list: "bullet" }, { list: "ordered" }, "clearFormat"];
  const [theme, setTheme] = useState("snow");
  const [{ quillRefreshToggle }] = useMiscUI();
  const bubbleToolbar = [
    fontSizeOptions,
    fontStylingOptions,
    fontColorOptions,
    backgroundColorOptions,
    alignOptions,
    listOptions,
  ];

  //in order to preserve html styling instructions needs to be set into a state varible with the whitelist
  const [loadedText, setloadedText] = useState({
    rawInput: "",
    loadedText: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [quillModules, setQuillModules] = useState();

  if (props.attached) {
    if (theme === "bubble" && props.attached === "true") {
      setTheme("snow");
    }
  }

  useEffect(() => {
    buttons();
  });
  useEffect(() => {
    if (vOneOneZero) {
      Quill.register("formats/size", CustomSizeInlineClassAttributor);
      Quill.register("formats/blockSize", CustomSizeBlockClassAttributor);
    } else {
      Quill.register("formats/size", OldCustomSizeEMStyleAttributor);
    }
    // Quill.register("formats/color", ListItemColorStyleAttributor);
    Quill.register("blots/block", CustomMainBlock);
    Quill.register("blots/text", CustomTextBlot);
    const isBubble = props.theme === "bubble";
    const quillModules = {
      toolbar: {
        container: isBubble ? bubbleToolbar : "#text-menu",
        handlers: createQuillHandlers(
          {
            version: props?.version,
            isBubble,
          } /**options */,
        ),
      },
      keyboard: {
        bindings: quillKeyboardBindings,
      },
    };
    setQuillModules(quillModules);

    setIsLoading(false);
    return () => {};
  }, [quillRefreshToggle]);

  useEffect(() => {
    if (!isLoading) {
      if (props.quillRef && props.quillRef?.current) {
        props.quillRef.current.regenerate();
        props.quillRef.current.editor.root.innerHTML = props.displayText;
        props.quillRef.current.focus();
      }
    }
  }, [isLoading]);

  useEffect(() => {
    console.log("quillrefresh");
    if (props.quillRef && props.quillRef?.current) {
      props.quillRef.current.regenerate();
      const clipboard = props.quillRef.current.getEditor().clipboard;
      const stuff = clipboard.convert({ html: props.displayText });
      props.quillRef.current.getEditor().setContents([]);
      props.quillRef.current.getEditor().setContents(stuff);
    }
  }, [quillRefreshToggle]);
  const assignRawInput = (newText, delta, source, editor) => {
    if (source === "user") {
      let formattedString = newText;
      if (props?.version === undefined) {
        formattedString = formatUnicodeWithinString(newText);
      }
      setloadedText({ ...loadedText, rawInput: formattedString });
      props.assignNewText(formattedString);
    }
    // else if(newText !== props.displayText) {
    //     setloadedText({ ...loadedText, rawInput: formattedString });
    //     props.assignNewText(formattedString);
    // }
  };
  function handleChangeSelection(range, source, editor) {
    // const editor2 = ReactQuill;

    if (props.handleSelectionChange) {
      props.handleSelectionChange(range, source, editor);
    }
  }
  const formatUnicodeWithinString = (inputString) => {
    //recursivly iterates through a string and converts unicode characters
    if (
      inputString.length > 0 &&
      inputString.includes("\\u") &&
      inputString.replace(/<[^>]*>?/gm, "").length - 5 > inputString.replace(/<[^>]*>?/gm, "").indexOf("\\u")
    ) {
      //unicode detected
      let testvar = inputString.slice(inputString.indexOf("\\u"), inputString.indexOf("\\u") + 6); //slices out the unicode then formats it now it needs to be put back into the string at position
      let formattedCode = String.fromCharCode(parseInt(testvar.slice(2, 6), 16));
      let convertedInput = [
        inputString.slice(0, inputString.indexOf("\\u")),
        formattedCode,
        inputString.slice(inputString.indexOf("\\u") + 6, inputString.length),
      ].join("");
      if (convertedInput.includes("\\u")) {
        //if there are still unformatted codes the function executes again
        return formatUnicodeWithinString(convertedInput);
      } else {
        return convertedInput;
      }
    } else {
      return inputString;
    }
  };
  if (isLoading) {
    return <></>;
  } else {
    return (
      <React.Fragment>
        <ReactQuill
          id="editor"
          ref={props.quillRef}
          theme={props.theme}
          defaultValue={props.displayText}
          className="max-height-one-hundred-percent heighBox"
          onChange={assignRawInput}
          formats={StandardFormats}
          preserveWhitespace={true}
          modules={quillModules}
          onChangeSelection={handleChangeSelection}
          readOnly={props?.readOnly ?? false}
          onFocus={() => {
            if (props.setIsToolbarShown) {
              props.setIsToolbarShown(true);
            }
          }}
        ></ReactQuill>
      </React.Fragment>
    );
  }
};
export default RichTextEditor;
