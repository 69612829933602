import React, { useState } from "react";
import "./hsEditor.css";
import WorkShop from "./workShop";
import { ReactComponent as IncreaseNudge } from "../../../../assets/icons/3DPage/increase-nudge.svg";
import { ReactComponent as DecreaseNudge } from "../../../../assets/icons/3DPage/decrease-nudge.svg";
import { ReactComponent as SceneForward } from "../../../../assets/icons/3DPage/scene-fwd.svg";
import { ReactComponent as SceneBack } from "../../../../assets/icons/3DPage/scene-bkw.svg";
import { ReactComponent as SceneLeft } from "../../../../assets/icons/3DPage/scene-left.svg";
import { ReactComponent as SceneRight } from "../../../../assets/icons/3DPage/scene-right.svg";
import { ReactComponent as SceneUp } from "../../../../assets/icons/3DPage/scene-up.svg";
import { ReactComponent as SceneDown } from "../../../../assets/icons/3DPage/scene-down.svg";
import { ReactComponent as TiltBack } from "../../../../assets/icons/3DPage/tilt-fwd.svg";
import { ReactComponent as TiltForward } from "../../../../assets/icons/3DPage/tilt-bkw.svg";
import { ReactComponent as OpenHotspot } from "../../../../assets/icons/Common/open-menu-hotspot-controls-icon-white.svg";
import { ReactComponent as CloseHotspot } from "../../../../assets/icons/Common/close-menu-hotspot-controls-icon-white.svg";

import { useMiscUI } from "../../../../contexts/MiscUI/MiscUIProvider";

const innards = (
  <>
    <hr />
    <strong className="guideP">Mouse Controls:</strong>
    <div className="guideP">
      {" "}
      <strong>LMB:</strong> Rotate view around HS{" "}
    </div>
    <div className="guideP">
      {" "}
      <strong>RMB:</strong> Move HS along horizontal plane
    </div>
    <div className="guideP">
      {" "}
      <strong>Wheel:</strong> Zoom
    </div>
    <hr />
    <strong className="guideP">Keyboard Controls:</strong>
    <div>
      <IncreaseNudge />
      <p>Increase nudge</p>
    </div>
    <div>
      <DecreaseNudge />
      <p>Decrease nudge</p>
    </div>
    <div>
      <SceneForward />
      <p>Scene Forward </p>
    </div>
    <div>
      <SceneLeft />
      <p>Scene Left </p>
    </div>
    <div>
      <SceneRight />
      <p>Scene Right </p>
    </div>
    <div>
      <SceneBack />
      <p>Scene Back </p>
    </div>
    <div>
      <SceneUp />
      <p>Scene Up </p>
    </div>
    <div>
      <SceneDown />
      <p>Scene Down </p>
    </div>
    <div>
      <TiltForward />
      <p>Tilt Hot Spot Forward</p>
    </div>
    <div>
      <TiltBack />
      <p>Tilt Hot Spot Back</p>
    </div>
  </>
);

const HSEditor = (props) => {
  const [miscUI, setMiscUI] = useMiscUI();
  const [editMode, setEditMode] = useState("scale");

  const ModePicker = () => {
    let styleMarker1 = "style2";
    let styleMarker2 = "style1";
    // let styleMarker3 = "style1"
    switch (editMode) {
      case "scale": {
        styleMarker1 = "style2";
        styleMarker2 = "style1";
        // styleMarker3 = "style1"
        break;
      }
      case "rotate": {
        styleMarker1 = "style1";
        styleMarker2 = "style2";
        // styleMarker3 = "style1"
        break;
      }
      case "translate": {
        styleMarker1 = "style1";
        styleMarker2 = "style1";
        // styleMarker3 = "style2"
        break;
      }
      default: {
        styleMarker1 = "style1";
        styleMarker2 = "style1";
        // styleMarker3 = "style2"
      }
    }
    return (
    <div className="hsControlGui">
      <div className="hsControlInnardsCo">
        <div className="modeBox">
          <button className={styleMarker1} id="resizeButton" onClick={() => setEditMode("scale")}>
            re-size
          </button>
          <button className={styleMarker2} id="rotateButton" onClick={() => setEditMode("rotate")}>
            rotate
          </button>
        </div>
        <hr />
        {props.hotSpotEditor()}
      </div>
    </div>
    );
  };
  const show = miscUI.hotSpotEditorControlsVisible;
  return (
    <>
      <WorkShop editMode={editMode} mode={props.setDeepEditorMode} cpHotSpot={props.cpHotSpot} />
      <div className="absolute-container" style={{ pointerEvents: "none" }}>
        <div className="relative-container workshop-grid">
          <div className="userGuide">
            <div className="hsec guideP" onClick={() => setMiscUI({ type: "TOGGLE_HSEC" })}>
              <p>Hot Spot Editor Controls</p>
              <p>{show ? <CloseHotspot /> : <OpenHotspot />}</p>
            </div>
            {show && innards}
          </div>
          <ModePicker />
        </div>
      </div>
    </>
  );
};
export default HSEditor;
