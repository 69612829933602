import React from "react";
import { connect } from "react-redux";
import "../CPaT3d_Player.css";
// import { setCurtain } from '../../../store/actions/actions'

const MagicCover = (props) => {
  if (props.curtainSetting === true) {
    return (
      <div className="sceneCover">
        <div className="loading-scene"> Loading 3d scene .... </div>
      </div>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state) => {
  return { curtainSetting: state.Current3dPage.curtain };
};
export default connect(mapStateToProps, {})(MagicCover);
