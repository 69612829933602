import Moveable from "react-moveable";
import { useObjectsDispatch, ObjectActionsType, useObjectsState } from "../../contexts/ObjectsProvider";
import { useMiscUI } from "../../contexts/MiscUI/MiscUIProvider";
import { useLayoutEffect, useRef } from "react";

export function MaskMoveable() {
  const objectsDispatch = useObjectsDispatch();
  const { selectedObjects } = useObjectsState();
  const [miscUI] = useMiscUI();
  const currentCutoutId = miscUI.selectedMask[0];
  const currentSelectedObjectId = selectedObjects[0]?.objectId;
  const currentSelectedObject = selectedObjects[0];
  const currentCutout = (selectedObjects[0]?.blurCutoutShapes || [])?.filter((item) => item?.id === currentCutoutId);
  const isTypeCircle = currentCutout[0]?.type === "circle";
  const moveableRef = useRef<Moveable>(null);
  const canRotate = !isTypeCircle;
  useLayoutEffect(() => {
    if (moveableRef.current) {
      moveableRef.current.updateRect();
    }
  });
  return (
    <Moveable
      target={[`#handle-${currentCutoutId}`]}
      dragTargetSelf={true}
      container={null}
      origin={true}
      edge={false}
      ref={moveableRef}
      draggable={true}
      throttleDrag={0}
      clickable={true}
      renderDirections={["s", "se", "e"]}
      onDrag={({ translate }) => {
        objectsDispatch({
          type: ObjectActionsType.UPDATE_BLUR_CUTOUT,
          payload: {
            objectId: currentSelectedObjectId,
            cutoutId: currentCutoutId,
            props: {
              x: translate[0],
              y: translate[1],
            },
          },
        });
      }}
      /* When resize or scale, keeps a ratio of the width, height. */
      keepRatio={isTypeCircle}
      /* resizable*/
      resizable={true}
      throttleResize={0}
      onResize={({ width, height }) => {
        objectsDispatch({
          type: ObjectActionsType.UPDATE_BLUR_CUTOUT,
          payload: {
            objectId: currentSelectedObjectId,
            cutoutId: currentCutoutId,
            props: {
              w: width,
              h: height,
            },
          },
        });
      }}
      /* rotatable */
      rotatable={canRotate}
      throttleRotate={0}
      onRotate={({ dist }) => {
        objectsDispatch({
          type: ObjectActionsType.UPDATE_BLUR_CUTOUT,
          payload: {
            objectId: currentSelectedObjectId,
            cutoutId: currentCutoutId,
            props: {
              rotate: dist,
            },
          },
        });
      }}
    />
  );
}
