import { useEffect, useState } from "react";
import genericRepositoryService from "../services/genericRepositoryService";
import { useSelector } from "react-redux";
import { memoize } from "lodash";

// you want to import Settings and settingType in your component
export enum settingType {
  cpatonly = "cpatonly",
  customerenabled = "customerenabled",
  disabled = "disabled",
}

export type Settings = {
  [key: string]: settingType;
};

const isSettingType = (input: any): boolean => {
  if (Object.values(settingType).includes(input)) return true;
  return false;
};

// this memoizes the fetch result so we don't have to fetch each time the hook is used
const getSettingsWithCache = memoize(async (): Promise<{ [key: string]: settingType }> => {
  return new Promise((res) => {
    genericRepositoryService.getNewLessonInformation().then((response) => {
      res(response.data.settings);
    });
  });
});

const getWhatToRenderFromSettings = (pageSettings: Settings, property: string, isCpat: boolean) => {
  switch (pageSettings[property]) {
    case settingType.customerenabled:
      return { [property]: true };
    case settingType.cpatonly:
      return isCpat ? { [property]: true } : { [property]: false };
    case settingType.disabled:
      return { [property]: false };
    default:
      return { [property]: false };
  }
};

export const DEFAULT_SETTING_TOGGLE_PERMISSIONS = {
  hotspotsOnTimeline: settingType.cpatonly,
};
const useSettingsToggle = (defaultSettings: Settings) => {
  const [pageSettings, setPageSettings] = useState<Settings>(defaultSettings); // settings object passed during
  const [featuresToRender, setFeaturesToRender] = useState<{
    [key: string]: boolean;
  }>({}); // actual result
  const [settingsFromApi, setSettingsFromApi] = useState<Settings>({});
  const [authorized, setAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);
  const isCPaTUser: boolean = useSelector(({ authorizedState }: any) => authorizedState.isCpatUser);

  useEffect(() => {
    const getApiSettings = async () => {
      const settings = await getSettingsWithCache();
      setSettingsFromApi(settings);
      setLoading(false);
    };
    getApiSettings();
    setAuthorization(isCPaTUser);
  }, [isCPaTUser]);

  useEffect(() => {
    if (Object.keys(defaultSettings).length === 0) {
      setPageSettings(settingsFromApi);
    }
  }, [settingsFromApi]);

  // merges the settings provided by user with the ones provided by API
  const handleSettingsOverride = (incomingSettings: { [key: string]: string }) => {
    // these are your relevant settings from the state your initialized earlier
    Object.keys(pageSettings).forEach((setting) => {
      if (!incomingSettings || !incomingSettings[setting]) return;
      const settingFromAPI = incomingSettings[setting];
      if (isSettingType(settingFromAPI) && settingFromAPI !== pageSettings[setting]) {
        setPageSettings(Object.assign(pageSettings, incomingSettings));
      }
    });
  };
  // takes the merged settings object and returns the answer as to whether the set of features should be enabled or not
  const setSettingsToggle = (isCpatUser: boolean) => {
    Object.entries(pageSettings).forEach(([key]) => {
      const feature = getWhatToRenderFromSettings(pageSettings, key, isCpatUser); // returns {feature: bool}, for example {pptimport: true}
      setFeaturesToRender(Object.assign(featuresToRender, feature));
    });
  };
  useEffect(() => {
    const returnSettingToggle = (isCpatUser: boolean) => {
      handleSettingsOverride(settingsFromApi);
      setSettingsToggle(isCpatUser);
    };
    returnSettingToggle(isCPaTUser);
  }, [authorized, settingsFromApi, pageSettings]);

  // this wrapper is mostly for clarity. intellisense will show the isCpatUser argument when you hover the function,
  // you won't if you pass the setState hook directly
  const setAuthorization = (isCpatUser: boolean) => {
    setAuthorized(isCpatUser);
  };

  return [featuresToRender, setAuthorization, loading] as const;
};

export default useSettingsToggle;

/**
 *
 * Example of how to use this hook:
 *
 * let's say you have a feature that needs toggling depending on the user status (isCpat)


 1. import the necessary stuff to your component

 * import useSettingsToggle, {settingType, Settings} from ...
 *
 * 2. define your default settings for the component
 *
 * const settings: Settings = {
 *  machineTranslation: settingType.customerenabled,
 * }
 *
 * 3. use the hook, pass the settings object to the hook
 *
 *  const [features, setFeatures] = useSettingsToggle(settings)
 *  const {machineTranslation} = features // machineTranslation here is a boolean that tells you whether a feature should be rendered
 *
 * 4. Fetch API stuff.  Pass the user status to setFeatures -- NOTE: this is not necessary now as the redux store call has been moved into the hook itself. You can just use the features object directly
 *
 *
 *  useEffect(()=> {
 *          const isCpat = reduxStoreStuff() // still not exactly sure how this works!
 *          setFeatures(isCpatUser) // the structure of the fetched data will vary, don't follow the way of accessing this to the 
 * 
 *          
 *  })
 *
 * 5. Now you have an answer as to whether a feature should be rendered:
 *
 *  {machineTranslation && <YourComponent/>}
 *
 */
