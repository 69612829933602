import React, { useContext, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Canvas } from "@react-three/fiber";
import Controls from "./riggingControls";
import _ from "lodash";
import "../../CPaT3d_Player/components/hotSpotEditor/hsEditor.css";
import Plane from "../scene/plane";
import Enviroment from "../scene/enviroment";
import Skybox from "../scene/skybox";
// import SceneLights from "../scene/lights";
import { setWAHotSpot, setCurtain } from "../../../store/actions/actions";
import { PageContext } from "../../../routes/builderContexts";

import SceneLights from "../../Generic3DFiles/lights"

import {
  Sky
} from "@react-three/drei"

const WorkShop = (props) => {
  const pageContext = useContext(PageContext);
  const pageManifest = _.cloneDeep(pageContext.pageManifest);
  const [modelNumber, setModelNumber] = useState("");
  var modelPath = props.modelPath;

  const returnNewHotSpot = (data) => {
    if (data) {
      pageManifest.walkAroundHS1 = _.cloneDeep(data);
      pageContext.updatePageManifest(pageManifest);
      props.setWAHotSpot(data);
    } else {
      return;
    }
  };
  const returnProximity = (data) => {
    if (!data) return;
    pageManifest.proximityRange = parseFloat(data);
    pageContext.updatePageManifest(pageManifest);
  };

  const validateOffsetData = (data) => {
    const isData = Boolean(data);
    const allKeysPresent = Object.keys(data).every((value) => {
      return ["x", "y", "z"].includes(value);
    });
    const allIsNumbers = Object.values(data).every((value) => {
      return typeof value === "number";
    });

    return isData && allKeysPresent && allIsNumbers;
  };

  const returnProximityOffset = (data) => {
    const isValidData = validateOffsetData(data);
    if (!isValidData) {
      console.warn("Tried to set invalid data for proximity offset: " + data);
      return;
    }
    pageManifest.proximityOffset = data;
    pageContext.updatePageManifest(pageManifest);
  };

  useEffect(() => {
    let tempModelArray = modelPath.split("/");
    let tempModelNumber = tempModelArray[tempModelArray.length - 1];
    setModelNumber(tempModelNumber);
  }, [modelPath]);

  return (
    <>
      <div style={{ position: "relative", height: "100%", width: "100%" }}>
        <Canvas shadowMap={{ type: 2, enabled: true }}>
          <SceneLights env={"walkaround"}/>
          <Controls
            showHSEditor={props.showHSEditor}
            hotspotEditMode={props.hotspotEditMode}
            isSphere={props.isSphere}
            setIsSphere={props.setIsSphere}
            editMode={props.editMode}
            returnNewHotSpot={returnNewHotSpot}
            returnProximity={returnProximity}
            returnProximityOffset={returnProximityOffset}
            isVisible={props.isVisible}
            currentHotSpot={props.currentHotSpot}
            proximityRange={props.proximityRange}
            proximityOffset={props.proximityOffset}
          />
          <Plane modelPath={props.modelPath} callBack={() => {}}/>
          <Enviroment callBack={() => {}}/>
          <Sky sunPosition={[0,1,0]}/>
          {/* <Skybox /> */}
        </Canvas>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    modelPath: state.Current3dPage.model,
    currentHotSpot: state.Current3dPage.walkAroundHS1,
  };
};

export default connect(mapStateToProps, { setWAHotSpot, setCurtain })(WorkShop);
