import React, { useState, useContext, useEffect, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import { IPageContext, PageContext } from "../../../routes/builderContexts";
import { IFmsPageManifest } from "../Interfaces/IFmsPageManifest";
import RichTextEditor from "../../../components/richTextEditor/richTextEditor";
import UnicodeLegend from "../../../components/unicodeLegend/unicodeLegend";
import CDUGraphicsEditor from "./CDUGraphicsEditor";
import { IFmsContext, FmsContext } from "../Interfaces/fmsContext";
import FmsObjectPositionEditor from "./FmsObjectPositionEditor";

interface CduSingleLineEditorProps {
  cduPage: string;
  cduData: any;
  cduHtmlData: any;
  lineLength: number;
  updateManifestData: (newText: string, id: string) => void;
  cellId: string;
}

interface StringMap {
  [key: string]: any;
}
const CduSingleLineEditor: React.FC<CduSingleLineEditorProps> = (props: CduSingleLineEditorProps) => {
  const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);
  const pagesContext: IPageContext = useContext<IPageContext>(PageContext);
  const pageManifest: IFmsPageManifest = pagesContext.pageManifest as IFmsPageManifest;
  const quillComponent = useRef<ReactQuill>(null);
  const [rteInputValidation, setInput] = useState({
    inputText: props.cduHtmlData ? props.cduHtmlData : "",
    inputLength: 0,
    statusText: " ",
    fontClass: "text-success",
    submitted: false,
  });
  const quillBar = [{ align: "" }, { align: "center" }, { align: "right" }, { font: [false, "monospace"] }];
  // const quillFonts = ["1rem","0.9rem","0.8rem"];
  const quillFonts = ["1em", "0.9em", "0.8em"];
  const quillFormats = [
    "background",
    "bold",
    "color",
    "font",
    "italic",
    "size",
    "strike",
    "script",
    "underline",
    "header",
    "indent",
    "align",
    "direction",
    "spanwrapper",
  ];

  const getPageToEdit = () => {};
  const addCellFormating = () => {
    if (pageManifest.pageLayouts[pageManifest.bootPage].cellFormating === undefined) {
      pageManifest.pageLayouts[pageManifest.bootPage].cellFormating = {};
      pagesContext.updatePageManifest(pageManifest);
    }
  };

  const fixOldFontSizing = () => {
    // let stringStart = rteInputValidation.inputText.indexOf("font-size:")
    // let stringEnd
    return rteInputValidation.inputText?.replaceAll("0.9rem;", "0.9em;").replaceAll("0.8rem;", "0.8em;");
  };

  //     const spanWrapperFunction = () => {
  //     let editor = quillComponent.current ?.getEditor();
  //     var range = editor ?.getSelection()
  //     if (range !== null && range !== undefined) {
  //         var format: StringMap | undefined = editor ?.getFormat(range.index, range.index + range.length)
  //         if (format ?.spanwrapper !== 'wrapper') {
  //             editor ?.format('spanwrapper', 'wrapper')
  //         } else {
  //             editor ?.removeFormat(range.index, range.length)}
  //     }
  // }
  //     const quillModules = {
  //         toolbar: {
  //             container: [["bold", "italic", "underline"],
  //             [{ color: ["#ffffff", "#ff00ff", "#00FFFF", "#00b500", "#FFFF00", "#ffbf00", "#000000", "#969DA6"] }],
  //             [{ background: ["#00000000", "#ffffff", "#ff00ff", "#00FFFF", "#00b500", "#FFFF00", "#ffbf00","#969DA6"] }],
  //             [{ size:  ['1rem', '1.5rem', '2rem'] }],
  //             [],
  //             ],
  //             handlers: { 'spanwrapper': spanWrapperFunction }
  //         }
  //     }

  //     if (pageManifest.cdu === "e190_cdu.json" || pageManifest.cdu === "e175_cdu.json" || pageManifest.cdu === "e145_cdu.json") {
  //         quillModules.toolbar.container.splice(1, 0, ["spanwrapper"])
  //     }

  useEffect(() => {
    getPageToEdit();
    addCellFormating();
  }, []);

  useEffect(() => {
    setInput({
      ...rteInputValidation,
      inputLength: props.lineLength,
    });
  }, [props.lineLength]);

  useEffect(() => {
    setInput({
      ...rteInputValidation,
      statusText: " ",
    });
  }, [rteInputValidation.inputLength]);

  useEffect(() => {
    //when new cduHtmlData is passed to props the rte needs to refresh to the new string
    if (fmsContext.triggerReRender === true) {
      setInput({
        ...rteInputValidation,
        inputText: props.cduHtmlData,
        statusText: "",
      });
      setTimeout(() => {
        fmsContext.toggleReRender(false);
      }, 1);
    }
  }, [fmsContext.triggerReRender]);

  useEffect(() => {
    if (rteInputValidation.statusText === "Character Limit Exceded (Max 13 characters)") {
      setInput({
        ...rteInputValidation,
        fontClass: "text-danger",
      });
    } else {
      setInput({
        ...rteInputValidation,
        fontClass: "text-success",
      });
    }
  }, [rteInputValidation.statusText]);
  useEffect(() => {
    return () => {
      fmsContext.setEditingCduScreen(false);
    };
  }, []);

  const assignNewText = (newText: string): void => {
    const isTooLong = validateTextLength(newText);

    if (!isTooLong) {
      setInput({
        ...rteInputValidation,
        inputText: newText,
        inputLength: newText.replace(/<[^>]*>?/gm, "").length,
      });
      props.updateManifestData(newText, props.cduData.id);
      //need to only all caps text within styles
      //const filterText = newText.replace(/<[^>]*>?/gm, ".");
      //pageManifest.pages[props.cduPage][props.cduData.id] = newText;
      // pageManifest.pages[fmsContext.cduPageToEdit][props.cduData.id] = newText;
      //pagesContext.updatePageManifest(pageManifest);
    }
  };

  const emptyAllCells = () => {
    for (const prop in pageManifest.pages[props.cduPage]) {
      pageManifest.pages[props.cduPage][prop] = "";
    }
    pagesContext.updatePageManifest(pageManifest);
  };

  const validateTextLength = (text: string) => {
    //different for props.cduData.id TITLE
    //let regex = text.replace(/<[^>]*>?/gm, "");
    // if(regex.length > 13){
    //     setInput({
    //         ...rteInputValidation,
    //         statusText: "Character Limit Exceeded (Max 13 characters)"
    //     });
    //     return true;
    // } else {

    setInput({
      ...rteInputValidation,
      //statusText: "Cell Changed Successfully"
    });
    return false;
    //}
  };

  // const confirmationSubmition = () => {
  //         // validateTextLength();
  //         pageManifest.pages[props.cduPage][props.cduData.id] = rteInputValidation.inputText.toUpperCase();
  //         pagesContext.updatePageManifest(pageManifest);
  // }
  const toggleCduGrid = () => {
    if (fmsContext.gridBorder === "") {
      fmsContext.setGridBorder("1px dashed #ffffff");
    } else {
      fmsContext.setGridBorder("");
    }
  };

  //re render rte when data is set
  //font-family: Verdana, Geneva, Tahoma, sans-serif;
  const CduModalCheck = () => {
    const setBootModal = () => {
      if (pageManifest.pageLayouts.bootModal) {
        pageManifest.pageLayouts.bootModal =
          pageManifest.pageLayouts.bootModal !== fmsContext.currentModal.modalName
            ? fmsContext.currentModal.modalName
            : "";
      } else {
        pageManifest.pageLayouts["bootModal"] = fmsContext.currentModal.modalName;
      }
      pagesContext.updatePageManifest(pageManifest);
    };
    return fmsContext.currentModal.modalName && fmsContext.currentModal.modalName.length ? (
      <div className="gridCheck">
        <span className="start-menu-container">
          <div className="dataCheckContainer" style={{ color: "#ffffff", display: "inline-flex", width: "100%" }}>
            <span
              className="check-container fms-key-enable-checkbox grid-checkbox"
              style={{ marginRight: "1%" }}
              onClick={(event: React.MouseEvent) => {
                event.preventDefault();
                event.stopPropagation();
                setBootModal();
              }}
            >
              <input
                type="checkbox"
                className="fms-check-input"
                checked={
                  pageManifest.pageLayouts.bootModal === fmsContext.currentModal.modalName &&
                  pageManifest.pageLayouts.bootModal.length > 0
                }
              />
              <span className="cursor-checkmark shaded"></span>
            </span>
            <p style={{ marginLeft: "2%", width: "200px", marginBottom: "1%" }}> Menu open on start </p>
          </div>
        </span>
      </div>
    ) : (
      <></>
    );
  };
  const DropDownCheck = () => {
    const setBootDropDown = () => {
      if (pageManifest.pageLayouts.bootDropDown) {
        pageManifest.pageLayouts["bootDropDown"] =
          pageManifest.pageLayouts.bootDropDown !== fmsContext.dropDownParent.cduKeyName
            ? fmsContext.dropDownParent.cduKeyName
            : "";
      } else {
        pageManifest.pageLayouts["bootDropDown"] = fmsContext.dropDownParent.cduKeyName;
      }
      pagesContext.updatePageManifest(pageManifest);
    };

    return fmsContext.dropDownOpen && fmsContext.dropDownParent.cduKeyName !== undefined ? (
      <div className="gridCheck">
        <span className="start-drop-down-container" style={{ color: "#ffffff" }}>
          <div className="has-cursor-container drop-down-check-container">
            <span
              className="check-container fms-key-enable-checkbox grid-checkbox"
              onClick={(event: React.MouseEvent) => {
                event.preventDefault();
                event.stopPropagation();
                setBootDropDown();
              }}
            >
              <input
                type="checkbox"
                className="fms-check-input"
                checked={
                  pageManifest.pageLayouts["bootDropDown"] === fmsContext.dropDownParent.cduKeyName &&
                  pageManifest.pageLayouts["bootDropDown"].length > 0
                }
              />
              <span className="cursor-checkmark shaded"></span>
            </span>
            <p style={{ marginLeft: "2%", marginBottom: "1%", width: "200px" }}>Drop Down Open on start</p>
          </div>
        </span>
      </div>
    ) : (
      <></>
    );
  };

  const setCellCursor = () => {
    if (pageManifest.pageLayouts.cursorKey !== props.cduData.id) {
      pageManifest.pageLayouts.cursorKey = props.cduData.id;
      fmsContext.findCursorObject(props.cduData.id);
      // removeCursor();
      // addCursor();
    } else {
      if (pageManifest.cdu.includes("a220")) {
        //pageManifest.pageLayouts.cursorKey = "fms1";
        fmsContext.findCursorObject("fms1");
      } else if (pageManifest.cdu.includes("a350")) {
        fmsContext.findCursorObject("fms-select");
      } else {
        pageManifest.pageLayouts.cursorKey = "";
      }

      // removeCursor();
    }
    fmsContext.setCursorSelected(false);
    pagesContext.updatePageManifest(pageManifest);
  };

  const setCellOutline = (cellId: string) => {
    if (pageManifest.pageLayouts[pageManifest.bootPage].cellFormating?.cellOutlines === undefined) {
      pageManifest.pageLayouts[pageManifest.bootPage].cellFormating.cellOutlines = {};
    }
    if (pageManifest.pageLayouts[pageManifest.bootPage].cellFormating.cellOutlines.hasOwnProperty(cellId)) {
      if (pageManifest.pageLayouts[pageManifest.bootPage].cellFormating.cellOutlines[cellId].outlineShown === true) {
        pageManifest.pageLayouts[pageManifest.bootPage].cellFormating.cellOutlines[cellId].outlineShown = false;
      } else {
        pageManifest.pageLayouts[pageManifest.bootPage].cellFormating.cellOutlines[cellId].outlineShown = true;
      }
    } else {
      pageManifest.pageLayouts[pageManifest.bootPage].cellFormating.cellOutlines[cellId] = { outlineShown: true };
    }
    pagesContext.updatePageManifest(pageManifest);
  };

  const setCellBackground = (cellId: string) => {
    if (pageManifest.pageLayouts[pageManifest.bootPage].cellFormating?.cellBackgrounds === undefined) {
      pageManifest.pageLayouts[pageManifest.bootPage].cellFormating.cellBackgrounds = {};
    }
    if (pageManifest.pageLayouts[pageManifest.bootPage].cellFormating.cellBackgrounds.hasOwnProperty(cellId)) {
      if (
        pageManifest.pageLayouts[pageManifest.bootPage].cellFormating.cellBackgrounds[cellId].backgroundShown === true
      ) {
        pageManifest.pageLayouts[pageManifest.bootPage].cellFormating.cellBackgrounds[cellId].backgroundShown = false;
      } else {
        pageManifest.pageLayouts[pageManifest.bootPage].cellFormating.cellBackgrounds[cellId].backgroundShown = true;
      }
    } else {
      pageManifest.pageLayouts[pageManifest.bootPage].cellFormating.cellBackgrounds[cellId] = { backgroundShown: true };
    }

    setCellBackgroundColor(cellId, "gray");
  };

  const setCellBackgroundColor = (cellId: string, color: string) => {
    const cyan = "rgb(0,255,255)";
    const gray = "rgb(90,90,90)";

    if (color === "gray") {
      pageManifest.pageLayouts[pageManifest.bootPage].cellFormating.cellBackgrounds[cellId].backgroundColor = gray;
    } else {
      pageManifest.pageLayouts[pageManifest.bootPage].cellFormating.cellBackgrounds[cellId].backgroundColor = cyan;
    }

    pagesContext.updatePageManifest(pageManifest);
  };

  const hasOutline = (cellId: string) => {
    if (pageManifest.pageLayouts[pageManifest.bootPage].cellFormating?.cellOutlines?.hasOwnProperty(cellId)) {
      if (pageManifest.pageLayouts[pageManifest.bootPage].cellFormating.cellOutlines[cellId].outlineShown === true) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const hasBackground = (cellId: string) => {
    if (pageManifest.pageLayouts[pageManifest.bootPage].cellFormating?.cellBackgrounds?.hasOwnProperty(cellId)) {
      if (
        pageManifest.pageLayouts[pageManifest.bootPage].cellFormating.cellBackgrounds[cellId].backgroundShown === true
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <p className="instructions" style={{ fontSize: "1.125rem" }}>
            {" "}
            <strong id="cduSingleLineEditor">Configure - {props.cduData.id.replace(/_/g, " ")}</strong>{" "}
          </p>
          <div
            style={{
              display: "inline-flex",
              width: "100%",
              paddingInline: "15px",
              flexWrap: "wrap",
            }}
          >
            <div className="gridCheck">
              <span
                className="check-container fms-key-enable-checkbox cursor-checkbox"
                style={{ marginRight: "1%" }}
                onClick={(event: React.MouseEvent) => {
                  event.preventDefault();
                  event.stopPropagation();
                  toggleCduGrid();
                }}
              >
                <input type="checkbox" className="fms-check-input" checked={fmsContext.gridBorder !== ""} />
                <span className="cursor-checkmark shaded"></span>
              </span>
              <div style={{ width: "20px" }}></div>
              <p className="show-grid">show Grid</p>
            </div>

            {pageManifest.cdu.includes("a220") || pageManifest.cdu.includes("a350") ? (
              <div
                className="gridCheck"
                // style={{ color: "#ffffff", display: "inline-flex", marginLeft: "1%" }}
              >
                <span
                  className="check-container fms-key-enable-checkbox cursor-checkbox"
                  style={{ marginRight: "1%" }}
                  onClick={(event: React.MouseEvent) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setCellCursor();
                  }}
                >
                  <input
                    type="checkbox"
                    className="fms-check-input"
                    checked={pageManifest.pageLayouts.cursorKey === props.cduData.id ? true : false}
                  />
                  <span className="cursor-checkmark shaded"></span>
                </span>
                <div style={{ width: "20px" }}></div>
                <p className="show-grid"> has Cursor</p>
              </div>
            ) : (
              <></>
            )}

            {pageManifest.cdu.includes("a350") ? (
              <div className="gridCheck">
                <span
                  className="check-container fms-key-enable-checkbox cursor-checkbox"
                  style={{ marginRight: "1%" }}
                  onClick={(event: React.MouseEvent) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setCellOutline(props.cduData.id);
                  }}
                >
                  <input type="checkbox" className="fms-check-input" checked={hasOutline(props.cduData.id)} />
                  <span className="cursor-checkmark shaded"></span>
                </span>
                <div style={{ width: "20px" }}></div>
                <p className="show-grid">has Outline</p>
              </div>
            ) : (
              <></>
            )}
            {pageManifest.cdu.includes("a350") ? (
              <div className="gridCheck">
                <span
                  className="check-container fms-key-enable-checkbox cursor-checkbox"
                  style={{ marginRight: "1%" }}
                  onClick={(event: React.MouseEvent) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setCellBackground(props.cduData.id);
                  }}
                >
                  <input type="checkbox" className="fms-check-input" checked={hasBackground(props.cduData.id)} />
                  <span className="cursor-checkmark shaded"></span>
                </span>
                <div style={{ width: "20px" }}></div>
                <p className="show-grid">has Background</p>
                <div
                  style={{
                    marginInline: "2px",
                    height: "20px",
                    width: "20px",
                    backgroundColor: "cyan",
                    outline:
                      pageManifest.pageLayouts[pageManifest.bootPage].cellFormating?.cellBackgrounds?.[props.cduData.id]
                        ?.backgroundColor !== "rgb(0,255,255)"
                        ? ""
                        : "1px solid white",
                  }}
                  onClick={(event: React.MouseEvent) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setCellBackgroundColor(props.cduData.id, "cyan");
                  }}
                ></div>
                <div
                  style={{
                    marginInline: "2px",
                    height: "20px",
                    width: "20px",
                    backgroundColor: "gray",
                    outline:
                      pageManifest.pageLayouts[pageManifest.bootPage].cellFormating?.cellBackgrounds?.[props.cduData.id]
                        ?.backgroundColor !== "rgb(90,90,90)"
                        ? ""
                        : "1px solid white",
                  }}
                  onClick={(event: React.MouseEvent) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setCellBackgroundColor(props.cduData.id, "gray");
                  }}
                ></div>
              </div>
            ) : (
              <></>
            )}
            {/* <div
            style={{
              display: 'inline-flex',
              width: '100%',
              paddingInline: '10px',
            }}
          > */}
            {DropDownCheck()}

            {/* {CduModalCheck()} */}
          </div>
          {/* </div> */}

          <p className={rteInputValidation.fontClass}>{rteInputValidation.statusText}</p>
        </div>
      </div>
      <div className="row editor-container">
        <div className="col-12 cell-editor" style={{ height: "100%" }}>
          {fmsContext.triggerReRender ? (
            <></>
          ) : (
            <RichTextEditor
              displayText={fixOldFontSizing()}
              assignNewText={assignNewText}
              placeholder={""}
              module={quillBar}
              fontSize={quillFonts}
              pageFormats={quillFormats}
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <UnicodeLegend />
        </div>
      </div>

      <FmsObjectPositionEditor
        type={"cdu_cell"}
        cduData={props.cduData}
        pageManifest={pageManifest}
        pagesContext={pagesContext}
      />

      <div className="row">
        <div className="col-12">
          <CDUGraphicsEditor cduCell={props.cduData} cduPage={props.cduPage} />
        </div>
      </div>
    </>
  );
};
export default CduSingleLineEditor;
