import { IAircraft } from "../../models/IGetAircraftLessonData";
const createFormattedAircrafts = (aircrafts: IAircraft[]) => {
  const data = new ManufacturerList(aircrafts);

  return data;
};

export default createFormattedAircrafts;

interface tempManufacturer {
  manufacturerName: string;
  manufacturerId: number;
}

export class AircraftVariant {
  name: string;
  id: number;
  configurations: any = {};
  public static getIdByName = (aircrafts: IAircraft[], name: string) => {
    const aircraft = aircrafts.find((aircraft) => {
      return aircraft.aircraftName === name;
    });
    if (aircraft) {
      return aircraft.aircraftId;
    }
    return null;
  };
  constructor(variant: IAircraft) {
    this.name = variant.aircraftName;
    this.id = variant.aircraftId;
  }
  getId() {}
}
export class AircraftFamilyMember {
  id: number;
  variants: any = {};
  name: string;
  public static getIdByName = (aircrafts: IAircraft[], name: string) => {
    const aircraft = aircrafts.find((aircraft) => {
      return aircraft.aircraftFamilyName === name;
    });
    if (aircraft) {
      return aircraft.aircraftFamilyId;
    }
    return null;
  };
  constructor(familyMember: IAircraft, variants: IAircraft[]) {
    this.id = familyMember.aircraftFamilyId;
    this.name = familyMember.aircraftFamilyName;
    for (let i = 0; i < variants.length; i++) {
      if (!Object.keys(this.variants).includes(variants[i].aircraftName)) {
        Object.defineProperty(this.variants, variants[i].aircraftName, {
          value: new AircraftVariant(variants[i]),
          enumerable: true,
        });
      }
    }
  }
}

export class Manufacturer {
  fleets: any;
  name: string;
  id: number;
  public static getIdByName = (aircrafts: IAircraft[], name: string) => {
    const aircraft = aircrafts.find((aircraft) => {
      return aircraft.manufacturerName === name;
    });
    if (aircraft) {
      return aircraft.manufacturerId;
    }
    return null;
  };
  constructor(manufacturer: tempManufacturer, aircraftFamilies: IAircraft[]) {
    this.fleets = {};
    this.name = manufacturer.manufacturerName;
    this.id = manufacturer.manufacturerId;
    for (let i = 0; i < aircraftFamilies.length; i++) {
      if (!Object.keys(this.fleets).includes(aircraftFamilies[i].aircraftFamilyName)) {
        Object.defineProperty(this.fleets, aircraftFamilies[i].aircraftFamilyName, {
          value: new AircraftFamilyMember(
            aircraftFamilies[i],
            this.getVariants(aircraftFamilies, aircraftFamilies[i].aircraftFamilyName),
          ),
          enumerable: true,
        });
      }
    }
  }

  getVariants(aircraftFamilies: IAircraft[], familyName: string) {
    const variants = aircraftFamilies.filter((aircraft) => {
      return aircraft.aircraftFamilyName === familyName;
    });
    return variants;
  }
}

export class ManufacturerList {
  [key: string]:
    | any
    | Manufacturer
    | ((aircrafts: IAircraft[]) => tempManufacturer[])
    | ((aircrafts: IAircraft[], manufacturerName: string) => IAircraft[]);

  constructor(aircrafts: IAircraft[]) {
    const manufacturers = this.getManufacturers(aircrafts);
    for (let i = 0; i < manufacturers.length; i++) {
      const aircraftFamilies = this.getAircraftFamilies(aircrafts, manufacturers[i].manufacturerName);
      this[manufacturers[i].manufacturerName] = new Manufacturer(manufacturers[i], aircraftFamilies);
    }
  }

  getManufacturers(aircrafts: IAircraft[]) {
    const tempManufacturer: tempManufacturer[] = [];
    const tempManufacturerNames: string[] = [];
    aircrafts?.forEach((aircraft, index, array) => {
      if (!tempManufacturerNames.includes(aircraft.manufacturerName)) {
        tempManufacturer.push({
          manufacturerName: aircraft.manufacturerName,
          manufacturerId: aircraft.manufacturerId,
        });
        tempManufacturerNames.push(aircraft.manufacturerName);
      }
    });
    return tempManufacturer;
  }

  getAircraftFamilies(aircrafts: IAircraft[], manufacturerName: string) {
    const tempAircraftFamilies = aircrafts.filter((aircraft) => {
      return aircraft.manufacturerName === manufacturerName;
    });
    return tempAircraftFamilies;
  }
}
