import React from "react";
import { clippedSpaceFromUnitSpace, useTimeline } from "../../../contexts/TimelineProvider/TimelineProvider";
import { TOP_STRIP_HEIGHT, zIndexes } from "../TimelinePanel";
import HorizontalScrollablar from "./HorizontalScrollbar";
import TopStrip from "./TopStrip";
import Scrubber from "./Scrub/Scrubber";

export default function RightOverlay() {
  const [timeline] = useTimeline();
  const { sequenceLength, heightInPx } = timeline;

  const start = clippedSpaceFromUnitSpace(
    sequenceLength,
    timeline.scaledSpace.leftPadding,
    timeline.clippedSpace,
    timeline.widthInPx,
  );

  return (
    <div
      className="right-overlay"
      style={{
        width: timeline.widthInPx,
        height: "100%",
        display: "flex",
        position: "absolute",
        top: 0,
        right: 0,
        pointerEvents: "none",
        zIndex: zIndexes.rightOverlay,
        overflow: "hidden",
      }}
    >
      <TopStrip />
      <div
        className="right-side-of-length"
        style={{
          position: "absolute",
          // width: timeline.widthInPx,
          top: TOP_STRIP_HEIGHT,
          left: start,
          right: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          height: heightInPx - TOP_STRIP_HEIGHT,
        }}
      ></div>
      <HorizontalScrollablar />
      <Scrubber />
    </div>
  );
}
