import React, { useMemo } from "react";
import * as THREE from "three";

const SceneLights = (props: { modelNumber: string }) => {
  const modelNumber = props.modelNumber;
  const lightColor2 = 0xffffff;

  const theSunlight = useMemo(() => new THREE.SpotLight(lightColor2), []);
  const theSunShadowLight = useMemo(() => new THREE.SpotLight(lightColor2), []);
  theSunShadowLight.castShadow = true;

  theSunShadowLight.shadow.mapSize.height = 2048;
  theSunShadowLight.shadow.mapSize.width = 2048;

  // const light = useMemo(() => new THREE.SpotLight({ lightColor2 }), [])
  const light1b = useMemo(() => new THREE.SpotLight(lightColor2), []);
  const light1c = useMemo(() => new THREE.SpotLight(lightColor2), []);
  const light1d = useMemo(() => new THREE.SpotLight(lightColor2), []);
  const light2c = useMemo(() => new THREE.SpotLight(lightColor2), []);
  const light3l = useMemo(() => new THREE.SpotLight(lightColor2), []);
  const light3r = useMemo(() => new THREE.SpotLight(lightColor2), []);
  // const helper = new THREE.DirectionalLightHelper(light3l, 5);

  return (
    <>
      <primitive object={theSunlight} position={[0, 1000, 0]} intensity={1.5} angle={1} penumbra={1} />
      <primitive object={theSunlight.target} position={[0, 0, 0]} />

      <primitive object={theSunShadowLight} position={[0, 200, 0]} intensity={0.3} angle={1} penumbra={1} />
      <primitive object={theSunShadowLight.target} position={[0, 0, 0]} />

      {/* <primitive ref={spot1} object={light} position={[0, 150, 0]} intensity={1} angle={1} penumbra={1} />
            <primitive object={light.target} position={[0, 500, 0]} /> */}

      <primitive object={light1b} position={[-300, 0, 0]} intensity={1} angle={1} penumbra={1} />
      <primitive object={light1b.target} position={[0, 20, 0]} />

      <primitive object={light1c} position={[300, 0, 0]} intensity={1} angle={1} penumbra={1} />
      <primitive object={light1c.target} position={[0, 20, 0]} />

      <primitive object={light1d} position={[0, 0, -300]} intensity={1} angle={1} penumbra={1} />
      <primitive object={light1d.target} position={[0, 20, 0]} />

      <primitive object={light2c} position={[0, 0, 300]} intensity={0.75} angle={1} penumbra={1} />
      <primitive object={light2c.target} position={[0, 20, 0]} />

      {modelNumber === "b787" ? (
        <React.Fragment>
          <primitive object={light3l} position={[33, 10, 55]} intensity={3} angle={0.2} penumbra={0.01} distance={50} />
          <primitive object={light3l.target} position={[33, 11, 0]} />

          <primitive
            object={light3r}
            position={[-33, 10, 55]}
            intensity={3}
            angle={0.2}
            penumbra={0.01}
            distance={50}
          />
          <primitive object={light3r.target} position={[-33, 11, 0]} />
        </React.Fragment>
      ) : null}
    </>
  );
};
export default SceneLights;
