import React from "react";

type InputErrorType = {
  errorMessage: string;
};

const InputErrorMessage = ({ errorMessage }: InputErrorType) => {
  return (
    <React.Fragment>
      <div>
        <span className="opb-error">{errorMessage}</span>
      </div>
    </React.Fragment>
  );
};

export default InputErrorMessage;
