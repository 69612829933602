import React, { PropsWithChildren, useEffect } from "react";
import genericRepositoryService from "../../services/genericRepositoryService";
import ILessonPage from "../../models/ILessonPage";

// PageListInfo interface
// hasLessons lets know there's lessons to view
// lessonList is list of Lessons
interface PageListInfo {
  pageList: ILessonPage[];
  lessonVersionId?: number;
  loading: boolean;
}

// interface used for reducer
interface PageListAction {
  type: "GET_PAGES" | "SET_PAGES" | "NO_DATA";
  pages?: any;
  lessonVersionId?: number;
}

// initial state
const initialState: PageListInfo = {
  pageList: [],
  lessonVersionId: 0,
  loading: false,
};

const PageListState = React.createContext<PageListInfo | null>(null);
const PageListDispatch = React.createContext<React.Dispatch<PageListAction> | null>(null);

// reducer for provider
const PageListReducer = (state: PageListInfo, action: PageListAction) => {
  switch (action.type) {
    case "GET_PAGES": {
      return {
        pageList: [],
        lessonVersionId: action.lessonVersionId,
        loading: true,
      };
    }
    case "SET_PAGES": {
      return { ...state, pageList: action.pages, loading: false };
    }
    case "NO_DATA": {
      return { pageList: [], lessonVersionId: 0, loading: false };
    }
    default:
      return state;
  }
};

// retrieves all lessons async without blocking lesson load
async function fetchLessonPages(lessonVersionId: number) {
  const getLessonPagesRequest = {
    lessonVersionId: lessonVersionId,
  };

  const result = await genericRepositoryService.getLessonPages(getLessonPagesRequest);

  return result.data.lessonPages;
}

// provider that gets the initial data load
export const PageListProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = React.useReducer(PageListReducer, initialState);

  useEffect(() => {
    if (state.lessonVersionId) {
      fetchLessonPages(state.lessonVersionId).then((pageResults) => {
        // if no data is returned, don't change state
        if (!pageResults) {
          dispatch({ type: "NO_DATA" });

          return;
        }

        const pageList: ILessonPage[] = [];

        for (const lp in pageResults) {
          if (pageResults[lp].order > 3 && pageResults[lp].order < pageResults.length - 3) {
            pageList.push(pageResults[lp]);
          }
        }

        // update the lesson list with the latest results
        dispatch({ type: "SET_PAGES", pages: pageList });
      });
    }
  }, [state.lessonVersionId]);

  return (
    <PageListState.Provider value={state}>
      <PageListDispatch.Provider value={dispatch}>{children}</PageListDispatch.Provider>
    </PageListState.Provider>
  );
};

// library provider function that is used by the components
export const usePageList = () => {
  const ctx = React.useContext(PageListState);
  const ctx2 = React.useContext(PageListDispatch);

  if (!ctx || !ctx2) {
    throw new Error("must wrap in PageListProvider");
  }
  return [ctx, ctx2] as [typeof ctx, typeof ctx2];
};
