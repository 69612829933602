/* eslint-disable @typescript-eslint/no-unused-vars */
import { CSSProperties } from "react";
import {
  SmartObjectComponent,
  SmartObjectLine,
  SmartObjectPolygon,
  SmartObjectPolyline,
  SmartObjectSvgRendererProps,
  SmartObjectImage,
  SmartObjectRect,
  SmartObjectSvgPath,
} from "../";
import { useMetaVariableStore } from "../../../store";
import { getSmartObjectMetaVariableKey } from "../../../utils";
import { useSmartObjectStyles } from "../useSmartObjectStyles";

export function SmartObjectSvgFillRenderer({ element, objectId, parentId }: SmartObjectSvgRendererProps) {
  const { type, GROUP_ID, ACTION, HOTSPOT_ID, TYPE, VALUE, children, textContent, ...rest } = element;
  const key = getSmartObjectMetaVariableKey(objectId, GROUP_ID);
  const metaData = useMetaVariableStore((s) => s.metaVariablesData[key]);
  const selectedValue = useMetaVariableStore((s) => s.metaVariables[key]);
  const color = metaData?.colors?.[selectedValue as string];
  const fillStyle = { fill: color } as CSSProperties;

  switch (type) {
    case "g": {
      return <SvgFillG element={element} objectId={objectId} parentId={parentId} />;
    }
    case "polyline": {
      return <SmartObjectPolyline element={element} extraStyles={fillStyle} />;
    }
    case "rect": {
      return <SmartObjectRect element={element} extraStyles={fillStyle} />;
    }
    case "path": {
      return <SmartObjectSvgPath element={element} extraStyles={fillStyle} />;
    }
    case "polygon": {
      return <SmartObjectPolygon element={element} />;
    }
    case "line": {
      return <SmartObjectLine element={element} />;
    }
    case "image": {
      return <SmartObjectImage element={element} />;
    }
    default: {
      console.warn("SmartObjectSvgFillRenderer: type not handled", type);
      return null;
    }
  }
}

export function SvgFillG({ element, objectId }: SmartObjectComponent) {
  const style = useSmartObjectStyles(element.style);
  const { children, TYPE, GROUP_ID, VALUE, HOTSPOT_ID, ...rest } = element;

  return (
    <g {...rest} style={style}>
      {children?.map((item, idx) => (
        <SmartObjectSvgFillRenderer key={idx} element={item} objectId={objectId} />
      ))}
    </g>
  );
}
