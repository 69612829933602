import React from "react";

type DeleteButtonType = {
  removeElement(boolean: boolean): void;
};

const DeleteButton = (props: DeleteButtonType) => {
  return (
    <React.Fragment>
      <hr className="wysiwyg-toolbar-divider" id="remove-divider" />
      <button
        className="wysiwyg-toolbar-btn"
        id="remove"
        onClick={() => {
          props.removeElement(true);
        }}
        style={{ minWidth: "30px" }}
      >
        <span className="icon-remove-no-background"></span>
      </button>
    </React.Fragment>
  );
};

export default DeleteButton;
