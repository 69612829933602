import React from "react";
import { ICduCircularButton } from "../Interfaces/ICdu";

export interface ICduCircularButtonsProps {
  cduCircularButtons: ICduCircularButton[];
  assignFmsKeypad: (id: string) => void;
  highlights: string[];
}

const CduCircleButtons: React.FC<ICduCircularButtonsProps> = (props: ICduCircularButtonsProps): any => {
  return props.cduCircularButtons.map((circObject: ICduCircularButton, key: number) => {
    let cdukeyName = circObject.cduKeyName;
    if (cdukeyName === "decimal") {
      cdukeyName = ".";
    } else if (cdukeyName === "plus/minus") {
      cdukeyName = "+";
    }

    return (
      <ellipse
        key={key}
        cx={circObject.cx}
        cy={circObject.cy}
        rx={circObject.rx}
        ry={circObject.ry}
        fill="none"
        stroke={
          props.highlights.includes(cdukeyName) || props.highlights.includes(circObject.cduKeyName) ? "yellow" : ""
        }
        pointerEvents="all"
        id={cdukeyName}
        onClick={() => {
          props.assignFmsKeypad(circObject.cduKeyName);
        }}
      />
    );
  });
};

export default CduCircleButtons;
