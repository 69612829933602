import React, { useContext } from "react";
import { AbstractTooltip as Tooltip } from "../../ToolTip/ToolTip";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../../contexts/ObjectsProvider";
import { IPageContext, PageContext } from "../../../routes/builderContexts";
import _ from "lodash";

const SendToBack = (props: any) => {
  const { selectedObjects } = useObjectsState();
  const objectsDispatch = useObjectsDispatch();
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  return (
    <>
      <hr className="wysiwyg-toolbar-divider" />
      <button
        className="wysiwyg-toolbar-btn"
        id="send-to-back"
        onClick={() => {
          if (props.val) {
            const updatedManifest: any = _.cloneDeep(pageContext.pageManifest);
            let annotations = updatedManifest.annotations;
            // check if the targeted annotation is part of annotations or refAnnotations
            if (!annotations?.some(item => item.objectId === props.val.objectId)) {
              annotations = updatedManifest.refAnnotations;
            }
            annotations.find((item: any) => item.objectId === props.val.objectId).zIndex = 12;
            for (let i = 0; i < annotations.length; i++) {
              if (annotations[i].objectId !== props.val.objectId) {
                annotations[i].zIndex += 1;
              }
            }
            pageContext.updatePageManifest(updatedManifest);
          } else {
            objectsDispatch({
              type: ObjectActionsType.SET_Z_INDEX,
              payload: { objectId: selectedObjects[0].objectId, zIndex: 0 },
            });
          }
        }}
        data-tooltip-id="SendBack"
      >
        <Tooltip id="SendBack" />
        <span className="icon-send-to-back"></span>
      </button>
    </>
  );
};

export default SendToBack;
