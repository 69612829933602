import React, { PropsWithChildren } from "react";
export interface MiscUIAction {
  type:
    | "TOGGLE_HSEC"
    | "TOGGLE_COPYING"
    | "TOGGLE_QUILL_REFRESH"
    | "TOGGLE_EDITOR_TYPE"
    | "TOGGLE_SNAP_TO_GRID"
    | "OBJECT_LOCKED"
    | "SEEKING_ON_TIMELINE"
    | "SET_SELECTED_MASK_ID";
  payload?: any;
}
const MiscUIState = React.createContext<typeof initialState | null>(null);
const MiscUIDispatch = React.createContext<React.Dispatch<MiscUIAction> | null>(null);

const initialState = {
  hotSpotEditorControlsVisible: true,
  isCopying: false,
  quillRefreshToggle: false,
  editorTypeToggle: true,
  snapToGrid: true,
  objectLocked: false,
  seekingOnTimeline: false,
  selectedMask: [], // list support for multiple selection
};

const miscUIReducer = (state: typeof initialState, action: MiscUIAction) => {
  switch (action.type) {
    case "TOGGLE_HSEC":
      return {
        ...state,
        hotSpotEditorControlsVisible: !state.hotSpotEditorControlsVisible,
      };
    case "TOGGLE_COPYING":
      return { ...state, isCopying: !state.isCopying };
    case "TOGGLE_QUILL_REFRESH":
      return { ...state, quillRefreshToggle: !state.quillRefreshToggle };
    case "TOGGLE_EDITOR_TYPE":
      return { ...state, editorTypeToggle: !state.editorTypeToggle };
    case "TOGGLE_SNAP_TO_GRID":
      return { ...state, snapToGrid: !state.snapToGrid };
    case "OBJECT_LOCKED":
      return { ...state, objectLocked: action.payload };
    case "SEEKING_ON_TIMELINE":
      return { ...state, seekingOnTimeline: action.payload };
    case "SET_SELECTED_MASK_ID":
      if (action.payload === "") return { ...state, selectedMask: [] };
      // TODO add logic for multiple selection
      return { ...state, selectedMask: [action.payload] };
    default:
      return state;
  }
};

export interface MiscUIProviderProps {
  children: React.ReactNode;
}
const MiscUIProvider = ({ children }: PropsWithChildren<MiscUIProviderProps>) => {
  const [state, reducer] = React.useReducer(miscUIReducer, initialState);

  return (
    <MiscUIState.Provider value={state}>
      <MiscUIDispatch.Provider value={reducer}>{children}</MiscUIDispatch.Provider>
    </MiscUIState.Provider>
  );
};

const useMiscUI = () => {
  const ctx = React.useContext(MiscUIState);
  const ctx2 = React.useContext(MiscUIDispatch);
  if (!ctx || !ctx2) {
    throw new Error("must wrap in MiscUIProvider");
  }
  return [ctx, ctx2] as [typeof ctx, typeof ctx2];
};
// const useMiscUIState = () => {
//   const ctx = React.useContext(MiscUIState);
//   if (!ctx) {
//     throw new Error('must wrap in MiscUIProvider');
//   }
//   return ctx;
// }

// const useMiscUIDispatch = () => {
//    const ctx = React.useContext(MiscUIDispatch);
//   if (!ctx) {
//     throw new Error('must wrap in MiscUIProvider');
//   }
//   return ctx;
// }

export { MiscUIProvider, useMiscUI };
