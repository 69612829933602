import React from "react";
import { BasePageTextBlockVersion } from "../../../utils/Versioning/basePagesVersioning";
import { getFontsFromVersion } from "../../Annotation/AnnotationTextMenu/constants";
// import {fontSizes as sizes} from '../../Annotation/AnnotationTextMenu/constants';

type FontSizeType = {
  fontSizes?: Array<string>;
  version?: BasePageTextBlockVersion | string;
};

const FontSizeButton = (props: FontSizeType) => {
  const fontSizes: string[] = getFontsFromVersion(props.version);
  //please let austin know if the names need to be modified (not added)
  const sizeMap = {
    [fontSizes[0]]: "tiny",
    [fontSizes[1]]: "tiny2",
    [fontSizes[2]]: "small",
    [fontSizes[3]]: "small2",
    [fontSizes[4]]: "small3",
    [fontSizes[5]]: "normal",
    [fontSizes[6]]: "normal2",
    [fontSizes[7]]: "normal3",
    [fontSizes[8]]: "large",
    [fontSizes[9]]: "huge",
  };

  return (
    <select className="ql-size wysiwyg-toolbar-btn">
      {fontSizes.map((size, i) => {
        return (
          <option key={size + i + "sd"} value={fontSizes[i]}>
            {sizeMap[size]}
          </option>
        );
      })}
    </select>
  );
};

export default FontSizeButton;
