import React, { useEffect, useState, useContext } from "react";
import { IFmsContext, FmsContext } from "../../Interfaces/fmsContext";

import { successMatchProps } from "../../Interfaces/successCriteriaInterfaces";
//import deleteButton from "../../../../assets/icons/ld-remove-success-criteria-summary-icon.png";
import addButton from "../../../../assets/icons/Add-gray.svg";
// import { IPageContext, PageContext } from "../../../../routes/builderContexts";
// import { IFmsPageManifest } from "../../Interfaces/IFmsPageManifest";
import removeIcon from "../../../../assets/icons/FMSIcons/Remove-gray.svg";
import CriteriaKeyButton from "./CriteriaKeyButton";
import "./successCriteriaPage.css";
import lodash from "lodash";
import { AbstractTooltip as Tooltip } from "../../../../components/ToolTip/ToolTip";
//import blankFunctionKeys from "../../assets/blankFunctionKeyes.json";
// import { IPageContext, PageContext } from "../../../../routes/builderContexts";
// import { IFmsPageManifest } from "../../Interfaces/IFmsPageManifest";
// import deleteButton from "../../../../assets/icons/ld-remove-success-criteria-summary-icon.png";

const SuccessMatchForm: React.FC<successMatchProps> = (props: successMatchProps) => {
  // const pagesContext: IPageContext = useContext<IPageContext>(PageContext);
  const successMatchRef = React.useRef<HTMLInputElement>(null);
  // const pageManifest: IFmsPageManifest = pagesContext.pageManifest as IFmsPageManifest;
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const cdu = props.pageManifest.cdu;
  const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);

  useEffect(() => {
    if (props.selectedKey !== "") {
      appendSelectedKey();
      props.autoEnableKey();
    }
  }, [props.selectedKey]);

  const setSuccess = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    //work in progresss
    //needs to take the index form the second part of a name split
    console.info(props.successMatch);
    const str: string = event.target.value.toUpperCase();
    const lastChar = str.charAt(str.length - 1);
    const targetName = event.target.name;
    const successMatch = props.successMatch.toString();
    event.preventDefault();
    props.matchSuccess(event.target.name, event.target.value.toUpperCase(), index);
    if (successMatch.length > str.length) {
      props.removeHighlight(str);
    } else {
      props.selectedKeyHighlight(lastChar);
    }
  };

  const appendSelectedKey = () => {
    //let isNumber = /^[0-9+-]+$/.test(props.displaySuccessMatch.join());

    if (props.criteriaType === "textMatch") {
      if (
        props.selectedKey.length > 1 &&
        (props.successMatch.length === 0 || props.successMatch[0] === "") &&
        props.selectedKey !== "SpaceKey"
      ) {
        const newSequence = props.selectedKey + props.displaySuccessMatch.join();
        //props.matchSuccess(newSequence);
        props.changeToKeyPress(newSequence);
      } else if (props.selectedKey !== "+" && props.selectedKey.length === 1) {
        const newSequence = props.displaySuccessMatch[selectedIndex]
          ? props.displaySuccessMatch[selectedIndex] + props.selectedKey
          : props.selectedKey;
        props.matchSuccess(`successMatch`, newSequence, selectedIndex);
      } else if (props.selectedKey === "+" && !cdu.includes("f100")) {
        const lastChar: string =
          props.displaySuccessMatch.length > 0
            ? props.displaySuccessMatch[selectedIndex].charAt(props.displaySuccessMatch[selectedIndex].length - 1)
            : "";
        let matchVal: string = lodash.cloneDeep(props.displaySuccessMatch[selectedIndex]);
        if (lastChar === "-") {
          const matchCharArray = matchVal.split("");
          matchCharArray.length ? (matchCharArray[matchVal.length - 1] = "+") : matchCharArray.push("+");
          matchVal = matchCharArray.join("");
          props.matchSuccess(`successMatch`, matchVal, selectedIndex);
        } else if (lastChar === "+") {
          const matchCharArray = matchVal.split("");
          matchCharArray[matchVal.length - 1] = cdu.includes("400") ? "." : "-";
          matchVal = matchCharArray.join("");
          props.matchSuccess(`successMatch`, matchVal, selectedIndex);
        } else if (lastChar === ".") {
          const matchCharArray = matchVal.split("");
          matchCharArray[matchVal.length - 1] = "-";
          matchVal = matchCharArray.join("");
          props.matchSuccess(`successMatch`, matchVal, selectedIndex);
        } else {
          const newSequence = props.displaySuccessMatch[selectedIndex]
            ? props.displaySuccessMatch[selectedIndex] + "-"
            : "-";
          props.matchSuccess(`successMatch`, newSequence, selectedIndex);
        }
      } else if (props.selectedKey === "+" && cdu.includes("f100")) {
        let newSequence = props.displaySuccessMatch[selectedIndex] + props.selectedKey;
        props.matchSuccess(`successMatch`, newSequence, selectedIndex);
      } else if (props.selectedKey.length === 1) {
        const newSequence = props.selectedKey + props.displaySuccessMatch[selectedIndex];
        props.matchSuccess(`successMatch`, newSequence, selectedIndex);
      }
    } else {
      if (props.selectedKey.length > 1) {
        const newSequence = props.successMatch;
        props.selectedKey.includes("LSK") ? newSequence.push(props.selectedKey) : newSequence.push(props.selectedKey);
        props.matchSuccess(`successMatch`, newSequence.join(), selectedIndex);
      }
    }

    //if the key has not already been given an action apply default "enabled key" action perameters
    // if (pageManifest.actions[props.selectedKey] === undefined) { // prevents designer crash on click of ovfy button
    //
    //     pageManifest.actions[props.selectedKey] = JSON.parse(JSON.stringify([...blankFunctionKeys]));
    //     pagesContext.updatePageManifest(pageManifest);
    // }else if (pageManifest.actions[props.selectedKey].length === 0) {
    //
    //     pageManifest.actions[props.selectedKey] = JSON.parse(JSON.stringify([...blankFunctionKeys]));
    //     pagesContext.updatePageManifest(pageManifest);
    // }
    // }else{
    //
    // }
  };

  return (
    <>
      {props.criteriaType === "" ? (
        <></>
      ) : props.criteriaType === "textMatch" ? (
        <>
          <label style={{ width: "100%" }}>
            {props.successMatch.length ? "Scratchpad Value: " : "Scratchpad Value: "}
            {props.successMatch.map((entry: string, index: number) => (
              <span className="key-match-span scratchpad-span">
                <input
                  key={index}
                  name={`successMatch`}
                  className="spacedInput successInput"
                  type="text"
                  placeholder=""
                  value={props.displaySuccessMatch[index]}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSuccess(event, index);
                  }}
                  onFocus={() => {
                    setSelectedIndex(index);
                  }}
                ></input>
                {index !== 0 || (index === 0 && props.successMatch.length > 1) ? (
                  <figure
                    className="remove-key-button scratchpad-remove-key"
                    onClick={() => {
                      props.removeScratchpadEntry(index);
                    }}
                  >
                    <img className="scratchpad-remove-button" src={removeIcon} />
                  </figure>
                ) : (
                  <></>
                )}
              </span>
            ))}
            {props.displaySuccessMatch.length < 5 ? (
              <figure
                className="addScratchpadEntry-figure"
                onClick={props.addOptionalScratchpadCriteriaEntry}
                data-tooltip-id="add fms scratchpad"
              >
                <Tooltip id="add fms scratchpad" />
                <img src={addButton} className="addScratchpadEntry" />
              </figure>
            ) : (
              <></>
            )}
            <button onClick={props.clearSuccess} className="scratchpad-clear-button shaded">
              Clear All
            </button>
          </label>
        </>
      ) : (
        <>
          <div
            style={{
              display: "contents",
              width: "max-content",
              height: "min-content",
              alignItems: "center",
            }}
          >
            <span>{props.successMatch.length ? "Key Configuration: " : "Key Configuration: "}</span>
            <input
              ref={successMatchRef}
              name="successMatch"
              type="text"
              placeholder=""
              className="successInput"
              readOnly={true}
              value={props.displaySuccessMatch}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSuccess(event, 0);
              }}
              hidden
              onBlur={() => {
                props.cleanupBlankMatchSuccess("successMatch", props.successMatch);
              }}
            ></input>

            {props.successMatch.map((keyName: string, index: number) => (
              <CriteriaKeyButton
                key={index}
                index={index}
                keyName={keyName}
                openKeyMenu={props.openKeyMenu}
                clearKeyMatch={props.clearKeyMatch}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
};
export default SuccessMatchForm;
