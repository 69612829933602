import React from "react";
import { Interweave } from "interweave";
import { BaseWYSIWYGType } from "../BaseWYSIWYG";

const TO = (props: BaseWYSIWYGType) => {
  const { pageManifest } = props;

  return (
    <div className="basePageTO">
      <div className="block text-only">
        <Interweave content={pageManifest.textBlock[0].text} />
      </div>
    </div>
  );
};

export default TO;
