import React, { useRef } from "react";
// import HighlightAnnotation from "../hooks/HighlightAnnotation";
import { SVGAnnotationProps } from "../index";
import { useObjectsState } from "../../../contexts/ObjectsProvider";
import { HighlightAnnotation } from "../HighlightAnnotation";

export function SVGCircledNumber({
  x,
  y,
  objectId,
  onMouseDown,
  isDisplayed,
  rotate,
  height,
  width,
  zIndex,
  opacity,
  borderColor,
  strokeWidth,
  backgroundColor,
  fontColor,
}: SVGAnnotationProps & { fontColor?: string }) {
  const circNumRef: React.RefObject<SVGSVGElement> = useRef(null);
  const { annotations } = useObjectsState();
  const circleLetterAnnotations = annotations.filter((annotation: any) => annotation.type === "circledNumber");
  const index = circleLetterAnnotations.findIndex((annotation: any) => annotation.objectId === objectId);
  return (
    <svg
      viewBox={`0 0 100 100`}
      data-objectid={objectId}
      id={`circledNumber-${objectId}`}
      tabIndex={1}
      name="circledNumber"
      className="annotation"
      onMouseDown={(e: React.MouseEvent<SVGSVGElement>) => {
        onMouseDown(e, objectId);
      }}
      preserveAspectRatio="xMinYMin meet"
      style={{
        transform: `translate(${x}px, ${y}px) rotate(${rotate ?? 0}deg)`,
        width: `${width}px`,
        height: `${height}px`,
        zIndex: zIndex,
        opacity: isDisplayed ? opacity : 0.5,
        filter: isDisplayed ? "none" : "grayScale(100%)",
      }}
      ref={circNumRef}
      opacity={opacity}
    >
      <HighlightAnnotation objectId={objectId} />
      <circle
        cx="50"
        cy="50"
        r="47"
        stroke={`${borderColor ? borderColor : "transparent"}`}
        fill={`${backgroundColor ? backgroundColor : "#4B4F58"}`}
        strokeWidth={`${strokeWidth || "3"}`}
      ></circle>
      <text
        className="step-text"
        x="50"
        y="50"
        dy="26.5"
        textAnchor="middle"
        fill={`${fontColor ? fontColor : "white"}`}
      >
        {index + 1}
      </text>
    </svg>
  );
}
