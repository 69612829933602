import React, { useEffect } from "react";
import { ReactComponent as UploadIcon } from "../../../assets/icons/asset-uploader-upload-new-section-icon.svg";
//asset-uploader-upload-new-section-icon
import "./uploaderStyles.css";
interface dragContainerProps {
  //files: any;
  dragDiv: React.RefObject<HTMLDivElement>;
  multiFile: React.RefObject<HTMLInputElement>;
  dragEnter: (event: React.DragEvent<HTMLInputElement>) => void;
  dragLeave: (event: React.DragEvent<HTMLInputElement>) => void;
  dragOver: (event: React.DragEvent<HTMLInputElement>) => void;
  drop: (event: React.DragEvent<HTMLInputElement>) => void;
  changeEffectUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setClickedFileUpload: (value: boolean) => void;
  setUploadsProcessing: (value: boolean) => void;
  newFiles: any;
  setNewFiles: (value: any) => void;
  dragging: boolean;
  fileString: string;
}

const DragContainer = (props: dragContainerProps) => {
  useEffect(() => {}, [props.newFiles]);
  // const openFile = () => {
  //     if(props.multiFile.current){
  //         props.multiFile.current.click();
  //     }
  // }
  const inputClick = () => {
    props.setClickedFileUpload(true);
  };

  return (
    <div className="dragInput" style={{ opacity: props.dragging ? "50%" : "100%" }}>
      <UploadIcon className="z-1 upload-icon" />
      <p className="z-1">
        Drop file here to upload or <u>browse files</u>
      </p>

      <input
        style={{ opacity: "0", width: "100%", height: "100%" }}
        type="file"
        multiple={true}
        ref={props.multiFile}
        onDragEnter={props.dragEnter}
        onDragLeave={props.dragLeave}
        onDrop={props.drop}
        onClick={inputClick}
        onChange={props.changeEffectUpload}
        accept={props.fileString}
        className="z-2 asset-manager-input"
      ></input>
    </div>
  );
};

export default DragContainer;
