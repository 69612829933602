import React from "react";
import { ReactComponent as ExitIcon } from "../../../../assets/icons/Common/remove-tag-icon.svg";
import { Tag } from "../state";
interface AssetTagPairProps {
  kvp: Tag;
  canEdit: boolean;
  canRemove: boolean;
  removeTag: (id: number) => void;
  editTag: (id: number, field: "tagKey" | "tagValue", content: string) => void;
}

export const AssetTagPair = ({ kvp, canEdit, canRemove, removeTag, editTag }: AssetTagPairProps) => {
  const { tagKey, tagValue, id } = kvp;
  return (
    <div className="assetManager-metadata-editor-sidebar-tagdata-tag-input-added-tags">
      <div>
        <div className={`metadata-editor-sidebar-tagdata-tag-input ${!canEdit ? "disabled" : "enabled"}`}>
          <p>Tag Name</p>
          <textarea
            disabled={!canEdit}
            name="tagKey"
            key={"key" + id}
            value={tagKey}
            style={{
              color: tagKey === "" ? "red" : "",
            }}
            onChange={(e) => {
              editTag(kvp.id, "tagKey", e.target.value);
            }}
            maxLength={50}
            placeholder="Enter key"
          ></textarea>
        </div>
        <div className={`metadata-editor-sidebar-tagdata-tag-input ${!canEdit ? "disabled" : "enabled"}`}>
          <p>Description</p>
          <textarea
            disabled={!canEdit}
            name="tagValue"
            value={tagValue}
            key={"value" + id}
            style={{
              color: tagValue === "" ? "red" : "",
            }}
            onChange={(e) => {
              editTag(kvp.id, "tagValue", e.target.value);
            }}
            maxLength={125}
            placeholder="Enter value"
          ></textarea>
        </div>
      </div>

      {(canRemove || canEdit) && (
        <div className="metadata-editor-sidebar-tagdata-tag-input-delete-button">
          <ExitIcon className="" onClick={() => removeTag(kvp.id)} />
        </div>
      )}
    </div>
  );
};
