import React, { useEffect, useState, Suspense } from "react";
import { connect } from "react-redux";
import "../CPaT3d_Player.css";
import Controls from "./controls";
import SkyBox from "./skybox";
import AtGate from "./atGate";
import Cockpit from "./cockpit";
import Chair from "./chair";
import HSR from "./rigger";
import IGA from "../../../components/IGA/IGA";
import StepForm from "./stepForm";
import Gremlin from "./gremlin";
import { Canvas } from "@react-three/fiber";
import { setdisplayStyle, setCurtain, setStepType } from "../../../store/actions/actions";
import { blankStep } from "../../../components/IGA/helpers/initialSteps";

import SceneLights from "../../Generic3DFiles/lights";

const PageScene = (props) => {
  const [ActiveHotSpot, setActiveHotSpot] = useState(props.ActiveHotSpot);
  const [hsData, setHsData] = useState(props.cpHotSpot);

  useEffect(() => {
    setHsData(props.cpHotSpot);
  }, [props.cpHotSpot]);

  // useEffect(() => {
  //     setHsData(props.currentHotSpot)
  //
  // }, [props.currentHotSpot]);

  const checkActiveHotSpot = (e) => {
    setActiveHotSpot(e);
    props.setdisplayStyle("block");
  };

  const handleIGAClose = () => {
    setActiveHotSpot(null);
    props.setdisplayStyle("");
  };

  const StepSystem = () => {
    if (props.displayStyle === "block" && props.passedIGSObject === "legacy") {
      return (
        <>
          <div className="pagePlayerLeftOverlay">
            <StepForm />
          </div>
        </>
      );
    } else {
      return <></>;
    }
  };

  const displayIGA = () => {
    let IGAObject = props.currentIGA;
    if (!IGAObject) {
      IGAObject = blankStep;
    }
    if (props.displayStyle === "block" && props.passedIGSObject !== "legacy") {
      return (
        <div className="absolute-container">
          <div id="procedures-iga-container">
            <IGA
              currentIGA={IGAObject}
              handleClose={handleIGAClose}
              handleSave={props.handleSave}
              assetManagerProps={props.assetManagerProps}
            />
            <div></div>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const [sceneWidth, setSceneWidth] = useState(99);
  const [skyLoaded, setSkyLoaded] = useState(false);
  const [modelLoaded, setModelLoaded] = useState(false);

  const finishedLoadingModel = () => {
    setModelLoaded(true);
  };
  const finishedLoadingSky = () => {
    setSkyLoaded(true);
  };
  const finishedLoading = () => {
    props.setCurtain(false);
    setSceneWidth(100);
  };

  useEffect(() => {
    if (skyLoaded && modelLoaded) {
      finishedLoading();
    }
    return () => {
      props.setCurtain(true);
    };
  }, [skyLoaded, modelLoaded]);

  useEffect(() => {}, []);

  const isBrowser = typeof window !== "undefined";
  const backgroundStyle =
    props.displayStyle === "block" ? "linear-gradient(27deg, #2E4460 5%, #BBC1CA 100%, #FAFAFA 100%)" : "inherit";

  return (
    <>
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: `${sceneWidth}%`,
          backgroundColor: `${props.displayStyle === "block" ? "#283D55" : "inherit"}`,
          background: backgroundStyle,
        }}
      >
        {isBrowser && (
          <Canvas
            onCreated={({ gl }) => {
              gl.useLegacyLights = true;
            }}
          >
            <pointLight position={[0, 250, 155]} />
            <Controls role={props.role} />
            {props.displayStyle !== "block" && (
              <Suspense>
                <Cockpit modelPath={props.modelPath} callBack={finishedLoadingModel} />
                <Chair modelPath={props.modelPath} role={props.role} />
                <HSR
                  hsData={hsData}
                  checkActiveHotSpot={checkActiveHotSpot}
                  ActiveHotSpot={ActiveHotSpot}
                  modelSet={props.modelPath}
                />
                {props.condition === "atGate" && <AtGate callBack={finishedLoadingSky} />}
                {props.condition === "InAir" && <SkyBox callBack={finishedLoadingSky} />}
              </Suspense>
            )}
          </Canvas>
        )}
        <Gremlin ActiveHotSpot={ActiveHotSpot} />
        <StepSystem />
      </div>
      {displayIGA()}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    stepSystemDisplay: state.Current3dPage.stepSystemDisplay,
    displayStyle: state.Current3dPage.displayStyle,
    modelPath: state.Current3dPage.model,
    role: state.Current3dPage.Manifest.role,
    condition: state.Current3dPage.condition,
    // currentHotSpot: state.Current3dPage.CustomeHotSpot,
    ActiveHotSpot: state.Current3dPage.Manifest.ActiveHotSpot,
  };
};

export default connect(mapStateToProps, {
  setdisplayStyle,
  setCurtain,
  setStepType,
})(PageScene);
