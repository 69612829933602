export const ADD_PAGE = "ADD_PAGE"
export const PAGE_COMPLETE = "PAGE_COMPLETE"
export const EDIT_PAGE = "EDIT_PAGE"
export const EXPORT_DATA = "EXPORT_DATA"
export const SET_QUESTION = "SET_QUESTION"
export const SET_QUESTION_AUDIO = "SET_QUESTION_AUDIO"
export const SET_QUESTION_NARRATOR = "SET_QUESTION_NARRATOR"
export const SET_CORRECT_ANSWER = "SET_CORRECT_ANSWER"
export const ADD_ANSWER = "ADD_ANSWER"
export const REMOVE_ANSWER = "REMOVE_ANSWER"
export const SET_PAGE = "SET_PAGE"
export const SET_IMAGE_SRC = "SET_IMAGE_SRC"
export const SET_PROMPT_CORRECT_TXT = "SET_PROMPT_CORRECT_TXT"
export const SET_PROMPT_CORRECT_AUDIO = "SET_PROMPT_CORRECT_AUDIO"
export const SET_PROMPT_CORRECT_NARRATOR = "SET_PROMPT_CORRECT_NARRATOR"
export const SET_PROMPT_INCORRECT_TXT = "SET_PROMPT_INCORRECT_TXT"
export const SET_PROMPT_INCORRECT_AUDIO = "SET_PROMPT_INCORRECT_AUDIO"
export const SET_PROMPT_INCORRECT_NARRATOR = "SET_PROMPT_INCORRECT_NARRATOR"
export const SET_PROMPT_ASSISTANCE = "SET_PROMPT_ASSISTANCE"

//export const PAGE_NOTCOMPLETE = "PAGE_NOTCOMPLETE"
export const INIT_LESSONMANIFEST = "INIT_LESSONMANIFEST"
export const INIT_PAGEMANIFESTS = "INIT_PAGEMANIFESTS"
export const SET_CURRENTPAGE = "SET_CURRENTPAGE"
export const SET_PAGESTATES = "SET_PAGESTATES"
export const PAGE_TEXT = "PAGE_TEXT"
export const SET_TEXT = "SET_TEXT"
export const SET_NARRATOR_SIZE = "SET_NARRATOR_SIZE"
// export const SET_PAGE_ATTEMPTS = "SET_PAGE_ATTEMPTS"
export const SET_PAGE_PASSFAIL = "SET_PAGE_PASSFAIL"
// export const SAVE_PAGE_COMPLETE = "SAVE_PAGE_COMPLETE"
export const SET_LESSON_MODES = "SET_LESSON_MODES"
export const SET_RETURN_TO_PAGE = "SET_RETURN_TO_PAGE"

//audio
export const SET_AUDIO_SRC = "SET_AUDIO_SRC"
export const SET_AUDIO_DURATION = "SET_AUDIO_DURATION"
export const SET_AUDIO_TIME = "SET_AUDIO_TIME"
export const SET_AUDIO_CURRENT_TIME = "SET_AUDIO_CURRENT_TIME"
export const FINISH_AUDIO = "FINISH_AUDIO"
export const START_AUDIO = "START_AUDIO"
export const PLAY_AUDIO = "PLAY_AUDIO"
export const PAUSE_AUDIO = "PAUSE_AUDIO"
export const TOGGLE_AUDIO_MUTE = "TOGGLE_AUDIO_MUTE"

//quiz

export const SET_SUBMIT = "SET_SUBMIT"
export const SET_PROMPT = "SET_PROMPT"

//fms
export const CDU_KEY_PRESS = "CDU_KEY_PRESS"
//3d
export const SET_MOVE = "SET_MOVE"
export const SET_MOVE_TARGET = "SET_MOVE_TARGET"
export const SHOW_MODAL = "SHOW_MODAL"
export const HIDE_MODAL = "HIDE_MODAL"
export const SET_STEP_GRAPHIC = "SET_STEP_GRAPHIC"
export const PAGE3D_TEXT = "PAGE3D_TEXT"
export const SET_STEP_RESPONSE_1 = "SET_STEP_RESPONSE_1"
export const SET_STEP_RESPONSE_2 = "SET_STEP_RESPONSE_2"
export const SET_STEP_RESPONSE_3 = "SET_STEP_RESPONSE_3"
export const SET_STEP_COMPLETE = "SET_STEP_COMPLETE"
export const HIDE_WRONG_STEP = "HIDE_WRONG_STEP"
export const DISPLAY_STEP_SYSTEM = "DISPLAY_STEP_SYSTEM"
export const DISPLAY_CHOOSE_STEP = "DISPLAY_CHOOSE_STEP"
export const DISPLAY_ADD_STEP = "DISPLAY_ADD_STEP"
export const DISPLAY_STEP_RESPONSE_PANEL = "DISPLAY_STEP_RESPONSE_PANEL"
export const DISPLAY_STYLE = "DISPLAY_STYLE"
export const STORED_CURRENT_STEP = "STORED_CURRENT_STEP"
export const SET_MODEL = "SET_MODEL"
export const SET_CURTAIN = "SET_CURTAIN"
export const SET_HOT_SPOT = "SET_HOT_SPOT"
export const SET_WA_HOT_SPOT = "SET_WA_HOT_SPOT"
export const SET_ROLE = "SET_ROLE"
export const SET_CUSTOM = "SET_CUSTOM"
export const SET_CONDITION = "SET_CONDITION"
export const SET_MANIFEST = "SET_MANIFEST"
export const SET_STEP_BUILDER_STEP = "SET_STEP_BUILDER_STEP"
export const SET_STEP_OBJECT = "SET_STEP_OBJECT"
export const SET_STEP_HOT_SPOT = "SET_STEP_HOT_SPOT"
export const SET_STEP_IMAGE = "SET_STEP_IMAGE"
export const SET_STEP_TYPE = "SET_STEP_TYPE"
export const SET_IS_TERMINAL = "SET_IS_TERMINAL"
export const SET_TASK_DESC = "SET_TASK_DESC"
export const SET_QUESTION2 = "SET_QUESTION2"
export const SET_CHOICES = "SET_CHOICES"
export const RESET_STEP_IMAGES = "RESET_STEP_IMAGES"
export const SET_IGSO = "SET_IGSO"
export const SET_STEP_NUMBER = "SET_STEP_NUMBER"
export const SET_ALL_STEP_IMAGES = "SET_ALL_STEP_IMAGES"
export const SET_IS_IGS = "SET_IS_IGS"
export const WA_BUILD_GRID = "WA_BUILD_GRID"

export const SET_HS_LIST = "SET_HS_LIST"

export const SET_CURRENT_HSDATA= "SET_CURRENT_HSDATA"
export const SET_HSLISTER_OPEN="SET_HSLISTER_OPEN"