import React from "react";
import { Modal } from "react-bootstrap";

interface FailedToSaveProps {
  failedToSave: boolean;
  setFailedToSave(failedToSave: boolean): void;
  saveLesson(): void;
}

const FailedToSave = (props: FailedToSaveProps) => {
  const handleClick = () => {
    props.setFailedToSave(!props.failedToSave);
    props.saveLesson();
  };
  const handleDismiss = () => {
    props.setFailedToSave(!props.failedToSave);
  };
  return (
    <Modal show={props.failedToSave} onHide={() => {}}>
      <Modal.Body>
        <h2>Save Failed Check your network connection</h2>
        <button onClick={handleClick}>Try Again</button>
        <button onClick={handleDismiss}>Dismiss</button>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(FailedToSave);
