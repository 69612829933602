import React, { useState, useContext, useEffect } from "react";
import "./unicodelegend.css";
import { IFmsContext, FmsContext } from "../../../src/pageTypes/FMS_Player/Interfaces/fmsContext";
import { IPageContext, PageContext } from "../../../src/routes/builderContexts";
import { IFmsPageManifest } from "../../../src/pageTypes/FMS_Player/Interfaces/IFmsPageManifest";
import { fmsPageManifestAssetCollector } from "../../pageTypes/FMS_Player/fmsDesigner";

const UnicodeLegend = () => {
  // const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);
  const pagesContext: IPageContext = useContext<IPageContext>(PageContext);
  const pageManifest: IFmsPageManifest = pagesContext.pageManifest as IFmsPageManifest;

  const [unicodeLegend, setLegend] = useState({ symbol: [""], code: [""] });

  // const [unicodeLegendExtended, setLegendExtended] = useState({
  // symbol: ["\u2190", "\u2192", "\u2193", "\u2191", "\u2610","\u25AF", "\u00B0", "\u25b3", "\uD83D\uDDD8", "\u21BA", "\u21BB", "\u25B6", "\u25C0", "\u25C0\u25B6", "\u25FC", "\u29BE", "\u29BF"],
  // code: ["u2190", "u2192", "u2193", "u2191", "u2610","u25AF", "u00B0", "u25b3", "uD83DuDDD8", "u21BA", "u21BB", "u25B6", "u25C0", "u25C0u25B6", "u25FC", "u29BE", "u29BF"],
  // });
  useEffect(() => {
    if (pageManifest.cdu.includes("e19") || pageManifest.cdu.includes("e17")) {
      setLegend({
        symbol: [
          "\u2190",
          "\u2192",
          "\u2193",
          "\u2191",
          "\u2610",
          "\u25AF",
          "\u00B0",
          "\u25b3",
          "\uD83D\uDDD8",
          "\u21BA",
          "\u21BB",
          "\u25B8",
          "\u25C2",
          "\u25C2\u25B8",
          "\u25FC",
          "\u29BE",
          "\u29BF",
          "\u2B58",
        ],
        code: [
          "u2190",
          "u2192",
          "u2193",
          "u2191",
          "u2610",
          "u25AF",
          "u00B0",
          "u25b3",
          "uD83DuDDD8",
          "u21BA",
          "u21BB",
          "u25B8",
          "u25C2",
          "u25C2u25B8",
          "u25FC",
          "u29BE",
          "u29BF",
          "u2B58",
        ],
      });
    } else if (pageManifest.cdu.includes("145")) {
      setLegend({
        symbol: [
          "\u2190",
          "\u2192",
          "\u2193",
          "\u2191",
          "\u2610",
          "\u25AF",
          "\u00B0",
          "\u25b3",
          "\uD83D\uDDD8",
          "\u21BA",
          "\u21BB",
          "\u25B8",
          "\u25C2",
          "\u25C2\u25B8",
          "\u25FC",
          "\u29BE",
          "\u29BF",
          "\u2B58",
        ],
        code: [
          "u2190",
          "u2192",
          "u2193",
          "u2191",
          "u2610",
          "u25AF",
          "u00B0",
          "u25b3",
          "uD83DuDDD8",
          "u21BA",
          "u21BB",
          "u25B8",
          "u25C2",
          "u25C2u25B8",
          "u25FC",
          "u29BE",
          "u29BF",
          "u2B58",
        ],
      });
    } else if (pageManifest.cdu.includes("220")) {
      setLegend({
        symbol: ["\u2190", "\u2192", "\u2193", "\u2191", "\u2610", "\u25AF", "\u00B0", "\u25b3", "\u2714"],
        code: ["u2190", "u2192", "u2193", "u2191", "u2610", "u25AF", "u00B0", "u25b3", "u2714"],
      });
    } else {
      setLegend({
        symbol: ["\u2190", "\u2192", "\u2193", "\u2191", "\u2610", "\u25AF", "\u00B0", "\u25b3", "\u2588", "\u259D"],
        code: ["u2190", "u2192", "u2193", "u2191", "u2610", "u25AF", "u00B0", "u25b3", "U+2588", "U+259D"],
      });
    }
  }, []);

  const displaySymbols = () => {
    return (
      <React.Fragment>
        {unicodeLegend.symbol.map((character: string, index: number) => {
          const subString1 = unicodeLegend.code[index].substring(0, 5);
          const substring2 = unicodeLegend.code[index].substring(5, 10);
          if (unicodeLegend.code[index].length > 5) {
          }
          return (
            <React.Fragment key={index}>
              {unicodeLegend.code[index].length > 5 ? (
                <div className="editor-text characterBox">
                  {character} &nbsp; = \{subString1}\{substring2}
                </div>
              ) : (
                <div className="editor-text characterBox">
                  {character} &nbsp; = \{unicodeLegend.code[index]}
                </div>
              )}
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  };

  return (
    <>
      <fieldset>
        <legend className="instructions">Commonly Used Symbols</legend>
        <div className="symbolHolder">{displaySymbols()}</div>
      </fieldset>
    </>
  );
};
export default UnicodeLegend;
