/* eslint-disable @typescript-eslint/no-unused-vars */
import { SVGProps, useMemo } from "react";
import { SmartObjectComponent, SmartObjectG, SmartObjectRect, SmartObjectSvgRendererProps } from "../";
import { useMetaVariableStore } from "../../../store";
import { getSmartObjectMetaVariableKey } from "../../../utils";
import { SVG_SMART_OBJECT_TYPES } from "../../..";
import { useSmartObjectStyles } from "../useSmartObjectStyles";

export function SmartObjectSvgImageTextRenderer({
  element,
  objectId,
  parentId,
  extraStyles,
}: SmartObjectSvgRendererProps) {
  const { children, TYPE, GROUP_ID, VALUE, HOTSPOT_ID, RATE, type, style, ...rest } = element;

  if (TYPE === SVG_SMART_OBJECT_TYPES.IMAGE_TEXT) {
    return <SmartObjectImageText element={element} objectId={objectId} />;
  }

  switch (type) {
    case "g": {
      return <SmartObjectG element={element} objectId={objectId} parentId={parentId} />;
    }
    case "rect": {
      return <SmartObjectRect element={element} objectId={objectId} extraStyles={extraStyles} />;
    }
    default: {
      console.warn("SmartObjectSvgImageTextRenderer: type not handled", type);
      return null;
    }
  }
}

export function SmartObjectImageText({ element, objectId }: SmartObjectComponent) {
  const {
    children,
    TYPE,
    GROUP_ID,
    VALUE,
    HOTSPOT_ID,
    RATE,
    FONT,
    OPTION_JUSTIFY,
    OPTION_STACK,
    type,
    style,
    ...rest
  } = element;
  const key = getSmartObjectMetaVariableKey(objectId, GROUP_ID);
  const value = useMetaVariableStore((s) => s.metaVariables[key]);
  const font = useMetaVariableStore((s) => s.metaVariablesData[FONT as string]);
  const styles = useSmartObjectStyles(element.style);
  const justifyRight = OPTION_JUSTIFY?.toLowerCase() === "right";
  const letters = typeof value === "string" ? (justifyRight ? value.split("").reverse() : value.split("")) : [];
  const stackElement = OPTION_STACK?.toLowerCase().replaceAll("'", "");

  const lettersWithoutStack = useMemo(() => {
    return letters.filter((letter) => letter !== stackElement);
  }, [letters]);

  const { x, y, width } = rest;

  if (!font || x === undefined || y === undefined) {
    return null;
  }

  return (
    <>
      {type === "rect" && <SmartObjectRect element={element} objectId={objectId} extraStyles={styles} />}

      {letters.map((letter, idx) => {
        const stack = letter === stackElement;
        let index = idx;

        if (stack) {
          index = index > 0 ? index - 1 : 0;
        }

        if (letters.length !== lettersWithoutStack.length) {
          const offset = letters.reduce((acc, val, currentIndex) => {
            if (stackElement === val && currentIndex < idx) {
              return acc + 1;
            }

            return acc;
          }, 0);

          index = index - offset;
        }

        return (
          <SmartObjectImageTextValue
            key={idx}
            value={letter}
            font={font.characters}
            index={index}
            x={Number(x)}
            y={Number(y)}
            width={Number(width)}
            justifyRight={justifyRight}
          />
        );
      })}
    </>
  );
}

export function SmartObjectImageTextValue({
  value,
  font,
  x,
  y,
  index,
  width,
  justifyRight = false,
}: {
  value: any;
  font: any;
  x: number;
  y: number;
  width: number;
  index: number;
  justifyRight?: boolean;
}) {
  const data = font[value] ?? font["UNDEFINED"] ?? null;
  const transform = data.transform;
  let scaleCssProperty = "";
  let scaleValue = 1;

  if (!data) return null;

  /**
   * Adobe Illustrator renders the SVG Font sometimes with different
   * transform properties, one of them being a scale transform property
   * which affects the size of the "font" (image).
   *
   * We want to change the translate to render the font in the correct place
   * but we want to keep the scale so the images get render in the correct width and height.
   */
  if (transform) {
    const properties = transform.split(" ");
    const scaleIndex = properties.findIndex((p: string) => p.includes("scale"));

    if (scaleIndex !== -1) {
      scaleCssProperty = properties[scaleIndex];
      scaleValue = Number(scaleCssProperty.split("(")[1].split(")")[0]);
    }
  }

  const currentIndex = index;

  const { FONT_CHAR, ...rest } = data;
  const digitWidth = Number(data.width ?? 0) * scaleValue;

  let newX = x + digitWidth * currentIndex;

  if (justifyRight) {
    newX = width + x - digitWidth * currentIndex - digitWidth;
  }

  const imageProps = rest as SVGProps<SVGImageElement> & { "xlink:href": string };

  return (
    <image
      {...imageProps}
      xlinkHref={imageProps["xlink:href"]}
      transform={`translate(${newX},${y}) ${scaleCssProperty}`}
    ></image>
  );
}
