import React, { useContext } from "react";
import { IPageContext, PageContext } from "../../../routes/builderContexts";
import ILessonPageDto from "../../../models/ILessonPageDto";

type ReviewSelect = {
  isDisabled: boolean;
  isLinkOn: boolean;
  handleChange: (event: any) => void;
  handleReviewPageSelect: (event: any) => void;
  listBasePages: () => JSX.Element[];
  lessonPages: ILessonPageDto[];
};

const QuizReviewSelect = (props: ReviewSelect): JSX.Element => {
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const { isDisabled, isLinkOn, handleChange, handleReviewPageSelect, listBasePages } = props;
  // function matchOrderToVersionId(pageVersionId: number): number {
  //   let x = (props.lessonPages.filter((el:ILessonPageDto, index:number) => {
  //     return el.pageVersionId === pageVersionId;
  //   })[0].order);
  //   return x
  // }
  return (
    <div className="pageListerActionButton review-page-action-button">
      <div style={{ display: "flex" }}>
        Review Link:
        <div className="toggle toggle-switch">
          <input
            type="checkbox"
            className="toggle-checkbox"
            id="toggle-switch"
            checked={isLinkOn}
            onChange={(e: any) => handleChange(e)}
            disabled={isDisabled}
          />
          <label htmlFor="toggle-switch" className="toggle-btn">
            <span className="toggle-feature" data-label-on="on" data-label-off="off"></span>
          </label>
        </div>
      </div>
      {isLinkOn && (
        <div className={`${!isLinkOn ? "hidden" : ""} `}>
          <label htmlFor="base-page-select" className="review-page-select-container">
            Select Page:
            <select
              name="base-page-select"
              id="base-page-select"
              value={pageContext.helperReference ? pageContext.helperReference : undefined}
              onChange={handleReviewPageSelect}
            >
              {listBasePages()}
            </select>
          </label>
        </div>
      )}
    </div>
  );
};

export default QuizReviewSelect;
