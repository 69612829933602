import React, { useReducer, createContext, useState, Dispatch } from "react";
import { reducer as metadataEditorReducer } from "./MetadataEditorReducer";
import MetadataEditorState from "../interfaces/MetadataEditorState";

interface Props {
  children: React.ReactNode;
}

const initialState: MetadataEditorState = {
  selectedMetadata: {
    manufacturerId: undefined,
    variantId: undefined,
    fleetId: undefined,
    tags: {},
  },
  allAssetsSelected: false,
  selectedAsset: undefined,
  imagePreview: undefined,
  showImagePreview: false,
  defaultTags: { "": "" },
};

interface EditorContextType {
  state: MetadataEditorState;
  dispatch: Dispatch<any>;
}

export const EditorContext = createContext<EditorContextType>({
  state: initialState,
  dispatch: () => null,
});

export const MetadataEditorContext = ({ children }: Props) => {
  const [state, dispatch] = useReducer(metadataEditorReducer, initialState);
  return <EditorContext.Provider value={{ state: state, dispatch: dispatch }}>{children}</EditorContext.Provider>;
};

export default MetadataEditorContext;
