import React, { useEffect, useState } from "react";
import RichTextEditor from "../../../components/richTextEditor/NEWrichTextEditor";
import { IRTEModule } from "../BasePageDesigner";

type BaseProps = {
  moduleRTE: IRTEModule[];
  placeholderText: string;
  updatedManifest: any;
  textBlockIndex: number;
  assignNewText: (newText: string) => void;
  displayImage: (imageIndex: number) => JSX.Element;
  handleImageDivClick: (index: number) => void;
  // selectImages: () => Promise<void>,
};

const DoubleGraphicSplitHorizTextAbove: React.FC<BaseProps> = (props: any) => {
  const versionString1 = props.updatedManifest.textBlock[0].version;
  const versionString2 = props.updatedManifest.textBlock[1].version;
  const text1 = props.updatedManifest.textBlock[0].text;
  const text2 = props.updatedManifest.textBlock[1].text;

  return (
    <div className="graphic-center-container-row">
      <div className="double-graphic-text-column">
        <div
          className="double-graphic-center-horiz-text-container"
          onFocus={() => props.setTextBlockIndex(0)}
          onBlur={() => props.setTextBlockIndex(0)}
        >
          <RichTextEditor
            displayText={text1}
            assignNewText={props.assignNewText}
            placeholder={props.placeholderText}
            module={props.moduleRTE}
            quillRef={props.quillRef}
            handleSelectionChange={props.handleSelectionChange()}
            version={versionString1}
          />
        </div>
        <div className="double-graphic-center-horiz-image" onClick={() => props.handleImageDivClick(0)} id="0">
          <div className="double-graphic-center-horiz-text-container-img-aspect-container">
            <div className="img-aspect-container-inside">
              <div className="img-aspect-centering">{props.displayImage(0)}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="double-graphic-text-column">
        <div
          className="double-graphic-center-horiz-text-container"
          onFocus={() => props.setTextBlockIndex(1)}
          onBlur={() => props.setTextBlockIndex(0)}
        >
          <RichTextEditor
            displayText={text2}
            assignNewText={props.assignNewText}
            placeholder={props.placeholderText}
            module={props.moduleRTE}
            quillRef={props.quillRef}
            theme="bubble"
            version={versionString2}
          />
        </div>
        <div className="double-graphic-center-horiz-image" onClick={() => props.handleImageDivClick(1)} id="0">
          <div className="double-graphic-center-horiz-text-container-img-aspect-container">
            <div className="img-aspect-container-inside">
              <div className="img-aspect-centering">{props.displayImage(1)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoubleGraphicSplitHorizTextAbove;
