import React, { useEffect } from "react";
// import HighlightAnnotation from "../hooks/HighlightAnnotation";
import { SVGAnnotationProps } from "../index";
import { HighlightAnnotation } from "../HighlightAnnotation";

export function SVGLine({
  x,
  y,
  objectId,
  onMouseDown,
  isDisplayed,
  rotate,
  height,
  width,
  zIndex,
  opacity,
  borderColor,
  strokeWidth,
  backgroundColor,
}: SVGAnnotationProps) {
  const svgRef: React.RefObject<SVGSVGElement> = React.useRef(null);
  const checkResize = () => {
    const thisSVG = svgRef.current;
    const lineElement = thisSVG?.lastChild as any;
    if (thisSVG && lineElement) {
      lineElement.attributes.y1.nodeValue = `${thisSVG.clientHeight / 2}`;
      lineElement.attributes.y2.nodeValue = `${thisSVG.clientHeight / 2}`;
    }
  };
  const transformString = `translate(${x}px, ${y}px) rotate(${rotate ?? 0}deg)`;

  useEffect(() => {
    window.addEventListener("resize", checkResize);
    return () => {
      window.removeEventListener("resize", checkResize);
    };
  }, []);

  return (
    <svg
      ref={svgRef}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="https://www.w3.org/1999/xlink"
      data-objectid={objectId}
      id={`solidLine-${objectId}`}
      tabIndex={0}
      name="solidLine"
      className="annotation"
      version="1.1"
      // viewBox={`0 0 auto 20`}
      height={`20`}
      preserveAspectRatio="xMinYMid meet"
      style={{
        transform: transformString,
        width: `${width.toFixed(2)}px`,
        zIndex: zIndex,
        opacity: isDisplayed ? opacity : 0.5,
        filter: isDisplayed ? "none" : "grayScale(100%)",
      }}
      onMouseDown={(e: React.MouseEvent<SVGSVGElement>) => {
        onMouseDown(e, objectId);
      }}
    >
      <HighlightAnnotation objectId={objectId} />
      <line
        className="annotation-arrow-arm"
        x1="0"
        x2={`100%`}
        y1={`50%`}
        y2={`50%`}
        style={{
          fill: `${borderColor || "white"}`,
          stroke: `${borderColor || "white"}`,
          strokeLinecap: "butt",
          strokeWidth: `${strokeWidth || "4"}`,
        }}
      />
    </svg>
  );
}
