import React, { useState, useEffect } from "react";
import Portal from "../Portal/Portal";
import { ReactComponent as LoadingSvg } from "../../assets/icons/NarratorTab/Dual Ring-1s-32px.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/Common/question-feedback-icon.svg";
import { AbstractTooltip as Tooltip } from "../ToolTip/ToolTip";
const BYTES_IN_MB = 1000000; // used for calculation
function calculatePercentage(val1: number, val2: number) {
  return (val2 * 100) / val1;
}
const LessonSize = ({ lessonMetaData: { lessonSizeInBytes, sizeThresholdsMB } }: any) => {
  const MAX_LESSON_SIZE = sizeThresholdsMB?.maximum || 500; // in MegaBytes (x100,000)
  const ORANGE_THRESHOLD = sizeThresholdsMB?.warning || MAX_LESSON_SIZE * 0.8; //MB 80% of 500 = 400
  const YELLOW_THRESHOLD = sizeThresholdsMB?.large || MAX_LESSON_SIZE * 0.512; //MB 51.2% of 500 = 256
  const [lessonMB, setLessonMB] = useState(0);
  const [showSizeWarning, setShowSizeWarning] = useState(false);
  const [hasLessonSizeWarningAppeared, setHasLessonSizeWarningAppeared] = useState(false);
  const percentage = calculatePercentage(MAX_LESSON_SIZE, lessonMB);
  const OVER_LIMIT = lessonMB > MAX_LESSON_SIZE;
  useEffect(() => {
    const res = lessonSizeInBytes / BYTES_IN_MB;
    // const res = 505;
    if (res > YELLOW_THRESHOLD) {
      setShowSizeWarning(true);
    }
    setLessonMB(res);
  }, [lessonSizeInBytes]);
  function barColor(MB: number) {
    if (MB >= MAX_LESSON_SIZE) {
      return "#FAAFAF"; // red
    } else if (MB > ORANGE_THRESHOLD) {
      return "#E8C38B"; // orange
    } else if (MB > YELLOW_THRESHOLD) {
      return "#F2E391"; // yeller
    }
    return "#93D173";
  }
  function lessonSizeDataTip(MB: number) {
    let output = "LSize";
    if (MB >= MAX_LESSON_SIZE) {
      output += "Red"; // red
    } else if (MB > ORANGE_THRESHOLD) {
      output += "Orange"; // orange
    } else if (MB > YELLOW_THRESHOLD) {
      output += "Yellow"; // yeller
    }
    return output;
  }

  function handleCloseWarningModal() {
    // if(!OVER_LIMIT) {
    setShowSizeWarning(false);
    setHasLessonSizeWarningAppeared(true);
    // }
  }
  return (
    <div className="lesson-size-tab">
      <span style={{ whiteSpace: "nowrap" }}>
        {lessonMB > YELLOW_THRESHOLD ? <InfoIcon title="" data-tooltip-id={"lessonSize"} /> : null}
        <Tooltip id="lessonSize" content={lessonSizeDataTip(lessonMB)} />
        Lesson Size:
        {lessonMB ? lessonMB.toFixed(0) : <LoadingSvg style={{ display: "inline", height: "80%", width: "8%" }} />}
        MB/{MAX_LESSON_SIZE}MB
      </span>

      <div className="lesson-size-progress" style={{ width: `${percentage}%`, background: barColor(lessonMB) }}></div>
      {showSizeWarning && !hasLessonSizeWarningAppeared && (
        <Portal path={"delete-confirm-container"}>
          <div className="delete-confirm-modal">
            <div className="delete-confirm-header">
              <p>Lesson Size</p>
            </div>
            <div className="delete-confirm-text" style={{ display: "flex", flexDirection: "column" }}>
              <h4 style={{ alignSelf: "center" }}>
                <strong>Warning!</strong>
              </h4>
              {!OVER_LIMIT ? (
                <p>
                  The lesson size is{" "}
                  <strong>
                    {lessonMB ? lessonMB.toFixed(0) : <LoadingSvg style={{ display: "inline", height: "35%" }} />}
                    MB
                  </strong>
                  , the maximum size allowed is <strong>{MAX_LESSON_SIZE}MB</strong>. If the lesson reaches{" "}
                  <strong>{MAX_LESSON_SIZE}MB</strong> you will need to reduce the lesson size.
                </p>
              ) : (
                <h6>Reduce Lesson Size</h6>
              )}
            </div>
            <div className="delete-confirm-buttons-container">
              <button className="main-modal-button" onClick={handleCloseWarningModal}>
                Close
              </button>
            </div>
          </div>
        </Portal>
      )}
    </div>
  );
};

export default LessonSize;
