import React, { FC } from "react";
import { FramedInput, Section } from "../../../panels/ObjectPropertiesPanel";
import styles from "./PanoramicViewMenu.module.css";
import { ReactComponent as MouseIcon } from "../../../assets/MouseIcon.svg";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../../contexts/ObjectsProvider";
import { PanoramicObject } from "../../../types";
import { useLessonPagesState } from "../../../contexts/LessonPagesProvider/LessonPagesProvider";
import { useObjectIsInTime } from "../../../hooks/useObjectIsInTime";
import { useTimeline } from "../../../contexts/TimelineProvider/TimelineProvider";
import { useAnimatedObject } from "../../../hooks/useAnimatedObject";
import {
  SelectedObjectActionTypes,
  useSelectedObjectDispatch,
  useSelectedObjectState,
} from "../../../contexts/SelectedObjectProvider/SelectedObjectProvider";
import { degreesToRadians } from "../../../utils/math";

const PanoramicViewMenu: FC = () => {
  const { selectedObjects } = useObjectsState();
  const objectDispatch = useObjectsDispatch();
  const { selectedPanel } = useLessonPagesState();
  const { yaw, pitch, zoom } = useSelectedObjectState();
  const selectedObjectDispatch = useSelectedObjectDispatch();
  const objectInTimeline = useObjectIsInTime(selectedObjects[0]?.objectId);
  const panoramicObject = selectedObjects[0] as PanoramicObject;
  const animatedObject = useAnimatedObject(panoramicObject?.objectId);
  const objectsDispatch = useObjectsDispatch();
  const [tl] = useTimeline();
  const currentTime = tl?.scrubbingCurrentTime;
  const frameAtCurrentTime = animatedObject?.frames?.find((frame) => frame.timestamp === currentTime);
  const pitchHovered = typeof frameAtCurrentTime?.pitch === "number";
  const yawHovered = typeof frameAtCurrentTime?.yaw === "number";
  const zoomHovered = typeof frameAtCurrentTime?.zoom === "number";
  // These values should be user oriented and the yaw and pitch should be in degrees

  const handlePitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === "") {
      // this means the user has typed a "-" and we want to keep the input unchanged

      // we set it downstream to an empty string so that the input is not updated
      selectedObjectDispatch({
        type: SelectedObjectActionTypes.SET_PITCH,
        payload: "",
      });
      return;
    }

    let pitchInDegrees = parseInt(value, 10); // pitch coming in as degrees

    if (isNaN(pitchInDegrees)) {
      selectedObjectDispatch({
        type: SelectedObjectActionTypes.SET_PITCH,
        payload: 0,
      });
      return;
    }
    if (pitchInDegrees > 89) {
      pitchInDegrees = 89;
    }
    if (pitchInDegrees < -89) {
      pitchInDegrees = -89;
    }

    const pitchInRadians = degreesToRadians(pitchInDegrees);

    objectDispatch({
      type: ObjectActionsType.UPDATE_OBJECT,
      payload: {
        objectId: panoramicObject?.objectId,
        object: { pitch: pitchInRadians },
      },
    });

    panoramicObject.moduleRef?.rotate({
      yaw: degreesToRadians(yaw),
      pitch: pitchInRadians,
    });
    selectedObjectDispatch({
      type: SelectedObjectActionTypes.SET_PITCH,
      payload: pitchInDegrees,
    });

    if (objectInTimeline) {
      // create a frame with the new pitch value
      objectsDispatch({
        type: ObjectActionsType.UPSERT_OBJECT_FRAME,
        payload: {
          objectId: panoramicObject?.objectId,
          frame: {
            timestamp: currentTime,
            pitch: pitchInRadians,
          },
        },
      });
    }
  };

  const handleYawChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === "") {
      // this means the user has typed a "-" and we want to keep the input unchanged

      // we set it downstream to an empty string so that the input is not updated
      selectedObjectDispatch({
        type: SelectedObjectActionTypes.SET_YAW,
        payload: "",
      });
      return;
    }

    let yawInDegrees = parseInt(value, 10); // yaw coming in as degrees

    if (isNaN(yawInDegrees)) {
      selectedObjectDispatch({
        type: SelectedObjectActionTypes.SET_YAW,
        payload: 0,
      });
      return;
    }
    if (yawInDegrees > 180) {
      yawInDegrees = 180;
    }
    if (yawInDegrees < -179) {
      yawInDegrees = -179;
    }

    const yawInRadians = degreesToRadians(yawInDegrees);

    objectDispatch({
      type: ObjectActionsType.UPDATE_OBJECT,
      payload: {
        objectId: panoramicObject?.objectId,
        object: { yaw: yawInRadians },
      },
    });

    panoramicObject.moduleRef?.rotate({
      yaw: yawInRadians,
      pitch: degreesToRadians(pitch),
    });
    selectedObjectDispatch({
      type: SelectedObjectActionTypes.SET_YAW,
      payload: yawInDegrees,
    });

    if (objectInTimeline) {
      // create a frame with the new yaw value
      objectsDispatch({
        type: ObjectActionsType.UPSERT_OBJECT_FRAME,
        payload: {
          objectId: panoramicObject?.objectId,
          frame: {
            timestamp: currentTime,
            yaw: yawInRadians,
          },
        },
      });
    }
  };

  const handleZoomChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let zoom = parseInt(event.target.value, 10);

    if (isNaN(zoom)) return;

    if (zoom > 100) {
      zoom = 100;
    }
    if (zoom < 0) {
      zoom = 0;
    }
    objectDispatch({
      type: ObjectActionsType.UPDATE_OBJECT,
      payload: {
        objectId: panoramicObject.objectId,
        object: { zoom },
      },
    });
    selectedObjectDispatch({
      type: SelectedObjectActionTypes.SET_ZOOM,
      payload: zoom,
    });

    objectsDispatch({
      type: ObjectActionsType.UPSERT_OBJECT_FRAME,
      payload: {
        objectId: panoramicObject.objectId,
        frame: {
          timestamp: currentTime,
          zoom,
        },
      },
    });

    panoramicObject.moduleRef?.zoom(zoom);
  };
  const visualYaw = typeof yaw === "number" ? Math.round(yaw) : "";
  const visualPitch = typeof pitch === "number" ? Math.round(pitch) : "";
  return (
    <>
      <Section title={"Camera Rotation"}>
        <div className={styles.panoramicPanel}>
          {selectedPanel !== "advanced" && <CameraViewInstructions />}
          <FramedInput
            label="Vertical"
            value={visualPitch}
            inputType="range"
            rangeMin={-89}
            rangeMax={90}
            isHovered={pitchHovered}
            onInputChange={handlePitchChange}
            onInputBlur={(event) => {
              if (event.currentTarget.value === "") {
                selectedObjectDispatch({
                  type: SelectedObjectActionTypes.SET_PITCH,
                  payload: 0,
                });
              }
            }}
            onFrameAdd={() => {
              objectsDispatch({
                type: ObjectActionsType.UPSERT_OBJECT_FRAME,
                payload: {
                  objectId: panoramicObject.objectId,
                  frame: {
                    timestamp: currentTime,
                    pitch: degreesToRadians(pitch),
                  },
                },
              });
            }}
            onFrameRemove={() => {
              objectsDispatch({
                type: ObjectActionsType.DELETE_PROPERTY_FROM_OBJECT_FRAME,
                payload: {
                  objectId: panoramicObject.objectId,
                  timestamp: currentTime,
                  property: "pitch",
                },
              });
            }}
          />
          <FramedInput
            label="Horizontal"
            value={visualYaw}
            inputType="range"
            rangeMin={-179}
            rangeMax={180}
            isHovered={yawHovered}
            onInputChange={handleYawChange}
            onInputBlur={(event) => {
              if (event.currentTarget.value === "") {
                selectedObjectDispatch({
                  type: SelectedObjectActionTypes.SET_YAW,
                  payload: 0,
                });
              }
            }}
            onFrameAdd={() => {
              objectsDispatch({
                type: ObjectActionsType.UPSERT_OBJECT_FRAME,
                payload: {
                  objectId: panoramicObject.objectId,
                  frame: {
                    timestamp: currentTime,
                    yaw: degreesToRadians(yaw),
                  },
                },
              });
            }}
            onFrameRemove={() => {
              objectsDispatch({
                type: ObjectActionsType.DELETE_PROPERTY_FROM_OBJECT_FRAME,
                payload: {
                  objectId: panoramicObject.objectId,
                  timestamp: currentTime,
                  property: "yaw",
                },
              });
            }}
          />
        </div>
      </Section>
      <Section>
        <FramedInput
          label="Zoom"
          value={zoom}
          inputType="range"
          rangeMin={0}
          rangeMax={100}
          isHovered={zoomHovered}
          onInputChange={handleZoomChange}
          // onInputBlur={(event) => setUserZoom(event.currentTarget.value)}
          onFrameAdd={() => {
            objectsDispatch({
              type: ObjectActionsType.UPSERT_OBJECT_FRAME,
              payload: {
                objectId: panoramicObject.objectId,
                frame: {
                  timestamp: currentTime,
                  zoom: zoom,
                },
              },
            });
          }}
          onFrameRemove={() => {
            objectsDispatch({
              type: ObjectActionsType.DELETE_PROPERTY_FROM_OBJECT_FRAME,
              payload: {
                objectId: panoramicObject.objectId,
                timestamp: currentTime,
                property: "zoom",
              },
            });
          }}
        />
      </Section>
    </>
  );
};

export const CameraViewInstructions = () => {
  return (
    <div className={styles.sectionContainer}>
      <div className={styles.textContainer}>
        Camera
        <MouseIcon />
      </div>
      <span className={styles.panoramicViewInstructions}>Click and drag to move the Panoramic View camera around</span>
    </div>
  );
};

export default PanoramicViewMenu;
