import _ from "lodash";
import { BoundType } from "../../react-moveable";
import { IAnnotation } from "../models/IAnnotation";
import { Annotation } from "./AnnotationClass";

export class Shape extends Annotation {
  fullList: any;

  constructor(
    annotations: IAnnotation[],
    type: string,
    index: number,
    bounds: BoundType,
    fullList: any,
    objectId: number,
  ) {
    super(annotations, type, index, bounds, fullList, objectId);
    this.newObject = {
      type: type,
      width: 10,
      height: 10 * (4 / 3),
      left: 3,
      top: 6,
      zIndex: 12,
      borderColor: "white",
      backgroundColor: "transparent",
      objectId: `${type}${objectId}`,
      isDisplayed: true,
    };
    this.fullList = fullList;
  }

  // add() {
  //   const position = this.setInitialPosition(this.fullList, this.index, this.bounds);
  //   this.newObject.top = position.top;
  //   this.newObject.left = position.left;
  //   if(!_.isEmpty(this.fullList)) {
  //     const newZ = this.findMaxZIndex(this.fullList);
  //     this.newObject.zIndex = newZ;
  //     this.newObject.name = `${this.type}-${this.fullList.length + 1}`;
  //   }
  //   return this.newObject;
  // }
}
