import "../../pageTypes/BasicPage_Player/components/BaseFreeForm/FreeForm.css";
import "./WYSIWYG.css";
import "../Annotation/AnnotationTextMenu/AnnotationTextMenu.css";
import _ from "lodash";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  RefObject,
  createRef,
  useEffect,
  useRef,
  MouseEvent,
} from "react";
import * as pageTypes from "../constants/pageTypes";
import Annotations from "../Annotation/Annotation";
import BaseWYSIWYG from "./pageTypes/Base/BaseWYSIWYG";
import ConfirmDelete from "../Modals/ConfirmDelete";
import FMSWYSIWYG from "./pageTypes/FMS_Player/FMSWYSIWYG";
import ISymbolStyle from "../Symbol/models/ISymbolStyle";
import { ISymbolV2 } from "../Symbol/models/ISymbol";
import MoveableClass from "../Moveable/MoveableClass";
import QuizWYSIWYG from "./pageTypes/Quiz/QuizWYSIWYG";
import Symbol from "../Symbol/Symbol";
import WYSIWYGToolbar from "../WYSIWYGToolbar/WYSIWYGToolbar";
import { BoundType } from "../react-moveable";
import { IAnnotation } from "../Annotation/models/IAnnotation";
import { ElementTypes, IBasicPageAttributes } from "../../pageTypes/BasicPage_Player/components/IBasePage";
import { IPageContext, PageContext } from "../../routes/builderContexts";
import { IPBRefs, IPropertyBox } from "../ObjectPropertyBox/models/IObjectPropertyBox";
import { evaluateZPosition } from "../../pageTypes/BasicPage_Player/components/BaseFreeForm/functions/zIndexFunctions";
import { IKeyPressHandler } from "../ObjectPropertyBox/models/IKeyPressHandler";
import * as pbFunctions from "../ObjectPropertyBox/functions/PropertyBoxFunctions";
import { emptyPropertyBox } from "../ObjectPropertyBox/models/emptyPropertyBox";
import ObjectPropertyBox from "../ObjectPropertyBox/ObjectPropertyBox";
import { IAnnotationState } from "../Annotation/models/IAnnotationState";
import { annotationsToAdd } from "../Annotation/AnnotationFunctions/AnnotationFunctions";
import {
  borderColorConversion,
  convertLabelTextFormat,
} from "../../pageTypes/BasicPage_Player/components/BaseFreeForm/functions/formatConversion";
import { CustomPageElement } from "../../pageTypes/BasicPage_Player/components/BaseFreeForm/classes/CustomPageElement";
import { WYSIWYGObjectList } from "../../classes/ObjectList/WYSIWYGObjectList";
import { Annotation } from "../Annotation/classes/AnnotationClass";
import { CustomPageObjectList } from "../../classes/ObjectList/CustomPageObjectList";
import { ClassSymbol } from "../Symbol/classes/ClassSymbol";
import { determinePageBG } from "../../contexts/PageColorProvider/PageColorProvider";
import { useObjectsState } from "../../contexts/ObjectsProvider";

const WYSIWYG = (props: any) => {
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const pageManifest: any = pageContext.pageManifest;
  const labelRefs: React.MutableRefObject<HTMLDivElement[]> = useRef([]);
  const moveRef: RefObject<any> = useRef(null);
  const playerRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();
  const { selectedObjects, selectedObjectId } = useObjectsState();

  const pbRefs: IPBRefs = {
    opbLeftRef: useRef(null),
    opbTopRef: useRef(null),
    opbDegreesRef: useRef(null),
    opbWidthRef: useRef(null),
    opbHeightRef: useRef(null),
    obpZindex: useRef(null),
  };

  const { isModalShown, pageType } = props;

  // const [annotations, setAnnotations]: [IAnnotation[], Dispatch<SetStateAction<IAnnotation[]>>] = useState<IAnnotation[]>([]);
  // const [refAnnotations, setRefAnnotations]: [IAnnotation[], Dispatch<SetStateAction<IAnnotation[]>>] = useState<IAnnotation[]>([]);
  const [bounds, setBounds]: [BoundType, Dispatch<SetStateAction<BoundType>>] = useState<BoundType>({
    top: 0,
    bottom: 800,
    left: 0,
    right: 1500,
  });
  const [directions, setDirections]: [string[], Dispatch<SetStateAction<string[]>>] = useState([
    "n",
    "nw",
    "ne",
    "s",
    "se",
    "sw",
    "e",
    "w",
  ]);
  const [editableIndex, setEditableIndex]: [number, Dispatch<SetStateAction<number>>] = useState<number>(-1);
  const [previousIndex, setPreviousIndex]: [number, Dispatch<SetStateAction<number>>] = useState<number>(-1);
  const [elementType, setElementType]: [ElementTypes, Dispatch<SetStateAction<ElementTypes>>] =
    useState<ElementTypes>("annotations");
  const [isLoading, setIsLoading]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(true);
  const [isDragging, setIsDragging]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false);
  const [isPropertyBoxShown, setIsPropertyBoxShown]: [boolean, Dispatch<SetStateAction<boolean>>] =
    useState<boolean>(false);
  const [isRotating, setIsRotating]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false);
  const [isResizing, setIsResizing]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false);
  const [nodeToUpdate, setNodeToUpdate]: [any, Dispatch<SetStateAction<any>>] = useState<any>();
  const [pageDims, setPageDims]: [DOMRect | null, Dispatch<SetStateAction<DOMRect | null>>] = useState<DOMRect | null>(
    null,
  );
  const [propertyBox, setPropertyBox]: [IPropertyBox, Dispatch<SetStateAction<IPropertyBox>>] =
    useState<IPropertyBox>(emptyPropertyBox);
  const [showConfirm, setShowConfirm]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false);
  const [target, setTarget]: [any, Dispatch<SetStateAction<any>>] = useState<any>();
  const [isAnnotationMenuShown, setIsAnnotationMenuShown]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] =
    useState<boolean>(false);
  const annotationName: string[] = ["circledNumber", "circledLetter", "squareNumber", "squareLetter"];
  const lowestZ: number = 12;
  const eotWidth: number = 247;
  const pageProps = {
    pageManifest,
  };
  let tempNode: any = _.cloneDeep(nodeToUpdate);

  const keyPressHandlers: IKeyPressHandler = {
    props: {
      isDisplayed: true,
      elementType: elementType,
      moveRef: moveRef.current,
      nodeToUpdate: nodeToUpdate,
      pageDims: pageDims,
      pbRefs: pbRefs,
      properties: propertyBox,
      shouldMaintainRatio: shouldMaintainRatio(),
      target: target,
      changePropertyBox,
      updateAttributes,
      updateZindex,
    },
    changePropertyBox,
    removeSelected,
    setTarget,
    shouldMaintainRatio,
    updateAttributes,
    // }
  };

  const annotationsState: IAnnotationState = {
    annotations: pageManifest.annotations,
    bounds: bounds,
    editableIndex: editableIndex,
    pageDims: pageDims,
    tempNode: tempNode,
    selectedAnnotation: nodeToUpdate,
    symbols: pageManifest?.symbols as ISymbolV2[],
    setNodeToUpdate,
  };

  useEffect(() => {
    const manifestCopy = _.cloneDeep(pageManifest);
    const objectList = new WYSIWYGObjectList(pageManifest.annotations, pageManifest.symbols);
    const refObjectList = new WYSIWYGObjectList(pageManifest.refAnnotations, pageManifest.refSymbols);
    if (pageManifest.symbols && !_.isEqual(pageManifest.symbols, objectList.symbols)) {
      pageManifest.symbols = _.cloneDeep(objectList.symbols);
    }
    if (pageManifest.refSymbols && !_.isEqual(pageManifest.refSymbols, refObjectList.symbols)) {
      pageManifest.refSymbols = _.cloneDeep(refObjectList.symbols);
    }

    handleTextAndLabels();
    handleRefTextAndLabels();
    if (_.has(pageManifest, "symbols") || _.has(pageManifest, "refSymbols")) convertSymbolsIfHaveStyleAttributes();
    setIsLoading(false);
    if (!_.isEqual(manifestCopy, pageManifest)) {
      pageContext.updatePageManifest(pageManifest);
    }
  }, []);

  useEffect(() => {
    if (editableIndex > -1) {
      setPreviousIndex(editableIndex);
    }
  }, [editableIndex]);

  useEffect(() => {
    if (window.innerHeight > 0 && window.innerWidth > 0) {
      makeBounds(playerRef.current);
    }
  }, [window.innerHeight, window.innerWidth]);

  useEffect(() => {
    let labelArray: IAnnotation[] = [];
    if (pageManifest.annotations && pageManifest.annotations?.length > 0) {
      _.forEach(pageManifest.annotations, (annotation) => {
        if (annotation.type === "label") {
          labelArray.push(annotation);
        }
      });
    }

    if (labelRefs.current.length > 0) {
      _.forEach(labelRefs.current, (el: HTMLDivElement, index: number) => {
        labelRefs.current[index].innerHTML = labelArray[index].text as string;
      });
    }

    makeBounds(playerRef.current);
    evaluateZIndex();

    // pageContext.updatePageManifest(pageManifest)
    // setAnnotations(pageManifest.annotations);
    // setRefAnnotations(pageManifest.refAnnotations);
  }, [isLoading]);

  useEffect(() => {
    if (elementType === "annotations" && pageContext.pageManifest?.annotations?.length > 0) {
      const lastAdded: number = pageContext.pageManifest?.annotations?.length - 1;
      const addedID: string = `${pageManifest.annotations[lastAdded]?.type}-${lastAdded}`;
      const addedTarget = document.querySelector(`#${addedID}`) as HTMLElement | SVGSVGElement;
      if (addedTarget) {
        tempNode = pageManifest.annotations[lastAdded];
        tempNode.name = validateNodeName(lastAdded, tempNode, "annotation");
        pbFunctions.createPropertyBox(tempNode, pageDims as DOMRect, setPropertyBox);
        setIsPropertyBoxShown(true);
        setNodeToUpdate(tempNode);
        getDirections(addedTarget);
        setTarget(addedTarget);
        addedTarget.focus();
      }
    }
  }, [pageContext.pageManifest?.annotations?.length]);

  useEffect(() => {
    if (isModalShown && elementType === "annotations" && pageContext.pageManifest?.refAnnotations?.length > 0) {
      const lastAdded: number = pageContext.pageManifest?.refAnnotations?.length - 1;
      const addedID: string = `${pageManifest.refAnnotations[lastAdded]?.type}-${lastAdded}`;
      const addedTarget = document.querySelector(`#${addedID}`) as HTMLElement | SVGSVGElement;
      if (addedTarget) {
        tempNode = pageManifest.refAnnotations[lastAdded];
        tempNode.name = validateNodeName(lastAdded, tempNode, "annotation");
        pbFunctions.createPropertyBox(tempNode, pageDims as DOMRect, setPropertyBox);
        setIsPropertyBoxShown(true);
        setNodeToUpdate(tempNode);
        getDirections(addedTarget);
        setTarget(addedTarget);
        addedTarget.focus();
      }
    }
  }, [isModalShown, pageContext.pageManifest?.refAnnotations?.length]);

  useEffect(() => {
    if (isModalShown) {
      setTarget(null);
      setEditableIndex(-1);
      setNodeToUpdate({});
      setIsPropertyBoxShown(false);
      setElementType("annotations");
    }
  }, [isModalShown]);

  useEffect(() => {
    if (!props.referenceRect && playerRef.current) {
      makeBounds(playerRef.current);
      setPageDims(playerRef.current?.getBoundingClientRect());
    } else {
      if (props.referenceRect) {
        let rect = props.referenceRect;
        setBounds({ top: 0, left: 0, bottom: rect.height, right: rect.width });
        setPageDims(props.referenceRect);
      }
    }
  }, [props.referenceRect, props.referenceBounds]);

  useEffect(() => {
    if (!target && isPropertyBoxShown) {
      setIsPropertyBoxShown(false);
    }
  }, [target, isPropertyBoxShown]);

  async function handleTextAndLabels() {
    if (pageManifest.annotations) {
      const notations = await new Annotation(pageManifest.annotations, "", -1, {}, null, 0).validateAnnotationNames();
      pageManifest.annotations = _.cloneDeep(notations);
      pageManifest.annotations = convertLabelTextFormat(pageManifest.annotations);
      pageManifest.annotations = borderColorConversion(pageManifest.annotations);
      pageContext.updatePageManifest(pageManifest);
    }
  }

  async function handleRefTextAndLabels() {
    if (pageManifest.refAnnotations) {
      const notations = await new Annotation(
        pageManifest.refAnnotations,
        "",
        -1,
        {},
        null,
        0,
      ).validateAnnotationNames();
      pageManifest.refAnnotations = _.cloneDeep(notations);
      pageManifest.refAnnotations = convertLabelTextFormat(pageManifest.refAnnotations);
      pageManifest.refAnnotations = borderColorConversion(pageManifest.refAnnotations);
      pageContext.updatePageManifest(pageManifest);
    }
  }

  function convertSymbolsIfHaveStyleAttributes() {
    let incrementor = pageContext.getObjectIncrementor();
    if (pageManifest.symbols && pageManifest.symbols.length > 0) {
      const symbol = new ClassSymbol(-1, {}, pageManifest.symbols, "", [], incrementor);
      const newSymbols = symbol.convertSymbolIfHasStyleAttribute();
      pageManifest.symbols = newSymbols;
    }

    if (pageManifest.refSymbols && pageManifest.refSymbols.length > 0) {
      const symbol = new ClassSymbol(-1, {}, pageManifest.refSymbols, "", [], incrementor);
      const newSymbols = symbol.convertSymbolIfHasStyleAttribute();
      pageManifest.refSymbols = newSymbols;
    }
  }

  const makeBounds = (currentRef: any) => {
    if (currentRef) {
      let tempBounds = _.cloneDeep(bounds);

      if (pageContext.pageType === "FMS") {
        tempBounds = {
          top: 0,
          bottom: currentRef.getBoundingClientRect().height,
          left: 0,
          right: currentRef.getBoundingClientRect().width - eotWidth,
        };
      } else {
        tempBounds = {
          top: 0,
          bottom: currentRef.getBoundingClientRect().height,
          left: 0,
          right: currentRef.getBoundingClientRect().width,
        };
      }
      setBounds(tempBounds);
      setPageDims(currentRef.getBoundingClientRect());
    }
  };

  const displayPageType = () => {
    switch (pageType) {
      case pageTypes.BASIC_PAGE:
        return <BaseWYSIWYG {...pageProps} />;
      case pageTypes.FMS_PAGE:
        // addAnnotationsToManifest();
        return (
          <div id="fms-player">
            <FMSWYSIWYG
              cduPath={props.cduPath}
              enablingObjective={props.enablingObjective}
              enablingObjectiveTitle={props.enablingObjectiveTitle}
              image={props.image}
              mode={props.mode}
              pageManifest={pageManifest}
            />
          </div>
        );
      case pageTypes.QUIZ_PAGE:
        // addAnnotationsToManifest();
        return <QuizWYSIWYG imageSource={props.imageSource} pageManifest={pageManifest} />;
      case pageTypes.ThreeD_PAGE:
        return;
      default:
        return <h2>This Page Type Does Not Exist to Edit</h2>;
    }
  };

  const markSelected = (target: HTMLElement | SVGSVGElement, index: number, type: ElementTypes) => {
    if (!isDragging && !isResizing && !isRotating && target) {
      const selected = new CustomPageElement(pageManifest, "");
      if (type === "symbols") {
        selected.getSelectedSymbolHeight(target as SVGSVGElement, pageDims as DOMRect);
      }

      let node: any;
      if (!isModalShown) {
        if (type === "annotations") {
          node = pageManifest.annotations[index];
        } else {
          node = pageManifest.symbols[index];
          node.height = (target.getBoundingClientRect().height / (pageDims?.height as number)) * 100;
        }
      } else {
        if (type === "annotations") {
          node = pageManifest.refAnnotations[index];
        } else {
          node = pageManifest.refSymbols[index];
          if (node.name.length === 0) node.name = `${pageManifest.refSymbols[index].name} ${index}`;
        }
      }

      setEditableIndex(index);
      getDirections(target);
      setTarget(target);
      setNodeToUpdate(node);
      setElementType(type);
      pbFunctions.createPropertyBox(node, pageDims as DOMRect, setPropertyBox);
      setIsPropertyBoxShown(true);
    }
  };

  function validateNodeName(index: number, node: any, type: string) {
    if (!_.has(node, "name") || node.name?.length === 0) {
      switch (type) {
        case "annotation":
          return `${node.type} ${index}`;
        case "symbols":
          return `${pageManifest.symbols[index].name} ${index}`;
        default:
          return;
      }
    } else {
      return node.name;
    }
  }

  const getDirections = (target: HTMLElement | SVGSVGElement) => {
    const name: string | null = target ? target.getAttribute("name") : null;

    if (
      name === "arrow" ||
      name === "lineArrow" ||
      name === "solidLine" ||
      name === "dashedLine" ||
      name === "pointArrow" ||
      _.startsWith(target.id, "label")
    ) {
      setDirections(["e", "w"]);
    } else {
      setDirections(["n", "nw", "ne", "s", "se", "sw", "e", "w"]);
    }
  };

  const handleActionsEnd = (target: HTMLElement | SVGElement, tempNode: any) => {
    target.style.zIndex = nodeToUpdate.zIndex;
    const name = target.getAttribute("name");
    if (isDragging) setIsDragging(false);
    if (isRotating) setIsRotating(false);
    if (isResizing) setIsResizing(false);

    if (name === "symbol") {
      isModalShown === true
        ? (pageManifest.refSymbols[editableIndex] = tempNode)
        : (pageManifest.symbols[editableIndex] = tempNode);
    } else {
      if (isModalShown === true) {
        pageManifest.refAnnotations[editableIndex] = tempNode;
      } else {
        pageManifest.annotations[editableIndex] = tempNode;
      }
    }
    setNodeToUpdate(tempNode);
    pbFunctions.createPropertyBox(tempNode, pageDims as DOMRect, setPropertyBox);
    pageContext.updatePageManifest(pageManifest);
  };

  const addNewAnnotation = (type: string, addFunction: any) => {
    const annotationKey: "annotations" | "refAnnotations" = isModalShown ? "refAnnotations" : "annotations";
    const symbolKey: "symbols" | "refSymbols" = isModalShown ? "refSymbols" : "symbols";
    if (!(annotationKey in pageManifest) || pageManifest[annotationKey] === undefined) {
      pageManifest[annotationKey] = [];
    }
    if (isModalShown) annotationsState.annotations = _.cloneDeep(pageManifest.refAnnotations);
    const objectList = new WYSIWYGObjectList(pageManifest[annotationKey], pageManifest[symbolKey]);
    const fullList = !isModalShown ? objectList.fullList : objectList.getReferenceObjectList();
    let incrementor = pageContext.getObjectIncrementor();
    const newAnnotation: IAnnotation = annotationsToAdd[addFunction](
      pageManifest[annotationKey],
      type,
      editableIndex,
      bounds,
      fullList,
      incrementor,
      pageDims,
    );
    pageManifest[annotationKey].push(newAnnotation);

    const index: number = pageManifest[annotationKey].length - 1;
    setEditableIndex(index);
    setTarget(null);
    setIsPropertyBoxShown(false);
    setElementType("annotations");
    pageManifest.objectIncrementor = ++incrementor;
    pageContext.updatePageManifest(pageManifest);
  };

  function removeSelected() {
    if (previousIndex > -1) {
      switch (elementType) {
        // case 'annotation':
        case "annotations":
          isModalShown === true
            ? pageManifest.refAnnotations.splice(previousIndex, 1)
            : pageManifest.annotations.splice(previousIndex, 1);
          break;
        case "symbols":
          // case 'symbol':
          isModalShown === true
            ? pageManifest.refSymbols.splice(previousIndex, 1)
            : pageManifest.symbols.splice(previousIndex, 1);
          break;
        default:
          break;
      }
    }

    setIsPropertyBoxShown(false);
    setTarget(null);
    setEditableIndex(-1);
    setShowConfirm(false);
    setElementType("annotations");
    setNodeToUpdate(undefined);
    pageContext.updatePageManifest(pageManifest);
  }

  const showFontIcons = () => {
    if (isModalShown) {
      return false;
    }
    // else {
    //   return (elementType === 'annotations' && (pageManifest.annotations && (pageManifest.annotations[editableIndex]?.type === 'label' || pageManifest.refAnnotations && pageManifest.refAnnotations[editableIndex]?.type === 'label')))
    // }
  };

  function updateAttributes(attributes: IAnnotation | IBasicPageAttributes | ISymbolStyle) {
    switch (elementType) {
      // case 'annotation':
      case "annotations":
        if (isModalShown === true) {
          pageManifest.refAnnotations[editableIndex] = attributes;
        } else {
          pageManifest.annotations[editableIndex] = attributes;
        }
        break;
      // case 'symbol':
      case "symbols":
        isModalShown === true
          ? (pageManifest.refSymbols[editableIndex] = attributes)
          : (pageManifest.symbols[editableIndex] = attributes);
        break;
      default:
        break;
    }
    setNodeToUpdate(attributes);
    pageContext.updatePageManifest(pageManifest);
  }

  function changePropertyBox(properties: IPropertyBox) {
    const temp: IPropertyBox = _.cloneDeep(properties);

    if (!_.isEqual(temp, propertyBox)) {
      setPropertyBox(temp);
    }
  }

  const evaluateZIndex = () => {
    if (pageManifest.annotations) {
      let needToChange: boolean = false;
      _.forEach(pageManifest.annotations, (annotation: IAnnotation, index: number) => {
        if (annotation.zIndex < lowestZ) {
          pageManifest.annotations[index].zIndex = lowestZ;
          needToChange = true;
        }
      });

      if (needToChange) {
        // setAnnotations(pageManifest.annotations);
        // pageManifest.annotations = _.cloneDeep(pageManifest.annotations);
        pageContext.updatePageManifest(pageManifest);
      }
    }
    if (pageManifest.refAnnotations) {
      let needToChange: boolean = false;
      _.forEach(pageManifest.refAnnotations, (refAnnotation: IAnnotation, index: number) => {
        if (refAnnotation.zIndex < lowestZ) {
          pageManifest.refAnnotations[index].zIndex = lowestZ;
          needToChange = true;
        }
      });

      if (needToChange) {
        // setRefAnnotations(pageManifest.refAnnotations);
        // pageManifest.annotations = _.cloneDeep(pageManifest.annotations);
        pageContext.updatePageManifest(pageManifest);
      }
    }
  };

  const sendToBack = async () => {
    const annotationKey = isModalShown ? "refAnnotations" : "annotations";
    const symbolKey = isModalShown ? "refSymbols" : "symbols";
    const list = new WYSIWYGObjectList(pageManifest[annotationKey], pageManifest[symbolKey]);
    if (isModalShown) list.getReferenceObjectList(pageManifest[annotationKey], pageManifest[symbolKey]);
    await list.sendSelectedBack(nodeToUpdate);
    if (list.annotations) pageManifest[annotationKey] = list.annotations;
    if (list.symbols) pageManifest[symbolKey] = list.symbols;
    pageContext.updatePageManifest(pageManifest);
  };

  async function updateZindex(value: string) {
    const zValue: number = parseInt(value);
    let newNode: IAnnotation | ISymbolV2 = _.cloneDeep(nodeToUpdate);
    const properties: IPropertyBox = { ...propertyBox, zIndex: zValue };
    newNode = { ...newNode, zIndex: zValue };
    let newPM = evaluateZPosition(elementType, newNode, nodeToUpdate, pageManifest, editableIndex);
    let incrementor = pageContext.getObjectIncrementor();
    const objectList = new CustomPageObjectList(pageManifest, incrementor);
    await objectList.changeTheZ(newNode, nodeToUpdate);
    newPM = objectList.rebuildPageManifest();
    setPropertyBox(properties);
    setNodeToUpdate(newNode);
    pageContext.updatePageManifest(newPM);
  }

  const updateLabel = (text: string, scrollWidth: number, scrollHeight: number) => {
    if (text === "<p><br></p>") text = "";
    if (isModalShown) {
      pageManifest.refAnnotations[editableIndex] = {
        ...pageManifest.refAnnotations[editableIndex],
        text: text,
        height: scrollHeight, // width: scrollWidth
      };
      setNodeToUpdate(pageManifest.refAnnotations[editableIndex]);
    } else {
      pageManifest.annotations[editableIndex] = {
        ...pageManifest.annotations[editableIndex],
        text: text,
        height: scrollHeight, // width: scrollWidth
      };
      setNodeToUpdate(pageManifest.annotations[editableIndex]);
    }
    pageContext.updatePageManifest(pageManifest);
  };

  const addSymbol = (name: string) => {
    const pmKey: "symbols" | "refSymbols" = isModalShown ? "refSymbols" : "symbols";
    if (!_.has(pageManifest, pmKey)) {
      pageManifest[pmKey] = [];
    }
    let incrementor = pageContext.getObjectIncrementor();

    const objectList = new CustomPageObjectList(pageManifest, incrementor);
    const fullList = objectList.fullList;
    const symbol = new ClassSymbol(editableIndex, bounds, pageManifest[pmKey], name, fullList, incrementor);
    const newSymbol = symbol.add();
    pageManifest[pmKey].push(newSymbol);
    const lastIndex: number = pageManifest[pmKey].length - 1;
    setNodeToUpdate(newSymbol);
    setElementType("symbols");
    setEditableIndex(lastIndex);
    pageManifest.objectIncrementor = ++incrementor;
    pageContext.updatePageManifest(pageManifest);

    // if (!_.has(pageManifest, 'symbols')) {
    //   pageManifest.symbols = [];
    // }
    // if (!_.has(pageManifest, "refSymbols")) {
    //   pageManifest.refSymbols = [];
    // }

    // const newSymbol: any = {};
    // newSymbol.name = name;
    // newSymbol.style = initialSymbolStyle;
    // newSymbol.style.zIndex = getMaxZIndex('symbol');
    // if (isModalShown === true) {
    //   pageManifest.refSymbols.push(newSymbol);
    // } else {
    //   pageManifest.symbols.push(newSymbol);
    // }
    // pageContext.updatePageManifest(pageManifest);
  };

  function shouldMaintainRatio() {
    let boolean: boolean = false;
    _.forEach(annotationName, (named) => {
      if (named === target?.getAttribute("name")) {
        boolean = true;
      }
    });
    return boolean;
  }

  function handleFormatChange(e: React.MouseEvent<HTMLButtonElement>, funct: any, value: any, keyValue: any) {
    const newAttributes = funct(nodeToUpdate, value, keyValue);
    updateAttributes(newAttributes);
  }

  function showAnnotationButton() {
    if (isModalShown) {
      if (pageManifest.refAnnotations && pageManifest.refAnnotations.length >= 100) {
        return false;
      }
      return true;
    } else {
      if (pageManifest.annotations && pageManifest.annotations.length >= 100) {
        return false;
      }
      return true;
    }
  }

  if (isLoading) {
    return <></>;
  } else {
    return (
      <React.Fragment>
        <div className="contentBox">
          <div id={isModalShown ? "wysiwyg-wrapper-modal" : "wysiwyg-wrapper"}>
            <WYSIWYGToolbar
              areAnnotationsShown={showAnnotationButton()}
              attributes={nodeToUpdate}
              editableIndex={editableIndex}
              elementType={elementType}
              showFontIcons={showFontIcons()}
              addNewAnnotation={addNewAnnotation}
              addSymbolOLD={addSymbol}
              removeElement={setShowConfirm}
              sendToBack={sendToBack}
              // updateAttributes={updateAttributes}
              rightSideActions={props.rightSideActions}
              handleFormatChange={handleFormatChange}
            />
            {isModalShown ? (
              <React.Fragment>
                <div ref={playerRef} className="wysiwyg" id="reference-image-player">
                  {props.children ? props.children : displayPageType()}
                  <Annotations
                    annotations={pageManifest.refAnnotations}
                    editableIndex={editableIndex}
                    isMenuShown={isAnnotationMenuShown}
                    kp={keyPressHandlers}
                    target={target}
                    handleFormatChange={handleFormatChange}
                    markSelected={markSelected}
                    updateLabel={updateLabel}
                    setElementType={setElementType}
                    setEditableIndex={setEditableIndex}
                    setNodeToUpdate={setNodeToUpdate}
                    setTarget={setTarget}
                    setIsMenuShown={setIsAnnotationMenuShown}
                  />
                  {_.has(pageManifest, "refSymbols") && pageManifest.refSymbols?.length > 0 && isModalShown ? (
                    <Symbol
                      symbols={pageManifest.refSymbols}
                      kp={keyPressHandlers}
                      handleKeyPress={pbFunctions.handleKeyPress}
                      markSelected={markSelected}
                    />
                  ) : (
                    <></>
                  )}
                  {pageDims ? (
                    <MoveableClass
                      bounds={bounds}
                      canResize={true}
                      directions={directions}
                      isMovable={true}
                      moveRef={moveRef}
                      nodeToUpdate={nodeToUpdate}
                      pageDims={pageDims}
                      propertyBox={propertyBox}
                      shouldMaintainRatio={shouldMaintainRatio()}
                      target={target}
                      endActions={handleActionsEnd}
                      setIsDragging={setIsDragging}
                      setIsResizing={setIsResizing}
                      setIsRotating={setIsRotating}
                      // updatePropertyBox={updatePropertyBox}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="draggable-aspect-ratio-page-container" id="draggable">
                  {isPropertyBoxShown ? <ObjectPropertyBox {...keyPressHandlers.props} /> : <></>}

                  <div
                    ref={playerRef}
                    className="wysiwyg page-player-area"
                    id="pageplayerarea"
                    style={
                      pageContext.pageManifest.theme
                        ? {
                            background: determinePageBG(pageContext.pageManifest.theme),
                          }
                        : {}
                    }
                  >
                    {props.children ? props.children : displayPageType()}
                    <Annotations
                      annotations={pageManifest.annotations}
                      editableIndex={editableIndex}
                      isMenuShown={isAnnotationMenuShown}
                      kp={keyPressHandlers}
                      target={target}
                      handleFormatChange={handleFormatChange}
                      markSelected={markSelected}
                      updateLabel={updateLabel}
                      setEditableIndex={setEditableIndex}
                      setElementType={setElementType}
                      setNodeToUpdate={setNodeToUpdate}
                      setTarget={setTarget}
                      setIsMenuShown={setIsAnnotationMenuShown}
                    />
                    {_.has(pageManifest, "symbols") && pageManifest.symbols?.length > 0 ? (
                      <Symbol
                        symbols={pageManifest.symbols}
                        kp={keyPressHandlers}
                        handleKeyPress={pbFunctions.handleKeyPress}
                        markSelected={markSelected}
                      />
                    ) : (
                      <></>
                    )}
                    {pageDims ? (
                      <MoveableClass
                        bounds={bounds}
                        canResize={true}
                        directions={directions}
                        isMovable={true}
                        moveRef={moveRef}
                        nodeToUpdate={nodeToUpdate}
                        pageDims={pageDims}
                        propertyBox={propertyBox}
                        shouldMaintainRatio={shouldMaintainRatio()}
                        target={target}
                        endActions={handleActionsEnd}
                        setIsDragging={setIsDragging}
                        setIsResizing={setIsResizing}
                        setIsRotating={setIsRotating}
                        // updatePropertyBox={updatePropertyBox}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        <ConfirmDelete show={showConfirm} cancel={setShowConfirm} confirm={removeSelected} />
      </React.Fragment>
    );
  }
};

export default WYSIWYG;
