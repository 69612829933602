import _ from "lodash";
import "../../pageTypes/BasicPage_Player/BasePageDesigner.css";
import { basePageIconInfo, IBasePageIconType } from "./basePageIconInfo";
//import { ISelectIconType } from './ISelectIcon';
import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { IPageContext, PageContext } from "../../routes/builderContexts";
// import { ReactComponent as ExitIcon } from '../../assets/icons/NewPageSelector/exit-icon-close.svg';

type ModalType = {
  show: boolean;
  onHide: () => void;
};

const BasePageSelector: React.FC<ModalType> = (props: ModalType) => {
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const [selectedKey, setSelectedKey]: [number, React.Dispatch<React.SetStateAction<number>>] = useState<number>(-1);
  const [selectedType, setSelectedType]: [string, React.Dispatch<React.SetStateAction<string>>] = useState<string>("");

  const displayBasePageLayout = () => {
    return basePageIconInfo.map((layout: IBasePageIconType, key: number) => {
      if (!pageContext.pageManifest || (pageContext.pageManifest && layout.basePageType !== "freeForm")) {
        return (
          <div className={`page-type-change-icon-container ${key === selectedKey ? "selected" : ""}`} key={key}>
            <figure onClick={() => handleIconClick(layout.basePageType, key)}>
              {layout.component && (
                <layout.component
                  style={layout.basePageType === "doubleGraphicSplitRight" ? { transform: "scaleX(-1)" } : {}}
                />
              )}
              <figcaption>
                <small>{layout.caption}</small>
              </figcaption>
            </figure>
          </div>
        );
      }
      return <></>;
    });
  };

  const handleIconClick = (basePageType: string, key: number) => {
    setSelectedType(basePageType);
    setSelectedKey(key);
  };

  const handleSelectClick = (): void => {
    const newPageManifest = _.cloneDeep(pageContext.pageManifest);
    newPageManifest.basePageType = selectedType;
    pageContext.updatePageManifest(newPageManifest);
    props.onHide();
  };

  return (
    <Modal show={props.show} onHide={props.onHide} dialogClassName="basic-page-modal-content-newPages">
      <Modal.Header closeButton>
        <div className="modal-header-newPage">
          <h2>Select a Layout</h2>
          {/* <ExitIcon onClick={() => { props.onHide() }} /> */}
        </div>
      </Modal.Header>
      <Modal.Body className="page-type-change-modal-body-scroll">{displayBasePageLayout()}</Modal.Body>
      <Modal.Footer className="button-align">
        <button className="layout-select-button" onClick={() => props.onHide()}>
          Cancel
        </button>
        <button className="layout-select-button" onClick={() => handleSelectClick()}>
          Select
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default BasePageSelector;
