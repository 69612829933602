export const blankStep = {
  stepType: 0,
  taskDescription: "",
  question: "",
  choices: [
    {
      isCorrect: false,
    },
  ],
};

export const blankHotSpot = {
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};

export const blankDOMRect: DOMRect = {
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  height: 0,
  width: 0,
  x: 0,
  y: 0,
  toJSON() {},
};

export const interactiveStep = {
  stepType: 1,
  taskDescription: "",
  question: "",
  choices: [
    {
      text: "sometext",
      isCorrect: false,
    },
  ],
  images: {
    path: "",
    assetVersionId: 0,
    imageHotSpot: blankHotSpot,
    imageDimensions: blankDOMRect,
  },
  version: 1.0,
};

export const standardQuizStep = {
  stepType: 2,
  taskDescription: "",
  question: "",
  choices: [
    {
      text: "",
      isCorrect: true,
    },
    {
      text: "",
      isCorrect: false,
    },
  ],
  images: {
    path: "",
    assetVersionId: 0,
  },
};

export const graphicQuizStep = {
  stepType: 3,
  taskDescription: "",
  question: "",
  choices: [
    {
      isCorrect: true,
      image: {
        path: "",
        assetVersionId: 0,
      },
    },
    {
      isCorrect: false,
      image: {
        path: "",
        assetVersionId: 0,
      },
    },
  ],
};
