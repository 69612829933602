import React from "react";
import { AnnotationMenuIconType } from "../AnnotationMenu";
import AnnotationDashedLine from "../icons/AnnotationDashedLine";
import AnnotationMenuArrowSolid from "../icons/AnnotationMenuArrowSolidIcon";
import AnnotationMenuLineIcon from "../icons/AnnotationMenuLineIcon";
import AnnotationArrowLine from "../icons/AnnotationArrowLine";

export const AnnotationMenuLineIcons: AnnotationMenuIconType[] = [
  {
    name: "fillArrow",
    icon: <AnnotationMenuArrowSolid />,
  },
  {
    name: "lineArrow",
    icon: <AnnotationArrowLine />,
  },
  {
    name: "solidLine",
    icon: <AnnotationMenuLineIcon />,
  },
  {
    name: "dashedLine",
    icon: <AnnotationDashedLine />,
  },
];
