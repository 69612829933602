import React, { useEffect } from "react";
import { useFrame } from "@react-three/fiber";
import {
  useGLTF,
  useProgress
} from "@react-three/drei";

const Skybox = (props) => {
  const skyPath = "/api/Asset/cpat/generic/3dmodels/conditions/SkyBox/Skybox.gltf";

  const { scene } = useGLTF(skyPath)
  const { progress } = useProgress();

  scene.position.set(-125, 0, 100);
  scene.scale.set(2, 2, 2);

  useEffect(() => {
    if(progress === 100) {
      props.callBack();
    }
  }, [progress])

  useFrame(() => {
    scene.rotation.x += 0.0001;
  });

  return <primitive object={scene} />
};

export default Skybox;