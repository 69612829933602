export function parseSemanticVersioning(semanticVersion: string | undefined): number[] | undefined;
export function parseSemanticVersioning(
  semanticVersion: string | undefined,
  versionToReturn?: "major" | "minor" | "patch",
) {
  //using this type of versioning to determine sections of code to run.
  if (typeof semanticVersion === "string") {
    const splitVersion = semanticVersion.split(".");
    if (splitVersion.length === 3) {
      const MAJOR_VERSION = +splitVersion[0];
      const MINOR_VERSION = +splitVersion[1];
      const PATCH_VERSION = +splitVersion[2];
      switch (versionToReturn) {
        case "major":
          return MAJOR_VERSION;
        case "minor":
          return MINOR_VERSION;
        case "patch":
          return PATCH_VERSION;
        default:
          return [MAJOR_VERSION, MINOR_VERSION, PATCH_VERSION];
      }
    }
  }
}
