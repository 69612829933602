import { useState } from "react";
import BlurCutoutInput from "../../components/ObjectPropertyBox/components/BlurCutoutInput";
import BlurInput from "../../components/ObjectPropertyBox/components/BlurInput";
import PanoramicViewMenu, {
  CameraViewInstructions,
} from "../../components/ObjectPropertyBox/components/PanoramicViewMenu";
import { useObjectsState } from "../../contexts/ObjectsProvider";
import { SmartObjectAdvancedPanel } from "../../lib/SmartObject/components/Svg/AdvancedPanel";
import { DropSeparator, Section } from "../ObjectPropertiesPanel";
import styles from "./Advanced.module.css";
import { useTimeline } from "../../contexts/TimelineProvider/TimelineProvider";
import FreeFormPolyMenu from "../../components/ObjectPropertyBox/components/FreeFormPolyMenu";

export const AdvancedPanel = () => {
  const { selectedObjects } = useObjectsState();
  const [tl] = useTimeline();
  const currentTime = tl?.scrubbingCurrentTime;
  const [showPanoramicViewMenu, setShowPanoramicViewMenu] = useState<boolean>(true);
  const [showBlurMenu, setShowBlurMenu] = useState<boolean>(true);
  const [showFreeFormSVGSettings, setShowFreeFormSVGSettings] = useState<boolean>(true);
  const selectedObject = selectedObjects[0];
  const isSmartObject = selectedObject?.type === "smartObject";
  const isImage = selectedObject?.type === "pageImage";
  const isPanoramic = selectedObject?.type === "panoramic";
  const isFreeFormPoly = selectedObject?.type === "freeFormPoly";
  const isAnnonationShape =
    selectedObject?.type === "circle" ||
    selectedObject?.type === "square" ||
    selectedObject?.type === "roundedSquare" ||
    selectedObject?.type === "triangle" ||
    selectedObject?.type === "freeFormPoly";

  return (
    <div
      className={styles.padding}
      style={{
        overflowY: "auto",
        height: "100%",
      }}
    >
      {isSmartObject && (
        <>
          <SmartObjectAdvancedPanel objectId={selectedObject.objectId} />
        </>
      )}
      {isImage && (
        <>
          <DropSeparator title="Blur Settings" setShowMenu={setShowBlurMenu} showMenu={showBlurMenu} />
          <section className="property-box-panel">
            {showBlurMenu && isAnnonationShape && <BlurInput selectedObject={selectedObject} />}
            {showBlurMenu && isImage && <BlurCutoutInput selectedObject={selectedObject} isModalShown />}
          </section>
        </>
      )}
      {isPanoramic && (
        <>
          <Section title={"Camera view"} wrap>
            <CameraViewInstructions />
          </Section>
          <DropSeparator
            title="Camera settings"
            setShowMenu={setShowPanoramicViewMenu}
            showMenu={showPanoramicViewMenu}
          />
          {showPanoramicViewMenu && <PanoramicViewMenu />}
        </>
      )}
      {isAnnonationShape && (
        <>
          <DropSeparator title="Blur Settings" setShowMenu={setShowBlurMenu} showMenu={showBlurMenu} />
          {showBlurMenu && <BlurInput selectedObject={selectedObject} currentTime={currentTime} />}
        </>
      )}
      {isFreeFormPoly && <FreeFormPolyMenu />}
    </div>
  );
};
