import React, { useContext, useEffect, useState } from "react";
import { FmsContext, IFmsContext } from "../../Interfaces/fmsContext";
import { successCriteriaObject, highlightFormProps } from "../../Interfaces/successCriteriaInterfaces";
import "./highlightForm.css";

const HighlightingForm: React.FC<highlightFormProps> = (props: highlightFormProps) => {
  const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);

  const [highlightList, setHighlightList] = useState<string[]>([]);
  useEffect(() => {
    const getAllHighlights = () => {
      const groupNames: string[] = [];
      fmsContext.currentCDU.groupingObjects.map((group) => {
        groupNames.push(group.id);
      });
      fmsContext.currentCDU.polyGroupings.map((polyGroup: any) => {
        groupNames.push(polyGroup.cduKeyName);
      });
      groupNames.push("Scratchpad");
      return groupNames;
    };

    setHighlightList(getAllHighlights());
  }, []);

  useEffect(() => {
    if (fmsContext.selectedKey && fmsContext.selectedKey.length > 0) {
      if (fmsContext.selectedKey !== "") {
        // let newSequence = props.displaySuccessMatch.join()+props.selectedKey;
        //props.matchSuccess(newSequence);
        props.selectedKeyHighlight(fmsContext.selectedKey);
      }
    }
  }, [fmsContext.selectedKey]);

  const textHighlight = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();

    props.textInputHightlight(event.target.value.toUpperCase());
  };
  const clickHighlight = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (fmsContext.highlightedGroups.includes(event.currentTarget.value)) {
      props.removeHighlight(event.currentTarget.value);
    } else {
      props.pushHighlight(event.currentTarget.value);
    }
  };

  return (
    <>
      <div className="highlightRow">
        <div className="highlightMenu">
          <p>Current Highlighting: </p>
          <textarea
            className="spacedInput highlighting"
            placeholder="Type a keygroup, individual key or text line name here or select a key"
            value={props.displayHighlight}
            onChange={textHighlight}
            onBlur={() => {
              props.cleanupBlankMatchSuccess("highlights", props.highlights);
            }}
          ></textarea>
        </div>

        <div className="highlightMenu">
          <p>Highlight Groups:</p>
          <div className="highlightContainer">
            {highlightList.map((group, index) => (
              <button
                className={fmsContext.highlightedGroups.includes(group) ? "selectedHighlight" : "highlightButton"}
                onClick={clickHighlight}
                key={index}
                value={group}
              >
                {group.replace(/_/g, " ")}
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default HighlightingForm;
