import React, { useMemo } from "react";

import { snapToTimeline, useTimeline } from "../../../../contexts/TimelineProvider/TimelineProvider";
import { BAR_HEIGHT } from "../../TimelinePanel";
import "./index.css";
import { useDomRef } from "../../../../hooks/useDomRef";
import useDrag from "../../../../hooks/useDrag";
import classes from "../../../../utils/HTML/classes";
import { useShallow } from "zustand/react/shallow";
import { useAudioManagerStore } from "../../../../contexts/PageAudioManager";
import { NarrationGroupedObject, ConflictType, useNarrationStore } from "../CompositeNarrationRow/store";
import { SecondaryAudioWaveBar } from "../SecondaryAudioWaveBar";
import { useObjectsUtils } from "../../../../contexts/ObjectsProvider";
import { AudioPopoverAction } from "../CompositeNarrationRow/types";
import { AddAudioButton } from "../CompositeNarrationRow/AddAudioButton";

export interface InteractiveAudioGroupProps {
  groupObject: NarrationGroupedObject;
  onMoved: (newStart: number) => void;
  onClickAdd: (targetObjectId: string, action: AudioPopoverAction, close: () => void) => void;
}

export const InteractiveAudioGroup = ({ groupObject, onClickAdd, onMoved }: InteractiveAudioGroupProps) => {
  const [, , { clippedSpaceFromUnitSpace, clippedSpaceToUnitSpace }] = useTimeline();
  const [audioBarRef, setRef] = useDomRef();
  const [objects, setDragging, isDragging, setCurrentPosition, setConflict, conflictType, conflictObject] =
    useNarrationStore(
      useShallow((state) => [
        state.objects,
        state.setDragging,
        state.isDragging,
        state.setCurrentPosition,
        state.setConflict,
        state.conflictType,
        state.conflictObject,
      ]),
    );
  const { unselectAll } = useObjectsUtils();
  const [selectedAudioId, selectAudio, clearSelection] = useAudioManagerStore(
    useShallow((state) => [state.selectedAudio, state.selectAudio, state.clearSelection]),
  );
  const [primaryAudio, secondaryAudio] = useAudioManagerStore(
    useShallow((state) => [
      state.getNarrationAudio(groupObject.primaryId),
      state.getNarrationAudio(groupObject.secondaryId ?? ""),
    ]),
  );

  const otherAudios = useMemo(
    () => [...objects.values()].filter((item) => item.primaryId !== groupObject.primaryId),
    [objects],
  );

  const handleAddAudio = (action: AudioPopoverAction, close: () => void) => {
    onClickAdd(groupObject.primaryId, action, close);
  };

  const handleSelectAudio = (objectId: string, unselect?: boolean) => {
    if (unselect) {
      clearSelection();
    } else {
      selectAudio(objectId);
    }
    unselectAll();
  };

  const start = groupObject.start;

  const clippedStart = clippedSpaceFromUnitSpace(start ?? 0);

  const [draggingMiddle] = useDrag(audioBarRef, {
    debugName: "InteractiveAudioGroup",
    onDragStart() {
      const initialPosition = clippedStart;
      let finalUnitPosition: number;

      return {
        onDrag(dx) {
          if (!audioBarRef) return;
          setDragging(groupObject.primaryId);

          const newPosition = initialPosition + dx;
          finalUnitPosition = clippedSpaceToUnitSpace(newPosition);
          setCurrentPosition(clippedSpaceToUnitSpace(newPosition));

          // Check here if we are hovering other audios
          let noConflicts = true;
          otherAudios.forEach((otherAudio) => {
            const leftSideConflict =
              finalUnitPosition > otherAudio.start &&
              finalUnitPosition < otherAudio.start + (otherAudio.end - otherAudio.start) / 2;
            const rightSideConflict =
              finalUnitPosition > otherAudio.start + (otherAudio.end - otherAudio.start) / 2 &&
              finalUnitPosition < otherAudio.end;

            if (leftSideConflict) {
              setConflict(otherAudio.primaryId, ConflictType.LEFT);
              noConflicts = noConflicts && false;
            } else if (rightSideConflict) {
              noConflicts = noConflicts && false;
              setConflict(otherAudio.primaryId, ConflictType.RIGHT);
            }
          });

          if (noConflicts) {
            setConflict(null);
          }

          audioBarRef.style.left = newPosition + "px";
          audioBarRef.classList.add("narration-grouped-audios--dragging");
        },
        onDragEnd(dragHappened, mouseEvent) {
          if (!dragHappened) return;
          if (!audioBarRef) return;

          let snappedUnitPosition = snapToTimeline(finalUnitPosition);
          if (snappedUnitPosition < 0) {
            snappedUnitPosition = 0;
          }

          const newClippedSpace = clippedSpaceFromUnitSpace(primaryAudio?.start ?? 0);

          audioBarRef.style.left = newClippedSpace + "px";

          audioBarRef.classList.remove("narration-grouped-audios--dragging");
          onMoved(snappedUnitPosition);

          // mouseEvent
          if (mouseEvent) {
            mouseEvent.stopPropagation();
            mouseEvent.preventDefault();
          }

          setDragging(null);
        },
        onClick(mouseUpEvent) {
          mouseUpEvent.preventDefault();
          mouseUpEvent.stopPropagation();
        },
      };
    },
  });

  const isConflict = groupObject.primaryId === conflictObject;
  const cursorLock = draggingMiddle
    ? conflictObject && conflictType === ConflictType.LEFT
      ? "url('/Mouse/snap-left.svg') 10 5, auto"
      : conflictObject && conflictType === ConflictType.RIGHT
      ? "url('/Mouse/snap-right.svg') 10 5, auto"
      : "url('/Mouse/move-row.svg') 10 5, auto"
    : "";

  if (!primaryAudio) {
    return null;
  }

  const groupWidth = clippedSpaceFromUnitSpace(groupObject.end) - clippedSpaceFromUnitSpace(groupObject.start);

  return (
    <div
      className={classes("narration-grouped-audios", {
        "narration-grouped-audios--dragging": draggingMiddle,
        "narration-grouped-audios--left": isConflict && conflictType === ConflictType.LEFT,
        "narration-grouped-audios--right": isConflict && conflictType === ConflictType.RIGHT,
      })}
      ref={setRef}
      style={{
        left: clippedStart,
        width: `${groupWidth}px`,
      }}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <AddAudioButton handleAddAudio={handleAddAudio} disabled={isDragging} />

      <div
        className={classes("pointer-events center audio-bar-middle-handle", {
          "pointer--dragging": !draggingMiddle,
        })}
        style={{
          height: BAR_HEIGHT * 2,
          cursor: cursorLock,
        }}
      >
        <div className="drop-indicator drop-indicator--left"></div>

        <SecondaryAudioWaveBar
          objectId={groupObject.primaryId}
          onSelect={(unselect) => {
            handleSelectAudio(groupObject.primaryId, unselect);
          }}
          selected={selectedAudioId === groupObject.primaryId}
        />

        {secondaryAudio && (
          <SecondaryAudioWaveBar
            objectId={secondaryAudio.objectId}
            onSelect={(unselect) => groupObject.secondaryId && handleSelectAudio(groupObject.secondaryId, unselect)}
            selected={selectedAudioId === groupObject.secondaryId}
          />
        )}

        <div className="drop-indicator drop-indicator--right"></div>
      </div>
    </div>
  );
};
