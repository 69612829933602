import React, { useRef, useState, useEffect } from "react";
import { extend, useFrame, useThree } from "@react-three/fiber";
import "./hsEditor.css";

import {
  Vector3
} from "three"

import {
  OrbitControls,
  TransformControls
} from "@react-three/drei"

const Controls = (props) => {
  const { camera } = useThree();
  const { editMode } = props


  const newHSref = useRef({rotation: [0,0,0], position: [0,0,0], geometry: [1,32,32]});
  const sentHSref = useRef({rotation: [0,0,0], position: [0,0,0], geometry: [1,32,32]});

  const camControls = useRef();
  useEffect(() => {
    if(camControls.current){
      camControls.current.listenToKeyEvents(window);
    }
  }, [camControls.current])
  

  useEffect(() => {
    camera.position.set(0, 200, 200);
    camera.rotation.set(40, 10, 20);
    camera.far = 5000;
    camera.near = 0.01;
    camera.fov = 75;
    newHSref.current.scale.set(30, 30, 30);
    newHSref.current.position.set(0, 150, 0);
    if (props.currentHotSpot) {
      if (
        props.currentHotSpot.geometry[0] > 0 &&
        props.currentHotSpot.geometry[1] > 0 &&
        props.currentHotSpot.geometry[2] > 0
      ) {
        newHSref.current.scale.set(
          props.currentHotSpot.geometry[0],
          props.currentHotSpot.geometry[1],
          props.currentHotSpot.geometry[2],
        );
        newHSref.current.position.set(
          props.currentHotSpot.position[0],
          props.currentHotSpot.position[1],
          props.currentHotSpot.position[2],
        );
        newHSref.current.rotation.set(
          props.currentHotSpot.rotation[0],
          props.currentHotSpot.rotation[1],
          props.currentHotSpot.rotation[2],
        );
      }
    }
    sentHSref.current = newHSref.current;
    return () => {
      props.returnNewHotSpot({
        key: "active",
        position: [sentHSref.current.position.x, sentHSref.current.position.y, sentHSref.current.position.z],
        rotation: [sentHSref.current.rotation.x, sentHSref.current.rotation.y, sentHSref.current.rotation.z],
        geometry: [sentHSref.current.scale.x, sentHSref.current.scale.y, sentHSref.current.scale.z],
      });
    };
  }, []);

  useFrame(({ camera }) => {
    camera.updateProjectionMatrix(
      void ((camControls.current.target = newHSref.current.position),
      camera.lookAt(newHSref.current.position),
      camControls.current.update()),
    );
  });
  
  let nudge = 1;
  const nudgeUp = () => {
    newHSref.current.position.y += nudge;
  };
  const nudgedown = () => {
    newHSref.current.position.y -= nudge;
  };
  const nudegeleft = () => {
    newHSref.current.position.x -= nudge;
  };
  const nudgeRight = () => {
    newHSref.current.position.x += nudge;
  };
  const nudgeForward = () => {
    newHSref.current.position.z -= nudge;
  };
  const nudgeBack = () => {
    newHSref.current.position.z += nudge;
  };
  const tiltForward = () => {
    newHSref.current.rotation.x += 0.196349;
  };
  const tiltBack = () => {
    newHSref.current.rotation.x -= 0.196349;
  };
  const increaseNudge = () => {
    if (nudge < 9.9) {
      nudge += 0.5;
    }
  };
  const decreaseNudge = () => {
    if (nudge > 0.49) {
      nudge -= 0.5;
    }
  };

  const moveHS = (dir) => {
    const keyPanSpeed = 7;

    // let direction = new Vector3();
    // camera.getWorldDirection(direction);
    // direction = direction.multiplyScalar(2);
    // switch(dir) {
    //   case "forward":
    //     newHSref.current.position.x += direction.x
    //     newHSref.current.position.z += direction.z
    //     break;
    //   case "back":
    //     newHSref.current.position.x -= direction.x
    //     newHSref.current.position.z -= direction.z
    //     break;
    //   case "left":
    //     newHSref.current.position.x += direction.x
    //     newHSref.current.position.z -= direction.z
    //     break;
    //   case "right":
    //     break;
    // }
  }

  const handleKeyPress = (event) => {
    if (event) {
      event.preventDefault();
      switch (event.key.toLowerCase()) {
        case "q": {
          nudgeUp();
          break;
        }
        case "z": {
          nudgedown();
          break;
        }
        case "a": {
          nudegeleft();
          break;
        }
        case "d": {
          nudgeRight();
          break;
        }
        case "w": {
          nudgeForward();
          break;
        }
        case "x": {
          nudgeBack();
          break;
        }
        case "e": {
          tiltForward();
          break;
        }
        case "c": {
          tiltBack();
          break;
        }
        case "+": {
          increaseNudge();
          break;
        }
        case "-": {
          decreaseNudge();
          break;
        }
        case "arrowup": {
          moveHS("forward");
          break;
        }
        case "arrowdown": {
          moveHS("back");
          break;
        }
        case "arrowleft": {
          moveHS("left");
          break;
        }
        case "arrowright": {
          moveHS("right");
          break;
        }
        default: {
          break;
        }
      }
      // sentHSref.current = newHSref.current;
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    sentHSref.current = newHSref.current;
  }, [newHSref.current.position])

  return (
    <>
      <mesh ref={newHSref}>
        <boxGeometry attach="geometry" args={[1, 1, 1]} />
        <meshNormalMaterial attach="material" color="red" />
      </mesh>

      <OrbitControls
        ref={camControls}
        makeDefault
        enableDamping={false}
        screenSpacePanning={false}
        onChange={() => {
          newHSref.current.position.y = newHSref.current.position.y;
        }}
      />
      <TransformControls 
        onChange={(e) => {
          if(e.target.dragging) {
            sentHSref.current = newHSref.current;
          }
        }}
        mode={editMode}
        object={newHSref}
      />
    </>
  );
};

export default Controls;
