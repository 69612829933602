export function BlurCutoutComponent({ imagePath, cutoutId }: { imagePath: string; cutoutId: string }) {
  return (
    <>
      <img
        id={`ff-image-${cutoutId}-blur-cutout`}
        src={imagePath}
        alt=""
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          maskImage: `url(#${cutoutId})`,
          maskComposite: "add",
          height: "100%",
          width: "100%",
          zIndex: 2000,
          userSelect: "none",
          pointerEvents: "none",
        }}
      />
    </>
  );
}
