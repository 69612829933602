import React from "react";

const AnnotationSquareLetter = () => {
  return (
    <React.Fragment>
      <span className="icon-square-letter"></span>
    </React.Fragment>
  );
};

export default AnnotationSquareLetter;
