import React, { useContext } from "react";
import { IRTEModule } from "../BasePageDesigner";
import { PageContext, IPageContext } from "../../../routes/builderContexts";
import _ from "lodash";

// import IBasePage from '../components/IBasePage';

type BaseProps = {
  moduleRTE: IRTEModule[];
  placeholderText: string;
  updatedManifest: any;
  textBlockIndex: number;
  assignNewText: (newText: string) => void;
  displayImage: (imageIndex: number) => JSX.Element;
  handleImageDivClick: (index: number) => void;
  // selectImages: () => Promise<void>,
};

const BaseTrippleGraphicSplitLeft: React.FC<BaseProps> = (props: any) => {
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const updatedManifest: any = _.cloneDeep(pageContext.pageManifest);
  if (!pageContext.pageManifest.pageImage[2]) {
    updatedManifest.pageImage[2] = pageContext.pageManifest.pageImage[1];
    pageContext.updatePageManifest(updatedManifest);
  }
  return (
    <div className="graphic-center-container-row">
      <div className="double-graphic-center-horiz-image-container" onClick={() => props.handleImageDivClick(0)} id="2">
        <div className="img-aspect-container">
          <div className="img-aspect-container-inside">
            <div className="img-aspect-centering">{props.displayImage(0)}</div>
          </div>
        </div>
      </div>

      <div className="tripple-graphic-column">
        <div className="double-graphic-center-image-container" onClick={() => props.handleImageDivClick(1)} id="0">
          <div className="img-aspect-container">
            <div className="img-aspect-container-inside">
              <div className="img-aspect-centering">{props.displayImage(1)}</div>
            </div>
          </div>
        </div>
        <div className="double-graphic-center-image-container" onClick={() => props.handleImageDivClick(2)} id="1">
          <div className="img-aspect-container">
            <div className="img-aspect-container-inside">
              <div className="img-aspect-centering">{props.displayImage(2)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BaseTrippleGraphicSplitLeft;
