export enum MetadataEditorActions {
  SELECT_ASSET = "SELECT_ASSET",
  OPEN_ASSET_PREVIEW = "OPEN_ASSET_PREVIEW",
  CLOSE_ASSET_PREVIEW = "CLOSE_ASSET_PREVIEW",
  SELECT_METADATA = "SELECT_METADATA",
  SELECT_ALL_ASSETS = "SELECT_ALL_ASSETS",
  SUBMIT_ASSETS = "SUBMIT_ASSETS",
  UPDATE_TAGS = "UPDATE_TAGS",
  ADD_KEY_VALUE_TAG = "ADD_KEY_VALUE_TAG",
  REMOVE_KEY_VALUE_TAG = "REMOVE_KEY_VALUE_TAG",
  ADD_DEFAULT_TAG = "ADD_DEFAULT_TAG",
  REMOVE_DEFAULT_TAG = "REMOVE_DEFAULT_TAG",
}
