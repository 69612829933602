import _ from "lodash";
import React, { useContext, useState } from "react";
//import { Modal, ModalBody } from 'react-bootstrap';
import { BoundType } from "../../../../react-moveable";
import { IFmsPageManifest, IFMSReferenceImage } from "../../../../../pageTypes/FMS_Player/Interfaces/IFmsPageManifest";
import { IPageContext, PageContext } from "../../../../../routes/builderContexts";
//import AddReferenceImage from './AddReferenceImage';
//import blobHelper from '../../../../blobUrlFormatHelper';
//import WYSIWYG from '../../../WYSIWYG';

import StandardFMSModal from "./FMSModalComponents/StandardFMSModal";
import ReferenceTabFMSModal from "./FMSModalComponents/ReferenceTabFMSModal";

type ModalType = {
  imageSource: string;
  isModalShown: boolean;
  version: number;
  setIsModalShown: (bool: boolean) => void;
  referenceTab: boolean;
  removeImage: (e: any, assetVersionId: number, index: number) => void;
  assetVersionId: number;
  newPageImageId: number;
  setNewPageImageId: (value: number) => void;
};
// interface modalComponentProps {
//   isModalShown: boolean,
//   handleHide: () => void,
//   setIsModalShown: (value: boolean) => void,
//   referenceBounds: BoundType,
//   pageDims: DOMRect,
//   imageRef: React.RefObject<HTMLDivElement>,
//   handleLoad: (e: React.SyntheticEvent<HTMLDivElement>) => void,
//   imageSource: string
// }

const FMSReferenceImageModal = (props: ModalType) => {
  const { imageSource, isModalShown, version, setIsModalShown, assetVersionId } = props;
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const imageRef: React.RefObject<HTMLDivElement> = React.useRef(null);
  const [referenceBounds, setReferenceBounds]: [BoundType, React.Dispatch<React.SetStateAction<BoundType>>] =
    useState<BoundType>({ top: 0, bottom: 0, left: 0, right: 0 });
  const [pageDims, setPageDims]: [DOMRect | undefined, React.Dispatch<React.SetStateAction<DOMRect | undefined>>] =
    useState<DOMRect | undefined>();
  // let rect: DOMRect | undefined = imageRef.current?.getBoundingClientRect();

  const handleLoad = (e: React.SyntheticEvent<HTMLDivElement>) => {
    const imgRect: DOMRect = e.currentTarget.getBoundingClientRect();

    const bounds = {
      top: 0,
      left: 0,
      bottom: imgRect.height,
      right: imgRect.width,
    };
    setReferenceBounds(bounds);
    setPageDims(imageRef.current?.getBoundingClientRect());
    if (isModalShown) {
      addImageHeightIfExists(imgRect.height);
    }
  };

  const handleHide = () => {
    // rect = undefined;
    setIsModalShown(false);
  };

  function addImageHeightIfExists(height: number) {
    if (
      !_.has(pageContext.pageManifest, "version") ||
      pageContext.pageManifest.version < version ||
      pageContext.pageManifest.pageImage?.height !== height
    ) {
      const newManifest: IFmsPageManifest = _.cloneDeep(pageContext.pageManifest);
      newManifest.version = version;
      (newManifest.pageImage as IFMSReferenceImage[])[0].height = height;
      pageContext.updatePageManifest(newManifest);
    }
  }

  return props.referenceTab ? (
    <ReferenceTabFMSModal
      isModalShown={isModalShown}
      setIsModalShown={setIsModalShown}
      handleHide={handleHide}
      handleLoad={handleLoad}
      referenceBounds={referenceBounds}
      pageDims={pageDims}
      imageRef={imageRef}
      imageSource={imageSource}
      removeImage={props.removeImage}
      assetVersionId={assetVersionId}
      newPageImageId={props.newPageImageId}
      setNewPageImageId={props.setNewPageImageId}
    />
  ) : (
    <StandardFMSModal
      isModalShown={isModalShown}
      setIsModalShown={setIsModalShown}
      handleHide={handleHide}
      handleLoad={handleLoad}
      referenceBounds={referenceBounds}
      pageDims={pageDims}
      imageRef={imageRef}
      imageSource={imageSource}
      removeImage={props.removeImage}
      assetVersionId={assetVersionId}
      newPageImageId={props.newPageImageId}
      setNewPageImageId={props.setNewPageImageId}
    />
  );
  // }
};

export default FMSReferenceImageModal;
