import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import { handleKeyUp } from "../../../../../components/ObjectPropertyBox/functions/PropertyBoxFunctions";
import { TextPropType } from "../models/ICustomPage";
import RTE from "./BaseFFRTE";
import { Interweave } from "interweave";
import {
  BasePageTextBlockVersion,
  basePageTextBoxWrapperVersioning,
} from "../../../../../utils/Versioning/basePagesVersioning";
// import AnnotationTextMenu from '../../../../../components/Annotation/AnnotationTextMenu/AnnotationTextMenu';
import {
  InteractivityHotspotActionTypes,
  useInteracitvityHotspotDispatch,
} from "../../../../../contexts/InteractivityHotspotProvider";
import HightlightRect from "./HighlightRect";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../../../../contexts/ObjectsProvider";
import { colors, getFontsFromVersion } from "../../../../../components/Annotation/AnnotationTextMenu/constants";
import {
  ILessonAdditionalSettingsContext,
  LessonAdditionalSettingsContext,
} from "../../../../../routes/builderContexts";
import { useObjectPosition } from "../../../../../hooks/useObjectPosition";
import { useSelectedObjectState } from "../../../../../contexts/SelectedObjectProvider/SelectedObjectProvider";
import { useMovableElementsPlaneState } from "../../../../../contexts/MovableElementsPlaneProvider";
import { isNumber } from "../../../../../utils";
import { is } from "@react-spring/shared";
import {
  percentageToValue,
  xAxisViewPercentageToPixel,
  yAxisViewPercentageToPixel,
} from "../../../../../utils/Conversion";
import AnnotationHighlight from "./HighlightRect";
import { useObjectIsInTime } from "../../../../../hooks/useObjectIsInTime";

const { SET_CURRENT_HOTSPOT } = InteractivityHotspotActionTypes;
const BaseFFText = (props: TextPropType): JSX.Element => {
  const {
    lineHeight,
    index,
    kp,
    selectedFontSize,
    text,
    handleFormatChange,
    handleKeyPress,
    handleSelectionChange,
    editTextBlock,
    selectTarget,
    objectIsInTimeline,
    objectId,
    isDisplayed,
    version,
    top,
    left,
    width,
    height,
    rotate,
    zIndex,
    backgroundColor,
    borderColor,
    strokeWidth,
    shadowColor,
    textAlign,
    fontColor,
    textDecoration,
    fontWeight,
    fontStyle,
    fontFamily,
    ghost,
  } = props;
  const objectsDispatch = useObjectsDispatch();
  const {
    position: [x, y],
    size: [w, h],
    rotation,
  } = useObjectPosition(objectId, top, left, width, height, rotate);
  const { activeTextBox, selectedObjects } = useObjectsState();
  const {
    x: selectedX,
    y: selectedY,
    width: selectedWidth,
    height: selectedHeight,
    rotation: selectedRotation,
  } = useSelectedObjectState();
  const { viewportDOMElementHeight, viewportDOMElementWidth } = useMovableElementsPlaneState();
  useState<boolean>(false);

  const isActive = activeTextBox?.objectId === objectId;
  const isInTime = useObjectIsInTime(objectId);
  const textBoxRef = useRef<HTMLDivElement>(null);
  const StandardFormats = [
    "background",
    "bold",
    "color",
    "font",
    "italic",
    "link",
    "size",
    "strike",
    "script",
    "underline",
    "blockquote",
    "header",
    "indent",
    "list",
    "align",
    "direction",
    "image",
    "video",
  ];
  const quillRef: React.RefObject<any> = useRef<any>(null);
  useEffect(() => {
    const externalMouseDown = (e: any) => {
      objectsDispatch({
        type: ObjectActionsType.SET_ACTIVE_TEXT_BOX,
        payload: null,
      });
    };
    document.addEventListener("mousedown", externalMouseDown);
    return () => {
      document.removeEventListener("mousedown", externalMouseDown);
    };
  });

  const doTextClick = (e: React.MouseEvent<HTMLDivElement>) => {
    quillRef.current?.focus();
    objectsDispatch({
      type: ObjectActionsType.SET_ACTIVE_TEXT_BOX,
      payload: { objectId },
    });
    objectsDispatch({
      type: ObjectActionsType.SET_SELECTED_OBJECT,
      payload: null,
    });
  };

  const onMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement>, annotationId: string) => {
    e.stopPropagation();
    if (!annotationId) return;

    let type = ObjectActionsType.SET_SELECTED_OBJECT;
    if (e.ctrlKey) {
      type = ObjectActionsType.ADD_SELECTED_OBJECT;
    }
    objectsDispatch({
      type,
      payload: { objectId: annotationId },
    });
  }, []);
  const moveableContainerMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    onMouseDown(e, objectId);
  };
  const thereIsASelectedObject = selectedObjects.length > 0;
  const opacity = isInTime ? 1 : ghost ? 0.25 : 0;
  let menuPositionY = thereIsASelectedObject ? selectedY : y;
  let menuPositionX = thereIsASelectedObject ? selectedX + selectedWidth / 2 : x + selectedWidth / 2;
  const menuHeight = 38;
  const menuWidth = 483;
  const topTrigger = 95;
  const rightTrigger = (viewportDOMElementWidth ?? 0) - menuWidth;

  const xx = thereIsASelectedObject ? selectedX : x;
  const yy = thereIsASelectedObject ? selectedY : y;
  const ww = thereIsASelectedObject ? selectedWidth : w;
  const hh = thereIsASelectedObject ? selectedHeight : h;
  const rr = thereIsASelectedObject ? selectedRotation : rotation;
  const rad = (rr * Math.PI) / 180; // convert to radians
  // find the center of the box
  const cx = xx + ww / 2;
  const cy = yy + hh / 2;

  // find the original unrotated corners
  const corners = [
    { x: xx, y: yy },
    { x: xx + ww, y: yy },
    { x: xx + ww, y: yy + hh },
    { x: xx, y: yy + hh },
  ];
  // rotate each corner around the center of the box
  const rotatedCorners = corners.map((corner) => {
    const dx = corner.x - cx;
    const dy = corner.y - cy;

    return {
      x: dx * Math.cos(rad) - dy * Math.sin(rad) + cx,
      y: dx * Math.sin(rad) + dy * Math.cos(rad) + cy,
    };
  });
  rotatedCorners.sort((a, b) => a.y - b.y || a.x - b.x);

  // // The topmost (and if tied, leftmost) corner is now the first item.
  const topCorner = rotatedCorners[0];
  if (selectedY < topTrigger) {
    menuPositionY = rotatedCorners[3].y + 10;
  } else {
    menuPositionY = topCorner.y - menuHeight - 42;
  }
  if (selectedX + ww / 2 > rightTrigger) {
    menuPositionX = menuPositionX - 400;
  }

  return (
    <>
      <AnnotationTextMenu
        formats={StandardFormats}
        // selectedFontSize={selectedFontSize}
        // handleFormatChange={handleFormatChange}
        //this is the first time we are adding version
        //the presence of lineHeight will indicate different
        //behaviors in the rich text editor as a boolean flag
        objectId={objectId}
        version={props.version}
        hide={objectId !== activeTextBox?.objectId}
        id={`text-menu-${objectId}`}
        style={{
          position: "absolute",
          transform: `translate(${menuPositionX}px, ${menuPositionY}px)`,
        }}
      />
      <div
        ref={textBoxRef}
        key={index}
        id={`text-target-${index}`}
        data-objectid={objectId}
        className="target ff-text-box"
        onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
          e.stopPropagation();
        }}
        style={{
          width: `${w}px`,
          height: `${h}px`,
          zIndex: isActive ? 3000 : zIndex,
          transform: `translate(${x}px, ${y}px) rotate(${rotation}deg)`,
          backgroundColor: `${backgroundColor}`,
          outline: borderColor ? `${strokeWidth}px solid ${borderColor}` : "none",
          boxShadow: `${shadowColor}`,
          textAlign: textAlign,
          color: `${fontColor}`,
          textDecoration: `${textDecoration}`,
          fontWeight: fontWeight,
          fontStyle: fontStyle,
          fontFamily: fontFamily,
          opacity,
          filter: isDisplayed ? "none" : "grayScale(100%)",
        }}
      >
        {/* <HightlightRect attributes={ffElement as any} /> */}

        <AnnotationHighlight objectId={objectId} />
        <div
          className={"ff-text" + basePageTextBoxWrapperVersioning(props.version ?? "")}
          onClick={doTextClick}
          style={{
            outline: isActive ? "#ADFF2F dashed 2px" : "none",
            zIndex: 3002,
            position: "absolute",
            width: "100%",
            //if undefined lineHeight here then it wont be set and will default to css
            lineHeight,
          }}
        >
          <RTE
            quillRef={quillRef}
            index={index}
            displayText={text}
            objectId={objectId}
            placeholder={"Enter Text Here..."}
            onFocus={() => {
              quillRef.current?.focus();
              objectsDispatch({
                type: ObjectActionsType.SET_ACTIVE_TEXT_BOX,
                payload: { objectId },
              });
            }}
            //this is the first time we are adding version
            //the presence of lineHeight will indicate different
            //behaviors in the rich text editor as a boolean flag
            version={version}
            toolbarId={`#text-menu-${objectId}`}
            handleSelectionChange={handleSelectionChange}
            theme="snow"
          />
        </div>
        <div
          className="ff-text-div-2"
          style={{
            zIndex: 10,
          }}
          onMouseDown={moveableContainerMouseDown}
          tabIndex={0}
        ></div>
      </div>
    </>
  );
};

export default BaseFFText;

function AnnotationTextMenu(props: any) {
  const { bgColors, fontColors, formats } = props;
  const highlightRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const currentColors = useContext<ILessonAdditionalSettingsContext>(LessonAdditionalSettingsContext)?.getColorsList(
    colors,
  );
  return (
    <div
      className="text-format-menu"
      id={props.id}
      onMouseDown={(e) => e.stopPropagation()}
      onMouseUp={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
      style={{
        display: props.hide ? "none" : "flex",
        zIndex: 3010,
        ...props.style,
      }}
    >
      <FontSizeButton version={props.version} />
      <FontColorButton colors={fontColors ? fontColors : currentColors} />
      <TextHighlightButton colors={bgColors ? bgColors : currentColors} highlightRef={highlightRef} />
      <FontStyleButtons />
      <AlignButtons />
      <ListButtons />
      <button className="ql-clearFormat">
        {/* <ClearFormatting  title='Remove Formatting: (Shift + Alt + F) removes all formatting'/> */}
      </button>
    </div>
  );
}
type FontSizeType = {
  fontSizes?: Array<string>;
  version?: BasePageTextBlockVersion | string;
};

function ListButtons() {
  return (
    <React.Fragment>
      <button className="ql-list wysiwyg-toolbar-btn" value="bullet">
        <span className="icon-text-bullet-points-icon"></span>
      </button>
      <button className="ql-list wysiwyg-toolbar-btn" value="ordered">
        <span className="icon-text-numbers-icon"></span>
      </button>
    </React.Fragment>
  );
}

function AlignButtons() {
  return (
    <React.Fragment>
      <button id="left" className={`ql-align wysiwyg-toolbar-btn`} value="">
        <span className="icon-text-align-left-icon"></span>
      </button>
      <button id="center" className={`ql-align wysiwyg-toolbar-btn`} value="center">
        <span className="icon-text-align-center-icon"></span>
      </button>
      <button id="right" className={`ql-align wysiwyg-toolbar-btn`} value="right">
        <span className="icon-text-align-right-icons"></span>
      </button>
    </React.Fragment>
  );
}
function FontStyleButtons() {
  return (
    <React.Fragment>
      <button id="bold" className={`ql-bold wysiwyg-toolbar-btn`} type="button">
        <span className="icon-bold-icon"></span>
      </button>
      <button className={`ql-italic wysiwyg-toolbar-btn`}>
        <span className="icon-italic-icon"></span>
      </button>
      <button className={`ql-underline wysiwyg-toolbar-btn`}>
        <span className="icon-underline-icon"></span>
      </button>
    </React.Fragment>
  );
}

function TextHighlightButton({
  colors,
  highlightRef,
}: {
  colors: string[];
  highlightRef: React.RefObject<HTMLDivElement>;
}) {
  return (
    <div ref={highlightRef} className="toolbar-btn-container">
      <select className="ql-background wysiwyg-toolbar-btn">
        {colors.map((color) => {
          return <option key={color} value={`${color}`}></option>;
        })}
      </select>
    </div>
  );
}
function FontColorButton({ colors }: { colors: string[] }) {
  function makeSelectColors() {
    return colors.map((color) => {
      return <option key={color} value={`${color}`}></option>;
    });
  }

  return (
    <div className="toolbar-btn-container">
      <select className="ql-color wysiwyg-toolbar-btn">{makeSelectColors()}</select>
    </div>
  );
}
function FontSizeButton(props: FontSizeType) {
  const fontSizes: string[] = getFontsFromVersion(props.version);
  //please let austin know if the names need to be modified (not added)
  const sizeMap = {
    [fontSizes[0]]: "tiny",
    [fontSizes[1]]: "tiny2",
    [fontSizes[2]]: "small",
    [fontSizes[3]]: "small2",
    [fontSizes[4]]: "small3",
    [fontSizes[5]]: "normal",
    [fontSizes[6]]: "normal2",
    [fontSizes[7]]: "normal3",
    [fontSizes[8]]: "large",
    [fontSizes[9]]: "huge",
  };

  return (
    <React.Fragment>
      <select className="ql-size wysiwyg-toolbar-btn" value={fontSizes[5]} onChange={() => {}}>
        {fontSizes.map((size, i) => {
          return (
            <option key={size + i + "sd"} value={fontSizes[i]}>
              {sizeMap[size]}
            </option>
          );
        })}
      </select>
    </React.Fragment>
  );
}
