import React from "react";
import { SVGAnnotationProps } from "../index";
import { HighlightAnnotation } from "../HighlightAnnotation";

export function SVGCoursePointer2({
  onMouseDown,
  objectId,
  y,
  x,
  width,
  height,
  isDisplayed,
  zIndex,
  borderColor,
  strokeWidth,
  backgroundColor,
  opacity,
  rotate,
}: // transform,
SVGAnnotationProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13.104153 9.2434324"
      key={objectId}
      data-objectid={objectId}
      id={`symbol-${objectId}`}
      tabIndex={0}
      className="symbol target"
      name="symbol"
      onMouseDown={(e: React.MouseEvent<SVGSVGElement>) => {
        onMouseDown(e, objectId);
      }}
      preserveAspectRatio="xMidYMid meet"
      style={{
        // top: `${y}px`,
        // left: `${x}px`,
        width: `${width}px`,
        height: `${height}px`,
        zIndex: zIndex,
        transform: `translate(${x}px, ${y}px) rotate(${rotate ?? 0}deg)`,
        opacity: isDisplayed ? opacity : 0.5,
        filter: isDisplayed ? "none" : "grayScale(100%)",
        // `rotate(${attributes?.transform?.rotate}deg) scaleX(${attributes?.transform?.scaleX}) scaleY(${attributes?.transform?.scaleY}) matrix3d(${attributes?.transform?.matrix3d})`
      }}
    >
      <HighlightAnnotation objectId={objectId} />
      <path
        stroke={`${borderColor || "white"}`}
        fill={`${backgroundColor || "transparent"}`}
        strokeWidth={`${strokeWidth / 10 || 0.1}`}
        // className="selected_course_pointer_1"
        d="M 12.58,0.56 8.56,0.8 4.15,3.11 3.36,1.54 1.52,2.47 2.32,4.06 0.68,4.92 2.28,7.93 3.85,7.11 4.77,8.94 6.61,8.02 5.68,6.15 10.16,3.81 Z"
      />
    </svg>
  );
}
