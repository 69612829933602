import React, { useState, useContext } from "react";
import { ILessonPageActionsContext, LessonPageActionsContext } from "../routes/builderContexts";
import { AbstractTooltip as Tooltip } from "./ToolTip/ToolTip";
import { useObjectsState } from "../contexts/ObjectsProvider";

import { ReactComponent as PreviewLessonIcon } from "../assets/icons/HeaderIcons/preview-lesson-icon.svg";
import { ReactComponent as ArrowIcon } from "../assets/icons/HeaderIcons/drop-down.svg";

export interface LessonPreviewProps {
  lessonVersionId?: number;
}

const LessonPreview = ({ lessonVersionId }: LessonPreviewProps) => {
  const lessonPageActions: ILessonPageActionsContext = useContext<ILessonPageActionsContext>(LessonPageActionsContext);
  const [dropDownVisible, setDropDownVisible]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] =
    useState<boolean>(false);
  const link = "/api/PassThru/cpat/lesson/lessonpreview/" + lessonVersionId + ".preview/index.html";
  const objectsState = useObjectsState();
  const isDirty = objectsState?.needSave || lessonPageActions.dirt !== 0;
  const previewLinkI = link + "?clasmode=Instructional";
  const previewLinkP = link + "?clasmode=Practice";
  const previewLinkT = link + "?clasmode=Test";

  function canUse() {
    switch (lessonPageActions.previewMode) {
      case "Demo":
        return previewLinkI;
      case "Practice":
        return previewLinkP;
      case "Perform":
        return previewLinkT;
      default:
        return "";
    }
  }

  return (
    <div className="preview-component" data-tooltip-id="Preview Lesson">
      <Tooltip id="Preview Lesson" />
      <div className="previewButtonContainer">
        {!isDirty ? (
          <a href={canUse()} target="_blank" rel="noreferrer">
            {" "}
            <div className="header-icon">
              {objectsState.needSave ? (
                <PreviewLessonIcon className="disabled-preview" />
              ) : (
                <>
                  <PreviewLessonIcon />{" "}
                </>
              )}
            </div>
          </a>
        ) : (
          <div className="header-icon">
            <PreviewLessonIcon className="disabled-preview" />
          </div>
        )}
        <div className="preview-modes-dropdown-container" onClick={() => setDropDownVisible(!dropDownVisible)}>
          <p>{lessonPageActions.previewMode}</p>
          <ArrowIcon style={dropDownVisible ? { transform: "rotate(180deg)" } : {}} className="header-preview-svg" />
          <div className="preview-modes-selection-container" style={dropDownVisible ? {} : { display: "none" }}>
            <div className="preview-modes-selection-choices" onClick={() => lessonPageActions.setPreviewMode("Demo")}>
              Demo
            </div>
            <div
              className="preview-modes-selection-choices"
              onClick={() => lessonPageActions.setPreviewMode("Practice")}
            >
              Practice
            </div>
            <div
              className="preview-modes-selection-choices"
              onClick={() => lessonPageActions.setPreviewMode("Perform")}
            >
              Perform
            </div>
          </div>
        </div>
      </div>
      <p>Lesson Preview {isDirty && "(Save Lesson)"}</p>
    </div>
  );
};

export default LessonPreview;
