import type { IInteractiveAudio } from "../../models/IInteractiveAudio";
import pick from "lodash/pick";

export const audioManifestPropertiesWhitelist = <TAudioType extends IInteractiveAudio>(
  audio: TAudioType,
): TAudioType => {
  if ("ttsProps" in audio) {
    audio.ttsProps = pick(audio.ttsProps, ["language", "voice", "prosodyRate", "narrationText", "pronunciationText"]);
  }

  return pick(audio, [
    "objectId",
    "type",
    "start",
    "end",
    "duration",
    "input",
    "savedText",
    "title",
    "description",
    "language",
    "parentObjectId",
    "ttsProps",
  ]) as TAudioType;
};

const ensureRelativePath = (path: string) => {
  if (path[0] === "/") {
    return path.substring(1);
  } else {
    return path;
  }
};

const ASSET_API_ENDPOINT = "/api/Asset";
/**
 * WARNING:
 *  When saving AUDIO url in the manifest we need to keep it "relative"
 *  that's needed for LessonPlayer and other services
 *  They expect it to be appended to their current path
 *  Do NOT save it as absolute it is going to crash lesson player
 */
export const audioBlobToApi = (url: string) =>
  url.includes(ASSET_API_ENDPOINT.substring(1))
    ? url
    : `/${ASSET_API_ENDPOINT}/${url}`.replace(new RegExp("//", "g"), "/");
export const audioApiToBlob = (url: string) =>
  ensureRelativePath(url.replace(new RegExp(ASSET_API_ENDPOINT, "gmi"), ""));
