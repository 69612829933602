import React, { useState, useEffect, useMemo } from "react";
import axios, { AxiosRequestConfig } from "axios";
import AssetLibraryFilter from "./AssetLibraryFilter";
import AssetLibraryListView from "./AssetLibraryListView";
import AssetLibraryListViewLoading from "./AssetLibraryListView.Loading";
import AssetLibraryDetailedView from "./AssetLibraryDetailedView";
import genericRepositoryService from "../../../services/genericRepositoryService";
import IGetFilteredAssetsRequest from "../../../models/IGetFilteredAssets/IGetFilteredAssetsRequest";
import IGetFilteredAssetsResponse from "../../../models/IGetFilteredAssets/IGetFilteredAssetsResponse";
import IAsset from "../../../models/IAsset";
import { ReactComponent as SearchIcon } from "../../../assets/icons/AssetManager/search-bar-icon.svg";
import { ReactComponent as ImageIcon } from "../../../assets/icons/AssetManager/type-file-image-icon-not-selected.svg";
import { ReactComponent as PanoramicIcon } from "../../../assets/icons/AssetManager/type-file-panoramic-icon.svg";
import { ReactComponent as PdfIcon } from "../../../assets/icons/AssetManager/type-file-pdf-icon-not-selected.svg";
import { ReactComponent as VideoIcon } from "../../../assets/icons/AssetManager/type-file-video-icon-not-selected.svg";
import { ReactComponent as PreviewIcon } from "../../../assets/icons/AssetManager/detail-view.svg";
import { ReactComponent as LeftArrowIcon } from "../../../assets/icons/AssetManager/pagination-arrow-left.svg";
import { ReactComponent as RightArrowIcon } from "../../../assets/icons/AssetManager/pagination-arrow-right.svg";
import { ReactComponent as InteractiveDiagramIcon } from "../../../assets/icons/AssetManager/interactive-diagram-icon.svg";
import { ReactComponent as SmartObjectIcon } from "../../../assets/icons/AssetManager/smart-object.svg";
import { ReactComponent as DoubleListViewIcon } from "../../../assets/icons/AssetManager/List-two-columns-icon-white.svg";
import { useAssetManagerState, useAssetManagerDispatch } from "../AssetManagerContext/AssetManagerProvider";
import * as AssetManagerTypes from "../AssetManager.types";
import AssetLibraryListViewDoubleColumn from "./AssetLibraryListViewDoubleColumn";
import { ASSET_TYPE_ID } from "../../../const";

export function getAssetThumbnail(asset: IAsset, typeMode: number): string | null {
  if (typeMode === ASSET_TYPE_ID.IMAGE) {
    return asset.blobPath;
  }

  if (typeMode === ASSET_TYPE_ID.PANORAMIC_IMAGE && asset.panoramicImages) {
    return `${asset.blobPath}/${asset.panoramicImages.frontImage}`;
  }

  if (typeMode === ASSET_TYPE_ID.SMART_OBJECT && asset.smartObject) {
    /**
     * Smart Object Svg files that are named with spaces get those replaced with underscores
     * in the blob storage, but the original name with spaces its sent in the backend response
     */
    const correctPathName = asset.smartObject.smartObjectSvg.toLowerCase().replaceAll(" ", "_");

    return `${asset.blobPath}/${correctPathName}`;
  }

  return null;
}

function AssetLibrary(props: any) {
  const initialFilter: Array<AssetManagerTypes.FilterListItem> = [] as AssetManagerTypes.FilterListItem[];
  // let initialResults: Array<IAsset> = [] ;
  const {
    assetLibraryDisplayedAssetType,
    assetLibraryFilters,
    retrievedData,
    currentSelectedAsset,
    pagination,
    keywords,
    textQuery,
    itemsPerPage,
  } = useAssetManagerState();
  const AssetManagerDispatch = useAssetManagerDispatch();
  const [filtersCurrentlyActive, setFiltersCurrentlyActive] =
    useState<AssetManagerTypes.FilterListItem[]>(initialFilter);
  const [listMode, setListMode] = useState<AssetManagerTypes.ListModes>("DoubleList");
  const [assetsAreLoading, setAssetAreLoading] = useState<boolean>(false);
  const [previousAssetsPerPage, setPreviousAssetsPerPage] = useState<number>(itemsPerPage ?? 12);

  const assetLibraryProps = {
    allAvailableAircraft: props.allAvailableAircraft,
    filtersCurrentlyActive,
    setFiltersCurrentlyActive,
    listLoading: assetsAreLoading,
  };
  const setAssetsAreLoading = (value: boolean) => {
    AssetManagerDispatch({
      type: "SET_ASSETS_LOADING",
      payload: value,
    });
  };
  const setItemsPerPage = (value: number) => {
    AssetManagerDispatch({
      type: "SET_ITEMS_PER_PAGE",
      payload: value,
    });
  };
  useEffect(() => {
    setItemsPerPage(listMode === "DoubleList" ? 12 : 6);
    setPreviousAssetsPerPage(listMode === "DoubleList" ? 12 : 6);
    return () => {
      setItemsPerPage(12);
    };
  }, [listMode, itemsPerPage]);

  async function retrieveFilteredAssets(
    filterListItems: Array<AssetManagerTypes.FilterListItem>,
    config: AxiosRequestConfig,
    options: any,
  ): Promise<IGetFilteredAssetsResponse> {
    function processFilters(filterListItems: Array<AssetManagerTypes.FilterListItem>): IGetFilteredAssetsRequest {
      const manufacturerId: Array<number> = [];
      const aircraftFamilyId: Array<number> = [];
      const aircraftId: Array<number> = [];
      const configurationId: Array<number> = [];
      let currentLesson = false;
      let recentAssets = false;
      let myUploaded = false;

      for (let i = 0; i < filterListItems.length; i++) {
        if (filterListItems[i].manufacturerId) manufacturerId.push(filterListItems[i].manufacturerId!);
        if (filterListItems[i].aircraftFamilyId) aircraftFamilyId.push(filterListItems[i].aircraftFamilyId!);
        if (filterListItems[i].aircraftId) aircraftId.push(filterListItems[i].aircraftId!);
        if (filterListItems[i].currentLesson === true) currentLesson = true;
        if (filterListItems[i].recentlyUploaded === true) recentAssets = true;
        if (filterListItems[i].myUploaded === true) myUploaded = true;
      }
      // baseline page number request. Does not change is itemsPerPage has not changed (i.e navigate normally through pages)
      let requestPageNum: number = pagination.currentPage!;
      // stores the offset index of the selected item
      let offset = 0;
      // index of the current item at the top of the asset list
      const itemIdx = previousAssetsPerPage * pagination.currentPage!;
      // This will be modified depending of the way the itemsPerPage changes
      const tempPageNumber = Math.round(itemIdx / options.itemsPerPage);
      //
      const ratio = previousAssetsPerPage / itemsPerPage;
      const pageBreakIdx = Math.floor((previousAssetsPerPage - 1) / ratio);
      if (currentSelectedAsset) {
        offset = retrievedData.map((a) => a.assetVersionId).indexOf(currentSelectedAsset.assetId); // so that the current selected item always appears on the page;
        if (offset < 0) offset = 0; // I havent found any cases where the idx is not found (-1) but just in case
      }
      if (previousAssetsPerPage > itemsPerPage) {
        // if we go from bigger to smaller, we're off by one page number if we naively divide total items by the current idx
        const pageNumberOffset = offset < pageBreakIdx ? -1 : 0;
        requestPageNum = tempPageNumber + pageNumberOffset <= 0 ? 1 : tempPageNumber + pageNumberOffset;
      } else if (previousAssetsPerPage < itemsPerPage) {
        // if we go the other way, we're off by one but in the other direction
        const pageNumberOffset = offset > pageBreakIdx ? -1 : 0;
        requestPageNum = tempPageNumber + pageNumberOffset <= 0 ? 1 : tempPageNumber + pageNumberOffset;
      }

      return {
        currentLesson: currentLesson ? [...props.lessonAssetList.map((asset: any) => asset.assetVersionId)] : [],
        manufacturerId,
        aircraftFamilyId,
        aircraftId,
        configurationId,
        keywords,
        assetTypeIds: [assetLibraryDisplayedAssetType],
        page: requestPageNum, //pagination.currentPage!,
        lessonVersionId: currentLesson ? props.lessonVersionId : null,
        recentAssets,
        excludeLibraryItems: myUploaded,
        excludeArchivedItems: props.excludeArchivedItems,
        ...options,
      };
    }
    setAssetsAreLoading(true);
    const response = await genericRepositoryService.getFilteredAssets(processFilters(filterListItems), config);
    setAssetsAreLoading(false);

    return response.data;
  }

  const isDisabled = (mode: AssetManagerTypes.AssetManagerModes, tab: AssetManagerTypes.AssetTypeSlected) => {
    // business logic for disabling library tabs
    switch (mode) {
      case "SCORM":
        return tab !== "SCORM";
      case "ImagesOnly":
        return tab !== "Image";
      case "VideosOnly":
        return tab !== "Video";
      case "SmartObject":
        return tab !== "SmartObject";
      case "PanoramicOnly":
        return tab !== "Panoramic";
      case "Standalone":
      default:
        return false;
    }
  };

  useEffect(() => {
    if (props.mode === "ImagesOnly") {
      (AssetManagerDispatch as AssetManagerTypes.Dispatch<number>)({
        type: "CHANGE_ASSET_TYPE_DISPLAYED",
        payload: ASSET_TYPE_ID.IMAGE,
      });
    } else if (props.mode === "SCORM") {
      (AssetManagerDispatch as AssetManagerTypes.Dispatch<number>)({
        type: "CHANGE_ASSET_TYPE_DISPLAYED",
        payload: ASSET_TYPE_ID.SCORM,
      });
    } else if (props.mode === "VideosOnly") {
      (AssetManagerDispatch as AssetManagerTypes.Dispatch<number>)({
        type: "CHANGE_ASSET_TYPE_DISPLAYED",
        payload: ASSET_TYPE_ID.VIDEO,
      });
    } else if (props.mode === "SmartObject") {
      (AssetManagerDispatch as AssetManagerTypes.Dispatch<number>)({
        type: "CHANGE_ASSET_TYPE_DISPLAYED",
        payload: ASSET_TYPE_ID.SMART_OBJECT,
      });
    } else if (props.mode === "PanoramicOnly") {
      (AssetManagerDispatch as AssetManagerTypes.Dispatch<number>)({
        type: "CHANGE_ASSET_TYPE_DISPLAYED",
        payload: 15,
      });
    }
  }, [props.mode]);

  let cancel = false;
  function sendQueryRequest(config: AxiosRequestConfig, options: any = { itemsPerPage: 12 }) {
    let x;
    (async () => {
      setAssetAreLoading(true);

      x = await retrieveFilteredAssets(assetLibraryFilters, config, options);
      if (Object.entries(x).length !== 0) {
        if (x.assets) {
          AssetManagerDispatch({
            type: "SET_RETRIEVED_ASSETS",
            payload: x.assets,
          });
          if (pagination?.totalPages! > x?.currentPage!) {
          } else {
          }
          AssetManagerDispatch({
            type: "SET_LIBRARY_PAGINATION",
            payload: {
              currentPage: x.currentPage,
              totalPages: x.totalPages,
              previousPage: x.previousPage,
              nextPage: x.nextPage,
            },
          });
        } else if (x.assets === null) {
          AssetManagerDispatch({ type: "SET_RETRIEVED_ASSETS", payload: [] });
        } else {
          AssetManagerDispatch({ type: "SET_RETRIEVED_ASSETS", payload: [] });
        }
        if (assetLibraryDisplayedAssetType === 7 || assetLibraryDisplayedAssetType === 8) {
          // if the asset call was video or pdf then switch loading off when data retrieved instead of delegating to image load event.
          //  setAssetAreLoading(false)
        }
        if (assetLibraryDisplayedAssetType === 4 && listMode === "Detailed") {
          //  setAssetAreLoading(false)
        }
        if (x.assets && x.assets.length === 0) {
          setAssetAreLoading(false);
        } else {
          // if (x.assets) {
          //   AssetManagerDispatch({ type: 'SELECT_ASSET', payload: x.assets[0] });
          // }
          setAssetAreLoading(false);
        }
      } else {
        if (!cancel) {
          AssetManagerDispatch({ type: "SET_RETRIEVED_ASSETS", payload: [] });
          setAssetAreLoading(false);
        }
      }
    })();
  }
  useEffect(() => {
    if (retrievedData.length > 0 && currentSelectedAsset) {
      const assetIndex = retrievedData.map((a) => a.assetVersionId).indexOf(currentSelectedAsset.assetId);
      if (assetIndex < 0 || assetIndex > retrievedData.length - 1) {
        AssetManagerDispatch({
          type: "SELECT_ASSET",
          payload: retrievedData[0],
        });
      } else {
        AssetManagerDispatch({
          type: "SELECT_ASSET",
          payload: retrievedData[assetIndex],
        });
      }
    }
  }, [retrievedData, pagination.currentPage]);
  useEffect(() => {
    AssetManagerDispatch({
      type: "SET_LIBRARY_PAGINATION",
      payload: {
        currentPage: 1,
        previousPage: null,
        nextPage: null,
        totalPages: 0,
      },
    });
  }, [props.currentTab]);

  useEffect(() => {
    const abortAxios = axios.CancelToken.source();
    if (props.mode === "ImagesOnly" && assetLibraryDisplayedAssetType !== 4) {
      //dont send Query
    } else if (assetLibraryDisplayedAssetType) {
      const config: AxiosRequestConfig = {
        cancelToken: abortAxios.token,
      };

      sendQueryRequest(config, { itemsPerPage: itemsPerPage ?? 12 });
    }
    return () => {
      cancel = true;
      abortAxios.cancel("new filter");
    };
  }, [
    assetLibraryFilters.length,
    assetLibraryDisplayedAssetType,
    pagination.currentPage,
    keywords,
    listMode,
    props.excludeArchivedItems,
    props.currentTab,
  ]);

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    AssetManagerDispatch({ type: "SET_TEXT_QUERY", payload: e.target.value });
    if (e.target.value === "") {
      applyKeywordsToContext([]);
    }
  };

  const applyKeywordsToContext = (query: string[]) => {
    AssetManagerDispatch({ type: "SET_CURRENT_KEYWORDS", payload: query });
    AssetManagerDispatch({
      type: "SET_LIBRARY_PAGINATION",
      payload: {
        currentPage: 1,
        previousPage: null,
        nextPage: null,
        totalPages: 0,
      },
    });
  };

  const transformUserInput = () => {
    const charArray = textQuery.split("");
    const numberOfDoubleQuotes = charArray.filter((s) => s === '"').length;
    const isOdd = (n: number) => n % 2 !== 0;
    if (isOdd(numberOfDoubleQuotes)) {
      charArray.push('"');
    }
    function* adder(arr: string[]) {
      let i = 0;
      while (true) {
        yield arr[i];
        i += 1;
      }
    }
    let gen = adder(charArray),
      startRecording = false,
      longKeyword = [],
      longKeywordList = [],
      charOutsideBoundry = [],
      charOutsideBoundryList = [];
    for (let i = 0; i < charArray.length; i++) {
      const charachter = gen.next().value;
      if (charachter === '"' && startRecording) {
        longKeywordList.push(longKeyword);
        longKeyword = [];
        startRecording = !startRecording;
      } else if (charachter === '"' && !startRecording) {
        if (charOutsideBoundry.length !== 0) {
          charOutsideBoundryList.push(charOutsideBoundry);
        }
        charOutsideBoundry = [];
        startRecording = !startRecording;
      } else if (charachter !== '"' && !startRecording && i === charArray.length - 1) {
        charOutsideBoundry.push(charachter);
        charOutsideBoundryList.push(charOutsideBoundry);
        charOutsideBoundry = [];
      } else if (startRecording) {
        longKeyword.push(charachter);
      } else if (!startRecording) {
        charOutsideBoundry.push(charachter);
      }
      //////////////////////////////////////
    }
    const x = longKeywordList.map((sa) => sa.join(""));
    const y = charOutsideBoundryList
      .map((sa) =>
        sa
          .join("")
          .split(" ")
          .filter((s) => s !== ""),
      )
      .flat(1);

    return [...x, ...y];
  };

  const handleSearchClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const input = transformUserInput();
    applyKeywordsToContext(input);
  };

  const handleEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ("key" in e && e.key === "Enter") {
      const input = transformUserInput();
      applyKeywordsToContext(input);
    }
  };

  const Pages = () => {
    //if selected number is 6 or greater then the left side will show 1 ...
    //if selected number is 3 less than the lenght do it to the right side.
    // useEffect(()=> {
    //   handleL();
    // },[itemsPerPage])
    function handleL() {
      if (pagination.currentPage === 1) {
      } else {
        // if(!assetsAreLoading){
        AssetManagerDispatch({
          type: "SET_LIBRARY_PAGINATION",
          payload: {
            currentPage: pagination.currentPage - 1,
            previousPage: pagination.previousPage! - 1,
            nextPage: pagination.nextPage! - 1,
          },
        });
        // }
      }
    }
    function handleR() {
      if (pagination.currentPage === pagination.totalPages) {
      } else {
        // if(!assetsAreLoading){
        AssetManagerDispatch({
          type: "SET_LIBRARY_PAGINATION",
          payload: {
            currentPage: pagination.currentPage + 1,
            previousPage: pagination.previousPage! + 1,
            nextPage: pagination.nextPage! + 1,
          },
        });
        // }
      }
    }
    function createNumberArray() {
      const numArray: number[] = [];
      if (pagination.totalPages! > 6) {
        for (let i = 3; i < pagination.totalPages!; i++) {
          numArray.push(i);
        }
      } else {
        for (let i = 3; i <= pagination.totalPages!; i++) {
          numArray.push(i);
        }
      }

      return numArray;
    }
    const pagesArray = useMemo(() => {
      return createNumberArray();
    }, [pagination.totalPages]);

    function viewableNumbers() {
      if (pagination.totalPages) {
        return pagesArray
          .filter((el: number, index: number) => {
            if (pagination.totalPages! > 6) {
              if (pagination.currentPage <= 5) {
                return el <= 5;
              } else if (pagination.currentPage >= pagination.totalPages! - 4) {
                return el >= pagination.totalPages! - 4 && el !== pagination.totalPages! - 1;
              } else {
                return el === pagination.previousPage || el === pagination.nextPage || el === pagination.currentPage;
              }
            } else {
              return el <= pagination.totalPages!;
            }
          })
          .map((el: number) => {
            return (
              <li
                key={el + 34 + "sds"}
                onClick={goToPage(el)}
                className={pagination.currentPage === el ? "selected-libraryPage" : ""}
              >
                {el}
              </li>
            );
          });
      }
    }

    const goToPage = (pageNumber: number) => (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      // if(!assetsAreLoading)
      AssetManagerDispatch({
        type: "SET_LIBRARY_PAGINATION",
        payload: {
          currentPage: pageNumber,
          previousPage: pageNumber - 1,
          nextPage: pageNumber + 1,
        },
      });
    };

    return pagination.totalPages ? (
      <div
        className="assetLibrary-pagination-container"
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
      >
        <LeftArrowIcon onClick={handleL} />
        <ul style={pagination.totalPages <= 5 ? { justifyContent: "space-evenly" } : {}}>
          {pagination.totalPages >= 1 && (
            <li onClick={goToPage(1)} className={pagination.currentPage === 1 ? "selected-libraryPage" : ""}>
              1
            </li>
          )}
          {pagination.totalPages >= 2 && (
            <li
              style={pagination.currentPage >= 6 ? { alignItems: "center", width: "15%" } : {}}
              onClick={pagination.currentPage >= 6 ? () => {} : goToPage(2)}
              className={pagination.currentPage === 2 ? "selected-libraryPage" : ""}
            >
              {pagination.currentPage >= 6 ? <span>&hellip;</span> : 2}
            </li>
          )}
          {viewableNumbers()}
          {pagination.totalPages! > 6 && (
            <li
              style={pagination.currentPage <= pagination.totalPages - 5 ? { alignItems: "center", width: "15%" } : {}}
              onClick={
                pagination.currentPage <= pagination.totalPages - 4 ? () => {} : goToPage(pagination.totalPages - 1)
              }
              className={pagination.currentPage === pagination.totalPages - 1 ? "selected-libraryPage" : ""}
            >
              {pagination.currentPage <= pagination.totalPages - 5 ? <span>&hellip;</span> : pagination.totalPages - 1}
            </li>
          )}
          {pagination.totalPages! > 6 && (
            <li
              onClick={goToPage(pagination.totalPages)}
              className={pagination.currentPage === pagination.totalPages ? "selected-libraryPage" : ""}
            >
              {pagination.totalPages}
            </li>
          )}
        </ul>
        <RightArrowIcon onClick={handleR} />
      </div>
    ) : (
      <div></div>
    );
  };
  return (
    <div className="assetManager-library-container">
      <div className="assetManager-keyword-and-tabs-container">
        <div className="assetType-selection-container">
          <div
            className={`assetType-selection-image${
              isDisabled(props.mode, "Image")
                ? " assetType-disabled"
                : assetLibraryDisplayedAssetType === ASSET_TYPE_ID.IMAGE
                ? " assetType-selected"
                : ""
            }`}
            onClick={() => {
              if (!isDisabled(props.mode, "Image")) {
                (AssetManagerDispatch as AssetManagerTypes.Dispatch<number>)({
                  type: "CHANGE_ASSET_TYPE_DISPLAYED",
                  payload: ASSET_TYPE_ID.IMAGE,
                });
                if (assetLibraryDisplayedAssetType !== ASSET_TYPE_ID.IMAGE) {
                  AssetManagerDispatch({
                    type: "SET_LIBRARY_PAGINATION",
                    payload: {
                      currentPage: 1,
                      totalPages: null,
                      previousPage: null,
                      nextPage: null,
                    },
                  });
                }
              }
            }}
          >
            <ImageIcon />
            <p>Image</p>
          </div>
          <div
            className={`assetType-selection-pdf${
              isDisabled(props.mode, "PDF")
                ? " assetType-disabled"
                : assetLibraryDisplayedAssetType === ASSET_TYPE_ID.PDF
                ? " assetType-selected"
                : ""
            }`}
            onClick={() => {
              if (!isDisabled(props.mode, "PDF")) {
                (AssetManagerDispatch as AssetManagerTypes.Dispatch<number>)({
                  type: "CHANGE_ASSET_TYPE_DISPLAYED",
                  payload: ASSET_TYPE_ID.PDF,
                });
                if (assetLibraryDisplayedAssetType !== ASSET_TYPE_ID.PDF) {
                  AssetManagerDispatch({
                    type: "SET_LIBRARY_PAGINATION",
                    payload: {
                      currentPage: 1,
                      totalPages: null,
                      previousPage: null,
                      nextPage: null,
                    },
                  });
                  if (assetLibraryDisplayedAssetType !== 7) {
                    AssetManagerDispatch({
                      type: "SET_LIBRARY_PAGINATION",
                      payload: {
                        currentPage: 1,
                        totalPages: null,
                        previousPage: null,
                        nextPage: null,
                      },
                    });
                  }
                }
              }
            }}
          >
            <PdfIcon />
            <p>PDF</p>
          </div>
          <div
            className={`assetType-selection-video${
              isDisabled(props.mode, "Video")
                ? " assetType-disabled"
                : assetLibraryDisplayedAssetType === ASSET_TYPE_ID.VIDEO
                ? " assetType-selected"
                : ""
            }`}
            onClick={() => {
              if (!isDisabled(props.mode, "Video")) {
                (AssetManagerDispatch as AssetManagerTypes.Dispatch<number>)({
                  type: "CHANGE_ASSET_TYPE_DISPLAYED",
                  payload: ASSET_TYPE_ID.VIDEO,
                });
                if (assetLibraryDisplayedAssetType !== ASSET_TYPE_ID.VIDEO) {
                  AssetManagerDispatch({
                    type: "SET_LIBRARY_PAGINATION",
                    payload: {
                      currentPage: 1,
                      totalPages: null,
                      previousPage: null,
                      nextPage: null,
                    },
                  });
                  if (assetLibraryDisplayedAssetType !== 8) {
                    AssetManagerDispatch({
                      type: "SET_LIBRARY_PAGINATION",
                      payload: {
                        currentPage: 1,
                        totalPages: null,
                        previousPage: null,
                        nextPage: null,
                      },
                    });
                  }
                }
              }
            }}
          >
            <VideoIcon />
            <p>Video</p>
          </div>
          <div
            className={`assetType-selection-image${
              isDisabled(props.mode, "SCORM")
                ? " assetType-disabled"
                : assetLibraryDisplayedAssetType === ASSET_TYPE_ID.SCORM
                ? " assetType-selected"
                : ""
            }`}
            onClick={() => {
              if (!isDisabled(props.mode, "SCORM")) {
                (AssetManagerDispatch as AssetManagerTypes.Dispatch<number>)({
                  type: "CHANGE_ASSET_TYPE_DISPLAYED",
                  payload: ASSET_TYPE_ID.SCORM,
                });
                if (assetLibraryDisplayedAssetType !== ASSET_TYPE_ID.SCORM) {
                  AssetManagerDispatch({
                    type: "SET_LIBRARY_PAGINATION",
                    payload: {
                      currentPage: 1,
                      totalPages: null,
                      previousPage: null,
                      nextPage: null,
                    },
                  });
                  if (assetLibraryDisplayedAssetType !== 10) {
                    AssetManagerDispatch({
                      type: "SET_LIBRARY_PAGINATION",
                      payload: {
                        currentPage: 1,
                        totalPages: null,
                        previousPage: null,
                        nextPage: null,
                      },
                    });
                  }
                }
              }
            }}
          >
            <InteractiveDiagramIcon />
            <p>Interactive Diagram</p>
          </div>
          <div
            className={`assetType-selection-panoramic${
              isDisabled(props.mode, "Panoramic")
                ? " assetType-disabled"
                : assetLibraryDisplayedAssetType === 15
                ? " assetType-selected"
                : ""
            }`}
            onClick={() => {
              if (!isDisabled(props.mode, "Panoramic")) {
                (AssetManagerDispatch as AssetManagerTypes.Dispatch<number>)({
                  type: "CHANGE_ASSET_TYPE_DISPLAYED",
                  payload: 15,
                });
                if (assetLibraryDisplayedAssetType !== 15) {
                  AssetManagerDispatch({
                    type: "SET_LIBRARY_PAGINATION",
                    payload: {
                      currentPage: 1,
                      totalPages: null,
                      previousPage: null,
                      nextPage: null,
                    },
                  });
                }
              }
            }}
          >
            <PanoramicIcon />
            <p>Panoramic View</p>
          </div>

          <div
            className={`assetType-selection-image${
              isDisabled(props.mode, "SmartObject")
                ? " assetType-disabled"
                : assetLibraryDisplayedAssetType === ASSET_TYPE_ID.SMART_OBJECT
                ? " assetType-selected"
                : ""
            }`}
            onClick={() => {
              if (!isDisabled(props.mode, "SmartObject")) {
                (AssetManagerDispatch as AssetManagerTypes.Dispatch<number>)({
                  type: "CHANGE_ASSET_TYPE_DISPLAYED",
                  payload: ASSET_TYPE_ID.SMART_OBJECT,
                });
                if (assetLibraryDisplayedAssetType !== ASSET_TYPE_ID.SMART_OBJECT) {
                  AssetManagerDispatch({
                    type: "SET_LIBRARY_PAGINATION",
                    payload: {
                      currentPage: 1,
                      totalPages: null,
                      previousPage: null,
                      nextPage: null,
                    },
                  });
                }
              }
            }}
          >
            <SmartObjectIcon
              width={20}
              height={20}
              style={{ color: assetLibraryDisplayedAssetType === 16 ? "white" : "#4B4F58" }}
            />
            <p>Smart Object</p>
          </div>
        </div>
      </div>
      <div className="assetManager-keyword-and-tabs-container-search">
        <div className="row keyword-search-container-row">
          <div className="keyword-search-container">
            <input
              placeholder="Enter Keywords"
              value={textQuery}
              onChange={handleTextChange}
              onKeyDown={handleEnterPress}
              onMouseDown={(e) => e.stopPropagation()}
            />
            <div className="assetManager-search-button" onClick={handleSearchClick}>
              <SearchIcon />
            </div>
          </div>
        </div>
      </div>

      <AssetLibraryFilter {...assetLibraryProps} />

      <div className="assetManager-viewMode">
        <p>File View: </p>
        <div
          className={`AssetManager-viewMode-noPreview${listMode === "DoubleList" ? " active-list-mode" : ""}`}
          onClick={() => {
            setListMode("DoubleList");
            setItemsPerPage(12);
          }}
        >
          <DoubleListViewIcon />
        </div>
        <div
          className={`AssetManager-viewMode-preview${listMode === "Detailed" ? " active-list-mode" : ""}`}
          onClick={() => {
            setListMode("Detailed");
            setItemsPerPage(6);
          }}
        >
          <PreviewIcon />
        </div>
      </div>

      <div className="assetLibrary-viewport">
        {(listMode === "List" || listMode === "DoubleList" || listMode === "Detailed") && assetsAreLoading === true && (
          <AssetLibraryListViewLoading />
        )}
        {listMode === "List" && (
          <AssetLibraryListView
            data={retrievedData}
            selectedAsset={currentSelectedAsset}
            AssetManagerDispatch={AssetManagerDispatch}
            assetsAreLoading={assetsAreLoading}
            hasCurrentLessonFilter={false}
            typeMode={assetLibraryDisplayedAssetType}
          />
        )}
        {!assetsAreLoading && listMode === "Detailed" && (
          <AssetLibraryDetailedView
            data={retrievedData}
            selectedAsset={currentSelectedAsset}
            AssetManagerDispatch={AssetManagerDispatch}
            setAssetAreLoading={setAssetAreLoading}
            assetsAreLoading={assetsAreLoading}
            typeMode={assetLibraryDisplayedAssetType}
          />
        )}
        {!assetsAreLoading && listMode === "DoubleList" && (
          <AssetLibraryListViewDoubleColumn
            data={retrievedData}
            selectedAsset={currentSelectedAsset}
            AssetManagerDispatch={AssetManagerDispatch}
            assetsAreLoading={assetsAreLoading}
            hasCurrentLessonFilter={assetLibraryFilters[0]?.currentLesson ?? false}
            typeMode={assetLibraryDisplayedAssetType}
          />
        )}
      </div>

      <div className="assetLibrary-pagination">
        <Pages />
      </div>
    </div>
  );
}

export default AssetLibrary;
