import { mapValues } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useTimeline } from "../../../contexts/TimelineProvider/TimelineProvider";
import { useDomRef } from "../../../hooks/useDomRef";
import useDrag from "../../../hooks/useDrag";
import "./HorizontalScrollbar.css";

export default function HorizontalScrollbar() {
  const unitPosToHumanReadablePos = useCallback((n: number) => n.toFixed(2), []);
  const [timeline, timelineDispatch] = useTimeline();
  const { widthInPx, clippedSpace, sequenceLength } = timeline;
  const rightWidth = widthInPx - 25;
  const clippedSpaceRange = clippedSpace.range;
  const assumedLengthOfSequence = Math.max(clippedSpaceRange.end, sequenceLength);
  const rangeStartX = (clippedSpaceRange.start / assumedLengthOfSequence) * rightWidth;
  const rangeEndX = (clippedSpaceRange.end / assumedLengthOfSequence) * rightWidth;
  const [beingDragged, setBeingDragged] = useState<"nothing" | "both" | "start" | "end">("nothing");

  const handles = useMemo(() => {
    let valuesBeforeDrag = {
      rightWidth,
      clippedSpaceRange,
      sequenceLength,
      assumedLengthOfSequence,
      rangeStartX,
      rangeEndX,
    };
    const noteValuesBeforeDrag = () => {
      valuesBeforeDrag = {
        rightWidth,
        clippedSpaceRange,
        sequenceLength,
        assumedLengthOfSequence,
        rangeStartX,
        rangeEndX,
      };
    };
    const deltaXToDeltaPos = (dx: number): number => {
      const asAFractionOfRightWidth = dx / valuesBeforeDrag.rightWidth;
      return asAFractionOfRightWidth * valuesBeforeDrag.assumedLengthOfSequence;
    };

    const self = {
      onRangeDragStart() {
        noteValuesBeforeDrag();
        return {
          onDrag(dx: number) {
            setBeingDragged("both");
            const deltaPosInUnitSpace = deltaXToDeltaPos(dx);
            const newRange = mapValues(valuesBeforeDrag.clippedSpaceRange, (p) => p + deltaPosInUnitSpace);

            timelineDispatch({
              type: "SET_RANGE",
              payload: {
                start: newRange.start,
                end: newRange.end,
              },
            });
          },
          onDragEnd() {
            setBeingDragged("nothing");
          },
        };
      },

      onRangeStartDragStart() {
        noteValuesBeforeDrag();
        return {
          onDrag(dx: number) {
            setBeingDragged("start");

            const deltaPosInUnitSpace = deltaXToDeltaPos(dx);

            const newRange = {
              start: valuesBeforeDrag.clippedSpaceRange.start + deltaPosInUnitSpace,
              end: valuesBeforeDrag.clippedSpaceRange.end,
            };

            if (newRange.start > newRange.end - 1) {
              newRange.start = newRange.end - 1;
            }

            if (newRange.start <= 0) {
              newRange.start = 0;
            }

            timelineDispatch({
              type: "SET_RANGE",
              payload: {
                start: newRange.start,
                end: newRange.end,
              },
            });
          },
          onDragEnd() {
            setBeingDragged("nothing");
          },
        };
      },

      onRangeEndDragStart() {
        noteValuesBeforeDrag();
        return {
          onDrag(dx: number) {
            setBeingDragged("end");

            const deltaPosInUnitSpace = deltaXToDeltaPos(dx);

            const newRange = {
              start: valuesBeforeDrag.clippedSpaceRange.start,
              end: valuesBeforeDrag.clippedSpaceRange.end + deltaPosInUnitSpace,
            };

            if (newRange.end < newRange.start + 1) {
              newRange.end = newRange.start + 1;
            }

            if (newRange.end >= valuesBeforeDrag.assumedLengthOfSequence) {
              newRange.end = valuesBeforeDrag.assumedLengthOfSequence;
            }

            timelineDispatch({
              type: "SET_RANGE",
              payload: {
                start: newRange.start,
                end: newRange.end,
              },
            });
          },
          onDragEnd() {
            setBeingDragged("nothing");
          },
        };
      },
    };
    return self;
  }, [
    rightWidth,
    clippedSpaceRange.start,
    clippedSpaceRange.end,
    sequenceLength,
    assumedLengthOfSequence,
    rangeStartX,
    rangeEndX,
  ]);

  const [rangeDragNode, setRangeDragNode] = useDomRef();
  useDrag(rangeDragNode, {
    debugName: "HorizontalScrollbar/onRangeDrag",
    onDragStart: handles.onRangeDragStart,
    lockCSSCursorTo: "ew-resize",
  });
  const [rangeStartDragNode, setRangeStartDragNode] = useDomRef();
  useDrag(rangeStartDragNode, {
    debugName: "HorizontalScrollbar/onRangeStartDrag",
    onDragStart: handles.onRangeStartDragStart,
    lockCSSCursorTo: "w-resize",
  });

  const [rangeEndDragNode, setRangeEndDragNode] = useDomRef();
  useDrag(rangeEndDragNode, {
    debugName: "HorizontalScrollbar/onRangeEndDrag",
    onDragStart: handles.onRangeEndDragStart,
    lockCSSCursorTo: "e-resize",
  });

  return (
    <div
      className="horizontal-scrollbar"
      style={{
        bottom: 8 + "px",
        // @ts-ignore
        "--threadHeight": "6px",
        "--bg-inactive": "#32353b",
        "--bg-active": "#5b5c5d",
        position: "absolute",
        height: "0",
        width: "100%",
        left: "12px",
      }}
    >
      <div
        className="time-thread"
        style={{
          position: "relative",
          top: "0",
          left: "0",
          width: "100%",
          height: "var(--threadHeight)",
        }}
      >
        <div
          className="range-bar"
          ref={setRangeDragNode}
          style={{
            width: `${rangeEndX - rangeStartX}px`,
            transform: `translate3d(${rangeStartX}px, 0, 0)`,
          }}
        ></div>
        <div
          className="range-start-drag-node-handle range-handle"
          ref={setRangeStartDragNode}
          style={{ transform: `translate3d(${rangeStartX}px, 0, 0)` }}
        ></div>
        <div
          className="range-end-drag-node-handle range-handle"
          ref={setRangeEndDragNode}
          style={{ transform: `translate3d(${rangeEndX}px, 0, 0)` }}
        ></div>
      </div>
    </div>
  );
}
