import { RotatePanel } from "..";
import styles from "../AdvancedPanel.module.css";
import { useMetaVariableStore } from "../../../../store";
import { useAdvancedPanelTimeline } from "../useAdvancedPanelTimeline";
import { TimelineButton } from "./TimeLineButton";
import { useTimeline } from "../../../../../../contexts/TimelineProvider/TimelineProvider";
import { useObjectsState } from "../../../../../../contexts/ObjectsProvider";
import { Collapse } from "./Collapse";

interface RotateProps {
  data: RotatePanel;
  onChange: () => void;
}

export function Rotate({ data, onChange }: RotateProps) {
  const value = useMetaVariableStore((s) => s.metaVariables[data.key] as string);
  const set = useMetaVariableStore((s) => s.setMetaVariable);
  const upsertAnimatedMetaVariable = useMetaVariableStore((s) => s.upsertAnimatedMetaVariable);
  const [tl] = useTimeline();
  const currentTime = tl?.scrubbingCurrentTime;
  const selectedObjectId = useObjectsState().selectedObjects[0]?.objectId;
  const { isObjectInTimeline, handleTimelineClick, metaVariableInCurrentTime } = useAdvancedPanelTimeline(data.key);

  const handleChange = (key: string, value: any) => {
    set(key, Number(value));
    if (isObjectInTimeline) {
      upsertAnimatedMetaVariable(key, currentTime, Number(value), selectedObjectId, true);
    }
    onChange();
  };

  const onTimelineClick = () => {
    handleTimelineClick(value, true);
    onChange();
  };

  return (
    <Collapse title={data.name}>
      <div className={styles.group} key={data.name}>
        <div className={styles.timelineWrapper}>
          <div className={styles.selectWrapper}>
            <RangeInput
              id={data.key}
              onInputChange={handleChange}
              value={Number(value)}
              label="Value"
              min={data.minValue}
              max={data.maxValue}
            />
          </div>
          {isObjectInTimeline && <TimelineButton onClick={onTimelineClick} active={!!metaVariableInCurrentTime} />}
        </div>
      </div>
    </Collapse>
  );
}

interface RangeInputProps {
  id: string;
  label: string;
  value: string | number;
  onInputChange: (key: string, value: any) => void;
  step?: number;
  disabled?: boolean;
  min: number;
  max: number;
}

export function RangeInput({ id, label, onInputChange, value, step, disabled, min, max }: RangeInputProps) {
  const handleOnChange = (e: any) => {
    onInputChange(id, e.target.value);
  };

  return (
    <div className={styles.rangeInputContainer}>
      <label className={styles.rangeInputLabel}>
        <div>{label}</div>
        <div className={styles.rangeInputWrapper}>
          <input type="range" min={min} max={max} onChange={handleOnChange} value={Number(value)} />
          <input
            defaultValue={typeof value === "number" ? value : 0}
            id={label ?? undefined}
            onInput={(event) => {
              if (disabled) return;
              //@ts-expect-error value doesnt exist but it does
              const value = event.target.value;
              if (value < min) {
                //@ts-expect-error value doesnt exist but it does
                event.target.value = min;
              } else if (value > max) {
                //@ts-expect-error value doesnt exist but it does
                event.target.value = max;
              }
            }}
            onChange={handleOnChange}
            type={"number"}
            value={value}
            step={step ?? 1}
            min={min}
            max={max}
          />
        </div>
      </label>
    </div>
  );
}
