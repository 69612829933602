import type { ReactNode } from "react";
import type { ValidClassName } from "../../utils/HTML/classes";

export enum FieldTypes {
  TEXT = "TEXT",
  NUMBER = "NUMBER",
  SELECT = "SELECT",
  CHECKBOX = "CHECKBOX",
  TEXTAREA = "TEXTAREA",
  DISPLAY = "DISPLAY",
  AUDIO = "AUDIO",
}

export type PanelProperty<T> = {
  identifier: string;
  type: FieldTypes;
  className?: ValidClassName;
  label?: string;
  render?: (value: T) => ReactNode;
  visible?: (value: T) => boolean;
  readOnly?: boolean;
  disabled?: boolean;
};

export type PropertyConfig<T> = Array<PanelProperty<T>>;
