export const toolTipText = [
  //Landing Page//
  {
    id: "Add New Lesson",
    text: "Adds a new lesson to the lesson list using a generic template.",
  },
  {
    id: "Delete Lesson",
    text: "Deletes the lesson from the list, dont worry we will make sure you are sure.",
  },
  { id: "Copy Lesson", text: "Duplicates a lesson for easy testing." },

  //LessonDesigner//
  //Header
  { id: "Home Button", text: "Takes you back to the lesson list." },
  {
    id: "addPage",
    text: "Add a lesson page, choosing the desired page type from the provided menu.",
  },
  {
    id: "Copy Page",
    text: "Duplicates the current selected lesson page and adds it to the page list.",
  },
  {
    id: "Remove Page",
    text: "Removes the selected lesson page from the page list.",
  },
  {
    id: "saveLesson",
    text: "Saves progress on lesson development and indicates time since last save",
  },
  { id: "Preview Page", text: "Launches page preview." },
  {
    id: "Preview Lesson",
    text: "Launches lesson preview in the selected mode.",
  },
  {
    id: "Settings",
    text: "Edit various attributes of the lesson such as the Lesson Name, Lesson Type, Manufacturer, and more. ",
  },
  { id: "Export", text: "Exports the lesson for uploading to an LMS." },
  {
    id: "Asset Manager",
    text: "Upload new and manage existing assets throughout the lesson.",
  },
  { id: "LSizeYellow", text: "The lesson maximum size allowed is 500MB" },
  { id: "LSizeOrange", text: "The lesson is approaching the maximum size" },
  { id: "LSizeRed", text: "The lesson is at maximum size" },

  { id: "AddTextBox", text: "Add a text box" },
  { id: "AddImage", text: "Add an image" },
  { id: "AddVideo", text: "Add a video" },
  { id: "AddInteractiveDiagram", text: "Add an interactive diagram" },
  { id: "AddHotSpot", text: "Add a hot spot" },
  { id: "AddSmartObject", text: "Add a smart object" },
  { id: "AddAnnotation", text: "Add an annotation graphic" },
  { id: "AddPanoramic", text: "Add a 360 panoramic object" },
  { id: "AddTable", text: "Add a table" },

  { id: "LineWidth", text: "Adjust line width" },
  { id: "BorderColor", text: "Change line color" },
  { id: "FillColor", text: "Change fill color" },
  { id: "SendBack", text: "Send to back" },
  { id: "FontColor", text: "Change font color" },
  { id: "Copy", text: "Copy" },
  { id: "Paste", text: "Paste" },

  //Page List
  {
    id: "Page List",
    text: "This column contains a list of the lesson pages, you can change the order by draging a page to the desired location in the lesson.",
  },
  { id: "Page List Lesson Modes", text: "Page List Lesson Modes." },

  //Page Editor
  { id: "Show Advanced Editor", text: "CPaT DEV" },
  { id: "Show Livery Editor", text: "Livery" },
  {
    id: "Auto Advance",
    text: "Determines whether or not the current page will automatically advance to the next page upon completion.",
  },
  {
    id: "Lesson Modes",
    text: "Select which modes in which the slide should be included",
  },
  {
    id: "Upload Audio",
    text: "Select an audio file to be played on the page.",
  },
  {
    id: "Change Layout",
    text: "Select various text layouts from the provided menu.",
  },
  { id: "Answer Feedback", text: "Answer Feedback." },
  { id: "Is Scorable", text: "Is Scorable" },
  { id: "Add Description", text: "Add Description." },
  { id: "Success Criteria", text: "Success Criteria." },
  {
    id: "Review Link",
    text: "Link a lesson page, and provide a Review link to the student.",
  },
  {
    id: "Randomize Answers",
    text: "Randomize question answer order for the selected lesson page.",
  },
  { id: "Update Fonts", text: "Update Fonts" },
  { id: "Page Graphic", text: "Add an additional Page Graphic" },
  { id: "CDU Pages", text: "Select CDU Page" },
  { id: "FMCDataWorksheet", text: "Show worksheet in new tab" },

  {
    id: "Lesson Mode Selector",
    text: "Select which mode you are editing the current page for.",
  },
  { id: "Page Objective", text: "Enter Objectives text for the student" },
  {
    id: "To Do Tasks",
    text: "Set up required lesson actions for the student.",
  },
  { id: "Enabling Objective", text: "Enabling Objective information." },
  { id: "modals", text: "Select FMS page modals to display" },
  { id: "Annotation Mode Switch", text: "Select FMS page modals to display" },

  {
    id: "Auto Advance Base Page",
    text: "BASE PAGE Determines whether or not the current page will automatically advance to the next page upon completion.",
  },
  {
    id: "Lesson Modes Base Page",
    text: "Select which modes the current slide will be ",
  },
  //Add New Page Modal
  {
    id: "Basic Page",
    text: "Select from a list of Base Page templates to add to the lesson.",
  },
  { id: "FMS Page", text: "Add an FMS page to the lesson." },
  {
    id: "Quiz Page",
    text: "Select from a list of Quize page templates to add to the lesson.",
  },
  { id: "Text Only", text: "Add a Text Only Base Page." },
  { id: "Split Text Left", text: "Add a Split Text Left Base Page." },
  { id: "Split Text Right", text: "Add a Split Text Right Page." },
  {
    id: "Single Graphic Full Page",
    text: "Add a Single Graphic Full Base Page.",
  },
  {
    id: "Single Graphic Center",
    text: "Add a Single Graphic Center Base Page.",
  },
  { id: "Graphic Text Above", text: "Add a Graphic Text Above Base Page." },
  { id: "Single Graphic Left", text: "Add a Single Graphic Left Base Page." },
  { id: "Single Graphic Right", text: "Add a Single Graphic Right Base Page." },
  { id: "Tripple Graphic Top", text: "Add Tripple Graphic Top Base Page." },
  {
    id: "Tripple Graphic Bottom",
    text: "Add a Tripple Graphic Bottom Base Page.",
  },
  { id: "Custome Basic Page", text: "Add a Custom Basic Page." },

  // Cognitive Services
  {
    id: "Audio Options",
    text: "Select an option for adding audio to be played on the page.",
  },
  { id: "TTS Tips and Tricks", text: "Tips and Tricks" },
  { id: "Pronunciations", text: "Pronunciations" },
  {
    id: "Copy Text",
    text: "Copy text from narration area to pronunciation area.",
  },
  { id: "Highlight Text", text: "Highlight Text." },
  { id: "Generate Audio", text: "Generate audio and listen to a preview." },
  { id: "Translations", text: "Translate text from one language to another." },

  //fms tooltips
  { id: "add first fms condition", text: "Adds Condition" },
  { id: "remove fms condition", text: "Removes Condition" },
  { id: "add fms scratchpad", text: "Adds Extra Value" },

  // asset manager ??
  {
    id: "MyUploads",
    text: "My Uploads includes all uploads made by individuals to your company's Asset Library",
  },

  //lessonsize
];
