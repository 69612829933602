import React from "react";
import Portal from "../Portal/Portal";
// import lodash from "lodash"
import LessonSettingsContainer from "./LessonSettingsContainer";
import { LessonSettingsProvider } from "./LessonSettingsContext/LessonSettingsProvider";
import "./LessonSettings.styles.css";
import { connect } from "react-redux";

const LessonSettings = React.memo((props: any) => {
  return (
    <LessonSettingsProvider lessonMetaData={props.lessonMetaData}>
      {props.lessonSettingsController.isOpen && (
        <Portal path={"lesson-settings"} keepOn={props.lessonSettingsController.isOpen}>
          <LessonSettingsContainer {...props} />
        </Portal>
      )}
    </LessonSettingsProvider>
  );
});

const mapProps = (state: any) => {
  return {
    isCpatUser: state.authorizedState.isCpatUser,
  };
};
export default connect(mapProps, null)(LessonSettings);
