import { connect, Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker.js";
import { PersistGate } from "redux-persist/integration/react";
import ErrorBoundary, { ErrorDisplay } from "./components/ErrorBoundary/ErrorBoundary";
import genericRepository from "./services/genericRepositoryService";
import SplashScreen from "./splashScreen";
import { ILoginResponse } from "./models/ILogin/ILogin";
import IApiResponse from "./models/IApiResponse";
import LessonDesignerStore from "./store/lessonDesignerStore";
import queryStringParser from "query-string";
import lodash from "lodash";
import urlService from "./services/urlService";
import { createRoot } from "react-dom/client";
import * as ReactDOM from "react-dom";
const ROOT_ELEMENT_ID = "root";
const ENTIRE_APP_LEVEL_ERROR_RELOAD_ID = "ENTIRE_APP_LEVEL_ERROR_RELOAD_ID";
const MAX_NUMBER_OF_RELOAD_ERRORS = 7;
const RESET_ERRORS_TO_NONE = 0;
const rootContainer = document.getElementById(ROOT_ELEMENT_ID);
const root = createRoot(rootContainer!);

const v18 = false;

async function logUserIntoApp() {
  if (v18) {
    root.render(<SplashScreen />);
  } else {
    ReactDOM.render(<SplashScreen />, rootContainer);
  }

  try {
    const loginResponse: IApiResponse<ILoginResponse> = await genericRepository.login(); // check auth/authorization state and receive auth/authorization flags

    if (!loginResponse.isSuccess) {
      ErrorHandler();
      return;
    }

    localStorage.setItem(ENTIRE_APP_LEVEL_ERROR_RELOAD_ID, lodash.toString(RESET_ERRORS_TO_NONE));

    const loginData: ILoginResponse = loginResponse.data;

    if (loginData.isLoggedIn) {
      // if logged in, then set initial logged in state below

      const queryStringObject: any = queryStringParser.parse(window.location.search);

      let lmsKey: number = loginData.defaultLmsPermission.lmsKey;
      let fromQueryParameter = false;
      if (queryStringObject && queryStringObject.lmsKey) {
        const possibleNumber: number = lodash.parseInt(queryStringObject.lmsKey as string);
        if (lodash.isSafeInteger(possibleNumber)) {
          fromQueryParameter = true;
          lmsKey = possibleNumber;
        }
      }

      const storeConfiguration = LessonDesignerStore.createStore(
        lmsKey,
        fromQueryParameter,
        loginData.isLoggedIn,
        loginData.defaultLmsPermission.canManageUsers,
        loginData.isCpatSession,
      );

      if (v18) {
        root.render(
          <ErrorBoundary>
            <Provider store={storeConfiguration.store}>
              <PersistGate loading={null} persistor={storeConfiguration.persistor}>
                <App />
              </PersistGate>
            </Provider>
          </ErrorBoundary>,
        );
      } else {
        ReactDOM.render(
          <ErrorBoundary>
            <Provider store={storeConfiguration.store}>
              <PersistGate loading={null} persistor={storeConfiguration.persistor}>
                <App />
              </PersistGate>
            </Provider>
          </ErrorBoundary>,
          rootContainer,
        );
      }
      return;
    }

    const targetPageAndComponents: string = encodeURIComponent(urlService.getUrlWithoutHostOrProtocol());
    window.location.replace(loginResponse.data.lmsRedirectUrl + targetPageAndComponents);
  } catch (error) {
    ErrorHandler();
  }
}

function ErrorHandler(): void {
  try {
    const numberOfErrorsRaw: string | null = localStorage.getItem(ENTIRE_APP_LEVEL_ERROR_RELOAD_ID);
    if (!numberOfErrorsRaw) {
      // if false, then first error was experienced
      localStorage.setItem(ENTIRE_APP_LEVEL_ERROR_RELOAD_ID, "1");
      window.location.reload();
      return;
    }

    const numberOfErrorsThatHaveOccured: number = lodash.toSafeInteger(numberOfErrorsRaw);
    if (numberOfErrorsThatHaveOccured > MAX_NUMBER_OF_RELOAD_ERRORS) {
      console.error("could not load needed data for app");
      if (v18) {
        root.render(<ErrorDisplay />);
      } else {
        ReactDOM.render(<ErrorDisplay />, rootContainer);
      }
      return;
    }

    const newnumberOfErrorsThatHaveOccured: number = numberOfErrorsThatHaveOccured + 1;
    localStorage.setItem(ENTIRE_APP_LEVEL_ERROR_RELOAD_ID, lodash.toString(newnumberOfErrorsThatHaveOccured));
    window.location.reload();
  } catch (error) {
    console.error("could not load needed data for app", error);
    if (v18) {
      root.render(<ErrorDisplay />);
    } else {
      ReactDOM.render(<ErrorDisplay />, rootContainer);
    }
  }
}

logUserIntoApp();

export default connect()(App);
serviceWorker.unregister();
