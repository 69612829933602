import React from "react";

const AnnotationDashedLine = () => {
  return (
    <React.Fragment>
      <span className="icon-dashed-line-new"></span>
    </React.Fragment>
  );
};

export default AnnotationDashedLine;
