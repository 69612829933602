import React from "react";
import { AnnotationPropType } from "../AnnotationThumbnail";
// import { ICircledNumber } from '../models/ICircledNumber';
// import { IAttributes } from '../models/IAttributes';
// import { ICircledLetter } from '../models/ICircledLetter';

// type CircledNumberType = {
//   letters: ICircledLetter[];
//   numbers: ICircledNumber[];
// }

const AnnotationCircledNumber = (props: AnnotationPropType) => {
  const { annotation, index, count } = props;

  const display = () => {
    return (
      <svg
        viewBox="0 0 100 100"
        key={index}
        id={`${index}`}
        className="annotation"
        preserveAspectRatio="xMinYMin meet"
        style={{
          top: `${annotation.top}%`,
          left: `${annotation.left}%`,
          width: `${annotation.width}%`,
          height: `${annotation.height}%`,
          zIndex: annotation.zIndex,
          transform: `rotate(${annotation?.transform?.rotate}deg)`,
        }}
      >
        {/* <circle cx="50" cy="50" r="47" stroke={`${annotation.fontColor}`} strokeWidth={`${annotation.strokeWidth || "3"}`} fill={`${annotation.backgroundColor}`}> */}
        {/* MATT: I made a change to annotation.fontColor to annotation.borderColor because I know you have a ticket that this is part of and I was fixing the border stroke anyways -- Van */}
        <circle
          cx="50"
          cy="50"
          r="47"
          stroke={`${annotation.borderColor}`}
          strokeWidth={`${annotation.strokeWidth || "3"}`}
          fill={`${annotation.backgroundColor}`}
        ></circle>
        <text className="step-text" x="50" y="50" dy="26.5" textAnchor="middle" fill={`${annotation.fontColor}`}>
          {count}
        </text>
      </svg>
    );
  };

  return <React.Fragment>{display()}</React.Fragment>;
};

export default AnnotationCircledNumber;
