import React, { useContext, useState, useRef, useEffect, useCallback } from "react";
import { IPageContext, PageContext } from "../../routes/builderContexts";
import blobUrlFormatHelper from "../../components/blobUrlFormatHelper";
import Portal from "../../components/Portal/Portal";
import { ReactComponent as PauseIcon } from "../../assets/icons/NarratorTab/narrator-text-pause-icon.svg";
import { ReactComponent as PlayIcon } from "../../assets/icons/NarratorTab/narrator-text-play-icon.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/Common/remove-page-icon.svg";
import { ReactComponent as LoadingSvg } from "../../assets/icons/NarratorTab/Dual Ring-1s-32px.svg";
import { ReactComponent as ExitIcon } from "../../assets/icons/Common/remove-tag-icon.svg";
import * as uploaderFunctions from "../AssetManager/AssetManagerUtils/UploaderFunctions";
import * as AssetManagerTypes from "../AssetManager/AssetManager.types";
import TextToSpeech from "../Modals/CognitiveServices/TextToSpeech/textToSpeech";
import { ReactComponent as ArrowIcon } from "../../assets/icons/HeaderIcons/drop-down.svg";
import { useInteractivityBuilderState } from "../../contexts/InteractivityBuilderProvider";
import { useGlobalAudioDispatch, useGlobalAudioState } from "../../contexts/GlobalAudioProvider/GlobalAudioProvider";
import useRefAndState from "../../hooks/useRefAndState";
import { LessonPagesActions, useLessonPagesDispatch } from "../../contexts/LessonPagesProvider/LessonPagesProvider";
import { AbstractTooltip as Tooltip } from "../ToolTip/ToolTip";
import { AudioOptionsDropdown } from "../NarrationDrawer/components/AudioActions";
import { UploadedAudioInfo } from "../NarrationDrawer/components/AudioInformation";

const NarratorAudio: React.NamedExoticComponent<any> = React.memo((props) => {
  const lessonPagesDispatch = useLessonPagesDispatch();
  const globalAudioDipatch = useGlobalAudioDispatch();
  // const globalAudioControls = useGlobalAudioControls()
  const globalAudioState = useGlobalAudioState();
  const [ref, audioSourceControl] = useRefAndState<HTMLAudioElement | null>(null);
  const audioInput = useRef<HTMLInputElement>(null);
  const deleteWarningResolve = React.useRef<(value: unknown) => void>();
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  // const pageAssociation = usePageAssociation(-1)
  const [audioObject, setAudioObject] = useState<AudioObject>({
    audioUrl: "",
    lessonMode: undefined,
    assetVersionId: undefined,
    audioFileName: "",
  });
  const [audioPlaying, setAudioPlaying] = useState<boolean>(false);
  const [audioHasPlayed, setAudioHasPlayed] = useState<boolean>(false);
  const [audioIsUploading, setAudioIsUploading] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");
  const [warningShowing, setWarningShowing] = React.useState(false);
  const [dropDownVisible, setDropDownVisible]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] =
    useState<boolean>(false);
  //const [audioOptions, setAudioOptions] = useRef<string>("Audio Options")
  const audioOptions = useRef<string>("Audio Options");
  const interactivityBuilderState = useInteractivityBuilderState();
  interface AudioObject {
    audioUrl: string;
    lessonMode: number | undefined;
    assetVersionId: number | undefined;
    audioFileName: string;
  }

  useEffect(() => {
    return () => {
      globalAudioState.globalAudioManager.pause();
    };
  }, []);
  const setAudioOptions = (selectedOption: string) => {
    audioOptions.current = selectedOption;
  };

  function indexSelector(index: number) {
    const newIndex = props.selectedNarration * 3 + index;
    if (pageContext?.pageManifest && "Audio" in pageContext.pageManifest && pageContext.pageManifest.Audio[newIndex]) {
      return newIndex;
    }
    return index;
  }

  const updateManifestAudioAsset = (
    pageManifest: any,
    assetData: AssetManagerTypes.CurrentSelectedAsset,
    audioIndex: number,
  ) => {
    //needs to check for the index to exist
    if (props.narratorHasAllModes && props.modeDrawerIsOpen) {
      //means it has 3 modes
      pageManifest.Audio.splice(indexSelector(audioIndex), 1, {
        ...pageManifest.Audio[indexSelector(audioIndex)],
        File: assetData ? assetData.blobPath : null,
        Version: assetData ? assetData.assetId : null,
      });
    } else {
      pageManifest.Audio[indexSelector(0)] = {
        ...pageManifest.Audio[indexSelector(0)],
        File: assetData ? assetData.blobPath : null,
        Version: assetData ? assetData.assetId : null,
      };
      pageManifest.Audio[indexSelector(1)] = {
        ...pageManifest.Audio[indexSelector(1)],
        File: assetData ? assetData.blobPath : null,
        Version: assetData ? assetData.assetId : null,
      };
      pageManifest.Audio[indexSelector(2)] = {
        ...pageManifest.Audio[indexSelector(2)],
        File: assetData ? assetData.blobPath : null,
        Version: assetData ? assetData.assetId : null,
      };
    }

    if (pageManifest.Audio[indexSelector(props.tab)].PronunciationText) {
      pageManifest.Audio[indexSelector(props.tab)].PronunciationText = null;
    }

    if (pageManifest.Audio[indexSelector(props.tab)].Prosody) {
      pageManifest.Audio[indexSelector(props.tab)].Prosody = null;
    }

    if (pageManifest.Audio[indexSelector(props.tab)].Language) {
      pageManifest.Audio[indexSelector(props.tab)].Language = null;
    }

    if (pageManifest.Audio[indexSelector(props.tab)].Voice) {
      pageManifest.Audio[indexSelector(props.tab)].Voice = null;
    }

    return pageManifest;
  };

  /**
   * This function will upload the selected file to the server and then associate it.
   * @param {HTMLInputElement["files"]} files This is the native array from input tag
   *
   */
  const uploadLocalFile = async (files: HTMLInputElement["files"]) => {
    const lessonData = {
      aircraftPlacementIds: null, //keep null
      ataIds: null, //not needed just keep null
      aircraftId: null, //keep null
      manufacturerId: null,
      versionName: "",
      aircraftFamilyId: null,
      tailoredForLmsKey: null, //from redux
      uploadForLmsKey: null,
    };
    if (files) {
      setAudioIsUploading(true);
      let _file = files[0];
      const fileBuffer = await _file.arrayBuffer();
      const fileBufferCopy = globalAudioState.globalAudioManager.copy(fileBuffer);
      _file = new File([fileBufferCopy], _file.name, { type: "audio/mp3" });
      const uploadResult = await uploaderFunctions.fileUploadProcess(_file, lessonData, 5);
      if (uploadResult && uploadResult.data && uploadResult.data.blobPath) {
        const url = uploadResult.data.blobPath;
        globalAudioState.globalAudioManager.loadArrayBuffer(fileBufferCopy, url);
      }
      setAudioIsUploading(false);
      const assetToRemove = uploaderFunctions.getAudioAssetsToRemove(
        pageContext.pageManifest,
        props.tab,
        uploadResult?.data?.assetId as number,
      );
      const newManifest = updateManifestAudioAsset(
        pageContext.pageManifest,
        uploadResult!.data as unknown as AssetManagerTypes.CurrentSelectedAsset,
        props.tab,
      );

      const versions: number[] = pageContext.pageManifest.Audio.map(
        (e: any, i: number) => props.tab !== i && e.Version,
      );
      const allModesTabActive = props.modeDrawerIsOpen === false && props.narratorHasAllModes === true;

      if (props.narratorHasAllModes && allModesTabActive) {
        // FMS/3D/WA All Modes tab upload/change
        pageContext.updatePageAsset(uploadResult?.data?.assetId as number, assetToRemove);
      } else if (props.narratorHasAllModes && !allModesTabActive && versions.includes(assetToRemove)) {
        // one tab was edited but other tabs still have the asset. So we do not want to remove it
        pageContext.updatePageAsset(uploadResult?.data?.assetId as number, null);
      } else {
        //remove the asset from exports
        pageContext.updatePageAsset(uploadResult?.data?.assetId as number, assetToRemove);
      }

      pageContext.updatePageManifest(newManifest);
      audioSourceControl!.load();
    }
  };

  function showWarning() {
    setWarningShowing(true);
    return new Promise((resolve) => {
      deleteWarningResolve.current = resolve;
    });
  }

  function deleteTextToSpeechInfoFromManifest(index: number) {
    const limit = index + 3;

    for (let i = index; i < limit; i++) {
      if (pageContext.pageManifest.Audio[i].PronunciationText) {
        pageContext.pageManifest.Audio[i].PronunciationText = null;
      }

      if (pageContext.pageManifest.Audio[i].Prosody) {
        pageContext.pageManifest.Audio[i].Prosody = null;
      }

      if (pageContext.pageManifest.Audio[i].Language) {
        pageContext.pageManifest.Audio[i].Language = null;
      }

      if (pageContext.pageManifest.Audio[i].Voice) {
        pageContext.pageManifest.Audio[i].Voice = null;
      }
    }
  }

  async function handleDeleteAudio() {
    // audioSourceControl!.pause();
    globalAudioState.globalAudioManager.pause();

    const confirmation = await showWarning();
    if (confirmation) {
      const assetToRemove = pageContext.pageManifest.Audio[props.tab].Version;
      const versions: number[] = pageContext.pageManifest.Audio.map(
        (e: any, i: number) => props.tab !== i && e.Version,
      );
      const allModesTabActive = props.modeDrawerIsOpen === false && props.narratorHasAllModes === true;

      if (props.narratorHasAllModes && !allModesTabActive && versions.includes(assetToRemove)) {
        // do not remove the audio asset from exports (book keep) because another mode has it on this page.
      } else {
        //remove the asset from exports
        pageContext.updatePageAsset(null, assetToRemove);

        if (allModesTabActive) {
          switch (indexSelector(props.tab)) {
            case 0:
            case 1:
            case 2:
              deleteTextToSpeechInfoFromManifest(0);
              break;
            case 3:
            case 4:
            case 5:
              deleteTextToSpeechInfoFromManifest(3);
              break;
          }
        } else {
          if (pageContext.pageManifest.Audio[indexSelector(props.tab)].PronunciationText) {
            pageContext.pageManifest.Audio[indexSelector(props.tab)].PronunciationText = null;
          }

          if (pageContext.pageManifest.Audio[indexSelector(props.tab)].Prosody) {
            pageContext.pageManifest.Audio[indexSelector(props.tab)].Prosody = null;
          }

          if (pageContext.pageManifest.Audio[indexSelector(props.tab)].Language) {
            pageContext.pageManifest.Audio[indexSelector(props.tab)].Language = null;
          }

          if (pageContext.pageManifest.Audio[indexSelector(props.tab)].Voice) {
            pageContext.pageManifest.Audio[indexSelector(props.tab)].Voice = null;
          }
        }
      }

      interactivityBuilderState.interactivityBuilderInstance?.cleanupWaitTasks();
      const newTree = interactivityBuilderState.interactivityBuilderInstance?.getRoot().printTree();
      lessonPagesDispatch({
        type: LessonPagesActions.UPDATE_CURRENT_PAGE_MANIFEST,
        payload: {
          interactivity: newTree,
        },
      });
      const newManifest = updateManifestAudioAsset(
        pageContext.pageManifest,
        "" as unknown as AssetManagerTypes.CurrentSelectedAsset,
        props.tab,
      );
      pageContext.updatePageManifest(newManifest);
    }

    setAudioPlaying(false);
  }

  function handleSelectedAudio(e: React.ChangeEvent<HTMLInputElement>) {
    globalAudioState.globalAudioManager.pause();
    setAudioPlaying(false);
    if (props.pageManifest && "Audio" in props.pageManifest) {
    }

    if (e.target.files!.length > 0) {
      const localUrl = URL.createObjectURL(e.target.files![0]);
      setAudioObject({
        ...audioObject,
        audioUrl: localUrl,
      });

      const mutableFile = new File([...e.target.files!], uploaderFunctions.massageFileName(e.target.files![0]));

      uploadLocalFile(e.target.files);
      setFileName(e.target.files![0].name);
      // setLocalAudio(localUrl)
      // addLocalFile(e.target.files!)
    }
    e.target.value = ""; // this will reset the input tag so we can upload the same file twice
  }

  const handleAudioSource = useCallback(() => {
    if (props.tab === 0) {
      if (checkIfTabHasAudioSrc(0)) {
        const path = blobUrlFormatHelper(props.pageManifest.Audio[indexSelector(0)].File);
        return path;
      } else {
        return "";
      }
    }
    if (props.tab === 1) {
      if (checkIfTabHasAudioSrc(1)) {
        return blobUrlFormatHelper(props.pageManifest.Audio[indexSelector(1)].File);
      } else return "";
    }
    if (props.tab === 2) {
      if (checkIfTabHasAudioSrc(2)) {
        return blobUrlFormatHelper(props.pageManifest.Audio[indexSelector(2)].File);
      } else return "";
    }
  }, [props.pageManifest, props.selectedNarration]);

  const handAudioName = useCallback(() => {
    //setFileName(props.pageManifest.Audio[0].File.split("/")[props.pageManifest.Audio[0].File.split("/").length-1])
    if (props.tab === 0) {
      if (checkIfTabHasAudioSrc(0)) {
        return props.pageManifest.Audio[indexSelector(0)].File.split("/")[
          props.pageManifest.Audio[indexSelector(0)].File.split("/").length - 1
        ];
      } else return "No Audio Uploaded";
    }
    if (props.tab === 1) {
      if (checkIfTabHasAudioSrc(1)) {
        return props.pageManifest.Audio[indexSelector(1)].File.split("/")[
          props.pageManifest.Audio[indexSelector(1)].File.split("/").length - 1
        ];
      } else return "No Audio Uploaded";
    }
    if (props.tab === 2) {
      if (checkIfTabHasAudioSrc(2)) {
        return props.pageManifest.Audio[indexSelector(2)].File.split("/")[
          props.pageManifest.Audio[indexSelector(2)].File.split("/").length - 1
        ];
      } else return "No Audio Uploaded";
    }
  }, [props.pageManifest, props.selectedNarration]);

  // const handleAudioName = useCallback(() => {
  //   if (props.tab === 0) {
  //     if (checkIfTabHasAudioSrc(0)) {
  //       return props.pageManifest.Audio[indexSelector(0)].File.split("/")[props.pageManifest.Audio[indexSelector(0)].File.split("/").length - 1];
  //     }
  //     else return "No Audio Uploaded"
  //   }
  //   if (props.tab === 1) {
  //     if (checkIfTabHasAudioSrc(1)) {
  //       return props.pageManifest.Audio[indexSelector(1)].File.split("/")[props.pageManifest.Audio[indexSelector(1)].File.split("/").length - 1]
  //     }
  //     else return "No Audio Uploaded"
  //   }
  //   if (props.tab === 2) {
  //     if (checkIfTabHasAudioSrc(2)) {
  //       return props.pageManifest.Audio[indexSelector(2)].File.split("/")[props.pageManifest.Audio[indexSelector(2)].File.split("/").length - 1]
  //     }
  //     else return "No Audio Uploaded"
  //   }
  // }, [props.pageManifest, props.selectedNarration])

  const checkIfTabHasAudioSrc = (tab: number) => {
    if (
      props.pageManifest &&
      "Audio" in props.pageManifest &&
      props.pageManifest.Audio[indexSelector(tab)] &&
      props.pageManifest.Audio[indexSelector(tab)].File
    ) {
      return true;
    } else {
      globalAudioDipatch({ type: "SET_AUDIO_LENGTH", payload: 0 });
      return false;
    }
  };
  const PlayPausePlaceHolder = ({ disabled }: any) => {
    return !audioIsUploading && !disabled ? (
      <>
        {!audioPlaying && (
          <PlayIcon
            className={
              checkIfTabHasAudioSrc(props.tab) ? "active-narrator-play-button" : "disabled-narrator-play-button"
            }
            onMouseDown={(e) => {
              // need to do this cause conflict with eventListener in BaseFFText.tsx
              e.stopPropagation();
            }}
            onClick={(e) => {
              e.stopPropagation();

              setAudioPlaying((ap: boolean) => {
                if (ap === true) {
                  // audioSourceControl!.pause();
                  globalAudioState.globalAudioManager.pause();
                } else if (ap === false && checkIfTabHasAudioSrc(props.tab)) {
                  setAudioHasPlayed((hp: boolean) => {
                    if (hp === false) {
                      if (props.tab === 0) {
                        // audioSourceControl!.load()
                        // audioSourceControl!.play();
                        globalAudioState.globalAudioManager.play();
                      } else if (props.tab === 1) {
                        audioSourceControl!.load();
                        // audioSourceControl!.play();
                        globalAudioState.globalAudioManager.play();
                      } else if (props.tab === 2) {
                        audioSourceControl!.load();
                        // audioSourceControl!.play();
                        globalAudioState.globalAudioManager.play();
                      }
                    } else {
                      if (props.tab === 0) {
                        // audioSourceControl!.play();
                        globalAudioState.globalAudioManager.play();
                      } else if (props.tab === 1) {
                        // audioSourceControl!.play();
                        globalAudioState.globalAudioManager.play();
                      } else if (props.tab === 2) {
                        // audioSourceControl!.play();
                        globalAudioState.globalAudioManager.play();
                      }
                    }
                    return true;
                  });
                }

                if (checkIfTabHasAudioSrc(props.tab)) {
                  return !ap;
                } else {
                  return ap;
                }
              });
            }}
          />
        )}
        {audioPlaying && (
          <PauseIcon
            className={
              checkIfTabHasAudioSrc(props.tab) ? "active-narrator-play-button" : "disabled-narrator-play-button"
            }
            onMouseDown={(e) => {
              // need to do this cause conflict with eventListener in BaseFFText.tsx
              e.stopPropagation();
            }}
            onClick={() => {
              setAudioPlaying((ap: boolean) => {
                if (ap === true) {
                  // audioSourceControl!.pause();
                  globalAudioState.globalAudioManager.pause();
                } else if (ap === false && checkIfTabHasAudioSrc(props.tab)) {
                  setAudioHasPlayed((hp: boolean) => {
                    if (hp === false) {
                      if (props.tab === 0) {
                        // audioSourceControl!.load()
                        // audioSourceControl!.play();
                        globalAudioState.globalAudioManager.play();
                      } else if (props.tab === 1) {
                        audioSourceControl!.load();
                        // audioSourceControl!.play();
                        globalAudioState.globalAudioManager.play();
                      } else if (props.tab === 2) {
                        audioSourceControl!.load();
                        // audioSourceControl!.play();
                        globalAudioState.globalAudioManager.play();
                      }
                    } else {
                      if (props.tab === 0) {
                        // audioSourceControl!.play();
                        globalAudioState.globalAudioManager.play();
                      } else if (props.tab === 1) {
                        // audioSourceControl!.play();
                        globalAudioState.globalAudioManager.play();
                      } else if (props.tab === 2) {
                        // audioSourceControl!.play();
                        globalAudioState.globalAudioManager.play();
                      }
                    }
                    return true;
                  });
                }

                if (checkIfTabHasAudioSrc(props.tab)) {
                  return !ap;
                } else {
                  return ap;
                }
              });
            }}
          />
        )}
      </>
    ) : (
      <div>
        <LoadingSvg />
      </div>
    );
  };

  useEffect(() => {
    //PAUz Effect
    if (audioSourceControl) {
      audioSourceControl.pause();
    }
    setAudioPlaying(false);
  }, [props.selectedTab, props.selectedPage, props.selectedNarration]); //insert here whenever component should be paused

  useEffect(() => {
    //PAUz Effect
    setAudioHasPlayed(false);
  }, [props.selectedPage, props.selectedNarration]); //insert here whenever audio should be refreshed

  useEffect(() => {
    if (audioSourceControl) {
      audioSourceControl.load();
    }
  }, [JSON.stringify(pageContext.pageManifest?.Audio)]);

  const createInput = () => {
    return (
      <input
        className="form-control-file"
        type="file"
        ref={audioInput}
        onChange={(e) => {
          handleSelectedAudio(e);
        }}
        hidden={true}
        accept="audio/mp3"
      />
    );
  };

  return (
    <div className="narrator-tab-audio-uploader">
      <DeleteAudioWarning show={warningShowing} setShow={setWarningShowing} res={deleteWarningResolve.current} />
      {
        //setFileName(pageManifest.Audio[modeIndex].File.substring(pageManifest.Audio[modeIndex].File.lastIndexOf("/")+1,pageManifest.Audio[modeIndex].File.length));
      }
      <p className="audio-tab-name" title={handAudioName()} style={{ marginBottom: "0.2%" }}>
        {handAudioName()}
      </p>
      <audio
        preload="auto"
        ref={ref}
        onLoadedData={(e) => {
          globalAudioDipatch({
            type: "SET_AUDIO_LENGTH",
            payload: e.currentTarget.duration,
          });
        }}
      >
        <source src={handleAudioSource()} type="audio/mpeg" />
      </audio>
      <PlayPausePlaceHolder disabled={globalAudioState.loading} />

      <div className="audio-options-component" data-tooltip-id="Audio Options">
        <Tooltip id="Audio Options" />
        <div className="audioOptionsButtonContainer">
          <div
            className="audio-options-dropdown-container"
            onClick={() => setDropDownVisible(!dropDownVisible)}
            onMouseDown={(e) => {
              e.stopPropagation();
            }}
          >
            <p>{audioOptions.current}</p>
            <ArrowIcon style={dropDownVisible ? { transform: "rotate(180deg)" } : {}} className="header-preview-svg" />
            <div
              className="audio-options-selection-container"
              style={dropDownVisible ? {} : { display: "none" }}
              onClick={(e) => {
                setDropDownVisible(false);
              }}
            >
              <TextToSpeech
                pageManifest={props.pageManifest}
                index={indexSelector(props.tab)}
                lessonMetaData={props.lessonMetaData}
              />
              <div
                className="audio-options-selection-choices"
                onClick={() => {
                  if (!props.disabled && audioInput.current) {
                    audioInput.current.click();
                    setAudioOptions("Upload Audio");
                  }
                }}
              >
                Upload Audio
              </div>
            </div>
          </div>
        </div>
      </div>

      {createInput()}
      {props.pageManifest &&
      props.pageManifest.Audio &&
      props.pageManifest.Audio[indexSelector(props.tab)] &&
      props.pageManifest.Audio[indexSelector(props.tab)].File ? (
        <TrashIcon onClick={props.disabled ? () => {} : handleDeleteAudio} />
      ) : (
        ""
      )}
    </div>
  );
});

NarratorAudio.displayName = "NarratorAudio";

const DeleteAudioWarning = (props: any) => {
  const globalAudioDipatch = useGlobalAudioDispatch();

  return props.show ? (
    <Portal path="delete-confirm-container">
      <div className="delete-confirm-modal">
        <div className="delete-confirm-header">
          <p>Remove Audio</p>
          <ExitIcon
            onClick={() => {
              props.setShow(false);
              props.res(false);
            }}
          />
        </div>
        <div className="delete-confirm-text">
          <p>The audio file that has been uploaded will be removed. are you sure that you would like to continue?</p>
        </div>
        <div className="delete-confirm-buttons-container">
          <button
            onClick={() => {
              props.setShow(false);
              props.res(false);
            }}
          >
            Cancel
          </button>
          <button
            onClick={() => {
              props.setShow(false);
              props.res(true);
              globalAudioDipatch({ type: "SET_AUDIO_LENGTH", payload: 0 });
            }}
          >
            Confirm
          </button>
        </div>
      </div>
    </Portal>
  ) : null;
};
export default NarratorAudio;
