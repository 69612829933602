import { useState, useContext, Dispatch, SetStateAction, useEffect } from "react";
import { Section, FramedInput } from "../../../panels/ObjectPropertiesPanel";
import { useSelectedObjectDispatch } from "../../../contexts/SelectedObjectProvider/SelectedObjectProvider";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../../contexts/ObjectsProvider";
import { useLessonPagesState } from "../../../contexts/LessonPagesProvider/LessonPagesProvider";
import { useTimeline } from "../../../contexts/TimelineProvider/TimelineProvider";
import { IPageContext, PageContext } from "../../../routes/builderContexts";
import { ElementTypes, IBasicPageAttributes } from "../../../pageTypes/BasicPage_Player/components/IBasePage";
import { IAnnotation } from "../../Annotation/models/IAnnotation";
import { BaseObject } from "../../../types";
import { BlurCutoutActionType } from "../../../utils/Cutout";
import { BlurCutoutObject, CutoutShapes } from "../../../types/Objects";
import ISymbolStyle from "../../Symbol/models/ISymbolStyle";
import ColorPicker from "./ColorPicker";
import ToggleSwitch from "../../LessonSettings/components/ToggleSwitch";
import { ReactComponent as PlusIcon } from "../../../assets/icons/Settings/add-new-tag.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/Common/remove-page-icon.svg";
import { useObjectIsInTime } from "../../../hooks/useObjectIsInTime";
import { useMiscUI } from "../../../contexts/MiscUI/MiscUIProvider";
import { nanoid } from "../../../lib/nanoId";

type BlurCutoutType = {
  selectedObject: BaseObject;
  isModalShown: boolean;
};

const BlurCutoutInput = ({ selectedObject, isModalShown }: BlurCutoutType) => {
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const pageManifest = pageContext.pageManifest;
  const [nodeToUpdate, setNodeToUpdate] = useState<any>();
  const [elementType] = useState<ElementTypes>("annotations");
  const [editableIndex] = useState<number>(-1);
  const objectsState = useObjectsState();
  const objectsDispatch = useObjectsDispatch();
  const [, setMiscUI] = useMiscUI();
  const animatedObject = objectsState.animatedObjects.find((o) => o.id === selectedObject?.objectId);
  const [tl] = useTimeline();
  const inTimeline = useObjectIsInTime(selectedObject.objectId);
  const currentTime = tl?.scrubbingCurrentTime;
  const frameAtCurrentTime = animatedObject?.frames?.find((frame) => frame.timestamp === currentTime);
  const xHovered = typeof frameAtCurrentTime?.x === "number";
  const yHovered = typeof frameAtCurrentTime?.y === "number";
  const widthHovered = typeof frameAtCurrentTime?.width === "number";
  const heightHovered = typeof frameAtCurrentTime?.height === "number";
  const blurHovered = typeof frameAtCurrentTime?.blur?.intensity === "number";
  const blurIntensity = selectedObject?.blur?.intensity;
  const hasBlur = typeof blurIntensity === "number";
  const rotationHovered = typeof frameAtCurrentTime?.rotation === "number";
  const { selectedPanel } = useLessonPagesState();

  const canEditHeight =
    objectsState?.selectedObjects[0]?.type?.toUpperCase().includes("LINE") ||
    objectsState?.selectedObjects[0]?.type?.toUpperCase().includes("ARROW")
      ? false
      : true;
  const blurCutoutList = selectedObject?.blurCutoutShapes || [];

  const blurCutoutBaseObject: BlurCutoutObject = {
    type: "rectangular",
    x: 50,
    y: 50,
    w: 100,
    h: 100,
    isVisible: true,
    isOpen: false,
    rotate: 0,
  };

  const cutoutShapes = ["rectangular", "circle", "triangle"];

  function handleFormatChange(funct: any, value: any, keyValue: any) {
    const newAttributes = funct(nodeToUpdate, value, keyValue);
    updateAttributes(newAttributes);
  }

  function updateAttributes(attributes: IAnnotation | IBasicPageAttributes | ISymbolStyle) {
    switch (elementType) {
      // case 'annotation':
      case "annotations":
        if (isModalShown === true) {
          pageManifest.refAnnotations[editableIndex] = attributes;
        } else {
          pageManifest.annotations[editableIndex] = attributes;
        }
        break;
      // case 'symbol':
      case "symbols":
        isModalShown === true
          ? (pageManifest.refSymbols[editableIndex] = attributes)
          : (pageManifest.symbols[editableIndex] = attributes);
        break;
      default:
        break;
    }
    setNodeToUpdate(attributes);
    pageContext.updatePageManifest(pageManifest);
  }

  const onBlurIntensityChange = (intensity: string) => {
    const blurIntensity = parseFloat(intensity);
    // selectedObjectDispatch({
    //   type: SelectedObjectActionTypes.SET_BLUR_INTENSITY,
    //   payload: blurIntensity,
    // });
    objectsDispatch({
      type: ObjectActionsType.SET_BLUR_INTENSITY,
      payload: { objectId: selectedObject.objectId, blurIntensity },
    });
    objectsDispatch({
      type: ObjectActionsType.UPSERT_OBJECT_FRAME,
      payload: {
        objectId: selectedObject.objectId,
        frame: {
          timestamp: currentTime,
          blur: { intensity: blurIntensity },
        },
      },
    });
  };

  function updateBlurCutout(
    index: number,
    action: BlurCutoutActionType,
    value: CutoutShapes | number | boolean | { x: number; y: number } | { w: number; h: number },
    blurCutoutList: BlurCutoutObject[],
    setBlurCutoutList: Dispatch<SetStateAction<BlurCutoutObject[]>>,
    objectId: string,
    currentTime: number,
  ) {
    const updatedList = blurCutoutList.map((item, i) => {
      if (i === index) {
        const updatedObject = { ...item };
        if (action === BlurCutoutActionType.TYPE) {
          updatedObject.type = value as CutoutShapes;
        } else if (action === BlurCutoutActionType.X) {
          updatedObject.x = value as number;
        } else if (action === BlurCutoutActionType.Y) {
          updatedObject.y = value as number;
        } else if (action === BlurCutoutActionType.W) {
          updatedObject.w = value as number;
        } else if (action === BlurCutoutActionType.H) {
          updatedObject.h = value as number;
        } else if (action === BlurCutoutActionType.IS_VISIBLE) {
          updatedObject.isVisible = value as boolean;
        } else if (action === BlurCutoutActionType.IS_OPEN) {
          updatedObject.isOpen = value as boolean;
        } else if (action === BlurCutoutActionType.ROTATE) {
          updatedObject.rotate = value as number;
        } else if (action === BlurCutoutActionType.RESIZE) {
          const { w, h } = value as { w: number; h: number };
          updatedObject.w = w;
          updatedObject.h = h;
        } else if (action === BlurCutoutActionType.POSITION) {
          const { x, y } = value as { x: number; y: number };
          updatedObject.x = x;
          updatedObject.y = y;
        }
        return updatedObject;
      } else {
        return item;
      }
    });
    setBlurCutoutList(updatedList);

    objectsDispatch({
      type: ObjectActionsType.UPSERT_OBJECT_FRAME,
      payload: {
        objectId: objectId,
        frame: {
          timestamp: currentTime,
          blurCutoutShapes: [...updatedList],
        },
      },
    });
  }

  useEffect(() => {
    return blurCutoutList.forEach((item) => {
      objectsDispatch({
        type: ObjectActionsType.UPDATE_BLUR_CUTOUT,
        payload: {
          cutoutId: item.id,
          props: { isOpen: false },
        },
      });
    });
  }, []);

  return (
    <>
      <Section title="Color" wrap boldTitle={false}>
        <ColorPicker
          selectedObject={selectedObject}
          keyValue="backgroundColor"
          handleFormatChange={handleFormatChange}
          color={selectedObject?.backgroundColor || ""}
        />
      </Section>
      <Section title={null} wrap={false}>
        <FramedInput
          label="Blur"
          min={0}
          value={hasBlur ? blurIntensity : 0}
          inputType="number"
          isHovered={blurHovered}
          onFrameRemove={() => {
            objectsDispatch({
              type: ObjectActionsType.DELETE_PROPERTY_FROM_OBJECT_FRAME,
              payload: {
                objectId: selectedObject.objectId,
                timestamp: currentTime,
                property: "blur",
              },
            });
          }}
          onInputChange={(e) => onBlurIntensityChange(e.target.value)}
        />
      </Section>
      <Section title="Blur Cutout" wrap boldTitle={false}>
        {!!blurCutoutList.length &&
          blurCutoutList.map((blurCutoutItem, index) => (
            <section
              onClick={() => {
                setMiscUI({
                  type: "SET_SELECTED_MASK_ID",
                  payload: blurCutoutItem.id,
                });
              }}
              key={`blurcutout-${index}`}
              className="blur-image-cutout-panel"
            >
              <div className="blur-cutout-internal-area">
                <div className={`blur-cutout-title-${blurCutoutItem.isOpen ? "border" : "no-border"}`}>
                  <div className="blur-cutout-title-left">
                    <TrashIcon
                      role="button"
                      onClick={() => {
                        objectsDispatch({
                          type: ObjectActionsType.DELETE_BLUR_CUTOUT,
                          payload: {
                            cutoutId: blurCutoutItem.id,
                          },
                        });
                      }}
                    />
                    <span>Blur Cutout {index + 1}</span>
                  </div>
                  <div
                    className={`expand-triangle-icon-${blurCutoutItem.isOpen ? "down" : "up"}`}
                    role="button"
                    onClick={() => {
                      blurCutoutList.forEach((item) => {
                        if (item.id !== blurCutoutItem.id) {
                          objectsDispatch({
                            type: ObjectActionsType.UPDATE_BLUR_CUTOUT,
                            payload: {
                              cutoutId: item.id,
                              props: { isOpen: false },
                            },
                          });
                        }
                      });
                      objectsDispatch({
                        type: ObjectActionsType.UPDATE_BLUR_CUTOUT,
                        payload: {
                          cutoutId: blurCutoutItem.id,
                          props: { isOpen: !blurCutoutItem.isOpen },
                        },
                      });
                    }}
                  ></div>
                </div>
                {blurCutoutItem.isOpen && (
                  <div className="blur-cutout-properties">
                    <Section title="Cutout shape" wrap>
                      <select
                        id="cutoutShape"
                        onChange={(e) => {
                          objectsDispatch({
                            type: ObjectActionsType.UPDATE_BLUR_CUTOUT,
                            payload: {
                              cutoutId: blurCutoutItem.id,
                              props: { type: e.target.value as CutoutShapes },
                            },
                          });
                        }}
                        value={blurCutoutItem.type}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        {cutoutShapes.map((item, index) => (
                          <option value={item} key={`blurcutout-select-options-${index}`}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </Section>
                    <Section title="Position" wrap>
                      <div className="sub-section-nowrap">
                        <FramedInput
                          label="X"
                          value={selectedObject.blurCutoutShapes[index]?.x || blurCutoutItem.x}
                          inputType="number"
                          isHovered={xHovered}
                          onInputChange={(e) => {
                            objectsDispatch({
                              type: ObjectActionsType.UPDATE_BLUR_CUTOUT,
                              payload: {
                                cutoutId: blurCutoutItem.id,
                                props: { x: Number(e.target.value) },
                              },
                            });
                            if (inTimeline) {
                              objectsDispatch({
                                type: ObjectActionsType.UPSERT_OBJECT_FRAME,
                                payload: {
                                  objectId: selectedObject.objectId,
                                  frame: {
                                    timestamp: currentTime,
                                    blurCutoutShapes: {
                                      ...selectedObject.blurCutoutShapes,
                                      x: Number(e.target.value),
                                    },
                                  },
                                },
                              });
                            }
                          }}
                          onFrameAdd={() => {
                            objectsDispatch({
                              type: ObjectActionsType.UPSERT_OBJECT_FRAME,
                              payload: {
                                objectId: selectedObject.objectId,
                                frame: {
                                  timestamp: currentTime,
                                  blurCutoutShapes: {
                                    ...selectedObject.blurCutoutShapes,
                                    x: selectedObject.blurCutoutShapes[index]?.x || blurCutoutItem.x,
                                  },
                                },
                              },
                            });
                          }}
                          onFrameRemove={() => {
                            objectsDispatch({
                              type: ObjectActionsType.DELETE_BLUR_CUTOUT_PROPERTY_FROM_OBJECT_FRAME,
                              payload: {
                                objectId: selectedObject.objectId,
                                timestamp: currentTime,
                                index,
                                property: "x",
                              },
                            });
                          }}
                          min={0}
                        />
                        <FramedInput
                          label="Y"
                          value={selectedObject.blurCutoutShapes[index]?.y || blurCutoutItem.y}
                          inputType="number"
                          isHovered={yHovered}
                          onInputChange={(e) => {
                            objectsDispatch({
                              type: ObjectActionsType.UPDATE_BLUR_CUTOUT,
                              payload: {
                                cutoutId: blurCutoutItem.id,
                                props: { y: Number(e.target.value) },
                              },
                            });
                            if (inTimeline) {
                              objectsDispatch({
                                type: ObjectActionsType.UPSERT_OBJECT_FRAME,
                                payload: {
                                  objectId: selectedObject.objectId,
                                  frame: {
                                    timestamp: currentTime,
                                    blurCutoutShapes: {
                                      ...selectedObject.blurCutoutShapes,
                                      y: Number(e.target.value),
                                    },
                                  },
                                },
                              });
                            }
                          }}
                          onFrameAdd={() => {
                            objectsDispatch({
                              type: ObjectActionsType.UPSERT_OBJECT_FRAME,
                              payload: {
                                objectId: selectedObject.objectId,
                                frame: {
                                  timestamp: currentTime,
                                  blurCutoutShapes: {
                                    ...selectedObject.blurCutoutShapes,
                                    y: selectedObject.blurCutoutShapes[index]?.y || blurCutoutItem.y,
                                  },
                                },
                              },
                            });
                          }}
                          onFrameRemove={() => {
                            objectsDispatch({
                              type: ObjectActionsType.DELETE_BLUR_CUTOUT_PROPERTY_FROM_OBJECT_FRAME,
                              payload: {
                                objectId: selectedObject.objectId,
                                timestamp: currentTime,
                                index,
                                property: "y",
                              },
                            });
                          }}
                          min={0}
                        />
                      </div>
                    </Section>
                    <Section title="Dimensions" wrap={false}>
                      {blurCutoutItem.type === "circle" ? (
                        <FramedInput
                          label="Size"
                          value={selectedObject.blurCutoutShapes[index]?.w || blurCutoutItem.w}
                          inputType="number"
                          isHovered={widthHovered}
                          onInputChange={(e) => {
                            if (inTimeline) {
                              objectsDispatch({
                                type: ObjectActionsType.UPSERT_OBJECT_FRAME,
                                payload: {
                                  objectId: selectedObject.objectId,
                                  frame: {
                                    timestamp: currentTime,
                                    blurCutoutShapes: {
                                      ...selectedObject.blurCutoutShapes,
                                      w: Number(e.target.value),
                                    },
                                  },
                                },
                              });
                            } else {
                              objectsDispatch({
                                type: ObjectActionsType.UPDATE_BLUR_CUTOUT,
                                payload: {
                                  cutoutId: blurCutoutItem.id,
                                  props: { w: Number(e.target.value) },
                                },
                              });
                            }
                          }}
                          onFrameAdd={() => {
                            objectsDispatch({
                              type: ObjectActionsType.UPSERT_OBJECT_FRAME,
                              payload: {
                                objectId: selectedObject.objectId,
                                frame: {
                                  timestamp: currentTime,
                                  blurCutoutShapes: {
                                    ...selectedObject.blurCutoutShapes,
                                    w: selectedObject.blurCutoutShapes[index]?.w || blurCutoutItem.w,
                                  },
                                },
                              },
                            });
                          }}
                          onFrameRemove={() => {
                            objectsDispatch({
                              type: ObjectActionsType.DELETE_BLUR_CUTOUT_PROPERTY_FROM_OBJECT_FRAME,
                              payload: {
                                objectId: selectedObject.objectId,
                                timestamp: currentTime,
                                index,
                                property: "w",
                              },
                            });
                          }}
                          min={0}
                        />
                      ) : (
                        <>
                          <FramedInput
                            label="W"
                            value={selectedObject.blurCutoutShapes[index]?.w || blurCutoutItem.w}
                            inputType="number"
                            isHovered={widthHovered}
                            onInputChange={(e) => {
                              objectsDispatch({
                                type: ObjectActionsType.UPDATE_BLUR_CUTOUT,
                                payload: {
                                  cutoutId: blurCutoutItem.id,
                                  props: { w: Number(e.target.value) },
                                },
                              });
                              if (inTimeline) {
                                objectsDispatch({
                                  type: ObjectActionsType.UPSERT_OBJECT_FRAME,
                                  payload: {
                                    objectId: selectedObject.objectId,
                                    frame: {
                                      timestamp: currentTime,
                                      blurCutoutShapes: {
                                        ...selectedObject.blurCutoutShapes,
                                        w: Number(e.target.value),
                                      },
                                    },
                                  },
                                });
                              }
                            }}
                            onFrameAdd={() => {
                              objectsDispatch({
                                type: ObjectActionsType.UPSERT_OBJECT_FRAME,
                                payload: {
                                  objectId: selectedObject.objectId,
                                  frame: {
                                    timestamp: currentTime,
                                    blurCutoutShapes: {
                                      ...selectedObject.blurCutoutShapes,
                                      w: selectedObject.blurCutoutShapes[index]?.w || blurCutoutItem.w,
                                    },
                                  },
                                },
                              });
                            }}
                            onFrameRemove={() => {
                              objectsDispatch({
                                type: ObjectActionsType.DELETE_BLUR_CUTOUT_PROPERTY_FROM_OBJECT_FRAME,
                                payload: {
                                  objectId: selectedObject.objectId,
                                  timestamp: currentTime,
                                  index,
                                  property: "w",
                                },
                              });
                            }}
                            min={0}
                          />
                          <FramedInput
                            label="H"
                            value={selectedObject.blurCutoutShapes[index]?.h || blurCutoutItem.h}
                            inputType="number"
                            isHovered={heightHovered}
                            canEditHeight={canEditHeight}
                            onInputChange={(e) => {
                              objectsDispatch({
                                type: ObjectActionsType.UPDATE_BLUR_CUTOUT,
                                payload: {
                                  cutoutId: blurCutoutItem.id,
                                  props: { h: Number(e.target.value) },
                                },
                              });
                              if (inTimeline) {
                                objectsDispatch({
                                  type: ObjectActionsType.UPSERT_OBJECT_FRAME,
                                  payload: {
                                    objectId: selectedObject.objectId,
                                    frame: {
                                      timestamp: currentTime,
                                      blurCutoutShapes: {
                                        ...selectedObject.blurCutoutShapes,
                                        h: Number(e.target.value),
                                      },
                                    },
                                  },
                                });
                              }
                            }}
                            onFrameAdd={() => {
                              objectsDispatch({
                                type: ObjectActionsType.UPSERT_OBJECT_FRAME,
                                payload: {
                                  objectId: selectedObject.objectId,
                                  frame: {
                                    timestamp: currentTime,
                                    blurCutoutShapes: {
                                      ...selectedObject.blurCutoutShapes,
                                      h: selectedObject.blurCutoutShapes[index]?.h || blurCutoutItem.h,
                                    },
                                  },
                                },
                              });
                            }}
                            onFrameRemove={() => {
                              objectsDispatch({
                                type: ObjectActionsType.DELETE_BLUR_CUTOUT_PROPERTY_FROM_OBJECT_FRAME,
                                payload: {
                                  objectId: selectedObject.objectId,
                                  timestamp: currentTime,
                                  index,
                                  property: "h",
                                },
                              });
                            }}
                            min={0}
                          />
                        </>
                      )}
                    </Section>
                    <Section title="Rotation" wrap={false}>
                      <FramedInput
                        label="Degrees"
                        value={selectedObject.blurCutoutShapes[index]?.rotate || blurCutoutItem.rotate || 0}
                        inputType="number"
                        isHovered={rotationHovered}
                        onInputChange={(e) => {
                          objectsDispatch({
                            type: ObjectActionsType.UPDATE_BLUR_CUTOUT,
                            payload: {
                              cutoutId: blurCutoutItem.id,
                              props: { rotate: Number(e.target.value) },
                            },
                          });
                          if (inTimeline) {
                            objectsDispatch({
                              type: ObjectActionsType.UPSERT_OBJECT_FRAME,
                              payload: {
                                objectId: selectedObject.objectId,
                                frame: {
                                  timestamp: currentTime,
                                  blurCutoutShapes: {
                                    ...selectedObject.blurCutoutShapes,
                                    rotate: Number(e.target.value),
                                  },
                                },
                              },
                            });
                          }
                        }}
                        onFrameAdd={() => {
                          objectsDispatch({
                            type: ObjectActionsType.UPSERT_OBJECT_FRAME,
                            payload: {
                              objectId: selectedObject.objectId,
                              frame: {
                                timestamp: currentTime,
                                blurCutoutShapes: {
                                  ...selectedObject.blurCutoutShapes,
                                  rotate: selectedObject.blurCutoutShapes[index]?.rotate || blurCutoutItem.rotate || 0,
                                },
                              },
                            },
                          });
                        }}
                        onFrameRemove={() => {
                          objectsDispatch({
                            type: ObjectActionsType.DELETE_BLUR_CUTOUT_PROPERTY_FROM_OBJECT_FRAME,
                            payload: {
                              objectId: selectedObject.objectId,
                              timestamp: currentTime,
                              index,
                              property: "rotate",
                            },
                          });
                        }}
                      />
                    </Section>
                    <Section separator={false} wrap>
                      <ToggleSwitch
                        initialIsOn={selectedObject.blurCutoutShapes[index]?.isVisible || blurCutoutItem.isVisible}
                        onChange={() => {
                          objectsDispatch({
                            type: ObjectActionsType.UPDATE_BLUR_CUTOUT,
                            payload: {
                              cutoutId: blurCutoutItem.id,
                              props: { isVisible: !blurCutoutItem.isVisible },
                            },
                          });
                        }}
                      />
                      Visibility
                    </Section>
                  </div>
                )}
              </div>
            </section>
          ))}
        <div
          className="tags-add blur-cutout-plus-icon"
          role="button"
          onClick={() => {
            objectsDispatch({
              type: ObjectActionsType.ADD_BLUR_CUTOUT,
              payload: {
                objectId: selectedObject.objectId,
                cutout: {
                  ...blurCutoutBaseObject,
                  x: 50,
                  y: 50,
                  w: 100,
                  h: 100,
                  isVisible: true,
                  isOpen: true,
                  rotate: 0,
                  id: nanoid(),
                },
              },
            });
          }}
        >
          <PlusIcon />
        </div>
      </Section>
    </>
  );
};

export default BlurCutoutInput;
