import React from "react";
import { Interweave } from "interweave";
import { BaseWYSIWYGType } from "../BaseWYSIWYG";
import blobHelper from "../../../../blobUrlFormatHelper";

const SGL = (props: BaseWYSIWYGType) => {
  const { pageManifest } = props;
  const imageName = blobHelper(pageManifest.pageImage[0].imagePath);

  return (
    <div className="basePageSGL">
      <div className="basePageSGLimage img-container">
        <div className="img-aspect-container">
          <div className="img-aspect-container-inside">
            <div className="img-aspect-centering">
              <img src={imageName} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="block basePage single-graphic-text">
        <Interweave content={pageManifest.textBlock[0].text} />
      </div>
    </div>
  );
};

export default SGL;
