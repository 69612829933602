import React, { useContext, useState, useEffect } from "react";
import { ICduRectangularButton } from "../Interfaces/ICdu";

import { IPageContext, PageContext } from "../../../routes/builderContexts";
import { IFmsPageManifest } from "../Interfaces/IFmsPageManifest";
import { IFmsContext, FmsContext } from "../Interfaces/fmsContext";

import blobUrlFormatHelper from "../../../components/blobUrlFormatHelper";

export interface ICduRectangularButtonsProps {
  rectangularButtons: ICduRectangularButton[];
  assignFmsKeypad: (id: string) => void;
  highlights: string[];
  cduAssets: any[] | undefined;
}

const CduRectangularButtons: React.FC<ICduRectangularButtonsProps> = (properties: ICduRectangularButtonsProps): any => {
  const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);
  const pagesContext: IPageContext = useContext<IPageContext>(PageContext);
  const pageManifest: IFmsPageManifest = pagesContext.pageManifest as IFmsPageManifest;
  const [highlightColor, setHighlightColor] = useState<string>("");

  const findLSKAssetPath = (id: string) => {
    if (id.length > 2) {
      const returnPath = properties.cduAssets?.filter((asset: any) => {
        if (id.includes("LSKR")) {
          return pageManifest.actions[id].length ? asset.id.includes("LSKR_on") : asset.id.includes("LSKR_off");
        } else {
          return pageManifest.actions[id].length ? asset.id.includes("LSKL_on") : asset.id.includes("LSKL_off");
        }
      });
      return returnPath ? pageManifest.cduPath + returnPath[0].href : "";
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (fmsContext.highlightFrom === "key") {
      setHighlightColor("red");
    } else {
      setHighlightColor("yellow");
    }
  });

  return properties.rectangularButtons.map((rectObject: ICduRectangularButton, key: number) => {
    if (
      rectObject.id.includes("LSK") &&
      fmsContext.currentCDU.cduAssets &&
      (pageManifest.cdu.includes("a220") || pageManifest.cdu.includes("787"))
    ) {
      return (
        <g key={key}>
          <rect
            onClick={() => {
              properties.assignFmsKeypad(rectObject.id);
            }}
            x={rectObject.xCoordinate}
            y={rectObject.yCoordinate}
            width={rectObject.width}
            height={rectObject.height}
            fill={"none"}
            stroke={properties.highlights.includes(rectObject.id) ? highlightColor : ""}
            strokeWidth="2"
            //detects if id name is a name in the action list
            //if it is in action list, pointer is active, if not in list, no pointer action can be done
            id={rectObject.id}
          />
          <image
            onClick={() => {
              properties.assignFmsKeypad(rectObject.id);
            }}
            x={rectObject.xCoordinate}
            y={rectObject.yCoordinate}
            width={rectObject.width}
            height={rectObject.height}
            preserveAspectRatio="none"
            href={blobUrlFormatHelper(findLSKAssetPath(rectObject.id))}
          />
        </g>
      );
    } else {
      return (
        <rect
          key={key}
          x={rectObject.xCoordinate}
          y={rectObject.yCoordinate}
          width={rectObject.width}
          height={rectObject.height}
          onClick={() => {
            properties.assignFmsKeypad(rectObject.id);
          }}
          fill="none"
          stroke={properties.highlights.includes(rectObject.id) ? highlightColor : ""}
          strokeWidth="2"
          pointerEvents="all"
          id={rectObject.id}
        />
      );
    }
  });
};

export default CduRectangularButtons;
