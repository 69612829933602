import React, { useMemo } from "react";
import { SVGAnnotationProps } from "../index";
import { HighlightAnnotation } from "../HighlightAnnotation";

export function SVGPointArrow({
  x,
  y,
  objectId,
  onMouseDown,
  isDisplayed,
  rotate,
  height,
  width,
  zIndex,
  opacity,
  borderColor,
  strokeWidth,
  backgroundColor,
}: SVGAnnotationProps) {
  const values = useMemo(() => {
    const values = {
      point1: 18,
      point1b: 10,
      point2: 3,
      point2b: 18,
      point3b: 25,
      lineStart: 7,
    };
    if (strokeWidth) {
      values.point2 = strokeWidth;
      values.point1 = 12 + strokeWidth;
      values.point1b = 12 - strokeWidth * 0.5;
      values.point3b = 23 + strokeWidth * 0.5;
      switch (strokeWidth) {
        case 1:
          values.lineStart = 2;
          break;
        case 2:
          values.lineStart = 4;
          break;
        case 3:
          values.lineStart = 6;
          break;
        case 4:
          values.lineStart = 8;
          break;
        case 5:
          values.lineStart = 10;
          break;
        case 6:
          values.lineStart = 10;
          break;
        case 7:
          values.lineStart = 12;
          break;
        case 8:
          values.lineStart = 14;
          break;
        case 9:
          values.lineStart = 16;
          break;
      }
    }
    return values;
  }, [strokeWidth]);
  const transformString = `translate(${x}px, ${y}px) rotate(${rotate ?? 0}deg)`;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="https://www.w3.org/1999/xlink"
      id={`fillArrow-${objectId}`}
      data-objectid={objectId}
      tabIndex={0}
      name="lineArrow"
      className="annotation"
      version="1.1"
      // viewBox={`0 0 auto 20`}
      height={`36`}
      // preserveAspectRatio="xMinYMid meet"
      preserveAspectRatio="xMinYMin meet"
      style={{
        transform: transformString,
        // scale: `${scaleString}`,
        opacity: isDisplayed ? opacity : 0.5,
        filter: isDisplayed ? "none" : "grayScale(100%)",
        width: `${width}px`,
        zIndex: zIndex,
      }}
      onMouseDown={(e: React.MouseEvent<SVGSVGElement>) => {
        onMouseDown(e, objectId);
      }}
    >
      <HighlightAnnotation objectId={objectId} />
      <svg height="100%">
        <polyline
          id={`pointPoly-${objectId}`}
          points={`${values.point1}, ${values.point1b} ${values.point2},${values.point2b} ${values.point1},${values.point3b}`}
          stroke={`${borderColor || "white"}`}
          fill={`transparent`}
          strokeWidth={`${strokeWidth || "4"}`}
          strokeLinejoin="round"
          strokeLinecap="round"
        />
      </svg>
      <line
        x1={`${values.lineStart}`}
        x2={`100%`}
        y1={`50%`}
        y2={`50%`}
        style={{
          fill: `${borderColor || "white"}`,
          stroke: `${borderColor || "white"}`,
          strokeLinecap: "butt",
          strokeWidth: `${strokeWidth || "4"}`,
        }}
      />
    </svg>
  );
}
