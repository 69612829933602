import React from "react";
import { Point3D } from "../hotSpotEditor/types";

type DetectionProps = {
  detectRad: any;
  proximityRange: number;
  proximityOffset: Point3D;
  position: Array<number>;
};
const DetectionRadius = (props: DetectionProps) => {
  const { detectRad, proximityRange, proximityOffset, position } = props;
  const { x: offsetX, z: offsetZ } = proximityOffset;
  const [x, y, z] = position;

  return (
    <mesh scale={[1, 1, 1]} position={[x - offsetX, y, z - offsetZ]} ref={detectRad}>
      <cylinderGeometry attach="geometry" args={[proximityRange, proximityRange, 0.25, 64]} /> :
      <meshBasicMaterial attach="material" color={"green"} transparent opacity={0.25} />
    </mesh>
  );
};

export default DetectionRadius;
