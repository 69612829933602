import { useState, useEffect } from "react";
import { Section, FramedInput } from "../../../panels/ObjectPropertiesPanel";
import {
  SelectedObjectActionTypes,
  useSelectedObjectDispatch,
} from "../../../contexts/SelectedObjectProvider/SelectedObjectProvider";
import { ObjectActionsType, useObjectsDispatch } from "../../../contexts/ObjectsProvider";
import { BaseObject } from "../../../types";

type BlurInputType = {
  selectedObject: BaseObject;
  currentTime?: number;
};

const BlurInput = ({ selectedObject }: BlurInputType) => {
  const [intensity, setIntensity] = useState(selectedObject?.blur?.intensity || 0);
  const selectedObjectDispatch = useSelectedObjectDispatch();
  const objectsDispatch = useObjectsDispatch();

  useEffect(() => {
    setIntensity(selectedObject?.blur?.intensity || 0);
  }, [selectedObject]);

  const onBlurIntensityChange = (intensity: string) => {
    const blurIntensity = Number(intensity);
    setIntensity(Number(blurIntensity));

    selectedObjectDispatch({
      type: SelectedObjectActionTypes.SET_BLUR_INTENSITY,
      payload: blurIntensity,
    });
    objectsDispatch({
      type: ObjectActionsType.SET_BLUR_INTENSITY,
      payload: { objectId: selectedObject.objectId, blurIntensity },
    });
  };

  return (
    <>
      <Section title={null} wrap={false} separator={false}>
        <FramedInput
          label="Blur"
          min={0}
          value={intensity}
          inputType="number"
          isHovered={false}
          onInputChange={(e) => onBlurIntensityChange(e.target.value)}
          svgShowing={false}
        />
      </Section>
    </>
  );
};

export default BlurInput;
