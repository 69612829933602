import React, { useRef } from "react";
import "../Cabin.css";
import VisualHotspot from "../components/hotspot";
import DetectionRadius from "../components/detectionRadius";
import { HSRProps } from "./types";

const HSR = (props: HSRProps) => {
  const ref = useRef(null);
  const proximityRef = useRef();

  if (!props.HotSpotList) {
    return <></>;
  }
  return (
    <>
      {props.HotSpotList.map((hsData, i) => {
        const [x, , z] = hsData.position;
        return (
          <group key={hsData.id}>
            <mesh
              key={hsData.id}
              position={hsData.position}
              rotation={hsData.rotation}
              scale={hsData.scale}
              // commenting this line out disables clicking on a 3D hotspot to open the respective IGA
              // onClick={() => props.checkActiveHotSpot(i)}
            >
              <VisualHotspot selected={props.hsIndex === i} isSphere={hsData.isSphere} meshRef={ref} />
            </mesh>
            <DetectionRadius
              position={[x, 0.25, z]}
              proximityOffset={hsData?.proximityOffset ?? { x: 0, y: 0, z: 0 }}
              detectRad={proximityRef}
              proximityRange={hsData.proximityRange}
            />
          </group>
        );
      })}
    </>
  );
};

export default HSR;
