import React from "react";
interface Props {
  id: string;
  width?: number;
  height?: number;
}
const HightlightRect = ({ id, width, height }: Props) => {
  return (
    <rect
      fill="none"
      width={width ? `${width}%` : "100%"}
      height={height ? `${height}%` : "100%"}
      strokeWidth="4"
      id={id}
      style={{
        zIndex: 1000,
      }}
    ></rect>
  );
};

export default HightlightRect;
