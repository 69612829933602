import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as CourseDevToolsIcon } from "../../../assets/icons/HeaderIcons/Copy-page-from-another-lesson-icon.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/Close-icon.svg";
import "./CopyPageFromLesson.css";
import LessonList from "../../LessonList/LessonList";
import PageList from "./components/PageList/PageList";
import SearchToolbar from "../../SearchToolbar/SearchToolbar";
import { useUpdateLessonLibrary } from "../../../contexts/LessonLibrary/LessonLibraryProvider";
import genericRepositoryService from "../../../services/genericRepositoryService";
import lodash from "lodash";
import ILessonPageDto from "../../../models/ILessonPageDto";
import { ILessonManagementContext, LessonManagementContext } from "../../../routes/builderContexts";
import { LessonPagesActions, useLessonPagesDispatch } from "../../../contexts/LessonPagesProvider/LessonPagesProvider";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../../contexts/ObjectsProvider";
import {
  SET_ALL_VISIBLE_HOTSPOTS,
  useInteractivityBuilderDispatch,
} from "../../../contexts/InteractivityBuilderProvider";
import { InteractivityBuilder } from "../../../lib/interactivity";
import { useInteracitvityHotspotDispatch } from "../../../contexts/InteractivityHotspotProvider";
import { useMetaVariableStore } from "../../../lib/SmartObject/store";

type CopyPageFromLessonProps = {
  CurrentLessonVersionId: number;
  ChangePageOrder?: (newOrderLessonPages: ILessonPageDto[], pageToGoTo?: number) => void;
};

const CopyPageFromLesson = (props: CopyPageFromLessonProps) => {
  const { CurrentLessonVersionId, ChangePageOrder } = props;
  const lessonManagementContext: ILessonManagementContext =
    useContext<ILessonManagementContext>(LessonManagementContext);
  const { lessonPages, currentlyDisplayedPageIndex } = lessonManagementContext;
  const [show, setShow] = useState<boolean>(false);
  const [selectedLessonVersionId, setSelectedLessonVersionId] = useState<number>(0);
  const [selectedPageVersionId, setSelectedPageVersionId] = useState<number>(0);
  const [linkedToParent] = useState(true);
  const [lessonSearchName, setLessonSearchName] = useState<string>("");
  const [selectedPage, setSelectedPage] = useState<any>(null);
  const lessonPagesDispatch = useLessonPagesDispatch();
  const objectsState = useObjectsState();
  const objectsDispatch = useObjectsDispatch();
  const interactivityHotspotDispatch = useInteracitvityHotspotDispatch();
  const lessonLibraryUpdater = useUpdateLessonLibrary();
  const interactivityBuilderDispatch = useInteractivityBuilderDispatch();
  const { clearAllMetaVariables, loadStore } = useMetaVariableStore();

  useEffect(() => {
    if (selectedLessonVersionId === 0) {
      resetModal();
    }
  }, [selectedLessonVersionId]);

  function resetModal() {
    setSelectedPageVersionId(0);
    setLessonSearchName("");
    setSelectedPage(null);
    setSelectedLessonVersionId(0);
  }

  const order =
    currentlyDisplayedPageIndex < 4
      ? 4
      : currentlyDisplayedPageIndex >= lessonPages.length - 3
      ? lessonPages.length - 3
      : currentlyDisplayedPageIndex + 1;

  const copyPage = async () => {
    if (selectedPageVersionId) {
      const copyPageRequest = linkedToParent
        ? {
            PageVersionId: selectedPageVersionId,
          }
        : {
            PageVersionId: selectedPageVersionId,
            trackParent: linkedToParent,
          };
      const response = await genericRepositoryService.addLessonPage(copyPageRequest);
      const lessonPagesCopy = lodash.cloneDeep(lessonPages);

      const page = {
        name: selectedPage.name,
        order: order,
        blobPath: "",
        shouldAutoPageForward: selectedPage.shouldAutoPageForward,
        isScorable: selectedPage.isScorable,
        lessonVersionId: CurrentLessonVersionId,
        lessonPageId: -1,
        pageId: -1,
        pageManifest: selectedPage.pageManifest,
        pagePlayerType: selectedPage.pagePlayerType,
        pageVersionId: response.data.pageVersionId,
        pageAssetIds: response.data.assetVersionIds,
        isClientSideNewPage: true,
        lessonPageIsDirty: false,
        pageManifestIsDirty: false,
        lessonModeIds: selectedPage.lessonModeIds,
        uniquePageListerID: lessonPagesCopy.length - 7,
      };

      lessonPagesCopy.splice(order, 0, page);
      if (ChangePageOrder) {
        ChangePageOrder(lessonPagesCopy, order);
      }
      if (page.pagePlayerType === "Base") {
        if (page.pageManifest.interactivity) {
          const interactivityBuilder = new InteractivityBuilder(page.pageManifest.interactivity);
          const hotspots = interactivityBuilder.getAllFirstHotspots();
          if (hotspots) {
            interactivityHotspotDispatch({
              type: SET_ALL_VISIBLE_HOTSPOTS,
              payload: hotspots,
            });
          } else {
            interactivityHotspotDispatch({
              type: SET_ALL_VISIBLE_HOTSPOTS,
              payload: [],
            });
          }
          interactivityBuilderDispatch({
            type: "SET_INSTANCE",
            payload: interactivityBuilder,
          });
        } else {
          const interactivityBuilder = new InteractivityBuilder();
          interactivityBuilderDispatch({
            type: "SET_INSTANCE",
            payload: interactivityBuilder,
          });
          interactivityHotspotDispatch({
            type: SET_ALL_VISIBLE_HOTSPOTS,
            payload: [],
          });
        }
      }

      lessonPagesDispatch({
        type: LessonPagesActions.SET_OBJECTS_ON_PAGE_MANIFEST,
        payload: {
          objects: objectsState.objectList,
          animatedObjects: objectsState.animatedObjects,
          images: objectsState.images,
          textBoxes: objectsState.textBoxes,
          tables: objectsState.tables,
          annotations: objectsState.annotations,
          videos: objectsState.videos,
          hotspots: objectsState.hotspots,
          scorm: objectsState?.objectList?.filter((object) => object?.type === "SCORM")[0],
          smartObjects: objectsState?.smartObjects,
          panoramicList: objectsState?.panoramicList,
        },
      });
      lessonPagesDispatch({
        type: LessonPagesActions.ADD_NEW_PAGE,
        payload: {
          page: page,
          pageIndex: order,
        },
      });
      lessonPagesDispatch({
        type: LessonPagesActions.SET_CURRENT_PAGE_INDEX,
        payload: order,
      });
      objectsDispatch({
        type: ObjectActionsType.SET_OBJECTS_FROM_PAGE_MANIFEST,
        payload: page.pageManifest,
      });

      clearAllMetaVariables();

      const newPageMetaVariables = selectedPage.pageManifest.metaVariables;
      const newPageMetaVariablesData = selectedPage.pageManifest.metaVariablesData;
      const newPageAnimatedMetaVariables = selectedPage.pageManifest.animatedMetaVariables;

      if (newPageMetaVariables) {
        loadStore(newPageMetaVariables, newPageMetaVariablesData, newPageAnimatedMetaVariables ?? []);
      }
    }
  };

  function toggleModal() {
    setShow((prev) => !prev);
  }

  function handleOnChange(e: string) {
    setLessonSearchName(e);
  }

  // fetchAllLessons
  return (
    <>
      <div className="icons-wrapper">
        <div
          className="header-icon"
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          onClick={toggleModal}
        >
          <CourseDevToolsIcon />
        </div>
        <p>Copy From Lesson</p>
      </div>

      <Modal
        show={show}
        onHide={toggleModal}
        dialogClassName={"copy-page-from-lesson-modal"}
        backdrop={"static"}
        keyboard={false}
        bsPrefix={"copy-page-from-lesson"}
      >
        <Modal.Header
          bsPrefix={"copy-page-from-lesson-header"}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
        >
          <Modal.Title className={"copy-page-from-lesson-title"}>
            <p>Copy Page From Lesson</p>
          </Modal.Title>
          <div className={"modal-close"}>
            <CloseIcon className={"modal-close-icon"} onClick={toggleModal} />
          </div>
        </Modal.Header>

        <Modal.Body
          bsPrefix={"copy-page-from-lesson-body"}
          onMouseDown={(e: { stopPropagation: () => void }) => {
            e.stopPropagation();
          }}
        >
          <div className={"search-toolbar"}>
            <SearchToolbar
              HandleOnChange={handleOnChange}
              RefreshHandler={lessonLibraryUpdater}
              Title={"Search Lesson"}
            />
          </div>
          <div className={"datatables"}>
            <div className={"select-lessons-list"}>
              <LessonList
                Show={show}
                setLessonVersionId={setSelectedLessonVersionId}
                SearchString={lessonSearchName}
                CurrentLessonVersionId={CurrentLessonVersionId}
              />
            </div>
            <div className={"select-page-list"}>
              <PageList
                PopulateList={show}
                LessonVersionId={selectedLessonVersionId}
                SetPageVersionId={setSelectedPageVersionId}
                SetSelectedPage={setSelectedPage}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer
          onMouseDown={(e: { stopPropagation: () => void }) => {
            e.stopPropagation();
          }}
        >
          <div className="copy-page-from-lesson-buttons">
            <button className={"copy-page-from-lesson-cancel-button"} onClick={toggleModal}>
              Cancel
            </button>
            <button
              className={"copy-page-from-lesson-copy-button"}
              onMouseDown={(e) => {
                e.stopPropagation();
              }}
              onClick={copyPage}
            >
              Copy Page
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CopyPageFromLesson;
