import React, { createContext, Context } from "react";
import IImageAsset from "../../models/IAsset";

export type IAssetData = {
  aircraftIds: string;
  aircraftPlacementIds: string;
  ataIds: string;
  description: string;
  tailoredForLmsKey: string;
  versionName: string;
};
export type IAudioObject = {
  File: string | null;
  Version: string;
  ParentVersion: string;
  FileMD5: string;
  NarratorText: string;
  PronuncationText: string;
};

export interface IUploaderContext {
  imagesList: IImageAsset[];
  rawFile: React.RefObject<HTMLInputElement>;
  description: string;
  aircraftIds: number;
  ataIds: number[];
  aircraftPlacementIds: string;
  isFilePresent: boolean;
  isFileSelectShown: boolean;
  isGoodFilename: boolean;
  isCollapsed: boolean;
  isOptionsShown: boolean;
  nameSplit: string;
  selectedImage: number;
  tailoredForLmsKey: string;
  versionName: string;
  uploadSuccess: boolean;
  selectedAudio: IAudioObject;
  narratorText: string;
  statusMessage: string;
  deleteWarning: boolean;
  uploadProcessing: boolean;
  clearStateVariables: () => void;
  createAudioPlaceholder: (audioArray: IAudioObject[], assetIndex: number) => void;
  removeAudio: () => void;
  resetAudio: () => void;
  setDeleteWarning: (value: boolean) => void;
  setStatusMessage: (value: string) => void;
  setDescription: (value: string) => void;
  setNarratorText: (value: string) => void;
  setSelectedAudio: (value: IAudioObject) => void;
  setUploadSuccess: (value: boolean) => void;
  handleChange: (event: any) => void;
  handleSelected: (event: any) => void;
  selectImages: () => void;
  handleSelectImageClick: () => void;
  handleUploadClick: () => void;
  massageFileName: (event: any) => void;
  setIsOptionsShown: (bool: boolean) => void;
  defineAudioDescription: () => void;
  manageFile: (e: any) => void;
  saveNarratorText: (text: string) => void;
  processUploadedFile: (clickEvent: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<void>;
  manageFileExtension: () => any;
  setAtaIds: (value: number[]) => void;
  setAircraftIds: (value: any) => void;
}

export const UploaderContext: Context<IUploaderContext> = createContext<IUploaderContext>({} as IUploaderContext);
