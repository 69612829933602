import { useMemo } from "react";
import { SVG_SMART_OBJECT_TYPES } from "../..";
import { useMetaVariableStore } from "../../store";
import { getHotSpotMetaVariablesKeys, getSmartObjectMetaVariableKey } from "../../utils";

const ALWAYS_RENDER_TYPES = [
  SVG_SMART_OBJECT_TYPES.IMAGE_SWAP,
  SVG_SMART_OBJECT_TYPES.ROTATE,
  SVG_SMART_OBJECT_TYPES.ROTATE_REF,
  SVG_SMART_OBJECT_TYPES.FILL,
  SVG_SMART_OBJECT_TYPES.FILL_VALUE,
  SVG_SMART_OBJECT_TYPES.DYNAMIC_TEXT,
];

export const useShouldRender = (
  objectId: string | undefined,
  groupId: string | undefined,
  action: SVG_SMART_OBJECT_TYPES | undefined,
  value: string | undefined,
  hotspotId?: string | undefined,
) => {
  let key = "";

  if (action === SVG_SMART_OBJECT_TYPES.HOTSPOT) {
    const hotspotKeys = getHotSpotMetaVariablesKeys(objectId, hotspotId, groupId);
    key = hotspotKeys.display;
  } else if (action === SVG_SMART_OBJECT_TYPES.SWAP_VALUE) {
    key = getSmartObjectMetaVariableKey(objectId, groupId);
  }

  const variables = useMetaVariableStore((s) => s.metaVariables[key]);
  const shouldRender = useMemo(() => {
    /**
     * If the element does not have any of these attributes
     * then it is a normal node/tag in the svg and it
     * should always be rendered.
     */
    if (!objectId || !groupId || !action) {
      return true;
    }

    /**
     * If the action of the node/tag is one of the following
     * then it should always be rendered
     * IMAGE_SWAP || ROTATE
     * (other action types as we implement them should be added here
     * if the also should always render)
     */
    if (ALWAYS_RENDER_TYPES.includes(action)) {
      return true;
    }

    /**
     * At this point the action type should either be
     * hotspot or swap_value, in the case of a swap value, the value attribute
     * is required, if for some reason it is not in the element we don't render it
     * (this case shouldn't happen)
     */
    if (action === SVG_SMART_OBJECT_TYPES.SWAP_VALUE && !value) {
      return false;
    }

    if (action === SVG_SMART_OBJECT_TYPES.HOTSPOT && hotspotId) {
      if (variables === true) return true;
    }

    if (action === SVG_SMART_OBJECT_TYPES.SWAP_VALUE) {
      if (variables && variables === value) {
        return true;
      }
    }

    /**
     * If somehow we reach here then we default to false
     * (this mean we're probably not handling a TYPE from the smart object)
     */
    return false;
  }, [objectId, groupId, value, action, variables]);

  return shouldRender;
};
