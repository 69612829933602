import React, { useEffect } from "react";
import * as THREE from "three";

type DetectionProps = {
  detectRad: any;
  proximityRange: number;
  proximityOffset: {
    x: number;
    y: number;
    z: number;
  };
  position: Array<number>;
};
const DetectionRadius = (props: DetectionProps) => {
  const { detectRad, proximityRange, proximityOffset, position } = props;

  useEffect(() => {
    if (!detectRad?.current || !proximityOffset) return;
    detectRad.current.position.set(position[0] - proximityOffset.x, 0.25, position[2] - proximityOffset.z);
  }, [detectRad.current, proximityRange, proximityOffset, position]);
  return (
    <mesh scale={[1, 1, 1]} position={position} ref={detectRad}>
      <cylinderGeometry attach="geometry" args={[proximityRange, proximityRange, 0.25, 64]} /> :
      <meshBasicMaterial attach="material" color={"green"} transparent opacity={0.25} />
    </mesh>
  );
};

export default DetectionRadius;
