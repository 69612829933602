import "./SymbolsMenu.css";
import React from "react";
import SymbolsDisplay from "./components/SymbolsDisplay";

type SymbolsMenu = {
  addNewAnnotation: (name: string, func: any) => void;
  addSymbolOLD: any;
};

const SymbolsMenu = (props: SymbolsMenu) => {
  const { addNewAnnotation } = props;
  // const [selectedIcon, setSelectedIcon]: [string, React.Dispatch<React.SetStateAction<string>>] = useState<string>("");
  // const [showSelectError, setShowSelectError]: [boolean, React.Dispatch<SetStateAction<boolean>>] = useState<boolean>(false);

  // const handleInsert = () => {
  //   if(selectedIcon === "") {
  //     setShowSelectError(true);
  //   }else {
  //     props.addSymbol(selectedIcon);
  //     props.setAreSymbolsShown(false);
  //   }
  // }

  // const handleClose = () => {
  //   setSelectedIcon("");
  //   setShowSelectError(false);
  //   props.setAreSymbolsShown(false);
  // }

  return (
    <div id="symbols-container">
      {/* <div id="symbols-menu-header">
        <span>Symbols</span>
      </div> */}
      {/* <div id="symbols-menu-search">
        <input type="search" />
      </div> */}
      <div id="symbols-selector-area">
        <SymbolsDisplay addNewAnnotation={addNewAnnotation} addSymbolOLD={props.addSymbolOLD} />
      </div>
      {/* <div id="symbols-recent">

      </div> */}
      {/* <div id="symbols-footer">
        <div>
          {showSelectError ?
            <p id='select-error'>Please Select a Symbol or Press 'Close' to Exit</p>
          : <></>}
        </div>

        <div>
          <div className='button' onClick={handleClose}>Close</div>
          <div className='button' onClick={handleInsert}>Insert</div>
        </div>
      </div> */}
    </div>
  );
};

export default SymbolsMenu;
