import React, { useContext } from "react";
import { IPageContext, PageContext } from "../../../routes/builderContexts";
import { IFmsPageManifest } from "../Interfaces/IFmsPageManifest";
import { IFmsContext, FmsContext } from "../Interfaces/fmsContext";
import { Interweave } from "interweave";

interface dropDrownProps {
  options: optionsObject;
  parentMenu: any;
  accessCduLineInformation: (id: string) => void;
  addDropDownRow: (dropName: string) => void;
  assignFmsKeypad: any;
  highlightedGroups: any;
}
interface optionsObject {
  [name: string]: string;
}

const CDUDropDown = (props: dropDrownProps) => {
  const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);
  const pagesContext: IPageContext = useContext<IPageContext>(PageContext);
  const pageManifest: IFmsPageManifest = pagesContext.pageManifest as IFmsPageManifest;
  const clickHandler = (event: React.MouseEvent, id: string): void => {
    if (fmsContext.cursorSelected) {
      fmsContext.findCursorObject(event.currentTarget.id);
    } else {
      props.accessCduLineInformation(event.currentTarget.id);
    }
  };

  function getWidth(parentMenu: any) {
    const width =
      parentMenu.actions[0]?.parameter?.openDropDown?.width !== undefined
        ? parentMenu.actions[0]?.parameter?.openDropDown?.width
        : parentMenu.width;
    return width;
  }

  function rect() {
    if (pageManifest.cdu.includes("a220")) {
      return (
        <rect
          fill={"#333333"}
          x={props.parentMenu.xCoordinate}
          y={props.parentMenu.yCoordinate + props.parentMenu.height + 2}
          height={22 * Object.keys(props.options).length + 22}
          width={getWidth(props.parentMenu)}
          stroke={"#cccccc"}
          strokeWidth="2"
        />
      );
    } else {
      return (
        <g>
          <rect
            fill={"#333333"}
            x={props.parentMenu.xCoordinate}
            y={props.parentMenu.yCoordinate + props.parentMenu.height + 1}
            height={22 * Object.keys(props.options).length + 22}
            width={getWidth(props.parentMenu)}
            stroke={"#cccccc"}
            strokeWidth="0"
          />
          <rect
            fill={"#888888"}
            x={props.parentMenu.xCoordinate + (getWidth(props.parentMenu) - 15)}
            y={props.parentMenu.yCoordinate + props.parentMenu.height + 1}
            height={22 * Object.keys(props.options).length + 22}
            width={15}
            stroke={"#cccccc"}
            strokeWidth="0"
          />
        </g>
      );
    }
  }

  return (
    <g>
      {rect()}
      {props.options ? (
        Object.keys(props.options).map((option: string, index: number) => (
          <foreignObject
            id={option}
            key={index + option}
            x={props.parentMenu.xCoordinate}
            y={2 + (props.parentMenu.yCoordinate + props.parentMenu.height) + index * 22}
            width={getWidth(props.parentMenu)}
            height={22}
            className={`${
              pageManifest.cdu.includes("a220")
                ? "cell-display cdu_display a220_cdu_data"
                : "cell-display cdu_display a220_cdu_data"
            } ${props.highlightedGroups.includes(option) ? "highlightedElement" : ""}`}
            onClick={
              document.getElementById("AddToDoTask")
                ? () => props.assignFmsKeypad(option)
                : (event: React.MouseEvent) => {
                    clickHandler(event, props.parentMenu.cduKeyName);
                  }
            }
          >
            <div
              className="displayDiv"
              id={option}
              style={{
                width: "100%",
                height: "100%",
                overflow: "show",
                textTransform: "uppercase",
                border: fmsContext.gridBorder,
              }}
            >
              <Interweave content={props.options[option]} />
            </div>
          </foreignObject>
        ))
      ) : (
        <></>
      )}

      <g
        onClick={() => {
          props.addDropDownRow(props.parentMenu.cduKeyName);
        }}
      >
        <rect
          x={props.parentMenu.xCoordinate}
          y={2 + props.parentMenu.yCoordinate + 22 * Object.keys(props.options).length + 6 + 22}
          height={22}
          width={getWidth(props.parentMenu)}
          fill={"#333333"}
          strokeWidth="2"
          stroke="white"
          strokeDasharray="2 4"
        />
        <text
          x={props.parentMenu.xCoordinate + getWidth(props.parentMenu) / 2 - 6}
          y={props.parentMenu.yCoordinate + 22 / 2 + 22 * Object.keys(props.options).length + 33}
          strokeWidth="2"
          stroke="white"
        >
          +
        </text>
      </g>
    </g>
  );
};
export default CDUDropDown;
