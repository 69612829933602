import React from "react";
import {
  useGLTF
} from "@react-three/drei";

const Chair = (props) => {
  const chairPath = props.modelPath;
  var fullChairPath = chairPath;
  switch (props.role) {
    case "Captain": {
      fullChairPath = chairPath + "/CopilotSeat.gltf";
      break;
    }
    case "FO": {
      fullChairPath = chairPath + "/PilotSeat.gltf";
      break;
    }
    default: {
      console.log(props.role);
    }
  }
  const { scene } = useGLTF(fullChairPath);
  return <primitive object={scene} />
};
export default Chair;
