import React, { useState, useEffect } from "react";
import { CDULayoutProps } from "./ClassicLayout";
import _ from "lodash";
import blobHelper from "../../../../../../components/blobUrlFormatHelper";
import { CPAT_LESSON_MODE_INSTRUCTIONAL } from "../../../../helpers/lessonmodetypes";

import SmartObjective from "../../../../components/SmartObjective/SmartObjective";
import { IFMSReferenceImage } from "../../../../../../pageTypes/FMS_Player/Interfaces/IFmsPageManifest";
import FMSReferenceImage from "../../components/FMSReferenceImage";
import FMSReferenceImageModal from "../../components/FMSReferenceImageModal";

const StandardCDULayout = (props: CDULayoutProps) => {
  const [labelClass, setLabelClass] = useState<string>("");
  useEffect(() => {
    const generateLabelClass = () => {
      return props.cduPath.includes("a220")
        ? "a220-preview-label"
        : props.cduPath.includes("b787-8")
        ? "b787-8-preview-label"
        : props.cduPath.includes("a350")
        ? "a350-preview-label"
        : props.cduPath.includes("145")
        ? "erj145-preview-label"
        : props.cduPath.includes("e190") || props.cduPath.includes("e175")
        ? "embraer-preview-label"
        : "CDU-grid-preview-label";
    };
    setLabelClass(generateLabelClass());
  }, []);
  return (
    <React.Fragment>
      <div className={props.getFMSRatio() > 78 ? "fms-row-grid medium-fms-row-grid" : "fms-row-grid"}>
        <div
          className={props.image?.width > props.image?.height ? "fms-left-container-wideCDU" : "fms-left-container"}
          id={`${props.getWidth()}`}
        >
          {/* {displayPageAttempts()} */}
          <FMSReferenceImage
            pageManifest={props.pageManifest}
            largeCduModel={false}
            cduHeigth={props.image?.height || 100}
            cduWidth={props.image?.width || 100}
            displayReferenceImageModal={props.displayReferenceImageModal}
            removeImage={props.removeImage}
            gridLayout={true}
          />
        </div>
        <div ref={props.fmsRef} className={"fms-container"}>
          <svg
            className="fms-svg"
            // width="35vw"
            // height="70vh"
            overflow="visible"
            width={`${props.fmsDims.width}`}
            height={`${props.fmsDims.height}`}
            viewBox={`0 0 ${props.image?.width} ${props.image?.height}`}
          >
            <g>
              <image height="100%" width="100%" overflow="visible" href={blobHelper(props.cduPath)} />
              {/* <rect fill="black" height={fmsDims.height} width={fmsDims.width} fillOpacity=".5"  />{" "} */}
              <rect fill="black" height={"100%"} width={"100%"} fillOpacity=".5" />{" "}
            </g>
          </svg>
          <div className="CDU-preview-label-center">
            <p className={labelClass}>CDU (Preview Only) - Configuration is only available in Edit Mode </p>
          </div>
        </div>
        <SmartObjective
          list={props.stepInstructions}
          enablingObjective={props.pageManifest.instructions.lessonInstructions.replace(/<[^>]*>/g, "")}
          enablingObjectiveTitle={props.pageManifest.instructions.lessonTitle.replace(/<[^>]*>/g, "")}
          listIndex={props.criteriaIndex}
          lessonMode={CPAT_LESSON_MODE_INSTRUCTIONAL}
          // isFeedbackShown={isHelperShown}
          // feedback={fms.getCustomFeedback(stateOfFMS())}
          // disableHint={maxAttempts === 0 ? true : false}
          setIsFeedbackShown={props.setIsHelperShown}
        />
      </div>
      {_.has(props.pageManifest, "pageImage") && (props.pageManifest.pageImage as IFMSReferenceImage[])?.length > 0 ? (
        <FMSReferenceImageModal
          imageSource={(props.pageManifest.pageImage as IFMSReferenceImage[])[0].imagePath}
          isModalShown={props.isReferenceShown}
          setIsModalShown={props.setIsReferenceShown}
          version={props.version}
          referenceTab={false}
          removeImage={props.removeImage}
          assetVersionId={
            props.pageManifest.pageImage ? (props.pageManifest.pageImage as IFMSReferenceImage[])[0].assetVersionId : -1
          }
          newPageImageId={props.newPageImageId}
          setNewPageImageId={props.setNewPageImageId}
        />
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default StandardCDULayout;
