import React from "react";
import { debounce } from "lodash";

export function limitString(str, limit) {
  let text = str;
  text = text.substring(0, limit);
  return str;
}
class Field extends React.Component {
  constructor(props) {
    super(props);

    this.updateName = props.updateName.bind(this);
  }
  componentDidMount() {
    this.ref && this.ref.focus();
  }
  _handleTextChange(e) {
    this.o = e.target.value;
    if (this.props.charLimit && this.props.charLimit < this.o.length) {
      this.o = limitString(this.o, this.props.charLimit);
    }
    if (!this.debouncedFn) {
      this.debouncedFn = debounce(() => {
        let searchString = this.o;
        this.updateName(searchString, this.props.currentIndex);
      }, 250);
    }
    this.debouncedFn();
  }

  render() {
    const { autoFocus, onBlur, onKeyPress, name } = this.props;

    // auto focus
    const ref = autoFocus
      ? (ref) => {
          this.ref = ref;
        }
      : null;
    return (
      <input
        onChange={this._handleTextChange.bind(this)}
        ref={ref}
        defaultValue={name}
        type="text"
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        maxLength={this.props.charLimit}
      />
    );
  }
}
export default class EditableContainer extends React.Component {
  constructor(props) {
    super(props);

    // init counter
    this.count = 0;

    // init state
    this.state = {
      edit: false,
    };
  }

  componentWillUnmount() {
    // cancel click callback
    if (this.timeout) clearTimeout(this.timeout);
  }

  // handleClick (e) {
  //   // todo: component unmount

  //   // cancel previous callback
  //   if (this.timeout) clearTimeout(this.timeout)

  //   // increment count
  //   this.count++

  //   // schedule new callback  [timeBetweenClicks] ms after last click
  //   this.timeout = setTimeout(() => {
  //     // listen for double clicks
  //     if (this.count === 2) {
  //       // turn on edit mode
  //       this.setState({
  //         edit: true,
  //       })
  //     }

  handleClick(e) {
    this.setState({
      edit: true,
    });
  }

  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.setState({
        edit: false,
      });
    }
  }

  handleBlur(event) {
    // handle saving here
    if (event.key === "Enter") {
    }
    // close edit mode
    this.setState({
      edit: false,
    });
  }

  render() {
    const { children, ...rest } = this.props;
    const { edit } = this.state;
    if (edit) {
      // edit mode
      return (
        <Field autoFocus {...rest} onBlur={this.handleBlur.bind(this)} onKeyPress={this.handleKeyPress.bind(this)} />
      );
    } else {
      // view mode
      // return (
      //   <div className={this.props.className}
      //     onClick={this.handleClick.bind(this)}
      //   >
      //     {children}
      //   </div>
      // )
      return React.createElement(
        this.props.tag ?? "div",
        {
          className: this.props.className,
          onClick: this.handleClick.bind(this),
        },
        children,
      );
    }
  }
}
