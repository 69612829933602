import _ from "lodash";
import { BoundType } from "../../react-moveable";
import { initialSymbolStyle } from "../../Symbol/helpers/initalSymbolStyle";
import { ISymbolV2 } from "../../Symbol/models/ISymbol";
import ISymbolStyle from "../../Symbol/models/ISymbolStyle";
// import { initialProperties } from "../assets/initialProperties";
import { Label } from "../classes/Label";
import { Line } from "../classes/Line";
import { NumberAnnotation } from "../classes/Number";
import { Shape } from "../classes/Shape";
import { Symbol } from "../classes/Symbol";
import { IAnnotation } from "../models/IAnnotation";
import { IAnnotationState } from "../models/IAnnotationState";

export const annotationsToAdd: any = {
  addLabel,
  addLineType,
  addNumberType,
  addShapeType,
  addSymbol,
};

function addLabel(
  annotations: IAnnotation[],
  type: string,
  index: number,
  bounds: BoundType,
  objectList: any,
  objectId: number,
) {
  const label = new Label(annotations, type, index, bounds, objectList, objectId);
  return label.add();
}

function addLineType(
  annotations: IAnnotation[],
  type: string,
  index: number,
  bounds: BoundType,
  objectList: any,
  objectId: number,
) {
  const line = new Line(annotations, type, index, bounds, objectList, objectId);
  return line.add();
}

function addNumberType(
  annotations: IAnnotation[],
  type: string,
  index: number,
  bounds: BoundType,
  objectList: any,
  objectId: number,
  pageDims: DOMRect,
) {
  const number = new NumberAnnotation(annotations, type, index, bounds, objectList, objectId);
  const newNumberType = number.add();
  newNumberType.width = number.getAnnotationWidth(pageDims);
  return newNumberType;
}

function addShapeType(
  annotations: IAnnotation[],
  type: string,
  index: number,
  bounds: BoundType,
  objectList: any,
  objectId: number,
) {
  const shape = new Shape(annotations, type, index, bounds, objectList, objectId);
  return shape.add();
}

function addSymbol(
  annotations: IAnnotation[],
  type: string,
  index: number,
  bounds: BoundType,
  objectList: any,
  objectId: number,
) {
  const symbol = new Symbol(annotations, type, index, bounds, objectList, objectId);
  return symbol.add();
}

function setZIndex(annotations: IAnnotation[], symbols: ISymbolV2[]) {
  let zIndex = 112;
  let annotationZ = 112;
  let symbolZ = 112;
  if (_.isEmpty(annotations) && _.isEmpty(symbols)) {
    return zIndex;
  }

  if (!_.isEmpty(annotations)) {
    annotationZ = findLargestAnnotationZ(annotations);
  }
  if (!_.isEmpty(symbols)) {
    symbolZ = findLargestSymbolZ(symbols);
  }
  zIndex = _.max([annotationZ, symbolZ]) as number;
  return zIndex;
}

function findLargestAnnotationZ(annotations: IAnnotation[]) {
  let zIndex = 112;
  _.forEach(annotations, (annotation) => {
    if (annotation.zIndex > zIndex) {
      zIndex = annotation.zIndex;
    }
  });
  return zIndex;
}

function findLargestSymbolZ(symbols: ISymbolV2[]) {
  let zIndex = 112;
  _.forEach(symbols, (symbol) => {
    if ((symbol.zIndex as number) > zIndex) {
      zIndex = symbol.zIndex as number;
    }
  });
  return zIndex;
}

function setInitialSymbolPosition(newSymbol: ISymbolV2, exists: boolean, aState: IAnnotationState) {
  if (aState.symbols === undefined || !_.isArray(aState.symbols)) {
    return newSymbol;
  }
  let symbol: ISymbolV2 = _.cloneDeep(newSymbol);
  const lastIndex: number = aState.symbols.length - 1;
  if (!aState.selectedAnnotation) {
    if (_.isEmpty(aState.symbols)) {
      symbol.top = 50 - symbol.height / 2;
      symbol.left = 50 - symbol.width / 2;
    } else {
      if (exists) {
        const lastSymbol: ISymbolV2 = _.findLast(aState.symbols, (symbol) => {
          return symbol.name === newSymbol.name;
        }) as ISymbolV2;
        symbol.top = lastSymbol.top + 2;
        symbol.left = lastSymbol.left + 2;
      } else {
        symbol.top = aState.symbols[lastIndex].top + 2;
        symbol.left = aState.symbols[lastIndex].left + 2;
      }
    }
    symbol = validatePosition(symbol, aState.bounds) as ISymbolStyle;
    return symbol;
  }
  return newSymbol;
}

function validatePosition(annotation: IAnnotation | ISymbolStyle, bounds: BoundType) {
  const newTop: number = 50 - annotation.height / 2; //in percent
  const newLeft: number = 50 - annotation.width / 2; //in percent
  let top = 0;
  let left = 0;

  if (!bounds.bottom || !bounds.right) {
    return annotation;
  }
  const potBottom = ((annotation.top + annotation.height + 1) / 100) * bounds.bottom;
  const potRight = ((annotation.left + annotation.width + 1) / 100) * bounds.right;

  if (potBottom > bounds.bottom) {
    top = newTop + 4;
  } else {
    top = annotation.top + 2;
  }

  if (potRight > bounds.right) {
    left = newLeft + 4;
  } else {
    left = annotation.left + 2;
  }
  annotation = { ...annotation, top: top, left: left };
  // current = getNewPositionIfAlreadyExists(elements, current);
  return annotation;
}

function checkIfSameSymbolExists(name: string, symbols: ISymbolV2[]) {
  return _.some(symbols, ["name", name]);
}

export function shouldMaintainRatio(target: any) {
  const annotationName: string[] = ["circledNumber", "circledLetter", "squareNumber", "squareLetter"];
  return _.includes(annotationName, `${target?.getAttribute("name")}`);
}

export function sendAnnotationToBottomZ(node: IAnnotation, annotations: IAnnotation[]) {
  const tempArray: IAnnotation[] = _.cloneDeep(annotations);
  _.forEach(annotations, (annotation, index) => {
    if (annotation.zIndex <= node.zIndex) {
      (tempArray[index].zIndex as number) += 1;
    }
  });
  return tempArray;
}

export function sendSymbolToBottomZ(node: ISymbolV2, symbols: ISymbolV2[]) {
  const tempArray: ISymbolV2[] = _.cloneDeep(symbols);
  _.forEach(symbols, (symbol, index) => {
    if ((symbol.zIndex as number) <= (node.zIndex as number)) {
      (tempArray[index].zIndex as number) += 1;
    }
  });
  return tempArray;
}

export function processAnnotationNewZGreaterThanOldZ(newZ: number, oldZ: number, annotations: IAnnotation[]) {
  const tempArray: IAnnotation[] = annotations.map((annotation) => {
    if (annotation.zIndex > oldZ && annotation.zIndex <= newZ) {
      annotation.zIndex -= 1;
      return annotation;
    }
    return annotation;
  });
  return tempArray;
}

export function processAnnotationNewZLessThanOldZ(newZ: number, oldZ: number, annotations: IAnnotation[]) {
  const tempArray: IAnnotation[] = annotations.map((annotation) => {
    if (annotation.zIndex > newZ && annotation.zIndex <= oldZ) {
      annotation.zIndex += 1;
      return annotation;
    }
    return annotation;
  });
  return tempArray;
}

export function processSymbolNewZGreaterThanOldZ(newZ: number, oldZ: number, symbols: ISymbolV2[]) {
  const tempArray: ISymbolV2[] = symbols.map((symbol) => {
    if ((symbol.zIndex as number) > oldZ && (symbol.zIndex as number) <= newZ) {
      (symbol.zIndex as number) -= 1;
      return symbol;
    }
    return symbol;
  });
  return tempArray;
}

export function processSymbolNewZLessThanOldZ(newZ: number, oldZ: number, symbols: ISymbolV2[]) {
  const tempArray: ISymbolV2[] = symbols.map((symbol) => {
    if ((symbol.zIndex as number) > newZ && (symbol.zIndex as number) <= oldZ) {
      (symbol.zIndex as number) -= 1;
      return symbol;
    }
    return symbol;
  });
  return tempArray;
}
