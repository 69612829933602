import React, { cloneElement, ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import classes from "../../utils/HTML/classes";

import { ReactComponent as PlayIcon } from "../../assets/icons/audio/play-icon.svg";
import { ReactComponent as PauseIcon } from "../../assets/icons/audio/pause-icon.svg";
import { ReactComponent as LoadingSpinner } from "../../assets/icons/Common/loading.svg";
import "./index.css";
import { audioBlobToApi } from "../../panels/TimelinePanel/helpers";

export interface AudioPlayerChildrenProps {
  ready: boolean;
  playing: boolean;
  loading: boolean;
  onClickHandler: () => void;
}
export interface AudioPlayerProps {
  className?: string;
  src: string | null;
  children?: ReactNode | ((props: AudioPlayerChildrenProps) => ReactNode);
  autoPlay: boolean;
}
export const AudioPlayer = ({ className, src, autoPlay = false, children }: AudioPlayerProps) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [ready, setReady] = useState(true);
  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickPlay = () => {
    if (playing) {
      audioRef?.current?.pause();
    } else {
      audioRef?.current?.play();
    }
  };

  const handleReady = () => {
    setReady(true);
    setLoading(false);

    if (autoPlay) {
      handleClickPlay();
    }
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.onloadeddata = () => {
        handleReady();
      };
      audioRef.current.onloadstart = () => {
        setReady(false);
        setLoading(true);
      };
      audioRef.current.onplay = () => {
        setPlaying(true);
      };
      audioRef.current.onpause = () => {
        setPlaying(false);
      };
      audioRef.current.onended = () => {
        setPlaying(false);
      };

      if (audioRef.current.readyState === 4) {
        handleReady();
      }
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.onloadeddata = null;
        audioRef.current.onloadstart = null;
        audioRef.current.onplay = null;
        audioRef.current.onpause = null;
        audioRef.current.onended = null;
        setReady(false);
        setPlaying(false);
      }
    };
  }, [audioRef?.current]);

  return (
    <div className={classes("audio-player-wrapper", className)}>
      {src && <audio ref={audioRef} src={audioBlobToApi(src)} hidden />}
      {!children ? (
        <>
          {ready ? (
            <button className="cpat-icon-button cpat-icon-button--md" onClick={handleClickPlay} title="Play/Resume">
              {playing ? <PauseIcon /> : <PlayIcon />}
            </button>
          ) : (
            <LoadingSpinner className="cpat-loading-spinner" />
          )}
        </>
      ) : typeof children === "function" ? (
        children({ playing, ready, loading, onClickHandler: handleClickPlay })
      ) : (
        <>
          {ready ? (
            cloneElement(children as ReactElement, {
              onClick: handleClickPlay,
            })
          ) : (
            <LoadingSpinner className="cpat-loading-spinner" />
          )}
        </>
      )}
    </div>
  );
};
