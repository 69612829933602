import React, { useContext, useEffect } from "react";
import lodash from "lodash";
import { IFmsContext, FmsContext } from "../Interfaces/fmsContext";
import { IPageContext, PageContext } from "../../../routes/builderContexts";
import { IFmsPageManifest } from "../Interfaces/IFmsPageManifest";
import blobUrlFormatHelper from "../../../components/blobUrlFormatHelper";

const CDUCellCursor = (props: any) => {
  //in development
  const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);
  const pagesContext: IPageContext = useContext<IPageContext>(PageContext);
  const pageManifest: IFmsPageManifest = pagesContext.pageManifest as IFmsPageManifest;

  const cursorColor = "";

  const objectID =
    pagesContext.pageManifest.pageLayouts[pageManifest.bootPage]?.objectOffsets?.[props.cursorCellObject.id];
  const objectHasHighlightOffset = props.cursorCellObject.highlight !== undefined ? true : false;
  const highlightOffset = objectHasHighlightOffset ? props.cursorCellObject.highlight : {};
  const objectHasOffset = objectID !== undefined ? true : false;
  const objectOffset = objectHasOffset
    ? {
        ...pagesContext.pageManifest.pageLayouts[pageManifest.bootPage]?.objectOffsets?.[props.cursorCellObject.id]
          .position,
      }
    : null;
  const cduHasCursor = props.cursor !== undefined ? true : false;

  const highlightPositon = {
    width: objectHasHighlightOffset
      ? highlightOffset.width
      : objectHasOffset
      ? objectOffset.width
      : props.cursorCellObject.width,
    height: objectHasHighlightOffset
      ? highlightOffset.height
      : objectHasOffset
      ? objectOffset.height
      : props.cursorCellObject.height,
    xCoordinate: objectHasHighlightOffset
      ? highlightOffset.xCoordinate
      : objectHasOffset
      ? objectOffset.xCoordinate
      : props.cursorCellObject.xCoordinate,
    yCoordinate: objectHasHighlightOffset
      ? highlightOffset.yCoordinate
      : objectHasOffset
      ? objectOffset.yCoordinate
      : props.cursorCellObject.yCoordinate,
  };

  const cursorPosition = cduHasCursor
    ? {
        width: objectHasOffset ? props.cursor.width : props.cursor.width,
        height: objectHasOffset ? props.cursor.height : props.cursor.height,
        xCoordinate: objectHasOffset
          ? (objectOffset.xCoordinate + (objectOffset.xCoordinate + objectOffset.width)) / 2 - props.cursor.width / 2
          : (props.cursorCellObject.xCoordinate + (props.cursorCellObject.xCoordinate + props.cursorCellObject.width)) /
              2 -
            props.cursor.width / 2,
        yCoordinate: objectHasOffset
          ? (objectOffset.yCoordinate + (objectOffset.yCoordinate + objectOffset.height)) / 2 - props.cursor.height / 2
          : (props.cursorCellObject.yCoordinate +
              (props.cursorCellObject.yCoordinate + props.cursorCellObject.height)) /
              2 -
            props.cursor.height / 2,
      }
    : {};

  useEffect(() => {
    const loadCursor = () => {
      if (pageManifest.pageLayouts && pageManifest.pageLayouts.cursorKey && pageManifest.pageLayouts.cursorKey !== "") {
        fmsContext.findCursorObject(pageManifest.pageLayouts.cursorKey);
      }
    };
    loadCursor();
  }, []);

  function getWidth(parentMenu: any, cursorCellObject: any, highlightPositon: any) {
    const onDropDownCell =
      cursorCellObject.cduKeyName.includes("Drop") && cursorCellObject.cduKeyName.includes("option") ? true : false;
    const width =
      highlightPositon.width !== undefined
        ? highlightPositon.width
        : cursorCellObject.highlight
        ? cursorCellObject.highlight.width
        : onDropDownCell
        ? parentMenu?.actions[0]?.parameter?.openDropDown?.width
        : cursorCellObject.width;

    return width;
  }

  const ModalDataPresent = () => {
    return props.cursor && props.cursorCellObject && Object.keys(props.cursorCellObject).length > 0 ? (
      props.cursorCellObject && props.cursorCellObject.cduKeyName ? (
        <>
          <rect
            id={"CDU CURSOR"}
            width={getWidth(props.parentMenu, props.cursorCellObject, highlightPositon)}
            height={highlightPositon.height}
            x={highlightPositon.xCoordinate}
            y={highlightPositon.yCoordinate}
            strokeWidth="2"
            stroke={props.cursorCellObject.cduKeyName !== "none" ? "cyan" : ""}
            fill="none"
          />
          <image
            href={blobUrlFormatHelper(props.cduPath + props.cursor.href)}
            width={cursorPosition.width}
            height={cursorPosition.height}
            x={cursorPosition.xCoordinate}
            y={cursorPosition.yCoordinate}
          />
        </>
      ) : (
        <></>
      )
    ) : (
      <></>
    );
  };
  const ModalDataNotPresent = () => {
    return props.cursor && props.cursorCellObject && !lodash.isEmpty(props.cursorCellObject) ? (
      <>
        <rect
          id={"CDU CURSOR"}
          width={getWidth(props.parentMenu, props.cursorCellObject, highlightPositon)}
          height={highlightPositon.height}
          x={highlightPositon.xCoordinate}
          y={highlightPositon.yCoordinate}
          strokeWidth="2"
          stroke={props.cursorCellObject.cduKeyName !== "none" ? "cyan" : ""}
          fill="none"
        />
        <image
          href={blobUrlFormatHelper(props.cduPath + props.cursor.href)}
          width={cursorPosition.width}
          height={cursorPosition.height}
          x={cursorPosition.xCoordinate}
          y={cursorPosition.yCoordinate}
        />
      </>
    ) : (
      <></>
    );
  };

  return pageManifest.pageLayouts.cursorKey &&
    props.cursorCellObject !== "" &&
    (pageManifest.cdu.includes("a220") || pageManifest.cdu.includes("a350")) ? (
    <g
      onClick={() => {
        fmsContext.setCursorSelected(!fmsContext.cursorSelected);
      }}
    >
      {Object.keys(props.modalData).length === 0 ? <ModalDataNotPresent /> : <ModalDataPresent />}
      <rect
        width={props.cursor.width}
        height={props.cursor.height}
        x={
          !props.cursorCellObject.cduKeyName.includes("column")
            ? cursorPosition.xCoordinate
            : props.cursorCellObject.xCoordinate
        }
        y={
          !props.cursorCellObject.cduKeyName.includes("column")
            ? cursorPosition.yCoordinate
            : props.cursorCellObject.yCoordinate
        }
        strokeWidth="2"
        stroke={fmsContext.cursorSelected ? "yellow" : ""}
        fill="none"
      ></rect>
    </g>
  ) : (
    <></>
  );
};

export default CDUCellCursor;
