import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Highlight } from "../../../assets/icons/text-highlight-1.svg";

type HighlightType = {
  colors: Array<string>;
  highlightRef: React.RefObject<HTMLDivElement>;
};

const TextHighlightButton = (props: HighlightType) => {
  const { colors, highlightRef } = props;

  function makeSelectColors() {
    return _.map(colors, (color) => {
      return <option key={color} value={`${color}`}></option>;
    });
  }

  return (
    <React.Fragment>
      <div ref={highlightRef} className="toolbar-btn-container">
        <select className="ql-background wysiwyg-toolbar-btn">{makeSelectColors()}</select>
      </div>
    </React.Fragment>
  );
};

export default TextHighlightButton;
