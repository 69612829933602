import React from "react";
import { AnnotationMenuIconType } from "../AnnotationMenu";
import AnnotationMenuLabels from "../icons/AnnotationMenuLabelIcon";

export const AnnotationLabelIcons: AnnotationMenuIconType[] = [
  {
    name: "label",
    icon: <AnnotationMenuLabels />,
  },
];
