import { Audio } from "../../classes/GlobalAudioManager/types";
import { NarrationAudioState, PageAudioManagerState, TimelineAudioState } from "./types";
import { LanguageType } from "../../models/IInteractiveAudio";

/**
 * Filter out source, blob and other memory heavy properties
 * Bring only what's needed to the context
 * @param audio
 */
type AudioProps = { objectId: string } & Partial<Audio>;

export const defaultAudioStateProps = (audio: AudioProps) => {
  return {
    start: audio.start ?? 0,
    end: audio.end ?? 0,
    duration: audio.duration ?? 0,
    playing: false,
    loading: false,
    ...audio,
  };
};

export const getSequenceAudios = (state: PageAudioManagerState) => {
  return [...state.audios, ...state.narrationAudios]
    .filter((audio) => audio.language === state.selectedLanguage)
    .sort((audioA, audioB) => {
      return audioA.start > audioB.start ? 1 : -1;
    });
};

export const getAudioPosition = (
  duration: number,
  beforeObject: TimelineAudioState | null,
  state: PageAudioManagerState,
): [{ start: number; end: number }, boolean] => {
  const sequenceAudios = getSequenceAudios(state);
  const lastItem = sequenceAudios?.[sequenceAudios.length - 1];

  let start: number;
  let end: number;
  let shouldPushAudios = false;

  if (beforeObject) {
    // PREPEND
    start = beforeObject.start;
    end = start + duration;

    // Shift everyone else to the right
    shouldPushAudios = true;
  } else {
    // APPEND
    if (lastItem) {
      start = lastItem.end;
      end = lastItem.end + duration;
    } else {
      start = 0;
      end = duration;
    }
  }

  // Return new position
  return [{ start, end }, shouldPushAudios];
};

export const getAudioStateObject = (objectId: string, state: PageAudioManagerState) => {
  const { audios, narrationAudios } = state;

  return (
    audios.find((audio) => audio.objectId === objectId) ??
    narrationAudios.find((audio) => audio.objectId === objectId) ??
    null
  );
};

export const getAudioChildren = (objectId: string, state: PageAudioManagerState) => {
  const { audios, narrationAudios } = state;

  return [
    ...audios.filter((audio) => audio.parentObjectId === objectId),
    ...narrationAudios.filter((audio) => audio.parentObjectId === objectId),
  ];
};

export const getSortedNarrations = (narrations: NarrationAudioState[]) => {
  return narrations.sort((audioA, audioB) => {
    return audioA.start > audioB.start ? 1 : -1;
  });
};

export const removeSelfFromNarration = (objectId: string, narrations: NarrationAudioState[]) => {
  return narrations.filter((audio) => audio.objectId !== objectId && audio.parentObjectId !== objectId);
};

export const getSecondaryAudio = (objectId: string, state: PageAudioManagerState) =>
  getSortedNarrations(state.narrationAudios)
    .filter((audio) => audio.language === LanguageType.SECONDARY)
    .find((audio) => audio.parentObjectId === objectId);

export const getAllAudiosAfterTime = (timeInSeconds: number, state: PageAudioManagerState) =>
  getSortedNarrations(state.narrationAudios).filter((audio) => audio.start >= timeInSeconds);

// export const getAudiosAfterTime = (timeInSeconds: number, language: LanguageType, state: PageAudioManagerState) =>
//   getSortedNarrations(state.narrationAudios)
//     .filter((audio) => audio.language === language)
//     .filter((audio) => audio.start >= timeInSeconds);

export const getSortedAudios = (audios: NarrationAudioState[]) => {
  return audios.sort((audioA, audioB) => {
    return audioA.start > audioB.start ? 1 : -1;
  });
};

export const getAudiosAfterTime = (timeInSeconds: number, audios: NarrationAudioState[]) =>
  audios.filter((audio) => audio.start >= timeInSeconds);

export const getAudiosByLanguage = (language: LanguageType, audios: NarrationAudioState[]) =>
  audios.filter((audio) => audio.language === language);

export const removeAudioFromList = (objectId: string, audios: NarrationAudioState[]) => {
  return audios.filter((audio) => audio.objectId !== objectId && audio.parentObjectId !== objectId);
};

export const getBiggestDuration = (audios: NarrationAudioState[]) => {
  return audios.reduce((duration, audio) => (audio.duration > duration ? audio.duration : duration), 0);
};

export const getSecondary = (objectId: string, audios: NarrationAudioState[]) =>
  audios.find((audio) => audio.parentObjectId === objectId);

export const getGroupEnd = (objectId: string, audios: NarrationAudioState[]) => {
  const primaryAudio = audios.find((audio) => audio.objectId === objectId);
  const secondaryAudio = getSecondary(objectId, audios);

  if (!primaryAudio) return 0;

  return secondaryAudio && secondaryAudio.end > primaryAudio.end ? secondaryAudio.end : primaryAudio.end ?? 0;
};
