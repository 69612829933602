import React from "react";
import { HighlightAnnotation } from "../HighlightAnnotation";
import { SVGAnnotationProps } from "../index";

export function SVGDashedLine({
  objectId,
  opacity,
  x,
  y,
  rotate,
  width,
  zIndex,
  onMouseDown,
  borderColor,
  strokeWidth,
  isDisplayed,
}: SVGAnnotationProps) {
  const transformString = `translate(${x}px, ${y}px) rotate(${rotate ?? 0}deg)`;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="https://www.w3.org/1999/xlink"
      data-objectid={objectId}
      id={`dashedLine-${objectId}`}
      tabIndex={0}
      name="dashedLine"
      className="annotation"
      version="1.1"
      // viewBox={`0 0 auto 20`}
      height={`20`}
      preserveAspectRatio="xMinYMid meet"
      style={{
        width: `${width}px`,
        zIndex: zIndex,
        transform: transformString,
        opacity: isDisplayed ? opacity : 0.5,
        filter: isDisplayed ? "none" : "grayScale(100%)",
      }}
      onMouseDown={(e: React.MouseEvent<SVGSVGElement>) => {
        onMouseDown(e, objectId);
      }}
    >
      <HighlightAnnotation objectId={objectId} />
      <line
        className="annotation-arrow-arm"
        x1="0"
        x2={`100%`}
        y1={`50%`}
        y2={`50%`}
        style={{
          fill: `${borderColor || "white"}`,
          stroke: `${borderColor || "white"}`,
          strokeLinecap: "butt",
          strokeDasharray: `12 9`,
          strokeWidth: `${strokeWidth || "4"}`,
        }}
      />
    </svg>
  );
  // }
}
