import React, { useRef, useState, useEffect } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import "../../CPaT3d_Player/components/hotSpotEditor/hsEditor.css";
import VisualHotspot from "../components/hotspot";
import { ControlProps, DetectionRadiusProps } from "./types";
import { Mesh } from "three";

import { 
  OrbitControls,
  TransformControls
 } from "@react-three/drei";

import type { OrbitControls as OC } from "three-stdlib"

// import { CustomOrbitControls } from "./customOControls";
// extend({ CustomOrbitControls });

const DetectionRadius = (props: DetectionRadiusProps) => {
  const { proximityRange, newHSref, detectRad, isVisible } = props;
  return newHSref.current ? (
    <mesh ref={detectRad}>
      <cylinderGeometry attach="geometry" args={[proximityRange, proximityRange, 0.25, 64]} /> :
      <meshBasicMaterial attach="material" color={"green"} transparent opacity={isVisible ? 0.25 : 0} />
    </mesh>
  ) : null;
};

const Controls = (props: ControlProps) => {
  const nudge = props.boundaries ? 1 / props.boundaries.max.y : 0.25;
  const { camera, gl } = useThree();
  const camControls = useRef<OC>(null);

  useEffect(() => {
    if(camControls.current){
      camControls.current.listenToKeyEvents(window);
    }
  }, [camControls.current])
  //TODO
  const newHSref = useRef<any>({rotation: [0,0,0], position: [0,0,0], isSphere: true, scale: [1,1,1], geometry: [1,32,32]});
  const sentHSref = useRef<any>({rotation: [0,0,0], position: [0,0,0], isSphere: true, scale: [1,1,1], geometry: [1,32,32]});

  const detectRad = useRef<Mesh>(null);
  const { hsIndex, proximityRange, proximityOffset, editMode, HotSpotList, positionRef } = props;

  useFrame(() => {
    if (!newHSref.current) return;

    const { scale, position, rotation } = newHSref.current;
    const [rotationX, rotationY, rotationZ] = rotation.toArray();
    // this ref is passed from all the way up to CabinPlayer.tsx; this stores the most recent
    // position, scale, etc without causing unnecessary re-renders  and allows to save it when
    // the save button is clicked
    positionRef.current = {
      scale: [scale.x, scale.y, scale.z],
      position: [position.x, position.y, position.z],
      rotation: [rotationX, rotationY, rotationZ],
      geometry: [scale.x, 32, 32],
    };
  });

  useEffect(() => {
    if(newHSref.current === null) {
      return;
    }
    const [x, , z] = HotSpotList[hsIndex].position;
    camera.position.set(x, 7, z - 3);
    camera.rotation.set(0, 0, 0);
    camera.far = 5000;
    camera.near = 0.01;
    // @ts-ignore
    camera.fov = 75;
    newHSref.current.scale.set(1, 1, 1);
    newHSref.current.position.set(0, 8, 0);
    newHSref.current.rotation.set(0, 0, 0);
    newHSref.current.isSphere = true;

    if (props.HotSpotList[hsIndex]) {
      if (
        props.HotSpotList[hsIndex].geometry[0] > 0 &&
        props.HotSpotList[hsIndex].geometry[1] > 0 &&
        props.HotSpotList[hsIndex].geometry[2] > 0
      ) {
        newHSref.current.position.set(
          props.HotSpotList[hsIndex].position[0],
          props.HotSpotList[hsIndex].position[1],
          props.HotSpotList[hsIndex].position[2],
        );
        newHSref.current.isSphere = props.isSphere;
        newHSref.current.scale.set(
          props.HotSpotList[hsIndex].scale[0],
          props.HotSpotList[hsIndex].scale[1],
          props.HotSpotList[hsIndex].scale[2],
        );
        newHSref.current.rotation.set(
          props.HotSpotList[hsIndex].rotation[0],
          props.HotSpotList[hsIndex].rotation[1],
          props.HotSpotList[hsIndex].rotation[2],
        );
      }
    }
  }, []);

  useFrame(({ camera }) => {
    if (!newHSref.current || !props.boundaries) return;

    // do not let the hotspot get out of the boundaries
    if (newHSref.current.position.y < props.boundaries.min.y) {
      newHSref.current.position.y = props.boundaries.min.y;
    }
    if (newHSref.current.position.y > props.boundaries.max.y) {
      newHSref.current.position.y = props.boundaries.max.y;
    }
    if (newHSref.current.position.x < props.boundaries.min.x) {
      newHSref.current.position.x = props.boundaries.min.x;
    }
    if (newHSref.current.position.x > props.boundaries.max.x) {
      newHSref.current.position.x = props.boundaries.max.x;
    }
    if (newHSref.current.position.z < props.boundaries.min.z) {
      newHSref.current.position.z = props.boundaries.min.z;
    }
    if (newHSref.current.position.z > props.boundaries.max.z) {
      newHSref.current.position.z = props.boundaries.max.z;
    }
    if (newHSref.current.position.y < props.boundaries.min.y) {
      newHSref.current.position.y = props.boundaries.min.y;
    }
    if (newHSref.current.position.y > props.boundaries.max.y) {
      newHSref.current.position.y = props.boundaries.max.y;
    }
    if (!camControls.current || !detectRad.current?.position || !camControls.current.update) return;
    camera.updateProjectionMatrix();
    camControls.current.target = newHSref.current.position;
    detectRad.current.position.set(
      newHSref.current.position.x - proximityOffset.x,
      0.3,
      newHSref.current.position.z - proximityOffset.z,
    );
    camera.lookAt(newHSref.current.position);
    camControls.current?.update();
  });

  useFrame(() => {
    if (!props.boundaries) return;
    if (camera.position.x < props.boundaries.min.x) {
      camera.position.x = props.boundaries.min.x;
    }
    if (camera.position.x > props.boundaries.max.x) {
      camera.position.x = props.boundaries.max.x;
    }
    if (camera.position.z < props.boundaries.min.z) {
      camera.position.z = props.boundaries.min.z;
    }
    if (camera.position.z > props.boundaries.max.z) {
      camera.position.z = props.boundaries.max.z;
    }
    if (camera.position.y < props.boundaries.min.y) {
      camera.position.y = props.boundaries.min.y;
    }
    if (camera.position.y > props.boundaries.max.y) {
      camera.position.y = props.boundaries.max.y;
    }
  });

  const increaseSize = () => {
    newHSref.current.scale.x += nudge;
    newHSref.current.scale.y += nudge;
    newHSref.current.scale.z += nudge;
  };
  const decreaseSize = () => {
    if (newHSref.current.scale.x > 0.26 && newHSref.current.scale.y > 0.26 && newHSref.current.scale.z > 0.26) {
      newHSref.current.scale.x -= nudge;
      newHSref.current.scale.y -= nudge;
      newHSref.current.scale.z -= nudge;
    }
  };
  const increaseWidth = () => {
    newHSref.current.scale.x += nudge;
  };
  const decreaseWidth = () => {
    if (newHSref.current.scale.x > 0.26) {
      newHSref.current.scale.x -= nudge;
    }
  };
  const increaseHeight = () => {
    newHSref.current.scale.y += nudge;
  };
  const decreaseHeight = () => {
    if (newHSref.current.scale.y > 0.26) {
      newHSref.current.scale.y -= nudge;
    }
  };
  const increaseLength = () => {
    newHSref.current.scale.z += nudge;
  };
  const decreaseLength = () => {
    if (newHSref.current.scale.z > 0.26) {
      newHSref.current.scale.z -= nudge;
    }
  };
  const resetSize = () => {
    newHSref.current.scale.x = 1;
    newHSref.current.scale.y = 1;
    newHSref.current.scale.z = 1;
  };

  //*
  useEffect(() => {
    const moveUp = () => {
      if (!props.boundaries) return;
      if (newHSref.current.position.y <= props.boundaries.max.y) {
        newHSref.current.position.y += nudge;
      }
    };

    const moveDown = () => {
      if (!props.boundaries) return;
      if (newHSref.current.position.y >= props.boundaries.min.y) {
        newHSref.current.position.y -= nudge;
      }
    };

    const handleKeyPress = (event: KeyboardEvent) => {
      if (event) {
        event.preventDefault();
        switch (event.key.toLowerCase()) {
          case "q": {
            moveUp();
            break;
          }
          case "z": {
            moveDown();
            break;
          }
          case "+": {
            increaseSize();
            break;
          }
          case "-": {
            decreaseSize();
            break;
          }
          case "w": {
            increaseHeight();
            break;
          }
          case "x": {
            decreaseHeight();
            break;
          }
          case "a": {
            decreaseWidth();
            break;
          }
          case "d": {
            increaseWidth();
            break;
          }
          case "e": {
            increaseLength();
            break;
          }
          case "v": {
            decreaseLength();
            break;
          }
          case "c": {
            resetSize();
            break;
          }
          case "r":
            if (!props.boundaries) break;
            newHSref.current.position.set(0, props.boundaries.max.y - 2, 0);
            camera.position.set(0, props.boundaries.max.y - 2, -10);
            camera.rotation.set(15, 0, 20);
            if (camControls.current?.update) camControls.current.update();
            break;

          default:
            break;
        }
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [props]);

  return (
    <>
      <VisualHotspot selected={false} opacity={0.65} isSphere={props.isSphere} meshRef={newHSref} />
      {props.HotSpotList.map((hsData, i) => {
        if (i === hsIndex) return <></>;
        return (
          <group key={hsData.id}>
            {/* @ts-ignore */}
            <mesh key={hsData.id} position={hsData.position} rotation={hsData.rotation} scale={hsData.scale}>
              <VisualHotspot selected={props.hsIndex === i} isSphere={hsData.isSphere} meshRef={null} opacity={0.25} />
            </mesh>
          </group>
        );
      })}
      <DetectionRadius proximityRange={proximityRange} newHSref={newHSref} detectRad={detectRad} isVisible />
      <OrbitControls
        ref={camControls}
        makeDefault
        enableDamping={false}
        screenSpacePanning={false}
        onChange={() => {
          newHSref.current.position.y = newHSref.current.position.y;
        }}
      />
      <TransformControls
        onChange={(e) => {
          {/* @ts-ignore */}
          if(e.target.dragging) {
            sentHSref.current = newHSref.current;
          }
        }}
        mode={editMode}
        object={newHSref}
      />
    </>
  );
};

export default Controls;
