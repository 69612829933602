import { useCallback } from "react";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../contexts/ObjectsProvider";
import { useObjectPosition } from "../../hooks/useObjectPosition";
// import { SVGAircraftSymbol } from "../Annotations/AircraftSymbol";
// import {SVGAircraftSymbol1} from "./AircraftSymbol1";
// import { SVGCoursePointer1 } from "../Annotations/CoursePointer1";
// import { SVGCoursePointer2 } from "./CoursePointer2";
// import { SVGCoursePointer3 } from "./CoursePointer3";
// import { SVGCoursePointer4 } from "./CoursePointer4";
// import { SVGCurrentTrack } from "./CurrentTrack";
// import {SVGDistanceSymbol} from "./DistanceSymbol";
// import {SVGDMETACAN} from "./DMETACAN";
// import {SVGExpandedCompass} from "./ExpandedCompass";
// import {SVGGlideSlopePointer} from "./GlideSlopePointer";
// import {SVGHeadingBug} from "./HeadingBug";
// import {SVGILSLocalizer} from   "./ILSLocalizer";
// import {SVGLineM} from "../Annotations/LineM";
// import {SVGTrackLineRangeScale} from "./TrackLineRangeScale";
// import {SVGVOR} from "./VOR";
// import {SVGVOR1PointerHead} from "./VOR1PointerHead";
// import {SVGVOR2PointerHead} from "./VOR2PointerHead";
// import {SVGVOR1PointerTail} from "./VOR1PointerTail";
// import {SVGVOR2PointerTail} from "./VOR2PointerTail";

export interface SymbolProps {
  type: string;
  onMouseDown: any;
  objectId: string;
  transform: any;
  top: number;
  left: number;
  width: number;
  height: number;
  isDisplayed: boolean;
  zIndex: number;
  borderColor: string;
  strokeWidth: number;
  backgroundColor: string;
  opacity?: number;
  rotation?: number;
  fontColor?: string;
  text?: string;
  lineWidth?: number;
  name?: string;
}

export interface SVGSymbolProps {
  objectId: SymbolProps["objectId"];
  onMouseDown: SymbolProps["onMouseDown"];
  x: number;
  y: number;
  isDisplayed: SymbolProps["isDisplayed"];
  rotation: SymbolProps["rotation"];
  height: SymbolProps["height"];
  width: SymbolProps["width"];
  zIndex: SymbolProps["zIndex"];
  opacity: SymbolProps["opacity"];
  borderColor: SymbolProps["borderColor"];
  strokeWidth: SymbolProps["strokeWidth"];
  backgroundColor: SymbolProps["backgroundColor"];
}

export function Symbols() {
  const { symbols } = useObjectsState();
  const objectsDispatch = useObjectsDispatch();
  const onMouseDown = useCallback((e: React.MouseEvent<SVGSVGElement>, annotationId: string) => {
    e.stopPropagation();
    if (!annotationId) return;
    let type = ObjectActionsType.SET_SELECTED_OBJECT;
    if (e.ctrlKey) {
      type = ObjectActionsType.ADD_SELECTED_OBJECT;
    }
    objectsDispatch({
      type,
      payload: { objectId: annotationId },
    });
  }, []);
  return (
    <>
      {symbols.map((symbol: any) => {
        return (
          <Symbol
            key={symbol.objectId}
            type={symbol.type}
            onMouseDown={onMouseDown}
            objectId={symbol.objectId}
            transform={symbol.transform}
            top={symbol.top}
            left={symbol.left}
            width={symbol.width}
            height={symbol.height}
            isDisplayed={symbol.isDisplayed}
            zIndex={symbol.zIndex}
            borderColor={symbol.borderColor}
            strokeWidth={symbol.strokeWidth}
            backgroundColor={symbol.backgroundColor}
            rotation={symbol.rotation}
            opacity={symbol.opacity}
            fontColor={symbol.fontColor}
            text={symbol.text}
            name={symbol.name}
          />
        );
      })}
    </>
  );
}

export function Symbol({
  type,
  onMouseDown,
  objectId,
  transform,
  top,
  left,
  width,
  height,
  isDisplayed,
  zIndex,
  borderColor,
  strokeWidth,
  backgroundColor,
  fontColor,
  text,
  name,
}: SymbolProps) {
  const {
    position: [x, y],
    size: [w, h],
    opacity,
  } = useObjectPosition(objectId, top, left, width, height);
  const rotation = transform?.rotate ? transform?.rotate?.toFixed(2) : 0;
  const commonProps: SVGSymbolProps = {
    onMouseDown,
    objectId,
    x: x ?? 0,
    y: y ?? 0,
    width: w ?? 0,
    height: h ?? 0,
    isDisplayed,
    zIndex,
    borderColor,
    strokeWidth,
    backgroundColor,
    rotation,
    opacity,
  };
  switch (name) {
    // case "aircraft":
    //     return <SVGAircraftSymbol {...commonProps}/>
    // case "aircraft_1":
    //     return <SVGAircraftSymbol1 {...commonProps}/>
    // case "course_pointer_1":
    //     return <SVGCoursePointer1 {...commonProps}/>
    // case "course_pointer_2":
    //     return <SVGCoursePointer2 {...commonProps}/>
    // case "course_pointer_3":
    //     return <SVGCoursePointer3 {...commonProps}/>
    // case "course_pointer_4":
    //     return <SVGCoursePointer4 {...commonProps}/>
    // case "current_track":
    //     return <SVGCurrentTrack {...commonProps}/>
    // case "distance_symbol":
    //     return <SVGDistanceSymbol {...commonProps}/>
    // case "dme_tacan":
    //     return <SVGDMETACAN {...commonProps}/>
    // case "expanded_compass":
    //     return <SVGExpandedCompass {...commonProps}/>
    // case "glide_slope_pointer":
    //     return <SVGGlideSlopePointer {...commonProps}/>
    // case "heading_bug":
    //     return <SVGHeadingBug {...commonProps}/>
    // case "ils_localizer":
    //     return <SVGILSLocalizer {...commonProps}/>
    // case "line_m":
    //     return <SVGLineM {...commonProps}/>
    // case "track_line_range_scale":
    //     return <SVGTrackLineRangeScale {...commonProps}/>
    // case "vor":
    //     return <SVGVOR {...commonProps}/>
    // case "vor_1_pointer_head":
    //     return <SVGVOR1PointerHead {...commonProps}/>
    // case "vor_2_pointer_head":
    //     return <SVGVOR2PointerHead {...commonProps}/>
    // case "vor_1_pointer_tail":
    //     return <SVGVOR1PointerTail {...commonProps}/>
    // case "vor_2_pointer_tail":
    //     return <SVGVOR2PointerTail {...commonProps}/>
    default:
      return <></>;
  }
}
