import React from "react";

const AnnotationSquare = () => {
  return (
    <React.Fragment>
      <span className="icon-square-icon"></span>
    </React.Fragment>
  );
};

export default AnnotationSquare;
