import React, { useContext } from "react";
import { Interweave } from "interweave";
import { PageContext, IPageContext } from "../../../../routes/builderContexts";
import { IFmsContext, FmsContext } from "../../Interfaces/fmsContext";

const CDUCellDisplay = (props: any) => {
  const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const CDU = pageContext.pageManifest.cdu;
  const clickHandler = (event: React.MouseEvent, id: string): void => {
    event.preventDefault();
    if (fmsContext.cursorSelected) {
      fmsContext.findCursorObject(id);
    } else {
      props.accessCduLineInformation(event.currentTarget.id);
    }
  };

  return props.cduLinesInformation.map((cduLineInformation: any, key: number) => {
    const oldthese = "acft-num,messages,tmpy,fms-num,mode,";
    const these =
      "flt-nbr,from,to,altn,cpny-rte,altn-rte,crz-fl,crz-temp,tropo,ci,trip-wind,fuel-penalty,vor1-ident,vor1-freq,vor1-crs,vor2-ident,vor2-freq,vor2-crs,adf1-ident,adf1-freq,adf2-ident,adf2-freq,ls-ident,ls-freq,ls-crs,deselected-navaid1,deselected-navaid2,deselected-navaid3,deselected-navaid4,deselected-navaid5,deselected-navaid6,zfw,block,zfwcg,taxi,rte-rsv,rte-rsv2,altn,final,final2,min-fuel,jtsn-gw,pax-num,v1,vr,v2,crz,to-shift,ths-for,thd-red,accel,trans,eo-accels,intercept-angle,offset-dist,at-val,above-val,below-val,inbd-crs,time-val,dist-val,via";
    const bootPage = pageContext.pageManifest.bootPage;

    const objectHasOffset =
      pageContext.pageManifest.pageLayouts[bootPage]?.objectOffsets?.[cduLineInformation.id] !== undefined
        ? true
        : false;
    const objectOffset = objectHasOffset
      ? pageContext.pageManifest.pageLayouts[bootPage]?.objectOffsets?.[cduLineInformation.id].position
      : null;
    const cellHasOutline =
      pageContext.pageManifest.pageLayouts[bootPage]?.cellFormating?.cellOutlines?.[cduLineInformation.id]
        ?.outlineShown !== undefined
        ? pageContext.pageManifest.pageLayouts[bootPage]?.cellFormating?.cellOutlines?.[cduLineInformation.id]
            ?.outlineShown
        : cduLineInformation.outline !== undefined
        ? cduLineInformation.outline
        : false;
    const cellHasBackground =
      pageContext.pageManifest.pageLayouts[bootPage]?.cellFormating?.cellBackgrounds?.[cduLineInformation.id]
        ?.backgroundShown !== undefined
        ? pageContext.pageManifest.pageLayouts[bootPage]?.cellFormating?.cellBackgrounds?.[cduLineInformation.id]
            ?.backgroundShown
        : false;
    const backgroundColor =
      pageContext.pageManifest.pageLayouts[bootPage]?.cellFormating?.cellBackgrounds?.[cduLineInformation.id]
        ?.backgroundColor;

    return (
      <foreignObject
        key={key}
        className={
          CDU.includes("350")
            ? `cell-display`
            : `cell-display ${props.highlightedGroups.includes(cduLineInformation.id) ? CDU.includes("a220") ? "highlightedElement a220_cdu" : "highlightedElement" : ""}`
        }
        // className={`cell-display ${props.highlightedGroups.includes(cduLineInformation.id) ? "highlightedElement": "" }`}
        id={cduLineInformation.id}
        x={cduLineInformation.xCoordinate}
        y={cduLineInformation.yCoordinate}
        width={cduLineInformation.width}
        height={cduLineInformation.height}
        overflow={"visible"}
        stroke={"grey"}
        fill="none"
        pointerEvents="all"
        // onClick={(event)=>{clickHandler(event,cduLineInformation.id)}}
        onClick={
          document.getElementById("AddToDoTask")
            ? () => props.assignFmsKeyPad(cduLineInformation.id)
            : (event) => {
                clickHandler(event, cduLineInformation.id);
              }
        }
        alignmentBaseline="baseline"
      >
        {CDU.includes("350") ? (
          <div
            // className={`${props.highlightedGroups.includes(cduLineInformation.id) ? "highlightedElement": "" }`}
            style={{
              height: objectHasOffset ? objectOffset.height : cduLineInformation.height,
              width: objectHasOffset ? objectOffset.width : cduLineInformation.width,
              color: "#ffffff",
              outline: props.highlightedGroups.includes(cduLineInformation.id)
                ? "rgb(255, 255, 0) solid 2px"
                : cellHasOutline
                ? "rgb(90, 90, 90) solid 2px"
                : "",
              whiteSpace: "pre",
              textTransform: "uppercase",
              backgroundColor: cellHasBackground ? backgroundColor : "",
              padding: "0 auto",
              fontSize: "16px",
              zIndex: 10,
              position: "absolute",
            }}
          ></div>
        ) : (
          <></>
        )}

        <div
          className="displayDiv"
          style={{
            height: cduLineInformation.height,
            width: cduLineInformation.width,
            color: "#ffffff",
            border: fmsContext.gridBorder,
            whiteSpace: "pre",
            textTransform: "uppercase",
            fontSize: "16px",
            padding: "0 auto",
            zIndex: 10,
            position: "absolute",
          }}
        >
          <Interweave content={props.cellValues[cduLineInformation.id]} />
        </div>
      </foreignObject>
    );
  });
};
export default CDUCellDisplay;
