import React, { ChangeEventHandler, EventHandler, MouseEventHandler, useEffect, useState } from "react";
import { getSpeedRateValue, recordToSelect, wrapSelectionWithColor } from "./utils";
import { useTextToSpeech, useTextToSpeechState } from "../../../contexts/TextToSpeech";
import blobUrlFormatHelper from "../../blobUrlFormatHelper";
import SpeedSlider from "../../Slider/SpeedSlider";

import { ReactComponent as CopyTextIcon } from "../../../assets/icons/copy-text.svg";
import { ReactComponent as PauseIcon } from "../../../assets/icons/NarratorTab/narrator-text-pause-icon.svg";
import { ReactComponent as PlayIcon } from "../../../assets/icons/NarratorTab/Square-Button-Play.svg";
import { ReactComponent as HelpIcon } from "../../../assets/icons/TextToSpeech/Square-Button-Help.svg";
import { ReactComponent as PronunciationIcon } from "../../../assets/icons/TextToSpeech/Square-Button-Pronunciation.svg";
import { ReactComponent as GeneratingAudioSpinner } from "../../../assets/icons/HeaderIcons/loading-sequence-airplane-icon.svg";

import "./TextToSpeechForm.css";
import InputPicker from "rsuite/InputPicker";
import { IUploadAssetResponse } from "../../../models/IUploadAssetResponse";
import { AudioPlayer } from "../../AudioPlayer";
import { generateFileName } from "../../../utils/Audio/generateFileName";
import { Tooltip } from "../../ToolTip/ToolTip";
import { DEFAULT_PROSODY_RATE } from "./constants";

export interface TextToSpeechFormSchema {
  narrationText: string; // original text
  pronunciationText: string; // text to be actually transformed (can be a fraction of the narration)
  language: string | undefined; // country language
  voice: string | undefined; // voice actor
  prosodyRate: number; // speaking speed
  selections: string[];
  generatedAudio?: IUploadAssetResponse;
}

export interface TextToSpeechFormProps {
  values?: Partial<TextToSpeechFormSchema>;
  onSubmit: (values: Required<Omit<TextToSpeechFormSchema, "selections">>) => void;
  onCancel: () => void;
}

const getDefaultValues = (externalValues?: Partial<TextToSpeechFormSchema>): TextToSpeechFormSchema => {
  return {
    narrationText: "",
    pronunciationText: "",
    language: "en-US",
    voice: undefined,
    prosodyRate: DEFAULT_PROSODY_RATE,
    // hidden fields
    selections: [],
    generatedAudio: undefined,
    ...externalValues,
  };
};

export const TextToSpeechForm = ({ values: externalValues, onSubmit, onCancel }: TextToSpeechFormProps) => {
  const values = getDefaultValues(externalValues);
  // Fields
  const [narrationText, setNarrationText] = useState<string>(values.narrationText);
  const [pronunciationText, setPronunciationText] = useState<string>(values.pronunciationText);
  const [language, setLanguage] = useState<string | undefined>(values.language);
  const [voice, setVoice] = useState<string | undefined>(values.voice);
  const [prosodyRate, setProsodyRate] = useState<number>(values.prosodyRate);
  // State Flag
  const [shouldGenerateAudio, setShouldGenerateAudio] = useState<boolean>(false);
  // Services
  const { getLanguages, getVoices, generateAudio, reset: resetTextToSpeech } = useTextToSpeech();
  const { audio: generatedAudio, languages, voices, isGenerating, isLoading: isTTSLoading } = useTextToSpeechState();

  // Handlers
  const handleReset = () => {
    setLanguage(undefined);
    setPronunciationText("");

    resetTextToSpeech();
  };
  const handleCancel = () => {
    handleReset();
    onCancel?.();
  };
  const handleSubmit = () => {
    if (generatedAudio) {
      onSubmit({
        pronunciationText,
        language,
        voice,
        prosodyRate,
        generatedAudio,
        narrationText,
      });

      handleReset();
    } else if (canGenerateAudio) {
      setShouldGenerateAudio(false);

      void generateAudio({
        language,
        voice,
        prosodyRate: getSpeedRateValue(prosodyRate),
        narrationText: pronunciationText,
        displayName: generateFileName(),
      }).then((generatedAudio) => {
        onSubmit({
          pronunciationText,
          language,
          voice,
          prosodyRate,
          generatedAudio,
          narrationText,
        });

        handleReset();
      });
    }
  };
  const handleClickPlay = (next?: () => void) => {
    if (shouldGenerateAudio && canGenerateAudio && !isGenerating) {
      // We need to generate the audio first
      void generateAudio({
        language,
        voice,
        prosodyRate: getSpeedRateValue(prosodyRate),
        narrationText: pronunciationText,
        displayName: "tts-audio",
      }).then(() => {
        setShouldGenerateAudio(false);
        next?.();
      });
    } else {
      next?.();
    }
  };
  const handlePronunciationTextChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    setPronunciationText(event.target.value ?? "");
    setShouldGenerateAudio(true);
  };
  const handleNarrationTextChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    setNarrationText(event.target.value ?? "");
  };
  const handleProsodyRateChange = (value: number) => {
    setProsodyRate(value);
    setShouldGenerateAudio(true);
  };
  // Flags
  const canGenerateAudio = language && voice && !isNaN(prosodyRate) && pronunciationText?.length > 0;
  const canPlay = generatedAudio?.blobPath;
  // Effects
  useEffect(() => {
    void getLanguages({}).then(() => {
      setShouldGenerateAudio(true);
    });
  }, []);
  useEffect(() => {
    if (language) {
      void getVoices({ language }).then((voicesRecord) => {
        const voices = Object.keys(voicesRecord);
        if (voices.length > 0) {
          if (!voice || (voice && !voices.includes(voice))) {
            setVoice(voices[0]);
          }
        }
        setShouldGenerateAudio(true);
      });
    }
  }, [voice, language]);
  // Data
  const languageOptions = languages ? recordToSelect(languages, "Select Language") : [];
  const voiceOptions = voices ? recordToSelect(voices, "Select Voice") : [];
  // NOTE: Commented out for now, since we might enable highlight again.
  // const getSourceTextWithSelections = (narrationText: string) => {
  //   let highlightedSourceText = narrationText;
  //   // Keep current selection
  //   if (pronunciationText) {
  //     highlightedSourceText = wrapSelectionWithColor(highlightedSourceText ?? "", pronunciationText, "new");
  //   }
  //   // Show previous selections
  //   if (values?.selections?.length > 0) {
  //     for (const selection of values.selections) {
  //       highlightedSourceText = wrapSelectionWithColor(highlightedSourceText ?? "", selection, "old");
  //     }
  //   }
  //
  //   return highlightedSourceText;
  // };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
      }}
    >
      <div className="tts-form-wrapper">
        <div className="form-section">
          <label className="form-label">Narration Text:</label>
          <div className="form-field">
            <textarea
              // must be span for this to work...
              // className={classes("tts-source-text", "cpat-textarea")}
              // dangerouslySetInnerHTML={{
              //   __html: getSourceTextWithSelections(narrationText),
              // }}
              className="cpat-textarea"
              name="narrationText"
              id="narrationText"
              rows={4}
              value={narrationText}
              onChange={handleNarrationTextChange}
            />
            <div className="tts-actions">
              <Tooltip title="Copy Text">
                <button
                  className="cpat-button cpat-button--icon tts-action tts-copy-button"
                  onClick={() => setPronunciationText(narrationText)}
                >
                  <CopyTextIcon />
                </button>
              </Tooltip>
            </div>
          </div>
        </div>

        <div className="form-section">
          <label className="form-label">Pronunciation:</label>
          <div className="form-field">
            <textarea
              className="cpat-textarea"
              name="pronunciationText"
              id="pronunciationText"
              rows={4}
              value={pronunciationText}
              onChange={handlePronunciationTextChange}
            />

            <div className="tts-actions">
              {/*Language*/}
              <InputPicker
                data={languageOptions}
                appearance="default"
                placeholder="Select a Language"
                value={language}
                labelKey="label"
                valueKey="value"
                onChange={(language: string) => {
                  setLanguage(language);
                }}
                size="sm"
                cleanable={false}
                className="language-dropdown"
                menuMaxHeight={150}
                disabled={isTTSLoading}
              />
              {/*Voices*/}
              <InputPicker
                data={voiceOptions}
                appearance="default"
                placeholder="Select a Voice"
                value={voice}
                labelKey="label"
                valueKey="value"
                onChange={(voice: string) => {
                  setVoice(voice);
                  setShouldGenerateAudio(true);
                }}
                size="sm"
                cleanable={false}
                className="voice-dropdown"
                menuMaxHeight={150}
                disabled={isTTSLoading}
              />
              {/*Speed*/}
              <SpeedSlider
                min={0}
                max={6}
                step={1}
                default={prosodyRate}
                class={"speed-slider"}
                labelClassName={"slider-label"}
                sliderClassName={"slider"}
                barClassName={"bar-style"}
                disabled={isTTSLoading}
                marks={[
                  { tick: 1, mark: "S" },
                  { tick: 3, mark: "M" },
                  { tick: 5, mark: "F" },
                ]}
                label={"Speed"}
                onChangeCommitted={handleProsodyRateChange}
                sliderHeaderClassName={"slider-header"}
              />
              {/*Controls*/}
              <AudioPlayer
                src={generatedAudio?.blobPath ? blobUrlFormatHelper(generatedAudio.blobPath) : null}
                autoPlay
              >
                {({ playing, ready, loading, onClickHandler }) =>
                  isGenerating || loading ? (
                    <button className="cpat-button cpat-button--icon">
                      <GeneratingAudioSpinner className="generating-spinner" />
                    </button>
                  ) : !canPlay ? (
                    <button className="tts-play-button" onClick={() => handleClickPlay()} disabled={!canGenerateAudio}>
                      <PlayIcon />
                    </button>
                  ) : (
                    ready && (
                      <button className="tts-play-button" onClick={() => handleClickPlay(onClickHandler)}>
                        {playing ? <PauseIcon /> : <PlayIcon />}
                      </button>
                    )
                  )
                }
              </AudioPlayer>
              <Tooltip title="TTS Tips and Tricks">
                <a href="/api/Asset/cpat/generic/pdf/tips.pdf" target="_blank" className="cpat-icon-button">
                  <HelpIcon />
                </a>
              </Tooltip>
              <Tooltip title="Pronunciations">
                <a href="/api/Asset/cpat/generic/pdf/pronunciations.pdf" target="_blank" className="cpat-icon-button">
                  <PronunciationIcon />
                </a>
              </Tooltip>
            </div>
          </div>
        </div>

        {/*Actions*/}

        <div className="form-actions">
          <button className="cpat-button cpat-button--label cpat-button--reverse" onClick={handleCancel}>
            Cancel
          </button>
          <button
            className="cpat-button cpat-button--label"
            onClick={() => {
              handleSubmit();
            }}
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};
