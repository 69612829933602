import React, { ChangeEventHandler, useEffect, useState } from "react";
import { PanelProperty } from "../types";
import classes from "../../../utils/HTML/classes";
import useDebounce from "../../../hooks/useDebounce";

export type TextPropertyProps = PanelProperty<unknown> & {
  value: string;
  onChange: (value: string) => void;
};

export const TextProperty = ({ value, onChange, readOnly, disabled, className }: TextPropertyProps) => {
  const [inputValue, setInputValue] = useState<string>(value);
  const debouncedInputValue = useDebounce(inputValue, 250);

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    onChange?.(debouncedInputValue);
  }, [debouncedInputValue]);

  return (
    <input
      className={classes("property-field-text", className)}
      type="text"
      defaultValue={inputValue}
      onChange={handleInputChange}
      disabled={disabled}
      readOnly={readOnly}
    />
  );
};
