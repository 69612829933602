import React, { PropsWithChildren, createContext, useState, useContext, useReducer } from "react";
import { cloneDeep } from "lodash";

interface HotspotVisualObject {
  x: number;
  y: number;
}
interface InteractivityState {
  currentlyPaintedHotspots: Set<HotspotVisualObject>;
}

const initialState: InteractivityState = {
  currentlyPaintedHotspots: new Set(),
};

const InteractivityStateContext = createContext<any>({});
const InteractivityDispatchContext = createContext<any>({});

const interactivityReducer = (state: InteractivityState, action: any) => {
  switch (action.type) {
    case "ADD_CURRENT_PAINTED_HOTSPOT": {
      state.currentlyPaintedHotspots.add(action.payload);
      return { ...state };
    }
    case "DELETE_CURRENT_PAINTED_HOTSPOT": {
      state.currentlyPaintedHotspots.delete(action.payload);
      return { ...state };
    }
    case "REMOVE_ALL_PAINTED_HOTSPOTS": {
      state.currentlyPaintedHotspots.forEach((hotspot) => {
        state.currentlyPaintedHotspots.delete(hotspot);
      });
      return { ...state };
    }
    default:
      return state;
  }
};

export function InteractivityProvider({ children }: PropsWithChildren<any>) {
  const [state, dispatch] = useReducer(interactivityReducer, initialState); // TODO use reducer

  return (
    <InteractivityDispatchContext.Provider value={dispatch}>
      <InteractivityStateContext.Provider value={state}>{children}</InteractivityStateContext.Provider>
    </InteractivityDispatchContext.Provider>
  );
}

export function useInteractivityDispatch() {
  const ctx = useContext(InteractivityDispatchContext);
  if (ctx === undefined) {
    throw new Error("Wrap component in Interactivity Provider");
  }
  return ctx;
}
export function useInteractivityState() {
  const ctx = useContext(InteractivityStateContext);
  if (ctx === undefined) {
    throw new Error("Wrap component in Interactivity Provider");
  }
  return ctx;
}
