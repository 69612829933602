/* eslint-disable @typescript-eslint/no-unused-vars */
import { SVGProps } from "react";
import { SmartObjectSvgRendererProps } from "../";
import { useMetaVariableStore } from "../../../store";
import { getSmartObjectMetaVariableKey } from "../../../utils";
import { useSmartObjectStyles } from "../useSmartObjectStyles";

export function SmartObjectSvgDynamicTextRenderer({ element, objectId, parentId }: SmartObjectSvgRendererProps) {
  const { children, TYPE, GROUP_ID, VALUE, HOTSPOT_ID, RATE, type, ...rest } = element;
  const key = getSmartObjectMetaVariableKey(objectId, GROUP_ID);
  const value = useMetaVariableStore((s) => s.metaVariables[key]);
  const hide = useMetaVariableStore((s) => s.metaVariablesData[key]?.hide);
  const style = useSmartObjectStyles(element.style);
  const textProps = rest as SVGProps<SVGTextElement>;

  if (hide === false) {
    return null;
  }

  return (
    <text {...textProps} style={style}>
      <tspan>{value || ""}</tspan>
    </text>
  );
}
