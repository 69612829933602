import React from "react";
import { SymbolComponentType } from "../helpers/getSymbol";

const ILSLocalizer = (props: SymbolComponentType) => {
  const { index, attributes, transformStr } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12.7 23.53"
      key={index}
      id={`symbol-${index}`}
      tabIndex={0}
      className="symbol target"
      name="symbol"
      preserveAspectRatio="xMidYMid meet"
      style={{
        top: `${attributes.top}%`,
        left: `${attributes.left}%`,
        width: `${attributes.width}%`,
        height: `${attributes.height}%`,
        zIndex: attributes.zIndex,
        // transform:`rotate(${attributes?.transform?.rotate}deg) scaleX(${attributes?.transform?.scaleX}) scaleY(${attributes?.transform?.scaleY}) matrix3d(${attributes?.transform?.matrix3d})`
        transform: transformStr,
      }}
    >
      <path
        // className="ils_localizer_1"
        stroke={`${attributes.borderColor || "white"}`}
        fill={`${attributes?.backgroundColor || "transparent"}`}
        strokeWidth={`${attributes.lineWidth || 0.1}`}
        d="M 5.0799999,0.5 H 7.75 V 23.030001 H 5.0799999 Z"
      />
      {/* <path
        // className="ils_localizer_2"
        stroke={`${attributes.borderColor || 'white'}`}
        fill={`${attributes?.backgroundColor || 'transparent'}`}
        strokeWidth={`${attributes.lineWidth || .1}`}
        d="M 3.73,11.65 A 1.6900001,1.6900001 0 0 1 2.04,13.34 1.6900001,1.6900001 0 0 1 0.3499999,11.65 1.6900001,1.6900001 0 0 1 2.04,9.9599996 1.6900001,1.6900001 0 0 1 3.73,11.65"
      />
      <path
        // className="ils_localizer_2"
        stroke={`${attributes.borderColor || 'white'}`}
        fill={`${attributes?.backgroundColor || 'transparent'}`}
        strokeWidth={`${attributes.lineWidth || .1}`}
        d="M 12.35,11.65 A 1.6900001,1.6900001 0 0 1 10.66,13.34 1.6900001,1.6900001 0 0 1 8.9699998,11.65 1.6900001,1.6900001 0 0 1 10.66,9.9599996 1.6900001,1.6900001 0 0 1 12.35,11.65"
      /> */}
    </svg>
  );
};

export default ILSLocalizer;
