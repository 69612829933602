import _ from "lodash";
import "./uploadModal.css";
import { Modal, Button } from "react-bootstrap";
import React, { useState, useContext, useEffect, ChangeEvent, Dispatch, SetStateAction, useRef } from "react";
import {
  IPageContext,
  PageContext,
  IAssetManagementContext,
  AssetContext,
  PagesManagementContext,
  ILessonPageActionsContext,
  LessonPageActionsContext,
} from "../../routes/builderContexts";
import { IUploadAssetRequest } from "../../models/IUploadAssetRequest";

import createFormDataInMbChunks from "../fileDivider";
import { APPLICATION_JSON } from "../constants/mimeTypes";
import genericRepositoryService from "../../services/genericRepositoryService";
import BlobUploader from "./BlobUploader";
import ImageUploader from "./ImageUploader";
import AudioUploader from "./audioUploader/audioUploader";
import IApiResponse from "../../models/IApiResponse";
import IImageAsset from "../../models/IAsset";
import GetAllImagesResponse from "../../models/IGetAllImagesResponse";
import IAsset from "../../models/IAsset";
import { IUploaderContext, UploaderContext, IAudioObject } from "./uploaderContext";
import * as constants from "../constants/pageTypes";
import { IUploadAssetResponse } from "../../models/IUploadAssetResponse";

export interface IBlobUploaderModal {
  showModal: boolean;
  children?: React.ReactElement;
  noModal?: boolean; //determines the format wether it should be a modal or not
}

interface AircraftForLesson {
  aircraftId: number | null;
  aircraftFamilyId: number | null;
  manufacturerId: number | null;
}

const BlobUploaderModal: React.FC<IBlobUploaderModal> = (properties: IBlobUploaderModal) => {
  const assetContext: IAssetManagementContext = useContext<IAssetManagementContext>(AssetContext);
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const updatedManifest = _.cloneDeep(pageContext.pageManifest);
  const lessonPageActionsContext: ILessonPageActionsContext =
    useContext<ILessonPageActionsContext>(LessonPageActionsContext);
  const rawFile: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();

  const [aircraftIds, setAircraftIds]: [AircraftForLesson, Dispatch<SetStateAction<AircraftForLesson>>] =
    useState<AircraftForLesson>({
      aircraftId: null,
      aircraftFamilyId: null,
      manufacturerId: null,
    });
  const [aircraftPlacementIds, setAircraftPlacementIds]: [string, Dispatch<SetStateAction<string>>] =
    useState<string>("");
  const [ataIds, setAtaIds]: [number[], Dispatch<SetStateAction<number[]>>] = useState<number[]>([]);
  const [blobPath, setBlobPath]: [string, Dispatch<SetStateAction<string>>] = useState<string>("");
  const [component, setComponent]: [JSX.Element, Dispatch<SetStateAction<JSX.Element>>] = useState<JSX.Element>(<></>);
  const [description, setDescription]: [string, Dispatch<SetStateAction<string>>] = useState<string>("");
  const [imgDims, setImgDims]: [{ h: number; w: number }, Dispatch<SetStateAction<{ h: number; w: number }>>] =
    useState<{ h: number; w: number }>({ h: -1, w: -1 });
  const [imagesList, setImagesList]: [IImageAsset[], Dispatch<SetStateAction<IImageAsset[]>>] = useState<IImageAsset[]>(
    [],
  );
  const [isCollapsed, setIsCollapsed]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(true);
  const [isFilePresent, setIsFilePresent]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false);
  const [isFileSelectShown, setIsFileSelectShown]: [boolean, Dispatch<SetStateAction<boolean>>] =
    useState<boolean>(false);
  const [isGoodFilename, setIsGoodFilename]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false);
  const [isOptionsShown, setIsOptionsShown]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false);
  const [nameSplit, setNameSplit]: [string, Dispatch<SetStateAction<string>>] = useState<string>("");
  const [selectedImage, setSelectedImage]: [number, Dispatch<SetStateAction<number>>] = useState<number>(-1);
  const [showBeforeSuccessfulUpload, setShowBeforeSuccessfulUpload]: [boolean, Dispatch<SetStateAction<boolean>>] =
    useState<boolean>(true);
  const [tailoredForLmsKey, setTailoredForLmsKey]: [string, Dispatch<SetStateAction<string>>] = useState<string>("");
  const [versionName, setVersionName]: [string, Dispatch<SetStateAction<string>>] = useState<string>("");
  const [uploadSuccess, setUploadSuccess]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false);
  const [uploadProcessing, setUploadProcessing]: [boolean, Dispatch<SetStateAction<boolean>>] =
    useState<boolean>(false);

  const [statusMessage, setStatusMessage] = useState<string>("  ");
  const [narratorText, setNarratorText] = useState<string>("");
  const [deleteWarning, setDeleteWarning] = useState<boolean>(false);
  const [selectedAudio, setSelectedAudio]: [IAudioObject, Dispatch<SetStateAction<IAudioObject>>] =
    useState<IAudioObject>({
      File: "",
      Version: "",
      ParentVersion: "",
      FileMD5: "",
      NarratorText: "",
      PronuncationText: "",
    });

  useEffect(() => {
    selectImages();
  }, []);

  useEffect(() => {
    ShowUploaderType();
    UploaderTypeButtons();
    setLessonModeIndex();
  }, [assetContext.assetTypeId]);

  const clearStateVariables = (): void => {
    saveNarratorText(narratorText);
    setAircraftIds({
      aircraftId: null,
      aircraftFamilyId: null,
      manufacturerId: null,
    });
    setAircraftPlacementIds("");
    setAtaIds([]);
    setBlobPath("");
    //setComponent(<></>);
    setDescription("");
    setIsCollapsed(true);
    setIsFileSelectShown(false);
    setIsGoodFilename(false);
    setIsOptionsShown(false);
    setIsFilePresent(false);
    setUploadSuccess(false);
    setNameSplit("");
    setSelectedImage(-1);
    setShowBeforeSuccessfulUpload(true);
    setTailoredForLmsKey("");
    setVersionName("");
    setSelectedAudio(newAudioObject());
    setStatusMessage("");
    //assetContext.setAssetIndex(-1);
    assetContext.setAssetTypeId(-1);
  };

  const setLessonModeIndex = () => {
    // switch(assetContext.assetTypeId){
    //     case 5:
    //         return assetContext.setAssetIndex(lessonPageActionsContext.selectedEditorLessonModes);
    //     default:
    //         break;
    // }
  };
  /**
     * 
 


    tailoredForLmsKey: number | null;
     */
  const manageFile = async (e: any) => {
    setStatusMessage(" ");
    getImageDims(e);
    massageFileName(e);
    // manageFileType();
  };

  const getImageDims = (e: any) => {
    const _URL = window.URL || window.webkitURL;
    let image: any;
    let file: any;

    if ((file = e.target.files[0])) {
      image = new Image();
      image.onload = () => {
        setImgDims({ h: image.height, w: image.width });
      };
      image.src = _URL.createObjectURL(file);
    }
  };

  const massageFileName = (e: any) => {
    let fileNameSplit = "";
    const spaces = /\s/g;
    // if(rawFile.current){

    // }
    setNameSplit("");
    setIsGoodFilename(false);
    setIsFilePresent(false);
    if (!rawFile.current?.files) {
      throw "File is empty";
    } else {
      fileNameSplit = manageFileExtension();
      setIsFilePresent(true);
    }

    if (spaces.test(fileNameSplit)) {
      fileNameSplit = fileNameSplit.replace(spaces, "_");
    }

    manageFileType(fileNameSplit);
    setNameSplit(fileNameSplit);
  };

  const manageFileExtension = () => {
    const regex = /[^0-9a-zA-Z\-\._\s]/g;
    let fileNameSplit = "";
    let tempString: string[] = [];
    let lastIndex = 0;
    if (rawFile.current?.files?.length === 0 || !rawFile.current?.files) {
      //no op
    } else {
      if (!regex.test(rawFile.current?.files[0].name)) {
        tempString = rawFile.current?.files[0].name.split(".");
        lastIndex = tempString.length - 1;
        tempString[lastIndex] = tempString[lastIndex].toLowerCase();
        fileNameSplit = tempString?.join(".");
        setIsGoodFilename(true);
        setStatusMessage(" ");
      } else {
        setStatusMessage("Please review the file name or upload a new file");
        setIsGoodFilename(false);
      }
    }
    return fileNameSplit;
  };
  const manageFileType = (fileNameSplit: string) => {
    const validFileTypes = {
      images: [".jpg", ".png", ".gif", ".jpeg"],
      audio: [".mp3"],
      video: [".mp4"],
      text: [".txt", ".rtf"],
      pdf: [".pdf"],
    };
    if (fileNameSplit) {
      switch (assetContext.assetTypeId) {
        case 1:
        case 2:
        case 3:
        case 4: {
          fileTypeCheck(fileNameSplit, validFileTypes.images);
          break;
        }
        case 5:
          fileTypeCheck(fileNameSplit, validFileTypes.audio);
          break;
        case 6:
        case 7:
        case 8:
        default:
          break;
      }
    }

    //if(rawFile.current?.files?.length !== 0 && rawFile.current?.files?[0].name !== ".mp3") ){

    //}
  };
  const fileTypeCheck = (fileNameSplit: string, array: string[]) => {
    if (rawFile?.current && rawFile.current.files && rawFile?.current?.files[0].name) {
      //looks at the file type at the end of a string for valid type for the asset
      const fileName = fileNameSplit.split(".");
      if (array.indexOf(`.${fileName[fileName.length - 1]}`) === -1) {
        setStatusMessage(`Error: invalid file type. Supported file type(s) are: ${array.join(" ")}`);
        setIsGoodFilename(false);
      }
    }
  };
  //#region [rgba(0,0,0,0.4)]
  const sendFileToServer = async (): Promise<void> => {
    if (
      !rawFile ||
      !rawFile.current ||
      !rawFile.current.files ||
      rawFile.current.files.length === 0 ||
      rawFile.current.files[0].size === 0
    )
      throw "A non-empty File is required";

    if (!assetContext.assetTypeId) throw "Asset Type ID is required";

    if (!description) throw "Description is required";

    const requestFormData = new FormData();
    // let aircraftIdsArray: string[] = aircraftIds.split(",");
    const aircraftPlacementIdsArray: string[] = aircraftPlacementIds.split(",");
    // let ataIdsArray: string[] = ataIds.split(",");
    //request object will need the new generic lesson fields involving aircrafts

    const request: IUploadAssetRequest = {
      imageWidth: imgDims.w === -1 ? null : imgDims.w,
      imageHeight: imgDims.h === -1 ? null : imgDims.h,
      assetTypeId: assetContext.assetTypeId,
      description: description,
      aircraftId: aircraftIds.aircraftId, // aircraftIds && aircraftIdsArray && aircraftIdsArray.length > 0 ? aircraftIdsArray.map(value => parseInt(value)) : null,
      aircraftFamilyId: aircraftIds.aircraftFamilyId,
      manufacturerId: aircraftIds.manufacturerId,
      aircraftPlacementIds:
        aircraftPlacementIds && aircraftPlacementIdsArray && aircraftPlacementIdsArray.length > 0
          ? aircraftPlacementIdsArray.map((value) => parseInt(value))
          : null,
      ataIds: ataIds.length > 0 ? ataIds : null,
      versionName: versionName,
      tailoredForLmsKey: tailoredForLmsKey ? parseInt(tailoredForLmsKey) : null,
      uploadForLmsKey: null,
    };
    const requestBlob = new Blob([JSON.stringify(request)], {
      type: APPLICATION_JSON,
    });
    requestFormData.append("request", requestBlob);
    createFormDataInMbChunks(requestFormData, rawFile.current.files[0]);
    const response = await genericRepositoryService.uploadAsset(requestFormData);
    // if (response.data.isReusedAsset ) {
    //             // TODO GPS: show user if any were duplicates?
    // }
    const audioIndex = audioIndexHack();

    if (!response.isSuccess) {
      setStatusMessage("Error: Failed to Upload");
      setUploadProcessing(false);
      throw response.errorMessage;
    }

    if (assetContext.assetIndex >= 0 || audioIndex >= 0) {
      updateManifestOnAssetType(response.data);
    }
    const assetToRemove: number = await getAssetsToRemove();
    // pageContext.addAssetToCurrentLessonPage(response.data.assetId);
    // pageContext.removeAssetFromCurrentLessonPage(assetToRemove);
    pageContext.updatePageAsset(response.data.assetId, assetToRemove);
    pageContext.updatePageManifest(updatedManifest);
    setBlobPath(response.data.blobPath);
    setIsFileSelectShown(false);
    setShowBeforeSuccessfulUpload(false);
    setUploadSuccess(true);
    selectImages(); //only update if current asset type is for images
    updateAudioData(response.data.assetId, response.data.blobPath);
    setUploadProcessing(false);
  };

  const ShowUploaderType = (): void => {
    switch (assetContext.assetTypeId) {
      case 1:
      case 2:
      case 3:
        setComponent(<React.Fragment></React.Fragment>);
        break;
      case 4:
        setComponent(<ImageUploader />);
        break;
      case 5:
        setComponent(<AudioUploader />);
        break;
      default:
        setComponent(<BlobUploader />);
        break;
    }
  };
  //#endregion
  const UploaderTypeButtons = (): JSX.Element => {
    switch (assetContext.assetTypeId) {
      case 1:
      case 2:
      case 3:
        return <React.Fragment></React.Fragment>;
      case 4:
        return (
          <React.Fragment>
            <Button className="image-loader-button" disabled={uploadProcessing} onClick={() => closeModal()}>
              Cancel
            </Button>
            {isCollapsed ? (
              <></>
            ) : (
              <Button className="image-loader-button" onClick={() => updateImageData()}>
                Add
              </Button>
            )}
            {isFileSelectShown && isGoodFilename && description.length > 0 ? (
              <Button
                className={uploadProcessing ? "image-loader-button uploadingAnimation" : "image-loader-button"}
                onClick={processUploadedFile}
                disabled={
                  (!isGoodFilename && !isFilePresent && rawFile?.current?.files?.length === 0) || uploadProcessing
                }
              >
                {uploadProcessing ? "Uploading" : "Upload"}
              </Button>
            ) : (
              <></>
            )}
          </React.Fragment>
        );
      case 5:
        return (
          <React.Fragment>
            <Button className="image-loader-button" disabled={uploadProcessing} onClick={() => closeModal()}>
              Cancel
            </Button>
            {isFilePresent && isGoodFilename && !deleteWarning && !uploadSuccess ? (
              <Button
                className={uploadProcessing ? "image-loader-button uploadingAnimation" : "image-loader-button"}
                onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
                  processUploadedFile(event);
                }}
                disabled={(!isGoodFilename && !description && !isFilePresent) || uploadProcessing}
              >
                {uploadProcessing ? "Uploading" : "Upload"}
              </Button>
            ) : (
              <></>
            )}
          </React.Fragment>
        );
      default:
        return showBeforeSuccessfulUpload ? (
          <></>
        ) : (
          <Button
            variant="danger"
            onClick={() => {
              closeModal();
            }}
          >
            Close
          </Button>
        );
    }
  };
  /*********************************************************************************************************
   ********************************* ASSET ASSOCIATION  *****************************************************
   **********************************************************************************************************/
  const updateManifestOnAssetType = (data: IUploadAssetResponse): void => {
    switch (assetContext.assetTypeId) {
      case 4:
        updateManifestImageAsset(data);
        break;
      case 5:
        updateManifestAudioAsset(data);
        break;
      default:
        break;
    }
  };
  const updateManifestAudioAsset = (data: IUploadAssetResponse) => {
    //needs to check for the index to exist
    const audioIndex = audioIndexHack();
    updatedManifest.Audio.splice(audioIndex, 1, {
      ...updatedManifest.Audio[audioIndex],
      File: data.blobPath,
      assetVersionId: data.assetId,
    });
    pageContext.updatePageManifest(updatedManifest);
  };
  const updateManifestImageAsset = (data: IUploadAssetResponse) => {
    //lessonPageActionsContext.selectedEditorLessonModes === 3
    //choices[index].image
    //choices[index].assetVersionId
    switch (pageContext.page.pagePlayerType) {
      case constants.ThreeD_PAGE:
        if (lessonPageActionsContext.selectedEditorLessonModes === 3) {
          updatedManifest.choices.splice(assetContext.assetIndex, 1, {
            image: data.blobPath,
            assetVersionId: data.assetId,
          });
        } else {
          updatedManifest.StepGraphic.splice(assetContext.assetIndex, 1, {
            image: data.blobPath,
            assetVersionId: data.assetId,
          });
        }
        break;
      case constants.QUIZ_PAGE:
        updatedManifest.image.splice(assetContext.assetIndex, 1, {
          imagePath: data.blobPath,
          assetVersionId: data.assetId,
        });
        break;
      case constants.FMS_PAGE:
        AddFmsImage(data);
        break;
      case constants.BASIC_PAGE:
      default:
        // updatedManifest.pageImage.splice(assetContext.assetIndex, 1, { "imagePath": data.blobPath, "assetVersionId": data.assetId });
        updatedManifest.pageImage[assetContext.assetIndex].imagePath = data.blobPath;
        updatedManifest.pageImage[assetContext.assetIndex].assetVersionId = data.assetId;
        break;
      case constants.TITLE_PAGE:
      case constants.EXIT_PAGE:
        updateTitleOrExit(data);
        break;
    }
  };

  const removeAssetsFromTitleOrExit = () => {
    switch (assetContext.assetIndex) {
      case 0:
        return pageContext.pageManifest.BrandedLogo.assetVersionId;
      case 1:
        return pageContext.pageManifest.AirplaneOverlay.assetVersionId;
      case 2:
        return pageContext.pageManifest.Background.assetVersionId;
      default:
        return -1;
    }
  };

  const getAssetsToRemove = () => {
    try {
      switch (assetContext.assetTypeId) {
        case 4:
          return getImageAssetsToRemove();
        case 5:
          return getAudioAssetsToRemove();
        default:
          return null;
      }

      // if(pageContext.page.pagePlayerType === constants.ThreeD_PAGE
      //     && pageContext.pageManifest.StepGraphic[assetContext.assetIndex].assetVersionId !== updatedManifest.StepGraphic[assetContext.assetIndex].assetVersionId)
      //     {
      //     return pageContext.pageManifest.StepGraphic[assetContext.assetIndex].assetVersionId;
      // }else if(pageContext.page.pagePlayerType === constants.QUIZ_PAGE
      //     && pageContext.pageManifest.image[assetContext.assetIndex].assetVersionId !== updatedManifest.image[assetContext.assetIndex].assetVersionId)
      //     {
      //     return pageContext.pageManifest.image[assetContext.assetIndex].assetVersionId;
      // }else {
      //     return pageContext.pageManifest.pageImage[assetContext.assetIndex].assetVersionId;
      // }
    } catch (err) {
      console.error("Nothing to remove", err);
    }
  };

  const getAudioAssetsToRemove = () => {
    const audioIndex = audioIndexHack();
    if (pageContext.pageManifest.Audio[audioIndex].Version !== updatedManifest.Audio[audioIndex].Version) {
      return pageContext.pageManifest.Audio[audioIndex].Version === ""
        ? null
        : pageContext.pageManifest.Audio[audioIndex].Version;
    } else {
      return null;
    }
  };

  const getImageAssetsToRemove = () => {
    switch (pageContext.page.pagePlayerType) {
      case constants.ThreeD_PAGE:
        if (
          pageContext.pageManifest.StepGraphic[assetContext.assetIndex].assetVersionId !==
          updatedManifest.StepGraphic[assetContext.assetIndex].assetVersionId
        ) {
          return pageContext.pageManifest.StepGraphic[assetContext.assetIndex].assetVersionId;
        }
      case constants.QUIZ_PAGE:
        if (
          pageContext.pageManifest.image[assetContext.assetIndex].assetVersionId !==
          updatedManifest.image[assetContext.assetIndex].assetVersionId
        ) {
          return pageContext.pageManifest.image[assetContext.assetIndex].assetVersionId;
        }
      case constants.TITLE_PAGE:
      case constants.EXIT_PAGE:
        return removeAssetsFromTitleOrExit();
      case constants.BASIC_PAGE:
      default:
        return pageContext.pageManifest.pageImage[assetContext.assetIndex].assetVersionId;
    }
  };

  /*********************************************************************************************************
   ********************************* END OF ASSET ASSOCIATION  **********************************************
   **********************************************************************************************************/
  /*********************************************************************************************************
   *********************************   IMAGE SECTION ********************************************************
   **********************************************************************************************************/
  const selectImages = async (): Promise<void> => {
    const res: IApiResponse<GetAllImagesResponse> = await genericRepositoryService.getAllImages();
    const list = res.data.assets;
    setImagesList(list);
  };

  const handleSelected = (e: any) => {
    const value = parseInt(e.currentTarget.id);
    setSelectedImage(value);
  };

  const handleSelectImageClick = (): void => {
    if (isFileSelectShown) {
      setIsFileSelectShown(false);
    }

    if (isOptionsShown) {
      setIsOptionsShown(false);
    }
    setIsCollapsed(!isCollapsed);
  };

  const handleUploadClick = (): void => {
    if (!isCollapsed) {
      setIsCollapsed(true);
    }
    setIsFileSelectShown(!isFileSelectShown);
  };

  const updateTitleOrExit = async (data: IUploadAssetResponse): Promise<void> => {
    // imagesList.forEach((image: IAsset) => {
    // if (image.assetVersionId === selectedImage) {
    switch (assetContext.assetIndex) {
      case 0:
        updatedManifest.BrandedLogo.imagePath = data.blobPath;
        updatedManifest.BrandedLogo.assetVersionId = data.assetId;
        // updatedManifest.BrandedLogo.parentVersionId = image.parentVersionId;
        break;
      case 1:
        updatedManifest.Background.imagePath = data.blobPath;
        updatedManifest.Background.assetVersionId = data.assetId;
        // updatedManifest.Background.parentVersionId = image.parentVersionId;
        break;
      case 2:
        updatedManifest.AirplaneOverlay.imagePath = data.blobPath;
        updatedManifest.AirplaneOverlay.assetVersionId = data.assetId;
        // updatedManifest.AirplaneOverlay.parentVersionId = image.parentVersionId;
        break;
      default:
        break;
    }
    // }
    // });
  };

  const updateImageData = async (): Promise<void> => {
    imagesList.forEach((image: IAsset) => {
      if (image.assetVersionId === selectedImage) {
        switch (pageContext.page.pagePlayerType) {
          case constants.ThreeD_PAGE:
            if (lessonPageActionsContext.selectedEditorLessonModes === 3) {
              // updatedManifest.choices.splice(assetContext.assetIndex, 1, {"image": image.blobPath, "assetVersionId": image.assetVersionId});
              updatedManifest.choices[assetContext.assetIndex].image = image.blobPath;
              updatedManifest.choices[assetContext.assetIndex].assetVersionId = image.assetVersionId;
            } else {
              updatedManifest.StepGraphic.splice(assetContext.assetIndex, 1, {
                image: image.blobPath,
                assetVersionId: image.assetVersionId,
              });
            }
            break;
          case constants.QUIZ_PAGE:
            updatedManifest.image.splice(assetContext.assetIndex, 1, {
              imagePath: image.blobPath,
              assetVersionId: image.assetVersionId,
            });
            break;
          case constants.FMS_PAGE:
            updateFmsImageData(image);
            break;
          case constants.BASIC_PAGE:
          default:
            // updatedManifest.pageImage.splice(assetContext.assetIndex, 1, { "imagePath": image.blobPath, "assetVersionId": image.assetVersionId });
            updatedManifest.pageImage[assetContext.assetIndex].imagePath = image.blobPath;
            updatedManifest.pageImage[assetContext.assetIndex].assetVersionId = image.assetVersionId;
            break;
          case constants.TITLE_PAGE:
          case constants.EXIT_PAGE:
            const data: IUploadAssetResponse = {
              blobPath: image.blobPath,
              assetId: image.assetVersionId,
              errorMessage: "",
              isReusedAsset: false,
              isArchived: false,
            };

            updateTitleOrExit(data);
            break;
        }
      }
    });

    _.size(_.find(updatedManifest));

    const assetToRemove: number = await getAssetsToRemove();
    // pageContext.addAssetToCurrentLessonPage(selectedImage);
    // pageContext.removeAssetFromCurrentLessonPage(assetToRemove);
    pageContext.updatePageAsset(selectedImage, assetToRemove);
    pageContext.updatePageManifest(updatedManifest);
    closeModal();
  };
  const AddFmsImage = (data: IUploadAssetResponse) => {
    const imageObject = {
      imagePath: data.blobPath,
      assetVersionId: data.assetId,
      ParentVersion: 0,
    };
    if (pageContext.pageManifest.pageImage) {
      updatedManifest.pageImage[0] = imageObject;
      pageContext.updatePageManifest(updatedManifest);
    } else {
      updatedManifest["pageImage"] = [imageObject];
      pageContext.updatePageManifest(updatedManifest);
    }
  };
  const updateFmsImageData = (image: IAsset) => {
    if (pageContext.pageManifest.pageImage) {
      updatedManifest.pageImage.splice(assetContext.assetIndex, 1, {
        imagePath: image.blobPath,
        assetVersionId: image.assetVersionId,
        parentVersion: 0,
      });
    } else {
      updatedManifest["pageImage"] = [
        {
          imagePath: image.blobPath,
          assetVersionId: image.assetVersionId,
          parentVersion: 0,
        },
      ];
    }
  };

  /*********************************************************************************************************
   ********************************* END OF IMAGE SECTION   *************************************************
   **********************************************************************************************************/

  /*********************************************************************************************************
   *********************************   AUDIO SECTION *******************************************************
   *********************************************************************************************************/
  const audioIndexHack = () => {
    //using asset index in conjunction with audio on 3D pages results in an infinite re-render
    let modeIndex = 0;
    if (lessonPageActionsContext.selectedEditorLessonModes === 2) {
      modeIndex = 1;
    } else if (lessonPageActionsContext.selectedEditorLessonModes === 3) {
      modeIndex = 2;
    }
    return modeIndex;
  };

  const newAudioObject = (): IAudioObject => {
    const blankAudio: IAudioObject = {
      File: "",
      Version: "",
      ParentVersion: "",
      FileMD5: "4263222331c96f5b8841a5a26560c145",
      NarratorText: "",
      PronuncationText: "",
    };
    return blankAudio;
  };

  const defineAudioDescription = (): void => {
    //sets description based on audio index
    const audioIndex = audioIndexHack();
    switch (audioIndex) {
      case 0:
        return setDescription("Instructional Mode Default Audio for page ID: " + pageContext.pageId);
      case 1:
        return setDescription("Practice Mode Default Audio for page ID: " + pageContext.pageId);
      case 2:
        return setDescription("Test Mode Default Audio for page ID: " + pageContext.pageId);
      default:
        return setDescription("Default Audio for page ID: " + pageContext.pageId);
    }
  };

  const updateAudioData = (assetVersionId: number, newBlobPath: string) => {
    //after file upload so that the editor doesn't need to close
    if (assetContext.assetTypeId === 5) {
      const uploadedAudio: IAudioObject = {
        File: newBlobPath,
        Version: assetVersionId.toString(),
        ParentVersion: "",
        FileMD5: "4263222331c96f5b8841a5a26560c145",
        NarratorText: narratorText,
        PronuncationText: "",
      };
      resetAudio();
      setSelectedAudio(uploadedAudio);
    }
  };
  const resetAudio = () => {
    //if the user declines to upload a new audio file the file input needs to reset
    if (rawFile.current) {
      if (selectedAudio.File) {
        rawFile.current.value = "";
        setIsFilePresent(false);
      }
      setDeleteWarning(false);
    }
  };
  const removeAudio = () => {
    const audioIndex = audioIndexHack();
    const assetVersionId = parseInt(selectedAudio.Version);
    const newAudioObject = {
      File: "",
      Version: "",
      ParentVersion: "",
      FileMD5: selectedAudio.FileMD5,
      NarratorText: selectedAudio.NarratorText,
      PronuncationText: "",
    };
    setSelectedAudio(newAudioObject);
    if (updatedManifest.Audio[audioIndex]) {
      updatedManifest.Audio[audioIndex] = newAudioObject;
      pageContext.updatePageManifest(updatedManifest);
    }
    pageContext.updatePageAsset(null, assetVersionId);
    setDeleteWarning(false);
    setIsGoodFilename(false);
    setStatusMessage("File Successfully Removed");
  };
  const createAudioPlaceholder = (audioArray: IAudioObject[], assetIndex: number) => {
    if (audioArray.length - 1 < assetIndex) {
      const newAudioArray: IAudioObject[] = pageContext.pageManifest.Audio;
      const blankAudio: IAudioObject = newAudioObject();
      newAudioArray.push(blankAudio);
      createAudioPlaceholder(newAudioArray, assetIndex);
    } else {
      updatedManifest.Audio = audioArray;
      if (updatedManifest.Audio !== pageContext.pageManifest) {
        return pageContext.updatePageManifest(updatedManifest);
      }
    }
  };
  const saveNarratorText = (text: string) => {
    if (assetContext.assetTypeId === 5) {
      const audioIndex = audioIndexHack();
      if (pageContext.pageManifest) {
        if (pageContext.pageManifest.Audio[audioIndex]) {
          updatedManifest.Audio[audioIndex].NarratorText = narratorText;
          pageContext.updatePageManifest(updatedManifest);
        }
      }
      setNarratorText("");
    }
  };

  /*********************************************************************************************************
   ********************************* END OF AUDIO SECTION   ************************************************
   *********************************************************************************************************/

  const processUploadedFile = async (clickEvent: React.MouseEvent<HTMLElement, MouseEvent>): Promise<void> => {
    clickEvent.preventDefault();

    setUploadProcessing(true);
    await sendFileToServer();
    //closeModal();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e)
      switch (e.target.id) {
        case "description":
          setDescription(e.target.value);
          break;
        case "aircraftPlacementIds":
          setAircraftPlacementIds(e.target.value);
          break;
        case "versionName":
          setVersionName(e.target.value);
          break;
        case "tailoredForLmsKey":
          const value = parseInt(e.target.value);
          if (value < 1) {
            console.error("value must be greater than zero");
          }
          setTailoredForLmsKey(e.target.value);
          break;
        default:
          break;
      }
  };

  const closeModal = (): void => {
    clearStateVariables();
    assetContext.showBlobUploader(false);
  };

  const createUploaderContextProvider = (): IUploaderContext => {
    return {
      imagesList: imagesList,
      rawFile: rawFile,
      description: description,
      aircraftIds: 1, //need change
      ataIds: ataIds,
      aircraftPlacementIds: aircraftPlacementIds,
      isFilePresent: isFilePresent,
      isFileSelectShown: isFileSelectShown,
      isGoodFilename: isGoodFilename,
      isCollapsed: isCollapsed,
      isOptionsShown: isOptionsShown,
      nameSplit: nameSplit,
      selectedImage: selectedImage,
      tailoredForLmsKey: tailoredForLmsKey,
      versionName: versionName,
      uploadSuccess: uploadSuccess,
      selectedAudio: selectedAudio,
      narratorText: narratorText,
      statusMessage: statusMessage,
      deleteWarning: deleteWarning,
      uploadProcessing: uploadProcessing,
      clearStateVariables,
      createAudioPlaceholder,
      removeAudio,
      resetAudio,
      setDeleteWarning,
      setStatusMessage,
      setDescription,
      setNarratorText,
      setSelectedAudio,
      setUploadSuccess,
      handleChange,
      handleSelected,
      handleSelectImageClick,
      handleUploadClick,
      massageFileName,
      selectImages,
      setIsOptionsShown,
      defineAudioDescription,
      manageFile,
      saveNarratorText,
      processUploadedFile,
      manageFileExtension,
      setAtaIds,
      setAircraftIds,
    };
  };
  // const renders = useRef(0)

  // const runTest = () => {

  //     renders.current += 1
  //     return () =>{
  // }}
  const displayAssetUploaderWithModal = () => (
    <Modal
      show={properties.showModal}
      onHide={() => {
        if (!uploadProcessing) {
          closeModal();
        }
      }}
      animation={false}
      key="mine2"
    >
      <Modal.Header closeButton>
        <Modal.Title>Asset Uploader</Modal.Title>
      </Modal.Header>
      <Modal.Body>{component}</Modal.Body>
      <Modal.Footer>
        <UploaderTypeButtons />
        {/* {blobPath} */}
      </Modal.Footer>
    </Modal>
  );

  const displayReferenceAssetsSelector = () => {
    if (properties.children) return properties.children;
    else return <div>{component}</div>;
  };

  const displayAssetUploaderModal = () => {
    if (properties.showModal) return displayAssetUploaderWithModal();
    else if (properties.noModal) return displayReferenceAssetsSelector();
  };

  return (
    <UploaderContext.Provider value={createUploaderContextProvider()} key="mine1">
      {displayAssetUploaderModal()}
    </UploaderContext.Provider>
  );
};

export default BlobUploaderModal;
