import React from "react";
import { IAircraft } from "../models/IGetAircraftLessonData";
import createFormattedAircrafts, { ManufacturerList } from "./utils/createFormattedAircrafts";
import genericRepositoryService from "../services/genericRepositoryService";
// import LessonDataModal from '../components/LessonDataModal/LessonDataModal'
import { IAtaNumberDto } from "../models/IGetATAData";
interface LessonStyle {
  lessonStyleId: number;
  name: string;
  nickname: string | null;
}

interface LessonType {
  lessonTypeId: number;
  name: string;
  nickname: string | null;
}

interface PermissionsObject {
  canCreate: boolean;
  canEdit: boolean;
  name: string;
  pagePlayerTypeId: number;
}
export interface LessonData {
  aircrafts: IAircraft[];
  ataNumbers: IAtaNumberDto[];
  lessonTypes: LessonType[];
  lessonStyles: LessonStyle[];
  lmss: any;
  lmsName: string;
  pagePlayerTypePermissions: PermissionsObject[];
}

interface UseLessonDataOptions {
  formattedAircrafts?: boolean;
  permissions?: any;
}

interface LessonDataContext {
  lessonData: LessonData;
  formattedAircrafts: ManufacturerList;
  permissions: PermissionsHelper;
}
// const permissionsData = [
//   { name: 'Title', pagePlayerTypeId: 1, canEdit: true, canCreate: true },
//   { name: 'Base', pagePlayerTypeId: 2, canEdit: true, canCreate: true },
//   { name: 'Quiz', pagePlayerTypeId: 3, canEdit: true, canCreate: true },
//   { name: 'ThreeDModel', pagePlayerTypeId: 4, canEdit: true, canCreate: true },
//   { name: 'FMS', pagePlayerTypeId: 5, canEdit: false, canCreate: true },
//   { name: 'Score', pagePlayerTypeId: 6, canEdit: true, canCreate: true },
//   { name: 'Exit', pagePlayerTypeId: 7, canEdit: true, canCreate: true },
//   { name: 'Walkaround', pagePlayerTypeId: 7, canEdit: false, canCreate: true },
//  ]

class PermissionsHelper {
  private permissions: PermissionsObject[];

  constructor(permissions: PermissionsObject[]) {
    this.permissions = permissions;
  }

  canEdit = (pageType: string) => {
    // return false
    try {
      const pageInQuestion = this.permissions.find((page) => {
        return page.name === pageType;
      });
      if (pageInQuestion) {
        return pageInQuestion.canEdit;
      } else {
        return false;
      }
    } catch (e) {}
  };

  canCreate = (pageType: string) => {
    // return false
    const pageInQuestion = this.permissions.find((page) => {
      return page.name === pageType;
    });
    if (pageInQuestion) {
      return pageInQuestion.canCreate;
    } else {
      return false;
    }
  };
}

const LessonDataStateContext = React.createContext<LessonDataContext | undefined>(undefined);

const LessonDataProvider = ({ children }: any) => {
  const [lessonData, setLessonData]: [LessonData, React.Dispatch<React.SetStateAction<LessonData>>] = React.useState(
    {} as LessonData,
  );
  const [formattedAircrafts, setformattedAircrafts]: [
    ManufacturerList,
    React.Dispatch<React.SetStateAction<ManufacturerList>>,
  ] = React.useState({} as ManufacturerList);
  const [permissions, setPermissions]: [PermissionsHelper, React.Dispatch<React.SetStateAction<PermissionsHelper>>] =
    React.useState({} as PermissionsHelper);
  const ctxValue = React.useMemo(() => {
    return {
      lessonData,
      formattedAircrafts,
      permissions,
    };
  }, [lessonData, formattedAircrafts, permissions]);
  React.useEffect(() => {
    (async () => {
      const response = await genericRepositoryService.getNewLessonInformation();
      setLessonData(response.data);
      setformattedAircrafts(createFormattedAircrafts(response.data.aircrafts));
      setPermissions(new PermissionsHelper(response.data.pagePlayerTypePermissions));
      //  setPermissions(new PermissionsHelper(permissionsData))
    })();
  }, []);
  return <LessonDataStateContext.Provider value={ctxValue}>{children}</LessonDataStateContext.Provider>;
};

function useLessonData(): LessonData;
function useLessonData({ formattedAircrafts }: { formattedAircrafts: boolean }): [LessonData, ManufacturerList];
function useLessonData({ permissions }: { permissions: boolean }): PermissionsHelper;
function useLessonData(options?: UseLessonDataOptions) {
  const context = React.useContext(LessonDataStateContext);

  if (context) {
    if (options) {
      if ("formattedAircrafts" in options) {
        return [context.lessonData, context.formattedAircrafts] as [LessonData, ManufacturerList];
      } else if ("permissions" in options) {
        return context.permissions as PermissionsHelper;
      }
    }
    return context.lessonData as LessonData;
  } else throw new Error("Context undefined must wrap in Global Provider");
}

export { useLessonData, LessonDataProvider };
