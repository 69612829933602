import React from "react";
import { TOP_STRIP_HEIGHT } from "../TimelinePanel";
import StampsGrid from "./StampsGrid";

import "./TopStrip.css";
export default function TopStrip() {
  return (
    <div
      className="top-strip"
      style={{
        height: TOP_STRIP_HEIGHT,
      }}
    >
      <StampsGrid />
    </div>
  );
}
