import React, { useRef } from "react";
import { handleKeyPress, handleKeyUp } from "../../ObjectPropertyBox/functions/PropertyBoxFunctions";
import { AnnotationPropType } from "../Annotation";
import {
  InteractivityHotspotActionTypes,
  useInteracitvityHotspotDispatch,
} from "../../../contexts/InteractivityHotspotProvider";
import HighlightAnnotation from "../hooks/HighlightAnnotation";
import AnnotationHighlight from "../../../pageTypes/BasicPage_Player/components/BaseFreeForm/components/HighlightRect";
const { SET_CURRENT_HOTSPOT } = InteractivityHotspotActionTypes;

const AnnotationCircledNumber = (props: AnnotationPropType) => {
  const { annotation, count, index, kp, markSelected, objectIsInTimeline } = props;
  const circNumRef: React.RefObject<SVGSVGElement> = useRef(null);
  const hotspotsDispatch = useInteracitvityHotspotDispatch();
  const isDisplayed = typeof annotation.isDisplayed === "undefined" || annotation.isDisplayed;
  const opacity = objectIsInTimeline ? 1 : isDisplayed ? 1 : 0.5;

  const display = () => {
    return (
      <svg
        viewBox={`0 0 100 100`}
        key={index}
        id={`circledNumber-${index}`}
        tabIndex={1}
        name="circledNumber"
        className="annotation"
        onMouseDown={(e: React.MouseEvent<SVGSVGElement>) => {
          e.stopPropagation();
          hotspotsDispatch({
            type: SET_CURRENT_HOTSPOT,
            payload: null,
          });
          markSelected(e.currentTarget, index, "annotations");
        }}
        onKeyDown={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyPress(e, kp)}
        onKeyUp={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyUp(e, kp)}
        preserveAspectRatio="xMinYMin meet"
        style={{
          top: `${annotation.top}%`,
          left: `${annotation.left}%`,
          width: `${annotation.width}%`,
          height: `${annotation.height}%`,
          zIndex: annotation.zIndex,
          transform: `rotate(${annotation?.transform?.rotate ? annotation?.transform?.rotate : 0}deg)`,
        }}
        ref={circNumRef}
        opacity={opacity}
      >
        <HighlightAnnotation annotation={annotation} />

        <circle
          cx="50"
          cy="50"
          r="47"
          stroke={`${annotation?.borderColor ? annotation.borderColor : "transparent"}`}
          fill={`${annotation?.backgroundColor ? annotation.backgroundColor : "#4B4F58"}`}
          strokeWidth={`${annotation.strokeWidth || "3"}`}
        ></circle>
        <text
          className="step-text"
          x="50"
          y="50"
          dy="26.5"
          textAnchor="middle"
          fill={`${annotation?.fontColor ? annotation.fontColor : "white"}`}
        >
          {count}
        </text>
      </svg>
    );
  };

  return <React.Fragment>{display()}</React.Fragment>;
};

export default AnnotationCircledNumber;
