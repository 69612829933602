import React, { Dispatch, SetStateAction, useEffect, useState, useRef } from "react";
import { AnnotationPropType } from "../AnnotationThumbnail";

const AnnotationCircle = (props: AnnotationPropType) => {
  const { annotation, imgRect, index, ratio } = props;
  const circleRef: React.RefObject<SVGSVGElement> = useRef<SVGSVGElement>(null);
  const [bounds, setBounds]: [
    { height: number; width: number },
    Dispatch<SetStateAction<{ height: number; width: number }>>,
  ] = useState<{ height: number; width: number }>({ height: 0, width: 0 });
  const [isLoading, setIsLoading]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(true);

  useEffect(() => {
    function handleLoad() {
      setIsLoading(false);
    }

    handleLoad();
  }, []);

  useEffect(() => {
    let newBounds: { height: number; width: number } = {
      height: (circleRef.current?.getBoundingClientRect() as DOMRect)?.height,
      width: (circleRef.current?.getBoundingClientRect() as DOMRect)?.width,
    };
    const makeBounds = () => {
      if (!isLoading && circleRef.current) {
        circleRef.current.style.transform = `rotate(${annotation?.transform?.rotate.toFixed(2)}deg)`;
        setBounds(newBounds);
      }
    };

    makeBounds();

    return () => {
      newBounds = { height: 0, width: 0 };
    };
  }, [isLoading]);

  // function checkResize() {
  //   let thisSVG: any = document.getElementById(`circle-${index}`) as any
  //   thisSVG.firstChild.attributes.cx.nodeValue = thisSVG.clientWidth / 2;
  //   thisSVG.firstChild.attributes.cy.nodeValue = thisSVG.clientHeight / 2;
  //   thisSVG.firstChild.attributes.rx.nodeValue = (thisSVG.clientWidth / 2) - 3;
  //   thisSVG.firstChild.attributes.ry.nodeValue = (thisSVG.clientHeight / 2) - 3;
  // }

  return (
    <svg
      // viewBox='0 0 100 auto'
      id={`circle-${index}`}
      name="circle"
      className="annotation"
      preserveAspectRatio="xMinYMin meet"
      style={{
        top: `${annotation.top.toFixed(2)}%`,
        left: `${annotation.left.toFixed(2)}%`,
        width: `${annotation.width.toFixed(2)}%`,
        height: `${annotation.height.toFixed(2)}%`,
        zIndex: annotation.zIndex,
        // transform:`rotate(${annotation?.transform?.rotate.toFixed(2)}deg)`
      }}
      ref={circleRef}
    >
      {bounds && bounds.height > 0 ? (
        <ellipse
          cx={`${bounds.width / 2}`}
          cy={`${bounds.height / 2}`}
          rx={`${bounds.width / 2 - 3 * ratio}`}
          ry={`${bounds.height / 2 - 3 * ratio}`}
          stroke={`${annotation?.borderColor}` || "white"}
          fill={`${annotation?.backgroundColor}` || "transparent"}
          strokeWidth={`${annotation.strokeWidth * ratio || 4 * ratio}`}
        ></ellipse>
      ) : (
        <></>
      )}
    </svg>
  );
};

export default AnnotationCircle;
