// FIXME: Why didn't we already have these page types?

export enum BasePageType {
  INTRODUCTION = "introduction",
  OBJECTIVE = "objective",
  OBJECTIVES = "objectives",
  WARNING = "warning",
  FREE_FORM = "freeForm",
  TEXT_ONLY = "textOnly",
  SUMMARY = "summary",
  CONCLUSION = "conclusion",

  SINGLE_GRAPHIC_RIGHT = "singleGraphicRight",
  SINGLE_GRAPHIC_LEFT = "singleGraphicLeft",
  SINGLE_GRAPHIC_CENTER = "singleGraphicCenter",
  SINGLE_GRAPHIC_CENTER_2 = "singleGraphicCenter2",

  FULL_PAGE_GRAPHIC = "fullPageGraphic",

  DOUBLE_GRAPHICS_SPLIT = "doubleGraphicsSplit",
  DOUBLE_GRAPHICS_SPLIT_VERTICAL = "doubleGraphicSplitVert",
  DOUBLE_GRAPHICS_SPLIT_HORIZONTAL = "doubleGraphicSplitHoriz",
  DOUBLE_GRAPHICS_SPLIT_HORIZONTAL_TEXT = "doubleGraphicSplitHorizText",
  DOUBLE_GRAPHICS_SPLIT_HORIZONTAL_TEXT_2 = "doubleGraphicSplitHorizText2",
  DOUBLE_GRAPHICS_SPLIT_RIGHT = "doubleGraphicSplitRight",

  TRIPLE_GRAPHICS_SPLIT_LEFT = "TrippleGraphicSplitLeft",
  TRIPLE_GRAPHICS_SPLIT_RIGHT = "TrippleGraphicSplitRight",
  TRIPLE_GRAPHICS_SPLIT_TOP = "TrippleGraphicSplitTop",
  TRIPLE_GRAPHICS_SPLIT_BOTTOM = "TrippleGraphicSplitBottom",

  FOUR_GRAPHICS_SPLIT = "FourGraphicSplit",
  FOUR_GRAPHICS_SPLIT_TEXT = "FourGraphicSplitText",
}

export enum PageType {
  TITLE = "Title",
  QUIZ = "Quiz",
  SCORE = "Score",
  EXIT = "Exit",
  BASE = "Base",
  FMS = "FMS",
  THREE_D = "ThreeDModel",
  CABIN = "Cabin",
  WALK_AROUND = "Walkaround",
}
