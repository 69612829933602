import { BaseModalProps } from "./types";
import { MouseEventHandler, ReactElement } from "react";

export interface HeaderSlotProps extends Pick<BaseModalProps, "title" | "slots" | "forceInteraction"> {
  handleClose: MouseEventHandler;
}

export const Header = ({ slots, forceInteraction, title, handleClose }: HeaderSlotProps) => {
  if (slots?.header) {
    return slots?.header as ReactElement;
  }

  return (
    <div className="cpat-modal-header">
      <h5 className="cpat-modal-title">{title}</h5>
      <div className="cpat-modal-header-actions">
        {slots?.topActions}
        {!forceInteraction && <button className="cpat-modal-close-button" onClick={handleClose}></button>}
      </div>
    </div>
  );
};
