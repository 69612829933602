import Portal from "../../Portal/Portal";
import "./index.css";
import { BaseModalProps, ModalSize } from "./types";
import { MouseEventHandler, PropsWithChildren } from "react";
import { getSizeClass } from "./utils";
import { Header } from "./Header";
import { Content } from "./Content";
import { Footer } from "./Footer";
import classes from "../../../utils/HTML/classes";

export const BaseModal = ({
  children,
  title,
  size,
  open,
  clickOutside,
  slots,
  onClose,
}: PropsWithChildren<BaseModalProps>) => {
  const forceInteraction = !onClose; // Might add more variables into forcing user interaction
  const hasFooter = slots?.footer || slots?.bottomActions;

  if (!open) {
    return null;
  }

  // Handlers
  const handleClickModal: MouseEventHandler = (event) => {
    // This specific handler is protecting the backdrop from unintentional clicks
    event.stopPropagation();
  };
  const handleClickOutside: MouseEventHandler = (event) => {
    event.stopPropagation();

    if (forceInteraction) {
      return;
    }

    if (clickOutside) {
      onClose();
    }
  };
  const handleClickClose: MouseEventHandler = (event) => {
    event.stopPropagation();

    if (forceInteraction) {
      return;
    }

    onClose();
  };

  return (
    <Portal path={"cpat-modal-portal"}>
      <div className="cpat-modal-backdrop" onClick={handleClickOutside}>
        <div className={classes("cpat-modal", getSizeClass(size ?? ModalSize.MD))} onClick={handleClickModal}>
          <div className={classes("cpat-modal-container", hasFooter ? "cpat-modal-container--footerless" : "")}>
            <Header title={title} slots={slots} forceInteraction={forceInteraction} handleClose={handleClickClose} />
            <Content slots={slots}>{children}</Content>
            {hasFooter && <Footer slots={slots} />}
          </div>
        </div>
      </div>
    </Portal>
  );
};
