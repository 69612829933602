import React, { useContext, Suspense } from "react";
import { connect } from "react-redux";
import { Canvas } from "@react-three/fiber";
import Controls from "./riggingControls";
import _ from "lodash";
import "./hsEditor.css";
import SkyBox from "../skybox";
// import Hangar from '../hangar';
import AtGate from "../atGate";
import Cockpit from "../cockpit";
import Chair from "../chair";
import { setHotSpot, setCurtain } from "../../../../store/actions/actions";
import { PageContext } from "../../../../routes/builderContexts";

// import SceneLights from "../../../Generic3DFiles/lights";

const WorkShop = (props) => {
  const pageContext = useContext(PageContext);
  const pageManifest = _.cloneDeep(pageContext.pageManifest);
  const returnNewHotSpot = (data) => {
    if (data) {
      console.log(data);
      props.setHotSpot(data);
      pageManifest.ActiveHotSpot = data.key;
      pageManifest.CustomeHotSpot = data;
      pageContext.updatePageManifest(pageManifest);
    } else {
      return;
    }
  };

  return (
    <>
      <div style={{ position: "relative", height: "100%", width: "100%" }}>
        <Canvas>

          <pointLight position={[0, 250, 155]} intensity={1} color={0xffffff} />
          <Controls editMode={props.editMode} returnNewHotSpot={returnNewHotSpot} currentHotSpot={props.cpHotSpot} />
          <Suspense>
            <Cockpit modelPath={props.modelPath} callBack={() => {}} />
            <Chair role="Captain" modelPath={props.modelPath} />
            <Chair role="FO" modelPath={props.modelPath} />
            {props.condition === "InAir" && <SkyBox callBack={() => {}} />}
            {props.condition === "atGate" && <AtGate callBack={() => {}} />}
          </Suspense>
        </Canvas>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    stepSystemDisplay: state.Current3dPage.stepSystemDisplay,
    displayStyle: state.Current3dPage.displayStyle,
    modelPath: state.Current3dPage.model,
    condition: state.Current3dPage.condition,
    // currentHotSpot: state.Current3dPage.CustomeHotSpot
  };
};

export default connect(mapStateToProps, { setHotSpot, setCurtain })(WorkShop);
