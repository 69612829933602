import { RangeStatic } from "quill";
import React from "react";
import RichTextEditor from "../../../components/richTextEditor/NEWrichTextEditor";
import { basePageTextBoxWrapperVersioning } from "../../../utils/Versioning/basePagesVersioning";
import { IRTEModule } from "../BasePageDesigner";

type BaseProps = {
  moduleRTE: IRTEModule[];
  placeholderText: string;
  updatedManifest: any;
  textBlockIndex: number;
  assignNewText: (newText: string) => void;
  displayImage: (imageIndex: number) => JSX.Element;
  handleImageDivClick: (index: number) => void;
  handleSelectionChange: (range: RangeStatic) => void;
  // selectImages: () => Promise<void>,
};

const BaseSingleGraphicRight: React.FC<BaseProps> = (props: any) => {
  const text = props.updatedManifest.textBlock[0].text;
  const version = props.updatedManifest.textBlock[0].version;
  return (
    <div className="single-graphic-row">
      <div
        className={"single-graphic-box single-graphic-text" + basePageTextBoxWrapperVersioning(version)}
        onFocus={() => props.setTextBlockIndex(0)}
      >
        <RichTextEditor
          displayText={text}
          assignNewText={props.assignNewText}
          placeholder={props.placeholderText}
          module={props.moduleRTE}
          quillRef={props.quillRef}
          handleSelectionChange={props.handleSelectionChange()}
          theme="bubble"
          version={version}
        />
      </div>
      <div className="single-graphic-box graphic-img-container" onClick={() => props.handleImageDivClick(0)} id="0">
        <div className="img-aspect-container">
          <div className="img-aspect-container-inside">
            <div className="img-aspect-centering">{props.displayImage(0)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BaseSingleGraphicRight;
