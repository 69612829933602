import React from "react";
import { ElementTypes } from "../../../pageTypes/BasicPage_Player/components/IBasePage";
import HighlightAnnotation from "../../Annotation/hooks/HighlightAnnotation";
import { handleKeyUp } from "../../ObjectPropertyBox/functions/PropertyBoxFunctions";
import { SymbolComponentType } from "../helpers/getSymbol";

const CoursePointer4 = (props: SymbolComponentType) => {
  const { index, attributes, kp, transformStr, handleKeyPress, markSelected, objectIsInTimeline } = props;
  const symbols: ElementTypes = "symbols";
  const isDisplayed = typeof attributes.isDisplayed === "undefined" || attributes.isDisplayed;
  const opacity = objectIsInTimeline ? 1 : isDisplayed ? 1 : 0.5;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18.11 10.65"
      key={index}
      id={`symbol-${index}`}
      tabIndex={0}
      className="symbol target"
      name="symbol"
      onMouseDown={(e: React.MouseEvent<SVGSVGElement>) => markSelected(e.currentTarget, index, symbols)}
      onKeyDown={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyPress(e, kp)}
      onKeyUp={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyUp(e, kp)}
      preserveAspectRatio="xMidYMid meet"
      style={{
        top: `${attributes.top}%`,
        left: `${attributes.left}%`,
        width: `${attributes.width}%`,
        height: `${attributes.height}%`,
        zIndex: attributes.zIndex,
        transform: transformStr,
        // `rotate(${attributes?.transform?.rotate}deg) scaleX(${attributes?.transform?.scaleX}) scaleY(${attributes?.transform?.scaleY}) matrix3d(${attributes?.transform?.matrix3d})`
        opacity,
      }}
    >
      <path
        // className="selected_course_pointer_3"
        stroke={`${attributes.borderColor || "white"}`}
        fill={`${attributes?.backgroundColor || "transparent"}`}
        strokeWidth={`${attributes.lineWidth || 0.1}`}
        d="M 5.1,9.97 17.44,3.7 15.88,0.67 3.54,6.94 1.06,10.11 Z"
      />
      <HighlightAnnotation annotation={attributes as any} id={"glow-rect-for-symbols"} />
    </svg>
  );
};

export default CoursePointer4;
