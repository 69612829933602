import React, { useContext, useEffect, useState } from "react";
import { EditorContext } from "./MetaDataEditorContext/MetadataEditorContext";
import { MetadataEditorActions } from "./MetaDataEditorContext/MetadataEditorActions";
import { ReactComponent as ExitIcon } from "../../assets/icons/Common/remove-tag-icon.svg";
import { ReactComponent as AddIcon } from "../../assets/icons/add-tag-icon.svg";

const TagData = () => {
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const { state, dispatch } = useContext(EditorContext);
  const {
    selectedMetadata: { tags: currentTags },
  } = state;

  const handleSubmitTag = (): void => {
    if (Object.entries(state.defaultTags).length === 0) {
      dispatch({
        type: MetadataEditorActions.ADD_DEFAULT_TAG,
        payload: { key: "", value: "" },
      });
    } else {
      if (value.length === 0 || key.length === 0) return;
      dispatch({
        type: "ADD_KEY_VALUE_TAG",
        payload: { [key.trim()]: value.trim() },
      });
    }
    setKey("");
    setValue("");
  };

  const handlerPressEnter = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === "Enter") {
      handleSubmitTag();
    }
    return;
  };

  const handleRemoveTag = (key: string) => {
    dispatch({
      type: MetadataEditorActions.REMOVE_KEY_VALUE_TAG,
      payload: key.trim(),
    });
  };

  const handleRemoveDefaultTag = () => {
    if (key === "" || value === "") return;
    dispatch({
      type: MetadataEditorActions.REMOVE_DEFAULT_TAG,
    });
  };

  useEffect(() => {
    const tag = Object.entries(state.defaultTags)[0];
    const thisKey = Object.keys(tag)[0];

    dispatch({
      type: MetadataEditorActions.ADD_DEFAULT_TAG,
      payload: { [key.trim()]: value.trim() },
    });
  }, [key, value]);

  return (
    <div className="metadata-editor-sidebar-tagdata">
      <div className="image-metadata-separator"> </div>
      <div> Asset Tags</div>
      {Object.entries(state.defaultTags).length > 0 && (
        <div className="metadata-editor-sidebar-tagdata-tag-input-added-tags">
          <div className="metadata-editor-sidebar-tagdata-tag-input">
            <p>Key</p>
            <input
              value={key}
              onChange={(e) => {
                setKey(e.target.value);
              }}
              type="text"
              placeholder="Enter key"
              onKeyDown={(e) => handlerPressEnter(e)}
            ></input>
          </div>
          <div className="metadata-editor-sidebar-tagdata-tag-input">
            <p>Value</p>
            <input
              type="text"
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              placeholder="Enter value"
              onKeyDown={(e) => handlerPressEnter(e)}
            ></input>
            <ExitIcon className="remove-tag-btn" onClick={() => handleRemoveDefaultTag()} />
          </div>
        </div>
      )}
      <div className="metadata-editor-sidebar-tagdata-addbtn">
        <AddIcon onClick={handleSubmitTag} />
      </div>

      <div className="metadata-editor-sidebar-tagdata-added-tags">
        {currentTags && Object.keys(currentTags).length > 0 ? (
          Object.entries(currentTags).map(([key, value]) => (
            <div key={key} className="metadata-editor-sidebar-tagdata-tag-input-added-tags">
              <div className="metadata-editor-sidebar-tagdata-tag-input-added">
                <p>Key</p>
                <input
                  disabled
                  value={key}
                  onChange={(e) => setKey(e.target.value.trim())}
                  type="text"
                  placeholder="Enter key"
                ></input>
              </div>
              <div className="metadata-editor-sidebar-tagdata-tag-input-added">
                <p>Value</p>
                <input
                  disabled
                  type="text"
                  value={value.toString()}
                  onChange={(e) => setValue(e.target.value.trim())}
                  placeholder="Enter value"
                ></input>
                <ExitIcon className="remove-tag-btn" onClick={() => handleRemoveTag(key)}></ExitIcon>
              </div>
            </div>
          ))
        ) : (
          <div className="metadata-editor-sidebar-tagdata-tag-input-added-tags">
            {/* {Object.keys(defaultTags)[0].length ===0 && <p>No added tags </p>} */}
          </div>
        )}
      </div>
    </div>
  );
};

export default TagData;
