import React from "react";
import CpatWhitetail from "./assets/cpatbrandedairplane.png";

export default function SplashScreen(): React.SFCElement<any> {
  return (
    <div className="container-fluid" style={{ height: "100%" }}>
      <div className="row">
        <div className="col-12 text-center">
          <img
            className="img-fluid"
            src={CpatWhitetail}
            alt=""
            style={{
              marginTop: "5%",
              width: "65%",
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-center">
          <div>Please fasten your seatbelts...</div>
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  );
}
