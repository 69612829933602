/**
 * InteractivityBuilder
 * interactivityBuilder
 */
import { cloneDeep } from "lodash";
import React, { PropsWithChildren, createContext, useState, useContext, useReducer, useEffect } from "react";
import { HotspotAction, Interactivity, InteractivityBuilder } from "../../lib/interactivity";
import { AnimatedObjectManifest } from "../../lib/interactivity/TimelineConverter/TimelineConverter";
import { IPageContext, PageContext } from "../../routes/builderContexts";
import { createLogger } from "../../utils";
import {
  InteractivityVisualHotspot,
  useInteracitvityHotspotDispatch,
  useInteracitvityHotspotState,
} from "../InteractivityHotspotProvider";

import { InteractivityHotspotActionTypes } from "../InteractivityHotspotProvider/types";
export const {
  ADD_NEW_VISIBLE_HOTSPOT,
  EDIT_VISIBLE_HOTSPOT,
  CLEAR_ALL_VISIBLE_HOTSPOTS,
  SET_ALL_VISIBLE_HOTSPOTS,
  REMOVE_VISIBLE_HOTSPOT,
} = InteractivityHotspotActionTypes;

const log = createLogger("InteractivityBuilderProvider", {
  background: "black",
  color: "white",
});
interface InteractivityBuilderState {
  interactivityBuilderInstance: InteractivityBuilder | null;
  // timelineEditorInstance: TimelineEditor | null;
}
type InteracitvityBuilderAction = {
  type: "SET_INSTANCE" | "ADD_HOTSPOT_ON_ROOT";
  payload?: any;
};
type InteractivityBuilderReducer = (
  s: InteractivityBuilderState,
  a: InteracitvityBuilderAction,
) => InteractivityBuilderState;

type InteractivityBuilderDispatch = (action: InteracitvityBuilderAction) => void;
const initialState: InteractivityBuilderState = {
  interactivityBuilderInstance: null,
};

const InteractivityBuilderState = createContext<InteractivityBuilderState>(initialState);
const InteractivityBuilderDispatch = createContext<any>({});

const interactivityBuilderReducer = (
  state: InteractivityBuilderState,
  action: InteracitvityBuilderAction,
): InteractivityBuilderState => {
  switch (action.type) {
    case "SET_INSTANCE": {
      return { ...state, interactivityBuilderInstance: action.payload };
    }

    case "ADD_HOTSPOT_ON_ROOT": {
      const visualHotspots: InteractivityVisualHotspot[] = action.payload;
      if (state.interactivityBuilderInstance) {
        state.interactivityBuilderInstance.addChildLevel();
      } else {
        state.interactivityBuilderInstance = new InteractivityBuilder();
        const level = state.interactivityBuilderInstance.addChildLevel();
      }

      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export function InteractivityBuilderProvider({ children }: PropsWithChildren<any>) {
  const [state, dispatch] = useReducer<InteractivityBuilderReducer>(interactivityBuilderReducer, initialState); // TODO use reducer

  // const interactivityHotspotState = useInteracitvityHotspotState();
  const interactivityHotspotDispatch = useInteracitvityHotspotDispatch();
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);

  function setInteractivityOnManifest(manifest: any, timelineManifest?: any) {
    const pm = cloneDeep(pageContext.pageManifest);
    pm.interactivity = manifest;
    if (timelineManifest) {
      pm.timeline = timelineManifest;
    }
    log("setInteractivityOnManifest", pm);
    pageContext.updatePageManifest(pm);
  }
  function setTimelineOnManifest(newManifest: any) {
    const pm = cloneDeep(pageContext.pageManifest);
    pm.timeline = newManifest;
    log("setTimelineOnManifest", pm);
    pageContext.updatePageManifest(pm);
  }

  function hotspotEditedHandler(hotspotAction: HotspotAction) {
    setInteractivityOnManifest(hotspotAction.level.root.printTree());
    interactivityHotspotDispatch({
      type: EDIT_VISIBLE_HOTSPOT,
      payload: hotspotAction,
    });
  }

  function hotspotRemovedHandler(hotspotAction: HotspotAction) {
    setInteractivityOnManifest(hotspotAction.level.root.printTree());
    interactivityHotspotDispatch({
      type: REMOVE_VISIBLE_HOTSPOT,
      hotspot: hotspotAction,
    });
  }

  function syncTreeHandler(printedTree: Interactivity.PageManifestData) {
    setInteractivityOnManifest(printedTree);
  }

  function handleTimelineSequenceLengthChange(length: number) {}
  function handleTimelineObjectAdd(timelineObject: AnimatedObjectManifest) {}
  function handleTimelineCreation() {}

  function handleTimelineEditorChanged() {
    setInteractivityOnManifest(state.interactivityBuilderInstance?.root.printTree(), newTimelineManifestStructure);
  }

  /**
   * Due to the page manifest state being stale inside of the event listener
   * we need to assign a new event listener every time that page manifest is updated to be
   * able to have a fresh instance of it of every time (essentially runs every render until
   * optimizations are made to handling of the page manifest on the applications global level)
   */
  useEffect(
    () => {
      // state.interactivityBuilderInstance?.events.on("hotspot_edited", hotspotEditedHandler);
      // state.interactivityBuilderInstance?.events.on("hotspot_removed", hotspotRemovedHandler);
      // state.interactivityBuilderInstance?.events.on("sync_tree_with_page_manifest", syncTreeHandler);
      // state.interactivityBuilderInstance?.timelineEditor?.on(
      //   "sequence-length-change",
      //   handleTimelineSequenceLengthChange,
      // );
      // state.interactivityBuilderInstance?.timelineEditor?.on("timeline-object-add", handleTimelineObjectAdd);
      // state.interactivityBuilderInstance?.timelineEditor?.on("timeline-editor-changed", handleTimelineEditorChanged);
      // state.interactivityBuilderInstance?.events?.on("created-timeline-editor", handleTimelineCreation);
      return () => {
        // state.interactivityBuilderInstance?.timelineEditor?.off("timeline-editor-changed", handleTimelineEditorChanged);
        // state.interactivityBuilderInstance?.events?.off("created-timeline-editor", handleTimelineCreation);
        // state.interactivityBuilderInstance?.timelineEditor?.off("timeline-object-add", handleTimelineObjectAdd);
        // state.interactivityBuilderInstance?.timelineEditor?.off(
        //   "sequence-length-change",
        //   handleTimelineSequenceLengthChange,
        // );
        // state.interactivityBuilderInstance?.events.off("hotspot_removed", hotspotRemovedHandler);
        // state.interactivityBuilderInstance?.events.off("hotspot_edited", hotspotEditedHandler);
        // state.interactivityBuilderInstance?.events.off("sync_tree_with_page_manifest", syncTreeHandler);
      };
    },
    [
      // hotspotEditedHandler,
      // syncTreeHandler,
      // hotspotRemovedHandler,
      // handleTimelineSequenceLengthChange,
      // handleTimelineEditorChanged,
    ],
  );

  // useEffect(() => {
  //   log(pageManifest);
  //   if (!!pageManifest?.interactivity === false) {
  //     // do nothing
  //   } else {
  //     //create new instance of interactivity builder based on the interactivity data
  //     const interactivityBuilder = new InteractivityBuilder(
  //       pageManifest.interactivity,
  //     );
  //     dispatch({ type: 'SET_INSTANCE', payload: interactivityBuilder });
  //   }
  // }, [!!pageManifest?.interactivity]);

  return (
    <InteractivityBuilderDispatch.Provider value={dispatch}>
      <InteractivityBuilderState.Provider value={state}>{children}</InteractivityBuilderState.Provider>
    </InteractivityBuilderDispatch.Provider>
  );
}

export function useInteractivityBuilderDispatch() {
  const ctx = useContext(InteractivityBuilderDispatch);
  if (ctx === undefined) {
    throw new Error("Wrap component in Wysiwyg Provider");
  }
  return ctx as InteractivityBuilderDispatch;
}
export function useInteractivityBuilderState() {
  const ctx = useContext(InteractivityBuilderState);
  if (ctx === undefined) {
    throw new Error("Wrap component in Wysiwyg Provider");
  }
  return ctx;
}
