import React from "react";

import "./TextToSpeechModal.css";
import { BaseModal } from "../BaseModal";
import { TextToSpeechModalProps } from "./types";
import { TextToSpeechForm, TextToSpeechFormProps } from "./TextToSpeechForm";
import { ModalSize } from "../BaseModal/types";

export const TextToSpeechModal = ({ open, onClose, onFinish, data }: TextToSpeechModalProps) => {
  const handleFormSubmit: TextToSpeechFormProps["onSubmit"] = (values) => {
    onFinish(values);
  };
  const handleFormCancel = () => {
    onClose?.();
  };

  return (
    <BaseModal size={ModalSize.LG} open={open} title="Text To Speech">
      <div className="tts-modal-content">
        <TextToSpeechForm values={data} onSubmit={handleFormSubmit} onCancel={handleFormCancel} />
      </div>
    </BaseModal>
  );
};
