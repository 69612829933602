import { StatefulAsyncMethod, StatefulSyncMethod } from "../../types/StatefulMethod";
import { TimerState } from "../../classes/GlobalTimer/types";

export enum TimerStoreActionsType {
  CHANGE_STATE = "CHANGE_STATE",
  SET_TIME = "SET_TIME",
}

export interface TimerStoreState {
  [key: string]: unknown;

  state: TimerState;
}

export type ChangeState = {
  type: TimerStoreActionsType.CHANGE_STATE;
  payload: {
    state: TimerState;
  };
};

export type SetTime = {
  type: TimerStoreActionsType.SET_TIME;
  payload: {
    time: number;
  };
};

export type TimerStoreAction = ChangeState | SetTime;

export type TimerStatefulSyncMethod<TResult = void, TParams = undefined> = StatefulSyncMethod<
  TimerStoreState,
  TimerStoreAction,
  TResult,
  TParams
>;

export type TimerStatefulAsyncMethod<TResult = void, TParams = undefined> = StatefulAsyncMethod<
  TimerStoreState,
  TimerStoreAction,
  TResult,
  TParams
>;
