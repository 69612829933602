import { createContext, Dispatch, PropsWithChildren, useCallback, useContext, useEffect, useReducer } from "react";
import { TimerStoreAction, TimerStoreActionsType, TimerStoreState } from "./types";
import { TimerEvents, TimerState } from "../../classes/GlobalTimer/types";
import GlobalTimer from "../../classes/GlobalTimer";

const initialState: TimerStoreState = {
  state: TimerState.STOPPED,
};
const TimerStoreContext = createContext<TimerStoreState>(initialState);
const TimerStoreDispatch = createContext<unknown>({});

const timerStoreReducer = (state: TimerStoreState, action: TimerStoreAction): TimerStoreState => {
  switch (action.type) {
    case TimerStoreActionsType.CHANGE_STATE: {
      return {
        ...state,
        state: action.payload.state,
      };
    }

    default:
      return state;
  }
};
export function useTimerStoreDispatch() {
  const ctx = useContext(TimerStoreDispatch);
  if (ctx === undefined) {
    throw new Error("Wrap component in TimerStoreProvider");
  }
  return ctx as Dispatch<TimerStoreAction>;
}

export function useTimerStoreState() {
  const ctx = useContext(TimerStoreContext);
  if (ctx === undefined) {
    throw new Error("Wrap component in TimerStoreProvider");
  }
  return ctx as TimerStoreState;
}

export function TimerStoreProvider({ children }: PropsWithChildren<unknown>) {
  const [state, dispatch] = useReducer(timerStoreReducer, initialState);

  const timerStartCallback = useCallback(() => {
    dispatch({
      type: TimerStoreActionsType.CHANGE_STATE,
      payload: {
        state: TimerState.RUNNING,
      },
    });
  }, []);

  const timerStopCallback = useCallback(() => {
    dispatch({
      type: TimerStoreActionsType.CHANGE_STATE,
      payload: {
        state: TimerState.STOPPED,
      },
    });
  }, []);

  useEffect(() => {
    GlobalTimer.on(TimerEvents.START, timerStartCallback);
    GlobalTimer.on(TimerEvents.STOP, timerStopCallback);

    return () => {
      GlobalTimer.off(TimerEvents.START, timerStartCallback);
      GlobalTimer.off(TimerEvents.STOP, timerStopCallback);
      GlobalTimer.off(TimerEvents.TICK);
    };
  }, []);

  return (
    <TimerStoreDispatch.Provider value={dispatch}>
      <TimerStoreContext.Provider value={state}>{children}</TimerStoreContext.Provider>
    </TimerStoreDispatch.Provider>
  );
}

export function useTimer() {
  const { state } = useTimerStoreState();

  const handleStart = useCallback((startTime = 0) => {
    GlobalTimer.start(startTime);
  }, []);

  const handleStop = useCallback(() => {
    GlobalTimer.stop();
  }, []);

  const handleOnTick = useCallback((callback: (time: number) => unknown) => {
    GlobalTimer.on(TimerEvents.TICK, callback);
  }, []);

  const handleOffTick = useCallback((callback?: (time: number) => unknown) => {
    GlobalTimer.off(TimerEvents.TICK, callback);
  }, []);

  return {
    state,
    // Selectors
    running: state === TimerState.RUNNING,
    stopped: state === TimerState.STOPPED,
    // Mutators
    start: handleStart,
    stop: handleStop,
    // Listener
    onTick: handleOnTick,
    offTick: handleOffTick,
  };
}
