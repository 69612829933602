/**
 * MovableElementsPlane
 * movableElementsPlane
 */
import React, { PropsWithChildren, createContext, useContext, useReducer, useEffect } from "react";
import { InteractivityHotspotProvider } from "../InteractivityHotspotProvider";
import { InteractivityBuilderProvider } from "../InteractivityBuilderProvider";
import { ObjectPropertyBoxProvider } from "../ObjectPropertyBox/ObjectPropertyBoxProvider";
import Moveable from "../../components/react-moveable";
import { create } from "zustand";
// import type Moveable from '../../react-moveable';
interface UIState {
  designerViewportDims: {
    width: number;
    height: number;
  };
}
export const useUIStore = create<
  UIState & {
    setDesignerViewportDims: (width: number, height: number) => void;
  }
>()((set) => ({
  designerViewportDims: {
    width: 0,
    height: 0,
  },
  setDesignerViewportDims: (width: number, height: number) =>
    set(() => ({
      designerViewportDims: { width, height },
    })),
}));
interface MovableElementsPlaneState {
  viewportDOMElementHeight: number | null; // would be an animatable object
  viewportDOMElementWidth: number | null; // would be an animatable object
  selected: any[];
  focused: any;
  currentSelectedHotspot: any;
  movableObjectReference: Moveable | null;
}

const initialState: MovableElementsPlaneState = {
  viewportDOMElementHeight: null,
  viewportDOMElementWidth: null,
  selected: [],
  focused: null,
  currentSelectedHotspot: null,
  movableObjectReference: null,
};

const MovableElementsPlaneState = createContext<any>(initialState);
const MovableElementsPlaneDispatch = createContext<any>({});

const movableElementsPlaneReducer = (state: MovableElementsPlaneState, action: any) => {
  switch (action.type) {
    case "SET_PLANE_VIEWPORT_HEIGHT": {
      return { ...state, viewportDOMElementHeight: action.payload };
    }
    case "SET_PLANE_VIEWPORT_WIDTH": {
      return { ...state, viewportDOMElementWidth: action.payload };
    }
    case "SET_CURRENT_SELECTED_HOTSPOT": {
      return { ...state, currentSelectedHotspot: action.payload };
    }
    case "SET_MOVABLE_OBJECT_REF": {
      return { ...state, movableObjectReference: action.payload };
    }
    //   case 'SET_OBJECT_LIST': {
    //
    //     return { ...state, objectList: action.payload};
    //   }
    case "SET_FOCUSED": {
      return { ...state, focused: action.payload };
    }
    case "RESET_STATE": {
      return {
        ...state,
        selected: [],
        focused: null,
        currentSelectedHotspot: null,
        movableObjectReference: null,
      };
    }
    default:
      return state;
  }
};

export function MovableElementsPlaneProvider({ children }: PropsWithChildren<any>) {
  const [state, dispatch] = useReducer(movableElementsPlaneReducer, initialState); // TODO use reducer

  useEffect(() => {
    return () => {
      // dispatch({
      //   type: 'REMOVE_PLANE_VIEWPORT_ELEMENT'
      // })
      // dispatch({
      //   type: 'SET_MOVABLE_OBJECT_REF',
      //   payload: null,
      // })
      dispatch({
        type: "RESET_STATE",
      });
    };
  }, []);
  return (
    <MovableElementsPlaneDispatch.Provider value={dispatch}>
      <MovableElementsPlaneState.Provider value={state}>
        <InteractivityHotspotProvider>
          <InteractivityBuilderProvider>
            <ObjectPropertyBoxProvider>{children}</ObjectPropertyBoxProvider>
          </InteractivityBuilderProvider>
        </InteractivityHotspotProvider>
      </MovableElementsPlaneState.Provider>
    </MovableElementsPlaneDispatch.Provider>
  );
}

export function useMovableElementsPlaneDispatch() {
  const ctx = useContext(MovableElementsPlaneDispatch);
  if (ctx === undefined) {
    throw new Error("Wrap component in Wysiwyg Provider");
  }
  return ctx;
}
export function useMovableElementsPlaneState() {
  const ctx = useContext(MovableElementsPlaneState);
  if (ctx === undefined) {
    throw new Error("Wrap component in Wysiwyg Provider");
  }
  return ctx as MovableElementsPlaneState;
}
