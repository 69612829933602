import React from "react";
import { connect } from "react-redux";
import "../../CPaT3d_Player/CPaT3d_Player.css";
import {
  displayStepSystem,
  setdisplayStyle,
  displayChooseStep,
  displayAddStep,
  displayStepResponsePanel,
  storedCurrentStep,
} from "../../../store/actions/actions";
// import MagicCover from '../scene/loadingScreen'

import styles from "./hud.module.css";
type props = {
  whatToDoList: string[];
  currentObjectiveLabel: string;
  currentObjectiveText: string;
};
const PagePresentation = ({ whatToDoList, currentObjectiveLabel, currentObjectiveText }: props) => {
  return (
    <div className={styles.pagePlayerRightOverlay}>
      <div className={styles.upperPanel}>
        <div id="objectiveLabelText">
          <div id="editInputLabel">
            <input type="text" disabled value={currentObjectiveLabel} className={styles.editInputLabel} />
          </div>
        </div>
        <div id="objectiveText">
          <textarea disabled value={currentObjectiveText} className={styles.editCurLabel} />
        </div>
      </div>
      <div className={styles.midPanel}>
        <div className={styles.whatToDoContainer}>
          <div className={styles.currentObjectiveDescriptionLabelText}>
            <input type="text" disabled value={"What to do:"} className={styles.editInputLabel} />
          </div>
          {whatToDoList.length &&
            whatToDoList.map((listItem, i) => {
              if (listItem?.trim().length === 0) return <></>;
              return <li key={listItem + i}>{listItem}</li>;
            })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    openStepResponsePanel: state.Current3dPage.openStepResponsePanel,
    currentStep: state.Current3dPage.currentStep,
  };
};

export default connect(mapStateToProps, {
  displayStepSystem,
  displayChooseStep,
  setdisplayStyle,
  displayAddStep,
  displayStepResponsePanel,
  storedCurrentStep,
})(PagePresentation);
