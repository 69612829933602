import { BasePageType, PageType } from "./types";

export const pageHasNarration = (pageType: PageType, basePageType?: BasePageType) => {
  // Enabled narration pages
  // One exception is BASE page + WARNING type
  return (
    [PageType.BASE, PageType.FMS, PageType.THREE_D, PageType.WALK_AROUND, PageType.CABIN].includes(pageType) &&
    (basePageType ? ![BasePageType.WARNING].includes(basePageType) : true)
  );
};
