import React, { useState, useEffect } from "react";

interface IHeaderAnnouncerProps {
  children: string;
  handleApiError(response: any, flag?: string): void;
}

const HeaderAnnouncer: React.FC<IHeaderAnnouncerProps> = (props: IHeaderAnnouncerProps) => {
  const [displayedMessage, setDisplayedMessage] = useState(props.children);

  useEffect(() => {
    let timer: any;

    setDisplayedMessage(props.children);
    if (props.children && props.children !== "") {
      timer = setTimeout(() => {
        props.handleApiError("", "");
      }, 5000);
    }

    return () => {
      return clearTimeout(timer);
    };
  }, [props.children]);

  return <div style={{ color: "crimson" }}>{displayedMessage}</div>;
};

export default HeaderAnnouncer;
