import React, { useContext } from "react";
import { KeyDataListProps, buttonObject } from "../../Interfaces/keyDataInterfaces";
import { IPageContext, PageContext } from "../../../../routes/builderContexts";

import { IFmsPageManifest } from "../../Interfaces/IFmsPageManifest";
import blankFunctionKeys from "../../assets/blankFunctionKeyes.json";
import addButton from "../../../../assets/icons/Add-gray.svg";
import deleteButton from "../../../../assets/icons/FMSIcons/Remove.svg";
import editButton from "../../../../assets/icons/FMSIcons/Edit.svg";
import "./keyDataEditor.css";
import lodash from "lodash";

const KeyDataList = (props: KeyDataListProps) => {
  //keyName={keyName} destinationList={keyList} toggleButtonGroup={toggleButtonGroup} validateButtonGroup={validateButtonGroup}
  const pagesContext: IPageContext = useContext<IPageContext>(PageContext);
  const pageManifest: IFmsPageManifest = pagesContext.pageManifest as IFmsPageManifest;
  const addKeyAction = () => {
    const blankAction = lodash.cloneDeep(blankFunctionKeys);
    pageManifest.actions[props.keyName].push(blankAction[0]);
    pagesContext.updatePageManifest(pageManifest);
    editorActions(pageManifest.actions[props.keyName].length - 1);
    if (props.nestedMenu) {
      props.nestedMenu(true);
    }
  };

  const removeKeyAction = (actionIndex: number) => {
    const newKeyActionArray = pageManifest.actions[props.keyName].filter((action: any, index: number) => {
      return index !== actionIndex;
    });
    pageManifest.actions[props.keyName] = newKeyActionArray;
    pagesContext.updatePageManifest(pageManifest);
  };

  const editorActions = (indexValue: number) => {
    let manifestChange = false;
    if (!pageManifest.actions[props.keyName][indexValue].parameter.highlightCduCell) {
      pageManifest.actions[props.keyName][indexValue].parameter.highlightCduCell = { keyName: " " };
      manifestChange = true;
    }
    if (!pageManifest.actions[props.keyName][indexValue].parameter.cduKeyPress) {
      pageManifest.actions[props.keyName][indexValue].parameter["cduKeyPress"] = { keyName: "" };
      manifestChange = true;
    }
    if (manifestChange) {
      pagesContext.updatePageManifest(pageManifest);
    }

    props.toggleEditingAction(true);
    props.setActionIndex(indexValue);
    if (props.nestedMenu) {
      props.nestedMenu(true);
    }
  };
  const returnUserFriendlyCondition = (conditionType: string) => {
    switch (conditionType) {
      case "textAttribute": {
        return "Text Present Attribute";
      }
      case "pageAttribute": {
        return "Page Attribute";
      }
      case "cellAttribute": {
        return "Highlight Attribute";
      }
      default: {
        return "Not Selected";
      }
    }
  };
  const returnUserFriendlyFunction = (functionName: string) => {
    switch (functionName) {
      case "changeCDUCell": {
        return "Change CDU Cell";
      }
      case "highlightCduCell": {
        return "Highlight Cell";
      }
      case "clearKeyHandler": {
        return "Clear Scratchpad";
      }
      case "cduKeyPress": {
        return "CDU Key Press";
      }
      default: {
        return "Append to Scratchpad";
      }
    }
  };

  return (
    <>
      <p className="instructions">This key can perform the following actions</p>
      <div
        style={{
          width: "100%",
          backgroundColor: "#ffffff",
          padding: "2%",
          color: "#000000",
        }}
      >
        {pageManifest.actions[props.keyName].length ? (
          pageManifest.actions[props.keyName].map((action: buttonObject, index: number) => (
            <div key={index} className="criteriaRow">
              <div className="col-4 rowElement centered-div">
                <p>{returnUserFriendlyFunction(action.function)}</p>
              </div>
              <div className="col-6 crowElement divider centered">
                {action.conditions.length ? (
                  <>
                    <p>
                      <strong>
                        Conditions:
                        <br />
                      </strong>
                    </p>
                    <div className="centered-div">
                      {action.conditions.map((condition, index) => (
                        <p key={`p${index}`}>
                          {index === action.conditions.length - 1
                            ? `${returnUserFriendlyCondition(condition.type)}`
                            : `${returnUserFriendlyCondition(condition.type)},`}
                        </p>
                      ))}
                    </div>
                  </>
                ) : (
                  <p>No Conditions</p>
                )}
              </div>
              <div className="col-2 centered rowElement criteria-control-buttons">
                <div className="criteria-control-container">
                  <figure
                    className="control-figure"
                    onClick={() => {
                      editorActions(index);
                    }}
                  >
                    <img className="rowButton shaded" src={editButton} />
                  </figure>
                </div>
                <div className="criteria-control-container">
                  <figure
                    className="control-figure"
                    onClick={() => {
                      removeKeyAction(index);
                    }}
                  >
                    <img
                      className="rowButton shaded"
                      src={deleteButton}
                      onClick={() => {
                        removeKeyAction(index);
                      }}
                    />
                  </figure>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="criteriaRow">
            <div className="col-12 rowElement centered">
              <p>This Key Has no actions</p>
            </div>
          </div>
        )}

        <div className="col-12 criteriaRow buttonRow">
          <div className="bottom-button-container">
            <figure className="bottom-button-figure" onClick={addKeyAction}>
              <img src={addButton} className="rowElement bottomButton shaded" />
            </figure>
          </div>
        </div>
      </div>
    </>
  );
};

export default KeyDataList;
