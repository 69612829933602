import React from "react";
import { Modal } from "react-bootstrap";
const RefreshModal = (props: any) => {
  return (
    <Modal show={props.show}>
      <Modal.Body>
        <h5>Lesson is out of SYNC......Please load latest changes.</h5>
        <button onClick={() => window.location.reload()}>Reload</button>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default RefreshModal;
