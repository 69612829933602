import React from "react";
import { AnnotationPropType } from "../AnnotationThumbnail";
import { Interweave } from "interweave";

const AnnotationLabel = (props: AnnotationPropType) => {
  const { annotation, index, ratio } = props;

  const display = () => {
    const rotate: number = annotation?.transform?.rotate;
    // const scaleX: number = annotation?.transform?.scaleX;
    // const scaleY: number = annotation?.transform?.scaleY;
    // const matrix: number[] = annotation?.transform?.matrix3d;
    return (
      <div
        className="annotation label"
        key={index}
        id={`${index}`}
        style={{
          top: `${annotation.top}%`,
          left: `${annotation.left}%`,
          width: `${annotation.width}%`,
          // height: `calc(1.9 * 18.74px)`,
          zIndex: annotation.zIndex,
          transform: `rotate(${rotate}deg)`,
          textAlign: annotation.textAlign,
          fontSize: `calc(1em * ${ratio})`,
          backgroundColor: `${annotation.backgroundColor}`,
          outline: annotation.borderColor
            ? `${annotation.strokeWidth * ratio}px solid ${annotation.borderColor}`
            : "none",
        }}
      >
        <div
          className="annotation-label annotation-label-input"
          style={{
            // height: `calc(1.9em * ${ratio})`,
            color: `${annotation.fontColor}`,
            fontWeight: annotation.fontWeight,
            fontStyle: `${annotation.fontStyle}`,
            textDecoration: `${annotation.textDecoration}`,
          }}
        >
          <Interweave content={annotation.text} />
        </div>
      </div>
    );
  };

  return <React.Fragment>{display()}</React.Fragment>;
};

export default AnnotationLabel;
