import { Interactivity, Level } from "../";

type MemoryOrDataLevel = Interactivity.PageManifestData | Level;
export function travelTree<T>(startingNode: MemoryOrDataLevel, cb: (level: MemoryOrDataLevel) => void) {
  cb(startingNode);

  startingNode.levelBuffer.forEach((node: MemoryOrDataLevel) => {
    travelTree(node, cb);
  });
}
