import React, { Component } from "react";

import oopsimage from "../assets/CPaT_Error_Message_Illustration300x.png";
import genericRepositoryService from "../services/genericRepositoryService";
import ILogErrorRequest from "../models/ILogErrorRequest";
import "./PageEditorErrorBoundary.css";

const ErrorView = () => (
  <div className="pageerrorcontainer">
    <img src={oopsimage} className="pageerrorimage" />
    {/* Oops! Something went wrong with this page. Please try again. Contact CPaT Support if the issue persists. */}
  </div>
);

export default class PageEditorErrorBoundary extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
      myprops: props,
      identifyingInfo: props.identifyingInfo,
    };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ error: error, errorInfo: errorInfo });
    const issueData: ILogErrorRequest = {
      tag: "PageEditor",
      identifyingInfo: this.state.identifyingInfo,
      error: error,
      errorInfo: errorInfo,
    };
    genericRepositoryService.logIssue(issueData);
  }

  render() {
    const { error, errorInfo } = this.state;
    if (errorInfo) return <ErrorView />;

    return this.props.children;
  }
}

// export function pageEditorErrorBoundary(WrappedComponent) {
//   return class extends PageEditorErrorBoundary {
//     render() {
//       const { error, errorInfo } = this.state;
//       if (errorInfo) {

//         return <ErrorView {...{ error, errorInfo }} />;
//       }
//       return <WrappedComponent {...this.props} />;
//     }
//   };
// }
