/* eslint-disable @typescript-eslint/no-unused-vars */
import { CSSProperties, useMemo } from "react";
import {
  SmartObjectComponent,
  SmartObjectLine,
  SmartObjectPolygon,
  SmartObjectPolyline,
  SmartObjectSvgPath,
  SmartObjectSvgRenderer,
  SmartObjectSvgRendererProps,
} from "../";
import { SVG_SMART_OBJECT_HOTSPOTS_ACTIONS, SVG_SMART_OBJECT_TYPES } from "../../..";
import { useMetaVariableStore } from "../../../store";
import { getHotSpotMetaVariablesKeys } from "../../../utils";

export function SvgHotSpotRenderer({ element, objectId, parentId }: SmartObjectSvgRendererProps) {
  const { type, GROUP_ID, ACTION, HOTSPOT_ID, TYPE, VALUE, children, ...rest } = element;

  if (TYPE === SVG_SMART_OBJECT_TYPES.HOTSPOT_ONCLICK) {
    return <SmartObjectHotspotOnClick element={element} objectId={objectId} />;
  }

  switch (type) {
    case "g": {
      return <SvgHotSpotG element={element} objectId={objectId} parentId={parentId} />;
    }
    case "polyline": {
      return <SmartObjectPolyline element={element} />;
    }
    case "line": {
      return <SmartObjectLine element={element} />;
    }
    case "polygon": {
      return <SmartObjectPolygon element={element} />;
    }
    case "path": {
      return <SmartObjectSvgPath element={element} />;
    }
    default: {
      console.warn("SvgHotSpotRenderer: type not handled", type);
      return null;
    }
  }
}

export function SmartObjectSvgHotSpot({ element, objectId }: SmartObjectComponent) {
  const { children, ACTION, TYPE, GROUP_ID, VALUE, HOTSPOT_ID, SET_ID, ...rest } = element;
  const { target, enabled, display } = getHotSpotMetaVariablesKeys(objectId, HOTSPOT_ID, SET_ID);
  const variables = useMetaVariableStore((s) => s.metaVariables);
  const data = useMetaVariableStore((s) => s.metaVariablesData[target]);
  const set = useMetaVariableStore((s) => s.setMetaVariable);
  const toggleNext = useMetaVariableStore((s) => s.toggleNext);
  const togglePrevious = useMetaVariableStore((s) => s.togglePrevious);
  const increment = useMetaVariableStore((s) => s.increment);
  const decrement = useMetaVariableStore((s) => s.decrement);

  const handleClick = () => {
    if (!variables[enabled]) return;

    switch (ACTION) {
      case SVG_SMART_OBJECT_HOTSPOTS_ACTIONS.SET_LITERAL: {
        if (VALUE) {
          set(target, VALUE);
        }
        break;
      }

      case SVG_SMART_OBJECT_HOTSPOTS_ACTIONS.TOGGLE_NEXT: {
        toggleNext(target);
        break;
      }

      case SVG_SMART_OBJECT_HOTSPOTS_ACTIONS.TOGGLE_PREVIOUS: {
        togglePrevious(target);
        break;
      }

      case SVG_SMART_OBJECT_HOTSPOTS_ACTIONS.INCREMENT: {
        increment(target);
        break;
      }

      case SVG_SMART_OBJECT_HOTSPOTS_ACTIONS.DECREMENT: {
        decrement(target);
        break;
      }

      default:
        break;
    }
  };

  const handleMouseDown = () => {
    if (!variables[enabled]) return;

    switch (ACTION) {
      case SVG_SMART_OBJECT_HOTSPOTS_ACTIONS.SET_MOMENTARY: {
        const choices = data.choices;
        if (choices && choices.length >= 1) {
          set(target, choices[1]);
        }
        break;
      }

      case SVG_SMART_OBJECT_HOTSPOTS_ACTIONS.HOLD_INCREMENT:
        // TODO: implement
        break;

      case SVG_SMART_OBJECT_HOTSPOTS_ACTIONS.HOLD_DECREMENT:
        // TODO: implement
        break;

      default:
        break;
    }
  };

  const handleMouseUp = () => {
    if (!variables[enabled]) return;

    switch (ACTION) {
      case SVG_SMART_OBJECT_HOTSPOTS_ACTIONS.SET_MOMENTARY: {
        const choices = data.choices;
        if (choices && choices.length >= 1) {
          set(target, choices[0]);
        }
        break;
      }

      default:
        break;
    }
  };

  const styles = useMemo(() => {
    return {
      cursor: "pointer",
      opacity: variables[display] ? 1 : 0,
      pointerEvents: variables[enabled] ? "auto" : "none",
    } as CSSProperties;
  }, [variables[display], variables[enabled]]);

  return (
    <g
      {...rest}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      style={styles}
      onMouseDownCapture={handleMouseDown}
    >
      {children?.map((item, idx) => (
        <SvgHotSpotRenderer key={idx} element={item} objectId={objectId} />
      ))}
    </g>
  );
}

export function SvgHotSpotG({ element, objectId }: SmartObjectComponent) {
  const { children, TYPE, GROUP_ID, VALUE, HOTSPOT_ID, style, ...rest } = element;

  return (
    <g {...rest}>
      {children?.map((item, idx) => (
        <SvgHotSpotRenderer key={idx} element={item} objectId={objectId} />
      ))}
    </g>
  );
}

export function SmartObjectHotspotOnClick({ element, objectId }: SmartObjectComponent) {
  const styles = {
    fill: "white",
    fillOpacity: 0,
    strokeOpacity: 0,
  } as CSSProperties;

  return <SmartObjectSvgRenderer element={element} objectId={objectId} extraStyles={styles} />;
}
