import React from "react";

interface Props {
  handleSubmit: () => Promise<void>;
  validSubmission: boolean;
  setSaving: React.Dispatch<React.SetStateAction<boolean>>;
}

const FooterSection = ({ handleSubmit, validSubmission, setSaving }: Props) => {
  const submitAssets = async () => {
    if (!validSubmission) return;
    setSaving(true);
    try {
      await handleSubmit();
    } catch (e) {
      console.error(e);
    } finally {
      setSaving(false);
    }
  };
  return (
    <div className="image-metadata-editor-footer">
      <div className="image-metadata-editor-footer-btns">
        <button className="metadata-editor-disabled-btn">Save and Continue Later</button>
        <button
          className={validSubmission ? "metadata-editor-btn" : "metadata-editor-disabled-btn"}
          onClick={submitAssets}
        >
          Add To Library
        </button>
      </div>
    </div>
  );
};

export default FooterSection;
