import { FC } from "react";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../../contexts/ObjectsProvider";
import {
  LessonPagesActions,
  useLessonPage,
  useLessonPagesDispatch,
} from "../../../contexts/LessonPagesProvider/LessonPagesProvider";

const FreeFormPolyMenu: FC = () => {
  const { selectedObjects } = useObjectsState();
  const { selectedPointId } = useLessonPage();
  const objectsDispatch = useObjectsDispatch();
  const lessonPagesDispatch = useLessonPagesDispatch();
  const annotation = selectedObjects[0];

  const handleRemoveClick = () => {
    const newPoints = [...annotation.points];
    const index = newPoints.findIndex((p) => p.id === selectedPointId);

    if (index === -1) return;

    newPoints.splice(index, 1);

    objectsDispatch({
      type: ObjectActionsType.UPDATE_OBJECT,
      payload: {
        objectId: annotation.objectId,
        object: {
          points: newPoints,
        },
      },
    });

    lessonPagesDispatch({
      type: LessonPagesActions.UPDATE_SELECTED_POINT_ID,
      payload: null,
    });
  };

  /**
   * Button is disabled if there is not selected point to remove
   * or if there are less than 3 points in the polygon.
   * This is to prevent having an annotation with no points.
   */
  const disabled = !selectedPointId || annotation.points.length < 3;

  return (
    <div className="freeform-remove-button-wrapper">
      <button disabled={disabled} onClick={handleRemoveClick}>
        Remove Selected Point
      </button>
    </div>
  );
};

export default FreeFormPolyMenu;
