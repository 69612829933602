import React, { useContext } from "react";
import blobUrlFormatHelper from "../../../components/blobUrlFormatHelper";
import { IOverlayGroup } from "../Interfaces/ICdu";
import { IFmsContext, FmsContext } from "../Interfaces/fmsContext";
import { IPageContext, PageContext } from "../../../routes/builderContexts";
import { IFmsPageManifest } from "../Interfaces/IFmsPageManifest";

export interface ICduImageOverlaysProps {
  overLays: IOverlayGroup[] | undefined;
  cduPath: string;
  pageOverlays?: any[];
  pageImage?: any;
  paging?: boolean;
  pageTemplates?: any;
  fromModal?: boolean;
  modalData?: any;
  // assignFmsKeypad: any;
  // accessCduLineInformation: any;
  assignFmsKeypad: null | ((id: string) => void);
  accessCduLineInformation: null | ((id: string) => void);
}
const CduImageOverlays: React.FC<ICduImageOverlaysProps> = (props: ICduImageOverlaysProps): any => {
  const pagesContext: IPageContext = useContext<IPageContext>(PageContext);
  const pageManifest: IFmsPageManifest = pagesContext.pageManifest as IFmsPageManifest;
  const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);
  // const findOverlayData = (id: string, valueName: string) => {
  //     let returnAsset = props.cduAssets.filter(asset => {
  //         return asset.id === id;
  //     });
  //     return returnAsset[0][valueName]
  // }
  // useEffect(()=>{

  // },[props.pageImage])
  //a220 needs to hide images not present in modal

  const isOverlayShown = (overLay: any) => {
    let displayOverLay = false;
    if (Object.keys(pageManifest.pages[pageManifest.bootPage]).includes(overLay.parentCell)) {
      displayOverLay = true;
    }
    if (
      pageManifest.pageLayouts[pageManifest.bootPage] &&
      pageManifest.pageLayouts[pageManifest.bootPage].modals &&
      pageManifest.pageLayouts[pageManifest.bootPage].modals[fmsContext.currentModal.modalName]
    ) {
      Object.keys(pageManifest.pageLayouts[pageManifest.bootPage].modals[fmsContext.currentModal.modalName]).map(
        (modalValue: any) => {
          if (
            Object.keys(
              pageManifest.pageLayouts[pageManifest.bootPage].modals[fmsContext.currentModal.modalName][modalValue],
            ).includes(overLay.parentCell) &&
            fmsContext.showCduModal
          ) {
            displayOverLay = true;
          } else {
            displayOverLay = false;
          }
        },
      );
    }

    if (overLay.parentCell === "actMod") {
      displayOverLay = true;
    } else if (pageManifest.cdu.includes("a350")) {
      displayOverLay = true;
    }
    return displayOverLay;
  };
  // return(
  //     <>

  if (props.fromModal) {
    return props.overLays !== undefined ? (
      <>
        {props.overLays?.map((overlay: any, index) => {
          if (
            overlay.actions[0]?.function === "changeImage" &&
            pageManifest.pageLayouts[pageManifest.bootPage].modals?.[fmsContext.currentModal.modalName]?.overLays !==
              undefined
          )
            return (
              <image
                key={index}
                className="overLay"
                href={blobUrlFormatHelper(
                  `${props.cduPath}${
                    pageManifest.pageLayouts[pageManifest.bootPage].modals[fmsContext.currentModal.modalName]
                      ?.overLays[0]?.image
                  }`,
                )}
                width={overlay.width}
                height={overlay.height}
                id={overlay.id}
                preserveAspectRatio="none"
                //   onClick={() => {
                //     props.assignFmsKeypad !== null
                //       ? props.assignFmsKeypad(overlay.id)
                //       : console.log('click');
                //   }}
                //   pointerEvents={overlay.id === 'knob' ? 'all' : 'none'}
                x={overlay.xCoordinate}
                y={overlay.yCoordinate}
              />
            );
        })}
      </>
    ) : (
      <></>
    );
  } else {
    return (
      <>
        {/* {props.fromModal
          ? props.overLays?.map((overlay, index) => {
              return (
                <image
                  key={index}
                  className="overLay"
                //   href={blobUrlFormatHelper(`${props.cduPath}${overlay.href}`)}
                //   width={overlay.width}
                //   height={overlay.height}
                //   id={overlay.id}
                //   onClick={() => {
                //     props.assignFmsKeypad !== null
                //       ? props.assignFmsKeypad(overlay.id)
                //       : console.log('click');
                //   }}
                //   pointerEvents={overlay.id === 'knob' ? 'all' : 'none'}
                //   x={overlay.xCoordinate}
                //   y={overlay.yCoordinate}
                 />
              );
            })
          : null} */}

        {props.overLays &&
          props.overLays.map((overlay, index) => {
            return (
              <image
                key={index}
                className="overLay"
                href={blobUrlFormatHelper(`${props.cduPath}${overlay.href}`)}
                width={overlay.width}
                height={overlay.height}
                id={overlay.id}
                onClick={() => {
                  props.assignFmsKeypad !== null ? props.assignFmsKeypad(overlay.id) : console.log("click");
                }}
                pointerEvents={overlay.id === "knob" ? "all" : "none"}
                x={overlay.xCoordinate}
                y={overlay.yCoordinate}
              />
            );
          })}

        {props.pageOverlays ? (
          props.pageOverlays.map((overlay: any, index: number) =>
            isOverlayShown(overlay) ? (
              <image
                key={index}
                href={blobUrlFormatHelper(`${props.cduPath}${overlay.image}`)}
                width={overlay.width}
                height={overlay.height}
                x={overlay.xCoordinate}
                y={overlay.yCoordinate}
                preserveAspectRatio="none"
                onClick={() => {
                  overlay.parentCell !== "actMod"
                    ? props.assignFmsKeypad !== null
                      ? props.assignFmsKeypad(overlay.id)
                      : console.log("click")
                    : console.log("click");
                }}
              />
            ) : (
              <></>
            ),
          )
        ) : (
          <></>
        )}
      </>
    );
  }
};

export default CduImageOverlays;
