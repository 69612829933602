import ILessonPageDto from "../../models/ILessonPageDto";
import { BasePageType, PageType } from "../../components/TimelineDrawer/types";

const NARRATION_DISABLED_PAGES = [PageType.TITLE, PageType.QUIZ, PageType.SCORE, PageType.EXIT];
const NARRATION_DISABLED_SUBPAGES = [BasePageType.WARNING];
const TIMELINE_ENABLED_SUBPAGES = [BasePageType.FREE_FORM];

export const isNarrationEnabled = (page?: ILessonPageDto) => {
  if (page) {
    const pageType = page.pagePlayerType as PageType;

    // Check if it is one of the forbidden pages
    if (NARRATION_DISABLED_PAGES.includes(pageType)) {
      return false;
    }

    // In case it is a BASE page we should check
    //  if its free form we pass it on to TIMELINE otherwise we check
    //  for narrator enabled pages
    if (pageType === PageType.BASE) {
      const basePageType = page.pageManifest.basePageType as BasePageType;
      if (basePageType === BasePageType.FREE_FORM) {
        return false;
      } else {
        return !NARRATION_DISABLED_SUBPAGES.includes(basePageType);
      }
    }

    // For all other pages it should be true!
    return true;
  }

  return false;
};

export const isTimelineEnabled = (page?: ILessonPageDto) => {
  if (page) {
    const pageType = page.pagePlayerType as PageType;

    // Simple elimination, timeline works only on BASE page types
    if (pageType !== PageType.BASE) {
      return false;
    }

    // We are sure this is a base page at this point
    const basePageType = page.pageManifest.basePageType as BasePageType;

    // Timeline is only available for FREE FORM right now
    if (TIMELINE_ENABLED_SUBPAGES.includes(basePageType)) {
      return true;
    }

    // For all other pages it should be true!
    return false;
  }

  return false;
};
