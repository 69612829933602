import { handleTransparencyColor } from "../../../utils/Conversion";

export function BlurComponent({
  width,
  height,
  transformString,
  objectId,
  blurIntensity,
  opacity,
  borderRadius,
  isCircle,
  zIndex,
  id,
  onMouseDown,
  borderColor,
  strokeWidth,
  isDisplayed,
  fullWidth,
  inactiveDrag,
  backgroundColor,
}: {
  width: number;
  height: number;
  transformString?: string;
  objectId: string;
  blurIntensity: number;
  opacity: number;
  borderRadius?: number;
  isCircle?: boolean;
  zIndex: number;
  id: string;
  onMouseDown?: (e: React.MouseEvent<HTMLDivElement | SVGSVGElement>) => void;
  borderColor?: string;
  strokeWidth?: number;
  isDisplayed: boolean;
  fullWidth?: boolean;
  inactiveDrag?: boolean;
  backgroundColor?: string;
}) {
  return (
    <div
      data-objectid={objectId}
      onMouseDown={onMouseDown}
      id={id}
      style={{
        position: "absolute",
        pointerEvents: inactiveDrag ? "none" : "all",
        transform: transformString,
        width: fullWidth ? "100%" : `${width}px`,
        height: fullWidth ? "100%" : `${height}px`,
        zIndex: zIndex,
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          backdropFilter: `blur(${opacity === 0 ? 0 : blurIntensity}px)`,
          WebkitBackdropFilter: `blur(${opacity === 0 ? 0 : blurIntensity}px)`,
          cursor: "grab",
          borderRadius: isCircle ? "50%" : `${borderRadius}px`,
          border: `${strokeWidth || 4}px solid ${handleTransparencyColor(borderColor || "transparent", opacity)}`,
          filter: isDisplayed ? "none" : "grayScale(100%)",
          backgroundColor: handleTransparencyColor(backgroundColor as string, opacity),
        }}
      />
    </div>
  );
}
