import React from "react";
import { AnnotationMenuIconType, AnnotationMenuPropType } from "../AnnotationMenu";
import { AnnotationMenuLineIcons } from "../helpers/AnnotationMenuLineIcons";

const AnnotationMenuLines = (props: AnnotationMenuPropType) => {
  const { addNewAnnotation } = props;
  const display = () => {
    return AnnotationMenuLineIcons.map((icon: AnnotationMenuIconType, index: number) => {
      return (
        <div
          id={icon.name}
          className={`annotation-icon`}
          key={index}
          onClick={(e: React.MouseEvent<HTMLDivElement>) => addNewAnnotation(e.currentTarget.id, "addLineType")}
        >
          {icon.icon}
        </div>
      );
    });
  };

  return <React.Fragment>{display()}</React.Fragment>;
};

export default AnnotationMenuLines;
