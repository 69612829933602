import { convertUnitSize, splitBracket, splitSpace } from "@daybrush/utils";
import { ControlPose } from "../../../react-moveable";
import { getRadiusValues } from "./roundable/borderRadius";
import { CLIP_RECT_DIRECTIONS } from "./consts";
import { PropertyBoxType } from "../../../ObjectPropertyBox/ObjectPropertyBox";
import { IPropertyBox } from "../../../ObjectPropertyBox/models/IObjectPropertyBox";
import _ from "lodash";

export type clipPath = {
  top: number;
  left: number;
  right: number;
  bottom: number;
  absoluteTop: number;
  absoluteLeft: number;
  absoluteRight: number;
  absoluteBottom: number;
};

export function getRectPoses(top: number, right: number, bottom: number, left: number): ControlPose[] {
  const xs = [left, (left + right) / 2, right];
  const ys = [top, (top + bottom) / 2, bottom];

  return CLIP_RECT_DIRECTIONS.map(([dirx, diry, dir]) => {
    const x = xs[dirx + 1];
    const y = ys[diry + 1];
    return {
      vertical: Math.abs(diry),
      horizontal: Math.abs(dirx),
      direction: dir,
      pos: [x, y],
    };
  });
}

export function getClipPath(
  target: HTMLElement | SVGElement,
  width: number,
  height: number,
  defaultClip?: string,
  customClip?: string,
) {
  let clipText: string | undefined = customClip;

  if (!clipText) {
    const style = getComputedStyle(target!);
    const clipPath = style.clipPath!;

    clipText = clipPath !== "none" ? clipPath : style.clip!;
  }
  if (!clipText || clipText === "none" || clipText === "auto") {
    clipText = defaultClip;

    if (!clipText) {
      return;
    }
  }
  const { prefix: clipPrefix = clipText, value = "" } = splitBracket(clipText);
  const splitter = " ";

  if (clipPrefix === "inset") {
    const values = splitSpace(value! || "0 0 0 0");
    const roundIndex = values.indexOf("round");

    const rectLength = (roundIndex > -1 ? values.slice(0, roundIndex) : values).length;
    const radiusValues = values.slice(rectLength + 1);
    const [topValue, rightValue = topValue, bottomValue = topValue, leftValue = rightValue] = values.slice(
      0,
      rectLength,
    );
    const [top, bottom] = [topValue, bottomValue].map((pos) => convertUnitSize(pos, height));
    const [left, right] = [leftValue, rightValue].map((pos) => convertUnitSize(pos, width));
    const nextRight = width - right;
    const nextBottom = height - bottom;
    const radiusPoses = getRadiusValues(radiusValues, nextRight - left, nextBottom - top, left, top);
    const poses: ControlPose[] = [...getRectPoses(top, nextRight, nextBottom, left), ...radiusPoses];

    return {
      type: "inset",
      clipText,
      poses,
      top,
      left,
      right: nextRight,
      bottom: nextBottom,
      radius: radiusValues,
      splitter,
    } as const;
  }
  return;
}

export function getClipObject(clipStyles: Array<string>, width: number, height: number): clipPath {
  const clipObject = {} as clipPath;
  const positions = ["top", "right", "bottom", "left"];
  const absPositions = ["absoluteTop", "absoluteRight", "absoluteBottom", "absoluteLeft"];

  // clip positions are provided as an array of strings (e.g. ["20.1124%", "1.43%", "2.342%", "0%"]) by the movable api so convert them to float and round to 6 decimal places
  const clipPositions = clipStyles.map((style) => Math.round(parseFloat(style.split("%")[0]) * 1000000) / 1000000);

  if (clipPositions.length < 4 || clipPositions.some((pos) => isNaN(pos))) {
    positions.forEach((pos: string) => {
      Object.assign(clipObject, { [pos]: 0 }); // return crop coordinates of 0 for all sides if invalid data is sent
    });
    absPositions.forEach((pos: string, i: number) => {
      Object.assign(clipObject, { [pos]: 0 });
    });
  } else {
    positions.forEach((pos: string, i: number) => {
      Object.assign(clipObject, { [pos]: clipPositions[i] });
    });
    absPositions.forEach((pos: string, i: number) => {
      let cropAmount: number;
      if (i % 2 === 0) cropAmount = (clipPositions[i] / 100) * height;
      // indexes for absoluteTop and absoluteBottom
      else cropAmount = (clipPositions[i] / 100) * width; // indexes for absoluteRight and absoluteLeft
      Object.assign(clipObject, { [pos]: Math.floor(cropAmount) });
    });
  }
  return clipObject;
}

export const hasClipCoordinates = (node: any) => {
  if (!node || !node.hasOwnProperty("clipPath")) return false;
  return !Object.values(node.clipPath as clipPath).every((value) => value === 0);
};

export const getAbsoluteClipPosOnResize = (width: number, height: number, clipPath: clipPath): clipPath => {
  if (!clipPath) {
    return {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      absoluteBottom: 0,
      absoluteLeft: 0,
      absoluteRight: 0,
      absoluteTop: 0,
    } as clipPath;
  }
  const clipObject = { ...clipPath } as clipPath;
  const { top, right, bottom, left, absoluteBottom, absoluteTop, absoluteRight, absoluteLeft } = _.cloneDeep(clipPath);
  clipObject.absoluteBottom = Math.floor((bottom / 100) * height);
  clipObject.absoluteTop = Math.floor((top / 100) * height);
  clipObject.absoluteLeft = Math.floor((left / 100) * width);
  clipObject.absoluteRight = Math.floor((right / 100) * width);
  return clipObject;
};
//
// export const getClipString = (clipObject: clipPath) =>
//   `inset(${clipObject.top}% ${clipObject.right}% ${clipObject.bottom}% ${clipObject.left}%)`;

export const getCroppedImageSize = (target: any, node: any, pageDims: DOMRect): number[] => {
  if (!target || !node) return [0.0, 0.0];

  const height = parseFloat(((node.height * pageDims.height * node.transform.scale[1]) / 100).toFixed(2));
  const width = parseFloat(((node.width * pageDims.width * node.transform.scale[0]) / 100).toFixed(2));

  if (node.hasOwnProperty("clipPath")) {
    const { top: deltaTop, bottom: deltaBottom, left: deltaLeft, right: deltaRight } = node.clipPath;
    const clippedHeight = height - ((deltaTop + deltaBottom) / 100) * height;
    const clippedWidth = width - ((deltaRight + deltaLeft) / 100) * width;
    return [clippedWidth, clippedHeight].map((x) => Math.floor(x));
  }
  return [width, height];
};

export const getCroppedImagePosition = (target: any, props: IPropertyBox, node: any, pageDims: DOMRect) => {
  if (!target) return [0.0, 0.0];
  const tempProps: IPropertyBox = props;
  if (node.hasOwnProperty("clipPath")) {
    const { top: deltaTop, bottom: deltaBottom, left: deltaLeft, right: deltaRight } = node.clipPath;
    const [absoluteTop, absoluteLeft] = getAbsoluteImagePosition(target, pageDims);
    const clippedTop = absoluteTop + (deltaTop / 100) * tempProps.height;
    const clippedLeft = absoluteLeft + (deltaLeft / 100) * tempProps.width;
    return [clippedTop, clippedLeft].map((x) => Math.ceil(x));
  }

  return [tempProps.top, tempProps.left].map((x) => x);
};

const getAbsoluteImagePosition = (target: any, pageDims: DOMRect): number[] => {
  const { top, left } = target.getBoundingClientRect();
  return [(top - (pageDims as DOMRect).top) as number, (left - (pageDims as DOMRect).left) as number];
};

export const getUpdatedClipPath = (width: number, height: number, clipPath: clipPath): clipPath => {
  if (!clipPath) {
    return {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      absoluteBottom: 0,
      absoluteLeft: 0,
      absoluteRight: 0,
      absoluteTop: 0,
    } as clipPath;
  }
  const clipObject = { ...clipPath } as clipPath;
  const { top, right, bottom, left, absoluteBottom, absoluteTop, absoluteRight, absoluteLeft } = clipPath;
  clipObject.absoluteBottom = Math.floor((bottom / 100) * height);
  clipObject.absoluteTop = Math.floor((top / 100) * height);
  clipObject.absoluteLeft = Math.floor((left / 100) * width);
  clipObject.absoluteRight = Math.floor((right / 100) * width);
  return clipObject;
};
