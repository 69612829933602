import React from "react";
import { SVGAnnotationProps } from "../index";
import { HighlightAnnotation } from "../HighlightAnnotation";

export function SVGTrackLineRangeScale({
  onMouseDown,
  objectId,
  y,
  x,
  width,
  height,
  isDisplayed,
  zIndex,
  borderColor,
  strokeWidth,
  backgroundColor,
  opacity,
  rotate,
}: // transform,
SVGAnnotationProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 4.61 25.66"
      key={objectId}
      id={`symbol-${objectId}`}
      data-objectid={objectId}
      tabIndex={0}
      className="symbol target"
      name="symbol"
      onMouseDown={(e: React.MouseEvent<SVGSVGElement>) => {
        onMouseDown(e, objectId);
      }}
      // onKeyDown={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyPress(e, kp)}
      // onKeyUp={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyUp(e, kp)}
      preserveAspectRatio="xMidYMid meet"
      style={{
        // top: `${y}px`,
        // left: `${x}px`,
        width: `${width}px`,
        height: `${height}px`,
        zIndex: zIndex,
        transform: `translate(${x}px, ${y}px) rotate(${rotate ?? 0}deg)`,
        opacity: isDisplayed ? opacity : 0.5,
        filter: isDisplayed ? "none" : "grayScale(100%)",
        // `rotate(${attributes?.transform?.rotate}deg) scaleX(${attributes?.transform?.scaleX}) scaleY(${attributes?.transform?.scaleY}) matrix3d(${attributes?.transform?.matrix3d})`
      }}
    >
      <HighlightAnnotation objectId={objectId} />
      <path
        // className="track_line_range_scale"
        stroke={`${borderColor || "white"}`}
        fill={`${backgroundColor || "transparent"}`}
        strokeWidth={`${strokeWidth / 10 || 0.1}`}
        d="M 2.3050001,24.666083 V 0.00608282"
      />
      <path
        // className="track_line_range_scale"
        stroke={`${borderColor || "white"}`}
        fill={`${backgroundColor || "transparent"}`}
        //  strokeWidth={`${strokeWidth  ||null}`}
        d="m -0.02625,4.3975246 h 4.615"
      />
      <path
        id="rect8-2"
        d="M 0.005,12.745638 H 4.62"
        // className="track_line_range_scale"
        stroke={`${borderColor || "white"}`}
        fill={`${backgroundColor || "transparent"}`}
        //  strokeWidth={`${strokeWidth  || null}`}
      />
      <path
        id="rect8-1"
        d="m -0.0575,21.09375 h 4.615"
        // className="track_line_range_scale"
        stroke={`${borderColor || "white"}`}
        fill={`${backgroundColor || "transparent"}`}
        // strokeWidth={`${strokeWidth  || null}`}
      />
    </svg>
  );
}
