import React from "react";
import { handleKeyPress, handleKeyUp } from "../../ObjectPropertyBox/functions/PropertyBoxFunctions";
import { AnnotationPropType } from "../Annotation";
import HighlightAnnotation from "../hooks/HighlightAnnotation";

const AnnotationSquareNumber = (props: AnnotationPropType) => {
  const { annotation, count, index, kp, markSelected, objectIsInTimeline } = props;
  const isDisplayed = typeof annotation.isDisplayed === "undefined" || annotation.isDisplayed;
  const opacity = objectIsInTimeline ? 1 : isDisplayed ? 1 : 0.5;

  const display = () => {
    return (
      // <div>
      <svg
        viewBox="0 0 100 100"
        key={index}
        id={`squareNumber-${index}`}
        className="annotation"
        name="squareNumber"
        onMouseDown={(e: React.MouseEvent<SVGSVGElement>) => markSelected(e.currentTarget, index, "annotations")}
        onKeyDown={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyPress(e, kp)}
        onKeyUp={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyUp(e, kp)}
        preserveAspectRatio="xMinYMin meet"
        tabIndex={0}
        opacity={opacity}
        style={{
          top: `${annotation.top}%`,
          left: `${annotation.left}%`,
          width: `${annotation.width}%`,
          height: `${annotation.height}%`,
          zIndex: annotation.zIndex,
          transform: `rotate(${annotation?.transform?.rotate ? annotation?.transform?.rotate : 0}deg)`,
        }}
      >
        <rect
          x="1.5"
          y="1.5"
          height="97"
          width="97"
          stroke={`${annotation?.borderColor ? annotation.borderColor : "white"}`}
          strokeWidth={`${annotation.strokeWidth || "3"}`}
          fill={`${annotation?.backgroundColor ? annotation.backgroundColor : "#4B4F58"}`}
        ></rect>
        <text
          // style={{transform: 'translate(-2px, -1px)'}}
          className="step-text"
          x="50"
          y="50"
          dy="26.5"
          dx="0.0em"
          textAnchor="middle"
          fill={`${annotation?.fontColor ? annotation.fontColor : "white"}`}
        >
          {count}
        </text>
        <HighlightAnnotation annotation={annotation} id="glow-rect-for-square" />
      </svg>

      // </div>
    );
  };

  return <React.Fragment>{display()}</React.Fragment>;
};

export default AnnotationSquareNumber;
