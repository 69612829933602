import React, { ChangeEventHandler, createContext, PropsWithChildren, useContext, useRef, useState } from "react";
import { TextToSpeechModal } from "../../components/Modals/TextToSpeechModal";
import { TextToSpeechFormSchema } from "../../components/Modals/TextToSpeechModal/TextToSpeechForm";
import blobUrlFormatHelper from "../../components/blobUrlFormatHelper";
import {
  EInteractiveAudioType,
  IInteractiveAudio,
  LanguageType,
  NarrationAudio,
  OneShotAudio,
} from "../../models/IInteractiveAudio";
import type { TextToSpeechModalProps } from "../../components/Modals/TextToSpeechModal/types";
import { useAudioManagerStore } from "../PageAudioManager";
import { useAudioUpload } from "../../hooks/useAudioUpload";
import { useLessonPage } from "../LessonPagesProvider/LessonPagesProvider";
import { useShallow } from "zustand/react/shallow";
import { nanoid } from "../../lib/nanoId";

export enum AudioUploadTool {
  TTS,
  FILE,
}

export enum AudioUploadOperation {
  CREATE,
  REPLACE,
}

export type UploadParams = {
  audioType: EInteractiveAudioType;
  tool: AudioUploadTool;
  operation: AudioUploadOperation;
  languageType: LanguageType;
  parentAudio?: IInteractiveAudio;
  audio?: IInteractiveAudio;
  TTSData?: Partial<TextToSpeechFormSchema>;
};

interface AudioUploadState {
  upload: (params: UploadParams) => Promise<IInteractiveAudio | null>;
}

const AudioUploadContext = createContext<AudioUploadState>({} as AudioUploadState);

export function AudioUploadProvider({ children }: PropsWithChildren<unknown>) {
  const { pageManifest } = useLessonPage();
  const [audioType, setAudioType] = useState<EInteractiveAudioType>(EInteractiveAudioType.NARRATION);
  const [operationType, setOperationType] = useState<AudioUploadOperation>(AudioUploadOperation.CREATE);
  const [languageType, setLanguageType] = useState<LanguageType>(LanguageType.PRIMARY);
  const [parentAudioObjectId, setParentAudioObjectId] = useState<string | null>(null);
  const [audioObjectId, setAudioObjectId] = useState<string | null>(null);
  const [TTSFormData, setTTSFormData] = useState<Partial<TextToSpeechFormSchema> | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [replaceNarrationAudio, loadNarrationAudio] = useAudioManagerStore(
    useShallow((state) => [state.replaceNarrationAudio, state.loadNarrationAudio]),
  );
  const { upload } = useAudioUpload();

  const audioInputRef = useRef<HTMLInputElement>(null);
  const resolverReference = useRef<(audio: IInteractiveAudio | null) => void>();

  // HandlersIUploadAssetResponse
  const doAddAudio = async (
    audio: {
      blobPath: string;
      assetId: number;
    },
    audioProps?: Partial<OneShotAudio | NarrationAudio>,
  ) => {
    const result = await loadNarrationAudio(nanoid(), blobUrlFormatHelper(audio.blobPath), {
      title: `Audio ${audio.assetId.toString()}`,
      objectId: audio.assetId.toString(),
      input: blobUrlFormatHelper(audio.blobPath),
      start: 0,
      type: EInteractiveAudioType.NARRATION,
      language: languageType,
      ...(parentAudioObjectId ? { parentObjectId: parentAudioObjectId } : {}),
      ...audioProps,
    });

    resolverReference?.current?.(result);
  };

  const doReplaceAudio = async (
    file: {
      blobPath: string;
      assetId: number;
    },
    audioProps?: Partial<OneShotAudio | NarrationAudio>,
  ) => {
    if (!audioObjectId) {
      return;
    }

    const result = await replaceNarrationAudio(audioObjectId, {
      objectId: nanoid(),
      input: blobUrlFormatHelper(file.blobPath),
      start: 0,
      ...audioProps,
    });

    resolverReference?.current?.(result);
  };

  const handleBrowseFiles: ChangeEventHandler<HTMLInputElement> = async (event) => {
    const file = event.target.files?.[0];

    if (file) {
      void upload(file).then(async (uploadedFile) => {
        if (uploadedFile) {
          operationType === AudioUploadOperation.CREATE ? doAddAudio(uploadedFile) : doReplaceAudio(uploadedFile);
        }
      });
    }
  };

  const handleTTSAudio: TextToSpeechModalProps["onFinish"] = async ({ generatedAudio, ...ttsProps }) => {
    setIsModalOpen(false);

    if (!generatedAudio) {
      return;
    }

    operationType === AudioUploadOperation.CREATE
      ? doAddAudio(generatedAudio, { savedText: ttsProps.narrationText, ttsProps })
      : doReplaceAudio(generatedAudio, { savedText: ttsProps.narrationText, ttsProps });
  };

  // Context actions
  const context: AudioUploadState = {
    upload: ({ audioType, tool, operation, languageType, parentAudio, audio, TTSData }) => {
      setAudioType(audioType);
      setOperationType(operation);
      setLanguageType(languageType);
      setParentAudioObjectId(parentAudio?.objectId ?? null);
      setAudioObjectId(audio?.objectId ?? null);
      setTTSFormData(TTSData);

      if (tool === AudioUploadTool.TTS) {
        // Add data to params and set here
        // setTTSFormData()
        setIsModalOpen(true);
      } else {
        audioInputRef?.current?.click();
      }

      return new Promise<IInteractiveAudio | null>((resolve) => {
        resolverReference.current = resolve;
      });
    },
  };

  return (
    <AudioUploadContext.Provider value={context}>
      {children}
      {/*For file upload*/}
      <input ref={audioInputRef} type="file" onChange={handleBrowseFiles} className="hidden-input" accept="audio/*" />
      {/*For TTS*/}
      <TextToSpeechModal
        open={isModalOpen}
        onFinish={handleTTSAudio}
        data={{
          narrationText: pageManifest?.pageText,
          selections: pageManifest?.narrationAudios?.map((audio: NarrationAudio) => audio.savedText),
          ...TTSFormData,
        }}
        onClose={() => setIsModalOpen(false)}
      />
    </AudioUploadContext.Provider>
  );
}

export const useAudioDialog = (): AudioUploadState => useContext(AudioUploadContext);
