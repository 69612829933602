import React, { useRef, useEffect } from "react";
import "../CPaT3d_Player.css";
import { PovControls } from "./cameraControls";
import { extend, useThree, useFrame } from "@react-three/fiber";

extend({ PovControls });

const Controls = (props) => {
  const controls = useRef();
  const { camera, gl } = useThree();
  useFrame(() => controls.current.update());
  useEffect(() => {
    switch (props.role) {
      case "Captain": {
        camera.position.set(-180, 220, 50.1);
        break;
      }
      case "FO": {
        camera.position.set(180, 220, 50.1);
        break;
      }
      default: {
        camera.position.set(-180, 220, 50.1);
      }
    }
    camera.rotation.set(-0.2, -0.3, 0);
    camera.far = 5000;
    camera.near = 0.01;
    camera.fov = 75;
  }, []);
  return <povControls ref={controls} args={[camera, gl.domElement, props.role]} />;
};

export default Controls;
