import React, { useEffect, useState, useRef } from "react";
import playButton from "../../../assets/icons/lpld-play- icon.png";
import pauseButton from "../../../assets/icons/lpld-Pause- icon.png";
import blobUrlFormatHelper from "../../blobUrlFormatHelper";

interface audioProps {
  audioFile: string;
}
const AudioControls: React.FC<audioProps> = (props: audioProps) => {
  const audioSourceControl = useRef<HTMLAudioElement>(null);
  const audioProgressBar = useRef<HTMLDivElement>(null);
  const audioProgressPoint = useRef<HTMLDivElement>(null);

  const [audioTimeLength, setAudioTimeLength] = useState<number>(0);
  const [audioPlaying, setAudioPlaying] = useState<boolean>(false);
  const [audioCurrentTime, setAudioCurrentTime] = useState<number>(0);
  const [audioCompleteWidth, setAudioCompleteWidth] = useState<number>(0); //%complete
  const [manualProgress, setManualProgress] = useState<boolean>(false);

  const convertTimeToPercent = () => {
    const timePercent = (audioCurrentTime * 100) / audioTimeLength;
    setAudioCompleteWidth(timePercent);
  };

  const playHandler = () => {
    if (audioSourceControl.current) {
      audioPlaying ? audioSourceControl.current.play() : audioSourceControl.current.pause();
    }
  };
  const clickAudioPosition = (event: React.MouseEvent) => {
    if (audioProgressBar.current) {
      const newTimePercent = (event.nativeEvent.offsetX * 100) / audioProgressBar.current.clientWidth;
      setManualProgress(true);
      setAudioCompleteWidth(newTimePercent);
    }
  };
  const manualAudioProgress = () => {
    if (audioSourceControl.current) {
      audioSourceControl.current.pause();
      const convertedAudioTime = (audioCompleteWidth * audioTimeLength) / 100;
      audioSourceControl.current.currentTime = convertedAudioTime;
      audioSourceControl.current.play();
    }
  };

  useEffect(() => {
    if (audioSourceControl.current) {
      audioSourceControl.current.load();
    }
  }, []);
  useEffect(() => {
    if (!manualProgress) {
      convertTimeToPercent();
    }
  }, [audioCurrentTime]);
  useEffect(() => {
    if (manualProgress) {
      manualAudioProgress();
      setManualProgress(false);
    }
  }, [audioCompleteWidth]);
  useEffect(() => {
    playHandler();
  }, [audioPlaying]);

  return (
    <>
      <audio
        preload="auto"
        ref={audioSourceControl}
        onLoadedMetadata={(event: React.SyntheticEvent<HTMLAudioElement>) => {
          setAudioTimeLength(event.currentTarget.duration);
        }}
        onTimeUpdate={(event: React.SyntheticEvent<HTMLAudioElement>) => {
          setAudioCurrentTime(event.currentTarget.currentTime);
        }}
      >
        <source src={blobUrlFormatHelper(props.audioFile)} type="audio/mpeg" />
      </audio>
      <div className="audioContainer">
        <div className="audioBar">
          <img
            className="playerImage"
            src={audioPlaying ? pauseButton : playButton}
            onClick={() => {
              setAudioPlaying(!audioPlaying);
            }}
            alt={"audioControl"}
          ></img>
          <div className="progressDisplay">
            <div
              className="audioProgress"
              ref={audioProgressBar}
              onClick={clickAudioPosition}
              onDrag={clickAudioPosition}
            >
              <div
                className="progressPoint"
                ref={audioProgressPoint}
                style={{ marginLeft: `${audioCompleteWidth}%` }}
              ></div>
            </div>
            <div className="timeDisplay">
              <p className="playingTime modalText">{Math.floor(audioCurrentTime * 100) / 100}</p>
              <p className="fullDuration modalText">{Math.floor(audioTimeLength * 100) / 100}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AudioControls;
