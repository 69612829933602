import React from "react";
import "./AspectRatioImage.styles.css";
interface AspectRatioContainerProps {
  height?: number;
  width?: number;
  src: string;
  style?: React.CSSProperties | undefined;
  onClick?: ((event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void) | undefined;
  className?: string;
  hoverSelect?: boolean;
}
interface AbsoluteContainer {
  height: string;
  width: string;
}
/**
 * Creates a series of containers to maintain an Images original Aspect Ratio
 * starting with a <relative positioning container<absolute container< image>>>
 * The top level relative container is set to H:100% and W:100%:
 * this means that it will resize to its closest parent element,
 * which implies the parent element should be positioned and scale factor
 * exactly where and how you want the Image to be located
 *
 *
 * @param props.height  This is for finer control of your image HEIGHT while still maintaining ratio (within the parent component)
 * @param props.width   This is for finer control of your image WIDTH while still maintaining ratio (within the parent component)
 * @param props.src     img src tag
 * @param {React.CSSProperties | undefined} props.style   maps to the img tag style native props
 * @param {((event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void) | undefined} props.onClick
 * @param props.className     img src tag
 * @param {boolean} props.hoverSelect hovering select effect
 */
function AspectRatioImage({
  height = 100,
  width = 100,
  src,
  className,
  hoverSelect,
  onClick,
  style,
}: AspectRatioContainerProps) {
  const absoluteContainerStyle: AbsoluteContainer = {
    height: `${height}%`,
    width: `${width}%`,
  };

  const absoluteClassName = React.useMemo(() => {
    if (hoverSelect) {
      return "aspectRatio-image-absolute" + " image-select-hover";
    } else {
      return "aspectRatio-image-absolute";
    }
  }, [hoverSelect]);
  return (
    <div className="aspectRatio-image-relative">
      <div className={absoluteClassName} style={{ ...absoluteContainerStyle }}>
        <img className={className} style={{ ...(style ? style : undefined) }} src={src} onClick={onClick} />
      </div>
    </div>
  );
}

export default AspectRatioImage;
