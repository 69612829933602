import React from "react";
import { ICdu } from "./ICdu";

export interface IFmsContext {
  addModalDataToManifest: (modalData: any) => void;
  fetchCduModal: (modalName: string) => void;
  //currentCdu: any;
  currentCDU: ICdu;
  currentCDUPage: string;
  highlightedGroups: string[];
  keyList: string[];
  selectedKey: string | undefined;
  gridBorder: string;
  triggerReRender: boolean;
  editingCduScreen: boolean;
  setEditingCduScreen(value: boolean): void;
  toggleReRender(value: boolean): void;
  setGridBorder(value: string): void;
  setHighlights(value: string[]): void;
  criteriaIndex: number;
  setCriteriaIndex(value: number): void;
  checkHighlightingKeyWords: () => string[];
  cduPageToEdit: string;
  setCduPageToEdit(value: string): void;
  highlightFrom: string;
  setHighlightFrom(value: string): void;
  currentModal: any;
  setCurrentModal(value: any): void;
  showCduModal: boolean;
  setShowCduModal(value: boolean): void;
  getPageData: (pageId: string) => void;
  currentCDUPageData: any;
  setCurrentCDUPageData(value: any): void;
  cursorSelected: boolean;
  setCursorSelected(value: boolean): void;
  findCursorObject: (highlightedElement: string) => void;
  dropDownOpen: boolean;
  setDropDownOpen(value: boolean): void;
  dropDownValues: any;
  setDropDownValues(value: any): void;
  dropDownParent: any;
  setDropDownParent(value: any): void;
  holdingDisplayEditorShown: boolean;
  setHoldingDisplayEditorShown(value: boolean): void;
  imageToDisplayIndex: any;
  setImageToDisplayIndex(value: any): void;
}

export const FmsContext: React.Context<IFmsContext> = React.createContext<IFmsContext>({} as IFmsContext);
