import React from "react";
import { SVGAnnotationProps } from "../index";
import { HighlightAnnotation } from "../HighlightAnnotation";

export function SVGVOR({
  onMouseDown,
  objectId,
  y,
  x,
  width,
  height,
  isDisplayed,
  zIndex,
  borderColor,
  strokeWidth,
  backgroundColor,
  opacity,
  rotate,
}: // transform,
SVGAnnotationProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 6.29 5.4"
      key={objectId}
      id={`symbol-${objectId}`}
      data-objectid={objectId}
      tabIndex={0}
      className="symbol target"
      name="symbol"
      onMouseDown={(e: React.MouseEvent<SVGSVGElement>) => {
        onMouseDown(e, objectId);
      }}
      // onKeyDown={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyPress(e, kp)}
      // onKeyUp={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyUp(e, kp)}
      preserveAspectRatio="xMidYMid meet"
      style={{
        // top: `${y}px`,
        // left: `${x}px`,
        width: `${width}px`,
        height: `${height}px`,
        zIndex: zIndex,
        transform: `translate(${x}px, ${y}px) rotate(${rotate ?? 0}deg)`,
        opacity: isDisplayed ? opacity : 0.5,
        filter: isDisplayed ? "none" : "grayScale(100%)",
        // `rotate(${attributes?.transform?.rotate}deg) scaleX(${attributes?.transform?.scaleX}) scaleY(${attributes?.transform?.scaleY}) matrix3d(${attributes?.transform?.matrix3d})`
      }}
    >
      <HighlightAnnotation objectId={objectId} />
      <path
        // className="vor"
        stroke={`${borderColor || "white"}`}
        fill={`${backgroundColor || "transparent"}`}
        strokeWidth={`${strokeWidth / 10 || 0.1}`}
        d="M 1.78,0.25 H 4.36 L 5.99,2.77 4.41,5.16 H 1.78 L 0.29,2.7 Z"
      />
    </svg>
  );
}
