import { SmartObjectHotspot } from "./store";

type SVGElements = "svg" | "rect" | "path" | string;

export enum SVG_SMART_OBJECT_TYPES {
  IMAGE_SWAP = "IMAGE_SWAP",
  SWAP_VALUE = "SWAP_VALUE",
  HOTSPOT = "HOTSPOT",
  HOTSPOT_ONCLICK = "HOTSPOT_ONCLICK",
  ROTATE = "ROTATE",
  ROTATE_REF = "ROTATE_REF",
  FILL = "FILL",
  FILL_VALUE = "FILL_VALUE",
  DYNAMIC_TEXT = "DYNAMIC_TEXT",
  IMAGE_TEXT = "IMAGE_TEXT",
  FONT = "FONT",
}

export enum SVG_SMART_OBJECT_HOTSPOTS_ACTIONS {
  SET_LITERAL = "SET_LITERAL",
  TOGGLE_NEXT = "SET_INC",
  TOGGLE_PREVIOUS = "SET_DEC",
  SET_MOMENTARY = "SET_MOMENT", // (PUSH) / HOLD
  /** To be defined later  */
  INCREMENT = "INCREMENT",
  DECREMENT = "DECREMENT",
  HOLD_INCREMENT = "HOLD_INCREMENT",
  HOLD_DECREMENT = "HOLD_DECREMENT",
}

interface SvgJson {
  elementName: SVGElements;
  attributes: Record<string, string>;
  children: SvgJson[];
  textContent?: string;
}

export interface SmartObjectElement {
  type?: SVGElements;
  id?: string;
  children?: SmartObjectElement[];
  choices?: SmartObjectElement[];
  allowedValues?: string[];
  GROUP_ID?: string;
  ACTION?: string;
  HOTSPOT_ID?: string;
  VALUE?: string;
  TYPE?: string;
  FONT?: string;
  OPTION_JUSTIFY?: string;
  OPTION_STACK?: string;
  SET_ID?: string;
  RATE?: number;
  groupId?: string;
  value?: string;
  parentId?: string;
  styles?: string;
  style?: string;
  textContent?: string;
}

export function jsonFromSvgString(svgString: string): SvgJson {
  const parser = new DOMParser();
  const doc = parser.parseFromString(svgString, "image/svg+xml");
  const svgElement = doc.documentElement;
  return jsonFromSvgElement(svgElement);
}

export function jsonFromSvgElement(svgEl: Element): SvgJson {
  const jsonSvg: SvgJson = {
    elementName: svgEl.tagName,
    attributes: {},
    children: [],
  };
  for (const attribute of svgEl.attributes) {
    jsonSvg.attributes[attribute.name] = attribute.value;
  }
  for (const child of svgEl.children) {
    jsonSvg.children.push(jsonFromSvgElement(child));
  }

  if (svgEl.textContent) {
    jsonSvg.textContent = svgEl.textContent;
  }

  return jsonSvg;
}

const keywords = [
  /**
   * should be one of SVG_SMART_OBJECT_HOTSPOTS_ACTIONS
   */
  "ACTION",
  /**
   * is the GROUP_ID of the affected element
   */
  "SET_ID",
  /**
   * should be one of SVG_SMART_OBJECT_TYPES
   */
  "TYPE",
  /**
   * the id of the meta variable for an IMAGE_SWAP
   */
  "GROUP_ID",
  /**
   * The name that will be displayed on the right advanced panel
   * in the lesson designer
   */
  "NAME",
  /**
   * For hotspots the value that will be set on the group id that it targets
   * for SWAP_VALUE is the value that if its match it will render the image
   */
  "VALUE",
  /**
   * Custom orders for image swap, useful for hot spots and animations
   * to guarantee the correct order of functions like toggleNext and togglePrevious
   */
  "ORDER",
  /**
   * Boolean to have the element displayed or not by default
   */
  "DISPLAY",
  /**
   * if an IMAGE_SWAP has this attribute it will change the controls on the advanced panel
   * to select wether the group is animated or not
   */
  "ANIMATE",
  "ANIMATE_VALUE",
  "HIDE_VALUE",
  /**
   * Rate of the animation of an IMAGE_SWAP in milliseconds
   */
  "RATE",
  /**
   * For hotspots, it sets if the hotspot is enabled or not by default
   */
  "ENABLED",
  /**
   * For TYPE=ROTATE it determines the position of the element to rotate relative to a value
   * should have the following syntax:
   * [TYPE=ROTATE,ANGLES=0:0|40:-120|80:-240]
   * Where the `|` is used as a separator of pair values.
   * The left number of the `:` denotes the "value" and the right number of the `:` denotes
   * the degree of rotation.
   */
  "ANGLES",
  /**
   * Value of a child from TYPE=FILL
   */
  "FILL_VALUE",
  /**
   * Defines a font collection, for now only digits
   * the value should be a `FONT_NAME` thats found in a
   * collection TYPE=FONT
   */
  "FONT",
  /**
   * Defines a name for the font be referenced by another smart element
   */
  "FONT_NAME",
  /**
   * Value of the element Ex: FONT_CHAR=1
   */
  "FONT_CHAR",
  /**
   * Defines how to stack extra characters in the font
   */
  "OPTION_STACK",
  /**
   * Defines how to justify the text it should be either left or right
   */
  "OPTION_JUSTIFY",
] as const;

export function parseSvgJsonIntoSmartObjectStructure(svgJson: SvgJson, styles?: string[]) {
  /**
   * This counter is used to add style tags at the top of the SVG, usually within a <defs></defs> tag
   * It is implemented using a counter just in case more than one Style tag is included in the SVG
   * (with all the testing done, usually only one is included) so this might not be needed
   */
  let stylesCounter = 0;

  /**
   * This functions parses the `data-name` attribute that comes
   * in the svg from adobe illustrator, when adding names to the layers of the svg
   * that name gets inserted into both the ID and data-name, we're using that functionality
   * to insert metadata to parse and manipulate the SVG in the lesson editor
   */
  let hotspotCounter = 0;
  const parseDataName = (dataName: string, parentGroupId?: string | undefined, parentName?: string | undefined) => {
    const attributes: { [keY: string]: string } = {};
    const name = dataName.replaceAll("[", "").replaceAll("]", "");
    const regex = new RegExp(`(?:${keywords.join("|")})=([^,]+)`, "g");
    /**
     * This regex will parse the `data-name` attribute from the tags of the svg
     * it will look for a combination of one of the keywords listed above followed by an equals sign
     * and the value should end with a comma
     * Ex: [TYPE=HOTSPOT,ACTION=SET_LITERAL,SET_ID=SUPPLY_LEVER,VALUE=DOWN,DISPLAY=TRUE,ENABLED=TRUE]
     * That string will be converted into
     * {
     * "TYPE": "HOTSPOT",
     * "SET_ID": "SUPPLY_LEVER",
     * "VALUE": "UP",
     * "DISPLAY": "TRUE",
     * "ENABLED": "TRUE"
     * }
     */
    let match;

    while ((match = regex.exec(name)) !== null) {
      const [found, value] = match;
      const [key] = found.split("=");
      attributes[key] = value;
    }

    if (Object.keys(attributes).length > 0) {
      if (parentGroupId) {
        attributes["GROUP_ID"] = attributes["GROUP_ID"] ?? parentGroupId;
        // this is for hotspots
        attributes["SET_ID"] = attributes["SET_ID"] ?? parentGroupId;
      }

      if (parentName) {
        attributes["NAME"] = attributes["name"] ?? parentName;
      }

      if (attributes["TYPE"] === "HOTSPOT") {
        const hotSpotName = attributes["NAME"] ?? ++hotspotCounter;
        attributes["HOTSPOT_ID"] = `${attributes["SET_ID"]}-hotspot-${hotSpotName}`;
      }
    }

    return attributes;
  };

  const createElement = (json: SvgJson, parentGroupId?: string, parentName?: string): SmartObjectElement => {
    const { elementName, attributes, children, textContent } = json;
    let parsedDataName;
    let groupId = parentGroupId;
    let name = parentName;

    if (attributes["data-name"]) {
      parsedDataName = parseDataName(attributes["data-name"], parentGroupId);
      if (parsedDataName.GROUP_ID) {
        groupId = parsedDataName.GROUP_ID;
      }

      if (parsedDataName.NAME) {
        name = parsedDataName.NAME;
      }
    }

    switch (elementName) {
      case "svg":
        return {
          type: "svg",
          id: attributes.id,
          children: children.map((child) => createElement(child)),
          ...attributes,
          ...parsedDataName,
        };
      case "g": {
        return {
          type: "g",
          id: attributes.id,
          children: children.map((child) => createElement(child, groupId, name)),
          ...attributes,
          ...parsedDataName,
        };
      }
      case "path": {
        return {
          ...attributes,
          type: "path",
          id: attributes.id,
          children: children.map((child) => createElement(child, groupId, name)),
          ...parsedDataName,
        };
      }
      case "image": {
        return {
          ...attributes,
          id: attributes.id,
          type: "image",
          groupId: parentGroupId ?? undefined,
          ...parsedDataName,
        };
      }
      case "use": {
        return {
          ...attributes,
          id: attributes.id,
          type: "use",
          ...parsedDataName,
        };
      }
      case "style": {
        const style = !!styles && styles[stylesCounter] ? styles[stylesCounter] : undefined;
        stylesCounter++;

        return {
          ...attributes,
          id: attributes.id,
          type: elementName,
          children: children.map((child) => createElement(child, groupId, name)),
          styles: style,
        };
      }

      case "text":
      case "tspan": {
        return {
          ...attributes,
          id: attributes.id,
          type: elementName,
          children: children.map((child) => createElement(child, groupId, name)),
          textContent: textContent,
          ...parsedDataName,
        };
      }

      case "circle":
      case "rect":
      case "line":
      case "polyline":
      case "polygon":
      case "defs": {
        return {
          ...attributes,
          id: attributes.id,
          type: elementName,
          children: children.map((child) => createElement(child, groupId, name)),
          ...parsedDataName,
        };
      }
      default:
        throw new Error(`Unknown element name ${elementName}`);
    }
  };

  const createElementList = (json: SvgJson): SmartObjectElement[] => {
    const elementList: SmartObjectElement[] = [];
    elementList.push(createElement(json));
    return elementList;
  };

  const elementList = createElementList(svgJson);

  return elementList;
}

export const svgFileToString = (file: File) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject("No file provided");
    }

    const reader = new FileReader();
    reader.onload = (e) => resolve(e?.target?.result);
    reader.onerror = () => reject("Error reading the file");
    reader.readAsText(file);
  });
};

export const parseSvgFile = async (file: File) => {
  const string = await svgFileToString(file);
  if (typeof string !== "string") {
    throw new Error("could not parsed svg file");
  }
  const parsedString = string.replace(/&amp;quot;/g, "'");
  const styles = getStylesFromSvgString(parsedString);
  const json = jsonFromSvgString(parsedString);
  const smartObject = parseSvgJsonIntoSmartObjectStructure(json, styles);

  return convertObjectIntoJsonFile(smartObject, "smartObject");
};

export const convertObjectIntoJsonFile = (data: unknown, name: string) => {
  const string = JSON.stringify(data);
  const blob = new Blob([string], { type: "application/json" });
  const file = new File([blob], `${name}.json`, { type: "application/json" });

  return file;
};

export const getStylesFromSvgString = (svg: string) => {
  // Create a temporary div element
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = svg;

  // Get all <style> tags within the temporary div
  const styleTags = tempDiv.getElementsByTagName("style");
  const parsedStyles: string[] = [];

  // Loop through each <style> tag
  for (let i = 0; i < styleTags.length; i++) {
    const styleContent = styleTags[i].textContent;
    if (typeof styleContent === "string") {
      const minifiedStyle = styleContent.replace(/\n/g, "").replace(/\s+/g, " ");
      parsedStyles.push(minifiedStyle);
    }
  }

  return parsedStyles;
};

export const getVariablesFromSmartObject = (smartObject: any, objectId: string) => {
  const variables: Record<string, any> = {};
  const variablesData: Record<string, any> = {};
  const fonts: Record<string, any> = {};

  const getAttributes = (smartObject: any) => {
    const {
      TYPE,
      GROUP_ID,
      VALUE,
      ORDER,
      NAME,
      DISPLAY,
      ACTION,
      ANIMATE,
      ANIMATE_VALUE,
      HIDE_VALUE,
      RATE,
      SET_ID,
      ENABLED,
      HOTSPOT_ID,
      ANGLES,
      FONT,
      FONT_NAME,
      OPTION_STACK,
      OPTION_JUSTIFY,
      children,
    } = smartObject;

    switch (TYPE) {
      case SVG_SMART_OBJECT_TYPES.IMAGE_SWAP: {
        const key = `${objectId}-${GROUP_ID}`;

        if (variables[key] === undefined) {
          variables[key] = null;
        }

        if (variablesData[key] === undefined) {
          variablesData[key] = {
            type: SVG_SMART_OBJECT_TYPES.IMAGE_SWAP,
            name: NAME ?? GROUP_ID,
            choices: [],
            hotspots: [],
            animate: parseStringAsBoolean(ANIMATE_VALUE) ?? false,
            isAnimating: false,
            hide: parseStringAsBoolean(HIDE_VALUE) ?? false,
            rate: RATE ? Number(RATE) : 500,
            ...variablesData[key],
          };
        } else {
          /**
           * This is when a hotspot initializes the IMAGE_SWAP meta data key
           * in the variables, so we keep the values and override the ones that are falsey.
           * with the initial state
           */
          variablesData[key].name = variablesData[key].name ?? NAME ?? GROUP_ID;
          variablesData[key].choices = variablesData[key].choices ?? [];
          variablesData[key].hotspots = variablesData[key].hotspots ?? [];
          variablesData[key].animate = parseStringAsBoolean(ANIMATE_VALUE) ?? false;
          variablesData[key].hide = parseStringAsBoolean(HIDE_VALUE) ?? false;
          variablesData[key].rate = RATE ? Number(RATE) : 500;
        }

        break;
      }
      case SVG_SMART_OBJECT_TYPES.SWAP_VALUE: {
        const key = `${objectId}-${GROUP_ID}`;

        /**
         * Adding the value to the possible choices of the IMAGE_SWAP
         * Might be a good idea to add validation for duplicate
         *
         * If the meta variables is null we assign this choice as the
         * "default" value
         */
        if (variablesData[key] && VALUE) {
          variablesData[key]?.choices.push(VALUE);
          if (variables[key] === null) variables[key] = VALUE;

          if (ORDER) {
            if (!variablesData[key].order) {
              variablesData[key].order = {} as Record<string, string>;
            }

            variablesData[key].order[VALUE] = ORDER;
          }
        }
        break;
      }
      case SVG_SMART_OBJECT_TYPES.HOTSPOT: {
        const targetKey = `${objectId}-${SET_ID}`;
        const key = `${objectId}-${HOTSPOT_ID}`;
        const enabledKey = `${key}-enabled`;
        const displayKey = `${key}-display`;

        variables[enabledKey] = parseStringAsBoolean(ENABLED) ?? false;
        variables[displayKey] = parseStringAsBoolean(DISPLAY) ?? false;

        const hotspotData: SmartObjectHotspot = {
          action: ACTION ?? SVG_SMART_OBJECT_HOTSPOTS_ACTIONS.SET_LITERAL,
          type: SVG_SMART_OBJECT_TYPES.HOTSPOT,
          setId: SET_ID,
          value: VALUE ?? undefined,
          target: targetKey,
          enabledKey,
          displayKey,
          id: key,
          name: NAME ?? HOTSPOT_ID,
        };

        /**
         * Depending on how the smart object is built in
         * Adobe Illustrator we could parse a hotspot
         * before parsing the IMAGE_SWAP where the hotspot belongs
         */
        if (variablesData[targetKey] === undefined) {
          variablesData[targetKey] = {
            type: SVG_SMART_OBJECT_TYPES.IMAGE_SWAP,
            name: undefined, // should be filled out when parsing the image swap
            choices: [],
            hotspots: [],
            animate: parseStringAsBoolean(ANIMATE_VALUE) ?? false,
            isAnimating: false,
            hide: parseStringAsBoolean(HIDE_VALUE) ?? false,
            rate: RATE ? Number(RATE) : 500,
            ...variablesData[targetKey],
          };
        }

        if (!variablesData[targetKey]?.hotspots) {
          variablesData[targetKey].hotspots = [hotspotData];
        } else {
          variablesData[targetKey]?.hotspots.push(hotspotData);
        }

        break;
      }
      case SVG_SMART_OBJECT_TYPES.ROTATE: {
        const key = `${objectId}-${GROUP_ID}`;

        if (!ANGLES && typeof ANGLES !== "string") {
          throw `"ANGLES" property is missing for Smart Object Type ${SVG_SMART_OBJECT_TYPES.ROTATE}`;
        }

        const values: number[] = [];
        const degrees: number[] = [];
        let minValue = 0;
        let maxValue = 0;
        let minDegree = 0;
        let maxDegree = 0;

        const angles = ANGLES.split("|");

        angles.forEach((angle: string) => {
          const [v, d] = angle.split(":");
          const value = Number(v);
          /**
           * This negative 1 multiplication is because Adobe Illustrator
           * uses negative numbers for clockwise rotation, but it seems
           * that the property `transform=rotate` uses positive numbers instead
           */
          const degree = Number(d) * -1;

          if (Number.isNaN(value) || Number.isNaN(degree)) {
            throw "ANGLES is not formatted properly as 'value':'degree' (should only be numbers)";
          }

          if (value < minValue) {
            minValue = value;
          } else if (value > maxValue) {
            maxValue = value;
          }

          if (degree < minDegree) {
            minDegree = degree;
          } else if (degree > maxDegree) {
            maxDegree = degree;
          }

          values.push(value);
          degrees.push(degree);
        });

        if (variables[key] === undefined) {
          variables[key] = minValue;
        }

        if (variablesData[key] === undefined) {
          variablesData[key] = {
            type: SVG_SMART_OBJECT_TYPES.ROTATE,
            name: NAME ?? GROUP_ID,
            minValue,
            maxValue,
            minDegree,
            maxDegree,
          };
        }

        break;
      }
      case SVG_SMART_OBJECT_TYPES.FILL: {
        const key = `${objectId}-${GROUP_ID}`;

        variables[key] = null;

        variablesData[key] = {
          type: SVG_SMART_OBJECT_TYPES.FILL,
          name: NAME ?? GROUP_ID,
          choices: [],
          colors: {},
        };

        break;
      }
      case SVG_SMART_OBJECT_TYPES.FILL_VALUE: {
        const key = `${objectId}-${GROUP_ID}`;
        const colors: Record<string, string> = {};
        const currentChoices = variablesData[key].choices ?? [];

        const choices: string[] = [];
        const pairValues = VALUE.split("|");

        for (const pair of pairValues) {
          const [value, color] = pair.split(":");

          if (currentChoices.includes(value)) {
            continue;
          }

          if (!variables[key]) {
            variables[key] = value;
          }

          choices.push(value);
          colors[value] = color;
        }

        if (choices.length > 0) {
          variablesData[key] = {
            ...variablesData[key],
            choices,
            colors,
          };
        }

        break;
      }
      case SVG_SMART_OBJECT_TYPES.DYNAMIC_TEXT: {
        const key = `${objectId}-${GROUP_ID}`;

        if (variables[key] === undefined) {
          variables[key] = null;
        }

        if (variablesData[key] === undefined) {
          variablesData[key] = {
            type: SVG_SMART_OBJECT_TYPES.DYNAMIC_TEXT,
            name: NAME ?? GROUP_ID,
            hide: false,
            ...variablesData[key],
          };
        }

        break;
      }
      case SVG_SMART_OBJECT_TYPES.IMAGE_TEXT: {
        const key = `${objectId}-${GROUP_ID}`;

        if (variables[key] === undefined) {
          variables[key] = null;
        }

        if (variablesData[key] === undefined) {
          variablesData[key] = {
            type: SVG_SMART_OBJECT_TYPES.IMAGE_TEXT,
            name: NAME ?? GROUP_ID,
            font: FONT,
            stack: OPTION_STACK ?? ".",
            justify: OPTION_JUSTIFY ?? "left",
            ...variablesData[key],
          };
        }

        break;
      }

      case SVG_SMART_OBJECT_TYPES.FONT: {
        const key = FONT_NAME;
        const characters: Record<string, any> = {};

        for (const char of smartObject.children) {
          const label = char.FONT_CHAR.replaceAll("'", "");
          characters[label] = char;
        }

        if (fonts[key] === undefined) {
          fonts[key] = {
            type: SVG_SMART_OBJECT_TYPES.FONT,
            name: FONT_NAME,
            characters,
            ...variablesData[key],
          };
        }

        break;
      }

      default: {
        if (TYPE) {
          console.warn("getVariablesFromSmartObject: TYPE not handled ", TYPE);
        }
        break;
      }
    }

    if (children) {
      children.forEach((child: any) => getAttributes(child));
    }
  };

  getAttributes(smartObject);

  return {
    variables,
    variablesData,
    fonts,
  };
};

const parseStringAsBoolean = (str: string): boolean | null => {
  if (!str || typeof str !== "string") return null;

  if (str.toLowerCase() === "true") return true;
  if (str.toLowerCase() === "false") return false;

  return true;
};
