import React from "react";
import { handleKeyUp } from "../../../../../components/ObjectPropertyBox/functions/PropertyBoxFunctions";
import { SCORMPropType } from "../models/ICustomPage";
import { ReactComponent as InteractiveDiagramIcon } from "../../../../../assets/icons/AssetManager/interactive-diagram-icon.svg";
import { ObjectActionsType, useObjectsDispatch } from "../../../../../contexts/ObjectsProvider";
import {
  InteractivityHotspotActionTypes,
  useInteracitvityHotspotDispatch,
} from "../../../../../contexts/InteractivityHotspotProvider";
import { useObjectPosition } from "../../../../../hooks/useObjectPosition";
const { SET_CURRENT_HOTSPOT } = InteractivityHotspotActionTypes;

const BaseFFSCORM = (props: SCORMPropType) => {
  const hotspotsDispatch = useInteracitvityHotspotDispatch();
  const objectsDispatch = useObjectsDispatch();
  const {
    blobPath,
    ffElement,
    index,
    kp,
    scormRef,
    target,
    handleSCORMClick,
    handleKeyPress,
    loadSCORMObject,
    selectTarget,
  } = props;
  const objectId = ffElement?.objectId;
  const {
    position: [x, y],
    size: [w, h],
  } = useObjectPosition(objectId, ffElement?.top, ffElement?.left, ffElement?.width, ffElement?.height);
  // const rends = React.useRef(0);
  const transformString = `translate(${x}px, ${y}px) rotate(${ffElement?.transform?.rotate ?? 0}deg)`;

  if (blobPath.length === 0) {
    return (
      <div
        className="empty-scorm target ff-scorm"
        onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
          e.stopPropagation();
          hotspotsDispatch({
            type: SET_CURRENT_HOTSPOT,
            payload: null,
          });
          if (!objectId) return;
          let type = ObjectActionsType.SET_SELECTED_OBJECT;
          if (e.ctrlKey) {
            type = ObjectActionsType.ADD_SELECTED_OBJECT;
          }
          objectsDispatch({
            type,
            payload: { objectId },
          });
        }}
        onDoubleClick={() => handleSCORMClick()}
        data-objectid={objectId}
        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => handleKeyPress(e, kp)}
        onKeyUp={(e: React.KeyboardEvent<HTMLDivElement>) => handleKeyUp(e, kp)}
        tabIndex={0}
        style={{
          // top: `${y}px`,
          // left: `${x}px`,
          transform: transformString,
          width: `${w}px`,
          height: `${h}px`,
          zIndex: ffElement?.zIndex,
          color: `${ffElement?.fontColor}`,
          fontStyle: `${ffElement?.fontStyle}`,
          fontWeight: ffElement?.fontWeight,
          textDecoration: `${ffElement?.textDecoration}`,
          backgroundColor: `${ffElement?.backgroundColor}`,
          border: `${ffElement?.borderColor}`,
          boxShadow: `${ffElement?.shadowColor}`,
        }}
      >
        <InteractiveDiagramIcon style={{ transform: "scale(2)" }} />
      </div>
    );
  } else {
    return (
      <div
        id={`scorm-target`}
        // data-objectid={objectId}
      >
        <iframe title={"API_1484_11"} style={{ display: "none" }} src="/REF_API_FAKE_1484_11.html" name="API_1484_11" />
        <div
          id={`ff-scorm-main`}
          className="target ff-scorm"
          data-objectid={objectId}
          tabIndex={0}
          onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            hotspotsDispatch({
              type: SET_CURRENT_HOTSPOT,
              payload: null,
            });
            if (!objectId) return;
            let type = ObjectActionsType.SET_SELECTED_OBJECT;
            if (e.ctrlKey) {
              type = ObjectActionsType.ADD_SELECTED_OBJECT;
            }
            objectsDispatch({
              type,
              payload: { objectId },
            });
          }}
          style={{
            transform: transformString,
            width: `${w}px`,
            height: `${h}px`,
            // border: "1px solid black",
            zIndex: ffElement?.zIndex,
            color: `${ffElement?.fontColor}`,
            fontStyle: `${ffElement?.fontStyle}`,
            fontWeight: ffElement?.fontWeight,
            textDecoration: `${ffElement?.textDecoration}`,
            backgroundColor: `${ffElement?.backgroundColor}`,
            border: `${ffElement?.borderColor}`,
            boxShadow: `${ffElement?.shadowColor}`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <iframe
            ref={scormRef}
            title={"scorm-viewer"}
            // onDoubleClick={() => handleVideoClick()}
            // onKeyDown={(e: React.KeyboardEvent<any>) => handleKeyPress(e, kp)}
            // onKeyUp={(e: React.KeyboardEvent<HTMLVideoElement>) => handleKeyUp(e, kp)}
            src={"../../api/Asset/" + props.blobPath + "/index.html"}
            style={{
              pointerEvents: "none",
              height: "98%",
              width: "98%",
              // border: "1px solid red",
            }}
            onLoad={(e: React.SyntheticEvent<HTMLIFrameElement>) => loadSCORMObject(e, index)}
          ></iframe>
        </div>
      </div>
    );
  }
};

export default BaseFFSCORM;
