import "./BaseWYSIWYG.css";
import "../assets/WYSIWYG.css";
import React, { useContext } from "react";
import { IPageContext, PageContext, IAssetManagementContext, AssetContext } from "../../../../routes/builderContexts";
import IBasePage from "./components/IBasePage";
import SGR from "./components/SGR";
import SGL from "./components/SGL";
import SGC from "./components/SGC";
import SGC2 from "./components/SGC2";

import DGSV from "./components/DGSV";
import DGSH from "./components/DGSH";
import DGSHT from "./components/DGSHT";
import DGSHT2 from "./components/DGSHT2";
import TGSL from "./components/TGSL";
import TGSR from "./components/TGSR";
import TGST from "./components/TGST";
import TGSB from "./components/TGSB";
import FGS from "./components/FGS";
import FGST from "./components/FGST";

import FPG from "./components/FPG";
import DGS from "./components/DGS";
import DGSR from "./components/DGSR";
import TO from "./components/TO";
import TestDisclaimer from "./components/TestDisclaimer";
import Introduction from "./components/Introduction";
import Objective from "./components/Objective";
import Summaryplayer from "./components/Summaryplayer";

export type BaseWYSIWYGType = {
  pageManifest: IBasePage;
};

const BaseWYSIWYG = (props: BaseWYSIWYGType) => {
  const { pageManifest } = props;
  let component: JSX.Element = <React.Fragment></React.Fragment>;

  switch (pageManifest.basePageType) {
    case "singleGraphicRight":
      component = <SGR pageManifest={pageManifest} />;
      break;
    case "singleGraphicLeft":
      component = <SGL pageManifest={pageManifest} />;
      break;
    case "singleGraphicCenter":
      component = <SGC pageManifest={pageManifest} />;
      break;
    case "fullPageGraphic":
      component = <FPG pageManifest={pageManifest} />;
      break;
    case "doubleGraphicsSplit":
      component = <DGS pageManifest={pageManifest} />;
      break;
    case "doubleGraphicSplitRight":
      component = <DGSR pageManifest={pageManifest} />;
      break;
    //new base pages

    case "singleGraphicCenter2":
      component = <SGC2 pageManifest={pageManifest} />;
      break;
    case "doubleGraphicSplitVert":
      component = <DGSV pageManifest={pageManifest} />;
      break;
    case "doubleGraphicSplitHoriz":
      component = <DGSH pageManifest={pageManifest} />;
      break;
    case "doubleGraphicSplitHorizText":
      component = <DGSHT pageManifest={pageManifest} />;
      break;
    case "doubleGraphicSplitHorizText2":
      component = <DGSHT2 pageManifest={pageManifest} />;
      break;
    case "TrippleGraphicSplitLeft":
      component = <TGSL pageManifest={pageManifest} />;
      break;
    case "TrippleGraphicSplitRight":
      component = <TGSR pageManifest={pageManifest} />;
      break;
    case "TrippleGraphicSplitTop":
      component = <TGST pageManifest={pageManifest} />;
      break;
    case "TrippleGraphicSplitBottom":
      component = <TGSB pageManifest={pageManifest} />;
      break;
    case "FourGraphicSplit":
      component = <FGS pageManifest={pageManifest} />;
      break;
    case "FourGraphicSplitText":
      component = <FGST pageManifest={pageManifest} />;
      break;

    case "textOnly":
      component = <TO pageManifest={pageManifest} />;
      break;
    case "warning":
      component = <TestDisclaimer pageManifest={pageManifest} />;
      break;
    case "introduction":
      component = <Introduction pageManifest={pageManifest} />;
      break;
    case "objective":
    case "objectives":
      component = <Objective pageManifest={pageManifest} />;
      break;
    case "summary":
    case "conclusion":
      component = <Summaryplayer pageManifest={pageManifest} />;
      break;
    // case "freeForm":
    //   component = <FreeForm pageManifest={pageManifest} handleImageDivClick={handleImageDivClick} />
    //   break;
    default:
      component = <div>ERROR NO VALID PAGE TYPE</div>;
  }

  return <React.Fragment>{component}</React.Fragment>;
};

export default BaseWYSIWYG;
