import React from "react";

interface DropDownProps {
  className?: string;
  childrenClassName?: string;
  styles?: { [key: string]: string };
  readonly: boolean;
  children?: React.ReactNode;
}

export const Dropdowns = ({ className, styles, readonly, children }: DropDownProps) => {
  return (
    <div className={className ?? ""} style={{ ...styles }}>
      {children}
    </div>
  );
};

export default Dropdowns;
