export interface IUrlService {
  getUrlWithoutHostOrProtocol(): string;
  getUrlHost(): string;
}

const urlService: IUrlService = {
  getUrlHost: (): string => {
    return window.location.host;
  },
  getUrlWithoutHostOrProtocol: (): string => {
    return window.location.href.substring(
      window.location.href.indexOf(window.location.host) + window.location.host.length,
    );
  },
};

export default urlService;
