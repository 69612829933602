import "./PageEditor.css";
import _ from "lodash";
import React, { useContext } from "react";
import { BasicPagePlayer } from "../pageTypes/BasicPage_Player/BasePageDesigner";
import CPaT3dPlayer from "../pageTypes/CPaT3d_Player/CPaT3d_Player";
import FmsDesigner from "../pageTypes/FMS_Player/fmsDesigner";
import QuizPlayer from "../pageTypes/Quiz_Player/Quiz_Editor";
import WalkAroundPlayer from "../pageTypes/WalkAround/WalkAroundPlayer";
import CabinPlayer from "../pageTypes/Cabin/CabinPlayer";
import TitlePlayer from "../pageTypes/Title_Player/BaseTitlePage";
import ExitPlayer from "../pageTypes/Exit_Player/BaseExitPage";
import ScorepagePlayer from "../pageTypes/Score_Player/ScorePage";
import { IPageContext, PageContext } from "../routes/builderContexts";
import {
  ThreeD_PAGE,
  QUIZ_PAGE,
  BASIC_PAGE,
  FMS_PAGE,
  TITLE_PAGE,
  EXIT_PAGE,
  SCORE_PAGE,
  WALK_AROUND,
  CABIN,
} from "./constants/pageTypes";
import DefaultEditor from "../pageTypes/defaultEditor/defaultEditor";
import ILessonMetaData from "../models/ILessonMetaRequest";
import { useLessonData } from "../contexts/LessonDataProvider";
import PageEditorErrorBoundary from "./PageEditorErrorBoundary";
type EditorType = {
  assetManagerProps: any;
  lessonMetaData: ILessonMetaData;
  lessonName: string;
  setLessonMetaData: (data: ILessonMetaData) => void;
  permeateLessonName: (data: string) => void;
  isSaved: boolean;
  pageIndex: number; // this is for simpler error identification in logs
};

const PageEditor: React.FC<EditorType> = (props: EditorType) => {
  const { pageType, pageId }: IPageContext = useContext<IPageContext>(PageContext);
  const permissions = useLessonData({ permissions: true });
  const canEdit = Object.entries(permissions).length !== 0 ? permissions.canEdit(pageType) : false;

  let PageTypeComponent: JSX.Element = <div />;
  switch (pageType) {
    case BASIC_PAGE:
      PageTypeComponent = canEdit ? (
        <BasicPagePlayer key={pageId} lessonMetaData={props.lessonMetaData} />
      ) : (
        <NoAccess />
      );
      break;
    case ThreeD_PAGE:
      PageTypeComponent = canEdit ? (
        <CPaT3dPlayer key={pageId} assetManagerProps={props.assetManagerProps} />
      ) : (
        <NoAccess />
      );
      break;
    case FMS_PAGE:
      PageTypeComponent = canEdit ? <FmsDesigner key={pageId} /> : <NoAccess />;
      break;
    case QUIZ_PAGE:
      PageTypeComponent = canEdit ? <QuizPlayer key={pageId} /> : <NoAccess />;

      break;
    case TITLE_PAGE:
      PageTypeComponent = (
        <TitlePlayer
          key={pageId}
          lessonMetaData={props.lessonMetaData}
          setLessonMetaData={props.setLessonMetaData}
          permeateLessonName={props.permeateLessonName}
          lessonName={props.lessonName}
        />
      );
      break;
    case EXIT_PAGE:
      PageTypeComponent = (
        <ExitPlayer key={pageId} lessonMetaData={props.lessonMetaData} setLessonMetaData={props.setLessonMetaData} />
      );
      break;
    case SCORE_PAGE:
      PageTypeComponent = <ScorepagePlayer />;
      break;
    case WALK_AROUND:
      PageTypeComponent = canEdit ? (
        <WalkAroundPlayer
          lessonMetaData={props.lessonMetaData}
          key={pageId}
          assetManagerProps={props.assetManagerProps}
          isSaved={props.isSaved}
        />
      ) : (
        <NoAccess />
      );
      break;
    case CABIN:
      PageTypeComponent = canEdit ? (
        <CabinPlayer
          lessonMetaData={props.lessonMetaData}
          key={pageId}
          assetManagerProps={props.assetManagerProps}
          isSaved={props.isSaved}
        />
      ) : (
        <NoAccess />
      );
      break;
    default:
      PageTypeComponent = <div />;
      break;
  }
  const identifyinginfo = {
    pageType: pageType,
    pageIndex: props.pageIndex,
    pageVersionId: pageId,
    lessonversionid: props.lessonMetaData.lessonVersionId,
    lessonname: props.lessonMetaData.lessonName,
  };
  PageTypeComponent = (
    <PageEditorErrorBoundary key={pageId} identifyingInfo={identifyinginfo}>
      {PageTypeComponent}
    </PageEditorErrorBoundary>
  );
  return <div id="designBoxWrapper">{PageTypeComponent}</div>;
};

const NoAccess = () => {
  return (
    <div className="can-not-edit-page">
      <p>You do not have permission to edit this page type</p>
    </div>
  );
};
export default PageEditor;
