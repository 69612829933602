import React, { ChangeEventHandler, useRef, useState } from "react";
import { FieldTypes, PropertyConfig } from "../PropertiesPanel/types";
import { TimelineAudioState } from "../../contexts/PageAudioManager/types";
import { EInteractiveAudioType, LanguageType } from "../../models/IInteractiveAudio";
import { useAudioManagerStore } from "../../contexts/PageAudioManager";
import { PropertiesPanel } from "../PropertiesPanel/PropertiesPanel";
import { TextToSpeechModal } from "../../components/Modals/TextToSpeechModal";
import blobUrlFormatHelper from "../../components/blobUrlFormatHelper";
import { useAudioUpload } from "../../hooks/useAudioUpload";

import type { TextToSpeechModalProps } from "../../components/Modals/TextToSpeechModal/types";

import { renderTimeLength } from "./helpers";
import { AudioActions } from "./AudioActions";
import { useConfirmation } from "../../contexts/Confirmation";
import { getSecondary } from "../../contexts/PageAudioManager/helpers";
import { audioBlobToApi } from "../TimelinePanel/helpers";
import { nanoid } from "../../lib/nanoId";

const audioPanelStructure: PropertyConfig<TimelineAudioState> = [
  {
    identifier: "input",
    type: FieldTypes.AUDIO,
    label: "Audio File",
    readOnly: true,
  },
  {
    identifier: "title",
    type: FieldTypes.TEXT,
    label: "Object Name",
  },
  {
    identifier: "duration",
    type: FieldTypes.DISPLAY,
    label: "Narration Audio Length",
    render: (audio) => {
      const length = audio.duration ?? audio.end - audio.start;

      return renderTimeLength(length);
    },
  },
  {
    identifier: "savedText",
    type: FieldTypes.TEXTAREA,
    className: "audio-narration-textarea",
    label: "Narration Text",
    visible: (audio) => {
      return audio.type === EInteractiveAudioType.NARRATION;
    },
  },
];
export const AudioPropertiesPanel = () => {
  const audioInputRef = useRef<HTMLInputElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [
    selectedAudio,
    selectAudio,
    removeNarrationAudio,
    replaceNarrationAudio,
    updateNarrationAudio,
    loadNarrationAudio,
    setIsDirty,
  ] = useAudioManagerStore((state) => [
    state.getSelectedAudio(),
    state.selectAudio,
    state.removeNarrationAudio,
    state.replaceNarrationAudio,
    state.updateNarrationAudio,
    state.loadNarrationAudio,
    state.setIsDirty,
  ]);
  const secondaryAudio = useAudioManagerStore((state) =>
    state.selectedAudio ? getSecondary(state.selectedAudio, state.narrationAudios) : null,
  );

  const { upload } = useAudioUpload();
  const { confirm } = useConfirmation();

  // Handlers
  const handleOnChange = (identifier: string, value: unknown) => {
    if (selectedAudio?.objectId) {
      updateNarrationAudio(selectedAudio.objectId, {
        [identifier]: value,
      });

      setIsDirty(true);
    }
  };

  const handleUpload = () => {
    audioInputRef?.current?.click();
  };

  const handleTextToSpeech = () => {
    setIsModalOpen(true);
  };

  const handleRemoveAudio = async () => {
    const shouldRemove = await confirm({
      title: "Remove Audio",
      message: "Are you sure you want to delete this audio from the timeline?",
    });

    if (shouldRemove && selectedAudio) {
      removeNarrationAudio(selectedAudio.objectId);
      setIsDirty(true);
    }
  };

  const handleBrowseFiles: ChangeEventHandler<HTMLInputElement> = async (event) => {
    const file = event.target.files?.[0];

    if (file) {
      void upload(file).then(async (uploadedFile) => {
        selectedAudio &&
          uploadedFile &&
          replaceNarrationAudio(selectedAudio.objectId, {
            objectId: uploadedFile.assetId.toString(),
            input: blobUrlFormatHelper(uploadedFile.blobPath),
            start: selectedAudio.start,
          });
        setIsDirty(true);
      });
    }
  };
  const handleTTSAudio: TextToSpeechModalProps["onFinish"] = async ({ generatedAudio, ...ttsProps }) => {
    setIsModalOpen(false);

    if (!generatedAudio) {
      return;
    }

    selectedAudio &&
      replaceNarrationAudio(selectedAudio.objectId, {
        objectId: generatedAudio.assetId.toString(),
        input: blobUrlFormatHelper(generatedAudio.blobPath),
        start: selectedAudio.start,
        ttsProps,
      });
    setIsDirty(true);
  };

  if (selectedAudio) {
    return (
      <>
        <PropertiesPanel<TimelineAudioState>
          identifier={selectedAudio.objectId}
          data={selectedAudio}
          config={audioPanelStructure}
          onChange={handleOnChange}
          actionsSlot={
            <AudioActions
              audio={selectedAudio}
              haSecondaryAudio={!!secondaryAudio}
              onUpload={handleUpload}
              onTextToSpeech={handleTextToSpeech}
              onRemove={handleRemoveAudio}
              onChangeLanguage={(language) => {
                if (language === LanguageType.SECONDARY && secondaryAudio) {
                  selectAudio(secondaryAudio.objectId);
                } else if (language === LanguageType.PRIMARY && selectedAudio?.parentObjectId) {
                  selectAudio(selectedAudio.parentObjectId);
                }
              }}
              onCreateSecondary={async () => {
                const temporaryAudioId = nanoid();

                await loadNarrationAudio(temporaryAudioId, audioBlobToApi(selectedAudio.input), {
                  ...selectedAudio,
                  loading: true,
                  ready: false,
                  objectId: temporaryAudioId,
                  parentObjectId: selectedAudio.objectId,
                  language: LanguageType.SECONDARY,
                  title: `Cloned from ${selectedAudio.title ?? selectedAudio.objectId}`,
                });

                selectAudio(temporaryAudioId);
              }}
            />
          }
        />
        <input ref={audioInputRef} type="file" onChange={handleBrowseFiles} className="hidden-input" accept="audio/*" />
        <TextToSpeechModal
          onFinish={handleTTSAudio}
          onClose={() => setIsModalOpen(false)}
          data={{
            ...(selectedAudio?.ttsProps ?? {}),
            narrationText: selectedAudio.savedText,
          }}
          open={isModalOpen}
        />
      </>
    );
  } else {
    return <div>Select Audio</div>;
  }
};
