import { ReactNode } from "react";

export interface BaseModalProps {
  // Visible elements
  title?: string;
  // Functionality flags
  size?: ModalSize;
  open: boolean; // Visible or not
  forceInteraction?: boolean; // Forces user to do something, can't skip
  clickOutside?: boolean; // Allows to click in the backdrop to close/skip or not
  // Slots
  slots?: {
    topActions?: ReactNode;
    bottomActions?: ReactNode;
    header?: ReactNode;
    content?: ReactNode;
    footer?: ReactNode;
  };
  // Handlers
  onClose?: () => void; // External handler for closing the modal
}

export enum ModalSize {
  XS = "xs",
  SM = "sm",
  MD = "md",
  LG = "lg",
  XL = "xl",
}
