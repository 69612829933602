// import { CubemapAdapter, ReactPhotoSphereViewer } from "";
import blobUrlFormatHelper from "../blobUrlFormatHelper";
import styles from "./DetailView360p.module.css";
import { AssetPanoramicImages } from "../../models/IAsset";
import { ReactPhotoSphereViewer } from "../../lib/react-photo-sphere-viewer";
import { CubemapAdapter } from "@photo-sphere-viewer/cubemap-adapter";
import { useMemo } from "react";

interface IDetailView360p {
  panoramicImages: AssetPanoramicImages;
  blobPath: string;
}

export function buildPanorama(images: AssetPanoramicImages, blobPath: string) {
  const basePath = blobUrlFormatHelper(blobPath);

  return {
    left: `${basePath}/${images.leftImage}`,
    front: `${basePath}/${images.frontImage}`,
    right: `${basePath}/${images.rightImage}`,
    back: `${basePath}/${images.backImage}`,
    top: `${basePath}/${images.topImage}`,
    bottom: `${basePath}/${images.bottomImage}`,
  };
}
/**
 * This component uses the react-photo-sphere-viewer
 * Documentation: https://github.com/Elius94/react-photo-sphere-viewer#usage
 * Which is a react wrapper of: https://photo-sphere-viewer.js.org/
 * Documentation for native library: https://photo-sphere-viewer.js.org/guide/config.html#standard-options
 */
export const DetailView360p = ({ panoramicImages, blobPath }: IDetailView360p) => {
  // const psRef = useRef<any>();
  // const handleReady = (instance: any) => {
  //   console.log(instance);
  // };
  const panorama = useMemo(() => buildPanorama(panoramicImages, blobPath), [blobPath]);

  return (
    <div id="360p-container" className={styles.container}>
      <ReactPhotoSphereViewer
        adapter={CubemapAdapter}
        // panorama={panorama}
        key={panorama.front}
        container="360p-container"
        //@ts-expect-error library maps the src prop to the `panorama` option in the native library
        src={panorama}
        /**
         * According to official documentation when using the `CubemapAdapter`
         * the `src` prop is not needed and instead we should use the `panorama` prop
         * for the images however, this doesn't load the images properly
         * using the panorama object in the `src` prop correctly loads the sphere viewer
         * even though it throws a `type` error
         */
        width="100%"
        height="100%"
        // onReady={handleReady}
        // ref={psRef}
      />
    </div>
  );
};
