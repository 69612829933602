import { useEffect, useState } from "react";

export default function useDebounce<TValue>(value: TValue, interval: number): TValue {
  const [debouncedValue, setDebouncedValue] = useState<TValue>(value);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, interval);

    return () => {
      clearInterval(timeout);
    };
  }, [value, interval]);

  return debouncedValue;
}
