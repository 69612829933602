import React, { useCallback, useEffect, useReducer, useState } from "react";
import {
  AssetTags,
  Footer,
  DescriptionField,
  Dropdowns,
  CustomDropDown,
  BlockingModal,
  Checkboxes,
  InfoPanel,
  Checkbox,
} from "./components";
import useMFVDropdown from "../../../hooks/useMFVDropdown";
import {
  reducer,
  MetadataEditorActionType,
  AssetMetadataEditorProps,
  CreateTag,
  TagField,
  emptyDropdown,
  initialState,
  errorState,
} from "./state";
import { AssetTagPair } from "./components/AssetTagPair";
import genericRepositoryService from "../../../services/genericRepositoryService";
import IAsset from "../../../models/IAsset";
import { getUpdateRequestFromState, mapStateToIAsset, replaceAsset } from "./utils";
import ConfirmDelete from "../../Modals/ConfirmDelete";

const AssetMetadataEditor = ({
  selectedAsset,
  assetsAreLoading,
  setAssets,
  currentAssets,
  featuresToRender,
  isCpatUser,
  isSameLMS,
}: AssetMetadataEditorProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { readonly, description, tags, manFleetVariantDto, dataHasChanged, assetIsLibrary, assetIsArchived } = state;

  const [assetIsSubmitting, setAssetIsSubmitting] = useState<boolean>(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [showInfoPanel, setShowInfoPanel] = useState<boolean>(false);
  const [infoPanelMsg, setInfoPanelMsg] = useState<string>("");

  const { assetMetaEditing: showAssetMetaEditing, assetArchiving: showAssetArchiving } = featuresToRender;
  // the mfv dropdown has its own separate state with functions exposed to set/get data from it
  const mfvDropdown = useMFVDropdown({
    initialManufacturer: manFleetVariantDto.manufacturerName,
    initialFleet: manFleetVariantDto.fleetName,
    initialVariant: manFleetVariantDto.variantName,
  });

  const canEdit = () => {
    // dont show button if assets are loading; or the feature is not allowed; or if the item is not from the same LMS;
    // or if the user is not CPaT and the item is library
    if (assetsAreLoading) return false;
    if (!showAssetMetaEditing) return false;
    if (isCpatUser) return true;
    if (!isCpatUser && selectedAsset.isLibraryItem) return false;
    if (!isSameLMS) return false;
    return true;
  };

  const setAssetIsArchived = (value: boolean) => {
    // show the info panel if the current archive data is different from the original one
    if (selectedAsset.isArchived !== value) {
      const place = value ? "archives" : "library";
      setInfoPanelMsg(`This item will be moved to the ${place} tab once the new data is saved`);
      setShowInfoPanel(true);
    } else {
      setShowInfoPanel(false);
    }
    dispatch({
      type: MetadataEditorActionType.SET_ASSET_IS_ARCHIVED,
      payload: value,
    });
  };
  const setDataChanged = (value: boolean) => {
    dispatch({
      type: MetadataEditorActionType.SET_DATA_CHANGED,
      payload: value,
    });
  };
  const submitAsset = async () => {
    const updateAssetRequest = getUpdateRequestFromState(selectedAsset, state, mfvDropdown);
    setAssetIsSubmitting(true);
    const response = await genericRepositoryService.updateAssetMetaData(updateAssetRequest);
    setAssetIsSubmitting(false);
    if (response.isSuccess) {
      // this block of code maps the current state to the format that is used in the asset library mngr
      const assetToReplace: IAsset = mapStateToIAsset(selectedAsset, state, mfvDropdown);
      // take the existing assets, replace the previous asset with the newly edited asset
      const newData: IAsset[] = replaceAsset(currentAssets, assetToReplace);
      // setAssets was passed as props; in this case, it replaces the CurrentSelectedAsset in the library
      // with a new one with updated data
      setAssets(newData);
      // if there was a change in the archived status, show the panel with the appropriate message
      if (selectedAsset.isArchived !== assetIsArchived) {
        setShowInfoPanel(true);
        setInfoPanelMsg(
          assetIsArchived
            ? "This item will be available in the archives tab soon"
            : "This item will be available in the library tab soon",
        );
      }
      setReadonly(true);
    } else {
      setError({
        errorMessage:
          "Asset Data could not be updated. Make sure you have the permissions to edit this asset and are logged in to the correct LMS and try again",
        hasError: true,
        errorType: "UPDATE_FAILED",
      });
    }
  };

  const setAssetIsLibrary = (value: boolean) => {
    dispatch({
      type: MetadataEditorActionType.SET_ASSET_IS_LIBRARY,
      payload: value,
    });
  };

  const changeDescription = useCallback((description: string) => {
    dispatch({
      type: MetadataEditorActionType.CHANGE_DESCRIPTION,
      payload: description,
    });
  }, []);

  const setError = (error: errorState) => {
    dispatch({
      type: MetadataEditorActionType.SET_ERROR_STATE,
      payload: error,
    });
  };

  const removeTagById = useCallback((id: number) => {
    dispatch({
      type: MetadataEditorActionType.REMOVE_TAG_BY_ID,
      payload: id,
    });
  }, []);

  const editTag = useCallback((id: number, field: TagField, content: string) => {
    dispatch({
      type: MetadataEditorActionType.EDIT_TAG,
      payload: { id, field, content },
    });
  }, []);

  const setReadonly = (value: boolean) => {
    dispatch({
      type: MetadataEditorActionType.CHANGE_MODE,
      payload: value,
    });
    if (manFleetVariantDto.manufacturerId !== -1) {
      const { manufacturerName, fleetName, variantName } = manFleetVariantDto;
      mfvDropdown.setMostSpecificAssociation({
        initialManufacturer: manufacturerName,
        initialFleet: fleetName,
        initialVariant: variantName,
      });
      // this prompts the user to save changes if some of the mfv values are null
      if (Object.values(manFleetVariantDto).some((x) => x === null)) setDataChanged(true);
    }
  };

  const addTag = (tag: CreateTag) => {
    dispatch({
      type: MetadataEditorActionType.ADD_TAG,
      payload: tag,
    });
  };

  useEffect(() => {
    // this just makes the dropdown, tag, and description fields empty as the user is paginating
    if (assetsAreLoading) {
      dispatch({
        type: MetadataEditorActionType.CLEAR_METADATA,
      });
      setShowInfoPanel(false);
    }
  }, [assetsAreLoading]);

  useEffect(() => {
    if (!assetsAreLoading) {
      // this is the initial population of data once an asset gets selected
      // selectedAsset keeps the original data, while the state, reducer modify a copy of this data
      // to allow for easy discard of changes
      dispatch({
        type: MetadataEditorActionType.SET_INITIAL_STATE,
        payload: {
          // tags are mapped into a format that is useful for add/delete/edit
          // isPreExistingTag is used to disable the editing of tags that already exist
          // prior to the current editing session
          // newly added tags do not have this tag and can be edited freely
          tags: (selectedAsset.tags ?? []).map((tag, i) => {
            return {
              tagKey: tag.key,
              tagValue: tag.value,
              id: i,
              isPreExistingTag: true,
            };
          }),
          description: selectedAsset.description ?? "",
          manFleetVariantDto: selectedAsset.manufacturerFleetVariantDtos?.length
            ? selectedAsset.manufacturerFleetVariantDtos[0]
            : emptyDropdown,
          assetIsArchived: Boolean(selectedAsset.isArchived),
          assetIsLibrary: Boolean(selectedAsset.isLibraryItem),
        },
      });
      // the dropdown has its own independent state and init, so it has to be initialized too
      const { manufacturerName, fleetName, variantName } = manFleetVariantDto;
      mfvDropdown.setMostSpecificAssociation({
        initialManufacturer: manufacturerName,
        initialFleet: fleetName,
        initialVariant: variantName,
      });
      // reset the error state each time you change asset/go to a different mode
      setError({
        hasError: false,
        errorMessage: "",
        errorType: "NO_ERROR",
      });
      setShowInfoPanel(false);
    }
  }, [selectedAsset, readonly]);

  return (
    <>
      <div className="assetManager-modal-body-right">
        <DescriptionField readonly={readonly} description={description} setDescription={changeDescription} />
        <Dropdowns
          className={"assetManager-metadata-dropdowns"}
          readonly={readonly}
          childrenClassName={readonly ? "" : ""}
        >
          <CustomDropDown
            title={"Manufacturer"}
            options={readonly ? [manFleetVariantDto.manufacturerName] : mfvDropdown.manufacturerList}
            value={
              readonly ? manFleetVariantDto.manufacturerName ?? "" : mfvDropdown.currentManufacturer?.name ?? "None"
            }
            readonly={readonly}
            onChange={(value) => {
              setDataChanged(true);
              mfvDropdown.setSelectedManufacturer(value);
            }}
          />
          <CustomDropDown
            title={"Model"}
            options={readonly ? [manFleetVariantDto.fleetName ?? ""] : mfvDropdown.fleetList}
            readonly={readonly || mfvDropdown.currentManufacturer?.name === "None"}
            value={readonly ? manFleetVariantDto.fleetName ?? "" : mfvDropdown.currentFleet?.name ?? "None"}
            onChange={(value) => {
              setDataChanged(true);
              mfvDropdown.setSelectedFleet(value);
            }}
          />
          <CustomDropDown
            title={"Variant"}
            options={readonly ? [manFleetVariantDto.variantName ?? ""] : mfvDropdown.variantList}
            readonly={readonly || mfvDropdown.currentFleet?.name === "None" || mfvDropdown.currentFleet === null}
            onChange={(value) => {
              setDataChanged(true);
              mfvDropdown.setSelectedVariant(value);
            }}
            value={readonly ? manFleetVariantDto.variantName ?? "" : mfvDropdown.currentVariant?.name ?? "None"}
          />
        </Dropdowns>

        <Checkboxes infoPanelIsShown={showInfoPanel}>
          {isCpatUser && (
            <Checkbox
              description={"Share with all clients"}
              disabled={readonly}
              onClick={() => {
                setAssetIsLibrary(!assetIsLibrary);
              }}
              checked={assetIsLibrary}
            />
          )}
          {showAssetArchiving && (
            <Checkbox
              description={"Archive"}
              disabled={readonly}
              onClick={() => setAssetIsArchived(!assetIsArchived)}
              checked={assetIsArchived}
            />
          )}
          {showInfoPanel && <InfoPanel message={infoPanelMsg} />}
        </Checkboxes>
        <AssetTags readonly={readonly} existingTags={tags.length} addTag={addTag} disabled={tags.length >= 10}>
          {tags.map((tag, i) => {
            return (
              <AssetTagPair
                kvp={tag}
                key={i}
                removeTag={removeTagById}
                editTag={editTag}
                canEdit={!tag.isPreExistingTag}
                canRemove={!readonly}
              />
            );
          })}
        </AssetTags>
        {!assetsAreLoading && showAssetMetaEditing && (
          <Footer
            ableToEdit={canEdit()}
            readonly={readonly}
            setReadonly={setReadonly}
            dataHasChanged={dataHasChanged}
            submitAsset={submitAsset}
            errorState={state.errorState}
            showConfirmDialog={() => setShowConfirmDialog(true)}
          />
        )}
      </div>
      {(!readonly || assetIsSubmitting) && (
        <BlockingModal
          width={assetIsSubmitting ? "clamp(99%, 29vw, 40vw)" : "clamp(71%, 29vw, 40vw)"}
          height={"96%"}
          additionalStyles={{ background: "white", opacity: 0.4 }}
        >
          <ConfirmDelete
            show={showConfirmDialog}
            confirm={() => {
              setReadonly(true);
              setShowConfirmDialog(false);
            }}
            cancel={() => {
              setShowConfirmDialog(false);
            }}
            message={"Your current changes will be lost. Are you sure you want to discard the changes?"}
            title={"Asset Metadata"}
          />
        </BlockingModal>
      )}
    </>
  );
};

export default AssetMetadataEditor;
