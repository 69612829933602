// import _ from 'lodash';
import React, { Dispatch, SetStateAction, useRef, useEffect, useState } from "react";
import { AnnotationPropType } from "../AnnotationThumbnail";

const AnnotationTriangle = (props: AnnotationPropType) => {
  const { annotation, imgRect, index, ratio } = props;
  const triangleRef: React.RefObject<SVGSVGElement> = useRef<SVGSVGElement>(null);

  // const triangleRef: React.RefObject<SVGSVGElement> = useRef<SVGSVGElement>(null);
  const [bounds, setBounds]: [
    { height: number; width: number },
    Dispatch<SetStateAction<{ height: number; width: number }>>,
  ] = useState<{ height: number; width: number }>({ height: 0, width: 0 });
  const [isLoading, setIsLoading]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(true);

  useEffect(() => {
    function handleLoad() {
      const temp: { height: number; width: number } = {
        height: ((imgRect as DOMRect)?.height * annotation.height) / 100,
        width: ((imgRect as DOMRect)?.width * annotation.width) / 100,
      };
      setBounds(temp);
      setIsLoading(false);
    }

    handleLoad();
  }, []);

  useEffect(() => {
    let newBounds: {
      height: number;
      width: number;
    } = {
      height: (triangleRef.current?.getBoundingClientRect() as DOMRect)?.height,
      width: (triangleRef.current?.getBoundingClientRect() as DOMRect)?.width,
    };
    const makeBounds = () => {
      if (!isLoading && triangleRef.current)
        triangleRef.current.style.transform = `rotate(${annotation?.transform?.rotate?.toFixed(2)}deg)`;
      setBounds(newBounds);
    };

    makeBounds();

    return () => {
      newBounds = { height: 0, width: 0 };
    };
  }, [isLoading, triangleRef.current, annotation]);

  useEffect(() => {
    window.addEventListener("resize", checkResize);
    return () => {
      window.removeEventListener("resize", checkResize);
    };
  }, []);

  function checkResize() {
    const thisSVG: any = document.getElementById(`triangle-thumb-${index}`) as any;
    if (thisSVG && thisSVG.clientHeight > 0) {
      thisSVG.firstChild.attributes.points.nodeValue = `3,${thisSVG.clientHeight - 3} ${thisSVG.clientWidth / 2},3 ${
        thisSVG.clientWidth - 3
      },${thisSVG.clientHeight - 3}`;
    }
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // viewBox="0 0 auto 100"
      height="100"
      id={`triangle-thumb-${index}`}
      tabIndex={0}
      name="triangle"
      className="annotation"
      preserveAspectRatio="xMinYMin meet"
      style={{
        top: `${annotation.top.toFixed(2)}%`,
        left: `${annotation.left.toFixed(2)}%`,
        width: `${annotation.width.toFixed(2)}%`,
        height: `${annotation.height.toFixed(2)}%`,
        zIndex: annotation.zIndex,
        // transform:`rotate(${annotation?.transform?.rotate?.toFixed(2)}deg)`
      }}
      ref={triangleRef}
    >
      <polygon
        points={`${4 * ratio},${bounds?.height - 4 * ratio || 0} 
          ${(bounds?.width - 2 * ratio || 0) / 2},${4 * ratio} 
          ${bounds?.width - 4 * ratio || 0},${bounds?.height - 4 * ratio || 0}`}
        stroke={`${annotation.borderColor || "white"}`}
        fill={`${annotation.backgroundColor || "transparent"}`}
        strokeWidth={`${annotation.strokeWidth * ratio || 4 * ratio}`}
      />
    </svg>
  );
};

export default AnnotationTriangle;
