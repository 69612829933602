import React, { useContext, useEffect, useState } from "react";
import PBMenuHeader from "./PBMenuHeader";
import "../../../panels/ObjectPropertiesPanel/ObjectPropertiesPanel.css";
import { useInteracitvityHotspotState } from "../../../contexts/InteractivityHotspotProvider";
import { PageContext } from "../../../routes/builderContexts";
import { isAtLeastVersion_1_2_0 } from "../../InteractivityHotspots";
import useSettingsToggle, { DEFAULT_SETTING_TOGGLE_PERMISSIONS } from "../../../hooks/useSettingsToggle";
import { LessonPagesActions, useLessonPagesDispatch } from "../../../contexts/LessonPagesProvider/LessonPagesProvider";
import { HotspotObject } from "../../../types";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../../contexts/ObjectsProvider";

const HotspotOptions = ({ hotspot }: { hotspot: HotspotObject }) => {
  const interactivityHotspotState = useInteracitvityHotspotState();
  const currentHotspotVersion = interactivityHotspotState.currentlySelectedHotspot?.interactivityBuilderAction?.version;
  const [settings] = useSettingsToggle(DEFAULT_SETTING_TOGGLE_PERMISSIONS);

  const [waitForAudioIsChecked, setWaitForAudioIsChecked] = useState(false);
  const [hotspotIsRequired, setHotspotIsRequired] = useState(false);
  const [hotspotIsVisible, setHotspotIsVisible] = useState(false);
  const pageContext = useContext(PageContext);
  const lessonPagesDispatch = useLessonPagesDispatch();
  const hasAudio = pageContext.pageManifest.Audio?.length > 0 && pageContext.pageManifest.Audio[0]?.File;
  const objectsDispatch = useObjectsDispatch();
  const hotspotAction = interactivityHotspotState.currentlySelectedHotspot?.interactivityBuilderAction;
  const { animatedObjects } = useObjectsState();
  const animatedObjectHotspot = animatedObjects.find((obj) => obj.id === hotspot.objectId);
  const hotspotHasDelay = (hotspotAction?.delay ?? 0) > 0;

  const handleShowAfterAudioClick = () => {
    const level = interactivityHotspotState?.currentlySelectedHotspot?.interactivityBuilderAction?.level;
    if (!level) return;
    if (waitForAudioIsChecked) {
      level.removeWaitForAudio();
      const newTree = level.getRoot().printTree();
      lessonPagesDispatch({
        type: LessonPagesActions.UPDATE_CURRENT_PAGE_MANIFEST,
        payload: {
          interactivity: newTree,
        },
      });
    } else {
      level.addWaitForAudio();
      const newTree = level.getRoot().printTree();
      lessonPagesDispatch({
        type: LessonPagesActions.UPDATE_CURRENT_PAGE_MANIFEST,
        payload: {
          interactivity: newTree,
        },
      });
    }
    setWaitForAudioIsChecked(!waitForAudioIsChecked);
  };

  useEffect(() => {
    const waitForAudio =
      interactivityHotspotState?.currentlySelectedHotspot?.interactivityBuilderAction?.level.actionBuffer[0].type ===
      "external-wait";
    setWaitForAudioIsChecked(waitForAudio);
    const hotspotRequired = Boolean(
      interactivityHotspotState?.currentlySelectedHotspot?.interactivityBuilderAction?.required,
    );
    setHotspotIsRequired(hotspotRequired);

    const hotspotVisible = Boolean(
      interactivityHotspotState?.currentlySelectedHotspot?.interactivityBuilderAction?.hotspotLocation?.isDisplayed,
    );
    setHotspotIsVisible(hotspotVisible);
  }, [interactivityHotspotState?.currentlySelectedHotspot]);

  useEffect(() => {
    if (!hasAudio) {
      setWaitForAudioIsChecked(false);
    }
  }, [hasAudio]);

  return (
    <>
      <div className={"hotspotActionInput"}>
        <label className={"inputLabel"}>Visible</label>
        <input
          type="checkbox"
          checked={hotspot.isDisplayed}
          onChange={() => {
            objectsDispatch({
              type: ObjectActionsType.UPDATE_HOTSPOT,
              payload: {
                clickIndex: 0,
                hotspot: {
                  isDisplayed: !hotspot.isDisplayed,
                },
                objectId: hotspot.objectId,
              },
            });
          }}
        ></input>
      </div>
      <div className={"hotspotActionInput"}>
        <label className={"inputLabel"}>Required To Continue</label>
        <input
          type="checkbox"
          checked={hotspot.required}
          onChange={() => {
            objectsDispatch({
              type: ObjectActionsType.UPDATE_HOTSPOT,
              payload: {
                clickIndex: 0,
                hotspot: {
                  required: !hotspot.required,
                },
                objectId: hotspot.objectId,
              },
            });
          }}
        ></input>
      </div>
      {!isAtLeastVersion_1_2_0(hotspot.version) && (
        <div className={"hotspotActionInput"}>
          <label className={hasAudio ? "inputLabel" : "inputLabelDisabled"}>Activate After Audio</label>
          <input
            type="checkbox"
            disabled={!hasAudio}
            checked={waitForAudioIsChecked}
            onChange={handleShowAfterAudioClick}
          ></input>
        </div>
      )}
      {settings.hotspotsOnTimeline && (
        <div className={"hotspotActionInput"}>
          <label className={true ? "inputLabel" : "inputLabelDisabled"}>Hotspot Pauses Timeline</label>
          <input
            type="checkbox"
            disabled={!Boolean(animatedObjectHotspot)}
            checked={hotspot.pauseOnLoad}
            onChange={() => {
              objectsDispatch({
                type: ObjectActionsType.UPDATE_HOTSPOT,
                payload: {
                  clickIndex: 0,
                  hotspot: {
                    pauseOnLoad: !hotspot.pauseOnLoad,
                  },
                  objectId: hotspot.objectId,
                },
              });
            }}
          ></input>
        </div>
      )}
    </>
  );
};

export default HotspotOptions;
