import React from "react";
export const ActiveIcon = () => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Active</title>
    <g id="New-Drawer" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g className="svg-icon-bg" id="Drawer-" transform="translate(-386.000000, -138.000000)">
        <g id="Group-19">
          <g id="Group-13">
            <g id="Group-7-Copy-7" transform="translate(386.000000, 138.000000)">
              <path
                d="M10,0 C15.5228475,-1.01453063e-15 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 C4.4771525,20 6.76353751e-16,15.5228475 0,10 C-6.76353751e-16,4.4771525 4.4771525,1.01453063e-15 10,0 Z M14.1090724,5 L8.21633008,10.521597 L6.26164706,8.23021079 L4,10 L8.21633008,15 L16,7.05340015 L14.1090724,5 Z"
                id="Combined-Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const NotActiveIcon = (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Rectangle Copy 82</title>
    <g id="New-Drawer" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g className="svg-icon-bg" transform="translate(-495.000000, -250.000000)" fill="#283D55">
        <g id="Group-19">
          <g id="Group-13">
            <g id="Group-7-Copy-63" transform="translate(495.000000, 250.000000)">
              <rect id="Rectangle-Copy-82" x="0" y="0" width="20" height="20" rx="10"></rect>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const unavailableIcon = (color) => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Combined Shape Copy 15</title>
    <g id="New-Drawer-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Drawer-" transform="translate(-441.000000, -250.000000)" fill={color}>
        <g id="Group-19">
          <g id="Group-13">
            <rect id="Combined-Shape-Copy-15" x="441" y="250" width="20" height="20" rx="10"></rect>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export const drawerIcon = (pagePullout) => (
  <svg width="18px" height="30px" viewBox="0 0 18 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Fill 163 Copy 5</title>
    <g id="Drawer-with-new-colors" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g className="svg-icon-bg" transform="translate(-711.000000, -556.000000)">
        <g id="Group-77-Copy-2" transform="translate(704.000000, 119.000000)">
          <g id="Group-73-Copy" transform="translate(1.000000, 0.000000)">
            <path
              style={{ transform: !pagePullout ? "rotate3d(1,1,1,0deg)" : "" }}
              d="M10.9264576,437.944644 L23.2028579,450.101875 C24.265714,451.154414 24.265714,452.844855 23.2028579,453.896652 L10.9264576,466.054625 C9.81940938,467.150185 8.29590735,467.380126 7.18811015,466.283083 L6.72746426,465.826908 C5.89455656,464.865605 5.66535714,463.721831 6.63533508,462.853987 C9.63440201,459.883291 12.6334689,456.866606 15.680473,453.896652 C16.7425801,452.844855 16.7425801,451.154414 15.680473,450.101875 C12.6334689,447.086674 9.63440201,444.115978 6.63533508,441.143798 C5.66535714,440.276696 5.89455656,439.133664 6.72746426,438.127113 L7.18811015,437.716927 C8.29590735,436.620625 9.81940938,436.849083 10.9264576,437.944644"
              id="Fill-163-Copy-5"
              transform="translate(15.000000, 452.000000) rotate(-180.000000) translate(-15.000000, -452.000000) "
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
