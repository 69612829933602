import React from "react";
import { ISymbolV2 } from "../models/ISymbol";
import AircraftSymbol from "../components/AircraftSymbol";
import AircraftSymbol1 from "../components/AircraftSymbol1";
import CoursePointer1 from "../components/CoursePointer1";
import CoursePointer2 from "../components/CoursePointer2";
import CoursePointer3 from "../components/CoursePointer3";
import CoursePointer4 from "../components/CoursePointer4";
import CurrentHeadingPointer from "../components/CurrentHeadingPointer";
import CurrentTrack from "../components/CurrentTrack";
import DistanceSymbol from "../components/DistanceSymbol";
import DMETACAN from "../components/DMETACAN";
import ExpandedCompass from "../components/ExpandedCompass";
import GlideslopePointer from "../components/GlideslopePointer";
import HeadingBug from "../components/HeadingBug";
import ILSLocalizer from "../components/ILSLocalizer";
import LineM from "../components/LineM";
import TrackLineRangeScale from "../components/TrackLineRangeScale";
import VOR from "../components/VOR";
import VOR1PointerHead from "../components/VOR1PointerHead";
import VOR1PointerTail from "../components/VOR1PointerTail";
import VOR2PointerHead from "../components/VOR2PointerHead";
import VOR2PointerTail from "../components/VOR2PointerTail";
import VORTAC from "../components/VORTAC";
import ISymbolStyle from "../models/ISymbolStyle";
import { IKeyPressHandler } from "../../ObjectPropertyBox/models/IKeyPressHandler";
import { ElementTypes } from "../../../pageTypes/BasicPage_Player/components/IBasePage";

export type SymbolComponentType = {
  index: number;
  attributes: ISymbolV2;
  kp: IKeyPressHandler;
  transformStr: string;
  objectIsInTimeline?: boolean;
  handleKeyPress: (e: React.KeyboardEvent<HTMLDivElement | SVGSVGElement>, kp: IKeyPressHandler) => void;
  markSelected: (target: HTMLDivElement | SVGSVGElement, index: number, type: ElementTypes) => void;
};

export function getSymbol(
  index: number,
  kp: IKeyPressHandler,
  currentSymbol: ISymbolV2,
  handleKeyPress: (event: React.KeyboardEvent<HTMLDivElement | SVGSVGElement>, kp: IKeyPressHandler) => void,
  markSelected: (target: HTMLDivElement | SVGSVGElement, index: number, type: ElementTypes) => void,
  objectIsIntTimeline?: boolean,
) {
  let transformStr = `${currentSymbol.transform?.rotate ? `rotate(${currentSymbol.transform.rotate}deg)` : ""}
      ${currentSymbol.transform?.scaleX ? currentSymbol.transform.scaleX : ""} 
      ${currentSymbol.transform?.scaleY ? currentSymbol.transform.scaleY : ""} 
      ${currentSymbol.transform?.matrix3d ? currentSymbol.transform.matrix3d : ""}
    `;
  transformStr = transformStr.trim();

  const symbolProps = {
    index: index,
    attributes: currentSymbol,
    kp: kp,
    transformStr: transformStr,
    handleKeyPress,
    markSelected,
    objectIsIntTimeline,
  };

  const symbolComponents: Record<string, JSX.Element> = {
    aircraft: <AircraftSymbol {...symbolProps} key={index} />,
    aircraft_1: <AircraftSymbol1 {...symbolProps} key={index} />,
    current_heading: <CurrentHeadingPointer {...symbolProps} key={index} />,
    current_track: <CurrentTrack {...symbolProps} key={index} />,
    distance: <DistanceSymbol {...symbolProps} key={index} />,
    dme_tacan: <DMETACAN {...symbolProps} key={index} />,
    expanded_compass: <ExpandedCompass {...symbolProps} key={index} />,
    glideslope: <GlideslopePointer {...symbolProps} key={index} />,
    ils_localizer: <ILSLocalizer {...symbolProps} key={index} />,
    line_m: <LineM {...symbolProps} key={index} />,
    course_pointer_1: <CoursePointer1 {...symbolProps} key={index} />,
    course_pointer_2: <CoursePointer2 {...symbolProps} key={index} />,
    course_pointer_3: <CoursePointer3 {...symbolProps} key={index} />,
    course_pointer_4: <CoursePointer4 {...symbolProps} key={index} />,
    heading_bug: <HeadingBug {...symbolProps} key={index} />,
    track_line_range: <TrackLineRangeScale {...symbolProps} key={index} />,
    vor_1_head: <VOR1PointerHead {...symbolProps} key={index} />,
    vor_1_tail: <VOR1PointerTail {...symbolProps} key={index} />,
    vor_2_head: <VOR2PointerHead {...symbolProps} key={index} />,
    vor_2_tail: <VOR2PointerTail {...symbolProps} key={index} />,
    vor: <VOR {...symbolProps} key={index} />,
    vortac: <VORTAC {...symbolProps} key={index} />,
  };

  const returnSymbol = symbolComponents[currentSymbol.name];
  return returnSymbol;
}
