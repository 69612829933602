import _ from "lodash";
import { IAnnotation } from "../../components/Annotation/models/IAnnotation";
import { ISymbolV2 } from "../../components/Symbol/models/ISymbol";
import { ListOfObjects } from "./ListOfObjects";

export class WYSIWYGObjectList extends ListOfObjects {
  // annotations: IAnnotation[] | undefined;
  // symbols: ISymbolV2[] | undefined;

  constructor(annotations: IAnnotation[] | undefined, symbols: ISymbolV2[] | undefined) {
    super();
    this.annotations = annotations ? annotations : [];
    this.symbols = symbols ? symbols : [];
    this.fullList = this.getAnnotationAndSymbolList();
  }

  protected getAnnotationAndSymbolList() {
    let tempList: any[] = [];
    this.validateSymbolType();
    this.validateAnnotationType();

    if (!this.annotations && !this.symbols) {
      return tempList;
    } else if (this.annotations && !this.symbols) {
      tempList = _.cloneDeep(this.annotations);
    } else if (!this.annotations && this.symbols) {
      tempList = _.cloneDeep(this.symbols);
    } else {
      tempList = _.concat(this.annotations as any, this.symbols);
    }

    // const tempList = _.concat((this.annotations as any), this.symbols);
    return tempList;
  }

  private validateSymbolType() {
    // let newList: IAnnotation[] | ISymbolV2[] = _.cloneDeep(this.symbols);
    _.forEach(this.symbols, (item, index) => {
      if (!item.type || (item.type as string) === "") {
        this.symbols[index].type = "symbol";
      }
    });
    // return newList;
  }

  private validateAnnotationType() {
    _.forEach(this.annotations, (annotation, index) => {
      if (!annotation.type || annotation.type === "") {
        this.annotations[index].type = "annotation";
      }
    });
  }
}
