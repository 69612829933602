import React from "react";
import EmptyImage from "../../../../EmptyImage";

type AddReferenceImageType = {
  isLargeCDU: boolean;
  triggerUploadModal: (e: React.MouseEvent<HTMLDivElement>, val: number) => void;
};

const AddReferenceImage = (props: AddReferenceImageType) => {
  const displayText = "Click Here to Select or Upload a Reference Image.";

  return (
    <div className="fms-image-upload-container">
      <div
        className="fms-image-upload"
        onClick={(e: React.MouseEvent<HTMLDivElement>) => props.triggerUploadModal(e, 0)}
      >
        <EmptyImage showUploadText={props.isLargeCDU} displayText={displayText} />
      </div>
    </div>
  );
};

export default AddReferenceImage;
