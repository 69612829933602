import { useEffect } from "react";
import { useTimeline } from "../../contexts/TimelineProvider/TimelineProvider";
import { useMetaVariableStore } from "./store";
import { getFramesNearCurrentTime } from "../../utils/Animation/getFramesNearCurrentTime";

export function useMetaVariableTimelineTracker() {
  const set = useMetaVariableStore((s) => s.setMetaVariable);
  const animatedMetaVariables = useMetaVariableStore((s) => s.animatedMetaVariables);
  const [tl] = useTimeline();
  const currentTime = tl?.scrubbingCurrentTime;

  useEffect(() => {
    if (animatedMetaVariables.length === 0) return;

    for (let i = 0; i < animatedMetaVariables.length; i++) {
      const animatedMetaVariable = animatedMetaVariables[i];
      const oneshots = animatedMetaVariable.oneshot ?? [];
      const interpolated = animatedMetaVariable.interpolated ?? [];

      if (oneshots?.length === 0 && interpolated?.length === 0) {
        continue;
      }

      if (interpolated?.length > 0) {
        // @ts-expect-error function expects Frame[] instead of AnimatedMetaVariableFrame[]
        const interpolatedFrame = getFramesNearCurrentTime(interpolated, currentTime);
        set(animatedMetaVariable.metaVariableKey, interpolatedFrame?.value as number);
        continue;
      }

      if (oneshots?.length > 0) {
        let lastValidFrame;
        for (let j = oneshots.length - 1; j >= 0; j--) {
          const frame = oneshots[j];
          if (frame.timestamp <= currentTime) {
            lastValidFrame = frame;
            break;
          }
        }

        if (lastValidFrame) {
          set(animatedMetaVariable.metaVariableKey, lastValidFrame.value);
        }
        continue;
      }
    }
  }, [currentTime]);
}
