import React, { useContext } from "react";
import { ILessonPageActionsContext, LessonPageActionsContext } from "../../../routes/builderContexts";
import { AbstractTooltip as Tooltip } from "../../ToolTip/ToolTip";
const AnnotationModeButton = () => {
  const context: ILessonPageActionsContext = useContext<ILessonPageActionsContext>(LessonPageActionsContext);

  return (
    <React.Fragment>
      <div className="pageEditorActionListerRow">
        <div
          className="pageListerActionButton edit-mode-btn"
          data-tooltip-id="Editor Mode Switch"
          onClick={() => {
            context.setIsAnnotationsShown(!context.isAnnotationsShown);
          }}
          style={context.isAnnotationsShown ? { border: "3px solid greenyellow" } : {}}
        >
          <Tooltip id="Editor Mode Switch" />
          {context.isAnnotationsShown ? <span>Design Mode</span> : <span>Edit Mode</span>}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AnnotationModeButton;
