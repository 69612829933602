import React, { useRef, useState } from "react";
import VisualHotspot from "../components/hotspot";
import DetectionRadius from "../components/detectionRadius";
const HSR = (props) => {
  const ref = useRef();
  const proximityRef = useRef();

  let { proximityData, hsData } = props;
  if (hsData === undefined || hsData === null) {
    hsData = {};
  }
  if (hsData.key === undefined || hsData.key === null) {
    hsData.key = "default";
  }
  if (hsData.position === undefined || hsData.position === null) {
    hsData.position = [35.5, 9.5, 28.25];
  }
  if (hsData.rotation === undefined || hsData.rotation === null) {
    hsData.rotation = [0, 0, 0];
  }
  if (hsData.geometry === undefined || hsData.geometry === null) {
    hsData.geometry = [1, 32, 32];
  }
  if (hsData.scale === undefined || hsData.scale === null) {
    hsData.scale = [1, 1, 1];
  }
  if (hsData.isSphere === undefined || hsData.isSphere === null) {
    hsData.isSphere = true;
  }
  const [hovered, setHovered] = useState(false);

  return (
    <>
      <mesh
        key={hsData.key}
        position={hsData.position}
        rotation={hsData.rotation}
        scale={hsData.scale}
        onPointerOver={() => setHovered(true)}
        onPointerOut={() => setHovered(false)}
        onClick={() => props.checkActiveHotSpot(`${hsData.key}`)}
      >
        <VisualHotspot hovered={hovered} isSphere={props.isSphere} meshRef={ref} />
        {/* {props.isSphere ? <sphereBufferGeometry attach="geometry" args={[1,32,32]} /> : <boxBufferGeometry attach="geometry" args={[1,1,1]}/>}
                <meshPhysicalMaterial attach="material" color={hovered ? 'purple' : 'green'} transparent opacity={hovered ? '1' : '1'} /> */}
      </mesh>
      {
        <DetectionRadius
          position={hsData.position}
          proximityOffset={proximityData?.proximityOffset ?? { x: 0, y: 0, z: 0 }}
          detectRad={proximityRef}
          proximityRange={proximityData?.proximityRange ?? 50}
          isVisible={true}
        />
      }
    </>
  );
};

export default HSR;
