import React from "react";
import { AnnotationPropType } from "../AnnotationThumbnail";

const AnnotationSquareLetter = (props: AnnotationPropType) => {
  const { annotation, count, index, ratio } = props;
  const alphas = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  const display = () => {
    return (
      <div>
        <svg
          viewBox="0 0 100 100"
          key={index}
          id={`squareNumber-${index}`}
          className="annotation"
          name="squareNumber"
          preserveAspectRatio="xMinYMin meet"
          style={{
            top: `${annotation.top}%`,
            left: `${annotation.left}%`,
            width: `${annotation.width}%`,
            height: `${annotation.height}%`,
            zIndex: annotation.zIndex,
            transform: `rotate(${annotation?.transform?.rotate}deg)`,
          }}
        >
          <rect
            x={`${1.5}`}
            y={`${1.5}`}
            height="97"
            width="97"
            stroke={`${annotation.borderColor}`}
            strokeWidth={`${annotation.strokeWidth || 4}`}
            fill={`${annotation.backgroundColor}`}
          ></rect>
          <text
            className="step-text"
            x="50"
            y="50"
            dy="26.5"
            dx="0.0em"
            textAnchor="middle"
            fill={`${annotation.fontColor}`}
            // style={{transform: `translate(${-2 * ratio}px, ${-1 * ratio}px)`}}
          >
            {alphas[(count as number) - 1]}
          </text>
        </svg>
      </div>
    );
  };

  return <React.Fragment>{display()}</React.Fragment>;
};

export default AnnotationSquareLetter;
