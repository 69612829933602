import Slider from "rsuite/Slider";
import "./slider.css";

import React from "react";

type SliderProps = {
  min: number;
  max: number;
  step: number;
  default: number;
  class: string;
  disabled: boolean;
  barClassName: string;
  marks: { tick: number; mark: string }[];
  label: string;
  labelClassName: string;
  sliderClassName: string;
  onChangeCommitted: (...args: any[]) => any;
  sliderHeaderClassName: string;
};

const SpeedSlider = (props: SliderProps) => {
  return (
    <div className={props.class}>
      <div className={props.labelClassName}>
        <label className={props.disabled ? props.sliderHeaderClassName + "-disabled" : props.sliderHeaderClassName}>
          {props.label}
        </label>
      </div>
      <div className={props.sliderClassName}>
        <Slider
          min={props.min}
          max={props.max}
          step={props.step}
          defaultValue={props.default}
          tooltip={false}
          graduated
          disabled={props.disabled}
          barClassName={props.barClassName}
          renderMark={(mark) => {
            for (const v in props.marks) {
              if ([props.marks[v].tick].includes(mark)) {
                return <span>{props.marks[v].mark}</span>;
              }
            }
            return null;
          }}
          onChangeCommitted={props.onChangeCommitted}
          onChange={props.onChangeCommitted}
        />
      </div>
    </div>
  );
};

export default SpeedSlider;
