import React, { useState } from "react";

interface ToggleSwitchProps {
  //callback function to be called when the toggle switch is clicked

  onChange?: (isOn: boolean) => void;
  initialIsOn?: boolean;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = (props) => {
  const [isOn, setIsOn] = useState(props.initialIsOn ?? false);

  const toggleSwitch = () => {
    setIsOn(!isOn);
    if (props.onChange) {
      props.onChange(!isOn);
    }
  };

  return (
    <div className={`toggle-switch ${isOn ? "on" : "off"}`} onClick={toggleSwitch}>
      <div className="toggle-handle"></div>
    </div>
  );
};

export default ToggleSwitch;
