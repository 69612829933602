import React from "react";
import "./uploaderStyles.css";

let resolve: (value?: unknown) => void;

interface uploaderWarningProps {
  setUploadWarningShown: (value: boolean) => void;
  errorMessage: string;
}
const UploadWarningModal = (props: uploaderWarningProps) => {
  const closeManager = () => {
    resolve(true);
  };
  const closeWarning = () => {
    props.setUploadWarningShown(false);
    resolve(false);
  };

  return (
    <div className="upload-warning-backdrop z-3">
      <div className="warning-modal-card">
        <p className="cancel-modal-header">Cancel Upload</p>
        <p className="cancel-modal-body-text">
          All the changes that have been made will be lost. Are you sure that you would like to cancel the upload?
        </p>
        {props.errorMessage.length ? <p className="cancel-modal-error-message">{props.errorMessage}</p> : <></>}
        <div className="cancel-modal-button-container">
          <button className="cancel-modal-button-yes" onClick={closeManager}>
            Confirm
          </button>
          <button className="cancel-modal-button-no" onClick={closeWarning}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
UploadWarningModal.show = (props: any) => {
  props.setUploadWarningShown(true);
  return new Promise((res) => {
    resolve = res;
  });
};
/**
 * DeleteConfirmation.show = (props:any) => {
    props.setDeletePageConfirmationModal(true);
    return new Promise((res) => {
      resolve = res;
    });
}
 */
export default UploadWarningModal;
