import "./Modals.css";
import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";

import { useTablesDataDispatch, useTablesDataState } from "../Tables/TablesDataProvider";
import { PageContext } from "../../routes/builderContexts";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../contexts/ObjectsProvider";

type ConfirmType = {
  show: boolean;
  cancel: (bool: boolean) => void;
  confirm: () => void;
  message?: string;
  title?: string;
};

const ConfirmDelete = (props: ConfirmType) => {
  const objectsDispatch = useObjectsDispatch();
  const tablesDispatch = useTablesDataDispatch();
  const tablesState = useTablesDataState();
  const pageContext = React.useContext(PageContext);
  const { selectedObjects } = useObjectsState();
  const modalRef = React.useRef<HTMLDivElement>(null);
  const { show, cancel, confirm, message, title } = props;

  function whatToDo() {
    if (selectedObjects.length > 0 && selectedObjects[0]?.type === "SCORM") {
      objectsDispatch({
        type: ObjectActionsType.DELETE_OBJECT,
        objectId: selectedObjects[0].objectId,
      });
      pageContext.updatePageManifest({
        ...pageContext.pageManifest,
        pageScormObject: [],
      });
      cancel(false);
    } else if (tablesState.selectedTable !== undefined) {
      tablesDispatch({ action: "deleteTable", payload: [] });
      if (tablesState.tables.length === 1) {
        pageContext.updatePageManifest({
          ...pageContext.pageManifest,
          tables: [],
        });
      }
      // confirm();
      cancel(false);
    } else {
      confirm();
    }
  }

  useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (!show) return;
      if (e.key === "Escape") {
        cancel(false);
      } else if (e.key === "Enter") {
        whatToDo();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [show]);
  return (
    <Modal show={show} centered className="confirm-delete">
      <Modal.Header className="confirm-delete-header">
        <p>{title ?? "Confirm Delete"}</p>
      </Modal.Header>
      <Modal.Body className="confirm-delete-text">
        <p>{message ?? "Are you sure you want to delete that?"}</p>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" id="cancel" onClick={() => cancel(false)}>
          Cancel
        </button>
        <button type="button" id="confirm" onClick={() => whatToDo()}>
          Confirm
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDelete;
