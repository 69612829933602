import React, { useState, useContext, useEffect } from "react";
import { connect } from "react-redux";
import "../../CPaT3d_Player/CPaT3d_Player.css";
import { PageContext } from "../../../routes/builderContexts";
import {
  displayStepSystem,
  setdisplayStyle,
  displayChooseStep,
  displayAddStep,
  displayStepResponsePanel,
  storedCurrentStep,
} from "../../../store/actions/actions";
// import MagicCover from '../scene/loadingScreen'
const PagePresentation = (props) => {
  const [manifestIsChanged, setManifestIsChanged] = useState(false);
  useEffect(() => {
    let mounted = true;
    return () => {
      if (manifestIsChanged === true) {
        pageContext.updatePageManifest(updatedManifest);
      }
      mounted = false;
    };
  }, []);

  const pageContext = useContext(PageContext);
  const updatedManifest = pageContext.pageManifest;
  const [currentObjectiveText, setCurrentObjectiveText] = useState(updatedManifest.CurrentObjective);
  const [currentObjectiveDescriptionLabelText, setCurrentObjectiveDescriptionLabelText] = useState(
    updatedManifest.CurrentObjectiveDescLabel,
  );
  const [currentObjectiveDescriptionText, setCurrentObjectiveDescriptionText] = useState(
    updatedManifest.CurrentObjectiveDescription,
  );
  const [failResponse, setfailResponse] = useState(updatedManifest.FailResponse);
  const [currentObjectiveLabelText, setCurrentObjectiveLabelText] = useState(updatedManifest.CurrentObjectiveLabel);
  const handleCurrentObjectiveLabel = (e) => {
    setCurrentObjectiveLabelText(e.target.value);
    setManifestIsChanged(true);
  };
  const handleCurrentObjective = (e) => {
    setCurrentObjectiveText(e.target.value);
    setManifestIsChanged(true);
  };
  const handleCurrentObjectiveDescriptionLabel = (e) => {
    setCurrentObjectiveDescriptionLabelText(e.target.value);
    setManifestIsChanged(true);
  };
  const handleCurrentObjectiveDescription = (e) => {
    setCurrentObjectiveDescriptionText(e.target.value);
    setManifestIsChanged(true);
  };
  const handleLowerPanel = (e) => {
    setfailResponse(e.target.value);
    setManifestIsChanged(true);
  };

  const saveChanges = () => {
    // updatedManifest.CurrentObjectiveLabel = currentObjectiveLabelText;
    updatedManifest.CurrentObjective = currentObjectiveText;
    updatedManifest.CurrentObjectiveDescLabel = currentObjectiveDescriptionLabelText;
    updatedManifest.CurrentObjectiveDescription = currentObjectiveDescriptionText;
    updatedManifest.FailResponse = failResponse;
    pageContext.updatePageManifest(updatedManifest);
    setManifestIsChanged(false);
  };
  const checkForEnter = (e) => {
    if (e.key === "Enter") {
      saveChanges();
    }
  };

  const handleSelectChange = (e) => {
    updatedManifest.CurrentObjectiveLabel = e.currentTarget.value;
    pageContext.updatePageManifest(updatedManifest);
  };

  return (
    <div className="pagePlayerRightOverlay" onSubmit={() => saveChanges()}>
      <div className="upperPanel">
        <div id="objectiveLabelText">
          {/* <input type="text" value={currentObjectiveLabelText} className="editInputLabel" onChange={(e) => handleCurrentObjectiveLabel(e)} onBlur={() => (saveChanges())} onKeyPress={(e) => checkForEnter(e)} /> */}
          <select
            className="editInputLabel"
            value={updatedManifest.CurrentObjectiveLabel}
            onChange={(e) => handleSelectChange(e)}
          >
            <option value="Enabling Objective">Enabling Objective</option>
            <option value="Terminal Objective">Terminal Objective</option>
            <option value="Information">Information</option>
          </select>
        </div>
        <div id="objectiveText">
          <textarea
            type="text"
            value={currentObjectiveText}
            className="editCurObjective"
            onChange={(e) => handleCurrentObjective(e)}
            onBlur={() => saveChanges()}
            onKeyPress={(e) => checkForEnter(e)}
          />
        </div>
      </div>
      <div className="midPanel">
        <div className="what-to-do-container">
          <div id="currentObjectiveDescriptionLabelText">
            <input
              type="text"
              value={currentObjectiveDescriptionLabelText}
              className="editInputLabel"
              onChange={(e) => handleCurrentObjectiveDescriptionLabel(e)}
              onBlur={() => saveChanges()}
              onKeyPress={(e) => checkForEnter(e)}
            />
          </div>{" "}
          <div id="currentObjectiveDescriptionText">
            <textarea
              type="text"
              value={currentObjectiveDescriptionText}
              className="editCurObjectiveDesc"
              onChange={(e) => handleCurrentObjectiveDescription(e)}
              onBlur={() => saveChanges()}
              onKeyPress={(e) => checkForEnter(e)}
            />
          </div>{" "}
        </div>
        <div className="lowerPanel">
          <div>Fail Response</div>
          <div id="stepResponseText">
            <textarea
              type="text"
              value={failResponse}
              className="editStepResponse"
              onChange={(e) => handleLowerPanel(e)}
              onBlur={() => saveChanges()}
              onKeyPress={(e) => checkForEnter(e)}
            />
          </div>{" "}
        </div>
        {/* <div className="hud-instructions" >You must press the "ENTER" key to capture your changes</div> */}
        <button className="saveText" onClick={() => saveChanges()}>
          {" "}
          Save Text Changes{" "}
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openStepResponsePanel: state.Current3dPage.openStepResponsePanel,
    currentStep: state.Current3dPage.currentStep,
  };
};

export default connect(mapStateToProps, {
  displayStepSystem,
  displayChooseStep,
  setdisplayStyle,
  displayAddStep,
  displayStepResponsePanel,
  storedCurrentStep,
})(PagePresentation);
