/**
 * 'clsx' like helper, very useful for tailwind class conditions
 * classes( "myClassName", { "otherClassName": someBoolean } ) => className="myClassName" or className="myClassName otherClassName"
 * classes( "wrapper", { "wrapper--blue": color === "blue" } ) => className="wrapper wrapper--blue"
 * @param classNames
 */

export type ValidClassName = string | string[] | Record<string, boolean> | undefined;

export const classes = (...classNames: ValidClassName[]): string => {
  if (!classNames || !classNames?.length) {
    return "";
  }

  const classList = [];

  for (const className of classNames) {
    if (typeof className === "string") {
      classList.push(className);
    } else if (Array.isArray(className)) {
      classList.push(...className);
    } else if (className) {
      // Object, run through all the props
      const objectClasses = Object.entries(className)
        // Filter out all the falses
        .filter(([, condition]) => !!condition)
        // Gather all classes
        .map(([className]) => className);

      classList.push(...objectClasses);
    }
  }

  // this prevents extra spaces
  return [...new Set(classList)].join(" ");
};

export default classes;
