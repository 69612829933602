import React from "react";
import { SVGAnnotationProps } from "../index";
import { HighlightAnnotation } from "../HighlightAnnotation";

export function SVGCurrentHeadingPointer({
  onMouseDown,
  objectId,
  y,
  x,
  width,
  height,
  isDisplayed,
  zIndex,
  borderColor,
  strokeWidth,
  backgroundColor,
  opacity,
  rotate,
}: // transform,
SVGAnnotationProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8.56 7.34"
      key={objectId}
      data-objectid={objectId}
      id={`symbol-${objectId}`}
      tabIndex={0}
      className="symbol target"
      name="symbol"
      onMouseDown={(e: React.MouseEvent<SVGSVGElement>) => {
        onMouseDown(e, objectId);
      }}
      preserveAspectRatio="xMidYMid meet"
      style={{
        // top: `${y}px`,
        // left: `${x}px`,
        width: `${width}px`,
        height: `${height}px`,
        zIndex: zIndex,
        transform: `translate(${x}px, ${y}px) rotate(${rotate ?? 0}deg)`,
        opacity: isDisplayed ? opacity : 0.5,
        filter: isDisplayed ? "none" : "grayScale(100%)",
        // `rotate(${attributes?.transform?.rotate}deg) scaleX(${attributes?.transform?.scaleX}) scaleY(${attributes?.transform?.scaleY}) matrix3d(${attributes?.transform?.matrix3d})`
      }}
    >
      <HighlightAnnotation objectId={objectId} />
      <path
        stroke={`${borderColor || "white"}`}
        fill={`${backgroundColor || "transparent"}`}
        strokeWidth={`${strokeWidth / 10 || 0.1}`}
        // className="heading_pointer"
        d="M 0.88,0.5 H 7.69 L 4.34,6.35 Z"
      />
    </svg>
  );
}
