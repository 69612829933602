import { combineReducers, AnyAction, Reducer } from "redux";
import CPaT3dPageCurrentReducer from "../reducers/CPaT3dPageCurrent";
import { authorizeReducer, IAuthorizeReducer } from "./authorizeReducer";
// import { persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage' // defaults to localStorage

export interface ILessonDesignerStore {
  Current3dPage: any;
  authorizedState: IAuthorizeReducer;
}

export const rootReducer: Reducer<ILessonDesignerStore, AnyAction> = combineReducers<ILessonDesignerStore>({
  Current3dPage: CPaT3dPageCurrentReducer,
  authorizedState: authorizeReducer,
});

export default rootReducer;
