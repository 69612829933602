import _ from "lodash";
import React, { ChangeEvent } from "react";
import {
  clipPath,
  getAbsoluteClipPosOnResize,
  getClipObject,
  getUpdatedClipPath,
  hasClipCoordinates,
} from "../../Moveable/lib/ables/cropFunctions";
import { IKeyPressHandler } from "../models/IKeyPressHandler";
import { IPropertyBox } from "../models/IObjectPropertyBox";
import { PropertyBoxType } from "../ObjectPropertyBox";
import { getBounds } from "../../Moveable/lib/ables/snappable/bounds";

function roundToNDecimalPlaces(number: number | string, decimalPlaces = 2) {
  if (typeof number === "string") number = parseFloat(number);
  return parseFloat(number.toFixed(decimalPlaces));
}

export const createPropertyBox = (tempNode: any, pageDims: DOMRect, setPropertyBox: (box: IPropertyBox) => void) => {
  if (tempNode) {
    const newPropertyBox = {
      name: tempNode.name,
      left: parseFloat(((tempNode.left / 100) * (pageDims?.width as number)).toFixed(2)),
      top: parseFloat(((tempNode.top / 100) * (pageDims?.height as number)).toFixed(2)),
      zIndex: tempNode.zIndex,
      rotation: tempNode.transform?.rotate || 0.0,
      width: parseFloat(((tempNode.width / 100) * (pageDims?.width as number)).toFixed(2)),
      height: parseFloat(((tempNode.height / 100) * (pageDims?.height as number)).toFixed(2)),
      displayName: tempNode.displayName,
    };
    setPropertyBox(newPropertyBox);
  }
};

export const getCroppedImageSize = (target: any, props: PropertyBoxType, moveRef?: any): number[] => {
  if (!target || !props.nodeToUpdate) return [420.0, 0.0];
  if (!props.nodeToUpdate?.transform?.scale) props.nodeToUpdate.transform = { scale: [1.0, 1.0] };
  const node = props.nodeToUpdate;
  const height = parseFloat(
    ((parseFloat(target.style.height) * (props.pageDims as DOMRect).height * node.transform.scale[1]) / 100).toFixed(2),
  );
  const width = parseFloat(
    ((parseFloat(target.style.width) * (props.pageDims as DOMRect).width * node.transform.scale[0]) / 100).toFixed(2),
  );

  if (props.nodeToUpdate.hasOwnProperty("clipPath")) {
    const { top: deltaTop, bottom: deltaBottom, left: deltaLeft, right: deltaRight } = node.clipPath;
    const clippedHeight = height - ((deltaTop + deltaBottom) / 100) * height;
    const clippedWidth = width - ((deltaRight + deltaLeft) / 100) * width;
    return [clippedWidth, clippedHeight].map((x) => Math.floor(x));
  }
  return [width, height].map((x) => Math.floor(x));
};

export const getCroppedImagePosition = (target: any, props: PropertyBoxType, moveRef: any) => {
  if (!target || !props.nodeToUpdate || !props.nodeToUpdate?.transform?.scale) return [0.0, 0.0];
  const pageDims = props.pageDims as DOMRect;
  const tempProps: IPropertyBox = props.properties;
  const moveableState = moveRef?.moveable?.state;
  const croppedRect = moveableState?.croppedRect;
  if (props.nodeToUpdate.hasOwnProperty("clipPath") && croppedRect) {
    const { top, left } = target.getBoundingClientRect();
    const clippedTop = top + croppedRect.top - pageDims!.top;
    const clippedLeft = left + croppedRect.left - pageDims!.left;
    return [clippedTop, clippedLeft].map((x) => (x > 0.9 ? Math.ceil(x) : 0.0));
  }
  return [tempProps.top, tempProps.left];
};

const getAbsoluteImagePosition = (target: any, props: PropertyBoxType): number[] => {
  const { top, left } = target.getBoundingClientRect();
  return [
    Math.ceil((top - (props.pageDims as DOMRect).top) as number),
    Math.ceil((left - (props.pageDims as DOMRect).left) as number),
  ];
};

export const handleNameChange = (value: string, props: PropertyBoxType, moveRef: any) => {
  const tempProps: IPropertyBox = _.cloneDeep(props.properties);
  const node = _.cloneDeep(props.nodeToUpdate);
  tempProps.displayName = value;
  node.displayName = value;
  props.updateAttributes(node);
  props.changePropertyBox(tempProps);
};

export const handleTopChange = (value: string, props: PropertyBoxType, moveRef: any) => {
  const tempProps: IPropertyBox = _.cloneDeep(props.properties);
  const node = _.cloneDeep(props.nodeToUpdate);
  if (!_.has(props.nodeToUpdate, "transform")) {
    node.transform = { rotate: 0 };
  }
  tempProps.top = Number(parseFloat(value).toFixed(2));
  node.top = Number(parseFloat(value).toFixed(2));
  node.top = (node.top / (props.pageDims as DOMRect).height) * 100;
  if (node.rawData) {
    props.target.getElementsByTagName("img")[0].style.top = parseFloat(value).toFixed(2) + "px";
    props.target.style.top = parseFloat(value).toFixed(2) + "px";
  }
  moveRef.updateRect();
  props.changePropertyBox(tempProps);
  props.updateAttributes(node);
};

export const handleLeftChange = (value: string, props: PropertyBoxType, moveRef: any) => {
  const tempProps: IPropertyBox = _.cloneDeep(props.properties);
  const node = _.cloneDeep(props.nodeToUpdate);
  if (!_.has(props.nodeToUpdate, "transform")) {
    node.transform = { rotate: 0 };
  }
  tempProps.left = Number(parseFloat(value).toFixed(2));
  node.left = parseFloat(value);
  node.left = (node.left / (props.pageDims as DOMRect).width) * 100;
  if (node.rawData) {
    props.target.style.left = parseFloat(value).toFixed(2) + "px";
  }
  moveRef.updateRect();
  props.changePropertyBox(tempProps);
  props.updateAttributes(node);
};

// Since there seems to be a discrepancy in how top and left values are calculated for images, I decided to make a version
//of this function that gets the direction of the top change and shifts it by a page height unit (the delta var in this function)
export const handleImageTopChange = (value: string, direction: number, props: PropertyBoxType, moveRef: any) => {
  const tempProps: IPropertyBox = _.cloneDeep(props.properties);
  const node = _.cloneDeep(props.nodeToUpdate);
  if (!_.has(props.nodeToUpdate, "transform")) {
    node.transform = { rotate: 0 };
  }
  const delta = (direction / (props.pageDims as DOMRect).height) * 100;
  tempProps.top = parseFloat(value);
  node.top = Number(parseFloat(node.top + delta));
  if (node.rawData && props.target) {
    props.target.style.top = Number(parseFloat(props.target.style.top) + direction) + "px";
    props.target.getElementsByTagName("img")[0].style.top =
      Number(parseFloat(props.target.style.top) + direction) + "px";
  }
  moveRef.updateRect();
  props.changePropertyBox(tempProps);
  props.updateAttributes(node);
};

export const handleImageLeftChange = (value: string, direction: number, props: PropertyBoxType, moveRef: any) => {
  const tempProps: IPropertyBox = _.cloneDeep(props.properties);
  const node = _.cloneDeep(props.nodeToUpdate);
  if (!_.has(props.nodeToUpdate, "transform")) {
    node.transform = { rotate: 0 };
  }
  const delta = (direction / (props.pageDims as DOMRect).width) * 100;
  tempProps.left = parseFloat(value);
  node.left = node.left + delta; //parseFloat((node.top + delta).toFixed(2));
  if (node.rawData && props.target) {
    props.target.style.left = Number(parseFloat(props.target.style.left) + direction).toFixed(2) + "px";
    props.target.getElementsByTagName("img")[0].style.left =
      Number(parseFloat(props.target.style.top) + direction) + "px";
  }
  moveRef.updateRect();
  props.changePropertyBox(tempProps);
  props.updateAttributes(node);
};

export const handleImageHeightChange = (
  value: string,
  target: any,
  props: PropertyBoxType,
  moveRef: any,
  stretch = true,
) => {
  // do not resize beyond the page boundaries
  const tempProps: IPropertyBox = _.cloneDeep(props.properties);
  const node = _.cloneDeep(props.nodeToUpdate);
  if (!_.has(props.nodeToUpdate, "transform")) {
    node.transform = { rotate: 0 };
  }
  const parsedVal = parseFloat(parseFloat(value).toFixed(2));
  const keepRatio = typeof props.nodeToUpdate.maintainRatio === "undefined" || props.nodeToUpdate.maintainRatio;
  const styleString = parsedVal + "px";
  const isCroppedImage = target && props.nodeToUpdate && hasClipCoordinates(props.nodeToUpdate);

  tempProps.height = parseFloat(parseFloat(value).toFixed(2));
  node.height = parseFloat(value);
  node.height = (node.height / (props.pageDims as DOMRect).height) * 100;
  props.target.style.height = styleString;
  const imgElement = props.target.getElementsByTagName("img")[0];
  imgElement.style.height = styleString;
  if (keepRatio) {
    if (!imgElement) return;
    const { naturalHeight: height, naturalWidth: width } = imgElement;
    // const width =  ((parseFloat(target.style.width )* ((props.pageDims as DOMRect).width) / 100));
    // const height = ((parseFloat(target.style.height )* ((props.pageDims as DOMRect).height))) / 100
    const diffAbs = parseFloat(value) - height;
    const ratio = width / height;
    const diffRel = diffAbs * ratio;
    const newWidth = Math.round(width + diffRel);
    tempProps.width = newWidth;
    node.width = newWidth;
    node.width = (node.width / (props.pageDims as DOMRect).width) * 100;
    props.target.style.width = newWidth.toFixed(2) + "px";
    imgElement.style.width = newWidth.toFixed(2) + "px";
  }

  moveRef.updateRect();
  props.changePropertyBox(tempProps);

  if (isCroppedImage) {
    const moveableState = moveRef.moveable.state;
    node.clipPath.croppedImagePixelData = moveableState.croppedRect;
  }

  props.updateAttributes(node);
};

export const handleImageWidthChange = (value: string, target: any, props: PropertyBoxType, moveRef: any) => {
  const tempProps: IPropertyBox = _.cloneDeep(props.properties);
  const node = _.cloneDeep(props.nodeToUpdate);
  const parsedVal = parseFloat(parseFloat(value).toFixed(2));
  const keepRatio = typeof props.nodeToUpdate.maintainRatio === "undefined" || props.nodeToUpdate.maintainRatio;
  const styleString = parsedVal + "px";
  const isCroppedImage = target && props.nodeToUpdate && hasClipCoordinates(props.nodeToUpdate);

  tempProps.width = parsedVal;
  props.target.style.width = styleString;
  node.width = (parsedVal / (props.pageDims as DOMRect).width) * 100;
  const imgElement = props.target.getElementsByTagName("img")[0];
  imgElement.style.width = styleString;
  if (keepRatio) {
    if (!imgElement) return;
    const { naturalHeight: height, naturalWidth: width } = imgElement;
    const diffAbs = parseFloat(value) - width;
    const ratio = height / width;
    const diffRel = diffAbs * ratio;
    const newHeight = Math.round(height + diffRel);
    tempProps.height = newHeight;
    node.height = newHeight;
    node.height = (node.height / (props.pageDims as DOMRect).height) * 100;
    props.target.style.height = newHeight.toFixed(2) + "px";
    imgElement.style.height = newHeight.toFixed(2) + "px";
  }

  if (!_.has(props.nodeToUpdate, "transform")) {
    node.transform = { rotate: 0 };
  }

  moveRef.updateRect();
  props.changePropertyBox(tempProps);

  if (isCroppedImage) {
    const moveableState = moveRef.moveable.state;
    node.clipPath.croppedImagePixelData = moveableState.croppedRect;
  }

  props.updateAttributes(node);
};

export const handleCroppedImageWidthChange = (
  target: any,
  value: string | number,
  delta: number,
  props: PropertyBoxType,
  moveRef: any,
  isCropping: any,
) => {
  const tempProps: IPropertyBox = _.cloneDeep(props.properties);
  const node = _.cloneDeep(props.nodeToUpdate);
  if (!_.has(props.nodeToUpdate, "transform")) {
    node.transform = { rotate: 0.0 };
  }
  if (!_.has(props.nodeToUpdate.transform, "scale")) {
    node.transform = { ...node.transform, scale: [1.0, 1.0] };
  }
  if (!node.hasOwnProperty("clipPath")) {
    node.clipPath = {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    };
  }
  if (isCropping) {
    //just change cropped area if is cropping
    const { width, height } = target.getBoundingClientRect();
    const change = (1 / width) * 100 * delta;
    if (node.clipPath.right <= 0) node.clipPath.right = 0.01;
    if (delta > 2 || delta < -1) {
      return;
    }
    node.clipPath.right = node.clipPath.right - change;
    node.clipPath = getUpdatedClipPath(Math.floor(width), Math.floor(height), node.clipPath);
    moveRef.updateRect();
    props.changePropertyBox(tempProps);
    props.updateAttributes(node);
    return;
  }
  const width = Math.floor(parseFloat(((parseFloat(node.width) * (props.pageDims as DOMRect).width) / 100).toFixed(2)));
  const scaleDelta = delta / width;
  const tempScale: number[] = [...node.transform.scale];

  if (props.shouldMaintainRatio) {
    node.transform = {
      rotate: node.transform.rotate,
      scale: tempScale.fill(tempScale[0] + scaleDelta),
    };
  } else {
    node.transform = {
      ...node.transform,
      scale: [tempScale[0] + scaleDelta, tempScale[1]],
    };
  }
  {
    const { width, height } = target.getBoundingClientRect();
    node.clipPath = getUpdatedClipPath(Math.floor(width), Math.floor(height), node.clipPath);
  }
  moveRef.updateRect();
  props.changePropertyBox(tempProps);
  props.updateAttributes(node);
};

export const handleCroppedImageHeightChange = (
  target: any,
  value: string | number,
  delta: number,
  props: PropertyBoxType,
  moveRef: any,
  isCropping: any,
) => {
  const tempProps: IPropertyBox = _.cloneDeep(props.properties);
  const node = _.cloneDeep(props.nodeToUpdate);

  if (!_.has(props.nodeToUpdate, "transform")) {
    node.transform = { rotate: 0.0 };
  }
  if (!_.has(props.nodeToUpdate.transform, "scale")) {
    node.transform = { ...node.transform, scale: [1.0, 1.0] };
  }

  if (!node.hasOwnProperty("clipPath")) {
    node.clipPath = {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    };
  }
  if (isCropping) {
    //just change cropped area if is cropping
    const { width, height } = target.getBoundingClientRect();
    const change = (1 / height) * 100 * delta;
    if (node.clipPath.top <= 0) node.clipPath.top = 0.01;
    node.clipPath.top = node.clipPath.top - change;
    node.clipPath = getUpdatedClipPath(Math.floor(width), Math.floor(height), node.clipPath);
    moveRef.updateRect();
    props.changePropertyBox(tempProps);
    props.updateAttributes(node);
    return;
  }

  const height = parseFloat(((parseFloat(node.height) * (props.pageDims as DOMRect).height) / 100).toFixed(2));
  const scaleDelta = delta / height;
  const tempScale: number[] = [...node.transform.scale];

  if (props.shouldMaintainRatio) {
    node.transform = {
      rotate: node.transform.rotate,
      scale: tempScale.fill(tempScale[0] + scaleDelta),
    };
  } else {
    node.transform = {
      ...node.transform,
      scale: [tempScale[0], tempScale[1] + scaleDelta],
    };
  }
  {
    const { width, height } = target.getBoundingClientRect();
    node.clipPath = getUpdatedClipPath(Math.floor(width), Math.floor(height), node.clipPath);
  }
  moveRef.updateRect();
  props.changePropertyBox(tempProps);
  props.updateAttributes(node);
};

export const handleRotationChange = (value: string, props: PropertyBoxType, moveRef: any) => {
  const tempProps: IPropertyBox = _.cloneDeep(props.properties);
  const node = _.cloneDeep(props.nodeToUpdate);
  if (!_.has(props.nodeToUpdate, "transform")) {
    node.transform = { rotate: 0 };
  }

  tempProps.rotation = parseFloat(parseFloat(value).toFixed(2));
  node.transform.rotate = parseFloat(parseFloat(value).toFixed(2));

  if (node.transform.rotate < 0) {
    node.transform.rotate += 360;
    tempProps.rotation += 360;
  }

  if (node.transform.rotate >= 360) {
    const multiple: number = node.transform.rotate / 360;
    node.transform.rotate -= multiple * 360;
    tempProps.rotation -= multiple * 360;
  }
  const isScorm = Array.from(props.target.classList).includes("ff-scorm");

  if (!isScorm) {
    moveRef.updateRect();
    props.changePropertyBox(tempProps);
    props.updateAttributes(node);
  }
};

export const handleWidthChange = (value: string, props: PropertyBoxType, moveRef: any) => {
  const tempProps: IPropertyBox = _.cloneDeep(props.properties);
  const node = _.cloneDeep(props.nodeToUpdate);
  if (!_.has(props.nodeToUpdate, "transform")) {
    node.transform = { rotate: 0 };
  }
  tempProps.width = parseFloat(value);
  node.width = parseFloat(value);
  node.width = (node.width / (props.pageDims as DOMRect).width) * 100;
  if (props.shouldMaintainRatio) {
    tempProps.height = parseFloat(parseFloat(value).toFixed(2));
    node.height = parseFloat(value);
    node.height = (node.height / (props.pageDims as DOMRect).height) * 100;
  }
  moveRef.updateRect();
  props.changePropertyBox(tempProps);
  props.updateAttributes(node);
};

export const handleHeightChange = (value: string, props: PropertyBoxType, moveRef: any) => {
  const tempProps: IPropertyBox = _.cloneDeep(props.properties);
  const node = _.cloneDeep(props.nodeToUpdate);
  if (!_.has(props.nodeToUpdate, "transform")) {
    node.transform = { rotate: 0 };
  }
  tempProps.height = parseFloat(value);
  node.height = parseFloat(value);
  node.height = (node.height / (props?.pageDims as DOMRect).height) * 100;
  if (props.shouldMaintainRatio) {
    tempProps.width = parseFloat(parseFloat(value).toFixed(2));
    node.width = parseFloat(parseFloat(value).toFixed(2));
    node.width((node.width / (props?.pageDims as DOMRect).width) * 100);
  }
  moveRef.updateRect();
  props.changePropertyBox(tempProps);
  props.updateAttributes(node);
};

export const restoreImage = (
  target: any,
  props: PropertyBoxType,
  moveRef: any,
  positions: number[],
  isCropping = false,
  bounds: any,
) => {
  const tempProps: IPropertyBox = _.cloneDeep(props.properties);
  const node = _.cloneDeep(props.nodeToUpdate);
  const { width, height } = target.getBoundingClientRect();
  const relWidth = parseFloat(((width / (props.pageDims as DOMRect).width) * 100).toFixed(2));
  const relHeight = parseFloat(((height / (props.pageDims as DOMRect).height) * 100).toFixed(2));
  const [newWidth] = getCroppedImageSize(target, props, moveRef);
  const clipObject = getClipObject([], 0, 0); // the function called with these parameters just returns a clipObject with all 0 values
  if (isCropping) {
    node.clipPath = clipObject;
    if (node.left < 10 || node.left > 90) node.left = 25;
    if (node.top < 10 || node.top > 90) node.top = 25;
    if (relWidth > 99 || relHeight > 99) {
      node.transform.scale = [1];
      target.style.transform = "";
    }
    target.style.top = `${node.top}%`;
    target.style.left = `${node.left}%`;
    moveRef.updateRect();
    props.updateAttributes(node);
    return;
  }

  const scaleFactorWidth = props.pageDims?.width! / newWidth;

  node.clipPath = clipObject;
  // node.left = 25;
  // node.top = 25;

  if (node.left < 0) {
    node.left = 0;
  }

  if (node.top < 0) {
    node.top = 0;
  }
  // if (node.left < 10 || node.left > 90) node.left = 25;
  // if(node.top < 10 || node.top > 90) node.top= 25;
  // if(relWidth > 90 || relHeight > 90) {
  //   node.transform = {rotate: 0, scale: [1]};
  // }

  target.style.clipPath = "";
  // target.style.transform = ""
  target.style.top = `${node.top}%`;
  target.style.left = `${node.left}%`;
  moveRef.updateRect();
  props.changePropertyBox(tempProps);
  props.updateAttributes(node);
};

export const handleKeyPress = (
  e: React.KeyboardEvent<HTMLDivElement | SVGSVGElement | HTMLVideoElement>,
  kp: IKeyPressHandler,
) => {
  const deltaH: number = parseFloat(((1 / (kp.props.pageDims?.height as number)) * 100).toFixed(2));
  const deltaW: number = parseFloat(((1 / (kp.props.pageDims?.width as number)) * 100).toFixed(2));
  e.preventDefault();
  switch (e.key) {
    case "w":
    case "W":
    case "ArrowUp":
      const up: number = parseFloat(e.currentTarget.style.top);
      let value = 0;
      if (kp.props.nodeToUpdate.type === "pageImage") e.currentTarget.style.top = `${up - deltaH}px`;
      else e.currentTarget.style.top = `${up - deltaH}%`;
      if (kp.props.pbRefs.opbTopRef.current) {
        value = parseFloat(kp.props.pbRefs.opbTopRef.current.value);
        kp.props.pbRefs.opbTopRef.current.value = `${value - 1}`;
      }
      kp.props.moveRef.updateRect();
      break;
    case "s":
    case "S":
    case "ArrowDown":
      const down = parseFloat(e.currentTarget.style.bottom);
      let valueD = 0;
      if (kp.props.nodeToUpdate.type === "pageImage") e.currentTarget.style.top = `${down - deltaH}px`;
      else e.currentTarget.style.top = `${down - deltaH}%`;
      if (kp.props.pbRefs.opbTopRef.current) {
        valueD = parseFloat(kp.props.pbRefs.opbTopRef.current.value);
        kp.props.pbRefs.opbTopRef.current.value = `${valueD + 1}`;
      }
      kp.props.moveRef.updateRect();
      break;
    case "a":
    case "A":
    case "ArrowLeft":
      const left = parseFloat(e.currentTarget.style.left);
      let valueL = 0;
      if (kp.props.nodeToUpdate.type === "pageImage") e.currentTarget.style.left = `${left - deltaW}px`;
      else e.currentTarget.style.left = `${left - deltaW}%`;
      if (kp.props.pbRefs.opbLeftRef.current) {
        valueL = parseFloat(kp.props.pbRefs.opbLeftRef.current.value);
        kp.props.pbRefs.opbLeftRef.current.value = `${valueL - 1}`;
      }

      kp.props.moveRef.updateRect();
      break;
    case "d":
    case "D":
    case "ArrowRight":
      const right = parseFloat(e.currentTarget.style.right);
      let valueR = 0;
      if (kp.props.nodeToUpdate.type === "pageImage") e.currentTarget.style.left = `${right - deltaW}px`;
      else e.currentTarget.style.left = `${right - deltaW}%`;
      if (kp.props.pbRefs.opbLeftRef.current) {
        valueR = parseFloat(kp.props.pbRefs.opbLeftRef.current.value);
        kp.props.pbRefs.opbLeftRef.current.value = `${valueR + 1}`;
      }
      kp.props.moveRef.updateRect();
      break;
    case "Delete":
    case "Backspace":
      kp.removeSelected();
      break;
    case "Escape":
      kp.setTarget(null);
      break;
    default:
      break;
  }
};

export function handleKeyUp(
  e: React.KeyboardEvent<HTMLDivElement | SVGSVGElement | HTMLVideoElement>,
  kp: IKeyPressHandler,
) {
  switch (e.key) {
    case "ArrowUp":
    case "ArrowDown":
      if (kp.props.pbRefs.opbTopRef.current) {
        handleTopChange(kp.props.pbRefs.opbTopRef.current?.value, kp.props, kp.props.moveRef);
      }
      if (kp.props.endActions && kp.props.nodeToUpdate.type === "pageImage") {
        kp.props.endActions(kp.props.target, kp.props.nodeToUpdate, e);
      }
      break;
    case "ArrowLeft":
    case "ArrowRight":
      if (kp.props.pbRefs.opbLeftRef.current) {
        handleLeftChange(kp.props.pbRefs.opbLeftRef.current.value, kp.props, kp.props.moveRef);
      }
      if (kp.props.endActions && kp.props.nodeToUpdate.type === "pageImage") {
        kp.props.endActions(kp.props.target, kp.props.nodeToUpdate, e);
      }
      break;
    default:
      break;
  }
}

export function changePropertyBox(properties: IPropertyBox, propertyBox: IPropertyBox) {
  const temp: IPropertyBox = _.cloneDeep(properties);

  if (!_.isEqual(temp, propertyBox)) {
    return temp;
  }
  return propertyBox;
}

export const dispatchWidthChange = (
  elementType: string,
  value: string,
  target: any,
  props: PropertyBoxType,
  moveRef: any,
) => {
  const inputValue = parseFloat(value) > 15 ? value : "15";
  if (elementType === "pageImage") {
    return handleImageWidthChange(inputValue, target, props, moveRef);
  }
  return handleWidthChange(inputValue, props, moveRef);
};

export const dispatchHeightChange = (
  elementType: string,
  value: string,
  target: any,
  props: PropertyBoxType,
  moveRef: any,
) => {
  const inputValue = parseFloat(value) > 15 ? value : "15";
  if (elementType === "pageImage") {
    return handleImageHeightChange(inputValue, target, props, moveRef);
  }
  return handleHeightChange(inputValue, props, moveRef);
};

const dispatchTopChange = (elementType: string, value: string, delta: number, props: PropertyBoxType, moveRef: any) => {
  if (elementType === "pageImage") {
    return handleImageTopChange(value, delta, props, moveRef);
  }
  return handleTopChange(value, props, moveRef);
};

const dispatchLeftChange = (
  elementType: string,
  value: string,
  delta: number,
  props: PropertyBoxType,
  moveRef: any,
) => {
  if (elementType === "pageImage") {
    return handleImageLeftChange(value, delta, props, moveRef);
  }
  return handleLeftChange(value, props, moveRef);
};

export const changeTop = (
  elementType: string,
  e: ChangeEvent<HTMLInputElement>,
  properties: IPropertyBox,
  props: any,
  moveRef: any,
) => {
  dispatchTopChange(
    elementType,
    e.currentTarget.value,
    parseFloat(e.currentTarget.value) - properties.top,
    props,
    moveRef,
  );
};

export const changeLeft = (
  elementType: string,
  e: ChangeEvent<HTMLInputElement>,
  properties: IPropertyBox,
  props: any,
  moveRef: any,
) => {
  dispatchLeftChange(
    elementType,
    e.currentTarget.value,
    parseFloat(e.currentTarget.value) - properties.left,
    props,
    moveRef,
  );
};

// export const restoreImageDispatch = (target: any, props: any, moveRef:any, positions: number[], bounds) => {
//   return restoreImage(target, props, moveRef, positions, bounds);
// }
