import React, { useLayoutEffect, useMemo } from "react";
import { clippedSpaceFromUnitSpace, snapToGrid, useTimeline } from "../../contexts/TimelineProvider/TimelineProvider";
import { useDomRef } from "../../hooks/useDomRef";

import createGrid from "./createGrid";
import "./GridBackground.css";
export default function GridBackground() {
  const [timeline, timelineDispatch] = useTimeline();
  return (
    <div className="grid-background" style={{ width: timeline.widthInPx }}>
      <FrameGrid />
    </div>
  );
}
const getBackingStoreRatio = (ctx: CanvasRenderingContext2D): number => {
  const _ctx = ctx as any;
  return (
    _ctx.webkitBackingStorePixelRatio ||
    _ctx.mozBackingStorePixelRatio ||
    _ctx.msBackingStorePixelRatio ||
    _ctx.oBackingStorePixelRatio ||
    _ctx.backingStorePixelRatio ||
    1
  );
};
export const getDevicePixelRatio = () => window.devicePixelRatio || 1;
export const getRatio = (ctx: CanvasRenderingContext2D) => {
  const devPxRatio = getDevicePixelRatio();
  const backingStoreRatio = getBackingStoreRatio(ctx);
  const ratio = devPxRatio / backingStoreRatio;
  return ratio;
};
function FrameGrid() {
  const [timeline, timelineDispatch] = useTimeline();
  const {
    clippedSpace,
    widthInPx,
    scaledSpace: { leftPadding },
    subUnitsPerUnit,
    heightInPx,
  } = timeline;
  const [containerRef, setContainerRef] = useDomRef();
  const [canvasRef, setCanvasRef] = useDomRef<HTMLCanvasElement>();
  const { ctx, ratio } = useMemo(() => {
    if (!canvasRef) return {};
    const ctx = canvasRef.getContext("2d")!;
    const ratio = 1;

    return { ctx, ratio };
  }, [canvasRef]);

  useLayoutEffect(() => {
    if (!ctx) return;

    canvasRef!.width = widthInPx * ratio!;
    canvasRef!.height = heightInPx * ratio!;

    drawGrid({
      clippedSpaceRange: clippedSpace.range,
      clippedSpaceWidth: widthInPx,
      ctx,
      leftPadding,
      fps: subUnitsPerUnit,
      height: heightInPx,
    });

    return () => {};
  }, [ctx, widthInPx, heightInPx, clippedSpace.range.start, clippedSpace.range.end, leftPadding, subUnitsPerUnit]);

  return (
    <div ref={setContainerRef} className="frame-grid-container">
      <canvas
        ref={setCanvasRef}
        style={{
          width: `${widthInPx}px`,
          height: `${heightInPx}px`,
        }}
      ></canvas>
    </div>
  );
}

function drawGrid(
  opts: {
    clippedSpaceWidth: number;
    height: number;
    ctx: CanvasRenderingContext2D;
    leftPadding: number;
  } & Parameters<typeof createGrid>[0],
) {
  const { clippedSpaceWidth, height, ctx } = opts;

  ctx.clearRect(0, 0, clippedSpaceWidth, height);

  createGrid(opts, (_posInUnitSpace, isFullSecond) => {
    const posInUnitSpace = snapToGrid(_posInUnitSpace, opts.fps);
    const posInClippedSpace = Math.floor(
      clippedSpaceFromUnitSpace(
        posInUnitSpace,
        opts.leftPadding,
        { range: opts.clippedSpaceRange },
        opts.clippedSpaceWidth,
      ),
    );

    ctx.strokeStyle = isFullSecond ? "rgba(221, 218, 220, 0.53)" : "rgba(196, 188, 193, 0.7)";
    ctx.beginPath();
    ctx.moveTo(posInClippedSpace, 0);
    ctx.lineTo(posInClippedSpace, height);
    ctx.stroke();
    ctx.closePath();
  });
}
