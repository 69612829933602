import React, { useContext, useEffect, useState } from "react";
import Asset from "./interfaces/Asset";
import { EditorContext } from "./MetaDataEditorContext/MetadataEditorContext";
import { MetadataEditorActions } from "./MetaDataEditorContext/MetadataEditorActions";
import { ReactComponent as ExpandIcon } from "../../assets/icons/expand-icon-gray.svg";
import { ReactComponent as SelectedCheckbox } from "../../assets/icons/checkmark-selected.svg";
import { ReactComponent as UnSelectedCheckbox } from "../../assets/icons/checkmark-not-selected.svg";

interface Props {
  asset: Asset;
  allAssetsSelected: boolean | undefined;
  handleSelectAsset: (assetId: number) => void;
  handleUpdateDescription: (assetId: number, description: string) => void;
}

const SingleAssetContainer = ({ asset, handleSelectAsset, handleUpdateDescription, allAssetsSelected }: Props) => {
  const [assetDescription, setAssetDescription] = useState<string>(asset.description);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(true);
  const { dispatch } = useContext(EditorContext);
  const [defaultDescription] = useState(asset.description);

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // this function first sets the image description state and then sends it for update to the parent
    setAssetDescription(e.target.value);
    handleUpdateDescription(asset.assetVersionId, e.target.value);
  };

  const handleOpenPreview = () => {
    dispatch({
      type: MetadataEditorActions.OPEN_ASSET_PREVIEW,
      payload: { assetURL: asset.blobPath, assetType: asset.assetTypeName },
    });
  };

  useEffect(() => {
    setChecked(() => (allAssetsSelected ? true : false));
  }, [allAssetsSelected]);

  useEffect(() => {
    setAssetDescription(asset.description);
  }, []);

  return (
    <>
      <div className={`individual-image-container ${asset.isSubmitted ? "submitted-asset" : ""}`}>
        <ExpandIcon className="metadata-editor-expand-icon" onClick={handleOpenPreview}></ExpandIcon>
        <div>
          {"Video" == asset.assetTypeName ? (
            <video /*autoPlay*/
              className={
                /*loading
                ? 'individual-image-container-loading-img'
                :*/ "individual-image-container-video"
              }
              onLoad={() => setLoading(false)}
              src={`/api/Asset/${asset.blobPath}`}
            />
          ) : (
            <img
              className={loading ? "individual-image-container-loading-img" : "individual-image-container-img"}
              alt={asset.description}
              onLoad={() => setLoading(false)}
              src={`/api/Asset/${asset.blobPath}`}
            />
          )}
        </div>

        <div className="individual-image-input">
          <div className="individual-image-name-input" placeholder={asset.name}>
            <p>{asset.name}</p>
          </div>
          <input
            className={
              "individual-image-description-input " +
              `${assetDescription.length === 0 ? "description-input-incomplete" : ""}`
            }
            value={assetDescription}
            onFocus={(e) => {
              if (assetDescription === defaultDescription) {
                setAssetDescription("");
                handleUpdateDescription(asset.assetVersionId, "");
              }
            }}
            onChange={(e) => handleDescriptionChange(e)}
            placeholder="*Add a description"
            type="text"
          />
        </div>
        <div
          className={
            asset.isSelected
              ? "metadata-editor-single-asset-checkbox-selected"
              : "metadata-editor-single-asset-checkbox-unselected"
          }
          onClick={(e) => {
            e.preventDefault();
            setChecked((prevState) => !prevState);
            handleSelectAsset(asset.assetVersionId);
          }}
        >
          {asset.isSelected ? <SelectedCheckbox /> : <UnSelectedCheckbox />}
        </div>
      </div>
    </>
  );
};

export default SingleAssetContainer;
