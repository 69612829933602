export interface GlobalTimerConfig {
  FPS: number;
}
export enum TimerState {
  RUNNING,
  STOPPED,
}

export enum TimerEvents {
  // global events
  TICK = "tick",
  // state events
  START = "start",
  STOP = "stop",
}
