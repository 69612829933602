import React from "react";
import blobUrlFormatHelper from "../../components/blobUrlFormatHelper";
interface AspectRatioContainerProps {
  height: number;
  width: number;
  src: string;
  additionalStyle?: any;
  onLoadedImage?: any;
  onLoadError?: any;
  imgRef?: any;
}
interface RelativeContainer {
  height: string;
  width: string;
  position: "relative";
  maxWidth: "100%";
  maxHeight: "100%";
  display: "flex";
  justifyContent: "center";
  alignItems: "center";
}
interface AbsoluteContainer {
  height: string;
  width: string;
  position: "absolute";
  maxWidth: "100%";
  maxHeight: "100%";
  display: "flex";
  justifyContent: "center";
  alignItems: "center";
}

function AspectRatioContainer(props: AspectRatioContainerProps) {
  const relativeContainerStyles: RelativeContainer = {
    height: "100%",
    width: "100%",
    position: "relative",
    maxWidth: "100%",
    maxHeight: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const absoluteContainerStyle: AbsoluteContainer = {
    height: `${props.height}%`,
    width: `${props.width}%`,
    position: "absolute",
    maxWidth: "100%",
    maxHeight: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const imageStyles = {
    maxHeight: "100%",
    maxWidth: "100%",
  };

  return (
    <div style={{ ...relativeContainerStyles }}>
      <div style={{ ...absoluteContainerStyle }}>
        <img
          onLoad={props.onLoadedImage}
          style={{
            ...imageStyles,
            ...(props.additionalStyle ? props.additionalStyle : {}),
          }}
          src={blobUrlFormatHelper(props.src)}
          alt=""
        />
      </div>
    </div>
  );
}

export default AspectRatioContainer;
