import React from "react";
import AspectRatioContainer from "../../../services/Utils/AspectRatioContainer";
import * as AssetManagerTypes from "../AssetManager.types";
import IAsset from "../../../models/IAsset";
import { ReactComponent as PdfPlaceholder } from "../../../assets/icons/AssetManager/uploading-file-pdf-icon.svg";
import { ASSET_TYPE_ID } from "../../../const";
import { getAssetThumbnail } from "./AssetLibrary";
import { DetailView360p } from "../../360Panoramic/DetailView360p";
function AssetLibraryDetailedView(props: any) {
  const handleAssetClick = (asset: IAsset) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
    (props.AssetManagerDispatch as AssetManagerTypes.Dispatch<IAsset>)({
      type: "SELECT_ASSET",
      payload: asset,
    });
  const isSelected = (el: any) => {
    return el.assetVersionId === props.selectedAsset.assetId;
  };

  const thumbnail = getAssetThumbnail(props.selectedAsset, props.typeMode);

  return (
    <div className="assetLibrary-detailView-container">
      <div className="assetLibrary-detailView-assetsContainer">
        {props.data.map((assetInView: any, index: number) => {
          let name = assetInView.name;
          const selected: string = isSelected(assetInView) ? "selected" : "";

          if (props.typeMode === ASSET_TYPE_ID.SMART_OBJECT && assetInView.smartObject) {
            name = assetInView.smartObject?.smartObjectSvg ?? assetInView.name;
          }

          if (index > 5) return <></>;
          return (
            <div
              key={"aldvks" + index}
              className="assetLibrary-detailView-singleAsset-container-border"
              style={
                isSelected(assetInView)
                  ? {
                      background: "var(--cpat-green-hover)",
                      color: "var(--buttons-color)",
                      fontWeight: 700,
                    }
                  : {}
              }
            >
              <div
                className={`assetLibrary-detailView-singleAsset-container ${selected}`}
                onClick={handleAssetClick(assetInView)}
              >
                <div title={assetInView.name} className="detailView-singleAsset-name">
                  <div style={isSelected(assetInView) ? { fontWeight: 700 } : {}}>
                    Name: <p style={isSelected(assetInView) ? { fontWeight: 700 } : {}}>{name}</p>
                  </div>
                </div>
                <div title={assetInView.description} className="detailView-singleAsset-description">
                  <div style={isSelected(assetInView) ? { fontWeight: 700 } : {}}>
                    Description: <p>{assetInView.description}</p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="assetLibrary-detailView-viewport">
        {(props.typeMode === ASSET_TYPE_ID.IMAGE || props.typeMode === ASSET_TYPE_ID.SMART_OBJECT) && thumbnail && (
          <AspectRatioContainer
            height={100}
            width={100}
            src={thumbnail}
            additionalStyle={{ position: "absolute" }}
            onLoadedImage={() => {}}
          />
        )}
        {props.typeMode === ASSET_TYPE_ID.PANORAMIC_IMAGE && props.selectedAsset.panoramicImages && (
          <DetailView360p
            panoramicImages={props.selectedAsset.panoramicImages}
            blobPath={props.selectedAsset.blobPath}
          />
        )}
        {props.typeMode === ASSET_TYPE_ID.PDF && (
          // <embed
          //   // sandbox="true"
          //   //the pdf will NOT work if the content-type (MIME) headers of the pdf are "octet-stream"
          //   height="100%"
          //   width="100%"
          //   type="application/pdf, application/octet-stream"
          //   itemType="application/pdf"
          //   src={'/api/Asset/' + props.selectedAsset.blobPath}
          //   ></embed>
          <PdfPlaceholder />
        )}
        {props.typeMode === ASSET_TYPE_ID.VIDEO && (
          <video
            src={"/api/Asset/" + props.selectedAsset.blobPath}
            width="100%"
            height="100%"
            controls={true}
            autoPlay={false}
          />
        )}
        {props.typeMode === ASSET_TYPE_ID.SCORM && (
          <React.Fragment>
            <iframe style={{ display: "none" }} src="/REF_API_FAKE_1484_11.html" name="API_1484_11" />
            <iframe
              src={"../../api/Asset/" + props.selectedAsset.blobPath + "/index.html"}
              width="100%"
              height="56.25%"
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export default AssetLibraryDetailedView;
