import * as LessonSettingsTypes from "../LessonSettings.types";

export const setLessonSettingsTab: LessonSettingsTypes.setLessonSettingsTab = (state, action) => {
  return { ...state, lessonSettingsTab: action.payload };
};
export const setLessonMetaData: LessonSettingsTypes.setLessonMetaData = (state, action) => {
  return {
    ...state,
    lessonMetaData: { ...state.lessonMetaData, ...action.payload },
  };
};
export const setLessonMetaDataLocalCopy: LessonSettingsTypes.setLessonMetaDataLocalCopy = (state, action) => {
  if (action.payload.lessonName !== undefined && action.payload.lessonName.length <= 200) {
    return {
      ...state,
      lessonMetaDataLocalCopy: {
        ...state.lessonMetaDataLocalCopy,
        ...action.payload,
      },
    };
  } else if (action.payload.lessonName === undefined) {
    return {
      ...state,
      lessonMetaDataLocalCopy: {
        ...state.lessonMetaDataLocalCopy,
        ...action.payload,
      },
    };
  } else {
    return {
      ...state,
      lessonMetaDataLocalCopy: { ...state.lessonMetaDataLocalCopy },
    };
  }
  // if(action.payload)
};

export const reconcileLessonMetaData: LessonSettingsTypes.setLessonMetaDataLocalCopy = (state, action) => {
  if (action?.payload?.additionalSettings?.studentLanguages?.length === 0) {
    action.payload.additionalSettings.studentLanguages.push("English");
  }
  return {
    ...state,
    lessonMetaData: action.payload,
    lessonMetaDataLocalCopy: action.payload,
  };
};

export const cancel = () => {};

export const removeLessonReference: LessonSettingsTypes.removeLessonReference = (state, action) => {
  const currentReferences = [...state.lessonMetaDataLocalCopy.lessonReferences];
  let deleteIndex = -1;
  currentReferences.forEach((ref, index) => {
    if (ref.assetVersionId === action.payload.assetVersionId) {
      deleteIndex = index;
    }
  });
  if (deleteIndex !== -1) {
    currentReferences.splice(deleteIndex, 1);
  }
  return {
    ...state,
    lessonMetaDataLocalCopy: {
      ...state.lessonMetaDataLocalCopy,
      lessonReferences: currentReferences,
    },
  };
};
