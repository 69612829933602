import React, { useState, useContext, createContext, useReducer, useEffect } from "react";
import { ITable, ICell, IColumn, IRow, ITableData, Table } from "./ITable";
import { IPageContext, PageContext } from "../../routes/builderContexts";
import Moveable, { OnDragEnd } from "../react-moveable";
import lodash from "lodash";
import shadowClone from "./shadowClone";
import { nanoid } from "../../lib/nanoId";
import { ObjectActionsType } from "../../contexts/ObjectsProvider";

interface reducerAction {
  action: string;
  payload: any[];
}

const tableData: ITableData = {
  selectedTable: undefined,
  selectedTableData: undefined,
  selectedRow: undefined,
  selectedColumn: undefined,
  selectedCell: undefined,
  tables: undefined,
  movableObjectRef: undefined,
  selectedTableDomRef: undefined,
};

const defaultRowHeight = 50;
const defaultColumnWidth = 100;
const defaultCell = { content: "Cell Text" };

const TablesDataState = createContext<ITableData>(tableData);
const TablesDataDispatch = createContext<any>(null);
// const pageContext: IPageContext = useContext<IPageContext>(PageContext);

function reducer(state: ITableData, action: reducerAction) {
  switch (action.action) {
    case "addTable":
      return addTable(state, action.payload);
    case "addTablesDomRef":
      return addTablesDomRef(state, action.payload);
    case "deleteTable":
      return deleteTable(state, action.payload);
    case "setSelectedTable":
      return setSelectedTable(state, action.payload);
    case "setSelectedRow":
      return setSelectedRow(state, action.payload);
    case "setSelectedColumn":
      return setSelectedColumn(state, action.payload);
    case "setSelectedCell":
      return setSelectedCell(state, action.payload);
    case "insertRow":
      return insertRow(state, action.payload);
    case "insertColumn":
      return insertColumn(state, action.payload);
    case "removeRow":
      return removeRow(state, action.payload);
    case "removeColumn":
      return removeColumn(state, action.payload);
    case "increaseColumnWidth":
      return increaseColumnWidth(state, action.payload);
    case "reduceColumnWidth":
      return reduceColumnWidth(state, action.payload);
    case "increaseRowHeight":
      return increaseRowHeight(state, action.payload);
    case "reduceRowHeight":
      return reduceRowHeight(state, action.payload);
    case "updateTableSize":
      return updateTableSize(state, action.payload);
    case "SET_MOVABLE_OBJECT_REF":
      return { ...state, movableObjectRef: action.payload }; // spreads state and sets movableObjectRef from action.payload
    case "UPDATE_PAGE_MANIFEST":
      return updatePageManifest(state, action.payload);
    case "GET_TABLES_FROM_MANIFEST":
      return getTablesFromManifest(state, action.payload);
    case "UPDATE_CELL_CONTENT":
      return updateCellContent(state, action.payload);
    case "resetState":
      return resetState(state, action.payload);
    default:
      return state;
  }
}

////// Reducer Functions /////////////

function resetState(state: ITableData, payload: any[]) {
  const newState = { ...tableData };
  return newState;
}

function updateCellContent(state: ITableData, payload: any[]) {
  const newState = { ...state };
  const newSelectedTableData: ITable = { ...state.selectedTableData };

  newSelectedTableData.cells[state.selectedCell.index].content = payload[0];
  newState.selectedTableData = newSelectedTableData;

  // let cellNewContent = pageContext.pageManifest.tables[state.selectedTable].cells[state.selectedCell].content
  // let newPageManifest = lodash.cloneDeep(pageContext.pageManifest)
  // newPageManifest.tables[state.selectedTable].cells[state.selectedCell].content = payload[0]

  // pageContext.updatePageManifest(newPageManifest);
  // state.selectedTable.index
  return state;
}

function updatePageManifest(state: ITableData, payload: any[]) {
  return state;
}

function getTablesFromManifest(state: ITableData, payload: any[]) {
  const newState = { ...state };
  const pageManifestTables = payload[0];
  if (pageManifestTables)
    pageManifestTables.forEach((table: ITable, index: number) => {
      if (table.objectId === undefined) {
        table.objectId = "table-" + nanoid();
      }
    });

  newState.tables = pageManifestTables;

  return newState;
}

function addTable(state: ITableData, payload: any[]) {
  // const table = new Table([],[]);
  const newState = { ...state };
  const tablesArray = newState.tables !== undefined ? newState.tables : [];
  const pagePlayerArea = document.getElementById("pageplayerarea");
  const pagePlayerAreaRect = pagePlayerArea?.getBoundingClientRect();

  // determines possition of new table based on existing table data if any
  let newTablePos = { x: "15%", y: "15%", z: 0 };
  if (state.tables === undefined && state.selectedTable === undefined) {
    //noop
  } else if (state.tables !== undefined && state.selectedTable !== undefined) {
    const x =
      parseFloat(state.tables[state.selectedTable].position.x) +
      parseFloat(state.tables[state.selectedTable].size.width) +
      5 +
      "%";
    const y =
      parseFloat(state.tables[state.selectedTable].position.y) +
      parseFloat(state.tables[state.selectedTable].size.height) +
      5 +
      "%";
    newTablePos = {
      x: x,
      y: y,
      z: 0,
    };
  } else if (state.tables !== undefined && state.selectedTable === undefined) {
    const x = parseFloat(state.tables[state.tables.length - 1].position.x) + 5 + "%";
    const y = parseFloat(state.tables[state.tables.length - 1].position.y) + 5 + "%";
    newTablePos = {
      x: x,
      y: y,
      z: 0,
    };
  }

  function buildCellsArray(length: number) {
    const arr: ICell[] = new Array(length);
    for (let a = 0; a < length; a++) {
      arr[a] = { content: "", index: a };
    }
    return arr;
  }

  const newCellsArray = buildCellsArray(payload[0].row * payload[0].column);
  const tableToAdd: ITable = {
    name: "New Table",
    position: {
      x: newTablePos.x,
      y: newTablePos.y,
      z: 10,
    },
    objectId: "table-" + nanoid(),
    size: {
      height: ((payload[0].row * defaultRowHeight) / pagePlayerAreaRect!.height) * 100 + "%", /// where to get page player area size from to convert this to percent
      width: ((payload[0].column * defaultColumnWidth) / pagePlayerAreaRect!.width) * 100 + "%", // where to get page player araa size to convert this to percent
    },
    rows: new Array(payload[0].row).fill(100 / payload[0].row + "%"), // contains size data for rows
    columns: new Array(payload[0].column).fill(100 / payload[0].column + "%"), // contains size data for columns
    cells: [...newCellsArray], // contains data for cell content and format
    border: "1px solid white",
    backgroundColor: undefined,
    domRef: undefined,
    zIndex: 200 + (state?.tables?.length ?? 1) + 1,
    isDisplayed: true,
  };
  const objectsDispatch = payload[2];
  // if (objectsDispatch) {
  //   objectsDispatch({
  //     type: ObjectActionsType.ADD_NEW_OBJECT,
  //     object: tableToAdd,
  //   });
  // }
  tablesArray.push(tableToAdd);
  newState.tables = tablesArray;

  return newState;
}

function addTablesDomRef(state: ITableData, payload: any[]) {
  const newState = { ...state };
  // let newTables: ITable[] = [ ...state.tables ];
  // let nodeList = [...payload[0]]

  // for (var i = 0; i < nodeList.length; i++){
  //   newTables[i].domRef = nodeList[i]
  // }

  // newState.tables = newTables

  newState.selectedTableDomRef = payload[0];

  return newState;
}

function deleteTable(state: ITableData, payload: any[]) {
  const newState = { ...state };
  newState.tables = newState.tables?.filter((table: ITable, index: number) => {
    return index !== newState.selectedTable;
  });
  newState.selectedTable = undefined;

  if (newState.tables?.length === 0) {
    newState.tables = undefined;
  }
  // payload[0]();
  // let confirm = payload[0]
  // confirm()

  return newState;
}

function setSelectedTable(state: ITableData, payload: any[]) {
  const newState = { ...state };
  if (payload[0] === undefined) {
    newState.selectedTableData = undefined;
    newState.selectedTable = undefined;
    newState.selectedRow = undefined;
    newState.selectedColumn = undefined;
    newState.selectedCell = undefined;
  } else {
    newState.selectedTable = payload[0];
    // newState.selectedTableData = newState.tables[newState.selectedTable];
    if (state.selectedTable !== payload[0]) {
      newState.selectedRow = undefined;
      newState.selectedColumn = undefined;
      newState.selectedCell = undefined;
    }
  }
  return newState;
}

function setSelectedRow(state: ITableData, payload: any[]) {
  const newState = { ...state };
  newState.selectedRow = payload[0];
  newState.selectedColumn = undefined;
  newState.selectedCell = undefined;

  return newState;
}

function setSelectedColumn(state: ITableData, payload: any[]) {
  const newState = { ...state };
  newState.selectedColumn = payload[0];
  newState.selectedRow = undefined;
  newState.selectedCell = undefined;

  return newState;
}

function setSelectedCell(state: ITableData, payload: any[]) {
  const newState = { ...state };
  const selectedCell = {
    column: payload[0] + 1,
    row: payload[1] + 1,
    index: payload[2],
  };
  if (payload[0] === undefined) {
    newState.selectedCell = undefined;
  } else {
    newState.selectedCell = selectedCell;
  }
  newState.selectedColumn = undefined;
  newState.selectedRow = undefined;
  return newState;
}

function insertRow(state: ITableData, payload: any[]) {
  const pagePlayerArea = document.getElementById("pageplayerarea");
  const pagePlayerAreaRect = pagePlayerArea?.getBoundingClientRect();
  const newState: ITableData = { ...state };
  const newTables: ITable[] = [...state.tables];
  const newSelectedTableData: ITable = { ...state.selectedTableData };
  const newTableSize = { ...state.selectedTableData.size };
  let newRows = [...state.selectedTableData.rows];
  const newCells = [...state.selectedTableData.cells];
  const newCell = { content: "" };
  const cellsToInsert: ICell[] = new Array(state.selectedTableData.columns.length).fill({ ...newCell });
  const cellsToInsertIndex: number =
    state.selectedTableData.columns.length * (payload[0] === "above" ? state.selectedRow : state.selectedRow + 1);

  const newTableHeight =
    pagePlayerAreaRect!.height * (parseFloat(state.selectedTableData.size.height) / 100) + defaultRowHeight; //calculates the pixle height of the new table
  const newRowPercent: string = (defaultRowHeight * 100) / newTableHeight + "%";
  const defaultRowHeightPercent = (defaultRowHeight / pagePlayerAreaRect!.height) * 100;

  function updateRowHeights(rowPercent: string, index: number) {
    //
    // This function is used to itterate through each existing row and calculate its pixle height.
    // The new table pixle height is calculated.
    // The new table pixle height is then used to calculate a new % values for each row based on the new table height to maintain the current row pixle height.
    //
    // let newOldRowPercent: string;
    const oldRowPercent = parseFloat(rowPercent) / 100; // converts the % string for each existing rows height into a decimal.
    const rowHeight =
      pagePlayerAreaRect!.height * (parseFloat(state.selectedTableData.size.height) / 100) * oldRowPercent; // calculates the pixle height of each row.
    const newRowPercent = (rowHeight * 100) / newTableHeight + "%"; // calculates new % string based on new table pixle height and original row pixle height.
    return newRowPercent;
  }

  if (payload[0] === "above") {
    newCells.splice(cellsToInsertIndex, 0, ...cellsToInsert);
    newState.selectedRow = newState.selectedRow + 1;
  } else {
    newCells.splice(cellsToInsertIndex, 0, ...cellsToInsert);
    newState.selectedRow = newState.selectedRow;
  }

  newRows = newRows.map(updateRowHeights);
  newRows.splice(payload[0] === "before" ? state.selectedRow : state.selectedRow + 1, 0, newRowPercent);
  newTableSize.height = parseFloat(newTableSize.height) + defaultRowHeightPercent + "%";

  function cellIndexReset(cell: ICell, index: number) {
    cell.index = index;
    return { ...cell };
  }

  const newnewCells = newCells.map(cellIndexReset);

  const requester = state.movableObjectRef.request("resizable");
  const key = "offsetHeight";
  const value = (parseFloat(newTableSize.height) / 100) * pagePlayerAreaRect!.height;
  requester.request({ [key]: value });

  newSelectedTableData.rows = [...newRows];
  newSelectedTableData.size = { ...newTableSize };
  newSelectedTableData.cells = [...newnewCells];

  newTables[state.selectedTable] = { ...newSelectedTableData };

  newState.selectedTableData = { ...newSelectedTableData };
  newState.tables = [...newTables];

  return newState;
}

function insertColumn(state: ITableData, payload: any[]) {
  const pagePlayerArea = document.getElementById("pageplayerarea");
  const pagePlayerAreaRect = pagePlayerArea?.getBoundingClientRect();
  const newState = { ...state };
  const newTables = [...state.tables];
  const newSelectedTableData: ITable = { ...state.selectedTableData };
  const newTableSize = { ...state.selectedTableData.size };
  let newColumns = [...state.selectedTableData.columns];
  const newCells = [...state.selectedTableData.cells];
  const newCell = { content: "" };
  const indexOfNewCells = []; /// index values for each new cell based on table size

  for (let rowNum = 0; rowNum < state.selectedTableData.rows.length; rowNum++) {
    const index = rowNum * state.selectedTableData.columns.length + state.selectedColumn;
    indexOfNewCells.push(index + (payload[0] === "before" ? 0 : 1));
  }

  for (let i = 0; i < indexOfNewCells.length; i++) newCells.splice(indexOfNewCells[i] + i, 0, newCell); /// inserts new array item at proper index for new table updates index value to account for previous cells added + 1, +2, +3 ext

  const newTableWidth =
    pagePlayerAreaRect!.width * (parseFloat(state.selectedTableData.size.width) / 100) + defaultColumnWidth; //calculates the pixle width of the new table
  const newColumnPercent: string = (defaultColumnWidth * 100) / newTableWidth + "%";
  const defaultColumnWidthPercent = (defaultColumnWidth / pagePlayerAreaRect!.width) * 100;

  function updateColumnWidths(columnPercent: string, index: number) {
    const oldColumnPercent = parseFloat(columnPercent) / 100;
    const columnWidth =
      pagePlayerAreaRect!.width * (parseFloat(state.selectedTableData.size.width) / 100) * oldColumnPercent; // calculates the pixle width of each column.
    const newColumnPercent = (columnWidth * 100) / newTableWidth + "%"; // calculates new % string based on new table pixle width and original column pixle width.
    return newColumnPercent;
  }

  newColumns = newColumns.map(updateColumnWidths);
  newColumns.splice(payload[0] === "before" ? state.selectedColumn : state.selectedColumn + 1, 0, newColumnPercent);

  function cellIndexReset(cell: ICell, index: number) {
    cell.index = index;
    return { ...cell };
  }

  const newnewCells = newCells.map(cellIndexReset);

  newTableSize.width = parseFloat(newTableSize.width) + defaultColumnWidthPercent + "%";
  const requester = state.movableObjectRef.request("resizable");
  const key = "offsetWidth";
  const value = (parseFloat(newTableSize.width) / 100) * pagePlayerAreaRect!.width;
  requester.request({ [key]: value });

  newSelectedTableData.columns = [...newColumns];
  newSelectedTableData.size = { ...newTableSize };
  newSelectedTableData.cells = [...newnewCells];

  newTables[state.selectedTable] = { ...newSelectedTableData };

  newState.selectedColumn = payload[0] === "before" ? newState.selectedColumn + 1 : newState.selectedColumn;
  newState.selectedTableData = { ...newSelectedTableData };
  newState.tables = [...newTables];

  return state.selectedColumn === undefined ? state : newState;
}

function removeRow(state: ITableData, payload: any[]) {
  const pagePlayerArea = document.getElementById("pageplayerarea");
  const pagePlayerAreaRect = pagePlayerArea?.getBoundingClientRect();
  const newState = { ...state };
  const newTables = [...state.tables];
  const selectedTableData: ITable = { ...state.selectedTableData };
  const selectedRowHeight = state.selectedTableData.rows[state.selectedRow];
  const originalrows = [...state.selectedTableData.rows];
  const originalCells = [...state.selectedTableData.cells];

  const originalTableSize = { ...state.selectedTableData.size };
  const originalTableHeightDecimalPercent = parseFloat(originalTableSize.height) / 100;
  const originalTableHeightPx = pagePlayerAreaRect!.height * originalTableHeightDecimalPercent;

  const originalRowHeightDecimalPercent = parseFloat(selectedRowHeight) / 100;
  const originalRowheightPx = originalTableHeightPx * originalRowHeightDecimalPercent;

  const newTableHeightPx = originalTableHeightPx - originalTableHeightPx * originalRowHeightDecimalPercent; //calculates the pixle height of the new table

  function updateRowHeights(rowPercent: string, index: number) {
    //
    // This function is used to itterate through each existing row and calculate its pixle height.
    // The new table pixle height is calculated.
    // The new table pixle height is then used to calculate a new % values for each row based on the new table height to maintain the current row pixle height.
    //
    // let newOldRowPercent: string;
    const oldRowDecimalPercent = parseFloat(rowPercent) / 100; // converts the % string for each existing rows height into a decimal.
    const rowHeightPx =
      pagePlayerAreaRect!.height * (parseFloat(state.selectedTableData.size.height) / 100) * oldRowDecimalPercent; // calculates the pixle height of each row.
    const newRowPercent = (rowHeightPx * 100) / newTableHeightPx + "%"; // calculates new % string based on new table pixle height and original row pixle height.
    return newRowPercent;
  }

  function removeRowCells(cells: ICell[]) {
    const cellsToRemoveStartIndex = state.selectedRow * selectedTableData.columns.length;
    const cellsToRemoveEndIndex = selectedTableData.columns.length;

    newCells.splice(cellsToRemoveStartIndex, cellsToRemoveEndIndex);
  }

  function cellIndexReset(cell: ICell, index: number) {
    cell.index = index;
    return { ...cell };
  }

  const newCells = [...originalCells];
  let newRows = [...originalrows];

  newRows = newRows.map(updateRowHeights);
  newRows.splice(state.selectedRow, 1);

  removeRowCells(newCells);
  newCells.map(cellIndexReset);

  selectedTableData.rows = [...newRows];
  selectedTableData.size = { ...originalTableSize };
  selectedTableData.cells = [...newCells];

  newTables[state.selectedTable] = { ...selectedTableData };

  newState.selectedTableData = { ...selectedTableData };
  newState.tables = [...newTables];

  const requester = state.movableObjectRef.request("resizable");
  const key = "offsetHeight";
  const value = newTableHeightPx;
  requester.request({ [key]: value });

  return newState;
}

function removeColumn(state: ITableData, payload: any[]) {
  const pagePlayerArea = document.getElementById("pageplayerarea");
  const pagePlayerAreaRect = pagePlayerArea?.getBoundingClientRect();
  const newState = { ...state };
  const newTables = [...state.tables];
  const selectedTableData: ITable = { ...state.selectedTableData };
  const selectedRowWidth = state.selectedTableData.columns[state.selectedColumn];
  const originalColumns = [...state.selectedTableData.columns];
  const originalCells = [...state.selectedTableData.cells];

  const originalTableSize = { ...state.selectedTableData.size };
  const originalTableWidthDecimalPercent = parseFloat(originalTableSize.width) / 100;
  const originalTableWidthPx = pagePlayerAreaRect!.width * originalTableWidthDecimalPercent;

  const originalRowWidthDecimalPercent = parseFloat(selectedRowWidth) / 100;
  const originalRowWidthPx = originalTableWidthPx * originalRowWidthDecimalPercent;

  const newTableWidthPx = originalTableWidthPx - originalTableWidthPx * originalRowWidthDecimalPercent; //calculates the pixle height of the new table

  function updateColumnWidths(columnPercent: string, index: number) {
    //
    // This function is used to itterate through each existing row and calculate its pixle height.
    // The new table pixle height is calculated.
    // The new table pixle height is then used to calculate a new % values for each row based on the new table height to maintain the current row pixle height.
    //
    // let newOldRowPercent: string;
    const oldColumnDecimalPercent = parseFloat(columnPercent) / 100; // converts the % string for each existing rows height into a decimal.
    const columnWidthPx =
      pagePlayerAreaRect!.width * (parseFloat(state.selectedTableData.size.width) / 100) * oldColumnDecimalPercent; // calculates the pixle height of each row.
    const newColumnPercent = (columnWidthPx * 100) / newTableWidthPx + "%"; // calculates new % string based on new table pixle height and original row pixle height.
    return newColumnPercent;
  }

  function removeColumnCells() {
    const indexOfCellsToRemove: any = []; /// index values for each new cell based on table size
    for (let rowNum = 0; rowNum < state.selectedTableData.rows.length; rowNum++) {
      const index = rowNum * state.selectedTableData.columns.length + state.selectedColumn;
      indexOfCellsToRemove.push(index);
    }

    for (let i = 0; i < indexOfCellsToRemove.length; i++) {
      newCells.splice(indexOfCellsToRemove[i] - i, 1);
    }
  }

  function cellIndexReset(cell: ICell, index: number) {
    cell.index = index;
    return { ...cell };
  }

  let newColumns = [...originalColumns];

  newColumns = newColumns.map(updateColumnWidths);
  newColumns.splice(state.selectedColumn, 1);

  const newCells = [...originalCells];

  removeColumnCells();
  newCells.map(cellIndexReset);

  // removeColumnCells(newCells);
  // newCells.map(cellIndexReset);

  selectedTableData.columns = [...newColumns];
  selectedTableData.size = { ...originalTableSize };
  selectedTableData.cells = [...newCells];

  newTables[state.selectedTable] = { ...selectedTableData };

  newState.selectedTableData = { ...selectedTableData };
  newState.tables = [...newTables];

  const requester = state.movableObjectRef.request("resizable");
  const key = "offsetWidth";
  const value = newTableWidthPx;
  requester.request({ [key]: value });

  return newState;
}

function updateTableSize(state: ITableData, payload: any[]) {
  const newState = { ...state };
  const newSelectedTableData: ITable = { ...newState.selectedTableData };
  const left = payload[0];
  const top = payload[1];
  const width = payload[2];
  const height = payload[3];

  newSelectedTableData.size.width = width;
  newSelectedTableData.size.height = height;
  newSelectedTableData.position.x = left;
  newSelectedTableData.position.y = top;

  newState.selectedTableData = newSelectedTableData;

  return newState;
}

function reduceColumnWidth(state: ITableData, payload: any[]) {
  const newState = { ...state };
  const newSelectedTableData: ITable = { ...newState.selectedTableData };
  const newColumns = [...state.selectedTableData.columns];
  const pagePlayerArea = document.getElementById("pageplayerarea");
  const pagePlayerAreaRect = pagePlayerArea?.getBoundingClientRect();
  const onePixPercent = (1 / pagePlayerAreaRect!.width) * 100;
  newState.selectedColumn = payload[0];
  newState.selectedRow = undefined;
  newState.selectedCell = undefined;

  function updateColumnWidths(columnPercent: string, index: number) {
    if (index === state.selectedColumn) {
      // columnPercent = parseFloat(columnPercent) - 1 + "%";
      columnPercent = parseFloat(columnPercent) - onePixPercent + "%";
    } else {
      // columnPercent = (parseFloat(columnPercent)) + ( 1 / (newColumns.length - 1 )) + "%";
      columnPercent = parseFloat(columnPercent) + onePixPercent / (newColumns.length - 1) + "%";
    }
    return columnPercent;
  }

  const updatedColumns = newColumns.map(updateColumnWidths);

  newSelectedTableData.columns = [...updatedColumns];
  newSelectedTableData.size.width = parseFloat(state.selectedTableData.size.width) - onePixPercent + "%";

  const requester = state.movableObjectRef.request("resizable");
  const key = "offsetWidth";
  const value = (parseFloat(newSelectedTableData.size.width) / 100) * pagePlayerAreaRect!.width;
  requester.request({ [key]: value });

  newState.selectedTableData = { ...newSelectedTableData };
  newState.tables[state.selectedTable] = { ...newSelectedTableData };

  return newState;
}

function increaseColumnWidth(state: ITableData, payload: any[]) {
  const newState = { ...state };
  const newSelectedTableData: ITable = { ...state.selectedTableData };
  const newColumns = [...state.selectedTableData.columns];
  const pagePlayerArea = document.getElementById("pageplayerarea");
  const pagePlayerAreaRect = pagePlayerArea?.getBoundingClientRect();
  const onePixPercent = (1 / pagePlayerAreaRect!.width) * 100;
  newState.selectedColumn = payload[0];
  newState.selectedRow = undefined;
  newState.selectedCell = undefined;

  function updateColumnWidths(columnPercent: string, index: number) {
    if (index === state.selectedColumn) {
      columnPercent = parseFloat(columnPercent) + onePixPercent + "%";
    } else {
      columnPercent = parseFloat(columnPercent) - onePixPercent / (newColumns.length - 1) + "%";
    }
    return columnPercent;
  }

  const updatedColumns = newColumns.map(updateColumnWidths);

  newSelectedTableData.columns = [...updatedColumns];
  newSelectedTableData.size.width = parseFloat(state.selectedTableData.size.width) + onePixPercent + "%";

  const requester = state.movableObjectRef.request("resizable");
  const key = "offsetWidth";
  const value = (parseFloat(newSelectedTableData.size.width) / 100) * pagePlayerAreaRect!.width;
  requester.request({ [key]: value });

  newState.selectedTableData = { ...newSelectedTableData };
  newState.tables[state.selectedTable] = { ...newSelectedTableData };

  return newState;
}

function increaseRowHeight(state: ITableData, payload: any[]) {
  const newState = { ...state };
  const newSelectedTableData: ITable = { ...state.selectedTableData };
  const newRows = [...state.selectedTableData.rows];
  const pagePlayerArea = document.getElementById("pageplayerarea");
  const pagePlayerAreaRect = pagePlayerArea?.getBoundingClientRect();
  const onePixPercent = (1 / pagePlayerAreaRect!.height) * 100;
  newState.selectedRow = payload[0];
  newState.selectedColumn = undefined;
  newState.selectedCell = undefined;

  function updateRowHeights(rowPercent: string, index: number) {
    if (index === state.selectedRow) {
      rowPercent = parseFloat(rowPercent) + onePixPercent + "%";
    } else {
      rowPercent = parseFloat(rowPercent) - onePixPercent / (newRows.length - 1) + "%";
    }
    return rowPercent;
  }

  const updatedRows = newRows.map(updateRowHeights);

  newSelectedTableData.rows = [...updatedRows];
  newSelectedTableData.size.height = parseFloat(state.selectedTableData.size.height) + onePixPercent + "%";

  const requester = state.movableObjectRef.request("resizable");
  const key = "offsetHeight";
  const value = (parseFloat(newSelectedTableData.size.height) / 100) * pagePlayerAreaRect!.height;
  requester.request({ [key]: value });

  newState.selectedTableData = { ...newSelectedTableData };
  newState.tables[state.selectedTable] = { ...newSelectedTableData };

  return newState;
}

function reduceRowHeight(state: ITableData, payload: any[]) {
  const newState = { ...state };
  const newSelectedTableData: ITable = { ...newState.selectedTableData };
  const newRows = [...state.selectedTableData.rows];
  const pagePlayerArea = document.getElementById("pageplayerarea");
  const pagePlayerAreaRect = pagePlayerArea?.getBoundingClientRect();
  const onePixPercent = (1 / pagePlayerAreaRect!.height) * 100;
  newState.selectedRow = payload[0];
  newState.selectedColumn = undefined;
  newState.selectedCell = undefined;

  function updateRowHeights(rowPercent: string, index: number) {
    if (index === state.selectedRow) {
      rowPercent = parseFloat(rowPercent) - onePixPercent + "%";
    } else {
      rowPercent = parseFloat(rowPercent) + onePixPercent / (newRows.length - 1) + "%";
    }
    return rowPercent;
  }

  const updatedRows = newRows.map(updateRowHeights);

  newSelectedTableData.rows = [...updatedRows];
  newSelectedTableData.size.height = parseFloat(state.selectedTableData.size.height) - onePixPercent + "%";

  const requester = state.movableObjectRef.request("resizable");
  const key = "offsetHeight";
  const value = (parseFloat(newSelectedTableData.size.height) / 100) * pagePlayerAreaRect!.height;
  requester.request({ [key]: value });

  newState.selectedTableData = { ...newSelectedTableData };
  newState.tables[state.selectedTable] = { ...newSelectedTableData };

  return newState;
}

//////////////////////////////////////

export function useTablesDataState() {
  const ctx = useContext(TablesDataState);
  if (ctx === undefined) {
    throw new Error("Tables State Error");
  }
  return ctx;
}

export function useTablesDataDispatch() {
  const ctx = useContext(TablesDataDispatch);
  if (ctx === undefined) {
    throw new Error("Tables State Error");
  }
  return ctx;
}

export function TablesDataProvider({ children }: any) {
  const [state, dispatch] = useReducer(reducer, tableData);
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);

  function addTablesToManifest(state: ITableData) {
    const newManifest = lodash.cloneDeep(pageContext.pageManifest);
    if (!newManifest.tables) newManifest.tables = [];
    // saving names that are already in the manifest so no rewriting
    const names = newManifest.tables?.map((t: any) => {
      return t.name;
    });
    const zIndexs = newManifest.tables?.map((t: any) => {
      return t.zIndex;
    });
    let tables = lodash.cloneDeep(state.tables);
    tables = tables.map((t: any, i: number) => {
      if (names) {
        const name = names[i] || `Table ${i}`;
        t.name = name;
      }
      if (zIndexs?.[i]) {
        const zIndex = zIndexs[i] || 255;
        t.zIndex = zIndex;
      }
      return t;
    });

    if (tables !== undefined) {
      newManifest.tables = tables;
      pageContext.updatePageManifest(newManifest);
    } else {
      // newManifest.tables = tables;
      // pageContext.updatePageManifest(newManifest);
    }
  }

  function flushTableState(state: ITableData) {
    state = { ...tableData };
    // dispatch({action: "resetState", payload:[tableData]})
    // let newManifest = lodash.cloneDeep(pageContext.pageManifest);
    // let tables = lodash.cloneDeep(state.tables);
    // newManifest.tables = tables;
    // pageContext.updatePageManifest(newManifest);
  }

  useEffect(() => {
    if (state.tables === undefined) {
      // flushTableState(state);
    } else {
      // addTablesToManifest(state);
    }
  }, [state.tables?.length, state?.selectedTableData]);

  return (
    <TablesDataState.Provider value={state}>
      <TablesDataDispatch.Provider value={dispatch}>{children}</TablesDataDispatch.Provider>
    </TablesDataState.Provider>
  );
}
