import React, { ChangeEvent, useEffect, useState } from "react";
import styles from "./EditImageMenu.module.css";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/HeaderIcons/drop-down.svg";
import { ObjectActionsType, useObjectsState, useObjectsDispatch } from "../../../contexts/ObjectsProvider";
import { percentageToValue, valueToPercentage } from "../../../utils/Conversion";
import { useMovableElementsPlaneState } from "../../../contexts/MovableElementsPlaneProvider";

interface Props {
  isCropping: boolean;
  isCroppedImage: boolean;
  startCropping: () => void;
  restoreImage: () => any;
  nodeToUpdate: any;
  endActions?: any;
  target?: any;
}

const EditImageMenu = (props: Props) => {
  const objectsState = useObjectsState();
  const objectsDispatch = useObjectsDispatch();
  const selectedObject = objectsState.selectedObjects[0];
  const isCroppedImage = (selectedObject?.clipPath || selectedObject?.clipPathString) !== undefined ? true : false;
  const isCropping = objectsState.isCropping;
  const { viewportDOMElementWidth, viewportDOMElementHeight } = useMovableElementsPlaneState();

  const checkIfValidEntry = (e: ChangeEvent<HTMLInputElement>) => {
    if (parseInt(e.currentTarget.value) > parseInt(e.currentTarget.max)) {
      e.currentTarget.value = e.currentTarget.max;
    }

    if (parseInt(e.currentTarget.value) < parseInt(e.currentTarget.min)) {
      e.currentTarget.value = e.currentTarget.min;
    }

    return e.currentTarget.value;
  };

  const changeCropTop = (e: ChangeEvent<HTMLInputElement>) => {
    const newTopPixel = e.currentTarget.value;
    const objectHeightPixle = percentageToValue(selectedObject.height, viewportDOMElementHeight || 0);
    const newTopPercent = valueToPercentage(parseInt(newTopPixel), objectHeightPixle);
    const absoluteTop = objectHeightPixle * (newTopPercent / 100);
    const clipPath = selectedObject.clipPath ? selectedObject.clipPath : { top: 0, right: 0, bottom: 0, left: 0 };
    const clipPathClone = { ...clipPath, top: newTopPercent, absoluteTop: absoluteTop };
    const newClipPathString = `inset(${clipPathClone.top}% ${clipPathClone.right}% ${clipPathClone.bottom}% ${clipPathClone.left}%)`;
    objectsDispatch({
      type: ObjectActionsType.SET_OBJECT_CLIP_PATH,
      payload: {
        objectId: selectedObject.objectId,
        clipPath: clipPathClone,
        clipPathString: newClipPathString,
      },
    });
  };

  const changeCropBottom = (e: ChangeEvent<HTMLInputElement>) => {
    const newBottomPixel = e.currentTarget.value;
    const objectHeightPixle = percentageToValue(selectedObject.height, viewportDOMElementHeight || 0);
    const newBottomPercent = valueToPercentage(parseInt(newBottomPixel), objectHeightPixle);
    const absoluteBottom = objectHeightPixle * (newBottomPercent / 100);
    const clipPath = selectedObject.clipPath ? selectedObject.clipPath : { top: 0, right: 0, bottom: 0, left: 0 };
    const clipPathClone = { ...clipPath, bottom: newBottomPercent, absoluteBottom: absoluteBottom };
    const newClipPathString = `inset(${clipPathClone.top}% ${clipPathClone.right}% ${clipPathClone.bottom}% ${clipPathClone.left}%)`;
    objectsDispatch({
      type: ObjectActionsType.SET_OBJECT_CLIP_PATH,
      payload: {
        objectId: selectedObject.objectId,
        clipPath: clipPathClone,
        clipPathString: newClipPathString,
      },
    });
  };

  const changeCropLeft = (e: ChangeEvent<HTMLInputElement>) => {
    const newLeftPixel = e.currentTarget.value;
    const objectWidthPixle = percentageToValue(selectedObject.width, viewportDOMElementWidth || 0);
    const newLeftPercent = valueToPercentage(parseInt(newLeftPixel), objectWidthPixle);
    const absoluteLeft = objectWidthPixle * (newLeftPercent / 100);
    const clipPath = selectedObject.clipPath ? selectedObject.clipPath : { top: 0, right: 0, bottom: 0, left: 0 };
    const clipPathClone = { ...clipPath, left: newLeftPercent, absoluteLeft: absoluteLeft };
    const newClipPathString = `inset(${clipPathClone.top}% ${clipPathClone.right}% ${clipPathClone.bottom}% ${clipPathClone.left}%)`;
    objectsDispatch({
      type: ObjectActionsType.SET_OBJECT_CLIP_PATH,
      payload: {
        objectId: selectedObject.objectId,
        clipPath: clipPathClone,
        clipPathString: newClipPathString,
      },
    });
  };

  const changeCropRight = (e: ChangeEvent<HTMLInputElement>) => {
    const newRightPixel = e.currentTarget.value;
    const objectWidthPixle = percentageToValue(selectedObject.width, viewportDOMElementWidth || 0);
    const newRightPercent = valueToPercentage(parseInt(newRightPixel), objectWidthPixle);
    const absoluteRight = objectWidthPixle * (newRightPercent / 100);
    const clipPath = selectedObject.clipPath ? selectedObject.clipPath : { top: 0, right: 0, bottom: 0, left: 0 };
    const clipPathClone = { ...clipPath, right: newRightPercent, absoluteRight: absoluteRight };
    const newClipPathString = `inset(${clipPathClone.top}% ${clipPathClone.right}% ${clipPathClone.bottom}% ${clipPathClone.left}%)`;
    objectsDispatch({
      type: ObjectActionsType.SET_OBJECT_CLIP_PATH,
      payload: {
        objectId: selectedObject.objectId,
        clipPath: clipPathClone,
        clipPathString: newClipPathString,
      },
    });
  };

  return (
    <div>
      {/* <div className={styles.editImageHeader} onClick={() => setShowEditMenu((prevState) => !prevState)}>
        <div className={styles.editImageHeaderText}>Crop Image</div>
        <ArrowIcon style={showEditMenu ? { transform: "rotate(180deg)" } : {}} className={styles.menuArrow} />
      </div> */}
      {/* top property 
      /////////////
      //////////////*/}
      <div className={styles.croppedImagePropertiesContainer}>
        <section className={styles.croppedImageProperties}>
          <div className={styles.croppedImagePropertiesHeader}>Area to Crop</div>
          <label className={styles.croppedImagePropertiesLabel} htmlFor="top-property">
            <div className={styles.croppedImageText}>Top</div>
            <input
              type="number"
              name="top-property"
              className={styles.croppedImagePropertiesInput}
              disabled={!isCropping}
              min={0}
              max={percentageToValue(selectedObject.height, viewportDOMElementHeight || 0)}
              value={
                isCroppedImage
                  ? percentageToValue(
                      selectedObject.clipPath.top,
                      percentageToValue(selectedObject.height, viewportDOMElementHeight || 0) || 0,
                    ).toFixed(0)
                  : 0
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                changeCropTop(e);
              }}
            />
          </label>
          {/* right property
          //////////////////
          /////////////////*/}
          <label className={styles.croppedImagePropertiesLabel} htmlFor="right-property">
            <div className={styles.croppedImageText}>Right</div>
            <input
              type="number"
              name="right-property"
              className={styles.croppedImagePropertiesInput}
              disabled={!isCropping}
              min={0}
              max={percentageToValue(selectedObject.width, viewportDOMElementWidth || 0)}
              // props.nodeToUpdate?.clipPath?.croppedImagePixelData?.right.toFixed(0)
              value={
                isCroppedImage
                  ? percentageToValue(
                      selectedObject.clipPath.right,
                      percentageToValue(selectedObject.width, viewportDOMElementWidth || 0) || 0,
                    ).toFixed(0)
                  : 0
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                changeCropRight(e);
              }}
            />
          </label>
          {/* bottom property 
          //////////////////
          /////////////////*/}
          <label className={styles.croppedImagePropertiesLabel} htmlFor="bottom-property">
            <div className={styles.croppedImageText}>Bottom</div>
            <input
              type="number"
              name="bottom-property"
              className={styles.croppedImagePropertiesInput}
              disabled={!isCropping}
              min={0}
              max={percentageToValue(selectedObject.height, viewportDOMElementHeight || 0)}
              // props.nodeToUpdate?.clipPath?.croppedImagePixelData?.bottom.toFixed(0)
              value={
                isCroppedImage
                  ? percentageToValue(
                      selectedObject.clipPath.bottom,
                      percentageToValue(selectedObject.height, viewportDOMElementHeight || 0) || 0,
                    ).toFixed(0)
                  : 0
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                changeCropBottom(e);
              }}
            />
          </label>

          {/* left property 
          ///////////////////
          ////////////////////*/}
          <label className={styles.croppedImagePropertiesLabel} htmlFor="left-property">
            <div className={styles.croppedImageText}>Left</div>
            <input
              type="number"
              name="left-property"
              className={styles.croppedImagePropertiesInput}
              disabled={!isCropping}
              min={0}
              max={percentageToValue(selectedObject.width, viewportDOMElementWidth || 0)}
              // props.nodeToUpdate?.clipPath?.croppedImagePixelData?.left.toFixed(0)
              value={
                isCroppedImage
                  ? percentageToValue(
                      selectedObject.clipPath.left,
                      percentageToValue(selectedObject.width, viewportDOMElementWidth || 0) || 0,
                    ).toFixed(0)
                  : 0
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                changeCropLeft(e);
              }}
            />
          </label>
        </section>
        <div className={styles.editImageButtonContainer}>
          <button
            className={`${styles.editImageButton} ${isCropping ? styles.imageEditButtonActive : ""}`}
            id={styles.cropImageButton}
            onClick={() => {
              objectsDispatch({
                type: ObjectActionsType.TOGGLE_IS_CROPPING,
              });
            }}
          >
            Crop Image
          </button>
          <div className={styles.editImageButtonSeparator}></div>
          {isCroppedImage && (
            <button
              className={styles.editImageButton}
              onClick={() => {
                objectsDispatch({
                  type: ObjectActionsType.RESTORE_CROPPED_IMAGE,
                  payload: {
                    objectId: selectedObject.objectId,
                  },
                });
              }}
            >
              Restore Image
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditImageMenu;
