import { ReactComponent as PanoramaIcon } from "../../assets/icons/AssetManager/type-file-panoramic-icon.svg";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../contexts/ObjectsProvider";
import { ReactPhotoSphereViewer } from "../../lib/react-photo-sphere-viewer";
import { PanoramicObject } from "../../types";
import { useObjectPosition } from "../../hooks/useObjectPosition";
import blobUrlFormatHelper from "../../components/blobUrlFormatHelper";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./Panoramics.module.css";
import {
  InteractivityHotspotActionTypes,
  useInteracitvityHotspotDispatch,
} from "../../contexts/InteractivityHotspotProvider";
import { useInterpolatedFrame } from "../../hooks/useInterpolatedFrame";
import { CubemapAdapter } from "@photo-sphere-viewer/cubemap-adapter";
import { useObjectIsInTime } from "../../hooks/useObjectIsInTime";
import { objectOpacityFromRules } from "../../utils";
import { useLessonPagesState } from "../../contexts/LessonPagesProvider/LessonPagesProvider";
import { useTimeline } from "../../contexts/TimelineProvider/TimelineProvider";
import { useTimerStoreState } from "../../contexts/Timer";
import { TimerState } from "../../classes/GlobalTimer/types";
import { Viewer } from "@photo-sphere-viewer/core";
import { useMiscUI } from "../../contexts/MiscUI/MiscUIProvider";
import {
  SelectedObjectActionTypes,
  useSelectedObjectDispatch,
} from "../../contexts/SelectedObjectProvider/SelectedObjectProvider";
import { degreesToRadians, radiansToDegrees } from "../../utils/math";

interface PanoramicsProps {
  handleDoubleClick: (e: React.MouseEvent<HTMLDivElement | HTMLVideoElement>) => void;
}

export function Panoramics({ handleDoubleClick }: PanoramicsProps) {
  const { panoramicList } = useObjectsState();
  return panoramicList.map(
    ({
      objectId,
      cubeMap,
      assetBlobPath,
      left,
      top,
      width,
      height,
      rotation,
      pitch,
      yaw,
      moduleRef,
      isDisplayed,
      zIndex,
      zoom,
      ghost,
    }) => (
      <Panoramic
        key={objectId}
        objectId={objectId}
        cubeMap={cubeMap}
        assetBlobPath={assetBlobPath}
        left={left}
        top={top}
        width={width}
        height={height}
        rotate={rotation}
        yaw={yaw}
        zoom={zoom ?? 0}
        pitch={pitch}
        zIndex={zIndex}
        isDisplayed={isDisplayed}
        moduleRef={moduleRef}
        handleDoubleClick={handleDoubleClick}
        ghost={ghost}
      />
    ),
  );
}

interface PanoramicComponentProps {
  objectId: string;
  cubeMap: PanoramicObject["cubeMap"];
  assetBlobPath: PanoramicObject["assetBlobPath"];
  left: number;
  top: number;
  width: number;
  height: number;
  rotate?: number;
  opacity?: number;
  pitch?: number;
  yaw?: number;
  zoom?: number;
  zIndex?: number;
  moduleRef: any;
  isDisplayed: boolean;
  ghost?: boolean;
  handleDoubleClick: (e: React.MouseEvent<HTMLDivElement | HTMLVideoElement>) => void;
}
type ImagePaths = {
  [key in PanoramicObject["cubeMap"][number]["type"]]?: string;
};
function createCubePanoramicImages(cubeMap: PanoramicObject["cubeMap"], blobPath: string) {
  const images: ImagePaths = {};
  if (cubeMap.length < 6) return null;
  for (let i = 0; i < cubeMap.length; i++) {
    if (!cubeMap[i].path) return null;
    images[cubeMap[i].type] = blobUrlFormatHelper(blobPath + "/" + cubeMap[i].path);
  }
  return images;
}

export const Panoramic: FC<PanoramicComponentProps> = ({
  objectId,
  assetBlobPath,
  cubeMap,
  height,
  left,
  top,
  width,
  yaw,
  zoom,
  pitch,
  isDisplayed,
  zIndex = 0,
  handleDoubleClick,
  ghost = false,
}) => {
  const {
    position: [x, y],
    size: [widthWithFrames, heightWithFrames],
    opacity: opacityValue,
  } = useObjectPosition(objectId, top, left, width, height);
  const transformString = `translate(${x}px, ${y}px)`;
  const objectDispatch = useObjectsDispatch();
  const hotspotsDispatch = useInteracitvityHotspotDispatch();
  const panorama = useMemo(() => createCubePanoramicImages(cubeMap, assetBlobPath), [objectId, assetBlobPath]);
  const isInTime = useObjectIsInTime(objectId);
  const [dragging, setDragging] = useState(false);
  const [mouseOverPanoramic, setMouseOverPanoramic] = useState(false);
  return (
    <div
      className={`${panorama ? "" : styles.empty} panoramic-container`}
      data-objectid={objectId}
      style={{
        position: "absolute",
        width: `${widthWithFrames}px`,
        height: `${heightWithFrames}px`,
        opacity: objectOpacityFromRules(opacityValue, isDisplayed, isInTime, ghost),
        zIndex: zIndex,
        //   scale: `${scaleString}`,
        // rotate: `${rotateString}`,
        transform: transformString,
        filter: isDisplayed ? "none" : "grayScale(100%)",
      }}
      onMouseEnter={() => {
        setMouseOverPanoramic(true);
      }}
      onMouseLeave={() => {
        setMouseOverPanoramic(false);
      }}
      onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        hotspotsDispatch({
          type: InteractivityHotspotActionTypes.SET_CURRENT_HOTSPOT,
          payload: null,
        });
        if (!objectId) return;
        let type = ObjectActionsType.SET_SELECTED_OBJECT;
        if (e.ctrlKey) {
          type = ObjectActionsType.ADD_SELECTED_OBJECT;
        }
        objectDispatch({
          type,
          payload: { objectId },
        });
        setDragging(true);
      }}
      onMouseUp={() => {
        setDragging(false);
      }}
      onDoubleClick={(e: React.MouseEvent<HTMLDivElement>) => handleDoubleClick(e)}
    >
      <SphereWrapper
        panorama={panorama}
        yaw={yaw ?? 0}
        pitch={pitch ?? 0}
        objectId={objectId}
        zoom={zoom ?? 50}
        isInTime={isInTime}
        ghost={ghost}
        dragging={dragging}
        mouseOverPanoramic={mouseOverPanoramic}
      />
    </div>
  );
};

// const MemoizedSphereWrapper = memo(SphereWrapper, (prv, nxt) => {
//   if (prv.objectId !== nxt.objectId) return false;
//   if (prv.yaw !== nxt.yaw) return false;
//   if (prv.pitch !== nxt.pitch) return false;
//   if (prv.panorama !== nxt.panorama) return false;

//   return true;
// });

function SphereWrapper({
  objectId,
  yaw,
  pitch,
  panorama,
  zoom,
  isInTime = false,
  ghost = false,
  dragging,
  mouseOverPanoramic,
}: {
  // assetBlobPath: string;
  // cubeMap: PanoramicObject["cubeMap"];
  // moduleRef: any;
  objectId: string;
  yaw: number;
  pitch: number;
  panorama: any;
  zoom: number;
  isInTime?: boolean;
  ghost?: boolean;
  dragging: boolean;
  mouseOverPanoramic: boolean;
}) {
  const ref = useRef<any>();
  const objectDispatch = useObjectsDispatch();
  const interpolatedFrame = useInterpolatedFrame(objectId);
  const { selectedPanel } = useLessonPagesState();
  const [{ seekingOnTimeline }, setMisc] = useMiscUI();
  const [{ usingScrub, scrubbingCurrentTime }] = useTimeline();
  const { state } = useTimerStoreState();
  const isUsingScrubRef = useRef<boolean>();
  const timeRef = useRef<number>(0);
  const playingRef = useRef<TimerState>(state);
  const readyRef = useRef<boolean>(false);
  const seekingOnTimelineRef = useRef<boolean>(seekingOnTimeline);
  const draggingRef = useRef<boolean>(dragging);
  const mouseOverPanoramicRef = useRef<boolean>(mouseOverPanoramic);
  const objectsDispatch = useObjectsDispatch();
  const interpolatedPitch = interpolatedFrame?.pitch ?? pitch;
  const interpolatedYaw = interpolatedFrame?.yaw ?? yaw;
  const interpolatedZoom = interpolatedFrame?.zoom ?? zoom;
  const selectedObjectDispatch = useSelectedObjectDispatch();

  useEffect(() => {
    draggingRef.current = dragging;
  }, [dragging]);
  useEffect(() => {
    isUsingScrubRef.current = usingScrub;
    timeRef.current = scrubbingCurrentTime;
    playingRef.current = state;
    mouseOverPanoramicRef.current = mouseOverPanoramic;
    seekingOnTimelineRef.current = seekingOnTimeline;
  }, [usingScrub, scrubbingCurrentTime, state, seekingOnTimeline, mouseOverPanoramic]);

  useEffect(() => {
    const p = interpolatedPitch;
    const y = interpolatedYaw;

    if (interpolatedPitch) {
      ref.current?.rotate({ pitch: p, yaw: y });
      selectedObjectDispatch({
        type: SelectedObjectActionTypes.SET_PITCH,
        payload: radiansToDegrees(p),
      });
      selectedObjectDispatch({
        type: SelectedObjectActionTypes.SET_YAW,
        payload: radiansToDegrees(y),
      });
    }
  }, [interpolatedPitch, interpolatedYaw]);

  useEffect(() => {
    const z = interpolatedZoom || 0;
    if (typeof interpolatedZoom === "number") {
      ref.current?.zoom(z);
      selectedObjectDispatch({
        type: SelectedObjectActionTypes.SET_ZOOM,
        payload: z,
      });
    }
  }, [interpolatedZoom]);

  const refCallback = useCallback(
    (viewerModule: any) => {
      if (viewerModule?.viewer === ref.current) {
        return;
      }
      if (!viewerModule?.viewer) {
        return;
      }
      ref.current = viewerModule.viewer;
      if (viewerModule) {
        objectDispatch({
          type: ObjectActionsType.SET_OBJECT_MODULE_REF,
          objectId,
          module: viewerModule.viewer,
        });
      }
    },
    [objectId],
  );

  const onPositionChange = useCallback((pitch: number, yaw: number) => {
    if (isUsingScrubRef.current === true) return;
    if (playingRef.current === TimerState.RUNNING) return;
    if (readyRef.current === false) return;
    if (seekingOnTimelineRef.current === true) {
      seekingOnTimelineRef.current = false;
      setMisc({ type: "SEEKING_ON_TIMELINE", payload: false });
      return;
    }
    if (draggingRef.current !== true) return;

    // if the yaw goes over 180 degrees, normalize it to -180 to 180
    const normalizedYaw = yaw > Math.PI ? yaw - 2 * Math.PI : yaw;
    // if the pitch goes over 180 degrees, normalize it to -180 to 180
    const normalizedPitch = pitch > Math.PI ? pitch - 2 * Math.PI : pitch;

    const pitchInRadians = degreesToRadians(normalizedPitch);
    const yawInRadians = degreesToRadians(normalizedYaw);

    objectsDispatch({
      type: ObjectActionsType.UPSERT_OBJECT_FRAME,
      payload: {
        objectId,
        frame: {
          timestamp: timeRef.current,
          pitch: normalizedPitch,
          yaw: normalizedYaw,
        },
      },
    });
    objectDispatch({
      type: ObjectActionsType.UPDATE_OBJECT,
      payload: {
        objectId: objectId,
        object: { pitch: normalizedPitch },
      },
    });
    objectDispatch({
      type: ObjectActionsType.UPDATE_OBJECT,
      payload: {
        objectId: objectId,
        object: { yaw: normalizedYaw },
      },
    });
    selectedObjectDispatch({
      type: SelectedObjectActionTypes.SET_PITCH,
      payload: radiansToDegrees(normalizedPitch),
    });
    selectedObjectDispatch({
      type: SelectedObjectActionTypes.SET_YAW,
      payload: radiansToDegrees(normalizedYaw),
    });
  }, []);

  const onZoomChange = useCallback((changedZoom: any) => {
    if (mouseOverPanoramicRef.current === false) return;
    if (isUsingScrubRef.current === true) {
      return;
    }
    if (playingRef.current === TimerState.RUNNING) return;
    if (readyRef.current === false) return;
    if (seekingOnTimelineRef.current === true) {
      seekingOnTimelineRef.current = false;
      setMisc({ type: "SEEKING_ON_TIMELINE", payload: false });
      return;
    }

    objectsDispatch({
      type: ObjectActionsType.UPSERT_OBJECT_FRAME,
      payload: {
        objectId,
        frame: {
          timestamp: timeRef.current,
          zoom: changedZoom.zoomLevel,
        },
      },
    });
    objectDispatch({
      type: ObjectActionsType.UPDATE_OBJECT,
      payload: {
        objectId: objectId,
        object: { zoom: changedZoom.zoomLevel },
      },
    });
    selectedObjectDispatch({
      type: SelectedObjectActionTypes.SET_ZOOM,
      payload: changedZoom.zoomLevel,
    });
  }, []);

  const handleReady = useCallback(
    (viewer: Viewer) => {
      const pos = viewer.getPosition();
      if (pos.pitch !== interpolatedPitch || pos.yaw !== interpolatedYaw) {
        viewer.rotate({ pitch: interpolatedPitch, yaw: interpolatedYaw });
      }
      readyRef.current = true;
    },
    [interpolatedPitch, interpolatedYaw],
  );

  return (
    <>
      {panorama && (isInTime || ghost) && (
        <ReactPhotoSphereViewer
          adapter={CubemapAdapter}
          container="360p-container"
          src={panorama}
          /**
           * According to official documentation when using the `CubemapAdapter`
           * the `src` prop is not needed and instead we should use the `panorama` prop
           * for the images however, this doesn't load the images properly
           * using the panorama object in the `src` prop correctly loads the sphere viewer
           * even though it throws a `type` error
           */
          width="100%"
          height="100%"
          defaultYaw={yaw}
          defaultPitch={pitch}
          onPositionChange={onPositionChange}
          onZoomChange={onZoomChange}
          defaultZoomLvl={zoom}
          onReady={handleReady}
          ref={refCallback}
          /**
           * These properties disable the ability to move around
           * the panoramic object, it should only be able to move around
           * through the panels on the right of the lesson designer
           */
          navbar={false}
          mousemove={selectedPanel === "advanced"}
          mousewheel={selectedPanel === "advanced"}
          touchmoveTwoFingers={selectedPanel === "advanced"}
        />
      )}
      {!panorama && (
        <span>
          <PanoramaIcon width={200} height={200} />
        </span>
      )}
    </>
  );
}
