import React from "react";

type SelectorType = {
  handleStepTypeSelect: (e: React.MouseEvent<HTMLDivElement>) => void;
};

const StepTypeSelector = (props: SelectorType) => {
  return (
    <React.Fragment>
      <div>
        <span>Choose an Activity:</span>
      </div>
      <div
        className="iga-type-select-btn"
        id="1"
        onClick={(e: React.MouseEvent<HTMLDivElement>) => props.handleStepTypeSelect(e)}
      >
        <span>Interactive Graphic</span>
      </div>
      <div
        className="iga-type-select-btn"
        id="2"
        onClick={(e: React.MouseEvent<HTMLDivElement>) => props.handleStepTypeSelect(e)}
      >
        <span>Standard Quiz</span>
      </div>
      <div
        className="iga-type-select-btn"
        id="3"
        onClick={(e: React.MouseEvent<HTMLDivElement>) => props.handleStepTypeSelect(e)}
      >
        <span>Graphic Quiz</span>
      </div>
    </React.Fragment>
  );
};

export default StepTypeSelector;

//  <label className="stepFormLabel"> Step Type to add
//   <select className="stepFormLabelSelect" onChange={(e: React.ChangeEvent<HTMLSelectElement>) => props.addActionHandler(e)}  >
//     <option> Select a step type </option>
//     <option value="1"> Add Interactive Graphic </option>
//     <option value="2"> Add Question W / Text Answers </option>
//     <option value="3"> Add Question w/ Graphic Answers </option>
//   </select>
// </label>
