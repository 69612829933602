import { useLessonPage } from "../../../../contexts/LessonPagesProvider/LessonPagesProvider";
import { PageAction } from "../PageAction";
import React, { ChangeEventHandler, useEffect, useState } from "react";

import "./index.css";
import useDebounce from "../../../../hooks/useDebounce";

export const PageTextAction = () => {
  const {
    currentLessonPage: { pageManifest },
    updateManifest,
  } = useLessonPage();

  // Initialize with manifest value if there is any
  const [pageText, setPageText] = useState(pageManifest?.pageText ?? "");
  // Using debounce, we do not want to hit our context update for every keystroke
  const debouncedPageText = useDebounce(pageText, 250);

  const handleChangeText: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    event.preventDefault();

    setPageText(event.target.value);
  };

  useEffect(() => {
    updateManifest({
      pageText: debouncedPageText,
    });
  }, [debouncedPageText]);

  // Update/restart state value if manifest changes
  useEffect(() => {
    setPageText(pageManifest?.pageText ?? "");
  }, [pageManifest?.pageText]);

  if (!pageManifest) {
    return null;
  }

  return (
    <PageAction title="Page Text" defaultOpen>
      <textarea
        className="page-text-textarea"
        name="pageText"
        id="pageText"
        onChange={handleChangeText}
        value={pageText}
        placeholder="Enter Text"
      />
    </PageAction>
  );
};
