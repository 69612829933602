import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill-2";
import "./tables.css";
import { useMiscUI } from "../../contexts/MiscUI/MiscUIProvider";
import { parseSemanticVersioning } from "../../utils/Versioning/versioning";
import {
  OLD_EM_FONT_WHITELIST,
  BASE_PAGES_QUILL_FONT_SIZE_WHITELIST,
  OLD_REM_FONT_WHITELIST,
} from "../../../src/components/Annotation/AnnotationTextMenu/constants";
import { useTablesDataDispatch, useTablesDataState } from "./TablesDataProvider";
import { PageContext } from "../../routes/builderContexts";
import _ from "lodash";
import { useObjectsDispatch, useObjectsState, ObjectActionsType } from "../../contexts/ObjectsProvider";
const standardFormats = [
  "background",
  "bold",
  "color",
  "font",
  "italic",
  "link",
  "size",
  "strike",
  "script",
  "underline",
  "blockquote",
  "header",
  "indent",
  "list",
  "align",
  "direction",
  "image",
  "video",
];
export const QUILL_BLOCK_CLASS_NAME = "standard-blot-block";
export const QUILL_BLOCK_TAG_NAME = "P";
export const QUILL_BLOCK_CLASS_ATTRIBUTOR_NAME = "ql-custom-size-block";
export const QUILL_INLINE_CLASS_ATTRIBUTOR_NAME = "ql-custom-size-inline";

const Parchment = Quill.import("parchment");
const Block = Quill.import("blots/block");
const TextBlot = Quill.import("blots/text");

// let cachedQuill: any = undefined;
class CustomMainBlock extends Block {}
class CustomTextBlot extends TextBlot {
  static create(value: any) {
    return document.createTextNode(value);
  }
}
function createQuillHandlers(options: any) {
  return {
    // background: function background(value) {

    //   const format = this.quill.getFormat()

    //   if(format.list) {
    //     this.quill.format('backgroundColorListItem', value)
    //   }
    //   this.quill.format('background', value)
    // },
    color: function color(value: string) {
      if (!this.quill) return;
      const format = this.quill.getFormat();
      if (format.list) {
        this.quill.format("colorListItem", value, "user");
      }
      this.quill.format("color", value, "user");
    },
    size: function size(value) {
      if (!this.quill) return;
      // const format = this.quill.getFormat();
      // const range = this.quill.getSelection();
      // const blockSize = parseInt(format.blockSize, 10);
      // const size = parseInt(format.size, 10);

      const version = parseSemanticVersioning(options.version);
      if (version) {
        const lineHeightEditVersion = version[0] >= 1 && version[1] >= 1;
        if (lineHeightEditVersion || options.isBubble) {
          this.quill.format("blockSize", value, "user");
        }
      }
      this.quill.format("size", value, "user");
    },
    clearFormat: function clearFormat() {
      if (!this.quill) return;

      const sel = this.quill.getSelection();
      this.quill.removeFormat(sel.index, sel.length, "user");
      this.quill.format("size", "normal", "user");
      this.quill.format("blockSize", "normal", "user");
    },
  } as {
    [key: string]: any;
    quill?: Quill;
  };
}
CustomMainBlock.tagName = QUILL_BLOCK_TAG_NAME;
CustomMainBlock.className = QUILL_BLOCK_CLASS_NAME;

const OldCustomSizeEMStyleAttributor = new Parchment.StyleAttributor("size", "font-size", {
  //there are hard coded SCOPES within Parchment.Scope the do various different things.
  scope: Parchment.Scope.INLINE,
  whitelist: [...OLD_EM_FONT_WHITELIST, ...OLD_REM_FONT_WHITELIST],
});

const CustomSizeInlineClassAttributor = new Parchment.ClassAttributor("size", QUILL_INLINE_CLASS_ATTRIBUTOR_NAME, {
  //there are hard coded SCOPES within Parchment.Scope the do various different things.
  scope: Parchment.Scope.INLINE,
  whitelist: BASE_PAGES_QUILL_FONT_SIZE_WHITELIST,
});

const CustomSizeBlockClassAttributor = new Parchment.ClassAttributor("blockSize", QUILL_BLOCK_CLASS_ATTRIBUTOR_NAME, {
  //there are hard coded SCOPES within Parchment.Scope the do various different things.
  scope: Parchment.Scope.BLOCK,
  whitelist: BASE_PAGES_QUILL_FONT_SIZE_WHITELIST,
});

const ListItemColorStyleAttributor = new Parchment.StyleAttributor("colorListItem", "color", {
  //there are hard coded SCOPES within Parchment.Scope the do various different things.
  scope: Parchment.Scope.BLOCK,
});

const RichTextEditor = (props: any) => {
  const pageContext = useContext(PageContext);
  const version = parseSemanticVersioning(props.version);

  const vOneOneZero = version && version[0] >= 1 && version[1] >= 1 && version[2] >= 0;
  const [{ quillRefreshToggle }] = useMiscUI();
  useEffect(() => {
    if (vOneOneZero) {
      Quill.register("formats/size", CustomSizeInlineClassAttributor);
      Quill.register("formats/blockSize", CustomSizeBlockClassAttributor);
      //below if we want bubble theme to be on each click
      // Quill.register('themes/bubble', ExtendBubbleTheme);
    } else {
      Quill.register("formats/size", OldCustomSizeEMStyleAttributor);
    }
    Quill.register("formats/color", ListItemColorStyleAttributor);
    Quill.register("blots/block", CustomMainBlock);
    Quill.register("blots/text", CustomTextBlot);
  }, [quillRefreshToggle]);

  const TablesDispatch = useTablesDataDispatch();
  const TablesState = useTablesDataState();
  const objectsState = useObjectsState();
  const objectsDispatch = useObjectsDispatch();

  let controlDown = false;
  document.addEventListener("keydown", function (event) {
    const ctrlKeyPressed = event.ctrlKey;

    // Now you can use ctrlKeyPressed in your logic
    if (ctrlKeyPressed) {
      // Do something when 'SomeKey' and 'Ctrl' are pressed together
      controlDown = true;
    }
  });

  const assignRawInput = (newText: any, delta: any, source: any, editor: any) => {
    if (source === "user") {
      const formattedString = newText;
      // setloadedText({ ...loadedText, rawInput: formattedString });
      objectsDispatch({
        type: ObjectActionsType.UPDATE_TABLE,
        payload: { type: "updateCell", value: formattedString, selection: TablesState.selectedCell },
      });
      // objectsState.selectedObjects[0].cells[TablesState.selectedCell.index].content = formattedString;
    } else if (source === "api" && controlDown) {
      const formattedString = newText;
      objectsDispatch({
        type: ObjectActionsType.UPDATE_TABLE,
        payload: { type: "updateCell", value: formattedString, selection: TablesState.selectedCell },
      });
    }
  };

  function handleChangeSelection(range: any, source: any, editor: any) {
    if (props.handleSelectionChange) {
      props.handleSelectionChange(range, source, editor);
    }
  }
  const modules = useMemo(() => {
    return {
      toolbar: {
        container: "#tables-rte-menu-" + props.objectId,
        handlers: createQuillHandlers(
          {
            version: props?.version,
            isBubble: false,
          } /**options */,
        ),
      },
    };
  }, [props.objectId, props?.version]);
  // if (TablesState.selectedCell !== undefined) {
  return (
    <ReactQuill
      id="editor"
      // ref={props.quillRef}
      theme={props.theme}
      value={props.displayText}
      className="max-height-one-hundred-percent heighBox"
      onChange={assignRawInput}
      formats={standardFormats}
      modules={modules}
      readOnly={false}
    />
  );
  // } else {
  //   return null;
  // }
};

export default RichTextEditor;
