import * as actionTypes from "./actionTypes";

export const addPage = (lessonPages) => (dispatch) => {
  dispatch({ type: actionTypes.ADD_PAGE, payload: lessonPages });
};

export const pageComplete = (data) => (dispatch) => {
  dispatch({ type: actionTypes.PAGE_COMPLETE, payload: true });
};

export const editPage = (page) => (dispatch) => {
  dispatch({ type: actionTypes.EDIT_PAGE, payload: page });
};

/**
 * Exports current lesson data to a playable form.
 * @param {String} exportType - type of export, ("local" or "network")
 */
export const exportData = (exportType) => (dispatch) => {
  dispatch({ type: actionTypes.EXPORT_DATA, payload: exportType });
};

export const setQuestion = (page, question) => (dispatch) => {
  dispatch({ type: actionTypes.SET_QUESTION, payload: { page, question } });
};

export const setQuestionAudio = (page, value) => (dispatch) => {
  dispatch({ type: actionTypes.SET_QUESTION_AUDIO, payload: { page, value } });
};

export const setQuestionNarrator = (page, value) => (dispatch) => {
  dispatch({ type: actionTypes.SET_QUESTION_NARRATOR, payload: { page, value } });
};

export const setCorrectAnswer = (page, answer) => (dispatch) => {
  dispatch({ type: actionTypes.SET_CORRECT_ANSWER, payload: { page, answer } });
};

export const addAnswer = (page, answer) => (dispatch) => {
  dispatch({ type: actionTypes.ADD_ANSWER, payload: { page, answer } });
};

export const removeAnswer = (page, answer) => (dispatch) => {
  dispatch({ type: actionTypes.REMOVE_ANSWER, payload: { page, answer } });
};

export const setPage = (num) => (dispatch) => {
  dispatch({ type: actionTypes.SET_PAGE, payload: num });
};

export const setImageSource = (page, value) => (dispatch) => {
  dispatch({ type: actionTypes.SET_IMAGE_SRC, payload: { page, value } });
};

export const setPromptCorrectText = (page, value) => (dispatch) => {
  dispatch({ type: actionTypes.SET_PROMPT_CORRECT_TXT, payload: { page, value } });
};

export const setPromptCorrectAudioSrc = (page, value) => (dispatch) => {
  dispatch({ type: actionTypes.SET_PROMPT_CORRECT_AUDIO, payload: { page, value } });
};

export const setPromptCorrectNarratorTxt = (page, value) => (dispatch) => {
  dispatch({ type: actionTypes.SET_PROMPT_CORRECT_NARRATOR, payload: { page, value } });
};

export const setPromptIncorrectText = (page, value) => (dispatch) => {
  dispatch({ type: actionTypes.SET_PROMPT_INCORRECT_TXT, payload: { page, value } });
};

export const setPromptIncorrectAudioSrc = (page, value) => (dispatch) => {
  dispatch({ type: actionTypes.SET_PROMPT_INCORRECT_AUDIO, payload: { page, value } });
};

export const setPromptIncorrectNarratorTxt = (page, value) => (dispatch) => {
  dispatch({ type: actionTypes.SET_PROMPT_INCORRECT_NARRATOR, payload: { page, value } });
};

export const setPromptAssistanceText = (page, value) => (dispatch) => {
  dispatch({ type: actionTypes.SET_PROMPT_ASSISTANCE, payload: { page, value } });
};

// 3d page actions

export const displayStepSystem = (data) => (dispatch) => {
  dispatch({ type: actionTypes.DISPLAY_STEP_SYSTEM, payload: data });
};
export const displayChooseStep = (data) => (dispatch) => {
  dispatch({ type: actionTypes.DISPLAY_CHOOSE_STEP, payload: data });
};
export const displayAddStep = (data) => (dispatch) => {
  dispatch({ type: actionTypes.DISPLAY_ADD_STEP, payload: data });
};
export const displayStepResponsePanel = (data) => (dispatch) => {
  dispatch({ type: actionTypes.DISPLAY_STEP_RESPONSE_PANEL, payload: data });
};
export const setdisplayStyle = (data) => (dispatch) => {
  dispatch({ type: actionTypes.DISPLAY_STYLE, payload: data });
};
export const storedCurrentStep = (data) => (dispatch) => {
  dispatch({ type: actionTypes.STORED_CURRENT_STEP, payload: data });
};

export const page3DText = (data) => (dispatch) => {
  dispatch({ type: actionTypes.PAGE3D_TEXT, payload: data });
};

export const setStepGraphic = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SET_STEP_GRAPHIC, payload: data });
};

export const showModal = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SHOW_MODAL, payload: "block" });
};

export const hideModal = (data) => (dispatch) => {
  if (data === true) {
    dispatch({ type: actionTypes.HIDE_MODAL, payload: "none" });
  } else {
    dispatch({ type: actionTypes.HIDE_MODAL, payload: "block" });
  }
};

export const hideWrongStep = (data) => (dispatch) => {
  dispatch({ type: actionTypes.HIDE_WRONG_STEP, payload: data });
};

export const setNarratorText = (text) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_TEXT,
    payload: text,
  });
};

export const setStepResponse1 = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SET_STEP_RESPONSE_1, payload: data });
};

export const setStepResponse2 = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SET_STEP_RESPONSE_2, payload: data });
};
export const setNarratorSize = (size) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_NARRATOR_SIZE,
    payload: size,
  });
};

export const setStepResponse3 = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SET_STEP_RESPONSE_3, payload: data });
};
export const setStepComplete = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SET_STEP_COMPLETE, payload: data });
};

export const setModel = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SET_MODEL, payload: data });
};
export const setCurtain = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SET_CURTAIN, payload: data });
};
export const setHotSpot = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SET_HOT_SPOT, payload: data });
};
export const setWAHotSpot = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SET_WA_HOT_SPOT, payload: data });
};
export const setRole = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SET_ROLE, payload: data });
};
export const setCustom = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SET_CUSTOM, payload: data });
};
export const setCondition = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SET_CONDITION, payload: data });
};
export const setManifest = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SET_MANIFEST, payload: data });
};
export const setBuilderStep = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SET_STEP_BUILDER_STEP, payload: data });
};
export const setStepObjectinRedux = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SET_STEP_OBJECT, payload: data });
};
export const setStepHotSpot = (imageIndex, data) => (dispatch) => {
  dispatch({ type: actionTypes.SET_STEP_HOT_SPOT, payload: data, imageIndex: imageIndex });
};
export const setStepImage = (imageIndex, imagePath, assetVersionId) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_STEP_IMAGE,
    imagePath: imagePath,
    imageIndex: imageIndex,
    assetVersionId: assetVersionId,
  });
};
export const resetStepImages = () => (dispatch) => {
  dispatch({ type: actionTypes.RESET_STEP_IMAGES });
};
export const setStepType = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SET_STEP_TYPE, payload: data });
};
export const setIsTerminal = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SET_IS_TERMINAL, payload: data });
};
export const setTaskDesc = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SET_TASK_DESC, payload: data });
};
export const setQuestion2 = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SET_QUESTION2, payload: data });
};
export const setChoices = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SET_CHOICES, payload: data });
};
export const setIGSOs = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SET_IGSO, payload: data });
};
export const setStepNumber = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SET_STEP_NUMBER, payload: data });
};
export const setMove = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SET_MOVE, payload: data });
};
export const setMoveTarget = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SET_MOVE_TARGET, payload: data });
};
export const setAllStepImages = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SET_ALL_STEP_IMAGES, payload: data });
};
export const setisIGS = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SET_IS_IGS, payload: data });
};
export const setBuildGrid = (data) => (dispatch) => {
  dispatch({ type: actionTypes.WA_BUILD_GRID, payload: data });
};
export const setHSList = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SET_HS_LIST, payload: data });
};
export const setHsData = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SET_CURRENT_HSDATA, payload: data });
};
export const setHsListerIsOpen = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SET_HSLISTER_OPEN, payload: data });
};
