import React, { useEffect, useRef, useState } from "react";
import { LabelTextEditorMenu } from "./LabelTextEditorMenu";
import RTE, { Quill } from "react-quill";
import { RangeStatic } from "quill";
import {
  BASE_PAGES_QUILL_FONT_SIZE_WHITELIST,
  OLD_EM_FONT_WHITELIST,
} from "../../Annotation/AnnotationTextMenu/constants";
import { adjustLabelHeight } from "../../Annotation/AnnotationFunctions/AnnotationLabelFunctions";
import { SVGAnnotationProps } from "../index";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../../contexts/ObjectsProvider";
import { useMovableElementsPlaneState } from "../../../contexts/MovableElementsPlaneProvider";
import { useSelectedObjectState } from "../../../contexts/SelectedObjectProvider/SelectedObjectProvider";
import AnnotationHighlight from "../../../pageTypes/BasicPage_Player/components/BaseFreeForm/components/HighlightRect";

const MIN_LINE_HEIGHT = 28;

type LabelType = SVGAnnotationProps & {
  text: string;
  height: number;
  width: number;
};

const sizeStyle = Quill.import("attributors/style/size");
sizeStyle.whitelist = [...BASE_PAGES_QUILL_FONT_SIZE_WHITELIST, ...OLD_EM_FONT_WHITELIST];
Quill.register(sizeStyle, true);

export function AnnotationLabel({
  backgroundColor,
  borderColor,
  height,
  objectId,
  text,
  opacity,
  onMouseDown,
  rotate,
  width,
  x,
  y,
  zIndex,
  strokeWidth,
  isDisplayed,
}: LabelType) {
  const objectsDispatch = useObjectsDispatch();
  const { viewportDOMElementHeight, viewportDOMElementWidth } = useMovableElementsPlaneState();
  const {
    x: selectedX,
    y: selectedY,
    width: selectedWidth,
    height: selectedHeight,
    rotation: selectedRotation,
  } = useSelectedObjectState();

  const { selectedObjects } = useObjectsState();
  const [selectedFontSize, setSelectedFontSize]: [string, React.Dispatch<React.SetStateAction<string>>] =
    useState<string>("1em");
  const [hovering, setHovering] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const quillRef: React.RefObject<any> = useRef<any>(null);
  const annotationRef = React.useRef<HTMLDivElement>(null);

  const transformString = `translate(${x}px, ${y}px) rotate(${rotate ?? 0}deg)`;
  const formats: Array<string> = [
    "background",
    "bold",
    "color",
    "font",
    "italic",
    "size",
    "underline",
    "indent",
    "align",
  ];

  useEffect(() => {
    const externalClick = () => {
      setIsActive(false);
    };
    document.addEventListener("mousedown", externalClick);
    document.addEventListener("mouseup", externalClick);
    return () => {
      document.removeEventListener("mousedown", externalClick);
      document.removeEventListener("mouseup", externalClick);
    };
  }, []);

  function handleTextChange(text: string) {
    let scrollHeight = MIN_LINE_HEIGHT;
    if (text === "<p><br></p>") text = "";

    if (quillRef.current?.editingArea?.scrollHeight >= scrollHeight) {
      scrollHeight = adjustLabelHeight(quillRef.current.editingArea.scrollHeight, {
        height: viewportDOMElementHeight ?? 0,
      });
    }

    objectsDispatch({
      type: ObjectActionsType.UPDATE_LABEL,
      payload: {
        objectId: objectId,
        height: scrollHeight,
        text: text,
      },
    });
  }

  const doTextClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    // setIsActive(false);
    quillRef.current?.focus();
    setIsActive(true);
    objectsDispatch({
      type: ObjectActionsType.SET_SELECTED_OBJECT,
      payload: null,
    });
  };

  const moveableContainerMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onMouseDown(e, objectId);
    setIsActive(false);
  };

  function handleSelectionChange(range: RangeStatic) {
    if (range !== null) {
      const attr = quillRef.current.editor.getFormat(range.index, range.length);
      if ("size" in attr) {
        setSelectedFontSize(attr.size);
      } else {
        setSelectedFontSize("1em");
      }
    }
  }

  const modules = {
    toolbar: {
      container: `#menu-${objectId}`,
    },
  };
  const thereIsASelectedObject = selectedObjects.length > 0;
  let menuPositionY = thereIsASelectedObject ? selectedY : y;
  let menuPositionX = thereIsASelectedObject ? selectedX + selectedWidth / 2 : x + selectedWidth / 2;
  const menuHeight = 38;
  const menuWidth = 483;
  const topTrigger = 75;
  const rightTrigger = (viewportDOMElementWidth ?? 0) - menuWidth;

  const xx = thereIsASelectedObject ? selectedX : x;
  const yy = thereIsASelectedObject ? selectedY : y;
  const ww = thereIsASelectedObject ? selectedWidth : width;
  const hh = thereIsASelectedObject ? selectedHeight : height;
  const rr = thereIsASelectedObject ? selectedRotation : rotate ?? 0;
  const rad = (rr * Math.PI) / 180; // convert to radians
  // find the center of the box
  const cx = xx + ww / 2;
  const cy = yy + hh / 2;

  // find the original unrotated corners
  const corners = [
    { x: xx, y: yy },
    { x: xx + ww, y: yy },
    { x: xx + ww, y: yy + hh },
    { x: xx, y: yy + hh },
  ];
  // rotate each corner around the center of the box
  const rotatedCorners = corners.map((corner) => {
    const dx = corner.x - cx;
    const dy = corner.y - cy;

    return {
      x: dx * Math.cos(rad) - dy * Math.sin(rad) + cx,
      y: dx * Math.sin(rad) + dy * Math.cos(rad) + cy,
    };
  });
  rotatedCorners.sort((a, b) => a.y - b.y || a.x - b.x);

  // // The topmost (and if tied, leftmost) corner is now the first item.
  const topCorner = rotatedCorners[0];
  if (selectedY < topTrigger) {
    menuPositionY = rotatedCorners[3].y + 10;
  } else {
    menuPositionY = topCorner.y - menuHeight - 10;
  }
  if (selectedX + ww / 2 > rightTrigger) {
    menuPositionX = menuPositionX - 400;
  }
  return (
    <>
      <LabelTextEditorMenu
        id={`menu-${objectId}`}
        isLabel={true}
        formats={formats}
        hide={!isActive}
        style={{
          transform: `translate(${menuPositionX}px, ${menuPositionY}px)`,
          zIndex: 1000,
        }}
      />
      <div
        id={`label-${objectId}`}
        data-objectid={objectId}
        onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
          e.stopPropagation();
        }}
        ref={annotationRef}
        className="annotation ff-text-box"
        style={{
          minHeight: quillRef.current?.editingArea?.scrollHeight ?? MIN_LINE_HEIGHT,
          height,
          width,
          zIndex,
          transform: transformString,
          backgroundColor: `${backgroundColor}`,
          outline: borderColor ? `${strokeWidth}px solid ${borderColor}` : isActive ? "2px #ADFF2F dashed" : "none",
          opacity: isDisplayed ? opacity : 0.5,
          filter: isDisplayed ? "none" : "grayScale(100%)",
        }}
      >
        <div
          className="annotation-label annotation-label-input"
          style={{
            width: `${text === "" ? "100%" : ""}`,
            zIndex: hovering ? 500 : 12,
            maxHeight: "auto",
          }}
          onClick={doTextClick}
          onMouseDown={(e) => e.stopPropagation()}
          onMouseEnter={() => () => {
            setHovering(true);
          }}
          onMouseLeave={() => () => {
            setHovering(false);
          }}
        >
          <AnnotationHighlight objectId={objectId} />
          <RTE
            ref={quillRef}
            readOnly={false}
            defaultValue={text}
            onFocus={() => {
              setIsActive(true);
            }}
            onChange={handleTextChange}
            placeholder={"Enter Text Here..."}
            modules={modules}
            formats={formats}
            onChangeSelection={handleSelectionChange}
            preserveWhitespace
          />
        </div>
        <div className="ff-text-div-2" onMouseDown={moveableContainerMouseDown} tabIndex={0} />
      </div>
    </>
  );
}
