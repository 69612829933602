import React, { RefObject } from "react";
import _ from "lodash";
import blobHelper from "../../../../../../components/blobUrlFormatHelper";
import {
  CPAT_LESSON_MODE_INSTRUCTIONAL,
  CPAT_LESSON_MODE_PRACTICE,
  CPAT_LESSON_MODE_TEST,
} from "../../../../helpers/lessonmodetypes";

import SmartObjective from "../../../../components/SmartObjective/SmartObjective";
import {
  IFmsPageManifest,
  IFMSReferenceImage,
} from "../../../../../../pageTypes/FMS_Player/Interfaces/IFmsPageManifest";
import FMSReferenceImage from "../../components/FMSReferenceImage";
import { ISuccessCriteria } from "../../models/IFMS";
import { ICduImage } from "../../../../../../pageTypes/FMS_Player/Interfaces/ICdu";
import FMSReferenceImageModal from "../../components/FMSReferenceImageModal";
//import { editPage } from '../../../../store/actions/actions';
import { IPageContext, PageContext } from "../../../../../../routes/builderContexts";
import { instructionsType } from "../../FMSWYSIWYG";

export interface CDULayoutProps {
  cduPath: string;

  image: ICduImage;
  pageManifest: IFmsPageManifest;
  mode: number;
  fmsRef: RefObject<HTMLDivElement>;
  criteriaIndex: number;
  getWidth: () => number;
  displayReferenceImageModal: (e: React.MouseEvent<HTMLSpanElement>) => void;
  removeImage: (e: any, assetVersionId: number, index: number) => void;
  setIsHelperShown: (value: boolean) => void;
  setIsReferenceShown: (value: boolean) => void;
  isReferenceShown: boolean;
  fmsDims: { height: number; width: number };
  stepInstructions: instructionsType[];
  version: number;
  newPageImageId: number;
  setNewPageImageId: (value: number) => void;
  getFMSRatio: () => number;
}
const ClassicLayout = (props: CDULayoutProps) => {
  return (
    <React.Fragment>
      <div
        className={props.image?.width > props.image?.height ? "fms-left-container-wideCDU" : "fms-left-container"}
        style={{ width: `calc(100% - (calc(${props.getWidth()}% + 15.75em))` }}
      >
        {/* {displayPageAttempts()} */}
        <FMSReferenceImage
          pageManifest={props.pageManifest}
          largeCduModel={props.image?.width > props.image?.height}
          cduHeigth={props.image?.height || 100}
          cduWidth={props.image?.width || 100}
          displayReferenceImageModal={props.displayReferenceImageModal}
          removeImage={props.removeImage}
          gridLayout={false}
        />
      </div>
      <div ref={props.fmsRef} className={"fms-container"} style={{ width: `${props.getWidth()}%` }}>
        <svg
          className="fms-svg"
          // width="35vw"
          // height="70vh"
          overflow="visible"
          width={`${props.fmsDims.width}`}
          height={`${props.fmsDims.height}`}
          viewBox={`0 0 ${props.image?.width} ${props.image?.height}`}
        >
          <g>
            <image height="100%" width="100%" overflow="visible" href={blobHelper(props.cduPath)} />
            {/* <rect fill="black" height={fmsDims.height} width={fmsDims.width} fillOpacity=".5"  />{" "} */}
            <rect fill="black" height={"100%"} width={"100%"} fillOpacity=".5" />{" "}
          </g>
        </svg>
        <div className="CDU-preview-label-center">
          <p
            className={
              props.cduPath.includes("a220")
                ? "a220-preview-label"
                : props.cduPath.includes("b787-8")
                ? "b787-8-preview-label"
                : props.cduPath.includes("a350")
                ? "a350-preview-label"
                : props.cduPath.includes("145")
                ? "erj145-preview-label"
                : "CDU-preview-label"
            }
          >
            CDU (Preview Only) - Configuration is only available in Edit Mode{" "}
          </p>
        </div>
      </div>
      <SmartObjective
        list={props.stepInstructions}
        enablingObjective={props.pageManifest.instructions.lessonInstructions.replace(/<[^>]*>/g, "")}
        enablingObjectiveTitle={props.pageManifest.instructions.lessonTitle.replace(/<[^>]*>/g, "")}
        listIndex={props.criteriaIndex}
        lessonMode={CPAT_LESSON_MODE_INSTRUCTIONAL}
        // isFeedbackShown={isHelperShown}
        // feedback={fms.getCustomFeedback(stateOfFMS())}
        // disableHint={maxAttempts === 0 ? true : false}
        setIsFeedbackShown={props.setIsHelperShown}
      />

      {_.has(props.pageManifest, "pageImage") && (props.pageManifest.pageImage as IFMSReferenceImage[])?.length > 0 ? (
        <FMSReferenceImageModal
          imageSource={(props.pageManifest.pageImage as IFMSReferenceImage[])[0].imagePath}
          isModalShown={props.isReferenceShown}
          setIsModalShown={props.setIsReferenceShown}
          version={props.version}
          referenceTab={false}
          removeImage={props.removeImage}
          assetVersionId={
            props.pageManifest.pageImage ? (props.pageManifest.pageImage as IFMSReferenceImage[])[0].assetVersionId : -1
          }
          newPageImageId={props.newPageImageId}
          setNewPageImageId={props.setNewPageImageId}
        />
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};
export default ClassicLayout;
