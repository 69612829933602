import React from "react";
import { IBasicPageAttributes } from "../../../pageTypes/BasicPage_Player/components/IBasePage";
import { IAnnotation } from "../../Annotation/models/IAnnotation";
import ISymbolStyle from "../../Symbol/models/ISymbolStyle";
// import { formatFunctions } from '../WYSIWYGToolbarFunctions/WYSIWYGToolbarFunctions';

type fontStyleButtonType = {
  attributes?: IAnnotation | IBasicPageAttributes | ISymbolStyle;
};

const FontStyleButtons = (props: fontStyleButtonType) => {
  return (
    <React.Fragment>
      <button id="bold" className={`ql-bold wysiwyg-toolbar-btn`} type="button">
        <span className="icon-bold-icon"></span>
      </button>
      <button className={`ql-italic wysiwyg-toolbar-btn`}>
        <span className="icon-italic-icon"></span>
      </button>
      <button className={`ql-underline wysiwyg-toolbar-btn`}>
        <span className="icon-underline-icon"></span>
      </button>
    </React.Fragment>
  );
};

export default FontStyleButtons;
