import React, { useEffect } from "react";
import { handleKeyUp, handleKeyPress } from "../../ObjectPropertyBox/functions/PropertyBoxFunctions";
import { AnnotationPropType } from "../Annotation";
import HighlightAnnotation from "../hooks/HighlightAnnotation";
import AnnotationHighlight from "../../../pageTypes/BasicPage_Player/components/BaseFreeForm/components/HighlightRect";

const AnnotationLine = (props: AnnotationPropType) => {
  const { annotation, index, kp, markSelected, objectIsInTimeline } = props;
  const isDisplayed = typeof annotation.isDisplayed === "undefined" || annotation.isDisplayed;
  const opacity = objectIsInTimeline ? 1 : isDisplayed ? 1 : 0.5;

  useEffect(() => {
    window.addEventListener("resize", checkResize);
    return () => {
      window.removeEventListener("resize", checkResize);
    };
  }, []);

  function checkResize() {
    const thisSVG = document.getElementById(`solidLine-${index}`) as any;
    if (thisSVG) {
      thisSVG.lastChild.attributes.y1.nodeValue = `${thisSVG.clientHeight / 2}`;
      thisSVG.lastChild.attributes.y2.nodeValue = `${thisSVG.clientHeight / 2}`;
    }
  }

  const display = () => {
    const rotateDeg: number | undefined = annotation?.transform?.rotate ? annotation?.transform?.rotate : 0;
    // const scaleXNum: number | undefined = annotation?.transform?.scaleX
    // const scaleYNum: number | undefined = annotation?.transform?.scaleY
    // const matrix: number[] | undefined = annotation?.transform?.matrix3d;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="https://www.w3.org/1999/xlink"
        id={`solidLine-${index}`}
        tabIndex={0}
        name="solidLine"
        className="annotation"
        version="1.1"
        // viewBox={`0 0 auto 20`}
        height={`20`}
        preserveAspectRatio="xMinYMid meet"
        // width={annotation.width}
        // height={annotation.height}
        style={{
          top: `${annotation.top.toFixed(2)}%`,
          left: `${annotation.left.toFixed(2)}%`,
          width: `${annotation.width.toFixed(2)}%`,
          zIndex: annotation.zIndex,
          transform: `rotate(${rotateDeg?.toFixed(2)}deg)`,
        }}
        onMouseDown={(e: React.MouseEvent<SVGSVGElement>) => markSelected(e.currentTarget, index, "annotations")}
        onKeyDown={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyPress(e, kp)}
        onKeyUp={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyUp(e, kp)}
        opacity={opacity}
      >
        <HighlightAnnotation annotation={annotation} />
        <AnnotationHighlight objectId={annotation.objectId} />
        
        <line
          className="annotation-arrow-arm"
          x1="0"
          x2={`100%`}
          y1={`50%`}
          y2={`50%`}
          style={{
            fill: `${annotation.borderColor || "white"}`,
            stroke: `${annotation.borderColor || "white"}`,
            strokeLinecap: "butt",
            strokeWidth: `${annotation.strokeWidth || "4"}`,
          }}
        />
      </svg>
    );
  };

  return <React.Fragment>{display()}</React.Fragment>;
};

export default AnnotationLine;
