import React from "react";
import { Interweave } from "interweave";
import { BaseWYSIWYGType } from "../BaseWYSIWYG";
import blobHelper from "../../../../blobUrlFormatHelper";

const DGSV = (props: BaseWYSIWYGType) => {
  const { pageManifest } = props;
  const imageName = blobHelper(pageManifest.pageImage[0].imagePath);

  return (
    <div className="contentBox">
      <div className="basePageSGC-container">
        <div className="single-graphic-center-img-container">
          <div className="img-aspect-container">
            <div className="img-aspect-container-inside">
              <div className="img-aspect-centering">
                <img src={imageName} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="single-graphic-center-img-container">
          <div className="img-aspect-container">
            <div className="img-aspect-container-inside">
              <div className="img-aspect-centering">
                <img src={imageName} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DGSV;
