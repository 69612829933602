import { ManufacturerList } from "../../../contexts/utils/createFormattedAircrafts";
import MetadataState from "../interfaces/MetadataState";

export function getFleetsFromManufacturerList(manufacturer: string, manufacturerList: ManufacturerList): string[] {
  if (manufacturerList[manufacturer].fleets === undefined || manufacturerList[manufacturer].fleets === null) {
    return ["None"];
  }

  return Object.keys(manufacturerList[manufacturer]?.fleets) ?? [];
}

// input: fleet key, manObject: an element in a manufacturerlist
export function getVariantsFromFleet(fleet: string, manObject: any) {
  // return
  if (manObject === undefined || manObject === null) return ["None"];
  if (manObject.fleets[fleet] === undefined || manObject.fleets[fleet] === null) {
    return ["None"];
  }
  return Object.keys(manObject.fleets[fleet].variants);
}

export function getFleetIdFromManufacturerList(
  manufacturer: string,
  fleet: string,
  manufacturerList: ManufacturerList,
) {
  if (!manufacturerList[manufacturer].fleets[fleet]) {
    return;
  }
  return manufacturerList[manufacturer].fleets[fleet].id;
}

export function getVariantIdFromFleet(fleetObj: any, fleet: string, variant: string) {
  if (fleetObj === undefined) return;
  if (
    fleetObj.fleets[fleet] == null ||
    fleetObj.fleets[fleet].variants === null ||
    fleetObj.fleets[fleet].variants === undefined
  ) {
    return;
  }
  return fleetObj.fleets[fleet].variants[variant].id;
}

// return a MetadataState object that includes the default (first key) fleet and variant from a manufacturer
// this is necessary to populate the state after the manufacturer is changed
export function getManufacturerId(manufacturer: string, manufacturerList: ManufacturerList): MetadataState {
  const manObject = manufacturerList[manufacturer];
  return {
    manufacturerId: manObject.id,
    fleetId: undefined,
    variantId: undefined,
  };
}

export function getMetadataState(
  manufacturer: string,
  manufacturerList: ManufacturerList,
  fleet?: string,
  variant?: string,
): MetadataState {
  if (typeof fleet === "undefined" && typeof variant === "undefined") {
    return getManufacturerId(manufacturer, manufacturerList);
  } else if (typeof fleet === "string" && typeof variant === "undefined") {
    // TODO: handle overload for only fleet
    return getManufacturerId(manufacturer, manufacturerList);
  } else {
    // TODO: handle overload for manufacturer, fleet, and variant selection
    return {
      manufacturerId: undefined,
      fleetId: undefined,
      variantId: undefined,
    };
  }
}
