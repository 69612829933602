import { HotspotObject } from "../types";

export enum ASSET_TYPE_ID {
  FMS = 1,
  COCKPIT_PROCEDURE = 2,
  MISCELLANEOUS = 3,
  IMAGE = 4,
  AUDIO = 5,
  RICH_TEXT = 6,
  PDF = 7,
  VIDEO = 8,
  WALKAROUND = 9,
  SCORM = 10,
  IMPORTED_LESSON = 11,
  LIVERY = 12,
  CABIN = 13,
  EMBEDDED_SCO = 14,
  PANORAMIC_IMAGE = 15,
  SMART_OBJECT = 16,
}

export const OBJECT_TYPE_PANORAMIC = "panoramic";
export const PM_PANORAMIC_LIST_KEY = "panoramicList";
export const annotationTypes = new Set([
  "label",
  //
  "fillArrow",
  "pointArrow",
  "arrow",
  "line",
  "lineArrow",
  "solidLine",
  "dashedLine",
  //
  "circledNumber",
  "circledLetter",
  "circleLetter",
  "squareNumber",
  "squareLetter",
  //
  "square",
  "roundedSquare",
  "triangle",
  "circle",
  "freeFormPoly",
]);

export const symbolTypes = new Set([
  "aircraft",
  "aircraft_1",
  "current_heading",
  "current_track",
  "distance",
  "dme_tacan",
  "expanded_compass",
  "glideslope",
  "ils_localizer",
  "line_m",
  "course_pointer_1",
  "course_pointer_2",
  "course_pointer_3",
  "course_pointer_4",
  "heading_bug",
  "track_line_range",
  "vor_1_head",
  "vor_1_tail",
  "vor_2_head",
  "vor_2_tail",
  "vor",
  "vortac",
]);

export const shapeAnnotation = new Set(["square", "roundedSquare", "triangle", "circle"]);

export const fontAnnotation = new Set(["circledNumber", "circledLetter", "squareNumber", "squareLetter"]);

export const lineAnnotation = new Set(["fillArrow", "lineArrow", "solidLine", "dashedLine"]);

export const DEFAULT_TEXT_BOX = {
  text: `<p class="standard-blot-block"><span style="font-size: 1em;">Text Box </span></p>`,
  version: "1.2.0",
  lineHeight: "normal",
  width: 15,
  height: 20,
  left: 10,
  top: 10,
  transform: {
    rotate: 0,
    scaleX: 1,
    scaleY: 1,
    matrix3d: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
  },
  translate: [0, 0],
  fontColor: "ivory",
  fontStyle: "",
  fontWeight: "",
  textDecoration: "",
  backgroundColor: "",
  borderColor: "",
  shadowColor: "",
  type: "textBlock",
  zIndex: 1,
  isDisplayed: true,
  displayName: `text-box`,
};
export const DEFAULT_ANNOTATION = {
  width: 10,
  height: 10,
  left: 2,
  top: 2,
  zIndex: 1,
  isDisplayed: true,
};
export const DEFAULT_SCORM = {
  blobPath: "",
  assetVersionId: 0,
  name: "interactive-graphic-scorm",
  width: 60,
  height: 60,
  left: 0,
  top: 0,
  zIndex: 1,
  isDisplayed: true,
  type: "SCORM",
  lockAspectRatio: true,
};
export const DEFAULT_IMAGE = {
  imagePath: "cpat/generic/images/13/13/FED878B1C46AEA11817D60F26251769D/cpatbrandedairplane.png",
  assetVersionId: 13,
  type: "pageImage",
  name: `image`,
  displayName: `image`,
  width: 15,
  height: 15,
  left: 2,
  top: 2,
  zIndex: 2,
  isDisplayed: true,
  lockAspectRatio: true,
  visible: true,
  rotate: 0,
  transform: {
    rotate: 0,
  },
};
export const DEFAULT_VIDEO = {
  path: "",
  name: `video`,
  assetVersionId: 0,
  height: 40,
  width: 40,
  top: 0,
  left: 0,
  zIndex: 1,
  type: "video",
  lockAspectRatio: true,
};
export const DEFAULT_HOTSPOT: Omit<HotspotObject, "objectId"> & Partial<HotspotObject> = {
  height: 10,
  width: 10,
  left: 2,
  top: 2,
  zIndex: 1,
  isDisplayed: true,
  nextClicks: [],
  type: "hotspot",
  resumeOnClick: false,
  pauseOnLoad: false,
  required: false,
  targets: [],
  version: "1.2.0",
};

export const DEFAULT_SMART_OBJECT = {
  type: "smartObject",
  assetVersionId: 13,
  name: `smartObject`,
  displayName: `smartObject`,
  width: 60,
  height: 60,
  left: 2,
  top: 2,
  zIndex: 2,
  isDisplayed: true,
  lockAspectRatio: true,
  visible: true,
  rotate: 0,
  transform: {
    rotate: 0,
  },
};

export const DEFAULT_PANORAMIC = {
  type: OBJECT_TYPE_PANORAMIC,
  assetBlobPath: "",
  assetVersionId: 0,
  height: 50,
  width: 60,
  top: 0,
  left: 0,
  zIndex: 1,
  lockAspectRatio: true,
  cubeMap: [],
  pType: "cubemap",
  isDisplayed: true,
  zoom: 50,
  pitch: 0,
  yaw: 0,
};
