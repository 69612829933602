import React, { useState } from "react";
import "../../CPaT3d_Player/components/hotSpotEditor/hsEditor.css";
import "./hsEditor.css";
import WorkShop from "./workShop";
import { ReactComponent as EIcon } from "../../../assets/icons/3DPage/tilt-fwd.svg";
import { ReactComponent as CIcon } from "../../../assets/icons/3DPage/tilt-bkw.svg";
import { ReactComponent as DIcon } from "../../../assets/icons/3DPage/scene-right.svg";
import { ReactComponent as QIcon } from "../../../assets/icons/3DPage/scene-up.svg";
import { ReactComponent as ZIcon } from "../../../assets/icons/3DPage/scene-down.svg";
import { ReactComponent as WIcon } from "../../../assets/icons/3DPage/scene-fwd.svg";
import { ReactComponent as AIcon } from "../../../assets/icons/3DPage/scene-left.svg";
import { ReactComponent as XIcon } from "../../../assets/icons/3DPage/scene-bkw.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/3DPage/increase-nudge.svg";
import { ReactComponent as MinusIcon } from "../../../assets/icons/3DPage/decrease-nudge.svg";
import { ReactComponent as OpenHotspot } from "../../../assets/icons/Common/open-menu-hotspot-controls-icon-white.svg";
import { ReactComponent as CloseHotspot } from "../../../assets/icons/Common/close-menu-hotspot-controls-icon-white.svg";
import { ReactComponent as VIcon } from "../../../assets/icons/3DPage/icon-v.svg";

import { useMiscUI } from "../../../contexts/MiscUI/MiscUIProvider";
import { HsEditorProps, ModePickerProps, EditMode } from "./types";
const hsInnards = (
  <>
    <hr />
    <strong className="guideP">Mouse Controls:</strong>
    <div className="guideP">
      {" "}
      <strong>Left:</strong> Rotate view around HS{" "}
    </div>
    <div className="guideP">
      {" "}
      <strong>Right:</strong> Move HS along horizontal plane
    </div>
    <div className="guideP">
      {" "}
      <strong>Wheel:</strong> Zoom
    </div>
    <hr />
    <strong className="guideP">Keyboard Controls:</strong>
    <div>
      <p style={{ color: "green" }}>
        <WIcon /> Increase hotspot height
      </p>
    </div>
    <div>
      <p style={{ color: "green" }}>
        <XIcon /> Decrease hotspot height
      </p>
    </div>
    <div>
      <p style={{ color: "red" }}>
        <DIcon /> Increase hotspot width
      </p>
    </div>
    <div>
      <p style={{ color: "red" }}>
        <AIcon /> Decrease hotspot width
      </p>
    </div>
    <div>
      <p style={{ color: "#4d4dff" }}>
        <EIcon /> Increase hotspot length
      </p>
    </div>
    <div>
      <p style={{ color: "#4d4dff" }}>
        <VIcon /> Decrease hotspot length
      </p>
    </div>
    <div>
      <p>
        <PlusIcon /> Increase hotspot size
      </p>
    </div>
    <div>
      <p>
        <MinusIcon /> Decrease hotspot size
      </p>
    </div>
    <div>
      <p>
        <CIcon /> Reset hotspot to default size
      </p>
    </div>
    <div>
      <p>
        <QIcon /> Move hotspot up
      </p>
    </div>
    <div>
      <p>
        <ZIcon /> Move hotspot down
      </p>
    </div>
  </>
);

const proxInnards = (
  <>
    <hr />
    <strong className="guideP">Mouse Controls:</strong>
    <div className="guideP">
      <strong>Left:</strong> Rotate view around proximity{" "}
    </div>
    <div className="guideP">
      <strong>Wheel:</strong> Zoom
    </div>
  </>
);

const ModePicker = (props: ModePickerProps) => {
  const { showHSEditor, setMiscUI, setEditMode, changeHS, editMode } = props;

  const [miscUI] = useMiscUI();

  return (
    <div className="hsControlGui">
      <div className="hsControlTitle">
        <button
          className={miscUI.editorTypeToggle ? "style2" : "style1"}
          onClick={(e) => (showHSEditor ? e.preventDefault() : setMiscUI({ type: "TOGGLE_EDITOR_TYPE" }))}
        >
          Hot Spot
        </button>
        <button
          className={miscUI.editorTypeToggle ? "style1" : "style2"}
          onClick={(e) => (showHSEditor ? setMiscUI({ type: "TOGGLE_EDITOR_TYPE" }) : e.preventDefault())}
        >
          Proximity
        </button>
      </div>

      <div className="hsControlInnards">
        {showHSEditor ? (
          <>
            <div className="shapeControl">
              Shapes:
              <label className="testLabel">
                <input
                  type="radio"
                  name="shape"
                  value="Box"
                  defaultChecked={!props.isSphere}
                  onClick={() => changeHS("box")}
                />{" "}
                Square
              </label>
              <label className="testLabel">
                <input
                  type="radio"
                  name="shape"
                  value="Sphere"
                  defaultChecked={props.isSphere}
                  onClick={() => changeHS("sphere")}
                />{" "}
                Sphere
              </label>
            </div>
            <hr />
            <div className="modeBox">
              <button
                className={editMode === "scale" ? "style2" : "style1"}
                id="resizeButton"
                onClick={() => setEditMode("scale")}
              >
                re-size
              </button>
              <button
                className={editMode === "rotate" ? "style2" : "style1"}
                id="rotateButton"
                onClick={() => setEditMode("rotate")}
              >
                rotate
              </button>

              <div></div>
            </div>
          </>
        ) : (
          props.proxEditor()
        )}
        <hr />
        {props.hotSpotEditor()}
      </div>
    </div>
  );
};

const HSEditor = (props: HsEditorProps) => {
  const [miscUI, setMiscUI] = useMiscUI();
  const [editMode, setEditMode] = useState<EditMode>("scale");

  const show = miscUI.hotSpotEditorControlsVisible;
  const showHSEditor = miscUI.editorTypeToggle;
  //moving forward, change this to something that uses an index as well

  const changeHS = (changeTo: "box" | "sphere") => {
    if (changeTo === "box") {
      props.setIsSphere(false);
    } else if (changeTo === "sphere") {
      props.setIsSphere(true);
    }
  };

  return (
    <>
      <WorkShop
        hsIndex={props.hsIndex}
        setHsIndex={props.setHsIndex}
        isSphere={props.isSphere}
        showHSEditor={showHSEditor}
        editMode={editMode}
        hotspotEditMode={miscUI.editorTypeToggle}
        mode={props.setDeepEditorMode}
        proximityRange={props.proximityRange}
        proximityOffset={props.proximityOffset}
        isEditing={props.isEditing}
        positionRef={props.positionRef}
      />
      ;
      <div className="absolute-container" style={{ pointerEvents: "none" }}>
        <div className="relative-container workshop-grid">
          <ModePicker
            {...props}
            changeHS={changeHS}
            setEditMode={setEditMode}
            editMode={editMode}
            showHSEditor={showHSEditor}
            setMiscUI={setMiscUI}
          />
          <div className="userGuide">
            <div className="hsec guideP" onClick={() => setMiscUI({ type: "TOGGLE_HSEC" })}>
              <p>
                <strong>{showHSEditor ? "Hot Spot Editor Controls" : "Proximity Editor Controls"}</strong>
              </p>
              <p>{show ? <CloseHotspot /> : <OpenHotspot />}</p>
            </div>
            {show && showHSEditor ? hsInnards : proxInnards}
          </div>
        </div>
      </div>
    </>
  );
};
export default HSEditor;
