import { BaseModalProps } from "./types";
import { PropsWithChildren, ReactElement } from "react";
export type ContentSlotProps = Pick<BaseModalProps, "slots">;

export const Content = ({ children, slots }: PropsWithChildren<ContentSlotProps>) => {
  if (slots?.content) {
    return slots?.content as ReactElement;
  }

  return <div className="cpat-modal-content">{children}</div>;
};
