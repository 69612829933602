import React, { useState, useEffect, useContext } from "react";
import { LessonPageActionsContext, ILessonPageActionsContext } from "../../../routes/builderContexts";
import _ from "lodash";
import { IFmsContext, FmsContext } from "../Interfaces/fmsContext";

interface FmsObjectPositionEditorProps {
  type: string;
  cduData: any;
  pageManifest: any;
  pagesContext: any;
}

function FmsObjectPositionEditor(props: FmsObjectPositionEditorProps) {
  const lessonPageActionsContext: ILessonPageActionsContext =
    useContext<ILessonPageActionsContext>(LessonPageActionsContext);
  const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);
  const maxX = fmsContext.currentCDU.image.width;
  const maxY = fmsContext.currentCDU.image.height;
  const [type, setType] = useState<string>("none");

  const cellData = props.cduData;
  const cellName: string = cellData.id;
  const pageManifestNew = { ...props.pageManifest };
  const currentPageOffsets = pageManifestNew.pageLayouts[props.pageManifest.bootPage].objectOffsets;
  let currentObjectHasOffset = false;
  const pageModals =
    pageManifestNew.pageLayouts[props.pageManifest.bootPage].modals !== undefined
      ? pageManifestNew.pageLayouts[props.pageManifest.bootPage].modals
      : {};
  const pageDropDowns =
    pageManifestNew.pageLayouts[props.pageManifest.bootPage].dropDownCells !== undefined
      ? pageManifestNew.pageLayouts[props.pageManifest.bootPage].dropDownCells
      : {};
  let showPositionEditor = true;

  useEffect(() => {
    if (type !== "none") {
      document.getElementById("fmsObjectOffsetInput-" + type)!.focus();
    }
  }, [type]);

  // if(props.type === "cdu_screen_object"){
  //     return <></>
  // } else{

  // props.pagesContext.updatePageManifest(pageManifestNew);

  //checks for cells on modal and dropdown these cells currently do not support the position editor
  Object.values(pageModals).forEach((item: any) => {
    if (cellName in item.cellValues) {
      showPositionEditor = false;
    }
  });
  Object.values(pageDropDowns).forEach((item: any) => {
    if (cellName in item) {
      showPositionEditor = false;
    }
  });

  let currentPosition = {
    xCoordinate: 0,
    yCoordinate: 0,
    height: 0,
    width: 0,
    x1: 0,
    x2: 0,
    y1: 0,
    y2: 0,
    length: 0,
  };
  if (currentPageOffsets !== undefined && currentPageOffsets[cellName] !== undefined) {
    currentObjectHasOffset = true;
  }

  if (cellData.objectType === "line") {
    currentPosition =
      currentObjectHasOffset === false
        ? {
            x1: Math.round(cellData.x1),
            x2: Math.round(cellData.x2),
            y1: Math.round(cellData.y1),
            y2: Math.round(cellData.y2),
            length: cellName.includes("Horizontal") ? cellData.x2 - cellData.x1 : cellData.y2 - cellData.y1,
          }
        : {
            ...currentPageOffsets[cellName].position,
          };
  } else if (cellData.objectType === "rect") {
    currentPosition =
      currentObjectHasOffset === false
        ? {
            xCoordinate: Math.round(cellData.x),
            yCoordinate: Math.round(cellData.y),
            height: Math.round(cellData.height),
            width: Math.round(cellData.width),
          }
        : {
            ...currentPageOffsets[cellName].position,
          };
  } else {
    currentPosition =
      currentObjectHasOffset === false
        ? {
            xCoordinate: Math.round(cellData.xCoordinate),
            yCoordinate: Math.round(cellData.yCoordinate),
            height: Math.round(cellData.height),
            width: Math.round(cellData.width),
          }
        : {
            ...currentPageOffsets[cellName].position,
          };
  }

  function changeValue(e: any, cellData: any, type: string) {
    let newValue = parseInt(e.currentTarget.value);
    // set value to zero if it is not a number or less than zero
    if (isNaN(newValue) || newValue < 0) {
      newValue = 0;
    }

    if (currentPageOffsets !== undefined) {
      pageManifestNew.pageLayouts[props.pageManifest.bootPage].objectOffsets = {
        ...currentPageOffsets,
      };
    } else {
      pageManifestNew.pageLayouts[props.pageManifest.bootPage].objectOffsets = {};
    }

    if (type === "x") {
      // if value is greater than max x value set value to max x value
      if (newValue > maxX) {
        newValue = maxX;
      }

      if (cellData.objectType === "line") {
        if (cellData.id === "Vertical-Line-1") {
          //update x for vertical line
          pageManifestNew.pageLayouts[props.pageManifest.bootPage].objectOffsets[cellName] = {
            position: { ...currentPosition, x1: newValue, x2: newValue },
          };
        } else {
          //update x for horizontal line
          pageManifestNew.pageLayouts[props.pageManifest.bootPage].objectOffsets[cellName] = {
            position: {
              ...currentPosition,
              x1: newValue,
              x2: newValue + currentPosition.length,
              length: currentPosition.length,
            },
          };
        }
      } else if (cellData.objectType === "rect") {
        pageManifestNew.pageLayouts[props.pageManifest.bootPage].objectOffsets[cellName] = {
          position: { ...currentPosition, xCoordinate: newValue },
        };
      } else {
        pageManifestNew.pageLayouts[props.pageManifest.bootPage].objectOffsets[cellName] = {
          position: { ...currentPosition, xCoordinate: newValue },
        };
      }
    } else if (type === "y") {
      // if value is greater than max y value set value to max y value
      if (newValue > maxY) {
        newValue = maxY;
      }
      if (cellData.objectType === "line") {
        if (cellData.id === "Vertical-Line-1") {
          //update y for vertical line
          pageManifestNew.pageLayouts[props.pageManifest.bootPage].objectOffsets[cellName] = {
            position: {
              ...currentPosition,
              y1: newValue,
              y2: newValue + currentPosition.length,
            },
          };
        } else {
          //update y for horizontal line
          pageManifestNew.pageLayouts[props.pageManifest.bootPage].objectOffsets[cellName] = {
            position: { ...currentPosition, y1: newValue, y2: newValue },
          };
        }
      } else {
        pageManifestNew.pageLayouts[props.pageManifest.bootPage].objectOffsets[cellName] = {
          position: { ...currentPosition, yCoordinate: newValue },
        };
      }
    } else if (type === "height") {
      pageManifestNew.pageLayouts[props.pageManifest.bootPage].objectOffsets[cellName] = {
        position: { ...currentPosition, height: newValue },
      };
    } else if (type === "width") {
      pageManifestNew.pageLayouts[props.pageManifest.bootPage].objectOffsets[cellName] = {
        position: { ...currentPosition, width: newValue },
      };
    } else if (type === "length") {
      if (cellData.id.includes("Vertical")) {
        pageManifestNew.pageLayouts[props.pageManifest.bootPage].objectOffsets[cellName] = {
          position: {
            ...currentPosition,
            length: newValue,
            y2: currentPosition.y1 + newValue,
          },
        };
      } else {
        pageManifestNew.pageLayouts[props.pageManifest.bootPage].objectOffsets[cellName] = {
          position: {
            ...currentPosition,
            length: newValue,
            x2: currentPosition.x1 + newValue,
          },
        };
      }
    }

    props.pagesContext.updatePageManifest(pageManifestNew);
    setType(type);
  }

  return showPositionEditor ? (
    <div className="row" style={{ marginTop: "10px" }}>
      <div className="col-12">
        <strong className="instructions" style={{ color: "#ffffff", fontSize: "1.125rem" }}>
          Object Position
        </strong>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "60%",
            minWidth: "fit-content",
            justifyContent: "space-between",
            color: "white",
            margin: "10px",
          }}
        >
          {/* <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}> */}
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            X:
            <input
              type="number"
              className="fms-number-input"
              id="fmsObjectOffsetInput-x"
              onChange={(e) => changeValue(e, cellData, "x")}
              defaultValue={cellData.objectType === "line" ? currentPosition.x1 : currentPosition.xCoordinate}
              value={cellData.objectType === "line" ? currentPosition.x1 : currentPosition.xCoordinate}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            Y:
            <input
              type="number"
              className="fms-number-input"
              id="fmsObjectOffsetInput-y"
              onChange={(e) => changeValue(e, cellData, "y")}
              defaultValue={cellData.objectType === "line" ? currentPosition.y1 : currentPosition.yCoordinate}
              value={cellData.objectType === "line" ? currentPosition.y1 : currentPosition.yCoordinate}
            />
          </div>
          {cellData.objectType === "line" ? (
            <div style={{ display: "flex", flexDirection: "row" }}>
              Length:
              <input
                type="number"
                className="fms-number-input"
                id="fmsObjectOffsetInput-length"
                onChange={(e) => changeValue(e, cellData, "length")}
                defaultValue={currentPosition.length}
                value={currentPosition.length}
              />
            </div>
          ) : (
            <>
              <div style={{ display: "flex", flexDirection: "row" }}>
                Height:
                <input
                  type="number"
                  className="fms-number-input"
                  id="fmsObjectOffsetInput-height"
                  onChange={(e) => changeValue(e, cellData, "height")}
                  defaultValue={currentPosition.height}
                  value={currentPosition.height}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                Width:
                <input
                  type="number"
                  className="fms-number-input"
                  id="fmsObjectOffsetInput-width"
                  onChange={(e) => changeValue(e, cellData, "width")}
                  defaultValue={cellData.objectType === "line" ? currentPosition.length : currentPosition.width}
                  value={currentPosition.width}
                />
              </div>
            </>
          )}
          {/* </div> */}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
  // }
}

export default FmsObjectPositionEditor;
