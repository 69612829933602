import React from "react";
import { createPortal } from "react-dom";

class Portal extends React.Component<any> {
  root: HTMLElement | null = null;
  el: HTMLDivElement;

  constructor(props: any) {
    super(props);
    this.state = {};
    this.el = document.createElement("div");
    this.el.className = `portal-container ${props.path}`;
  }

  componentDidMount() {
    this.root = document.getElementById("portal");
    this.root && this.root.appendChild(this.el);
  }

  componentWillUnmount() {
    this.root && this.root.removeChild(this.el);
  }

  render() {
    return createPortal(this.props.children, this.el);
  }
}

export default Portal;
