import { IPropertyBox } from "./IObjectPropertyBox";

export const emptyPropertyBox: IPropertyBox = {
  name: "",
  left: 0,
  top: 0,
  zIndex: 0,
  rotation: 0,
  width: 0,
  height: 0,
};
