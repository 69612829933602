import React from "react";
import CDUmodalColumns from "./CDUmodalColumns";
import CDUCellDisplay from "./CDUCellDisplay";
import CDUModalButtons from "./CDUPageButtons";
import blobUrlFormatHelper from "../../../../components/blobUrlFormatHelper";
import CDUImageOverlays from "../cduImageOverlays";
// import CDUDropDown from "../CDUDropDown";
// import CDUModalColumns from "./CDUmodalColumns";
interface cduModalProps {
  paging?: boolean;
  cduPath: string;
  modalData?: any;
  modalShown: boolean;
  manifestModalData: any;
  accessCduLineInformation: any;
  addModalRow: (columnName: string) => void;
  assignFmsKeypad: any;
  highlightGroups: any;
}

const CDUmodal = (props: cduModalProps) => {
  return Object.keys(props.modalData).length > 0 && props.modalData && props.modalShown ? (
    <g>
      <image
        href={blobUrlFormatHelper(props.cduPath + props.modalData.table.href)}
        width={props.modalData.table.width}
        height={props.modalData.table.height}
        x={props.modalData.table.xCoordinate}
        y={props.modalData.table.yCoordinate}
        id="modal"
      />

      <CDUImageOverlays
        overLays={props.modalData.rectangularObjects}
        cduPath={props.cduPath}
        assignFmsKeypad={props.assignFmsKeypad}
        accessCduLineInformation={props.accessCduLineInformation}
        fromModal={true}
        modalData={props.modalData}
      />

      {props.modalData.rectangularObjects ? (
        <CDUModalButtons
          rectangularObjects={props.modalData.rectangularObjects}
          fromModal={true}
          assignFmsKeypad={props.assignFmsKeypad}
          highlightGroups={props.highlightGroups}
          cduModalData={props.modalData}
        />
      ) : (
        <></>
      )}
      {props.modalData.foreignObjects &&
      props.manifestModalData[props.modalData.modalName] &&
      props.manifestModalData[props.modalData.modalName].cellValues ? (
        <CDUCellDisplay
          accessCduLineInformation={props.accessCduLineInformation}
          cduLinesInformation={props.modalData.foreignObjects}
          cellValues={props.manifestModalData[props.modalData.modalName]?.cellValues}
          highlights={() => {}}
          assignFmsKeyPad={props.assignFmsKeypad}
          highlightedGroups={props.highlightGroups}
        />
      ) : (
        <></>
      )}
      {/* {props.dropDownOpen && props.parentMenu ?
                    <CDUDropDown 
                      parentMenu={props.parentMenu}
                      options={props.options}
                      accessCduLineInformation={props.accessDropInformation}
                      addDropDownRow={props.addDropDownRow}
                    />
                    :<></>} */}
      {props.modalData &&
        props.manifestModalData &&
        props.manifestModalData[props.modalData.modalName] &&
        props.modalData.columns &&
        Object.keys(props.modalData.columns).map((col: string, index: number) =>
          props.manifestModalData[props.modalData.modalName][col] && props.modalData.columns[col] ? (
            <CDUmodalColumns
              accessCduLineInformation={props.accessCduLineInformation}
              columnName={col}
              columnValues={props.manifestModalData[props.modalData.modalName][col]}
              columnData={props.modalData.columns[col]}
              key={index}
              baseX={props.modalData.table.xCoordinate}
              baseY={props.modalData.table.yCoordinate}
              columnIndex={index}
              addModalRow={props.addModalRow}
            />
          ) : (
            <></>
          ),
        )}
    </g>
  ) : (
    <></>
  );
};
export default CDUmodal;
