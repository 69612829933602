import React, { useState } from "react";
import { IAircraft } from "../../../models/IGetAircraftLessonData";
import * as AssetManagerTypes from "../AssetManager.types";
import { ReactComponent as RemoveFilterIcon } from "../../../assets/icons/Common/remove-tag-icon.svg";
import { ReactComponent as DropdownFilterIcon } from "../../../assets/icons/AssetManager/drop-down-icon.svg";
import { useAssetManagerDispatch, useAssetManagerState } from "../AssetManagerContext/AssetManagerProvider";
import { AbstractTooltip as Tooltip } from "../../ToolTip/ToolTip";
// import { useLessonData } from '../../../contexts/LessonDataProvider'
const NONE_MANUFACTURER_ID = 5;
const NONE_FLEET_ID = 11;
const NONE_VARIANT_ID = 60;
interface IFleetValues {
  [key: string]: string[];
}
interface IManufacturerOptions {
  fleets: IFleetValues;
}
interface IManufacturersList {
  [key: string]: IManufacturerOptions;
}

type DropdownToggle = {
  currentLesson: boolean;
  manufacturer: boolean;
  model: boolean;
  variant: boolean;
};

function AssetLibraryFilter(props: any) {
  const assetManagerState = useAssetManagerState();
  const assetManagerDispatch = useAssetManagerDispatch();
  const [dropdown, setDropdown] = useState<DropdownToggle>({
    currentLesson: false,
    manufacturer: false,
    model: false,
    variant: false,
  });

  const [enabledDropdowns, setEnabledDropdowns] = useState<DropdownToggle>({
    currentLesson: true,
    manufacturer: true,
    model: false,
    variant: false,
  });

  const { aircrafts } = props.allAvailableAircraft;
  const manufacturers: string[] = makeManufacturersList();

  const manufacturerList: any = createAirplaneObjectsWithFields(manufacturers);
  function makeManufacturersList() {
    const manufacturers: any[] = [];
    try {
      for (let i = 0; i < aircrafts.length; i++) {
        const manufacturer = aircrafts[i].manufacturerName;
        if (!manufacturers.includes(manufacturer)) {
          manufacturers.push(manufacturer);
        }
      }
    } catch (e) {
      console.error(e);
    }
    return manufacturers;
  }
  function populateManufacturerFleets(manufacturer: any) {
    const fleets: string[] = [];
    const o = {};
    if (aircrafts) {
      for (let i = 0; i < aircrafts.length; i++) {
        const fleet = aircrafts[i].aircraftFamilyName;
        if (aircrafts[i].manufacturerName === manufacturer && !fleets.includes(fleet) && fleet !== null) {
          Object.defineProperty(o, fleet, {
            value: populateFleetVariants(fleet),
            enumerable: true,
          });
        }
        fleets.push(fleet);
      }
    }
    return o;
  }

  function createAirplaneObjectsWithFields(man: any) {
    const manufacturerList: IManufacturersList = {};
    for (let i = 0; i < man.length; i++) {
      manufacturerList[man[i]] = {
        fleets: populateManufacturerFleets(man[i]),
        // configurations: populateManufacturerConfigurations(manufacturers[i]),
      };
    }
    return manufacturerList;
  }
  function populateFleetVariants(fleet: any) {
    const variants = [];
    for (let i = 0; i < aircrafts.length; i++) {
      if (aircrafts[i].aircraftFamilyName === fleet) variants.push(aircrafts[i].aircraftName);
    }
    return { variants };
  }

  const handleManufacturerClick = (manufacturerName: string) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    const manufacturerAlreadySet = assetManagerState.assetLibraryFilters.some(
      (filterItem: AssetManagerTypes.FilterListItem) => {
        return filterItem.manufacturerId !== null;
      },
    );

    if (manufacturerAlreadySet) {
      setDropdown(() => {
        return {
          ...dropdown,
          manufacturer: false,
        };
      });
      return;
    }

    let updatedSpecs = {
      variant:
        manufacturerList[manufacturerName].fleets[Object.keys(manufacturerList[manufacturerName].fleets)[0]]
          ?.variants[0] ?? NONE_VARIANT_ID,
      fleet: Object.keys(manufacturerList[manufacturerName].fleets)[0],
      manufacturer: manufacturerName,
    };
    if (manufacturerName === "None") {
      setDropdown({
        currentLesson: false,
        manufacturer: true,
        model: false,
        variant: false,
      });
      {
        updatedSpecs = {
          manufacturer: "", //manufacturerName
          fleet: "", //aircraftFamilyName
          variant: "", //aircraftName
        };
      }
    }
    assetManagerDispatch({
      type: "SET_SELECTED_AIRCRAFT_DATA",
      payload: updatedSpecs,
    });
    if (props.listLoading) {
      //wait till loading is done.
    } else {
      const manufacturerId = findAirplane("manufacturerId", manufacturerName);

      assetManagerDispatch({
        type: "ADD_FILTER",
        payload: {
          manufacturerId: manufacturerId,
          manufacturerName: manufacturerName,
          aircraftFamilyId: null,
          aircraftFamilyName: null,
          aircraftId: null,
          aircraftName: null,
        },
      });
      assetManagerDispatch({
        type: "SET_LIBRARY_PAGINATION",
        payload: {
          currentPage: 1,
          totalPages: null,
          previousPage: null,
          nextPage: null,
        },
      });

      // disable Manufacturer drop down here... TODO
      setDropdown({
        ...dropdown,
        manufacturer: false,
      });
      setEnabledDropdowns({
        ...enabledDropdowns,
        manufacturer: false,
        model: manufacturerName !== "None",
        variant: false,
      });
    }
  };

  const handleFleetClick = (fleetName: string) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();

    if (assetManagerState.currentlySelectedAircraftData.manufacturer !== "" && manufacturerList) {
      // setAircraftData({
      //   ...aircraftData,
      //   fleet: fleetName,
      //   variant: manufacturerList[aircraftData.manufacturer].fleets[fleetName].variants[0],
      // });
      const updatedSpecs = {
        fleet: fleetName,
        variant:
          manufacturerList[assetManagerState.currentlySelectedAircraftData.manufacturer].fleets[fleetName].variants[0],
      };
      assetManagerDispatch({
        type: "SET_SELECTED_AIRCRAFT_DATA",
        payload: updatedSpecs,
      });

      if (props.listLoading) {
      } else {
        const familyId = findAirplane("aircraftFamilyId", fleetName);
        assetManagerDispatch({
          type: "ADD_FILTER",
          payload: {
            manufacturerId: null,
            manufacturerName: null,
            aircraftFamilyId: familyId,
            aircraftFamilyName: fleetName,
            aircraftId: null,
            aircraftName: null,
          },
        });
        assetManagerDispatch({
          type: "SET_LIBRARY_PAGINATION",
          payload: {
            currentPage: 1,
            totalPages: null,
            previousPage: null,
            nextPage: null,
          },
        });

        //   props.setFiltersCurrentlyActive((currentFilters: Array<AssetManagetTypes.FilterListItem>) => {
        //     let isAlreadyAFilter = currentFilters.find((filterItem) => {
        //       return filterItem.aircraftFamilyId === familyId;
        //     });
        //     if(isAlreadyAFilter){
        //       return currentFilters;
        //     } else {
        //       return  [...currentFilters, {
        //         manufacturerId: null,
        //         manufacturerName: null,
        //         aircraftFamilyId: familyId,
        //         aircraftFamilyName: fleetName,
        //         aircraftId:null,
        //         aircraftName:null
        //   }]
        //     }
        //    //nothing has changed so do nothing
        // })

        // disable Model (fleet) drop down here... TODO

        setDropdown({
          ...dropdown,
          model: false,
        });
        setEnabledDropdowns({
          ...enabledDropdowns,
          model: false,
          variant: true,
        });
      }
    }
  };

  const handleVariantClick = (variantName: string) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    const updates = {
      variant: variantName,
    };
    assetManagerDispatch({
      type: "SET_SELECTED_AIRCRAFT_DATA",
      payload: updates,
    });

    if (props.listLoading) {
    } else {
      const aircraftId = findAirplane("aircraftId", variantName);
      assetManagerDispatch({
        type: "ADD_FILTER",
        payload: {
          manufacturerId: null,
          manufacturerName: null,
          aircraftFamilyId: null,
          aircraftFamilyName: null,
          aircraftId: aircraftId,
          aircraftName: variantName,
        },
      });
      assetManagerDispatch({
        type: "SET_LIBRARY_PAGINATION",
        payload: {
          currentPage: 1,
          totalPages: null,
          previousPage: null,
          nextPage: null,
        },
      });
      setDropdown({
        ...dropdown,
        variant: false,
      });
      setEnabledDropdowns({
        ...enabledDropdowns,
        variant: false,
      });
    }
  };

  function findAirplane(flag: string, override?: string) {
    if (flag === "manufacturerId" && (assetManagerState.currentlySelectedAircraftData.manufacturer || override)) {
      const manufacturerId = aircrafts.find((el: IAircraft, index: number) => {
        return (
          el.manufacturerName === (override ? override : assetManagerState.currentlySelectedAircraftData.manufacturer)
        );
      })!["manufacturerId"];
      return manufacturerId;
    } else if (
      flag === "aircraftFamilyId" &&
      ((assetManagerState.currentlySelectedAircraftData.manufacturer &&
        assetManagerState.currentlySelectedAircraftData.fleet) ||
        override)
    ) {
      const aircraftFamilyId = aircrafts.find((el: IAircraft, index: number) => {
        return el.aircraftFamilyName === (override ? override : assetManagerState.currentlySelectedAircraftData.fleet);
      })!["aircraftFamilyId"];
      return aircraftFamilyId;
    } else if (
      flag === "aircraftId" &&
      ((assetManagerState.currentlySelectedAircraftData.manufacturer &&
        assetManagerState.currentlySelectedAircraftData.fleet &&
        assetManagerState.currentlySelectedAircraftData.variant) ||
        override)
    ) {
      const aircraftId = aircrafts.filter((el: IAircraft, index: number) => {
        return el.aircraftName === (override ? override : assetManagerState.currentlySelectedAircraftData.variant);
      })[0]["aircraftId"];
      return aircraftId;
    } else {
      return null;
    }
  }
  const handleCellClick = (deletedFilter: AssetManagerTypes.FilterListItem) => () => {
    if (props.listLoading) return;

    if (deletedFilter.manufacturerId) {
      setEnabledDropdowns({
        ...enabledDropdowns,
        manufacturer: true,
        model: false,
        variant: false,
      });
      assetManagerDispatch({
        type: "REMOVE_DOWNSTREAM_FILTERS",
        payload: ["manufacturerId", "aircraftFamilyId", "aircraftId"],
      });
    } else if (deletedFilter.aircraftFamilyId) {
      setEnabledDropdowns({
        ...enabledDropdowns,
        model: true,
        variant: false,
      });
      assetManagerDispatch({
        type: "REMOVE_DOWNSTREAM_FILTERS",
        payload: ["aircraftFamilyId", "aircraftId"],
      });
    } else if (deletedFilter.aircraftId) {
      setEnabledDropdowns({
        ...enabledDropdowns,
        variant: true,
      });
      assetManagerDispatch({
        type: "REMOVE_DOWNSTREAM_FILTERS",
        payload: ["aircraftId"],
      });
    } else {
      assetManagerDispatch({
        type: "REMOVE_FILTER",
        payload: deletedFilter,
      });
    }
    assetManagerDispatch({
      type: "SET_LIBRARY_PAGINATION",
      payload: {
        currentPage: 1,
        totalPages: null,
        previousPage: null,
        nextPage: null,
      },
    });
    setDropdown({
      ...dropdown,
      manufacturer: false,
      model: false,
      variant: false,
    });
  };

  const handleCurrentLesson = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    assetManagerDispatch({
      type: "ADD_FILTER",
      payload: {
        manufacturerId: null,
        manufacturerName: null,
        aircraftFamilyId: null,
        aircraftFamilyName: null,
        aircraftId: null,
        aircraftName: null,
        currentLesson: true,
        myUploaded: null,
      },
    });
    assetManagerDispatch({
      type: "SET_LIBRARY_PAGINATION",
      payload: {
        currentPage: 1,
        totalPages: null,
        previousPage: null,
        nextPage: null,
      },
    });
  };
  const handleRecentlyUploaded = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    assetManagerDispatch({
      type: "ADD_FILTER",
      payload: {
        manufacturerId: null,
        manufacturerName: null,
        aircraftFamilyId: null,
        aircraftFamilyName: null,
        aircraftId: null,
        aircraftName: null,
        recentlyUploaded: true,
        myUploaded: null,
      },
    });
    assetManagerDispatch({
      type: "SET_LIBRARY_PAGINATION",
      payload: {
        currentPage: 1,
        totalPages: null,
        previousPage: null,
        nextPage: null,
      },
    });
  };

  const handleMyUploaded = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    assetManagerDispatch({
      type: "ADD_FILTER",
      payload: {
        manufacturerId: null,
        manufacturerName: null,
        aircraftFamilyId: null,
        aircraftFamilyName: null,
        aircraftId: null,
        aircraftName: null,
        recentlyUploaded: null,
        myUploaded: true,
      },
    });
    assetManagerDispatch({
      type: "SET_LIBRARY_PAGINATION",
      payload: {
        currentPage: 1,
        totalPages: null,
        previousPage: null,
        nextPage: null,
      },
    });
  };

  function formatFilterName(el: any) {
    let name = Object.entries(el).find((filter: any[], index: number) => {
      return typeof filter[1] === "boolean";
    })![0];
    switch (name) {
      case "currentLesson":
        name = "Current Lesson";
        break;
      case "myUploaded":
        name = "My Uploads";
        break;
      case "recentlyUploaded":
        name = "Recently Uploaded";
        break;
      default:
        break;
    }
    return name;
  }

  function formatFilterTags(filterItem: AssetManagerTypes.FilterListItem) {
    const tagArray = Object.entries(filterItem).find((el: any[]) => {
      return typeof el[1] === "string";
    })!;
    let [prefixString, suffixString] = tagArray;
    prefixString = `${tagArray[0].split("N")[0]}`.toLowerCase();

    switch (prefixString) {
      case "manufacturer":
        prefixString = "Manufacturer";
        break;
      case "aircraftfamily":
        prefixString = "Model";
        break;
      case "aircraft":
        prefixString = "Variant";
    }
    return `${prefixString}: ${suffixString}`;
  }

  return (
    <React.Fragment>
      <div className="assetManager-filter">
        <div className="filters-container">
          <div
            className="assetManager-filterField"
            onClick={(e) => {
              e.stopPropagation();
              setDropdown((prevState: any) => {
                return {
                  ...prevState,
                  currentLesson: !prevState.currentLesson,
                };
              });
            }}
          >
            <p>Advanced Filters</p>
            <div className="open-close-filter-arrow">
              <DropdownFilterIcon />
            </div>
            {dropdown.currentLesson && (
              <div className="manufacturer-options-filter">
                <div className="manufacturer-options-filter-list-item" onMouseDown={handleCurrentLesson}>
                  <p>Current Lesson</p>
                </div>
                <div className="manufacturer-options-filter-list-item" onMouseDown={handleRecentlyUploaded}>
                  <p>Recently Uploaded</p>
                </div>
                <div className="manufacturer-options-filter-list-item" onMouseDown={handleMyUploaded}>
                  <p data-tooltip-id="MyUploads">My Uploads</p>
                  <Tooltip id="MyUploads" />
                </div>
              </div>
            )}
          </div>
          <div
            className={`assetManager-filterField ${enabledDropdowns.manufacturer ? "" : "master-disabled-button"}`}
            onMouseDown={(e) => {
              if (!enabledDropdowns.manufacturer) return;
              setDropdown((dropdown: any) => {
                return {
                  ...dropdown,
                  manufacturer: !dropdown.manufacturer,
                };
              });
            }}
          >
            <p>Manufacturer</p>
            <div className="open-close-filter-arrow">
              <DropdownFilterIcon style={dropdown.manufacturer ? { transform: "rotate(180deg)" } : {}} />
            </div>
            {
              <div className="manufacturer-options-filter">
                {dropdown.manufacturer &&
                  manufacturers.map((el, index) => (
                    <div
                      className="manufacturer-options-filter-list-item"
                      key={"dsfg" + index}
                      onMouseDown={handleManufacturerClick(el)}
                    >
                      <p>{el}</p>
                    </div>
                  ))}
              </div>
            }
          </div>
          <div
            className={`assetManager-filterField ${enabledDropdowns.model ? "" : "master-disabled-button"}`}
            onMouseDown={() => {
              if (!enabledDropdowns.model) return;
              setDropdown((dropdown: any) => {
                return {
                  ...dropdown,
                  model: !dropdown.model,
                };
              });
            }}
          >
            <p>Model</p>
            <div className="open-close-filter-arrow">
              <DropdownFilterIcon style={dropdown.model ? { transform: "rotate(180deg)" } : {}} />
            </div>
            {
              <div className="manufacturer-options-filter">
                {dropdown.model && manufacturerList && assetManagerState.currentlySelectedAircraftData.manufacturer
                  ? Object.keys(
                      manufacturerList[assetManagerState.currentlySelectedAircraftData.manufacturer].fleets,
                    ).map((fleet, index) => (
                      <div
                        className="manufacturer-options-filter-list-item"
                        key={"dsfg" + index}
                        onMouseDown={handleFleetClick(fleet)}
                        onMouseUp={(e) => e.stopPropagation()}
                      >
                        <p>{fleet}</p>
                      </div>
                    ))
                  : null}
              </div>
            }
          </div>
          <div
            className={`assetManager-filterField${enabledDropdowns.variant ? "" : " master-disabled-button"}`}
            onMouseDown={() => {
              if (!enabledDropdowns.variant) return;
              setDropdown((dropdown: any) => {
                return {
                  ...dropdown,
                  variant: !dropdown.variant,
                };
              });
            }}
          >
            <p>Variant</p>
            <div className="open-close-filte-arrow">
              <DropdownFilterIcon style={dropdown.variant ? { transform: "rotate(180deg)" } : {}} />
            </div>
            <div className="manufacturer-options-filter">
              {dropdown.variant &&
              assetManagerState.currentlySelectedAircraftData &&
              assetManagerState.currentlySelectedAircraftData.fleet
                ? manufacturerList[assetManagerState.currentlySelectedAircraftData.manufacturer].fleets[
                    assetManagerState.currentlySelectedAircraftData.fleet
                  ].variants.map((el: any, index: any, array: any) => (
                    <div
                      className="manufacturer-options-filter-list-item"
                      key={index}
                      onMouseDown={handleVariantClick(el)}
                      onMouseUp={(e) => e.stopPropagation()}
                    >
                      {el}
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
        <div className="selected-filters-container">
          {assetManagerState.assetLibraryFilters.length > 0 && (
            <React.Fragment>
              <div className="selected-filters-text">
                <p>Selected Filters</p>
              </div>
              <div className="selected-filters-box">
                {assetManagerState.assetLibraryFilters.map((el: AssetManagerTypes.FilterListItem, index: number) => {
                  return (
                    <div
                      key={"filtritms" + index}
                      className="asset-filter-cell"
                      onClick={handleCellClick(el)}
                      onMouseUp={(e) => e.stopPropagation()}
                      onMouseDown={(e) => e.stopPropagation()}
                    >
                      <p
                        data-hover={
                          el.currentLesson || el.recentlyUploaded || el.myUploaded || el.archivedItems
                            ? formatFilterName(el)
                            : Object.entries(el).find((el: any[]) => {
                                return typeof el[1] === "string";
                              })![1]
                        }
                      >
                        {el.currentLesson || el.recentlyUploaded || el.myUploaded || el.archivedItems
                          ? formatFilterName(el)
                          : formatFilterTags(el)}
                      </p>
                      <RemoveFilterIcon />
                    </div>
                  );
                })}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default AssetLibraryFilter;
