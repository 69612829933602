import { parseSemanticVersioning } from "./versioning";
type lineHeightUpdateVersion = "1.1.0";
type listsUpdateVersion = "1.2.0";
export type BasePageTextBlockVersion = lineHeightUpdateVersion | listsUpdateVersion | string;
export const CURRENT_TEXTBOX_VERSION = "1.2.0";
/**
 *
 * @param v semantic version
 * @returns a string that is the appropriate class or classes to set to override old styling with new styling
 */
export function basePageTextBoxWrapperVersioning(v: string) {
  const versionString = v;
  const version = parseSemanticVersioning(versionString);
  let classString = "";
  // introduced for the line-height font-size-classes edit
  const versionCheck1 = !!version && version[0] >= 1 && version[1] >= 1 && version[2] >= 0; //^1.1.0
  // introduced for bullet and numbered list changes
  const versionCheck2 = !!version && version[0] >= 1 && version[1] >= 2 && version[2] >= 0; //^1.2.0
  if (versionCheck1) {
    classString += " text-block-version-changes-1-1-0";
  }
  if (versionCheck2) {
    classString += " lists-version-changes-1-2-0";
  }
  return classString;
}
