import React, { PropsWithChildren, createContext, useContext, useReducer, useEffect } from "react";

// import { cloneDeep } from 'lodash';
// import { useMovableElementsPlaneDispatch } from '../MovableElementsPlaneProvider';
import { useInteractivityBuilderState } from "../InteractivityBuilderProvider";
import {
  InteracitvityHotspotAction,
  InteracitvityHotspotState,
  InteractivityHotspotActionTypes,
  InteractivityVisualHotspot,
} from "./types";
export * from "./types";
const {
  ADD_NEW_VISIBLE_HOTSPOT,
  SET_ALL_VISIBLE_HOTSPOTS,
  SET_CURRENT_HOTSPOT,
  SET_CURRENT_SELECTED_HOTSPOT_FROM_DOM_ELEMENT,
  UPDATE_DOM_ELEMENTS,
  EDIT_VISIBLE_HOTSPOT,
  CLEAR_ALL_VISIBLE_HOTSPOTS,
  REMOVE_VISIBLE_HOTSPOT,
  REPLACE_INNER_ACTION_ON_SELECTED_VIUSAL_HOTSPOT,
  CLEAR_NEED_SAVE,
} = InteractivityHotspotActionTypes;

const initialState: InteracitvityHotspotState = {
  visibleHotspots: [],
  currentlySelectedHotspot: null,
  needSave: false,
};

const InteracitvityHotspotStateContext = createContext<InteracitvityHotspotState>(initialState);
const InteracitvityHotspotDispatchContext = createContext(
  undefined as unknown as React.Dispatch<InteracitvityHotspotAction>,
);

const interacitvityHotspotReducer = (
  state: InteracitvityHotspotState,
  action: InteracitvityHotspotAction,
): InteracitvityHotspotState => {
  switch (action.type) {
    case SET_ALL_VISIBLE_HOTSPOTS: {
      const hotspots = action.payload.map((h, i) => {
        return new InteractivityVisualHotspot({
          height: h.hotspotLocation.height,
          width: h.hotspotLocation.width,
          top: h.hotspotLocation.top,
          left: h.hotspotLocation.left,
          id: i,
          interactivityBuilderAction: h,
          domReference: null,
        });
      });

      return { ...state, visibleHotspots: hotspots };
    }
    case CLEAR_ALL_VISIBLE_HOTSPOTS: {
      return { ...state, currentlySelectedHotspot: null, visibleHotspots: [] };
    }
    case SET_CURRENT_HOTSPOT: {
      return { ...state, currentlySelectedHotspot: action.payload };
    }
    case SET_CURRENT_SELECTED_HOTSPOT_FROM_DOM_ELEMENT: {
      const domElementTarget = action.payload;
      const hs = state.visibleHotspots.find((h) => h.domReference === domElementTarget);
      if (hs) {
        return { ...state, currentlySelectedHotspot: hs, needSave: true };
      } else {
        return state;
      }
    }
    case REMOVE_VISIBLE_HOTSPOT: {
      const newVisualHotspots = state.visibleHotspots.filter((vhs) => {
        return vhs.interactivityBuilderAction !== action.hotspot;
      });
      return {
        ...state,
        visibleHotspots: newVisualHotspots,
        currentlySelectedHotspot: null,
        needSave: true,
      };
    }
    case REPLACE_INNER_ACTION_ON_SELECTED_VIUSAL_HOTSPOT: {
      const hsAction = action.payload;
      let newHs: InteractivityVisualHotspot;

      const newHotspots = state.visibleHotspots.map((vhs) => {
        if (vhs.interactivityBuilderAction === state.currentlySelectedHotspot?.interactivityBuilderAction) {
          newHs = new InteractivityVisualHotspot({
            top: hsAction.hotspotLocation.top,
            left: hsAction.hotspotLocation.left,
            height: hsAction.hotspotLocation.height,
            domReference: vhs.domReference,
            id: vhs.id,
            width: hsAction.hotspotLocation.width,
            interactivityBuilderAction: hsAction,
          });
          return newHs;
        }
        return vhs;
      });
      if (newHs!) {
        return {
          ...state,
          currentlySelectedHotspot: newHs!,
          visibleHotspots: newHotspots,
          needSave: true,
        };
      } else {
        throw new Error("Bad thing happened, while trying to substitue the internal action");
      }
    }
    case EDIT_VISIBLE_HOTSPOT: {
      const hsAction = action.payload;
      let newHs: InteractivityVisualHotspot;
      const newHotspots = state.visibleHotspots.map((vhs) => {
        if (vhs.interactivityBuilderAction === hsAction) {
          /**
           * generating a new object is clean in the sense of safe from muation bugs
           * but if we are storing the reference somewhere that link is immediately broken
           */

          newHs = new InteractivityVisualHotspot({
            top: hsAction.hotspotLocation.top,
            left: hsAction.hotspotLocation.left,
            height: hsAction.hotspotLocation.height,
            domReference: vhs.domReference,
            id: vhs.id,
            width: hsAction.hotspotLocation.width,
            interactivityBuilderAction: hsAction,
          });
          return newHs;
        }
        return vhs;
      });
      if (newHs!) {
        return {
          ...state,
          visibleHotspots: newHotspots,
          currentlySelectedHotspot: newHs!,
          needSave: true,
        };
      } else {
        throw new Error("bad things happened could not find the visual hotsoit reference");
      }
    }
    case ADD_NEW_VISIBLE_HOTSPOT: {
      const { top, left, height, width, id, domReference, interactivityBuilderAction } = action.payload;
      let counter = 0;
      if (state.visibleHotspots.length) {
        const ids = state.visibleHotspots.map((ob) => {
          return ob.id;
        });

        counter = ids.reduce((prev, curr) => {
          return prev + curr;
        });
      }
      const hs = new InteractivityVisualHotspot({
        top: top ?? 0,
        left: left ?? 0,
        height: height ?? 10,
        width: width ?? 10,
        id: id ?? counter + state.visibleHotspots.length,
        domReference: domReference ?? null,
        interactivityBuilderAction: interactivityBuilderAction ?? null,
      });

      return {
        ...state,
        visibleHotspots: [...state.visibleHotspots, hs],
        currentlySelectedHotspot: hs,
        needSave: true,
      };
    }
    case UPDATE_DOM_ELEMENTS: {
      const matchedDomElements = Array.from(action.payload as NodeListOf<HTMLDivElement>);
      let visibleHotspots: InteractivityVisualHotspot[] = state.visibleHotspots;
      matchedDomElements.forEach((node) => {
        const hotspotId = node.attributes.getNamedItem("data-hotspot-id")?.value;
        if (hotspotId) {
          visibleHotspots = state.visibleHotspots.map((hs) => {
            if (hs.id.toString() === hotspotId) {
              hs.domReference = node;
            }
            return hs;
          });
        }
      });

      return { ...state, visibleHotspots };
    }
    case CLEAR_NEED_SAVE: {
      return { ...state, needSave: false };
    }
    default:
      return state;
  }
};

export function InteractivityHotspotProvider({ children }: PropsWithChildren<any>) {
  const [state, dispatch] = useReducer<
    (state: InteracitvityHotspotState, action: InteracitvityHotspotAction) => InteracitvityHotspotState
  >(interacitvityHotspotReducer, initialState);

  const interactivityBuilderState = useInteractivityBuilderState();
  useEffect(() => {
    return () => {};
  }, [interactivityBuilderState.interactivityBuilderInstance]);

  return (
    <InteracitvityHotspotDispatchContext.Provider value={dispatch}>
      <InteracitvityHotspotStateContext.Provider value={state}>{children}</InteracitvityHotspotStateContext.Provider>
    </InteracitvityHotspotDispatchContext.Provider>
  );
}

export function useInteracitvityHotspotDispatch() {
  const ctx = useContext(InteracitvityHotspotDispatchContext);
  if (ctx === undefined) {
    throw new Error("Wrap component in Wysiwyg Provider");
  }
  return ctx;
}
export function useInteracitvityHotspotState() {
  const ctx = useContext(InteracitvityHotspotStateContext);
  if (ctx === undefined) {
    throw new Error("Wrap component in Wysiwyg Provider");
  }
  return ctx;
}

export {};
