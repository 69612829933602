import React from "react";
const usePrevious = (value: any, initialValue: any) => {
  const ref = React.useRef(initialValue);
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useEffectDebugger = (
  effectHook: React.EffectCallback,
  dependencies: React.DependencyList | undefined,
  dependencyNames: string[] = [],
) => {
  const previousDeps = usePrevious(dependencies, []);
  const changedDeps = dependencies!.reduce((accum, dependency, index) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames[index] || index;
      return {
        ...accum,
        [keyName]: {
          before: previousDeps[index],
          after: dependency,
        },
      };
    }

    return accum;
  }, {});

  if (Object.keys(changedDeps).length) {
  }

  React.useEffect(effectHook, dependencies);
};
