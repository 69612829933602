import "./ObjectPropertyBox.css";
import React, { ChangeEvent, MouseEvent, useContext, useEffect, useState, useMemo } from "react";
import { IPBRefs, IPropertyBox } from "./models/IObjectPropertyBox";
import { ElementTypes, IBasicPageAttributes } from "../../pageTypes/BasicPage_Player/components/IBasePage";
import { IAnnotation } from "../Annotation/models/IAnnotation";
import { ISymbolV2 } from "../Symbol/models/ISymbol";
import ZIndexInput from "./components/ZIndexInput";
import {
  handleHeightChange,
  handleImageHeightChange,
  handleImageLeftChange,
  handleImageTopChange,
  handleImageWidthChange,
  handleLeftChange,
  handleNameChange,
  handleRotationChange,
  handleTopChange,
  handleWidthChange,
  restoreImage,
} from "./functions/PropertyBoxFunctions";
import RotationInput from "./components/RotationInput";
import { PageContext } from "../../routes/builderContexts";
import Moveable from "../react-moveable";
import {
  useObjectPropertyBoxDispatch,
  useObjectPropertyBoxState,
} from "../../contexts/ObjectPropertyBox/ObjectPropertyBoxProvider";

import EditImageMenu from "./components/EditImageMenu";
export type PropertyBoxType = {
  elementType: ElementTypes;
  isRatioEqual?: boolean;
  moveRef: any;
  nodeToUpdate: any;
  pageDims: DOMRect | null | undefined;
  pbRefs: IPBRefs;
  properties: IPropertyBox;
  shouldMaintainRatio: boolean;
  target: HTMLElement | SVGSVGElement;
  changePropertyBox(properties: IPropertyBox): void;
  setIsRatioEqual?: (boolean: boolean) => void;
  updateIsDisplayed?: (value: boolean) => void;
  updateAttributes:
    | ((attributes: IAnnotation) => void)
    | ((attributes: IBasicPageAttributes) => void)
    | ((attributes: ISymbolV2) => void);
  isCroppable?: boolean;
  setIsCroppable?: (value: boolean) => void;
  isCropping?: boolean;
  setIsCropping?: (value: boolean) => void;
  allowedToCrop?: boolean;
  targetIsCroppedImage?: boolean;
  updateZindex: (value: string) => void;
  isDisplayed: boolean;
  isDisplayedTabShow?: boolean;
  permissions?: { [key: string]: boolean };
  endActions?: (target: HTMLElement | SVGElement, passedNode: any, e: any) => void;
  updateMaintainRatio?: (value: boolean) => void;
  index?: number;
  bounds?: any;
};

let top: HTMLInputElement;
let left: HTMLInputElement;
let degrees: HTMLInputElement;
let height: HTMLInputElement;
let width: HTMLInputElement;
// let moveRef: any;

export function updatePropertyBox(properties: IPropertyBox) {
  // runs while dragging
  if (top) top.value = properties.top.toFixed(2);
  if (left) left.value = properties.left.toFixed(2);
  if (degrees) degrees.value = properties.rotation ? properties.rotation.toFixed(2) : "0.00";
  if (height) height.value = properties.height.toFixed(2);
  if (width) width.value = properties.width.toFixed(2);
  // if(ref) moveRef = ref;
}

const ObjectPropertyBox = (props: PropertyBoxType) => {
  const {
    elementType,
    isRatioEqual,
    moveRef,
    pbRefs,
    properties,
    target,
    setIsRatioEqual,
    updateZindex,
    nodeToUpdate,
    isCropping,
    setIsCropping,
    isCroppable,
    setIsCroppable,
    allowedToCrop,
    targetIsCroppedImage,
    bounds,
  } = props;
  const [displayName, setDisplayName] = useState<string>(properties.displayName ?? properties.name);
  const [imageFileName, setImageFileName] = useState<string>("");
  const pageContext = useContext(PageContext);
  const propertyBoxState = useObjectPropertyBoxState();
  const propertyBoxDispatch = useObjectPropertyBoxDispatch();
  const showImageEditMenu = allowedToCrop && setIsCropping && setIsCroppable && typeof isCropping !== "undefined";
  const [croppedPositions, setCroppedPositions] = useState<Array<number>>([0.0, 0.0]);

  const objectIsInTimeline = useMemo(() => {
    const is = !!pageContext?.pageManifest?.timeline?.animatedObjects?.find((ao: any) => {
      return ao.id === nodeToUpdate?.objectId;
    });
    return is;
  }, [nodeToUpdate?.objectId, pageContext?.pageManifest?.timeline?.animatedObjects?.length]);

  if (pbRefs.opbLeftRef) left = pbRefs.opbLeftRef.current as HTMLInputElement;
  if (pbRefs.opbTopRef) top = pbRefs.opbTopRef.current as HTMLInputElement;
  if (pbRefs.opbDegreesRef) degrees = pbRefs.opbDegreesRef.current as HTMLInputElement;
  if (pbRefs.opbWidthRef) width = pbRefs.opbWidthRef.current as HTMLInputElement;
  if (pbRefs.opbHeightRef) height = pbRefs.opbHeightRef.current as HTMLInputElement;

  const [maintainRatio, setMaintainRatio] = useState<boolean>(
    props.nodeToUpdate?.maintainRatio || typeof props.nodeToUpdate?.maintainRatio === "undefined",
  );

  useEffect(() => {
    if (target && target?.classList) {
      const ffVideo = "ff-video";
      target.classList.forEach((item: any) => {
        if (item === ffVideo) {
          pbRefs.obpZindex.current?.setAttribute("min", "1");
          pbRefs.obpZindex.current?.setAttribute("max", "1");
        }
        // else {
        //   pbRefs.obpZindex.current?.setAttribute('min', '12');
        //   pbRefs.obpZindex.current?.setAttribute('max', '211');
        // }
      });
    }
  }, [pbRefs.obpZindex, target]);

  useEffect(() => {
    setDisplayName(properties.displayName ?? properties.name ?? "");
  }, [properties.displayName, properties.name]);

  useEffect(() => {
    if (!props.nodeToUpdate) return;

    if (elementType === "pageImage") {
      if (nodeToUpdate.imagePath) {
        const blobPathArray = nodeToUpdate.imagePath.split("/");
        setImageFileName(blobPathArray[blobPathArray.length - 1]);
      }
    }

    if (elementType === "video") {
      if (nodeToUpdate.path) {
        const blobPathArray = nodeToUpdate.path.split("/");
        setImageFileName(blobPathArray[blobPathArray.length - 1]);
      }
    }

    if (elementType === "pageScormObject") {
      if (nodeToUpdate.blobPath) {
        const blobPathArray = nodeToUpdate.blobPath.split("/");
        const fileName = blobPathArray[blobPathArray.length - 1].replace("_unpacked", "");
        setImageFileName(fileName);
      }
    }

    setMaintainRatio(props.nodeToUpdate?.maintainRatio || typeof props.nodeToUpdate?.maintainRatio === "undefined");
  }, [props.nodeToUpdate]);

  function showStretchable() {
    if (elementType === "pageImage") {
      return (
        <section className="maintain-ratio-input">
          <h5>Maintain Ratio</h5>
          <input
            type="checkbox"
            name="stretchable"
            id="stretchable"
            checked={maintainRatio}
            onChange={(e) => {
              if (!props.nodeToUpdate || !props.updateMaintainRatio) return;
              props.updateMaintainRatio(!maintainRatio);
              setMaintainRatio(!maintainRatio);
              if (setIsRatioEqual) setIsRatioEqual(!isRatioEqual);
            }}
          />
        </section>
      );
    } else {
      return <></>;
    }
  }

  const restoreImageDispatch = () => {
    if (!setIsCropping || !setIsCroppable) return;
    restoreImage(target, props, moveRef, croppedPositions, isCropping, bounds);
    setIsCropping(false);
    setIsCroppable(false);
  };

  const cropDispatch = () => {
    if (!setIsCroppable || !setIsCropping) return;
    setIsCropping(!isCropping);
    setIsCroppable(!isCroppable);
  };

  function showEditImage() {
    if (elementType === "pageImage" && pageContext?.pageManifest?.basePageType === "freeForm") {
      return (
        <section className={"property-box-edit-image-section"}>
          {showImageEditMenu && (
            <EditImageMenu
              isCropping={Boolean(isCropping)}
              isCroppedImage={Boolean(targetIsCroppedImage)}
              startCropping={cropDispatch}
              restoreImage={restoreImageDispatch}
              nodeToUpdate={nodeToUpdate}
              endActions={props.endActions}
              target={target}
            />
          )}
        </section>
      );
    } else {
      return <></>;
    }
  }

  const isScorm = target?.id === "ff-scorm-main";

  const dispatchWidthChange = (value: string, target: any, props: PropertyBoxType, moveRef: any) => {
    const inputValue = parseFloat(value) > 15 ? value : "15";
    if (elementType === "pageImage") {
      return handleImageWidthChange(inputValue, target, props, moveRef);
    }
    return handleWidthChange(inputValue, props, moveRef);
  };

  const dispatchHeightChange = (value: string, target: any, props: PropertyBoxType, moveRef: any) => {
    const inputValue = parseFloat(value) > 15 ? value : "15";
    if (elementType === "pageImage") {
      return handleImageHeightChange(inputValue, target, props, moveRef);
    }
    return handleHeightChange(inputValue, props, moveRef);
  };

  const dispatchTopChange = (value: string, delta: number, props: PropertyBoxType, moveRef: any) => {
    if (elementType === "pageImage" && properties.rotation !== 0) {
      return handleImageTopChange(value, delta, props, moveRef);
    }
    return handleTopChange(value, props, moveRef);
  };

  const dispatchLeftChange = (value: string, delta: number, props: PropertyBoxType, moveRef: any) => {
    if (elementType === "pageImage" && properties.rotation !== 0) {
      return handleImageLeftChange(value, delta, props, moveRef);
    }
    return handleLeftChange(value, props, moveRef);
  };

  const changeTop = (e: ChangeEvent<HTMLInputElement>) => {
    dispatchTopChange(e.currentTarget.value, parseFloat(e.currentTarget.value) - properties.top, props, moveRef);
  };

  const changeLeft = (e: ChangeEvent<HTMLInputElement>) => {
    dispatchLeftChange(e.currentTarget.value, parseFloat(e.currentTarget.value) - properties.left, props, moveRef);
  };

  useEffect(() => {
    if (properties.top < 0.1) {
      properties.top = 0;
    }
    if (properties.left < 0.1) {
      properties.left = 0;
    }
  }, [properties]);

  const callbackRef = React.useCallback(
    (el: HTMLElement | null) => {
      if (propertyBoxState.objectPropertyBoxDomNode !== el && el !== null) {
        propertyBoxDispatch({
          type: "SET_OBJECT_PROPERTY_BOX_DOM_NODE",
          payload: el,
        });
      }
    },
    [propertyBoxState.objectPropertyBoxDomNode],
  );

  function showImageFileName() {
    if (elementType === "pageImage" || elementType === "video" || elementType === "pageScormObject") {
      return (
        <section className="property-box-file-name-section">
          <h5>File Name</h5>
          <input disabled type="text" name="file-name" className="object-name-input" value={imageFileName} />
        </section>
      );
    } else {
      return <></>;
    }
  }

  return (
    <>
      <div ref={callbackRef as React.LegacyRef<HTMLDivElement>} className="property-box-container">
        <div className="property-box-header">
          Properties
          <span className="icon-move-window-icon"></span>
        </div>
        {showImageFileName()}
        <section
          className={
            elementType !== "pageImage" && elementType !== "video" && elementType !== "pageScormObject"
              ? "property-box-name-section-non-image"
              : "property-box-name-section"
          }
        >
          <h5>Object Name</h5>
          <input
            disabled={false}
            type="text"
            name="object-name"
            className="object-name-input"
            placeholder="Enter name here"
            value={displayName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setDisplayName(e.currentTarget.value);
              handleNameChange(e.currentTarget.value, props, moveRef);
            }}
          />
        </section>
        <section
          className={
            elementType !== "pageImage" && elementType !== "video" && elementType !== "pageScormObject"
              ? "property-box-position-section-non-image"
              : "property-box-position-section"
          }
        >
          <h5>Position</h5>
          <div className="position-grid">
            <label htmlFor="y-property">
              top:
              <input
                ref={pbRefs.opbTopRef}
                type="number"
                name="y-property"
                className="number-property"
                min={0}
                max={props.pageDims?.height}
                value={properties.top.toFixed(2)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  changeTop(e);
                  if ((e.nativeEvent as any).inputType && props.endActions && elementType === "pageImage") {
                    props.endActions(target, props.nodeToUpdate, null);
                  }
                }}
                onMouseUp={(e) => {
                  if (props.endActions) {
                    props.endActions(target, props.nodeToUpdate, e);
                  }
                }}
              />
            </label>
            <label htmlFor="x-property">
              left:
              <input
                ref={pbRefs.opbLeftRef}
                type="number"
                name="x-property"
                className="number-property"
                min={0}
                max={props.pageDims?.width}
                value={properties.left.toFixed(2)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  changeLeft(e);
                  if ((e.nativeEvent as any).inputType && props.endActions && elementType === "pageImage") {
                    props.endActions(target, props.nodeToUpdate, e);
                  }
                }}
                onMouseUp={(e) => {
                  if (props.endActions) {
                    props.endActions(target, props.nodeToUpdate, e);
                  }
                }}
              />
            </label>

            <ZIndexInput pbRefs={pbRefs} zIndex={properties.zIndex} updateZindex={updateZindex} />
          </div>
        </section>
        {!isScorm && (
          <RotationInput
            moveRef={moveRef}
            pbProps={props}
            pbRefs={pbRefs}
            properties={properties}
            handleRotationChange={handleRotationChange}
            elementType={elementType}
          />
        )}
        <section
          className={
            elementType !== "pageImage" && elementType !== "video" && elementType !== "pageScormObject"
              ? "property-box-dimension-section-non-image"
              : "property-box-dimension-section"
          }
        >
          <h5>Dimensions</h5>
          <label htmlFor="width-property">
            w:
            <input
              ref={pbRefs.opbWidthRef}
              type="number"
              name="width-property"
              className="number-property"
              min={15}
              value={properties.width.toFixed(2)}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                dispatchWidthChange(e.currentTarget.value, target, props, moveRef);
                if ((e.nativeEvent as any).inputType && props.endActions && elementType === "pageImage") {
                  props.endActions(target, props.nodeToUpdate, e);
                }
              }}
              onMouseUp={(e: MouseEvent) => {
                if (elementType === "pageImage" && props.endActions) {
                  props.endActions(target, props.nodeToUpdate, e);
                }
              }}
            />
          </label>
          <label htmlFor="height-property">
            h:
            <input
              ref={pbRefs.opbHeightRef}
              type="number"
              name="height-property"
              className="number-property"
              min={15}
              value={properties.height.toFixed(2)}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                dispatchHeightChange(e.currentTarget.value, target, props, moveRef);
                if ((e.nativeEvent as any).inputType && props.endActions && elementType === "pageImage") {
                  props.endActions(target, props.nodeToUpdate, null);
                }
              }}
              onMouseUp={(e: MouseEvent) => {
                if (elementType === "pageImage" && props.endActions) {
                  props.endActions(target, props.nodeToUpdate, e);
                }
              }}
            />
          </label>
        </section>
        {showStretchable()}
        {props.permissions?.interactivity &&
          pageContext?.pageManifest?.basePageType === "freeForm" &&
          !(elementType === "pageScormObject" || elementType === "video") && (
            <>
              {!objectIsInTimeline && (
                <section
                  className={
                    elementType === "pageImage" ? "property-box-visible-section" : "property-box-position-visible"
                  }
                >
                  <div className="position-grid visibity-input">
                    <label htmlFor="visibility-property">Visible</label>
                    <input
                      type="checkbox"
                      name="visibility-property-checkbox"
                      className="number-property"
                      checked={props.isDisplayed}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        // set selected elements
                        if (props.updateIsDisplayed) props.updateIsDisplayed(!props.isDisplayed);
                      }}
                    />
                  </div>
                </section>
              )}
              <section
                className={
                  elementType === "pageImage"
                    ? "property-box-timeline-section"
                    : "property-box-position-timeline-non-image"
                }
              >
                <div className="timeline-inputs-container"></div>
                <div></div>
              </section>
            </>
          )}
        {showEditImage()}
      </div>
      <Moveable
        target={document.querySelector(".property-box-container") as HTMLDivElement}
        dragTarget={document.querySelector(".property-box-header") as HTMLDivElement}
        className={"pb-moveable"}
        draggable={true}
        onDragStart={({ inputEvent }) => {
          inputEvent.stopPropagation();
        }}
        onDrag={({ target, left, top, inputEvent }) => {
          const parent = target.parentElement;
          const p_w = parent?.offsetWidth!;
          const p_h = parent?.offsetHeight!;
          const leftPercentage = (100 * left) / p_w;
          const topPercentage = (100 * top) / p_h;
          target.style.left = leftPercentage + "%";
          target.style.top = topPercentage + "%";

          if (parseFloat(target.style.left) < 1) {
            target.style.left = 1 + "%";
          }
          if (parseFloat(target.style.top) < 1) {
            target.style.top = 1 + "%";
          }

          const widthPercentage = ((target as HTMLDivElement)?.offsetWidth! * 100) / parent?.offsetWidth!;
          const heightPercentage = ((target as HTMLDivElement)?.offsetHeight! * 100) / parent?.offsetHeight!;

          if (parseFloat(target.style.top) > 100 - heightPercentage - 1) {
            target.style.top = 100 - heightPercentage - 1 + "%";
          }
          if (parseFloat(target.style.left) > 100 - widthPercentage - 1) {
            target.style.left = 100 - widthPercentage - 1 + "%";
          }
        }}
        onDragEnd={(e) => {
          const { left, top } = e.target.style;
          propertyBoxDispatch({
            type: "SET_OBJECT_PROPERTY_BOX_COORDS",
            payload: {
              top: parseFloat(top),
              left: parseFloat(left),
            },
          });
        }}
      />
    </>
  );
};

export default ObjectPropertyBox;
