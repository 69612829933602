import React, { useState, useEffect } from "react";
import { useTablesDataDispatch, useTablesDataState } from "./TablesDataProvider";
import MenuNewTableSize from "./MenuNewTableSize";
import { ReactComponent as RemoveColumn } from "./assets/remove-column.svg";
import { ReactComponent as RemoveRow } from "./assets/remove-row.svg";
import { useObjectsDispatch, ObjectActionsType } from "../../contexts/ObjectsProvider";

type RemoveRowMenu = {
  isMenuOpen: boolean;
  setIsMenuOpen(boolean: boolean): void;
};

function RemoveRowMenu(props: RemoveRowMenu) {
  const TablesDispatch = useTablesDataDispatch();
  const TablesState = useTablesDataState();
  const objectsDispatch = useObjectsDispatch();

  useEffect(() => {
    function clickOutsideMenu(e: MouseEvent) {
      props.setIsMenuOpen(false);
      document.removeEventListener("click", clickOutsideMenu);
    }
    if (props.isMenuOpen) {
      document.addEventListener("click", clickOutsideMenu);
    }
  }, [props.isMenuOpen]);

  return props.isMenuOpen ? (
    // <div className="table-inset-menu">
    <div className="table-remove-menu-body">
      <div
        onClick={() => {
          props.setIsMenuOpen(false);
          // TablesDispatch({ action: "removeRow", payload: [null] });
          objectsDispatch({
            type: ObjectActionsType.UPDATE_TABLE,
            payload: { type: "removeRow", selection: TablesState.selectedRow },
          });
        }}
      >
        <div className="flex-row">
          {" "}
          <RemoveRow></RemoveRow>Remove Row
        </div>
      </div>
    </div>
  ) : null;
}

export default RemoveRowMenu;
