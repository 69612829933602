import React, { useState, useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { showModal } from "../../../store/actions/actions";
import ProceduresDropDown from "./ProceduresDropDown";
import {
  IPageContext,
  PageContext,
  LessonPageActionsContext,
  ILessonPageActionsContext,
} from "../../../routes/builderContexts";

interface proceduresProps {
  showModal: any;
  lessonMetaData: any;
  setShowModal: (value: boolean) => void;
  deletePageSequence: (type: string) => void;
  allAvailableAircraft: any;
  setLessonMetaData: (data: any) => void;
}

const SelectProceduresModal = (props: proceduresProps) => {
  let aircrafts: any = props.allAvailableAircraft.aircrafts;
  const pagesContext: IPageContext = useContext<IPageContext>(PageContext); //cant use page context will need to reafactor fms designer for that
  const lessonPageActions: ILessonPageActionsContext = useContext<ILessonPageActionsContext>(LessonPageActionsContext);
  const [aircraftData, setAircraftData]: [any, any] = useState(
    aircrafts
      .filter((el: any, index: number) => {
        return el.aircraftId === props.lessonMetaData.aircraftId;
      })
      .map((el: any, index: number) => {
        return {
          manufacturer: el.manufacturerName, //manufacturerName
          fleet: el.aircraftFamilyName, //aircraftFamilyName
          variant: el.aircraftName, //aircraftName
          configuration: el.configuration, //configurationName
        };
      })[0],
  );
  const [loaded, setLoaded]: [any, any] = useState(true);

  const manufacturers: string[] = [];
  let manufacturerList: any;
  if (props.allAvailableAircraft) {
    aircrafts = props.allAvailableAircraft.aircrafts;
    function makeManufacturersList() {
      for (let i = 0; i < aircrafts.length; i++) {
        const manufacturer = aircrafts[i].manufacturerName;
        if (!manufacturers.includes(manufacturer)) {
          manufacturers.push(manufacturer);
        }
      }
      return;
    }

    makeManufacturersList();

    function populateFleetVariants(fleet: any) {
      const variants = [];
      for (let i = 0; i < aircrafts.length; i++) {
        if (aircrafts[i].aircraftFamilyName === fleet) variants.push(aircrafts[i].aircraftName);
      }
      return { variants };
    }
    function populateManufacturerFleets(manufacturer: any) {
      const fleets: any = [];
      const o = {};
      for (let i = 0; i < aircrafts.length; i++) {
        const fleet = aircrafts[i].aircraftFamilyName;
        if (aircrafts[i].manufacturerName === manufacturer && !fleets.includes(fleet) && fleet !== null) {
          Object.defineProperty(o, fleet, {
            value: populateFleetVariants(fleet),
            enumerable: true,
          });
        }
        fleets.push(fleet);
      }
      return o;
    }

    function createAirplaneObjectsWithFields(): { fleets: any } {
      const manufacturerList: any = {};
      for (let i = 0; i < manufacturers.length; i++) {
        manufacturerList[manufacturers[i]] = {
          fleets: populateManufacturerFleets(manufacturers[i]),
          // configurations: populateManufacturerConfigurations(manufacturers[i]),
        };
      }
      return manufacturerList;
    }

    manufacturerList = createAirplaneObjectsWithFields();
  }

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (lessonPageActions.proceduresObject.modelSet === "no3DorFms") {
      props.deletePageSequence("ThreeDModel");
    } else {
      const craft = props.allAvailableAircraft.aircrafts.find((x: any) => x.aircraftId === findAirplane());
      pagesContext.addNewPageAndDisplay(
        pagesContext.currentlyDisplayedPageIndex + 1,
        "ThreeDModel",
        `${craft.aircraftName} Procedures`,
      );
    }
    props.setShowModal(!showModal);
    setLoaded((loaded: any) => loaded);
    props.setLessonMetaData((md: any) => ({
      ...md,
      aircraftId: findAirplane(),
    }));
  };

  function findAirplane() {
    if (aircrafts) {
      const x = aircrafts.filter((el: any) => {
        return el.aircraftName === aircraftData.variant;
      })[0]["aircraftId"];
      return x;
    }
  }

  useEffect(() => {
    if (aircrafts) {
      if (aircraftData.variant) {
        setAircraftData(
          aircrafts
            .filter((el: any, i: number) => el.aircraftId === props.lessonMetaData.aircraftId)
            .map((el: any, i: number) => ({
              manufacturer: el.manufacturerName, //manufacturerName
              fleet: el.aircraftFamilyName, //aircraftFamilyName
              variant: el.aircraftName, //aircraftName
              configuration: el.configuration, //configurationName
            }))[0],
        );
      }
    }
  }, [aircrafts]);

  useEffect(() => {
    if (aircrafts) {
      if (aircraftData.variant) {
        setAircraftData(
          aircrafts
            .filter((el: any, i: number) => el.aircraftId === props.lessonMetaData.aircraftId)
            .map((el: any, i: number) => ({
              manufacturer: el.manufacturerName, //manufacturerName
              fleet: el.aircraftFamilyName, //aircraftFamilyName
              variant: el.aircraftName, //aircraftName
              configuration: el.configuration, //configurationName
            }))[0],
        );
      }
    }
  }, [props.showModal]);

  return (
    <>
      <Modal show={props.showModal} animation={true}>
        <Modal.Header>
          <Modal.Title>Select Cockpit Procedures</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProceduresDropDown
            setLoaded={setLoaded}
            aircraftId={aircraftData.variant && findAirplane()}
            setCurrent3DObject={lessonPageActions.setCurrent3DObject}
            current3DObject={lessonPageActions.proceduresObject}
            setWarning={lessonPageActions.setDeleteWarning}
            setTypeToDelete={lessonPageActions.setPageTypeToDelete}
            isThereA3DPage={pagesContext.isThereA3DPage}
          />

          <React.Fragment>
            <div className="manufacturer-list ldr-row" style={{ background: "#7F8893", color: "white" }}>
              <p>Manufacturer: </p>
              <select
                disabled
                value={aircraftData.manufacturer}
                onChange={(e) => {
                  setAircraftData({
                    ...aircraftData,
                    variant:
                      manufacturerList[e.target.value].fleets[Object.keys(manufacturerList[e.target.value].fleets)[0]]
                        .variants[0],
                    fleet: Object.keys(manufacturerList[e.target.value].fleets)[0],
                    manufacturer: e.target.value,
                  });
                }}
              >
                {manufacturers.map((el, index) => (
                  <option value={el} key={index}>
                    {el}
                  </option>
                ))}
              </select>
            </div>
            <div className="fleet-list ldr-row">
              <p>Fleet: </p>
              <select
                disabled
                value={aircraftData.fleet}
                onChange={(e) => {
                  setAircraftData({
                    ...aircraftData,
                    fleet: e.target.value,
                    variant: manufacturerList[aircraftData.manufacturer].fleets[e.target.value].variants[0],
                  });
                }}
              >
                {Object.keys(manufacturerList[aircraftData.manufacturer].fleets).map((el, index, array) => (
                  <option value={el} key={index}>
                    {el}
                  </option>
                ))}
              </select>
            </div>
            <div className="variant-list ldr-row">
              <p>Variant: </p>
              <select
                disabled
                value={aircraftData.variant!}
                onChange={(e) => {
                  setAircraftData({
                    ...aircraftData,
                    variant: e.target.value!,
                  });
                }}
              >
                {manufacturerList[aircraftData.manufacturer].fleets[aircraftData.fleet].variants.map(
                  (el: any, index: number, array: any[]) => (
                    <option value={el} key={index}>
                      {array.length === 0 ? "n/a" : el}
                    </option>
                  ),
                )}
              </select>
            </div>
          </React.Fragment>
        </Modal.Body>
        <Modal.Footer>
          {loaded ? (
            <button onClick={handleClick}>Choose</button>
          ) : (
            <button disabled onClick={handleClick}>
              Choose
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default SelectProceduresModal;
