import _ from "lodash";
import React from "react";
import { SymbolObject, Symbols } from "../assets/symbols";
import { useObjectsState } from "../../../contexts/ObjectsProvider";
import { useLessonPagesState } from "../../../contexts/LessonPagesProvider/LessonPagesProvider";

type SymbolDisplay = {
  // selectedIcon: string,
  // showSelectError: boolean,
  addNewAnnotation: (name: string, func: any) => void;
  addSymbolOLD: any;
  // setSelectedIcon: (val: string) => void
  // setShowSelectError: (boolean: boolean) => void
};

const SymbolsDisplay = (props: SymbolDisplay) => {
  const { selectedObjects, selectedObjectId } = useObjectsState();
  const { addNewAnnotation } = props;
  const lessonPagesState = useLessonPagesState();
  const display = () => {
    const chunk = _.chunk(Symbols, 9);
    return chunk.map((group: SymbolObject[], index: number) => {
      return (
        <div className="symbol-icon-row" key={index}>
          {displayIcons(group)}
        </div>
      );
    });
  };

  const addSymbol = (event: any, name: string) => {
    if (
      lessonPagesState.currentLessonPage.interactivity ||
      lessonPagesState.currentLessonPage?.pagePlayerType === "Base"
    ) {
      addNewAnnotation(name, "addSymbol");
    } else {
      props.addSymbolOLD(name, "addSymbol");
    }
  };

  const displayIcons = (group: SymbolObject[]) => {
    return group.map((symbol, index) => {
      return (
        <div className={`symbol-icon`} key={index}>
          <symbol.icon className="symbol" onClick={(e: React.MouseEvent) => addSymbol(e, symbol.name)} />
        </div>
      );
    });
  };

  // const handleSymbolClick = (e: React.MouseEvent, name: string) => {
  //   props.setSelectedIcon(name);
  //   if(props.showSelectError) props.setShowSelectError(false);
  // }

  return <React.Fragment>{display()}</React.Fragment>;
};

export default SymbolsDisplay;
