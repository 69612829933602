import React, { useContext, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { setModel, setManifest, setHotSpot } from "../../../store/actions/actions";
import { PageContext } from "../../../routes/builderContexts";

const Gremlin = (props) => {
  const pageContext = useContext(PageContext);
  const pageManifest = _.cloneDeep(pageContext.pageManifest);
  const manifestModel = pageManifest.modelSet.modelSet;
  const modelSetPath = "/api/Asset/" + manifestModel;

  useEffect(() => {
    props.setManifest(pageManifest);
    props.setModel(modelSetPath);
    // if (pageManifest.CustomeHotSpot) {
    //     props.setHotSpot(pageManifest.CustomeHotSpot)
    // }
  }, []);

  return (
    <>
      <div className="hidden">context manager</div>
    </>
  );
};

const mapStateToProps = (state) => {
  return { currentHotSpot: state.Current3dPage.CustomeHotSpot };
};

export default connect(mapStateToProps, { setModel, setManifest, setHotSpot })(Gremlin);
