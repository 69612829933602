import React from "react";
import { Interweave } from "interweave";
import { BaseWYSIWYGType } from "../BaseWYSIWYG";

const TestDisclaimer = (props: BaseWYSIWYGType) => {
  const { pageManifest } = props;

  const displayWarningPageText = () => {
    return pageManifest.textBlock.map((text, index) => {
      if (!pageManifest.textBlock[index] || pageManifest.textBlock[index].text.length < 1) {
        return <></>;
      } else {
        return <Interweave content={pageManifest.textBlock[index].text} />;
      }
    });
  };

  return (
    <div className="basePageTestDisclaimer">
      <div className="block">
        <div className="warning-header-container">
          <p className="warning-header">{pageManifest.greeting}</p>
          <p>The Directory Menu is being disabled in test mode. This page will serve as a reference for this content</p>
        </div>

        {displayWarningPageText()}
      </div>
    </div>
  );
};

export default TestDisclaimer;
