import React from "react";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import "rsuite/dist/rsuite.min.css";
import Builder from "./routes/builder";
import Lesson from "./components/Lesson";
import NewLessonModal from "./routes/NewLesson";
import { PageColorProvider } from "./contexts/PageColorProvider/PageColorProvider";
import GlobalProvider from "./contexts/GlobalProvider";

const router = createBrowserRouter([
  {
    path: "/",
    element: <NewLessonModal />,
  },
  {
    path: "/home",
    element: <NewLessonModal />,
  },
  {
    path: "/newlesson",
    element: <NewLessonModal />,
  },
  {
    path: "/newlessonfromfile",
    element: <NewLessonModal />,
  },
  {
    path: "/newlessonfromCLASSCOfile",
    element: <NewLessonModal />,
  },
  {
    path: "/build",
    element: <Builder />,
  },
  {
    path: "/builder",
    element: <Builder />,
  },
  {
    path: "/:method/lesson/:lessonId",
    element: <Lesson />,
  },
  {
    path: "*",
    element: <div>Sorry Not Found</div>,
  },
]);

function App() {
  return (
    <GlobalProvider>
      <PageColorProvider>
        <RouterProvider router={router} />
      </PageColorProvider>
    </GlobalProvider>
  );
}

export default App;
