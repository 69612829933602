import React from "react";
import { IPolyGroupings } from "../Interfaces/ICdu";

interface PolyGroupType {
  groups: IPolyGroupings[];
  highlightGroups: string[];
}

const FMSPolyGroup: React.FC<PolyGroupType> = (props: PolyGroupType): JSX.Element => {
  return (
    <React.Fragment>
      {props.groups.map((polyObject: IPolyGroupings, key: number) => {
        return (
          <polygon
            key={key}
            points={polyObject.points}
            fill="none"
            stroke={props.highlightGroups.includes(polyObject.cduKeyName) ? "yellow" : ""}
            strokeWidth="2"
            pointerEvents="none"
            id={polyObject.cduKeyName}
          />
        );
      })}
    </React.Fragment>
  );
};

export default FMSPolyGroup;
