import React from "react";
import { SVGAnnotationProps } from "../index";
import { HighlightAnnotation } from "../HighlightAnnotation";

export function SVGLineM({
  onMouseDown,
  objectId,
  y,
  x,
  width,
  height,
  isDisplayed,
  zIndex,
  borderColor,
  strokeWidth,
  backgroundColor,
  opacity,
  rotate,
}: // transform,
SVGAnnotationProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19.27 12.02"
      key={objectId}
      id={`symbol-${objectId}`}
      data-objectid={objectId}
      tabIndex={0}
      className="symbol target"
      name="symbol"
      onMouseDown={(e: React.MouseEvent<SVGSVGElement>) => {
        onMouseDown(e, objectId);
      }}
      // onKeyDown={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyPress(e, kp)}
      // onKeyUp={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyUp(e, kp)}
      preserveAspectRatio="xMidYMid meet"
      style={{
        // top: `${y}px`,
        // left: `${x}px`,
        width: `${width}px`,
        height: `${height}px`,
        zIndex: zIndex,
        transform: `translate(${x}px, ${y}px) rotate(${rotate ?? 0}deg)`,
        opacity: isDisplayed ? opacity : 0.5,
        filter: isDisplayed ? "none" : "grayScale(100%)",
        // `rotate(${transform?.rotate}deg) scaleX(${transform?.scaleX}) scaleY(${transform?.scaleY}) matrix3d(${transform?.matrix3d})`
      }}
    >
      <HighlightAnnotation objectId={objectId} />
      <path
        // className="line_m"
        stroke={`${borderColor || "white"}`}
        fill={`${backgroundColor || "transparent"}`}
        strokeWidth={`${strokeWidth / 10 || 0.1}`}
        d="M 8.2700005,6.1799998 19.030001,0.44"
      />
      <path
        // className="line_m"
        stroke={`${borderColor || "white"}`}
        fill={`${backgroundColor || "transparent"}`}
        strokeWidth={`${strokeWidth / 10 || 0.1}`}
        d="M 6.55,9.08 2.27,11.35 0.68,8.33 4.95,6.07 8.97,5.84 Z"
      />
    </svg>
  );
}
