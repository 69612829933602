import React, { PropsWithChildren, createContext, useContext, useReducer, useEffect } from "react";

interface ObjectPropertyBoxState {
  interactivityEditingTarget: any; // would be an animatable object
  objectPropertyBoxDomNode: HTMLDivElement | null;
  selectedObjectId: number | null;
  renderCoordinates?: { top: number; left: number };
}

const initialState: ObjectPropertyBoxState = {
  interactivityEditingTarget: null,
  objectPropertyBoxDomNode: null,
  selectedObjectId: null,
};

const objectPropertyBoxState = createContext<any>(initialState);
const objectPropertyBoxDispatch = createContext<any>({});

const objectPropertyBoxReducer = (state: ObjectPropertyBoxState, action: any) => {
  switch (action.type) {
    case "SET_SELECTED_INTERACTIVITY_EDITING_TARGET": {
      return { ...state, interactivityEditingTarget: action.payload };
    }
    case "SET_OBJECT_PROPERTY_BOX_DOM_NODE": {
      state.objectPropertyBoxDomNode = null;
      const domNode = action.payload;
      return {
        ...state,
        objectPropertyBoxDomNode: domNode,
      };
    }
    case "REMOVE_OBJECT_PROPERTY_BOX_DOM_NODE": {
      return {
        ...state,
        objectPropertyBoxDomNode: null,
      };
    }
    case "SET_OBJECT_PROPERTY_BOX_COORDS":
      return {
        ...state,
        renderCoordinates: action.payload,
      };
    //   case 'SET_OBJECT_LIST': {
    //
    //     return { ...state, objectList: action.payload};
    //   }
    default:
      return state;
  }
};

export function ObjectPropertyBoxProvider({ children }: PropsWithChildren<any>) {
  const [state, dispatch] = useReducer(objectPropertyBoxReducer, initialState); // TODO use reducer

  useEffect(() => {
    if (state.objectPropertyBoxDomNode && state.renderCoordinates) {
      const { top, left } = state.renderCoordinates;
      state.objectPropertyBoxDomNode.style.top = top + "%";
      state.objectPropertyBoxDomNode.style.left = left + "%";
    }
  }, []);
  return (
    <objectPropertyBoxDispatch.Provider value={dispatch}>
      <objectPropertyBoxState.Provider value={state}>{children}</objectPropertyBoxState.Provider>
    </objectPropertyBoxDispatch.Provider>
  );
}

export function useObjectPropertyBoxDispatch() {
  const ctx = useContext(objectPropertyBoxDispatch);
  if (ctx === undefined) {
    throw new Error("Wrap component in Wysiwyg Provider");
  }
  return ctx;
}
export function useObjectPropertyBoxState() {
  const ctx = useContext(objectPropertyBoxState);
  if (ctx === undefined) {
    throw new Error("Wrap component in Wysiwyg Provider");
  }
  return ctx as ObjectPropertyBoxState;
}
