import React, { useContext, useEffect, useState } from "react";
import { useSpring, animated, config } from "react-spring";
import { HotSpot3D } from "../hotSpotEditor/types";
import styles from "./HotspotLister.module.css";
import SingleHSDisplay from "./SingleHSDisplay";
import { ReactComponent as AddIcon } from "../../../assets/icons/add-tag-icon.svg"; // '../../../../assets/icons/add-tag-icon.svg'; // '../../assets/icons/add-tag-icon.svg';
import { ReactComponent as TabButtonIcon } from "../../../assets/icons/NarratorTab/narrator-text-closed-tab-icon.svg";
import { PageContext } from "../../../routes/builderContexts";
import PageObjective from "./PageObjective";
import { setHsListerIsOpen } from "../../../store/actions/actions";
import { connect } from "react-redux";
import { AbstractTooltip as Tooltip } from "../../../components/ToolTip/ToolTip";
const MAX_HS_ALLOWED = 5;

type HotSpotListerProps = {
  hsIndex: number;
  HotSpotList: HotSpot3D[];
  setHSList: (data: HotSpot3D[]) => void;
  setHsIndex: (i: number) => void;
  openIGA: (index: number) => void;
  openEditor: (val: string) => void;
  hsListerIsOpen: boolean;
  setHsListerIsOpen: (val: boolean) => void;
};

const maxHsId = (HSList: HotSpot3D[]) => {
  return Math.max(...HSList.map((hs) => Number(hs.id.split("-")[1]))) + 1;
};
const maxHsOrder = (HSList: HotSpot3D[]) => {
  return Math.max(...HSList.map((hs) => hs.order)) + 1;
};
const newHotspot = (HSList: HotSpot3D[]): HotSpot3D => {
  const maxId = maxHsId(HSList);
  const maxOrder = maxHsOrder(HSList);
  const defaultHotspot: HotSpot3D = {
    id: `HotSpot-${maxId}`,
    name: `HotSpot-${maxId}`,
    position: [0, 8, 3 * (maxId - 1)],
    geometry: [6, 32, 32],
    rotation: [0, 0, 0],
    scale: [1, 1, 1],
    proximityRange: 5,
    proximityOffset: {
      x: 0,
      y: 0,
      z: 0,
    },
    key: "active",
    isSphere: false,
    IGSObject: [
      {
        stepType: 0,
        isTerminal: false,
        taskDescription: "What to do",
        question: "What to ask",
        choices: [
          {
            isCorrect: false,
          },
        ],
        images: {},
      },
    ],
    order: maxOrder,
    failResponse: "",
    whatToDo: "",
    isRequired: true,
    isScorable: false,
    remainAfterCompletion: true,
  };
  return defaultHotspot;
};

const HotspotLister = ({
  HotSpotList,
  setHSList,
  setHsIndex,
  hsIndex,
  openEditor,
  openIGA,
  hsListerIsOpen,
  setHsListerIsOpen,
}: HotSpotListerProps) => {
  const pageContext = useContext(PageContext);
  const pageManifest = { ...pageContext.pageManifest };
  const { hotspotsAreSequenced } = pageManifest;
  const [dragId, setDragId] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);

  const allowedToDelete = HotSpotList.length > 1;

  useEffect(() => {
    // hsListerIsOpen comes from the redux store and gets set to true in NewPageSelector.tsx
    //  whenever a cabin page is added to the lesson
    const timeout = setTimeout(() => {
      const isOpen = hsListerIsOpen;
      setOpen(isOpen);
      setHsListerIsOpen(false);
    }, 500);
    return () => {
      setHsListerIsOpen(false);
      setOpen(false);
      clearInterval(timeout);
    };
  }, []);

  const updateHsAreSequenced = (val: boolean) => {
    const pageManifest = { ...pageContext.pageManifest };
    pageManifest.hotspotsAreSequenced = val;
    pageContext.updatePageManifest(pageManifest);
  };

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    setDragId(e.currentTarget?.id);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    const dragHs = HotSpotList.find((hs) => hs.id === dragId);
    const dropHs = HotSpotList.find((hs) => hs.id === e.currentTarget.id);
    if (!dragHs || !dropHs) return;
    const dragHsOrder = dragHs.order;
    const dropHsOrder = dropHs.order;
    const newHSList = HotSpotList.map((hs) => {
      if (hs.id === dragId) {
        hs.order = dropHsOrder;
      }
      if (hs.id === e.currentTarget.id) {
        hs.order = dragHsOrder;
      }
      return hs;
    });
    setHSList(newHSList);
  };

  const contentProps = useSpring({
    width: open ? Math.max(document!.querySelector(".pagePlayerInnerBox")!.clientWidth * 0.5, 500) : 46,
    config: config.default,
  });

  const replaceHotspot = (newHS: HotSpot3D, indexToRemove: number) => {
    const newHsList = HotSpotList.map((hs) => (hs.order === indexToRemove ? newHS : hs));
    setHSList(newHsList);
  };
  const removeHotspot = (indexToRemove: number) => {
    if (!allowedToDelete) return;
    const filteredList = HotSpotList.filter((hs) => hs.order !== indexToRemove);
    filteredList.forEach((hs, i) => {
      hs.order = i + 1;
    });
    setHSList(filteredList);
    setHsIndex(filteredList.length - 1);
  };

  return (
    <animated.div className={`${styles.hotspotListContainer} ${open ? styles.containerOpen : ""}`} style={contentProps}>
      <div className={`${styles.openTab} ${open ? styles.open : ""}`} onClick={() => setOpen(!open)}>
        <TabButtonIcon style={open ? { transform: "rotate(180deg)" } : {}} />
        <div>
          <p style={open ? {} : { color: "white" }}>Hotspots And Objective</p>
        </div>
      </div>
      {open && (
        <div className={styles.hotspotListerBody}>
          <PageObjective
            pageObjective={pageManifest.CurrentObjective}
            objectiveLabel={pageManifest.CurrentObjectiveLabel}
            onLabelChange={(val) => {
              pageManifest.CurrentObjectiveLabel = val;
              pageContext.updatePageManifest(pageManifest);
            }}
            onTextChange={(val) => {
              pageManifest.CurrentObjective = val;
              pageContext.updatePageManifest(pageManifest);
            }}
          />
          <div>
            <div className={styles.hotSpotListHeader}>
              <div className={styles.hotspotHeaderOrderCheck} data-tooltip-id="hs-order-explainer">
                <Tooltip
                  id="hs-order-explainer"
                  content={
                    hotspotsAreSequenced
                      ? "The Hotspots will be enabled for the Student based on the order position"
                      : "All the Hotspots will be enabled simultaneously"
                  }
                />
                <div
                  onClick={() => updateHsAreSequenced(!hotspotsAreSequenced)}
                  className={`${styles.orderCheckBox} ${
                    hotspotsAreSequenced ? styles.orderCheckBoxChecked : styles.orderCheckBoxUnchecked
                  }`}
                >
                  <div className={styles.orderCheckBoxFill}></div>
                </div>
                <p>Order</p>
              </div>
              <div className={styles.hotSpotListHeaderName}>
                {" "}
                <p> Hotspot Name </p>
              </div>
              <div className={styles.hotSpotHeaderScoreSection}>
                <div>
                  {" "}
                  <p>Remain after completion</p>{" "}
                </div>
                <div>
                  {" "}
                  <p>Scorable</p>
                </div>
                <div>
                  {" "}
                  <p>Required</p>
                </div>
              </div>
              <div className={styles.hotspotListHeaderButtons}>
                <div>
                  <p>Hotspot Editor</p>
                </div>
                <div>
                  <p>Interactive Activity</p>
                </div>
                {/* for padding */}
                <div></div>
              </div>
            </div>
            <div className={styles.hotspotListerTable}>
              {HotSpotList.sort((a, b) => a.order - b.order).map((hs, i) => (
                <SingleHSDisplay
                  removeHotspot={removeHotspot}
                  replaceHotspot={replaceHotspot}
                  openIGA={() => {
                    openIGA(i);
                  }}
                  openEditor={() => {
                    openEditor("hsEditor");
                    setOpen((prev) => !prev);
                  }}
                  allowedToDelete={allowedToDelete}
                  handleDrag={handleDrag}
                  handleDrop={handleDrop}
                  key={hs.id}
                  hotspot={hs}
                  selectHotspot={() => setHsIndex(i)}
                  selected={hsIndex === i}
                  hsAreOrdered={pageManifest.hotspotsAreSequenced}
                />
              ))}
            </div>
            <div className={styles.addHSBtn}>
              <AddIcon
                onClick={() => {
                  if (HotSpotList.length < MAX_HS_ALLOWED) setHSList([...HotSpotList, newHotspot(HotSpotList)]);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </animated.div>
  );
};

const mapState = (state: any) => {
  return {
    hsListerIsOpen: state.Current3dPage.openHsLister,
  };
};

export default connect(mapState, {
  setHsListerIsOpen,
})(HotspotLister);

// export default (HotspotLister);
