import React, { useState, useEffect } from "react";
import MenuNewTableSize from "./MenuNewTableSize";
import { useTablesDataDispatch, useTablesDataState } from "./TablesDataProvider";
import { useObjectsState, ObjectActionsType, useObjectsDispatch } from "../../contexts/ObjectsProvider";

function ColumnWidthControls() {
  const [isSizeMenuOpen, setIsSizeMenuOpen] = useState(false);
  const [intervalId, setIntervalId] = useState(null);

  const TablesDispatch = useTablesDataDispatch();
  const TablesState = useTablesDataState();
  const objectsState = useObjectsState();
  const objectsDispatch = useObjectsDispatch();

  const handleMouseDown = (actionType) => {
    const updateTable = () => {
      objectsDispatch({
        type: ObjectActionsType.UPDATE_TABLE,
        payload: { type: actionType, selection: TablesState.selectedColumn },
      });
    };

    updateTable();
    const id = setInterval(updateTable, 50); // Adjust the interval as per your requirement
    setIntervalId(id);
  };

  const handleMouseUp = () => {
    clearInterval(intervalId);
  };

  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId]);

  return TablesState.selectedColumn !== undefined ? (
    <div className="flex-row">
      <button
        className="wysiwyg-toolbar-btn cell-resize"
        onMouseDown={() => handleMouseDown("increaseColumnWidth")}
        onMouseUp={handleMouseUp}
      >
        Increase Width
      </button>
      <button
        className="wysiwyg-toolbar-btn cell-resize"
        onMouseDown={() => handleMouseDown("decreaseColumnWidth")}
        onMouseUp={handleMouseUp}
      >
        Decrease Width
      </button>
    </div>
  ) : (
    <></>
  );
}

export default ColumnWidthControls;
