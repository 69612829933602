import type { PopoverProps } from "rsuite/esm/Popover/Popover";
import { AudioPopoverAction } from "./types";
import React, { forwardRef, MouseEventHandler } from "react";
import { Popover } from "rsuite";

export interface AudioOptionsPopoverProps extends PopoverProps {
  onSelect: (action: AudioPopoverAction) => void;
}

export const AudioOptionsPopover = forwardRef<HTMLDivElement, AudioOptionsPopoverProps>(
  ({ onSelect, visible, className }, ref) => {
    const handleSelect = (action: AudioPopoverAction): MouseEventHandler => {
      return (event) => {
        event.stopPropagation();
        onSelect(action);
      };
    };

    return (
      <Popover ref={ref} visible={visible} className={className} full arrow={false}>
        <ul className="cpat-list">
          <li className="cpat-list-item" onClick={handleSelect(AudioPopoverAction.TextToSpeech)}>
            Text to Speech
          </li>
          <li className="cpat-list-item" onClick={handleSelect(AudioPopoverAction.UploadAudio)}>
            Upload Audio
          </li>
        </ul>
      </Popover>
    );
  },
);
AudioOptionsPopover.displayName = "AudioOptionsPopover";
