import _ from "lodash";
import { BoundType } from "../../../../../components/react-moveable";
import { IBasicPageScormV2 } from "../../IBasePage";
import { CustomPageElement } from "./CustomPageElement";

export class CustomPageScorm extends CustomPageElement {
  index: number;
  scormList: IBasicPageScormV2[];
  bounds: BoundType;
  fullList: any;

  constructor(
    index: number,
    scormList: IBasicPageScormV2[],
    bounds: BoundType,
    fullList: Array<any>,
    objectId: number,
  ) {
    super(scormList, `scorm${objectId}`);
    this.newObject = {
      blobPath: "",
      assetVersionId: 0,
      ...this.newObject,
    };

    this.newObject = {
      ...this.newObject,
      type: "SCORM",
      height: 60,
      width: 60,
      top: 0,
      left: 0,
      zIndex: 1,
      // objectId: objectId,
    };

    this.index = index;
    this.scormList = scormList;
    this.bounds = bounds;
    this.fullList = fullList;
  }

  public addNewCustomPageSCORM() {
    this.newObject.name = `interactive-graphic-${this.newObject.objectId}`;
    return this.newObject;
  }

  public updateSCORMAttributes() {
    _.forEach(this.scormList, (scorm, index: number) => {
      if (_.has(scorm, "attributes")) {
        this.scormList[index] = {
          blobPath: scorm.blobPath,
          assetVersionId: scorm.assetVersionId,
          ...(scorm as any).attributes,
        };
        if (!_.has(scorm, "type" || scorm.type.length === 0)) this.scormList[index].type = "SCORM";
      }
    });
    return this.scormList;
  }
}
