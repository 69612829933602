import React, { PropsWithChildren, useEffect } from "react";
import ILesson from "../../models/ILesson";
import genericRepositoryService from "../../services/genericRepositoryService";

// LessonLibraryInfo interface
// hasLessons lets know there's lessons to view
// lessonList is list of Lessons
interface LessonLibraryInfo {
  hasLessons: boolean;
  lessonList: ILesson[];
}

// interface used for reducer
interface LessonLibraryAction {
  type: "SET_LESSONS" | "NO_DATA";
  lessons?: any;
}

// initial state
const initialState: LessonLibraryInfo = {
  hasLessons: false,
  lessonList: [],
};

const LessonLibraryState = React.createContext<LessonLibraryInfo | null>(null);
const LessonLibraryDispatch = React.createContext<React.Dispatch<LessonLibraryAction> | null>(null);

// reducer for provider
const lessonLibraryReducer = (state: LessonLibraryInfo, action: LessonLibraryAction) => {
  switch (action.type) {
    case "SET_LESSONS": {
      return { lessonList: action.lessons, hasLessons: !state.hasLessons };
    }
    default:
      return state;
  }
};

// retrieves all lessons async without blocking lesson load
async function fetchAllLessons(dispatch: any) {
  const result = await genericRepositoryService.getAccessibleLessons();

  const lessonResults = result.data.lessons;

  // if no data is returned, don't change state
  if (!lessonResults) {
    dispatch({ type: "NO_DATA" });
  }

  if (lessonResults) {
    const lessonList: ILesson[] = [];

    for (const lp in lessonResults) {
      if (lessonResults[lp]) {
        lessonResults[lp].name =
          lessonResults[lp].name +
          " (id" +
          lessonResults[lp].lessonId +
          " v" +
          lessonResults[lp].lessonVersionDisplayableId +
          ")";

        if (lessonResults[lp].publishedDateUtc) {
          lessonResults[lp].status = "Draft/Published";
        } else {
          lessonResults[lp].status = "In Development";
        }
        lessonList.push(lessonResults[lp]);
      }
    }

    // update the lesson list with the latest results
    dispatch({ type: "SET_LESSONS", lessons: lessonList });
  }
}

// provider that gets the initial data load
export const LessonLibraryProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = React.useReducer(lessonLibraryReducer, initialState);

  useEffect(() => {
    fetchAllLessons(dispatch).then(() => {});
  }, []);

  return (
    <LessonLibraryState.Provider value={state}>
      <LessonLibraryDispatch.Provider value={dispatch}>{children}</LessonLibraryDispatch.Provider>
    </LessonLibraryState.Provider>
  );
};

// function used to update the lesson list
export const useUpdateLessonLibrary = () => {
  const dispatch = React.useContext(LessonLibraryDispatch);

  if (!dispatch) {
    throw new Error("Dispatch is undefined");
  }

  return React.useCallback(() => {
    fetchAllLessons(dispatch).then(() => {});
  }, []);
};

// library provider function that is used by the components
export const useLessonLibrary = () => {
  const ctx = React.useContext(LessonLibraryState);
  const ctx2 = React.useContext(LessonLibraryDispatch);
  if (!ctx || !ctx2) {
    throw new Error("must wrap in LessonLibraryProvider");
  }
  return [ctx, ctx2] as [typeof ctx, typeof ctx2];
};
