import React from "react";
import { ReactComponent as LoadingSvg } from "../../assets/icons/NarratorTab/Dual Ring-1s-32px.svg";

const SavingModal = () => {
  return (
    <div className="metadata-editor-saving-modal">
      <LoadingSvg className="metadata-editor-spinner" />
    </div>
  );
};

export default SavingModal;
