import { PanelProperty } from "./types";
import React, { ReactElement } from "react";
import { defaultFieldProperties, typeToComponentMap } from "./constants";
import classes from "../../utils/HTML/classes";

export type PropertiesPanelFieldProps<T> = {
  field: PanelProperty<T>;
  data: T;
  onChange: (value: unknown) => void;
};

export const PropertiesPanelField: <T>(props: PropertiesPanelFieldProps<T>) => ReactElement = ({
  data,
  field,
  onChange,
}) => {
  const Component = typeToComponentMap[field.type];

  return (
    <div className={classes("properties-section", field.identifier)} key={field.identifier}>
      {field.label && <div className="property-title">{field.label}</div>}
      <div className="property-content">
        {field.render ? (
          field?.render(data)
        ) : Component ? (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <Component value={data?.[field.identifier] ?? null} onChange={onChange} {...defaultFieldProperties(field)} />
        ) : (
          field.identifier
        )}
      </div>
    </div>
  );
};
