import * as React from "react";
import * as AssetManagerTypes from "../AssetManager.types";
import * as AssetLibraryActions from "./AssetManager.Library.Actions";
import * as AssetManagerActions from "./AssetManager.actions";
import { PageContext, AssetContext, LessonPageActionsContext } from "../../../routes/builderContexts";
// import {IUploadManagerContext, UploadManagerContext} from "../interfaces/uploaderInterfaces";
import * as uploaderFunctions from "../AssetManagerUtils/UploaderFunctions";
import lodash from "lodash";

const initialState: AssetManagerTypes.AssetManagerState = {
  assetLibraryFilters: [
    /*{recentlyUploaded: true}*/
  ],
  assetLibraryDisplayedAssetType: 4, // null, 4=images
  currentlySelectedAircraftData: {
    manufacturer: "", //manufacturerName
    fleet: "", //aircraftFamilyName
    variant: "", //aircraftName
    configuration: null, //configurationName
  },
  retrievedData: [],
  currentSelectedAsset: {
    assetId: -1,
    blobPath: "",
    errorMessage: null,
  },
  pagination: {
    currentPage: 1,
    totalPages: null,
    previousPage: null,
    nextPage: null,
  },
  keywords: [],
  textQuery: "",
  isEditing: false,
} as never;

const AssetManagerStateContext = React.createContext<AssetManagerTypes.AssetManagerState | undefined>(undefined);
const AssetManagerDispatchContext = React.createContext<AssetManagerTypes.Dispatch | undefined>(undefined);

function assetManagerReducer(state: AssetManagerTypes.AssetManagerState, action: AssetManagerTypes.AssetManagerAction) {
  switch (action.type) {
    case "ADD_FILTER":
      return AssetLibraryActions.addFilter(state, action);
    case "REMOVE_FILTER":
      return AssetLibraryActions.removeFilter(state, action);
    case "CHANGE_ASSET_TYPE_DISPLAYED":
      return AssetLibraryActions.changeAssetTypeDisplayed(state, action);
    case "SET_LIBRARY_PAGINATION":
      return AssetLibraryActions.setPagination(state, action);
    case "SET_CURRENT_KEYWORDS":
      return AssetLibraryActions.setCurrentKeywords(state, action);
    case "SET_TEXT_QUERY":
      return AssetLibraryActions.setTextQuery(state, action);
    case "REMOVE_DOWNSTREAM_FILTERS":
      return AssetLibraryActions.removeDownstreamFilters(state, action);
    case "SET_ITEMS_PER_PAGE":
      return AssetLibraryActions.setItemsPerPage(state, action);
    case "SET_IS_EDITING":
      return AssetLibraryActions.setIsEditing(state, action);
    case "SELECT_ASSET":
      return AssetManagerActions.selectAsset(state, action);
    case "SET_SELECTED_AIRCRAFT_DATA":
      return AssetManagerActions.setSelectedAircraftData(state, action);
    case "SET_RETRIEVED_ASSETS":
      return AssetManagerActions.setRetrievedAssets(state, action);
    case "SET_ASSETS_LOADING":
      return AssetManagerActions.setIsBusy(state, action);

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AssetManagerProvider({ children }: AssetManagerTypes.AssetManagerProviderProps) {
  const [state, dispatch] = React.useReducer(assetManagerReducer, initialState);
  return (
    <AssetManagerStateContext.Provider value={state}>
      <AssetManagerDispatchContext.Provider value={dispatch}>{children}</AssetManagerDispatchContext.Provider>
    </AssetManagerStateContext.Provider>
  );
}

function useAssetManagerState() {
  const context = React.useContext(AssetManagerStateContext);
  if (context === undefined) {
    throw new Error("useCountState must be used within a CountProvider");
  }
  return context;
}

function useAssetManagerDispatch() {
  const context = React.useContext(AssetManagerDispatchContext);
  if (context === undefined) {
    throw new Error("useCountState must be used within a CountProvider");
  }
  return context;
}

const usePageAssociation = (IGAAssetType: number) => {
  const assetContext = React.useContext(AssetContext);
  const lessonPageActionsContext = React.useContext(LessonPageActionsContext);
  const pageContext = React.useContext(PageContext);
  const pageManifest = lodash.cloneDeep(pageContext.pageManifest);
  const assetManagerStateContext = React.useContext(AssetManagerStateContext);

  if (IGAAssetType === 4) {
    const assetId = assetManagerStateContext!.currentSelectedAsset.assetId;
    const blobPath = assetManagerStateContext!.currentSelectedAsset.blobPath;
    const image: { assetId: number; blobPath: string } = { assetId, blobPath };
    return getIGAImage(image);
  }

  if (assetContext.assetTypeId !== -1 && IGAAssetType !== -1) {
    const editorMode = lessonPageActionsContext.selectedEditorLessonModes;
    const assetTypeId = assetContext.assetTypeId;
    const pagePlayerType = pageContext.page.pagePlayerType;
    const assetIndex = assetContext.assetIndex;
    //   let chosenAsset = {
    //     assetId: uploadedAssets[selectedId].assetId,
    //     blobPath: uploadedAssets[selectedId].blobPath,
    //     errorMessage: null
    //   }

    return async () => {
      const assetToRemove: number = await uploaderFunctions.getAssetsToRemove(
        pageManifest,
        pagePlayerType,
        assetTypeId,
        assetIndex,
        assetManagerStateContext!.currentSelectedAsset,
      );
      const newManifest = uploaderFunctions.associateAssetToPage(
        pageManifest,
        assetManagerStateContext!.currentSelectedAsset,
        assetTypeId,
        editorMode,
        pagePlayerType,
        assetIndex,
        pageContext.isAnnotationsShown,
      );
      pageContext.updatePageAsset(assetManagerStateContext!.currentSelectedAsset.assetId, assetToRemove);
      pageContext.updatePageManifest(newManifest);
    };
  } else return () => console.warn("assetTypeID not found");
};
// const getFileType = (name: string) => {
//   let nameSplit = name.split(".");
//   let images = ["png", "jpeg", "jpg", "gif"];
//   let audio = ["mp3"];
//   if(images.includes(nameSplit[nameSplit.length-1])){
//     return 4
//   }else if (audio.includes(nameSplit[nameSplit.length-1])){
//     return 5
//   }else {
//     return -1
//   }

// }
export function useAssetManager() {
  const ctx = React.useContext(AssetManagerStateContext);
  if (ctx === undefined) {
    throw new Error("useAssetManager must be used within a AssetManagerProvider");
  }
  return ctx;
}
const getIGAImage = (image: any) => {
  return image;
};

export { AssetManagerProvider, useAssetManagerState, useAssetManagerDispatch, usePageAssociation };
