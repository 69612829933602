import React, { useState, useEffect } from "react";
import MenuNewTableSize from "./MenuNewTableSize";
import { useTablesDataDispatch, useTablesDataState } from "./TablesDataProvider";
import { ReactComponent as RowResize } from "../../assets/icons/HeaderIcons/drop-down.svg";
import { useObjectsDispatch, ObjectActionsType } from "../../contexts/ObjectsProvider";

function RowHeightControls() {
  const [intervalId, setIntervalId] = useState(null);

  const TablesState = useTablesDataState();
  const objectsDispatch = useObjectsDispatch();

  const handleMouseDown = (actionType) => {
    const updateHeight = () => {
      objectsDispatch({
        type: ObjectActionsType.UPDATE_TABLE,
        payload: { type: actionType, selection: TablesState.selectedRow },
      });
    };

    updateHeight();
    const id = setInterval(updateHeight, 50); // Adjust the interval as per your requirement
    setIntervalId(id);
  };

  const handleMouseUp = () => {
    clearInterval(intervalId);
  };

  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId]);

  return TablesState.selectedRow !== undefined ? (
    <div className="flex-row">
      <button
        className="wysiwyg-toolbar-btn cell-resize"
        onMouseDown={() => handleMouseDown("increaseRowHeight")}
        onMouseUp={handleMouseUp}
      >
        Increase Height
      </button>
      <button
        className="wysiwyg-toolbar-btn cell-resize"
        onMouseDown={() => handleMouseDown("decreaseRowHeight")}
        onMouseUp={handleMouseUp}
      >
        Decrease Height
      </button>
    </div>
  ) : (
    <></>
  );
}

export default RowHeightControls;
