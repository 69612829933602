import { EventEmitter } from "eventemitter3";
import { Interactivity, travelTree } from "../";
import { createLogger, validation, conversion } from "../../../utils";
import { AllPartialBut } from "../../../types";

export const HOTSPOT_LATEST_VERSION = "1.2.0";

const log = createLogger("InteractivityBuilder", {
  background: "black",
  color: "white",
});

// type AllPartialBut<T, K extends keyof T> = { [P in keyof T]: P extends K ? T[P] : T[P] | undefined }

type ActionValue = string | number | boolean;
type ActionValueUnit = string | null;
export type ObjectId = string;
export type MappedActions = "ROTATE" | "SHOW/HIDE";
export interface TaskOptions {
  action: Action;
  targetId: string | number;
  mappedAction: MappedActions;
  actionValue: ActionValue;
  actionValueUnit: ActionValueUnit;
}
export class Task {
  action: Action;
  targetId: ObjectId;
  mappedAction: "ROTATE" | "SHOW/HIDE";
  actionValue: ActionValue;
  actionValueUnit: ActionValueUnit;
  constructor(options: TaskOptions) {
    this.action = options.action;
    if (options.targetId && typeof options.targetId === "string") {
      this.targetId = options.targetId;
    } else {
      throw new Error("Must have a target id");
    }

    if (options.mappedAction) {
      this.mappedAction = options.mappedAction;
    } else {
      throw new Error("need a mapped action string");
    }

    if (options.actionValue !== undefined || options.actionValue !== null) {
      this.actionValue = options.actionValue;
    } else throw new Error("need an action Value");

    if (options.actionValueUnit || options.actionValueUnit === null) {
      this.actionValueUnit = options.actionValueUnit;
    } else throw new Error("need an action Value Unit");
  }

  editTask(options: Partial<TaskOptions>) {
    if (typeof options.actionValue === "number" || typeof options.actionValue === "boolean") {
      this.actionValue = options.actionValue;
    } else {
      throw new Error("Tried to set an unsupported type for actionValue " + typeof options.actionValue);
    }
    if ((options.actionValueUnit || options.actionValue === null) && options.actionValueUnit !== undefined)
      this.actionValueUnit = options.actionValueUnit;
    if (options.mappedAction) this.mappedAction = options.mappedAction;
    if (options.targetId !== "" && typeof options.targetId === "string") this.targetId = options.targetId;
    if (options.action) this.action = options.action;
  }
  addTargetId() {}
}

export interface ActionOptions {
  level: Level;
  hotspotLocation?: Interactivity.HotspotLocation;
  taskBuffer?: Task[];
  required?: boolean;
  // 0 means no delay
  delay?: number;
  version?: string;
  pauseOnLoad?: boolean;
  resumeOnClick?: boolean;
}
export class MergedTasksByObjectTargetId {
  targetId: string;
  tasks: Set<Task> = new Set();
  action: Action;
  constructor(targetId: ObjectId, action: Action) {
    this.targetId = targetId;
    this.action = action;
  }
  findIndexOfTarget(targetId: ObjectId) {}
  replaceTasksWithId(
    ...params: {
      targetIdToReplace: ObjectId;
      newTargetId: ObjectId;
      innards: Omit<TaskOptions, "action">;
    }[]
  ) {
    let tempBuffer: Task[] | undefined;
    let action: Action;
    params.forEach((param) => {
      const task = this.getTask(param.targetIdToReplace, param.innards.mappedAction);
      if (task?.action) {
        action = task.action;
      }
      if (task && !tempBuffer) {
        const newTaskBuffer = task?.action.taskBuffer.map((treeTask) => {
          //if the reference in the UIefied Object and the reference in the interactivity tree are the same
          if (task === treeTask) {
            // overwrite task
            return new Task({
              ...param.innards,
              targetId: param.newTargetId,
              action: task.action,
            });
          }
          return treeTask;
        });
        tempBuffer = newTaskBuffer;
      } else if (tempBuffer) {
        const newTaskBuffer = tempBuffer.map((treeTask) => {
          //if the reference in the UIefied Object and the reference in the interactivity tree are the same
          if (task === treeTask) {
            // overwrite task
            return new Task({
              ...param.innards,
              targetId: param.newTargetId,
              action: task.action,
            });
          }
          return treeTask;
        });
        tempBuffer = newTaskBuffer;
      }
    });
    if (action! && tempBuffer) {
      action!.replaceTaskBufferWith(tempBuffer);
    }
  }
  /**
   * target id and mapped action together should for a unique identifier (FOR NOW) to
   * be able to retrieve the Task in the list
   */
  getTask(targetId: ObjectId, mappedAction: MappedActions) {
    for (const val of this.tasks.values()) {
      if (val.targetId === this.targetId || val.targetId === targetId) {
        if (val && val.mappedAction === mappedAction) {
          return val;
        }
      }
    }
  }
  removeCorrespondingTasks() {
    this.action.deleteAllTasksWithTargetId(this.targetId);
  }
  private getValue(targetId: ObjectId, mappedAction: MappedActions) {
    const val = this.getTask(this.targetId, mappedAction);
    if (val) {
      return val.actionValue;
    }
  }
  private setValue(value: number | boolean, mappedAction: MappedActions) {
    const val = this.getTask(this.targetId, mappedAction);
    if (val)
      val.editTask({
        actionValue: value,
      });
  }
  getRotationValue(targetId?: ObjectId) {
    return this.getValue(targetId || this.targetId, "ROTATE");
  }
  setRotationValue(value: number) {
    this.setValue(value, "ROTATE");
  }
  getVisibilityValue(targetId?: ObjectId) {
    return !!this.getValue(targetId || this.targetId, "SHOW/HIDE");
  }
  setVisibilityValue(value: boolean) {
    this.setValue(value, "SHOW/HIDE");
  }
}
export class Action {
  delay = 0;
  type: Interactivity.PageManifestInteractivityActionTypes = "hotspot";
  taskBuffer: Task[] = [];
  level: Level;
  required?: boolean;
  pauseOnLoad?: boolean;
  resumeOnClick?: boolean;
  cachedTargetIdList?: Set<string>;
  constructor(options: ActionOptions) {
    this.level = options.level;

    if (options.pauseOnLoad) this.pauseOnLoad = options.pauseOnLoad;
    if (options.resumeOnClick) this.resumeOnClick = options.resumeOnClick;

    if (options.required) {
      this.required = options.required;
    }
    if (options.delay) {
      this.delay = options.delay;
    }
  }

  private _destroySelf() {
    this.taskBuffer = [];
    this.level.actionBuffer = this.level.actionBuffer.filter((a) => a !== this);
  }
  deleteTasks() {
    this.taskBuffer = [];
  }

  setPauseOnLoad(value: boolean) {
    this.pauseOnLoad = value;
  }

  setResumeOnClick(value: boolean) {
    this.resumeOnClick = value;
  }

  deleteTaskById(id: ObjectId) {
    this.taskBuffer = this.taskBuffer.filter((task) => {
      return task.targetId !== id;
    });
    if (this.level instanceof StartEndLevel) {
      if (this.level.actionBuffer.length === 0) {
        this._destroySelf();
      }
    }
  }
  deleteAllTasksWithTargetId(targetId: ObjectId) {
    this.taskBuffer = this.taskBuffer.filter((task) => {
      return task.targetId !== targetId;
    });
  }
  getTargetsIdLists() {
    // if(this.cachedTargetIdList) {
    //   return this.cachedTargetIdList
    // } else {

    const idList = new Set<ObjectId>();
    for (const val of this.taskBuffer.values()) {
      idList.add(val.targetId);
    }
    // this.cachedTargetIdList = idList;
    return idList;
    // }
  }
  replaceTaskBufferWith(taskBuffer: Task[]) {
    this.taskBuffer = taskBuffer;
  }
  getTasksWithTargetObjectsMerged() {
    const newTaskFormat: MergedTasksByObjectTargetId[] = [];
    this.taskBuffer.forEach((task) => {
      let mergeObjecBeingEdited = newTaskFormat.find((mergedObj) => {
        return mergedObj.targetId === task.targetId;
      });
      if (!mergeObjecBeingEdited) {
        mergeObjecBeingEdited = new MergedTasksByObjectTargetId(task.targetId, task.action);
        newTaskFormat.push(mergeObjecBeingEdited);
      }
      mergeObjecBeingEdited?.tasks.add(task);
    });

    return newTaskFormat;
  }

  lookupTask(targetId: ObjectId, mappedAction: MappedActions, actionValue?: any) {
    const temp: Task[] = [];
    for (let i = 0; i < this.taskBuffer.length; i++) {
      if (this.taskBuffer[i].targetId === targetId && this.taskBuffer[i].mappedAction === mappedAction) {
        // takes prioritizes the action value set

        if (actionValue !== undefined && this.taskBuffer[i].actionValue === actionValue) {
          return this.taskBuffer[i];
        }
        temp.push(this.taskBuffer[i]);
      }
    }
    if (temp.length) {
      return temp[0];
    }
    return null;
  }
  /**
   *
   * @deprecated task can be found with combination of MappedAction And the Target Id
   * this only looks for mapped action...
   */
  lookupTaskByMappedAction(mappedAction: MappedActions) {
    for (let i = 0; i < this.taskBuffer.length; i++) {
      if (this.taskBuffer[i].mappedAction === mappedAction) {
        return this.taskBuffer[i];
      }
    }
    return null;
  }

  private buildTask({ mappedAction, actionValueUnit, actionValue, targetId }: AllPartialBut<TaskOptions, "targetId">) {
    if (actionValue === undefined) {
      throw new Error("actionValueUnit can not be undefined");
    }
    const task = new Task({
      targetId,
      action: this,
      actionValue,
      actionValueUnit: actionValueUnit ?? null,
      mappedAction: mappedAction ?? "ROTATE",
    });
    return task;
  }
  createMultipleTasks(params: AllPartialBut<TaskOptions, "targetId">[]) {
    const freshTasks: Task[] = [];
    params.forEach((param) => {
      freshTasks.push(this.buildTask(param));
    });
    this.taskBuffer.push(...freshTasks);
    return freshTasks;
  }

  createTask({ mappedAction, actionValueUnit, actionValue, targetId }: AllPartialBut<TaskOptions, "targetId">) {
    const task = this.buildTask({
      mappedAction,
      targetId,
      actionValue,
      actionValueUnit,
    });
    this.taskBuffer.push(task);

    return task;
  }
}

export class HotspotAction extends Action {
  hotspotLocation: Interactivity.HotspotLocation = {
    top: 0,
    left: 0,
    width: 10,
    height: 10,
    isDisplayed: true,
  };
  version?: string;
  constructor(options: ActionOptions) {
    super(options);

    this.version = options.version || HOTSPOT_LATEST_VERSION;

    if (options.hotspotLocation) {
      this.hotspotLocation = options.hotspotLocation;
    }
  }

  setVersionTo_1_2_0() {
    this.version = "1.2.0";
  }

  setDelay(delay: number) {
    this.delay = delay;
  }

  editLocationForAllHotspotsInBuffer(newLocation: Partial<Interactivity.HotspotLocation>) {
    this.level.actionBuffer.forEach((action) => {
      if (action.type === "hotspot") {
        (action as HotspotAction).hotspotLocation = {
          ...(action as HotspotAction).hotspotLocation,
          ...newLocation,
        };
      }
    });
    return this;
  }

  // visibilty requires still to be able to interact so we use opacity
  setIsVisible(isVisible: boolean) {
    this.hotspotLocation.isDisplayed = isVisible;
  }

  setIsRequired(isRequired: boolean) {
    this.required = isRequired;
    this.level.actionBuffer.forEach((action) => {
      action.required = isRequired;
    });
  }
  destroy() {
    //   this.level.actionBuffer = this.level.actionBuffer.filter((hotspotAction) => {
    //     return this !== hotspotAction;
    //   })
    //   this.level.root.events.emit('hotspot_removed', this)
    //   //possible memory leak if this is used again.
    //   return this;
    // }
    this.level.actionBuffer = this.level.actionBuffer.filter((action) => action !== this);
  }
}
/**
 * Wait Action is specifically for the audio on the page to end, which will trigger the end of this action.
 */
export class WaitAction extends Action {
  constructor(options: ActionOptions) {
    super(options);
    this.type = "external-wait";
  }
}
/**
 * @description
 * Will create an action with an arbitrary time set.
 */
export class DelayAction extends Action {
  constructor(options: ActionOptions) {
    super(options);
    this.type = "delay";
  }
}

export class Level {
  parent: Level | null = null;

  siblings: Level[] = [];
  levelBuffer: Level[] = []; // children
  actionBuffer: (HotspotAction | WaitAction | DelayAction)[] = [];
  flowMode: "async" | "sync" = "async";

  location: NodeLocation = [];

  root: InteractivityBuilder;
  //linkedDomElement = null
  constructor(options: NodeOptions) {
    this.parent = options.parent;
    if (options?.flowMode) {
      this.flowMode = options.flowMode;
    }
    if (options?.location) {
      this.location = options.location;
    } else {
      this.location = this.findLocation();
    }
    if (options.root) {
      this.root = options.root;
    } else {
      this.root = this.getRoot();
    }
  }

  //   findLocation(startingNode: Node) {
  //     let counter = 0;
  //     while(startingNode.parent) {

  //         startingNode = startingNode.parent
  //     }
  //   }

  removeExternalWaitActions() {
    this.actionBuffer = this.actionBuffer.filter((action) => action.type !== "external-wait");
  }

  getDelayActions() {
    // assumes that the root is calling this method
    const delayActionsInLevel = this.actionBuffer.filter((action) => action.type === "delay");
    return delayActionsInLevel;
  }
  addNewChildStartEndLevel(objectId: string, ops?: { endDelay?: number | null; startDelay?: number }): StartEndLevel {
    const level = this;
    const location = [...level.location, level.levelBuffer.length].filter((l) => l >= 0);
    let sd = ops?.startDelay;
    if (typeof sd === "number") {
      sd = validation.noLessThanZero(sd);
    }
    const nodeToAdd: Level = new StartEndLevel({
      location,
      parent: level,
      root: level.root,
      objectId,
      endDelay: ops?.endDelay,
      startDelay: sd,
    });
    level.levelBuffer.push(nodeToAdd);
    return nodeToAdd as StartEndLevel;
  }
  deleteAction(action: Action) {
    const newActionBuffer = this.actionBuffer.filter((a) => a !== action);

    if (newActionBuffer.length === 0) {
      return this.deleteSelf();
    } else {
      this.actionBuffer = newActionBuffer;
    }
  }
  destroyHotspot() {
    // this assumes that there is a single level per hotspot
    if (this.isRoot()) {
      return;
      //can't destroy root (or can you)
    }
    let action: HotspotAction | undefined | Action = this.actionBuffer[0];
    this.parent!.levelBuffer = this.parent!.levelBuffer.filter((level) => {
      // hack cheating we will  need to know the action later
      // we can not blindly access index 0
      if (this === level) {
        action = level.actionBuffer.find((action) => action.type === "hotspot");
      }
      return this !== level;
    });
    if (!action) {
      return;
    }
    // TODO emit the event based on the type of things removed downstream
    // this.root.events.emit("hotspot_removed", action as HotspotAction);
    return action as HotspotAction;
  }
  findLocation() {
    const level = this;
    if (level.parent === null) {
      // root
      return [];
    }

    function findIndex(level: Level) {
      let index = 0;
      if (level.parent?.levelBuffer.length === 0) {
        return index;
      }
      level.parent!.levelBuffer.forEach((lvl, i, a) => {
        if (lvl === level) {
          index = i;
        } else if (i === a.length - 1) {
          index += 1 + i;
        }
      });
      return index;
    }

    // let loc = [0,1,1]
    function findLevel(level: Level) {
      const depth = 0;
      const location = [];
      while (level.parent) {
        location.push(findIndex(level));
        level = level.parent;
      }
      location.reverse();
      return location;
    }

    return findLevel(level);
  }

  addChildLevel(newLevel?: Level) {
    const level = this;
    const location = [...level.location, level.levelBuffer.length].filter((l) => l >= 0);
    const nodeToAdd: Level =
      newLevel ??
      new Level({
        location,
        parent: level,
        root: level.root,
      });
    level.levelBuffer.push(nodeToAdd);
    return nodeToAdd;
  }

  getLevel(level: Level) {
    function traverseLevels(level: Level) {}
    for (let i = 0; i < this.root.levelBuffer.length; i++) {
      // if(level === level.)
    }
  }

  updateLevel(location: NodeLocation): void;
  updateLevel(level: Level | NodeLocation): void {
    // if(level instanceof Level) {
    // } else {
    //   level.forEach()
    // }
  }

  /**
   *
   * @param type
   * @param options
   * @returns
   * @description
   * probably a good idea to just refacto this methods to something different without a switch case in the middle.
   * issue that I can' pass in paramaters from othr function with this.
   */
  addAction(
    type: Interactivity.PageManifestInteractivityActionTypes,
    options?: Omit<ActionOptions, "level"> & { beginning?: boolean },
  ) {
    const level = this;
    switch (type) {
      case "hotspot": {
        const opts: ActionOptions = {
          level,
          hotspotLocation: options?.hotspotLocation,
          taskBuffer: options?.taskBuffer,
          version: HOTSPOT_LATEST_VERSION,
        };

        if (options?.required) {
          opts.required = options.required;
        }
        const action = new HotspotAction(opts);
        level.actionBuffer.push(action);
        return action;
      }
      case "external-wait": {
        const action = new WaitAction({
          level,
        });
        if (level.actionBuffer[0].type !== "external-wait") {
          level.actionBuffer.unshift(action);
          return action;
        }
        return null;
      }
      case "delay": {
        const action = new DelayAction({
          level,
          delay: options?.delay,
        });
        if (options?.beginning) {
          level.actionBuffer.unshift(action);
        } else {
          level.actionBuffer.push(action);
        }
        return action as DelayAction;
      }

      default:
        throw new Error("action type not implemented");
    }
  }
  addWaitForAudio() {
    const level = this;
    const waitIsAlreadyThere = level.actionBuffer[0].type === "external-wait";
    if (waitIsAlreadyThere) return;
    this.addAction("external-wait");
  }
  removeWaitForAudio() {
    try {
      const level = this;
      const action = this.actionBuffer[0];
      if (action.type === "external-wait") {
        level.actionBuffer.shift();
      }
    } catch (e) {
      console.error("remove Wait for audio failed", e);
    }
  }
  getAllTargetIdsInActions() {
    const idList = new Set<ObjectId>();
    for (const action of this.actionBuffer.values()) {
      for (const val of action.taskBuffer.values()) {
        idList.add(val.targetId);
      }
    }
    // this.cachedTargetIdList = idList;
    return idList;
  }
  addHotspotLevel() {
    const level = this.addChildLevel();
    const action = level.addAction("hotspot");
    return action as HotspotAction;
  }

  getRoot(): InteractivityBuilder {
    let node: Level = this;
    while (node.parent !== null) {
      node = node.parent;
    }
    return node as InteractivityBuilder;
  }
  addSiblingLevel(newLevel?: Level) {
    const level = this;
    const location = [...level.location];
    if (level.parent === null) {
      throw new Error("cant add sibling to root");
    }
    location.pop();
    location.push(level.parent.levelBuffer.length);
    const levelToAdd: Level =
      newLevel ??
      new Level({
        location,
        parent: level.parent,
        root: level.root,
      });

    level.parent.levelBuffer.push(levelToAdd);
    return levelToAdd;
  }

  isRoot(): boolean {
    return this.parent === null;
  }
  gotoNode(nodeLocation: NodeLocation, startingNode: Level = this) {
    let level = startingNode; // root
    for (let i = 0; i < nodeLocation.length; i++) {
      level = level.levelBuffer[nodeLocation[i]]; //node on level 1
      // need to go one level deeper
    }
    return level;
  }
  protected deleteSelf() {
    const level = this;
    const parent = level.parent;
    if (parent === null) {
      throw new Error("cant delete root");
    }
    parent.levelBuffer = parent.levelBuffer.filter((l) => l !== level);
  }
}
interface DelayLevelOptions {
  objectId: string;
  startDelay?: number;
  endDelay?: number | null;
}
// find the level reserved for a start-end data structure for the given target
// this special level can be called whatever. but it will look like this:
/**
 * {
 *             //  `start(SHOWS)` `end(HIDE)`
 *  actionBuffer: [DelayAction, DelayAction],
 *
 * }
 * // start
 * DelayAction: {
 *  delay: 5000 // flips visible on after 5 seconds
 *
 *  taskBuffer: [Task] // the task in here flips to on! it is important cause that is how we determine
 * }
 */

/**
 * conditions, start position must:
 * 1. not be smaller than 0 or 0
 * 2. be one less than the end position
 * 3. be null if 0 aka non existant
 *
 */
const startPositionValidation = {
  smallerThanEndPosition: (startPosition: number, endPosition: number, oneUnit = 1) => {
    if (startPosition >= endPosition) {
      startPosition = endPosition - oneUnit; // 1 is the smallest unit, it should be modular
    }
    return startPosition;
  },
};
export function startPositionBusinessLogic(startPosition: number, endPosition?: number, oneUnit?: number) {
  if (typeof startPosition === "number") {
    startPosition = validation.noLessThanZero(startPosition);
    if (typeof endPosition === "number") {
      startPosition = startPositionValidation.smallerThanEndPosition(startPosition, endPosition, oneUnit);
    }
  }
  return startPosition;
}
export class StartEndLevel extends Level {
  objectId: string;
  startAction?: DelayAction;
  endAction?: DelayAction;
  constructor(options: NodeOptions & DelayLevelOptions) {
    super(options);

    if (options.objectId) {
      this.objectId = options.objectId;
    } else {
      throw new Error("need target id");
    }
    if (options.startDelay) {
      this._buildStartAction(options.startDelay);
    }
    if (options.endDelay) {
      this._buildEndAction(options.endDelay);
    }
  }

  /**
   *
   * @param delay in milliseconds, straight from the engine data
   */
  _buildStartAction(startDelay: number) {
    if (this.startAction) {
      throw new Error("start action already exists");
    }
    if (startDelay <= 0) {
      throw new Error("start delay must be greater than 0, this should not be in the data plase clean up the data");
    }
    const action = this.addAction("delay", {
      delay: startDelay,
      beginning: true,
    });
    if (action) {
      action.createTask({
        mappedAction: "SHOW/HIDE",
        actionValue: true,
        targetId: this.objectId,
      });
      this.startAction = action;
      return this.startAction;
    } else {
      throw new Error("error creating action, bad stuff");
    }
  }

  /**
   *
   * @param endDelay in milliseconds, straight from the engine data
   */
  _buildEndAction(endDelay: number) {
    if (this.endAction) {
      throw new Error("end action already exists");
    }
    if (endDelay <= 0) {
      throw new Error("end delay must be greater than 0, this should not be in the data plase clean up the data");
    }
    const action = this.addAction("delay", {
      delay: endDelay,
      beginning: false,
    });
    if (action) {
      action.createTask({
        mappedAction: "SHOW/HIDE",
        actionValue: false,
        targetId: this.objectId,
      });
      this.endAction = action;
      return this.endAction;
    } else {
      throw new Error("error creating action, bad stuff");
    }
  }

  verifyNewStartDelay(delay: number) {
    // start delay can not be 0
    if (delay === 0) {
      // delete the start action
    }
  }

  editStartAndEndDelay(newStartDelay: number, newEndDelay: number | null) {
    if (newEndDelay === null) {
      this.deleteEndAction();
    } else if (typeof newEndDelay === "number") {
      this.editEndAction(newEndDelay);
    }

    // here we need to check if the deleting the end action deleted the whole start end level

    if (newStartDelay) {
      const startEndLevel = this.root.getStartEndLevel(this.objectId);
      if (startEndLevel) {
        // this === startEndLevel
        this.editStartAction(newStartDelay);
      } else {
        // the start end level was deleted, we need to re-create it
        this.root.addNewChildStartEndLevel(this.objectId, {
          startDelay: newStartDelay,
          endDelay: newEndDelay,
        });
      }
    } else {
      this.deleteStartAction();
    }
  }
  /**
   *
   * @param newStartDelay in milliseconds this should be the final start delay all math to
   * figure out the end and start is done externally, therefore this is a "dumb" method
   * and should be used with caution as it will straight up overwrite the delay values
   * @returns
   */
  editStartAction(newStartDelay: number /** in ms */) {
    log("EDITING START ACTION, NEW START DELAY", newStartDelay);
    const oldStartDelay = this.startAction?.delay;
    const oldEndDelay = this.endAction?.delay;
    log("OLD START DELAY", oldStartDelay);
    log("OLD END DELAY", oldEndDelay);
    // start action is set to 0 so we delete it.
    if (this.startAction && newStartDelay <= 0) {
      this.deleteStartAction();
    }

    // case: starting from second 0 and increasing the delay
    if (!this.startAction && newStartDelay > 0) {
      this.startAction = this._addStartAction(newStartDelay);
      return [this.startAction] as const;
    }

    if (this.startAction) {
      log("SETTING DELAY:", newStartDelay);
      this.startAction.delay = newStartDelay;
    }

    return [this.startAction] as const;
  }
  /**
   * similar to build action but this will also keep the end action in sync used while editing in memory
   *
   * @param startDelay in milliseconds
   * @returns
   */
  private _addStartAction(startDelay: number /**in ms*/) {
    log("ADDING START ACTION, NEW START DELAY", startDelay);
    if (this.startAction) {
      console.warn("start action already exists");
      return this.startAction;
    }
    if (startDelay <= 0) {
      console.warn("start delay can not be 0");
      return;
    }

    const action = this.addAction("delay", {
      delay: startDelay,
      beginning: true,
    });
    if (action) {
      action.createTask({
        mappedAction: "SHOW/HIDE",
        actionValue: true,
        targetId: this.objectId,
      });
      this.startAction = action;
      return this.startAction;
    } else {
      throw new Error("error creating action, bad stuff");
    }
  }

  deleteStartAction() {
    if (this.startAction) {
      log("DELETING START ACTION");
      this.startAction.level.deleteAction(this.startAction);
      this.startAction = undefined;
    } else {
      log("START ACTION DOES NOT EXIST");
    }
  }

  // this method is setting the end delay dumbly
  editEndAction(endDelay: number) {
    log("EDITING END ACTION, NEW END DELAY", endDelay);
    // the delay of the end action is the difference between the end delay and the start delay
    // because the start delay runs first, and the end delay runs second synchronously

    // this assumes that the start delay is always less than the end delay and always in ms
    if (this.endAction) {
      this.endAction.delay = endDelay;
      log("new end delay:", this.endAction?.delay);
      return this.endAction;
    } else {
      return this._addEndAction(endDelay);
    }
  }
  /**
   *
   * @param endDelay in milliseconds and the end delay as it is in the manifest
   * @returns
   */
  private _addEndAction(endDelay: number) {
    if (this.endAction) {
      return this.endAction;
    }

    const action = this.addAction("delay", { delay: endDelay });
    if (action) {
      action.createTask({
        mappedAction: "SHOW/HIDE",
        actionValue: false,
        targetId: this.objectId,
      });
      this.endAction = action;
      return this.endAction;
    } else {
      throw new Error("error creating action bad stuff");
    }
  }
  deleteEndAction() {
    if (this.endAction) {
      this.endAction.level.deleteAction(this.endAction);
      this.endAction = undefined;
      if (!this.startAction) {
        this.deleteSelf();
      }
    }
  }

  // no need to sync with react ? maybe we do
}

declare interface InteractivityBuilderEvents extends EventEmitter {
  on(event: "hotspot_added", listener: (action: HotspotAction) => void): this;
  emit(event: "hotspot_added", action: HotspotAction): boolean;

  on(event: "hotspot_removed", listener: (action: HotspotAction) => void): this;
  emit(event: "hotspot_removed", action: HotspotAction): boolean;

  on(event: "all_hotspots", listener: (action: HotspotAction[]) => void): this;
  emit(event: "all_hotspots", action: HotspotAction[]): boolean;

  on(event: "sync_tree_with_page_manifest", listener: (printedTree: Interactivity.PageManifestData) => void): this;
  emit(event: "sync_tree_with_page_manifest", printedTree: Interactivity.PageManifestData): boolean;
  on(event: "created-timeline-editor", listener: () => void): this;
  emit(event: "created-timeline-editor"): boolean;
}

export class InteractivityBuilder extends Level {
  level = 0;

  events: InteractivityBuilderEvents = new EventEmitter();

  jsonTreeOutput: Interactivity.PageManifestData = {
    actionBuffer: [],
    flowMode: "async",
    levelBuffer: [],
  };

  currentCoordinates: NodeLocation = [];

  currentEditingLevel!: Level;

  lastEditedCoordinates: NodeLocation[] = [];

  sibling = 0;

  private builtFromData = false;
  //   parent: Level | null = null
  /**
   * level buffer is the children property in a node tree
   */
  constructor(jsonData?: Interactivity.PageManifestData) {
    super({ parent: null });

    if (jsonData) {
      this.builtFromData = true;
      this.createFromData(jsonData);
      this.getAllHotspots();
    }
  }

  clearId(id: ObjectId) {
    travelTree(this, (s) => {
      s.actionBuffer.forEach((action) => {
        action.taskBuffer = action.taskBuffer.filter((task) => task.targetId !== id);
        if (action.taskBuffer.length === 0) {
          if (!action.level.isRoot()) {
            action.level.deleteAction(action);
          }
        }
      });
    });
  }

  clearTimelineIds(id: ObjectId) {
    travelTree(this, (s) => {
      s.actionBuffer.forEach((action) => {
        if (action.type === "delay") {
          action.taskBuffer = action.taskBuffer.filter((task) => task.targetId !== id);
          if (action.taskBuffer.length === 0) {
            if (!action.level.isRoot()) {
              action.level.deleteAction(action);
            }
          }
        }
      });
    });
  }

  deleteTaskFromTargetId(id: ObjectId) {
    //TODO cache tasks for more speed
    travelTree(this, (s) => {
      s.actionBuffer.forEach((action) => {
        (action as Action).deleteTaskById(id);
      });
    });
  }

  getStartEndLevels() {
    return this.levelBuffer.filter((l) => l instanceof StartEndLevel) as StartEndLevel[];
  }

  getStartEndLevel(objectId: string) {
    return this.getStartEndLevels().find((l) => {
      if (l?.objectId) {
        return l.objectId === objectId;
      }
      return false;
    });
  }
  /**
   *  emits event to sync tree with page manifest
   *  @emits {Interactivity.PageManifestData}
   *  @returns {Interactivity.PageManifestData}
   */

  public sync() {
    window.setTimeout(() => {
      const printedTree = this.printTree();
      this.events.emit("sync_tree_with_page_manifest", printedTree);
      return printedTree;
    }, 0);
  }

  /**
   *
   * @param startingLevel
   * @returns deep clone structure of tree as supposed to be written to JSON.
   *
   *
   * anytime the structure changes somehow, this method should be updated.
   * this methods is basically just a clone deep function. specifically for the interactivity tree.
   */
  printTree(startingLevel?: Level) {
    const instanceCurrentLevel = startingLevel ?? this;
    const o = {} as Interactivity.PageManifestData;
    // o.levelBuffer = currentLevel.levelBuffer;
    o.flowMode = instanceCurrentLevel.flowMode;
    o.levelBuffer = [];
    o.actionBuffer = [];
    instanceCurrentLevel.actionBuffer.forEach((instanceAction) => {
      const a = {} as Interactivity.PageManifestAction;

      if (instanceAction.pauseOnLoad) {
        a.pauseOnLoad = instanceAction.pauseOnLoad;
      }

      if (instanceAction.resumeOnClick) {
        a.resumeOnClick = instanceAction.resumeOnClick;
      }

      a.type = instanceAction.type;
      if (instanceAction.required === true || instanceAction.required === false) {
        a.required = instanceAction.required;
      }
      a.delay = instanceAction.delay;
      if (instanceAction.type === "hotspot" && (instanceAction as HotspotAction).hotspotLocation) {
        const hotspotInstance = instanceAction as HotspotAction;
        a.hotspotLocation = {
          top: hotspotInstance.hotspotLocation.top,
          left: hotspotInstance.hotspotLocation.left,
          width: hotspotInstance.hotspotLocation.width,
          height: hotspotInstance.hotspotLocation.height,
          isDisplayed: hotspotInstance.hotspotLocation.isDisplayed,
        };

        if (hotspotInstance.version) {
          a.version = hotspotInstance.version;
        }
        // print a.required
      }

      /**
       * if there is no targetId there is no way this can work, something must fail.
       */
      let tb: Interactivity.PageManifestTask[] = [];

      tb = instanceAction.taskBuffer.map((ta) => {
        if (ta.targetId) {
          return {
            targetId: ta.targetId,
            actionValue: ta.actionValue,
            mappedAction: ta.mappedAction,
            actionValueUnit: ta.actionValueUnit,
          };
        } else {
          throw new Error("Can not print with missing targetId");
        }
      });
      /**
       * check if the instance level is a delay level, any action in this level should have at least on task.
       * if not, there is a big problem.
       */

      if (instanceCurrentLevel instanceof StartEndLevel) {
        if (tb.length === 0) {
          throw new Error("Delay level, Delay action has no tasks, please reproduce issue and report");
        }
      }

      a.taskBuffer = tb;
      o.actionBuffer.push(a);
    });
    instanceCurrentLevel.levelBuffer.forEach((l, i) => {
      const res = this.printTree(l);
      o.levelBuffer[i] = res;
    });
    return o;
  }

  getAllFirstHotspots(startingLevel: Level = this) {
    const firstHotspotOfBuffer: HotspotAction[] = [];
    travelTree(startingLevel, (level) => {
      let isActionPushed = false;
      level.actionBuffer.forEach((action) => {
        //this will take the first hotspot action
        if (action.type === "hotspot" && !isActionPushed) {
          firstHotspotOfBuffer.push(action as HotspotAction);
          isActionPushed = true;
        }
      });
    });
    return firstHotspotOfBuffer;
  }
  /**
   * this will have to be edited in the future to accomodate when different things
   * are placed in sequence and are not immediately to be show to the user or maybe
   *  split that logic into something else
   */
  getAllHotspots(startingLevel: Level = this) {
    const hotspots: HotspotAction[] = [];

    function traverse(startingLevel: Level) {
      if (startingLevel.levelBuffer.length) {
        startingLevel.levelBuffer.forEach((level) => {
          /**
           * business rule only grabbing and editing hotspots on root.levelBuffer
           */
          if (level.parent?.isRoot()) {
            // root
            level.actionBuffer.forEach((action) => {
              if (action.type === "hotspot") {
                hotspots.push(action as HotspotAction);
              }
            });
          }

          traverse(level);
        });
      }
    }
    traverse(startingLevel);
    return hotspots;
  }

  getAllLevelsWithHotspots(startingLevel: Level = this) {
    const levels = new Set<Level>();
    function traverse(startingLevel: Level) {
      if (startingLevel.levelBuffer.length) {
        startingLevel.levelBuffer.forEach((level) => {
          /**
           * business rule only grabbing and editing hotspots on root.levelBuffer
           */
          if (level.parent?.isRoot()) {
            // root
            level.actionBuffer.forEach((action) => {
              if (action.type === "hotspot") {
                levels.add(level);
              }
            });
          }

          traverse(level);
        });
      }
    }
    traverse(startingLevel);
    return levels;
  }
  //function that transverses the tree and cleans up wait actions. this should be called after audio is removed from a
  cleanupWaitTasks() {
    const levels = this.levelBuffer;
    levels.forEach((level) => {
      level.removeWaitForAudio();
    });
  }

  determineIfLevelIsStartEndLevel(level: Interactivity.PageManifestData) {
    const delayActions = level.actionBuffer.filter((l) => l.type === "delay");
    let startD;
    let endD;
    let objectId;
    if (delayActions.length) {
      const firstTask = delayActions[0]?.taskBuffer?.[0];
      if (firstTask) {
        if (firstTask.mappedAction === "SHOW/HIDE") {
          objectId = firstTask.targetId as string;
          if (firstTask.actionValue === true) {
            startD = delayActions[0].delay;
          } else if (firstTask.actionValue === false) {
            endD = delayActions[0].delay;
          }
        }
      }
      const secondTask = delayActions[1]?.taskBuffer?.[0];
      if (secondTask) {
        if (secondTask.mappedAction === "SHOW/HIDE") {
          if (secondTask.actionValue === true) {
            startD = delayActions[1].delay;
          } else if (secondTask.actionValue === false) {
            endD = delayActions[1].delay;
          }
        }
      }
      return { startD, endD, objectId };
    } else {
      return null;
    }
  }

  /**
   *
   * this method should be edited anytime the tree structure changes.
   *
   *
   * */

  createFromData(jsonLevel: Interactivity.PageManifestData, startingLevel: Level = this) {
    if (jsonLevel.levelBuffer.length) {
      jsonLevel.levelBuffer.forEach((dataLevel) => {
        // this is the
        const delayLevelData = this.determineIfLevelIsStartEndLevel(dataLevel);
        let instanceLevel: Level | StartEndLevel;
        if (delayLevelData && (delayLevelData.startD || delayLevelData.endD) && delayLevelData.objectId) {
          instanceLevel = new StartEndLevel({
            parent: startingLevel,
            flowMode: dataLevel.flowMode,
            endDelay: delayLevelData.endD,
            startDelay: delayLevelData.startD,
            objectId: delayLevelData.objectId,
          });

          /**
           * if the timeline does not exist here, it should be generated and reconciliated
           * this is to deal with lessons that have been created without the use of the pageManifest.timeline structure
           * and that are only utilizing the pageManifest.interactivity structure
           */
          let start = delayLevelData.startD ?? 0;
          let end = delayLevelData.endD ?? null;
          if (end) {
            end = conversion.millisecondToNearestSecond(end + start);
          }
          if (start) {
            start = conversion.millisecondToNearestSecond(start);
          }
        } else {
          instanceLevel = new Level({
            parent: startingLevel,
            flowMode: dataLevel.flowMode,
          });
        }

        const instancesActionBuffer = dataLevel.actionBuffer.map((dataAction) => {
          if (dataAction.type === "hotspot") {
            const hotspotAction = new HotspotAction({
              level: instanceLevel,
              hotspotLocation: dataAction.hotspotLocation,
              delay: dataAction.delay,

              resumeOnClick: dataAction.resumeOnClick,
              pauseOnLoad: dataAction.pauseOnLoad,
            });
            hotspotAction.required = dataAction.required;

            hotspotAction.taskBuffer = dataAction.taskBuffer.map((dataTask) => {
              return new Task({
                action: hotspotAction,
                actionValue: dataTask.actionValue,
                actionValueUnit: dataTask.actionValueUnit,
                mappedAction: dataTask.mappedAction,
                targetId: dataTask.targetId,
              });
            });

            return hotspotAction;
          } else if (dataAction.type === "external-wait") {
            return new WaitAction({
              level: instanceLevel,
            });
          } else if (dataAction.type === "delay") {
            return new DelayAction({
              level: instanceLevel,
            });
          } else {
            // throw new Error(
            //   dataAction.type + ' : action type not implemented',
            // );
            console.error(dataAction.type + " : action type not implemented");
          }
        });
        if (!(instanceLevel instanceof StartEndLevel)) {
          instanceLevel.actionBuffer = instancesActionBuffer;
        }
        startingLevel.levelBuffer.push(instanceLevel);

        this.createFromData(dataLevel, instanceLevel);
      });
    }
  }
  // (jsonData: Interactivity.PageManifestData) {
  //   const rootLevelBuffer = [];
  // }
}

/**
 * provides tree taversal methods for the interactivy builder object
 */

export type NodeLocation = number[];

// const nodeLocation = [2, 0];

export interface NodeOptions {
  flowMode?: "async" | "sync";
  location?: NodeLocation;
  parent: Level | null;
  root?: InteractivityBuilder;
}
