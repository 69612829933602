import "./index.css";
import classes from "../../../../utils/HTML/classes";
import { LanguageType } from "../../../../models/IInteractiveAudio";
import { ReactComponent as AddIcon } from "assets/icons/Common/add-icon.svg";
import React from "react";

export interface SecondaryLanguageSwitchProps {
  onSelectPrimary: () => void;
  onSelectSecondary: () => void;
  onCreateSecondary: () => void;
  activeLanguage: LanguageType;
  hasSecondary: boolean;
}

export const SecondaryLanguageSwitch = ({
  onSelectSecondary,
  onSelectPrimary,
  onCreateSecondary,
  hasSecondary,
  activeLanguage,
}: SecondaryLanguageSwitchProps) => {
  const showSecondary = hasSecondary || activeLanguage === LanguageType.SECONDARY;

  return (
    <div className="language-switch__wrapper">
      <button
        className={classes("language-switch__button", { "button--active": activeLanguage === LanguageType.PRIMARY })}
        onClick={onSelectPrimary}
      >
        1
      </button>
      {showSecondary ? (
        <button
          className={classes("language-switch__button", {
            "button--active": activeLanguage === LanguageType.SECONDARY,
          })}
          onClick={onSelectSecondary}
        >
          2
        </button>
      ) : (
        <div className="add-button-container">
          <button
            className="add-button cpat-button cpat-button--icon"
            title="Add Audio"
            onClick={() => onCreateSecondary()}
          >
            <AddIcon />
          </button>
        </div>
      )}
    </div>
  );
};
