import React from "react";
import { SVGAnnotationProps } from "../index";
import { useObjectsState } from "../../../contexts/ObjectsProvider";
import { HighlightAnnotation } from "../HighlightAnnotation";

export function SVGCircledLetter({
  objectId,
  backgroundColor,
  borderColor,
  height,
  isDisplayed,
  onMouseDown,
  opacity,
  rotate,
  strokeWidth,
  width,
  x,
  y,
  zIndex,
  fontColor,
}: SVGAnnotationProps & {
  fontColor?: string;
}) {
  const alphas = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  const { annotations } = useObjectsState();
  const circleLetterAnnotations = annotations.filter(
    (annotation: any) => annotation.type === "circledLetter" || annotation.type === "circleLetter",
  );
  const index = circleLetterAnnotations.findIndex((annotation: any) => annotation.objectId === objectId);
  const transformString = `translate(${x}px, ${y}px) rotate(${rotate ?? 0}deg)`;
  return (
    <svg
      viewBox={`0 0 100 100`}
      id={`circledLetter-${objectId}`}
      data-objectid={objectId}
      tabIndex={0}
      name="circledLetter"
      className="annotation"
      onMouseDown={(e: React.MouseEvent<SVGSVGElement>) => {
        onMouseDown(e, objectId);
      }}
      preserveAspectRatio="xMinYMin meet"
      style={{
        width: `${width}px`,
        height: `${height}px`,
        zIndex: zIndex,
        transform: transformString,
        opacity: isDisplayed ? opacity : 0.5,
        filter: isDisplayed ? "none" : "grayScale(100%)",
      }}
    >
      <HighlightAnnotation objectId={objectId} />
      <circle
        cx="50"
        cy="50"
        r="47"
        stroke={`${borderColor ? borderColor : "transparent"}`}
        fill={`${backgroundColor ? backgroundColor : "#4B4F58"}`}
        strokeWidth={`${strokeWidth || "3"}`}
      ></circle>
      <text
        className="step-text"
        x="50"
        y="50"
        dy="26.5"
        textAnchor="middle"
        fill={`${fontColor ? fontColor : "white"}`}
      >
        {alphas[index]}
      </text>
    </svg>
  );
}
