import React from "react";

type WhatToDoType = {
  labelClass: string;
  WTDTitle?: string | undefined;
  displayWhatToDo: () => any;
};

const WhatToDo = (props: WhatToDoType) => {
  const { labelClass, WTDTitle, displayWhatToDo } = props;

  return (
    <React.Fragment>
      <div id="what-to-do-panel">
        <div id="what-to-do-header">
          <span className={labelClass}>{WTDTitle ? WTDTitle : "What To Do"}</span>
        </div>
        <div id="what-to-do-content">
          <ul>{displayWhatToDo()}</ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WhatToDo;
