import React from "react";
import ConfirmDelete from "../../Modals/ConfirmDelete";

type DeleteConfirmationType = {
  cancelDelete: () => void;
  confirmDeleteStep: () => void;
};

const IGADeleteStepConfirmation = (props: DeleteConfirmationType) => {
  const { cancelDelete, confirmDeleteStep } = props;
  return (
    <React.Fragment>
      <div id="iga-delete-confirmation-container">
        <div id="iga-delete-confirmation-header">
          <span>Confirm Step Delete</span>
          <button className="formCloser">
            <span className="icon-remove-icon-white" onClick={() => cancelDelete()}>
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
              <span className="path4"></span>
            </span>
          </button>
        </div>
        <div id="iga-delete-confirmation-body">
          <p>The currently selected step will be deleted and all changes will be lost.</p>
          <p>Do you wish to continue and lose all changes?</p>
        </div>
        <div id="iga-delete-confirmation-footer">
          <button onClick={() => cancelDelete()}>No</button>
          <button onClick={() => confirmDeleteStep()}>Yes</button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default IGADeleteStepConfirmation;
