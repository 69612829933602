import "../../BasePageDesigner.css";
import "./FreeForm.css";
import "./assets/ffi_style.css";
import _ from "lodash";
import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useReducer, useRef, useState } from "react";
import BaseFFText from "./components/BaseFFText";

import BaseFFVideo from "./components/BaseFFVideo";
import BaseFFSCORM from "./components/BaseFFSCORM";
import ConfirmDelete from "../../../../components/Modals/ConfirmDelete";
// import Symbols from "../../../../components/Symbol/Symbol";
import WYSIWYGToolbar from "../../../../components/WYSIWYGToolbar/WYSIWYGToolbar";
import { ISymbolV2 } from "../../../../components/Symbol/models/ISymbol";
import ISymbolStyle from "../../../../components/Symbol/models/ISymbolStyle";
import { BoundType } from "../../../../components/react-moveable";
import { IAnnotation } from "../../../../components/Annotation/models/IAnnotation";
import {
  ElementTypes,
  IBasePage,
  IBasePageVideoV2,
  IBasicPageAttributes,
  IBasicPageImageV2,
  IBasicPageScormV2,
  IBasicPageTextBlockV2,
} from "../IBasePage";
import { IPageContext, PageContext } from "../../../../routes/builderContexts";
import ObjectPropertyBox, { updatePropertyBox } from "../../../../components/ObjectPropertyBox/ObjectPropertyBox";
import { IPBRefs, IPropertyBox } from "../../../../components/ObjectPropertyBox/models/IObjectPropertyBox";
import { emptyPropertyBox } from "../../../../components/ObjectPropertyBox/models/emptyPropertyBox";
import * as pbFunctions from "../../../../components/ObjectPropertyBox/functions/PropertyBoxFunctions";
import { IKeyPressHandler } from "../../../../components/ObjectPropertyBox/models/IKeyPressHandler";
import { IAnnotationState } from "../../../../components/Annotation/models/IAnnotationState";
import { handleAnnotationZ } from "../../../../components/Annotation/AnnotationFunctions/AnnotationZValidation";
import { ListOfObjects } from "../../../../classes/ObjectList/ListOfObjects";
import { SCORMPropType, TextPropType, VideoPropType } from "./models/ICustomPage";
import { CustomPageElement } from "./classes/CustomPageElement";
import { ClassSymbol } from "../../../../components/Symbol/classes/ClassSymbol";
import { CustomPageObjectList } from "../../../../classes/ObjectList/CustomPageObjectList";
import CustomPageCover from "./components/CustomPageCover";
import InteractivityHotspots from "../../../../components/InteractivityHotspots";
import {
  useMovableElementsPlaneDispatch,
  useMovableElementsPlaneState,
  useUIStore,
} from "../../../../contexts/MovableElementsPlaneProvider";
import {
  InteractivityHotspotActionTypes,
  useInteracitvityHotspotDispatch,
  useInteracitvityHotspotState,
} from "../../../../contexts/InteractivityHotspotProvider";
import { freeformSettings } from "./configurations/permissions";
import useSettingsToggle from "../../../../hooks/useSettingsToggle";
import { useObjectPropertyBoxDispatch } from "../../../../contexts/ObjectPropertyBox/ObjectPropertyBoxProvider";
import Tables from "../../../../components/Tables/Tables";
import { useTablesDataDispatch } from "../../../../components/Tables/TablesDataProvider";
import { useInteractivityBuilderState } from "../../../../contexts/InteractivityBuilderProvider";
import { hasClipCoordinates } from "../../../../components/Moveable/lib/ables/cropFunctions";
import {
  useSelectedObjectDispatch,
  useSelectedObjectState,
} from "../../../../contexts/SelectedObjectProvider/SelectedObjectProvider";
import { determinePageBG } from "../../../../contexts/PageColorProvider/PageColorProvider";
import { Images } from "../../../../objects/Images";
import { runOnObjects, getObjectsFromManifest } from "../../../../utils/PageManifestParsing";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../../../contexts/ObjectsProvider";
import { AbstractMoveable } from "../../../../components/Moveable/AbstractMoveable";
import { AbstractMoveableCropping } from "../../../../components/Moveable/AbstractMoveableCropping";
import { Annotations } from "../../../../components/Annotations";
import {
  LessonPagesActions,
  useLessonPagesDispatch,
} from "../../../../contexts/LessonPagesProvider/LessonPagesProvider";
import { Symbols } from "../../../../components/Symbols";
import { nanoid } from "../../../../lib/nanoId";
import { symbolTypes, fontAnnotation, DEFAULT_SMART_OBJECT, DEFAULT_PANORAMIC } from "../../../../const";
import { CURRENT_TEXTBOX_VERSION } from "../../../../utils/Versioning/basePagesVersioning";
import { LineMoveable } from "../../../../components/Moveable/LineMoveable";
import { SmartObjects } from "../../../../components/SmartObjects";
import { useMetaVariableStore } from "../../../../lib/SmartObject/store";
import { Panoramics } from "../../../../objects/Panoramic/Panoramics";
import { useMiscUI } from "../../../../contexts/MiscUI/MiscUIProvider";
import PanoramicsOverlay from "../../../../objects/Panoramic/PanoramicsOverlay";
import { useMetaVariableTimelineTracker } from "../../../../lib/SmartObject/useMetaVariableTimelineTracker";
import { MaskMoveable } from "../../../../objects/Images/MaskMoveable";

const { SET_CURRENT_HOTSPOT, REMOVE_VISIBLE_HOTSPOT } = InteractivityHotspotActionTypes;
const SCORM_PLAYER_LIMIT = 1;
const MAX_MAJOR_ELEMENTS_COUNT = 150;
const MAX_ANNOTATIONS_COUNT = 250;

export type LineHeightType = number | undefined | "normal";
export const LINE_HEIGHT_FOR_TEXT_BOXES: LineHeightType = "normal";

type BaseProps = {
  quillRef: any;
  selectedFontSize: string;
  handleImageDivClick: (index: number) => void;
  handleVideoClick: () => void;
  handleSCORMClick: () => void;
  handleSmartObjectClick: () => void;
  handle360pClick: () => void;
  setSelectedFontSize: React.Dispatch<React.SetStateAction<string>>;
};

const BaseFreeForm = (props: BaseProps) => {
  const clearObjectMetaVariables = useMetaVariableStore((s) => s.clearObjectMetaVariables);

  const {
    quillRef,
    selectedFontSize,
    handleImageDivClick,
    handleVideoClick,
    handleSCORMClick,
    handleSmartObjectClick,
    setSelectedFontSize,
    handle360pClick,
  } = props;
  const [miscUI, setMiscUI] = useMiscUI();
  const tablesDispatch = useTablesDataDispatch();
  const lessonPagesDispatch = useLessonPagesDispatch();
  const interacitvityHotspotState = useInteracitvityHotspotState();
  const hotspotsDispatch = useInteracitvityHotspotDispatch();
  const movableElementsDispatch = useMovableElementsPlaneDispatch();
  const { viewportDOMElementHeight } = useMovableElementsPlaneState();
  const interactivityBuilderState = useInteractivityBuilderState();
  const propertyBoxDispatch = useObjectPropertyBoxDispatch();
  const objectsDispatch = useObjectsDispatch();
  const objectsState = useObjectsState();
  const selectedObjectDispatch = useSelectedObjectDispatch();
  const selectedObjectState = useSelectedObjectState();
  const { images, annotations, selectedObjects, moveableRef, textBoxes, videos, objectList, panoramicList } =
    useObjectsState();
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const pageManifest: IBasePage = _.cloneDeep(pageContext.pageManifest);
  const playerRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  const moveRef: React.RefObject<any> = useRef(null);
  const scormRef: React.RefObject<HTMLIFrameElement> = useRef(null);
  const pbRefs: IPBRefs = {
    opbLeftRef: useRef(null),
    opbTopRef: useRef(null),
    opbDegreesRef: useRef(null),
    opbWidthRef: useRef(null),
    opbHeightRef: useRef(null),
    obpZindex: useRef(null),
  };
  const isMounted = useRef<boolean>(true);
  const [bounds, setBounds]: [BoundType, Dispatch<SetStateAction<BoundType>>] = useState<BoundType>({
    top: 0,
    bottom: 900,
    left: 0,
    right: 1500,
  });
  const [editableIndex, setEditableIndex] = useState<number>(-1);
  const [elementType, setElementType] = useState<ElementTypes>("annotations");
  const [isAnnotationMenuShown, setIsAnnotationMenuShown]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [isPropertyBoxShown, setIsPropertyBoxShown] = useState<boolean>(false);
  const [isDisplayed, setIsDisplayed] = useState(true);
  const [isDisplayedTabShow, setIsDisplayedTabShow] = useState(false);
  const [isRotating, setIsRotating] = useState<boolean>(false);
  const [isResizing, setIsResizing] = useState<boolean>(false);
  const [isRatioEqual, setIsRatioEqual] = useState<boolean>(true);
  const [isCroppable, setIsCroppable] = useState<boolean>(false);
  const [isCropping, setIsCropping] = useState<boolean>(false);
  const [majorElementsCount, setMajorElementsCount] = useState<number>(0);
  const [nodeToUpdate, setNodeToUpdate] = useState<any>();
  const [pageDims, setPageDims] = useState<DOMRect | null | undefined>();
  const [propertyBox, setPropertyBox] = useState<IPropertyBox>(emptyPropertyBox);
  const [showConfirm, setShowDeleteConfirm] = useState<boolean>(false);
  const [target, setTarget] = useState<any>();
  const [permissions] = useSettingsToggle(freeformSettings);
  const allowedToCrop = permissions.imageCropping;

  const annotationName: string[] = ["circledNumber", "circledLetter", "squareNumber", "squareLetter"];
  const pageImage: ElementTypes = "pageImage";
  const tables: ElementTypes = "tables";
  const annotations_string: ElementTypes = "annotations";
  const symbols: ElementTypes = "symbols";
  const video: ElementTypes = "video";
  const scorm: ElementTypes = "pageScormObject";
  const [previousIndex, setPreviousIndex] = useState<number>(-1);

  const isCroppedImage = target && nodeToUpdate && hasClipCoordinates(nodeToUpdate);
  const [keepCropping, setKeepCropping] = useState<boolean>(false);
  const scormObject = objectList.find((x: any) => x?.type === "SCROM");
  const hasSCORMorVIDEO = scormObject || videos[0]?.path ? true : false;
  const [loadingVidOrScorm, setLoadingVidOrScorm] = useState<boolean>(false);
  useMetaVariableTimelineTracker();
  const endActions = (target: HTMLElement | SVGElement, passedNode: any, e: any) => {
    e?.stopPropagation();
    target.style.zIndex = nodeToUpdate.zIndex;
    if (isDragging) setIsDragging(false);
    if (isRotating) setIsRotating(false);
    if (isResizing) setIsResizing(false);

    // this is for an edge case where values have been set to NaN or null due to moveable package math
    if (target && elementType === "annotations") {
      if (!passedNode.top) {
        passedNode.top = 1;
      }

      if (!passedNode.left) {
        passedNode.left = 1;
      }
    }

    updateAttributes(passedNode);
    if (target && elementType === "pageImage") {
      const elementIdArray = target.id.split("-");
      const imageId = Number(elementIdArray[elementIdArray.length - 1]);
      const imageElement = document.getElementById("ff-image-" + imageId);
      if (imageElement) {
        setKeepCropping(true);
        setTarget(null);
        setNodeToUpdate(null);
        setEditableIndex(-1);
        setPreviousIndex(editableIndex);
        updateImage(imageElement, imageId, pageManifest.pageImage[imageId].objectId, true);
        setEditableIndex(imageId);
        setTarget(target);
        setNodeToUpdate(pageManifest.pageImage[imageId]);
        showAccurateMeasurementsForImages();
        return;
      }
    }

    pbFunctions.createPropertyBox(passedNode, pageDims as DOMRect, setPropertyBox);
  };

  let tempNode = _.cloneDeep(nodeToUpdate);

  const keyPressHandlers: IKeyPressHandler = {
    props: {
      updateIsDisplayed,
      updateMaintainRatio,
      permissions,
      isDisplayed,
      isDisplayedTabShow,
      elementType: elementType,
      isRatioEqual: isRatioEqual,
      moveRef: moveRef.current,
      nodeToUpdate: nodeToUpdate,
      pageDims: pageDims,
      pbRefs: pbRefs,
      properties: propertyBox,
      shouldMaintainRatio: shouldMaintainRatio(),
      target: target,
      changePropertyBox,
      setIsRatioEqual,
      updateAttributes,
      updateZindex,
      endActions,
      index: editableIndex,
      isCroppable,
      setIsCroppable,
      isCropping,
      setIsCropping,
      allowedToCrop: allowedToCrop && elementType === "pageImage",
      targetIsCroppedImage: hasClipCoordinates(nodeToUpdate),
      bounds,
    },
    changePropertyBox,
    removeSelected,
    setTarget,
    shouldMaintainRatio,
    updateAttributes,
  };

  const annotationsState: IAnnotationState = {
    annotations: pageManifest.annotations,
    bounds: bounds,
    editableIndex: editableIndex,
    pageDims: pageDims,
    tempNode: tempNode,
    selectedAnnotation: pageManifest.annotations[editableIndex],
    symbols: pageManifest.symbols as ISymbolV2[],
    setNodeToUpdate,
  };

  useEffect(() => {
    setIsLoading(false);
    isMounted.current = true;
    return () => {
      playerRef.current = null;
      propertyBoxDispatch({
        type: "REMOVE_OBJECT_PROPERTY_BOX_DOM_NODE",
      });
      movableElementsDispatch({
        type: "RESET_STATE",
      });
      isMounted.current = false;
    };
  }, []);
  const { setDesignerViewportDims } = useUIStore();
  useEffect(() => {
    // this will run right after the previous effect, the order of this matters
    // allso it will run because isLoading has changed state. which means this actually runs twice.
    //if(!isMounted.current) return;
    const tempBounds = {
      top: 0,
      bottom: playerRef?.current?.getBoundingClientRect().height,
      left: 0,
      right: playerRef?.current?.getBoundingClientRect().width,
    };

    const majorElementsCount = new ListOfObjects().countMajorObjects(
      pageManifest.textBlock,
      pageManifest.pageImage,
      pageManifest.tables,
    );
    if (playerRef.current) {
      setDesignerViewportDims(playerRef.current.offsetWidth, playerRef.current.offsetHeight);
      movableElementsDispatch({
        type: "SET_PLANE_VIEWPORT_HEIGHT",
        payload: playerRef.current.offsetHeight,
      });
      movableElementsDispatch({
        type: "SET_PLANE_VIEWPORT_WIDTH",
        payload: playerRef.current.offsetWidth,
      });
    }
    setBounds(tempBounds);
    setPageDims(playerRef?.current?.getBoundingClientRect());
    evaluateZindex();
    setMajorElementsCount(majorElementsCount);
    if (!_.isEqual(pageContext.pageManifest, pageManifest)) {
      pageContext.updatePageManifest(pageManifest);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading && isMounted.current) {
      // TODO dispatch({
      // 	type: 'SET_PAGE_DIMS',
      // 	payload: pageDims
      // })
      const keyMapping = {
        x: "pixelX",
        y: "pixelY",
        width: "pixelWidth",
        height: "pixelHeight",
        top: "pixelTop",
        right: "pixelRight",
        bottom: "pixelBottom",
        left: "pixelLeft",
      };

      for (const [key, value] of Object.entries(pageDims)) {
        const mappedKey = keyMapping[key];
        if (value !== pageManifest.designerEnvironmentData.pagePlayerArea[mappedKey]) {
          addPageDimsToManifest();
          break;
        }
      }
    }
  }, [pageDims]);

  function addPageDimsToManifest() {
    if (pageManifest) {
      const pagePlayerArea = document.getElementById("pageplayerarea");
      const designBoxWrapper = document.getElementById("designBoxWrapper");
      const containerForContentArea = document.getElementById("containerForContentArea");
      const containerForApp = document.getElementById("containerForApp");

      let pagePlayerAreaRect: any;
      let designBoxWrapperRect: any;
      let containerForContentAreaRect: any;
      let containerForAppRect: any;

      if (pagePlayerArea) {
        pagePlayerAreaRect = pagePlayerArea.getBoundingClientRect();
      }

      if (designBoxWrapper) {
        designBoxWrapperRect = designBoxWrapper.getBoundingClientRect();
      }

      if (containerForContentArea) {
        containerForContentAreaRect = containerForContentArea.getBoundingClientRect();
      }

      if (containerForApp) {
        containerForAppRect = containerForApp.getBoundingClientRect();
      }

      pageManifest.designerEnvironmentData = {
        pagePlayerArea: {
          pixelHeight: pagePlayerAreaRect.height,
          pixelWidth: pagePlayerAreaRect.width,
          pixelTop: pagePlayerAreaRect.top,
          pixelLeft: pagePlayerAreaRect.left,
          pixelRight: pagePlayerAreaRect.right,
          pixelBottom: pagePlayerAreaRect.bottom,
          pixelX: pagePlayerAreaRect.x,
          pixelY: pagePlayerAreaRect.y,
        },
        designBoxWrapper: {
          pixelHeight: designBoxWrapperRect.height,
          pixelWidth: designBoxWrapperRect.width,
          pixelTop: designBoxWrapperRect.top,
          pixelLeft: designBoxWrapperRect.left,
          pixelRight: designBoxWrapperRect.right,
          pixelBottom: designBoxWrapperRect.bottom,
          pixelX: designBoxWrapperRect.x,
          pixelY: designBoxWrapperRect.y,
        },
        containerForContentArea: {
          pixelHeight: containerForContentAreaRect.height,
          pixelWidth: containerForContentAreaRect.width,
          pixelTop: containerForContentAreaRect.top,
          pixelLeft: containerForContentAreaRect.left,
          pixelRight: containerForContentAreaRect.right,
          pixelBottom: containerForContentAreaRect.bottom,
          pixelX: containerForContentAreaRect.x,
          pixelY: containerForContentAreaRect.y,
        },
        containerForApp: {
          pixelHeight: containerForAppRect.height,
          pixelWidth: containerForAppRect.width,
          pixelTop: containerForAppRect.top,
          pixelLeft: containerForAppRect.left,
          pixelRight: containerForAppRect.right,
          pixelBottom: containerForAppRect.bottom,
          pixelX: containerForAppRect.x,
          pixelY: containerForAppRect.y,
        },
        version: 1,
      };

      pageContext.updatePageManifest(pageManifest);
    }
  }

  useEffect(() => {
    if (!isMounted.current) return;
    if (hasSCORMorVIDEO) {
      setLoadingVidOrScorm(true);
    } else {
      setLoadingVidOrScorm(false);
    }
  }, [hasSCORMorVIDEO]);

  useEffect(() => {
    const tempBounds = {
      top: 0,
      bottom: playerRef?.current?.getBoundingClientRect().height,
      left: 0,
      right: playerRef?.current?.getBoundingClientRect().width,
    };

    setBounds(tempBounds);
    setPageDims(playerRef?.current?.getBoundingClientRect());
  }, [playerRef.current, window.innerWidth, window.innerWidth]);

  useEffect(() => {
    if ((pageManifest.video?.path.length as number) > 0 && target?.id === "video-target") {
      setTarget(document.getElementById("ff-video-main"));
      setEditableIndex(1);
      setElementType("video");
    }
  }, [pageManifest.video?.path]);

  useEffect(() => {
    if (pageManifest?.pageScormObject?.[0]?.blobPath?.length > 0 && target?.id === "scorm-target") {
      const newScorm = document.getElementById("ff-scorm-main");
      tempNode = pageManifest.pageScormObject[0];
      pbFunctions.createPropertyBox(tempNode, pageDims as DOMRect, setPropertyBox);
      setTarget(newScorm);
      setEditableIndex(0);
      setNodeToUpdate(pageManifest.pageScormObject[0]);
      setElementType("pageScormObject");
      if (newScorm) newScorm.focus();
    }
  }, [pageManifest.pageScormObject?.length]);

  useEffect(() => {
    if (elementType === annotations_string && pageContext.pageManifest?.annotations?.length > 0) {
      const addedID = `${pageManifest.annotations[editableIndex]?.type}-${editableIndex}`;
      const addedTarget = document.querySelector(`#${addedID}`) as HTMLElement | SVGSVGElement;
      tempNode = pageManifest.annotations[editableIndex];
      pbFunctions.createPropertyBox(tempNode, pageDims as DOMRect, setPropertyBox);
      setNodeToUpdate(tempNode);
      setTarget(addedTarget);
      if (addedTarget) addedTarget.focus();
    }
  }, [pageContext.pageManifest?.annotations?.length]);

  useEffect(() => {
    if (0 <= editableIndex && elementType === "pageImage") {
      const image: HTMLImageElement = document.getElementById(`ff-image-${editableIndex}`) as HTMLImageElement;

      if (previousIndex !== editableIndex) {
        setPreviousIndex(editableIndex);
        resetCropPropertyBoxSettings();
        return;
      }

      if (target) {
        target.style.transform = `rotate(0deg)`;
        let { top, left, width }: DOMRect = document
          .getElementById(`ff-image-${editableIndex}`)
          ?.getBoundingClientRect() as DOMRect;
        const ratio = image.naturalHeight / image.naturalWidth;

        let height = width * ratio;

        if (
          height + top > pageManifest.designerEnvironmentData.pagePlayerArea!.pixelHeight ||
          width + left > pageManifest.designerEnvironmentData.pagePlayerArea!.pixelWidth
        ) {
          width = width / 2;
          height = width * ratio;
        }

        if (height + top > pageManifest.designerEnvironmentData.pagePlayerArea!.pixelBottom) {
          const bottomDifference = height + top - pageManifest.designerEnvironmentData.pagePlayerArea!.pixelBottom;
          pageManifest.pageImage[editableIndex].top =
            ((top - bottomDifference - pageManifest.designerEnvironmentData.pagePlayerArea!.pixelTop) /
              (pageManifest.designerEnvironmentData.pagePlayerArea!.pixelHeight as number)) *
            100;
        }

        if (width + left > pageManifest.designerEnvironmentData.pagePlayerArea!.pixelRight) {
          const rightDifference = width + left - pageManifest.designerEnvironmentData.pagePlayerArea!.pixelRight;
          pageManifest.pageImage[editableIndex].left =
            ((left - rightDifference - pageManifest.designerEnvironmentData.pagePlayerArea!.pixelLeft) /
              (pageManifest.designerEnvironmentData.pagePlayerArea!.pixelWidth as number)) *
            100;
        }

        const scale: number[] | undefined = pageManifest.pageImage[editableIndex].transform?.scale;
        const rotate: number | undefined = pageManifest.pageImage[editableIndex]?.transform?.rotate;
        const scaleString: string = scale ? `scale(1, 1)` : "";
        const rotateString: string = rotate ? `rotate(${rotate}deg)` : "rotate(0deg)";

        if (
          pageManifest.pageImage[editableIndex]?.transform &&
          pageManifest.pageImage[editableIndex].transform?.scale
        ) {
          pageManifest.pageImage[editableIndex].transform!.scale = [1, 1];
        }

        const tempAtt: any = {
          ...pageManifest.pageImage[editableIndex],
          height: (height / (pageDims?.height as number)) * 100,
          width: (width / (pageDims?.width as number)) * 100,
        };

        if (tempAtt.height > 95 || tempAtt.width > 95) {
          const tempWidth = (pageDims?.width as number) * 0.9;
          const tempHeight = tempWidth * ratio;
          tempAtt.width = (((pageDims?.width as number) * 0.9) / image.naturalWidth) * 100;
          tempAtt.height = (tempHeight / ((pageDims?.height as number) * 0.9)) * 100;
        }

        target.style.height = `${tempAtt.height}%`;
        target.style.width = `${tempAtt.width}%`;
        target.style.top = `${pageManifest.pageImage[editableIndex]?.top}%`;
        target.style.left = `${pageManifest.pageImage[editableIndex]?.left}%`;
        target.style.transform = `${rotateString} ${scaleString}`;
        moveRef.current?.moveable?.updateRect();
        pageManifest.pageImage[editableIndex] = tempAtt;

        if (image) {
          tempAtt.naturalWidth = image.naturalWidth;
          tempAtt.naturalHeight = image.naturalHeight;
        }

        updateImage(image, editableIndex, pageManifest.pageImage[editableIndex].objectId, true);

        if (tempAtt.maintainRatio || typeof tempAtt.maintainRatio === "undefined") {
          tempAtt.maintainRatio = true;
          image.style.height = "auto";
          image.style.width = "auto";
        }

        if (isCroppedImage) {
          delete pageManifest.pageImage[editableIndex].clipPath;
          // the function called with these parameters just returns a clipObject with all 0 values
          //pageManifest.pageImage[editableIndex].clipPath = getClipObject([], 0, 0);
        }

        setNodeToUpdate(pageManifest.pageImage[editableIndex]);
        setPreviousIndex(editableIndex);
        pageContext.updatePageManifest(pageManifest);
      }

      updatePropertyBoxonImageAdd(image);
    }
  }, [pageManifest.pageImage[editableIndex]?.imagePath]);

  useEffect(() => {
    //checks if target is set. Shows property box if set

    if (_.isNull(target) || _.isUndefined(target)) {
      setIsPropertyBoxShown(false);
      if (!keepCropping) {
        resetCropPropertyBoxSettings();
      }
    } else {
      setIsPropertyBoxShown(true);
    }
  }, [target]);

  const resetCropPropertyBoxSettings = () => {
    if (!setIsCroppable || !setIsCropping) return;
    setIsCropping(false);
    setIsCroppable(false);
    setKeepCropping(false);
  };

  useEffect(() => {
    // this useEffect updates the boundaries for cropped images
    if (!nodeToUpdate || !bounds || !target) return;
    if (elementType === "pageImage" && hasClipCoordinates(nodeToUpdate)) {
      const { width, height } = target.getBoundingClientRect();
      const moveableState = moveRef.current.moveable.state;
      // croppedRect is a property present in the custom able that returns an object that implements DOMRect properties
      const croppedRect = moveableState.croppedRect;
      // assignment of properties from the cropped Rect
      const deltaTop = croppedRect.top;
      const deltaLeft = croppedRect.left;
      const deltaRight = nodeToUpdate.transform?.rotate
        ? Math.floor(width - croppedRect.right)
        : (nodeToUpdate.clipPath.right / 100) * nodeToUpdate.rawData.pixelWidth;
      const deltaBottom = nodeToUpdate.transform?.rotate
        ? Math.floor(height - croppedRect.bottom)
        : (nodeToUpdate.clipPath.bottom / 100) * nodeToUpdate.rawData.pixelHeight;

      setBounds({
        ...bounds,
        right: playerRef?.current?.getBoundingClientRect().width! + deltaRight + 3,
        bottom: playerRef?.current?.getBoundingClientRect().height! + (deltaBottom + 1), // + deltabottom ,
        top: 0 - deltaTop,
        left: 0 - deltaLeft, //
      });
    } else {
      // reset the bounds to normal if not a cropped image
      setBounds({
        top: 0,
        bottom: playerRef?.current?.getBoundingClientRect().height,
        left: 0,
        right: playerRef?.current?.getBoundingClientRect().width,
      });
    }
  }, [nodeToUpdate, target, isResizing, isCropping, isRotating]);

  const evaluateZindex = () => {
    if (
      _.isEmpty(
        _.filter(pageManifest.annotations, (annotation) => {
          return annotation.zIndex < 113;
        }),
      )
    ) {
      pageManifest.annotations = handleAnnotationZ(annotationsState);
    }
  };

  function updateIsDisplayed(value: boolean): void {
    const pm = _.cloneDeep(pageContext.pageManifest);
    const nodeToUpdate: any = elementType === "video" ? pm.video : pm[elementType][editableIndex];
    nodeToUpdate.isDisplayed = value;
    pageContext.updatePageManifest(pm);
    updateAttributes(nodeToUpdate);
    setIsDisplayed(value);
  }

  function updateMaintainRatio(value: boolean): void {
    pageManifest.pageImage[editableIndex].maintainRatio = value;
    pageContext.updatePageManifest(pageManifest);
    nodeToUpdate.maintainRatio = value;
    updateAttributes(nodeToUpdate);
  }

  const selectTarget = async (
    target: HTMLDivElement | SVGSVGElement | HTMLVideoElement,
    index: number,
    type: ElementTypes,
  ) => {
    if (!isDragging && !isResizing && !isRotating && target && pageManifest[type]) {
      const selected = new CustomPageElement(pageManifest[type] as any, "");
      const editableNode = type === "video" ? pageManifest.video : selected.getNodeToEdit(index, type);
      if ((editableNode as any)?.isDisplayed || (editableNode as any)?.isDisplayed === false) {
        setIsDisplayed((editableNode as any)?.isDisplayed ?? true);
        setIsDisplayedTabShow(true);
      } else {
        setIsDisplayedTabShow(false);
      }
      // if(type === "pageImage") editableNode = await transformImageHandler(target, index);
      if (type === "symbols") {
        selected.getSelectedSymbolHeight(target as SVGSVGElement, pageDims as DOMRect);
      }

      setPreviousIndex(editableIndex);
      setEditableIndex(index);
      setNodeToUpdate(editableNode);
      getDirections(target);
      setElementType(type);
      setTarget(target);
      pbFunctions.createPropertyBox(editableNode, pageDims as DOMRect, setPropertyBox);
      setIsPropertyBoxShown(true);
      showAccurateMeasurementsForImages();
      shouldMaintainRatio();
    }
  };

  const showAccurateMeasurementsForImages = () => {
    if (!target || !pageDims || elementType !== "pageImage" || !nodeToUpdate) return;
    const pageImage = pageManifest.pageImage[editableIndex];
    if (pageImage && pageImage.rawData) {
      const { pixelHeight, pixelWidth, pixelTop, pixelLeft } = pageImage.rawData;
      propertyBox.height = pixelHeight;
      propertyBox.width = pixelWidth;
      propertyBox.left = pixelLeft;
      propertyBox.top = pixelTop;
    }
    if (typeof nodeToUpdate.transform?.rotate !== "undefined" && nodeToUpdate.transform?.rotate !== 0) {
      const { top, left } = (target as HTMLElement).getBoundingClientRect();
      propertyBox.left = left - pageDims.left;
      propertyBox.top = top - pageDims.top;
    }

    updatePropertyBox(propertyBox);
  };

  useEffect(() => {
    showAccurateMeasurementsForImages();
  }, [target, elementType]);

  const getDirections = (target: HTMLElement | SVGSVGElement) => {
    const name: string | null = target.getAttribute("name");

    if (
      name === "arrow" ||
      name === "lineArrow" ||
      name === "solidLine" ||
      name === "dashedLine" ||
      _.startsWith(target.id, "label")
    ) {
      setDirections(["e", "w"]);
    } else {
      setDirections(["nw", "n", "ne", "w", "e", "sw", "s", "se"]);
    }
  };

  async function transformImageHandler(target: HTMLElement | SVGSVGElement, index: number) {
    target.style.transform = `rotate(0deg)`;
    const image: HTMLImageElement = document.querySelector(`#ff-image-${index}`) as HTMLImageElement;
    const { height, width, top, left } = image.getBoundingClientRect();
    const scale: number[] | undefined = pageManifest.pageImage[index]?.transform?.scale;
    const rotate: number | undefined = pageManifest.pageImage[index]?.transform?.rotate;
    const scaleString: string = scale ? `scale(${scale[0]}, ${scale[1]})` : "";
    const rotateString: string = rotate
      ? `rotate(${pageManifest.pageImage[index].transform?.rotate}deg)`
      : "rotate(0deg)";
    const tempAttributes = {
      ...pageManifest.pageImage[index],
      height: (height / (pageDims?.height as number)) * 100,
      width: (width / (pageDims?.width as number)) * 100,
      top: ((top - (pageDims?.top as number)) / (pageDims?.height as number)) * 100,
      left: ((left - (pageDims?.left as number)) / (pageDims?.width as number)) * 100,
      transform: { rotate: 0 },
    };
    if (tempAttributes.height < 1) {
      tempAttributes.height = 1;
    }
    if (tempAttributes.width < 1) {
      tempAttributes.width = 1;
    }
    if (tempAttributes.top < 0) {
      tempAttributes.top = 0;
    }
    if (tempAttributes.left < 0) {
      tempAttributes.left = 0;
    }
    target.style.transform = `${rotateString} ${scaleString}`;
    tempAttributes.natural = {
      height: image.naturalHeight,
      width: image.naturalWidth,
    };

    // update the moveable rect after adding image
    target.style.height = `${tempAttributes.height}%`;
    target.style.width = `${tempAttributes.width}%`;
    image.style.width = "100%";
    moveRef?.current?.updateRect();
    updatePropertyBoxonImageAdd(image);
    return tempAttributes;
  }

  const updatePropertyBoxonImageAdd = (image: HTMLImageElement) => {
    if (!pageDims || !target || elementType !== "pageImage") return;
    const { width, height, top: newTop, left: newLeft } = image.getBoundingClientRect();
    propertyBox.width = width;
    propertyBox.height = height;
    propertyBox.left = newLeft - pageDims.left;
    propertyBox.top = newTop - pageDims.top;
    updatePropertyBox(propertyBox);
  };

  const newHandleImageDivClick = (index: number) => {
    if (isCropping) return;

    return handleImageDivClick(index);
  };

  const displayVideo = () => {
    if (!videos[0]) {
      return null;
    }
    const video = videos[0] as IBasePageVideoV2;
    const ffProps: VideoPropType = {
      ffElement: video as IBasePageVideoV2,
      target: target,
      handleKeyPress: pbFunctions.handleKeyPress,
      handleVideoClick: clickToAddVideo,
      blobUrl: video?.path as string,
      loadCustomPageVideo,
    };
    return <BaseFFVideo {...ffProps} />;
  };

  const displaySCORM = () => {
    const scorms = objectList.filter((o) => {
      return o.type === "SCORM";
    });
    if (scorms.length > 0) {
      return (scorms as IBasicPageScormV2[]).map((scormObj, index) => {
        const ffProps: SCORMPropType = {
          ffElement: scormObj as IBasicPageScormV2,
          index: index,
          kp: keyPressHandlers,
          target: target,
          handleKeyPress: pbFunctions.handleKeyPress,
          handleSCORMClick: clickToAddSCORM,
          selectTarget: (target: HTMLDivElement, index: number, val) => selectTarget(target, index, val),
          blobPath: scormObj?.blobPath as string,
          assetVersionId: scormObj?.assetVersionId,
          scormRef: scormRef,
          loadSCORMObject,
        };
        return <BaseFFSCORM {...ffProps} key={scormObj?.objectId} />;
      });
    }
    return null;
  };

  async function loadImage(e: React.SyntheticEvent<HTMLImageElement>, index: number, objectId: string) {
    if (editableIndex === index) {
      const naturals: { height: number; width: number } = {
        height: e.currentTarget.naturalHeight,
        width: e.currentTarget.naturalWidth,
      };

      if (!_.isEqual(pageManifest.pageImage[editableIndex].natural, naturals)) {
        const attributes = await transformImageHandler(target, index);
        setNodeToUpdate(attributes);
        pageManifest.pageImage[editableIndex] = attributes;
      }
    }
    if (!pageManifest.pageImage[index].version || Number(pageManifest.pageImage[index].version) < 3) {
      //TODO  dispatch({type: "UPDATE_IMAGE_RAW_DATA", payload: {}})
      updateImage(e.currentTarget, index, objectId);
    }

    if (!pageManifest.pageImage[index].version || Number(pageManifest.pageImage[index].version) < 4) {
      updateRawDataFromPercentage(index!);
    }
  }

  // Updates data for all images on the page on page load in the following scenarios:
  // 1. pageImage.version doesn't exist
  // 2. pageImage.version is less than 3
  // UPDATE: CLAS-5249 - this function has been refactored to use the new Object Dispatch actions
  function updateImage(element: HTMLElement, index: number, objectId: string, fromEndActions?: boolean) {
    if (element) {
      if (!fromEndActions) {
        element.style.width = "";
        element.style.height = "";
      }

      if (pageManifest) {
        // Find the image that is being updated
        const image = objectList.find((object) => object.objectId === objectId) as IBasicPageImageV2;
        const imageContainer = document.getElementById("image-target-" + index);
        const computedStyle = window.getComputedStyle(imageContainer!);
        const matrix = new DOMMatrixReadOnly(computedStyle.transform);
        const translateX = matrix.m41 + "px";
        const translateY = matrix.m42 + "px";

        imageContainer!.style.transform = `translate(${translateX}, ${translateY})`;
        const imageRect = element.getBoundingClientRect();

        const imagePixelTopVsPlayerAreaPixelTop =
          target && target.style?.top.includes("px")
            ? parseFloat(target.style.top)
            : imageRect.top - pageManifest.designerEnvironmentData.pagePlayerArea!.pixelTop;

        const imagePixelLeftVsPlayerAreaPixelLeft =
          target && target.style?.left.includes("px")
            ? parseFloat(target.style.left)
            : imageRect.left - pageManifest.designerEnvironmentData.pagePlayerArea!.pixelLeft;

        const imageHeight =
          target && target.style?.height.includes("px") ? parseFloat(target.style.height) : imageRect.height;

        const imageWidth =
          target && target.style?.width.includes("px") ? parseFloat(target.style.width) : imageRect.width;

        let version = image.version;

        if (!image.version || Number(image.version) <= 3) {
          version = "3";
        }

        if (fromEndActions && Number(image.version) < 4) {
          version = "4";
        }

        objectsDispatch({
          type: ObjectActionsType.UPDATE_OBJECT,
          payload: {
            objectId: objectId,
            object: {
              width: Number((imageWidth / pageManifest.designerEnvironmentData.pagePlayerArea!.pixelWidth) * 100),
              height: Number((imageHeight / pageManifest.designerEnvironmentData.pagePlayerArea!.pixelHeight) * 100),
              top: Number(
                (imagePixelTopVsPlayerAreaPixelTop / pageManifest.designerEnvironmentData.pagePlayerArea!.pixelHeight) *
                  100,
              ),
              left: Number(
                (imagePixelLeftVsPlayerAreaPixelLeft /
                  pageManifest.designerEnvironmentData.pagePlayerArea!.pixelWidth) *
                  100,
              ),
              rawData: {
                ...image.rawData,
                pixelHeight: imageHeight,
                pixelWidth: imageWidth,
                pixelTop: imagePixelTopVsPlayerAreaPixelTop,
                pixelLeft: imagePixelLeftVsPlayerAreaPixelLeft,
              },
              version: version,
            },
          },
        });

        objectsDispatch({
          type: ObjectActionsType.UPDATE_IMAGE_ASSET,
          payload: {
            objectId: objectId,
            imagePath: image.imagePath,
            assetVersionId: image.assetVersionId,
          },
        });
      }
    }
  }

  // this function runs as a part of CLAS-4118 update where, in the interim period between CLAS-3714 and CLAS-3929,
  // modifying and image's position of dimensions with the property box would not save the correct values,
  // resulting in shifting images
  const updateRawDataFromPercentage = (index: number) => {
    // dont do anything to images with no rawData.
    if (!pageManifest.pageImage[index].rawData) return;

    // these are the potentially wrong values that were saved in the interim period
    const { pixelTop, pixelLeft } = pageManifest.pageImage[index].rawData!;

    // recalculate top and left from percentages
    const percentTopToPixel: number = (pageManifest.pageImage[index].top * pageDims!.height) / 100;
    const percentLeftToPixel: number = (pageManifest.pageImage[index].left * pageDims!.width) / 100;

    // reassign top and left and update manifest
    if (pixelTop !== percentTopToPixel) {
      pageManifest.pageImage[index].rawData!.pixelTop = percentTopToPixel;
    }
    if (pixelLeft !== percentLeftToPixel) {
      pageManifest.pageImage[index].rawData!.pixelLeft = percentLeftToPixel;
    }
    if (pageManifest.pageImage[index].transform?.scale) {
      if (pageManifest.pageImage[index].transform?.scale?.some((x) => x !== 1)) {
        pageManifest.pageImage[index].maintainRatio = false;
      }
    }
    pageContext.updatePageManifest(pageManifest);
  };

  const editTextBlock = (e: React.FormEvent<HTMLDivElement>) => {
    const block: IBasicPageTextBlockV2 = _.cloneDeep(pageManifest.textBlock[editableIndex]);
    const str: any = e;
    block.text = str;
    pageManifest.textBlock[editableIndex] = _.cloneDeep(block);
    pageContext.updatePageManifest(pageManifest);
  };

  const editTableCellText = (props: any) => {
    // let newState = { ...tableData };
    // pageManifest.textBlock[editableIndex] = _.cloneDeep(block);
    pageContext.updatePageManifest(pageManifest);
  };

  const zIndexShifter = (pageManifest: any, objectIdToMove: string, zIndexToGoTo: "back" | "front" | number) => {
    // collect all the objects in the page manifest
    const zArray: Z[] = [];
    let counter = 0;
    for (const [key, value] of Object.entries(pageManifest)) {
      if (value) {
        if (key === "pageImage") {
          if (Array.isArray(value))
            value.forEach((image: any) => {
              zArray.push({ objectId: image.objectId, zIndex: image.zIndex });
            });
        } else if (key === "pageScormObject" && value) {
          if (Array.isArray(value))
            value.forEach((scorm: any) => {
              zArray.push({ objectId: scorm.objectId, zIndex: scorm.zIndex });
            });
        } else if (key === "textBlock") {
          if (Array.isArray(value))
            value.forEach((text: any) => {
              zArray.push({ objectId: text.objectId, zIndex: text.zIndex });
            });
        } else if (key === "symbols") {
          if (Array.isArray(value))
            value.forEach((symbol: any) => {
              zArray.push({ objectId: symbol.objectId, zIndex: symbol.zIndex });
            });
        } else if (key === "tables") {
          if (Array.isArray(value))
            // eslint-disable-next-line no-loop-func
            value.forEach((table: any) => {
              zArray.push({ objectId: table.objectId, zIndex: table.zIndex });
            });
        } else if (key === "annotations") {
          if (Array.isArray(value))
            value.forEach((annotation: any) => {
              zArray.push({
                objectId: annotation.objectId,
                zIndex: annotation.zIndex,
              });
            });
        }
      }
    }

    function findObjectAtDestination(list: Z[], desiredValue: number) {
      let closestObject = list[0];
      for (let i = 1; i < list.length; i++) {
        if (Math.abs(list[i].zIndex - desiredValue) < Math.abs(closestObject.zIndex - desiredValue)) {
          closestObject = list[i];
        }
      }
      return closestObject;
    }

    // sort the array by zIndex
    zArray.sort((a, b) => {
      if (a.zIndex === undefined) {
        a.zIndex = 225 - counter;
        counter++;
      }
      if (b.zIndex === undefined) {
        b.zIndex = 225 - counter;
        counter++;
      }
      return a.zIndex - b.zIndex;
    });

    if (zIndexToGoTo === "back") {
      zIndexToGoTo = zArray[0].zIndex;
    } else if (zIndexToGoTo === "front") {
      zIndexToGoTo = zArray[zArray.length - 1].zIndex;
    }

    zArray.forEach((z: Z, index: number) => {
      z.zIndex = index + 12;
    });
    // pull out the object to move
    const objectThatIsMoving = zArray.find((z: Z) => z.objectId === objectIdToMove);
    const objectThatIsAtDestination = findObjectAtDestination(zArray, zIndexToGoTo);
    const oldIndexOfObjectThatIsMoving = zArray.indexOf(objectThatIsMoving!);
    const oldIndexOfObjectThatIsAtDestination = zArray.indexOf(objectThatIsAtDestination!);
    // remove the object that should move
    zArray.splice(oldIndexOfObjectThatIsMoving, 1);
    // insert the object that should move at the new index
    zArray.splice(oldIndexOfObjectThatIsAtDestination, 0, objectThatIsMoving!);

    if (!objectThatIsAtDestination) {
      throw new Error("object to move not found");
    }
    zArray.forEach((z: Z, index: number) => {
      z.zIndex = index + 12;
    });
    return zArray;
  };

  const addNewTextBox = () => {
    const objectId = `txt${nanoid()}`;
    const newTextBox = {
      text: `<p class="standard-blot-block"><span style="font-size: 1em;">Text Box ${textBoxes.length + 1}</span></p>`,
      version: CURRENT_TEXTBOX_VERSION,
      lineHeight: "normal",
      width: 15,
      height: 20,
      left: 10 + textBoxes.length,
      top: 10 + textBoxes.length,
      transform: {
        rotate: 0,
        scaleX: 1,
        scaleY: 1,
        matrix3d: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      },
      translate: [0, 0],
      fontColor: "ivory",
      fontStyle: "",
      fontWeight: "",
      textDecoration: "",
      backgroundColor: "",
      borderColor: "",
      shadowColor: "",
      objectId,
      type: "textBlock",
      zIndex: objectsState.objectList.length + 1,
      isDisplayed: true,
      displayName: `text-${textBoxes.length + 1}`,
    };
    hotspotsDispatch({
      type: InteractivityHotspotActionTypes.SET_CURRENT_HOTSPOT,
      payload: null,
    });
    objectsDispatch({
      type: ObjectActionsType.ADD_NEW_OBJECT,
      object: newTextBox,
    });
  };

  const addNewImageBox = async () => {
    const objectId = `img${nanoid()}`;
    const image = {
      imagePath: "cpat/generic/images/13/13/FED878B1C46AEA11817D60F26251769D/cpatbrandedairplane.png",
      assetVersionId: 13,
      type: "pageImage",
      name: `image-${images.length + 1}`,
      displayName: `image-${images.length + 1}`,
      width: 15,
      height: 15,
      left: 2 + images.length,
      top: 2 + images.length,
      zIndex: objectsState.objectList.length + 1,
      objectId: objectId,
      isDisplayed: true,
      lockAspectRatio: true,
      visible: true,
      rotate: 0,
      transform: {
        rotate: 0,
      },
      rawData: {
        pixelHeight: 0.15 * pageManifest.designerEnvironmentData?.pagePlayerArea.pixelHeight,
        pixelWidth: 0.15 * pageManifest.designerEnvironmentData?.pagePlayerArea.pixelWidth,
        pixelTop: (2 + images.length / 100) * pageManifest.designerEnvironmentData?.pagePlayerArea.pixelTop,
        pixelLeft: (2 + images.length / 100) * pageManifest.designerEnvironmentData?.pagePlayerArea.pixelLeft,
      },
      version: 3,
    };
    hotspotsDispatch({
      type: InteractivityHotspotActionTypes.SET_CURRENT_HOTSPOT,
      payload: null,
    });
    objectsDispatch({
      type: ObjectActionsType.ADD_NEW_OBJECT,
      object: image,
    });
  };

  const addNewSCORM = () => {
    if (scormObject) {
      // over limit don't add
      return;
    }
    hotspotsDispatch({
      type: InteractivityHotspotActionTypes.SET_CURRENT_HOTSPOT,
      payload: null,
    });
    const id = `srm${nanoid()}`;

    const scorm = {
      blobPath: "",
      assetVersionId: 0,
      displayName: "interactive-graphic-scorm",
      width: 60,
      height: 60,
      left: 0,
      top: 0,
      zIndex: objectsState.objectList.length + 1,
      objectId: id,
      isDisplayed: true,
      type: "SCORM",
      lockAspectRatio: true,
    };

    objectsDispatch({
      type: ObjectActionsType.ADD_NEW_OBJECT,
      object: scorm,
    });
  };

  const addNewSmartObject = () => {
    const objectId = `smart-${nanoid()}`;

    const smartObject = {
      ...DEFAULT_SMART_OBJECT,
      objectId,
      displayName: `smart ${objectList.length + 1}`,
      zIndex: objectList.length + 1,
    };

    objectsDispatch({
      type: ObjectActionsType.ADD_NEW_OBJECT,
      object: smartObject,
    });
  };

  const addNewVideo = () => {
    const id = `vid${nanoid()}`;
    const newVideo = {
      path: "",
      displayName: `video ${videos.length + 1}`,
      assetVersionId: 0,
      height: 40,
      width: 40,
      top: 0,
      left: 0,
      zIndex: objectsState.objectList.length + 1,
      type: "video",
      objectId: id,
      lockAspectRatio: true,
      isDisplayed: true,
    };
    hotspotsDispatch({
      type: InteractivityHotspotActionTypes.SET_CURRENT_HOTSPOT,
      payload: null,
    });
    objectsDispatch({
      type: ObjectActionsType.ADD_NEW_OBJECT,
      object: newVideo,
    });
  };

  const addNewAnnotation = (type: string, addFunction: any) => {
    let width = 1;
    let height = 10;
    if (pageContext.pageManifest.basePageType === "freeForm") {
      width = 5.8;
      height = 10;
    }

    const newAnnotation = {
      objectId: `ann${nanoid()}`,
      type,
      width: width,
      height: height,
      left: 2 + annotations.length,
      top: 2 + annotations.length,
      zIndex: objectList.length + 1,
      isDisplayed: true,
      displayName: `${type} ${annotations.length + 1}`,
      lockAspectRatio: symbolTypes.has(type) || fontAnnotation.has(type) ? true : false,
      name: `${type}-${annotations.length}`,
    };

    if (symbolTypes.has(type)) {
      newAnnotation.width = 10;
      newAnnotation.height = 10;
      newAnnotation.backgroundColor = "transparent";
      newAnnotation.borderColor = "white";
    }

    switch (type) {
      case "label": {
        newAnnotation.text = "";
        newAnnotation.fontColor = "white";
        newAnnotation.width = 17;
        newAnnotation.height = 5;
        break;
      }
      case "dashedLine":
      case "arrow":
      case "line":
      case "solidLine":
      case "lineArrow": {
        if (viewportDOMElementHeight) {
          newAnnotation.height = (100 * 36) / viewportDOMElementHeight;
        }
        break;
      }
      case "fillArrow": {
        newAnnotation.width = 10;
        newAnnotation.height = 3;
        break;
      }
      case "circledNumber":
      case "circledLetter":
      case "circleLetter":
      case "squareNumber":
      case "squareLetter": {
        // newAnnotation.width = 10;
        // newAnnotation.height = 10;
        newAnnotation.fontColor = "white";
        newAnnotation.backgroundColor = "#4B4F58";
        newAnnotation.borderColor = "white";
        break;
      }
      case "circle":
      case "triangle":
      case "roundedSquare":
      case "square": {
        newAnnotation.width = 10;
        newAnnotation.height = 10 * (4 / 3);
        newAnnotation.backgroundColor = "transparent";
        newAnnotation.borderColor = "white";
        break;
      }
      case "freeFormPoly": {
        newAnnotation.width = 10;
        newAnnotation.height = 10 * (4 / 3);
        newAnnotation.backgroundColor = "transparent";
        newAnnotation.borderColor = "white";
        /**
         * nanoid is required for updating each point
         */
        newAnnotation.points = [
          { id: nanoid(), x: 5, y: 40 },
          { id: nanoid(), x: 60, y: 5 },
          { id: nanoid(), x: 115, y: 40 },
          { id: nanoid(), x: 100, y: 88 },
          { id: nanoid(), x: 20, y: 88 },
        ];
        break;
      }
    }
    // const annotation: IAnnotation = annotationsToAdd[addFunction](type, annotationsState);

    hotspotsDispatch({
      type: InteractivityHotspotActionTypes.SET_CURRENT_HOTSPOT,
      payload: null,
    });
    objectsDispatch({
      type: ObjectActionsType.ADD_NEW_OBJECT,
      object: newAnnotation,
    });
  };

  const addSymbol = async (name: string) => {
    if (!_.has(pageManifest, symbols)) {
      pageManifest.symbols = [];
    }
    let incrementor = pageContext.getObjectIncrementor();

    const objectList = new CustomPageObjectList(pageManifest, incrementor);
    const fullList = objectList.fullList;
    const symbol = new ClassSymbol(editableIndex, bounds, pageManifest.symbols, name, fullList, incrementor);
    const newSymbol = symbol.add();
    pageManifest.symbols.push(newSymbol);
    const lastIndex: number = pageManifest.symbols.length - 1;
    setElementType(symbols);
    setEditableIndex(lastIndex);
    setNodeToUpdate(newSymbol);
    pageManifest.objectIncrementor = ++incrementor;
    pageContext.updatePageManifest(pageManifest);
  };

  const addNewPanoramic = () => {
    if (panoramicList?.length >= 1) {
      return;
    }
    const panoramic = {
      ...DEFAULT_PANORAMIC,
      objectId: `pan${nanoid()}`,
      displayName: `panoramic ${objectList.length + 1}`,
      zIndex: objectList.length + 1,
    };
    objectsDispatch({
      type: ObjectActionsType.ADD_NEW_OBJECT,
      object: panoramic,
    });
  };

  function removeSelected() {
    const selectedObject = selectedObjects[0];
    let newCount = 0;
    if (!_.isArray(pageManifest[elementType])) {
      delete pageManifest.video;
    } else {
      pageManifest[elementType as "annotations" | "pageImage" | "textBlock" | "symbols" | "tables"].splice(
        editableIndex,
        1,
      );
    }

    if (elementType === "pageImage" || elementType === "textBlock" || elementType === "tables") {
      newCount = majorElementsCount - 1;
      setMajorElementsCount(newCount);
    }

    if (selectedObject.type === "smartObject") {
      /**
       * Might be useful for different kind of objects in the
       * future
       */
      clearObjectMetaVariables(selectedObject.objectId);
    }

    // inside the interactivity remove all instances of the element in the structure.
    // we do this in a microtask because updating the page manifest in te sane call will cause issues with stale page manifest data \0/
    setTimeout(() => {
      // interactivityBuilderState.interactivityBuilderInstance?.deleteTaskFromTargetId(deletedObjects[0].id.toString());
      interactivityBuilderState?.interactivityBuilderInstance?.clearId(selectedObjects[0].objectId);
      const newTree = interactivityBuilderState?.interactivityBuilderInstance?.printTree();
      lessonPagesDispatch({
        type: LessonPagesActions.UPDATE_CURRENT_PAGE_MANIFEST,
        payload: {
          interactivity: newTree,
        },
      });
    }, 10);
    objectsDispatch({ type: ObjectActionsType.DELETE_OBJECT, objectId: selectedObjects[0].objectId });
    setNodeToUpdate({});
    setEditableIndex(-1);
    setPreviousIndex(-1);
    setTarget(null);
    setShowDeleteConfirm(false);
    setElementType("annotations");
    setIsPropertyBoxShown(false);
    pageContext.updatePageManifest(pageManifest);
  }

  function removeHotspot() {
    const action =
      interacitvityHotspotState.currentlySelectedHotspot?.interactivityBuilderAction?.level.destroyHotspot();
    if (!action) return;
    hotspotsDispatch({
      type: REMOVE_VISIBLE_HOTSPOT,
      hotspot: action,
    });
    const newTree = action.level.root.printTree();
    lessonPagesDispatch({
      type: LessonPagesActions.UPDATE_CURRENT_PAGE_MANIFEST,
      payload: {
        interactivity: newTree,
      },
    });
    setShowDeleteConfirm(false);
    setIsPropertyBoxShown(false);
  }

  function removeObjectDispatch(hotspotIsSelected: boolean) {
    if (hotspotIsSelected) {
      return removeHotspot;
    } else return removeSelected;
  }
  type Z = {
    objectId: string;
    zIndex: number;
  };

  const sendToBack = () => {
    const pm = _.cloneDeep(pageManifest);
    const objectId = nodeToUpdate?.objectId ?? selectedObjectState.objectId;
    if (!objectId) return;
    const zIndexes = zIndexShifter(pm, objectId, "back");

    runOnObjects(pm, "all", (object: any) => {
      object.zIndex = zIndexes.find((z: Z) => z.objectId === object.objectId)!.zIndex;
    });
    if (nodeToUpdate || nodeToUpdate?.zIndex) {
      const objectList = getObjectsFromManifest(pm, "all");
      nodeToUpdate.zIndex = objectList.find((x: any) => x.objectId === nodeToUpdate.objectId)!.zIndex;
      const temp = pbFunctions.changePropertyBox(nodeToUpdate, propertyBox);
      pbFunctions.createPropertyBox(temp, pageDims as DOMRect, setPropertyBox);
      setNodeToUpdate(nodeToUpdate);
    }
    pageContext.updatePageManifest(pm);
  };

  function updateZindex(value: string) {
    const zValue: number = parseInt(value);
    const objectId = nodeToUpdate?.objectId ?? selectedObjectState.objectId;
    if (!objectId) return;
    if (nodeToUpdate) {
      let newNode: IBasicPageAttributes | IAnnotation | ISymbolV2 = _.cloneDeep(nodeToUpdate);
      newNode = { ...newNode, zIndex: zValue };
      setNodeToUpdate(newNode);
    }
    const pm = _.cloneDeep(pageManifest);

    const zIndexes = zIndexShifter(pm, objectId, zValue);
    runOnObjects(pm, "all", (object: any) => {
      object.zIndex = zIndexes.find((z: Z) => z.objectId === object.objectId)!.zIndex;
    });
    const objectList = getObjectsFromManifest(pm, "all");
    const newZIndex = objectList.find((o: any) => o.objectId === objectId)?.zIndex;
    const properties: IPropertyBox = { ...propertyBox, zIndex: newZIndex };
    setPropertyBox(properties);
    pageContext.updatePageManifest(pm);
  }

  const checkCurrentElement = (e: any) => {

    if (e.target.id === "baseplayer" || e.target.id === "draggable") {
      setEditableIndex(-1);
      setNodeToUpdate(null);
      setTarget(null);
      setIsPropertyBoxShown(false);
    }
    objectsDispatch({ type: ObjectActionsType.SET_SELECTED_OBJECT, payload: null });
    lessonPagesDispatch({
      type: LessonPagesActions.UPDATE_SELECTED_PANEL,
      payload: "properties",
    });
    setMiscUI({
      type: "SET_SELECTED_MASK_ID",
      payload: "",
    });
    tablesDispatch({ action: "setSelectedTable", payload: [undefined] });
  };

  function handleFormatChange(e: React.MouseEvent<HTMLButtonElement>, funct: any, value: any, keyValue: any) {
    const newAttributes = funct(nodeToUpdate, value, keyValue);
    updateAttributes(newAttributes);
  }

  function updateAttributes(attributes: IAnnotation | IBasicPageAttributes | ISymbolStyle) {
    if (elementType !== video && _.isArray(pageManifest[elementType])) {
      (pageManifest[elementType] as any)[editableIndex] = _.cloneDeep(attributes);
    } else {
      (pageManifest.video as IBasePageVideoV2) = _.cloneDeep(attributes as IBasePageVideoV2);
    }

    // Add transform if it doesn't exist to pageImages that don't have it
    if (elementType == "pageImage" && !attributes.transform) {
      attributes.transform = {
        rotate: 0,
      };
    }

    setNodeToUpdate(attributes);
    pageContext.updatePageManifest(pageManifest);
    moveRef.current.moveable.updateRect();
  }

  function changePropertyBox(properties: IPropertyBox) {
    const temp: IPropertyBox = _.cloneDeep(properties);

    if (!_.isEqual(temp, propertyBox)) {
      setPropertyBox(temp);
    }
  }

  function shouldMaintainRatio() {
    //let boolean: boolean = false;
    switch (elementType) {
      case symbols:
        return true;
      case annotations_string:
        _.forEach(annotationName, (named) => {
          if (named === target?.getAttribute("name")) {
            return true;
          }
        });
        return false;
      case tables:
      case video:
      case scorm:
        return true;
      case pageImage:
        return !!(typeof nodeToUpdate?.maintainRatio === "undefined" || nodeToUpdate?.maintainRatio);

      default:
        return false;
    }
  }

  function loadSCORMObject(e: React.SyntheticEvent<HTMLIFrameElement>, index: number) {
    setLoadingVidOrScorm(false);
    if (elementType === "pageScormObject")
      selectTarget(e.currentTarget.parentElement as HTMLDivElement, index, elementType);
  }

  function loadCustomPageVideo(e: React.SyntheticEvent<HTMLVideoElement>, index: number) {
    setLoadingVidOrScorm(false);
    if (elementType === "video") selectTarget(e.currentTarget as HTMLVideoElement, index, elementType);
  }

  function clickToAddSCORM() {
    handleSCORMClick();
  }

  function clickToAddVideo(e: React.MouseEvent<HTMLDivElement | HTMLVideoElement>) {
    e.preventDefault();
    handleVideoClick();
  }

  function clickToAddPanoramic(e: React.MouseEvent<HTMLDivElement | HTMLVideoElement>) {
    e.preventDefault();

    lessonPagesDispatch({
      type: LessonPagesActions.UPDATE_SELECTED_PANEL,
      payload: "properties",
    });

    handle360pClick();
  }

  function determineBusy(state: any, action: any) {
    switch (action.type) {
      case "NOT_BUSY":
      default:
        return false;
      case "BUSY":
        return true;
    }
  }

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      // find current focused element

      const focusedElement = document.activeElement;
      const activeClassName = focusedElement?.className;
      if (!selectedObjects[0]) return;
      if (
        focusedElement?.nodeName === "TEXTAREA" ||
        focusedElement?.nodeName === "INPUT" ||
        activeClassName === "ql-editor"
      )
        return;

      if (
        (e.key === "Backspace" || e.key === "Delete") &&
        (typeof activeClassName !== "string" || !activeClassName?.includes("ql"))
      ) {
        setShowDeleteConfirm(true);
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [moveableRef, selectedObjects[0]]);
  if (isLoading) {
    return <></>;
  } else {
    return (
      <React.Fragment>
        <div className="contentBox">
          <div
            className="free-form"
            id="base-page-wrapper"
            onClick={(e) => {
              setIsAnnotationMenuShown(false);
            }}
          >
            <WYSIWYGToolbar
              areAnnotationsShown={pageManifest.annotations && pageManifest.annotations.length < MAX_ANNOTATIONS_COUNT}
              areMajorsDisabled={majorElementsCount < MAX_MAJOR_ELEMENTS_COUNT}
              attributes={nodeToUpdate}
              editableIndex={editableIndex}
              elementType={elementType}
              isFreeForm={pageContext.pageManifest.basePageType === "freeForm"}
              isVideoButtonShown={!_.has(pageManifest, video) && !scormObject}
              isSCORMButtonShown={!scormObject}
              showFontIcons={false}
              addNewAnnotation={addNewAnnotation}
              addNewImageBox={addNewImageBox}
              addNewTextBox={addNewTextBox}
              addNewVideo={addNewVideo}
              addNewSCORM={addNewSCORM}
              addNewSmartObject={addNewSmartObject}
              addNewPanoramic={addNewPanoramic}
              addSymbolOLD={addSymbol}
              removeElement={setShowDeleteConfirm}
              sendToBack={sendToBack}
              setOldPropertyBoxIsShown={setIsPropertyBoxShown}
              permissions={permissions}
              // updateAttributes={updateAttributes}
              handleFormatChange={handleFormatChange}
            />
            <div className="draggable-aspect-ratio-page-container" id="draggable" onMouseDown={checkCurrentElement}>
              {isPropertyBoxShown ? <ObjectPropertyBox {...keyPressHandlers.props} /> : <></>}
              {/* <ObjectPropertyBoxNew /> */}
              {/* <ObjectPropertyBoxTables updateZindex={updateZindex} /> */}
              <div
                id="pageplayerarea"
                className="page-player-area"
                ref={playerRef}
                style={
                  pageContext.pageManifest.theme
                    ? {
                        background: determinePageBG(pageContext.pageManifest.theme),
                      }
                    : {}
                }
                onMouseDown={(e) => {
                  hotspotsDispatch({
                    type: SET_CURRENT_HOTSPOT,
                    payload: null,
                  });
                  selectedObjectDispatch({
                    type: "SET_OBJECT_ID",
                    payload: null,
                  });
                }}
              >
                <CustomPageCover showLoader={loadingVidOrScorm} />
                <div
                  id="baseplayer"
                  className="baseplayer"
                  style={{
                    display: "block",
                  }}
                >
                  {miscUI.objectLocked && <PanoramicsOverlay />}
                  <InteractivityHotspots setTarget={setTarget} setEditableIndex={setEditableIndex} />
                  <Tables
                    // quillRef={quillRef}
                    // handleFormatChange gets created each render of the component: BaseFreeForm
                    handleFormatChange={handleFormatChange}
                    selectedFontSize={selectedFontSize}
                    setTarget={setTarget}
                    setEditableIndex={setEditableIndex}
                    editTableCellText={editTableCellText}
                    setNodeToUpdate={setNodeToUpdate}
                    setIsPropertyBoxShown={setIsPropertyBoxShown}
                    // version={block.version}
                  />
                  <SmartObjects handleDoubleClick={handleSmartObjectClick} />

                  <Annotations />
                  <Symbols />
                  <Images
                    images={images}
                    editableIndex={editableIndex}
                    target={target}
                    keyPressHandlers={keyPressHandlers}
                    newHandleImageDivClick={newHandleImageDivClick}
                    handleFormatChange={handleFormatChange}
                    loadImage={loadImage}
                    selectTarget={selectTarget}
                  />
                  <TextBoxes selectedFontSize={selectedFontSize} />
                  <Panoramics handleDoubleClick={clickToAddPanoramic} />
                  {displaySCORM()}
                  {displayVideo()}
                  <MaskMoveable />
                  {!objectsState.isCropping && <AbstractMoveable pageDims={pageDims} />}
                  {objectsState.isCropping && <AbstractMoveableCropping pageDims={pageDims} />}
                  <LineMoveable pageDims={pageDims} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmDelete
          show={showConfirm}
          cancel={setShowDeleteConfirm}
          confirm={removeObjectDispatch(interacitvityHotspotState.currentlySelectedHotspot !== null)}
        />
      </React.Fragment>
    );
  }
};

export default BaseFreeForm;

export function TextBoxes({ selectedFontSize }: any) {
  // const {} = useLessonPagesState();
  const { textBoxes } = useObjectsState();
  if (textBoxes.length > 0) {
    return textBoxes.map((textBox, index) => {
      const ffProp: TextPropType = {
        index: index,
        // kp: keyPressHandlers,
        // quillRef: quillRef,
        objectId: textBox.objectId,
        selectedFontSize: selectedFontSize,
        isDisplayed: textBox.isDisplayed,
        text: textBox.text,
        top: textBox.top,
        left: textBox.left,
        width: textBox.width,
        height: textBox.height,
        zIndex: textBox.zIndex,
        rotate: textBox.rotation ? textBox.rotation : textBox.transform?.rotate ?? 0,
        backgroundColor: textBox.backgroundColor,
        borderColor: textBox.borderColor,
        strokeWidth: textBox.strokeWidth,
        fontStyle: textBox.fontStyle,
        fontFamily: textBox.fontFamily,
        // below (@lineHeight) is where versioning happens.
        // older text blocks will not have the lineHeight property within its data therefore we can version it going forwards
        // the default until now 08.23.2021 was 1.5 for all text boxes
        lineHeight: textBox.lineHeight,
        version: textBox.version,
        // handleBlur: handleBlur,
        // handleFormatChange: handleFormatChange,
        // handleKeyPress: pbFunctions.handleKeyPress,
        // handleSelectionChange: handleSelectionChange,
        // // editTextBlock: editTextBlock,

        // setEditableIndex: setEditableIndex,
        // setIsAnnotationMenuShown: setIsAnnotationMenuShown,
        // setSelectedFontSize: setSelectedFontSize,
        ghost: textBox.ghost,
      };
      return <BaseFFText key={textBox.objectId} {...ffProp} />;
    });
  }
}
