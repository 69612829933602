import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Canvas } from "@react-three/fiber";
import Controls from "./riggingControls";
import "../../CPaT3d_Player/components/hotSpotEditor/hsEditor.css";
import Cabin from "../scene/cabin";
import SceneLights from "../scene/lights";
import { setCurtain, setHSList, setHsData } from "../../../store/actions/actions";
import { Boundaries, WorkshopProps } from "./types";

const WorkShop = (props: WorkshopProps) => {
  const [modelNumber, setModelNumber] = useState("");
  const [boundaries, setBoundaries] = useState<Boundaries>();
  const modelPath = props.modelPath;
  const { hsIndex, HotSpotList, isSphere, editMode, isEditing } = props;

  useEffect(() => {
    const tempModelArray = modelPath.split("/");
    const tempModelNumber = tempModelArray[tempModelArray.length - 1];
    setModelNumber(tempModelNumber);
  }, [modelPath]);

  return (
    <>
      <div style={{ position: "relative", height: "100%", width: "100%" }}>
        <Canvas>
          <SceneLights modelNumber={modelNumber} />
          <Controls
            hsIndex={hsIndex}
            boundaries={boundaries}
            isSphere={isSphere}
            editMode={editMode}
            isEditing={isEditing}
            proximityRange={HotSpotList[hsIndex].proximityRange}
            proximityOffset={HotSpotList[hsIndex].proximityOffset}
            HotSpotList={HotSpotList}
            setHsData={props.setHsData}
            positionRef={props.positionRef}
          />
          <Cabin setBoundaries={setBoundaries} callBack={() => {}} modelPath={props.modelPath} />
        </Canvas>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    stepSystemDisplay: state.Current3dPage.stepSystemDisplay,
    displayStyle: state.Current3dPage.displayStyle,
    modelPath: state.Current3dPage.model,
    condition: state.Current3dPage.condition,
    HotSpotList: state.Current3dPage.HotSpotList,
  };
};

export default connect(mapStateToProps, { setCurtain, setHSList, setHsData })(WorkShop);
