import React from "react";
import { GlobalAudioProvider } from "./GlobalAudioProvider/GlobalAudioProvider";
import { LessonDataProvider } from "./LessonDataProvider";
import { LessonLibraryProvider } from "./LessonLibrary/LessonLibraryProvider";
import { PageListProvider } from "./PageListProvider/PageListProvider";
import { MiscUIProvider } from "./MiscUI/MiscUIProvider";
import { SelectedObjectProvider } from "./SelectedObjectProvider/SelectedObjectProvider";
import { LessonPagesProvider } from "./LessonPagesProvider/LessonPagesProvider";
import { ObjectsProvider } from "./ObjectsProvider";
import { InteractivityProvider } from "../components/Interactivity/Provider/InteractivityProvider";
import { TimelineProvider } from "./TimelineProvider/TimelineProvider";
import { MovableElementsPlaneProvider } from "./MovableElementsPlaneProvider";
import { TextToSpeechProvider } from "./TextToSpeech";
import { TimerStoreProvider } from "./Timer";
import { ConfirmationProvider } from "./Confirmation";
import { AudioUploadProvider } from "./AudioUpload";

// in here we can stack providers utilized by all components
//so the main builder file does not have to appear so cluttered
const GlobalContextState = React.createContext(null);
const GlobalContextDispatch = React.createContext(null);

const GlobalProvider = ({ children }: any) => {
  //   const [state, reducer] = React.useReducer()
  return (
    <TimerStoreProvider>
      <LessonPagesProvider>
        <GlobalContextState.Provider value={null}>
          <GlobalContextDispatch.Provider value={null}>
            <TextToSpeechProvider>
              <LessonDataProvider>
                <MiscUIProvider>
                  <ConfirmationProvider>
                    <GlobalAudioProvider>
                      <TimelineProvider>
                        <InteractivityProvider>
                          <MovableElementsPlaneProvider>
                            <ObjectsProvider>
                              <SelectedObjectProvider>
                                <LessonLibraryProvider>
                                  <AudioUploadProvider>
                                    <PageListProvider>{children}</PageListProvider>
                                  </AudioUploadProvider>
                                </LessonLibraryProvider>
                              </SelectedObjectProvider>
                            </ObjectsProvider>
                          </MovableElementsPlaneProvider>
                        </InteractivityProvider>
                      </TimelineProvider>
                    </GlobalAudioProvider>
                  </ConfirmationProvider>
                </MiscUIProvider>
              </LessonDataProvider>
            </TextToSpeechProvider>
          </GlobalContextDispatch.Provider>
        </GlobalContextState.Provider>
      </LessonPagesProvider>
    </TimerStoreProvider>
  );
};

export default GlobalProvider;
