import React, { useEffect } from "react";
import { AnnotationPropType } from "../AnnotationThumbnail";

const AnnotationDashedLine = (props: AnnotationPropType) => {
  const { annotation, index, ratio } = props;
  // const svgRef: React.RefObject<SVGSVGElement> = createRef<SVGSVGElement>();

  // const [bounds, setBounds]: [{height: number, width: number}, Dispatch<SetStateAction<{height: number, width: number}>>] = useState<{height: number, width: number}>({height: 0, width: 0});
  // const [isLoading, setIsLoading]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(true);

  // useEffect(() => {
  //   function handleLoad() {
  //     setIsLoading(false);
  //   }
  //   handleLoad();
  // }, []);

  // useEffect(() => {
  //   let newBounds: {height: number, width: number} = {height: (svgRef.current?.getBoundingClientRect() as DOMRect)?.height,
  //     width: (svgRef.current?.getBoundingClientRect() as DOMRect)?.width};
  //   const makeBounds = () => {
  //     if(!isLoading)
  //       setBounds(newBounds);
  //   }

  //   makeBounds();

  //   return () => { newBounds = {height: 0, width: 0}}
  // },[isLoading])

  useEffect(() => {
    window.addEventListener("resize", checkResize);
    return () => {
      window.removeEventListener("resize", checkResize);
    };
  }, []);

  function checkResize() {
    const thisSVG = document.getElementById(`dashedLine-${index}`) as any;
    if (thisSVG) {
      thisSVG.lastChild.attributes.y1.nodeValue = `${thisSVG.clientHeight / 2}`;
      thisSVG.lastChild.attributes.y2.nodeValue = `${thisSVG.clientHeight / 2}`;
    }
  }

  const display = () => {
    const rotateDeg: number | undefined = annotation?.transform?.rotate;
    // const scaleXNum: number | undefined = annotation?.transform?.scaleX
    // const scaleYNum: number | undefined = annotation?.transform?.scaleY
    // const matrix: number[] | undefined = annotation?.transform?.matrix3d;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="https://www.w3.org/1999/xlink"
        id={`dashedLine-${index}`}
        name="dashedLine"
        className="annotation"
        height={`${(20 * ratio).toFixed(3)}`}
        version="1.1"
        // viewBox={`0 0 auto ${20 * ratio}`}
        preserveAspectRatio="xMinYMid meet"
        style={{
          top: `${annotation.top.toFixed(2)}%`,
          left: `${annotation.left.toFixed(2)}%`,
          width: `${annotation.width.toFixed(2)}%`,
          zIndex: annotation.zIndex,
          transform: `rotate(${rotateDeg?.toFixed(2) || 0}deg)`,
        }}
      >
        <line
          className="annotation-arrow-arm"
          x1="0"
          x2={`100%`}
          y1={`50%`}
          y2={`50%`}
          style={{
            fill: `${annotation.borderColor || "white"}`,
            stroke: `${annotation.borderColor || "white"}`,
            strokeLinecap: "butt",
            strokeDasharray: `${12 * ratio} ${9 * ratio}`,
            strokeWidth: `${annotation.strokeWidth * ratio || 4 * ratio}`,
          }}
        />
      </svg>
    );
  };

  return <React.Fragment>{display()}</React.Fragment>;
};

export default AnnotationDashedLine;
