import React from "react";

const AnnotationRoundedSquare = () => {
  return (
    <React.Fragment>
      <span className="icon-square-icon-rounded"></span>
    </React.Fragment>
  );
};

export default AnnotationRoundedSquare;
