import "./assets/annotation.css";
import _ from "lodash";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { IAnnotation } from "./models/IAnnotation";
import AnnotationCircledNumber from "./types/AnnotationCircledNumber";
import AnnotationLineArrow from "./types/AnnotationLineArrow";
import AnnotationLabel from "./types/AnnotationLabel";
import AnnotationSquareNumber from "./types/AnnotationSquareNumber";
import { IAttributes } from "./models/IAttributes";
import AnnotationCircledLetter from "./types/AnnotationCircledLetter";
import AnnotationCircle from "./types/AnnotationCircle";
import AnnotationDashedLine from "./types/AnnotationDashedLine";
import AnnotationLine from "./types/AnnotationLine";
import AnnotationPointArrow from "./types/AnnotationPointArrow";
import AnnotationRoundedSquare from "./types/AnnotationRoundedSquare";
import AnnotationSquare from "./types/AnnotationSquare";
import AnnotationSquareLetter from "./types/AnnotationSquareLetter";
import AnnotationTriangle from "./types/AnnotationTriangle";

export type AnnotationPropType = {
  annotation: IAttributes;
  count?: number;
  imgRect?: DOMRect;
  index: number;
  ratio: number;
};

type annotations = {
  annotations: IAnnotation[];
  largeCDU: boolean;
  imgRect: DOMRect;
  ratio: number;
};

const Annotation = (props: annotations) => {
  const { ratio } = props;

  let circleLetterCount = 0;
  let circleNumberCount = 0;
  const labelCount = 0;
  let squareLetterCount = 0;
  let squareNumberCount = 0;
  // const ratio = props.largeCDU ? .1411 : .5012;

  const displayAnnotations = () => {
    if (props.annotations && props.annotations.length >= 0) {
      return (
        // props.annotations.map((annotation: IAttributes, index: number) => {
        props.annotations.map((annotation: IAttributes, index: number) => {
          switch (annotation.type) {
            case "arrow":
            case "pointArrow":
            case "fillArrow":
              return <AnnotationLineArrow key={index} annotation={annotation} index={index} ratio={ratio} />;
            case "circle":
              return (
                <AnnotationCircle
                  key={index}
                  annotation={annotation}
                  index={index}
                  ratio={ratio}
                  imgRect={props.imgRect}
                />
              );
            case "circleLetter":
            case "circledLetter":
              circleLetterCount++;
              return (
                <AnnotationCircledLetter
                  key={index}
                  annotation={annotation}
                  count={circleLetterCount}
                  index={index}
                  ratio={ratio}
                />
              );
            case "circledNumber":
              circleNumberCount++;
              return (
                <AnnotationCircledNumber
                  annotation={annotation}
                  key={index}
                  index={index}
                  count={circleNumberCount}
                  ratio={ratio}
                />
              );
            case "dashedLine":
              return <AnnotationDashedLine key={index} annotation={annotation} index={index} ratio={ratio} />;
            case "label":
              return (
                <AnnotationLabel annotation={annotation} key={index} index={index} count={labelCount} ratio={ratio} />
              );
            case "lineArrow":
              return <AnnotationPointArrow key={index} annotation={annotation} index={index} ratio={ratio} />;
            case "roundedSquare":
              return <AnnotationRoundedSquare key={index} annotation={annotation} index={index} ratio={ratio} />;
            case "solidLine":
              return <AnnotationLine key={index} annotation={annotation} index={index} ratio={ratio} />;
            case "square":
              return <AnnotationSquare key={index} annotation={annotation} index={index} ratio={ratio} />;
            case "squareLetter":
              squareLetterCount++;
              return (
                <AnnotationSquareLetter
                  key={index}
                  annotation={annotation}
                  count={squareLetterCount}
                  index={index}
                  ratio={ratio}
                />
              );
            case "squareNumber":
              squareNumberCount++;
              return (
                <AnnotationSquareNumber
                  annotation={annotation}
                  key={index}
                  index={index}
                  count={squareNumberCount}
                  ratio={ratio}
                />
              );
            case "triangle":
              return (
                <AnnotationTriangle
                  key={index}
                  annotation={annotation}
                  index={index}
                  imgRect={props.imgRect}
                  ratio={ratio}
                />
              );
            default:
              return;
          }
        })
      );
    }
  };

  return <React.Fragment>{displayAnnotations()}</React.Fragment>;
};

export default Annotation;
