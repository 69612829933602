import React, { useEffect, useState } from "react";
import "./PageList.css";
import * as constants from "../../../../constants/pageTypes";
import { Table, HeaderCell, Cell, Column } from "rsuite-table";
import { usePageList } from "../../../../../contexts/PageListProvider/PageListProvider";

export interface PageListProps {
  PopulateList: boolean;
  LessonVersionId: number;
  SetPageVersionId: any;
  SetSelectedPage: any;
}

const PageList = (props: PageListProps) => {
  const { PopulateList, LessonVersionId, SetPageVersionId, SetSelectedPage } = props;
  const [activeRow, setActiveRow] = useState<number>(0);
  const [lessonPageList, pageListStateDispatch] = usePageList(); // PageListProvider for retrieving the lesson's page list

  useEffect(() => {
    if (PopulateList && LessonVersionId !== 0) {
      pageListStateDispatch({
        type: "GET_PAGES",
        lessonVersionId: LessonVersionId,
      });
    } else {
      resetList();
    }
  }, [LessonVersionId]);

  useEffect(() => {
    if (lessonPageList?.pageList) {
      for (const page in lessonPageList.pageList) {
        if (lessonPageList.pageList[page].pageVersionId === activeRow) {
          SetSelectedPage(lessonPageList.pageList[page]);

          return;
        }
      }
    }
  }, [activeRow]);

  function getRowClassName(rowData: any) {
    if (rowData && rowData.pageVersionId && rowData.pageVersionId === activeRow) return "lesson-box selected-full";
    else return "lesson-box";
  }

  const ImageCell = ({ rowData, ...props }: any) => {
    let thumbnailClass: string;
    switch (rowData.pagePlayerType) {
      case constants.ThreeD_PAGE:
        thumbnailClass = "thumbnail thumbnail-container-ThreeDModel";
        break;
      case constants.QUIZ_PAGE:
        thumbnailClass = "thumbnail thumbnail-container-Quiz";
        break;
      case constants.BASIC_PAGE:
        thumbnailClass = "thumbnail thumbnail-container-Base";
        break;
      case constants.FMS_PAGE:
        thumbnailClass = "thumbnail thumbnail-container-FMS";
        break;
      case constants.WALK_AROUND:
        thumbnailClass = "thumbnail thumbnail-container-Walkaround";
        break;
      case constants.CABIN:
        thumbnailClass = "thumbnail thumbnail-container-Cabin";
        break;
      default:
        thumbnailClass = "";
    }

    return (
      <Cell {...props}>
        <div className={getRowClassName(rowData)}>
          <div className={"pageBox-container"}>
            <div className={thumbnailClass}></div>
            <div style={{ marginLeft: "10px" }}>{rowData.order + 1 + ". " + rowData.name}</div>
          </div>
        </div>
      </Cell>
    );
  };

  const resetList = () => {
    pageListStateDispatch({ type: "NO_DATA" });
    setActiveRow(0);
  };

  const handleOnRowClick = (rowData: any) => {
    setActiveRow(rowData.pageVersionId);
    SetPageVersionId(rowData.pageVersionId);
  };

  const renderLoading = () => {
    return (
      <div className="spin-container">
        <div className="spin" id="loader"></div>
        <div className="spin" id="loader2"></div>
        <div className="spin" id="loader3"></div>
        <div className="spin" id="loader4"></div>
        <span id="text">LOADING...</span>
      </div>
    );
  };

  return (
    <>
      <Table
        fillHeight
        data={lessonPageList?.pageList}
        className="page-list-table"
        bordered
        hover
        loading={lessonPageList.loading}
        renderLoading={renderLoading}
        onMouseDown={(e) => e.stopPropagation()}
        rowHeight={127}
        shouldUpdateScroll={false}
        onRowClick={handleOnRowClick}
      >
        <Column flexGrow={1}>
          <HeaderCell>Page Name and Number in Lesson Designer</HeaderCell>
          <ImageCell dataKey="name" />
        </Column>
      </Table>
    </>
  );
};

export default PageList;
