import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as CopyTextIcon } from "../../../../assets/icons/copy-text.svg";
import { ReactComponent as HelpIcon } from "../../../../assets/icons/TextToSpeech/Square-Button-Help.svg";
import genericRepositoryService from "../../../../services/genericRepositoryService";
import { IGenerateAudioRequest } from "../../../../models/IGenerateAudioRequest";
import { ReactComponent as PauseIcon } from "../../../../assets/icons/NarratorTab/narrator-text-pause-icon.svg";
import { ReactComponent as PlayIcon } from "../../../../assets/icons/NarratorTab/Square-Button-Play.svg";
import { ReactComponent as PronunciationIcon } from "../../../../assets/icons/TextToSpeech/Square-Button-Pronunciation.svg";
import { ReactComponent as GeneratingAudioSpinner } from "../../../../assets/icons/HeaderIcons/loading-sequence-airplane-icon.svg";
import { ReactComponent as ArrowDown } from "../../../../assets/icons/HeaderIcons/arrow-gray-down.svg";
import blobUrlFormatHelper from "../../../blobUrlFormatHelper";
import { IPageContext, PageContext } from "../../../../routes/builderContexts";
import InputPicker from "rsuite/InputPicker";
import "../inputPicker.css";
import SpeedSlider from "../../../Slider/SpeedSlider";
import { IAutoDetectLanguageRequest } from "../../../../models/IAutoDetectLanguageRequest";
import { AbstractTooltip as Tooltip } from "../../../ToolTip/ToolTip";

type TextToSpeechProps = {
  pageManifest: any;
  index: number;
  lessonMetaData: any;
};

type DataItemType = {
  value: string;
  label: string;
  children?: Array<DataItemType>; // property value is the value of childrenKey
  groupBy?: string;
};

type ItemDataType = {
  label?: string | React.ReactNode;
  value?: string | number;
  groupBy?: string;
  parent?: ItemDataType;
  children?: ItemDataType[];
  loading?: boolean;
};

const TextToSpeech = (props: TextToSpeechProps) => {
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const [disableVoiceDropdown, setDisableVoiceDropdown] = useState<boolean>(true);
  const [show, setShow] = useState<boolean>(false);
  const [audioPlaying, setAudioPlaying] = useState<boolean>(false);
  const [audioHasPlayed, setAudioHasPlayed] = useState<boolean>(false);
  const [audioGenerated, setAudioGenerated] = useState<boolean>(false);
  const [disableModal, setDisableModal] = useState<boolean>(false);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [needsUpdatedAudio, setNeedsUpdatedAudio] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  // Checks for Existing settings in the PageManifest
  const [pageManifestHasExistingPronunciationText, setPageManifestHasExistingPronunciationText] =
    useState<boolean>(false);
  const [pageManifestHasExistingNarratorText, setPageManifestHasExistingNarratorText] = useState<boolean>(false);
  const [pageManifestHasExistingLanguageCode, setPageManifestHasExistingLanguageCode] = useState<boolean>(false);
  const [pageManifestHasExistingProsody, setPageManifestHasExistingProsody] = useState<boolean>(false);
  const [pageManifestHasExistingLanguageDirection, setPageManifestHasExistingLanguageDirection] =
    useState<boolean>(false);
  const [pageManifestHasExistingVoiceName, setPageManifestHasExistingVoiceName] = useState<boolean>(false);
  const [badSelectedLanguage, setBadSelectedLanguage] = useState<boolean>(false);

  // Checks for Existing settings in the LessonMeta.AdditionalSettings
  const [
    hasExistingPrimaryTextToSpeechLanguageCodeInAdditionalSettings,
    setHasExistingPrimaryTextToSpeechLanguageCodeInAdditionalSettings,
  ] = useState<boolean>(false);
  const [
    hasExistingAlternateTextToSpeechLanguageCodeInAdditionalSettings,
    setHasExistingAlternateTextToSpeechLanguageCodeInAdditionalSettings,
  ] = useState<boolean>(false);
  const [
    hasExistingPrimaryTextToSpeechVoiceNameInAdditionalSettings,
    setHasExistingPrimaryTextToSpeechVoiceNameInAdditionalSettings,
  ] = useState<boolean>(false);
  const [
    hasExistingAlternateTextToSpeechVoiceNameInAdditionalSettings,
    setHasExistingAlternateTextToSpeechVoiceNameInAdditionalSettings,
  ] = useState<boolean>(false);

  // Settings from the page manifest
  const [pageManifestLanguageCode, setPageManifestLanguageCode] = useState<string>("");
  const [pageManifestNarratorText, setPageManifestNarratorText] = useState<string>("");
  const [pageManifestPronunciationText, setPageManifestPronunciationText] = useState<string>("");
  const [pageManifestProsody, setPageManifestProsody] = useState<string>("");
  const [pageManifestLanguageDirection, setPageManifestLanguageDirection] = useState<string>("");
  const [pageManifestVoiceName, setPageManifestVoiceName] = useState<string>("");

  // Settings from the LessonMeta.AdditionalSettings
  const [
    primaryTextToSpeechLanguageCodeFromAdditionalSettings,
    setPrimaryTextToSpeechLanguageCodeFromAdditionalSettings,
  ] = useState<string>("");
  const [
    alternateTextToSpeechLanguageCodeFromAdditionalSettings,
    setAlternateTextToSpeechLanguageCodeFromAdditionalSettings,
  ] = useState<string>("");
  const [primaryTextToSpeechVoiceNameFromAdditionalSettings, setTextToSpeechVoiceNameFromAdditionalSettings] =
    useState<string>("");
  const [
    alternateTextToSpeechVoiceNameFromAdditionalSettings,
    setAlternateTextToSpeechVoiceNameFromAdditionalSettings,
  ] = useState<string>("");

  // User selected settings
  const [selectedLanguageCode, setSelectedLanguageCode] = useState<string>("");
  const [selectedProsodyString, setSelectedProsodyString] = useState<string>("1.00");
  const [selectedProsodyNumber, setSelectedProsodyNumber] = useState<number>(3);
  const [selectedVoiceName, setSelectedVoiceName] = useState<string>("");

  // Settings from language detection
  const [narratorTextDetectedLanguageCode, setNarratorTextDetectedLanguageCode] = useState<string>("");

  const [generatedAudioBlobPath, setGeneratedAudioBlobPath] = useState<string>("");
  const [supportedLanguages, setSupportedLanguages] = useState<DataItemType[]>([{ value: "", label: "" }]);
  const [supportedVoices, setSupportedVoices] = useState<DataItemType[]>([{ value: "", label: "" }]);

  const ttsSourceControl = useRef<HTMLAudioElement>(null);
  const errorMessage = useRef<HTMLLabelElement>(null);

  if (ttsSourceControl.current) {
    ttsSourceControl.current.onended = function () {
      setAudioPlaying(false);
      setDisableModal(false);
      setDisableVoiceDropdown(false);
    };
  }

  function handleShow() {
    resetModal();
    ReadPageManifestSettings();
    ReadLessonMetaDataSettings();
    setShow(true);
    setNeedsUpdatedAudio(true);
  }

  useEffect(() => {
    getLanguages().then(() => {});
  }, []);

  useEffect(() => {
    if (show) {
      if (narratorTextDetectedLanguageCode !== "") {
        setSelectedLanguageCode(narratorTextDetectedLanguageCode);
      }
    }
  }, [show, narratorTextDetectedLanguageCode]);

  useEffect(() => {
    if (show) {
      if (pageManifestHasExistingNarratorText) {
        setPageManifestNarratorText(props.pageManifest.Audio[props.index].NarratorText);

        detectNarratorTextLanguage(props.pageManifest.Audio[props.index].NarratorText).then(() => {});
      }

      if (!pageManifestHasExistingPronunciationText) {
        setPageManifestPronunciationText(props.pageManifest.Audio[props.index].NarratorText);
      }
    }
  }, [pageManifestHasExistingNarratorText]);

  useEffect(() => {
    if (show) {
      if (pageManifestHasExistingPronunciationText) {
        setPageManifestPronunciationText(props.pageManifest.Audio[props.index].PronunciationText);
        setNeedsUpdatedAudio(false);
        setAudioGenerated(true);
        setGeneratedAudioBlobPath(props.pageManifest.Audio[props.index].File);
      }
    }
  }, [pageManifestHasExistingPronunciationText]);

  useEffect(() => {
    if (show) {
      if (pageManifestHasExistingLanguageCode) {
        setPageManifestLanguageCode(props.pageManifest.Audio[props.index].Language);
      }
    }
  }, [pageManifestHasExistingLanguageCode]);

  useEffect(() => {
    if (show) {
      if (pageManifestHasExistingLanguageDirection) {
        setPageManifestLanguageDirection(props.pageManifest.Audio[props.index].LanguageDirection);
      } else {
        setPageManifestLanguageDirection("ltr");
      }
    }
  }, [pageManifestHasExistingLanguageDirection]);

  useEffect(() => {
    if (show) {
      if (pageManifestHasExistingProsody) {
        setPageManifestProsody(props.pageManifest.Audio[props.index].Prosody);
        setSelectedProsodyNumber(getManifestProsody(props.pageManifest.Audio[props.index].Prosody));
        setSelectedProsodyString(props.pageManifest.Audio[props.index].Prosody);
      }
    }
  }, [pageManifestHasExistingProsody]);

  useEffect(() => {
    if (show) {
      if (pageManifestHasExistingVoiceName) {
        setPageManifestVoiceName(props.pageManifest.Audio[props.index].Voice);
      }
    }
  }, [pageManifestHasExistingVoiceName]);

  useEffect(() => {
    if (show) {
      if (hasExistingPrimaryTextToSpeechLanguageCodeInAdditionalSettings) {
        setPrimaryTextToSpeechLanguageCodeFromAdditionalSettings(
          props.lessonMetaData.additionalSettings.primaryTextToSpeechLanguageCode,
        );
      }
    }
  }, [hasExistingPrimaryTextToSpeechLanguageCodeInAdditionalSettings]);

  useEffect(() => {
    if (show) {
      if (hasExistingPrimaryTextToSpeechVoiceNameInAdditionalSettings) {
        setTextToSpeechVoiceNameFromAdditionalSettings(
          props.lessonMetaData.additionalSettings.primaryTextToSpeechVoiceName,
        );
      }
    }
  }, [hasExistingPrimaryTextToSpeechVoiceNameInAdditionalSettings]);

  useEffect(() => {
    if (show) {
      if (hasExistingAlternateTextToSpeechLanguageCodeInAdditionalSettings) {
        setAlternateTextToSpeechLanguageCodeFromAdditionalSettings(
          props.lessonMetaData.additionalSettings.alternateTextToSpeechLanguageCode,
        );
      }
    }
  }, [hasExistingAlternateTextToSpeechLanguageCodeInAdditionalSettings]);

  useEffect(() => {
    if (show) {
      if (hasExistingAlternateTextToSpeechVoiceNameInAdditionalSettings) {
        setAlternateTextToSpeechVoiceNameFromAdditionalSettings(
          props.lessonMetaData.additionalSettings.alternateTextToSpeechVoiceName,
        );
      }
    }
  }, [hasExistingAlternateTextToSpeechVoiceNameInAdditionalSettings]);

  useEffect(() => {
    if (show && selectedLanguageCode !== "" && !badSelectedLanguage) {
      getVoices(selectedLanguageCode).then(() => {});
    }
  }, [show, badSelectedLanguage, selectedLanguageCode]);

  useEffect(() => {
    function getSelectedVoiceName() {
      if (selectedLanguageCode === pageManifestLanguageCode) {
        setSelectedVoiceName(pageManifestVoiceName);
      } else if (
        hasExistingAlternateTextToSpeechLanguageCodeInAdditionalSettings ||
        hasExistingPrimaryTextToSpeechLanguageCodeInAdditionalSettings
      ) {
        if (props.index <= 2) {
          if (selectedLanguageCode === primaryTextToSpeechLanguageCodeFromAdditionalSettings) {
            setSelectedVoiceName(primaryTextToSpeechVoiceNameFromAdditionalSettings);
          } else {
            setSelectedVoiceName(supportedVoices[0].value);
          }
        } else if (props.index >= 3) {
          if (selectedLanguageCode === alternateTextToSpeechLanguageCodeFromAdditionalSettings) {
            setSelectedVoiceName(alternateTextToSpeechVoiceNameFromAdditionalSettings);
          } else {
            setSelectedVoiceName(supportedVoices[0].value);
          }
        } else {
          setSelectedVoiceName(supportedVoices[0].value);
        }
      } else {
        setSelectedVoiceName(supportedVoices[0].value);
      }
      setDisableVoiceDropdown(false);
    }
    getSelectedVoiceName();
  }, [supportedVoices]);

  useEffect(() => {
    setSelectedProsodyNumber(getManifestProsody(selectedProsodyString));
  }, [selectedProsodyString]);

  useEffect(() => {
    if (!show) {
      return;
    }
  }, [show]);

  const handleAudioSource = useCallback(() => {
    if (generatedAudioBlobPath) {
      return blobUrlFormatHelper(generatedAudioBlobPath);
    }
  }, [generatedAudioBlobPath]);

  const detectNarratorTextLanguage = async (value: string) => {
    const tabIndex = getTabIndex();

    const request: IAutoDetectLanguageRequest = {
      textToDetectLanguage: value,
    };

    const response = await genericRepositoryService.detectLanguage(request);

    // Some languages can't be detected, so ignore it
    if (!response.data.detectedLanguage) {
      return;
    }

    let detectedLanguageCode = response.data.detectedLanguage;
    if (detectedLanguageCode.length > 2) {
      detectedLanguageCode = detectedLanguageCode.substr(0, 2);
    }

    // Check if detected language variant exists in the page manifest, and use that instead
    if (pageManifestHasExistingLanguageCode) {
      if (pageContext.pageManifest.Audio[tabIndex].Language.includes(detectedLanguageCode)) {
        setNarratorTextDetectedLanguageCode(pageContext.pageManifest.Audio[tabIndex].Language);
        return;
      }
    }

    switch (tabIndex) {
      case 0:
      case 1:
      case 2:
        if (hasExistingPrimaryTextToSpeechLanguageCodeInAdditionalSettings) {
          if (props.lessonMetaData.additionalSettings.primaryTextToSpeechLanguageCode.includes(detectedLanguageCode)) {
            setNarratorTextDetectedLanguageCode(
              props.lessonMetaData.additionalSettings.primaryTextToSpeechLanguageCode,
            );
            return;
          }
        }
        break;
      case 3:
      case 4:
      case 5:
        if (hasExistingAlternateTextToSpeechLanguageCodeInAdditionalSettings) {
          if (
            props.lessonMetaData.additionalSettings.alternateTextToSpeechLanguageCode.includes(detectedLanguageCode)
          ) {
            setNarratorTextDetectedLanguageCode(
              props.lessonMetaData.additionalSettings.alternateTextToSpeechLanguageCode,
            );
            return;
          }
        }
        break;
    }

    // If the detected language is English, default to English (United States)
    if (detectedLanguageCode === "en") {
      setNarratorTextDetectedLanguageCode("en-US");
      return;
    }

    // Otherwise, find the first language variant that meets the detected language, and set it
    for (const v in supportedLanguages) {
      if (supportedLanguages[v].value.includes(detectedLanguageCode)) {
        setNarratorTextDetectedLanguageCode(supportedLanguages[v].value);
        return;
      }
    }
  };

  const audioControls = async () => {
    //x if !audioPlaying AND !pronunciationChange AND audioGenerated AND audioHasPlayed, then play existing
    //x if !audioPlaying AND !pronunciationChange AND audioGenerated AND !audioHasPlayed, then load and play existing
    //x if !audioPlaying AND !pronunciationChange AND !audioGenerated, then generate audio and play it
    //x if audioPlaying, pause audio
    //x if !audioPlaying AND pronunciationChange, generate audio and play it
    if (selectedLanguageCode && selectedVoiceName && pageManifestPronunciationText && !isGenerating) {
      if (!audioPlaying && !needsUpdatedAudio && audioGenerated && audioHasPlayed) {
        playAudio();
      }

      if (!audioPlaying && !needsUpdatedAudio && audioGenerated && !audioHasPlayed) {
        loadAudio();
        playAudio();
      }

      if ((!audioPlaying && !needsUpdatedAudio && !audioGenerated) || (!audioPlaying && needsUpdatedAudio)) {
        setIsGenerating(true);

        const generated = await generateAudio();

        if (generated !== "") {
          loadAudio();
          playAudio();
        }
      }

      if (audioPlaying) {
        pauseAudio();
      }
    }
  };

  const getLanguages = async () => {
    await genericRepositoryService.getLanguages().then((result) => {
      const languages = [];
      for (const v in result.data.supportedVoiceLanguages) {
        const obj = {
          value: v,
          label: result.data.supportedVoiceLanguages[v],
        };
        languages.push(obj);
      }
      setSupportedLanguages(languages);
    });
  };

  const getVoices = async (localeCode: string) => {
    await genericRepositoryService.getVoices({ localeCode: localeCode }).then((result) => {
      const voices = [];
      for (const v in result.data.supportedVoices) {
        const obj = {
          value: v,
          label: result.data.supportedVoices[v],
        };
        voices.push(obj);
      }

      setSupportedVoices(voices);
    });
  };

  const generateAudio = async (): Promise<string> => {
    setHasError(false);
    disableEverything();
    setIsGenerating(true);

    const tabIndex = getTabIndex();
    const tab = document.getElementsByClassName("narrator-tab-name")[tabIndex];

    // This is to handle previously generated audio
    const proRate = getProsodyForAudioGeneratedPriorToPhase2();

    const request: IGenerateAudioRequest = {
      narrationText: pageManifestPronunciationText,
      language: selectedLanguageCode,
      voice: selectedVoiceName,
      displayName: getDisplayName(selectedVoiceName) as string,
      tabName: tab.innerHTML.replace(" ", "-"),
      prosodyRate: proRate,
    };

    const response = await genericRepositoryService.generateAudio(request);

    if (response.errorMessage !== null && response.errorMessage !== "") {
      setNeedsUpdatedAudio(true);
      setGeneratedAudioBlobPath("");
      setAudioGenerated(false);
      setIsGenerating(false);
      setDisableModal(false);
      setHasError(true);

      if (pageManifestLanguageCode) {
        setDisableVoiceDropdown(false);
      }

      if (errorMessage.current != null) {
        errorMessage.current.innerText = "*" + response.errorMessage;
      }

      return "";
    }

    setGeneratedAudioBlobPath(response.data.blobPath);
    setAudioGenerated(true);
    setNeedsUpdatedAudio(false);

    return response.data.blobPath;
  };

  const doesPageManifestHaveNarratorText = () => {
    if (
      props.pageManifest &&
      "Audio" in props.pageManifest &&
      props.pageManifest.Audio !== undefined &&
      props.pageManifest.Audio[props.index] &&
      props.pageManifest.Audio[props.index].NarratorText
    ) {
      setPageManifestHasExistingNarratorText(true);
    }
  };

  const doesPageManifestHavePronunciationText = () => {
    if (
      props.pageManifest &&
      "Audio" in props.pageManifest &&
      props.pageManifest.Audio !== undefined &&
      props.pageManifest.Audio[props.index] &&
      props.pageManifest.Audio[props.index].PronunciationText
    ) {
      setPageManifestHasExistingPronunciationText(true);
    }
  };

  const doesPageManifestHaveLanguageCode = () => {
    if (
      props.pageManifest &&
      "Audio" in props.pageManifest &&
      props.pageManifest.Audio !== undefined &&
      props.pageManifest.Audio[props.index] &&
      props.pageManifest.Audio[props.index].Language
    ) {
      setPageManifestHasExistingLanguageCode(true);
    }
  };

  const doesPageManifestHaveLanguageDirection = () => {
    if (
      props.pageManifest &&
      "Audio" in props.pageManifest &&
      props.pageManifest.Audio !== undefined &&
      props.pageManifest.Audio[props.index] &&
      props.pageManifest.Audio[props.index].LanguageDirection
    ) {
      setPageManifestHasExistingLanguageDirection(true);
    }
  };

  const doesPageManifestHaveProsody = () => {
    if (
      props.pageManifest &&
      "Audio" in props.pageManifest &&
      props.pageManifest.Audio !== undefined &&
      props.pageManifest.Audio[props.index] &&
      props.pageManifest.Audio[props.index].Prosody
    ) {
      setPageManifestHasExistingProsody(true);
    }
  };

  const doesPageManifestHaveVoiceName = () => {
    if (
      props.pageManifest &&
      "Audio" in props.pageManifest &&
      props.pageManifest.Audio !== undefined &&
      props.pageManifest.Audio[props.index] &&
      props.pageManifest.Audio[props.index].Voice
    ) {
      setPageManifestHasExistingVoiceName(true);
    }
  };

  const doesPrimaryTextToSpeechLanguageCodeExistInAdditionalSettings = () => {
    if (props.lessonMetaData.additionalSettings.primaryTextToSpeechLanguageCode) {
      setHasExistingPrimaryTextToSpeechLanguageCodeInAdditionalSettings(true);
    }
  };

  const doesPrimaryTextToSpeechVoiceNameExistInAdditionalSettings = () => {
    if (props.lessonMetaData.additionalSettings.primaryTextToSpeechVoiceName) {
      setHasExistingPrimaryTextToSpeechVoiceNameInAdditionalSettings(true);
    }
  };

  const doesAlternateTextToSpeechLanguageCodeExistInAdditionalSettings = () => {
    if (props.lessonMetaData.additionalSettings.alternateTextToSpeechLanguageCode) {
      setHasExistingAlternateTextToSpeechLanguageCodeInAdditionalSettings(true);
    }
  };

  const doesAlternateTextToSpeechVoiceNameExistInAdditionalSettings = () => {
    if (props.lessonMetaData.additionalSettings.alternateTextToSpeechVoiceName) {
      setHasExistingAlternateTextToSpeechVoiceNameInAdditionalSettings(true);
    }
  };

  const myProps = {
    caretComponent: ArrowDown,
  };

  const ShowLanguageSelect = () => {
    supportedLanguages.sort((a, b) => (a.label > b.label ? 1 : -1));
    getSelectedLanguageCode();

    return (
      <InputPicker
        {...myProps}
        data={supportedLanguages}
        appearance="default"
        placeholder="Select a Language"
        value={selectedLanguageCode}
        labelKey={"label"}
        valueKey={"value"}
        disabled={disableModal}
        onChange={(e) => {
          handleLanguageChange(e as string);
        }}
        size={"sm"}
        cleanable={false}
        className={"language-dropdown"}
        menuMaxHeight={150}
      />
    );
  };

  const ShowVoiceSelect = () => {
    supportedVoices.sort((a, b) => (a.label > b.label ? 1 : -1));

    return (
      <InputPicker
        {...myProps}
        data={supportedVoices}
        appearance="default"
        placeholder="Select a Voice"
        value={selectedVoiceName}
        labelKey={"label"}
        valueKey={"value"}
        disabled={disableVoiceDropdown}
        onChange={(e) => {
          handleVoiceChange(e as string);
        }}
        size={"sm"}
        cleanable={false}
        className={"language-dropdown"}
        menuMaxHeight={150}
      />
    );
  };

  const PlayPausePlaceHolder = () => {
    const SvgIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> = audioPlaying ? PauseIcon : PlayIcon;

    if (isGenerating) {
      disableEverything();
      return (
        <div className="generating-container">
          <GeneratingAudioSpinner className="generating-spinner" />
        </div>
      );
    }

    return <SvgIcon onClick={audioControls} className={"generate-audio-button-icon"} />;
  };

  const helpButton = (url: string, type: string) => {
    let SvgIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

    let buttonClass = "preview_button";
    let toolTip = "";

    switch (type) {
      case "tips":
        SvgIcon = HelpIcon;
        toolTip = "TTS Tips and Tricks";
        buttonClass = "tips_button";
        break;
      case "pronunciations":
        SvgIcon = PronunciationIcon;
        toolTip = "Pronunciations";
        buttonClass = "pronunciation_button";
        break;
      case "copy":
        SvgIcon = CopyTextIcon;
        toolTip = "Copy Text";
        buttonClass = "preview_button";
        break;
      default:
        SvgIcon = HelpIcon;
        break;
    }

    return (
      <>
        <div style={{ float: "right" }} data-tooltip-id={toolTip}>
          <button
            className={buttonClass}
            disabled={disableModal}
            onClick={() => {
              openLink(url);
            }}
          >
            <SvgIcon />
          </button>
        </div>
        <Tooltip id={toolTip} />
      </>
    );
  };

  function ReadPageManifestSettings() {
    doesPageManifestHaveNarratorText(); // 1
    doesPageManifestHavePronunciationText(); // 2
    doesPageManifestHaveLanguageCode(); // 3
    doesPageManifestHaveLanguageDirection(); // 4
    doesPageManifestHaveProsody(); // 5
    doesPageManifestHaveVoiceName(); // 6
  }

  function ReadLessonMetaDataSettings() {
    doesPrimaryTextToSpeechLanguageCodeExistInAdditionalSettings(); // 7
    doesPrimaryTextToSpeechVoiceNameExistInAdditionalSettings(); // 8
    doesAlternateTextToSpeechLanguageCodeExistInAdditionalSettings(); // 9
    doesAlternateTextToSpeechVoiceNameExistInAdditionalSettings(); // 10
  }

  // because narrator-tab-name vs props.index is weird when you have alternate audio
  function getTabIndex(): number {
    switch (props.index) {
      case 0:
      case 3:
        return 0;
      case 1:
      case 4:
        return 1;
      case 2:
      case 5:
        return 2;
    }

    return 0;
  }

  function handleClose() {
    setShow(false);
    resetModal();
  }

  function openLink(url: string) {
    if (!disableModal) {
      window.open(blobUrlFormatHelper(url), "_blank", "noopener,noreferrer");
    }
  }

  function handleProsodyRateChange(rate: number) {
    // due to how the slider works (not able to implement multiple custom steps),
    // I'm creating a way to map the slider step to an array for speech speed
    const prosodyRateArray = ["0.50", "0.66", "0.84", "1.00", "1.33", "1.67", "2.00"];

    setNeedsUpdatedAudio(true);
    setSelectedProsodyString(prosodyRateArray[rate]);
  }

  function handleLanguageChange(selectedLanguage: string) {
    if (selectedLanguage !== selectedLanguageCode) {
      if (checkSelectedLanguageExists(selectedLanguage)) {
        setSelectedVoiceName("");
        setNeedsUpdatedAudio(true);
        setSelectedLanguageCode(selectedLanguage);
        setDisableVoiceDropdown(false);
        setBadSelectedLanguage(false);
      } else {
        setSelectedLanguageCode("");
        setBadSelectedLanguage(true);
      }
    }
  }

  function handleVoiceChange(selectedVoice: string) {
    if (selectedVoice !== selectedVoiceName) {
      if (checkVoiceExists(selectedVoice)) {
        setNeedsUpdatedAudio(true);
        setSelectedVoiceName(selectedVoice);
      } else {
        setNeedsUpdatedAudio(false);
        setSelectedVoiceName("");
        setDisableVoiceDropdown(false);
      }
    }
  }

  function handlePronunciationChange(text: string) {
    setPageManifestPronunciationText(text);
    setNeedsUpdatedAudio(true);
  }

  function checkSelectedLanguageExists(languageCode: string): boolean {
    for (const v in supportedLanguages) {
      if (supportedLanguages[v].value === languageCode) return true;
    }

    setSupportedVoices([{ value: "", label: "" }]);
    return false;
  }

  function checkVoiceExists(voiceName: string): boolean {
    for (const v in supportedVoices) {
      if (supportedVoices[v].value === voiceName) return true;
    }

    setPageManifestVoiceName("");
    return false;
  }

  function getManifestProsody(prosodyString: string): number {
    switch (prosodyString) {
      case "X-Slow":
      case "Slow":
      case "0.50":
        return 0;
      case "0.66":
        return 1;
      case "0.84":
        return 2;
      case "Default":
      case "Medium":
      case "1.00":
        return 3;
      case "1.33":
        return 4;
      case "1.67":
        return 5;
      case "X-Fast":
      case "Fast":
      case "2.00":
        return 6;
      default:
        return 3;
    }
  }

  function getDisplayName(voice: string) {
    for (const v in supportedVoices) {
      if (supportedVoices[v].value === voice) {
        return supportedVoices[v].label;
      }
    }
  }

  function getSelectedLanguageCode() {
    if (selectedLanguageCode && !badSelectedLanguage) {
      return;
    } else if (pageManifestHasExistingLanguageCode && !badSelectedLanguage) {
      setSelectedLanguageCode(pageManifestLanguageCode);
    } else if (narratorTextDetectedLanguageCode && !badSelectedLanguage) {
      setSelectedLanguageCode(narratorTextDetectedLanguageCode);
    } else if (
      hasExistingPrimaryTextToSpeechLanguageCodeInAdditionalSettings &&
      props.index <= 2 &&
      !badSelectedLanguage
    ) {
      setSelectedLanguageCode(primaryTextToSpeechLanguageCodeFromAdditionalSettings);
    } else if (
      hasExistingAlternateTextToSpeechLanguageCodeInAdditionalSettings &&
      props.index >= 3 &&
      !badSelectedLanguage
    ) {
      setSelectedLanguageCode(alternateTextToSpeechLanguageCodeFromAdditionalSettings);
    } else {
      setSelectedLanguageCode("");
      setSelectedVoiceName("");
      setDisableVoiceDropdown(true);
    }
  }

  function showProsodySlider() {
    if (show) {
      const marks = [
        { tick: 0, mark: "Slow" },
        { tick: 3, mark: "Default" },
        { tick: 6, mark: "Fast" },
      ];

      const pro = getManifestProsody(props.pageManifest.Audio[props.index].Prosody);
      return (
        <SpeedSlider
          min={0}
          max={6}
          step={1}
          default={pro}
          class={"speed-slider"}
          labelClassName={"slider-label"}
          sliderClassName={"slider"}
          barClassName={"bar-style"}
          disabled={disableModal}
          marks={marks}
          label={"Speech Speed"}
          onChangeCommitted={handleProsodyRateChange}
          sliderHeaderClassName={"slider-header"}
        />
      );
    }
  }

  function copyNarrationToPronunciation() {
    setPageManifestPronunciationText(pageManifestNarratorText);
    setNeedsUpdatedAudio(true);
  }

  // set Modal state back to default
  function resetModal() {
    setAudioGenerated(false);
    setPageManifestProsody("1.00");
    setAudioPlaying(false);
    setAudioHasPlayed(false);
    setGeneratedAudioBlobPath("");
    setNeedsUpdatedAudio(false);
    setPageManifestVoiceName("");
    setPageManifestLanguageCode("");
    setSupportedVoices([{ value: "", label: "" }]);
    setDisableVoiceDropdown(true);
    setDisableModal(false);
    setPageManifestNarratorText("");
    setIsGenerating(false);

    setHasExistingPrimaryTextToSpeechLanguageCodeInAdditionalSettings(false);
    setHasExistingAlternateTextToSpeechLanguageCodeInAdditionalSettings(false);
    setHasExistingPrimaryTextToSpeechVoiceNameInAdditionalSettings(false);
    setHasExistingAlternateTextToSpeechVoiceNameInAdditionalSettings(false);

    setPageManifestHasExistingPronunciationText(false);
    setPageManifestHasExistingNarratorText(false);
    setPageManifestHasExistingLanguageCode(false);
    setPageManifestHasExistingProsody(false);
    setPageManifestHasExistingLanguageDirection(false);
    setPageManifestHasExistingVoiceName(false);

    setPageManifestLanguageCode("");
    setPageManifestNarratorText("");
    setPageManifestPronunciationText("");
    setPageManifestLanguageDirection("");
    setPageManifestVoiceName("");

    setPrimaryTextToSpeechLanguageCodeFromAdditionalSettings("");
    setAlternateTextToSpeechLanguageCodeFromAdditionalSettings("");
    setTextToSpeechVoiceNameFromAdditionalSettings("");
    setAlternateTextToSpeechVoiceNameFromAdditionalSettings("");

    setSelectedLanguageCode("");
    setSelectedProsodyString("1.00");
    setSelectedVoiceName("");
    setNarratorTextDetectedLanguageCode("");
  }

  function disableEverything() {
    setDisableModal(true);
    setDisableVoiceDropdown(true);
  }

  function playAudio() {
    setAudioHasPlayed(true);
    setAudioPlaying(true);
    disableEverything();
    if (ttsSourceControl.current) {
      ttsSourceControl.current.play().then(() => {});
    }
  }

  function loadAudio() {
    setAudioHasPlayed(false);
    setAudioPlaying(false);
    setIsGenerating(false);
    //setDisableModal(false);

    if (ttsSourceControl.current) {
      ttsSourceControl.current.load();
    }
  }

  function pauseAudio() {
    setAudioPlaying(false);
    setAudioHasPlayed(true);
    setDisableModal(false);
    setDisableVoiceDropdown(false);

    if (ttsSourceControl.current) {
      ttsSourceControl.current.pause();
    }
  }

  function saveGeneratedAudio() {
    // if no audio generated, generate it
    if (!audioGenerated || needsUpdatedAudio) {
      generateAudio().then((blobPath) => save(blobPath));
    } else {
      save(generatedAudioBlobPath);
    }
  }

  function save(blobPath: string) {
    const tabIndex = getTabIndex();
    const tab = document.getElementsByClassName("narrator-tab-name")[tabIndex];

    // The audio removal requires a Version to be in the manifest so that the narrator doesn't close when it is
    // removed from a mode. The 5th element in the blob path is the AssetVersionId for this
    const blobArray = blobPath.split("/");
    const assetVersionId = blobArray[4];

    if (props.index <= 2) {
      props.lessonMetaData.additionalSettings.primaryTextToSpeechLanguageCode = selectedLanguageCode;
      props.lessonMetaData.additionalSettings.primaryTextToSpeechVoiceName = selectedVoiceName;
    } else {
      props.lessonMetaData.additionalSettings.alternateTextToSpeechLanguageCode = selectedLanguageCode;
      props.lessonMetaData.additionalSettings.alternateTextToSpeechVoiceName = selectedVoiceName;
    }
    // if it's All Modes, then set all modes
    if (tab.innerHTML === "All Modes") {
      if (props.index <= 2) {
        // primary audio
        for (let i = 0; i < 3; i++) {
          props.pageManifest.Audio[i].PronunciationText = pageManifestPronunciationText;
          props.pageManifest.Audio[i].File = blobPath;
          props.pageManifest.Audio[i].Prosody = selectedProsodyString;
          props.pageManifest.Audio[i].Language = selectedLanguageCode;
          props.pageManifest.Audio[i].Voice = selectedVoiceName;
          props.pageManifest.Audio[i].Version = parseInt(assetVersionId);
        }
      } else {
        // secondary audio
        for (let i = 3; i < 6; i++) {
          props.pageManifest.Audio[i].PronunciationText = pageManifestPronunciationText;
          props.pageManifest.Audio[i].File = blobPath;
          props.pageManifest.Audio[i].Prosody = pageManifestProsody;
          props.pageManifest.Audio[i].Language = selectedLanguageCode;
          props.pageManifest.Audio[i].Voice = selectedVoiceName;
          props.pageManifest.Audio[i].Version = parseInt(assetVersionId);
        }
      }
    } else {
      // set for specific modes
      props.pageManifest.Audio[props.index].PronunciationText = pageManifestPronunciationText;
      props.pageManifest.Audio[props.index].File = blobPath;
      props.pageManifest.Audio[props.index].Prosody = pageManifestProsody;
      props.pageManifest.Audio[props.index].Language = selectedLanguageCode;
      props.pageManifest.Audio[props.index].Voice = selectedVoiceName;
      props.pageManifest.Audio[props.index].Version = parseInt(assetVersionId);
    }

    pageContext.updatePageManifest(props.pageManifest);

    handleClose();
    resetModal();
  }

  function displayError() {
    if (hasError) {
      return (
        <div className={"error-message-container"}>
          <label ref={errorMessage} className={"error-message"}></label>
        </div>
      );
    }
  }

  function getProsodyForAudioGeneratedPriorToPhase2() {
    switch (pageManifestProsody) {
      case "X-Slow":
      case "Slow":
        return "0.50";
      case "Default":
      case "Medium":
        return "1.00";
      case "X-Fast":
      case "Fast":
        return "2.00";
      default:
        return selectedProsodyString;
    }
  }

  return (
    <>
      <div className={"audio-options-selection-choices"} onClick={handleShow}>
        Text To Speech
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName={"text-to-speech-modal"}
        backdrop={"static"}
        keyboard={false}
        bsPrefix={"tts"}
      >
        <Modal.Header
          closeButton
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Modal.Title className={"text-to-speech-title"}>
            <p>Text To Speech</p>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body
          bsPrefix={"tts-body"}
          style={{
            backgroundColor: "#f5f5f5",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <label className={disableModal ? "text-area-label-disabled" : "text-area-label"}>Narration Text:</label>
          <textarea
            className={["col-sm-12", "narration-text-area", pageManifestLanguageDirection].join(" ")}
            value={pageManifestNarratorText}
            disabled={true}
          />

          <div style={{ float: "right" }} data-tooltip-id="Copy Text">
            <button className={"copy-text-button"} onClick={copyNarrationToPronunciation} disabled={disableModal}>
              <CopyTextIcon />
            </button>
          </div>
          <Tooltip id={"Copy Text"} />

          <label className={disableModal ? "text-area-label-disabled" : "text-area-label"}>Pronunciation:</label>
          <textarea
            className={["col-sm-12", "pronunciation-text-area", pageManifestLanguageDirection].join(" ")}
            disabled={disableModal}
            value={pageManifestPronunciationText}
            onChange={(e) => {
              handlePronunciationChange(e.target.value);
            }}
          />

          <div className={"text-options-container"}>
            <ShowLanguageSelect />
            <ShowVoiceSelect />
            {showProsodySlider()}

            <div>
              <button
                data-tooltip-id="Generate Audio"
                className={"generate-audio-button"}
                disabled={
                  !((pageManifestPronunciationText && selectedVoiceName && selectedLanguageCode) || isGenerating)
                }
              >
                <audio preload="auto" ref={ttsSourceControl}>
                  <source src={handleAudioSource()} type="audio/mpeg" />
                </audio>
                <PlayPausePlaceHolder />
              </button>
            </div>

            <div>
              {helpButton("cpat/generic/pdf/pronunciations.pdf", "pronunciations")}
              {helpButton("cpat/generic/pdf/tips.pdf", "tips")}
            </div>
          </div>

          {displayError()}
        </Modal.Body>

        <Modal.Footer
          style={{
            backgroundColor: "#f5f5f5",
            alignItems: "center",
            justifyContent: "flex-end",
            height: "60px",
            paddingTop: "0px",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="audio-modal-buttons" style={{}}>
            <button onClick={handleClose}>Cancel</button>
            <button
              onClick={() => saveGeneratedAudio()}
              disabled={disableModal || !(pageManifestPronunciationText && selectedVoiceName && selectedLanguageCode)}
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TextToSpeech;
