import React from "react";
import { Modal, ModalBody } from "react-bootstrap";
//import { IFmsPageManifest } from '../../../../../pageTypes/FMS_Player/Interfaces/IFmsPageManifest';
//import { IAssetManagementContext, AssetContext } from '../../../../../../routes/builderContexts';
//import lodash from "lodash";
//import AddReferenceImage from '../AddReferenceImage';
import blobHelper from "../../../../../blobUrlFormatHelper";
import WYSIWYG from "../../../../WYSIWYG";
import { modalComponentProps } from "./interfaces/FmsModalInterfaces";

const StandardFMSModal = (props: modalComponentProps) => {
  return (
    <Modal show={props.isModalShown} onHide={() => props.handleHide()} size="lg" className="fms-ref-img" centered>
      {/* <Modal show={isModalShown} onHide={() => setIsModalShown(false)} dialogClassName="fms-ref-img"> */}
      <Modal.Header>
        <span>Reference Image Annotations and Symbols</span>
        <span className="icon-remove-icon-white" onClick={() => props.setIsModalShown(false)}>
          <span className="path1"></span>
          <span className="path2"></span>
          <span className="path3"></span>
          <span className="path4"></span>
        </span>
      </Modal.Header>
      <ModalBody>
        <div className="fms-img-container">
          <div className="img-aspect-container">
            <div className="img-aspect-container-inside">
              <div className="img-aspect-centering">
                <WYSIWYG
                  isModalShown={props.isModalShown}
                  referenceBounds={props.referenceBounds}
                  referenceRect={props.pageDims}
                >
                  {/* <div className="test1"> */}
                  <div
                    id="ref-div"
                    ref={props.imageRef}
                    onLoad={(e: React.SyntheticEvent<HTMLDivElement>) => props.handleLoad(e)}
                  >
                    {/* <div > */}
                    <img src={blobHelper(props.imageSource)} style={{ maxHeight: "35em" }}></img>
                    {/* </div> */}
                  </div>
                  {/* </div> */}
                </WYSIWYG>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default StandardFMSModal;
