import React, { useContext } from "react";
//import genericRepositoryService from '../../../../services/genericRepositoryService';
import { IFmsContext, FmsContext } from "../../Interfaces/fmsContext";
import { IPageContext, PageContext } from "../../../../routes/builderContexts";
import { IFmsPageManifest } from "../../Interfaces/IFmsPageManifest";
import lodash, { over } from "lodash";
const CDUModalButtons = (props: any) => {
  const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);
  const pagesContext: IPageContext = useContext<IPageContext>(PageContext);
  const pageManifest: IFmsPageManifest = pagesContext.pageManifest as IFmsPageManifest;
  const buttonClick = (rect: any) => {
    if (rect.actions[0] && rect.actions[0].function) {
      switch (rect.actions[0].function) {
        case "openModal": {
          openModal(rect);
          break;
        }
        case "closeModal": {
          closeModal(rect);
          break;
        }
        case "changeModal": {
          changeModal(rect);
          break;
        }
        case "openPage": {
          openPage(rect);
          break;
        }
        case "changeImage": {
          changeImage(rect);
          break;
        }
        case "openDropDown": {
          toggleDropDown(rect);
          break;
        }
        default: {
          break;
        }
      }
    }
  };
  const toggleDropDown = (rect: any) => {
    //when changing dropdowns menu needs to change not just close
    if (fmsContext.dropDownOpen) {
      if (fmsContext.dropDownParent && rect.cduKeyName && rect.cduKeyName !== fmsContext.dropDownParent.cduKeyName) {
        openDropDown(rect);
      } else {
        closeDropDown();
      }
    } else {
      openDropDown(rect);
    }
  };
  const openDropDown = (rect: any) => {
    if (
      pageManifest.pageLayouts[pageManifest.bootPage].dropDownCells &&
      pageManifest.pageLayouts[pageManifest.bootPage].dropDownCells[rect.actions[0].parameter.openDropDown.dropDown]
    ) {
      const dropVals =
        pageManifest.pageLayouts[pageManifest.bootPage].dropDownCells[rect.actions[0].parameter.openDropDown.dropDown];
      fmsContext.setDropDownValues(dropVals);
      fmsContext.setDropDownParent(rect);
      fmsContext.setDropDownOpen(true);
      pagesContext.updatePageManifest(pageManifest);
    } else if (!pageManifest.pageLayouts[pageManifest.bootPage].dropDownCells) {
      const newVar = `${rect.actions[0].parameter.openDropDown.dropDown}_option_0`;
      pageManifest.pageLayouts[pageManifest.bootPage]["dropDownCells"] = {};
      pageManifest.pageLayouts[pageManifest.bootPage]["dropDownCells"][
        rect.actions[0].parameter.openDropDown.dropDown
      ] = {};
      pageManifest.pageLayouts[pageManifest.bootPage]["dropDownCells"][rect.actions[0].parameter.openDropDown.dropDown][
        newVar
      ] = "";
      const dropVals =
        pageManifest.pageLayouts[pageManifest.bootPage].dropDownCells[rect.actions[0].parameter.openDropDown.dropDown];
      fmsContext.setDropDownValues(dropVals);
      fmsContext.setDropDownParent(rect);
      fmsContext.setDropDownOpen(true);
      pagesContext.updatePageManifest(pageManifest);
    } else {
      const newVar = `${rect.actions[0].parameter.openDropDown.dropDown}_option_0`;
      pageManifest.pageLayouts[pageManifest.bootPage]["dropDownCells"][
        rect.actions[0].parameter.openDropDown.dropDown
      ] = {};
      pageManifest.pageLayouts[pageManifest.bootPage]["dropDownCells"][rect.actions[0].parameter.openDropDown.dropDown][
        newVar
      ] = "";
      const dropVals =
        pageManifest.pageLayouts[pageManifest.bootPage].dropDownCells[rect.actions[0].parameter.openDropDown.dropDown];
      fmsContext.setDropDownValues(dropVals);
      fmsContext.setDropDownParent(rect);
      fmsContext.setDropDownOpen(true);
      pagesContext.updatePageManifest(pageManifest);
    }
  };
  const closeDropDown = () => {
    fmsContext.setDropDownOpen(false);
    fmsContext.setDropDownValues({});
    fmsContext.setDropDownParent({});
  };
  const changeImage = (rect: any) => {
    // let modActIndex = 0; //defaults at index 0 but if modified for whatever reason this should find it
    // let breaker: boolean = false
    // pageManifest.pageLayouts[pageManifest.bootPage].overLays.forEach((overLay:any, index:number)=>{
    //     if(overLay.image.includes("atc.svg") || overLay.image.includes("mod.svg")){
    //         modActIndex = index;
    //     }
    // })

    // rect.actions[0].parameter.changeImage.assets.map((asset: string, index: number) =>{
    //     if(pageManifest.pageLayouts[pageManifest.bootPage].overLays[modActIndex].image !== asset && !breaker){
    //         pageManifest.pageLayouts[pageManifest.bootPage].overLays[modActIndex].image = asset;
    //         breaker = true;
    //     }
    // })

    let overlayIndex = 0;
    let assetIndex = 0;
    const assetsLength = rect.actions[0].parameter.changeImage.assets.length;
    if (props.fromModal) {
      pageManifest.pageLayouts[pageManifest.bootPage]?.modals[fmsContext.currentModal.modalName].overLays.map(
        (overLay: any, index: number) => {
          if (overLay.id === rect.id) {
            overlayIndex = index;
          }
        },
      );

      rect.actions[0].parameter.changeImage.assets.forEach((assetPath: any, index: any) => {
        if (
          assetPath ===
          pageManifest.pageLayouts[pageManifest.bootPage].modals[fmsContext.currentModal.modalName].overLays[
            overlayIndex
          ].image
        ) {
          assetIndex = index;
        }
      });
      if (assetIndex < assetsLength - 1) {
        assetIndex++;
      } else {
        assetIndex = 0;
      }
      pageManifest.pageLayouts[pageManifest.bootPage].modals[fmsContext.currentModal.modalName].overLays[
        overlayIndex
      ].image = rect.actions[0].parameter.changeImage.assets[assetIndex];
    } else {
      pageManifest.pageLayouts[pageManifest.bootPage].overLays.forEach((overLay: any, index: number) => {
        if (overLay.parentCell === rect.cduKeyName) {
          overlayIndex = index;
        }
      });
      rect.actions[0].parameter.changeImage.assets.forEach((assetPath: any, index: number) => {
        if (assetPath === pageManifest.pageLayouts[pageManifest.bootPage].overLays[overlayIndex].image) {
          assetIndex = index;
        }
      });
      if (assetIndex < assetsLength - 1) {
        assetIndex++;
      } else {
        assetIndex = 0;
      }
      pageManifest.pageLayouts[pageManifest.bootPage].overLays[overlayIndex].image =
        rect.actions[0].parameter.changeImage.assets[assetIndex];
    }

    pagesContext.updatePageManifest(pageManifest);
  };
  const openModal = (rect: any) => {
    closeDropDown();
    if (
      rect.actions[0] &&
      rect.actions[0].parameter &&
      rect.actions[0].parameter.openModal &&
      rect.actions[0].parameter.openModal.modal
    ) {
      fmsContext.fetchCduModal(rect.actions[0].parameter.openModal.modal);
    }
  };
  const closeModal = (rect: any) => {
    closeDropDown();
    if (pageManifest.pageLayouts.cursorKey) {
      fmsContext.findCursorObject(pageManifest.cdu.includes("a350") ? "fms-select" : "fms1");
    }
    fmsContext.setShowCduModal(false);
    fmsContext.setCurrentModal({});
  };
  const changeModal = (rect: any) => {
    closeDropDown();
    if (pageManifest.pageLayouts.cursorKey) {
      fmsContext.findCursorObject(pageManifest.cdu.includes("a350") ? "fms-select" : "fms1");
    }
    fmsContext.setShowCduModal(false);
    fmsContext.setCurrentModal({});

    // fmsContext.fetchCduModal(rect.actions[0].parameter.openModal.modal);
    openModal(rect);
  };
  const openPage = (rect: any) => {
    closeDropDown();
    fmsContext.setShowCduModal(false);
    fmsContext.setCurrentModal({});
    fmsContext.getPageData(rect.actions[0].parameter.openPage.pageName);
  };
  const buttonClickEvent = (rect: any) => {
    //if modal is open all buttons can have cursor placed
    //if modal is open page buttons cannot have their functions clicked

    if (fmsContext.showCduModal) {
      if (props.fromModal) {
        if (fmsContext.cursorSelected) {
          fmsContext.findCursorObject(rect.cduKeyName);
        } else {
          buttonClick(rect);
        }
      } else {
        if (fmsContext.cursorSelected) {
          fmsContext.findCursorObject(rect.cduKeyName);
        }
      }
    } else {
      if (fmsContext.cursorSelected) {
        fmsContext.findCursorObject(rect.cduKeyName);
      } else {
        buttonClick(rect);
      }
    }
    //pointerEvents={fmsContext.showCduModal ? props.fromModal ? "all" : "none" : "all"}
    //{ fmsContext.cursorSelected ? fmsContext.findCursorObject(rect.cduKeyName) : buttonClick(rect) }
  };

  return props.rectangularObjects.map((rect: any, index: number) => (
    // <></>
    <>
      <rect
        key={rect.cduKeyName + index}
        x={rect.xCoordinate}
        y={rect.yCoordinate}
        width={rect.width}
        height={rect.height}
        onClick={
          !document.getElementById("AddToDoTask")
            ? () => {
                buttonClickEvent(rect);
              }
            : () => {
                props.assignFmsKeypad(rect.id ?? rect.cduKeyName);
              }
        }
        fill="none"
        strokeWidth="2"
        pointerEvents="all"
        id={rect.id ?? rect.cduKeyName}
        className={props.highlightGroups?.includes(rect.cduKeyName) ? "highlightedRectElement" : ""}
      />

      {/* <image
                    key={index}
                    href={blobUrlFormatHelper(`${props.cduPath}${overlay.image}`)} 
                    width={overlay.width} 
                    height={overlay.height}
                    x={overlay.xCoordinate}
                    y={overlay.yCoordinate}
                    preserveAspectRatio="none"                   
                    onClick={()=>{ overlay.parentCell !== "actMod" ? props.assignFmsKeypad !== null ? props.assignFmsKeypad(overlay.id) : console.log("click")  : console.log("click")}}
                /> : <></> */}
    </>
  ));
};
export default CDUModalButtons;
