import React, { useState, useEffect, useContext } from "react";
import { keyManagerProps } from "../../Interfaces/keyDataInterfaces";
import KeyDataList from "./KeyDataList";
import KeyDataEditor from "./keyDataEditor";
import { IFmsContext, FmsContext } from "../../Interfaces/fmsContext";

const KeyDataManager = (props: keyManagerProps) => {
  //const pagesContext: IPageContext = useContext<IPageContext>(PageContext);
  //const pageManifest: IFmsPageManifest = pagesContext.pageManifest as IFmsPageManifest;
  const [editingAction, toggleEditingAction] = useState<boolean>(false);
  const [actionIndex, setActionIndex] = useState<number>(0);
  const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);
  fmsContext.setHighlightFrom("key");
  useEffect(() => {
    toggleEditingAction(false);
    fmsContext.setHighlights([props.keyName]);
  }, [props.keyName]);
  useEffect(() => {
    return () => {
      fmsContext.setHighlightFrom("");
      fmsContext.setHighlights([]);
    };
  }, []);
  return (
    <>
      <p className="instructions-deletebutton" style={{ fontSize: "1.125rem" }}>
        {" "}
        <strong>Editing - {props.keyName}</strong>
      </p>
      {editingAction ? (
        <>
          <KeyDataEditor
            keyName={props.keyName}
            destinationList={props.destinationList}
            index={actionIndex}
            fromTask={props.fromTask}
            taskIndex={props.taskIndex}
          />
          <button
            onClick={() => {
              toggleEditingAction(false);
              if (props.nestedMenu) {
                props.nestedMenu(false);
              }
            }}
            className="btn floating-confirm-button default-button-colors fms-back-btn"
          >
            Back
          </button>
        </>
      ) : (
        <>
          {props.disabledKeyRemoval ? (
            <label>
              is Enabled
              <input type="checkbox" checked={true} onChange={props.disabledKeyRemoval} />
            </label>
          ) : (
            <></>
          )}
          <KeyDataList
            setActionIndex={setActionIndex}
            toggleEditingAction={toggleEditingAction}
            keyName={props.keyName}
            destinationList={props.destinationList}
            nestedMenu={props.nestedMenu}
          />
        </>
      )}
    </>
  );
};

export default KeyDataManager;
