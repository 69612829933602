import React from "react";
import { ReactComponent as InteractiveDiagramIcon } from "../../../assets/icons/AssetManager/interactive-diagram-icon.svg";
import { ReactComponent as AddHotspotIcon } from "../../../assets/icons/AssetManager/hotspot-icon-toolbar-white.svg";
import { ReactComponent as AddSmartObjectIcom } from "../../../assets/icons/AssetManager/smart-object.svg";
import AddTableButton from "../../Tables/AddTableButton";
import { useTablesDataDispatch } from "../../Tables/TablesDataProvider";
import { useSelectedObjectDispatch } from "../../../contexts/SelectedObjectProvider/SelectedObjectProvider";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../../contexts/ObjectsProvider";
import { AbstractTooltip as Tooltip } from "../../ToolTip/ToolTip";
import { DEFAULT_HOTSPOT } from "../../../const";
import { nanoid } from "../../../lib/nanoId";
type MajorsType = {
  isFreeForm?: boolean;
  isVideoButtonShown?: boolean;
  isSCORMButtonShown?: boolean;
  areMajorsDisabled?: boolean;
  addNewImageBox?: () => void;
  addNewTextBox?: () => void;
  addNewVideo?: () => void;
  addNewSCORM?: () => void;
  addNewTable?: () => void;
  addNewSmartObject?: () => void;
  addNewPanoramic?: () => void;
  permissions?: { [key: string]: boolean };
  setOldPropertyBoxIsShown?: (value: boolean) => void;
};
const HOTSPOT_LIMIT = 150;
const MajorElements = (props: MajorsType) => {
  const tablesDispatch = useTablesDataDispatch();
  const selectedObjectDispatch = useSelectedObjectDispatch();
  const { hotspots } = useObjectsState();
  const objectDispatch = useObjectsDispatch();
  const { panoramicList, videos, objectList } = useObjectsState();

  // interactivityBuilderState?.interactivityBuilderInstance?.getAllHotspots().length; -- fix

  const scormObject = objectList.find((x) => x.type === "SCORM");
  const hasSCORMorVIDEO = scormObject || videos[0] ? true : false;
  const addPanoramicDisabled = panoramicList?.length >= 1;
  if (props.isFreeForm && props.areMajorsDisabled) {
    return (
      <React.Fragment>
        <button
          id="wysiwyg-text-button"
          className="wysiwyg-toolbar-btn"
          onClick={props.addNewTextBox}
          data-tooltip-id="AddTextBox"
        >
          <Tooltip id="AddTextBox" />
          <span className="icon-insert-text-box-icon-vector"></span>
        </button>
        <button
          id="wysiwyg-image-button"
          className="wysiwyg-toolbar-btn"
          onClick={props.addNewImageBox}
          data-tooltip-id="AddImage"
        >
          <Tooltip id="AddImage" />
          <span className="icon-image-icon"></span>
        </button>

        <button
          id="wysiwyg-video-button"
          className="wysiwyg-toolbar-btn"
          style={!hasSCORMorVIDEO ? {} : { backgroundColor: "var(--disabled-color)" }}
          onClick={() => {
            if (props.addNewVideo && !hasSCORMorVIDEO) props.addNewVideo();
          }}
          data-tooltip-id="AddVideo"
        >
          <Tooltip id="AddVideo" />
          <span className="icon-Video-icon"></span>
        </button>

        <button
          id="wysiwyg-video-button"
          className="wysiwyg-toolbar-btn"
          style={!hasSCORMorVIDEO ? {} : { backgroundColor: "var(--disabled-color)" }}
          onClick={() => {
            if (props.addNewSCORM && !hasSCORMorVIDEO) {
              props.addNewSCORM();
            }
          }}
          data-tooltip-id="AddInteractiveDiagram"
        >
          <Tooltip id="AddInteractiveDiagram" />
          <InteractiveDiagramIcon />
        </button>
        {props.permissions?.interactivity && (
          <button
            id="wysiwyg-hotspot-button"
            className="wysiwyg-toolbar-btn add-hotspot-button"
            style={(hotspots?.length ?? 0) < HOTSPOT_LIMIT ? {} : { backgroundColor: "var(--disabled-color)" }}
            // style={props.isSCORMButtonShown ? {} : { backgroundColor: 'var(--disabled-color)' }}
            onClick={() => {
              tablesDispatch({
                action: "setSelectedTable",
                payload: [undefined],
              });
              selectedObjectDispatch({ type: "SET_OBJECT_ID", payload: null });

              objectDispatch({
                type: ObjectActionsType.ADD_NEW_OBJECT,
                object: {
                  ...DEFAULT_HOTSPOT,
                  left: hotspots.length * 3 + 2,
                  top: hotspots.length * 3 + 2,
                  objectId: nanoid(),
                  displayName: "Hotspot " + (hotspots.length + 1),
                },
              });
            }}
            data-tooltip-id="AddHotSpot"
          >
            <Tooltip id="AddHotSpot" />
            <AddHotspotIcon />
          </button>
        )}
        {props.permissions?.tables ? <AddTableButton /> : null}

        <button
          id="wysiwyg-image-button"
          className="wysiwyg-toolbar-btn"
          onClick={props.addNewSmartObject}
          data-tooltip-id="AddSmartObject"
        >
          <Tooltip id="AddSmartObject" />
          <AddSmartObjectIcom />
        </button>
        <button
          id="wysiwyg-panoramic-button"
          className="wysiwyg-toolbar-btn"
          style={addPanoramicDisabled ? { backgroundColor: "var(--disabled-color)" } : {}}
          onClick={props.addNewPanoramic}
          data-tooltip-id="AddPanoramic"
        >
          <Tooltip id="AddPanoramic" />
          <span className="icon-panoramic-icon"></span>
        </button>
        <hr className="wysiwyg-toolbar-divider" />
      </React.Fragment>
    );
  }
  return <></>;
};

export default MajorElements;
