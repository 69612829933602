/**
 *  ObjectsProvider - the place where all shareable state related to objects is stored
 */

import React, { Dispatch, PropsWithChildren, createContext, useContext, useEffect, useReducer } from "react";
import { produce } from "immer";
import { getObjectsInFlatListAndByType } from "../../utils/PageManifestParsing";
import { nanoid } from "../../lib/nanoId";
import {
  BaseObject,
  Frame,
  HotspotNextClick,
  HotspotObject,
  IAnnotation,
  SmartObject,
  PanoramicObject,
} from "../../types";
import { createLogger, isNumber } from "../../utils";
import type { ObjectsAction } from "./types";
import { AnimatedObjectManifest } from "../../lib/interactivity/TimelineConverter/TimelineConverter";
import { useUIStore } from "../MovableElementsPlaneProvider";
import { valueToPercentage } from "../../utils/Conversion";
import Moveable from "react-moveable";
import { OBJECT_TYPE_PANORAMIC, annotationTypes, symbolTypes } from "../../const";
import { ICell, ITable } from "../../components/Tables/ITable";
const UNDO_REDO_LIMIT = 50;
const log = createLogger("ObjectsProvider", {
  background: "#00ff00",
  color: "#000000",
  pretty: true,
  captureObjects: true,
});

export enum ObjectActionsType {
  /**
   * Set the objects in the page
   */
  SET_OBJECTS,
  /**
   * Set the single selected object in the objects list
   */
  SET_SELECTED_OBJECT,
  /**
   * Add to the list of the selected objects in the objects list
   */
  ADD_SELECTED_OBJECT,
  /**
   * takes an id or list of ids to delete from the objects list, and animated objects list
   */
  DELETE_OBJECT,
  /**
   * populate the objects list from the page manifest
   */
  SET_OBJECTS_FROM_PAGE_MANIFEST,
  /**
   * set the moveable object ref from the react-moveable library ref
   */
  SET_MOVEABLE_OBJECT_REF,
  /**
   * set the moveable object ref for lines from the react-moveable library ref
   */
  SET_MOVEABLE_OBJECT_REF_LINE,
  /**
   * takes an object and adds it the objects list
   */
  ADD_NEW_OBJECT,
  /**
   * takes an object id and adds it to the animated objects list, based on existing object in the objects list
   */
  ADD_ANIMATED_OBJECT,
  /**
   * takes an object id and updates it in the objects list
   */
  UPDATE_LABEL,
  UPDATE_VIDEO_ASSET,
  UPDATE_SCORM_ASSET,
  /**
   * set the object module from a third party
   */
  SET_OBJECT_MODULE_REF,
  /**
   * takes an object id and updates the image assertVersionId, and the image path
   */
  UPDATE_IMAGE_ASSET,
  /**
   * takes an object id and updates the transform origin of that object
   */
  // UPDATE_TRANSFORM_ORIGIN,
  /**
   * takes an object id and updates the text inside that text box
   */
  UPDATE_TEXT_BOX,
  /**
   *  takes an object id and updates the hotspot object
   */
  UPDATE_HOTSPOT,
  /**
   * takes an object id, catch all to update an object in the objects list takes a partial object and merges it with the
   */
  UPDATE_OBJECT,
  SET_LOCK_ASPECT_RATIO,
  SET_Z_INDEX,
  SET_OPACITY,
  /**
   * delete an animated object from the animated objects list
   */
  DELETE_ANIMATED_OBJECT,
  /**
   * goes back to the previous object state
   */
  GO_BACK_TO_PREVIOUS_OBJECT_STATE,
  /**
   * goes forward to the nearest undone object state
   */
  REDO_TO_NEXT_OBJECT_STATE,
  /**
   * toggle the isCropping flag
   */
  TOGGLE_IS_CROPPING,
  /** remove cropped image properties from the object */
  RESTORE_CROPPED_IMAGE,
  /**
   * takes an id,  update the object clip path
   */
  SET_OBJECT_CLIP_PATH,
  /**
   * takes an object id, a timestamp, and a property name, and updates that property from the object frame with the current timestamp
   */
  UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
  /**
   * update or insert a new frame based on the provided frame
   */
  UPSERT_OBJECT_FRAME,
  /**
   * update a frame based on the provided frame
   */
  UPDATE_OBJECT_FRAME,
  DELETE_PROPERTY_FROM_OBJECT_FRAME,
  /**
   * update the start and end of an animated object
   */
  UPDATE_OBJECT_START_END,
  /**
   * add a new frame to the animated object
   */
  ADD_NEW_FRAME,
  /**
   * delete a frame from the animated object
   */
  DELETE_FRAME,
  /**
   * update the object fill color
   */
  SET_BACKGROUND_COLOR,
  TOGGLE_OBJECT_GHOST,
  SET_BORDER_COLOR,
  SET_STROKE_WIDTH,
  SET_ANNOTATION_FONT_COLOR,
  SET_DISPLAY_NAME,
  SET_ACTIVE_TEXT_BOX,
  CLEAR_NEED_SAVE,
  SET_NEED_SAVE,
  UPDATE_TABLE,
  ADD_TABLE,
  COPY_OBJECTS,
  PASTE_OBJECTS,
  SET_BLUR_INTENSITY,
  SET_BLUR_COLOR,
  SET_BLUR_CUTOUT,

  DELETE_BLUR_CUTOUT_PROPERTY_FROM_OBJECT_FRAME,
  ADD_BLUR_CUTOUT,
  UPDATE_BLUR_CUTOUT,
  DELETE_BLUR_CUTOUT,
}

interface ObjectsState {
  /**
   * is the user currently cropping an image
   */
  isCropping: boolean;
  /**
   * the objects that are currently selected
   */
  selectedObjects: BaseObject[];
  /**
   * the ids of the objects that are currently selected
   */
  selectedObjectId: string[]; // utility for single object selection
  /**
   * are the selected objects grouped
   */
  grouped: boolean;
  /**
   * the list of objects in the page, this is a flat list of all the objects we support in the page manifest
   */
  objectList: BaseObject[];
  /**
   * the list of animated objects in the page
   */
  animatedObjects: AnimatedObjectManifest[];
  /**
   * a derived list of the objects that are images
   */
  images: BaseObject[];
  /**
   * a derived list of the objects that are annotations
   */
  annotations: BaseObject[];
  /**
   * a derived list of the objects that are text boxes
   */
  /**
   * a derived list of the objects that are symbols
   */
  symbols: BaseObject[];
  videos: BaseObject[];
  tables: ITable[];
  scorms: BaseObject[];
  hotspots: HotspotObject[];
  smartObjects: SmartObject[];
  panoramicList: PanoramicObject[];
  /**
   * the history of the object list
   */
  animatedObjectsHistory: AnimatedObjectManifest[][];
  /**
   * the redo history of the animated objects
   */
  animatedObjectsRedoHistory: AnimatedObjectManifest[][];
  /**
   * the history of the object list, it is separated because we track animated objects separately
   */
  objectListHistory: BaseObject[][];
  /**
   * the redo history of the object list
   */
  objectListRedoHistory: BaseObject[][];
  moveableRef: Moveable | null;
  moveableLineRef: Moveable | null;
  textBoxes: BaseObject[];
  activeTextBox: BaseObject | null;
  needSave: boolean;
  copyBuffer: BaseObject[];
  pasteInt: number;
}

const initialState: ObjectsState = {
  isCropping: false,
  selectedObjects: [],
  grouped: false,
  objectList: [],
  selectedObjectId: [],
  images: [],
  annotations: [],
  textBoxes: [],
  symbols: [],
  videos: [],
  tables: [],
  scorms: [],
  hotspots: [],
  smartObjects: [],
  panoramicList: [],
  animatedObjects: [],
  objectListHistory: [],
  objectListRedoHistory: [],
  animatedObjectsHistory: [],
  animatedObjectsRedoHistory: [],
  moveableRef: null,
  moveableLineRef: null,
  activeTextBox: null,
  needSave: false,
  copyBuffer: [],
  pasteInt: 0,
};

const ObjectsState = createContext<ObjectsState>(initialState);
const ObjectsDispatch = createContext<any>({});
const objectsReducer = (state: ObjectsState, action: ObjectsAction): ObjectsState => {
  switch (action.type) {
    case ObjectActionsType.SET_OBJECTS: {
      const objects = action.payload;
      return {
        ...state,
        objectList: objects,
      };
    }
    case ObjectActionsType.SET_SELECTED_OBJECT: {
      if (action.payload === null) {
        return {
          ...state,
          selectedObjects: [],
          selectedObjectId: [],
          isCropping: false,
        };
      }
      const { objectId } = action.payload; // single object

      if (
        state.selectedObjects?.[0]?.objectId === objectId ||
        state.selectedObjectId?.[0] === action.payload.objectId
      ) {
        return state;
      }

      const selectedObject = state.objectList.find((obj: any) => obj.objectId === objectId);

      if (!selectedObject) {
        return state;
      }
      return {
        ...state,
        selectedObjects: [selectedObject],
        selectedObjectId: [objectId],
        isCropping: false,
      };
    }
    case ObjectActionsType.ADD_SELECTED_OBJECT: {
      const { objectId } = action.payload; // single object

      if (state.selectedObjectId.includes(objectId)) {
        // remove it from list
        const newSelectedObjectId = state.selectedObjectId.filter((id) => id !== objectId);
        const newSelectedObjects = state.selectedObjects.filter((obj) => obj.objectId !== objectId);
        return {
          ...state,
          selectedObjectId: newSelectedObjectId,
          selectedObjects: newSelectedObjects,
          isCropping: false,
        };
      }

      const selectedObject = state.objectList.find((obj: any) => obj.objectId === objectId);

      if (!selectedObject) {
        return state;
      }

      return {
        ...state,
        selectedObjects: [...state.selectedObjects, selectedObject],
        selectedObjectId: [...state.selectedObjectId, objectId],
        isCropping: false,
      };
    }
    case ObjectActionsType.SET_OBJECT_MODULE_REF: {
      const { module, objectId } = action;
      const object = state.objectList.find((object) => object.objectId === objectId);

      if (object?.moduleRef === module) {
        return state;
      }

      const updateObject = produce((objectList: ObjectsState["objectList"]) => {
        const object = objectList.find((object) => object.objectId === objectId);
        if (!object) {
          return;
        }
        object.moduleRef = module;
      });

      const newObjectList = updateObject(state.objectList);

      return {
        ...state,
        ...updateObjectsList(state, { newObjectList }),
        ...splitObjectsList(newObjectList ?? state.objectList),
      };
    }
    case ObjectActionsType.ADD_NEW_OBJECT: {
      const object = action.object;
      const newObjectList = [...state.objectList, object];
      return {
        ...state,
        ...updateObjectsList(state, { newObjectList }),
        selectedObjects: [object],
        selectedObjectId: [object.objectId],
        ...splitObjectsList(newObjectList ?? state.objectList),
        isCropping: false,
        needSave: true,
      };
    }
    case ObjectActionsType.ADD_ANIMATED_OBJECT: {
      const { objectId } = action;
      if (state.animatedObjects.find((object) => object.id === objectId)) {
        return state;
      }
      const animatedObject: AnimatedObjectManifest = {
        frames: [],
        framesCacheId: nanoid(),
        start: 0,
        end: null,
        id: objectId,
        type: state.selectedObjects?.[0]?.type === "hotspot" ? "hotspot" : "bar",
      };
      const newAnimatedObjects = [...state.animatedObjects, animatedObject];
      return {
        ...state,
        ...updateObjectsList(state, { newAnimatedObjects }),
        needSave: true,
      };
    }
    case ObjectActionsType.SET_MOVEABLE_OBJECT_REF: {
      const { moveableRef } = action;
      if (moveableRef === state.moveableRef && moveableRef !== null) {
        return state;
      }
      return {
        ...state,
        moveableRef,
      };
    }
    case ObjectActionsType.SET_MOVEABLE_OBJECT_REF_LINE: {
      const { moveableRef } = action;
      if (moveableRef === state.moveableLineRef && moveableRef !== null) {
        return state;
      }
      return {
        ...state,
        moveableLineRef: moveableRef,
      };
    }
    case ObjectActionsType.DELETE_OBJECT: {
      const { objectId } = action;
      const deleteObject = produce((objectList: ObjectsState["objectList"]) => {
        const objectIndex = objectList.findIndex((object) => object.objectId === objectId);
        if (objectIndex === -1) {
          return;
        }
        objectList.splice(objectIndex, 1);
      });

      const newObjectList = deleteObject(state.objectList);

      const deleteAnimatedObject = produce((animatedObjects: AnimatedObjectManifest[]) => {
        const animatedObjectIndex = animatedObjects.findIndex((object) => object.id === objectId);
        if (animatedObjectIndex === -1) {
          return;
        }
        animatedObjects.splice(animatedObjectIndex, 1);
      });

      const newAnimatedObjects = deleteAnimatedObject(state.animatedObjects);

      return {
        ...state,
        ...updateObjectsList(state, { newObjectList, newAnimatedObjects }),
        selectedObjectId: [],
        selectedObjects: [],
        needSave: true,
      };
    }
    case ObjectActionsType.DELETE_ANIMATED_OBJECT: {
      const { objectId } = action;
      const deleteAnimatedObject = produce((animatedObjects: AnimatedObjectManifest[]) => {
        const animatedObjectIndex = animatedObjects.findIndex((object) => object.id === objectId);
        if (animatedObjectIndex === -1) {
          return;
        }
        animatedObjects.splice(animatedObjectIndex, 1);
      });

      const newAnimatedObjects = deleteAnimatedObject(state.animatedObjects);

      return {
        ...state,
        ...updateObjectsList(state, { newAnimatedObjects }),
        needSave: true,
      };
    }
    case ObjectActionsType.SET_OBJECTS_FROM_PAGE_MANIFEST: {
      const pageManifest = action.payload;
      const objects = getObjectsInFlatListAndByType(pageManifest);
      log(objects);
      const objectList = objects.objectsFlat;
      const animatedObjects = produce<AnimatedObjectManifest[]>(pageManifest?.timeline?.animatedObjects, (draft) => {
        return draft ?? [];
      });
      return {
        ...state,
        objectList,
        selectedObjects: [],
        selectedObjectId: [],
        ...splitObjectsList(objectList),
        animatedObjects: animatedObjects,
        objectListHistory: [],
        objectListRedoHistory: [],
        animatedObjectsHistory: [],
        animatedObjectsRedoHistory: [],
      };
    }
    case ObjectActionsType.UPDATE_OBJECT: {
      const { object: partialObject, objectId } = action.payload;
      const updateObject = produce((objectList: ObjectsState["objectList"]) => {
        const object = objectList.find((object) => object.objectId === objectId);
        if (!object) {
          return;
        }
        const newObject = {
          ...object,
          ...partialObject,
        };
        Object.assign(object, newObject);
      });
      const newObjectList = updateObject(state.objectList);

      return {
        ...state,
        ...updateObjectsList(state, { newObjectList }),
        needSave: true,
      };
    }
    case ObjectActionsType.SET_LOCK_ASPECT_RATIO: {
      const { objectId, lockAspectRatio } = action.payload;
      const updateObject = produce((objectList: ObjectsState["objectList"]) => {
        const object = objectList.find((object) => object.objectId === state.selectedObjectId[0]);
        if (!object) {
          return;
        }
        object.lockAspectRatio = lockAspectRatio;
      });
      const newObjectList = updateObject(state.objectList);

      return {
        ...state,
        ...updateObjectsList(state, { newObjectList }),
        needSave: true,
      };
    }
    case ObjectActionsType.SET_Z_INDEX: {
      const { objectId, zIndex } = action.payload;
      const updateObject = produce((ol: ObjectsState["objectList"]) => {
        // Find the main object and update its zIndex

        const object = ol.find((object) => object.objectId === objectId);
        if (object) {
          const currentZIndex = object.zIndex ?? 1;
          const direction = currentZIndex < zIndex ? "up" : "down";
          object.zIndex = zIndex;
          // Handle undefined zIndex
          ol.forEach((object) => {
            if (object.zIndex === undefined) {
              object.zIndex = 0;
            }
          });
          // Adjust zIndex of other objects
          ol.forEach((object) => {
            if (object.objectId !== objectId && object.zIndex === zIndex) {
              object.zIndex = direction === "up" ? zIndex - 1 : zIndex + 1;
            }
          });
          const temp = [...ol];
          temp.sort((a, b) => (a.zIndex ?? 0) - (b.zIndex ?? 0));
          temp.forEach((object, index) => {
            object.zIndex = index + 1;
          });
        }
      });
      const newObjectList = updateObject(state.objectList);
      return {
        ...state,
        ...updateObjectsList(state, { newObjectList }),
        needSave: true,
      };
    }
    case ObjectActionsType.SET_OPACITY: {
      const { objectId, opacity } = action.payload;
      const updateObject = produce((ol: ObjectsState["objectList"]) => {
        const object = ol.find((object) => object.objectId === objectId);
        if (!object) {
          return;
        }
        object.opacity = opacity;
      });
      const newObjectList = updateObject(state.objectList);
      return {
        ...state,
        ...updateObjectsList(state, { newObjectList }),
        needSave: true,
      };
    }
    case ObjectActionsType.SET_BACKGROUND_COLOR: {
      const { objectId, color, previousColor } = action.payload;
      const updateObject = produce((ol: ObjectsState["objectList"]) => {
        const object: IAnnotation = ol.find((object) => object.objectId === objectId);
        if (!object) {
          return;
        }
        object.backgroundColor = color.hex;
        object.previousBackgroundColor = previousColor.hex;
      });
      const newObjectList = updateObject(state.objectList);
      return {
        ...state,
        ...updateObjectsList(state, { newObjectList }),
        needSave: true,
      };
    }
    case ObjectActionsType.SET_BORDER_COLOR: {
      const { objectId, color, previousColor } = action.payload;
      const updateObject = produce((ol: ObjectsState["objectList"]) => {
        const object: IAnnotation = ol.find((object) => object.objectId === objectId);
        if (!object) {
          return;
        }
        object.borderColor = color.hex;
        object.previousBorderColor = previousColor.hex;
        object.strokeWidth ? null : (object.strokeWidth = 4);
      });
      const newObjectList = updateObject(state.objectList);
      return {
        ...state,
        ...updateObjectsList(state, { newObjectList }),
        needSave: true,
      };
    }
    case ObjectActionsType.SET_STROKE_WIDTH: {
      const { objectId, strokeWidth } = action.payload;
      const updateObject = produce((ol: ObjectsState["objectList"]) => {
        const object: IAnnotation = ol.find((object) => object.objectId === objectId);
        if (!object) {
          return;
        }
        object.strokeWidth = strokeWidth;
      });
      const newObjectList = updateObject(state.objectList);
      return {
        ...state,
        ...updateObjectsList(state, { newObjectList }),
        needSave: true,
      };
    }
    case ObjectActionsType.SET_ANNOTATION_FONT_COLOR: {
      const { objectId, color } = action.payload;
      const updateObject = produce((ol: ObjectsState["objectList"]) => {
        const object: IAnnotation = ol.find((object) => object.objectId === objectId);
        if (!object) {
          return;
        }
        object.fontColor = color.hex;
      });
      const newObjectList = updateObject(state.objectList);
      return {
        ...state,
        ...updateObjectsList(state, { newObjectList }),
        needSave: true,
      };
    }
    case ObjectActionsType.SET_DISPLAY_NAME: {
      const { objectId, displayName } = action.payload;
      const updateObject = produce((ol: ObjectsState["objectList"]) => {
        const object: IAnnotation = ol.find((object) => object.objectId === objectId);
        if (!object) {
          return;
        }
        object.displayName = displayName;
      });
      const newObjectList = updateObject(state.objectList);
      return {
        ...state,
        ...updateObjectsList(state, { newObjectList }),
        needSave: true,
      };
    }
    /**HISTORY */
    case ObjectActionsType.REDO_TO_NEXT_OBJECT_STATE: {
      const redos = [...state.objectListRedoHistory];
      if (redos.length === 0) {
        return state;
      }
      const nextObjectList = redos.pop() ?? state.objectList;
      return {
        ...state,
        objectListRedoHistory: redos,
        objectList: nextObjectList,
        objectListHistory: [...state.objectListHistory, state.objectList],
        ...splitObjectsList(nextObjectList),
        ...updateSelectedObjects(state.selectedObjects, nextObjectList),
        needSave: true,
      };
    }
    case ObjectActionsType.GO_BACK_TO_PREVIOUS_OBJECT_STATE: {
      const objectListHistory = [...state.objectListHistory];
      const animatedObjectsHistory = [...state.animatedObjectsHistory];
      if (objectListHistory.length === 0) {
        return state;
      }

      const previousObjectList = objectListHistory.pop();
      const previousAnimatedObjects = animatedObjectsHistory.pop();
      const objectList = previousObjectList ?? state.objectList;
      const animatedObjects = previousAnimatedObjects ?? state.animatedObjects;
      return {
        ...state,
        objectListHistory,
        objectList,
        animatedObjectsHistory,
        animatedObjects,
        ...splitObjectsList(objectList),
        ...updateSelectedObjects(state.selectedObjects, objectList),
        objectListRedoHistory: [...state.objectListRedoHistory, state.objectList],
        animatedObjectsRedoHistory: [...state.animatedObjectsRedoHistory, state.animatedObjects],
        needSave: true,
      };
    }
    /** CROPPING */
    case ObjectActionsType.TOGGLE_IS_CROPPING: {
      return {
        ...state,
        isCropping: !state.isCropping,
      };
    }
    case ObjectActionsType.RESTORE_CROPPED_IMAGE: {
      const { objectId } = action.payload;
      const updateObject = produce((objectList: ObjectsState["objectList"]) => {
        const object = objectList.find((object) => object.objectId === objectId);
        if (!object) {
          return;
        }
        delete object.clipPath;
        delete object.clipPathString;
      });
      const newObjectList = updateObject(state.objectList);
      return {
        ...state,
        ...updateObjectsList(state, { newObjectList }),
        needSave: true,
      };
    }
    case ObjectActionsType.UPDATE_LABEL: {
      const { objectId, text, height } = action.payload;

      const updateLabel = produce((objectList: ObjectsState["objectList"]) => {
        const label = objectList.find((object) => object.objectId === objectId);

        if (!label) {
          return;
        }

        label.text = text;
      });

      const newObjectList = updateLabel(state.objectList);

      return {
        ...state,
        ...updateObjectsList(state, { newObjectList }),
        needSave: true,
      };
    }
    case ObjectActionsType.UPDATE_TEXT_BOX: {
      const { html, objectId } = action.payload;
      const updateTextBox = produce((objectList: ObjectsState["objectList"]) => {
        const textBox = objectList.find((object) => object.objectId === objectId);

        if (!textBox) {
          return;
        }

        textBox.text = html;
      });

      const newObjectList = updateTextBox(state.objectList);

      return {
        ...state,
        ...updateObjectsList(state, { newObjectList }),
        ...splitObjectsList(newObjectList),
        needSave: true,
      };
    }
    case ObjectActionsType.UPDATE_IMAGE_ASSET: {
      const { objectId, assetVersionId: imageAssetId, imagePath } = action.payload;

      const updateImageAsset = produce((objectList: ObjectsState["objectList"]) => {
        const image = objectList.find((object) => object.objectId === objectId);

        if (!image) {
          return;
        }

        image.assetVersionId = imageAssetId;
        image.imagePath = imagePath;
      });

      const newObjectList = updateImageAsset(state.objectList);

      return {
        ...state,
        ...updateObjectsList(state, { newObjectList }),
        needSave: true,
      };
    }
    case ObjectActionsType.SET_ACTIVE_TEXT_BOX: {
      if (action.payload === null) {
        return {
          ...state,
          activeTextBox: null,
        };
      }
      const { objectId } = action.payload;
      const activeTextBox = state.objectList.find((object) => object.objectId === objectId);
      // if(state.selectedObjectId.includes(activeTextBox?.objectId)) {

      // }
      return {
        ...state,
        activeTextBox: activeTextBox ?? null,
        selectedObjectId: activeTextBox ? [activeTextBox.objectId] : [],
        selectedObjects: activeTextBox ? [activeTextBox] : [],
      };
    }
    case ObjectActionsType.UPDATE_VIDEO_ASSET: {
      const { objectId, assetVersionId, blobUrl } = action.payload;

      const updateImageAsset = produce((objectList: ObjectsState["objectList"]) => {
        const video = objectList.find((object) => object.objectId === objectId);

        if (!video) {
          return;
        }

        video.assetVersionId = assetVersionId;
        video.path = blobUrl;
      });

      const newObjectList = updateImageAsset(state.objectList);

      return {
        ...state,
        ...updateObjectsList(state, { newObjectList }),
        needSave: true,
      };
    }
    case ObjectActionsType.UPDATE_SCORM_ASSET: {
      const { objectId, assetVersionId, blobUrl } = action.payload;

      const updateScormAsset = produce((objectList: ObjectsState["objectList"]) => {
        const scorm = objectList.find((object) => object.objectId === objectId);

        if (!scorm) {
          return;
        }

        scorm.assetVersionId = assetVersionId;
        scorm.blobPath = blobUrl;
      });

      const newObjectList = updateScormAsset(state.objectList);

      return {
        ...state,
        ...updateObjectsList(state, { newObjectList }),
        needSave: true,
      };
    }
    case ObjectActionsType.SET_OBJECT_CLIP_PATH: {
      // IMAGE OBJECTS HAVE CLIP PATH

      const { objectId, clipPath, clipPathString } = action.payload;
      // TODO add types that this is an image
      const setClipPath = produce((objectList: ObjectsState["objectList"]) => {
        for (let i = 0; i < objectList.length; i++) {
          if (objectList[i].objectId === objectId) {
            // object will have clip path because is image, if not, well...
            objectList[i].clipPath = clipPath;
            objectList[i].clipPathString = clipPathString;
          }
        }
      });
      const newObjectList = setClipPath(state.objectList);
      return {
        ...state,
        ...updateObjectsList(state, { newObjectList }),
        needSave: true,
      };
    }
    /** FRAMES / TIMELINE */
    case ObjectActionsType.UPDATE_OBJECT_START_END: {
      const { start, end, objectId } = action.payload;
      const updateAnimatedObjectStartEnd = produce((animatedObjects: AnimatedObjectManifest[]) => {
        const animatedObject = animatedObjects.find((object) => object.id === objectId);
        if (!animatedObject) return;
        // if end and start are the same, we don't need to update
        if (animatedObject.start === start && animatedObject.end === end) return;
        // if the end is not the same and also the start is not the same, we need to update both, and the frames
        if (animatedObject.start !== start) {
          if (animatedObject.frames) {
            const startDiff = start - animatedObject.start;
            // mutate the frames
            animatedObject.frames.forEach((frame) => {
              frame.timestamp = frame.timestamp + startDiff;
            });
            // update the cache anytime frames are edited
            animatedObject.framesCacheId = nanoid();
          }
        }

        if (animatedObject.end !== end) {
          animatedObject.end = end;
        }
        if (animatedObject.start !== start) {
          animatedObject.start = start;
        }
      });

      const newAnimatedObjects = updateAnimatedObjectStartEnd(state.animatedObjects);
      return {
        ...state,
        ...updateObjectsList(state, { newAnimatedObjects }),
        needSave: true,
      };
    }
    // update or add frame
    case ObjectActionsType.UPSERT_OBJECT_FRAME: {
      const { frame: newFrame, objectId } = action.payload;
      const object = state.objectList.find((object) => object.objectId === objectId);
      if (object?.type === "table") {
        return state;
      }
      const upsertAnimatedObjectFrame = produce((animatedObjects: AnimatedObjectManifest[]) => {
        const animatedObject = animatedObjects.find((object) => object.id === objectId);
        if (!animatedObject) {
          return;
        }
        animatedObject.frames = animatedObject.frames || [];
        const frameIndex = animatedObject.frames.findIndex((existingFrame) => {
          return existingFrame.timestamp === newFrame.timestamp;
        });
        if (frameIndex !== -1) {
          animatedObject.frames[frameIndex] = {
            ...animatedObject.frames[frameIndex],
            ...newFrame,
          };
        } else {
          const f = { ...newFrame, id: nanoid() };
          animatedObject.frames.push(f);
        }

        // sort frames, since we just dumbly push above
        // easy perf win would be to insert in the right place
        animatedObject.frames.sort((a, b) => a.timestamp - b.timestamp);
        animatedObject.framesCacheId = nanoid();
      });

      const newAnimatedObjects = upsertAnimatedObjectFrame(state.animatedObjects);

      return {
        ...state,
        ...updateObjectsList(state, { newAnimatedObjects }),
        needSave: true,
      };
    }
    case ObjectActionsType.UPDATE_HOTSPOT: {
      const { objectId, clickIndex, hotspot: partialHotspot, newClicks, action: internalAction } = action.payload;
      const updateHotspot = produce((objectList: ObjectsState["objectList"]) => {
        const hotspot = objectList.find((object) => object.objectId === objectId) as HotspotObject;

        if (internalAction) {
          switch (internalAction.type) {
            case "ADD_CLICK": {
              hotspot.nextClicks = [
                ...hotspot.nextClicks,
                {
                  height: hotspot.height,
                  left: hotspot.left,
                  required: hotspot.required,
                  top: hotspot.top,
                  type: hotspot.type,
                  version: hotspot.version,
                  width: hotspot.width,
                  targets: [],
                },
              ];
              break;
            }
            case "UPDATE_CLICK_TASK": {
              const { clickIndex, mappedAction, targetId, value } = internalAction.payload;
              const click = clickIndex !== 0 ? hotspot.nextClicks[clickIndex - 1] : hotspot;
              const target = click.targets.find((target) => target.objectId === targetId);
              const targetTask = target?.tasks.find((t) => targetId === t.targetId && mappedAction === t.mappedAction);
              if (targetTask) {
                targetTask.actionValue = value;
              }
              break;
            }
            default: {
              break;
            }
          }
        } else if (newClicks) {
          hotspot.nextClicks = [...newClicks];
        } else if (hotspot && typeof clickIndex === "number") {
          const hpClicks: HotspotNextClick[] = [hotspot, ...hotspot.nextClicks];
          const hpClick = hpClicks[clickIndex];
          Object.assign(hpClick, partialHotspot);
        }
      });
      const newObjectList = updateHotspot(state.objectList);
      return {
        ...state,
        ...updateObjectsList(state, { newObjectList }),
        needSave: true,
      };
    }
    case ObjectActionsType.UPDATE_OBJECT_FRAME: {
      const { frame, objectId } = action.payload;
      const updateAnimatedObjectFrame = produce((animatedObjects: AnimatedObjectManifest[]) => {
        const animatedObject = animatedObjects.find((object) => object.id === objectId);
        if (!animatedObject) {
          return;
        }
        animatedObject.frames = animatedObject.frames || [];
        const frameIndex = animatedObject.frames.findIndex((existingFrame) => existingFrame.id === frame.id);
        if (frameIndex !== -1) {
          animatedObject.frames[frameIndex] = {
            ...animatedObject.frames[frameIndex],
            ...frame,
          };
          // update cache id to force rerender
          animatedObject.framesCacheId = nanoid();
          // sort frames
          animatedObject.frames.sort((a, b) => a.timestamp - b.timestamp);
        }
      });
      const newAnimatedObjects = updateAnimatedObjectFrame(state.animatedObjects);
      return {
        ...state,
        ...updateObjectsList(state, { newAnimatedObjects }),
        needSave: true,
      };
    }
    case ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT: {
      const {
        time,
        x: pixelX,
        y: pixelY,
        width: percentWidth,
        height: percentHeight,
        rotation,
        objectId,
        blur,
        blurCutoutShapes,
      } = action.payload;
      const nonFrameableObjects = ["table", "hotspot", "scorm"];
      const selectedObject = objectId
        ? state.selectedObjects.find((object) => object.objectId === objectId)
        : state.selectedObjects[0];
      if (!selectedObject) {
        return state;
      }
      const animatedObject = state.animatedObjects.find((object) => object.id === selectedObject.objectId);
      const {
        designerViewportDims: { height, width },
      } = useUIStore.getState();
      const updateObject = produce((objectList: ObjectsState["objectList"]) => {
        const object = objectList.find((object) => object.objectId === selectedObject.objectId);
        if (!object) {
          throw new Error("object not found");
        }

        if (isNumber(pixelX)) {
          object.left = valueToPercentage(pixelX, width);
        }
        if (isNumber(pixelY)) {
          object.top = valueToPercentage(pixelY, height);
        }
        if (isNumber(rotation)) {
          object.rotation = rotation;
        }
        if (isNumber(percentWidth)) {
          object.width = percentWidth;
        }
        if (isNumber(percentHeight)) {
          object.height = percentHeight;
        }
        if (isNumber(blur?.intensity)) {
          object.blur.intensity = blur?.intensity;
        }
        if (blurCutoutShapes) {
          object.blurCutoutShapes = blurCutoutShapes;
        }
      });
      // do not try to add frames to non frameable objects
      if (nonFrameableObjects.includes(selectedObject.type) || !animatedObject) {
        const newObjectList = updateObject(state.objectList);
        return {
          ...state,
          ...updateObjectsList(state, { newObjectList }),
          needSave: true,
        };
      }
      //else
      const updateAnimatedObjectAtTime = produce((animatedObjects: AnimatedObjectManifest[]) => {
        const animatedObject = animatedObjects.find((object) => object.id === selectedObject.objectId);
        if (!animatedObject) {
          return;
        }
        animatedObject.frames = animatedObject.frames || [];

        const frameIndex = animatedObject.frames.findIndex((frame) => frame.timestamp === time);

        if (selectedObject.type !== "table") {
          if (frameIndex >= 0) {
            // Update existing frame
            const newFrame = {
              ...animatedObject.frames[frameIndex],
            };
            if (isNumber(pixelX)) {
              newFrame.x = pixelX;
            }
            if (isNumber(pixelY)) {
              newFrame.y = pixelY;
            }
            if (isNumber(percentWidth)) {
              newFrame.width = percentWidth;
            }
            if (isNumber(percentHeight)) {
              newFrame.height = percentHeight;
            }
            if (isNumber(rotation)) {
              newFrame.rotation = rotation;
            }
            if (isNumber(blur?.intensity) && newFrame.blur) {
              newFrame.blur.intensity = blur?.intensity;
            }
            if (blurCutoutShapes) {
              newFrame.blurCutoutShapes = blurCutoutShapes;
            }
            animatedObject.frames.splice(frameIndex, 1, newFrame);
          } else {
            // Create new frame
            const newFrame: Frame = {
              id: nanoid(),
              timestamp: time,
            };
            if (isNumber(pixelX)) {
              newFrame.x = pixelX;
            }
            if (isNumber(pixelY)) {
              newFrame.y = pixelY;
            }
            if (isNumber(percentWidth)) {
              newFrame.width = percentWidth;
            }
            if (isNumber(percentHeight)) {
              newFrame.height = percentHeight;
            }
            if (isNumber(rotation)) {
              newFrame.rotation = rotation;
            }
            if (isNumber(blur?.intensity) && newFrame.blur) {
              newFrame.blur.intensity = blur.intensity;
            }
            if (blurCutoutShapes) {
              newFrame.blurCutoutShapes = blurCutoutShapes;
            }
            animatedObject.frames.push(newFrame);
            animatedObject.frames.sort((a, b) => a.timestamp - b.timestamp);
          }
        }
        animatedObject.framesCacheId = nanoid();
      });

      const newAnimatedObjects = updateAnimatedObjectAtTime(state.animatedObjects);
      return {
        ...state,
        ...updateObjectsList(state, { newAnimatedObjects }),
        needSave: true,
      };
    }
    case ObjectActionsType.DELETE_BLUR_CUTOUT_PROPERTY_FROM_OBJECT_FRAME: {
      const { objectId, timestamp, property: propertyToRemove, index: cutoutIndex } = action.payload;
      const deleteBlurCutoutPropertyFromObjectFrame = produce((animatedObjectList: AnimatedObjectManifest[]) => {
        const object = animatedObjectList.find((object) => object.id === objectId);
        if (!object) return;
        object.frames = object.frames || [];
        const frameIndex = object.frames.findIndex((frame) => frame.timestamp === timestamp);
        const currentFrame = object.frames[frameIndex];
        if (frameIndex >= 0) {
          const cutoutShapes = currentFrame.blurCutoutShapes;
          const selectedCutout = cutoutShapes?.[cutoutIndex];
          if (selectedCutout) {
            delete selectedCutout[propertyToRemove];
            object.framesCacheId = nanoid();
          }
        }
      });
      const newAnimatedObjects = deleteBlurCutoutPropertyFromObjectFrame(state.animatedObjects);
      return {
        ...state,
        ...updateObjectsList(state, { newAnimatedObjects }),
        needSave: true,
      };
    }
    case ObjectActionsType.DELETE_PROPERTY_FROM_OBJECT_FRAME: {
      const { objectId, timestamp, property } = action.payload;
      let lastPropInstanceValue: number | undefined;
      const deletePropertyFromObjectFrame = produce((animatedObjectList: AnimatedObjectManifest[]) => {
        const object = animatedObjectList.find((object) => object.id === objectId);
        if (!object) return;
        object.frames = object.frames || [];
        const frameIndex = object.frames.findIndex((frame) => frame.timestamp === timestamp);
        const currentFrame = object.frames[frameIndex];
        if (frameIndex >= 0) {
          lastPropInstanceValue = currentFrame[property];
          // found frame. delete property
          delete currentFrame[property];
          object.framesCacheId = nanoid();
        }

        // if the frame has no x, or y, or width, or height, delete the frame
        const propertiesToCheck = ["x", "y", "width", "height", "rotation", "opacity", "pitch", "yaw", "zoom"];
        const frame = object.frames[frameIndex];
        if (propertiesToCheck.every((prop) => !(prop in frame))) {
          object.frames.splice(frameIndex, 1);
          object.framesCacheId = nanoid();
        }
      });
      const setObjectsRespectivePropertyOnLastFrame = produce((objectList: ObjectsState["objectList"]) => {
        const object = objectList.find((object) => object.objectId === objectId);
        if (!object) return;
        if (typeof lastPropInstanceValue !== "number") return; // applies now but not when we use more properties

        // if it was the last property, set the objects corresponding property to the where the object is now
        switch (property) {
          case "x": {
            // value in px
            // left in %
            const { designerViewportDims } = useUIStore.getState();
            object.left = valueToPercentage(lastPropInstanceValue, designerViewportDims.width);
            break;
          }
          case "y": {
            // value in px
            // top in %
            const { designerViewportDims } = useUIStore.getState();
            object.top = valueToPercentage(lastPropInstanceValue, designerViewportDims.height);
            break;
          }
          case "width":
            object.width = lastPropInstanceValue;
            break;
          case "height":
            object.height = lastPropInstanceValue;
            break;
          case "rotation":
            object.rotation = lastPropInstanceValue;
            break;
          case "opacity":
            object.opacity = lastPropInstanceValue;
        }
      });
      // const newObjectList = deletePropertyFromObjectFrame(state.objectList);
      const newAnimatedObjects = deletePropertyFromObjectFrame(state.animatedObjects);
      const newObjectList = setObjectsRespectivePropertyOnLastFrame(state.objectList);

      const x: any = {
        newAnimatedObjects,
      };

      if (isNumber(lastPropInstanceValue)) {
        x.newObjectList = newObjectList;
      }

      return {
        ...state,
        ...updateObjectsList(state, x),
        needSave: true,
      };
    }
    case ObjectActionsType.DELETE_FRAME: {
      const { objectId, timestamp } = action.payload;
      log("delete frame", objectId, timestamp);
      const deleteFrame = produce((objectList: ObjectsState["objectList"]) => {
        runOnObject(objectList, objectId, (object) => {
          object.frames = object.frames || [];

          const frameIndex = object.frames.findIndex((frame) => frame.timestamp === timestamp);

          if (frameIndex >= 0) {
            // found frame. delete it
            object.frames.splice(frameIndex, 1);
            log("frame deleted", object.frames);
            object.framesCacheId = nanoid();
          }
        });
      });

      const newObjectList = deleteFrame(state.objectList);

      return {
        ...state,
        ...updateObjectsList(state, { newObjectList }),
        needSave: true,
      };
    }
    case ObjectActionsType.CLEAR_NEED_SAVE: {
      return {
        ...state,
        needSave: false,
      };
    }

    case ObjectActionsType.SET_NEED_SAVE: {
      return {
        ...state,
        needSave: true,
      };
    }
    case ObjectActionsType.ADD_TABLE: {
      const { size } = action.payload;
      const pagePlayerArea = document.getElementById("pageplayerarea");
      const pagePlayerAreaRect = pagePlayerArea?.getBoundingClientRect();
      const defaultRowHeight = 50;
      const defaultColumnWidth = 100;

      const addTable = produce((objectList: ObjectsState["objectList"]) => {
        const buildCellsArray = (length: number) => {
          const arr: ICell[] = new Array(length);
          for (let a = 0; a < length; a++) {
            arr[a] = { content: "", index: a };
          }
          return arr;
        };
        const newCellsArray = buildCellsArray(size.rows * size.columns);
        const tableToAdd: ITable = {
          displayName: "New Table " + state.tables.length ?? 0,
          objectId: "table-" + nanoid(),
          height: ((size.rows * defaultRowHeight) / pagePlayerAreaRect!.height) * 100, // where to get page player area size from to convert this to percent
          width: ((size.columns * defaultColumnWidth) / pagePlayerAreaRect!.width) * 100, // where to get page player araa size to convert this to percent
          rows: new Array(size.rows).fill(100 / size.rows + "%"), // contains size data for rows
          columns: new Array(size.columns).fill(100 / size.columns + "%"), // contains size data for columns
          cells: [...newCellsArray], // contains data for cell content and format
          border: "1px solid white",
          backgroundColor: undefined,
          type: "table",
          domRef: undefined,
          zIndex: state.objectList.length + 1,
          isDisplayed: true,
          //randomly generated position within the page player area
          top: 2 + state.tables.length,
          left: 2 + state.tables.length,
        };
        objectList.push(tableToAdd);
      });
      const newObjectList = addTable(state.objectList);
      return {
        ...state,
        ...updateObjectsList(state, { newObjectList }),
        // selectedObjects: [tableToAdd],
        // selectedObjectId: [tableToAdd.objectId],
        ...splitObjectsList(newObjectList ?? state.objectList),
        isCropping: false,
        needSave: true,
      };
    }

    case ObjectActionsType.UPDATE_TABLE: {
      const { type, value, selection } = action.payload;
      const defaultRowHeight = 50;
      const defaultColumnWidth = 100;
      const newCell = { content: "" };
      // const pagePlayerAreaRect = document.getElementById("pageplayerarea");
      const pagePlayerAreaRect = useUIStore.getState().designerViewportDims;
      const onePixPercentWidth = (1 / pagePlayerAreaRect!.width) * 100;
      const onePixPercentHeight = (1 / pagePlayerAreaRect!.height) * 100;
      const updateTable = produce((objectList: ObjectsState["objectList"]) => {
        const table = objectList.find((object) => object.objectId === state.selectedObjectId[0]);
        switch (type) {
          case "insertRow": {
            const cellsToInsert: ICell[] = new Array(table!.columns.length).fill(newCell);
            const cellsToInsertIndex: number = table!.columns.length * (value === "above" ? 0 : table!.rows.length);
            const newTableHeight = pagePlayerAreaRect!.height * (parseFloat(table.height) / 100) + defaultRowHeight; //calculates the pixle height of the new table
            const newRowPercent: string = (defaultRowHeight * 100) / newTableHeight + "%";
            const defaultRowHeightPercent = (defaultRowHeight / pagePlayerAreaRect!.height) * 100;

            const updateRowHeights = (rowPercent: string, index: number) => {
              const oldRowPercent = parseFloat(rowPercent) / 100; // converts the % string for each existing rows height into a decimal.
              const rowHeight = pagePlayerAreaRect!.height * (parseFloat(table.height) / 100) * oldRowPercent; // calculates the pixle height of each row.
              const newRowPercent = (rowHeight * 100) / newTableHeight + "%"; // calculates new % string based on new table pixle height and original row pixle height.
              return newRowPercent;
            };

            if (value === "above") {
              table!.cells.splice(cellsToInsertIndex, 0, ...cellsToInsert);
            } else {
              table!.cells.splice(cellsToInsertIndex, 0, ...cellsToInsert);
            }

            table.rows = table.rows.map(updateRowHeights);
            table.rows.splice(value === "before" ? 0 : table!.rows.length, 0, newRowPercent);
            table.height = parseFloat(table.height) + defaultRowHeightPercent;

            const cellIndexReset = (cell: ICell, index: number) => {
              cell.index = index;
              return { ...cell };
            };
            const newCells = table!.cells.map(cellIndexReset);
            table!.cells = [...newCells];
            break;
          }
          case "insertColumn": {
            const indexOfNewCells = []; /// index values for each new cell based on table size

            for (let rowNum = 0; rowNum < table.rows.length; rowNum++) {
              const index = rowNum * table.columns.length + selection;
              indexOfNewCells.push(index + (value === "before" ? 0 : 1));
            }

            for (let i = 0; i < indexOfNewCells.length; i++) table.cells.splice(indexOfNewCells[i] + i, 0, newCell);

            const newTableWidth = pagePlayerAreaRect!.width * (parseFloat(table.width) / 100) + defaultColumnWidth; //calculates the pixle width of the new table
            const newColumnPercent: string = (defaultColumnWidth * 100) / newTableWidth + "%";
            const defaultColumnWidthPercent = (defaultColumnWidth / pagePlayerAreaRect!.width) * 100;

            const updateColumnWidths = (columnPercent: string, index: number) => {
              const oldColumnPercent = parseFloat(columnPercent) / 100; // converts the % string for each existing column width into a decimal.
              const columnWidth = pagePlayerAreaRect!.width * (parseFloat(table.width) / 100) * oldColumnPercent; // calculates the pixle width of each column.
              const newColumnPercent = (columnWidth * 100) / newTableWidth + "%"; // calculates new % string based on new table pixle width and original column pixle width.
              return newColumnPercent;
            };

            table.columns = table.columns.map(updateColumnWidths);
            table.columns.splice(value === "before" ? selection : selection + 1, 0, newColumnPercent);

            const cellIndexReset = (cell: ICell, index: number) => {
              cell.index = index;
              return { ...cell };
            };

            table.cells = table.cells.map(cellIndexReset);
            table.width = parseFloat(table.width) + defaultColumnWidthPercent;
            break;
          }
          case "removeRow": {
            const selectedRowHeight = table.rows[selection];
            const originalTableHeightDecimalPercent = parseFloat(table.height) / 100;
            const originalTableHeightPx = pagePlayerAreaRect!.height * originalTableHeightDecimalPercent;

            const originalRowHeightDecimalPercent = parseFloat(selectedRowHeight) / 100;
            const originalRowheightPx = originalTableHeightPx * originalRowHeightDecimalPercent;
            const newTableHeightPx = originalTableHeightPx - originalRowheightPx; //calculates the pixle height of the new table
            const updateRowHeights = (rowPercent: string, index: number) => {
              const oldRowDecimalPercent = parseFloat(rowPercent) / 100; // converts the % string for each existing rows height into a decimal.
              const rowHeightPx = pagePlayerAreaRect!.height * (parseFloat(table.height) / 100) * oldRowDecimalPercent; // calculates the pixle height of each row.
              const newRowPercent = (rowHeightPx * 100) / newTableHeightPx + "%"; // calculates new % string based on new table pixle height and original row pixle height.
              return newRowPercent;
            };

            const removeRowCells = (cells: ICell[]) => {
              const cellsToRemoveStartIndex = selection * table.columns.length;
              const cellsToRemoveEndIndex = table.columns.length;

              table.cells.splice(cellsToRemoveStartIndex, cellsToRemoveEndIndex);
            };

            const cellIndexReset = (cell: ICell, index: number) => {
              cell.index = index;
              return { ...cell };
            };

            table.rows = table.rows.map(updateRowHeights);
            table.rows.splice(selection, 1);
            table.height = (newTableHeightPx / pagePlayerAreaRect!.height) * 100;

            removeRowCells(table.cells);
            table.cells.map(cellIndexReset);
            break;
          }
          case "removeColumn": {
            const selectedColumnWidth = table.columns[selection];
            const originalTableWidthDecimalPercent = parseFloat(table.width) / 100;
            const originalTableWidthPx = pagePlayerAreaRect!.width * originalTableWidthDecimalPercent;

            const originalColumnWidthDecimalPercent = parseFloat(selectedColumnWidth) / 100;
            const originalColumnWidthPx = originalTableWidthPx * originalColumnWidthDecimalPercent;
            const newTableWidthPx = originalTableWidthPx - originalColumnWidthPx; //calculates the pixle width of the new table
            const updateColumnWidths = (columnPercent: string, index: number) => {
              const oldColumnDecimalPercent = parseFloat(columnPercent) / 100; // converts the % string for each existing column width into a decimal.
              const columnWidthPx =
                pagePlayerAreaRect!.width * (parseFloat(table.width) / 100) * oldColumnDecimalPercent; // calculates the pixle width of each column.
              const newColumnPercent = (columnWidthPx * 100) / newTableWidthPx + "%"; // calculates new % string based on new table pixle width and original column pixle width.
              return newColumnPercent;
            };

            const removeColumnCells = () => {
              const indexOfCellsToRemove: any = []; /// index values for each new cell based on table size
              for (let rowNum = 0; rowNum < table.rows.length; rowNum++) {
                const index = rowNum * table.columns.length + selection;
                indexOfCellsToRemove.push(index);
              }

              for (let i = 0; i < indexOfCellsToRemove.length; i++) {
                table.cells.splice(indexOfCellsToRemove[i] - i, 1);
              }
            };

            const cellIndexReset = (cell: ICell, index: number) => {
              cell.index = index;
              return { ...cell };
            };

            table.columns = table.columns.map(updateColumnWidths);
            table.columns.splice(selection, 1);
            table.width = (newTableWidthPx / pagePlayerAreaRect!.width) * 100;
            removeColumnCells(table.cells);
            table.cells.map(cellIndexReset);
            break;
          }
          case "increaseRowHeight": {
            const updateRowHeights = (rowPercent: string, index: number) => {
              if (index === selection) {
                rowPercent = parseFloat(rowPercent) + onePixPercentHeight + "%";
              } else {
                rowPercent = parseFloat(rowPercent) - onePixPercentHeight / (table.rows.length - 1) + "%";
              }
              return rowPercent;
            };

            table.rows = table.rows.map(updateRowHeights);
            table.height = parseFloat(table.height) + onePixPercentHeight;
            break;
          }
          case "decreaseRowHeight": {
            const updateRowHeights = (rowPercent: string, index: number) => {
              if (index === selection) {
                rowPercent = parseFloat(rowPercent) - onePixPercentHeight + "%";
              } else {
                rowPercent = parseFloat(rowPercent) + onePixPercentHeight / (table.rows.length - 1) + "%";
              }
              return rowPercent;
            };

            table.rows = table.rows.map(updateRowHeights);
            table.height = parseFloat(table.height) - onePixPercentHeight;
            break;
          }
          case "increaseColumnWidth": {
            //             {
            //   "name": "Table 0",
            //   "position": {
            //     "x": "15%",
            //     "y": "15%",
            //     "z": 10
            //   },
            //   "objectId": "table-3ZwejtPLOzjitnDwLqtN_",
            //   "rows": [
            //     "25%",
            //     "25%",
            //     "25%",
            //     "25%"
            //   ],
            //   "columns": [
            //     "25%",
            //     "25%",
            //     "25%",
            //     "25%"
            //   ],
            //   "cells": [
            //     {
            //       "content": "",
            //       "index": 0
            //     },
            //     {
            //       "content": "",
            //       "index": 1
            //     },
            //     {
            //       "content": "",
            //       "index": 2
            //     },
            //     {
            //       "content": "",
            //       "index": 3
            //     },
            //     {
            //       "content": "",
            //       "index": 4
            //     },
            //     {
            //       "content": "",
            //       "index": 5
            //     },
            //     {
            //       "content": "",
            //       "index": 6
            //     },
            //     {
            //       "content": "",
            //       "index": 7
            //     },
            //     {
            //       "content": "",
            //       "index": 8
            //     },
            //     {
            //       "content": "",
            //       "index": 9
            //     },
            //     {
            //       "content": "",
            //       "index": 10
            //     },
            //     {
            //       "content": "",
            //       "index": 11
            //     },
            //     {
            //       "content": "",
            //       "index": 12
            //     },
            //     {
            //       "content": "",
            //       "index": 13
            //     },
            //     {
            //       "content": "",
            //       "index": 14
            //     },
            //     {
            //       "content": "",
            //       "index": 15
            //     }
            //   ],
            //   "border": "1px solid white",
            //   "zIndex": 202,
            //   "isDisplayed": true,
            //   "height": 28.86002886002886,
            //   "width": 33.47280334728033,
            //   "type": "table"
            // }
            const updateColumnWidths = (columnPercent: string, index: number) => {
              // Convert the columnPercent to a float
              let newColumnWidth = parseFloat(columnPercent);

              // Calculate the exact pixel change for the selected column
              const pixelChangeForSelected = (onePixPercentWidth * pagePlayerAreaRect!.width) / 100;

              // Calculate the percentage change for non-selected columns to keep their pixel width the same
              const percentChangeForOthers =
                ((pixelChangeForSelected / (table.columns.length - 1)) * 100) / pagePlayerAreaRect!.width;

              // If this is the selected column
              if (index === selection) {
                // Increase the width by onePixPercentWidth
                newColumnWidth += onePixPercentWidth;
              } else {
                // Decrease the width of the other columns by percentChangeForOthers to compensate for the increase
                newColumnWidth -= percentChangeForOthers;
              }

              return `${newColumnWidth}%`; // Convert back to a string with a percentage symbol
            };

            table.columns = table.columns.map(updateColumnWidths);
            table.width = parseFloat(table.width) + onePixPercentWidth;
            break;
          }
          case "decreaseColumnWidth": {
            const updateColumnWidths = (columnPercent: string, index: number) => {
              let newColumnWidth = parseFloat(columnPercent);
              if (index === selection) {
                newColumnWidth -= onePixPercentWidth;
              } else {
                newColumnWidth += onePixPercentWidth / (table.columns.length - 1);
              }
              return `${newColumnWidth}%`;
            };

            table.columns = table.columns.map(updateColumnWidths);
            table.width = parseFloat(table.width) - onePixPercentWidth;
            break;
          }
          case "updateCell": {
            if (table.cells[selection?.index] !== undefined) {
              table.cells[selection.index].content = value;
            }
            break;
          }
          case "backgroundColor": {
            table.backgroundColor = value;
            break;
          }
          case "borderColor": {
            table.border = "1px solid " + value;
            break;
          }
          case "updateTableSize": {
            break;
          }
        }
      });
      const newObjectList = updateTable(state.objectList);
      return {
        ...state,
        ...updateObjectsList(state, { newObjectList }),
        ...splitObjectsList(newObjectList ?? state.objectList),
        needSave: true,
      };
    }
    // copy paste
    case ObjectActionsType.COPY_OBJECTS: {
      const objectIds = state.selectedObjectId;
      const copyObjects = produce((objectList: ObjectsState["objectList"]) => {
        const objectsToCopy = state.selectedObjects;
      });
      const copyBuffer = copyObjects(state.selectedObjects);

      return {
        ...state,
        copyBuffer: copyBuffer,
        pasteInt: 0,
      };
    }
    case ObjectActionsType.PASTE_OBJECTS: {
      const pastedObjects = [];
      const pasteObjects = produce((objectList: ObjectsState["objectList"]) => {
        const newObjects = state.copyBuffer
          .map((object, index) => {
            const newObject = { ...object };
            newObject.objectId = nanoid();
            newObject.displayName = object.displayName + " copy " + state.pasteInt;
            newObject.zIndex = objectList.length + 1 + index;
            /**
             * replace the ids of cutouts
             */
            if (newObject.type === "pageImage") {
              if (newObject.blurCutoutShapes) {
                newObject.blurCutoutShapes = produce(newObject.blurCutoutShapes, (cutouts) => {
                  cutouts?.forEach((cutout) => {
                    cutout.id = nanoid();
                  });
                });
              }
            }
            // check if the object is a video and if there is already a video on the page dont paste it
            // also check if the object is of type SCORM and if there is already a SCORM on the page dont paste it
            if (
              (newObject.type === "video" && state.videos.length === 0 && state.scorms.length === 0) ||
              (newObject.type === "SCORM" && state.scorms.length === 0 && state.videos.length === 0) ||
              (newObject.type !== "video" && newObject.type !== "SCORM")
            ) {
              return newObject;
            }
          })
          .filter((object) => object !== undefined);

        objectList.push(...newObjects);
        pastedObjects.push(...newObjects);
      });

      const newObjectList = pasteObjects(state.objectList);
      if (pastedObjects.length === 0) {
        return state;
      } else {
        return {
          ...state,
          ...updateObjectsList(state, { newObjectList }),
          ...splitObjectsList(newObjectList ?? state.objectList),
          selectedObjects: pastedObjects,
          needSave: true,
          pasteInt: state.pasteInt + 1,
        };
      }
    }
    case ObjectActionsType.TOGGLE_OBJECT_GHOST: {
      const { objectId } = action;
      const setObjectGhost = produce((objectList: ObjectsState["objectList"]) => {
        runOnObject(objectList, objectId, (object) => {
          object.ghost = !object.ghost;
        });
      });
      const newObjectList = setObjectGhost(state.objectList);
      return {
        ...state,
        ...updateObjectsList(state, { newObjectList }),
      };
    }
    case ObjectActionsType.SET_BLUR_INTENSITY: {
      const { objectId, blurIntensity } = action.payload;
      const updateObject = produce((ol: ObjectsState["objectList"]) => {
        const object = ol.find((object) => object.objectId === objectId);
        if (!object) {
          return;
        }
        if (!object.blur) {
          object.blur = {};
        }
        object.blur.intensity = blurIntensity;
      });
      const newObjectList = updateObject(state.objectList);
      return {
        ...state,
        ...updateObjectsList(state, { newObjectList }),
        needSave: true,
      };
    }
    case ObjectActionsType.SET_BLUR_COLOR: {
      const { objectId, blurColor, blurOpacity, blurPickerFullHexColor } = action.payload;
      const updateObject = produce((ol: ObjectsState["objectList"]) => {
        const object = ol.find((object) => object.objectId === objectId);
        if (!object) {
          return;
        }
        if (!object.blur) {
          object.blur = {};
        }
        object.blur.color = blurColor;
        object.blur.opacity = blurOpacity;
        object.blur.pickerHexColor = blurPickerFullHexColor;
      });
      const newObjectList = updateObject(state.objectList);
      return {
        ...state,
        ...updateObjectsList(state, { newObjectList }),
        needSave: true,
      };
    }
    case ObjectActionsType.SET_BLUR_CUTOUT: {
      const { objectId, blurCutoutShapes } = action.payload;
      const updateObject = produce((ol: ObjectsState["objectList"]) => {
        const object = ol.find((object) => object.objectId === objectId);
        if (!object) {
          return;
        }
        if (!object.blurCutoutShapes) {
          object.blurCutoutShapes = [];
        }
        object.blurCutoutShapes = [...blurCutoutShapes];
      });
      const newObjectList = updateObject(state.objectList);
      return {
        ...state,
        ...updateObjectsList(state, { newObjectList }),
        needSave: true,
      };
    }
    case ObjectActionsType.ADD_BLUR_CUTOUT: {
      const { objectId, cutout } = action.payload;
      const updateObject = produce((ol: ObjectsState["objectList"]) => {
        const object = ol.find((object) => object.objectId === objectId);
        if (!object) {
          return;
        }
        if (!object.blurCutoutShapes) {
          object.blurCutoutShapes = [];
        }
        object.blurCutoutShapes.push(cutout);
      });
      const newObjectList = updateObject(state.objectList);
      return {
        ...state,
        ...updateObjectsList(state, { newObjectList }),
        needSave: true,
      };
    }
    case ObjectActionsType.UPDATE_BLUR_CUTOUT: {
      const { objectId, cutoutId, props: newProps } = action.payload;
      const updateObject = produce((ol: ObjectsState["objectList"]) => {
        const _objectId = objectId ?? state.selectedObjects[0].objectId;
        const object = ol.find((object) => object.objectId === _objectId);
        if (!object || !object.blurCutoutShapes) {
          return;
        }
        const currentCutout = object.blurCutoutShapes.find((c) => c.id === cutoutId);
        if (!currentCutout) {
          return;
        }
        Object.assign(currentCutout, newProps);
      });
      const newObjectList = updateObject(state.objectList);
      return {
        ...state,
        ...updateObjectsList(state, { newObjectList }),
        needSave: true,
      };
    }
    case ObjectActionsType.DELETE_BLUR_CUTOUT: {
      const { objectId, cutoutId } = action.payload;
      const updateObject = produce((ol: ObjectsState["objectList"]) => {
        const _objectId = objectId ?? state.selectedObjects[0].objectId;
        const object = ol.find((object) => object.objectId === _objectId);
        if (!object || !object.blurCutoutShapes) {
          return;
        }
        object.blurCutoutShapes = object.blurCutoutShapes.filter((c) => c.id !== cutoutId);
      });
      const newObjectList = updateObject(state.objectList);
      return {
        ...state,
        ...updateObjectsList(state, { newObjectList }),
        needSave: true,
      };
    }

    default:
      return state;
  }
};

export function ObjectsProvider({ children }: PropsWithChildren<any>) {
  const [state, dispatch] = useReducer(objectsReducer, initialState);
  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "z" && e.ctrlKey) {
        dispatch({ type: ObjectActionsType.GO_BACK_TO_PREVIOUS_OBJECT_STATE });
      }
      if (e.key === "y" && e.ctrlKey) {
        dispatch({ type: ObjectActionsType.REDO_TO_NEXT_OBJECT_STATE });
      }
      // if (e.key === "c" && e.ctrlKey) {
      //   dispatch({ type: ObjectActionsType.COPY_OBJECTS });
      // }
      // if (e.key === "v" && e.ctrlKey) {
      //   dispatch({ type: ObjectActionsType.PASTE_OBJECTS });
      // }
    });
  }, []);
  return (
    <ObjectsDispatch.Provider value={dispatch}>
      <ObjectsState.Provider value={state}>{children}</ObjectsState.Provider>
    </ObjectsDispatch.Provider>
  );
}

export function useObjectsDispatch() {
  const ctx = useContext(ObjectsDispatch);
  if (ctx === undefined) {
    throw new Error("Wrap component in ObjectsProvider");
  }
  return ctx as Dispatch<ObjectsAction>;
}

export function useObjectsState() {
  const ctx = useContext(ObjectsState);
  if (ctx === undefined) {
    throw new Error("Wrap component in ObjectsProvider");
  }
  return ctx as ObjectsState;
}

function splitObjectsList(objectList: BaseObject[]) {
  const images: BaseObject[] = [];
  const annotations: BaseObject[] = [];
  const textBoxes: BaseObject[] = [];
  const symbols: BaseObject[] = [];
  const videos: BaseObject[] = [];
  const tables: ITable[] = [];
  const scorms: BaseObject[] = [];
  const hotspots: HotspotObject[] = [];
  const smartObjects: SmartObject[] = [];
  const panoramicList: PanoramicObject[] = [];
  for (const object of objectList) {
    if (object && object.type)
      switch (true) {
        case object.type === "pageImage":
          images.push(object);
          break;
        case annotationTypes.has(object.type):
          annotations.push(object);
          break;
        case symbolTypes.has(object.type):
          annotations.push(object);
          break;
        case object.type === "textBlock":
          textBoxes.push(object);
          break;
        case object.type === "video":
          videos.push(object);
          break;
        case object.type === "table":
          tables.push(object);
          break;
        case object.type === "SCORM":
          scorms.push(object);
          break;
        case object.type === "hotspot":
          hotspots.push(object);
          break;
        case object?.type === "smartObject":
          smartObjects.push(object);
          break;
        case object.type === OBJECT_TYPE_PANORAMIC:
          panoramicList.push(object);
          break;
        default:
          console.error(`Object type ${object.type} not supported`, object);
          break;
      }
    else return;
  }

  return {
    images,
    annotations,
    symbols,
    textBoxes,
    videos,
    tables,
    scorms,
    hotspots,
    smartObjects,
    panoramicList,
  };
}

function updateSelectedObjects(oldSelectedObjects: BaseObject[], newObjectList: BaseObject[]) {
  const newSelectedObjects: BaseObject[] = [];
  // Convert newObjectList into a Map
  const newObjectMap = new Map(newObjectList.map((obj) => [obj.objectId, obj]));

  for (const oldSelectedObject of oldSelectedObjects) {
    const newSelectedObject = newObjectMap.get(oldSelectedObject.objectId);
    if (newSelectedObject) {
      newSelectedObjects.push({
        ...newSelectedObject,
        domRef: oldSelectedObject.domRef,
      });
    }
  }

  return {
    selectedObjects: newSelectedObjects,
  };
}
// should be run only if it is safe to mutate objectList
function runOnObjectList(objectList: BaseObject[], callback: (object: BaseObject) => void) {
  for (let i = 0; i < objectList.length; i++) {
    callback(objectList[i]);
  }
}
function runOnObject(objectList: BaseObject[], objectId: string, callback: (object: BaseObject) => void) {
  runOnObjectList(objectList, (object) => {
    if (object.objectId === objectId) {
      callback(object);
    }
  });
}

function updateObjectsList(
  state: ObjectsState,
  {
    newObjectList,
    newAnimatedObjects,
  }: {
    newObjectList?: BaseObject[];
    newAnimatedObjects?: AnimatedObjectManifest[];
  },
) {
  const newObjectListHistory = [...state.objectListHistory, state.objectList];
  const newAnimatedObjectsHistory = [...state.animatedObjectsHistory, state.animatedObjects];
  if (newObjectListHistory.length > UNDO_REDO_LIMIT) {
    newObjectListHistory.shift();
  }
  if (newAnimatedObjectsHistory.length > UNDO_REDO_LIMIT) {
    newAnimatedObjectsHistory.shift();
  }
  return {
    objectList: newObjectList ?? state.objectList,
    animatedObjects: newAnimatedObjects ?? state.animatedObjects,
    objectListHistory: newObjectListHistory,
    animatedObjectsHistory: newAnimatedObjectsHistory,
    ...updateSelectedObjects(state.selectedObjects, newObjectList ?? state.objectList),
    ...splitObjectsList(newObjectList ?? state.objectList),
  };
}

export function useObjectsUtils() {
  const dispatch = useObjectsDispatch();

  return {
    needtoSave: () =>
      dispatch({
        type: ObjectActionsType.SET_NEED_SAVE,
      }),
    unselectAll: () =>
      dispatch({
        type: ObjectActionsType.SET_SELECTED_OBJECT,
        payload: null,
      }),
  };
}
