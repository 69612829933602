import React from "react";
import { ElementTypes } from "../../../pageTypes/BasicPage_Player/components/IBasePage";
import HighlightAnnotation from "../../Annotation/hooks/HighlightAnnotation";
import { handleKeyUp } from "../../ObjectPropertyBox/functions/PropertyBoxFunctions";
import { SymbolComponentType } from "../helpers/getSymbol";

const AircraftSymbol = (props: SymbolComponentType) => {
  const { index, attributes, kp, transformStr, handleKeyPress, markSelected, objectIsInTimeline } = props;
  const isDisplayed = typeof attributes.isDisplayed === "undefined" || attributes.isDisplayed;
  const opacity = objectIsInTimeline ? 1 : isDisplayed ? 1 : 0.5;

  const symbols: ElementTypes = "symbols";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14.94 12.66"
      key={index}
      id={`symbol-${index}`}
      tabIndex={0}
      className="symbol target"
      name="symbol"
      onMouseDown={(e: React.MouseEvent<SVGSVGElement>) => markSelected(e.currentTarget, index, symbols)}
      onKeyDown={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyPress(e, kp)}
      onKeyUp={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyUp(e, kp)}
      preserveAspectRatio="xMidYMid meet"
      style={{
        top: `${attributes.top}%`,
        left: `${attributes.left}%`,
        width: `${attributes.width}%`,
        height: `${attributes.height}%`,
        zIndex: attributes.zIndex,
        transform: transformStr,
        opacity,
        // `rotate(${attributes?.transform?.rotate}deg) scaleX(${attributes?.transform?.scaleX}) scaleY(${attributes?.transform?.scaleY}) matrix3d(${attributes?.transform?.matrix3d})`
      }}
    >
      <path
        stroke={`${attributes.borderColor || "white"}`}
        fill={`${attributes?.backgroundColor || "white"}`}
        strokeWidth={`${attributes.lineWidth || 0.1}`}
        // className="aircraft_symbol"
        d="M 6.3,0 H 5.42 V 5.78 H 0 v 0.87 h 5.42 v 5.26 H 3.24 v 0.75 H 5.42 6.3 V 11.91 6.65 5.78 0 Z"
      />
      <path
        stroke={`${attributes.borderColor || "white"}`}
        fill={`${attributes?.backgroundColor || "white"}`}
        strokeWidth={`${attributes.lineWidth || 0.1}`}
        // className="aircraft_symbol"
        d="M 9.52,0 H 8.65 v 5.78 0.87 5.26 0.75 H 9.52 11.7 V 11.91 H 9.52 V 6.65 h 5.42 V 5.78 H 9.52 V 0 Z"
      />
      <HighlightAnnotation annotation={attributes as any} id={"glow-rect-for-symbols"} />
    </svg>
  );
};

export default AircraftSymbol;
