import React, { Component } from "react";
import genericRepositoryService from "../../services/genericRepositoryService";

//this HTML needs to look better... sooner would be nice :)
export const ErrorDisplay = () => (
  <div
    style={{
      height: "100%",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    }}
  >
    Apologies, we are currently experiencing an issue.{" "}
    <a style={{ color: "blue", cursor: "pointer" }} onClick={() => window.location.reload()} href="#">
      Please check back soon.
    </a>
  </div>
);

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // https://www.howtogeek.com/devops/how-to-use-reacts-error-boundaries-to-catch-crashes/   compares these 2 approaches

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    for (let props in error) {
    }

    let issueData = {
      tag: "ErrorBoundary",
      identifyingInfo: "",
      error: error,
      errorInfo: errorInfo,
    };
    // this stack trace won't be very useful with minified js symbol names...
    genericRepositoryService.logIssue(issueData);
  }

  render() {
    if (this.state.hasError) {
      return ErrorDisplay;
    }
    return this.props.children;
  }
}
