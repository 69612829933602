import "../../Annotation/AnnotationTextMenu/AnnotationTextMenu.css";
import _ from "lodash";
import React, { useEffect, useRef, useContext, CSSProperties } from "react";
import { colors as standard, bgColors as standardHighlight } from "../../Annotation/AnnotationTextMenu/constants";
import buttons from "../../Annotation/AnnotationTextMenu/helpers/buttons";
import { BasePageTextBlockVersion } from "../../../utils/Versioning/basePagesVersioning";

import { ILessonAdditionalSettingsContext, LessonAdditionalSettingsContext } from "../../../routes/builderContexts";

// import { ReactComponent as ClearFormatting } from "../../assets/icons/Common/remove-formatting-white.svg";
import { getFontsFromVersion } from "../../Annotation/AnnotationTextMenu/constants";
// import { IBasicPageAttributes } from '../../../pageTypes/BasicPage_Player/components/IBasePage';
// import ISymbolStyle from '../../Symbol/models/ISymbolStyle';
// import { FormatType, IAnnotation } from '../models/IAnnotation';
// import FillButton from '../../WYSIWYGToolbar/components/FillButton';

type TextMenuType = {
  bgColors?: string[];
  fontColors?: string[];
  isLabel?: boolean;
  hide?: boolean;
  formats?: Array<string>;
  position?: { top: number; left: number };
  version?: BasePageTextBlockVersion | string;
  id?: string;
  style: CSSProperties;
};

export function LabelTextEditorMenu(props: TextMenuType) {
  const { bgColors, fontColors, formats, id } = props;
  const highlightRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const currentColors = useContext<ILessonAdditionalSettingsContext>(LessonAdditionalSettingsContext)?.getColorsList(
    standard,
  );

  useEffect(() => {
    buttons();
  });

  return (
    <div
      className="text-format-menu"
      id={id}
      onMouseDown={(e) => e.stopPropagation()}
      onMouseUp={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
      style={{
        display: props.hide ? "none" : "flex",
        top: 0,
        left: 0,
        ...props.style,
      }}
    >
      <FontSizeButton version={props.version} />
      <FontColorButton colors={fontColors ? fontColors : currentColors} />
      <TextHighlightButton colors={bgColors ? bgColors : standardHighlight} highlightRef={highlightRef} />
      <FontStyleButtons />
      <AlignButtons />
      <ListButtons />
      {!props?.isLabel && (
        <button className="ql-clearFormat">
          {/* <ClearFormatting  title='Remove Formatting: (Shift + Alt + F) removes all formatting'/> */}
        </button>
      )}
    </div>
  );
}

type FontColorType = {
  colors?: string[];
};

const FontColorButton = (props: FontColorType) => {
  const { colors } = props;

  function makeSelectColors() {
    return colors?.map((color) => {
      return <option key={color} value={`${color}`}></option>;
    });
  }

  return (
    <div className="toolbar-btn-container">
      <select className="ql-color wysiwyg-toolbar-btn">{makeSelectColors()}</select>
    </div>
  );
};
type FontSizeType = {
  fontSizes?: Array<string>;
  version?: BasePageTextBlockVersion | string;
};

const FontSizeButton = (props: FontSizeType) => {
  const fontSizes: string[] = getFontsFromVersion(props.version);
  //please let austin know if the names need to be modified (not added)
  const sizeMap = {
    [fontSizes[0]]: "tiny",
    [fontSizes[1]]: "tiny2",
    [fontSizes[2]]: "small",
    [fontSizes[3]]: "small2",
    [fontSizes[4]]: "small3",
    [fontSizes[5]]: "normal",
    [fontSizes[6]]: "normal2",
    [fontSizes[7]]: "normal3",
    [fontSizes[8]]: "large",
    [fontSizes[9]]: "huge",
  };

  return (
    <select
      className="ql-size wysiwyg-toolbar-btn"
      value={fontSizes[5]}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
      onChange={() => {
        //
      }}
    >
      {fontSizes.map((size, i) => {
        return (
          <option key={size + i + "sd"} value={fontSizes[i]}>
            {sizeMap[size]}
          </option>
        );
      })}
    </select>
  );
};
type HighlightType = {
  colors: Array<string>;
  highlightRef: React.RefObject<HTMLDivElement>;
};

const TextHighlightButton = (props: HighlightType) => {
  const { colors, highlightRef } = props;

  function makeSelectColors() {
    return colors?.map((color) => {
      return <option key={color} value={`${color}`}></option>;
    });
  }

  return (
    <div ref={highlightRef} className="toolbar-btn-container">
      <select className="ql-background wysiwyg-toolbar-btn">{makeSelectColors()}</select>
    </div>
  );
};
const FontStyleButtons = () => {
  return (
    <React.Fragment>
      <button id="bold" className={`ql-bold wysiwyg-toolbar-btn`} type="button">
        <span className="icon-bold-icon"></span>
      </button>
      <button className={`ql-italic wysiwyg-toolbar-btn`}>
        <span className="icon-italic-icon"></span>
      </button>
      <button className={`ql-underline wysiwyg-toolbar-btn`}>
        <span className="icon-underline-icon"></span>
      </button>
    </React.Fragment>
  );
};

const AlignButtons = () => {
  return (
    <React.Fragment>
      <button id="left" className={`ql-align wysiwyg-toolbar-btn`} value="">
        <span className="icon-text-align-left-icon"></span>
      </button>
      <button id="center" className={`ql-align wysiwyg-toolbar-btn`} value="center">
        <span className="icon-text-align-center-icon"></span>
      </button>
      <button id="right" className={`ql-align wysiwyg-toolbar-btn`} value="right">
        <span className="icon-text-align-right-icons"></span>
      </button>
    </React.Fragment>
  );
};

const ListButtons = () => {
  return (
    <React.Fragment>
      <button className="ql-list wysiwyg-toolbar-btn" value="bullet">
        <span className="icon-text-bullet-points-icon"></span>
      </button>
      <button className="ql-list wysiwyg-toolbar-btn" value="ordered">
        <span className="icon-text-numbers-icon"></span>
      </button>
    </React.Fragment>
  );
};
