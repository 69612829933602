import { useMemo } from "react";
import { Fill, ImageSwapPanel, ImageTextPanel, RotatePanel, TextPanel } from ".";
import { SVG_SMART_OBJECT_TYPES } from "../../..";
import { useMetaVariableStore } from "../../../store";

const normalizeName = (name?: string) => (name ? name.toLowerCase().replaceAll("_", " ") : "");

export const useAdvancedPanelData = (objectId: string) => {
  const data = useMetaVariableStore((s) => s.metaVariablesData);

  const AdvancedPanelData = useMemo(() => {
    const imageSwap: ImageSwapPanel[] = [];
    const rotate: RotatePanel[] = [];
    const fill: Fill[] = [];
    const texts: TextPanel[] = [];
    const imageTexts: ImageTextPanel[] = [];

    for (const key of Object.keys(data)) {
      if (!key.includes(objectId)) continue;

      const name = normalizeName(data[key].name);
      const choices = data[key].choices;

      switch (data[key].type) {
        case SVG_SMART_OBJECT_TYPES.IMAGE_SWAP: {
          imageSwap.push({
            name,
            choices,
            key: data[key].key,
            hotspots: data[key].hotspots ?? [],
            animate: data[key].animate ?? false,
            rate: data[key].rate ?? 500,
            hide: data[key].hide ?? false,
          });

          break;
        }

        case SVG_SMART_OBJECT_TYPES.ROTATE: {
          rotate.push({
            name,
            minValue: data[key].minValue as number,
            maxValue: data[key].maxValue as number,
            minDegree: data[key].minDegree as number,
            maxDegree: data[key].maxDegree as number,
            key,
          });
          break;
        }

        case SVG_SMART_OBJECT_TYPES.FILL: {
          fill.push({
            name,
            choices,
            key,
          });

          break;
        }

        case SVG_SMART_OBJECT_TYPES.DYNAMIC_TEXT: {
          texts.push({
            name,
            key,
          });
          break;
        }

        case SVG_SMART_OBJECT_TYPES.IMAGE_TEXT: {
          imageTexts.push({
            name,
            key,
            font: data[key].font as string,
            stack: data[key].stack as string,
            justify: data[key].justify as string,
          });
          break;
        }

        default:
          break;
      }
    }

    return {
      imageSwap,
      rotate,
      fill,
      texts,
      imageTexts,
    };
  }, [objectId, data]);

  return AdvancedPanelData;
};
