import {
  InteractivityHotspotActionTypes,
  useInteracitvityHotspotDispatch,
} from "../../contexts/InteractivityHotspotProvider";
import { useObjectsState, ObjectActionsType, useObjectsDispatch } from "../../contexts/ObjectsProvider";
import { useObjectPosition } from "../../hooks/useObjectPosition";
import { ReactComponent as Icon } from "../../assets/icons/AssetManager/smart-object.svg";
import styles from "./SmartObject.module.css";
import { SmartObjectRenderer } from "../../lib/SmartObject/components/SmartObjectRenderer";
import React, { useMemo } from "react";
import { objectOpacityFromRules } from "../../utils";
import { useObjectIsInTime } from "../../hooks/useObjectIsInTime";

interface SmartObjectsProps {
  handleDoubleClick: (e: React.MouseEvent<HTMLDivElement | HTMLVideoElement>) => void;
}

export const SmartObjects = ({ handleDoubleClick }: SmartObjectsProps) => {
  const { smartObjects } = useObjectsState();

  return (
    <>
      {smartObjects.map((item) => {
        return <SmartObject key={item.objectId} handleDoubleClick={handleDoubleClick} {...item} />;
      })}
    </>
  );
};

interface SmartObjectProps {
  objectId: string;
  left: number;
  top: number;
  width: number;
  height: number;
  ghost?: boolean;
  /**@deprecated */
  rotate?: number;
  rotation?: number;
  opacity?: number;
  pitch?: number;
  yaw?: number;
  isDisplayed?: boolean;
  zIndex?: number;
  elementList?: any;
  handleDoubleClick: (e: React.MouseEvent<HTMLDivElement | HTMLVideoElement>) => void;
}

const SmartObject = ({
  objectId,
  left,
  top,
  width,
  height,
  rotation,
  opacity,
  zIndex,
  elementList,
  isDisplayed,
  ghost = false,
  handleDoubleClick,
}: SmartObjectProps) => {
  const {
    position: [x, y],
    rotation: finalRotation,
    size: [widthWithFrames, heightWithFrames],
    opacity: opacityValue,
  } = useObjectPosition(objectId, top, left, width, height, rotation, opacity);
  const objectDispatch = useObjectsDispatch();
  const hotspotsDispatch = useInteracitvityHotspotDispatch();
  const rotateString = typeof finalRotation === "number" ? `rotate(${finalRotation}deg)` : "rotate(0deg)";
  const transform = `translate(${x}px, ${y}px) ${rotateString}`;
  const isInTime = useObjectIsInTime(objectId);
  const finalOpacity = objectOpacityFromRules(opacityValue, isDisplayed, isInTime, ghost);

  const smartObject = useMemo(() => {
    return {
      objectId,
      height: heightWithFrames,
      width: widthWithFrames,
      left,
      top,
      elementList,
    };
  }, [objectId, heightWithFrames, widthWithFrames, top, left]);

  return (
    <div
      className={elementList ? "" : styles.empty}
      data-objectId={objectId}
      style={{
        position: "absolute",
        width: `${widthWithFrames}px`,
        height: `${heightWithFrames}px`,
        opacity: finalOpacity,
        zIndex,
        transform,
        rotate: `${rotateString}`,
        display: isInTime || ghost ? undefined : "none",
      }}
      onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        hotspotsDispatch({
          type: InteractivityHotspotActionTypes.SET_CURRENT_HOTSPOT,
          payload: null,
        });
        if (!objectId) return;
        let type = ObjectActionsType.SET_SELECTED_OBJECT;
        if (e.ctrlKey) {
          type = ObjectActionsType.ADD_SELECTED_OBJECT;
        }
        objectDispatch({
          type,
          payload: { objectId },
        });
      }}
      onDoubleClick={(e: React.MouseEvent<HTMLDivElement>) => handleDoubleClick(e)}
    >
      {elementList ? (
        <div className={styles.container}>
          <SmartObjectRenderer smartObject={smartObject} />
        </div>
      ) : (
        <span className={styles.icon}>
          <Icon
            style={{
              width: "80%",
              height: "80%",
            }}
          />
        </span>
      )}
    </div>
  );
};
