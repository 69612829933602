import React from "react";
import { useLessonData } from "../../../contexts/LessonDataProvider";
import { Manufacturer, AircraftFamilyMember, AircraftVariant } from "../../../contexts/utils/createFormattedAircrafts";
import { useLessonSettingsDispatch } from "../LessonSettingsContext/LessonSettingsProvider";
import { IAircraft } from "../../../models/IGetAircraftLessonData";

const AircraftSpecificitySelector = (props: any) => {
  const [lessonData, formattedAircrafts] = useLessonData({
    formattedAircrafts: true,
  });
  const [currentAircraft, setCurrentAircraft]: [any, React.Dispatch<React.SetStateAction<any>>] = React.useState({
    manufacturer: "Airbus",
    aircraftFamily: "A220",
    aircraft: "",
    configuration: "",
  });
  const settingsDispatch = useLessonSettingsDispatch();
  const loadingDone = React.useMemo(() => {
    return Object.entries(lessonData).length > 0 && Object.entries(formattedAircrafts).length > 0;
  }, [lessonData, formattedAircrafts]);

  React.useEffect(() => {
    getCurrentAircraftBasedOnSpecificity();
  }, [props.specificity.manufacturerId, props.specificity.aircraftFamilyId, props.specificity.aircraftId]);

  function noneCheck(value: string) {
    if (value === "None") {
      return "";
    }
    return value;
  }

  function getCurrentAircraftBasedOnSpecificity() {
    const spec: {
      manufacturerId: number | null;
      aircraftFamilyId: number | null;
      aircraftId: number | null;
    } = {
      manufacturerId: null,
      aircraftFamilyId: null,
      aircraftId: null,
    };
    if (props.specificity.aircraftId) {
      const craft = lessonData.aircrafts.find((a) => {
        return a.aircraftId === props.specificity.aircraftId;
      });
      spec.manufacturerId = craft?.manufacturerId!;
      spec.aircraftFamilyId = craft?.aircraftFamilyId!;
      spec.aircraftId = craft?.aircraftId!;
    } else if (props.specificity.aircraftFamilyId) {
      const craft = lessonData.aircrafts.find((a) => {
        return a.aircraftFamilyId === props.specificity.aircraftFamilyId;
      });
      spec.manufacturerId = craft?.manufacturerId!;
      spec.aircraftFamilyId = craft?.aircraftFamilyId!;
      spec.aircraftId = null;
    } else if (props.specificity.manufacturerId) {
      const craft = lessonData.aircrafts.find((a) => {
        return a.manufacturerId === props.specificity.manufacturerId;
      });
      spec.manufacturerId = craft?.manufacturerId!;
      spec.aircraftFamilyId = null;
      spec.aircraftId = null;
    } else {
    }
    const m = lessonData.aircrafts.find((a) => {
      return a.manufacturerId === spec.manufacturerId;
    })?.manufacturerName;
    const af = lessonData.aircrafts.find((a) => {
      return a.aircraftFamilyId === spec.aircraftFamilyId;
    })?.aircraftFamilyName;
    const ac = lessonData.aircrafts.find((a) => {
      return a.aircraftId === spec.aircraftId;
    })?.aircraftName;

    setCurrentAircraft({
      manufacturer: m ? m : "None",
      aircraftFamily: af ? af : "None",
      aircraft: ac ? ac : "None",
      configuration: "",
    });
  }

  return loadingDone ? (
    <div className={props.className}>
      <div>
        <p>Manufacturer</p>
        <select
          disabled={props.disabled.manufacturer}
          value={currentAircraft.manufacturer}
          onChange={(e) => {
            e.persist();
            const aircraftFamilyNames = Object.keys(formattedAircrafts[e.target.value].fleets);
            const aircraftName = Object.keys(
              formattedAircrafts[e.target.value].fleets[aircraftFamilyNames[0]].variants,
            )[0];
            setCurrentAircraft((aircraft: any) => {
              if (e.target.value === "None") {
                return {
                  manufacturer: "None",
                  aircraftFamily: "None",
                  aircraft: "None",
                };
              } else {
                return {
                  manufacturer: e.target.value,
                  aircraftFamily: aircraftFamilyNames[0],
                  aircraft: aircraftName,
                  configuration: "None",
                };
              }
            });
            const manufacturerId = Manufacturer.getIdByName(lessonData.aircrafts, noneCheck(e.target.value));
            const aircraftFamilyId = AircraftFamilyMember.getIdByName(
              lessonData.aircrafts,
              noneCheck(aircraftFamilyNames[0]),
            );
            const aircraftId = AircraftVariant.getIdByName(lessonData.aircrafts, noneCheck(aircraftName));
            settingsDispatch({
              type: "UPDATE_LESSON_META_DATA_LOCAL_COPY",
              payload: { manufacturerId, aircraftFamilyId, aircraftId },
            });
          }}
        >
          {Object.keys(formattedAircrafts).map((manufacturer, index) => {
            return <option key={"lsfa" + index}>{manufacturer}</option>;
          })}
        </select>
      </div>
      {/* //////////////////////////////////////////////////////////////////////////////////////////////////////// */}

      <div>
        <p>Fleet</p>

        <select
          disabled={props.disabled.aircraftFamily}
          value={currentAircraft.aircraftFamily}
          onChange={(e) => {
            e.persist();

            const aircraftName = noneCheck(e.target.value)
              ? Object.keys(formattedAircrafts[currentAircraft.manufacturer].fleets[e.target.value].variants)[0]
              : "None";
            setCurrentAircraft((aircraft: any) => {
              if (e.target.value === "None") {
                return {
                  manufacturer: aircraft.manufacturer,
                  aircraftFamily: "None",
                  aircraft: "None",
                  configuration: "None",
                };
              } else {
                return {
                  manufacturer: aircraft.manufacturer,
                  aircraftFamily: e.target.value,
                  aircraft: aircraftName,
                  configuration: "None",
                };
              }
            });

            const aircraftFamilyId = AircraftFamilyMember.getIdByName(lessonData.aircrafts, noneCheck(e.target.value));
            const aircraftId = AircraftVariant.getIdByName(lessonData.aircrafts, noneCheck(aircraftName));
            settingsDispatch({
              type: "UPDATE_LESSON_META_DATA_LOCAL_COPY",
              payload: { aircraftFamilyId, aircraftId },
            });
          }}
        >
          {currentAircraft.manufacturer === "None" ? (
            <option>None</option>
          ) : (
            Object.keys(formattedAircrafts[currentAircraft.manufacturer].fleets).map((fleet, index) => {
              return <option key={"lsfaf" + index}>{fleet}</option>;
            })
          )}
        </select>
      </div>

      {/* /////////////////////////////////////////////////////////////////////////////////////////////// */}

      <div>
        <p>Variant</p>

        <select
          disabled={props.disabled.aircraft}
          value={currentAircraft.aircraft}
          onChange={(e) => {
            e.persist();
            setCurrentAircraft((aircraft: any) => {
              if (e.target.value === "None") {
                return {
                  manufacturer: aircraft.manufacturer,
                  aircraftFamily: aircraft.aircraftFamily,
                  aircraft: "None",
                  configuration: "None",
                };
              } else {
                return {
                  manufacturer: aircraft.manufacturer,
                  aircraftFamily: aircraft.aircraftFamily,
                  aircraft: e.target.value,
                  configuration: "None",
                };
              }
            });
            const aircraftId = AircraftVariant.getIdByName(lessonData.aircrafts, noneCheck(e.target.value));
            settingsDispatch({
              type: "UPDATE_LESSON_META_DATA_LOCAL_COPY",
              payload: { aircraftId },
            });
          }}
        >
          {currentAircraft.aircraftFamily === "None" ? (
            <option>None</option>
          ) : (
            Object.keys(
              formattedAircrafts[currentAircraft.manufacturer].fleets[currentAircraft.aircraftFamily].variants,
            ).map((variant, index) => {
              return <option key={"lsfava" + index}>{variant}</option>;
            })
          )}
        </select>
      </div>
    </div>
  ) : null;
};

export default AircraftSpecificitySelector;

//utils

function initialIdsToNamesConverter(ids: any, aircrafts: IAircraft[]) {
  if (ids.aircraftId !== null) {
  }

  const manufacturerName = aircrafts.find((aircraft) => {
    return aircraft.manufacturerId === ids.manufacturerId;
  })?.manufacturerName;
  return manufacturerName;
}
