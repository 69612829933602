import React, { useContext, useState, useEffect, SetStateAction } from "react";
import { PageContext, IPageContext } from "../routes/builderContexts";
import ReactJson from "react-json-view";
import { Modal, Button } from "react-bootstrap";

export interface IGenericDesignerProps {
  show: boolean;
}

const AdvancedDesigner: React.FC<IGenericDesignerProps> = (properties: IGenericDesignerProps) => {
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);

  const [advancedEditorManifestJson, setAdvancedEditorManifestJson]: [object, React.Dispatch<SetStateAction<object>>] =
    useState<object>({});
  useEffect(() => {
    changeAdvancedEditorManifestJson(pageContext.pageManifest);
  }, [pageContext.currentlyDisplayedPageIndex]);

  const changeAdvancedEditorManifestJson = (updatedManifestValue: object) => {
    setAdvancedEditorManifestJson(updatedManifestValue);
  };

  return (
    <Modal
      className="advanced-edit"
      show={properties.show}
      onHide={() => {
        pageContext.showAdvancedEditor(false);
      }}
      size="xl"
      scrollable={true}
    >
      <Modal.Header>
        <Modal.Title>Advanced Editor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReactJson
          theme="hopscotch"
          src={pageContext.pageManifest}
          onAdd={({ updated_src }) => changeAdvancedEditorManifestJson(updated_src)}
          onEdit={({ updated_src }) => changeAdvancedEditorManifestJson(updated_src)}
          onDelete={({ updated_src }) => changeAdvancedEditorManifestJson(updated_src)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => {
            pageContext.showAdvancedEditor(false);
          }}
        >
          Discard Changes
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            pageContext.updatePageManifest(advancedEditorManifestJson);
            pageContext.showAdvancedEditor(false);
          }}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AdvancedDesigner;
