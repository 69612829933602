import "../../SymbolsMenu/SymbolsMenu.css";
import "./AnnotationMenu.css";
import React, { useState } from "react";
import AnnotationMenuLines from "./components/AnnotationMenuLines";
import AnnotationMenuShapes from "./components/AnnotationMenuShapes";
import AnnotationMenuLabels from "./components/AnnotationMenuLabels";
import AnnotationMenuNumbers from "./components/AnnotationMenuNumbers";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/HeaderIcons/drop-down.svg";
import SymbolsMenu from "../../SymbolsMenu/SymbolsMenu";

type MenuType = {
  isSymbolsMenuShown: boolean;
  menuRef: React.RefObject<HTMLDivElement>;
  addNewAnnotation: (value: string, addFunction: any) => void;
  setIsSymbolsMenuShown: (boolean: boolean) => void;
  addSymbolOLD: any;
};

export type AnnotationMenuIconType = {
  name: string;
  icon: any;
};

export type AnnotationMenuPropType = {
  addNewAnnotation(value: string, funcName: any): void;
  // selectedAnnotation: string
  // setSelectedAnnotation: (value: string) => void
};

const AnnotationMenu = (props: MenuType) => {
  const { isSymbolsMenuShown, menuRef, addNewAnnotation, setIsSymbolsMenuShown, addSymbolOLD } = props;

  function leaveSymbolSection(e: React.MouseEvent<HTMLDivElement>) {
    setIsSymbolsMenuShown(false);
  }

  return (
    <React.Fragment>
      <div ref={menuRef} id="annotation-menu">
        {/* <div id="annotation-menu-header">
          <span>Annotations</span>
        </div> */}
        <div id="annotation-menu-body">
          <div className="annotation-type-container">
            <h6>Lines</h6>
            <div id="line-container" className="annotation-menu-divider">
              <AnnotationMenuLines addNewAnnotation={addNewAnnotation} />
            </div>
          </div>
          <div className="annotation-type-container">
            <h6>Shapes</h6>
            <div id="shape-container" className="annotation-menu-divider">
              <AnnotationMenuShapes addNewAnnotation={addNewAnnotation} />
            </div>
          </div>
          <div className="annotation-type-container">
            <h6>Numbers</h6>
            <div id="number-container" className="annotation-menu-divider">
              <AnnotationMenuNumbers addNewAnnotation={addNewAnnotation} />
            </div>
          </div>
          <div className="annotation-type-container">
            <h6>Labels</h6>
            <div id="label-container" className="annotation-menu-divider">
              <AnnotationMenuLabels addNewAnnotation={addNewAnnotation} />
            </div>
          </div>
          <div
            className="annotation-type-container symbol-menu-area"
            onMouseEnter={() => setIsSymbolsMenuShown(true)}
            onMouseLeave={(e: React.MouseEvent<HTMLDivElement>) => leaveSymbolSection(e)}
          >
            <ArrowIcon className="header-preview-svg annotation-symbol-arrow" />
            <h6>Symbols</h6>
            {isSymbolsMenuShown ? (
              <SymbolsMenu addNewAnnotation={addNewAnnotation} addSymbolOLD={addSymbolOLD} />
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* <div id="annotation-menu-footer">
          <div></div>
          <div>
            <div className='button' onClick={handleClose}>Close</div>
            <div className='button' onClick={handleInsert}>Insert</div>
          </div>
        </div> */}
      </div>
    </React.Fragment>
  );
};

export default AnnotationMenu;
