import React, { useRef, useEffect } from "react";
import { extend, useFrame, useThree } from "@react-three/fiber";
import { CustomOrbitControls } from "../hotSpotEditor/customOControls";
import "../../CPaT3d_Player/components/hotSpotEditor/hsEditor.css";
import * as THREE from "three";

extend({ CustomOrbitControls });

const offset = 7;
const Controls = (props) => {
  const { camera, gl } = useThree();
  const camControls = useRef(null);
  // this is a 3D vector that the camera 'orbits' around
  const focusPointRef = useRef(new THREE.Vector3(-5, -100, -10));
  // arbitrary offset for the point where the camera is orbiting around
  // this is only applied for the x and z axes
  useEffect(() => {
    if (!props.boundaries) return;
    camera.position.set(0, (props.boundaries.max.y + props.boundaries.min.y) / 2, -10);
    camera.rotation.set(15, 0, 20);
    camera.far = 5000;
    camera.near = 0.01;
    camera.fov = 75;
    focusPointRef.current = new THREE.Vector3(0, props.boundaries.max.y - 2, 0); //.position.set(-5,props.boundaries.max.y-0.7,20)
  }, [props.boundaries]);

  useFrame(({ camera }) => {
    if (!focusPointRef.current || !camControls.current || !props.boundaries) return;
    camera.updateProjectionMatrix(
      void ((camControls.current.target = focusPointRef.current),
      camera.lookAt(focusPointRef.current),
      camControls.current.update()),
    );
  });

  useFrame(() => {
    if (!props.boundaries || !focusPointRef.current) return;
    if (camera.position.x < props.boundaries.min.x) {
      camera.position.x = props.boundaries.min.x;
    }
    if (camera.position.x > props.boundaries.max.x) {
      camera.position.x = props.boundaries.max.x;
    }
    if (camera.position.z < props.boundaries.min.z) {
      camera.position.z = props.boundaries.min.z;
    }
    if (camera.position.z > props.boundaries.max.z) {
      camera.position.z = props.boundaries.max.z;
    }
    if (camera.position.y < props.boundaries.min.y) {
      camera.position.y = props.boundaries.min.y;
    }
    if (camera.position.y > props.boundaries.max.y) {
      camera.position.y = props.boundaries.max.y;
    }

    if (focusPointRef.current.x < props.boundaries.min.x - offset) {
      focusPointRef.current.x = props.boundaries.min.x - offset;
    }
    if (focusPointRef.current.x > props.boundaries.max.x + offset) {
      focusPointRef.current.x = props.boundaries.max.x + offset;
    }
    if (focusPointRef.current.z < props.boundaries.min.z - offset) {
      focusPointRef.current.z = props.boundaries.min.z - offset;
    }
    if (focusPointRef.current.z > props.boundaries.max.z + offset) {
      focusPointRef.current.z = props.boundaries.max.z + offset;
    }
    if (focusPointRef.current.y < props.boundaries.min.y) {
      focusPointRef.current.y = props.boundaries.min.y;
    }
    if (focusPointRef.current.y > props.boundaries.max.y) {
      focusPointRef.current.y = props.boundaries.max.y;
    }
  });

  useEffect(() => {
    if (!props.boundaries) return;
    // this focuses the camera to whatever hotspott is selected
    const [, , hotspotZ] = props.currentHSPosition;
    const { z: cameraZ } = camera.position;
    const diffZ = (hotspotZ - cameraZ) / (hotspotZ - cameraZ); // 1 or -1 depending whether the camera is in front or at the back of the hotspot
    camera.position.set(props.currentHSPosition[0], 7, props.currentHSPosition[2] - diffZ * 6); // diff*6 = hotspot position plus or minus 6; arbitrary, but seems about right
    focusPointRef.current.set(props.currentHSPosition[0], 7, props.currentHSPosition[2]);
  }, [props.hsIndex]);

  return <customOrbitControls ref={camControls} args={[camera, gl.domElement, { hsEditor: false }]} />
};

export default Controls;
