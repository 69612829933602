import React from "react";
import { IIGA } from "../models/IIGA";

type AddNewStepType = {
  currentStep: number;
  IGAObject: IIGA[];
  maxAllowedSteps: number;
  handleStepSelect: (index: number) => void;
  setIsDeleteConfirmShown: (boolean: boolean) => void;
  setIsTypeSelectorShown: (boolean: boolean) => void;
  updatedManifest: any;
};

const IGASteps = (props: AddNewStepType) => {
  const {
    currentStep,
    IGAObject,
    maxAllowedSteps,
    setIsDeleteConfirmShown,
    handleStepSelect,
    setIsTypeSelectorShown,
    updatedManifest,
  } = props;

  // this is used to determine if the step needs to be clicked on for IGA updates
  function doesStepNeedUpdate(index: number) {
    if (
      !updatedManifest.IGSObject[index].aspectRatio &&
      ((updatedManifest.IGSObject[index].annotations && updatedManifest.IGSObject[index].annotations!.length > 0) ||
        (updatedManifest.IGSObject[index].stepType === 1 &&
          updatedManifest.IGSObject[index].images.imageHotSpot &&
          updatedManifest.IGSObject[index].images.path))
    ) {
      return true;
    }

    return false;
  }

  const displayStepButtons = () => {
    return IGAObject.map((object, index) => {
      return (
        <React.Fragment key={index}>
          <button
            className={
              currentStep === index
                ? `iga-active-step`
                : `iga-inactive-step ${doesStepNeedUpdate(index) ? "update-step" : ""}`
            }
            key={index}
            onClick={() => handleStepSelect(index)}
          >
            {index + 1}
          </button>
        </React.Fragment>
      );
    });
  };

  const displayAddStepButton = () => {
    if (IGAObject.length < maxAllowedSteps) {
      return (
        <React.Fragment>
          <div id="iga-remove-divider"></div>
          <button className="iga-add-step-btn" onClick={() => setIsTypeSelectorShown(true)}>
            <span className="icon-Add-icon">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
            </span>
          </button>
        </React.Fragment>
      );
    }
  };

  const displayRemoveStepButton = () => {
    if (IGAObject.length > 0) {
      return (
        <React.Fragment>
          {/* <div id="iga-remove-step-btn-container"> */}
          <button id="iga-remove-step-btn" onClick={() => setIsDeleteConfirmShown(true)}>
            <span className="icon-remove-no-background"></span>
            {/* <span>Remove Step</span> */}
          </button>
          {/* <div id="iga-remove-divider"></div> */}
          {/* </div> */}
        </React.Fragment>
      );
    } else {
      return <></>;
    }
  };

  return (
    <React.Fragment>
      <div className="iga-step-list-container">
        {displayStepButtons()}
        {displayAddStepButton()}
        {displayRemoveStepButton()}
      </div>
    </React.Fragment>
  );
};

export default IGASteps;
