import { ThreeD_PAGE } from "../../components/constants/pageTypes";
import { DEFAULT_TEXT_BOX, DEFAULT_IMAGE, DEFAULT_ANNOTATION } from "../../const";
import { HotspotAction, InteractivityBuilder } from "../../lib/interactivity";
import { AnimatedObjectManifest } from "../../lib/interactivity/TimelineConverter/TimelineConverter";
import { nanoid } from "../../lib/nanoId";
import { HotspotNextClick, HotspotObject, HotspotTarget } from "../../types";
import { runOnObjects } from "../PageManifestParsing";
import { legacyAudioPolyfill } from "./validateLegacyAudio";
import { BasePageType } from "../../components/TimelineDrawer/types";
/**
 *
 * @param pageType
 * @param pageManifest
 * @returns true if the pageManifest was changed
 */
export function validatePageManifestOnLoad(
  pageType: string,
  pageManifest: any /** as a mutable reference ie. immer produce */,
): boolean {
  let isDirty = false;
  //ALL
  runOnObjects(pageManifest, "all", (object, objectType) => {
    if (object && typeof object.objectId !== "string") {
      console.warn("object has no objectId, was probably imported from powerpoint", object);
      const objectId = nanoid();
      isDirty = true;
      switch (objectType) {
        case "textBlock": {
          const newObject = {
            ...DEFAULT_TEXT_BOX,
            ...object,
            ...(object?.attributes ?? {}),
            objectId: objectId,
            name: `text-box-${objectId}`,
          };
          Object.assign(object, newObject);
          if (object.attributes) {
            delete object.attributes;
          }
          break;
        }
        case "pageImage": {
          const newObject = {
            ...DEFAULT_IMAGE,
            ...object,
            ...(object?.attributes ?? {}),
            objectId: objectId,
            name: `image-${objectId}`,
          };
          if (typeof newObject?.imageOpacity === "number") {
            newObject.opacity = newObject.imageOpacity;
          }
          if (typeof newObject?.clipPath === "object") {
            if (typeof newObject?.rawData === "object") {
              newObject.clipPathString = `inset(${newObject.clipPath.top ?? 0}% ${newObject.clipPath.right ?? 0}% ${
                newObject.clipPath.bottom ?? 0
              }% ${newObject.clipPath.left ?? 0}%)`;
            }
          }
          Object.assign(object, newObject);
          if (object.attributes) {
            delete object.attributes;
          }
          if (object.imageOpacity) {
            delete object.imageOpacity;
          }
          break;
        }
        case "annotations": {
          const newAnnotation = {
            ...DEFAULT_ANNOTATION,
            ...object,
            objectId: objectId,
            rotation: object?.transform?.rotate ?? 0,
            displayName: `annotation-${objectId}`,
          };
          Object.assign(object, newAnnotation);
          break;
        }
        default: {
          const newObject = {
            ...object,
            objectId: objectId,
            name: `object-${objectId}`,
          };
          Object.assign(object, newObject);
          break;
        }
      }
    }
  });
  switch (pageType) {
    case "Base": {
      runOnObjects(pageManifest, "all", (object, objectType) => {
        switch (objectType) {
          case "textBlock": {
            if (!object.text) return;
            const htmlParser = new DOMParser();
            const htmlDoc = htmlParser.parseFromString(object.text, "text/html");
            const nodeIterator = htmlDoc.createNodeIterator(htmlDoc.body);
            let node = nodeIterator.nextNode();
            const getLastTextNodeFromPreviousSibling = (node: any) => {
              let sibling = node.previousSibling;
              let iterations = 0;
              const maxIterations = 100;
              while (iterations < maxIterations) {
                // If there's no sibling, go up to the parent and find its previous sibling
                if (!sibling) {
                  if (!node.parentNode) {
                    return null; // No more parents, so no text node could be found
                  }
                  sibling = node.parentNode.previousSibling;
                  node = node.parentNode;
                }
                // If there's no more elements to traverse
                if (!sibling) {
                  return null;
                }
                // If sibling is a text node, return it
                if (sibling.nodeType === Node.TEXT_NODE) {
                  return sibling;
                }
                // If sibling has children, move to the last child
                if (sibling.lastChild) {
                  node = sibling;
                  sibling = sibling.lastChild;
                } else {
                  // No children, move to the previous sibling
                  sibling = sibling.previousSibling;
                }
                iterations++;
              }
            };
            let hadToChange = false;
            while (node) {
              if (typeof node === "object") {
                if (node.nodeType === node.TEXT_NODE) {
                  if (node.textContent === " " && (node as Text).wholeText === " ") {
                    console.error("DETECTED MALFORMED SPACE");
                    hadToChange = true;
                    const previousTextNode = getLastTextNodeFromPreviousSibling(node);
                    if (previousTextNode) {
                      previousTextNode.textContent = previousTextNode.textContent + " ";
                      node.parentElement?.removeChild(node);
                    } else {
                      node.parentElement?.removeChild(node);
                    }
                  }
                }
              }
              node = nodeIterator.nextNode();
            }
            if (hadToChange) {
              object.text = htmlDoc.body.innerHTML;
              isDirty = true;
            }
            break;
          }
          case "tables": {
            if (!object) return;
            if (!object.size) return;

            object.height = parseFloat(object.size.height);
            object.width = parseFloat(object.size.width);
            delete object.size;
            if (object.type) return;
            object.type = "table";
            if (!object.position) return;
            object.left = parseFloat(object.position.x);
            object.top = parseFloat(object.position.y);
            delete object.position;

            // isDirty = true;
            break;
          }
          case "symbols": {
            // put symbols in the annotations
            if (!pageManifest.annotations) {
              pageManifest.annotations = [];
            }
            const convertSymbolToAnnotation = (symbol: any) => {
              const annotation = {
                ...symbol,
                objectId: symbol.objectId ?? nanoid(),
                type: symbol.name, // I really don't like this
                displayName: `symbol-${nanoid().slice(0, 5)}`,
              };

              return annotation;
            };
            pageManifest.annotations.push(convertSymbolToAnnotation(object));
            break;
          }
          case "video": {
            if (!object) return;
            object.type = "video";
            object.width = object.width ?? 50;
            object.height = object.height ?? 45;
            object.top = object.top ?? 10;
            object.left = object.left ?? 10;
            object.isDisplayed = object.isDisplayed ?? true;
            object.displayName = object.displayName ?? `video-${object.objectId.slice(0, 5)}`;
            object.opacity = object.opacity ?? 1;
            object.rotation = object.rotation ?? 0;
            object.maintainRatio = object.maintainRatio ?? false;
            break;
          }
          case "pageImage": {
            // This is to support images that already correctly render but don't have the version set
            // The manifest will have the DesignerEnvironmentData, but the image won't have the rawData
            // So we update that and add the right version
            if (pageManifest.designerEnvironmentData && !object.rawData) {
              object.rawData = {
                pixelHeight: (object.height / 100) * pageManifest.designerEnvironmentData.pagePlayerArea.pixelHeight,
                pixelWidth: (object.width / 100) * pageManifest.designerEnvironmentData.pagePlayerArea.pixelWidth,
                pixelTop: (object.top / 100) * pageManifest.designerEnvironmentData.pagePlayerArea.pixelTop,
                pixelLeft: (object.left / 100) * pageManifest.designerEnvironmentData.pagePlayerArea.pixelLeft,
              };

              object.version = 3;
            }
            break;
          }
          default: {
            break;
          }
        }
      });

      // convert pageManifest.interactivity and extract hotspots into their own list
      const hotspots = pageManifest?.hotspots;
      // if hotspots don't exist, remove them from the interactivity object
      if (!hotspots && pageManifest.interactivity) {
        // convert in here
        const interactivityBuilder = new InteractivityBuilder(pageManifest.interactivity);
        const allHotspotLevels = interactivityBuilder.getAllLevelsWithHotspots();
        const newAnimatedObjects: AnimatedObjectManifest[] = [];
        const convertInteractivityHotspotsToHotspotObjects = () => {
          const hostpotObjects: HotspotObject[] = [];
          allHotspotLevels.forEach((level) => {
            const hotspots = [...level.actionBuffer].filter((hp) => hp.type === "hotspot") as HotspotAction[];
            const initialHotspot = hotspots.shift();
            if (!initialHotspot) return;
            const objectId = "hp" + nanoid();
            if (initialHotspot?.delay > 0) {
              newAnimatedObjects.push({
                id: objectId,
                start: initialHotspot.delay / 1000,
                end: null,
                type: "hotspot",
              });
            }

            const createTargetsFromTasks = (tasks: HotspotAction["taskBuffer"]) => {
              const targets: HotspotTarget[] = [];
              tasks.forEach((task) => {
                const target = targets.find((target) => target.objectId === task.targetId);
                if (target) {
                  target.tasks.push({
                    actionValue: task.actionValue,
                    actionValueUnit: task.actionValueUnit,
                    mappedAction: task.mappedAction,
                    targetId: task.targetId,
                  });
                } else {
                  const newTarget: HotspotTarget = {
                    objectId: task.targetId,
                    tasks: [
                      {
                        actionValue: task.actionValue,
                        actionValueUnit: task.actionValueUnit,
                        mappedAction: task.mappedAction,
                        targetId: task.targetId,
                      },
                    ],
                  };
                  targets.push(newTarget);
                }
              });
              return targets;
            };

            const newHotspot: HotspotObject = {
              objectId,
              zIndex: 10,
              displayName: `hotspot-${objectId}`,
              nextClicks: hotspots.map((hotspot) => {
                return {
                  zIndex: 10,
                  ...hotspot.hotspotLocation,
                  resumeOnClick: hotspot.resumeOnClick,
                  type: "hotspot",
                  required: initialHotspot.required ?? false,
                  version: hotspot.version ?? "1.2.0",
                  targets: createTargetsFromTasks(hotspot.taskBuffer),
                } as HotspotNextClick;
              }),
              ...initialHotspot.hotspotLocation,
              pauseOnLoad: initialHotspot.pauseOnLoad,
              resumeOnClick: initialHotspot.resumeOnClick,
              required: initialHotspot.required ?? false,
              type: "hotspot",
              version: initialHotspot.version ?? "1.2.0",
              targets: createTargetsFromTasks(initialHotspot.taskBuffer),
            };
            hostpotObjects.push(newHotspot);
          });

          return hostpotObjects;
        };

        const hotspotObjects = convertInteractivityHotspotsToHotspotObjects();
        pageManifest.hotspots = hotspotObjects;
        if (newAnimatedObjects.length > 0) {
          if (!pageManifest.timeline) {
            pageManifest.timeline = {};
          }
          pageManifest.timeline.animatedObjects = [
            ...(pageManifest?.timeline?.animatedObjects ?? []),
            ...newAnimatedObjects,
          ];
        }
      }

      // Convert legacy audio into narration
      if (
        pageManifest.basePageType === BasePageType.FREE_FORM &&
        !pageManifest.narrationAudios &&
        pageManifest.Audio &&
        pageManifest.Audio.length > 1
      ) {
        legacyAudioPolyfill(pageManifest);
      }
      break;
    }
    case ThreeD_PAGE:
      if (typeof pageManifest.condition === "string" && pageManifest.condition === "InHangar") {
        pageManifest.condition = "atGate";
        isDirty = true;
      }
      // check for whitespaces/new line in the role
      if (pageManifest.role.search(/\s/) !== -1) {
        pageManifest.role = pageManifest.role.trim();
        isDirty = true;
      }
      break;
    default: {
      break;
    }
  }
  return isDirty;
}

export function validatePageManifestBeforeSave(
  pageType: string,
  pageManifest: any /** as a mutable reference ie. immer produce */,
) {
  switch (pageType) {
    case "Base": {
      runOnObjects(pageManifest, "all", (object, objectType) => {
        if (object?.ghost) {
          delete object.ghost;
        }
      });
      break;
    }
    default: {
      break;
    }
  }
}
