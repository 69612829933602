import React, { useContext, useEffect, useRef, useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import "../CPaT3d_Player/CPaT3d_Player.css";
import "./Cabin.css";
import PageScene from "./scene/scene";
import PagePresentation from "./components/hud";
import { PageContext, LessonPageActionsContext } from "../../routes/builderContexts";
import {
  setHSList,
  setisIGS,
  setAllStepImages,
  resetStepImages,
  setStepImage,
  setStepNumber,
  setTaskDesc,
  setIsTerminal,
  setStepType,
  setQuestion2,
  setChoices,
  displayStepSystem,
  displayChooseStep,
  displayAddStep,
  displayStepResponsePanel,
  setdisplayStyle,
  setModel,
  setRole,
  setCustom,
  storedCurrentStep,
  setCurtain,
  setHsData,
} from "../../store/actions/actions";
import * as THREE from "three";
import HSEditor from "./hotSpotEditor/presentation";
import PageTypeCrawler, { IDandPath, crawlAudioArray } from "../../services/Utils/PageTypeCrawler/PageTypeCrawler";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow-icon.svg";
import { HotSpot3D, PartialHotspot3D, Point3D } from "./hotSpotEditor/types";
import { CabinPlayerProps, ProxEditorProps, ProximityOffsetEditorProps, RangeSliderProps } from "./scene/types";
import HotspotLister from "./HotspotLister/HotspotLister";

const RangeSlider = (props: RangeSliderProps) => {
  const { proximityRange, setProximityRange } = props;
  const [slider, setSlider] = useState({
    max: 30,
    min: 2,
    value: proximityRange,
    displayValue: proximityRange,
  });

  return (
    <div className="range-slider-container">
      <label id="range-slider-title">Range Size: {slider.displayValue}</label>
      <input
        type="range"
        className="sliderStyle"
        min={slider.min}
        max={slider.max}
        step={0.25}
        defaultValue={slider.displayValue}
        onChange={(e) => {
          setSlider({
            ...slider,
            displayValue: parseFloat(e.target.value),
          });
        }}
        onMouseUp={(e) => {
          setSlider({ ...slider, value: parseFloat(e.currentTarget.value) });
          setProximityRange(parseFloat(e.currentTarget.value));
        }}
      ></input>
    </div>
  );
};

const ProximityOffsetEditor = (props: ProximityOffsetEditorProps) => {
  const { proximityOffset, setProximityOffset } = props;

  const handleArrowClick = (coord: keyof Point3D, direction: number) => {
    setProximityOffset({
      ...proximityOffset,
      [coord]: proximityOffset[coord] + direction,
    });
  };
  return (
    <div className="proxOffWrap">
      <label id="proxOffWrap-label">Position:</label>
      <div className="proxOffInput">
        <label> Aircraft Left/Right:</label>
        <div onClick={() => handleArrowClick("x", -1)}>
          <ArrowIcon />
        </div>
        <label className="proxOffStyle">{proximityOffset.x}</label>
        <div onClick={() => handleArrowClick("x", 1)}>
          <ArrowIcon style={{ transform: "rotate(-180deg)" }} />
        </div>
      </div>
      <div className="proxOffInput">
        <label>Aft/Forward:</label>
        <div onClick={() => handleArrowClick("z", -1)}>
          <ArrowIcon style={{ transform: "rotate(90deg)" }} />
        </div>
        <label className="proxOffStyle">{proximityOffset.z} </label>
        <div onClick={() => handleArrowClick("z", 1)}>
          <ArrowIcon style={{ transform: "rotate(-90deg)" }} />
        </div>
      </div>
    </div>
  );
};

const ProxEditor = (props: ProxEditorProps) => {
  const { proximityOffset, setProximityOffset, proximityRange, setProximityRange } = props;
  return (
    <div className="proxControl">
      <RangeSlider proximityRange={proximityRange} setProximityRange={setProximityRange} />
      <ProximityOffsetEditor proximityOffset={proximityOffset} setProximityOffset={setProximityOffset} />
    </div>
  );
};
// converting old hotspots to new format
const cleanupHotspots = (data: HotSpot3D[]): HotSpot3D[] => {
  const hsCopy = [...data].map((hs, i) => {
    const copy: HotSpot3D = { ...hs };
    if (!copy.name) {
      copy.name = copy.id;
    }
    if (typeof copy.order === "undefined") {
      copy.order = i + 1;
    }
    if (!copy.failResponse) {
      copy.failResponse = "";
    }
    if (!copy.whatToDo) {
      copy.whatToDo = "";
    }
    if (typeof copy.remainAfterCompletion === "undefined") {
      copy.remainAfterCompletion = true;
    }
    if (typeof copy.isRequired === "undefined") {
      copy.isRequired = false;
    }
    if (typeof copy.isScorable === "undefined") {
      copy.isScorable = false;
    }
    copy.key = "active";
    return copy;
  });
  return hsCopy;
};

const HotSpotEditor = ({ saveAndExit }: { saveAndExit: () => void }) => {
  return (
    <>
      <div className="deepEditorSelect" onClick={() => saveAndExit()}>
        Save Hotspot Position
      </div>
    </>
  );
};

const CabinPlayer = (props: CabinPlayerProps) => {
  THREE.Cache.enabled = true;
  const pageContext = useContext(PageContext);
  const lessonPageActionsContext = useContext(LessonPageActionsContext);
  const updatedManifest = pageContext.pageManifest;
  const manifestModel = updatedManifest.modelSet.modelSet;
  const modelSetPath =
    "/api/PassThru/cpat/lesson/lessonpreview/" + props.lessonMetaData.lessonVersionId + ".preview/" + manifestModel;

  const [deepEditorMode, setDeepEditorMode] = useState("normal");
  const [hsIndex, setHsIndex] = useState(0);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  // this gets passed down to riggingControls to store the current Hotspot position/size/rotation, etc without causing rerenders
  const currentHsData = useRef<PartialHotspot3D | null>(null);

  const saveProximityOffset = (data: Point3D) => {
    const newMan = _.cloneDeep(updatedManifest);
    newMan.HotSpotList[hsIndex].proximityOffset = data;
    pageContext.updatePageManifest(newMan);
    props.setHSList(newMan.HotSpotList);
  };

  const saveProximityRange = (range: number) => {
    const newMan = _.cloneDeep(updatedManifest);
    newMan.HotSpotList[hsIndex].proximityRange = range;
    pageContext.updatePageManifest(newMan);
    props.setHSList(newMan.HotSpotList);
  };

  const saveIsSphere = (val: boolean) => {
    const newMan = _.cloneDeep(updatedManifest);
    newMan.HotSpotList[hsIndex].isSphere = val;
    pageContext.updatePageManifest(newMan);
    props.setHSList(newMan.HotSpotList);
  };
  const updateHSList = (data: HotSpot3D[]) => {
    props.setHSList(data);
    updatedManifest.HotSpotList = data;
    pageContext.updatePageManifest(updatedManifest);
  };

  const handleIGASave = (IGAObject: any) => {
    updatedManifest.HotSpotList[hsIndex].IGSObject = _.cloneDeep(IGAObject);
    pageContext.updatePageManifest(updatedManifest);
  };

  const openIGA = (index: number) => {
    setHsIndex(index);
    props.setdisplayStyle("block");
  };

  const saveAndExitHsEditor = async () => {
    // put the currentHsData on the currently selected hotspot; update the manifest and the hotspot list in the
    // redux store
    const hsList = updatedManifest.HotSpotList.map((hs: HotSpot3D, i: number) => {
      return i === hsIndex ? { ...hs, ...currentHsData.current } : { ...hs };
    });
    updatedManifest.HotSpotList = hsList;
    pageContext.updatePageManifest(updatedManifest);
    props.setHSList(hsList);
    if (deepEditorMode === "normal") {
      props.setCurtain(true);
      setDeepEditorMode("hsEditor");
    } else {
      props.setCurtain(true);
      setDeepEditorMode("normal");
    }
  };

  const setBlankIGAArray = () => {
    updatedManifest.HotSpotList[0].IGSObject = [
      {
        stepType: 0,
        isTerminal: false,
        taskDescription: "What to do",
        question: "What to ask",
        choices: [
          {
            isCorrect: false,
          },
        ],
        images: {},
      },
    ];
    pageContext.updatePageManifest(updatedManifest);
    props.setHSList(updatedManifest.HotSpotList);
  };

  if (updatedManifest.HotSpotList[0].IGSObject) {
    props.setHSList(updatedManifest.HotSpotList);
  } else {
    setBlankIGAArray();
  }

  useEffect(() => {
    lessonPageActionsContext.setLessonPageActions([]);
    if (updatedManifest.StepGraphic === null || updatedManifest.StepGraphic === undefined) {
      const newStepGraphic = [
        {
          image: "cpat/generic/images/19/19/57E6CC11F46AEA11817D60F26251769D/blank.png",
          assetVersionId: "19",
        },
      ];
      updatedManifest.StepGraphic = newStepGraphic;
      pageContext.updatePageManifest(updatedManifest);
    }
  }, []);

  useEffect(() => {
    const cleanHotspots = cleanupHotspots([...updatedManifest.HotSpotList]);
    updatedManifest.HotSpotList = cleanHotspots;
    pageContext.updatePageManifest({ ...updatedManifest });
    props.setHSList(cleanHotspots);
    props.displayStepSystem(false);
    props.displayChooseStep(true);
    props.displayAddStep(false);
    props.displayStepResponsePanel("none");
    props.setdisplayStyle("none");
    props.storedCurrentStep(1);
    props.setModel(modelSetPath);
    props.setCurtain(true);
  }, []);

  useEffect(() => {
    const assetVersionIds = PageTypeCrawler.getAllAssetIds(pageContext.page.pagePlayerType, updatedManifest);
    if (assetVersionIds) {
      pageContext.setPageAssetIds(assetVersionIds);
    }
  }, [pageContext.currentlyDisplayedPageIndex]);

  switch (deepEditorMode) {
    case "normal":
      return (
        <>
          <PageScene
            isSphere={props.HotSpotList?.[hsIndex]?.isSphere ?? false}
            hsIndex={hsIndex}
            setHsIndex={setHsIndex}
            HotSpotList={props.HotSpotList}
            modelSetPath={modelSetPath}
            currentIGA={props.HotSpotList?.[hsIndex]?.IGSObject ?? {}}
            assetManagerProps={props.assetManagerProps}
            handleSave={handleIGASave}
          />
          <div className="relative-container walkaround-normal-grid">
            <div></div>
            <PagePresentation
              whatToDoList={(props.HotSpotList as HotSpot3D[])
                .sort((a, b) => a.order - b.order)
                .map((hs: HotSpot3D) => hs.whatToDo)}
              currentObjectiveLabel={updatedManifest.CurrentObjectiveLabel}
              currentObjectiveText={updatedManifest.CurrentObjective}
            />
          </div>
          <HotspotLister
            openEditor={(val: string) => {
              setDeepEditorMode(val);
              setIsEditing(true);
            }}
            openIGA={openIGA}
            hsIndex={hsIndex}
            setHsIndex={setHsIndex}
            HotSpotList={props.HotSpotList}
            setHSList={updateHSList}
          />
        </>
      );
    case "hsEditor":
      return (
        <>
          <HSEditor
            hsIndex={hsIndex}
            setHsIndex={setHsIndex}
            isSphere={props.HotSpotList[hsIndex].isSphere}
            setIsSphere={saveIsSphere}
            HotSpotList={props.HotSpotList}
            hotSpotEditor={() => <HotSpotEditor saveAndExit={saveAndExitHsEditor} />}
            proxEditor={() => (
              <ProxEditor
                proximityOffset={
                  props.HotSpotList?.[hsIndex]?.proximityOffset ?? {
                    x: 0,
                    y: 0,
                    z: 0,
                  }
                }
                setProximityOffset={saveProximityOffset}
                proximityRange={props.HotSpotList?.[hsIndex]?.proximityRange ?? 5}
                setProximityRange={saveProximityRange}
              />
            )}
            setDeepEditorMode={setDeepEditorMode}
            isEditing={isEditing}
            proximityRange={props.HotSpotList?.[hsIndex]?.proximityRange ?? 5}
            proximityOffset={props.HotSpotList[hsIndex].proximityOffset}
            positionRef={currentHsData}
          />
        </>
      );

    default:
      return <></>;
  }
};

const mapStateToProps = (state: any) => {
  return {
    displayStyle: state.Current3dPage.displayStyle,
    HotSpotList: state.Current3dPage.HotSpotList,
    currentHsData: state.Current3dPage.currentHsData,
    hsListerIsOpen: state.Current3dPage.openHsLister,
  };
};

export default connect(mapStateToProps, {
  setHSList,
  setisIGS,
  setAllStepImages,
  resetStepImages,
  setStepImage,
  setStepNumber,
  setTaskDesc,
  setIsTerminal,
  setStepType,
  setQuestion2,
  setChoices,
  displayStepSystem,
  displayChooseStep,
  displayAddStep,
  displayStepResponsePanel,
  setdisplayStyle,
  setModel,
  setRole,
  setCustom,
  storedCurrentStep,
  setCurtain,
  setHsData,
})(CabinPlayer);

export function cabinPageManifestAssetCollector(pageManifest: any) {
  const assets = [];
  for (const [key, value] of Object.entries(pageManifest)) {
    if (key === "HotSpotList") {
      for (const hotspot of value as any) {
        for (const [HSLKey, HSLValue] of Object.entries(hotspot)) {
          if (HSLKey === "IGSObject") {
            (HSLValue as any).forEach((igs: any) => {
              if ("choices" in igs) {
                const { choices } = igs;
                choices.forEach((choice: any) => {
                  if ("image" in choice) {
                    const { image } = choice;
                    if ("assetVersionId" in image && "path" in image) {
                      const { assetVersionId, path } = image;
                      assets.push(new IDandPath(+assetVersionId, path));
                    }
                  }
                });
              }
              if ("images" in igs) {
                const { images } = igs;
                if ("assetVersionId" in images && "path" in images) {
                  const { assetVersionId, path } = images;
                  assets.push(new IDandPath(+assetVersionId, path));
                }
              }
            });
          }
        }
      }
    }
    // else if (key === "IGSObject") {
    //     //OLD IGSObject HANDLER
    //     value.forEach((igs) => {
    //         if ('choices' in igs) {
    //             const { choices } = igs
    //             choices.forEach((choice) => {
    //                 if ('image' in choice) {
    //                     const { image } = choice
    //                     if ('assetVersionId' in image && 'path' in image) {
    //                         const { assetVersionId, path } = image
    //                         assets.push(new IDandPath(+assetVersionId, path))
    //                     }
    //                 }
    //             })
    //         }
    //         if ('images' in igs) {
    //             const { images } = igs
    //             if ('assetVersionId' in images && 'path' in images) {
    //                 const { assetVersionId, path } = images
    //                 assets.push(new IDandPath(+assetVersionId, path))
    //             }
    //         }
    //     })
    // }
    else if (key === "StepGraphic") {
      (value as any).forEach((z: any) => {
        if ("assetVersionId" in z && "image" in z) {
          const { assetVersionId, image } = z;
          assets.push(new IDandPath(+assetVersionId, image));
        }
      });
    } else if (key === "background") {
      if ("assetVersionId" in (value as any) && "path" in (value as any)) {
        const { assetVersionId, path } = value as any;
        assets.push(new IDandPath(+assetVersionId, path));
      }
    } else if (key === "choices") {
      (value as any).forEach((o: any) => {
        if ("assetVersionId" in o && "image" in o) {
          const { assetVersionId, image } = o;
          assets.push(new IDandPath(+assetVersionId, image));
        }
      });
    } else if (key === "modelSet") {
      if ("assetVersionId" in (value as any) && "modelSet" in (value as any)) {
        const { assetVersionId, modelSet } = value as any;
        assets.push(new IDandPath(+assetVersionId, modelSet));
      }
    } else if (key === "Audio") {
      assets.push(...crawlAudioArray(value as any));
    }
  }
  return assets;
}
