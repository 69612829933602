import React from "react";
import { ReactComponent as LoadingSvg } from "../../../../../assets/icons/NarratorTab/Dual Ring-1s-32px.svg";

const CustomPageCover = (props: any) => {
  const { children, showLoader } = props;

  if (!showLoader) {
    return <></>;
  } else {
    return (
      <div className="base-page-loading-cover">
        <LoadingSvg className="base-page-loading-spinner" />
      </div>
    );
  }
};

export default CustomPageCover;
