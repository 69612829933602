import React from "react";
import AspectRatioContainer from "../../../services/Utils/AspectRatioContainer";
import * as AssetManagerTypes from "../AssetManager.types";
import IAsset from "../../../models/IAsset";
// import lodash from "lodash"
function AssetLibraryListView(props: AssetManagerTypes.AssetLibraryListViewProps) {
  if (props.data.length === 0) {
  }
  const totalImages = React.useRef(props.data.length);
  totalImages.current = props.data.length;
  const loadedImages = React.useRef(0);
  const handleAssetClick = (asset: IAsset) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
    (props.AssetManagerDispatch as AssetManagerTypes.Dispatch<IAsset>)({
      type: "SELECT_ASSET",
      payload: asset,
    });

  const isSelected = (el: any) => {
    return el.assetVersionId === props.selectedAsset.assetId;
  };

  return (
    <div className="assetLibrary-list-view" style={{ display: props.assetsAreLoading ? "none" : "" }}>
      {props.data.length > 0 && (
        <div className="assetLibrary-list-view-header">
          <div>
            <p>File Name</p>
          </div>
          <div>
            <p>Description</p>
          </div>
        </div>
      )}
      {props.data.length > 0 ? (
        <div className="assetLibrary-list-view-content">
          {props.data.map((el: any, index: number) => {
            return (
              <div
                key={"astmngrlistvw" + index}
                className={`assetLibrary-single-asset-container ${isSelected(el) ? "selected" : ""}`}
                onClick={handleAssetClick(el)}
                style={isSelected(el) ? { background: "var(--cpat-green-hover)", color: "black" } : {}}
              >
                <div className="assetLibrary-list-view-content-name">
                  {props.typeMode === 4 && (
                    <div>
                      <AspectRatioContainer
                        height={100}
                        width={100}
                        src={el.blobPath}
                        additionalStyle={{ position: "absolute" }}
                        onLoadError={(e: any) => {}}
                      />
                    </div>
                  )}
                  <p>{el.name}</p>
                </div>
                <div className="assetLibrary-list-view-content-description">
                  <p>{el.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="assetLibrary-listView-no-results">Sorry, We found No results matching your filters </div>
      )}
    </div>
  );
}
export default AssetLibraryListView;
