import _ from "lodash";
import React, { RefObject, useContext, useState } from "react";
import Moveable, { BoundType, MoveableInterface, OnClip, OnDrag } from "../react-moveable";
import {
  useInteracitvityHotspotDispatch,
  InteractivityHotspotActionTypes,
} from "../../contexts/InteractivityHotspotProvider";
import { ElementTypes } from "../../pageTypes/BasicPage_Player/components/IBasePage";
import { IPageContext, PageContext } from "../../routes/builderContexts";
import { createPropertyBox } from "../ObjectPropertyBox/functions/PropertyBoxFunctions";
import { IPropertyBox } from "../ObjectPropertyBox/models/IObjectPropertyBox";
import { updatePropertyBox } from "../ObjectPropertyBox/ObjectPropertyBox";
import {
  getClipPath,
  getClipObject,
  hasClipCoordinates,
  getCroppedImageSize,
  getAbsoluteClipPosOnResize,
  getCroppedImagePosition,
  clipPath,
  getUpdatedClipPath,
} from "./lib/ables/cropFunctions";
import CropScalable from "./lib/ables/CropScalable";
import CropRotatable from "./lib/ables/CropRotatable";
import CropResizable from "./lib/ables/CropResizable";
import "./CustomMoveable.css";
import "./NotDisplayedMoveable.css";

type incomingPropsType = {
  bounds?: BoundType;
  canResize?: boolean;
  directions: string[];
  elementType?: ElementTypes;
  isMovable: boolean;
  moveRef: RefObject<any>;
  naturalSize?: { height: number; width: number };
  nodeToUpdate: any;
  pageDims: DOMRect;
  propertyBox: IPropertyBox;
  rotatable?: boolean;
  shouldMaintainRatio: boolean;
  target: any;
  isCroppable?: boolean;
  isCropping?: boolean;
  endActions: (val: any, node: any, inputEvent: any) => void;
  setIsDragging: (boolean: boolean) => void;
  setIsResizing: (boolean: boolean) => void;
  setIsRotating: (boolean: boolean) => void;
  isDisplayed?: boolean;
  editableIndex?: number;
  objectIsInTimeline?: boolean;
  // updatePropertyBox: (propertyBox: IPropertyBox, target: HTMLElement | SVGElement, moveRef: RefObject<any>) => void
};

const MoveableClass = (props: incomingPropsType) => {
  const {
    bounds,
    canResize,
    directions,
    elementType,
    isMovable,
    moveRef,
    naturalSize,
    nodeToUpdate,
    pageDims,
    propertyBox,
    rotatable,
    shouldMaintainRatio,
    target,
    isCroppable,
    isCropping,
    endActions,
    setIsDragging,
    setIsResizing,
    setIsRotating,
    isDisplayed,
    editableIndex,
    objectIsInTimeline,
    // updatePropertyBox
  } = props;
  const interactivityHotspotDispatch = useInteracitvityHotspotDispatch();
  const [frame] = useState({ translate: [0, 0] });
  let tempNode: any = _.cloneDeep(nodeToUpdate);
  const isCroppedImage = target && nodeToUpdate && hasClipCoordinates(nodeToUpdate);
  const [isSnappable, setIsSnappable] = useState(true);

  const doDragging = (target: HTMLElement | SVGElement, top: number, left: number) => {
    if (!pageDims) return;
    target.style.zIndex = "500";
    setIsDragging(true);
    if (!_.has(tempNode, "top")) tempNode.top = 0;
    if (!_.has(tempNode, "left")) tempNode.left = 0;
    const [newTop, newLeft] = [top, left].map((x) => Math.floor(x));

    tempNode.top = (newTop / pageDims.height) * 100;
    tempNode.left = (newLeft / pageDims.width) * 100;

    // if(tempNode.top >= 0 || tempNode.left >= 0) {
    target.style.top = `${newTop}px`;
    target.style.left = `${newLeft}px`;
    if (tempNode.rawData) {
      tempNode.rawData.pixelTop = newTop;
      tempNode.rawData.pixelLeft = newLeft;
    }

    propertyBox.left = newLeft;
    propertyBox.top = newTop;

    updatePropertyBox(propertyBox);
    // }
  };

  const doDraggingForImages = (target: HTMLElement | SVGElement, top: number, left: number) => {
    if (!pageDims || !tempNode) return;
    target.style.zIndex = "500";
    setIsDragging(true);
    if (!_.has(tempNode, "top")) tempNode.top = 0;
    if (!_.has(tempNode, "left")) tempNode.left = 0;

    const { top: newTop, left: newLeft } = (target as HTMLElement).getBoundingClientRect();
    tempNode.top = (Math.floor(top) / pageDims.height) * 100;
    tempNode.left = (Math.floor(left) / pageDims.width) * 100;
    target.style.top = `${Math.floor(top)}px`;
    target.style.left = `${Math.floor(left)}px`;

    propertyBox.left = newLeft - pageDims.left > 0.1 ? Math.floor(newLeft - pageDims.left) : 0.0;
    propertyBox.top = newTop - pageDims.top > 0.1 ? Math.floor(newTop - pageDims.top) : 0.0;

    updatePropertyBox(propertyBox);
  };

  const doDraggingDispatch = (target: HTMLElement | SVGElement, top: number, left: number) => {
    if (elementType === "pageImage" && nodeToUpdate?.transform?.rotate !== 0) {
      return doDraggingForImages(target, top, left);
    }
    return doDragging(target, top, left);
  };

  const doResizing = (target: HTMLElement | SVGElement, drag: OnDrag, height: number, width: number) => {
    if (!pageDims || width <= 1 || height <= 1) return;
    target.style.zIndex = "500";
    setIsResizing(true);
    if (!_.has(tempNode, "height")) tempNode["height"] = 0;
    if (!_.has(tempNode, "width")) tempNode["width"] = 0;
    if (!_.has(tempNode, "top")) tempNode["top"] = 0;
    if (!_.has(tempNode, "left")) tempNode["left"] = 0;

    const beforeTranslate = drag.beforeTranslate;
    tempNode = resizeTarget(target, width, height, beforeTranslate);
    const newProperties = _.cloneDeep(propertyBox);
    frame.translate = beforeTranslate;

    if (elementType === "pageImage") {
      width = Math.floor(width);
      height = Math.floor(height);
    }

    newProperties.left = beforeTranslate[0]; //parseFloat(((tempNode.left * pageDims.width) / 100).toFixed(2));
    newProperties.top = beforeTranslate[1]; //parseFloat(((tempNode.top * pageDims.height) / 100).toFixed(2));
    newProperties.width = parseFloat(width.toFixed(2));
    newProperties.height = parseFloat(height.toFixed(2));
    updatePropertyBox(newProperties);

    if ((target as any).firstChild.nodeName === "ellipse") {
      (target as any).firstChild.attributes.cx.nodeValue = `${target.clientWidth / 2}`;
      (target as any).firstChild.attributes.cy.nodeValue = `${target.clientHeight / 2}`;
      (target as any).firstChild.attributes.rx.nodeValue = `${target.clientWidth / 2 - 3}`;
      (target as any).firstChild.attributes.ry.nodeValue = `${target.clientHeight / 2 - 3}`;
    }

    if (isCroppedImage) {
      const moveableState = moveRef.current.moveable.state;
      tempNode.clipPath.croppedImagePixelData = moveableState.croppedRect;
    }

    if ((target as any).firstChild?.nodeName === "polygon" && target.getAttribute("name") === "triangle") {
      (target as any).firstChild.attributes.points.nodeValue = `4,${target.clientHeight - 4} ${
        (target.clientWidth - 2) / 2
      },4 ${target.clientWidth - 4},${target.clientHeight - 4}`;
    }
  };

  const doRotating = (target: HTMLElement | SVGElement, delta: number) => {
    setIsRotating(true);
    if (!_.has(tempNode, "transform") || !_.has(tempNode.transform, "rotate")) {
      tempNode.transform = { rotate: 0 };
    }
    tempNode.transform.rotate += delta;

    if (_.isNaN(tempNode.transform.rotate)) {
      tempNode.transform = { ...tempNode.transform, rotate: 0 };
    }

    if (tempNode.transform.rotate >= 360) {
      tempNode.transform.rotate -= 360;
    }

    if (tempNode.transform.rotate < 0) {
      tempNode.transform.rotate += 360;
    }

    const scaleString: string =
      tempNode && tempNode.transform && tempNode.transform.scale
        ? `scale(${tempNode.transform.scale[0]}, ${tempNode.transform.scale[1]})`
        : "";
    const rotateString = `rotate(${tempNode.transform.rotate}deg)`;
    target.style.transform = `${rotateString} ${scaleString}`;
    propertyBox.rotation = parseFloat(tempNode.transform.rotate.toFixed(2));
    updatePropertyBox(propertyBox);
  };

  function beginScaling(set: (scale: number[]) => void) {
    if (tempNode?.transform?.scale) {
      set([tempNode?.transform?.scale[0], tempNode?.transform?.scale[1]]);
    } else {
      set([1.0, 1.0]);
    }
  }

  /*DEPRECATED DO NOT USE SCALING EVER ON IMAGES OR ANY OTHER HTML ELEMENT YOU WILL ONLY SUFFER */
  const doScaling = (
    drag: OnDrag,
    scale: number[],
    target: HTMLElement | SVGElement,
    currentTarget: MoveableInterface,
  ) => {
    if (!target) return;

    target.style.zIndex = "500";
    setIsDragging(true);
    const beforeTranslate = drag.beforeTranslate;
    frame.translate = beforeTranslate;

    // actual, final measurements
    target.style.top = `${tempNode.top.toFixed(2)}%`;
    target.style.left = `${tempNode.left.toFixed(2)}%`;
    updatePropertyBox(propertyBox);
  };

  const handleEnd = (inputEvent: any, target: HTMLElement | SVGElement) => {
    if (!target) return;
    const imgElement = target.getElementsByTagName("img")[0];
    if (imgElement && tempNode.maintainRatio) {
      const { height } = target.getBoundingClientRect();
      const { height: imgHeight } = imgElement.getBoundingClientRect();
      if (height > imgHeight + 0.3 && tempNode.transform?.rotate === 0) {
        target.style.height = imgHeight + "px";
        tempNode.height = (imgHeight / pageDims.height) * 100;
        if (tempNode.rawData) tempNode.rawData.pixelHeight = imgHeight;
      }
    }
    endActions(target, tempNode, inputEvent);
  };

  function resizeTarget(target: HTMLElement | SVGElement, width: number, height: number, beforeTranslate: number[]) {
    const [beforeLeft, beforeTop] = beforeTranslate;
    const [afterWidth, afterHeight] = [width, height].map((x) => Math.floor(x));
    tempNode.width = (afterWidth / pageDims.width) * 100;
    tempNode.height = (afterHeight / pageDims.height) * 100;
    tempNode.top = (beforeTop / pageDims.height) * 100;
    tempNode.left = (beforeLeft / pageDims.width) * 100;
    if (!tempNode.transform?.rotate) {
      tempNode.transform = { rotate: 0 };
    }
    if (elementType === "pageImage") {
      const img = target.getElementsByTagName("img")[0];
      img.style.width = `${afterWidth}px`;
      img.style.height = `${afterHeight}px`;
    }

    target.style.width = `${afterWidth}px`;
    target.style.height = `${afterHeight}px`;
    target.style.top = `${beforeTop}px`;
    target.style.left = `${beforeLeft}px`;

    return tempNode;
  }

  function doClipping(target: HTMLElement | SVGElement, e: OnClip) {
    if (!target) return;
    const { width, height } = target.getBoundingClientRect();
    const clipPath = getClipObject(e.clipStyles, width, height);
    if (!isValidCrop(width, height, clipPath)) {
      return;
    }
    target.style.clipPath = e.clipStyle;

    const moveableState = moveRef.current.moveable.state;

    tempNode.clipPath = clipPath;
    tempNode.clipPath.fullImageSize = { width, height };
    tempNode.clipPath.croppedImagePixelData = moveableState.croppedRect;
  }

  function isValidCrop(width: number, height: number, clipPath: clipPath): boolean {
    return (
      height + 2 - clipPath.absoluteTop - clipPath.absoluteBottom > 30 &&
      width + 2 - clipPath.absoluteRight - clipPath.absoluteLeft > 30
    );
  }

  const grayBorderIsOn = objectIsInTimeline
    ? ""
    : typeof isDisplayed === "undefined" || isDisplayed
    ? ""
    : "target-not-displayed";
  return (
    <Moveable
      onClick={() => {
        interactivityHotspotDispatch({
          type: InteractivityHotspotActionTypes.SET_CURRENT_HOTSPOT,
          payload: null,
        });
      }}
      ref={moveRef}
      target={target}
      className={
        `target ${isCroppedImage && !isCropping ? "moveable-control-clipped" : ""}` +
        `${typeof isDisplayed === "undefined" || isDisplayed ? "" : "target-not-displayed"} ${grayBorderIsOn}`
      }
      renderDirections={directions}
      origin={false}
      draggable={isMovable}
      resizable={canResize || (elementType === "pageImage" && !isCroppedImage)}
      rotatable={rotatable ?? true}
      scalable={false}
      triggerAblesSimultaneously={false}
      throttleResize={1}
      snappable={isSnappable}
      bounds={bounds}
      ables={isCroppedImage ? [CropResizable, CropRotatable] : []}
      clipTargetBounds
      cropscalable={isCroppedImage}
      clipRelative
      snapBoundInfos={bounds}
      props={{
        snapBoundInfos: bounds,
      }}
      cropresizable={isCroppedImage}
      clippable={elementType === "pageImage" && isCroppable}
      keepRatio={shouldMaintainRatio && !isCropping}
      onDrag={({ target, top, left }) => {
        doDraggingDispatch(target, top, left);
      }}
      onDragEnd={({ target, inputEvent }) => handleEnd(inputEvent, target)}
      onResizeStart={({ setOrigin, dragStart, inputEvent }) => {
        setOrigin(["%", "%"]);
        dragStart && dragStart.set(frame.translate);
        inputEvent?.preventDefault();
      }}
      onResize={({ target, drag, height, width }) => {
        doResizing(target, drag, height, width);
      }}
      onResizeEnd={({ target, inputEvent }) => {
        handleEnd(inputEvent, target);
      }}
      onRotateStart={({ inputEvent }) => {
        inputEvent?.stopPropagation();
      }}
      onRotate={({ delta, target }) => doRotating(target, delta)}
      onRotateEnd={({ target, inputEvent }) => handleEnd(inputEvent, target)}
      onScaleStart={({ target, set }) => {
        beginScaling(set);
      }}
      onScale={(e) => {
        doScaling(e.drag, e.scale, e.target, e.currentTarget);
      }}
      onScaleEnd={({ target, inputEvent }) => handleEnd(inputEvent, target)}
      onClip={(e) => {
        doClipping(target, e);
      }}
      onClipEnd={({ target, inputEvent }) => {
        handleEnd(inputEvent, target);
      }}
    />
  );
};

export default MoveableClass;
