import { useAudioManager, useAudioManagerStore } from "../../contexts/PageAudioManager";
import PropertyBoxPanel, { IPropertyBoxPanel } from "../ObjectPropertiesPanel";
import { AudioPropertiesPanel } from "../AudioProperties/AudioPropertiesPanel";

// FIXME: Ideally Properties would be more generic and could hold a properties form
//  this way, as long as an object provides a form structure, it can be rendered
//  and collected to update the selected object properties.
//  For now I will just route between 2 different approachs since <PropertyBoxPanel/> Is far too tied to
//  screen objects.
export const Properties = (props: IPropertyBoxPanel) => {
  const selectedAudio = useAudioManagerStore((state) => state.selectedAudio);

  if (selectedAudio) {
    return <AudioPropertiesPanel />;
  } else {
    return <PropertyBoxPanel {...props} />;
  }
};
