import { create } from "zustand";
import { TimelineAudioState } from "../../../../contexts/PageAudioManager/types";
import { LanguageType } from "../../../../models/IInteractiveAudio";

export enum ConflictType {
  LEFT = "left",
  RIGHT = "right",
}

export interface NarrationGroupedObject {
  primaryId: string;
  secondaryId: string | null;
  start: number;
  end: number;
  width: number;
}

export interface NarrationStoreState {
  isDragging: boolean;
  currentPosition: number | null; // in seconds
  draggingObjectId: string | null; // objectId
  objects: Map<string, NarrationGroupedObject>;
  conflictObject: string | null;
  conflictType: ConflictType;
}

export interface NarrationStoreActions {
  setDragging: (objectId: string | null) => void;
  setObjects: (objects: TimelineAudioState[]) => void;
  setCurrentPosition: (position: number) => void;
  setConflict: (objectId: string | null, type?: ConflictType) => void;
}

export const useNarrationStore = create<NarrationStoreState & NarrationStoreActions>()((setState, getState) => ({
  // DRAGGING
  isDragging: false,
  draggingObjectId: null,
  setDragging: (objectId) => {
    if (objectId) {
      setState({
        isDragging: true,
        draggingObjectId: objectId,
      });
    } else {
      setState({
        isDragging: false,
        draggingObjectId: null,
      });
    }
  },
  // OBJECT HANDLING
  objects: new Map(),
  setObjects: (objects) => {
    // Initial pass to create the primary entries
    const baseObjectsMap: Map<string, NarrationGroupedObject> = new Map(
      objects
        .filter((audio) => audio.language === LanguageType.PRIMARY)
        .map<[string, NarrationGroupedObject]>((audio) => [
          audio.objectId,
          {
            primaryId: audio.objectId,
            secondaryId: null,
            start: audio.start,
            end: audio.end,
            width: audio.duration ?? audio.start - audio.end,
            conflictLeft: false,
            conflictRight: false,
          },
        ]),
    );

    // Secondary pass to fill out secondary info and maybe update size
    objects
      .filter((audio) => audio.language === LanguageType.SECONDARY)
      .forEach((secondaryAudio) => {
        if (secondaryAudio.parentObjectId) {
          const primaryObject = baseObjectsMap.get(secondaryAudio.parentObjectId);

          if (primaryObject) {
            baseObjectsMap.set(secondaryAudio.parentObjectId, {
              ...primaryObject,
              secondaryId: secondaryAudio.objectId,
              ...(secondaryAudio.end > primaryObject.end
                ? {
                    end: secondaryAudio.end,
                    width: secondaryAudio.duration ?? secondaryAudio.start - secondaryAudio.end,
                  }
                : {}),
            });
          }
        }
      });

    setState({
      objects: baseObjectsMap,
    });

    // Call for routine where we calculate
    // The deltas and consider secondary audio
  },
  // CONFLICT
  currentPosition: null,
  conflictObject: null,
  conflictType: ConflictType.LEFT,
  setCurrentPosition: (position: number) => {
    setState({
      currentPosition: position,
    });
  },
  setConflict: (conflictObject, conflictType = ConflictType.LEFT) => {
    setState({
      conflictObject,
      conflictType,
    });
  },
}));

window.narrationStore = useNarrationStore;
