import _ from "lodash";
import { ISymbol, ISymbolV2 } from "../../components/Symbol/models/ISymbol";
import {
  IBasePage,
  IBasicPageImage,
  IBasicPageImageV2,
  IBasicPageTextBlock,
  IBasicPageTextBlockV2,
} from "../../pageTypes/BasicPage_Player/components/IBasePage";
import { ListOfObjects } from "./ListOfObjects";

export class CustomPageObjectList extends ListOfObjects {
  // imageList: IBasicPageImage[] | IBasicPageImageV2[];
  // textList: IBasicPageTextBlock[] | IBasicPageTextBlockV2[];
  // symbolList: ISymbolV2[];
  manifest: IBasePage;

  constructor(manifest: IBasePage, incrementor: number) {
    super();
    this.manifest = manifest;
    // this.imageList = this.getImageList();
    // this.textList = this.getTextBoxList();
    this.symbols = this.getSymbolList();
    this.annotations = manifest.annotations;
    // this.scorm = this.getScormList();
    // this.fullList = this.getObjectList();
    this.getImageList();
    this.getTextBoxList();
    this.getScormList();
    manifest.objectIncrementor = this.getObjectList(incrementor);
  }

  private getObjectList(incrementor: number) {
    let tempList: Array<any> = [];
    if (this.manifest.pageImage && this.manifest.pageImage.length > 0) {
      tempList = _.concat(tempList, this.compileImageList(this.pageImage));
    }

    if (this.manifest.textBlock && this.manifest.textBlock.length > 0) {
      tempList = _.concat(tempList, this.compileTextBoxList(this.textBlock));
    }

    if (this.manifest.annotations && this.manifest.annotations.length > 0) {
      tempList = _.concat(tempList, this.manifest.annotations);
    }

    if (this.manifest.symbols && this.manifest.symbols.length > 0) {
      tempList = _.concat(tempList, this.manifest.symbols);
    }

    if (this.manifest.pageScormObject && this.manifest.pageScormObject.length > 0) {
      tempList = _.concat(tempList, this.compileScormList(this.scorm));
    }

    if (_.has(this.manifest, "video")) {
      tempList.push(this.manifest.video);
    }

    if (!this.manifest.objectIncrementor) {
      incrementor = this.updateExistingObjectsWithObjectIds(tempList, incrementor);
    } else {
      if (tempList.length > 0 && !tempList?.[0]?.objectId) {
        incrementor = this.updateExistingObjectsWithObjectIds(tempList, incrementor);
      } else {
        incrementor = this.manifest.objectIncrementor;
      }
    }

    this.updateExistingObjectsWithIsDisplayed(tempList);

    // return tempList;
    this.setFullList(tempList);

    return incrementor;
  }

  private updateExistingObjectsWithIsDisplayed(objectList: any) {
    (objectList ?? []).forEach((object: any) => {
      if (object?.isDisplayed === true || object?.isDisplayed === false) {
      } else {
        if (object) {
          object.isDisplayed = true;
        }
      }
    });
  }
  private updateExistingObjectsWithObjectIds(objectList: any, objectId: number): number {
    objectList
      .filter((o) => o)
      .forEach((object) => {
        object.objectId = `${object.type}${objectId}`;
        objectId++;
      });

    objectId++;
    return objectId;
  }

  private getImageList() {
    let newList: Array<IBasicPageImageV2> = [];

    if (this.manifest.pageImage && !_.has(this.manifest.pageImage[0], "attributes")) {
      newList = _.cloneDeep(this.manifest.pageImage);
      _.forEach(newList, (item, index: number) => {
        if (!item.zIndex || (_.has(item, "z-index") && !item.zIndex)) {
          newList[index].zIndex = index + 12;
        }
      });
    } else {
      _.forEach(this.manifest.pageImage as unknown as IBasicPageImage[], (image, index) => {
        const newImage: IBasicPageImageV2 = {
          imagePath: image.imagePath,
          assetVersionId: image.assetVersionId,
          type: "pageImage",
          ...(image as IBasicPageImage).attributes,
        };
        if (!_.has(newImage, "zIndex") || !newImage.zIndex) {
          //@ts-ignore
          newImage.zIndex = newImage["z-index"];
          //@ts-ignore
          delete newImage["z-index"];
          if (newImage.zIndex === 0 || !newImage.zIndex) newImage.zIndex = index + 12;
        }
        newList.push(newImage);
      });
      // return newList;
    }
    newList = this.populateName(newList);
    this.setImageList(newList);
    // return newList;
  }

  private getTextBoxList() {
    if (this.manifest.textBlock && !_.has(this.manifest.textBlock[0], "attributes")) {
      _.forEach(this.manifest.textBlock, (block, index: number) => {
        if (!block.zIndex || (_.has(block, "z-index") && !block.zIndex)) {
          this.manifest.textBlock[index].zIndex = index + 12;
        }
      });
      this.setTextBlockList(this.manifest.textBlock);
      // return this.manifest.textBlock;
    } else {
      let newList: Array<IBasicPageTextBlockV2> = [];
      _.forEach(this.manifest.textBlock as unknown as IBasicPageTextBlock[], (block, index) => {
        // old lessons do not have a version property and iot needs to be added programmaticaly
        //to the object in the newBlock variable ideal getTextBoxList() method does not have access
        //to such operations and this is delegated to something else (hard to read/maintain)
        const temp = {};
        for (const key of Object.keys(block)) {
          if (key === "attributes") {
            continue;
          }
          const res = Reflect.defineProperty(temp, key, {
            value: block[key],
            enumerable: true,
          });
          if (!res) {
            console.error("Could not add a property to the new text block"); // below
          }
        }
        // this overwrites everything in manifest and replaces with object created inline below
        //there is an updatePageManifest call writing to manifest. (later)
        // temp is used to add all other root properties any afer will be overwriting
        const newBlock: IBasicPageTextBlockV2 = {
          ...temp,
          text: block.text,
          type: "textBlock",
          ...block.attributes,
        };
        if (!_.has(newBlock, "zIndex") || !newBlock.zIndex) {
          //@ts-ignore
          newBlock.zIndex = newBlock["z-index"];
          //@ts-ignore
          delete newBlock["z-index"];
          if (newBlock.zIndex === 0) newBlock.zIndex = index + 12;
        }
        newList.push(newBlock);
      });
      newList = this.populateName(newList);
      this.setTextBlockList(newList);
      // return newList;
    }
  }

  private getScormList() {
    let newScorm = this.compileScormList(this.manifest.pageScormObject);
    newScorm = this.populateName(newScorm);
    // return newScorm;
    // this.scorm = newScorm;
    this.setScormList(newScorm);
  }

  private getSymbolList() {
    let newList: ISymbolV2[] = [];
    _.forEach(this.manifest.symbols, (symbol) => {
      if (!_.has(symbol, "style")) {
        newList.push(symbol);
      } else {
        newList.push((symbol as unknown as ISymbol).style as ISymbolV2);
      }
    });

    newList = this.populateName(newList);
    return newList;
  }

  private populateName(list: any[]) {
    const newList = _.cloneDeep(list);
    _.forEach(newList, (el, key: number) => {
      if (!_.has(el, "name") || el.name === "") {
        newList[key].name = `${el.type}-${key}`;
      }
    });
    return newList;
  }

  public rebuildPageManifest() {
    this.manifest = {
      ...this.manifest,
      pageImage: this.pageImage as IBasicPageImageV2[],
      textBlock: this.textBlock as IBasicPageTextBlockV2[],
      annotations: this.annotations,
      symbols: this.symbols,
      pageScormObject: this.scorm,
    };
    return this.manifest;
  }
}
