// PageColor Provider

import React, { createContext, useReducer, useState } from "react";

export const PageColorStateContext = createContext<any>(null);
export const PageColorDispatchContext = createContext<any>(null);

interface Color {
  color: string;
  linearColorStop: number;
}

export interface ThemeDTO {
  colorFill: "gradient" | "solid";
  colors: Color[];
  gradientAngle?: number;
  gradientType?: "linear";
  showCPaTIcon: boolean;
}

export interface PageColorState extends ThemeDTO {
  applyingToNewPage: boolean;
}

const initialState = {
  applyingToNewPage: false,
  showCPaTIcon: true,
};

function reducer(state: any, action: any) {
  switch (action.type) {
    case "TOGGLE_APPLYING_TO_NEW_PAGE": {
      return {
        ...state,
        applyingToNewPage: !state.applyingToNewPage,
      };
    }
    case "TOGGLE_SHOW_CPAT_ICON": {
      return {
        ...state,
        showCPaTIcon: !state.showCPaTIcon,
      };
    }
    case "UPDATE_THEME": {
      const theme: ThemeDTO = action.payload;
      return {
        ...state,
        ...theme,
      };
    }
    default:
      return state;
  }
}

export const PageColorProvider = (props: any) => {
  const [pageColors, setPageColors] = useReducer<any>(reducer, initialState);

  return (
    <PageColorStateContext.Provider value={pageColors}>
      <PageColorDispatchContext.Provider value={setPageColors}>{props.children}</PageColorDispatchContext.Provider>
    </PageColorStateContext.Provider>
  );
};

export function usePageColor() {
  const context = React.useContext(PageColorStateContext);
  const context2 = React.useContext(PageColorDispatchContext);
  if (context === undefined) {
    throw new Error("usePageColor must be used within a PageColorProvider");
  }
  if (context2 === undefined) {
    throw new Error("usePageColor must be used within a PageColorProvider");
  }
  return [context, context2];
}

function formatColorAndStop(color: Color) {
  return `${color.color}${color.linearColorStop ? " " + color.linearColorStop + "%" : ""}`;
}

export function determinePageBG(theme: ThemeDTO) {
  try {
    if (theme.colorFill === "solid") {
      return theme.colors[0].color;
    } else {
      return `linear-gradient(${theme.gradientAngle ?? 20}deg, ${formatColorAndStop(
        theme.colors[0],
      )}, ${formatColorAndStop(theme.colors[1])})`;
    }
  } catch (e) {
    return "linear-gradient(27deg, #2e4460 20%, #bbc1ca 100%, #fafafa 100%)";
  }
}
