import _ from "lodash";
import { IAnnotation } from "../../components/Annotation/models/IAnnotation";
import { ISymbolV2 } from "../../components/Symbol/models/ISymbol";
import { ElementTypes } from "../../pageTypes/BasicPage_Player/components/IBasePage";
import { ListOfObjects } from "./ListOfObjects";

export class IGAObjectList extends ListOfObjects {
  // annotations: IAnnotation[] | undefined;
  // symbols: ISymbolV2[] | undefined;

  constructor(annotations: IAnnotation[] | undefined, symbols: ISymbolV2[] | undefined) {
    super();
    this.annotations = annotations ? annotations : [];
    this.symbols = symbols ? symbols : [];
    this.fullList = this.getAnnotationAndSymbolList();
  }

  protected getAnnotationAndSymbolList() {
    let tempList: any[] = [];

    if (!this.annotations && !this.symbols) {
      return tempList;
    } else if (this.annotations && !this.symbols) {
      return this.annotations;
    } else if (!this.annotations && this.symbols) {
      return this.symbols;
    } else {
      tempList = _.concat(this.annotations as any, this.symbols);
      return tempList;
    }
  }

  getNodeToEdit(index: number, type: ElementTypes) {
    let node;
    if (type === "annotations" && this.annotations) {
      node = this.annotations[index];
    }

    if (type === "symbols" && this.symbols) {
      node = this.symbols[index];
    }

    if (node) node = this.getNodeName(index, type, node);
    return node;
  }

  protected getNodeName(index: number, type: ElementTypes, node: IAnnotation | ISymbolV2) {
    if (!_.has(node, "name") || node.name?.length === 0) {
      node.name = this.validateNodeName(index, type);
    }
    return node;
  }

  protected validateNodeName = (index: number, type: ElementTypes) => {
    switch (type) {
      case "annotations":
        return `${(this.annotations as IAnnotation[])[index].type} ${index}`;
      case "symbols":
        return `${(this.symbols as ISymbolV2[])[index].name} ${index}`;
      default:
        return;
    }
  };

  getSelectedSymbolHeight(target: SVGSVGElement, pageDims: DOMRect, node: ISymbolV2) {
    node.height = (target.getBoundingClientRect().height / pageDims.height) * 100;
    return node.height;
  }
}
