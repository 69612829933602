import React, { useContext } from "react";
import { EditorContext } from "./MetaDataEditorContext/MetadataEditorContext";
import { MetadataEditorActions } from "./MetaDataEditorContext/MetadataEditorActions";
import ReactDOM from "react-dom";
import { ReactComponent as ExitIcon } from "../../assets/icons/Common/remove-tag-icon.svg";
interface Props {
  imageURL: string;
  assetType: string;
}
const AssetPreview = ({ imageURL, assetType }: Props) => {
  const { dispatch } = useContext(EditorContext);

  const handleCloseImagePreview = () => {
    dispatch({
      type: MetadataEditorActions.CLOSE_ASSET_PREVIEW,
    });
  };

  return (
    <div className="metadata-editor-img-preview-modal">
      <div className="metadata-editor-asset-preview-wrapper">
        <ExitIcon className="metadata-editor-img-preview-modal-x-button" onClick={handleCloseImagePreview}></ExitIcon>

        {assetType === "Video" ? (
          <video
            id="ff-video-main"
            controls
            autoPlay
            controlsList="nodownload"
            disablePictureInPicture
            className="metadata-editor-video-preview-modal "
          >
            <source type="video/mp4" src={`/api/Asset/${imageURL}`} /> // this does not render style-wise correctly yet
          </video>
        ) : (
          <img src={`/api/Asset/${imageURL}`}></img>
        )}

        <div className="metadata-editor-img-preview-modal-btn-div">
          <button
            className="metadata-editor-btn metadata-editor-img-preview-modal-close-preview-button"
            onClick={handleCloseImagePreview}
          >
            Close preview
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssetPreview;
