import React, { useState, useEffect, useContext } from "react";
import { ICduRectangularButton } from "../Interfaces/ICdu";
import { IFmsContext, FmsContext } from "../Interfaces/fmsContext";
import { IFmsPageManifest } from "../Interfaces/IFmsPageManifest";
import { IPageContext, PageContext } from "../../../routes/builderContexts";

interface cduLightsProps {
  cduLights: ICduRectangularButton[];
  cduName: string;
  selectCduLight: (lightId: string) => void;
}
interface lightProps {
  cduLight: ICduRectangularButton;
  colors: string[];
  selectCduLight: (lightId: string) => void;
  cduName: string;
}
const CduLights: React.FC<cduLightsProps> = (props: cduLightsProps): any => {
  //const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);
  // const pagesContext: IPageContext = useContext<IPageContext>(PageContext);
  //const pageManifest: IFmsPageManifest = pagesContext.pageManifest as IFmsPageManifest;

  const [lightColors, setLightColors] = useState<string[]>([]);

  useEffect(() => {
    const cduName = props.cduName.toLowerCase().split("cdu.");

    switch (cduName[0]) {
      case "a320e": {
        //determin colors
        setLightColors(["#ffffff", "#ffa500"]);
        break;
      }
      case "a330-200_": {
        setLightColors(["gray", "#ffffff", "#c57a0a", "#09d109"]);
        break;
      }
      case "b737-8max_":
      case "b737-800_": {
        setLightColors(["gray", "#ffffff"]);
        break;
      }
      case "b777-200_": {
        setLightColors(["#ffffff", "#ffa500"]);
        break;
      }
      case "a220_":
      case "b787-8_": {
        setLightColors(["#00000000", "#00ff00"]);
        break;
      }
      case "a320neo_": {
        setLightColors(["gray", "#ffffff", "#c57a0a", "#09d109"]);
        break;
      }
      case "b747-8_":
      case "b747-400_":
      case "b767_200_":
      case "b767_":
      case "atr72-600.svg":
        setLightColors(["#00000000", "#00ff00"]);
      case "b767_300_":
      case "b757_200_": {
        setLightColors(["#00000000", "#ffffff"]);
        break;
      }
      default: {
        //for a320
        setLightColors(["#00000000", "#ffa500"]);
        break;
      }
    }
  }, [props.cduName]);
  //eplise buttons
  //different colors depending on cdu

  return props.cduLights.map((cduLight: ICduRectangularButton, key: number) => {
    return (
      <Light
        key={key}
        cduLight={cduLight}
        colors={lightColors}
        selectCduLight={props.selectCduLight}
        cduName={props.cduName.split("cdu.")[0]}
      />
    );
  });
};

export const Light = (props: lightProps) => {
  const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);
  const pagesContext: IPageContext = useContext<IPageContext>(PageContext);
  const pageManifest: IFmsPageManifest = pagesContext.pageManifest as IFmsPageManifest;
  const verticleButton = props.cduLight.height > props.cduLight.width;
  const [on, toggleOn] = useState<boolean>(false);
  let enabledInitially =
    pageManifest.lights[props.cduLight.id.toUpperCase()]?.enabledInitially !== undefined
      ? pageManifest.lights[props.cduLight.id.toUpperCase()]?.enabledInitially
      : false;
  // const [toggleCriteria, setToggleCriteria] = useState<number[]>([]);

  useEffect(() => {
    // if(pageManifest.lights[props.cduLight.id] && pageManifest.lights[props.cduLight.id].enabledInitially === undefined){
    //     pageManifest.lights[props.cduLight.id]["enabledInitially"] = false
    //     pagesContext.updatePageManifest(pageManifest);
    // }
    if (
      pageManifest.lights[props.cduLight.id] &&
      pageManifest.lights[props.cduLight.id.toUpperCase()]?.enabledInitially === true
    ) {
      toggleOn(true);
    }
  }, []);

  useEffect(() => {
    if (pageManifest.lights && pageManifest.lights[props.cduLight.id.toUpperCase()]) {
      if (fmsContext.criteriaIndex !== -1) {
        let criteriaBoolean = on;
        pageManifest.successCriteria.map((criteria, index) => {
          if (criteria.type === "sequential") {
            criteria.successMatch.map((key) => {
              if (
                pageManifest.lights[props.cduLight.id.toUpperCase()].cduKey &&
                pageManifest.lights[props.cduLight.id.toUpperCase()].cduKey.includes(key) &&
                index <= fmsContext.criteriaIndex - 1
              ) {
                const criteriaArray = [];
                criteriaArray.push(index + 1);
                // setToggleCriteria(criteriaArray);
                criteriaBoolean = !criteriaBoolean;
              }
            });
          }
        });

        //criteriaBoolean = !criteriaBoolean;
        toggleOn(criteriaBoolean);
      }
      //if the criteria is the last criteria then the lights will be toggle
      // if(fmsContext.criteriaIndex === pageManifest.successCriteria.length-1 && pageManifest.lights[props.cduLight.id.toUpperCase()].criteriaIndex !== null ){
      //     toggleOn(!pageManifest.lights[props.cduLight.id].enabledInitially);
      // }
      if (pageManifest.lights[props.cduLight.id] && fmsContext.criteriaIndex === -1) {
        toggleOn(pageManifest.lights[props.cduLight.id].enabledInitially);
      }
    }
  }, [fmsContext.criteriaIndex]);

  const clickLight = () => {
    props.selectCduLight(props.cduLight.id);
    toggleOn(!on);
  };
  if (props.cduLight.id === "EXEC") {
    return (
      <rect
        x={props.cduLight.xCoordinate}
        y={props.cduLight.yCoordinate}
        rx={
          props.cduName === "b777-200_"
            ? 4
            : props.cduName === "b737-8max_"
            ? 3
            : props.cduLight.rx
            ? props.cduLight.rx
            : 0
        }
        ry={
          props.cduName === "b777-200_"
            ? 4
            : props.cduName === "b737-8max_"
            ? 3
            : props.cduLight.ry
            ? props.cduLight.ry
            : 0
        }
        width={props.cduLight.width}
        height={props.cduLight.height}
        onClick={clickLight}
        fill={enabledInitially ? (props.cduLight.color ? props.cduLight.color : props.colors[1]) : "#000000"}
        strokeWidth="2"
        pointerEvents="all"
        id={props.cduLight.id}
        className={"light"}
      />
    );
  } else if (props.cduLight.id === "EFB") {
    return (
      <rect
        x={props.cduLight.xCoordinate}
        y={props.cduLight.yCoordinate}
        rx={props.cduName === "b787-8_" ? 3 : props.cduLight.rx ? props.cduLight.rx : 0}
        ry={props.cduName === "b787-8_" ? 3 : props.cduLight.ry ? props.cduLight.ry : 0}
        width={props.cduLight.width}
        height={props.cduLight.height}
        onClick={clickLight}
        fill={enabledInitially ? (props.cduLight.color ? props.cduLight.color : props.colors[1]) : "#000000"}
        strokeWidth="2"
        pointerEvents="all"
        id={props.cduLight.id}
        className={"light"}
      />
    );
  } else if (props.cduLight.id === "LKEY") {
    return (
      <rect
        x={props.cduLight.xCoordinate}
        y={props.cduLight.yCoordinate}
        rx={props.cduName === "b787-8_" ? 3 : props.cduLight.rx ? props.cduLight.rx : 0}
        ry={props.cduName === "b787-8_" ? 3 : props.cduLight.ry ? props.cduLight.ry : 0}
        width={props.cduLight.width}
        height={props.cduLight.height}
        onClick={clickLight}
        fill={enabledInitially ? (props.cduLight.color ? props.cduLight.color : props.colors[1]) : "#000000"}
        strokeWidth="2"
        pointerEvents="all"
        id={props.cduLight.id}
        className={"light"}
      />
    );
  } else if (props.cduLight.id === "RKEY") {
    return (
      <rect
        x={props.cduLight.xCoordinate}
        y={props.cduLight.yCoordinate}
        rx={props.cduName === "b787-8_" ? 3 : 0}
        ry={props.cduName === "b787-8_" ? 3 : 0}
        width={props.cduLight.width}
        height={props.cduLight.height}
        onClick={clickLight}
        fill={enabledInitially ? props.colors[1] : "#000000"}
        strokeWidth="2"
        pointerEvents="all"
        id={props.cduLight.id}
        className={"light"}
      />
    );
  } else if (props.cduLight.id === "LWR") {
    return (
      <rect
        x={props.cduLight.xCoordinate}
        y={props.cduLight.yCoordinate}
        rx={props.cduName === "b787-8_" ? 3 : 0}
        ry={props.cduName === "b787-8_" ? 3 : 0}
        width={props.cduLight.width}
        height={props.cduLight.height}
        onClick={clickLight}
        fill={enabledInitially ? props.colors[1] : "#000000"}
        strokeWidth="2"
        pointerEvents="all"
        id={props.cduLight.id}
        className={"light"}
      />
    );
  } else if (props.cduLight.id === "REQUEST") {
    return (
      <rect
        x={props.cduLight.xCoordinate}
        y={props.cduLight.yCoordinate}
        rx={props.cduName === "b787-8_" ? 3 : props.cduLight.rx ? props.cduLight.rx : 0}
        ry={props.cduName === "b787-8_" ? 3 : props.cduLight.ry ? props.cduLight.ry : 0}
        width={props.cduLight.width}
        height={props.cduLight.height}
        onClick={clickLight}
        fill={enabledInitially ? (props.cduLight.color ? props.cduLight.color : props.colors[1]) : "#000000"}
        strokeWidth="2"
        pointerEvents="all"
        id={props.cduLight.id}
        className={"light"}
      />
    );
  } else if (props.cduLight.id.includes("LIGHT")) {
    return (
      <rect
        x={props.cduLight.xCoordinate}
        y={props.cduLight.yCoordinate}
        rx={
          props.cduName === "b777-200_"
            ? 4
            : props.cduName === "b737-8max_"
            ? 3
            : props.cduLight.rx
            ? props.cduLight.rx
            : 0
        }
        ry={
          props.cduName === "b777-200_"
            ? 4
            : props.cduName === "b737-8max_"
            ? 3
            : props.cduLight.ry
            ? props.cduLight.ry
            : 0
        }
        width={props.cduLight.width}
        height={props.cduLight.height}
        onClick={clickLight}
        fill={enabledInitially ? props.colors[1] : "#000000"}
        strokeWidth="2"
        pointerEvents="all"
        id={props.cduLight.id}
        className={"light"}
      />
    );
  } else if (
    (props.cduLight.id.includes("FAIL") ||
      props.cduLight.id.includes("MSG") ||
      props.cduLight.id.includes("POS") ||
      props.cduLight.id.includes("OFST")) &&
    props.cduName === "atr72-600.svg"
  ) {
    // debugger;
    return (
      <>
        <rect
          x={props.cduLight.xCoordinate}
          y={props.cduLight.yCoordinate}
          width={props.cduLight.width}
          height={props.cduLight.height}
          onClick={clickLight}
          pointerEvents="all"
          id={props.cduLight.id}
          className={"light"}
          rx={6}
          display={"flex"}
        />
        <text
          x={props.cduLight.xCoordinate + (props.cduLight.id === "OFST" ? 5 : 9)}
          y={props.cduLight.yCoordinate + props.cduLight.height / 1.5}
          width={props.cduLight.width}
          height={props.cduLight.height}
          onClick={clickLight}
          stroke={on ? props.cduLight.color : "black"}
          fontSize={".8rem"}
          // writingMode="tb"
        >
          {props.cduLight.id}
        </text>
        {/* </rect> */}
      </>
    );
  } else {
    return (
      <>
        <filter id="shadow">
          <feDropShadow dx="0" dy="0" stdDeviation="4.5" floodColor="white" />
          <feGaussianBlur in="SourceGraphic" stdDeviation=".51" />
        </filter>
        <rect
          x={props.cduLight.xCoordinate}
          y={props.cduLight.yCoordinate}
          width={props.cduLight.width}
          height={props.cduLight.height}
          onClick={clickLight}
          stroke={""}
          fill={
            props.cduName === "a330-200_" || props.cduName === "a320neo_"
              ? "#000000"
              : verticleButton
              ? "#000000"
              : "#00000000"
          }
          pointerEvents="all"
          id={props.cduLight.id}
          className={"light"}
        />
        {verticleButton ? (
          <text
            x={(props.cduLight.xCoordinate + (props.cduLight.xCoordinate + props.cduLight.width)) / 2 - 1}
            y={props.cduLight.yCoordinate + props.cduLight.height / 2}
            dominantBaseline="middle"
            textAnchor="middle"
            glyphOrientationVertical={0}
            writingMode="tb"
            stroke={
              props.cduLight.color && on
                ? props.cduLight.color
                : props.cduName.toLowerCase().includes("b7") && props.cduLight.id === "FAIL"
                ? on
                  ? "#ffa500"
                  : props.colors[0]
                : on
                ? props.colors[1]
                : props.colors[0]
            }
            onClick={clickLight}
            fontSize={".5rem"}
            fontWeight="smaller"
            fill={"#00000000"}
            strokeWidth=".1%"
            style={{
              textOrientation: "upright",
              maxWidth: `${props.cduLight.width}px`,
              textAlign: "center",
            }}
            fontStyle={"monospace"}
          >
            {props.cduLight.id}
          </text>
        ) : (
          <text
            x={(props.cduLight.xCoordinate + (props.cduLight.xCoordinate + props.cduLight.width)) / 2}
            y={props.cduLight.yCoordinate + props.cduLight.height / 2}
            width={props.cduLight.width}
            height={props.cduLight.height}
            dominantBaseline="middle"
            textAnchor="middle"
            glyphOrientationVertical={0}
            stroke={
              props.colors.length > 2
                ? on
                  ? props.colors[3]
                  : props.colors[2]
                : on
                ? props.colors[1]
                : props.colors[0]
            }
            fontSize={".4rem"}
            letterSpacing="0.5"
            onClick={clickLight}
            fontWeight="smaller"
            fill={"#00000000"}
            strokeWidth=".1%"
            style={{
              maxWidth: `${props.cduLight.width}px`,
              textAlign: "center",
              color: "red",
            }}
            fontStyle={"monospace"}
          >
            {props.cduLight.id}
          </text>
        )}
      </>
    );
  }
};
export const verticleTextButton = () => {};
export default CduLights;
