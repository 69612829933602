import { RangeStatic } from "quill";
import React from "react";
// import RichTextEditor from '../../../components/richTextEditor/richTextEditor';
import RichTextEditor from "../../../components/richTextEditor/NEWrichTextEditor";
import { basePageTextBoxWrapperVersioning } from "../../../utils/Versioning/basePagesVersioning";
import { IRTEModule } from "../BasePageDesigner";
import { IBasePage } from "./IBasePage";

type BaseProps = {
  moduleRTE: IRTEModule[];
  quillRef: React.RefObject<any>;
  placeholderText: string;
  textBlockIndex: number;
  updatedManifest: IBasePage;
  assignNewText: (newText: string) => void;
  handleSelectionChange: (range: RangeStatic) => void;
};

const BaseTextOnlyCenter: React.FC<BaseProps> = (props: any) => {
  return (
    <div className="base-page-text-area">
      {/* <div id="rteToolBar" className="rteToolBarContainer" /> */}
      <div className="text-only-content">
        <div className="row text-editor-row">
          <div
            className={
              "text-editor-base-page" + basePageTextBoxWrapperVersioning(props.updatedManifest.textBlock[0].version)
            }
          >
            <RichTextEditor
              displayText={props.updatedManifest.textBlock[0].text}
              assignNewText={props.assignNewText}
              placeholder={props.placeholderText}
              quillRef={props.quillRef}
              handleSelectionChange={props.handleSelectionChange}
              theme="bubble"
              version={props.updatedManifest.textBlock[0].version}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BaseTextOnlyCenter;
