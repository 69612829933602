export const ErrorArray = [];
let counter = 0;

function determineProbableCause(status) {
  switch (status) {
    case 500:
      return "API could be disconnected";
    case 415:
      return "whatever";
    default:
      break;
  }
}

export function cpatErrorHandler(response) {
  let ErrorObject = {};
  if (response) {
    const { status, data } = response;
    const { url, method } = response.config;

    let probableCause = determineProbableCause(status);
    Object.defineProperty(ErrorObject, "errorOrder-" + counter, {
      value: {
        data,
        url,
        method,
        status,
        probableCause,
      },
      writable: false,
      enumerable: true,
    });
    counter++;
    ErrorArray.push(ErrorObject);
  } else {
    return ErrorArray;
  }
  // console.table(ErrorArray)
}
