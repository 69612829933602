import { BoundType } from "../../react-moveable";
import { IAnnotation } from "../models/IAnnotation";
import { Annotation } from "./AnnotationClass";

export class Label extends Annotation {
  fullList: any;

  constructor(
    annotations: IAnnotation[],
    type: string,
    index: number,
    bounds: BoundType,
    fullList: any,
    objectId: number,
  ) {
    super(annotations, type, index, bounds, fullList, objectId);
    this.newObject = {
      name: "",
      type: "label",
      text: "",
      width: 17,
      height: 5,
      left: 3,
      top: 6,
      zIndex: 12,
      fontColor: "white",
      textAlign: "left",
      isDisplayed: true,
      objectId: `${type}${objectId}`,
    };
    this.fullList = fullList;
  }
}
