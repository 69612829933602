/**
 * SelectedObject
 * selectedObject
 */
import React, { PropsWithChildren, createContext, useContext, useReducer, useLayoutEffect, useMemo } from "react";
import { useObjectsState } from "../ObjectsProvider";
import { useTimeline } from "../TimelineProvider/TimelineProvider";
import { getFramesNearCurrentTime } from "../../utils/Animation/getFramesNearCurrentTime";
import { percentageToValue } from "../../utils/Conversion";
import { useMovableElementsPlaneState } from "../MovableElementsPlaneProvider";
import { isNumber } from "../../utils";
import { Blur, BlurCutoutObject } from "../../types/Objects";

export enum SelectedObjectActionTypes {
  SET_X,
  SET_Y,
  SET_WIDTH,
  SET_HEIGHT,
  SET_ROTATION,
  SET_OPACITY,
  SET_LOCK_ASPECT_RATIO,
  SET_Z_INDEX,
  SET_BLUR_INTENSITY,
  SET_BLUR_COLOR,
  SET_BLUR_CUTOUT,
  SET_YAW,
  SET_PITCH,
  SET_ZOOM,
  DESELECT,
}

export interface SelectedObjectState {
  objectId: string | null;
  x: number;
  y: number;
  width: number;
  height: number;
  rotation: number;
  opacity: number;
  lockAspectRatio: boolean;
  zIndex: number;
  offScreen: string | false;
  blur: Blur;
  blurCutoutShapes: BlurCutoutObject[];
  pitch: number;
  yaw: number;
  zoom: number;
}

const initialState: SelectedObjectState = {
  objectId: null,
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  rotation: 0,
  opacity: 1,
  lockAspectRatio: false,
  zIndex: 0,
  offScreen: false,
  blur: {
    pickerHexColor: "",
    intensity: 0,
    color: "",
    opacity: 0,
  },
  blurCutoutShapes: [],
  pitch: 0,
  yaw: 0,
  zoom: 0,
};

const SelectedObjectState = createContext<any>(initialState);
const SelectedObjectDispatch = createContext<any>({});

const selectedObjectReducer = (state: SelectedObjectState, action: any): SelectedObjectState => {
  switch (action.type) {
    case "SET_OBJECT_ID": {
      return { ...state, objectId: action.payload };
    }
    case SelectedObjectActionTypes.SET_X: {
      return { ...state, x: action.payload };
    }
    case SelectedObjectActionTypes.SET_Y: {
      return { ...state, y: action.payload };
    }
    case SelectedObjectActionTypes.SET_WIDTH: {
      return { ...state, width: action.payload };
    }
    case SelectedObjectActionTypes.SET_HEIGHT: {
      return { ...state, height: action.payload };
    }
    case SelectedObjectActionTypes.SET_ROTATION: {
      return { ...state, rotation: action.payload };
    }
    case SelectedObjectActionTypes.SET_OPACITY: {
      return { ...state, opacity: action.payload };
    }
    case SelectedObjectActionTypes.SET_Z_INDEX: {
      return { ...state, zIndex: action.payload };
    }
    case SelectedObjectActionTypes.SET_BLUR_INTENSITY: {
      return {
        ...state,
        blur: { ...state.blur, intensity: action.payload },
      };
    }
    case SelectedObjectActionTypes.SET_YAW: {
      return { ...state, yaw: action.payload };
    }
    case SelectedObjectActionTypes.SET_PITCH: {
      return { ...state, pitch: action.payload };
    }
    case SelectedObjectActionTypes.SET_ZOOM: {
      return { ...state, zoom: action.payload };
    }
    case SelectedObjectActionTypes.SET_BLUR_COLOR: {
      return {
        ...state,
        blur: { ...state.blur, ...action.payload },
      };
    }
    case SelectedObjectActionTypes.SET_BLUR_CUTOUT: {
      return {
        ...state,
        blurCutoutShapes: [...action.payload],
      };
    }
    case SelectedObjectActionTypes.DESELECT: {
      return { ...initialState };
    }

    default:
      return state;
  }
};

export function SelectedObjectProvider({ children }: PropsWithChildren<any>) {
  const [state, dispatch] = useReducer(selectedObjectReducer, initialState);
  const objectsState = useObjectsState();
  const [tl] = useTimeline();

  const animatedObject = useMemo(
    () => {
      return objectsState.animatedObjects.find((object) => object.id === objectsState.selectedObjects?.[0]?.objectId);
    },
    // reference checking since there is nothing to create new state while moving the time, the reference will not change, this is sensitve to future updates in the objects provider if that rule changes, a better option could be to create a cacheId for the animated objects to be 100% sure
    [objectsState.animatedObjects, objectsState.selectedObjects?.[0]?.objectId],
  );

  const { viewportDOMElementWidth, viewportDOMElementHeight } = useMovableElementsPlaneState();
  const selectedObjectFrames = animatedObject?.frames;
  useLayoutEffect(() => {
    const [selectedObject] = objectsState.selectedObjects;
    if (!selectedObject) {
      return;
    }

    const { rotation = 0, opacity = 1, left, top, width, height, blur, blurCutoutShapes } = selectedObject;

    let x = 0,
      y = 0,
      objectWidth = 0,
      objectHeight = 0,
      objectRotation = rotation || 0,
      objectOpacity = opacity || 1,
      objectBlur = blur?.intensity || 0,
      objectBlurCutoutShapes = blurCutoutShapes || [];

    const setPixelVal = (
      value: number | undefined,
      viewportDimension: number,
      fallbackPercent: number | undefined,
    ): number => (isNumber(value) ? value : percentageToValue(fallbackPercent ?? 0, viewportDimension));

    const setPercentVal = (
      value: number | undefined,
      viewportDimension: number,
      fallbackPercent: number | undefined,
    ): number =>
      isNumber(value)
        ? percentageToValue(value, viewportDimension)
        : percentageToValue(fallbackPercent ?? 0, viewportDimension);

    if (!isNumber(viewportDOMElementHeight) || !isNumber(viewportDOMElementWidth)) return;
    if (objectsState.selectedObjects.length === 1 && selectedObjectFrames?.length) {
      const interpolated = getFramesNearCurrentTime(selectedObjectFrames, tl.scrubbingCurrentTime);
      if (interpolated) {
        x = setPixelVal(interpolated.x, viewportDOMElementWidth, left);
        y = setPixelVal(interpolated.y, viewportDOMElementHeight, top);
        objectWidth = setPercentVal(interpolated.width, viewportDOMElementWidth, width);
        objectHeight = setPercentVal(interpolated.height, viewportDOMElementHeight, height);
        objectRotation = isNumber(interpolated.rotation) ? interpolated.rotation : rotation;
        objectOpacity = interpolated.opacity || opacity;
        objectBlur = interpolated.blur?.intensity || blur?.intensity || 0;
        objectBlurCutoutShapes = interpolated.blurCutoutShapes || blurCutoutShapes || [];
      }
    } else {
      x = percentageToValue(left || 0, viewportDOMElementWidth);
      y = percentageToValue(top || 0, viewportDOMElementHeight);
      objectWidth = percentageToValue(width || 0, viewportDOMElementWidth);
      objectHeight = percentageToValue(height || 0, viewportDOMElementHeight);
    }

    const actions = [
      { type: SelectedObjectActionTypes.SET_X, payload: x },
      { type: SelectedObjectActionTypes.SET_Y, payload: y },
      { type: SelectedObjectActionTypes.SET_WIDTH, payload: objectWidth },
      { type: SelectedObjectActionTypes.SET_HEIGHT, payload: objectHeight },
      { type: SelectedObjectActionTypes.SET_ROTATION, payload: objectRotation },
      { type: SelectedObjectActionTypes.SET_OPACITY, payload: objectOpacity },
      { type: SelectedObjectActionTypes.SET_BLUR_INTENSITY, payload: objectBlur },
      { type: SelectedObjectActionTypes.SET_BLUR_CUTOUT, payload: objectBlurCutoutShapes },
    ];
    for (const action of actions) {
      dispatch(action);
    }
  }, [
    tl.scrubbingCurrentTime,
    objectsState.selectedObjects[0]?.objectId,
    objectsState.selectedObjects[0]?.framesCacheId,
    viewportDOMElementWidth,
    viewportDOMElementHeight,
  ]);

  return (
    <SelectedObjectDispatch.Provider value={dispatch}>
      <SelectedObjectState.Provider value={state}>{children}</SelectedObjectState.Provider>
    </SelectedObjectDispatch.Provider>
  );
}

export function useSelectedObjectDispatch() {
  const ctx = useContext(SelectedObjectDispatch);
  if (ctx === undefined) {
    throw new Error("Wrap component in SelectedObjectProvider");
  }
  return ctx;
}

export function useSelectedObjectState() {
  const ctx = useContext(SelectedObjectState);
  if (ctx === undefined) {
    throw new Error("Wrap component in SelectedObjectProvider");
  }
  return ctx as SelectedObjectState;
}
