import React, { useState, useContext } from "react";
import { ReactComponent as PreviewLessonIcon } from "../../../assets/icons/HeaderIcons/preview-lesson-icon.svg";
import {
  ILessonPageActionsContext,
  LessonPageActionsContext,
  PageContext,
  IPageContext,
} from "../../../routes/builderContexts";
import { AbstractTooltip as Tooltip } from "../../ToolTip/ToolTip";
import { useObjectsState } from "../../../contexts/ObjectsProvider";
import { useInteracitvityHotspotState } from "../../../contexts/InteractivityHotspotProvider";
function FilePart(bigPath: string): string {
  const group = bigPath.split("/");
  const filename = group[group.length - 1];
  return filename;
}
type LessonModes = "Demo" | "Practice" | "Perform";
const PagePreview: React.FC<any> = (props: any) => {
  const lessonPageActions: ILessonPageActionsContext = useContext<ILessonPageActionsContext>(LessonPageActionsContext);
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const objectsState = useObjectsState();
  const interactivityHotspotState = useInteracitvityHotspotState();
  const [dropDownVisible, setDropDownVisible]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] =
    useState<boolean>(false);
  const link = "/api/PassThru/cpat/lesson/lessonpreview/" + props.lessonVersionId + ".preview/index.html";

  let previewLinkI = link + "?clasmode=Instructional";
  let previewLinkP = link + "?clasmode=Practice";
  let previewLinkT = link + "?clasmode=Test";

  let previewText = "Preview";
  if (undefined != props.pagenumber) {
    previewLinkI = previewLinkI + "&previewpageindex=" + props.pagenumber;
    previewLinkP = previewLinkP + "&previewpageindex=" + props.pagenumber;
    previewLinkT = previewLinkT + "&previewpageindex=" + props.pagenumber;
    previewText = "Page Preview";
  } else {
  }

  function canUse() {
    switch (lessonPageActions.previewMode) {
      case "Demo":
        return previewLinkI;
      case "Practice":
        return previewLinkP;
      case "Perform":
        return previewLinkT;
    }
    return "";
  }
  const aProps =
    lessonPageActions.dirt === 0 &&
    !objectsState.needSave &&
    !interactivityHotspotState.needSave &&
    !isDisabledLessonMode(lessonPageActions.previewMode)
      ? { href: canUse(), target: "_blank" }
      : {};
  function isDisabledLessonMode(mode: string) {
    let x = 0;
    switch (mode) {
      case "Demo":
        x = 1;
        break;
      case "Practice":
        x = 2;
        break;
      case "Perform":
        x = 3;
        break;
    }

    if (pageContext.page && pageContext.page.lessonModeIds) {
      if (pageContext.page.lessonModeIds.includes(x)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
  return (
    <React.Fragment>
      <a
        className="page-previewButtonContainer"
        {...aProps}
        data-tooltip-id="Preview Page"
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div>
          <PreviewLessonIcon />
        </div>
        <p>
          Page Preview{" "}
          {lessonPageActions.dirt !== 0 || objectsState.needSave || interactivityHotspotState.needSave
            ? "(Save Lesson)"
            : isDisabledLessonMode(lessonPageActions.previewMode)
            ? "(Mode Disabled)"
            : ""}
        </p>
      </a>
      <Tooltip id="Preview Page" />
    </React.Fragment>
  );
};

export default PagePreview;
