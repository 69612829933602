import React, { useState, useEffect, useContext } from "react";
import { successCriteriaObject, criteriaListProps } from "../../Interfaces/successCriteriaInterfaces";
import addButton from "../../../../assets/icons/Add-gray.svg";
import editButton from "../../../../assets/icons/FMSIcons/Edit.svg";
import deleteButton from "../../../../assets/icons/FMSIcons/Remove.svg";
import upButton from "../../../../assets/icons/FMSIcons/MoveUp.svg";
import downButton from "../../../../assets/icons/FMSIcons/MoveDown.svg";
import { FmsContext, IFmsContext } from "../../Interfaces/fmsContext";
import { Interweave } from "interweave";

const SuccessCriteriaList: React.FC<criteriaListProps> = (props: criteriaListProps) => {
  const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);
  const [criteriaList, setList] = useState<successCriteriaObject[]>(props.criteria); //needed to store criteria to state to keep map accurate
  //const criteriaNames = ["Key Press", "Scratchpad"]
  useEffect(() => {
    if (props.updateListArray) {
      setList(props.criteria);
      props.setUpdateListArray(false);
    }
  }, [props.updateListArray]);
  useEffect(() => {
    //calls the delete function when the list is updated
    props.deleteCriteria(criteriaList);
  }, [criteriaList]);

  const deleteCriteria = (id: number): void => {
    //updates state after a criteria is removed from the list
    const filteredCriteria = criteriaList.filter((criteria, index) => {
      return index !== id;
    });
    setList(filteredCriteria);
    props.disassociateLightCriteria(id);
    fmsContext.setCriteriaIndex(-1);
  };
  // const deleteAllCriteria = () =>{
  //     setList([])
  // }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <p className="strong-header">
            <strong>To Do Tasks Summary</strong>
          </p>
          {/* <button className="criteriaRemoveAll" onClick={deleteAllCriteria}>Remove All Tasks</button> */}
        </div>
      </div>
      <div className="row criteriaWrapper">
        <div className="col-12">
          {criteriaList.length > 0 && criteriaList[0] !== undefined ? (
            <React.Fragment></React.Fragment>
          ) : (
            <h3>No Tasks have been entered</h3>
          )}
          <div className="criteriaList padding-all-sides-10">
            {criteriaList.map((criteria, index) =>
              criteria ? (
                <div
                  key={index}
                  className={
                    props.invalidCriteriaIndex.includes(index)
                      ? "criteriaRow criteriaError"
                      : props.highlightIndex === index
                      ? "criteriaRow criteriaPop"
                      : "criteriaRow"
                  }
                >
                  <div className="col-1 rowElement orderButtonWrapper">
                    <div className="order-button-container" hidden={index === 0}>
                      <button
                        className="criteriaOrderButton shaded"
                        hidden={index === 0}
                        onClick={() => {
                          props.reOrderCriteria("up", index);
                        }}
                      >
                        <img src={upButton}></img>
                      </button>
                    </div>
                    <div
                      className="order-button-container"
                      hidden={index === criteriaList.length - 1 && criteriaList.length > 1}
                    >
                      <button
                        className="criteriaOrderButton shaded"
                        hidden={index === criteriaList.length - 1 && criteriaList.length > 1}
                        disabled={index === 0 && criteriaList.length === 1}
                        onClick={() => {
                          props.reOrderCriteria("down", index);
                        }}
                      >
                        <img src={downButton}></img>
                      </button>
                    </div>
                  </div>
                  <div className="col-3 rowElement">
                    <p className="rowElement">{criteria.type === "textMatch" ? "Scratchpad" : "Key Press"}</p>
                  </div>
                  <div className="col-6 criteriaInstructions divider ">
                    <p className="rowElement" style={{ marginLeft: "1%", marginRight: "1%", maxHeight: "100%" }}>
                      <Interweave content={criteria.instructions.replace(/<[^>]*>?/gm, "")} />
                      {/* {criteria.instructions.replace(/<[^>]*>?/gm, "")} */}
                    </p>
                  </div>
                  <div className="col-2 centered rowElement criteria-control-buttons">
                    <div className="criteria-control-container">
                      <figure
                        className="control-figure"
                        onClick={() => {
                          deleteCriteria(index);
                        }}
                      >
                        <img alt="delte" src={deleteButton} className="rowButton shaded" />
                      </figure>
                    </div>
                    <div className="criteria-control-container">
                      <figure
                        className="control-figure"
                        onClick={() => {
                          props.setCriteria(index);
                        }}
                      >
                        <img src={editButton} className="rowButton shaded" alt="edit" />
                      </figure>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              ),
            )}
            <div className="col-12 criteriaRow buttonRow">
              <div className="bottom-button-container">
                <figure className="criteria-bottom-button-figure" onClick={props.newCriteria}>
                  <img src={addButton} className="rowElement bottomButton shaded" />
                </figure>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "block",
                overflowWrap: "break-word",
              }}
            >
              <p
                className="text-danger"
                style={{
                  display: "inline",
                  maxWidth: "100%",
                  fontSize: "0.875rem",
                  lineHeight: "1.0625rem",
                  width: "41rem",
                }}
              >
                {props.listStatusMessage}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SuccessCriteriaList;
