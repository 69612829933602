import React, { MouseEventHandler, useLayoutEffect, useRef, useState } from "react";

import {
  clippedSpaceFromUnitSpace,
  formatFullUnitForGrid,
  formatSubUnitForGrid,
  snapToGrid,
  useTimeline,
} from "../../../contexts/TimelineProvider/TimelineProvider";
import { useDomRef } from "../../../hooks/useDomRef";

import { zIndexes } from "../TimelinePanel";

import createGrid from "./createGrid";
import "./StampsGrid.css";
import { useMiscUI } from "../../../contexts/MiscUI/MiscUIProvider";
import { useObjectsState } from "../../../contexts/ObjectsProvider";
import { useLessonPagesState } from "../../../contexts/LessonPagesProvider/LessonPagesProvider";
export default function StampsGrid() {
  const [timeline, dispatch, { clippedSpaceToUnitSpace }] = useTimeline();
  const {
    clippedSpace,
    widthInPx,
    scaledSpace: { leftPadding },
    subUnitsPerUnit,
  } = timeline;
  const containerRef = useRef<HTMLDivElement>(null);
  const [fullSecondStampsContainer, setFullSecondsStampContainer] = useDomRef();
  const [, setMisc] = useMiscUI();
  const objectsState = useObjectsState();
  const isFreeFormPoly = objectsState.selectedObjects[0]?.type === "freeFormPoly";
  const { selectedPanel } = useLessonPagesState(); 
  useLayoutEffect(() => {
    if (!fullSecondStampsContainer) return;
    drawStamps({
      fps: subUnitsPerUnit,
      clippedSpaceRange: clippedSpace.range,
      clippedSpaceWidth: widthInPx,
      fullSecondStampsContainer,
      leftPadding: leftPadding,
    });
  }, [widthInPx, clippedSpace, subUnitsPerUnit, leftPadding, fullSecondStampsContainer]);

  const handleStampClick: MouseEventHandler<HTMLDivElement> = (event) => {
    if (isFreeFormPoly && selectedPanel === "advanced") return;
    const targetInfo = event.currentTarget.getBoundingClientRect();
    const clickedX = event.clientX - targetInfo.x;
    const seconds = clippedSpaceToUnitSpace(clickedX);
    //snap to grid
    const snapped = snapToGrid(seconds, subUnitsPerUnit);
    dispatch({
      type: "SET_SECONDS",
      payload: snapped < 0 ? 0 : snapped,
    });
  };

  const handleMouseDown: MouseEventHandler<HTMLDivElement> = (event) => {
    if (isFreeFormPoly && selectedPanel === "advanced") return;
    setMisc({ type: "SEEKING_ON_TIMELINE", payload: true });
  };
  return (
    <div
      ref={containerRef}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        pointerEvents: "initial",
        width: widthInPx,
        zIndex: zIndexes.currentFrameStamp,
      }}
      onMouseDown={handleMouseDown}
      onClick={handleStampClick}
    >
      <div className="the-stamps" style={{ width: widthInPx + "px" }}>
        <div className="full-second-stamps-container" ref={setFullSecondsStampContainer}></div>
      </div>
    </div>
  );
}

function drawStamps(options: {
  fullSecondStampsContainer: HTMLDivElement;
  fps: number;
  clippedSpaceWidth: number;
  leftPadding: number;
  clippedSpaceRange: { start: number; end: number };
}) {
  const opts = {
    clippedSpaceRange: options.clippedSpaceRange,
    clippedSpaceWidth: options.clippedSpaceWidth,
    fps: options.fps,
  };
  let innerHTML = "";
  createGrid(opts, (_posInUnitSpace, isFullUnit) => {
    const posInUnitSpace = snapToGrid(_posInUnitSpace, opts.fps);
    const posInClippedSpace = clippedSpaceFromUnitSpace(
      posInUnitSpace,
      options.leftPadding,
      { range: options.clippedSpaceRange },
      options.clippedSpaceWidth,
    );

    if (isFullUnit) {
      innerHTML += createStampClass(formatFullUnitForGrid(posInUnitSpace, opts.fps), posInClippedSpace, "full-unit");
    } else {
      innerHTML += createStampClass(formatSubUnitForGrid(posInUnitSpace, opts.fps), posInClippedSpace, "sub-unit");
    }
  });
  options.fullSecondStampsContainer.innerHTML = innerHTML;
}

function createStampClass(pos: string, x: number, type: "full-unit" | "sub-unit") {
  return `<span class="${type}" style="transform: translate3d(${x.toFixed(1)}px, -50%, 0);">${pos}</span>`;
}
