import React, { useState, useEffect } from "react";
import * as THREE from "three";

import {
  useGLTF,
  useProgress
} from "@react-three/drei"

const Enviroment = (props) => {
  const gateSetPath = "/api/Asset/cpat/generic/3dmodels/conditions/NewAirport/Airport.gltf";
  const { scene } = useGLTF(gateSetPath);
  const { progress } = useProgress();
  
  useEffect(() => {
    if(progress === 100) {
      props.callBack();
    }
  }, [progress])


  useEffect(() => {
    scene.traverse((child) => {
      if(child.isMesh) {
        child.receiveShadow = true;
      }
    })
  }, [scene])

  return <primitive object={scene} />
};

export default Enviroment;