import React from "react";

const EmptyEditingScreen: React.FC = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <div style={{ height: "10%", width: "60%" }}>
        <h2
          style={{
            color: "white",
            textAlign: "center",
            fontSize: "1.875rem",
            lineHeight: "2.25rem",
            fontFamily: "var(--body-font)",
            whiteSpace: "pre-wrap",
          }}
        >
          {" "}
          Please select an option on the right or a Key/CDU line on the left.
        </h2>
      </div>
    </div>
  );
};

export default EmptyEditingScreen;
