import React, { ReactElement, useContext } from "react";
import { IFmsContext, FmsContext } from "../Interfaces/fmsContext";
import { IPageContext, PageContext } from "../../../routes/builderContexts";
import { IFmsPageManifest } from "../Interfaces/IFmsPageManifest";
import blobUrlFormatHelper from "../../../components/blobUrlFormatHelper";
const CduCursorSelector = (): ReactElement => {
  const pagesContext: IPageContext = useContext<IPageContext>(PageContext);
  const pageManifest: IFmsPageManifest = pagesContext.pageManifest as IFmsPageManifest;
  const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);
  return (
    <div style={{ marginTop: "3%", color: "#ffffff" }}>
      <p style={{ display: "block" }}>
        <strong>Cursor Graphic</strong>
      </p>
      <div style={{ display: "flex", width: "50%" }}>
        {fmsContext.currentCDU.cduAssets?.map((asset: any, index: number) =>
          asset.id.includes("cursor") ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  padding: "5px",
                  justifyContent: "space-around",
                }}
              >
                {asset.id.replaceAll("_cursor", " ").replaceAll("no", "none")}
              </div>
              <img
                src={blobUrlFormatHelper(pageManifest.cduPath + asset.href)}
                key={asset.id + index}
                onClick={() => {
                  pageManifest.pageLayouts["cursorType"] = asset.id;
                  pagesContext.updatePageManifest(pageManifest);
                }}
                style={{
                  margin: "5px",
                  padding: "5px",
                  maxWidth: "5rem",
                  border: pageManifest.pageLayouts.cursorType === asset.id ? "1px solid yellow" : "1px solid white",
                }}
                alt=""
              />
            </div>
          ) : (
            <></>
          ),
        )}
      </div>
    </div>
  );
};
export default CduCursorSelector;
