import React from "react";

interface BlockingModalProps {
  width: number | string;
  height: number | string;
  additionalStyles: { [key: string]: string | number };
  children?: React.ReactNode;
  className?: string;
}

export const BlockingModal = ({ width, height, additionalStyles, children, className }: BlockingModalProps) => {
  return (
    <div
      className={className ?? ""}
      style={{
        position: "absolute",
        ...additionalStyles,
        width: width,
        height: height,
      }}
    >
      {children}
    </div>
  );
};
