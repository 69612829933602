import { MetadataEditorActions } from "./MetadataEditorActions";
import MetadataEditorState from "../interfaces/MetadataEditorState";
import MetadataState, { Tag } from "../interfaces/MetadataState";
import _ from "lodash";

export const reducer = (
  state: MetadataEditorState,
  action: { type: MetadataEditorActions; payload: unknown },
): MetadataEditorState => {
  switch (action.type) {
    case MetadataEditorActions.OPEN_ASSET_PREVIEW:
      return {
        ...state,
        imagePreview: action.payload as any,
        showImagePreview: true,
      };

    case MetadataEditorActions.CLOSE_ASSET_PREVIEW:
      return { ...state, imagePreview: undefined, showImagePreview: false };

    case MetadataEditorActions.SELECT_METADATA:
      const newMeta = action.payload as MetadataState;
      return {
        ...state,
        selectedMetadata: { ...state.selectedMetadata, ...newMeta },
      };

    case MetadataEditorActions.ADD_KEY_VALUE_TAG:
      const tagPayload = action.payload as Tag;
      const newTags = {
        ...state.selectedMetadata.tags,
        ...tagPayload,
        ...state.defaultTags,
      } as Tag;
      return {
        ...state,
        selectedMetadata: { ...state.selectedMetadata, tags: newTags },
        defaultTags: { "": "" },
      };

    case MetadataEditorActions.REMOVE_KEY_VALUE_TAG:
      const updatedTags = _.omit({ ...state.selectedMetadata.tags }, action.payload as string) as Tag;
      return {
        ...state,
        selectedMetadata: { ...state.selectedMetadata, tags: updatedTags },
      };

    case MetadataEditorActions.ADD_DEFAULT_TAG:
      const tag = action.payload as Tag;
      return { ...state, defaultTags: tag };

    case MetadataEditorActions.REMOVE_DEFAULT_TAG:
      return { ...state, defaultTags: {} };

    default:
      return state;
  }
};
