import React from "react";
import "./ImageDataEditor.css";
interface Props {
  children: React.ReactNode;
}

const ImageMetadataEditorContainer = ({ children }: Props) => {
  return <div className="metadata-editor-container">{children}</div>;
};

export default ImageMetadataEditorContainer;
