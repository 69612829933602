import { HexColorInput, HexColorPicker } from "react-colorful";
import { Fill, SelectPanel } from "..";
import styles from "../AdvancedPanel.module.css";
import { useMetaVariableStore } from "../../../../store";
import { useAdvancedPanelTimeline } from "../useAdvancedPanelTimeline";
import { TimelineButton } from "./TimeLineButton";
import { useTimeline } from "../../../../../../contexts/TimelineProvider/TimelineProvider";
import { useObjectsState } from "../../../../../../contexts/ObjectsProvider";
import { Collapse } from "./Collapse";

interface FillProps {
  data: Fill;
  onChange: () => void;
}

export function FillInput({ data, onChange }: FillProps) {
  const set = useMetaVariableStore((s) => s.setMetaVariable);
  const setColor = useMetaVariableStore((s) => s.setColor);
  const selectedValue = useMetaVariableStore((s) => s.metaVariables[data.key] as string);
  const metaData = useMetaVariableStore((s) => s.metaVariablesData[data.key]);
  const upsertAnimatedMetaVariable = useMetaVariableStore((s) => s.upsertAnimatedMetaVariable);
  const [tl] = useTimeline();
  const currentTime = tl?.scrubbingCurrentTime;
  const selectedObjectId = useObjectsState().selectedObjects[0]?.objectId;
  const { isObjectInTimeline, handleTimelineClick, metaVariableInCurrentTime } = useAdvancedPanelTimeline(data.key);

  const handleSelectPanelChange = (key: string, value: any) => {
    set(key, value);
    if (isObjectInTimeline) {
      upsertAnimatedMetaVariable(key, currentTime, value, selectedObjectId);
    }
    onChange();
  };

  const handleHexInputChange = (newColor: string) => {
    setColor(data.key, selectedValue, newColor);
    const color = {
      colors: {
        [selectedValue]: newColor,
      },
    };

    if (isObjectInTimeline) {
      upsertAnimatedMetaVariable(data.key, currentTime, selectedValue, selectedObjectId, false, color);
    }
    onChange();
  };

  const onTimelineClick = () => {
    handleTimelineClick(selectedValue);
    onChange();
  };

  return (
    <Collapse title={data.name} key={data.name}>
      <div className={styles.group}>
        <div className={styles.timelineWrapper}>
          <div className={styles.selectWrapper}>
            <SelectPanel
              key={data.key}
              data={data}
              selectedValue={selectedValue}
              handleChange={handleSelectPanelChange}
            />
          </div>
          {isObjectInTimeline && <TimelineButton active={!!metaVariableInCurrentTime} onClick={onTimelineClick} />}
        </div>
        <div className={styles.colorGroup}>
          <span>Color</span>
          <span className={styles.hexWrapper}>
            #
            <HexColorInput color={metaData.colors?.[selectedValue]} onChange={handleHexInputChange} />
          </span>
        </div>
        <HexColorPicker
          className={styles.colorPicker}
          color={metaData.colors?.[selectedValue]}
          onChange={handleHexInputChange}
        />
      </div>
    </Collapse>
  );
}
