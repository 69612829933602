import React, { useEffect, useState, useContext, Dispatch } from "react";
import "./proceduresDropdown.css";
import {
  LessonPageActionsContext,
  ILessonPageActionsContext,
  IFMSObject,
  IProceduresObject,
} from "../../../routes/builderContexts";
import IAsset from "../../../models/IAsset";
type TRole = "Captain" | "FO";
const ProceduresDropDown = React.memo((props: any) => {
  const lessonPageActions = useContext<ILessonPageActionsContext>(LessonPageActionsContext);
  const { aircraftId, setCurrent3DObject, setLoaded } = props;

  const [role, setRole]: [TRole, React.Dispatch<React.SetStateAction<TRole>>] = useState<TRole>(
    lessonPageActions.proceduresObject.role,
  );
  const [chosenModelIndex, setChosenModelIndex]: [number, React.Dispatch<React.SetStateAction<number>>] =
    useState<number>(0);

  useEffect(() => {
    lessonPageActions.setCurrent3DObject({
      modelSet: lessonPageActions.threeDModels[chosenModelIndex].blobPath,
      assetVersionId: lessonPageActions.threeDModels[chosenModelIndex].assetVersionId,
      role: role,
    });
  }, [chosenModelIndex]);

  const handleModelChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (props.isThereA3DPage) {
      lessonPageActions.setDeleteWarning(true);
      lessonPageActions.setPageTypeToDelete("ThreeDModel");
      setChosenModelIndex(event.target.selectedIndex);
      lessonPageActions.setCurrent3DObject({
        modelSet: lessonPageActions.threeDModels[chosenModelIndex].blobPath,
        assetVersionId: lessonPageActions.threeDModels[chosenModelIndex].assetVersionId,
        role: role,
      });
    }
    const selectedModel = lessonPageActions.threeDModels.find((el: IAsset, index: number) => {
      return el.blobPath === event.target.value;
    });
    if (selectedModel) {
      setChosenModelIndex(event.target.selectedIndex);
      lessonPageActions.setCurrent3DObject({
        modelSet: selectedModel.blobPath,
        assetVersionId: selectedModel.assetVersionId,
        role: role,
      });
    }
    // else if(event.target.value === 'no3DorFms'){
    //     lessonPageActions.setCurrent3DObject(
    //         {
    //             modelSet: event.target.value,
    //             assetVersionId: undefined,
    //             role: role
    //         }
    //     )
    // }
  };
  return (
    <div className="procedures-selection-menu">
      <div>
        <div style={{ display: "flex" }}>
          <p>Role: </p>
          <select
            value={role}
            onChange={(e) => {
              e.persist();
              if (e.target.value === "Captain" || e.target.value === "FO") {
                lessonPageActions.setCurrent3DObject(
                  (old3d: IProceduresObject) => ({ ...old3d, role: e.target.value } as IProceduresObject),
                );
                setRole(e.target.value);
              }
            }}
          >
            {"setWarning" in props ? (
              <React.Fragment>
                <option>Captain</option>
                <option>FO</option>
              </React.Fragment>
            ) : (
              <option>{lessonPageActions.proceduresObject.role}</option>
            )}
          </select>
        </div>
        <div>
          <select value={lessonPageActions.threeDModels[chosenModelIndex].blobPath} onChange={handleModelChange}>
            {lessonPageActions.threeDModels.map((model: IAsset, index: number) => {
              return (
                <option key={index + model.name} value={model.blobPath}>
                  {model.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className="image-box">
        <div className="model"></div>
      </div>
    </div>
  );
});

export default ProceduresDropDown;
