import ILessonMetaRequest from "../../../models/ILessonMetaRequest";
import { IUpdateLessonRequest } from "../../../models/UpdateLessonApiInterfaces";
import genericRepositoryService from "../../../services/genericRepositoryService";
import Asset from "../interfaces/Asset";
import MetadataState from "../interfaces/MetadataState";

export const validateMetadata = (asset: Asset): boolean => {
  // we don't care about validating data from unselected assets
  if (!asset.isSelected) return true;
  if (asset.assetMetadata === undefined || asset.assetMetadata === null) return false;

  if (
    asset.isSelected &&
    (asset.assetMetadata.manufacturerId === undefined ||
      asset.assetMetadata.fleetId === undefined ||
      asset.assetMetadata.variantId === undefined)
  ) {
    return false;
  }
  return true;
};

// this one just checks for valid metadata for no particular asset,
export const isValidMetadata = (metadata: MetadataState) => {
  if (Object.values(metadata).some((value) => value === undefined)) return false;
  return true;
};
export const validateAllAssetsMetadata = (assets: Asset[]): boolean => {
  const selectedAssets = assets.filter((asset) => asset.isSelected);
  if (!assets.some((asset) => asset.isSelected)) return false;
  if (selectedAssets.some((asset) => asset.isSelected && !validateMetadata(asset))) return false;
  return true;
};

export const removeForceCompletion = (lessonMeta: ILessonMetaRequest) => {
  const updateRequest: IUpdateLessonRequest = {
    lessonPagesToAdd: [],
    lessonPagesToUpdate: [],
    pageManifestsToUpdate: [],
    lessonPagesToDelete: [],
    //lessonAssetsToAdd: [],
    lessonAssetsToRemove: [],
    lessonMeta: lessonMeta,
  };

  genericRepositoryService.updateLesson(updateRequest);
};

export const updateAsset = async (asset: Asset, shareAssets = false, defaultTags: any) => {
  if (
    asset.assetMetadata === undefined ||
    asset.assetMetadata?.manufacturerId === undefined ||
    asset.assetMetadata?.fleetId === undefined ||
    asset.assetMetadata?.variantId === undefined
  )
    return;

  const tags = Object.keys(asset.assetMetadata.tags!).length === 0 ? null : asset.assetMetadata.tags;
  const otherTags = Object.keys(defaultTags)[0] === "" ? null : defaultTags;
  const consolidatedTags = [];
  if (tags) {
    Object.entries(tags).forEach(([key, value]) => {
      consolidatedTags.push({ [key]: value });
    });
  }
  if (otherTags) {
    consolidatedTags.push({ ...otherTags });
  }
  const updateAssetRequest = {
    assetVersionId: asset.assetVersionId,
    manufacturerId: [asset.assetMetadata.manufacturerId],
    aircraftFamilyId: [asset.assetMetadata.fleetId],
    aircraftId: [asset.assetMetadata.variantId],
    name: asset.name,
    description: asset.description.replace(/[^a-zA-Z0-9\w\s]/g, "_"),
    isLibrary: shareAssets,
    tags: consolidatedTags,
  };
  try {
    const response = await genericRepositoryService.updateAssetMetaData(updateAssetRequest);
    return response;
  } catch (e) {
    console.error(e);
    return { isSuccess: false };
  }
};

export const getMostRestrictiveIdentifier = (
  lessonMeta: ILessonMetaRequest,
): {
  manufacturerId: number | null;
  aircraftFamilyId: number | null;
  aircraftId: number | null;
} => {
  const { manufacturerId, aircraftId, aircraftFamilyId } = lessonMeta;

  if (aircraftId) {
    return {
      manufacturerId: null,
      aircraftFamilyId: null,
      aircraftId: aircraftId,
    };
  } else if (aircraftFamilyId) {
    return {
      manufacturerId: null,
      aircraftFamilyId: aircraftFamilyId,
      aircraftId: null,
    };
  } else if (manufacturerId) {
    return {
      manufacturerId: manufacturerId,
      aircraftFamilyId: null,
      aircraftId: null,
    };
  } else {
    return {
      manufacturerId: null,
      aircraftFamilyId: null,
      aircraftId: null,
    };
  }
};

/**
   
    lessonMeta updating looked like this when it was tightly couple with the Editor Component
    const removeForceCompletion = () => {
    const updateRequest: IUpdateLessonRequest = {
      lessonPagesToAdd: [],
      lessonPagesToUpdate: [],
      pageManifestsToUpdate: [],
      lessonPagesToDelete: [],
      lessonAssetsToAdd: [],
      lessonAssetsToRemove: [],
      lessonMeta: {
        ...Props.lessonMeta,
        additionalSettings: {
          ...Props.lessonMeta.additionalSettings,
          forceAssetMetaCompletion: false,
        },
      },
    };
    genericRepositoryService.updateLesson(updateRequest);
  };

   */
