import { createStore, applyMiddleware, AnyAction, Reducer, Store } from "redux";
import { persistStore, persistReducer, WebStorage, Persistor } from "redux-persist";
import rootReducer, { ILessonDesignerStore } from "./reducers/rootReducer";
import { PersistPartial } from "redux-persist/es/persistReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import sessionStorage from "redux-persist/lib/storage/session";

interface IPersistConfig {
  key: string;
  storage: WebStorage;
  whitelist: string[];
  stateReconciler?: any;
}

interface ILessonDesignerStoreConfiguration {
  store: Store<ILessonDesignerStore & PersistPartial, AnyAction> & {
    dispatch: unknown;
  };
  persistor: Persistor;
}

class LessonDesignerStore {
  private store: Store<ILessonDesignerStore & PersistPartial, AnyAction> & {
    dispatch: unknown;
  };

  constructor() {
    this.store = null as unknown as Store<ILessonDesignerStore & PersistPartial, AnyAction> & { dispatch: unknown };
  }

  private stateReconciler(
    stateStoredInLocalStorage: ILessonDesignerStore,
    stateSetFromLoginApi: ILessonDesignerStore,
    finalizedState: ILessonDesignerStore,
    fromQueryParam: boolean,
  ): ILessonDesignerStore {
    finalizedState.authorizedState.isAuthenticated = stateSetFromLoginApi.authorizedState.isAuthenticated;
    finalizedState.authorizedState.canManageUsers = stateSetFromLoginApi.authorizedState.canManageUsers;
    finalizedState.authorizedState.isCpatUser = stateSetFromLoginApi.authorizedState.isCpatUser;

    if (
      !fromQueryParam &&
      stateStoredInLocalStorage &&
      stateStoredInLocalStorage.authorizedState &&
      stateStoredInLocalStorage.authorizedState.lmsKey
    ) {
      finalizedState.authorizedState.lmsKey = stateStoredInLocalStorage.authorizedState.lmsKey;
    } else {
      finalizedState.authorizedState.lmsKey = stateSetFromLoginApi.authorizedState.lmsKey;
    }

    return finalizedState;
  }

  public fetchCurrentLms(): number | null {
    if (this.store) {
      return this.store.getState().authorizedState.lmsKey as number;
    } else {
      return null;
    }
  }

  public createStore(
    lmsKey: number,
    fromQueryParam: boolean,
    isLoggedIn: boolean,
    canManageUsers: boolean,
    isCpatUser: boolean,
  ): ILessonDesignerStoreConfiguration {
    const persistConfig: IPersistConfig = {
      key: "root",
      storage: sessionStorage,
      whitelist: ["authorizedState"],
      stateReconciler: (
        inboundState: ILessonDesignerStore,
        originalState: ILessonDesignerStore,
        reducedState: ILessonDesignerStore,
      ): ILessonDesignerStore => {
        return this.stateReconciler(inboundState, originalState, reducedState, fromQueryParam);
      },
    };

    const persistedReducer: Reducer<ILessonDesignerStore & PersistPartial, AnyAction> = persistReducer<
      ILessonDesignerStore,
      AnyAction
    >(persistConfig, rootReducer);

    this.store = createStore(
      persistedReducer,
      {
        authorizedState: {
          lmsKey: lmsKey,
          canManageUsers: canManageUsers,
          isAuthenticated: isLoggedIn,
          isCpatUser: isCpatUser,
        },
      },
      composeWithDevTools(applyMiddleware(thunk)),
    );

    const persistor: Persistor = persistStore(this.store);
    return {
      store: this.store,
      persistor: persistor,
    };
  }
}

export default new LessonDesignerStore();
