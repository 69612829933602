import {
  useLessonPagesState,
  LessonPagesActions,
  useLessonPagesDispatch,
} from "../contexts/LessonPagesProvider/LessonPagesProvider";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../contexts/ObjectsProvider";
import { useTimelineDispatch } from "../contexts/TimelineProvider/TimelineProvider";
import { useMetaVariableStore } from "../lib/SmartObject/store";
import { useAudioManagerStore } from "../contexts/PageAudioManager";

export function useGoToPage(OLD_STATE_lessonPages: any[]) {
  const lessonPagesState = useLessonPagesState();
  const lessonPagesDispatch = useLessonPagesDispatch();
  const objectsState = useObjectsState();
  const objectsDispatch = useObjectsDispatch();
  const timelineDispatch = useTimelineDispatch();
  const resetTimeline = useAudioManagerStore((state) => state.resetNarration);

  /**
   * Meta Variables
   */

  const clearMetaVariables = useMetaVariableStore((s) => s.clearAllMetaVariables);
  const clearAnimatedMetaVariables = useMetaVariableStore((s) => s.clearAnimatedMetaVariables);
  const loadStore = useMetaVariableStore((s) => s.loadStore);

  return (pageIndexToDisplay: number) => {
    // we save all edits made to the objects on the page before switching pages
    const scorm = objectsState.objectList.find((object) => object.type === "SCORM");
    lessonPagesDispatch({
      type: LessonPagesActions.SET_OBJECTS_ON_PAGE_MANIFEST,
      payload: {
        objects: objectsState.objectList,
        animatedObjects: objectsState.animatedObjects,
        images: objectsState.images,
        textBoxes: objectsState.textBoxes,
        tables: objectsState.tables,
        annotations: objectsState.annotations,
        videos: objectsState.videos,
        hotspots: objectsState.hotspots,
        scorm,
        smartObjects: objectsState?.smartObjects,
        panoramicList: objectsState.panoramicList,
      },
    });

    /**
     * We check for meta variables in current page and save them
     * to the current page manifest
     */
    const { metaVariables, metaVariablesData, animatedMetaVariables } = useMetaVariableStore.getState();

    if (metaVariables) {
      lessonPagesDispatch({
        type: LessonPagesActions.UPDATE_CURRENT_PAGE_MANIFEST,
        payload: {
          metaVariables,
          metaVariablesData,
          animatedMetaVariables,
        },
      });
    }

    /**
     * We clear the meta variables before going into a new page
     * to make sure that the zustand store doesnt get too loaded
     * with all the meta variables from all the pages
     */
    clearMetaVariables(); // probably not needed ?
    clearAnimatedMetaVariables();

    // we switch the currently selected page from the lesson pages
    lessonPagesDispatch({
      type: LessonPagesActions.SET_CURRENT_PAGE_INDEX,
      payload: pageIndexToDisplay,
    });
    const newPageManifest = {
      ...lessonPagesState.lessonPages[pageIndexToDisplay].pageManifest,
      // tables: OLD_STATE_pageManifest.tables,
    };

    /**
     * We check for meta variables in the page we're going to
     */
    const newPageMetaVariables = newPageManifest.metaVariables;
    const newPageMetaVariablesData = newPageManifest.metaVariablesData;
    const newPageAnimatedMetaVariables = newPageManifest.animatedMetaVariables;

    /**
     * This replaces the whole store with the new meta variables and
     * meta variables data, this will delete the old meta variables
     * if there were any.
     */
    if (newPageMetaVariables) {
      loadStore(newPageMetaVariables, newPageMetaVariablesData, newPageAnimatedMetaVariables ?? []);
    } else {
      clearMetaVariables();
    }

    // we create a new object list from the new page manifest
    objectsDispatch({
      type: ObjectActionsType.SET_OBJECTS_FROM_PAGE_MANIFEST,
      payload: newPageManifest,
    });
    const sequenceLength = newPageManifest?.timeline?.sequenceLength ?? newPageManifest?.timeline?.timelineLength ?? 30;
    timelineDispatch({
      type: "SET_SEQUENCE_LENGTH",
      payload: sequenceLength,
    });
    timelineDispatch({
      type: "SET_RANGE",
      payload: {
        start: 0,
        end: 25,
      },
    });
    // Reset scrub
    timelineDispatch({
      type: "SET_SECONDS",
      payload: 0,
    });
    // Reset selected audio if any
    resetTimeline();
  };
}
