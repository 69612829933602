import { useEffect, useReducer, useState } from "react";
import { LessonData, useLessonData } from "../contexts/LessonDataProvider";
import { ManufacturerList } from "../contexts/utils/createFormattedAircrafts";

const NONE_MANUFACTURER_ID = 5;
const NONE = "None";
type DropDownProperty = {
  name: string;
  id: number;
};
interface DropDownState {
  currentManufacturer: DropDownProperty;
  currentFleet: DropDownProperty | null;
  currentVariant: DropDownProperty | null;
  fleetList: { [key: string]: DropDownProperty };
  variantList: { [key: string]: DropDownProperty };
  aircraftPool: ManufacturerList;
}

type MFVDropDownProps = {
  initialManufacturer: string;
  initialVariant?: string;
  initialFleet?: string;
};

enum DropDownActionType {
  SET_MANUFACTURER = "SET_MANUFACTURER",
  SET_VARIANT = "SET_VARIANT",
  SET_FLEET = "SET_FLEET",
  SET_INITIAL_AIRCRAFT_POOL = "SET_INITIAL_AIRCRAFT_POOL",
  SET_MOST_SPECIFIC_ASSOC = "SET_MOST_SPECIFIC_ASSOC",
}

type SetManFleetVar = {
  type: Exclude<DropDownActionType, DropDownActionType.SET_INITIAL_AIRCRAFT_POOL>;
  payload: string;
};

type SetInitialAircraft = {
  type: DropDownActionType.SET_INITIAL_AIRCRAFT_POOL;
  payload: ManufacturerList;
};

type SetMostSpeficiSpec = {
  type: DropDownActionType.SET_MOST_SPECIFIC_ASSOC;
  payload: MFVDropDownProps;
};
type DropwDownAction = SetManFleetVar | SetInitialAircraft | SetMostSpeficiSpec;

export interface MFVDropDown {
  currentManufacturer: DropDownProperty;
  currentFleet: DropDownProperty | null;
  currentVariant: DropDownProperty | null;
  fleetList: string[];
  variantList: string[];
  manufacturerList: string[];
  setSelectedManufacturer: (value: string) => void;
  setSelectedFleet: (value: string) => void;
  setSelectedVariant: (value: string) => void;
  setMostSpecificAssociation: (input: MFVDropDownProps) => void;
}

const reducer = (state: DropDownState, action: DropwDownAction): DropDownState => {
  switch (action.type) {
    case DropDownActionType.SET_MANUFACTURER: {
      if (!Object.keys(state.aircraftPool).length) return state;
      const manKey = action.payload;
      const manList = state.aircraftPool;
      const fleets = manList[manKey].fleets;
      return {
        ...state,
        currentManufacturer: {
          name: manList[manKey]?.name ?? "",
          id: manList[manKey]?.id ?? "",
        },
        fleetList: fleets,
        variantList: {},
        currentFleet: {
          name: fleets[NONE].name,
          id: fleets[NONE].id,
        },
        currentVariant:
          manKey === "None"
            ? {
                name: fleets[NONE].variants[NONE].name,
                id: fleets[NONE].variants[NONE].id,
              }
            : null,
      };
    }

    case DropDownActionType.SET_FLEET: {
      const manList = state.aircraftPool;
      const fleetKey = action.payload;
      const fleets = manList?.[state.currentManufacturer.name]?.fleets;
      const variants = fleets?.[fleetKey]?.variants;
      const currentVariant = variants?.[NONE] ?? variants?.[Object.keys(variants)[0]] ?? null;
      return {
        ...state,
        currentFleet: {
          name: fleets?.[fleetKey]?.name ?? "",
          id: fleets?.[fleetKey]?.id ?? -1,
        },
        currentVariant: currentVariant
          ? {
              name: currentVariant.name,
              id: currentVariant.id,
            }
          : null,
        variantList: variants ?? {},
      };
    }
    case DropDownActionType.SET_VARIANT: {
      if (!Object.keys(state.variantList).length) return state;
      const variant = state.variantList[action.payload];
      return {
        ...state,
        currentVariant: variant,
      };
    }

    case DropDownActionType.SET_INITIAL_AIRCRAFT_POOL: {
      return {
        ...state,
        aircraftPool: action.payload as ManufacturerList,
      };
    }
    case DropDownActionType.SET_MOST_SPECIFIC_ASSOC: {
      const {
        initialManufacturer: manKey,
        initialFleet: fleetKey,
        initialVariant: variantKey,
      } = action.payload as MFVDropDownProps;
      const manList = state.aircraftPool;

      const manufacturer = manList[manKey];
      const fleets = manList[manKey]?.fleets ?? {};
      const fleet = fleets[fleetKey as string] ?? null;
      const variants = fleets[fleetKey as string]?.variants ?? {};
      const variant = variants[variantKey as string] ?? null;
      return {
        ...state,
        variantList: variants ?? {},
        currentManufacturer: manufacturer
          ? { id: manufacturer.id, name: manufacturer.name }
          : { id: NONE_MANUFACTURER_ID, name: "None" },
        currentFleet: fleet ? { id: fleet.id, name: fleet.name } : null,
        currentVariant: variant ? { id: variant.id, name: variant.name } : null,
      };
    }
    default:
      return state;
  }
};

const initialState: DropDownState = {
  currentManufacturer: { name: "None", id: NONE_MANUFACTURER_ID },
  fleetList: {},
  variantList: {},
  aircraftPool: {} as ManufacturerList,
  currentFleet: null,
  currentVariant: null,
};

const useMFVDropdown = ({ initialManufacturer, initialFleet, initialVariant }: MFVDropDownProps): MFVDropDown => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { currentManufacturer, currentFleet, currentVariant, fleetList, variantList, aircraftPool } = state;

  const [, manufacturerList]: [LessonData, ManufacturerList] = useLessonData({
    formattedAircrafts: true,
  });
  const [selectedManufacturer, setSelectedManufacturer] = useState<string>(initialManufacturer ?? "None");
  const [selectedFleet, setSelectedFleet] = useState<string>(initialFleet ?? "None");

  const [selectedVariant, setSelectedVariant] = useState<string>(initialVariant ?? "None");

  const setMostSpecificAssociation = (input: MFVDropDownProps) => {
    const { initialManufacturer, initialFleet, initialVariant } = input;
    if (initialManufacturer) setSelectedManufacturer(initialManufacturer);
    if (initialFleet) setSelectedFleet(initialFleet);
    if (initialVariant) setSelectedVariant(initialVariant);
    dispatch({
      type: DropDownActionType.SET_MOST_SPECIFIC_ASSOC,
      payload: {
        initialManufacturer: initialManufacturer ?? NONE,
        initialFleet: initialFleet ?? NONE,
        initialVariant: initialVariant ?? NONE,
      },
    });
  };

  useEffect(() => {
    dispatch({
      type: DropDownActionType.SET_INITIAL_AIRCRAFT_POOL,
      payload: manufacturerList,
    });
  }, []);

  useEffect(() => {
    if (!selectedManufacturer) return;
    dispatch({
      type: DropDownActionType.SET_MANUFACTURER,
      payload: selectedManufacturer,
    });
    dispatch({
      type: DropDownActionType.SET_FLEET,
      payload: NONE,
    });
  }, [selectedManufacturer, initialManufacturer]);

  useEffect(() => {
    if (!selectedFleet) return;
    dispatch({
      type: DropDownActionType.SET_FLEET,
      payload: selectedFleet,
    });
  }, [selectedFleet, initialFleet]);

  useEffect(() => {
    if (!selectedVariant) return;
    dispatch({
      type: DropDownActionType.SET_VARIANT,
      payload: selectedVariant,
    });
  }, [selectedVariant, initialVariant]);

  useEffect(() => {
    setSelectedManufacturer(initialManufacturer);
  }, [initialManufacturer]);

  useEffect(() => {
    if (!initialFleet) return;
    setSelectedFleet(initialFleet);
  }, [initialFleet]);

  useEffect(() => {
    if (!initialVariant) return;
    setSelectedVariant(initialVariant);
  }, [initialVariant]);

  return {
    currentManufacturer,
    currentFleet,
    currentVariant,
    fleetList: Object.keys(fleetList),
    variantList: Object.keys(variantList),
    manufacturerList: Object.keys(aircraftPool),
    setSelectedManufacturer,
    setSelectedFleet,
    setSelectedVariant,
    setMostSpecificAssociation,
  };
};

export default useMFVDropdown;
