import React, { useMemo } from "react";
import { IAnnotation } from "../../../../../components/Annotation/models/IAnnotation";
import { useInteracitvityHotspotState } from "../../../../../contexts/InteractivityHotspotProvider";
import { AllPartialBut, HotspotObject } from "../../../../../types";
import { useObjectsState } from "../../../../../contexts/ObjectsProvider";

interface Props {
  attributes?: AllPartialBut<IAnnotation, "isDisplayed">;
  id?: string;
  width?: number;
  height?: number;
  objectId?: string;
}
export default function AnnotationHighlight({ objectId }: Props) {
  const { selectedObjects } = useObjectsState();
  const hotspot = selectedObjects[0]?.type === "hotspot" ? (selectedObjects[0] as HotspotObject) : null;
  if (!hotspot) return null;
  const clicks = [hotspot, ...hotspot.nextClicks];
  const isHighlighted = clicks.find((click) => {
    return click.targets.find((target) => {
      return target.objectId === objectId;
    });
  });

  return isHighlighted ? <div className="glow-border"></div> : null;
}
