import React, { useState, useContext, useEffect } from "react";
import { IUploaderContext, UploaderContext } from "./uploaderContext";
import { IAssetManagementContext, AssetContext } from "../../routes/builderContexts";
import upArrow from "../../assets/icons/ld-lesson-data-up-arrow.png";
import downArrow from "../../assets/icons/ld-lesson-data-down-arrow.png";

const UploaderOptionFields = (): JSX.Element => {
  const assetContext: IAssetManagementContext = useContext<IAssetManagementContext>(AssetContext);
  const uploaderContext: IUploaderContext = useContext<IUploaderContext>(UploaderContext);

  const { aircrafts } = assetContext.lessonDataSettings.aircrafts;
  const { ataNumbers } = assetContext.lessonDataSettings.ata;
  const manufacturers: any = [];
  const [ataData, setATAData] = useState({
    showing: false,
    ataNumbers: assetContext.lessonMetaData.ataNumberDtos.map((el: any, i: number) => el.ataNumberId),
  });
  const [aircraftData, setAircraftData] = useState({
    manufacturer: "", //manufacturerName
    fleet: "", //aircraftFamilyName
    variant: "", //aircraftName
    configuration: "", //configurationName
    showing: false,
  });

  useEffect(() => {
    if (aircraftData) {
      uploaderContext.setAtaIds(ataData.ataNumbers);
      uploaderContext.setAircraftIds({
        aircraftId: findAirplane("aircraftId"),
        aircraftFamilyId: findAirplane("aircraftFamilyId"),
        manufacturerId: findAirplane("manufacturerId"),
      });
    }
  }, [aircraftData, ataData]);

  useEffect(() => {
    setAircraftToLesson(true);
  }, []);

  function findAirplane(flag: string) {
    if (aircraftData) {
      if (aircraftData.manufacturer === "")
        if (flag === "name") return "Generic";
        else return null;
      else if (aircraftData.manufacturer && aircraftData.fleet === "") {
        if (flag === "manufacturerId") {
          const manufacturerId = aircrafts.find((el: any, index: number) => {
            return el.manufacturerName === aircraftData.manufacturer;
          })["manufacturerId"];
          return manufacturerId;
        } else if (flag === "name") {
          const manufacturerId = aircrafts.find((el: any, index: number) => {
            return el.manufacturerName === aircraftData.manufacturer;
          })["manufacturerName"];
          return manufacturerId;
        } else return null;
      } else if (aircraftData.manufacturer && aircraftData.fleet && aircraftData.variant === "") {
        if (flag === "aircraftFamilyId") {
          const aircraftFamilyId = aircrafts.find((el: any, index: number) => {
            return el.manufacturerName === aircraftData.manufacturer && el.aircraftFamilyName === aircraftData.fleet;
          })["aircraftFamilyId"];
          return aircraftFamilyId;
        } else if (flag === "name") {
          const manufacturerId = aircrafts.find((el: any, index: number) => {
            return el.manufacturerName === aircraftData.manufacturer;
          })["manufacturerName"];

          const aircraftFamilyId = aircrafts.find((el: any, index: number) => {
            return el.manufacturerName === aircraftData.manufacturer && el.aircraftFamilyName === aircraftData.fleet;
          })["aircraftFamilyName"];
          return `${manufacturerId}  ${aircraftFamilyId}`;
        } else return null;
      } else {
        if (flag === "aircraftId") {
          const aircraftId = aircrafts.filter((el: any, index: number) => {
            return (
              el.manufacturerName === aircraftData.manufacturer &&
              el.aircraftFamilyName === aircraftData.fleet &&
              el.aircraftName === aircraftData.variant
            );
          })[0]["aircraftId"];
          return aircraftId;
        } else if (flag === "name") {
          const manufacturerId = aircrafts.find((el: any, index: number) => {
            return el.manufacturerName === aircraftData.manufacturer;
          })["manufacturerName"];

          const aircraftFamilyId = aircrafts.find((el: any, index: number) => {
            return el.manufacturerName === aircraftData.manufacturer && el.aircraftFamilyName === aircraftData.fleet;
          })["aircraftFamilyName"];
          const aircraftId = aircrafts.filter((el: any, index: number) => {
            return (
              el.manufacturerName === aircraftData.manufacturer &&
              el.aircraftFamilyName === aircraftData.fleet &&
              el.aircraftName === aircraftData.variant
            );
          })[0]["aircraftName"];
          return `${manufacturerId} ${aircraftFamilyId} ${aircraftId}`;
        } else return null;
      }
    } else {
      return null;
    }
  }
  function setAircraftToLesson(toggle: boolean) {
    if (toggle && aircrafts) {
      const checkM = (el: any) => el.manufacturerId === assetContext.lessonMetaData.manufacturerId;
      const checkA = (el: any) => el.aircraftId === assetContext.lessonMetaData.aircraftId;
      const checkF = (el: any) => el.aircraftFamilyId === assetContext.lessonMetaData.aircraftFamilyId;

      const x = aircrafts
        .filter((el: any, i: number) => checkM(el) || checkM(el) || checkM(el))
        .map((el: any, i: number) => ({
          manufacturer: checkM(el) ? el.manufacturerName : "", //manufacturerName
          fleet: checkF(el) ? el.aircraftFamilyName : "", //aircraftFamilyName
          variant: checkA(el) ? el.aircraftName : "", //aircraftName
          configuration: el.configuration, //configurationName
          showing: true,
        }))[0];

      if (x) {
        setAircraftData(x);
      } else {
        setAircraftData({
          manufacturer: "", //manufacturerName
          fleet: "", //aircraftFamilyName
          variant: "", //aircraftName
          configuration: "", //configurationName
          showing: false,
        });
      }
    } else {
      setAircraftData({
        ...aircraftData,
        manufacturer: "", //manufacturerName
        fleet: "", //aircraftFamilyName
        variant: "", //aircraftName
        configuration: "", //configurationName
      });
    }
  }

  function makeManufacturersList() {
    for (let i = 0; i < aircrafts.length; i++) {
      const manufacturer = aircrafts[i].manufacturerName;
      if (!manufacturers.includes(manufacturer)) {
        manufacturers.push(manufacturer);
      }
    }
    return;
  }

  makeManufacturersList();
  function populateManufacturerFleets(manufacturer: any) {
    const fleets: any[] = [];
    const o = {};
    for (let i = 0; i < aircrafts.length; i++) {
      const fleet = aircrafts[i].aircraftFamilyName;
      if (aircrafts[i].manufacturerName === manufacturer && !fleets.includes(fleet) && fleet !== null) {
        Object.defineProperty(o, fleet, {
          value: populateFleetVariants(fleet),
          enumerable: true,
        });
      }
      fleets.push(fleet);
    }
    return o;
  }

  function populateFleetVariants(fleet: any) {
    const variants = [];
    for (let i = 0; i < aircrafts.length; i++) {
      if (aircrafts[i].aircraftFamilyName === fleet) variants.push(aircrafts[i].aircraftName);
    }
    return { variants };
  }
  const manufacturerList = createAirplaneObjectsWithFields();
  function createAirplaneObjectsWithFields() {
    const manufacturerList: any = {};
    for (let i = 0; i < manufacturers.length; i++) {
      manufacturerList[manufacturers[i]] = {
        fleets: populateManufacturerFleets(manufacturers[i]),
        //configurations: populateManufacturerConfigurations(manufacturers[i]),
      };
    }
    return manufacturerList;
  }
  function handleATA(e: any) {
    const { value } = e.target;
    if (e.target.checked) {
      setATAData({
        ...ataData,
        ataNumbers: [...ataData.ataNumbers, parseInt(value)],
      });
    } else {
      const x = [...ataData.ataNumbers];
      x.splice(x.indexOf(parseInt(value)), 1);
      setATAData({ ...ataData, ataNumbers: [...x] });
    }
  }

  function returnAtaNumbers(): number[] {
    return ataData.ataNumbers.map((el: any, index: number) => {
      return ataNumbers.find((el2: any, index: number) => {
        return el2.ataNumberId === el;
      }).ataIdentifier;
    });
  }

  return (
    <React.Fragment>
      <div className="col-12" style={{ marginBottom: "10px" }}>
        <div className="ata-settings-dropdown">
          <div
            className="ata-toggler"
            onClick={(e) => {
              setAircraftData({
                ...aircraftData,
                showing: !aircraftData.showing,
              });
            }}
          >
            <p>Aircraft</p>
            {findAirplane("name")}
            <div>
              <p>{aircraftData.showing ? "Close" : "Open"}</p>
              <p>{aircraftData.showing ? <img src={upArrow}></img> : <img src={downArrow}></img>}</p>
            </div>
          </div>
          {aircraftData.showing && (
            <div className="aircraft-settings-container">
              <div className="setAircraftToLesson" onClick={() => setAircraftToLesson(true)}>
                <p>Reset</p>
              </div>
              <div
                className="manufacturer-list ldr-row"
                style={{
                  background: "#7F8893",
                  color: "white",
                  backgroundColor: "white",
                }}
              >
                <p style={{ color: "black" }}>Manufacturer: </p>
                <select
                  value={aircraftData.manufacturer ? aircraftData.manufacturer : "None"}
                  onChange={(e) => {
                    e.persist();
                    if (e.target.value === "None") {
                      setAircraftData({
                        ...aircraftData,
                        variant: "",
                        fleet: "",
                        manufacturer: "",
                      });
                    } else {
                      setAircraftData((aircraftData: any) => {
                        return {
                          ...aircraftData,
                          variant:
                            manufacturerList[e.target.value].fleets[
                              Object.keys(manufacturerList[e.target.value].fleets)[0]
                            ].variants[0],
                          fleet: Object.keys(manufacturerList[e.target.value].fleets)[0],
                          manufacturer: e.target.value,
                        };
                      });
                    }
                  }}
                >
                  {manufacturers.map((el: any, index: any) => (
                    <option value={el} key={index}>
                      {" "}
                      {el}
                    </option>
                  ))}
                  <option>None</option>
                </select>
              </div>
              <div className="fleet-list ldr-row">
                <p>Fleet: </p>
                <select
                  value={aircraftData && aircraftData.fleet ? aircraftData.fleet : "None"}
                  onChange={(e) => {
                    if (e.target.value === "None") {
                      setAircraftData({
                        ...aircraftData,
                        fleet: "",
                        variant: "",
                      });
                    } else {
                      setAircraftData({
                        ...aircraftData,
                        fleet: e.target.value,
                        variant: manufacturerList[aircraftData.manufacturer].fleets[e.target.value].variants[0],
                      });
                    }
                  }}
                >
                  {aircraftData.manufacturer ? (
                    Object.keys(manufacturerList[aircraftData.manufacturer].fleets).map((el, index, array) => (
                      <option value={el} key={index}>
                        {el}
                      </option>
                    ))
                  ) : (
                    <option>None</option>
                  )}
                  {aircraftData.manufacturer && <option>None</option>}
                </select>
              </div>
              <div className="variant-list ldr-row">
                <p>Variant: </p>
                <select
                  value={aircraftData && aircraftData.variant ? aircraftData.variant : "None"}
                  onChange={(e) => {
                    if (e.target.value === "None") {
                      setAircraftData({
                        ...aircraftData,
                        variant: "",
                      });
                    } else {
                      setAircraftData({
                        ...aircraftData,
                        variant: e.target.value,
                      });
                    }
                  }}
                >
                  {aircraftData && aircraftData.fleet ? (
                    manufacturerList[aircraftData.manufacturer].fleets[aircraftData.fleet].variants.map(
                      (el: any, index: number, array: any[]) => (
                        <option value={el} key={index}>
                          {array.length === 0 ? "n/a" : el}
                        </option>
                      ),
                    )
                  ) : (
                    <option>None</option>
                  )}
                  {aircraftData && aircraftData.fleet && <option>None</option>}
                </select>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="col-12" style={{ marginBottom: "10px" }}>
        <div className="ata-settings-dropdown">
          <div
            className="ata-toggler"
            onClick={(e) => {
              setATAData({ ...ataData, showing: !ataData.showing });
            }}
          >
            <p>ATA Number:</p>
            <div style={{ width: "80%", overflow: "hidden" }}>
              {ataData.ataNumbers.length === 0 ? (
                <span>Generic</span>
              ) : (
                returnAtaNumbers().map((el: any, index: number) => {
                  return <span key={`${el}${index}`}>{el},</span>;
                })
              )}
            </div>

            <div>
              <p>{ataData.showing ? "Close" : "Open"}</p>
              <p>{ataData.showing ? <img src={upArrow}></img> : <img src={downArrow}></img>}</p>
            </div>
          </div>
          {ataData.showing && (
            <div className="ata-checkbox-container">
              {ataNumbers.map((el: any, index: number) => (
                <label title={el.description} key={index}>
                  <input
                    value={el.ataNumberId}
                    type="checkbox"
                    checked={ataData.ataNumbers.includes(el.ataNumberId)}
                    onChange={(e) => {
                      handleATA(e);
                    }}
                  />
                  {`  ${el.ataIdentifier} - ${el.description}`}
                </label>
              ))}
            </div>
          )}
        </div>{" "}
      </div>
      {/* <div className="col-12" style={{ marginBottom: "10px" }}>
        <input className="form-control" type="text" placeholder="Enter a Aircraft Placement IDs as a comma separated list (optional)" id="aircraftPlacementIds" value={uploaderContext.aircraftPlacementIds} onChange={uploaderContext.handleChange} />
      </div>
      <div className="col-12" style={{ marginBottom: "10px" }}>
        <input className="form-control" type="text" placeholder="Enter a custom Version name (optional)" id="versionName" value={uploaderContext.versionName} onChange={uploaderContext.handleChange} />
      </div>
      <div className="col-12" style={{ marginBottom: "10px" }}>
        <input className="form-control" type="number" placeholder="Enter the ID of client who this asset is being tailored for (optional)" id="tailoredForLmsKey" value={uploaderContext.tailoredForLmsKey} min={0} onChange={uploaderContext.handleChange} />
      </div> */}
    </React.Fragment>
  );
};

export default UploaderOptionFields;
