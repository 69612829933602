import { Quill } from "react-quill-2";

const Parchment = Quill.import("parchment");
const ListItemBlot = Quill.import("formats/list");

// This could be refactored into generic attributors
const SUPPORTED_ATTRIBUTORS = [
  // style: color
  new Parchment.StyleAttributor("custom-color-attributor", "color"),
  // style: font-size
  new Parchment.StyleAttributor("custom-size-attributor", "font-size"),
  // style: font-family
  new Parchment.StyleAttributor("custom-family-attributor", "font-family"),
];

const SUPPORTED_ATTRIBUTORS_MAP = Object.fromEntries(
  SUPPORTED_ATTRIBUTORS.map(({ attrName, keyName }) => [keyName, attrName]),
);

export class CustomListItemBlot extends ListItemBlot {
  // Code based on quill issue #409
  optimize(context: string) {
    super.optimize(context);

    if (this.children.length >= 1) {
      const child = this.children.head;
      const attributes = child?.attributes?.attributes;

      if (attributes) {
        for (const key in attributes) {
          const element = attributes[key];
          const name = element.keyName;
          const value = element.value(child.domNode);

          if (SUPPORTED_ATTRIBUTORS_MAP[name]) {
            super.format(SUPPORTED_ATTRIBUTORS_MAP[name], value);
          }
        }
      }
    }
  }
}

export const register = (QuillInstance = Quill) => {
  Object.values(SUPPORTED_ATTRIBUTORS).forEach((attributor) => QuillInstance.register(attributor, true));
  QuillInstance.register(CustomListItemBlot, true);
};
