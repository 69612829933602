import React from "react";
import { SVGAnnotationProps } from "../index";
import { HighlightAnnotation } from "../HighlightAnnotation";

export function SVGGlideSlopePointer({
  onMouseDown,
  objectId,
  y,
  x,
  width,
  height,
  isDisplayed,
  zIndex,
  borderColor,
  strokeWidth,
  backgroundColor,
  opacity,
  rotate,
}: // transform,
SVGAnnotationProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 4.43 25.2"
      key={objectId}
      id={`symbol-${objectId}`}
      data-objectid={objectId}
      tabIndex={0}
      className="symbol target"
      name="symbol"
      onMouseDown={(e: React.MouseEvent<SVGSVGElement>) => {
        onMouseDown(e, objectId);
      }}
      // onKeyDown={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyPress(e, kp)}
      // onKeyUp={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyUp(e, kp)}
      preserveAspectRatio="xMidYMid meet"
      style={{
        // top: `${y}px`,
        // left: `${x}px`,
        width: `${width}px`,
        height: `${height}px`,
        zIndex: zIndex,
        transform: `translate(${x}px, ${y}px) rotate(${rotate ?? 0}deg)`,
        opacity: isDisplayed ? opacity : 0.5,
        filter: isDisplayed ? "none" : "grayScale(100%)",
        // `rotate(${attributes?.transform?.rotate}deg) scaleX(${attributes?.transform?.scaleX}) scaleY(${attributes?.transform?.scaleY}) matrix3d(${attributes?.transform?.matrix3d})`
      }}
    >
      <HighlightAnnotation objectId={objectId} />
      <path
        // className="glideslope_1"
        stroke={`${borderColor || "white"}`}
        fill={`${backgroundColor || "transparent"}`}
        strokeWidth={`${strokeWidth / 10 || 0.1}`}
        d="M 3.26,1.36 A 1.01,1.01 0 0 1 2.25,2.37 1.01,1.01 0 0 1 1.24,1.36 1.01,1.01 0 0 1 2.25,0.35000002 1.01,1.01 0 0 1 3.26,1.36"
      />
      <path
        // className="glideslope_1"
        stroke={`${borderColor || "white"}`}
        fill={`${backgroundColor || "transparent"}`}
        strokeWidth={`${strokeWidth / 10 || 0.1}`}
        d="m 3.26,7.1599998 a 1.01,1.01 0 0 1 -1.01,1.01 1.01,1.01 0 0 1 -1.01,-1.01 1.01,1.01 0 0 1 1.01,-1.0099999 1.01,1.01 0 0 1 1.01,1.0099999"
      />
      <path
        // className="glideslope_1"
        stroke={`${borderColor || "white"}`}
        fill={`${backgroundColor || "transparent"}`}
        strokeWidth={`${strokeWidth / 10 || 0.1}`}
        d="m 3.26,18.110001 a 1.01,1.01 0 0 1 -1.01,1.01 1.01,1.01 0 0 1 -1.01,-1.01 1.01,1.01 0 0 1 1.01,-1.01 1.01,1.01 0 0 1 1.01,1.01"
      />
      <path
        // className="glideslope_1"
        stroke={`${borderColor || "white"}`}
        fill={`${backgroundColor || "transparent"}`}
        strokeWidth={`${strokeWidth / 10 || 0.1}`}
        d="M 3.23,23.84 A 1.01,1.01 0 0 1 2.22,24.85 1.01,1.01 0 0 1 1.21,23.84 1.01,1.01 0 0 1 2.22,22.83 1.01,1.01 0 0 1 3.23,23.84"
      />
      <path
        // className="glideslope_1"
        stroke={`${borderColor || "white"}`}
        fill={`${backgroundColor || "transparent"}`}
        strokeWidth={`${strokeWidth / 10 || 0.1}`}
        d="M 0,12.691936 H 4.4299999"
      />
      {/* <path
        // className="glideslope_1"
        stroke={`${borderColor || 'white'}`}
        fill={`${backgroundColor || 'transparent'}`}
        strokeWidth={`${strokeWidth || .1}`}
        d="M 2.25,9.81 3.78,12.68 2.25,15.46 0.69,12.64 Z"
      /> */}
    </svg>
  );
}
