import _ from "lodash";
import React, { createRef, Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import ILessonMetaRequest from "../../../models/ILessonMetaRequest";
import AspectRatioImage from "../../../services/Utils/ImageAspectRatioContainer/AspectRatioImage";
import MoveableClass from "../../../components/Moveable/MoveableClass";
import { ElementTypes, IBasicPageImageAttributes } from "../../BasicPage_Player/components/IBasePage";
import { IPropertyBox } from "../../../components/ObjectPropertyBox/models/IObjectPropertyBox";
import { BoundType } from "../../../components/react-moveable";
import { IPageContext, PageContext } from "../../../routes/builderContexts";
import { ITitlePageLogoAsset, ITitlePageManifest } from "../BaseTitlePage";

type EditableProps = {
  airplaneoverlay: string;
  background: string;
  isCPaTUser: boolean;
  lessonMetaData: ILessonMetaRequest;
  lessonName: string;
  logo: string;
  handleImageClick: (clickedImage: number) => void;
  permeateLessonName: (data: string) => void;
};

const BaseTitleEditable = (props: EditableProps) => {
  const {
    airplaneoverlay,
    background,
    isCPaTUser,
    lessonMetaData,
    lessonName,
    logo,
    handleImageClick,
    permeateLessonName,
  } = props;
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const pageManifest: ITitlePageManifest = _.cloneDeep(pageContext.pageManifest);

  let canEdit = false;
  const [isLoading]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(true);
  const playerRef: React.RefObject<HTMLDivElement> = createRef<HTMLDivElement>();
  const [target, setTarget]: [any, Dispatch<SetStateAction<any>>] = useState<any>();
  const [pageDims, setPageDims]: [DOMRect | null | undefined, Dispatch<SetStateAction<DOMRect | null | undefined>>] =
    useState<DOMRect | null | undefined>();
  const [directions]: [string[], Dispatch<SetStateAction<string[]>>] = useState(["e", "s", "se"]);
  const [bounds, setBounds]: [BoundType, Dispatch<SetStateAction<BoundType>>] = useState<BoundType>({
    top: 0,
    bottom: 900,
    left: 0,
    right: 1500,
  });
  const moveRef: React.RefObject<any> = useRef(null);
  const setPropertyBox = setPropBox();
  const [isRotating, setIsRotating]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false);
  const [isResizing, setIsResizing]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(true);
  const [isDragging, setIsDragging]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false);
  const [nodeToUpdate, setNodeToUpdate]: [any, Dispatch<SetStateAction<any>>] = useState<any>();
  const previousPathRef = useRef<string>();
  const imgTarget: HTMLDivElement = document.getElementById(`title-logo-container-editable`) as HTMLDivElement;
  const image: HTMLImageElement = document.getElementById(`titlepagelogo`) as HTMLImageElement;
  const initialLogo: { height: number; width: number } = {
    height: 18.582,
    width: 34.1956,
  }; // height/width percentages for the default CPaT logo
  let initialAttributesEmpty = false;

  useEffect(() => {
    previousPathRef.current = pageManifest.BrandedLogo.imagePath;
  });

  const previousPath = previousPathRef.current;

  //if(isCPaTUser || _.isNull(lessonMetaData.parentLessonId) || lessonMetaData.parentLessonId === 1) {
  if (lessonMetaData.logoUpdate) {
    canEdit = true;
  }

  const endActions = (target: HTMLElement | SVGElement, tempNode: any, e: any) => {
    e.stopPropagation();
    target.style.zIndex = "1";
    updateAttributes(tempNode);
  };

  const selectTarget = (e: React.MouseEvent<HTMLDivElement | SVGSVGElement | HTMLVideoElement>, type: ElementTypes) => {
    e.stopPropagation();
    e.currentTarget.style.zIndex = "500";
    if (!pageManifest.BrandedLogo.attributes)
      populateAttributes(e.currentTarget.clientHeight, e.currentTarget.clientWidth);
    setNodeToUpdate(pageManifest.BrandedLogo.attributes);
    setTarget(e.currentTarget);
  };

  useEffect(() => {
    const tempBounds = {
      top: 0,
      bottom: playerRef?.current?.getBoundingClientRect().height,
      left: 0,
      right: playerRef?.current?.getBoundingClientRect().width,
    };

    setBounds(tempBounds);
    setPageDims(playerRef?.current?.getBoundingClientRect());
  }, [isLoading]);

  useEffect(() => {
    const tempBounds = {
      top: 0,
      bottom: playerRef?.current?.getBoundingClientRect().height,
      left: 0,
      right: playerRef?.current?.getBoundingClientRect().width,
    };

    setBounds(tempBounds);
    setPageDims(playerRef?.current?.getBoundingClientRect());
  }, [playerRef.current, window.innerWidth, window.innerWidth]);

  function transformImageHandler(height: number, width: number) {
    const { top, left } = image.getBoundingClientRect();
    const ratio = image.naturalHeight / image.naturalWidth;
    const minWidth = 150;

    const tempAttributes = {
      ...pageManifest.BrandedLogo.attributes,
      height: parseFloat(((height / (pageDims?.height as number)) * 100).toFixed(2)),
      width: parseFloat(((width / (pageDims?.width as number)) * 100).toFixed(2)),
      top: parseFloat((((top - (pageDims?.top as number)) / (pageDims?.height as number)) * 100).toFixed(2)),
      left: parseFloat((((left - (pageDims?.left as number)) / (pageDims?.width as number)) * 100).toFixed(2)),
    };

    if (tempAttributes.height < 1) {
      tempAttributes.height = 1;
    }
    if (tempAttributes.width < 1) {
      tempAttributes.width = 1;
    }

    if (tempAttributes.height > 95 || tempAttributes.width > 95) {
      const tempWidth = (pageDims?.width as number) * 0.9;
      const tempHeight = tempWidth * ratio;
      tempAttributes.width = parseFloat(((((pageDims?.width as number) * 0.9) / image.naturalWidth) * 100).toFixed(2));
      tempAttributes.height = parseFloat(((tempHeight / ((pageDims?.height as number) * 0.9)) * 100).toFixed(2));
    }

    if (initialAttributesEmpty) {
      imgTarget.style.height = `${initialLogo.height}%`;
      imgTarget.style.width = `${initialLogo.width}%`;
      tempAttributes.width = initialLogo.width;
      tempAttributes.height = initialLogo.height;
    } else {
      imgTarget.style.height = `${tempAttributes.height}%`;
      imgTarget.style.width = `${tempAttributes.width}%`;
    }

    imgTarget.style.minWidth = `${minWidth}px`;

    moveRef.current?.moveable?.updateRect();
    pageManifest.BrandedLogo.attributes = tempAttributes;
    pageContext.updatePageManifest(pageManifest);
    setNodeToUpdate(tempAttributes);
    return tempAttributes;
  }

  function populateAttributes(height: number, width: number) {
    // do this only if the default cpat logo is in place
    // this is to ensure new versions of old lessons with the cpat logo have the same logo size as a new lesson
    if (
      pageManifest.BrandedLogo.imagePath === "cpat/generic/images/21/21/73626D7FE180EA11A94C000D3A70C5E8/cpatlogo.png"
    ) {
      pageManifest.BrandedLogo.attributes = {
        height: initialLogo.height,
        width: initialLogo.width,
        natural: {
          height: 170,
          width: 560,
        },
      };
    } else {
      pageManifest.BrandedLogo.attributes = {
        height: parseFloat(((height / (pageDims?.height as number)) * 100).toFixed(2)),
        width: parseFloat(((width / (pageDims?.width as number)) * 100).toFixed(2)),
        natural: {
          height: height,
          width: width,
        },
      };
    }

    pageContext.updatePageManifest(pageManifest);
  }

  function loadImage(e: React.SyntheticEvent<HTMLImageElement>) {
    const naturals: { height: number; width: number } = {
      height: e.currentTarget.naturalHeight,
      width: e.currentTarget.naturalWidth,
    };

    if (!pageManifest.BrandedLogo.attributes) {
      initialAttributesEmpty = true;
      populateAttributes(naturals.height, naturals.width);
      transformImageHandler(naturals.height, naturals.width);
    }

    if (!_.isEqual(pageManifest.BrandedLogo.attributes?.natural, naturals) && target) {
      const ratio = naturals.height / naturals.width;

      const attributes = transformImageHandler(imgTarget.clientHeight, imgTarget.clientHeight / ratio);
      pageManifest.BrandedLogo.attributes = attributes;
      pageManifest.BrandedLogo.attributes.natural = naturals;
      setNodeToUpdate(attributes);
      pageContext.updatePageManifest(pageManifest);
    }
  }

  function updateAttributes(attributes: IBasicPageImageAttributes) {
    (pageManifest.BrandedLogo as ITitlePageLogoAsset).attributes = _.cloneDeep(attributes as IBasicPageImageAttributes);
    setNodeToUpdate(attributes);
    pageContext.updatePageManifest(pageManifest);
    moveRef.current.moveable.updateRect();
  }

  function setPropBox() {
    const props: IPropertyBox = {
      left: 0,
      top: 0,
      width: 1,
      height: 1,
      name: "test",
      rotation: 0,
      zIndex: -1,
    };
    return props;
  }

  function setFontSize() {
    // let fontSize = '30px'; // default font size from css class
    // /// 70 characters is the range at which the title begins to span outside the boundry of designer
    // /// itterates font size down as more characters are added to the title
    // /// stops reducing font side when title reaches 200 characters / 14px font minimum
    // if (lessonName.length > 70) {
    //   let fontSizeNum = 30 - ((lessonName.length - 70) / 5);
    //   if(fontSizeNum < 14){
    //     fontSizeNum = 14
    //   }
    //   fontSize = fontSizeNum + "px"
    // }
    // return fontSize;

    let fontSizeReduce: number | null = null;
    let titleFontSize = "";

    //calculates font size to keep title on 1 line dependnt on the length of the title
    // 70 characters starts the calculation based on text area limits
    // calculation differs as character count increases
    if (lessonName.length <= 70) {
      titleFontSize = "1.75vw"; // 7
      // } else if (lessonName.length > 135) {
      //   fontSizeReduce = 0.97 - (lessonName.length - 135) * 0.005;
      //   titleFontSize = fontSizeReduce + 'vw';
    } else if (lessonName.length > 180) {
      fontSizeReduce = 0.66 - (lessonName.length - 180) * 0.0025;
      if (fontSizeReduce < 0.61) {
        fontSizeReduce = 0.61;
      }
      titleFontSize = fontSizeReduce + "vw";
    } else if (lessonName.length > 170) {
      fontSizeReduce = 0.71 - (lessonName.length - 170) * 0.005;
      titleFontSize = fontSizeReduce + "vw";
    } else if (lessonName.length > 150) {
      fontSizeReduce = 0.81 - (lessonName.length - 150) * 0.005;
      titleFontSize = fontSizeReduce + "vw";
    } else if (lessonName.length > 120) {
      fontSizeReduce = 0.99 - (lessonName.length - 120) * 0.006;
      titleFontSize = fontSizeReduce + "vw";
    } else if (lessonName.length > 90) {
      fontSizeReduce = 1.35 - (lessonName.length - 90) * 0.012;
      titleFontSize = fontSizeReduce + "vw";
    } else if (lessonName.length > 70) {
      fontSizeReduce = 1.75 - (lessonName.length - 70) * 0.02;
      titleFontSize = fontSizeReduce + "vw";
    }
    return titleFontSize;
  }

  return (
    <React.Fragment>
      {/* //handleImageClick("logo") */}
      {/* <div className="contentBox">
        <div className="free-form" id="base-page-wrapper">
          <div ref={playerRef} id="pageplayerarea" className="page-player-area" > */}
      <div ref={playerRef} id="titleplayer">
        <div
          id={/*canEdit ? */ `title-logo-container-editable` /*: `title-logo-container`*/}
          onDoubleClick={() => {
            if (canEdit) handleImageClick(0);
          }}
          onClick={(e: React.MouseEvent<HTMLDivElement>) => {
            if (canEdit && e.currentTarget !== target) selectTarget(e, "pageImage" as ElementTypes);
          }}
          style={{
            height: `${pageManifest.BrandedLogo?.attributes?.height || initialLogo.height}%`,
            width: `${pageManifest.BrandedLogo?.attributes?.width || initialLogo.width}%`,
          }}
        >
          <div className="img-aspect-container">
            <div className={canEdit ? "free-form-element aspect-container-inside" : "aspect-container-inside"}>
              <div className={canEdit ? "free-form-inner aspect-centering" : "aspect-centering"}>
                <img
                  id="titlepagelogo"
                  className={canEdit ? "editable" : "non-editable"}
                  src={logo}
                  alt=""
                  onLoad={(e: React.SyntheticEvent<HTMLImageElement>) => loadImage(e)}
                />
              </div>
            </div>
          </div>
        </div>
        {pageDims ? (
          <MoveableClass
            bounds={bounds}
            directions={directions}
            canResize={true}
            isMovable={false}
            moveRef={moveRef}
            nodeToUpdate={nodeToUpdate}
            pageDims={pageDims}
            propertyBox={setPropertyBox}
            shouldMaintainRatio={true}
            target={target}
            rotatable={false}
            endActions={endActions}
            setIsDragging={setIsDragging}
            setIsResizing={setIsResizing}
            setIsRotating={setIsRotating}
          />
        ) : (
          <></>
        )}
        <p id="titlepagetext" style={{ fontSize: setFontSize() }}>
          <input
            type="text"
            value={lessonName}
            className="title-text"
            onChange={(e: any) => permeateLessonName(e.target.value)}
          />
        </p>
        <p id="titlepageversiontext">Lesson Version: {props.lessonMetaData.lessonVersionId}</p>
        <img id="titlebackground" src={background} alt="" />

        <div className="title-main-image-absolute-container">
          {/* <div className="title-main-image-relative-container">
                  <img id="titlepagebrandedairplane" className="editable" src={airplaneoverlay} alt='' onClick={() => handleImageClick(2)} />
                </div> */}
          <AspectRatioImage
            hoverSelect
            src={airplaneoverlay}
            onClick={() => handleImageClick(2)}
            className="editable"
          />
        </div>
      </div>
      {/* </div>
        </div>
      </div> */}
    </React.Fragment>
  );
};

export default BaseTitleEditable;
