import React, { useState, useEffect, useContext } from "react";
import { IPageContext, PageContext } from "../../../../routes/builderContexts";
import { IFmsPageManifest } from "../../Interfaces/IFmsPageManifest";
import { IFmsContext, FmsContext } from "../../Interfaces/fmsContext";
import { criteriaPageProps, successCriteriaObject, successPageState } from "../../Interfaces/successCriteriaInterfaces";
import SuccessCriteriaList from "./successCriteriaList";
import EditSuccessCriteria from "./editSuccessCriteria";
import lodash from "lodash";
import "../../fmsDesigner.css";
import "./successCriteriaPage.css";
// import CriteriaInstructions from "./CriteriaInstructions";
import blankFunctionKeys from "../../assets/blankFunctionKeyes.json";
import KeyDataEditor from "../keyDataEditor/keyDataEditor";
import KeyDataManager from "../keyDataEditor/KeyDataManager";

//import SingleSuccessCriteria from "./editSuccessCriteria";

const SuccessCriteriaPage: React.FC<criteriaPageProps> = (props: criteriaPageProps) => {
  const pagesContext: IPageContext = useContext<IPageContext>(PageContext); //data and context for page manifest
  const pageManifest: IFmsPageManifest = pagesContext.pageManifest as IFmsPageManifest;
  const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);
  // const lessonPageActionsContext: ILessonPageActionsContext = useContext<ILessonPageActionsContext>(LessonPageActionsContext);

  const [successPageState, setCriteria] = useState<successPageState>({
    singleSuccessCriteria: pageManifest.successCriteria[0]
      ? pageManifest.successCriteria[0]
      : {
          type: "",
          highlights: [],
          instructions: "",
          helperText: "",
          successFeedback: "",
          successMatch: [],
        }, //for success criteria so it initializes by default to the first position
    pageFlag: false, //toggles between the list of criteria and the edit criteria components
    indexValue: 0, //used for manipulating the criteria array based on index value
  });
  const [highlightIndex, setHightlightIndex] = useState<number | undefined>(undefined); //highlights criteria when reordered avoids conflicts with selected index
  const [displayHighlight, setDisplayHighlight] = useState<string[]>([]); //text fields are displayed as all caps so the values need to be kept seperately
  const [displaySuccessMatch, setDisplaySuccessMatch] = useState<string[]>([]);
  const [updateListArray, setUpdateListArray] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined);
  const [statusMessage, setStatusMessage] = useState<string>(" ");
  const [listStatusMessage, setListStatusMessage] = useState<string>(" ");
  const [invalidCriteriaIndex, setInvalidCriteriaIndex] = useState<number[]>([]); //will store index of any criteria sequences that would break in the player
  const [editingKey, setEditingKey] = useState<boolean>(false);
  const [keyToEdit, setKeyToEdit] = useState<string | undefined>("");
  const [hideKeyEditorBackButton, setHideKeyEditorBackButton] = useState<boolean>(false);

  /******************************************************************************************Criteria Edit ******************************************************************* */
  const selectCriteria = (id: number): void => {
    //when the user clicks edit in the list this will set the criteria to be modified
    const newCriteriaState = lodash.cloneDeep(successPageState);
    newCriteriaState.singleSuccessCriteria = pageManifest.successCriteria[id];
    newCriteriaState.pageFlag = !newCriteriaState.pageFlag;
    newCriteriaState.indexValue = id;
    if (newCriteriaState.singleSuccessCriteria.helperText === "INVALID ENTRY") {
      newCriteriaState.singleSuccessCriteria.helperText = "Incorrect";
    }
    setCriteria(newCriteriaState);
    setDisplayHighlight(pageManifest.successCriteria[id].highlights.join(",").toUpperCase().split(","));
    setDisplaySuccessMatch(pageManifest.successCriteria[id].successMatch);
    fmsContext.setCriteriaIndex(id);
  };

  const newCriteria = (): void => {
    //when the user clicks the add button in the list this will create a new criteria to be added to json
    const index = pageManifest.successCriteria.length;
    //"INVALID ENTRY"
    //defaultValue={props.criteriaIndex > 0 && pageManifest.successCriteria[props.criteriaIndex-1].type === "textMatch" ? "sequential" : props.criteria.type.length ? props.criteria.type: "textMatch"}>
    const blankCriteria = {
      type:
        pageManifest.successCriteria[index - 1] && pageManifest.successCriteria[index - 1].type === "textMatch"
          ? "sequential"
          : "textMatch",
      criteriaName: "",
      successMatch:
        pageManifest.successCriteria[index - 1] && pageManifest.successCriteria[index - 1].type === "textMatch"
          ? []
          : [""],
      highlights: [],
      instructions: "",
      helperText: "",
      successFeedback: "CORRECT",
    };

    pageManifest.successCriteria.push(lodash.cloneDeep(blankCriteria));

    const newCriteriaState = lodash.cloneDeep(successPageState);
    newCriteriaState.singleSuccessCriteria = pageManifest.successCriteria[index];
    newCriteriaState.pageFlag = !newCriteriaState.pageFlag;
    newCriteriaState.indexValue = index;
    setCriteria(newCriteriaState);
    setDisplayHighlight([]);
    setDisplaySuccessMatch([]);
    fmsContext.setCriteriaIndex(index); //index of new criteria is set to display highlighting for that criteria
    pagesContext.updatePageManifest(pageManifest);
  };

  const editCriteriaStringValue = (name: string, value: any) => {
    //used for simple string inputs and rte that don't need any manipulation

    setCriteria({
      ...successPageState,
      singleSuccessCriteria: {
        ...successPageState.singleSuccessCriteria,
        [name]: value,
      },
    });
    // if(name === "instructions"){
    //     pageManifest.successCriteria[successPageState.indexValue].instructions = value;
    //     pagesContext.updatePageManifest(pageManifest)
    // }
  };

  const deleteCriteria = (criteriaList: successCriteriaObject[]) => {
    //takes updating state from edit component to remove criteria from json
    pageManifest.successCriteria = criteriaList;
    pagesContext.updatePageManifest(pageManifest);
    validateCriteriaList();
  };

  const toggleScratchPad = (flag: string) => {
    //sets the type from sequential or text_match
    const newCriteria = successPageState.singleSuccessCriteria;
    newCriteria.type = flag;
    setCriteria({
      ...successPageState,
      singleSuccessCriteria: newCriteria,
    });
  };
  const compareKeysToInput = (text: string) => {
    //need to validate keys entered into success criteria to make sure they are stored properly based on the cdu keys
    let cduKey = "";
    fmsContext.keyList.forEach((key) => {
      if (key.toLowerCase() === text.toLowerCase()) {
        cduKey = key;
      }
    });
    return cduKey;
    //compare key to lower
  };
  /******************************************************************************************highlighting ******************************************************************* */
  const textInputHightlight = (text: string) => {
    //when the user inputs text into the highlight field
    const highlightsArray: string[] = [];
    let displayHighlightArray: string[] = [];
    displayHighlightArray = text.toUpperCase().split(/[, ]/);
    displayHighlightArray.map((highlight, index) => {
      const keyInfo = compareKeysToInput(highlight);
      highlightsArray.push(keyInfo);
    });
    setCriteria({
      ...successPageState,
      singleSuccessCriteria: {
        ...successPageState.singleSuccessCriteria,
        highlights: highlightsArray,
      },
    });
    setDisplayHighlight(displayHighlightArray);
  };

  const pushHighlight = (text: string) => {
    // for highlight group buttons in the highlight form
    const highlightsArray: string[] = lodash.cloneDeep(successPageState.singleSuccessCriteria.highlights);
    const displayHighlightArray: string[] = lodash.cloneDeep(displayHighlight);
    if (highlightsArray[0] === "") {
      const caps = lodash.clone(text);
      displayHighlightArray[0] = caps.toUpperCase();
      highlightsArray[0] = lodash.clone(text);
    } else {
      const caps = lodash.clone(text);
      displayHighlightArray.push(caps.toUpperCase());
      highlightsArray.push(text);
    }
    setCriteria({
      ...successPageState,
      singleSuccessCriteria: {
        ...successPageState.singleSuccessCriteria,
        highlights: highlightsArray,
      },
    });
    setDisplayHighlight(displayHighlightArray);
  };
  const removeHighlight = (text: string) => {
    //double clicking a highlight button will remove the highlight
    const highlightsArray: string[] = successPageState.singleSuccessCriteria.highlights.filter((highlight) => {
      return highlight !== text;
    });
    const displayArray = displayHighlight.filter((highlight) => {
      return highlight.toLowerCase() !== text.toLowerCase();
    });
    setCriteria({
      ...successPageState,
      singleSuccessCriteria: {
        ...successPageState.singleSuccessCriteria,
        highlights: highlightsArray,
      },
    });
    setDisplayHighlight(displayArray);
  };
  const selectedKeyHighlight = (keyName: string) => {
    let stateClone = successPageState.singleSuccessCriteria.highlights;
    const highlights = lodash.clone(successPageState.singleSuccessCriteria.highlights); //when a key is selected the key's data needs to be put into highlights and
    const displayHighlightArray: string[] = lodash.clone(displayHighlight);
    //put into display highlights in all caps
    if (highlights[0] !== "" || highlights[0] || highlights.length > 0) {
      //no real logic is needed for the first input
      if (!highlights.includes(keyName) && !displayHighlightArray.includes(keyName.toUpperCase())) {
        //prevents duplicate entries
        if (pageManifest.cdu.includes("f100")) {
          if (keyName === ".") {
            highlights.push("decimal");
            displayHighlightArray.push("decimal".toUpperCase());
          } else {
            highlights.push(keyName);
            displayHighlightArray.push(keyName.toUpperCase());
          }
        } else {
          if ((keyName === "+" || keyName === "-") && !highlights.includes("plus/minus")) {
            displayHighlightArray.push("plus/minus".toUpperCase());
            highlights.push("plus/minus");
          } else if (keyName !== "+" && keyName !== "-" && !highlights.includes(keyName)) {
            displayHighlightArray.push(keyName.toUpperCase());
            highlights.push(keyName);
          }
        }
      }
    } else {
      highlights[0] = keyName;
      displayHighlightArray[0] = keyName.toUpperCase();
    }
    stateClone = highlights;
    setCriteria({
      ...successPageState,
      singleSuccessCriteria: {
        ...successPageState.singleSuccessCriteria,
        highlights: stateClone,
      },
    });
    setDisplayHighlight(displayHighlightArray);
  };

  /******************************************************************************************Success Match ************************************************************************************* */
  const addOptionalScratchpadCriteriaEntry = (event: React.MouseEvent) => {
    /***********************************************************************************************
     **  Adds an additional string to a "textMatch" or "Scratchpad" criteria which will cause an   **
     ** additional text field to populate in the "SuccessMatch" component which the user can edit  **
     ** NOTE: users can only add up to a maximum of 5 entries                                      **
     ************************************************************************************************/
    event.preventDefault();
    const successArray: string[] = lodash.cloneDeep(successPageState.singleSuccessCriteria.successMatch);
    const displaySuccessArray: string[] = lodash.cloneDeep(displaySuccessMatch);
    if (successArray.length < 5) {
      const newEntry = "";
      successArray.push(newEntry);
      displaySuccessArray.push(newEntry);
      setCriteria({
        ...successPageState,
        singleSuccessCriteria: {
          ...successPageState.singleSuccessCriteria,
          successMatch: successArray,
        },
      });
      setDisplaySuccessMatch(displaySuccessArray);
    }
  };
  const removeScratchpadEntry = (indexValue: number) => {
    if (indexValue !== 0 || (indexValue === 0 && successPageState.singleSuccessCriteria.successMatch.length > 1)) {
      const successArray: string[] = lodash
        .cloneDeep(successPageState.singleSuccessCriteria.successMatch)
        .filter((entry, index) => {
          return index !== indexValue;
        });
      const displaySuccessArray: string[] = lodash.cloneDeep(displaySuccessMatch).filter((entry, index) => {
        return index !== indexValue;
      });
      setCriteria({
        ...successPageState,
        singleSuccessCriteria: {
          ...successPageState.singleSuccessCriteria,
          successMatch: successArray,
        },
      });
      setDisplaySuccessMatch(displaySuccessArray);
    }
  };

  const matchSuccess = (name: string, text: string, index: number) => {
    //adds to the match_success array similarly
    //this is going to need and index value for scratchpad entries
    const successClone = successPageState;
    const successArray: string[] = successPageState.singleSuccessCriteria.successMatch;
    let displaySuccessArray: string[] = lodash.cloneDeep(displaySuccessMatch);
    if (name === "toggleKey") {
      if (successClone.singleSuccessCriteria.toggleKey === undefined) {
        successClone.singleSuccessCriteria.toggleKey = true;
      } else {
        successClone.singleSuccessCriteria.toggleKey =
          successClone.singleSuccessCriteria.toggleKey === true ? false : true;
      }
      setCriteria(successClone);
      return;
    }
    if (successPageState.singleSuccessCriteria.type === "textMatch") {
      //let keyInfo = compareKeysToInput(text);
      successArray[index] = text.toUpperCase();
      displaySuccessArray[index] = text.toUpperCase();
    } else {
      displaySuccessArray = lodash.clone(text).toUpperCase().split(/[, ]/);
      displaySuccessArray.map((txt) => {
        const keyInfo = compareKeysToInput(txt);
        if (!successArray.includes(keyInfo) && successArray.length !== displaySuccessArray.length) {
          successArray.push(keyInfo);
          updateLightCriteria(keyInfo);
        }
      });
    }
    successClone.singleSuccessCriteria.successMatch = successArray;
    setCriteria(successClone);
    setDisplaySuccessMatch(displaySuccessArray);
    // pageManifest.successCriteria[successPageState.indexValue].successMatch = successArray;
    // setDisplaySuccessMatch(displaySuccessArray);
    // pagesContext.updatePageManifest(pageManifest)

    //cleanupBlankMatchSuccess();
  };
  const cleanupBlankMatchSuccess = (fieldName: string, fieldArray: string[]) => {
    //needs to remove blank spaces on blur

    if (fieldArray.length > 0) {
      const cleanedSuccess = fieldArray.filter((fieldElement) => {
        const keyInfo = compareKeysToInput(fieldElement);
        return fieldElement.replace(/<[^>]*>?/gm, "").length > 0 && fieldElement === keyInfo;
      });
      if (cleanedSuccess.length !== fieldArray.length) {
        //set a warning display here
        setStatusMessage("Please enter a valid key for select a key on the cdu");
      } else {
        setStatusMessage(" ");
      }
      setCriteria({
        ...successPageState,
        singleSuccessCriteria: {
          ...successPageState.singleSuccessCriteria,
          [fieldName]: cleanedSuccess,
        },
      });
      if (fieldName === "successMatch") {
        setDisplaySuccessMatch(cleanedSuccess);
      } else {
        setDisplayHighlight(cleanedSuccess);
      }
    }
  };
  const validateCriteriaList = () => {
    //needs to look at criteria list for invalid entries and flag them
    const invalidIndecies: number[] = [];
    const lastIndex = pageManifest.successCriteria.length - 1;
    pageManifest.successCriteria.map((criteria, index) => {
      if (index > 0 && criteria.type === "textMatch" && pageManifest.successCriteria[index - 1].type === "textMatch") {
        invalidIndecies.push(index);
      }
    });
    //need to account for invalid criteria at end on array
    if (pageManifest.successCriteria[lastIndex] && pageManifest.successCriteria[lastIndex].type === "textMatch") {
      setListStatusMessage(
        "Warning: Setting the last Task with a scratchpad entry will stop the student from completing the page!",
      );
      invalidIndecies.push(lastIndex);
    } else if (invalidIndecies.length > 0) {
      setListStatusMessage("Warning: Consecutive scratchpad entries will stop the student from completing the page!");
    } else {
      setListStatusMessage(" ");
    }
    setInvalidCriteriaIndex(invalidIndecies);
  };

  const clearSuccess = () => {
    //clears the match_success value
    if (successPageState.singleSuccessCriteria.type === "textMatch") {
      setCriteria({
        ...successPageState,
        singleSuccessCriteria: {
          ...successPageState.singleSuccessCriteria,
          successMatch: [""],
          highlights: [],
        },
      });
      disassociateLightCriteria(fmsContext.criteriaIndex);
      setDisplayHighlight([]);
      setDisplaySuccessMatch([""]);
    } else {
      const match = lodash.cloneDeep(successPageState.singleSuccessCriteria.successMatch);
      const pop = match.pop();
      setCriteria({
        ...successPageState,
        singleSuccessCriteria: {
          ...successPageState.singleSuccessCriteria,
          successMatch: match,
          highlights: [],
        },
      });
      disassociateLightCriteria(fmsContext.criteriaIndex);
      setDisplayHighlight([]);
      setDisplaySuccessMatch([]);
    }
  };
  const clearKeyMatch = (indexValue: number) => {
    const newSuccessMatch = lodash
      .cloneDeep(successPageState.singleSuccessCriteria.successMatch)
      .filter((match, index) => {
        return index !== indexValue;
      });
    const newHighlights = lodash
      .cloneDeep(successPageState.singleSuccessCriteria.highlights)
      .filter((highlight, index) => {
        return index !== indexValue;
      });
    const displayHighlightArray = lodash.cloneDeep(displayHighlight).filter((display: string, index) => {
      return index !== indexValue;
    });
    const newDisplaySuccess = lodash.cloneDeep(
      displaySuccessMatch.filter((display, index) => {
        return index !== indexValue;
      }),
    );
    setCriteria({
      ...successPageState,
      singleSuccessCriteria: {
        ...successPageState.singleSuccessCriteria,
        successMatch: newSuccessMatch,
        highlights: newHighlights,
      },
    });
    setDisplayHighlight(displayHighlightArray);
    setDisplaySuccessMatch(newDisplaySuccess);
    disassociateLightCriteria(fmsContext.criteriaIndex);
    //setDisplaySuccessMatch([]);
  };

  /******************************************************************************************Criteria Order******************************************************************* */
  const reOrderCriteria = (direction: string, index: number) => {
    let newIndex = 0;
    let criteriaList = lodash.cloneDeep(pageManifest.successCriteria);
    const criterion = lodash.clone(criteriaList[index]);
    if (direction === "up") {
      //down needs to progress the array while up needs to move backwards in the array

      if (index === 0) {
        //if the up arrow is clicked at index 0 the first and last places need to swap, same if the down arrow is clicked at the last index
        newIndex = criteriaList.length - 1;
        criteriaList = swapFirstLastCriteria(true, criteriaList);
      } else {
        newIndex = index - 1;
        criteriaList.splice(index, 1);
        criteriaList.splice(newIndex, 0, criterion);
        setSelectedIndex(newIndex);
        setHightlightIndex(newIndex);
      }
    } else {
      if (index === criteriaList.length - 1) {
        newIndex = 0;
        criteriaList = swapFirstLastCriteria(false, criteriaList);
      } else {
        newIndex = index + 1;
        criteriaList.splice(index, 1);
        criteriaList.splice(newIndex, 0, criterion);
        setSelectedIndex(newIndex);
        setHightlightIndex(newIndex);
      }
    }
    updateLightCriteriaIndex(index, newIndex);
    pageManifest.successCriteria = criteriaList;
    pagesContext.updatePageManifest(pageManifest);
    validateCriteriaList();
    setUpdateListArray(true);
  };

  const swapFirstLastCriteria = (first: boolean, criteriaList: successCriteriaObject[]) => {
    if (first) {
      criteriaList = swapFirstCriteria(criteriaList);
    } else {
      criteriaList = swapLastCriteria(criteriaList);
    }
    return criteriaList;
  };
  const swapFirstCriteria = (criteriaList: successCriteriaObject[]) => {
    const criterion = criteriaList[0];
    criteriaList.shift();
    criteriaList.push(criterion);
    setSelectedIndex(criteriaList.length - 1);
    setHightlightIndex(criteriaList.length - 1);
    return criteriaList;
  };
  const swapLastCriteria = (criteriaList: successCriteriaObject[]) => {
    const criterion = criteriaList[criteriaList.length - 1];
    criteriaList.pop();
    criteriaList.unshift(criterion);
    setSelectedIndex(0);
    setHightlightIndex(0);
    return criteriaList;
  };
  const confirmBack = () => {
    //if there is no text entered for either helperText or successFeedback it needs to default to the placeholder values when the user exits the edit component
    const helperFlag: boolean =
      successPageState.singleSuccessCriteria.helperText.replace(/<[^>]*>?/gm, "").length === 0 ? true : false; //the rte returns empty values as <p><br></P>
    const successFlag: boolean =
      successPageState.singleSuccessCriteria.successFeedback.replace(/<[^>]*>?/gm, "").length === 0 ? true : false;

    if (helperFlag || successFlag) {
      const setHelper = successPageState.singleSuccessCriteria.helperText;
      const setSuccessHelper = successFlag ? "CORRECT" : successPageState.singleSuccessCriteria.successFeedback;
      const newCriteria = lodash.cloneDeep(successPageState);
      newCriteria.pageFlag = false;
      newCriteria.singleSuccessCriteria.helperText = setHelper;
      newCriteria.singleSuccessCriteria.successFeedback = setSuccessHelper;
      // if(newCriteria.singleSuccessCriteria.instructions === "" ){
      //     newCriteria.singleSuccessCriteria.instructions = displayInstructions();
      // }

      setCriteria(newCriteria);
    } else {
      setCriteria({
        ...successPageState,
        pageFlag: false,
      });
    }
  };
  /*******************************************************************************************LIGHT ASSOCIATION ***************************************************************************/
  //if cdu lights have a key associated to them but no criteria then they need to be updated once a criteria selects that key
  const updateLightCriteria = (keyName: string) => {
    if (pageManifest.lights) {
      const lightKeys = Object.keys(pageManifest.lights);
      lightKeys.map((light, index) => {
        if (
          pageManifest.lights[light].cduKey &&
          pageManifest.lights[light].criteriaIndex === null &&
          pageManifest.lights[light].cduKey.includes(keyName)
        ) {
          pageManifest.lights[light]["criteriaIndex"] = fmsContext.criteriaIndex;
        }
      });
      pagesContext.updatePageManifest(pageManifest);
    }
  };
  //if the user removes a criteria then the light needs to set the criteria index for the light to null
  const disassociateLightCriteria = (indexValue: number) => {
    if (pageManifest.lights) {
      const lightKeys = Object.keys(pageManifest.lights);
      lightKeys.map((light) => {
        if (pageManifest.lights[light].criteriaIndex === indexValue) {
          pageManifest.lights[light].criteriaIndex = null;
        }
      });
      pagesContext.updatePageManifest(pageManifest);
    }
  };
  //when success criteria is ordered then the key criteria Index should update too THIS IS GOING TO ALSO NEED TO UPDATE OLD VALUES NOT JUST THE CRITERIA BEING ORDERED
  const updateLightCriteriaIndex = (oldIndex: number, updatedIndex: number) => {
    const lightKeys = Object.keys(pageManifest.lights);
    lightKeys.map((light) => {
      if (pageManifest.lights[light].criteriaIndex === oldIndex) {
        pageManifest.lights[light].criteriaIndex = updatedIndex;
      } else if (pageManifest.lights[light].criteriaIndex === updatedIndex) {
        pageManifest.lights[light].criteriaIndex = oldIndex;
      }
    });
  };

  /**********************************************************************************************Key Editor ***************************************************************************/
  const getKeyInfo = () => {
    const keyInfo =
      fmsContext.currentCDU.rectangularObjects.find((buttonItem) => buttonItem.id === keyToEdit) ||
      fmsContext.currentCDU.circleObjects.find((element: any) => element.cduKeyName === keyToEdit) ||
      fmsContext.currentCDU.overLays?.find((element: any) => element.id === keyToEdit && keyToEdit === "knob");
    return keyInfo;
  };
  const getKeyList = () => {
    const keyList = fmsContext.currentCDU.foreignObjects.map((foreignObject) => {
      return foreignObject.id;
    });
    if (fmsContext.currentCDU.lightObjects) {
      fmsContext.currentCDU.lightObjects.map((light) => {
        keyList.push(light.id);
      });
    }

    Object.keys(pageManifest.pages).map((page) => {
      keyList.push(page);
    });
    return keyList;
  };
  const getKeyName = () => {
    const keyInfo = getKeyInfo();
    //const keyList = getKeyList();
    let keyName = keyInfo.id || keyInfo.cduKeyName; //need the || because circular buttons don't currently have an id just the cduname
    if (keyName === "decimal") {
      keyName = ".";
    }
    if (keyName === "plus/minus") {
      keyName = "+";
    }
    return keyName;
  };
  const openCduKeyData = () => {
    const keyInfo = getKeyInfo();
    const keyList = getKeyList();
    const multiActionKeys = ["cursorControlPad", "enterKey", "cursorSelectRight", "cursorSelectLeft", "execKey"];

    if (keyInfo) {
      const keyName = getKeyName();
      if (multiActionKeys.includes(keyName)) {
        return (
          <KeyDataManager
            keyName={keyName}
            destinationList={keyList}
            fromTask={true}
            nestedMenu={setHideKeyEditorBackButton}
            disabledKeyRemoval={disabledKeyRemoval}
            taskIndex={fmsContext.criteriaIndex}
          />
        );
      } else {
        return (
          <KeyDataEditor
            keyName={keyName}
            index={0}
            destinationList={keyList}
            fromTask={true}
            disabledKeyRemoval={disabledKeyRemoval}
            taskIndex={fmsContext.criteriaIndex}
          />
        );
      }
    } else {
      return <div></div>;
    }
  };
  const toggleKeyMenu = () => {
    setEditingKey(false);
  };
  const openKeyMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    setKeyToEdit(event.currentTarget.id);
    setEditingKey(true);
  };
  /******************************************************************************************KEY EDITOR CONTROLS******************************************************************* */
  const disabledKeyRemoval = () => {
    const keyName = getKeyName();
    if (keyToEdit && keyName) {
      const match = lodash.cloneDeep(successPageState.singleSuccessCriteria.successMatch).filter((key) => {
        return key !== keyName;
      });
      setCriteria({
        ...successPageState,
        singleSuccessCriteria: {
          ...successPageState.singleSuccessCriteria,
          successMatch: match,
        },
      });
      disassociateLightCriteria(fmsContext.criteriaIndex);
      setDisplaySuccessMatch([]);
      setEditingKey(false);
    }
  };
  const changeToKeyPress = (newSequence: string) => {
    const successClone = successPageState;
    const newCriteria = lodash.cloneDeep(successPageState.singleSuccessCriteria);
    newCriteria.type = "sequential";
    newCriteria.successMatch = [newSequence];
    successClone.singleSuccessCriteria = newCriteria;
    setCriteria(successClone);
    autoEnableKey();
  };
  const autoEnableKey = () => {
    const noScratchpadBlankFunctionKeys = lodash.cloneDeep(blankFunctionKeys);
    noScratchpadBlankFunctionKeys[0].parameter.changeCDUCell.destination[0] = "Title";
    noScratchpadBlankFunctionKeys[0].conditions[0].placeToCheck = "Title";
    // pageManifest.cdu.includes('145')
    if (!lodash.has(pageManifest.actions, props.selectedKey)) {
      // prevents designer crash on click of ovfy button

      pageManifest.actions[props.selectedKey] = pageManifest.cdu.includes("145")
        ? lodash.cloneDeep(JSON.parse(JSON.stringify([...noScratchpadBlankFunctionKeys])))
        : lodash.cloneDeep(JSON.parse(JSON.stringify([...blankFunctionKeys])));
      pagesContext.updatePageManifest(pageManifest);
    } else if (pageManifest.actions[props.selectedKey] && pageManifest.actions[props.selectedKey].length === 0) {
      pageManifest.actions[props.selectedKey] = pageManifest.cdu.includes("145")
        ? lodash.cloneDeep(JSON.parse(JSON.stringify([...noScratchpadBlankFunctionKeys])))
        : lodash.cloneDeep(JSON.parse(JSON.stringify([...blankFunctionKeys])));
      pagesContext.updatePageManifest(pageManifest);
    } else {
    }
  };

  /**********************************************************************************************STATE CHANGE ***************************************************************************/
  useEffect(() => {
    //fmsContext.setHighlights(fmsContext.checkHighlightingKeyWords());
    if (successPageState.pageFlag) {
      fmsContext.setHighlights(fmsContext.checkHighlightingKeyWords());
      props.toggleKeyEditor(true);
    } else {
      fmsContext.setHighlights([]);
      props.toggleKeyEditor(false);
    }
  }, [successPageState.pageFlag]);

  useEffect(() => {
    if (pageManifest.successCriteria[0]) {
      const newCriteriaState = lodash.cloneDeep(successPageState);
      newCriteriaState.singleSuccessCriteria = pageManifest.successCriteria[0];
      setCriteria(newCriteriaState);
      setDisplayHighlight(pageManifest.successCriteria[0].highlights);
      setDisplaySuccessMatch(pageManifest.successCriteria[0].successMatch);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setHightlightIndex(undefined);
    }, 300);
  }, [highlightIndex]);

  useEffect(() => {
    if (statusMessage !== " ") {
      setTimeout(() => {
        setStatusMessage(" ");
      }, 3000);
    }
  }, [statusMessage]);

  useEffect(() => {
    //MANIFEST UPDATES HERE!!*****************************************************************************************************************************
    //here is some odd behavior I've noticed, but do not have time to properly address that could be useful to know about for debugging:
    //this useEffect will only happen if a value WITHIN the successPageState.singleSuccessCriteria object is updated
    //calling setCriteria and updating the ENTINRE successPageState appears to not trigger this useEffect even though the singleSuccessCriteria object might be updated with it
    /**
     * updating the criteria object seems to not always update immediately (this will not update the highlighting)
     * EX:
     * setCriteria({
     *  ...successPageState,
     *      singleSuccessCriteria: updatedCloneOfCriteria
     * })
     * this will not (usually) cause the useEffect to run and not immedetly update the pageManifest
     * it isn't always necessary for every state update to trigger this since often times multiple state updates happen at once.
     * the changeToKeyPress function does not trigger the update but the successMatch update does after the criteria type changes, avoiding an extra re-render
     */
    if (successPageState.indexValue === 0) {
      if (successPageState.singleSuccessCriteria.type !== "") {
        pageManifest.successCriteria[successPageState.indexValue] = successPageState.singleSuccessCriteria;
        pagesContext.updatePageManifest(pageManifest);
      }
    } else {
      pageManifest.successCriteria[successPageState.indexValue] = lodash.cloneDeep(
        successPageState.singleSuccessCriteria,
      );
      pagesContext.updatePageManifest(pageManifest);
    }
    fmsContext.setHighlights(fmsContext.checkHighlightingKeyWords());
  }, [successPageState.singleSuccessCriteria]);

  useEffect(() => {
    setSelectedIndex(-1);

    fmsContext.setHighlights([]);
    return () => {
      fmsContext.setHighlights([]);
    };
  }, []);

  const displayInstructions = () => {
    //need to directly update PM

    const index: number = successPageState.indexValue;
    const criteria = lodash.cloneDeep(successPageState.singleSuccessCriteria);

    //sequential or "Key Press" tasks have to modify the selected key to convert the name into a more readable format EX: enterKey becomes Enter so the check needs to be different
    const modMatchSuccess =
      criteria.type === "sequential"
        ? criteria.successMatch
            .map((key: string) => {
              return key.charAt(0).toUpperCase() + key.slice(1).replace("Key", "");
            })
            .join(",")
            .replace(/([A-Z])/gm, " $1")
            .replace(/Key/gm, "")
            .trim()
        : criteria.successMatch.join(",");

    if (criteria.type === "textMatch") {
      criteria.instructions = `Enter ${criteria.successMatch.join(",")} into the scratchpad`;
    } else {
      criteria.instructions = `Select the ${modMatchSuccess} key`;
    }

    return criteria.instructions;
  };

  /****************************************************************************************JSX**************************************************************************************************** */
  return (
    <div className="successCriteriaPage padding-all-sides-10">
      {/**PUT EDITOR IN HERE 
             * if (keyName === "cursorControlPad" || keyName === "enterKey" || keyName === "cursorSelectRight" || keyName === "cursorSelectLeft") {
                setEditorComponent(<KeyDataManager keyName={keyName} destinationList={keyList} />)
                } else {
                setEditorComponent(<KeyDataEditor keyName={keyName} index={0} destinationList={keyList} />);
            */}
      {editingKey ? (
        <>
          {openCduKeyData()}
          <button
            onClick={toggleKeyMenu}
            hidden={hideKeyEditorBackButton}
            className="btn floating-confirm-button default-button-colors"
          >
            Return to Task
          </button>
        </>
      ) : (
        <>
          {/* One Of These Things Is Not Like The Other */}
          {successPageState.pageFlag ? (
            <EditSuccessCriteria
              criteria={successPageState.singleSuccessCriteria}
              criteriaIndex={successPageState.indexValue}
              displayHighlight={displayHighlight}
              displaySuccessMatch={displaySuccessMatch}
              statusMessage={statusMessage}
              selectedKey={props.selectedKey}
              editCriteriaStringValue={editCriteriaStringValue}
              toggleScratchPad={toggleScratchPad}
              pushHighlight={pushHighlight}
              removeHighlight={removeHighlight}
              selectedKeyHighlight={selectedKeyHighlight}
              textInputHightlight={textInputHightlight}
              matchSuccess={matchSuccess}
              clearSuccess={clearSuccess}
              cleanupBlankMatchSuccess={cleanupBlankMatchSuccess}
              updateLightCriteria={updateLightCriteria}
              displayInstructions={displayInstructions}
              openKeyMenu={openKeyMenu}
              changeToKeyPress={changeToKeyPress}
              clearKeyMatch={clearKeyMatch}
              autoEnableKey={autoEnableKey}
              addOptionalScratchpadCriteriaEntry={addOptionalScratchpadCriteriaEntry}
              removeScratchpadEntry={removeScratchpadEntry}
            />
          ) : (
            <SuccessCriteriaList
              listStatusMessage={listStatusMessage}
              invalidCriteriaIndex={invalidCriteriaIndex}
              criteria={pageManifest.successCriteria}
              setCriteria={selectCriteria}
              newCriteria={newCriteria}
              deleteCriteria={deleteCriteria}
              reOrderCriteria={reOrderCriteria}
              updateListArray={updateListArray}
              setUpdateListArray={setUpdateListArray}
              highlightIndex={highlightIndex}
              selectedIndex={selectedIndex}
              disassociateLightCriteria={disassociateLightCriteria}
            />
          )}
          {successPageState.pageFlag ? (
            <button onClick={confirmBack} className="btn floating-confirm-button fms-back-btn">
              Back
            </button>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};
export default SuccessCriteriaPage;
