import React from "react";
import { ReactComponent as AddIcon } from "../../../../assets/icons/add-tag-icon.svg"; // '../../assets/icons/add-tag-icon.svg';
import { CreateTag } from "../state";

interface AssetTagsProps {
  readonly: boolean;
  existingTags: number;
  addTag: (tag: CreateTag) => void;
  children?: React.ReactNode;
  disabled: boolean;
}
export const AssetTags = ({ readonly, existingTags, addTag, children, disabled }: AssetTagsProps) => {
  return (
    <>
      <div className="assetManager-asset-tags-header">Tags</div>
      <div className={"assetManager-asset-tags"}>
        {existingTags > 0 ? <>{children}</> : <div className="assetManager-no-tags-label"> No added tags</div>}
        {!readonly && (
          <div className="assetManager-add-tag-btn">
            <AddIcon
              className={`assetManager-add-tag-btn ${disabled ? "disabled" : ""}`}
              onClick={() => {
                if (disabled) return;
                addTag({
                  tagKey: "key",
                  tagValue: "",
                });
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};
