import React from "react";

const AnnotationCircle = () => {
  return (
    <React.Fragment>
      <span className="icon-circle-icon"></span>
    </React.Fragment>
  );
};

export default AnnotationCircle;
