import React from "react";
import { SVGAnnotationProps } from "../index";
import { HighlightAnnotation } from "../HighlightAnnotation";

export function SVGAircraftSymbol({
  onMouseDown,
  objectId,
  y,
  x,
  width,
  height,
  isDisplayed,
  zIndex,
  borderColor,
  strokeWidth,
  backgroundColor,
  opacity,
  rotate,
}: // transform,
SVGAnnotationProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14.94 12.66"
      key={objectId}
      data-objectid={objectId}
      id={`symbol-${objectId}`}
      tabIndex={0}
      className="symbol target"
      name="symbol"
      onMouseDown={(e: React.MouseEvent<SVGSVGElement>) => {
        onMouseDown(e, objectId);
      }}
      // onKeyDown={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyPress(e, kp)}
      // onKeyUp={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyUp(e, kp)}
      preserveAspectRatio="xMidYMid meet"
      style={{
        // top: `${y}px`,
        // left: `${x}px`,
        width: `${width}px`,
        height: `${height}px`,
        zIndex: zIndex,
        transform: `translate(${x}px, ${y}px) rotate(${rotate ?? 0}deg)`,
        opacity: isDisplayed ? opacity : 0.5,
        filter: isDisplayed ? "none" : "grayScale(100%)",
        // `rotate(${attributes?.transform?.rotate}deg) scaleX(${attributes?.transform?.scaleX}) scaleY(${attributes?.transform?.scaleY}) matrix3d(${attributes?.transform?.matrix3d})`
      }}
    >
      <HighlightAnnotation objectId={objectId} />
      <path
        stroke={`${borderColor || "white"}`}
        fill={`${backgroundColor || "white"}`}
        strokeWidth={`${strokeWidth / 10 || 0.1}`}
        // className="aircraft_symbol"
        d="M 6.3,0 H 5.42 V 5.78 H 0 v 0.87 h 5.42 v 5.26 H 3.24 v 0.75 H 5.42 6.3 V 11.91 6.65 5.78 0 Z"
      />
      <path
        stroke={`${borderColor || "white"}`}
        fill={`${backgroundColor || "white"}`}
        strokeWidth={`${strokeWidth / 10 || 0.1}`}
        // className="aircraft_symbol"
        d="M 9.52,0 H 8.65 v 5.78 0.87 5.26 0.75 H 9.52 11.7 V 11.91 H 9.52 V 6.65 h 5.42 V 5.78 H 9.52 V 0 Z"
      />
    </svg>
  );
}
