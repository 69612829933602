import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import "./CPaT3d_Player.css";
import PageScene from "./components/scene";
import PagePresentation from "./components/hud";
import { PageContext, LessonPageActionsContext } from "../../routes/builderContexts";
import {
  setisIGS,
  setAllStepImages,
  setIGSOs,
  resetStepImages,
  setStepImage,
  setStepNumber,
  setTaskDesc,
  setIsTerminal,
  setStepType,
  setQuestion2,
  setChoices,
  setCondition,
  displayStepSystem,
  displayChooseStep,
  displayAddStep,
  displayStepResponsePanel,
  setdisplayStyle,
  setModel,
  setRole,
  setCustom,
  storedCurrentStep,
  setCurtain,
} from "../../store/actions/actions";
import HSEditor from "./components/hotSpotEditor/presentation";
import PageTypeCrawler, { IDandPath, crawlAudioArray } from "../../services/Utils/PageTypeCrawler/PageTypeCrawler";

const CPaT3dPlayer = (props) => {
  const pageContext = useContext(PageContext);
  const lessonPageActionsContext = useContext(LessonPageActionsContext);
  // const assetContext = useContext(AssetContext);
  const updatedManifest = pageContext.pageManifest;
  const [deepEditorMode, setDeepEditorMode] = useState("normal");
  const [condition, setCondition] = useState("atGate");
  const [buttonText, setButtonText] = useState("Hot Spot Editor");
  const [conditionText, setConditionText] = useState("At the Gate");
  const [cpHotSpot, setCpHotSpot] = useState(updatedManifest.CustomeHotSpot);

  useEffect(() => {
    setCpHotSpot(updatedManifest.CustomeHotSpot);
    // setCustom(updatedManifest.CustomeHotSpot)
  }, [updatedManifest.CustomeHotSpot]);

  useEffect(() => {
    props.displayStepSystem(false);
    lessonPageActionsContext.setLessonPageActions([]);
    props.displayChooseStep(true);
    props.displayAddStep(false);
    props.displayStepResponsePanel("none");
    props.setdisplayStyle("none");
    validateIGAObject();
    // validateHotSpot();
    if (updatedManifest.condition) {
      let conditionSetting = updatedManifest.condition;
      setCondition(conditionSetting);
      setConditionText(conditionSetting);
      props.setCondition(conditionSetting);
    } else {
      setCondition("InAir");
      setConditionText("InAir");
      props.setCondition("InAir");
    }
    clearSlate();
    props.setCurtain(true);
    props.setisIGS(false);
    props.setCustom(updatedManifest.CustomeHotSpot);
  }, []);
  useEffect(() => {
    const assetVersionIds = PageTypeCrawler.getAllAssetIds("ThreeDModel", updatedManifest);
    if (assetVersionIds) {
      pageContext.setPageAssetIds(assetVersionIds);
    }
  }, [pageContext.currentlyDisplayedPageIndex]);

  const clearSlate = () => {
    if (updatedManifest.IGSObject) {
      props.setIGSOs(updatedManifest.IGSObject);
      props.setStepNumber(0);
      props.setAllStepImages(updatedManifest.IGSObject[0].images);
      props.setTaskDesc(updatedManifest.IGSObject[0].taskDescription);
      props.setIsTerminal(false);
      props.setStepType(updatedManifest.IGSObject[0].stepType);
      props.setQuestion2(updatedManifest.IGSObject[0].question);
      props.setChoices(updatedManifest.IGSObject[0].choices);
    } else {
      props.setIGSOs([]);
      props.setCurtain(true);
      props.setStepNumber(0);
      props.resetStepImages();
      props.setTaskDesc("What to do:");
      props.setIsTerminal(false);
      props.setStepType(0);
      props.setQuestion2("What to ask");
      props.setChoices([
        {
          text: "answer",
          isCorrect: false,
        },
      ]);
    }
  };

  const hotSpotEditor = () => {
    if (deepEditorMode === "normal") {
      props.setCurtain(true);
      setDeepEditorMode("hsEditor");
      setButtonText("Save and Exit Hot Spot Editor");
    } else {
      props.setCurtain(true);
      setDeepEditorMode("normal");
      setButtonText("Hot Spot Editor");
    }
  };

  const storeConidtion = (data) => {
    updatedManifest.condition = data;
    pageContext.updatePageManifest(updatedManifest);
  };

  const setConditionBTN = () => {
    if (condition === "InAir") {
      setCondition("atGate");
      setConditionText(" At a Gate");
      storeConidtion("atGate");
      props.setCondition("atGate");
    } else {
      setCondition("InAir");
      setConditionText(" In the Air");
      storeConidtion("InAir");
      props.setCondition("InAir");
    }
  };
  var passedIGSObject = null;

  const convertLegecySteps = () => {
    updatedManifest.IGSObject = [
      {
        stepType: 1,
        isTerminal: false,
        taskDescription: "What to do",
        question: "What to ask",
        choices: [
          {
            text: "answer",
            isCorrect: false,
          },
        ],
        images: {
          path: "cpat/generic/images/19/19/57E6CC11F46AEA11817D60F26251769D/blank.png",
          assetVersionId: 19,
          imageHotSpot: "0,0,0,0",
        },
      },
    ];
    for (let i = 1; i < updatedManifest.StepGraphic.length; i++) {
      let moveingImage = { ...updatedManifest.StepGraphic[i] };
      updatedManifest.IGSObject[i - 1] = {
        stepType: 0,
        isTerminal: false,
        taskDescription: "What to do",
        question: "What to ask",
        choices: [
          {
            text: "answer",
            isCorrect: false,
          },
        ],
        images: {
          path: "cpat/generic/images/19/19/57E6CC11F46AEA11817D60F26251769D/blank.png",
          assetVersionId: 19,
          imageHotSpot: "0,0,0,0",
        },
      };
      updatedManifest.IGSObject[i - 1].stepType = 1;
      updatedManifest.IGSObject[i - 1].images[0] = moveingImage;
      if (updatedManifest.stepResponse[i]) {
        updatedManifest.IGSObject[i - 1].taskDescription = updatedManifest.stepResponse[i];
      } else {
        updatedManifest.IGSObject[i - 1].taskDescription = "select";
      }
      if (updatedManifest.hotspot[i]) {
        updatedManifest.IGSObject[i - 1].images[0].imageHotSpot = updatedManifest.hotspot[i];
      } else {
        updatedManifest.IGSObject[i - 1].images[0].imageHotSpot = "0,0,0,0";
      }
    }
    pageContext.updatePageManifest(updatedManifest);
    passedIGSObject = updatedManifest.IGSObject;
    props.setIGSOs(passedIGSObject);
    clearSlate();
  };

  const setBlankIGA = () => {
    updatedManifest.IGSObject = [
      {
        stepType: 0,
        isTerminal: false,
        taskDescription: "What to do",
        question: "What to ask",
        choices: [
          {
            // text: "answer",
            isCorrect: false,
          },
        ],
        images: {
          // image: "cpat/generic/images/19/19/57E6CC11F46AEA11817D60F26251769D/blank.png",
          // assetVersionId: 19,
          // imageHotSpot: "0,0,0,0"
        },
      },
    ];
    pageContext.updatePageManifest(updatedManifest);
    passedIGSObject = updatedManifest.IGSObject;
    props.setIGSOs(passedIGSObject);
  };

  if (updatedManifest.IGSObject) {
    passedIGSObject = updatedManifest.IGSObject;
    props.setIGSOs(passedIGSObject);
  } else {
    if (updatedManifest.StepGraphic && updatedManifest.StepGraphic[1]) {
      convertLegecySteps();
    } else {
      setBlankIGA();
    }
  }

  const handleIGASave = (IGAObject) => {
    updatedManifest.IGSObject = _.cloneDeep(IGAObject);
    pageContext.updatePageManifest(updatedManifest);
  };

  const validateIGAObject = () => {
    _.forEach(updatedManifest.IGSObject, (iga, index) => {
      switch (iga.stepType) {
        case 1:
          // if(!_.has(iga.images, 'path')) {
          //     const splitHotSpot = iga.images["0"].imageHotSpot.split(',');
          //     const newHotSpot = {
          //         left: parseFloat(splitHotSpot[0]),
          //         top: parseFloat(splitHotSpot[1]),
          //         right: parseFloat(splitHotSpot[2]),
          //         bottom: parseFloat(splitHotSpot[3])
          //     }
          //     updatedManifest.IGSObject[index].images = {"path": iga.images["0"].image, "assetVersionId": iga.images["0"].assetVersionId, "imageHotSpot": newHotSpot}
          //     pageContext.updatePageManifest(updatedManifest);
          // }
          break;
        case 2:
          if (!_.has(iga.images, "path")) {
            updatedManifest.IGSObject[index].images = {
              path: iga.images["0"].image,
              assetVersionId: iga.images["0"].assetVersionId,
            };
            pageContext.updatePageManifest(updatedManifest);
          }
          break;
        case 3:
          break;
        default:
          break;
      }
    });
  };

  const HotSpotEditor = () => {
    return (
      <div className="deepEditorSelect" onClick={() => hotSpotEditor()}>
        {" "}
        {buttonText}{" "}
      </div>
    );
  };
  const ConditionEditor = () => {
    return (
      <div className="conditionEditorSelect" onClick={() => setConditionBTN()}>
        {" "}
        Current Condition: {conditionText}{" "}
      </div>
    );
  };

  switch (deepEditorMode) {
    case "normal":
      return (
        <React.Fragment>
          <PageScene
            passedIGSObject={passedIGSObject}
            currentIGA={updatedManifest?.IGSObject}
            assetManagerProps={props.assetManagerProps}
            handleSave={handleIGASave}
            cpHotSpot={cpHotSpot}
          />
          <div className="relative-container threed-normal-grid">
            {props.displayStyle !== "block" ? <HotSpotEditor mode={setDeepEditorMode} /> : <div />}
            {props.displayStyle !== "block" ? <ConditionEditor /> : <div />}
            <PagePresentation passedIGSObject={passedIGSObject} />
          </div>
        </React.Fragment>
      );
    case "hsEditor":
      return (
        <React.Fragment>
          <HSEditor hotSpotEditor={HotSpotEditor} cpHotSpot={cpHotSpot} />
          {/* <HotSpotEditor /> */}
        </React.Fragment>
      );
    default:
      return (
        <React.Fragment>
          <PageScene passedIGSObject={passedIGSObject} cpHotSpot={cpHotSpot} />
          <PagePresentation passedIGSObject={passedIGSObject} />
          <HotSpotEditor />
          <ConditionEditor />
        </React.Fragment>
      );
  }
};
//Commenting for Van
const mapStateToProps = (state) => {
  return {
    isIGS: state.Current3dPage.isIGS,
    displayStyle: state.Current3dPage.displayStyle,
  };
};

export default connect(mapStateToProps, {
  setisIGS,
  setAllStepImages,
  setIGSOs,
  setStepNumber,
  resetStepImages,
  setStepImage,
  setTaskDesc,
  setIsTerminal,
  setStepType,
  setQuestion2,
  setChoices,
  displayStepSystem,
  setCondition,
  displayChooseStep,
  setdisplayStyle,
  displayAddStep,
  displayStepResponsePanel,
  setModel,
  setRole,
  setCustom,
  storedCurrentStep,
  setCurtain,
})(CPaT3dPlayer);

export function threeDPageManifestAssetCollector(pageManifest) {
  const assets = [];
  for (const [key, value] of Object.entries(pageManifest)) {
    if (key === "IGSObject") {
      value.forEach((igs) => {
        if ("choices" in igs) {
          const { choices } = igs;
          choices.forEach((choice) => {
            if ("image" in choice) {
              const { image } = choice;
              if ("assetVersionId" in image && "path" in image) {
                const { assetVersionId, path } = image;
                assets.push(new IDandPath(+assetVersionId, path));
              }
            }
          });
        }
        if ("images" in igs) {
          const { images } = igs;
          if ("assetVersionId" in images && "path" in images) {
            const { assetVersionId, path } = images;
            assets.push(new IDandPath(+assetVersionId, path));
          }
        }
      });
    } else if (key === "StepGraphic") {
      value.forEach((z) => {
        if ("assetVersionId" in z && "image" in z) {
          const { assetVersionId, image } = z;
          assets.push(new IDandPath(+assetVersionId, image));
        }
      });
    } else if (key === "background") {
      if ("assetVersionId" in value && "path" in value) {
        const { assetVersionId, path } = value;
        assets.push(new IDandPath(+assetVersionId, path));
      }
    } else if (key === "choices") {
      value.forEach((o) => {
        if ("assetVersionId" in o && "image" in o) {
          const { assetVersionId, image } = o;
          assets.push(new IDandPath(+assetVersionId, image));
        }
      });
    } else if (key === "modelSet") {
      if ("assetVersionId" in value && "modelSet" in value) {
        const { assetVersionId, modelSet } = value;
        assets.push(new IDandPath(+assetVersionId, modelSet));
      }
    } else if (key === "Audio") {
      assets.push(...crawlAudioArray(value));
    }
  }
  return assets;
}
