import _ from "lodash";
import React from "react";
import { IBasicPageAttributes } from "../../../pageTypes/BasicPage_Player/components/IBasePage";
import { IAnnotation } from "../../Annotation/models/IAnnotation";
import ISymbolStyle from "../../Symbol/models/ISymbolStyle";
// import { formatFunctions } from '../WYSIWYGToolbarFunctions/WYSIWYGToolbarFunctions';

type AlignButtonsType = {
  attributes?: IAnnotation | IBasicPageAttributes | ISymbolStyle;
};

const AlignButtons = () => {
  return (
    <React.Fragment>
      <button id="left" className={`ql-align wysiwyg-toolbar-btn`} value="">
        <span className="icon-text-align-left-icon"></span>
      </button>
      <button id="center" className={`ql-align wysiwyg-toolbar-btn`} value="center">
        <span className="icon-text-align-center-icon"></span>
      </button>
      <button id="right" className={`ql-align wysiwyg-toolbar-btn`} value="right">
        <span className="icon-text-align-right-icons"></span>
      </button>
    </React.Fragment>
  );
};

export default AlignButtons;
