import React, { useEffect, useRef, useState } from "react";
import Asset from "./interfaces/Asset";

interface Props {
  imageUrl: string;
  asset: Asset;
}
const AssetDataPreview = ({ imageUrl, asset }: Props) => {
  const formatImageDimensions = (height: number | null | undefined, width: number | null | undefined): string => {
    if (!height || !width) return "Not available";
    return `${height}x${width}`;
  };

  return (
    <div className="image-metadata-editor-sidebar-selected-image">
      <div className="">
        {"Video" == asset.assetTypeName ? (
          <>
            <video /*autoPlay*/
              id={"whattoevennamethis"}
              poster={`/api/Asset/${imageUrl}`}
              className={"assetdata-preview-video"} // this class doesn't look quite right
              src={`/api/Asset/${imageUrl}`}
            />
          </>
        ) : (
          <img src={`/api/Asset/${imageUrl}`} className="" />
        )}
      </div>
      <div className="metadata-editor-asset-data-table">
        <div>
          <p>File Name </p>
        </div>
        <div style={{ backgroundColor: "rgb(239,239,240)" }}>
          <p>{asset.name}</p>
        </div>
        <div>
          <p> Size </p>
        </div>
        <div>
          <p>{asset.sizeInBytes ? `${Math.round(asset.sizeInBytes / 1000)}kb` : "Not available"}</p>
        </div>
        <div>
          <p>Dimensions </p>
        </div>
        <div>{formatImageDimensions(asset.imageHeight, asset.imageWidth)} </div>
      </div>
    </div>
  );
};

export default AssetDataPreview;
