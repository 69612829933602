export enum AudioPopoverAction {
  TextToSpeech,
  UploadAudio,
}

export enum InsertMode {
  Before = "before",
  After = "after",
  Replace = "replace",
}
