import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../contexts/ObjectsProvider";
import React, { useLayoutEffect } from "react";
import Moveable from "react-moveable";
import { useTimeline } from "../../contexts/TimelineProvider/TimelineProvider";
import { useMovableElementsPlaneState } from "../../contexts/MovableElementsPlaneProvider";
import "./LineMoveable.css";
import {
  SelectedObjectActionTypes,
  useSelectedObjectDispatch,
} from "../../contexts/SelectedObjectProvider/SelectedObjectProvider";
import { useMiscUI } from "../../contexts/MiscUI/MiscUIProvider";

export function LineMoveable({ pageDims }: any) {
  const objectsState = useObjectsState();
  const objectsDispatch = useObjectsDispatch();
  const selectedObjectDispatch = useSelectedObjectDispatch();
  const { viewportDOMElementHeight, viewportDOMElementWidth } = useMovableElementsPlaneState();
  const [tl] = useTimeline();
  const selectedObject = objectsState.selectedObjects[0];
  const selected =
    selectedObject?.type?.includes("Arrow") || selectedObject?.type?.includes("Line")
      ? `[data-objectid="${selectedObject.objectId}"]`
      : null;
  const moveableRef = React.useRef<any>(null);
  const [miscUI, setMiscUI] = useMiscUI();
  const snapToGrid = miscUI.snapToGrid;
  // mapping objects, expensive. consider memoizing
  const guides = objectsState.objectList.map((object) => {
    return `[data-objectid="${object.objectId}"]`;
  });
  const determineAnimatedObjectOutOfBoundaries = (objectId: string) => {
    const animatedObject = objectsState.animatedObjects.find((object) => object.id === objectId);
    if (animatedObject) {
      return (
        tl.scrubbingCurrentTime < animatedObject.start ||
        tl.scrubbingCurrentTime > (animatedObject.end ?? tl.sequenceLength)
      );
    }
  };
  useLayoutEffect(() => {
    if (moveableRef.current) {
      moveableRef.current.updateRect();
    }
  });
  return (
    <Moveable
      target={selected}
      snappable={snapToGrid}
      elementGuidelines={guides}
      className={"target  false"}
      ref={(ref) => {
        moveableRef.current = ref;
        if (ref) {
          objectsDispatch({
            type: ObjectActionsType.SET_MOVEABLE_OBJECT_REF_LINE,
            moveableRef: ref,
          });
        }
      }}
      draggable={true}
      resolveAblesWithRotatable={{
        resizable: ["e", "w"],
      }}
      resizable={{
        renderDirections: false,
      }}
      rotatable={{
        renderDirections: ["e", "w"],
      }}
      rotationPosition={"none"}
      onRotateStart={({ target, clientX, clientY, datas, resizeStart, setFixedDirection, inputEvent }) => {
        if (resizeStart) {
          const direction = resizeStart.direction;
          setFixedDirection([-direction[0], direction[1]]);
        }
        datas.resizeStart = resizeStart;
      }}
      onRotate={({ target, datas, transform, resize, rotate, drag }) => {
        datas.rotation = rotate;
        datas.x = drag.beforeTranslate[0];
        datas.y = drag.beforeTranslate[1];
        datas.width = resize?.width;
        datas.height = resize?.height;
        target.style.transform = transform;
      }}
      onRotateEnd={({ target, clientX, clientY, datas }) => {
        const { objectid } = target.dataset;
        if (!objectid) return;
        const { rotation, x, y, width, height } = datas;
        if (datas.resizeStart) {
          objectsDispatch({
            type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
            payload: {
              objectId: objectid,
              rotation,
              x,
              y,
              width: (width / (viewportDOMElementWidth ?? 1)) * 100,
              height: (height / (viewportDOMElementHeight ?? 1)) * 100,
              time: tl.scrubbingCurrentTime,
            },
          });
          selectedObjectDispatch({
            type: SelectedObjectActionTypes.SET_X,
            payload: x,
          });
          selectedObjectDispatch({
            type: SelectedObjectActionTypes.SET_Y,
            payload: y,
          });
          selectedObjectDispatch({
            type: SelectedObjectActionTypes.SET_WIDTH,
            payload: width,
          });
          selectedObjectDispatch({
            type: SelectedObjectActionTypes.SET_HEIGHT,
            payload: height,
          });
        } else {
          objectsDispatch({
            type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
            payload: {
              objectId: objectid,
              rotation,
              time: tl.scrubbingCurrentTime,
            },
          });
        }
        selectedObjectDispatch({
          type: SelectedObjectActionTypes.SET_ROTATION,
          payload: rotation,
        });
      }}
      onResize={({ target, width, height, drag, direction, clientX, clientY, datas }) => {
        target.style.width = `${width}px`;
        target.style.height = `${height}px`;
        target.style.transform = drag.transform;
        const beforeTranslate = drag.beforeTranslate;
        datas.left = beforeTranslate[0];
        datas.top = beforeTranslate[1];
        datas.width = width;
        datas.height = height;
        selectedObjectDispatch({
          type: SelectedObjectActionTypes.SET_WIDTH,
          payload: width,
        });
      }}
      onResizeEnd={({ target, isDrag, clientX, clientY, datas }) => {
        objectsDispatch({
          type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
          payload: {
            objectId: datas.objectId,
            width: (datas.width / (viewportDOMElementWidth ?? 1)) * 100,
            time: tl.scrubbingCurrentTime,
          },
        });
      }}
      onDrag={({ target, datas, transform, beforeTranslate, delta }) => {
        datas.deltaX += delta[0];
        datas.deltaY += delta[1];
        target.style.transform = transform;
        datas.left = beforeTranslate[0];
        datas.top = beforeTranslate[1];
        selectedObjectDispatch({
          type: SelectedObjectActionTypes.SET_X,
          payload: datas.left,
        });
        selectedObjectDispatch({
          type: SelectedObjectActionTypes.SET_Y,
          payload: datas.top,
        });
      }}
      onDragEnd={({ target, datas }) => {
        const pixelX = datas.left;
        const pixelY = datas.top;
        const outOfTimeBoundaries = determineAnimatedObjectOutOfBoundaries(objectsState.selectedObjects[0].objectId);
        if (outOfTimeBoundaries) {
          return (target.style.transform = datas.initialTransform);
        }
        if (typeof pixelX === "number" && datas.deltaX !== 0) {
          objectsDispatch({
            type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
            payload: {
              time: tl.scrubbingCurrentTime,
              x: pixelX,
            },
          });
        }
        if (typeof pixelY === "number" && datas.deltaY !== 0) {
          objectsDispatch({
            type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
            payload: {
              time: tl.scrubbingCurrentTime,
              y: pixelY,
            },
          });
        }
      }}
      onRender={({ target, transform, cssText, transformObject, datas }) => {
        target.style.cssText += cssText;
        datas.rotation = transformObject.rotation;
        datas.x = transformObject.translate[0];
        datas.y = transformObject.translate[1];
      }}
    />
  );
}
