import React, { useState } from "react";
import Table from "rsuite/Table";

export type TableColumnsType = {
  key: string;
  label: string;
  width: number;
  hidden?: boolean;
};

export interface DatatableProps {
  Columns: TableColumnsType[];
  TableData: any[];
  HandleOnRowClick: (rowData: any) => void;
  RowClassName: (rowData: any) => string;
  TableClassName?: string;
  Hover?: boolean;
  Bordered?: boolean;
  HeaderHeight?: number;
  RowHeight?: number;
  Height?: number;
  Width?: number;
}

const Datatable: React.FC<DatatableProps> = (props) => {
  const {
    Columns,
    HandleOnRowClick,
    RowClassName,
    TableClassName,
    TableData,
    Width,
    Hover = true,
    Bordered = true,
    HeaderHeight = 40,
    RowHeight = 43,
    Height = 200,
  } = props;
  const [columnKeys] = useState(Columns.map((column) => column.key));
  const columns = Columns.filter((column) => columnKeys.some((key) => key === column.key));

  return (
    <Table
      className={TableClassName}
      hover={Hover}
      data={TableData}
      bordered={Bordered}
      height={Height}
      width={Width}
      rowClassName={(rowData) => {
        return RowClassName(rowData);
      }}
      onRowClick={(rowData) => {
        HandleOnRowClick(rowData);
      }}
      headerHeight={HeaderHeight}
      rowHeight={RowHeight}
    >
      {columns.map((column) => {
        const { key, label, ...rest } = column;
        return (
          <Table.Column {...rest} key={key}>
            <Table.HeaderCell>{label}</Table.HeaderCell>
            <Table.Cell dataKey={key} />
          </Table.Column>
        );
      })}
    </Table>
  );
};

export default Datatable;
