import _ from "lodash";
import React from "react";
// import ColorPicker from './ColorPicker';

type FontColorType = {
  colors?: string[];
};

const FontColorButton = (props: FontColorType) => {
  const { colors } = props;

  function makeSelectColors() {
    return _.map(colors, (color) => {
      return <option key={color} value={`${color}`}></option>;
    });
  }

  return (
    <div className="toolbar-btn-container">
      <select className="ql-color wysiwyg-toolbar-btn">{makeSelectColors()}</select>
    </div>
  );
};

export default FontColorButton;
