const unsupportedCharsRegX = /[^A-Za-z0-9\-\._]/g;
const spacesRegX = /\s/g;
/**
 *
 * @param fileName - STRING - Whole File Name
 * @returns A new string
 * @description unsupported chars become - (dash), spaces become _ (low dash) | Regexp = /[^A-Za-z0-9\-\._]/g
 */
export function replaceInvalidChars(fileName: string) {
  return fileName.replace(spacesRegX, "_").replace(unsupportedCharsRegX, "-");
}
/**
 *
 * @param fileName - STRING - Whole File Name
 * @returns a new string (filename) with the file extension to lower case if no extension is found it will return undefined
 */
export function lowerCaseExtension(fileName: string) {
  if (!fileName.includes(".")) {
    // errMsg();
    return;
    // throw new Error('No File Extension present');
  }
  const fileNameArr = fileName.split(".");
  const lowerCaseExt = fileNameArr.pop()!; // we know there will be a dot therefore at least two indexes in an array
  if (!lowerCaseExt) {
    // errMsg();
    return;
  }
  fileNameArr.push(lowerCaseExt.toLowerCase());
  return fileNameArr.join(".");
}
