import React from "react";
import * as LessonSettingsActions from "../LessonSettingsContext/LessonSettings.actions";
import * as LessonSettingsTypes from "../LessonSettings.types";
import lodash from "lodash";
const LessonSettingsStateContext = React.createContext<LessonSettingsTypes.LessonSettingsState | undefined>(undefined);
const LessonSettingsDispatchContext = React.createContext<LessonSettingsTypes.Dispatch | undefined>(undefined);
const initialState: LessonSettingsTypes.LessonSettingsState = {
  lessonSettingsTab: "Advanced Settings",
  lessonMetaData: null,
  lessonMetaDataLocalCopy: null,
  oldLessonReferences: [],
} as never;

const lessonSettingsReducer = (
  state: LessonSettingsTypes.LessonSettingsState,
  action: LessonSettingsTypes.LessonSettingsAction,
) => {
  switch (action.type) {
    case "SET_LESSON_SETTINGS_TAB":
      return LessonSettingsActions.setLessonSettingsTab(state, action);
    case "UPDATE_LESSON_META_DATA":
      return LessonSettingsActions.setLessonMetaData(state, action);
    case "UPDATE_LESSON_META_DATA_LOCAL_COPY":
      return LessonSettingsActions.setLessonMetaDataLocalCopy(state, action);
    case "RECONCILE_LESSON_META_DATA":
      return LessonSettingsActions.reconcileLessonMetaData(state, action);
    case "REMOVE_LESSON_REFERENCE":
      return LessonSettingsActions.removeLessonReference(state, action);
  }
};
const LessonSettingsProvider = ({ children, lessonMetaData }: LessonSettingsTypes.LessonSettingsProviderProps) => {
  const [state, reducer] = React.useReducer(lessonSettingsReducer, {
    ...initialState,
    lessonMetaData: { ...lessonMetaData },
  } as never);

  React.useEffect(() => {
    reducer({ type: "RECONCILE_LESSON_META_DATA", payload: lessonMetaData });
  }, [lessonMetaData]);

  return (
    <LessonSettingsStateContext.Provider value={state}>
      <LessonSettingsDispatchContext.Provider value={reducer}>{children}</LessonSettingsDispatchContext.Provider>
    </LessonSettingsStateContext.Provider>
  );
};

const useLessonSettingsState = () => {
  const context = React.useContext(LessonSettingsStateContext);
  if (context === undefined) {
    throw new Error("useLessonSettingsState must be used within a LessonSettingsProvider");
  }
  return context;
};

type useLessonSettingsDispatch<Payload = any> = () => LessonSettingsTypes.Dispatch<Payload>;

const useLessonSettingsDispatch = () => {
  const context = React.useContext(LessonSettingsDispatchContext);
  if (context === undefined) {
    throw new Error("useLessonSettingsDispatch must be used within a LessonSettingsProvider");
  }
  return context;
};

export { LessonSettingsProvider, useLessonSettingsState, useLessonSettingsDispatch };
