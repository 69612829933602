import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import { FMSObject } from "../../routes/builder";
import {
  LessonPageActionsContext,
  ILessonPageActionsContext,
  IFMSObject,
  IProceduresObject,
  PageContext,
  IPageContext,
} from "../../routes/builderContexts"; //need for cdu path
interface deletePagesWarningProps {
  deletePageSequence: (pageType: string) => void;
  setDeletePagesWarning: (value: boolean) => void;
  setFmsObject: (value: FMSObject) => void;
  set3DObject: (value: IProceduresObject) => void;
  pageType: string;
}
const DeletePagesWarningModal: React.FC<deletePagesWarningProps> = (props: deletePagesWarningProps) => {
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const lessonPageActions: ILessonPageActionsContext = useContext<ILessonPageActionsContext>(LessonPageActionsContext);

  // useEffect(()=>{
  //     //determinePageTypeToDelete();
  // },[]);

  const determinePageTypeToDelete = () => {
    switch (props.pageType) {
      case "FMS": {
        // pageContext.removeAssetFromCurrentLessonPage(lessonPageActions.fmsObject.assetVersionId);
        // pageContext.addAssetToCurrentLessonPage(lessonPageActions.dirtyFmsObject.assetVersionId);
        pageContext.updatePageAsset(
          lessonPageActions.dirtyFmsObject.assetVersionId,
          lessonPageActions.fmsObject.assetVersionId,
        );
        props.setFmsObject(lessonPageActions.dirtyFmsObject);
        break;
      }
      case "ThreeDModel": {
        props.deletePageSequence("ThreeDModel");
        // props.set3DObject({
        //         modelSet: "no3DorFms",
        //         assetVersionId: undefined,
        //         role: "Captain"
        //     }
        // )
        break;
      }
    }

    props.deletePageSequence(props.pageType);
    closeModal();
  };

  const declineRemoval = () => {
    props.setFmsObject(lessonPageActions.fmsObject);
    props.set3DObject(lessonPageActions.proceduresObject);
    closeModal();
  };

  const closeModal = () => {
    props.setDeletePagesWarning(false);
  };

  return (
    <Modal show={lessonPageActions.deleteWarning} onHide={() => {}} animation={false}>
      <Modal.Header></Modal.Header>
      <Modal.Body>
        <div className="row col-12">
          <p className="text-danger">
            <strong>WARNING THIS ACTION WILL DELETE ALL CURRENT {props.pageType} PAGES! Continue?</strong>
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={determinePageTypeToDelete}>Confirm</button>
        <button onClick={declineRemoval}>Decline</button>
      </Modal.Footer>
    </Modal>
  );
};
export default DeletePagesWarningModal;
