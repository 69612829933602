import React from "react";
import { Interweave } from "interweave";
import { BaseWYSIWYGType } from "../BaseWYSIWYG";

const summaryplayer = (props: BaseWYSIWYGType) => {
  const { pageManifest } = props;
  return (
    <div className="summaryplayer">
      <div className="base-page-text text-only">
        <Interweave content={pageManifest.textBlock[0].text} />
      </div>
    </div>
  );
};

export default summaryplayer;
