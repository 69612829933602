import React, { useEffect, useMemo, useState } from "react";
import "./LessonList.css";
import { useLessonLibrary } from "../../contexts/LessonLibrary/LessonLibraryProvider";
import { Table, Column, HeaderCell, Cell } from "rsuite-table";

// Current list of required properties needed to call the LessonList component
// This is subject to change
export interface LessonListProps {
  Show: boolean;
  setLessonVersionId: any;
  SearchString: string;
  CurrentLessonVersionId: number;
}

// LessonList makes a call to the LessonDesignerAPI that returns a list of lessons the user is allowed
// to view based upon the logged into LMS.
const LessonList = (props: LessonListProps) => {
  const { Show, setLessonVersionId, CurrentLessonVersionId, SearchString = "" } = props;
  const [activeRow, setActiveRow] = useState<number>(0);
  const [lessonLibrary] = useLessonLibrary(); // LessonLibraryProvider for retrieving the lesson list

  // Column setup for the Table component. These are column keys and values that are used.
  // The 'label' is the portion that gets displayed, the key is how they are referenced.
  // LessonVersionId and LessonId are hidden columns that will be referenced
  // Width is set to be what is needed.
  const defaultColumns = [
    {
      key: "lessonVersionId",
      label: "lessonVersionId",
      width: 0,
      hidden: true,
    },
    {
      key: "lessonId",
      label: "lessonId",
      width: 0,
      hidden: true,
    },
    {
      key: "name",
      label: "Lesson Name",
      fixed: true,
      flexGrow: 2,
    },
    {
      key: "status",
      label: "Status",
      fixed: true,
      flexGrow: 1,
    },
  ];

  const [columnKeys] = useState(defaultColumns.map((column) => column.key));

  const columns = defaultColumns.filter((column) => columnKeys.some((key) => key === column.key));

  // This useEffect gets called when the 'Show' property changes.
  // It's responsible for getting the data from the LessonDesignerAPI to be displayed
  // This will be rewritten and moved to another component.
  useEffect(() => {
    if (!Show) {
      resetList();
    }
  }, [Show]);

  // Resets the component back to default state
  // activeRow = 0
  // lessonVersionId = 0
  function resetList() {
    setActiveRow(0);
    setLessonVersionId(0);
  }

  // The class name of the row is determined on whether that row is the currently selected row
  // If the activeRow is the same as the selected row, then it gets the row-selected CSS class for
  // highlighting.
  function getRowClassName(rowData: any): string {
    if (rowData && rowData.lessonVersionId && rowData.lessonVersionId === activeRow) return "row-selected";
    else return "";
  }

  // Sets the activeRow to the lessonVersionId of the selected row
  // Sets the lessonVersionId that is being used by other components
  function handleOnRowClick(rowData: any) {
    setActiveRow(rowData.lessonVersionId);
    setLessonVersionId(rowData.lessonVersionId);
  }

  // The list of lessons to be displayed
  // Filtered by the Search bar
  // Removes the existing lesson from the list
  const lessons = useMemo(() => {
    return lessonLibrary.lessonList.filter(
      (lesson) =>
        lesson.name?.toLowerCase().includes(SearchString.toLowerCase()) &&
        lesson.lessonVersionId !== CurrentLessonVersionId,
    );
  }, [SearchString, lessonLibrary?.lessonList]);

  // Creates the table that is displayed in the LessonList component using the data inside
  // fullLessonList
  return (
    <>
      <Table
        className={"lesson-list-table"}
        shouldUpdateScroll={false}
        hover
        virtualized
        fillHeight
        data={lessons}
        bordered
        rowClassName={getRowClassName}
        onRowClick={handleOnRowClick}
        onMouseDown={(e) => e.stopPropagation()}
        headerHeight={40}
        rowHeight={43}
      >
        {columns.map((column) => {
          const { key, label, ...rest } = column;
          return (
            <Column {...rest} key={key}>
              <HeaderCell>{label}</HeaderCell>
              <Cell dataKey={key} />
            </Column>
          );
        })}
      </Table>
    </>
  );
};

export default LessonList;
