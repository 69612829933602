import React from "react";

interface Props {
  children?: React.ReactNode;
  infoPanelIsShown: boolean;
}
export function Checkboxes({ children, infoPanelIsShown }: Props) {
  return (
    <div
      className="assetManager-metadata-checkbox-area"
      style={infoPanelIsShown ? { gridTemplateRows: "35% 65%" } : {}}
    >
      {children}
    </div>
  );
}
