import React, { useEffect, useState, useContext } from "react";

import { IPageContext, PageContext } from "../../../routes/builderContexts";
import { IFmsPageManifest } from "../Interfaces/IFmsPageManifest";
import { IFmsContext, FmsContext } from "../Interfaces/fmsContext";
import { IForeignObject } from "../Interfaces/ICdu";
import lodash from "lodash";
import blobUrlFormatHelper from "../../../components/blobUrlFormatHelper";

interface graphicsEditorProps {
  cduCell: IForeignObject;
  cduPage: string;
}
interface overLayObject {
  [index: string]: string | number | undefined;
  xCoordinate: number;
  yCoordinate: number;
  width: number;
  height: number;
  image: string;
  parentCell?: string;
}
const whiteText = {
  color: "#ffffff",
  margin: "1%",
};
const CDUGraphicsEditor = (props: graphicsEditorProps) => {
  const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);
  const pagesContext: IPageContext = useContext<IPageContext>(PageContext);
  const pageManifest: IFmsPageManifest = pagesContext.pageManifest as IFmsPageManifest;

  const [cduAssets, setCduAssets] = useState<string[]>([]);
  const [selectedAsset, setSelectedAsset] = useState<overLayObject>({
    xCoordinate: 0,
    yCoordinate: 0,
    width: 0,
    height: 0,
    image: "",
  });
  const [screenDimensions, setScreenDimensions] = useState({
    xCoordinate: 0,
    yCoordinate: 0,
    width: 0,
    height: 0,
    maxY: 0,
    maxX: 0,
    maxWidth: 0,
    maxHeight: 0,
  });
  const [iconWidth, setIconWidth] = useState<number>(0);

  useEffect(() => {
    const populateAssets = () => {
      if (fmsContext.currentCDU.cduAssets) {
        const graphicAssets: string[] = [];
        fmsContext.currentCDU.cduAssets.map((asset) => {
          if (asset.id.includes("Field") && !graphicAssets.includes(asset.href)) {
            graphicAssets.push(asset.href);
          }
        });
        if (graphicAssets.length > 3) {
          setIconWidth(50);
        } else {
          setIconWidth(100);
        }
        setCduAssets(graphicAssets);
      }
    };
    const measureCduScreenDimensions = () => {
      //need to rework for a220

      let coordinateObject = {
        xCoordinate: 0,
        yCoordinate: 0,
        width: 0,
        height: 0,
        maxY: 0,
        maxX: 0,
        maxWidth: 0,
        maxHeight: 0,
      };
      if (pageManifest.cdu.includes("a220") && fmsContext.currentCDU.cduScreen) {
        //fmsContext.currentCDU.cduScreen find cords based off of screen
        coordinateObject = {
          xCoordinate: fmsContext.currentCDU.cduScreen.xCoordinate,
          yCoordinate: fmsContext.currentCDU.cduScreen.yCoordinate,
          width: parseInt(fmsContext.currentCDU.cduScreen.width.split("px")[0]),
          height: parseInt(fmsContext.currentCDU.cduScreen.height.split("px")[0]), //height and width are stored as strings followed by 'px'
          maxY:
            fmsContext.currentCDU.cduScreen.yCoordinate +
            1 +
            parseInt(fmsContext.currentCDU.cduScreen.height.split("px")[0]),
          maxX:
            fmsContext.currentCDU.cduScreen.xCoordinate +
            1 +
            parseInt(fmsContext.currentCDU.cduScreen.width.split("px")[0]),
          maxWidth:
            fmsContext.currentCDU.cduScreen.xCoordinate +
            parseInt(fmsContext.currentCDU.cduScreen.width.split("px")[0]),
          maxHeight:
            fmsContext.currentCDU.cduScreen.yCoordinate +
            parseInt(fmsContext.currentCDU.cduScreen.height.split("px")[0]),
        };
      } else {
        fmsContext.currentCDU.foreignObjects.map((object, index) => {
          if (index === 0) {
            coordinateObject.xCoordinate = object.xCoordinate;
            coordinateObject.yCoordinate = object.yCoordinate;
          }
          if (object.cduKeyName === "LSK1_Data_R6") {
            coordinateObject.width = object.xCoordinate + object.width - coordinateObject.xCoordinate;
            coordinateObject.height = object.yCoordinate + object.height - coordinateObject.yCoordinate;
          }
        });
        coordinateObject.maxX = coordinateObject.xCoordinate + 1 + coordinateObject.width;
        coordinateObject.maxY = coordinateObject.yCoordinate + 1 + coordinateObject.height;
        coordinateObject.maxWidth = coordinateObject.xCoordinate + coordinateObject.width;
        coordinateObject.maxHeight = coordinateObject.yCoordinate + coordinateObject.height;
      }
      setScreenDimensions(coordinateObject);
    };

    measureCduScreenDimensions();
    populateAssets();
  }, []);

  useEffect(() => {
    const checkForSelectedAsset = () => {
      let assetPresent = false;
      pageManifest.pageLayouts[props.cduPage].overLays.map((overLay: any, index: number) => {
        if (overLay.parentCell === props.cduCell.id) {
          assetPresent = true;
          setSelectedAsset(overLay);
        }
      });
      if (!assetPresent) {
        setSelectedAsset({
          xCoordinate: 0,
          yCoordinate: 0,
          width: 0,
          height: 0,
          image: "",
        });
      }
    };
    if (props.cduPage !== "INIT") {
      checkForSelectedAsset();
    }
  }, [props.cduCell]);

  const addOverlay = (event: React.MouseEvent<SVGElement>) => {
    let safeToAdd = true;
    let safeToReplace = false;
    let overLayIndex = 0;

    pageManifest.pageLayouts[props.cduPage].overLays.map((overLay: any, index: number) => {
      if (overLay.parentCell === props.cduCell.id) {
        safeToAdd = false;
        safeToReplace = true;
        overLayIndex = index;
      }
    });
    if (safeToAdd === true) {
      const imageData = {
        xCoordinate:
          props.cduCell.xCoordinate - 2 > screenDimensions.xCoordinate - 1
            ? props.cduCell.xCoordinate - 2
            : screenDimensions.xCoordinate - 1,
        yCoordinate: Math.floor(props.cduCell.yCoordinate) - 3,
        width: props.cduCell.width,
        height: Math.floor(props.cduCell.height) + 4,
        image: event.currentTarget.id,
        parentCell: props.cduCell.id,
      };
      pageManifest.pageLayouts[props.cduPage].overLays.push(imageData);
      pagesContext.updatePageManifest(pageManifest);
      setSelectedAsset(imageData);
    }
    if (safeToReplace) {
      pageManifest.pageLayouts[props.cduPage].overLays[overLayIndex].image = event.currentTarget.id;
      pagesContext.updatePageManifest(pageManifest);
      setSelectedAsset(pageManifest.pageLayouts[props.cduPage].overLays[overLayIndex]);
    }
  };
  const removeOverlay = () => {
    let indexValue = -1;
    pageManifest.pageLayouts[props.cduPage].overLays.map((overLay: any, index: number) => {
      if (overLay.parentCell === props.cduCell.id) {
        indexValue = index;
      }
    });
    if (indexValue !== -1) {
      const newOverlays = pageManifest.pageLayouts[props.cduPage].overLays.filter(
        (overlay: overLayObject, index: number) => {
          return index !== indexValue;
        },
      );
      pageManifest.pageLayouts[props.cduPage].overLays = lodash.cloneDeep(newOverlays);
      pagesContext.updatePageManifest(pageManifest);
      setSelectedAsset({
        xCoordinate: 0,
        yCoordinate: 0,
        width: 0,
        height: 0,
        image: "",
      });
    }
  };
  const adjustCoordinate = (event: React.ChangeEvent<HTMLInputElement>) => {
    // cannot eneter value outside of box
    event.preventDefault();

    if (!isNaN(parseFloat(event.target.value))) {
      const updatedAsset = {
        ...selectedAsset,
        [event.target.name]: parseFloat(event.target.value),
      };
      pageManifest.pageLayouts[props.cduPage].overLays.map((overLay: any, index: number) => {
        if (overLay.parentCell === selectedAsset.parentCell) {
          pageManifest.pageLayouts[props.cduPage].overLays[index] = updatedAsset;
        }
      });
      setSelectedAsset(updatedAsset);
      pagesContext.updatePageManifest(pageManifest);
    }
  };
  const blurValidtion = (fieldValue: number, variableName: string, min: number, max: number) => {
    let change = false;
    const updatedAsset = selectedAsset;
    if (fieldValue < min) {
      updatedAsset[variableName] = min;
      change = true;
    } else if (fieldValue > max) {
      updatedAsset[variableName] = max;
      change = true;
    } else if (fieldValue === null || fieldValue === undefined || fieldValue === NaN) {
      updatedAsset[variableName] = min;
    }
    if (change) {
      pageManifest.pageLayouts[props.cduPage].overLays.map((overLay: any, index: number) => {
        if (overLay.parentCell === selectedAsset.parentCell) {
          pageManifest.pageLayouts[props.cduPage].overLays[index] = updatedAsset;
        }
      });
      setSelectedAsset(updatedAsset);
      pagesContext.updatePageManifest(pageManifest);
    }
  };
  return (
    <div style={{ marginTop: "5%" }}>
      {/* {console.log(cduAssets)} */}
      {fmsContext.currentCDU.cduAssets || fmsContext.currentCDU.cduScreen ? (
        <>
          <h3 style={{ color: "#ffffff" }}>Layout Image</h3>
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-evenly",
              maxWidth: "100%",
            }}
          >
            <svg width="100%" style={{ margin: "0 Auto" }}>
              {cduAssets.map((asset: string, index: number) => (
                <g key={index}>
                  <image
                    onClick={addOverlay}
                    id={asset}
                    x={index * iconWidth}
                    y={0}
                    width={iconWidth}
                    height={iconWidth}
                    href={blobUrlFormatHelper(`${pageManifest.cduPath}${asset}`)}
                  />
                  <rect
                    x={index * iconWidth}
                    y={0}
                    onClick={addOverlay}
                    width={iconWidth}
                    height={iconWidth}
                    strokeWidth="2"
                    fill="none"
                    stroke={selectedAsset.image === asset ? "#ffffff" : ""}
                  />
                </g>
              ))}
              <g>
                <rect
                  onClick={removeOverlay}
                  id={"del"}
                  x={iconWidth * cduAssets.length + 10}
                  y={0}
                  width={"100"}
                  height={"100"}
                  strokeWidth="2"
                  stroke={""}
                  fill="none"
                />
                <text
                  onClick={removeOverlay}
                  x={iconWidth * cduAssets.length + 10}
                  y={50}
                  fill="#ffffff"
                  fontSize=".8rem"
                >
                  Remove
                </text>
              </g>
            </svg>
          </div>
          {selectedAsset.image !== "" ? (
            <>
              <p style={whiteText}>
                <strong>Customize Positioning</strong>
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "space-evenly",
                }}
              >
                <div>
                  <p style={whiteText}>X</p>
                  <input
                    className="display-input"
                    onChange={adjustCoordinate}
                    onBlur={() => {
                      blurValidtion(
                        selectedAsset.xCoordinate,
                        "xCoordinate",
                        screenDimensions.xCoordinate - 2,
                        screenDimensions.maxX - selectedAsset.width,
                      );
                    }}
                    type="number"
                    min={screenDimensions.xCoordinate - 2}
                    max={screenDimensions.maxX - selectedAsset.width}
                    value={selectedAsset.xCoordinate}
                    name="xCoordinate"
                    step={1}
                  ></input>
                </div>
                <div>
                  <p style={whiteText}>Y</p>
                  <input
                    className="display-input"
                    onChange={adjustCoordinate}
                    onBlur={() => {
                      blurValidtion(
                        selectedAsset.yCoordinate,
                        "yCoordinate",
                        screenDimensions.yCoordinate - 2,
                        screenDimensions.maxY - selectedAsset.height,
                      );
                    }}
                    type="number"
                    min={screenDimensions.yCoordinate - 2}
                    max={screenDimensions.maxY - selectedAsset.height}
                    value={selectedAsset.yCoordinate}
                    name="yCoordinate"
                    step={1}
                  ></input>
                </div>
                <div>
                  <p style={whiteText}>Width</p>
                  <input
                    className="display-input"
                    onChange={adjustCoordinate}
                    onBlur={() => {
                      blurValidtion(
                        selectedAsset.width,
                        "width",
                        1,
                        screenDimensions.maxWidth - selectedAsset.xCoordinate,
                      );
                    }}
                    type="number"
                    min="1"
                    max={screenDimensions.maxWidth - selectedAsset.xCoordinate}
                    value={selectedAsset.width}
                    name={"width"}
                    step={1}
                  ></input>
                </div>
                <div>
                  <p style={whiteText}>Height</p>
                  <input
                    className="display-input"
                    onChange={adjustCoordinate}
                    onBlur={() => {
                      blurValidtion(
                        selectedAsset.height,
                        "height",
                        1,
                        screenDimensions.maxHeight - selectedAsset.yCoordinate,
                      );
                    }}
                    type="number"
                    min="1"
                    max={screenDimensions.maxHeight - selectedAsset.yCoordinate}
                    value={selectedAsset.height}
                    name="height"
                    step={1}
                  ></input>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
export default CDUGraphicsEditor;
