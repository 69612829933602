import React, { useContext } from "react";
import PageListerHelper from "./PageListerHelper";
import StandardPageListerPanel from "./StandardPageListerPanel";
import "./PageLister.css";
import { PagesManagementContext } from "../../routes/builderContexts";
import { AbstractTooltip as Tooltip } from "../ToolTip/ToolTip";
const PageLister = () => {
  const pagesManagementContext = useContext(PagesManagementContext);
  const firstPages = pagesManagementContext.lessonPages.filter((e, i) => i <= 3);
  const content = pagesManagementContext.lessonPages
    .filter((e, i, a) => i >= 4 && i <= a.length - 4)
    .map((el, i) => {
      if ("uniquePageListerID" in el) {
      } else {
        el.uniquePageListerID = i;
      }
      return el;
    });
  const lastPages = pagesManagementContext.lessonPages.filter((e, i, a) => i > a.length - 4);

  function returnCorrect(juice, pageToGoTo) {
    let newPages = [...firstPages, ...juice, ...lastPages].map((el, i) => {
      el.order = i;
      return el;
    });
    pagesManagementContext.changePageOrder(newPages, pageToGoTo);
  }
  const switchPage = (i, flag) => {
    if (flag === "start") {
      pagesManagementContext.switchCurrentlyDisplayedPage(i);
    } else {
      let value = pagesManagementContext.lessonPages.length - 3 + i;
      pagesManagementContext.switchCurrentlyDisplayedPage(value);
    }
  };

  return (
    <React.Fragment>
      {pagesManagementContext.lessonPages.length > 3 ? (
        <div className="pages-container" data-tooltip-id="Page List">
          <Tooltip id="Page List" />
          <div className="standard-side-panel-container">
            <StandardPageListerPanel pagePullout={pagesManagementContext.pagePullout} flagDirection={"title"} />

            {firstPages.map((el, i) => {
              return (
                <div key={el.name + i}>
                  <StandardPageListerPanel
                    pagePullout={pagesManagementContext.pagePullout}
                    switchPage={switchPage}
                    flagDirection={"start"}
                    index={i}
                    name={el.name}
                    modes={el.lessonModeIds}
                  />
                </div>
              );
            })}
          </div>
          <PageListerHelper
            lessonModes={pagesManagementContext.lessonModes}
            pagesManagementContext={pagesManagementContext}
            lessonPages={content}
            updateArray={returnCorrect}
          />
          <div className="standard-side-panel-container">
            {lastPages.map((el, i) => {
              return (
                <div key={el.name + i}>
                  <StandardPageListerPanel
                    pagePullout={pagesManagementContext.pagePullout}
                    switchPage={switchPage}
                    flagDirection={"end"}
                    index={i}
                    name={el.name}
                    modes={el.lessonModeIds}
                  />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
    </React.Fragment>
  );
};
export default PageLister;
