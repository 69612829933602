import { createLogger } from "../../utils";

const log = createLogger("GlobalAudioManager", {
  background: "green",
  color: "white",
}); //Manager Only For the currently selected audio
const WORKLET_NAME = "position-reporting-processor";
const WORKLET_URL = `/worklet/${WORKLET_NAME}.js`;
export default class GlobalAudioManager {
  audioContext: AudioContext;
  audioLength = 0;
  currentTime: number | null = null; // in seconds
  loading = false;
  loadingFromDisk = false;
  counterLoading = false;
  wavesurferLoading = false;
  offset = 0;
  playbackRate = 1;
  startPosition = 0;
  lastPlay = 0;
  abortController: AbortController | null = null;
  buffers: {
    audioBuffer: AudioBuffer | null;
    counterBuffer: AudioBuffer | null;
    blob: Blob | null;
  } = {
    audioBuffer: null,
    counterBuffer: null,
    blob: null,
  };
  state: "playing" | "paused" | "stopped" = "stopped";
  private _counterTime = 0;
  audioNodes: {
    source: AudioBufferSourceNode | null;
    counter: AudioBufferSourceNode | null;
    worklet: AudioWorkletNode | null;
    wavesurfer: WaveSurfer | null;
  } = {
    source: null,
    counter: null,
    worklet: null,
    wavesurfer: null,
  };
  counterListener?: (time: number) => void;
  workletConnected = false;
  _url = "";
  reactDispatch: React.Dispatch<{ type: string; payload: any }> | null = null;
  constructor() {
    this.audioContext = new AudioContext();
    this.lastPlay = this.audioContext.currentTime;
    this.connectWorklet();
  }

  setWavesurfer(wavesurfer: WaveSurfer) {
    this.wavesurferLoading = true;
    if (!this.buffers.blob) {
      throw new Error("setWaveSurfer: No Blob");
    }
    this.audioNodes.wavesurfer = wavesurfer;
    wavesurfer.loadBlob(this.buffers.blob);
    wavesurfer.on("ready", () => {
      log("seeking to", this.startPosition / 1000);
      if (this.startPosition > 0) {
        wavesurfer.skipForward(this.startPosition);
      }
      log("wavesurfer ready");
      this.wavesurferLoading = false;
    });
    wavesurfer.on("seek", (progress) => {
      const audioStart = this.audioLength * progress;
      this.startPosition = audioStart;
      if (wavesurfer.isPlaying()) {
        this.lastPlay = this.audioContext!.currentTime;
        this.stopCounter();
        this.counterPlay();
      }
      if (this.reactDispatch) {
        this.reactDispatch({
          type: "SET_LAST_KNOWN_TIME",
          payload: audioStart,
        });
      }
    });
    wavesurfer.on("finish", () => {
      this.pause();
      this.startPosition = 0;
    });
  }
  removeWavesurfer() {
    if (!this.audioNodes.wavesurfer) {
      return;
    }

    this.startPosition = this.audioNodes.wavesurfer.getCurrentTime();
    this.audioNodes.wavesurfer.destroy();
    this.audioNodes.wavesurfer = null;
  }
  sources() {
    if (this.audioContext instanceof AudioContext) {
      return true;
    }
  }

  setReactDispatch(dispatch: React.Dispatch<{ type: string; payload: any }>) {
    this.reactDispatch = dispatch;
  }

  createCounterBuffer(audioBuffer: AudioBuffer): AudioBuffer {
    if (!this.sources()) {
      throw new Error("createCounterBuffer: No Audio Context");
    }

    const counterBuffer = this.audioContext!.createBuffer(1, audioBuffer.length, this.audioContext!.sampleRate);
    this.buffers.counterBuffer = counterBuffer;
    return counterBuffer;
  }
  createCounterBufferSource(audioBuffer: AudioBuffer): AudioBufferSourceNode {
    if (!this.sources()) {
      throw new Error("createCounterBufferSource: No Audio Context");
    }

    const counterBuffer = this.buffers.counterBuffer || this.createCounterBuffer(audioBuffer);
    const counterSource = new AudioBufferSourceNode(this.audioContext!, {
      loop: false,
    });

    counterSource.buffer = counterBuffer;
    const length = counterBuffer.length;
    const counterBufferCD = counterBuffer.getChannelData(0);
    // seems expensive
    for (let i = 0; i < length; ++i) {
      // Clamp to [0; 1).
      // Could clamp to [-1; 1) for higher precision, but it makes handling 0 troublesome.
      counterBufferCD[i] = i / length;
    }
    const _worklet = this.createWorkletNode();
    counterSource.connect(_worklet);
    _worklet.connect(this.audioContext!.destination);

    this.audioNodes.counter = counterSource;

    return counterSource;
  }

  async connectWorklet() {
    if (!this.sources()) {
      throw new Error("connectWorklet: No Audio Context");
    }
    try {
      await this.audioContext!.audioWorklet.addModule(WORKLET_URL);
      this.workletConnected = true;
    } catch (e) {
      console.error(e);
      console.error("Error Connecting Worklet");
    }
  }

  createWorkletNode(): AudioWorkletNode {
    log("creating worklet node");
    if (!this.sources()) {
      throw new Error("createWorkletNode: No Audio Context");
    }
    const prp = new AudioWorkletNode(this.audioContext!, WORKLET_NAME);
    prp.port.onmessage = (e) => {
      this.setCounterTime(e.data * this.audioLength);
    };
    prp.port.onmessageerror = (e) => {
      console.error(e);
    };
    this.audioNodes.worklet = prp;
    return prp;
  }

  // setUpCounterBuffer(audioBuffer: AudioBuffer) {
  //   if (!this.sources() || !audioBuffer) {
  //     throw new Error('setUpCounterBuffer: No Audio Context || audioBuffer');
  //   }
  //   const counterSource = this.createCounterBufferSource(audioBuffer);
  //   const prp = this.createWorkletNode();
  //   counterSource.connect(prp);
  //   prp.connect(this.audioContext!.destination);
  // }

  setCounterTime(time: number) {
    if (this.counterListener && this.state === "playing") {
      this.counterListener(time);
    }
    this._counterTime = time;
  }

  listenToCounter(callback: (time: number) => void) {
    log("listenToCounter");
    if (!this.sources()) {
      throw new Error("listenToCounter: No Audio Context");
    }
    this.counterListener = callback;
  }
  stopListeningToCounter() {
    log("stopListeningToCounter");
    this.counterListener = undefined;
  }

  async loadArrayBuffer(arrayBuffer: ArrayBuffer, url: string) {
    if (this.loading && this.abortController) {
      this.abortController.abort();
    }
    if (this.loading) {
      return;
    }
    log("loadArrayBuffer");
    if (!this.sources()) {
      throw new Error("loadArrayBuffer: No Audio Context");
    }
    if (!this.reactDispatch) {
      throw new Error("handleUrlChange: No React Dispatch");
    }
    if (!url) {
      this.reactDispatch({ type: "SET_CURRENT_AUDIO_URL", payload: url });
      return;
    }
    if (url === this._url) {
      return;
    }
    this.loadingFromDisk = true;
    this.loading = true;
    log("LOADING AUDIO");
    this.reactDispatch({ type: "SET_LOADING", payload: true });
    this._url = url;
    this.reactDispatch({ type: "SET_CURRENT_AUDIO_URL", payload: url });
    this.startPosition = 0;
    const audioDataCopy = this.copy(arrayBuffer);
    const _uInt8Array = new Uint8Array(audioDataCopy);
    const _blob = new Blob([_uInt8Array], { type: "audio/mp3" });
    log("SETTING BLOB", _blob);
    this.buffers.blob = _blob;
    const _audioBuffer = await this.audioContext!.decodeAudioData(arrayBuffer);
    this.buffers.audioBuffer = _audioBuffer;
    this.audioLength = _audioBuffer.duration;
    this.reactDispatch({ type: "SET_AUDIO_LENGTH", payload: this.audioLength });
    this.createCounterBuffer(_audioBuffer);
    this.createAudioBufferSource(_audioBuffer);
    this.lastPlay = this.audioContext!.currentTime;
    log("DONE LOADING AUDIO");
    this.loadingFromDisk = false;
    this.loading = false;
    this.reactDispatch({ type: "SET_LOADING", payload: false });
  }
  async loadAudio(url: string): Promise<void> {
    if (this.loading && this.abortController) {
      log("ABORTING");
      this.abortController.abort();
      this.loading = false;
    }

    log("loadAudio");
    if (!this.sources() || !url) {
      throw new Error("downloadArrayBuffer: No Audio Context || url");
    }
    if (!this.reactDispatch) {
      throw new Error("handleUrlChange: No React Dispatch");
    }
    if (!url) {
      this.reactDispatch({ type: "SET_CURRENT_AUDIO_URL", payload: url });
      return;
    }
    // saves having to do work on same audio
    if (url === this._url) {
      return;
    }
    log("LOADING AUDIO");
    this.loading = true;
    this.reactDispatch({ type: "SET_LOADING", payload: true });
    this._url = url;
    this.reactDispatch({ type: "SET_CURRENT_AUDIO_URL", payload: url });

    this.startPosition = 0;
    const controller = new AbortController();
    this.abortController = controller;
    const { signal } = controller;
    const response = await fetch(url, { signal });
    const audioArrayBuffer = await response.arrayBuffer();
    const audioDataCopy = this.copy(audioArrayBuffer);
    const _uInt8Array = new Uint8Array(audioDataCopy);
    const _blob = new Blob([_uInt8Array], { type: "audio/mp3" });
    log("_blob", _blob);
    this.buffers.blob = _blob;
    const _audioBuffer = await this.audioContext!.decodeAudioData(audioArrayBuffer);
    this.buffers.audioBuffer = _audioBuffer;
    this.audioLength = _audioBuffer.duration;
    this.reactDispatch({ type: "SET_AUDIO_LENGTH", payload: this.audioLength });
    this.createCounterBuffer(_audioBuffer);
    this.createAudioBufferSource(_audioBuffer);
    this.lastPlay = this.audioContext!.currentTime;
    log("DONE LOADING AUDIO");
    this.loading = false;
    this.reactDispatch({ type: "SET_LOADING", payload: false });
  }

  copy(src: ArrayBuffer) {
    const dst = new ArrayBuffer(src.byteLength);
    const u8 = new Uint8Array(dst);
    u8.set(new Uint8Array(src));
    return dst;
  }

  createAudioBufferSource(audioBuffer: AudioBuffer): AudioBufferSourceNode {
    if (!this.sources() || !audioBuffer) {
      throw new Error("createAudioBufferSource: No Audio Context || audioBuffer");
    }
    const source = new AudioBufferSourceNode(this.audioContext!, {
      buffer: audioBuffer,
      loop: false,
    });
    source.connect(this.audioContext!.destination);
    this.audioNodes.source = source;

    return source;
  }

  createBlob(audioUint8Array: Uint8Array): Blob {
    if (audioUint8Array) {
      const blob = new Blob([audioUint8Array], { type: "audio/mp3" });
      this.buffers.blob = blob;
      return blob;
    }

    throw new Error("createBlob: No Audio Uint8Array");
  }

  stopCounter() {
    if (this.audioNodes.counter) {
      this.audioNodes.counter.stop();
      this.audioNodes.counter.disconnect();
      this.audioNodes.counter = null;
    }
  }

  pause() {
    if (!this.sources()) {
      throw new Error("pause: No Audio Context");
    }

    if (this.state === "paused" || this.state === "stopped") {
      return;
    }
    if (this.state === "playing") {
      this.state = "paused";
      const playedTime = this.audioContext!.currentTime - this.lastPlay;
      if (this.startPosition + playedTime > this.audioLength) {
        this.startPosition = this.startPosition + playedTime - this.audioLength;
      } else {
        this.startPosition += playedTime;
      }
    }
    // SOURCE
    if (this.audioNodes.source && this.audioNodes.wavesurfer === null) {
      this.audioNodes.source.stop();
      this.audioNodes.source.disconnect();
      this.audioNodes.source = null;
    }
    // COUNTER
    this.stopCounter();

    // WORKLET
    if (this.audioNodes.worklet) {
      // this.audioNodes.worklet.disconnect()
    }
    // WAVESURFER
    if (this.audioNodes.wavesurfer) {
      this.audioNodes.wavesurfer.pause();
    }

    if (this.reactDispatch) {
      this.reactDispatch({ type: "SET_AUDIO_PLAYING", payload: false });
      this.reactDispatch({
        type: "SET_LAST_KNOWN_TIME",
        payload: this.startPosition,
      });
    }
  }

  playAt(n: number, node: any) {}
  getPlayedTime() {
    return (this.audioContext!.currentTime - this.lastPlay) * this.playbackRate;
  }

  private _createAndPlayCounter() {
    if (this.buffers.counterBuffer) {
      const _source = this.createCounterBufferSource(this.buffers.counterBuffer);
      _source.start(0, this.startPosition);
    }
  }
  private _playCounter() {
    this.audioNodes.counter!.start(0, this.startPosition);
  }

  counterPlay() {
    log("counterPlay");
    if (this.audioNodes.counter === null) {
      log("counterPlay", "creating");
      this._createAndPlayCounter();
    } else if (this.audioNodes.counter) {
      log("counterPlay", "playing");
      this._playCounter();
    }
  }
  play() {
    log("PLAY");
    log("loading", this.loading);
    if (!this.sources() || !this.buffers.audioBuffer || !this.buffers.counterBuffer) {
      log(this.audioContext, this.buffers);
      if (!this.buffers.audioBuffer || !this.buffers.counterBuffer) {
        return;
      }
      throw new Error("pause: No Audio Context || audioBuffer || counterBuffer");
    }

    if (this.wavesurferLoading) {
      return;
    }

    if ((this.state === "stopped" || this.state === "paused") && !this.loading) {
      this.state = "playing";
      this.lastPlay = this.audioContext!.currentTime;
    } else {
      return;
    }

    // COUNTER
    this.counterPlay();
    // WORKLET
    if (this.audioNodes.worklet) {
      // this.audioNodes.worklet.disconnect()
    }
    // WAVESURFER
    if (this.audioNodes.wavesurfer) {
      log("Play: Wavesurfer", this.audioNodes.wavesurfer);
      this.audioNodes.wavesurfer.play(this.startPosition);
    } else {
      // SOURCE: can only play if wavesurfer is null
      if (this.audioNodes.source === null) {
        log("Play: Create Source Buffer", this.buffers.audioBuffer);
        const _source = this.createAudioBufferSource(this.buffers.audioBuffer);
        _source.start(0, this.startPosition);
      } else if (this.audioNodes.source) {
        log("Play: Create Source Buffer", this.buffers.audioBuffer);
        this.audioNodes.source.start(0, this.startPosition);
      }
    }
    if (this.reactDispatch) {
      this.reactDispatch({ type: "SET_AUDIO_PLAYING", payload: true });
    }
  }

  cleanup() {
    log("CLEANUP");
    if (this.abortController && this.loading) {
      //cancel the request
      this.abortController.abort();
    }
    if (this.loadingFromDisk) {
      log("CLEANUP: loading, canceling");
      // since this is also called in a use effect unmount, if it is loading we should let the loading finish
      return;
    }
    this.abortController = null;
    this.pause();
    this.audioNodes.source = null;
    this.audioNodes.worklet = null;
    this.audioNodes.wavesurfer = null;
    this.audioNodes.counter = null;
    this.buffers.audioBuffer = null;
    this.buffers.counterBuffer = null;
    log("SETTING BLOB", null);
    this.buffers.blob = null;
    this.startPosition = 0;
    this._url = "";
    this.state = "stopped";
    if (this.reactDispatch) {
      this.reactDispatch({ type: "SET_CURRENT_AUDIO_URL", payload: "" });
      this.reactDispatch({ type: "SET_LAST_KNOWN_TIME", payload: null });
    }
  }

  cleanupForce() {
    log("CLEANUP");
    if (this.abortController && this.loading) {
      //cancel the request
      this.abortController.abort();
    }
    this.abortController = null;
    this.pause();
    this.audioNodes.source = null;
    this.audioNodes.worklet = null;
    this.audioNodes.wavesurfer = null;
    this.audioNodes.counter = null;
    this.buffers.audioBuffer = null;
    this.buffers.counterBuffer = null;
    log("SETTING BLOB", null);
    this.buffers.blob = null;
    this.startPosition = 0;
    this._url = "";
    this.state = "stopped";
    if (this.reactDispatch) {
      this.reactDispatch({ type: "SET_CURRENT_AUDIO_URL", payload: "" });
      this.reactDispatch({ type: "SET_LAST_KNOWN_TIME", payload: null });
    }
  }
}
