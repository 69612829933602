import React, { useEffect } from "react";
import * as THREE from "three";

import {
  useGLTF,
  useProgress
} from "@react-three/drei"

const Plane = (props) => {
  THREE.Cache.enabled = false;
  const planeSetPath = props.modelPath + "/Walkaround.gltf";
  const { scene } = useGLTF(planeSetPath);
  scene.scale.set(9,9,9);

  const { progress } = useProgress();
  
  useEffect(() => {
    if(progress === 100) {
      props.callBack();
    }
  }, [progress])

  // useEffect(() => {
  //   scene.traverse((child) => {
  //     if(child.isMesh && child.material.name === "Walkaround_Livery_Txr") {
  //       const texLoader = new THREE.TextureLoader();
  //       let newTexture = texLoader.load("/api/Asset/" + props.blobPath);
  //       newTexture.flipY = false;
  //       child.material.map = newTexture
  //     }
  //   })
  // }, [props.blobPath]);

  return <primitive object={scene} />
};

export default Plane;
