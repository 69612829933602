import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import IBaseResponse from "../models/IBaseResponse";
import IApiResponse from "../models/IApiResponse";
import IBaseRequest from "../models/IBaseRequest";
import { MULTIPART } from "../components/constants/mimeTypes";
import { cpatErrorHandler } from "../services/ErrorCentral/errorHandlerAPI";
import lodash from "lodash";
import { IUpdateLessonResponse } from "../models/UpdateLessonApiInterfaces";
import LessonDesignerStore from "../store/lessonDesignerStore";

interface IHttpHandlerService {
  get<TResponse extends IBaseResponse>(apiUrl: string): Promise<IApiResponse<TResponse>>;
  post<TRequest extends IBaseRequest, TResponse extends IBaseResponse>(
    apiUrl: string,
    request: TRequest,
    axiosConfig?: AxiosRequestConfig,
  ): Promise<IApiResponse<TResponse>>;
  getBlob<TResponse>(api: string): Promise<IApiResponse<TResponse>>;
  detectBlob<TResponse>(api: string): Promise<IApiResponse<TResponse>>; // like GET, but no BODY returned; use to check for existence, size, date
  postMultipart<TResponse extends IBaseResponse>(api: string, request: FormData): Promise<IApiResponse<TResponse>>;
  postWithSendBeacon<TRequest>(api: string, request: TRequest | null, config: any): Promise<boolean>;
}

const Forbidden = 403;
const UnAuthorized = 401;
const CONTENT_TYPE_HEADER = "Content-Type";

const checkForIsBaseResponseError = (response: AxiosResponse<any>, isBaseResponse: boolean): boolean => {
  return !!(isBaseResponse && response.data && (response.data as IBaseResponse).errorMessage);
};

const performApiCall = async <TRequest, TResponse>(
  api: string,
  isGetCall: boolean,
  request: TRequest | null = null,
  isBaseResponse = true,
  config: AxiosRequestConfig = { timeout: 0 },
  isHeadCall = false,
): Promise<IApiResponse<TResponse>> => {
  try {
    let response: AxiosResponse<TResponse> = {} as AxiosResponse<TResponse>;

    if (!config || !lodash.isObject(config)) {
      config = { headers: {} };
    } else if (!lodash.isObject(config.headers)) {
      config.headers = {};
    }

    config.headers.lmsKey = LessonDesignerStore.fetchCurrentLms();

    if (api.includes("download")) {
      config.responseType = "arraybuffer";
    }

    if (isGetCall) response = await axios.get<TResponse>(api, config);
    else if (isHeadCall)
      // (this could use some refactor perhaps...)
      response = await axios.head<TResponse>(api, config);
    // just get header, not actual content body
    else response = await axios.post<TResponse>(api, request, config);

    if (response.status === UnAuthorized) {
      window.location.reload(); // force app to refresh it's cookie -- this is a temporary hack
    }

    if (response.status > 299 || response.status < 200) {
      return {
        isSuccess: false,
        errorMessage: "Request could not be processed",
        data: {} as TResponse,
        isForbidden: response.status === Forbidden,
      };
    }

    // if(((response.data as unknown) as IBaseResponse).errorMessage !== '')
    // {
    //
    //     // console.dir(response)
    // }

    if (
      (response.data as unknown as IBaseResponse).errorMessage === null ||
      (response.data as unknown as IBaseResponse).errorMessage === "" ||
      (response.data as unknown as IBaseResponse).errorMessage === undefined
    ) {
      return {
        isSuccess: true,
        errorMessage: "", // no error message should be returned if the API call was successful
        data: response.data,
      };
    } else if (
      "forceReload" in response.data &&
      (response.data as unknown as IUpdateLessonResponse).forceReload === true &&
      (response.data as unknown as IBaseResponse).errorMessage
    ) {
      return {
        isSuccess: false,
        errorMessage: (response.data as unknown as IBaseResponse).errorMessage, // no error message should be returned if the API call was successful
        data: response.data,
      };
    } else if (checkForIsBaseResponseError(response, isBaseResponse)) {
      return {
        isSuccess: false,
        errorMessage: (response.data as unknown as IBaseResponse).errorMessage,
        data: {} as TResponse,
      };
    } else {
      return {
        isSuccess: false,
        errorMessage: (response.data as unknown as IBaseResponse).errorMessage, // no error message should be returned if the API call was successful
        data: {} as TResponse,
      };
    }
  } catch (error) {
    // console.dir(error);
    cpatErrorHandler(error.response);

    if (error.response && error.response.status === UnAuthorized) {
      window.location.reload(); // force app to refresh it's cookie -- this is a temporary hack
    }

    return {
      isSuccess: false,
      errorMessage: "Request could not be processed",
      data: {} as TResponse,
      isForbidden: !!error.response && error.response.status === Forbidden,
    };
  }
};

const performApiCallWithSendBeacon = async <TRequest>(
  api: string,
  request: TRequest | null = null,
  config: any = { timeout: 0 },
): Promise<boolean> => {
  const blob = new Blob([JSON.stringify(request)], {
    ...config,
    lmsKey: LessonDesignerStore.fetchCurrentLms(),
  } as any);
  const success = navigator.sendBeacon(api, blob);
  if (success) {
    return success;
  }
  return false;
};

const httpHandlerService: IHttpHandlerService = {
  getBlob: async <TResponse>(api: string): Promise<IApiResponse<TResponse>> => {
    return await performApiCall(api, true, null, false);
  },

  detectBlob: async <TResponse>(api: string): Promise<IApiResponse<TResponse>> => {
    return await performApiCall(api, false, null, false, undefined, true);
  },

  get: async <TResponse extends IBaseResponse>(api: string): Promise<IApiResponse<TResponse>> => {
    return await performApiCall(api, true);
  },

  post: async <TRequest extends IBaseRequest, TResponse extends IBaseResponse>(
    api: string,
    request: TRequest,
    axiosConfig?: AxiosRequestConfig,
  ): Promise<IApiResponse<TResponse>> => {
    return await performApiCall(api, false, request, undefined, axiosConfig);
  },

  postMultipart: async <TResponse extends IBaseResponse>(
    api: string,
    request: FormData,
  ): Promise<IApiResponse<TResponse>> => {
    const axiosRequestConfig: AxiosRequestConfig = { headers: {} };
    axiosRequestConfig.headers[CONTENT_TYPE_HEADER] = MULTIPART;

    return await performApiCall(api, false, request, true, axiosRequestConfig);
  },
  postWithSendBeacon: async <TRequest>(api: string, request: TRequest | null, config: any) => {
    return performApiCallWithSendBeacon(api, request, config);
  },
};

export default httpHandlerService;
