import React, { PropsWithChildren, useEffect, useContext } from "react";
import lodash from "lodash";
import { ITable } from ".//ITable";
import { useTablesDataDispatch, useTablesDataState } from "./TablesDataProvider";
import Cells from "./Cells";
import TableControls from "./TableControls";
import TablesMovableControls from "./TablesMovableControls";
import { IPageContext, PageContext } from "../../routes/builderContexts";
import { useObjectPosition } from "../../hooks/useObjectPosition";
import TableRTEMenu from "./TablesRTEMenu";
import { useSelectedObjectDispatch } from "../../contexts/SelectedObjectProvider/SelectedObjectProvider";
import {
  InteractivityHotspotActionTypes,
  useInteracitvityHotspotDispatch,
} from "../../contexts/InteractivityHotspotProvider";
import "./tables.css";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../contexts/ObjectsProvider";
import AnnotationHighlight from "../../pageTypes/BasicPage_Player/components/BaseFreeForm/components/HighlightRect";
import { useObjectIsInTime } from "../../hooks/useObjectIsInTime";

const { SET_CURRENT_HOTSPOT } = InteractivityHotspotActionTypes;

const StandardFormats = [
  "background",
  "bold",
  "color",
  "font",
  "italic",
  "link",
  "size",
  "strike",
  "script",
  "underline",
  "blockquote",
  "header",
  "indent",
  "list",
  "align",
  "direction",
  "image",
  "video",
];
// const renderSettings: Settings = {
//   tables: settingType.cpatonly,
// };
// const [featuresToRender, setFeaturesToRender, loading] = useSettingsToggle(renderSettings)

function TableBody({
  children,
  table,
  index,
  setTarget,
  setEditableIndex,
  zIndex,
  setNodeToUpdate,
  setIsPropertyBoxShown,
}: PropsWithChildren<any>) {
  const tablesDispatch = useTablesDataDispatch();
  const tablesState = useTablesDataState();
  const selectedObjectDispatch = useSelectedObjectDispatch();
  const objectsState = useObjectsState();
  const objectsDispatch = useObjectsDispatch();

  const hotspotsDispatch = useInteracitvityHotspotDispatch();
  const {
    position: [x, y],
  } = useObjectPosition(table.objectId, table.top, table.left, table.width, table.height);
  const transformString = `translate(${x}px, ${y}px)`;
  const isInTime = useObjectIsInTime(table.objectId);
  const opacity = isInTime ? 1 : table.ghost ? 0.25 : 0;

  const tableStyle = {
    // top: table.top + "%",
    // left: table.left + "%",
    opacity,
    filter: table.isDisplayed ? "none" : "grayScale(100%)",
    transform: transformString,
    width: table.width + "%",
    height: table.height + "%",
    border: table.border,
    zIndex: zIndex ?? 255,
    backgroundColor: table.backgroundColor,
  };
  let rows = `${table.rows}`;
  rows = rows.replaceAll(",", "");
  let columns = `${table.columns}`;
  columns = columns.replaceAll(",", "");
  return (
    <div
      className="custom-table"
      style={tableStyle}
      id={"custom-table-" + index}
      data-objectid={table.objectId}
      custom-table-id={"custom-table-" + index}
      onMouseDown={(e) => {
        const objectId = table?.objectId;
        let type = ObjectActionsType.SET_SELECTED_OBJECT;
        if (e.ctrlKey) {
          type = ObjectActionsType.ADD_SELECTED_OBJECT;
        }
        objectsDispatch({ type: type, payload: { objectId } });
        hotspotsDispatch({
          type: SET_CURRENT_HOTSPOT,
          payload: null,
        });
        e.stopPropagation();
      }}
      onClick={(e) => {
        const objectId = table?.objectId;
        setTarget(null);
        setEditableIndex(-1);
        setNodeToUpdate(null);
      }}
    >
      <AnnotationHighlight objectId={table.objectId} />
      <div
        className="custom-table-grid"
        key={index}
        style={{
          display: "grid",
          height: "100%",
          width: "100%",
          position: "relative",
          gridTemplateColumns: columns,
          gridAutoRows: rows,
        }}
      >
        {children}
      </div>
    </div>
  );
}

function Tables(props: any) {
  const tablesDispatch = useTablesDataDispatch();
  const tablesState = useTablesDataState();
  const objectsState = useObjectsState();
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const setIsPropertyBoxShown = props.setIsPropertyBoxShown;
  useEffect(() => {
    const nodeList = document.querySelectorAll("[custom-table-id]");
    if (tablesState.selectedTable !== undefined && nodeList !== undefined) {
      tablesDispatch({
        action: "addTablesDomRef",
        payload: [nodeList[tablesState.selectedTable]],
      });
    }
  }, [tablesState.selectedTable]);
  return (
    <>
      {objectsState.tables?.map((table: ITable, index: number) => {
        return (
          <>
            <TableRTEMenu
              key={"tr-" + index + tablesState.selectedCell?.index}
              formats={StandardFormats}
              objectId={table.objectId}
              style={{
                left: table.left + "%",
                top: table.top - 10 + "%",
                zIndex: 20000,
              }}
            />
            <TableBody
              table={table}
              index={index}
              zIndex={table.zIndex}
              key={"tb-" + index}
              setTarget={props.setTarget}
              setEditableIndex={props.setEditableIndex}
              setNodeToUpdate={props.setNodeToUpdate}
              setIsPropertyBoxShown={setIsPropertyBoxShown}
            >
              <Cells table={table} key={"c-" + index} />
              <TableControls
                // quillRef={props.quillRef}
                selectedFontSize={props.selectedFontSize}
                index={index}
                editTableCellText={props.editTableCellText}
                id={table.objectId}
              />
            </TableBody>
          </>
        );
      })}
    </>
  );
}

export default Tables;

// class Table {

//   constructor(public _rows: any, public _columns: any) {

//   }
//   rows() {
//     this._rows = ''
//   }
//   getStringyRows() {

//   }
// }
