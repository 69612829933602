import type { Frame, BlurCutoutObject } from "../../types";

export function interpolateFrame(framesBehind: Frame[], framesAhead: Frame[], currentTime: number) {
  function interpolateProperty<K extends keyof Omit<Frame, "id">>(property: K): number | undefined | BlurCutoutObject {
    const framesBehindWithProperty = framesBehind.filter((frame) => {
      const value = property === "blur" ? frame?.blur?.intensity : frame[property];
      return property === "blurCutoutShapes" ? true : typeof value === "number";
    });
    const framesAheadWithProperty = framesAhead.filter((frame) => {
      const value = property === "blur" ? frame?.blur?.intensity : frame[property];
      return property === "blurCutoutShapes" ? true : typeof value === "number";
    });
    const hasFramesBehind = framesBehindWithProperty.length > 0;
    const hasFramesAhead = framesAheadWithProperty.length > 0;
    const isInBetween = hasFramesBehind && hasFramesAhead;
    const isLastFrame = !hasFramesAhead && hasFramesBehind;
    const isFirstFrame = !hasFramesBehind && hasFramesAhead;
    if (!hasFramesBehind && !hasFramesAhead) {
      return undefined;
    }

    if (isInBetween) {
      if (property === "blur") {
        // handle because nested blur object
        return interpolate(
          framesBehindWithProperty[framesBehindWithProperty.length - 1]?.blur?.intensity as number,
          framesAheadWithProperty[0]?.blur?.intensity as number,
          framesBehindWithProperty[framesBehindWithProperty.length - 1].timestamp,
          framesAheadWithProperty[0].timestamp,
          currentTime,
        );
      }
      return interpolate(
        framesBehindWithProperty[framesBehindWithProperty.length - 1][property]! as number,
        framesAheadWithProperty[0][property]! as number,
        framesBehindWithProperty[framesBehindWithProperty.length - 1].timestamp,
        framesAheadWithProperty[0].timestamp,
        currentTime,
      );
    } else if (isFirstFrame) {
      return framesAheadWithProperty[0][property] as number;
    } else if (isLastFrame) {
      return framesBehindWithProperty[framesBehindWithProperty.length - 1][property] as number;
    }
  }

  // a frame can be missing x or y
  const interpolatedFrame: Omit<Frame, "id"> = {
    timestamp: currentTime,
    x: interpolateProperty("x"),
    y: interpolateProperty("y"),
    width: interpolateProperty("width"),
    height: interpolateProperty("height"),
    rotation: interpolateProperty("rotation"),
    opacity: interpolateProperty("opacity"),
    pitch: interpolateProperty("pitch"),
    yaw: interpolateProperty("yaw"),
    zoom: interpolateProperty("zoom"),
    value: interpolateProperty("value"),
    blur: {
      intensity: interpolateProperty("blur") as number,
    },
    blurCutoutShapes: interpolateProperty("blurCutoutShapes") as BlurCutoutObject[],
  };
  return interpolatedFrame;
}
export function interpolate(value1: number, value2: number, value1T: number, value2T: number, t: number) {
  return value1 + ((value2 - value1) * (t - value1T)) / (value2T - value1T);
}
