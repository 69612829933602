import "../Quiz_Editor.css";
import React from "react";

type RandomizerType = {
  isRandomOn: boolean;
  handleRandomChange: (boolean: boolean) => void;
};

const QuizRandomizer = (props: RandomizerType): JSX.Element => {
  const { isRandomOn, handleRandomChange } = props;
  return (
    <React.Fragment>
      <div className="pageListerActionButton review-page-action-button">
        <div>
          <p>Randomize Answers:</p>
          <div className="randomize randomize-switch">
            <input
              type="checkbox"
              className="randomize-checkbox"
              id="randomize-switch"
              checked={isRandomOn}
              onChange={(e: any) => handleRandomChange(e)}
            />
            <label htmlFor="randomize-switch" className="randomize-btn">
              <span className="randomize-feature" data-label-on="on" data-label-off="off"></span>
            </label>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default QuizRandomizer;
