import React from "react";
import AnnotationCircledNumber from "../../types/AnnotationCircledNumber";

const AnnotationMenuCircleNumber = () => {
  return (
    <React.Fragment>
      <span className="icon-circle-1-green">
        <span className="path1"></span>
        <span className="path2"></span>
        <span className="path3"></span>
      </span>
    </React.Fragment>
  );
};

export default AnnotationMenuCircleNumber;
