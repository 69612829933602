import React from "react";
import { LessonMetaDataState } from "../../../contexts/LessonMetaDataProvider";
import { useLessonSettingsState, useLessonSettingsDispatch } from "../LessonSettingsContext/LessonSettingsProvider";
import * as LessonSettingsTypes from "../LessonSettings.types";
import { useMiscUI } from "../../../contexts/MiscUI/MiscUIProvider";
import ToggleSwitch from "./ToggleSwitch";
// import FmsDropDown from "../../Modals/fmsSelection/fmsDropDown";

const AdvancedSettings = (props: any) => {
  const settingsState = useLessonSettingsState();
  const settingsDispatch = useLessonSettingsDispatch();
  const [miscUI, setMiscUI] = useMiscUI();
  // const [fmsObject, setFmsObject] = React.useState({ cdu: "", cduPath: "", assetVersionId: -1 });
  function doesAlternateAudioExist() {
    if (props?.lessonPages) {
      for (let i = 0; i < props.lessonPages.length; i++) {
        const pageAudio = props.lessonPages?.[i]?.pageManifest?.Audio;
        if (pageAudio && pageAudio.length > 3) {
          return true;
        }
      }
    }
    return false;
  }

  function onToggleChange(isOn: boolean) {
    setMiscUI({ type: "TOGGLE_SNAP_TO_GRID" });
  }

  return (
    <div className="advancedSettings">
      <div className="settings-passing-score">
        <p>Passing Score: </p>
        <input
          type="number"
          min="50"
          max="100"
          value={settingsState.lessonMetaDataLocalCopy.minimumPassingScore}
          onChange={(e) => {
            let score = +e.target.value;
            if (score < 50) {
              score = 50;
            } else if (score > 100) {
              score = 100;
            }

            (settingsDispatch as LessonSettingsTypes.Dispatch<LessonMetaDataState>)({
              type: "UPDATE_LESSON_META_DATA_LOCAL_COPY",
              payload: { minimumPassingScore: score } as LessonMetaDataState,
            });
          }}
        />
      </div>
      <div className="settings-alternate-audio">
        <label>
          Primary Narration
          <input
            value={settingsState?.lessonMetaDataLocalCopy?.additionalSettings?.studentLanguages[0] ?? ""}
            onChange={(e) => {
              const currentLanguages = [...settingsState.lessonMetaDataLocalCopy.additionalSettings.studentLanguages];
              currentLanguages[0] = e.target.value;
              const x = {
                additionalSettings: {
                  ...settingsState.lessonMetaDataLocalCopy.additionalSettings,
                  studentLanguages: currentLanguages,
                },
              };
              settingsDispatch({
                type: "UPDATE_LESSON_META_DATA_LOCAL_COPY",
                payload: x,
              });
            }}
          />
        </label>
        {(settingsState?.lessonMetaDataLocalCopy?.additionalSettings?.studentLanguages[1] ||
          doesAlternateAudioExist()) && (
          <label>
            Alternative Narration
            <input
              value={settingsState?.lessonMetaDataLocalCopy?.additionalSettings?.studentLanguages[1] ?? ""}
              placeholder="Enter Text"
              onChange={(e) => {
                const currentLanguages = [...settingsState.lessonMetaDataLocalCopy.additionalSettings.studentLanguages];
                currentLanguages[1] = e.target.value;
                const x = {
                  additionalSettings: {
                    ...settingsState.lessonMetaDataLocalCopy.additionalSettings,
                    studentLanguages: currentLanguages,
                  },
                };
                settingsDispatch({
                  type: "UPDATE_LESSON_META_DATA_LOCAL_COPY",
                  payload: x,
                });
              }}
            />
          </label>
        )}
      </div>
      {props.threeDModels.length > 0 && (
        <div className="procedures-settings">
          <div className="proc-options-container">
            <div className="proc-role">
              <p>Role: </p>
              <select>
                <option>Captain</option>
              </select>
            </div>
            <div className="proc-model">
              <p>Model: </p>
              <select>
                {props.threeDModels.map((mod: any) => {
                  return <option key={mod.name}>{mod.name}</option>;
                })}
              </select>
            </div>
          </div>
        </div>
      )}
      {props.fmsCdus.length > 0 && (
        <div className="fms-settings">
          <div>
            <p>FMS CDU: </p>
            <select>
              {props.fmsCdus.map((fms: any) => {
                return <option key={fms.name}>{fms.name}</option>;
              })}
            </select>
          </div>
        </div>
      )}
      <div className="ffs-settings">
        <label>Object Alignment</label>
        {/* <div className={`toggle-switch ${isOn ? "on" : "off"}`} onClick={toggleSwitch}> */}
        <ToggleSwitch initialIsOn={miscUI.snapToGrid} onChange={onToggleChange} />
      </div>
    </div>
  );
};

export default AdvancedSettings;
