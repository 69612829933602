import React, { useEffect, useState, useContext } from "react";
import _ from "lodash";
import { IPageContext, PageContext } from "../../../../routes/builderContexts";
import { IFmsPageManifest } from "../../Interfaces/IFmsPageManifest";
import RichTextEditor from "../../../../components/richTextEditor/richTextEditor";
import SuccessMatchForm from "./successMatchForm";
import HighlightingForm from "./highlightingForm";
import CriteriaInstructions from "./CriteriaInstructions";
import { editCriteriaProps } from "../../Interfaces/successCriteriaInterfaces";
import CriteriaActionSelect from "../../../../components/ActionSelect/ActionSelect";
import { FmsContext, IFmsContext } from "../../Interfaces/fmsContext";

//import { FmsContext, IFmsContext } from "../../Interfaces/fmsContext";

interface scratchpadColors {
  color: string;
  rgb: string;
  hex?: string;
}

// interface scratchpadColors {[index: number]: { color: string; rgb: string; hex?: string };}

const SingleSuccessCriteria: React.FC<editCriteriaProps> = (props: editCriteriaProps) => {
  const pagesContext: IPageContext = useContext<IPageContext>(PageContext); //data and context for page manifest
  const pageManifest: IFmsPageManifest = pagesContext.pageManifest as IFmsPageManifest;
  const updatedManifest: any = _.cloneDeep(pagesContext.pageManifest);
  const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);
  //const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);
  const pageCells: any = pageManifest.pages[pageManifest.bootPage];
  const cellSelect = document.getElementById("cellToMatch");
  // const selectedCell = cellSelect.value
  const cellList = fmsContext.currentCDU.foreignObjects.map((cell: any) => {
    return cell.cduKeyName;
  });

  const modalCellList =
    fmsContext.currentModal.foreignObjects !== undefined
      ? fmsContext.currentModal.foreignObjects.map((cell: any) => {
          return cell.cduKeyName;
        })
      : null;
  const joinedCellList = cellList.concat(modalCellList);
  const cellToMatch = pageManifest.successCriteria[props.criteriaIndex].cellToMatch;
  const scratchPadEntryColor = pageManifest.successCriteria[props.criteriaIndex].entryColor;
  const scratchpadColors = [
    { color: "White", rgb: "rgb:(255,255,255)", hex: "#ffffff" },
    { color: "Magenta", rgb: "rgb:(255,240,15)", hex: "#fff00f" },
    { color: "Cyan", rgb: "rgb:(255,0,255)", hex: "#ff00ff" },
    { color: "Green", rgb: "rgb:(0,181,0)", hex: "#00b500" },
    { color: "Yellow", rgb: "rgb:(255,255,0)", hex: "#ffff00" },
    { color: "Amber", rgb: "rgb:(255,191,0)", hex: "#ffbf00" },
    { color: "Black", rgb: "rgb:(0,0,0)", hex: "#000000" },
  ];

  const assignHelperText = (newText: string) => {
    props.editCriteriaStringValue("helperText", newText);
  };

  const handleInstructionsChange = (newText: string) => {
    props.editCriteriaStringValue("instructions", newText);
  };

  const toggleScratchPad = (event: React.ChangeEvent<HTMLSelectElement>) => {
    props.toggleScratchPad(event.target.value);
    props.clearSuccess();
  };
  const selectToggleScratchPad = (value: string) => {
    props.toggleScratchPad(value);
    props.clearSuccess();
  };

  const updateManifestSetEntryColor = (color: string | undefined) => {
    if (color !== undefined) {
      updatedManifest.successCriteria[props.criteriaIndex].entryColor = color;
      props.editCriteriaStringValue("entryColor", color);
    }
  };

  const SetDefaultEntryColor = (color: string | undefined) => {
    // updatedManifest.pages.INIT.Scratchpad ='<p><span style="color: rgb(0, 255, 255);"></span></p>'
    if (color !== undefined) {
      updatedManifest.successCriteria[props.criteriaIndex].entryColor = color;
      props.editCriteriaStringValue("entryColor", color);
      // pagesContext.updatePageManifest(updatedManifest);
    }
  };
  const updateManifestCellToMatch = (e: any) => {
    updatedManifest.successCriteria[props.criteriaIndex].cellToMatch = e;
    pagesContext.updatePageManifest(updatedManifest);
  };

  const updateCriteriaToggleTask = (e: any) => {
    console.log(e);
    // let curVal = updatedManifest.successCriteria[props.criteriaIndex]?.toggleKey;
    // updatedManifest.successCriteria[props.criteriaIndex].toggleKey = e
    // pagesContext.updatePageManifest(updatedManifest);
    props.editCriteriaStringValue("toggleKey", e);
  };

  if (
    props.criteria.type === "textMatch" &&
    (pageManifest.cdu.includes("B747") || pageManifest.cdu.includes("f100")) &&
    scratchPadEntryColor === undefined
  ) {
    SetDefaultEntryColor("Green");
  } else if (
    props.criteria.type === "textMatch" &&
    pageManifest.cdu.includes("B767") &&
    scratchPadEntryColor === undefined
  ) {
    SetDefaultEntryColor("Green");
  } else if (
    props.criteria.type === "textMatch" &&
    pageManifest.cdu.includes("B757") &&
    scratchPadEntryColor === undefined
  ) {
    SetDefaultEntryColor("Green");
  } else if (
    props.criteria.type === "textMatch" &&
    pageManifest.cdu.includes("a350") &&
    scratchPadEntryColor === undefined
  ) {
    SetDefaultEntryColor("Cyan");
  }
  // useEffect(()=>{
  //     // if(props.criteriaIndex > 0 && pageManifest.successCriteria[props.criteriaIndex-1].type === "textMatch"){//need to properly set a criteria type when a new criteria is created
  //     //     props.toggleScratchPad("sequential");
  //     // }else {
  //     //     props.toggleScratchPad("textMatch");
  //     // }
  //     return(()=>{

  //         //fmsContext.setCriteriaIndex(-1);
  //     })
  // },[]);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <p className="instructions-deletebutton fms-header strong-header" id="AddToDoTask">
            <strong>Add To Do Task</strong>
          </p>
          <p className="text-danger">{props.statusMessage}</p>
          <form>
            <label style={{ width: "100%" }}>
              Task Action:
              {/* <select 
                                className="spacedInput" 
                                id="sequenceCriteria" 
                                onChange={toggleScratchPad} 
                                value={props.criteria.type}>
                                <option className="criteria-option" disabled={props.criteriaIndex > 0 && pageManifest.successCriteria[props.criteriaIndex-1].type === "textMatch"} value={"textMatch"}>Scratchpad</option>
                                <option className="criteria-option"  value={"sequential"}>Key Press</option>
                                
                            </select> */}
              <CriteriaActionSelect
                action={props.criteria.type === "textMatch" ? "Scratchpad" : "Key Press"}
                options={["textMatch", "sequential"]}
                highlightColor="#293D54"
                defaultBackground="#ffffff"
                defaulTextColor="#000000"
                highlightTextColor="#ffffff"
                selectOption={selectToggleScratchPad}
                disabledOptions={
                  props.criteriaIndex > 0 && pageManifest.successCriteria[props.criteriaIndex - 1].type === "textMatch"
                    ? ["textMatch"]
                    : []
                }
                displayValues={["Scratchpad", "Key Press"]}
                parentClass={"spacedInput criteria-select-input"}
                parentId={"sequenceCriteria"}
                optionWrapperClass={"criteria-select-options-container"}
                optionClass={"criteria-select-option"}
                arrowClass={"criteria-arrow"}
              />
            </label>
          </form>

          {(props.criteria.type === "textMatch" && pageManifest.cdu.includes("B747")) ||
          (props.criteria.type === "textMatch" && pageManifest.cdu.includes("f100")) ||
          (props.criteria.type === "textMatch" && pageManifest.cdu.includes("B757")) ||
          (props.criteria.type === "textMatch" && pageManifest.cdu.includes("B767")) ||
          (props.criteria.type === "textMatch" && pageManifest.cdu.includes("a350")) ? (
            <form>
              <label style={{ width: "100%" }}>
                Entry Color:
                <select
                  className="spacedInput criteria-select-input action-select-wrapper-div"
                  // style={{color:scratchPadEntryColor}}
                  id="scratchPadEntryColor"
                  onChange={(e) => {
                    updateManifestSetEntryColor(e.target.options[e.target.selectedIndex].text);
                  }}
                >
                  <option selected key={scratchPadEntryColor}>
                    {scratchPadEntryColor}
                  </option>
                  ;
                  {scratchpadColors.map((e: scratchpadColors) => {
                    if (scratchPadEntryColor !== e.color) {
                      // return <option style={e.color === 'White'?{color:'black'}:{color:e.color}} key={e.color}>{e.color}</option>;
                      return (
                        <option value={e.rgb} key={e.color}>
                          {e.color}
                        </option>
                      );
                    }
                  })}
                </select>
              </label>
            </form>
          ) : (
            <></>
          )}

          {props.criteria.type === "textMatch" &&
          (pageManifest.cdu.includes("145") ||
            pageManifest.cdu.includes("350") ||
            pageManifest.cdu.includes("q400")) ? (
            <div>
              Cell to match:
              <select
                id="cellToMatch"
                onChange={(e) => {
                  updateManifestCellToMatch(e.target.options[e.target.selectedIndex].id);
                  props.editCriteriaStringValue("cellToMatch", e.target.options[e.target.selectedIndex].id);
                }}
              >
                <option>
                  {cellToMatch
                    ?.replace("LSK1_", "")
                    .replace(/_/g, " ")
                    .replace(/^\s+|\s+$/g, "")}
                </option>
                ;{/* {console.log(cellList)} */}
                {/* {console.log(Object.keys(pageManifest.pages[pageManifest.bootPage]))}
                  {console.log(pageManifest.pages[pageManifest.bootPage])} */}
                {/* {Object.keys(pageManifest.pages[pageManifest.bootPage]).map((cell: any) => { */}
                {/* {fmsContext.currentModal !== ""} */}
                {fmsContext.currentModal.modalName === ""
                  ? cellList.map((cell: any, index: number) => {
                      if (cellToMatch !== cell) {
                        return <option key={cell}>{cell}</option>;
                      }
                    })
                  : joinedCellList.map((cell: any, index: number) => {
                      if (cellToMatch !== cell && index < joinedCellList.length - 1) {
                        return (
                          <option id={cell} key={cell}>
                            {cell
                              ?.replace("LSK1_", "")
                              .replace(/_/g, " ")
                              .replace(/^\s+|\s+$/g, "")}
                          </option>
                        );
                      }
                    })}
              </select>
            </div>
          ) : (
            <></>
          )}

          <SuccessMatchForm
            criteriaType={props.criteria.type}
            successMatch={props.criteria.successMatch}
            selectedKey={props.selectedKey}
            displaySuccessMatch={props.displaySuccessMatch}
            matchSuccess={props.matchSuccess}
            clearSuccess={props.clearSuccess}
            cleanupBlankMatchSuccess={props.cleanupBlankMatchSuccess}
            openKeyMenu={props.openKeyMenu}
            changeToKeyPress={props.changeToKeyPress}
            clearKeyMatch={props.clearKeyMatch}
            autoEnableKey={props.autoEnableKey}
            addOptionalScratchpadCriteriaEntry={props.addOptionalScratchpadCriteriaEntry}
            removeScratchpadEntry={props.removeScratchpadEntry}
            selectedKeyHighlight={props.selectedKeyHighlight}
            removeHighlight={props.removeHighlight}
            pageManifest={pageManifest}
            criteria={props.criteria}
          />
          {props.criteria.type === "sequential" ? <></> : <></>}
          {fmsContext.currentCDU?.SpecialFeatures?.toggleKey && props.criteria.type === "textMatch" ? (
            <div style={{ display: "flex", height: "25px" }}>
              <input
                type="checkbox"
                name="toggleKey"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  updateCriteriaToggleTask(event.target.checked);
                }}
                defaultChecked={props.criteria.toggleKey}
                // value={props.criteria.toggleTask}
              ></input>
              <div style={{ marginLeft: "20px" }}>has Value Toggle</div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="row ">
        <div className="col-12 ">
          <hr className="white-hr" />

          <HighlightingForm
            succesCriteria={props.criteria}
            pushHighlight={props.pushHighlight}
            removeHighlight={props.removeHighlight}
            textInputHightlight={props.textInputHightlight}
            selectedKeyHighlight={props.selectedKeyHighlight}
            highlights={props.criteria.highlights}
            displayHighlight={props.displayHighlight}
            cleanupBlankMatchSuccess={props.cleanupBlankMatchSuccess}
          />
          <div className="col-12 criteriaContainer">
            <CriteriaInstructions
              criteriaType={props.criteria.type}
              selectedKey={props.selectedKey}
              criteriaInstructions={props.criteria.instructions}
              displayInstructions={props.displayInstructions}
              handleInstructionsChange={handleInstructionsChange}
              successMatch={props.criteria.successMatch}
            />
          </div>
          <div className="col-12 criteriaContainer">
            <p style={{ fontWeight: "bold" }}>Incorrect Response Feedback:</p>
            <RichTextEditor
              displayText={props.criteria.helperText}
              assignNewText={assignHelperText}
              placeholder={""}
              module={[]}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default SingleSuccessCriteria;
