function PanoramicsOverlay() {
  return (
    <>
      <div id="panoramics-overlay-portal"></div>
      <div className="panoramics-overlay" />
    </>
  );
}

export default PanoramicsOverlay;
