import React, { useState } from "react";
import "../../CPaT3d_Player/components/hotSpotEditor/hsEditor.css";
import "./hsEditor.css";
import WorkShop from "./workShop";
import { ReactComponent as EIcon } from "../../../assets/icons/3DPage/tilt-fwd.svg";
import { ReactComponent as CIcon } from "../../../assets/icons/3DPage/tilt-bkw.svg";
import { ReactComponent as DIcon } from "../../../assets/icons/3DPage/scene-right.svg";
import { ReactComponent as QIcon } from "../../../assets/icons/3DPage/scene-up.svg";
import { ReactComponent as ZIcon } from "../../../assets/icons/3DPage/scene-down.svg";
import { ReactComponent as WIcon } from "../../../assets/icons/3DPage/scene-fwd.svg";
import { ReactComponent as AIcon } from "../../../assets/icons/3DPage/scene-left.svg";
import { ReactComponent as XIcon } from "../../../assets/icons/3DPage/scene-bkw.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/3DPage/increase-nudge.svg";
import { ReactComponent as MinusIcon } from "../../../assets/icons/3DPage/decrease-nudge.svg";
import { ReactComponent as OpenHotspot } from "../../../assets/icons/Common/open-menu-hotspot-controls-icon-white.svg";
import { ReactComponent as CloseHotspot } from "../../../assets/icons/Common/close-menu-hotspot-controls-icon-white.svg";
import { ReactComponent as VIcon } from "../../../assets/icons/3DPage/icon-v.svg";

import { useMiscUI } from "../../../contexts/MiscUI/MiscUIProvider";
const hsInnards = (
  <>
    <hr />
    <strong className="guideP">Mouse Controls:</strong>
    <div className="guideP">
      {" "}
      <strong>Left:</strong> Rotate view around HS{" "}
    </div>
    <div className="guideP">
      {" "}
      <strong>Right:</strong> Move HS along horizontal plane
    </div>
    <div className="guideP">
      {" "}
      <strong>Wheel:</strong> Zoom
    </div>
    <hr />
    <strong className="guideP">Keyboard Controls:</strong>
    <div>
      <p style={{ color: "green" }}>
        <WIcon /> Increase hotspot height
      </p>
    </div>
    <div>
      <p style={{ color: "green" }}>
        <XIcon /> Decrease hotspot height
      </p>
    </div>
    <div>
      <p style={{ color: "red" }}>
        <DIcon /> Increase hotspot width
      </p>
    </div>
    <div>
      <p style={{ color: "red" }}>
        <AIcon /> Decrease hotspot width
      </p>
    </div>
    <div>
      <p style={{ color: "#4d4dff" }}>
        <EIcon /> Increase hotspot length
      </p>
    </div>
    <div>
      <p style={{ color: "#4d4dff" }}>
        <VIcon /> Decrease hotspot length
      </p>
    </div>
    <div>
      <p>
        <PlusIcon /> Increase hotspot size
      </p>
    </div>
    <div>
      <p>
        <MinusIcon /> Decrease hotspot size
      </p>
    </div>
    <div>
      <p>
        <CIcon /> Reset hotspot to default size
      </p>
    </div>
    <div>
      <p>
        <QIcon /> Move hotspot up
      </p>
    </div>
    <div>
      <p>
        <ZIcon /> Move hotspot down
      </p>
    </div>
  </>
);

const proxInnards = (
  <>
    <hr />
    <strong className="guideP">Mouse Controls:</strong>
    <div className="guideP">
      {" "}
      <strong>Left:</strong> Rotate view around proximity{" "}
    </div>
    {/*<div className='guideP'> <strong>Right:</strong> Move proximity along horizontal plane</div>*/}
    <div className="guideP">
      {" "}
      <strong>Wheel:</strong> Zoom
    </div>
  </>
);

const HSEditor = (props) => {
  const [controlsVisible, setControlsVisible] = useState(true);
  const [miscUI, setMiscUI] = useMiscUI();
  const [editMode, setEditMode] = useState("scale");
  const currentHotSpot = props.currentHotSpot;

  const show = miscUI.hotSpotEditorControlsVisible;
  const showHSEditor = miscUI.editorTypeToggle;

  const changeHS = (changeTo) => {
    if (changeTo === "box") {
      props.setIsSphere(false);
    } else if (changeTo === "sphere") {
      props.setIsSphere(true);
    }
  };

  const ModePicker = () => {
    let styleMarker1 = "style2";
    let styleMarker2 = "style1";
    switch (editMode) {
      case "scale": {
        styleMarker1 = "style2";
        styleMarker2 = "style1";
        break;
      }
      case "rotate": {
        styleMarker1 = "style1";
        styleMarker2 = "style2";
        break;
      }
      default: {
        styleMarker1 = "style1";
        styleMarker2 = "style1";
      }
    }
    let editorMarker1 = "style2";
    let editorMarker2 = "style1";
    if (showHSEditor) {
      editorMarker1 = "style2";
      editorMarker2 = "style1";
    } else {
      editorMarker1 = "style1";
      editorMarker2 = "style2";
    }

    return (
      <div className="hsControlGui">
        <div className="hsControlTitle">
          <button
            className={editorMarker1}
            onClick={(e) => (showHSEditor ? e.preventDefault() : setMiscUI({ type: "TOGGLE_EDITOR_TYPE" }))}
          >
            Hot Spot
          </button>
          <button
            className={editorMarker2}
            onClick={(e) => (showHSEditor ? setMiscUI({ type: "TOGGLE_EDITOR_TYPE" }) : e.preventDefault())}
          >
            Proximity
          </button>
        </div>

        <div className="hsControlInnards">
          {showHSEditor ? (
            <>
              <div className="shapeControl">
                Shapes:
                <label className="testLabel">
                  <input
                    type="radio"
                    name="shape"
                    value="Box"
                    checked={!props.isSphere}
                    onClick={() => changeHS("box")}
                  />{" "}
                  Square
                </label>
                <label className="testLabel">
                  <input
                    type="radio"
                    name="shape"
                    value="Sphere"
                    checked={props.isSphere}
                    onClick={() => changeHS("sphere")}
                  />{" "}
                  Sphere
                </label>
              </div>
              <hr />
              <div className="modeBox">
                <button className={styleMarker1} id="resizeButton" onClick={() => setEditMode("scale")}>
                  re-size
                </button>
                <button className={styleMarker2} id="rotateButton" onClick={() => setEditMode("rotate")}>
                  rotate
                </button>
              </div>
            </>
          ) : (
            props.proxEditor()
          )}
          {/*!showHSEditor && props.proxEditor()*/}
          <hr />
          {props.hotSpotEditor()}
        </div>
      </div>
    );
  };

  return (
    <>
      <WorkShop
        showHSEditor={showHSEditor}
        isSphere={props.isSphere}
        setIsSphere={props.setIsSphere}
        editMode={editMode}
        hotspotEditMode={miscUI.editorTypeToggle}
        mode={props.setDeepEditorMode}
        proximityRange={props.proximityRange}
        isVisible={props.isVisible}
        proximityOffset={props.proximityOffset}
      />
      ;
      <div className="absolute-container" style={{ pointerEvents: "none" }}>
        <div className="relative-container workshop-grid">
          {<ModePicker />}
          <div className="userGuide">
            <div className="hsec guideP" onClick={() => setMiscUI({ type: "TOGGLE_HSEC" })}>
              <p>
                <strong>{showHSEditor ? "Hot Spot Editor Controls" : "Proximity Editor Controls"}</strong>
              </p>
              <p>{show ? <CloseHotspot /> : <OpenHotspot />}</p>
            </div>
            {show && showHSEditor ? hsInnards : proxInnards}
          </div>
        </div>
      </div>
    </>
  );
};
export default HSEditor;
