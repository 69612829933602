import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { IFmsContext, FmsContext } from "../Interfaces/fmsContext";
import {
  PageContext,
  IFMSObject,
  IPageContext,
  LessonPageActionsContext,
  ILessonPageActionsContext,
} from "../../../routes/builderContexts";
import { IFmsPageManifest } from "../Interfaces/IFmsPageManifest";
import ActionSelect from "../../../components/ActionSelect/ActionSelect";
import FmsObjectPositionEditor from "./FmsObjectPositionEditor";

const CDUScreenObjects = (props: any) => {
  //in development
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const lessonPageActionsContext: ILessonPageActionsContext =
    useContext<ILessonPageActionsContext>(LessonPageActionsContext);
  const pageManifest: IFmsPageManifest = pageContext.pageManifest as IFmsPageManifest;
  const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);
  const currentCDU = lessonPageActionsContext.fmsObject.cduObject;
  const fmsData = lessonPageActionsContext.fmsObject;

  useEffect(() => {
    if (fmsData.cduObject.screenObjects) {
      const newPageManifest = _.cloneDeep(pageManifest);
      //search in fmsData.cduObject.screenObjects for objects that are not in pageManifest.screenObjects
      //if found, add to pageManifest.screenObjects
      fmsData.cduObject.screenObjects.forEach((object: any) => {
        let objectFound = false;
        pageManifest.screenObjects.forEach((pageObject: any) => {
          if (object.id === pageObject.id) {
            objectFound = true;
          }
        });
        if (!objectFound) {
          newPageManifest.screenObjects.push(object);
        }
      });

      pageContext.updatePageManifest(newPageManifest);
    }
  }, []);

  function mapObjects(object: any, index: number) {
    if (object.objectType === "line" && object.state === "ON") {
      return lineObject(object, index);
    } else if (object.objectType === "rect" && object.state === "ON") {
      return rectangularObject(object, index);
    }
  }

  function lineObject(object: any, index: number) {
    const bootPage = pageContext.pageManifest.bootPage;
    const objectHasOffset =
      pageContext.pageManifest.pageLayouts[bootPage]?.objectOffsets?.[object.id] !== undefined ? true : false;
    const objectOffset = pageContext.pageManifest.pageLayouts[bootPage]?.objectOffsets?.[object.id];
    return (
      <line
        x1={objectHasOffset ? objectOffset.position.x1 : object.x1}
        y1={objectHasOffset ? objectOffset.position.y1 : object.y1}
        x2={objectHasOffset ? objectOffset.position.x2 : object.x2}
        y2={objectHasOffset ? objectOffset.position.y2 : object.y2}
        stroke={object.strokeColor}
        strokeWidth={object.stroke}
        id={object.id}
      />
    );
  }

  function rectangularObject(object: any, index: number) {
    const bootPage = pageContext.pageManifest.bootPage;
    const objectHasOffset =
      pageContext.pageManifest.pageLayouts[bootPage]?.objectOffsets?.[object.id] !== undefined ? true : false;
    const objectOffset = pageContext.pageManifest.pageLayouts[bootPage]?.objectOffsets?.[object.id];
    return (
      <rect
        x={objectHasOffset ? objectOffset.position.xCoordinate : object.x}
        y={objectHasOffset ? objectOffset.position.yCoordinate : object.y}
        width={objectHasOffset ? objectOffset.position.width : object.width}
        height={objectHasOffset ? objectOffset.position.height : object.height}
        fill="none"
        stroke={object.strokeColor}
        strokeWidth={object.stroke}
        id={object.id}
      />
    );
  }

  return <>{pageManifest.cdu.includes("q400") ? <>{pageManifest.screenObjects?.map(mapObjects)}</> : <></>}</>;
};

const CDUScreenObjectsSelect = (props: any) => {
  const pagesContext: IPageContext = useContext<IPageContext>(PageContext);
  const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);
  const pageManifest: IFmsPageManifest = pagesContext.pageManifest as IFmsPageManifest;
  const lessonPageActionsContext: ILessonPageActionsContext =
    useContext<ILessonPageActionsContext>(LessonPageActionsContext);
  const pageTemplates = lessonPageActionsContext.fmsObject.cduObject.pageTemplates;
  const [selectedObject, setSelectedObject] = useState("");

  const [object, setObject] = useState({});

  useEffect(() => {}, [selectedObject]);

  const getScreenObjectList = () => {
    const editableGraphicsList: any = [];

    function addObjects(object: any, index: number) {
      editableGraphicsList.push(object.id);
    }

    if (fmsContext.currentCDU.screenObjects) {
      fmsContext.currentCDU.screenObjects.forEach(addObjects);
    }
    editableGraphicsList.push("NONE");
    return editableGraphicsList;
  };

  const getSelectedObjectState = () => {
    let objectState: any = "";
    pageManifest.screenObjects.forEach((object: any) => {
      if (object.id === selectedObject) {
        object.state ? (objectState = object.state) : (objectState = "OFF");
      }
    });

    return objectState;
  };

  const selectObject = (value: string) => {
    setSelectedObject(value);
    pageManifest.screenObjects.forEach((object: any) => {
      if (value === "NONE") {
        object.state = "OFF";
      } else {
        if (object.id === value) {
          setObject(object);
          if (object.state === "ON") {
            // object.state = 'OFF';
          } else {
            object.state = "ON";
          }
        }
      }
    });
    pagesContext.updatePageManifest(pageManifest);
  };

  const selectState = (value: string) => {
    // let newPageManifest = _.cloneDeep(pageManifest);
    const newPageManifest = pageManifest;
    newPageManifest.screenObjects.forEach((object: any) => {
      if (object.id === selectedObject) {
        object.state = value;
      }
    });
    pagesContext.updatePageManifest(pageManifest);
  };

  return (
    <React.Fragment>
      {pageManifest.cdu.includes("q400") ? (
        <label
          className="instructions page-opbjective-label edit-menu-label screen-adjust-objective-label"
          style={{ maxWidth: "500px" }}
        >
          Edit Object:
          <ActionSelect
            action={selectedObject === "" ? "NONE" : selectedObject}
            options={getScreenObjectList()}
            highlightColor="#293D54"
            defaultBackground="#ffffff"
            defaulTextColor="#000000"
            highlightTextColor="#ffffff"
            selectOption={selectObject}
            disabledOptions={[]}
            displayValues={getScreenObjectList()}
            parentClass={"spacedInput page-objective-input"}
            parentId={"page-objective-edite-menu-id"}
            optionWrapperClass={"pageObjective-template-options-container"}
            optionClass={"criteria-select-option"}
          />
        </label>
      ) : (
        <></>
      )}
      {pageManifest.cdu.includes("q400") ? (
        <label
          className="instructions page-opbjective-label edit-menu-label screen-adjust-objective-label"
          style={{ maxWidth: "500px" }}
        >
          Object State:
          <ActionSelect
            action={selectedObject === "" ? "OFF" : getSelectedObjectState()}
            options={["OFF", "ON"]}
            highlightColor="#293D54"
            defaultBackground="#ffffff"
            defaulTextColor="#000000"
            highlightTextColor="#ffffff"
            selectOption={selectState}
            disabledOptions={[]}
            displayValues={["OFF", "ON"]}
            parentClass={"spacedInput page-objective-input"}
            parentId={"page-objective-edite-menu-id"}
            optionWrapperClass={"pageObjective-template-options-container"}
            optionClass={"criteria-select-option"}
          />
        </label>
      ) : (
        <></>
      )}

      {pageManifest.cdu.includes("q400") ? (
        <FmsObjectPositionEditor
          type={"cdu_screen_object"}
          cduData={object}
          pageManifest={pageManifest}
          pagesContext={pagesContext}
        />
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export { CDUScreenObjects, CDUScreenObjectsSelect };
