import { ReactComponent as AudioIcon } from "../../assets/icons/audio/audio-icon.svg";
import { ReactComponent as SpeechIcon } from "../../assets/icons/audio/speech-icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/Common/trash-icon.svg";

import "./index.css";
import { LanguageType } from "../../models/IInteractiveAudio";
import { SecondaryLanguageSwitch } from "../../components/NarrationDrawer/components/SecondaryLanguageSwitch";
import { NarrationAudioState, OneShotAudioState } from "../../contexts/PageAudioManager/types";
export interface AudioActionsProps {
  audio: OneShotAudioState | NarrationAudioState;
  haSecondaryAudio: boolean;
  onUpload: () => void;
  onTextToSpeech: () => void;
  onRemove: () => void;
  onChangeLanguage: (language: LanguageType) => void;
  onCreateSecondary: () => void;
}
export const AudioActions = ({
  audio,
  haSecondaryAudio,
  onUpload,
  onTextToSpeech,
  onRemove,
  onChangeLanguage,
  onCreateSecondary,
}: AudioActionsProps) => {
  return (
    <>
      <div className="audio-actions-wrapper">
        <SecondaryLanguageSwitch
          activeLanguage={audio.language}
          onSelectPrimary={() => onChangeLanguage(LanguageType.PRIMARY)}
          onSelectSecondary={() => onChangeLanguage(LanguageType.SECONDARY)}
          onCreateSecondary={() => onCreateSecondary()}
          hasSecondary={haSecondaryAudio}
        />
      </div>
      <div className="audio-actions-title">Replace or Remove Audio</div>
      <div className="audio-actions-wrapper">
        <div className="audio-action">
          <button
            className="cpat-icon-button cpat-icon-button--dark cpat-icon-button--xl cpat-icon-button--rounded"
            title="Upload Audio"
            onClick={onUpload}
          >
            <AudioIcon />
          </button>
          <div className="audio-action-label">Upload Audio</div>
        </div>
        <div className="audio-action">
          <button
            className="cpat-icon-button cpat-icon-button--dark cpat-icon-button--xl cpat-icon-button--rounded"
            title="Text to Speech"
            onClick={onTextToSpeech}
          >
            <SpeechIcon />
          </button>
          <div className="audio-action-label">Text to Speech</div>
        </div>
        <div className="audio-action">
          <button
            className="cpat-icon-button cpat-icon-button--dark cpat-icon-button--xl cpat-icon-button--rounded"
            title="Remove Audio"
            onClick={onRemove}
          >
            <DeleteIcon />
          </button>
          <div className="audio-action-label">Remove Audio</div>
        </div>
      </div>
    </>
  );
};
