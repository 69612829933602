import { HotspotAction, Level, NodeLocation } from "../../lib/interactivity";

export enum InteractivityHotspotActionTypes {
  SET_ALL_VISIBLE_HOTSPOTS,
  SET_CURRENT_HOTSPOT,
  ADD_NEW_VISIBLE_HOTSPOT,
  EDIT_VISIBLE_HOTSPOT,
  UPDATE_DOM_ELEMENTS,
  SET_CURRENT_SELECTED_HOTSPOT_FROM_DOM_ELEMENT,
  CLEAR_ALL_VISIBLE_HOTSPOTS,
  REMOVE_VISIBLE_HOTSPOT,
  REPLACE_INNER_ACTION_ON_SELECTED_VIUSAL_HOTSPOT,
  CLEAR_NEED_SAVE,
}

export interface InteracitvityHotspotState {
  // todo convert to set
  visibleHotspots: InteractivityVisualHotspot[]; // would be an animatable object
  currentlySelectedHotspot: null | InteractivityVisualHotspot;
  needSave: boolean;
}

export type InteracitvityHotspotAction =
  | AddNewHotspot
  | SetAllVisibleHotspots
  | SetCurrentHotspot
  | SetCurrentSelectedHotspotFromDomElement
  | UpdateDomElements
  | EditHotspot
  | ClearHotspots
  | RemoveVisibleHotspot
  | UpdateInternalACtionOfVisualHotspot
  | ClearNeedSave;

export interface InteractivityHotspotOptions {
  top: number;
  left: number;
  width: number;
  height: number;
  domReference: HTMLDivElement | null;
  id: number;
  interactivityBuilderAction: HotspotAction | null;
}

// type InteracitvityHotspotActionPayload<Type extends InteracitvityHotspotActionTypes> =

export interface AddNewHotspot {
  type: InteractivityHotspotActionTypes.ADD_NEW_VISIBLE_HOTSPOT;
  payload: Partial<InteractivityHotspotOptions>;
}
export interface EditHotspot {
  type: InteractivityHotspotActionTypes.EDIT_VISIBLE_HOTSPOT;
  payload: HotspotAction;
}
export interface ClearHotspots {
  type: InteractivityHotspotActionTypes.CLEAR_ALL_VISIBLE_HOTSPOTS;
}
export interface RemoveVisibleHotspot {
  type: InteractivityHotspotActionTypes.REMOVE_VISIBLE_HOTSPOT;
  hotspot: HotspotAction;
}
export interface SetAllVisibleHotspots {
  type: InteractivityHotspotActionTypes.SET_ALL_VISIBLE_HOTSPOTS;
  payload: HotspotAction[];
}
export interface SetCurrentHotspot {
  type: InteractivityHotspotActionTypes.SET_CURRENT_HOTSPOT;
  payload: any;
}
export interface SetCurrentSelectedHotspotFromDomElement {
  type: InteractivityHotspotActionTypes.SET_CURRENT_SELECTED_HOTSPOT_FROM_DOM_ELEMENT;
  payload: any;
}
export interface UpdateDomElements {
  type: InteractivityHotspotActionTypes.UPDATE_DOM_ELEMENTS;
  payload: any;
}
export interface UpdateInternalACtionOfVisualHotspot {
  type: InteractivityHotspotActionTypes.REPLACE_INNER_ACTION_ON_SELECTED_VIUSAL_HOTSPOT;
  payload: HotspotAction;
}
export interface ClearNeedSave {
  type: InteractivityHotspotActionTypes.CLEAR_NEED_SAVE;
}

export class InteractivityVisualHotspot {
  top: number;
  left: number;
  width: number;
  height: number;
  domReference: HTMLDivElement | null = null;
  id: number;
  interactivityBuilderAction: HotspotAction | null = null;
  constructor({ height, width, top, left, domReference, id, interactivityBuilderAction }: InteractivityHotspotOptions) {
    this.top = top;
    this.left = left;
    this.width = width;
    this.height = height;
    this.domReference = domReference;
    this.id = id;
    this.interactivityBuilderAction = interactivityBuilderAction;
  }
}
