import React, { useCallback, useContext, useEffect, useState } from "react";
import Asset from "./interfaces/Asset";
import FooterSection from "./FooterSection";
import Header from "./Header";
import ImageMetadataEditorContainer from "./ImageMetadataEditorContainer";
import ImagesContainer from "./ImagesContainer";
import SingleAssetContainer from "./SingleAssetContainer";
import Sidebar from "./Sidebar";
import MetadataModalBody from "./MetadataModalBody";
import { EditorContext } from "./MetaDataEditorContext/MetadataEditorContext";
import genericRepositoryService from "../../services/genericRepositoryService";
import { IUpdateLessonRequest } from "../../models/UpdateLessonApiInterfaces";
import ILessonMetaRequest from "../../models/ILessonMetaRequest";
import IGetFilteredAssetsRequest from "../../models/IGetFilteredAssets/IGetFilteredAssetsRequest";
import { AxiosRequestConfig } from "axios";
import {
  getMostRestrictiveIdentifier,
  updateAsset,
  isValidMetadata,
  validateMetadata,
} from "./utils/uploaderFunctions";
import TagData from "./TagData";
import AssetPreview from "./AssetPreview";
import _ from "lodash";
import SavingModal from "./SavingModal";
import { useSelector } from "react-redux";

interface Props {
  showModal?: boolean;
  lessonMeta: ILessonMetaRequest;
}
interface GetIncompleteAssetsRequest extends IGetFilteredAssetsRequest {
  incomplete: true;
}

const MetadataEditor = (Props: Props) => {
  const [selectedAssetCount, setSelectedAssetCount] = useState(0);
  const [allAssetsSelected, setAllAssetsSelected] = useState<boolean | undefined>(undefined);
  const [submittedThroughSelectAll, setSubmittedThroughSelectAll] = useState(false);
  const [submittedAtLeastOnce, setSubmittedAtLeastOnce] = useState(false);
  const { state } = useContext(EditorContext);
  const { selectedMetadata, showImagePreview, imagePreview } = state;
  const [selectedSingleAsset, setSelectedSingleAsset] = useState<Asset | undefined>(undefined);
  const [assets, setAssets] = useState<Asset[]>([]);
  const [shareAssets, setShareAllAssets] = useState(false);
  const [updatedAssetIds, setUpdatedAssetIds] = useState<Array<number>>([]);
  const [saving, setSaving] = useState(false);
  const [validMetadata, setIsValidMetadata] = useState(false);
  const [validAssetDescription, setValidAssetDescription] = useState(false);
  const isCPaTUser: boolean = useSelector(({ authorizedState }: any) => authorizedState.isCpatUser);

  /// this should probably go on utils

  const removeForceCompletion = async () => {
    const lessonAircraftData = getMostRestrictiveIdentifier(Props.lessonMeta);
    const updateRequest: IUpdateLessonRequest = {
      lessonPagesToAdd: [],
      lessonPagesToUpdate: [],
      pageManifestsToUpdate: [],
      lessonPagesToDelete: [],
      // lessonAssetsToAdd: [],
      lessonAssetsToRemove: [],
      lessonMeta: {
        ...Props.lessonMeta,
        ...lessonAircraftData,
        additionalSettings: {
          ...Props.lessonMeta.additionalSettings,
          forceAssetMetaCompletion: false,
        },
      },
    };
    const response = await genericRepositoryService.updateLesson(updateRequest);
    return response;
  };

  const handleShareAssets = () => {
    setShareAllAssets((prevState) => !prevState);
  };
  const handleSelectAsset = (assetId: number) => {
    setSelectedSingleAsset(assets.find((asset) => asset.assetVersionId === assetId));
    setAssets((prevAssets) =>
      prevAssets.map((asset) => {
        return asset.assetVersionId === assetId ? { ...asset, isSelected: !asset.isSelected } : asset;
      }),
    );
    if (selectedAssetCount > 1) {
      setSelectedSingleAsset(undefined);
    }
    handleSelectMetadata();
  };

  const handleSelectMetadata = () => {
    setAssets((prevAssets) =>
      prevAssets.map((asset) => {
        return asset.isSelected ? { ...asset, assetMetadata: { ...selectedMetadata } } : asset;
      }),
    );
  };

  const handleUpdateDescription = useCallback((assetId: number, description: string) => {
    setAssets((prevAssets) =>
      prevAssets.map((asset) => {
        return asset.assetVersionId === assetId ? { ...asset, description: description } : asset;
      }),
    );
  }, []);

  const countSelectedImages = (assets: Asset[]) => {
    setSelectedAssetCount(assets.map((asset) => asset.isSelected).filter(Boolean).length);
  };

  const handleSelectAllAssets = (isChecked: boolean) => {
    assets.forEach((asset) => (asset.isSelected = !isChecked));
    handleSelectMetadata();
    setSelectedSingleAsset(undefined);
    countSelectedImages(assets);
    setAllAssetsSelected((prevState) => !prevState);
  };

  const handleSubmitAssets = async () => {
    if (!assets.some((asset) => asset.isSelected)) return;
    if (assets.some((asset) => asset.isSelected && !validateMetadata(asset))) return;
    setSaving(true);
    const selectedAssets = assets.filter((asset) => asset.isSelected);
    const responseArray = await Promise.all(
      selectedAssets.map((asset) => updateAsset(asset, shareAssets, state.defaultTags)),
    );
    countSelectedImages(assets);
    if (allAssetsSelected) {
      setSubmittedThroughSelectAll(() => !submittedThroughSelectAll);
      setAllAssetsSelected(false);
    }
    setSubmittedAtLeastOnce(true);
    // these are the asset Ids that are to be removed from the the asset array
    setUpdatedAssetIds(
      responseArray.filter((response) => response.isSuccess).map((response) => response.data.asset.assetVersionId),
    );
  };

  useEffect(() => {
    setIsValidMetadata(isValidMetadata(state.selectedMetadata));
    countSelectedImages(assets);
  }, [state]);

  useEffect(() => {
    countSelectedImages(assets);
  }, [assets]);

  useEffect(() => {
    const getIncompleteAssets = async () => {
      const request: GetIncompleteAssetsRequest = {
        incomplete: true,
        currentLesson: [],
        manufacturerId: [],
        aircraftFamilyId: [],
        aircraftId: [],
        configurationId: [],
        keywords: [],
        assetTypeIds: [4, 8],
        page: 0,
        recentAssets: false,
        lessonVersionId: Props.lessonMeta.lessonVersionId ?? undefined,
        excludeLibraryItems: false,
      };
      const config: AxiosRequestConfig = {};
      try {
        const response = await genericRepositoryService.getFilteredAssets(request, config);
        const { data } = response;
        if (response.isSuccess) {
          if (data.assets.length === 0) {
            setSubmittedAtLeastOnce(true);
            console.info("empty asset response, no assets to complete");
            const response = await removeForceCompletion();
            if (response && response.isSuccess) {
              document.getElementById("frontportal")!.setAttribute("style", "display: none ");
            }
          } else {
            setAssets(data.assets);
          }
        } else {
          alert("Error loading assets. Please try again later");
          return;
        }
      } catch (e) {
        alert("Error loading assets. Please try again later");
        return;
      }
    };
    getIncompleteAssets();
  }, []);

  useEffect(() => {
    const removeAssetcompletionModal = async (retries: number): Promise<void> => {
      if (retries === 0) {
        alert("There was an issue processing your request. Please try again later");
        document.getElementById("frontportal")!.setAttribute("style", "display: none ");
        return;
      }
      setSaving(true);
      console.info("no more assets to complete");
      const response = await removeForceCompletion();
      if (response?.isSuccess) {
        window.location.reload();
      } else {
        return await removeAssetcompletionModal(--retries);
      }
      setSaving(false);
    };
    const reloadPage = async () => {
      if (assets.length === 0 && submittedAtLeastOnce) {
        await removeAssetcompletionModal(5);
      }
    };
    reloadPage();
  }, [assets]);

  useEffect(() => {
    handleSelectMetadata();
  }, [state.selectedMetadata]);

  useEffect(() => {
    document.getElementById("frontportal")!.setAttribute("style", "display: ");
  }, []);

  useEffect(() => {
    if (updatedAssetIds.length > 0) {
      setAssets(_.remove(assets, (asset) => !updatedAssetIds.includes(asset.assetVersionId)));
    }
  }, [updatedAssetIds]);

  useEffect(() => {
    setValidAssetDescription(!assets.some((asset) => asset.isSelected && asset.description.length === 0));
  }, [assets]);

  return (
    <ImageMetadataEditorContainer>
      {showImagePreview && imagePreview && (
        <AssetPreview imageURL={imagePreview.assetURL} assetType={imagePreview.assetType} />
      )}
      {saving && <SavingModal />}
      <Header
        selectedImages={selectedAssetCount}
        handleSelectAll={handleSelectAllAssets}
        submitted={submittedThroughSelectAll}
        numberOfAssets={assets.length}
        shareAssets={shareAssets}
        handleShareAssets={handleShareAssets}
        isCpat={isCPaTUser}
      />
      <MetadataModalBody>
        <ImagesContainer>
          {assets?.map((asset) => (
            <SingleAssetContainer
              key={asset.assetVersionId}
              asset={asset}
              handleSelectAsset={handleSelectAsset}
              handleUpdateDescription={handleUpdateDescription}
              allAssetsSelected={allAssetsSelected}
            />
          ))}
        </ImagesContainer>
        <Sidebar
          selectedAsset={selectedSingleAsset}
          showAssetPreview={selectedAssetCount === 1}
          isCpat={isCPaTUser}
          validSubmission={validMetadata}
        >
          <TagData />
        </Sidebar>
      </MetadataModalBody>
      <FooterSection
        validSubmission={validMetadata && selectedAssetCount > 0} // && validAssetDescription}
        handleSubmit={handleSubmitAssets}
        setSaving={setSaving}
      />
    </ImageMetadataEditorContainer>
  );
};

export default MetadataEditor;
