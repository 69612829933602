import { CSSProperties, useState } from "react";
import styles from "../AdvancedPanel.module.css";
import { ReactComponent as Arrow } from "../../../../../../assets/icons/arrow-down-white.svg";

export function Collapse({ title, children }: { title: string; children: React.ReactNode }) {
  const [open, setOpen] = useState(false);
  const arrowStyles = {
    transform: open ? "rotate(180deg)" : "",
    transition: "all 150ms ease-out",
  } as CSSProperties;

  const childrenStyles = {
    display: open ? "block" : "none",
  };

  return (
    <div className={styles.collapse}>
      <div className={styles.collapseHeader}>
        <div className={styles.title}>{title}</div>
        <button className={styles.collapseButton} onClick={() => setOpen((v) => !v)}>
          <Arrow style={arrowStyles} />
        </button>
      </div>
      <div style={childrenStyles}>{children}</div>
    </div>
  );
}
