import "./BaseTitlePage.css";
import _ from "lodash";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { IPageContext, PageContext, IAssetManagementContext, AssetContext } from "../../routes/builderContexts";
import blobHelper from "../../components/blobUrlFormatHelper";
import ILessonMetaData from "../../models/ILessonMetaRequest";
import BaseTitleEditable from "./components/BaseTitleEditable";
import BaseTitleNonEditable from "./components/BaseTitleNonEditable";
import { IDandPath } from "../../services/Utils/PageTypeCrawler/PageTypeCrawler";
import { IBasicPageImageAttributes, ITitlePageAttributes } from "../BasicPage_Player/components/IBasePage";

export interface ITitlePageAsset {
  imagePath: string;
  assetVersionId: number;
  parentVersionId: number;
}

export interface ITitlePageLogoAsset {
  imagePath: string;
  assetVersionId: number;
  attributes: ITitlePageAttributes;
}

export interface ITitlePageManifest {
  [key: string]: string | ITitlePageAsset | ITitlePageLogoAsset;
  comment: string;
  AirplaneOverlay: ITitlePageAsset;
  Background: ITitlePageAsset;
  BrandedLogo: ITitlePageLogoAsset;
}

type BaseProps = {
  key: number;
  lessonMetaData: ILessonMetaData;
  lessonName: string;
  permeateLessonName: (data: string) => void;
  setLessonMetaData: (data: ILessonMetaData) => void;
};

const BaseTitlePage: React.FC<BaseProps> = (props: BaseProps) => {
  const assetContext: IAssetManagementContext = useContext<IAssetManagementContext>(AssetContext);
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const updatedManifest: any = _.cloneDeep(pageContext.pageManifest);

  const owningLMS: number = useSelector(({ authorizedState }: any) => authorizedState.lmsKey);
  const isCPaTUser: boolean = useSelector(({ authorizedState }: any) => authorizedState.isCpatUser);

  // const [newLessonName, setNewLessonName]: [string, React.Dispatch<React.SetStateAction<string>>] = useState<string>("")
  const airplaneoverlay = blobHelper(updatedManifest.AirplaneOverlay.imagePath)
    ? blobHelper(updatedManifest.AirplaneOverlay.imagePath)
    : blobHelper("cpat/generic/images/cpatbrandedairplane.png");
  const logo = blobHelper(updatedManifest.BrandedLogo.imagePath)
    ? blobHelper(updatedManifest.BrandedLogo.imagePath)
    : blobHelper("cpat/generic/images/cpatlogo.png");
  const bg = blobHelper(updatedManifest.Background.imagePath)
    ? blobHelper(updatedManifest.Background.imagePath)
    : blobHelper("cpat/generic/images/abovecloudlayertitlepage.png");
  //const holders = props.lessonMetaData.additionalCopyrightHolders

  const handleImageClick = (clickedImage: number) => {
    assetContext.setAssetIndex(clickedImage);
    assetContext.setAssetTypeId(4);
    // assetContext.showBlobUploader(true);
    assetContext.setAssetManagerController({
      isOpen: true,
      mode: "ImagesOnly",
    });
  };

  if (props.lessonMetaData === undefined) {
    return null;
  } else {
    /*if((holders && holders.length > 0 && _.find(holders, holder => {return holder.lmsKey === owningLMS})) || isCPaTUser) { */
    return (
      <BaseTitleEditable
        airplaneoverlay={airplaneoverlay}
        background={bg}
        isCPaTUser={isCPaTUser}
        logo={logo}
        lessonMetaData={props.lessonMetaData}
        lessonName={props.lessonName}
        handleImageClick={handleImageClick}
        permeateLessonName={props.permeateLessonName}
      />
    );
  } /*else {
    return (
      <BaseTitleNonEditable
        airplaneoverlay={airplaneoverlay}
        background={bg}
        logo={logo}
        lessonMetaData={props.lessonMetaData}
        lessonName={props.lessonName}
        permeateLessonName={props.permeateLessonName}
      />
    )
  }*/
};

export function TitlePageManifestAssetCollector(pageManifest: ITitlePageManifest) {
  const assets: IDandPath[] = [];
  for (const [key, value] of Object.entries(pageManifest)) {
    if (value && typeof value !== "string") {
      const { assetVersionId, imagePath } = value;
      const temp = new IDandPath(assetVersionId, imagePath);
      assets.push(temp);
    }
  }
  return assets;
}

export default BaseTitlePage;
