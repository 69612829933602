import { useEffect } from "react";
import { TextPanel } from "..";
import { useMetaVariableStore } from "../../../../store";
import styles from "../AdvancedPanel.module.css";
import { useAdvancedPanelTimeline } from "../useAdvancedPanelTimeline";
import { TimelineButton } from "./TimeLineButton";
import { useObjectsState } from "../../../../../../contexts/ObjectsProvider";
import { useTimeline } from "../../../../../../contexts/TimelineProvider/TimelineProvider";
import { Collapse } from "./Collapse";

interface TextProps {
  data: TextPanel;
  onChange: () => void;
}

export function Text({ data, onChange }: TextProps) {
  const value = useMetaVariableStore((s) => s.metaVariables[data.key]);
  const hide = useMetaVariableStore((s) => s.metaVariablesData[data.key].hide);
  const setMetadata = useMetaVariableStore((s) => s.setMetaData);
  const set = useMetaVariableStore((s) => s.setMetaVariable);
  const upsertAnimatedMetaVariable = useMetaVariableStore((s) => s.upsertAnimatedMetaVariable);
  const [tl] = useTimeline();
  const currentTime = tl?.scrubbingCurrentTime;
  const selectedObjectId = useObjectsState().selectedObjects[0]?.objectId;
  const { isObjectInTimeline, handleTimelineClick, metaVariableInCurrentTime } = useAdvancedPanelTimeline(data.key);

  const onTimelineClick = () => {
    handleTimelineClick(value);
    onChange();
  };

  const handleHideClick = () => {
    setMetadata(data.key, "hide", !hide);
    onChange();
  };

  const handleChange = (key: string, value: any) => {
    set(key, value);
    if (isObjectInTimeline) {
      upsertAnimatedMetaVariable(key, currentTime, value, selectedObjectId);
    }
    onChange();
  };

  return (
    <Collapse title={data.name}>
      <div className={styles.group} key={data.name}>
        <div className={styles.timelineWrapper}>
          <div className={styles.selectWrapper}>
            <div className={styles.textGroup}>
              Text:
              <input
                type="text"
                value={value as string}
                onChange={(e) => {
                  handleChange(data.key, e.target.value);
                }}
              />
            </div>
            <div className={styles.checkboxWrapper}>
              <input type="checkbox" checked={!hide} onChange={handleHideClick} />
              <p>Hide</p>
            </div>
          </div>
          {isObjectInTimeline && <TimelineButton onClick={onTimelineClick} active={!!metaVariableInCurrentTime} />}
        </div>
      </div>
    </Collapse>
  );
}
