import React from "react";
import BaseTextOnlyCenter from "./BaseTextOnlyCenter";
import { IRTEModule } from "../BasePageDesigner";
import { RangeStatic } from "quill";

type BaseProps = {
  moduleRTE: IRTEModule[];
  quillRef: React.RefObject<any>;
  placeholderText: string;
  textBlockIndex: number;
  updatedManifest: any;
  assignNewText: (newText: string) => void;
  handleSelectionChange: (range: RangeStatic) => void;
};

const BaseIntroduction: React.FC<BaseProps> = (props: BaseProps) => {
  return (
    <BaseTextOnlyCenter
      moduleRTE={props.moduleRTE}
      quillRef={props.quillRef}
      placeholderText={props.placeholderText}
      textBlockIndex={props.textBlockIndex}
      updatedManifest={props.updatedManifest}
      assignNewText={props.assignNewText}
      handleSelectionChange={props.handleSelectionChange}
    />
  );
};

export default BaseIntroduction;
