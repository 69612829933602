import React from "react";
import { AnnotationPropType } from "../AnnotationThumbnail";
import { ISquaredNumber } from "../models/ISquaredNumber";
// import { IAttributes } from '../models/IAttributes';
import { ISquaredLetter } from "../models/ISquaredLetter";

// type SquareType = {
//   letters: ISquaredLetter[];
//   numbers: ISquaredNumber[];
// }

const AnnotationSquareNumber = (props: AnnotationPropType) => {
  const { annotation, index, count } = props;

  const display = () => {
    // return (
    //   annotations.stepIndicators.square.map((sq: SquareType, index: number) => {
    //     return (
    //       sq.numbers.map((number: ISquaredNumber, index: number) => {
    //         const attributes = number.attributes;
    return (
      <svg
        viewBox="0 0 100 100"
        key={index}
        className="annotation"
        style={{
          top: `${annotation.top}%`,
          left: `${annotation.left}%`,
          width: `${annotation.width}%`,
          height: `${annotation.height}%`,
          zIndex: annotation.zIndex,
          transform: `rotate(${annotation?.transform?.rotate}deg)`,
        }}
      >
        <rect
          x={`${1.5}`}
          y={`${1.5}`}
          stroke={`${annotation.borderColor}`}
          height="97"
          width="97"
          strokeWidth={`${annotation.strokeWidth || 4}`}
          fill={`${annotation.backgroundColor}`}
        ></rect>
        <text
          // style={{transform: `translate(${-2 * ratio}px, ${-1 * ratio}px)`}}
          className="step-text"
          x="50"
          y="50"
          dy="26.5"
          dx="0.0em"
          textAnchor="middle"
          fill={`${annotation.fontColor}`}
        >
          {count}
        </text>
      </svg>
    );
    //       })
    //     )
    //   })
    // )
  };

  return <React.Fragment>{display()}</React.Fragment>;
};

export default AnnotationSquareNumber;
