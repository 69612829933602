import { AudioOptionsPopover } from "./AudioOptionsPopover";
import { Whisper } from "rsuite";
import React, { useRef } from "react";
import type { OverlayTriggerHandle } from "rsuite/Picker";
import { ReactComponent as AddIcon } from "../../../../assets/icons/Common/add-icon.svg";
import { AudioPopoverAction } from "./types";
import classes from "../../../../utils/HTML/classes";

export interface AddAudioButtonProps {
  handleAddAudio: (action: AudioPopoverAction, close: () => void) => void;
  disabled?: boolean;
}
export const AddAudioButton = ({ handleAddAudio, disabled = false }: AddAudioButtonProps) => {
  const popOverRef = useRef<OverlayTriggerHandle>(null);

  return (
    <Whisper
      controlId="add-audio-from-narration"
      placement="bottom"
      trigger="click"
      ref={popOverRef}
      speaker={
        <AudioOptionsPopover onSelect={(action) => handleAddAudio(action, () => popOverRef?.current?.close())} />
      }
    >
      <button
        className={classes("add-button cpat-button cpat-button--icon", {
          "cpat-button--ghost": disabled,
        })}
        title="Add Audio"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <AddIcon />
      </button>
    </Whisper>
  );
};
