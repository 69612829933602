import "../fmsDesigner.css";
import React, { useContext, useEffect, useState } from "react";
import {
  IPageContext,
  PageContext,
  LessonPageActionsContext,
  ILessonPageActionsContext,
} from "../../../routes/builderContexts";
import { IFmsPageManifest } from "../Interfaces/IFmsPageManifest";
import _, { sortedLastIndex, before, forEach, initial, update } from "lodash";
import { IFmsContext, FmsContext } from "../Interfaces/fmsContext";
import CduCursorSelector from "./CduCursorSelector";

const CursorSelectOptions: React.FC = () => {
  const pagesContext: IPageContext = useContext<IPageContext>(PageContext);
  const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);
  const pageManifest: IFmsPageManifest = pagesContext.pageManifest as IFmsPageManifest;
  const lessonPageActionsContext: ILessonPageActionsContext =
    useContext<ILessonPageActionsContext>(LessonPageActionsContext);
  const pageTemplates = lessonPageActionsContext.fmsObject.cduObject.pageTemplates;

  useEffect(() => {}, []);

  const DisplayCDUCursor = () => {
    const setCursorDisplay = (event: React.MouseEvent) => {
      if (pageManifest.pageLayouts.cursorKey) {
        pageManifest.pageLayouts.cursorKey = pageManifest.pageLayouts.cursorKey === "" ? "fms1" : "";
      } else {
        pageManifest.pageLayouts["cursorKey"] = "fms1";
      }
      fmsContext.setCursorSelected(false);
      pagesContext.updatePageManifest(pageManifest);
    };
    return pageManifest.cdu.includes("a220") || pageManifest.cdu.includes("a350") ? (
      <>
        <label
          className="instructions page-opbjective-label edit-menu-label screen-adjust-objective-label"
          style={{ maxWidth: "500px", width: "45%", marginRight: "30%" }}
        >
          Show Cursor:
          {/* <div onClick={setCursorDisplay}> */}
          <div style={{ width: "10rem", display: "grid" }}>
            <input
              onClick={setCursorDisplay}
              className="pageTemplateCheckbox"
              // style={{
              //   backgroundColor: 'rgba(0,0,0,0)',
              //   marginTop: '5px',
              //   marginLeft: '15px',
              // }}
              type="checkBox"
              checked={pageManifest.pageLayouts.cursorKey !== ""}
            ></input>
          </div>
          {/* </div> */}
        </label>
        <CduCursorSelector />
      </>
    ) : (
      <></>
    );
  };

  return <React.Fragment>{DisplayCDUCursor()}</React.Fragment>;
};

export default CursorSelectOptions;
