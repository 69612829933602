import * as uploaderFunctions from "../components/AssetManager/AssetManagerUtils/UploaderFunctions";
import { useCallback, useState } from "react";
import { CurrentSelectedAsset } from "../components/AssetManager/AssetManager.types";

const AUDIO_MIMETYPE = "audio/mp3";
const AUDIO_UPLOAD_TYPE = 5;
export const useAudioUpload = () => {
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState<CurrentSelectedAsset | null>(null);
  const upload = useCallback(async (file: File | null) => {
    const defaultLessonData = {
      aircraftPlacementIds: null, //keep null
      ataIds: null, //not needed just keep null
      aircraftId: null, //keep null
      manufacturerId: null,
      versionName: "",
      aircraftFamilyId: null,
      tailoredForLmsKey: null, //from redux
      uploadForLmsKey: null,
    };

    if (file) {
      setUploading(true);

      const fileBuffer = await file.arrayBuffer();
      const fileBufferCopy = fileBuffer.slice(0);
      const payload = new File([fileBufferCopy], file.name, { type: AUDIO_MIMETYPE });

      // NOTE: We should add fileTypes enum, since I couldn't find any concrete evidence of all types I avoided converting it RN
      // type 5 is Audio btw
      const uploadResult = await uploaderFunctions.fileUploadProcess(payload, defaultLessonData, AUDIO_UPLOAD_TYPE);
      // If upload was successful
      if (uploadResult?.data?.blobPath) {
        setFile(uploadResult.data);
        setUploading(false);

        return uploadResult.data;
      } else {
        setFile(null);
        setUploading(false);

        return null;
      }
    }
  }, []);

  return {
    upload,
    uploading,
    file,
  };
};
