import React, { useContext, useState, useEffect, useRef } from "react";
import "./Header.css";
import {
  ILessonManagementContext,
  LessonManagementContext,
  ILessonPageActionsContext,
  LessonPageActionsContext,
} from "../routes/builderContexts";
import LessonPreview from "./LessonPreview";
import HeaderAnnouncer from "./HeaderAnnouncer/HeaderAnnouncer";
import { useLessonData } from "../contexts/LessonDataProvider";
import { connect } from "react-redux";
import ILessonPageDto from "../models/ILessonPageDto";
import { useMiscUI } from "../contexts/MiscUI/MiscUIProvider";
import { ReactComponent as AddPageIcon } from "../assets/icons/HeaderIcons/add-page-icon.svg";
import { ReactComponent as RemovePageIcon } from "../assets/icons/Common/remove-page-icon.svg";
import { ReactComponent as CopyPageIcon } from "../assets/icons/HeaderIcons/copy-page-icon.svg";
import { ReactComponent as SavePageIcon } from "../assets/icons/HeaderIcons/save-page-icon.svg";
import { ReactComponent as SettingsLessonIcon } from "../assets/icons/HeaderIcons/settings-lesson-icon.svg";
import { ReactComponent as SavingSpinner } from "../assets/icons/HeaderIcons/loading-sequence-airplane-icon.svg";
import { ReactComponent as AssetManagerIcon } from "../assets/icons/HeaderIcons/asset-manager-with-background-icon.svg";
import { ReactComponent as InventLogo } from "../assets/Logos/invent-logo-white.svg";
import CopyPageFromLesson from "./Modals/CopyPageFromLesson/CopyPageFromLesson";
import useSettingsToggle, { Settings, settingType } from "../hooks/useSettingsToggle";
import { AbstractTooltip as Tooltip } from "./ToolTip/ToolTip";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../contexts/ObjectsProvider";
import {
  useInteracitvityHotspotDispatch,
  InteractivityHotspotActionTypes,
} from "../contexts/InteractivityHotspotProvider";

interface IButtonsContext {
  dirt: number;
}

export const ButtonsContext: React.Context<IButtonsContext> = React.createContext<IButtonsContext>(
  {} as IButtonsContext,
);

const AddPageButton: React.FC = () => {
  const lessonContext: ILessonManagementContext = useContext<ILessonManagementContext>(LessonManagementContext);
  return (
    <div className="icons-wrapper" data-tooltip-id="addPage">
      <div className="header-icon">
        <AddPageIcon onClick={() => lessonContext.setSelectorModalInfo("Add Page")} name="Add Page" />
      </div>
      <p>Add Page</p>
      <Tooltip id="addPage" />
    </div>
  );
};

const SaveTimer = ({ reset, unsavedChanges }: { reset: boolean; unsavedChanges: boolean }) => {
  const [counter, setCounter] = useState<number>(0);
  useEffect(() => {
    const runner = setInterval(() => {
      setCounter((count: number) => {
        count++;
        return count;
      });
    }, 1000);

    return () => {
      setCounter(0);
      clearInterval(runner);
    };
  }, [reset, unsavedChanges]);
  let output = "Saved";
  if (counter <= 60 && unsavedChanges) output = "Less than one min";
  else if (counter >= 3600 && unsavedChanges) output = "Greater than 60 min";
  else if (counter > 10 && unsavedChanges) output = `${Math.round(counter / 60)} min`;

  return <p>{output}</p>;
};

const SaveButton: React.FC = () => {
  const lessonManagementContext: ILessonManagementContext =
    useContext<ILessonManagementContext>(LessonManagementContext);
  const objectsDispatch = useObjectsDispatch();
  const lessonPageActions: ILessonPageActionsContext = useContext<ILessonPageActionsContext>(LessonPageActionsContext);
  const interacitvityHotspotDispatch = useInteracitvityHotspotDispatch();
  const { needSave } = useObjectsState();
  const { dirt, setDirt } = lessonPageActions;
  const dirtyPages = lessonManagementContext.lessonPages.filter((page: ILessonPageDto) => {
    return page.lessonPageIsDirty || page.areAssetsDirty || page.pageManifestIsDirty || page.isClientSideNewPage;
  });
  if (dirtyPages.length > 0) {
    dirt !== 5 && setDirt(5);
  } else {
    dirt !== 0 && setDirt(0);
  }

  const saveClick = () => {
    lessonManagementContext.saveLesson(lessonManagementContext.lessonPages);
    objectsDispatch({ type: ObjectActionsType.CLEAR_NEED_SAVE });
    interacitvityHotspotDispatch({ type: InteractivityHotspotActionTypes.CLEAR_NEED_SAVE });
  };

  const isDirty = needSave || lessonPageActions.dirt !== 0;

  return (
    <div className="icons-wrapper" data-tooltip-id="saveLesson">
      <Tooltip id="saveLesson" />
      <div className="header-icon">
        {lessonManagementContext.isSaved ? (
          <SavePageIcon onClick={saveClick} />
        ) : (
          <div className="spinner-container">
            <SavingSpinner className="saving-spinner" />
          </div>
        )}
      </div>
      <SaveTimer reset={lessonManagementContext.isSaved} unsavedChanges={isDirty} />
    </div>
  );
};

const RemovePageButton: React.FC = () => {
  const lessonManagementContext: ILessonManagementContext =
    useContext<ILessonManagementContext>(LessonManagementContext);
  const [disabledButton, setDisabledButton] = useState(true);

  useEffect(() => {
    setDisabledButton(lessonManagementContext.pageIndexIsSafeToDelete);
  }, [lessonManagementContext.currentlyDisplayedPageIndex]);

  const tooltipId = "Remove Page";
  return (
    <div className="icons-wrapper" data-tooltip-id={tooltipId}>
      <Tooltip id={tooltipId} />
      <div className="header-icon">
        <RemovePageIcon
          className={!disabledButton ? "disabled-header-button" : "header-button"}
          onClick={lessonManagementContext.deletePage}
        />
      </div>
      <p>Remove Page</p>
    </div>
  );
};

interface IDesignerHeader {
  lessonName?: string;
  lessonVersionId?: number;
  lessonVersionDisplayableId: number;
  lmsName?: string;
  setLessonSettingsController: (options: { isOpen: boolean }) => void;
  setCoursewareDeveloperToolsController?: any;
  setAssetTypeId(id: number): void;
  isCpatUser?: boolean;
  changePageOrder?: (newOrderLessonPages: ILessonPageDto[], pageToGoTo?: number) => void;
}

const CopyPage: React.FC = () => {
  const lessonManagementContext: ILessonManagementContext =
    useContext<ILessonManagementContext>(LessonManagementContext);
  const { copyPage, currentlyDisplayedPageIndex, lessonPages } = lessonManagementContext;
  const [miscUIState, miscUIDispatch] = useMiscUI();
  const permissions = useLessonData({ permissions: true });
  const page = lessonPages[currentlyDisplayedPageIndex];

  const canCopy =
    currentlyDisplayedPageIndex >= 4 &&
    currentlyDisplayedPageIndex <= lessonPages.length - 4 &&
    permissions.canEdit &&
    permissions.canEdit(page.pagePlayerType) &&
    permissions.canCreate(page.pagePlayerType);

  const handleOp = async () => {
    if (canCopy) {
      // let updatedLessonPages = await saveLesson(lessonPages);
      //the last paramater will overwrite the lesson pages used before this block is called so we may have the frsh updated pages (updatedLessonPages) that the save lesson modified
      miscUIDispatch({ type: "TOGGLE_COPYING" });
      // addNewPageAndDisplay((currentlyDisplayedPageIndex + 1), page.pagePlayerType, page.name, page.pageVersionId, updatedLessonPages )
      await copyPage(currentlyDisplayedPageIndex + 1, lessonPages as ILessonPageDto[]); // await does have effect here
      miscUIDispatch({ type: "TOGGLE_COPYING" });
    } else {
      console.warn("cant copy the page");
    }
  };
  return (
    <div className="icons-wrapper" data-tooltip-id="Copy Page">
      <Tooltip id="Copy Page" />
      <div className="header-icon">
        {miscUIState.isCopying ? (
          <div className="spinner-container">
            <SavingSpinner className="saving-spinner" />
          </div>
        ) : (
          <CopyPageIcon
            className={`${canCopy ? "header-button" : "disabled-header-button"}`}
            onClick={() => {
              handleOp();
            }}
            name="CopyPage"
          />
        )}
      </div>
      <p>Copy Page</p>
    </div>
  );
};

const renderSettings: Settings = {
  copyPagesFromLesson: settingType.cpatonly,
};

const DesignerHeader: React.FC<IDesignerHeader> = (props: IDesignerHeader) => {
  const lessonManagementContext: ILessonManagementContext =
    useContext<ILessonManagementContext>(LessonManagementContext);
  const [featuresToRender] = useSettingsToggle(renderSettings);
  const { copyPagesFromLesson } = featuresToRender;
  const [blobLink] = useState("");

  const LessonSettingsButton: React.FC = () => {
    return (
      <div className="icons-wrapper" data-tooltip-id="Settings">
        <div className="header-icon">
          <SettingsLessonIcon
            className="header-button"
            onMouseDown={(e) => {
              e.stopPropagation();
            }}
            onClick={() => {
              props.setAssetTypeId(-1);
              props.setLessonSettingsController({ isOpen: true });
            }}
          />
        </div>
        <p>Settings</p>
        <Tooltip id="Settings" />
      </div>
    );
  };

  const showBlobDownload = () => {
    if (blobLink && blobLink.length > 0) {
      return (
        <div id="downloadSCOLinkContainer">
          <a id="downloadSCOLink" href={blobLink}>
            {/* {FilePart(blobLink)} */}
            Download Export
          </a>
        </div>
      );
    }
  };

  const AssetManagerButton = () => {
    return (
      <div className="icons-wrapper" data-tooltip-id="Asset Manager">
        <Tooltip id="Asset Manager" />
        <div
          className="header-icon assetManager-icon"
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          onClick={() => {
            lessonManagementContext.setAssetManagerController({
              isOpen: true,
              mode: "Standalone",
            });
            props.setAssetTypeId(-1);
          }}
        >
          <AssetManagerIcon />
          <p>Asset Library Manager</p>
        </div>
        <p style={{ color: "var(--icon-header-color)", userSelect: "none" }}>AML</p>
      </div>
    );
  };

  function DeveloperToolsButton({ isCpatUser }: { isCpatUser: boolean }) {
    return isCpatUser || copyPagesFromLesson ? (
      <CopyPageFromLesson
        CurrentLessonVersionId={props.lessonVersionId as number}
        ChangePageOrder={props.changePageOrder}
      />
    ) : null;
  }

  return (
    <div>
      <div className="designer-header">
        <div className="headerLV">
          <InventLogo height="100%" width="100%" />
        </div>
        <div className="branding">{props && props.lessonName ? `${props.lessonName}` : ""}</div>

        {showBlobDownload()}

        <div className="headerRV-container">
          <div className="headerRV">
            <p>
              <strong>LMS:</strong>
              <span>({props.lessonVersionId})</span>
              {props.lmsName}
            </p>
          </div>
        </div>
      </div>

      <div className="iconheader">
        <div className="buttons-left">
          <AddPageButton />
          <CopyPage />
          <RemovePageButton />
        </div>
        <div className="developer-tools-portal">
          <DeveloperToolsButton isCpatUser={!!props?.isCpatUser} />
        </div>
        <div id="lesson-version-display">
          <HeaderAnnouncer handleApiError={lessonManagementContext.handleApiError}>
            {lessonManagementContext.errorMessage}
          </HeaderAnnouncer>
        </div>
        <div className="buttons-right">
          <AssetManagerButton />
          <LessonPreview lessonVersionId={props.lessonVersionId} />
          <SaveButton />
          <LessonSettingsButton />
        </div>
      </div>
    </div>
  );
};

const mapProps = (state: any) => ({
  isCpatUser: state.authorizedState.isCpatUser,
});

export default connect(mapProps, null)(DesignerHeader);
