import React, { useState, useEffect, useRef, Suspense } from "react";
import { connect } from "react-redux";
import Controls from "./controls";
import Enviroment from "./enviroment";
import Plane from "./plane";
import Skybox from "./skybox";
import HSR from "./rigger";
import IGA from "../../../components/IGA/IGA";
import { blankStep } from "../../../components/IGA/helpers/initialSteps";
import * as THREE from "three";
import { Canvas } from "@react-three/fiber";
import {
  setdisplayStyle,
  setCurtain,
} from "../../../store/actions/actions";
import {
  Sky,
} from "@react-three/drei"

// import MagicCover from "./loadingScreen"
import MagicCover from "../../Generic3DFiles/loadingScreen";
import SceneLights from "../../Generic3DFiles/lights"

const PageScene = (props) => {
  THREE.Cache.enabled = true;
  const [ActiveHotSpot, setActiveHotSpot] = useState(props.ActiveHotSpot);
  var modelPath = props.modelSetPath;
  let hsData = props.currentHotSpot;
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    hsData = props.currentHotSpot;
  }, [props.currentHotSpot]);

  const checkActiveHotSpot = (e) => {
    setActiveHotSpot(e);
    props.setdisplayStyle("block");
  };

  const handleIGAClose = () => {
    setActiveHotSpot(null);
    props.setdisplayStyle("");
  };

  const displayIGA = () => {
    let IGAObject = props.currentIGA;
    if (!IGAObject) {
      IGAObject = blankStep;
    }
    if (props.displayStyle === "block" && props.passedIGSObject !== "legacy") {
      return (
        <div className="absolute-container">
          <div id="procedures-iga-container">
            <IGA
              assetManagerProps={props.assetManagerProps}
              currentIGA={IGAObject}
              handleSave={props.handleSave}
              handleClose={handleIGAClose}
            />
            <div></div>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const [sceneWidth, setSceneWidth] = useState(99);
  const [skyLoaded, setSkyLoaded] = useState(false);
  const [gateLoaded, setGateLoaded] = useState(false);
  const [modelLoaded, setModelLoaded] = useState(false);

  const finishedLoadingModel = () => {
    setModelLoaded(true);
  };
  const finishedLoadingGate = () => {
    setGateLoaded(true);
  };
  const finishedLoadingSky = () => {
    setSkyLoaded(true);
  };
  const finishedLoading = () => {
    props.setCurtain(false);
    setSceneWidth(100);
    setIsLoaded(true);
  };

  useEffect(() => {
    if (props.reloadModel) {
    }
  }, [props.reloadModel]);

  useEffect(() => {
    if (
      //skyLoaded && 
      modelLoaded && 
      gateLoaded) {
      finishedLoading();
    }
    return () => {
      props.setCurtain(true);
    };
  }, [skyLoaded, modelLoaded, gateLoaded]);

  return (
    <>
      <MagicCover />
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: `${sceneWidth}%`,
          backgroundColor: `${props.displayStyle === "block" ? "#283D55" : "inherit"}`,
        }}
      >
        <Canvas
          onCreated={({ gl }) => {
            gl.useLegacyLights = true;
          }}
        >
          <Controls />
          <Suspense>
            <SceneLights env={"walkaround"} />
            <HSR
              isSphere={props.isSphere}
              hsData={hsData}
              checkActiveHotSpot={checkActiveHotSpot}
              ActiveHotSpot={ActiveHotSpot}
              modelSet={modelPath}
              proximityData={props.proximityData}
            />
            <Plane
              blobPath={props.blobPath}
              modelPath={modelPath}
              callBack={finishedLoadingModel}
            />
            <Enviroment callBack={finishedLoadingGate} />

            <Sky sunPosition={[0, 1, 0]} />
            {/* <Skybox callBack={finishedLoadingSky} /> */}

            {/* <fog attach="fog" args={['white', 50, 1000]} /> */}
          </Suspense>
        </Canvas>
      </div>
      {displayIGA()}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    displayStyle: state.Current3dPage.displayStyle,
    ActiveHotSpot: state.Current3dPage.Manifest.ActiveHotSpot,
  };
};

export default connect(mapStateToProps, {
  setdisplayStyle,
  setCurtain,
})(PageScene);
