import { useUIStore } from "../../contexts/MovableElementsPlaneProvider";

export const conversion = {
  secondToMillisecond(value: number): number {
    return value * 1000;
  },
  millisecondToNearestSecond(value: number): number {
    return Math.round(value / 1000);
  },
  millisecondsToQuarterSeconds(milliseconds: number): number {
    const seconds = milliseconds / 1000;
    const quarterSeconds = Math.round(seconds * 4) / 4;
    return quarterSeconds;
  },
};

export function percentageToValue(percentage: number, total: number): number {
  return (percentage / 100) * total;
}
export function xAxisViewPercentageToPixel(percentage: number) {
  const viewWidth = useUIStore.getState().designerViewportDims.width;
  return percentageToValue(percentage, viewWidth);
}
export function yAxisViewPercentageToPixel(percentage: number) {
  const viewHeight = useUIStore.getState().designerViewportDims.height;
  return percentageToValue(percentage, viewHeight);
}
export function valueToPercentage(value: number, total: number): number {
  return (value / total) * 100;
}
export function percentageToPX(percentage: number, total: number): number {
  return Math.round((percentage / 100) * total);
}

export function hexToRgb(hex: string) {
  // Remove the hash symbol if present

  hex = hex?.replace(/^#/, "");

  const r = parseInt(hex?.substr(0, 2), 16);
  const g = parseInt(hex?.substr(2, 2), 16);
  const b = parseInt(hex?.substr(4, 2), 16);

  // Return the RGB string
  return r + ", " + g + ", " + b;
}

export function handleTransparencyColor(color: string, opacity: number | undefined) {
  const isTransparent = color === "transparent" || color === "";
  if (isTransparent) {
    return color;
  }
  return `rgba(${color === "white" ? "255, 255, 255" : hexToRgb(color)}, ${opacity})`;
}

// Used for tables border string property
export function extractHexColor(str: string) {
  const hexColorRegex = /#[0-9a-fA-F]{1,6}/g;
  const match = str.match(hexColorRegex);
  return match ? match[0] : null;
}
