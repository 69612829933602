import React, { useContext, useState, useEffect } from "react";
import { Modal, ModalBody } from "react-bootstrap";
//import { BoundType } from 'react-moveable';
//import { IFmsPageManifest } from '../../../../../../pageTypes/FMS_Player/Interfaces/IFmsPageManifest';
import { IAssetManagementContext, AssetContext } from "../../../../../../routes/builderContexts";
import lodash from "lodash";
import AddReferenceImage from "../AddReferenceImage";
import blobHelper from "../../../../../blobUrlFormatHelper";
import WYSIWYG from "../../../../WYSIWYG";
import { modalComponentProps, removeIconProps } from "./interfaces/FmsModalInterfaces";

const RemoveIcon = (props: removeIconProps) => {
  return (
    <div
      id="remove-fms-reference-modal-btn"
      onClick={(e: any) => props.removeImage(e, props.assetVersionId, props.index)}
    >
      <span className="remove-fms-image-modal-button">Remove Image</span>
    </div>
  );
};
const ReferenceTabFMSModal = (props: modalComponentProps) => {
  const assetContext: IAssetManagementContext = useContext<IAssetManagementContext>(AssetContext);
  const [imageHighlighted, setImageHighlighted] = useState<boolean>(false);

  useEffect(() => {
    const handleClick = (e: any) => {
      if (props.imageRef.current && props.imageRef?.current?.contains(e.target)) {
        // inside click
        setImageHighlighted(true);
        return;
      }
      // outside click
      setImageHighlighted(false);
    };
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const triggerUploadModal = (e: React.MouseEvent<HTMLDivElement>, imageIndex: number) => {
    e.preventDefault();
    assetContext.setAssetIndex(imageIndex);
    assetContext.setAssetTypeId(4);
    // assetContext.showBlobUploader(true);
    assetContext.setAssetManagerController({
      isOpen: true,
      mode: "ImagesOnly",
    });
    props.setIsModalShown(false);
    props.setNewPageImageId(props.assetVersionId);
  };
  const handleImageClick = (e: React.MouseEvent<HTMLDivElement>, imageIndex: number) => {
    const highlightClone = lodash.cloneDeep(imageHighlighted);
    if (highlightClone) {
      triggerUploadModal(e, imageIndex);
    } else {
      setImageHighlighted(true);
    }
  };
  return (
    <Modal show={props.isModalShown} onHide={() => props.handleHide()} size="lg" className="fms-ref-img" centered>
      {/* <Modal show={isModalShown} onHide={() => setIsModalShown(false)} dialogClassName="fms-ref-img"> */}
      <Modal.Header>
        <span>Reference Image Annotations and Symbols</span>
        <span className="icon-remove-icon-white" onClick={() => props.setIsModalShown(false)}>
          <span className="path1"></span>
          <span className="path2"></span>
          <span className="path3"></span>
          <span className="path4"></span>
        </span>
      </Modal.Header>
      <ModalBody>
        <div className="fms-img-container ">
          <div className="img-aspect-container">
            <div className="img-aspect-container-inside">
              <div
                className={
                  props.imageSource.length === 0
                    ? "img-aspect-centering fms-img-aspect-centering upload-fms-img-aspect"
                    : "img-aspect-centering fms-img-aspect-centering"
                }
              >
                <WYSIWYG
                  isModalShown={props.isModalShown}
                  referenceBounds={props.referenceBounds}
                  referenceRect={props.pageDims}
                  handleLoad={props.handleLoad}
                >
                  {/* <div className="test1"> */}
                  {props.imageSource.length === 0 ? (
                    <AddReferenceImage isLargeCDU={false} triggerUploadModal={triggerUploadModal} />
                  ) : (
                    <div
                      id="ref-div"
                      ref={props.imageRef}
                      onDoubleClick={(e: React.MouseEvent<HTMLDivElement>) => {
                        triggerUploadModal(e, 0);
                      }}
                      onLoad={(e: React.SyntheticEvent<HTMLDivElement>) => props.handleLoad(e)}
                      // onDoubleClick={(e: React.MouseEvent<HTMLDivElement>) => triggerUploadModal(e, 0)}
                      style={{
                        borderColor: imageHighlighted ? "var(--cpat-green)" : "transparent",
                      }}
                    >
                      {/* <div > */}
                      <img src={blobHelper(props.imageSource)} style={{ maxHeight: "35em" }}></img>
                      {/* </div> */}
                    </div>
                  )}

                  {/* </div> */}
                </WYSIWYG>
              </div>
              {props.imageSource.length === 0 ? (
                <></>
              ) : (
                <RemoveIcon removeImage={props.removeImage} assetVersionId={props.assetVersionId} index={0} />
              )}
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default ReferenceTabFMSModal;
