import React from "react";
import Input from "rsuite/Input";
import InputGroup from "rsuite/InputGroup";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import "./input.css";
import "./inputgroup.css";
import "./SearchToolbar.css";
import { ReactComponent as Refresh } from "../../assets/icons/refresh.svg";

// List of properties for the Searchbar.
// HandleOnChange: This is an 'onChange' handler. This way the whatever component has added this component
// can do what they want with the search data
// ToolbarClassName: Allows the container to be customized
type SearchToolbarProps = {
  HandleOnChange: any;
  ToolbarClassName?: string;
  RefreshHandler: any;
  Title: any;
};

const SearchToolbar = (props: SearchToolbarProps) => {
  const { HandleOnChange, RefreshHandler, Title, ToolbarClassName = "search-container" } = props;

  return (
    <div className={ToolbarClassName}>
      <div className={"search-group"}>
        <div className={"search-label"}>{Title}</div>
        <div className={"input-button"}>
          <InputGroup inside>
            <Input
              classPrefix={"search"}
              onChange={(e) => {
                HandleOnChange(e as string);
              }}
            />
            <InputGroup.Addon>
              <Search className={"rs-icon"} width={"1em"} height={"1em"} viewBox={"0 0 16 16"} />
            </InputGroup.Addon>
          </InputGroup>
          <button className={"refresh-button"} onClick={RefreshHandler}>
            <Refresh />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchToolbar;
