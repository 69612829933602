import "../../pageTypes/BasicPage_Player/BasePageDesigner.css";
import _, { Function } from "lodash";
import React, { useState, useContext } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { pageSelectIconInfo, IPageSelectIcon } from "./pageSelectIconInfo";
import {
  IPageContext,
  PageContext,
  LessonPageActionsContext,
  ILessonPageActionsContext,
  AssetContext,
  IAssetManagementContext,
} from "../../routes/builderContexts";
import { BASIC_PAGE, FMS_PAGE, QUIZ_PAGE, ThreeD_PAGE, WALK_AROUND, CABIN } from "../constants/pageTypes";
import { basePageIconInfo, IBasePageIconType } from "./basePageIconInfo";
import { ReactComponent as ExitIcon } from "../../assets/icons/NewPageSelector/exit-icon-close.svg";
import { ReactComponent as GoBackIcon } from "../../assets/icons/NewPageSelector/go-back-icon.svg";
import { useLessonData } from "../../contexts/LessonDataProvider";
import useSettingsToggle, { settingType } from "../../hooks/useSettingsToggle";
import { setHsListerIsOpen } from "../../store/actions/actions";
import { AbstractTooltip as Tooltip } from "../ToolTip/ToolTip";
type ModalType = {
  show: boolean;
  onHide: () => void;
  isCpatUser?: boolean;
  setHsListerIsOpen: (val: boolean) => void;
};

const NewPageSelector: React.FC<ModalType> = (props: ModalType) => {
  // const assetContext: IAssetManagementContext = useContext<IAssetManagementContext>(AssetContext)
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const lessonPageActions: ILessonPageActionsContext = useContext<ILessonPageActionsContext>(LessonPageActionsContext);
  const [layoutShowing, setLayoutShowing]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] =
    useState<boolean>(false);
  const [selectedKey, setSelectedKey]: [number, React.Dispatch<React.SetStateAction<number>>] = useState<number>(-1);
  const [selectedBaseLayoutKey, setSelectedBaseLayoutKey]: [number, React.Dispatch<React.SetStateAction<number>>] =
    useState<number>(0);
  const [selectedType, setSelectedType]: [string, React.Dispatch<React.SetStateAction<string>>] = useState<string>("");
  // let selectedType: string;
  const permissions = useLessonData({ permissions: true });
  const [featuresToRender] = useSettingsToggle({
    cabinPages: settingType.cpatonly,
  });
  const { cabinPages } = featuresToRender;
  const hasModel = (pageType: string) => {
    switch (pageType) {
      case FMS_PAGE:
        return !(lessonPageActions.fmsCdus.length === 0);
      case ThreeD_PAGE:
        return !(lessonPageActions.threeDModels.length === 0);
      case WALK_AROUND:
        return !(lessonPageActions.walkaroundModels.length === 0);
      case CABIN:
        return !(lessonPageActions.cabinModels.length === 0) && cabinPages;
      default:
        return true;
    }
  };

  const cpatRestricted = (pageType: string) => {
    if (props.isCpatUser) {
      return false;
    }

    switch (pageType) {
      case WALK_AROUND:
      case CABIN:
        return true;
      default:
        return false;
    }
  };

  const pageRestriction = (pageType: string) => {
    return hasModel(pageType) && permissions.canCreate(pageType) && permissions.canEdit(pageType);
  };

  const displayIcons = () => {
    return pageSelectIconInfo
      .filter((el: IPageSelectIcon, index: number) => {
        // let check = false;
        // if (el.caption === '3D Page' && lessonPageActions.threeDModels.length === 0) check = true;
        // if (el.caption === 'FMS Page' && lessonPageActions.fmsCdus.length === 0 ) check = true;
        // if (el.pageType === 'Walkaround' && (props.isCpatUser === false || lessonPageActions.walkaroundModels.length === 0) ) check = true;
        // return !check
        return pageRestriction(el.pageType);
      })
      .map((pageType: IPageSelectIcon, key: number) => {
        return (
          <div className={`page-type-change-icon-container` + ` ${key === selectedKey ? "selected" : ""}`} key={key}>
            <figure onClick={() => handleIconClick(pageType.pageType, key)} data-tooltip-id={pageType.caption}>
              <Tooltip id={pageType.caption} />
              {pageType.icon && <pageType.icon />}
              <figcaption>
                <small>{pageType.caption}</small>
              </figcaption>
            </figure>
          </div>
        );
      });
  };

  // interface IBasePageIconType extends ISelectIconType {
  //   "basePageType": string;
  // }
  const displayBasePageLayout = () => {
    return basePageIconInfo.map((pageType: IBasePageIconType, key: number) => {
      return (
        <div
          className={`page-type-change-icon-container` + ` ${key === selectedBaseLayoutKey ? "selected" : ""}`}
          key={key}
        >
          <figure
            onClick={() => handleIconClick("Base", key, pageType.basePageType)}
            data-tooltip-id={pageType.caption}
          >
            <Tooltip id={pageType.caption} />
            {pageType.component && (
              <pageType.component
                style={pageType.basePageType === "doubleGraphicSplitRight" ? { transform: "scaleX(-1)" } : {}}
              />
            )}
            <figcaption>
              <small>{pageType.caption}</small>
            </figcaption>
          </figure>
        </div>
      );
    });
  };

  const handleIconClick = (pageType: string, key: number, basePageType?: string) => {
    setSelectedType(pageType);
    // selectedType = pageType;
    const pageName = providePageName(pageType);
    const pagePlacement: number = pageContext.currentlyDisplayedPageIndex + 1;
    if (pageName === "Cockpit Procedures" && !lessonPageActions.proceduresObject.assetVersionId) {
      props.onHide();
      pageContext.setProceduresSelectorBoolean(true);
      setSelectedKey(key);
    } else if (layoutShowing === true && basePageType) {
      pageContext.addNewPageAndDisplay(pagePlacement, pageType, pageName, undefined, undefined, basePageType);
      setSelectedBaseLayoutKey(key);
      props.onHide();
    } else if (pageName === "Base") {
      setSelectedKey(key);
      setLayoutShowing(true);
    } else if (pageName === "FMS") {
      props.onHide();
      lessonPageActions.showFMSSelector();
    } else if (pageName === "Walkaround" && !lessonPageActions.currentWalkAroundObject.assetVersionId) {
      props.onHide();
      lessonPageActions.setWalkaroundSelectorShowing(true);
    } else if (pageName === "Cabin" && !lessonPageActions.currentCabinObject.assetVersionId) {
      props.onHide();
      lessonPageActions.setCabinSelectorShowing(true);
    } else {
      setSelectedKey(key);
      if (pageType === "Cabin") {
        props.setHsListerIsOpen(true);
      }
      pageContext.addNewPageAndDisplay(pagePlacement, pageType, pageName);
      props.onHide();
    }
  };

  // const handleSelectClick = (): void => {
  //   const pageName = providePageName();
  //   const pagePlacement: number = pageContext.currentlyDisplayedPageIndex + 1;
  //
  //   if(pageName === 'ThreeDModel' && !lessonPageActions.proceduresObject.assetVersionId){
  //     props.onHide();
  //     pageContext.setProceduresSelectorBoolean(true);
  //   }
  //   else{
  //     pageContext.addNewPageAndDisplay(pagePlacement, selectedType, pageName);
  //     props.onHide();
  //   }

  // }

  const providePageName = (type: string) => {
    switch (type) {
      case BASIC_PAGE:
        return "Base";

      case FMS_PAGE:
        return "FMS";

      case QUIZ_PAGE:
        return "Quiz";

      case ThreeD_PAGE:
        return "Cockpit Procedures";

      case WALK_AROUND:
        return "Walkaround";

      case CABIN:
        return "Cabin";

      default:
        throw new Error("NO PAGES MATCHING THAT TYPE");
    }
  };

  return (
    <React.Fragment>
      <Modal show={props.show} onHide={props.onHide} dialogClassName="basic-page-modal-content-newPages">
        <Modal.Header>
          <div className="modal-header-newPage">
            {!layoutShowing ? <h2>Select a Page Type</h2> : <h2>Select a Layout</h2>}
            <ExitIcon
              onClick={() => {
                props.onHide();
              }}
            />
          </div>
        </Modal.Header>
        <Modal.Body className={!layoutShowing ? "page-type-change-modal-body" : "page-type-change-modal-body-scroll"}>
          {"canCreate" in permissions ? !layoutShowing ? displayIcons() : displayBasePageLayout() : <div>Loading</div>}
        </Modal.Body>
        <Modal.Footer className="button-align">
          <div>
            {!layoutShowing ? (
              <button className="layout-select-button main-modal-button" onClick={() => props.onHide()}>
                Cancel
              </button>
            ) : (
              <button className="layout-select-button-goBack main-modal-button" onClick={() => setLayoutShowing(false)}>
                <span>
                  <GoBackIcon />
                </span>
                Go Back
              </button>
            )}
          </div>
          <div className="page-type-small">
            <small>This will place a new page after the selected page</small>
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

const mapState = (state: any) => {
  return {
    isCpatUser: state.authorizedState.isCpatUser,
  };
};

export default connect(mapState, {
  setHsListerIsOpen,
})(NewPageSelector);
