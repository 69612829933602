import React from "react";
import { ReactComponent as AddIcon } from "../../assets/icons/Settings/add-new-tag.svg";
type ChoiceProps = {
  isMaxReached: boolean;
  addIcon: string;
  addChoice: () => void;
};

const QuizAddChoice: React.FC<ChoiceProps> = (props) => {
  if (!props.isMaxReached) {
    return <AddIcon className="add-choice-icon" onClick={() => props.addChoice()} />;
  } else {
    return null;
  }
};

export default QuizAddChoice;
