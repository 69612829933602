import styles from "../AdvancedPanel.module.css";
import { useMetaVariableStore } from "../../../../store";
import { useMemo } from "react";
import { getSmartObjectViewBoxMetaVariableKeys } from "../../../../utils";
import { FramedInput } from "../../../../../../panels/ObjectPropertiesPanel";
import { useAdvancedPanelTimeline } from "../useAdvancedPanelTimeline";
import { useTimeline } from "../../../../../../contexts/TimelineProvider/TimelineProvider";
import { useObjectsState } from "../../../../../../contexts/ObjectsProvider";
import { Collapse } from "./Collapse";
import { ImageSwapPanel } from "../index";

interface ZoomProps {
  objectId: string;
  onChange: () => void;
}

export function Zoom({ objectId, onChange }: ZoomProps) {
  const set = useMetaVariableStore((s) => s.setMetaVariable);
  const { selectedObjects, animatedObjects } = useObjectsState();
  const selectedObjectId = selectedObjects[0]?.objectId;
  const isObjectInTimeline = animatedObjects.find((o) => o.id === selectedObjectId);

  const onInputChange = (key: string, value: string) => {
    set(key, value);
    onChange();
  };

  return (
    <Collapse title="View Box">
      <ZoomInput label="X" objectId={objectId} onInputChange={onInputChange} viewBoxKey="viewBoxXKey" />
      <ZoomInput label="Y" objectId={objectId} onInputChange={onInputChange} viewBoxKey="viewBoxYKey" />
      <ZoomInput label="Width" objectId={objectId} onInputChange={onInputChange} viewBoxKey="viewBoxWidthKey" />
      <ZoomInput label="Height" objectId={objectId} onInputChange={onInputChange} viewBoxKey="viewBoxHeightKey" />
    </Collapse>
  );
}

interface ZoomInputProps {
  objectId: string;
  viewBoxKey: "viewBoxXKey" | "viewBoxYKey" | "viewBoxWidthKey" | "viewBoxHeightKey";
  label: string;
  onInputChange: (key: string, value: string) => void;
}

function ZoomInput({ objectId, label, onInputChange, viewBoxKey }: ZoomInputProps) {
  const key = useMemo(() => getSmartObjectViewBoxMetaVariableKeys(objectId)[viewBoxKey], [objectId]);
  const value = useMetaVariableStore((s) => s.metaVariables[key]);
  const upsertAnimatedMetaVariable = useMetaVariableStore((s) => s.upsertAnimatedMetaVariable);
  const deleteAnimatedMetaVariableFrame = useMetaVariableStore((s) => s.deleteAnimatedMetaVariableFrame);
  const [tl] = useTimeline();
  const currentTime = tl?.scrubbingCurrentTime;
  const selectedObjectId = useObjectsState().selectedObjects[0]?.objectId;
  const { metaVariableInCurrentTime } = useAdvancedPanelTimeline(key);

  const onFrameAdd = () => {
    upsertAnimatedMetaVariable(key, currentTime, value, selectedObjectId);
  };

  const onFrameRemove = () => {
    deleteAnimatedMetaVariableFrame(key, currentTime);
  };

  return (
    <>
      <FramedInput
        label={label}
        inputType="number"
        value={value}
        isHovered={!!metaVariableInCurrentTime}
        onInputChange={(e) => onInputChange(key, e.target.value)}
        onFrameAdd={onFrameAdd}
        onFrameRemove={onFrameRemove}
      />
    </>
  );
}
