import React from "react";
import blobUrlFormatHelper from "../../../components/blobUrlFormatHelper";

export interface ICduPageOverlaysProps {
  cduPath: any;
  selectedPageData: any;
  cduScreenArea: any;
}
const CduPageOverlays = (props: ICduPageOverlaysProps) => {
  return (
    <>
      {props.selectedPageData ? (
        <svg>
          <image
            x={props.cduScreenArea.xCoordinate}
            y={props.cduScreenArea.yCoordinate}
            href={blobUrlFormatHelper(`${props.cduPath}${props.selectedPageData.displayImage}`)}
            width={props.cduScreenArea.width}
            height={props.cduScreenArea.height}
            preserveAspectRatio="none"
          />
        </svg>
      ) : (
        <></>
      )}
    </>
  );
};

export default CduPageOverlays;
