import { EInteractiveAudioType, LanguageType } from "../../models/IInteractiveAudio";
import { NarrationAudioState } from "../../contexts/PageAudioManager/types";
import { DEFAULT_PROSODY_RATE } from "../../components/Modals/TextToSpeechModal/constants";
import { nanoid } from "../../lib/nanoId";

type ConvertedAudio = Pick<
  NarrationAudioState,
  "objectId" | "input" | "savedText" | "type" | "language" | "start" | "end" | "parentObjectId" | "ttsProps"
>;

export interface LegacyAudio {
  /**
   * The file path
   */
  File: string;
  /**
   * The language primary or secondary
   */
  language: string;
  /**
   * The narration if any
   */
  NarratorText: string;
  /**
   * Asset version
   */
  Version: number | string | null;
  /**
   * Text to speech related properties for re-generation
   */
  Voice: string;
  PronunciationText: string;
  Language: string;
  Prosody: string;
}

export interface LegacyAudioManifest {
  pageText?: string;
  Audio: LegacyAudio[];
  narrationAudios: ConvertedAudio[];
}

const legacyToNarration = (audio: LegacyAudio, language: LanguageType): ConvertedAudio => {
  return {
    objectId: audio.Version?.toString() ?? nanoid(),
    input: audio.File,
    savedText: audio.NarratorText,
    type: EInteractiveAudioType.NARRATION,
    language,
    start: 0,
    end: 0,
    parentObjectId: undefined,
    ttsProps: {
      pronunciationText: audio?.PronunciationText ?? "",
      narrationText: audio?.NarratorText ?? "",
      voice: audio?.Voice ?? "",
      language: audio?.Language ?? "",
      prosodyRate: Number(audio?.Prosody ?? DEFAULT_PROSODY_RATE),
    },
  };
};

export const legacyAudioPolyfill = (manifest: LegacyAudioManifest) => {
  const audioLength = manifest.Audio?.length ?? 0;
  const audios: ConvertedAudio[] = [];
  /**
   * Length here can have 3 meanings:
   *  0 or 1: No audio
   *  [1~3]: Common audio
   *  [4~6]: Secondary Audio
   *
   *  If audio [1] is available we have audio
   *  If audio [3] is available we have secondary audio
   */

  if (audioLength > 1 && !manifest.narrationAudios) {
    // Primary audio
    const primaryAudio = legacyToNarration(manifest.Audio[1], LanguageType.PRIMARY);
    // Check for nulls
    if (primaryAudio.input) {
      // Convert
      audios.push(primaryAudio);
      // Check for Secondary audio
      if (audioLength > 3) {
        const secondaryAudio = legacyToNarration(manifest.Audio[4], LanguageType.SECONDARY);

        if (secondaryAudio.input) {
          secondaryAudio.parentObjectId = primaryAudio.objectId;
          audios.push(secondaryAudio);
        }
      }

      manifest.narrationAudios = audios ?? [];
    }

    // Check for pageText
    if (!manifest.pageText && primaryAudio.savedText) {
      manifest.pageText = primaryAudio.savedText;
    }
  }
};
