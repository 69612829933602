import "../fmsDesigner.css";
import React, { useContext, useEffect, useState } from "react";
import {
  IPageContext,
  PageContext,
  LessonPageActionsContext,
  ILessonPageActionsContext,
} from "../../../routes/builderContexts";
import { IFmsPageManifest } from "../Interfaces/IFmsPageManifest";
import _, { sortedLastIndex, before, forEach, initial, update } from "lodash";
import { IFmsContext, FmsContext } from "../Interfaces/fmsContext";
import ActionSelect from "../../../components/ActionSelect/ActionSelect";

const PageTemplateOptions: React.FC = () => {
  const pagesContext: IPageContext = useContext<IPageContext>(PageContext);
  const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);
  const pageManifest: IFmsPageManifest = pagesContext.pageManifest as IFmsPageManifest;
  const lessonPageActionsContext: ILessonPageActionsContext =
    useContext<ILessonPageActionsContext>(LessonPageActionsContext);
  const pageTemplates = lessonPageActionsContext.fmsObject.cduObject.pageTemplates;

  useEffect(() => {}, []);

  const pageTemplatesArr = [];
  for (const key in pageTemplates) {
    pageTemplatesArr.push(key);
  }

  const getPageData = async (value: string) => {
    fmsContext.setCursorSelected(false);
    fmsContext.getPageData(value);
  };

  const selectModal = (value: string) => {
    if (value !== "NONE") {
      fmsContext.fetchCduModal(value);
    } else {
      fmsContext.setCurrentModal({});
      fmsContext.setShowCduModal(false);
    }
    fmsContext.setCursorSelected(false);
    fmsContext.findCursorObject("fms1");

    //make api call set modal here
  };
  const CduModalCheck = () => {
    const setBootModal = (event: React.MouseEvent) => {
      if (pageManifest.pageLayouts.bootModal) {
        pageManifest.pageLayouts.bootModal =
          pageManifest.pageLayouts.bootModal === "" ? fmsContext.currentModal.modalName : "";
        pageManifest.pageLayouts.modalPath =
          pageManifest.pageLayouts.bootModal === ""
            ? lessonPageActionsContext.fmsObject.cduObject.modalIndex[fmsContext.currentModal.modalName]
            : "";
      } else {
        pageManifest.pageLayouts["bootModal"] = fmsContext.currentModal.modalName;
        pageManifest.pageLayouts.modalPath =
          lessonPageActionsContext.fmsObject.cduObject.modalIndex[fmsContext.currentModal.modalName];
      }

      pagesContext.updatePageManifest(pageManifest);
    };
    return fmsContext.currentModal.modalName && fmsContext.currentModal.modalName.length ? (
      <label
        className="instructions page-opbjective-label edit-menu-label screen-adjust-objective-label"
        style={{ maxWidth: "500px", width: "45%", marginRight: "30%" }}
      >
        Menu open on start:
        {/* <span
            className="check-container fms-modal-menu-cursor "
            onClick={setBootModal}
          > */}
        <div style={{ width: "10rem", display: "grid" }}>
          <input
            onClick={setBootModal}
            className="pageTemplateCheckbox"
            // style={{
            //   backgroundColor: 'rgba(0,0,0,0)',
            //   marginTop: '5px',
            //   marginLeft: '15px',
            // }}
            type="checkBox"
            checked={
              pageManifest.pageLayouts.bootModal === fmsContext.currentModal.modalName &&
              pageManifest.pageLayouts.bootModal.length > 0
            }
          ></input>
        </div>
        {/* <input
              className="fms-check-input"
              style={{ marginLeft: '1%' }}
              type="checkBox"
              checked={
                pageManifest.pageLayouts.bootModal ===
                  fmsContext.currentModal.modalName &&
                pageManifest.pageLayouts.bootModal.length > 0
              }
            ></input> */}
        {/* <span className="cursor-checkmark shaded"></span> */}
        {/* </span> */}
      </label>
    ) : (
      <></>
    );
  };

  return (
    <React.Fragment>
      {pageTemplates ? (
        <label
          className="instructions page-opbjective-label page-template-label screen-adjust-objective-label"
          style={{ maxWidth: "500px" }}
        >
          Page Template:
          <ActionSelect
            action={pageManifest.bootPage}
            options={pageTemplatesArr.map((page, index) => {
              return page;
            })}
            highlightColor="#293D54"
            defaultBackground="#ffffff"
            defaulTextColor="#000000"
            highlightTextColor="#ffffff"
            selectOption={getPageData}
            disabledOptions={[]}
            displayValues={pageTemplatesArr.map((page, index) => {
              return page;
            })}
            parentClass={"spacedInput page-objective-input"}
            parentId={"page-objective-page-template-id"}
            optionWrapperClass={"pageObjective-template-options-container"}
            optionClass={"criteria-select-option"}
          />
        </label>
      ) : null}

      {pageManifest.cdu.includes("a220") || pageManifest.cdu.includes("a350") ? (
        <>
          <label
            className="instructions page-opbjective-label edit-menu-label screen-adjust-objective-label"
            style={{ maxWidth: "500px" }}
          >
            Edit Menu:
            <ActionSelect
              action={
                fmsContext.currentModal && fmsContext.currentModal.modalName
                  ? fmsContext.currentModal.modalName
                  : "NONE"
              }
              options={
                fmsContext.currentCDU.modalIndex
                  ? Object.keys(fmsContext.currentCDU.modalIndex)
                      .map((modal: any, index: number) => {
                        return modal;
                      })
                      .concat(["NONE"])
                  : ["NONE"]
              }
              highlightColor="#293D54"
              defaultBackground="#ffffff"
              defaulTextColor="#000000"
              highlightTextColor="#ffffff"
              selectOption={selectModal}
              disabledOptions={[]}
              displayValues={
                fmsContext.currentCDU.modalIndex
                  ? Object.keys(fmsContext.currentCDU.modalIndex)
                      .map((modal: any, index: number) => {
                        return modal;
                      })
                      .concat(["NONE"])
                  : ["NONE"]
              }
              parentClass={"spacedInput page-objective-input"}
              parentId={"page-objective-edite-menu-id"}
              optionWrapperClass={"pageObjective-template-options-container"}
              optionClass={"criteria-select-option"}
            />
          </label>
          {fmsContext.showCduModal ? CduModalCheck() : null}
        </>
      ) : null}
    </React.Fragment>
  );
};

export default PageTemplateOptions;
