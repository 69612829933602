import _ from "lodash";
import React from "react";
import QuizRemoveChoice from "./QuizRemoveChoice";
import IQuizManifest from "./IQuizInterfaces";
import { ReactComponent as InfoIcon } from "../../assets/icons/Common/question-feedback-icon.svg";

type choiceType = {
  text: string;
  isCorrect: boolean;
  responses: {
    correct: string;
    incorrect: string;
  };
  audio: {
    File: string;
    assetVersionId: number;
    parentVersionId: number;
    FileMD5: string;
    NarratorText: string;
  }[];
};

type ChoicesType = {
  newManifest: IQuizManifest;
  displayChoiceModal: (index: number) => void;
  removeChoice: (index: number) => void;
  markAsCorrect: (e: any) => void;
  updateChoice: (e: any, index: number) => void;
};

const QuizChoices: Function = (props: ChoicesType): JSX.Element[] => {
  // const updatedManifest: IQuizManifestType = _.cloneDeep(useContext<IPageContext>(PageContext).pageManifest);

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  if (_.isEmpty(props.newManifest.choices)) {
    return [<div>Click Add to Create an Option</div>];
  } else {
    return props.newManifest.choices.map((choice: choiceType, index: number) => {
      return (
        <section className="quiz-section" key={index}>
          <label htmlFor="choice" className="choice-label">
            <input
              type="radio"
              name="choice"
              value={index}
              checked={choice.isCorrect}
              onChange={(e: any) => props.markAsCorrect(e)}
            />
            <input
              type="text"
              className="question-choice"
              value={choice.text}
              placeholder={choice.text}
              onChange={(e: any) => props.updateChoice(e, index)}
              onFocus={handleFocus}
            />
          </label>
          <InfoIcon className="quiz-info-icon" onClick={() => props.displayChoiceModal(index)} />
          <QuizRemoveChoice updatedManifest={props.newManifest} removeChoice={props.removeChoice} choiceKey={index} />
        </section>
      );
    });
  }
};

export default QuizChoices;
