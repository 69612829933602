import "../fmsDesigner.css";
import React, { useContext, useEffect, useState } from "react";
import {
  IPageContext,
  PageContext,
  LessonPageActionsContext,
  ILessonPageActionsContext,
} from "../../../routes/builderContexts";
import { IFmsPageManifest } from "../Interfaces/IFmsPageManifest";
import _, { sortedLastIndex, before, forEach, initial, update } from "lodash";

import { IFmsContext, FmsContext } from "../Interfaces/fmsContext";
import ActionSelect from "../../../components/ActionSelect/ActionSelect";
import dropDownIcon from "../../../assets/icons/FMSIcons/Dropdown.svg";
import GraphicStateSelectionMenu from "./GraphicStateSelectionMenu";
import FMSLayoutOptions from "./FMSLayoutOptions";

const PageObjective: React.FC = () => {
  const pagesContext: IPageContext = useContext<IPageContext>(PageContext);
  const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);
  const pageManifest: IFmsPageManifest = pagesContext.pageManifest as IFmsPageManifest;
  const lessonPageActionsContext: ILessonPageActionsContext =
    useContext<ILessonPageActionsContext>(LessonPageActionsContext);
  const [pageTitle, setTitle] = useState(pageManifest.instructions.lessonTitle.replace(/<[^>]*>?/gm, ""));
  const [pageDescription, setPageDescription] = useState(pageManifest.instructions.lessonInstructions);
  const updatedManifest = _.cloneDeep(pageManifest);
  const pageTemplates = lessonPageActionsContext.fmsObject.cduObject.pageTemplates;

  useEffect(() => {
    updatePageAttempts();
  }, []);

  useEffect(() => {
    if (pageManifest.instructions.lessonInstructions !== pageDescription) {
      pageManifest.instructions.lessonInstructions = pageDescription;
      pagesContext.updatePageManifest(pageManifest);
    }
  }, [pageDescription]);

  const updatePageAttempts = () => {
    if (
      typeof pageManifest.pageAttempts === "number" ||
      !pageManifest.pageAttempts.perform ||
      !pageManifest.pageAttempts.practice
    ) {
      updatedManifest.pageAttempts = { practice: 5, perform: 5 };
      pagesContext.updatePageManifest(updatedManifest);
    }
  };

  const assignNewText = (newText: string): void => {
    pageManifest.instructions.lessonInstructions = newText;
    setPageDescription(newText);
    pagesContext.updatePageManifest(pageManifest);
  };

  const setPageManifestTitle = (value: string) => {
    updatedManifest.instructions.lessonTitle = value;
    pagesContext.updatePageManifest(updatedManifest);
    setTitle(value);
  };

  const getEditableGraphicsList = () => {
    const editableGraphicsList: any = [];

    function checkForChangeImage(rectangularObject: any, index: number) {
      if (rectangularObject?.actions[0]?.function === "changeImage") {
        editableGraphicsList.push(rectangularObject.id);
      }
    }

    fmsContext.currentCDUPageData.rectangularObjects.forEach(checkForChangeImage);

    return editableGraphicsList;
  };

  const selectGraphic = (value: string) => {
    // pagesContext.updatePageManifest(pageManifest);
  };

  const assignPageAttempt = (event: React.ChangeEvent<HTMLInputElement>) => {
    //let modeName = modeNames[editContext.selectedEditorLessonModes - 1];
    event.preventDefault();
    // switch (modeName) {
    //   // case "demo":
    //   //   pageManifest.pageAttempts.demo = parseInt(event.target.value);
    //   //   break;
    //   case "practice":
    //     pageManifest.pageAttempts.practice = parseInt(event.target.value);
    //     break;
    //   case "perform":
    pageManifest.pageAttempts.perform = parseInt(event.target.value);
    //   break;
    // default:
    //   break;
    // }
    pagesContext.updatePageManifest(pageManifest);
  };
  const incrimentPageAttempts = (value: number) => {
    pageManifest.pageAttempts.perform += value;
    pagesContext.updatePageManifest(pageManifest);
  };

  const modeHasAttempts = () => {
    return (
      <label className="instructions" style={{ display: "grid", gridTemplateColumns: "25% 25%" }}>
        {/* <div style={{width: "16"}}> */}
        Perform Mode Max Page Attempts:
        {/* </div> */}
        <div
          style={{
            width: "40px",
            display: "grid",
            alignContent: "center",
            borderRadius: "3px",
            marginLeft: "5px",
          }}
        >
          {/* <input type="number" className="attempts-input" value={updatedManifest.pageAttempts.perform} onChange={assignPageAttempt} min="1" max="10" style={{height: "1.5625rem", borderRadius: ".3rem"}}></input> */}
          <input
            style={{
              width: "40px",
              height: "25px",
              border: "none",
              borderRadius: "3px",
            }}
            type="number"
            value={updatedManifest.pageAttempts.perform}
            onChange={assignPageAttempt}
            min="1"
            max="10"
          ></input>
          <div
            style={{
              alignSelf: "center",
              display: "inline-flex",
              flexDirection: "column",
              position: "relative",
              width: "12px",
              left: "27px",
              top: "-22px",
            }}
          >
            <img
              onClick={() => {
                if (updatedManifest.pageAttempts.perform < 10) {
                  incrimentPageAttempts(1);
                }
              }}
              className={updatedManifest.pageAttempts.perform < 10 ? "attempts-up" : "attempts-up disabled-arrow"}
              src={dropDownIcon}
            ></img>
            <img
              onClick={() => {
                if (updatedManifest.pageAttempts.perform > 1) {
                  incrimentPageAttempts(-1);
                }
              }}
              className={updatedManifest.pageAttempts.perform > 1 ? "attempts-down" : "attempts-down disabled-arrow"}
              src={dropDownIcon}
            ></img>
          </div>
        </div>
      </label>
    );
  };

  return (
    <React.Fragment>
      <div className="row">
        <div
          className=""
          style={{
            borderRadius: "3px 3px 0 0",
            width: "30%",
            minWidth: "fit-content",
            backgroundColor: "white",
            marginInline: "2.5%",
          }}
        >
          <strong style={{ marginInline: "10px", color: "#293D54" }}>Page Objective Settings</strong>
        </div>
      </div>
      <div className="row" style={{ marginBottom: "10px" }}>
        <div
          style={{
            border: "1px solid white",
            borderRadius: "0px 5px 5px 5px",
            width: "95%",
            marginInline: "2.5%",
          }}
        >
          <label
            className="instructions page-opbjective-label screen-adjust-objective-label"
            style={{ width: "56.5%", maxWidth: "458px" }}
          >
            Page Objective Type:
            <ActionSelect
              action={updatedManifest.instructions.lessonTitle}
              options={["Enabling Objective", "Terminal Objective", "Information"]}
              highlightColor="#293D54"
              defaultBackground="#ffffff"
              defaulTextColor="#000000"
              highlightTextColor="#ffffff"
              selectOption={setPageManifestTitle}
              disabledOptions={[]}
              displayValues={["Enabling Objective", "Terminal Objective", "Information"]}
              parentClass={"spacedInput page-objective-input"}
              parentId={"sequenceCriteria"}
              optionWrapperClass={"pageObjective-select-options-container"}
              optionClass={"criteria-select-option"}
            />
          </label>
          {modeHasAttempts()}
          <p id="objective" style={{ marginLeft: "1.5%" }}>
            Objective:
          </p>
          <div className="row editor-container">
            <div className="col-12">
              <div className="padding-all-sides-10">
                <textarea
                  className="fms-page-objective"
                  value={pageManifest.instructions.lessonInstructions.replace(/<[^>]*>/g, "")}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => assignNewText(e.currentTarget.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {pageManifest.cdu.includes("220") ||
      pageManifest.cdu.includes("350") ||
      pageManifest.cdu.includes("q400") ||
      pageManifest.cdu.includes("145") ? (
        <FMSLayoutOptions />
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default PageObjective;
