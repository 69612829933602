import React from "react";
import { AnnotationPropType } from "../AnnotationThumbnail";

const AnnotationCircledLetter = (props: AnnotationPropType) => {
  const { annotation, count, index } = props;
  const alphas = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  const display = () => {
    return (
      <svg
        viewBox={`0 0 100 100`}
        key={index}
        id={`circledLetter-${index}`}
        tabIndex={0}
        name="circledLetter"
        className="annotation"
        preserveAspectRatio="xMinYMin meet"
        style={{
          top: `${annotation.top}%`,
          left: `${annotation.left}%`,
          width: `${annotation.width}%`,
          height: `${annotation.height}%`,
          zIndex: annotation.zIndex,
          transform: `rotate(${annotation?.transform?.rotate}deg)`,
        }}
      >
        <circle
          cx="50"
          cy="50"
          r="47"
          stroke={`${annotation?.borderColor}`}
          fill={`${annotation?.backgroundColor}`}
          strokeWidth={`${annotation.strokeWidth || "3"}`}
          // strokeWidth="3"
        ></circle>
        <text
          className="step-text"
          x="50"
          y="50"
          dy="26.5"
          textAnchor="middle"
          fill={`${annotation?.fontColor}`}
          // style={{
          //   transform: 'translate(0px, -4px)'
          // }}
        >
          {alphas[(count as number) - 1]}
        </text>
      </svg>
    );
  };

  return <React.Fragment>{display()}</React.Fragment>;
};

export default AnnotationCircledLetter;
