// import '../../pageTypes/BasicPage_Player/BasePageDesigner.css'
import React from "react";
import BasePageSelector from "./BasePageSelector";
import NewPageSelector from "./NewPageSelector";

type ModalType = {
  modalType: string;
  showModal: boolean;
  hideModal: () => void;
};

const DesignerModal: (props: ModalType) => JSX.Element = (props: ModalType) => {
  switch (props.modalType) {
    case "Change Layout":
      return <BasePageSelector show={props.showModal} onHide={() => props.hideModal()} />;
    case "Add Page":
      return <NewPageSelector show={props.showModal} onHide={() => props.hideModal()} />;
    default:
      return <></>;
  }
};

export default DesignerModal;
