import "../fmsDesigner.css";
import React, { useContext, useEffect, useState } from "react";
import {
  IPageContext,
  PageContext,
  LessonPageActionsContext,
  ILessonPageActionsContext,
} from "../../../routes/builderContexts";
import { IFmsPageManifest } from "../Interfaces/IFmsPageManifest";
import RichTextEditor from "../../../components/richTextEditor/richTextEditor";
import UnicodeLegend from "../../../components/unicodeLegend/unicodeLegend";
import _, { sortedLastIndex, before, forEach, initial, update } from "lodash";
import blobUrlFormatHelper from "../../../components/blobUrlFormatHelper";
import CduCursorSelector from "./CduCursorSelector";
import { IFmsContext, FmsContext } from "../Interfaces/fmsContext";
// import genericRepositoryService from "../../../services/genericRepositoryService";
import ActionSelect from "../../../components/ActionSelect/ActionSelect";
import dropDownIcon from "../../../assets/icons/FMSIcons/Dropdown.svg";
import { fmsPagePath } from "../../../components/constants/pageTypes";

const GraphicStateSelectionMenu: React.FC = () => {
  const pagesContext: IPageContext = useContext<IPageContext>(PageContext);
  const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);
  const pageManifest: IFmsPageManifest = pagesContext.pageManifest as IFmsPageManifest;
  const lessonPageActionsContext: ILessonPageActionsContext =
    useContext<ILessonPageActionsContext>(LessonPageActionsContext);
  const pageTemplates = lessonPageActionsContext.fmsObject.cduObject.pageTemplates;
  const [selectedGraphic, setSelectedGraphic] = useState("");

  useEffect(() => {}, [selectedGraphic]);

  const getEditableGraphicsList = () => {
    const editableGraphicsList: any = [];

    function checkForChangeImage(rectangularObject: any, index: number) {
      if (rectangularObject.actions !== undefined && rectangularObject?.actions[0]?.function === "changeImage") {
        editableGraphicsList.push(rectangularObject.id);
      }
    }

    function checkForScreenObject(screenObject: any, index: number) {}

    if (fmsContext.currentCDUPageData?.rectangularObjects) {
      fmsContext.currentCDUPageData.rectangularObjects?.forEach(checkForChangeImage);
    } else if (fmsContext.currentCDU.screenObjects) {
      fmsContext.currentCDU.screenObjects.forEach(checkForScreenObject);
    }

    return editableGraphicsList;
  };

  const getSelectedGraphicStates = () => {
    let graphicStates: any = [];
    function getStates(rectangularObject: any, index: number) {
      if (rectangularObject.id === selectedGraphic) {
        graphicStates = rectangularObject.actions[0].parameter.changeImage.assets;
      }
    }
    fmsContext.currentCDUPageData.rectangularObjects?.forEach(getStates);

    function trimAssetString(string: string) {
      const extension = string.search(".svg");
      const slash = string.lastIndexOf("/") + 1;
      if (string === "") {
        return "NONE";
      } else {
        return string.slice(slash, extension);
      }
    }

    const trimmedGraphicStates = graphicStates.map(trimAssetString);
    return trimmedGraphicStates;
  };

  const getSelectedGraphicState = () => {
    let graphicState: any = "";
    pageManifest.pageLayouts[pageManifest.bootPage].overLays.forEach((overLay: any, index: number) => {
      if (overLay.parentCell === selectedGraphic) {
        graphicState = overLay.image;
      }
    });

    function trimAssetString(string: string) {
      const extension = string.search(".svg");
      const slash = string.lastIndexOf("/") + 1;
      return string.slice(slash, extension);
    }

    return trimAssetString(graphicState);
  };

  const selectGraphic = (value: string) => {
    setSelectedGraphic(value);
  };

  const selectState = (value: string) => {
    pageManifest.pageLayouts[pageManifest.bootPage].overLays.forEach((overLay: any, index: number) => {
      if (overLay.parentCell === selectedGraphic) {
        let graphicStates: any = [];
        function getStates(rectangularObject: any, index: number) {
          if (rectangularObject.id === selectedGraphic) {
            graphicStates = rectangularObject.actions[0].parameter.changeImage.assets;
          }
        }
        fmsContext.currentCDUPageData.rectangularObjects?.forEach(getStates);
        graphicStates.forEach((path: string, index: number) => {
          if (path.includes(value + ".svg")) {
            overLay.image = path;
          } else if (value === "NONE") {
            overLay.image = "";
          }
        });
      }
    });
    pagesContext.updatePageManifest(pageManifest);
  };

  return (
    <React.Fragment>
      {pageManifest.cdu.includes("a350") ? (
        <label
          className="instructions page-opbjective-label edit-menu-label screen-adjust-objective-label"
          style={{ maxWidth: "500px" }}
        >
          Edit Graphic:
          <ActionSelect
            action={selectedGraphic === "" ? "NONE" : selectedGraphic}
            options={getEditableGraphicsList()}
            highlightColor="#293D54"
            defaultBackground="#ffffff"
            defaulTextColor="#000000"
            highlightTextColor="#ffffff"
            selectOption={selectGraphic}
            disabledOptions={[]}
            displayValues={getEditableGraphicsList()}
            parentClass={"spacedInput page-objective-input"}
            parentId={"page-objective-edite-menu-id"}
            optionWrapperClass={"pageObjective-template-options-container"}
            optionClass={"criteria-select-option"}
          />
        </label>
      ) : (
        <></>
      )}
      {pageManifest.cdu.includes("a350") ? (
        <label
          className="instructions page-opbjective-label edit-menu-label screen-adjust-objective-label"
          style={{ maxWidth: "500px" }}
        >
          Graphic State:
          <ActionSelect
            action={selectedGraphic === "" ? "NONE" : getSelectedGraphicState()}
            options={getSelectedGraphicStates()}
            highlightColor="#293D54"
            defaultBackground="#ffffff"
            defaulTextColor="#000000"
            highlightTextColor="#ffffff"
            selectOption={selectState}
            disabledOptions={[]}
            displayValues={getSelectedGraphicStates()}
            parentClass={"spacedInput page-objective-input"}
            parentId={"page-objective-edite-menu-id"}
            optionWrapperClass={"pageObjective-template-options-container"}
            optionClass={"criteria-select-option"}
          />
        </label>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default GraphicStateSelectionMenu;
