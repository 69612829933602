export enum MetadataEditorActionType {
  SET_INITIAL_DESCRIPTION = "SET_INITIAL_DESCRIPTION",
  SET_INITIAL_STATE = "SET_INITIAL_STATE",
  CHANGE_DESCRIPTION = "CHANGE_DESCRIPTION",
  CHANGE_MODE = "CHANGE_MODE",
  SET_INITIAL_TAGS = "SET_INITIAL_TAGS",
  ADD_TAG = "ADD_TAG",
  EDIT_TAG = "EDIT_TAG",
  REMOVE_TAG_BY_ID = "REMOVE_TAG_BY_ID",
  SET_MAN_FLEET_VAR_DTO = "SET_MAN_FLEET_VAR_DTO",
  CLEAR_METADATA = "CLEAR_METADATA",
  RESET_DATA_CHANGED = "RESET_DATA_CHANGED",
  SET_DATA_CHANGED = "SET_DATA_CHANGED",
  SET_ERROR_STATE = "SET_ERROR_STATE",
  SET_ASSET_IS_LIBRARY = "SET_ASSET_IS_LIBRARY",
  SET_ASSET_IS_ARCHIVED = "SET_ASSET_IS_ARCHIVED",
}
