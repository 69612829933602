import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import "./tables.css";
import { useTablesDataDispatch, useTablesDataState } from "./TablesDataProvider";
import { IPageContext, PageContext } from "../../routes/builderContexts";
import { ObjectActionsType, useObjectsDispatch } from "../../contexts/ObjectsProvider";
// import { TableButtonType } from "./TableButton";

type MenuNewTableSizeProps = {
  isSizeMenuOpen: boolean;
  setIsSizeMenuOpen(boolean: boolean): void;
};

type tableSize = {
  row: number;
  column: number;
};

const MenuNewTableSize = (props: MenuNewTableSizeProps) => {
  const maxTableSize = { columns: 8, rows: 8 };
  const columns = new Array(maxTableSize.columns).fill(null).map((_, i) => i + 1);
  const rows = new Array(maxTableSize.rows).fill(null).map((_, i) => i + 1);

  const [state, dispatch] = useReducer(reducer, { row: -1, column: -1 });

  const tablesDispatch = useTablesDataDispatch();
  const TablesState = useTablesDataState();

  function reducer(state: any, action: { action: string; payload: tableSize }) {
    switch (action.action) {
      case "highlight":
        return action.payload;
      default:
        break;
    }
  }

  useEffect(() => {
    function clickOutsideMenu(e: MouseEvent) {
      props.setIsSizeMenuOpen(false);
      document.removeEventListener("click", clickOutsideMenu);
    }
    if (props.isSizeMenuOpen) {
      document.addEventListener("click", clickOutsideMenu);
    }
  }, [props.isSizeMenuOpen]);

  function generateTableRows() {
    function mapRows(index: number) {
      return (
        <tr id={"row " + index}>
          <GenerateTableCells row={index} />
        </tr>
      );
    }
    return rows.map(mapRows);
  }

  function GenerateTableCells({ row }: { row: number }) {
    const pageContext = useContext<IPageContext>(PageContext);
    const objectsDispatch = useObjectsDispatch();
    function mapColumns(index: number) {
      let className = undefined;
      const size = { row: row, column: index };
      if (state?.row && state?.column && size.row <= state.row && size.column <= state.column) {
        className = " selected";
      }
      return (
        <th
          className={"size-select-cell" + (className !== undefined ? className : "")}
          id={JSON.stringify(size)}
          onMouseEnter={() => dispatch({ action: "highlight", payload: size })}
          key={index}
          onMouseDown={() => {
            objectsDispatch({
              type: ObjectActionsType.ADD_TABLE,
              payload: { size: { rows: size.row, columns: size.column } },
            });
            props.setIsSizeMenuOpen(false);
          }}
        ></th>
      );
    }
    return <>{columns.map(mapColumns)}</>;
  }

  return props.isSizeMenuOpen ? (
    <div className="table-size-selection-container">
      <table className="table-size-select-grid">
        <tbody className="table-size-menu">{generateTableRows()}</tbody>
      </table>
    </div>
  ) : null;
};

export default MenuNewTableSize;
