import React from "react";
import EmptyImage from "../../../EmptyImage";
import { IIGATextChoice } from "../../models/IIGA";
import blobHelper from "../../../blobUrlFormatHelper";
import { IMoveableProps } from "../../../Moveable/models/IMoveableProps";
import Annotation, { AnnotationType } from "../../../Annotation/Annotation";
import MoveableClass from "../../../Moveable/MoveableClass";
import { handleKeyPress } from "../../../ObjectPropertyBox/functions/PropertyBoxFunctions";
import Symbol from "../../../Symbol/Symbol";
import { ISymbolV2 } from "../../../Symbol/models/ISymbol";
import _ from "lodash";

type StandardQuizType = {
  annotationProps: AnnotationType;
  choices: IIGATextChoice[];
  containerRef: React.RefObject<HTMLDivElement>;
  currentImagePath?: string;
  maxStandardChoices: number;
  moveableProps: IMoveableProps;
  question: string;
  addQuizAnswer: () => void;
  addStandardQuizGraphic: (e: React.MouseEvent<HTMLDivElement>) => void;
  handleAnswerChange: (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
    index: number,
  ) => void;
  handleQuestionEntry: (value: string) => void;
  markStandardCorrect: (index: number) => void;
  removeAnswer: () => void;
  setSelectedAnswer: (index: number) => void;
  warning: boolean;
  updatedManifest: any;
  pageContext: any;
  currentStep: number;
  hsIndex: number;
};

const IGAStandardQuiz = (props: StandardQuizType) => {
  const {
    hsIndex,
    annotationProps,
    choices,
    containerRef,
    currentImagePath,
    maxStandardChoices,
    moveableProps,
    question,
    addQuizAnswer,
    addStandardQuizGraphic,
    handleAnswerChange,
    handleQuestionEntry,
    markStandardCorrect,
    removeAnswer,
    setSelectedAnswer,
  } = props;
  const displayText = "Click Here to Add an Image";
  const showIsCorrect = (isCorrect: boolean, index: number) => {
    if (isCorrect) {
      return (
        <div onClick={() => markStandardCorrect(index)}>
          {/* <span className="icon-radio-button-text-answers-selected">
            <span className="path1"></span>
            <span className="path2"></span>
          </span> */}
          <span className="icon-radio-button-text-answers-selected">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
            <span className="path4"></span>
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <span
            className="icon-radio-button-text-answers-not-selected"
            onClick={(e: React.MouseEvent<HTMLDivElement>) => markStandardCorrect(index)}
          ></span>
        </div>
      );
    }
  };

  const displayAnswers = () => {
    return choices.map((choice, index) => {
      return (
        <React.Fragment key={index}>
          <div className="iga-standard-quiz-answer-row">
            <div>{showIsCorrect(choice.isCorrect, index)}</div>
            <div className="iga-answer-input">
              <textarea
                rows={2}
                cols={1}
                className="iga-quiz-standard-answer"
                value={choice.text}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleAnswerChange(e, index)}
                onFocus={() => setSelectedAnswer(index)}
              />
            </div>
          </div>
        </React.Fragment>
      );
    });
  };

  const displayAddButton = () => {
    if (choices.length < maxStandardChoices) {
      return (
        <button onClick={() => addQuizAnswer()}>
          <span className="icon-Add-icon">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
          </span>
        </button>
      );
    }
  };

  const displayRemoveButton = () => {
    if (choices.length > 2) {
      return (
        <button onClick={() => removeAnswer()}>
          <span className="icon-remove-no-background"></span>
        </button>
      );
    }
  };

  function updateImage(index: number, pageManifest: any, pageContext: any, igsIndex?: number) {
    const quizImageContainer = document.getElementById("quiz-image-container");
    const imageAspectContainer = document.getElementById("img-aspect-container-0");
    const igaInteractiveGraphicWrapper = document.getElementById("iga-graphic-wrapper");
    const igaStandardGraphicContainer = document.getElementById("iga-standard-quiz-graphic-container");
    let quizImageContainerRect: any;

    const quizImage = document.getElementById("quiz-image-" + index);

    if (quizImageContainer) {
      quizImageContainerRect = quizImageContainer.getBoundingClientRect();
    }

    if (imageAspectContainer) {
      quizImageContainerRect = imageAspectContainer.getBoundingClientRect();
    }

    if (igaInteractiveGraphicWrapper) {
      quizImageContainerRect = igaInteractiveGraphicWrapper.getBoundingClientRect();
    }

    if (igaStandardGraphicContainer) {
      quizImageContainerRect = igaStandardGraphicContainer.getBoundingClientRect();
    }

    if (quizImage) {
      const quizImageRect = quizImage.getBoundingClientRect();

      if (pageManifest.image) {
        pageManifest.image[index].rawData = {
          pixelHeight: quizImageRect.height,
          pixelWidth: quizImageRect.width,
          pixelTop: quizImageRect.top,
          pixelLeft: quizImageRect.left,
        };

        pageManifest.image[index].version = 1;
        pageContext.updatePageManifest(pageManifest);
      }

      if (pageManifest.HotSpotList[hsIndex].IGSObject) {
        if (pageManifest.HotSpotList[hsIndex].IGSObject[igsIndex as number].images) {
          pageManifest.HotSpotList[hsIndex].IGSObject[igsIndex as number].images.rawData = {
            pixelHeight: quizImageRect.height,
            pixelWidth: quizImageRect.width,
            pixelTop: quizImageRect.top - quizImageContainerRect.top,
            pixelLeft: quizImageRect.left - quizImageContainerRect.left,
          };
          pageManifest.HotSpotList[hsIndex].IGSObject[igsIndex as number].aspectRatio =
            quizImageContainerRect.width / quizImageContainerRect.height;
          pageManifest.HotSpotList[hsIndex].IGSObject[igsIndex as number].images.version = 1;
        }

        if (
          !pageManifest.HotSpotList[hsIndex].IGSObject[igsIndex as number].images &&
          pageManifest.HotSpotList[hsIndex].IGSObject[igsIndex as number].choices
        ) {
          pageManifest.HotSpotList[hsIndex].IGSObject[igsIndex as number].choices[index].rawData = {
            pixelHeight: quizImageRect.height,
            pixelWidth: quizImageRect.width,
            pixelTop: quizImageRect.top - quizImageContainerRect.top,
            pixelLeft: quizImageRect.left - quizImageContainerRect.left,
          };
          pageManifest.HotSpotList[hsIndex].IGSObject[igsIndex as number].aspectRatio =
            quizImageContainerRect.width / quizImageContainerRect.height;
          pageManifest.HotSpotList[hsIndex].IGSObject[igsIndex as number].choices[index].version = 1;
        }
      }

      updatePageManifestDimensionPercentages(index, pageManifest, pageContext, igsIndex, quizImageContainerRect);
    }
  }

  function updatePageManifestDimensionPercentages(
    index: number,
    pageManifest: any,
    pageContext: any,
    igsIndex?: number,
    quizImageContainer?: any,
  ) {
    if (pageManifest.image) {
      // image pixel percent from top of pagePlayerArea
      pageManifest.image[index].top = Number(
        ((pageManifest.image[index].rawData!.pixelTop / quizImageContainer.height) * 100).toFixed(2),
      );
      // image pixel percent from left of pagePlayerArea
      pageManifest.image[index].left = Number(
        ((pageManifest.image[index].rawData!.pixelLeft / quizImageContainer.width) * 100).toFixed(2),
      );
      // image pixel percent height relative to pagePlayerArea
      pageManifest.image[index].height = Number(
        ((pageManifest.image[index].rawData!.pixelHeight / quizImageContainer.height) * 100).toFixed(2),
      );
      // image pixel percent width relative to pagePlayerArea
      pageManifest.image[index].width = Number(
        ((pageManifest.image[index].rawData!.pixelWidth / quizImageContainer.width) * 100).toFixed(2),
      );
    }

    if (pageManifest.HotSpotList[hsIndex].IGSObject) {
      if (pageManifest.HotSpotList[hsIndex].IGSObject[igsIndex as number].images) {
        // image pixel percent from top of pagePlayerArea
        pageManifest.HotSpotList[hsIndex].IGSObject[igsIndex as number].images.top = Number(
          (
            (pageManifest.HotSpotList[hsIndex].IGSObject[igsIndex as number].images.rawData!.pixelTop /
              quizImageContainer.height) *
            100
          ).toFixed(2),
        );
        // image pixel percent from left of pagePlayerArea
        pageManifest.HotSpotList[hsIndex].IGSObject[igsIndex as number].images.left = Number(
          (
            (pageManifest.HotSpotList[hsIndex].IGSObject[igsIndex as number].images.rawData!.pixelLeft /
              quizImageContainer.width) *
            100
          ).toFixed(2),
        );
        // image pixel percent height relative to pagePlayerArea
        pageManifest.HotSpotList[hsIndex].IGSObject[igsIndex as number].images.height = Number(
          (
            (pageManifest.HotSpotList[hsIndex].IGSObject[igsIndex as number].images.rawData!.pixelHeight /
              quizImageContainer.height) *
            100
          ).toFixed(2),
        );
        // image pixel percent width relative to pagePlayerArea
        pageManifest.HotSpotList[hsIndex].IGSObject[igsIndex as number].images.width = Number(
          (
            (pageManifest.HotSpotList[hsIndex].IGSObject[igsIndex as number].images.rawData!.pixelWidth /
              quizImageContainer.width) *
            100
          ).toFixed(2),
        );
      }

      if (
        !pageManifest.HotSpotList[hsIndex].IGSObject[igsIndex as number].images &&
        pageManifest.HotSpotList[hsIndex].IGSObject[igsIndex as number].choices
      ) {
        // image pixel percent from top of pagePlayerArea
        pageManifest.HotSpotList[hsIndex].IGSObject[igsIndex as number].choices[index].top = Number(
          (
            (pageManifest.HotSpotList[hsIndex].IGSObject[igsIndex as number].choices[index].rawData!.pixelTop /
              quizImageContainer.height) *
            100
          ).toFixed(2),
        );
        // image pixel percent from left of pagePlayerArea
        pageManifest.HotSpotList[hsIndex].IGSObject[igsIndex as number].choices[index].left = Number(
          (
            (pageManifest.HotSpotList[hsIndex].IGSObject[igsIndex as number].choices[index].rawData!.pixelLeft /
              quizImageContainer.width) *
            100
          ).toFixed(2),
        );
        // image pixel percent height relative to pagePlayerArea
        pageManifest.HotSpotList[hsIndex].IGSObject[igsIndex as number].choices[index].height = Number(
          (
            (pageManifest.HotSpotList[hsIndex].IGSObject[igsIndex as number].choices[index].rawData!.pixelHeight /
              quizImageContainer.height) *
            100
          ).toFixed(2),
        );
        // image pixel percent width relative to pagePlayerArea
        pageManifest.HotSpotList[hsIndex].IGSObject[igsIndex as number].choices[index].width = Number(
          (
            (pageManifest.HotSpotList[hsIndex].IGSObject[igsIndex as number].choices[index].rawData!.pixelWidth /
              quizImageContainer.width) *
            100
          ).toFixed(2),
        );
      }
    }

    pageContext.updatePageManifest(pageManifest);
  }

  const handleImageLoad = () => {
    updateImage(0, props.updatedManifest, props.pageContext, props.currentStep);
  };

  return (
    <React.Fragment>
      <div ref={containerRef} style={{ position: "relative" }} id={"iga-standard-quiz-graphic-container"}>
        <div
          className="iga-interactive-graphic-container interactive-hovering"
          onClick={(e: React.MouseEvent<HTMLDivElement>) => addStandardQuizGraphic(e)}
        >
          {!currentImagePath || currentImagePath?.length === 0 ? (
            <EmptyImage displayText={displayText} imageOnly={true} />
          ) : (
            <React.Fragment>
              <div className="img-aspect-container">
                <div className="aspect-container-inside">
                  <div className="aspect-centering">
                    <img
                      className="iga-graphicImage"
                      src={blobHelper(currentImagePath)}
                      alt=""
                      id={"quiz-image-0"}
                      onLoad={() => handleImageLoad()}
                    />
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
        {annotationProps.annotations && annotationProps.annotations.length > 0 ? (
          <Annotation {...annotationProps} />
        ) : (
          <></>
        )}
        {annotationProps.symbols && annotationProps.symbols.length > 0 ? (
          <Symbol
            kp={annotationProps.kp}
            symbols={annotationProps.symbols as ISymbolV2[]}
            handleKeyPress={handleKeyPress}
            markSelected={annotationProps.markSelected}
          />
        ) : (
          <></>
        )}
        {moveableProps.target ? <MoveableClass {...moveableProps} /> : <></>}
      </div>
      <div id="iga-standard-quiz-container">
        <div id="iga-standard-quiz-question-container">
          <label>Question:</label>
          <textarea
            value={question}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleQuestionEntry(e.currentTarget.value)}
          ></textarea>
        </div>
        <div id="iga-standard-quiz-answer-container">
          <div>
            <label>Select the Correct Answer:</label>
            {props.warning && <label>Max characters: 60</label>}
          </div>
          {displayAnswers()}
        </div>
        <div id="iga-quiz-add-answer-container">
          {displayAddButton()}
          {displayRemoveButton()}
        </div>
      </div>
    </React.Fragment>
  );
};

export default IGAStandardQuiz;
