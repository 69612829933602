import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { IPageContext, PageContext } from "../../../routes/builderContexts";
import "./SelectAircraftLiveryModal.css";
import "../../Datatable/dataTable.css";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download-solid.svg";
import genericRepositoryService from "../../../services/genericRepositoryService";
import IAsset from "../../../models/IAsset";
import fileDownload from "js-file-download";
import UploadLiveryModal from "./UploadLiveryModal";
import Datatable from "../../Datatable/Datatable";

export interface AircraftLiveryModalProps {
  show: boolean;
  lessonMetaData: any;
  currentlyDisplayedPageIndex: any;
  pages: any;
  saveLesson: any;
}

const SelectAircraftLiveryModal = (props: AircraftLiveryModalProps) => {
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const [liveries, setLiveries] = useState<IAsset[]>([]);
  const [activeRow, setActiveRow] = useState<number>(0);
  const [blobPath, setBlobPath] = useState<string>("");
  const [disableDownload, setDisableDownload] = useState<boolean>(true);
  const [imgDims, setImgDims]: [{ h: number; w: number }, Dispatch<SetStateAction<{ h: number; w: number }>>] =
    useState<{ h: number; w: number }>({ h: -1, w: -1 });
  const [liveryFileToUpload, setLiveryFileToUpload] = useState<HTMLInputElement>();
  const [selectedFile, setSelectedFile] = useState<File>();

  const [showModals, setShowModals] = useState({
    select: false,
    upload: false,
  });

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const errorMessage = useRef<HTMLLabelElement>(null);

  const defaultColumns = [
    {
      key: "assetVersionId",
      label: "AssetVersionId",
      width: 0,
      hidden: true,
    },
    {
      key: "blobPath",
      label: "BlobPath",
      width: 0,
      hidden: true,
    },
    {
      key: "description",
      label: "Description",
      fixed: true,
      width: 348,
    },
    {
      key: "dateUploaded",
      label: "Date Uploaded",
      fixed: true,
      width: 348,
    },
    {
      key: "timeUploaded",
      label: "Time",
      width: 232,
      fixed: true,
    },
  ];

  const [columnKeys] = useState(defaultColumns.map((column) => column.key));

  const columns = defaultColumns.filter((column) => columnKeys.some((key) => key === column.key));

  useEffect(() => {
    async function getLiveries() {
      await genericRepositoryService.getAircraftLiveries(props.lessonMetaData.aircraftId).then((result) => {
        setLiveries(result.data.assets);
      });
    }

    if (props.show) {
      getLiveries().then(() => {
        setShowModals({
          ...showModals,
          select: true,
        });
      });
    }
  }, [props.show, props.lessonMetaData.aircraftId]);

  useEffect(() => {
    if (
      props.lessonMetaData &&
      props.lessonMetaData.additionalSettings &&
      props.lessonMetaData.additionalSettings.clientCustomizations &&
      props.lessonMetaData.additionalSettings.clientCustomizations.waLivery
    ) {
      setActiveRow(props.lessonMetaData.additionalSettings.clientCustomizations.waLivery.waLiveryAssetVersionId);
    }
  }, [liveries, props.lessonMetaData]);

  useEffect(() => {
    liveries.forEach((element) => {
      if (element.assetVersionId === activeRow) {
        setBlobPath(element.blobPath);
        setDisableDownload(!element.blobPath.includes("template"));
      }
    });
  }, [activeRow, liveries]);

  const HandleCancel = () => {
    pageContext.showLiveryEditor(false);
    resetModal();
  };

  function resetModal() {
    setActiveRow(0);
    setLiveries([]);
    setShowModals({
      upload: false,
      select: false,
    });
  }

  const ShowLiveryChoices = () => {
    const table = buildTable(liveries);

    return table;
  };

  async function downloadTemplate() {
    if (activeRow) {
      const livery = blobPath.split("/");
      const fileName = livery[livery.length - 1];
      await genericRepositoryService.downloadAsset(blobPath).then((results) => {
        const blob = new Blob([results.data as unknown as string]);

        fileDownload(blob, fileName, "image/png");
      });
    }
  }

  const ApplySelectedLivery = async () => {
    if (activeRow) {
      if (
        props.lessonMetaData &&
        props.lessonMetaData.additionalSettings &&
        props.lessonMetaData.additionalSettings.clientCustomizations &&
        props.lessonMetaData.additionalSettings.clientCustomizations.waLivery
      ) {
        props.lessonMetaData.additionalSettings.clientCustomizations.waLivery.waLiveryAssetVersionId = activeRow;
        props.lessonMetaData.additionalSettings.clientCustomizations.waLivery.waModelAssetVersionId =
          props.pages[props.currentlyDisplayedPageIndex].pageManifest.modelSet.assetVersionId;
      } else {
        props.lessonMetaData.additionalSettings.clientCustomizations = {
          waLivery: {
            waLiveryAssetVersionId: activeRow,
            waModelAssetVersionId: props.pages[props.currentlyDisplayedPageIndex].pageManifest.modelSet.assetVersionId,
          },
        };
      }

      // meant to add the blob path for the texture to the page manifest
      liveries.forEach((element) => {
        if (element.assetVersionId === activeRow) {
          props.pages[props.currentlyDisplayedPageIndex].pageManifest.modelSet.liveryBlobPath = element.blobPath;
          pageContext.updatePageManifest(props.pages[props.currentlyDisplayedPageIndex].pageManifest);
        }
      });
      HandleCancel();

      props.saveLesson(props.pages, props.lessonMetaData);
    }
  };

  const handleOnRowClick = (rowData: any) => {
    setActiveRow(rowData.assetVersionId);
  };

  const handleNewOnClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const getRowClassName = (rowData: any): string => {
    if (rowData && rowData.assetVersionId && rowData.assetVersionId === activeRow) return "row-selected";
    else return "";
  };

  function buildTable(this: any, liveries: IAsset[]): JSX.Element {
    return (
      <Datatable
        Columns={columns}
        TableClassName={"livery-table"}
        TableData={liveries}
        RowClassName={getRowClassName}
        HandleOnRowClick={handleOnRowClick}
      />
    );
  }

  const handleFileUpload = async () => {
    setShowModals({
      ...showModals,
      select: false,
    });
    if (
      hiddenFileInput &&
      hiddenFileInput.current &&
      hiddenFileInput.current.files &&
      hiddenFileInput.current.files.length > 0
    ) {
      setLiveryFileToUpload(hiddenFileInput.current);
      setSelectedFile(hiddenFileInput.current.files[0]);
      getImageDims(hiddenFileInput.current.files[0]);
    }
  };

  useEffect(() => {
    if (selectedFile) {
      if (imgDims && selectedFile.name.toLowerCase().includes(".png")) {
        if (imgDims.w !== imgDims.h) {
          if (errorMessage && errorMessage.current) {
            errorMessage.current.innerText = "";
            errorMessage.current.innerText = "*The image must be square.";
          }

          setShowModals({
            upload: false,
            select: true,
          });

          return;
        }

        setShowModals({
          upload: true,
          select: false,
        });

        return;
      }

      if (errorMessage && errorMessage.current) {
        errorMessage.current.innerText = "";
        errorMessage.current.innerText = "*Please upload a .png file.";
      }

      setShowModals({
        upload: false,
        select: true,
      });

      return;
    }
  }, [imgDims, selectedFile]);

  const getImageDims = (e: any) => {
    const _URL = window.URL || window.webkitURL;
    let image: any;
    const file = e;

    image = new Image();
    image.onload = () => {
      setImgDims({ h: image.height, w: image.width });
    };
    image.src = _URL.createObjectURL(file);
  };

  function displayError() {
    return (
      <div className={"error-message-container"}>
        <label ref={errorMessage} className={"error-message"}></label>
      </div>
    );
  }

  return (
    <>
      <UploadLiveryModal
        setShowModals={setShowModals}
        showModals={showModals}
        liveryFileToUpload={liveryFileToUpload as HTMLInputElement}
        showUploadModal={showModals.upload}
        handleSelectCancel={HandleCancel}
        lessonMetaData={props.lessonMetaData}
        currentlyDisplayedPageIndex={props.currentlyDisplayedPageIndex}
        pages={props.pages}
        saveLesson={props.saveLesson}
      />
      <Modal
        show={showModals.select}
        dialogClassName={"livery-modal"}
        backdrop={"static"}
        keyboard={false}
        bsPrefix={"livery"}
        onHide={HandleCancel}
      >
        <Modal.Header closeButton>
          <Modal.Title className={"livery-title"}>
            <p>Aircraft Livery</p>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body bsPrefix={"livery-body"}>
          <div>
            <label className={"livery-label"}>Select Aircraft Design:</label>
          </div>

          <ShowLiveryChoices />

          <div>
            <label className={"sub-livery-label"}>
              *Selected aircraft livery will be the default for any Walk Around page
            </label>
          </div>

          <hr />

          <div>
            <label className={"livery-label"}>Add new Aircraft Design:</label>
          </div>
          <div className="add-livery-modal-buttons">
            <button disabled={disableDownload} onClick={downloadTemplate}>
              <DownloadIcon />
              Download Template
            </button>
            <button onClick={handleNewOnClick} disabled={liveries.length < 1}>
              Upload New
            </button>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleFileUpload}
              style={{ display: "none" }}
              accept={".png"}
            />
          </div>
          <div>
            <label className={"sub-livery-label"}>*Height and Width dimensions must be identical</label>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="livery-modal-buttons">
            {displayError()}
            <button onClick={HandleCancel}>Cancel</button>
            <button disabled={!activeRow} onClick={ApplySelectedLivery}>
              Save
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SelectAircraftLiveryModal;
