import React from "react";
import { SymbolComponentType } from "../helpers/getSymbol";

const VOR2PointerHead = (props: SymbolComponentType) => {
  const { index, attributes, transformStr } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 7.96 15.57"
      key={index}
      id={`symbol-${index}`}
      className="symbol target"
      name="symbol"
      tabIndex={0}
      preserveAspectRatio="xMidYMid meet"
      style={{
        top: `${attributes.top}%`,
        left: `${attributes.left}%`,
        width: `${attributes.width}%`,
        height: `${attributes.height}%`,
        zIndex: attributes.zIndex,
        // transform:`rotate(${attributes?.transform?.rotate}deg) scaleX(${attributes?.transform?.scaleX}) scaleY(${attributes?.transform?.scaleY}) matrix3d(${attributes?.transform?.matrix3d})`
        transform: transformStr,
      }}
    >
      <path
        // className="vor_2_pointer_head"
        stroke={`${attributes.borderColor || "white"}`}
        fill={`${attributes?.backgroundColor || "transparent"}`}
        strokeWidth={`${attributes.lineWidth || 0.1}`}
        d="M 2.6,1.9 4.18,0.48 5.55,1.9 v 9.16 l -1.48,-0.6 -1.47,0.6 z"
        id="polygon6"
      />
      <path
        // className="vor_2_pointer_head_2"
        stroke={`${attributes.borderColor || "white"}`}
        fill={`${attributes?.backgroundColor || "transparent"}`}
        strokeWidth={`${attributes.lineWidth || 0.1}`}
        d="m 7.6210937,12.076172 -3.5820312,-1.46875 -3.68945312,1.320312 0,3.121094 3.62109372,-1.168393 3.6796875,1.168393 z"
        id="path844"
      />
    </svg>
  );
};

export default VOR2PointerHead;
