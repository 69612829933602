import { FunctionComponent, SVGProps } from "react";
import { ReactComponent as aircraft } from "./symbols/Aircraft_symbol_(W).svg";
import { ReactComponent as aircraft_1 } from "./symbols/Aircraft_symbol_(W)_1.svg";
import { ReactComponent as current_heading } from "./symbols/Current_heading_pointer_(W).svg";
import { ReactComponent as current_track } from "./symbols/Current_track_(W).svg";
import { ReactComponent as distance } from "./symbols/Distance_(G).svg";
import { ReactComponent as dme_tacan } from "./symbols/DME_TACAN.svg";
import { ReactComponent as expanded_compass } from "./symbols/Expanded_compass_(W).svg";
import { ReactComponent as glideslope } from "./symbols/Glideslope_pointer.svg";
import { ReactComponent as ils_localizer } from "./symbols/ILS_localizer.svg";
import { ReactComponent as line_m } from "./symbols/Line_(M).svg";
import { ReactComponent as course_pointer_1 } from "./symbols/Selected_course_pointer_(W).svg";
import { ReactComponent as course_pointer_2 } from "./symbols/Selected_Course_pointer-1.svg";
import { ReactComponent as course_pointer_3 } from "./symbols/Selected_Course_pointer-2.svg";
import { ReactComponent as course_pointer_4 } from "./symbols/Selected_Course_pointer-3.svg";
import { ReactComponent as heading_bug } from "./symbols/Selected_heading_bug_(M).svg";
import { ReactComponent as track_line } from "./symbols/Track_Line_and_range_scale_(W).svg";
import { ReactComponent as vor_1_head } from "./symbols/VOR_1_pointer_head.svg";
import { ReactComponent as vor_1_tail } from "./symbols/VOR_1_pointer_tail.svg";
import { ReactComponent as vor_2_head } from "./symbols/VOR_2_pointer_head.svg";
import { ReactComponent as vor_2_tail } from "./symbols/VOR_2_pointer_tail.svg";
import { ReactComponent as vor } from "./symbols/VOR.svg";
import { ReactComponent as vortac } from "./symbols/VORTAC.svg";

export type SymbolObject = {
  name: string;
  icon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
};

export const Symbols: SymbolObject[] = [
  {
    name: "aircraft",
    icon: aircraft,
  },
  {
    name: "aircraft_1",
    icon: aircraft_1,
  },
  {
    name: "current_heading",
    icon: current_heading,
  },
  {
    name: "current_track",
    icon: current_track,
  },
  {
    name: "distance",
    icon: distance,
  },
  {
    name: "dme_tacan",
    icon: dme_tacan,
  },
  {
    name: "expanded_compass",
    icon: expanded_compass,
  },
  {
    name: "glideslope",
    icon: glideslope,
  },
  {
    name: "ils_localizer",
    icon: ils_localizer,
  },
  {
    name: "line_m",
    icon: line_m,
  },
  {
    name: "course_pointer_1",
    icon: course_pointer_1,
  },
  {
    name: "course_pointer_2",
    icon: course_pointer_2,
  },
  {
    name: "course_pointer_3",
    icon: course_pointer_3,
  },
  {
    name: "course_pointer_4",
    icon: course_pointer_4,
  },
  {
    name: "heading_bug",
    icon: heading_bug,
  },
  {
    name: "track_line_range",
    icon: track_line,
  },
  {
    name: "vor_1_head",
    icon: vor_1_head,
  },
  {
    name: "vor_1_tail",
    icon: vor_1_tail,
  },
  {
    name: "vor_2_head",
    icon: vor_2_head,
  },
  {
    name: "vor_2_tail",
    icon: vor_2_tail,
  },
  {
    name: "vor",
    icon: vor,
  },
  {
    name: "vortac",
    icon: vortac,
  },
];
