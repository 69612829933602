import { IActionPayload } from "../actions/actionPayloadType";

export interface IAuthorizeReducer {
  isAuthenticated: boolean;
  canManageUsers: boolean;
  lmsKey?: number;
  isCpatUser: boolean;
}

const initalState: IAuthorizeReducer = {
  isAuthenticated: false,
  canManageUsers: false,
  isCpatUser: false,
};

export const authorizeReducer = (state = initalState, action: IActionPayload<IAuthorizeReducer>) => {
  return state;
};
