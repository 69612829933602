import { Quill } from "react-quill-2";

const EmbedBlot = Quill.import("blots/block/embed");
// This BLOT ignores any attempt of converting the value into Quill Deltas and leave it as plain HTML
// it will prevent Quill from functioning on this block, no styling/customization will be possible
export class CustomKeepHTMLBlot extends EmbedBlot {
  static create(node: string) {
    return node;
  }
  static value(node: string) {
    return node;
  }
}

CustomKeepHTMLBlot.blotName = "CustomKeepHTML";
CustomKeepHTMLBlot.className = "custom-keep-html";
CustomKeepHTMLBlot.tagName = "div";

export const register = (QuillInstance = Quill) => {
  QuillInstance.register(CustomKeepHTMLBlot);
};
