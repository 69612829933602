import _ from "lodash";
import { BoundType } from "../../../../../components/react-moveable";
import { IBasePageVideoV2 } from "../../IBasePage";
import { CustomPageElement } from "./CustomPageElement";

export class CustomVideoElement extends CustomPageElement {
  index: number;
  videoList: IBasePageVideoV2;
  bounds: BoundType;
  fullList: any[];
  constructor(index: number, videoList: any, bounds: BoundType, fullList: Array<any>, objectId: number) {
    super(videoList, `video${objectId}`);
    this.newObject = {
      path: "",
      assetVersionId: 0,
      ...this.newObject,
    };
    this.newObject = {
      ...this.newObject,
      height: 40,
      width: 40,
      top: 0,
      left: 0,
      zIndex: 1,
      type: "video",
      // objectId: objectId,
    };

    this.index = index;
    this.videoList = videoList as unknown as IBasePageVideoV2;
    this.bounds = bounds;
    this.fullList = fullList;
  }

  public addNewCustomPageVideo() {
    this.newObject.name = `video-${this.newObject.objectId}`;
    return this.newObject;
  }

  public updateVideoAttributes() {
    this.videoList = {
      path: this.videoList.path,
      assetVersionId: this.videoList.assetVersionId,
      ...(this.videoList as any).attributes,
    };
    if (!_.has(this.videoList, "name") || this.videoList.name === "")
      this.videoList.name = `video-${this.videoList.assetVersionId}`;
    if (!_.has(this.videoList, "type")) this.videoList.type = "video";
    return this.videoList;
  }
}
