import React from "react";

export default function Preview(props: any) {
  const lessonId: number = props.lessonId;

  return (
    <div>
      <p>you are previewing {lessonId}</p>
    </div>
  );
}
