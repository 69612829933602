import React, { PropsWithChildren, useEffect, useLayoutEffect } from "react";
import { ProvidePointerCapturing } from "../../contexts/TimelineProvider/PointerCapturing";
import PointerEventsHandler from "../../contexts/TimelineProvider/PointerEventsHandler";
import { useTimeline, useTimelineDispatch } from "../../contexts/TimelineProvider/TimelineProvider";
import { useDomRef } from "../../hooks/useDomRef";
import GridBackground from "./GridBackground";
import Left from "./Left/Left";
import Right from "./Right/Right";
import RightOverlay from "./Right/RightOverlay";
import LeftOverlay from "./Left/LeftOverlay";
import "./TimelinePanel.css";
import VerticalScrollContainer, { useReceiveVerticalWheelEvent } from "./VerticalScrollContainer";
import useRefAndState from "../../hooks/useRefAndState";
import { useAudioManagerStore } from "../../contexts/PageAudioManager";
import { useTimer } from "../../contexts/Timer";
import { useObjectsState } from "../../contexts/ObjectsProvider";
import { useShallow } from "zustand/react/shallow";

export const TOP_STRIP_HEIGHT = 42;
export const WAVE_HEIGHT = 22;
export const OBJECT_ROW_HEIGHT = 28;
export const BAR_HEIGHT = OBJECT_ROW_HEIGHT - 1;
export const zIndexes = (() => {
  const s = {
    rightBackground: 0,
    scrollableArea: 0,
    rightOverlay: 0,
    lengthIndicatorCover: 0,
    lengthIndicatorStrip: 0,
    playhead: 0,
    currentFrameStamp: 0,
    marker: 0,
    horizontalScrollbar: 0,
  };

  // sort the z-indexes
  let i = -1;
  for (const key of Object.keys(s)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    s[key] = i;
    i++;
  }

  return s;
})();
function RightSideContainer({ children }: PropsWithChildren) {
  const [divRef, setDivRef] = useDomRef();
  const [, dispatch] = useTimeline();
  const [clearSelection] = useAudioManagerStore(useShallow((state) => [state.clearSelection]));

  useEffect(() => {
    if (divRef) {
      dispatch({ type: "SET_WIDTH_PX", payload: divRef.clientWidth });
      dispatch({ type: "SET_HEIGHT_PX", payload: divRef.clientHeight });
    }
  }, [divRef?.clientWidth, divRef?.clientHeight]);

  return (
    <div
      ref={setDivRef}
      className="right-side-container"
      style={{
        width: "70%",
        height: "100%",
        position: "absolute",
        right: 0,
      }}
      onClick={() => {
        // This is safe as long as we prevent event propagation.
        // This mean that anywhere we click in the timeline that is not a AudioBar or is not
        //  preventing propagation, the event will bubble until here, meaning we probably clicked
        //  inside an empty area.
        clearSelection();
      }}
    >
      {children}
    </div>
  );
}
export default function TimelinePanel() {
  const timelineDispatch = useTimelineDispatch();
  const { selectedObjects } = useObjectsState();
  const [selectedAudio, clearSelection] = useAudioManagerStore(
    useShallow((state) => [state.selectedAudio, state.clearSelection]),
  );
  const Timer = useTimer();

  // FIXME: Perfect would be if we could have a SELECTION context
  //  but right now it is too tied to Objects context.
  //  for now we listen to objects selection and unselect audio
  //  it is costly but will solve the issue right now, we need a hero to refactor the
  //  selection context to hold multiple selections from multiple contexts
  useEffect(() => {
    if (selectedAudio && selectedObjects.length > 0) {
      clearSelection();
    }
  }, [selectedAudio, selectedObjects]);

  // SCRUB POSITION UPDATE
  useEffect(() => {
    const tickCallback = (time: number) => {
      timelineDispatch({
        type: "SET_SECONDS",
        payload: time / 1000,
      });
    };
    Timer.onTick(tickCallback);

    return () => {
      Timer.offTick(tickCallback);
    };
  }, []);

  return (
    <ProvidePointerCapturing>
      <PointerEventsHandler>
        <div className="timeline-panel-container">
          <div className="relative-container">
            <div className="left-background"></div>
            <GridBackground />

            <VerticalScrollContainer>
              <LeftScrollableArea />
              <Left />

              <RightSideContainer>
                <Right />
              </RightSideContainer>
            </VerticalScrollContainer>
            <LeftOverlay />
            <RightOverlay />
          </div>
        </div>
      </PointerEventsHandler>
    </ProvidePointerCapturing>
  );
}
const listenerOptions = {
  capture: true,
  passive: false,
};
function LeftScrollableArea() {
  const [ref, refState] = useRefAndState<HTMLDivElement | null>(null);
  const receiveVerticalWheelEvent = useReceiveVerticalWheelEvent();

  useLayoutEffect(() => {
    if (!refState) {
      return;
    }
    const handleWheel = (e: WheelEvent) => {
      receiveVerticalWheelEvent(e);
      e.preventDefault();
      e.stopPropagation();
    };
    refState.addEventListener("wheel", handleWheel, listenerOptions);

    return () => {
      refState.removeEventListener("wheel", handleWheel, listenerOptions);
    };
  }, [refState]);

  return (
    <div
      ref={ref}
      style={{
        position: "absolute",
        pointerEvents: "all",
        width: "30%",
        height: "100%",
        left: "0",
        top: "0",
      }}
    ></div>
  );
}
