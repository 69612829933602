import React from "react";
import { errorState } from "../state";
interface FooterProps {
  ableToEdit: boolean;
  readonly: boolean;
  setReadonly: (value: boolean) => void;
  dataHasChanged: boolean;
  submitAsset: () => void;
  showConfirmDialog: () => void;
  errorState?: errorState;
}
export const Footer = ({
  ableToEdit,
  readonly,
  setReadonly,
  dataHasChanged,
  submitAsset,
  showConfirmDialog,
  errorState,
}: FooterProps) => {
  const handleDiscardChanges = () => {
    if (dataHasChanged) {
      return showConfirmDialog();
    } else {
      return setReadonly(!readonly);
    }
  };
  return (
    <div className="assetManager-metadataeditor-footer">
      <div className="assetManager-metadataeditor-errormsg">
        {errorState?.hasError && <p> {errorState.errorMessage}</p>}
      </div>
      <div className="assetManager-metadataeditor-footer-btns">
        {ableToEdit && (
          <>
            {readonly && (
              <button
                className="assetManager-button gray"
                onClick={() => setReadonly(!readonly)}
                style={{ marginLeft: "auto" }}
              >
                Edit Data
              </button>
            )}
            {!readonly && (
              <button
                className="assetManager-button white"
                onClick={() => handleDiscardChanges()}
                style={{
                  marginLeft: dataHasChanged ? "" : "auto",
                  marginRight: dataHasChanged ? "15px" : "",
                }}
              >
                {`${dataHasChanged ? "Discard changes" : "Cancel"}`}
              </button>
            )}
          </>
        )}
        {!readonly && (
          <>
            {dataHasChanged && (
              <button
                onClick={() => {
                  if (errorState?.hasError) return;
                  submitAsset();
                }}
                className={`assetManager-button ${errorState?.hasError ? "disabled" : "gray"}`}
                style={{
                  marginRight: "0",
                }}
              >
                Save Changes
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Footer;
