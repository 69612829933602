import React from "react";
import "./ScorePage.css";
import advancedScoring from "../../assets/icons/lp-scoring-page-detail-view-icon.png";
import { determinePageBG } from "../../contexts/PageColorProvider/PageColorProvider";
import { PageContext } from "../../routes/builderContexts";
const ScorepagePlayer: React.FC = () => {
  let passfailstyle;
  // let passorfail = "Undetermined";
  const passorfail = "PASS";
  switch (passorfail) {
    case "PASS":
      passfailstyle = "scorePass";
      break;
    case "FAIL":
      passfailstyle = "scoreFail";
      break;
    case "Undetermined":
      passfailstyle = "scoreUndetermined";
      break;
    default:
      break;
  }
  const pageContext = React.useContext(PageContext);
  const theGuts = (
    <div
      id="scorecontainer"
      style={
        pageContext.pageManifest.theme
          ? {
              background: determinePageBG(pageContext.pageManifest.theme),
            }
          : {}
      }
    >
      <div className="scoreStatusContainer">
        <div className="detailviewbutton">
          <img alt="scoring page detail" src={advancedScoring} />
          Detail View
        </div>
        <div className="scoreStatus">
          Status: <span className={passfailstyle}>{passorfail}</span>
        </div>
      </div>
      <div className="scoreDataContainer">
        <div className="score-page-row">
          <div className="scoreDataLabel">Questions correctly answered:</div>
          <div className="scoreDataValue">7</div>
        </div>
        <hr className="scoreDetailsSeparator" />
        <div className="score-page-row">
          <div className="scoreDataLabel">Total questions:</div>
          <div className="scoreDataValue">10</div>
        </div>
        <hr className="scoreDetailsSeparator" />
        <div className="score-page-row">
          <div className="scoreDataLabel">Percent correct:</div>
          <div className="scoreDataValue">70%</div>
        </div>
      </div>
    </div>
  );
  //pof var change name
  return <div id="scoreplayer">{theGuts}</div>;
};

export default ScorepagePlayer;
