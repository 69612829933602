import React from "react";

function AssetLibraryListViewLoading() {
  return (
    <div className="assetLibrary-list-view-loading">
      <div className="assetLibrary-list-view-header-loading">
        <div></div>
        <div></div>
      </div>

      <div className="assetLibrary-list-view-content-loading">
        <div className="assetLibrary-single-asset-container-loading-higerIndexWrap">
          <div className="loading-asset-border">
            <div></div>
            {/*Do Not Delete used for border*/}
            <div></div>
            {/*Do Not Delete used for border*/}
          </div>
          <div className="assetLibrary-single-asset-container-loading">
            <div className="assetLibrary-list-view-content-name-loading">
              <div className="thumb-loading" />
              <div className="assetLibrary-listView-asset-name-loading" />
            </div>
            <div className="assetLibrary-list-view-content-description-loading">
              <div className="assetLibrary-listView-asset-description-loading" />
            </div>
          </div>
        </div>

        <div className="assetLibrary-single-asset-container-loading-higerIndexWrap">
          <div className="loading-asset-border">
            <div></div>
            {/*Do Not Delete used for border*/}
            <div></div>
            {/*Do Not Delete used for border*/}
          </div>
          <div className="assetLibrary-single-asset-container-loading">
            <div className="assetLibrary-list-view-content-name-loading">
              <div className="thumb-loading" />
              <div className="assetLibrary-listView-asset-name-loading" />
            </div>
            <div className="assetLibrary-list-view-content-description-loading">
              <div className="assetLibrary-listView-asset-description-loading" />
            </div>
          </div>
        </div>

        <div className="assetLibrary-single-asset-container-loading-higerIndexWrap">
          <div className="loading-asset-border">
            <div></div>
            {/*Do Not Delete used for border*/}
            <div></div>
            {/*Do Not Delete used for border*/}
          </div>
          <div className="assetLibrary-single-asset-container-loading">
            <div className="assetLibrary-list-view-content-name-loading">
              <div className="thumb-loading" />
              <div className="assetLibrary-listView-asset-name-loading" />
            </div>
            <div className="assetLibrary-list-view-content-description-loading">
              <div className="assetLibrary-listView-asset-description-loading" />
            </div>
          </div>
        </div>

        <div className="assetLibrary-single-asset-container-loading-higerIndexWrap">
          <div className="loading-asset-border">
            <div></div>
            {/*Do Not Delete used for border*/}
            <div></div>
            {/*Do Not Delete used for border*/}
          </div>
          <div className="assetLibrary-single-asset-container-loading">
            <div className="assetLibrary-list-view-content-name-loading">
              <div className="thumb-loading" />
              <div className="assetLibrary-listView-asset-name-loading" />
            </div>
            <div className="assetLibrary-list-view-content-description-loading">
              <div className="assetLibrary-listView-asset-description-loading" />
            </div>
          </div>
        </div>

        <div className="assetLibrary-single-asset-container-loading-higerIndexWrap">
          <div className="loading-asset-border">
            <div></div>
            {/*Do Not Delete used for border*/}
            <div></div>
            {/*Do Not Delete used for border*/}
          </div>
          <div className="assetLibrary-single-asset-container-loading">
            <div className="assetLibrary-list-view-content-name-loading">
              <div className="thumb-loading" />
              <div className="assetLibrary-listView-asset-name-loading" />
            </div>
            <div className="assetLibrary-list-view-content-description-loading">
              <div className="assetLibrary-listView-asset-description-loading" />
            </div>
          </div>
        </div>

        <div className="assetLibrary-single-asset-container-loading-higerIndexWrap">
          <div className="loading-asset-border">
            <div></div>
            {/*Do Not Delete used for border*/}
            <div></div>
            {/*Do Not Delete used for border*/}
          </div>
          <div className="assetLibrary-single-asset-container-loading">
            <div className="assetLibrary-list-view-content-name-loading">
              <div className="thumb-loading" />
              <div className="assetLibrary-listView-asset-name-loading" />
            </div>
            <div className="assetLibrary-list-view-content-description-loading">
              <div className="assetLibrary-listView-asset-description-loading" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssetLibraryListViewLoading;
