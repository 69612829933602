import httpHandler from "./httpHandlerService";
import { ILoginResponse } from "../models/ILogin/ILogin";
import IGetAllLessonsResponse from "../models/IGetAllLessonsResponse";
import IApiResponse from "../models/IApiResponse";
// import ICreateLessonRequest from '../models/ICreateLessonRequest';
import { ICreateLessonResponse } from "../models/ICreateLessonResponse";
import IOpenLessonRequest from "../models/IOpenLessonRequest";
import IOpenLessonResponse from "../models/IOpenLessonResponse";
import IGetAllImagesResponse from "../models/IGetAllImagesResponse";
import IGetNewPageResponse from "../models/IGetNewPageResponse";
import IGetNewPageRequest from "../models/IGetNewPageRequest";
import IGetAircraftLessonData from "../models/IGetAircraftLessonData";
import IGetLessonStyle from "../models/IGetLessonStyle";
import IGetLessonModes from "../models/IGetLessonModes";
import IGetATAData from "../models/IGetATAData";
import IGetAllVideosResponse from "../models/IGetAllVideosResponse";
import IGetAllPdfResponse from "../models/IGetAllPdfResponse";
import IGetThreeDModel from "../models/IGetThreeDModel";
import IGetWalkaroundModels from "../models/IGetWalkaroundModel";
import IGetCabinModels from "../models/IGetCabinModels";
import IGetFilteredAssetsRequest from "../models/IGetFilteredAssets/IGetFilteredAssetsRequest";
import IGetFilteredAssetsResponse from "../models/IGetFilteredAssets/IGetFilteredAssetsResponse";
import IGetLessonTypes from "../models/IGetLessonTypes/IGetLessonTypes";
import {
  ICreateNewLessonWithMetadataRequest,
  ICreateNewLessonWithMetadataResponse,
} from "../models/ICreateNewLessonWithMetadata/ICreateNewLessonWithMetadata";
import { IExportLessonRequest, IExportLessonResponse } from "../models/IExportLesson";
import { IUpdateLessonRequest, IUpdateLessonResponse } from "../models/UpdateLessonApiInterfaces";
import blobUrlFormatHelper from "../components/blobUrlFormatHelper";
import { IUploadAssetResponse } from "../models/IUploadAssetResponse";
// import { lookup } from 'dns';
import { IGetFmsModelsResponse, ICollectionAssetResponse } from "../models/IGetFmsModelsResponse";
// import { IProfileResponse } from '../models/IProfileResponse';
import { IGetNewLessonDependenciesResponse } from "../models/GetNewLessonDependenciesResponse";
import { AxiosRequestConfig } from "axios";
import { IGenerateAudioRequest } from "../models/IGenerateAudioRequest";
import { ISupportedVoiceLanguageResponse } from "../models/ISupportedVoiceLanguageResponse";
import { ISupportedVoiceResponse } from "../models/ISupportedVoiceResponse";
import { ISupportedVoiceRequest } from "../models/ISupportedVoiceRequest";
import { IMachineTranslationRequest } from "../models/IMachineTranslationRequest";
import { IMachineTranslationResponse } from "../models/IMachineTranslationResponse";
import { IAutoDetectLanguageRequest } from "../models/IAutoDetectLanguageRequest";
import { IAutoDetectLanguageResponse } from "../models/IAutoDetectLanguageResponse";
import { ISupportedTranslationLanguageResponse } from "../models/ISupportedTranslationLanguageResponse";
import IGetAircraftLiveryResponse from "../models/IGetAircraftLiveryResponse";
import IDownloadAssetResponse from "../models/IDownloadAssetResponse";
import { ICloseLessonRequest } from "../models/ICloseLessonRequest";
import ILogErrorRequest from "../models/ILogErrorRequest";
import IGetLessonPagesRequest from "../models/IGetLessonPagesRequest";
import IGetLessonPagesResponse from "../models/IGetLessonPagesResponse";

// import { argv } from 'process';

export interface IGenericRepositoryService {
  login(): Promise<IApiResponse<ILoginResponse>>;
  getAllLessons(): Promise<IApiResponse<IGetAllLessonsResponse>>;
  getAccessibleLessons(): Promise<IApiResponse<IGetAllLessonsResponse>>;
  createNewLessonWithMetadata(
    request: ICreateNewLessonWithMetadataRequest,
  ): Promise<IApiResponse<ICreateNewLessonWithMetadataResponse>>;
  createNewLessonFromFileWithMetadata(request: FormData): Promise<IApiResponse<ICreateNewLessonWithMetadataResponse>>;
  copyLesson(request: number): Promise<IApiResponse<ICreateLessonResponse>>;
  openLesson(request: IOpenLessonRequest): Promise<IApiResponse<IOpenLessonResponse>>;
  updateLesson(request: IUpdateLessonRequest): Promise<IApiResponse<IUpdateLessonResponse>>;
  addLessonPage(request: any): Promise<IApiResponse<IGetNewPageResponse>>;
  exportLesson(request: IExportLessonRequest): Promise<IApiResponse<IExportLessonResponse>>;
  stagePreviewLesson(request: any): Promise<IApiResponse<any>>;
  blobFetch<TResponse>(relativeUrl: string): Promise<IApiResponse<TResponse>>;
  blobDetect<TResponse>(relativeUrl: string): Promise<IApiResponse<TResponse>>;
  uploadAsset(assetInformation: FormData): Promise<IApiResponse<IUploadAssetResponse>>;
  getAllImages(): Promise<IApiResponse<IGetAllImagesResponse>>;
  getAllPdf(): Promise<IApiResponse<IGetAllPdfResponse>>;
  getAllVideos(): Promise<IApiResponse<IGetAllVideosResponse>>;
  getAircraftLessonData(): Promise<IApiResponse<IGetAircraftLessonData>>;
  getATAData(): Promise<IApiResponse<IGetATAData>>;
  getFmsModels(
    aircraftId: number | null,
    type?: "manufacturer" | "aircraft" | "aircraftfamily",
  ): Promise<IApiResponse<IGetFmsModelsResponse>>;
  getAssetFromCollection(collectionAssetVersionId: number): Promise<IApiResponse<ICollectionAssetResponse>>;
  getLessonTypes(): Promise<IApiResponse<IGetLessonTypes>>;
  getLessonStyle(): Promise<IApiResponse<IGetLessonStyle>>;
  getLessonModes(): Promise<IApiResponse<IGetLessonModes>>;
  getThreeDModel(
    id: number | null,
    type?: "manufacturer" | "aircraft" | "aircraftfamily",
  ): Promise<IApiResponse<IGetThreeDModel>>;
  getWalkaroundModels(
    id: number | null,
    type?: "manufacturer" | "aircraft" | "aircraftfamily",
  ): Promise<IApiResponse<IGetWalkaroundModels>>;
  getCabinModels(
    id: number | null,
    type?: "manufacturer" | "aircraft" | "aircraftfamily",
  ): Promise<IApiResponse<IGetCabinModels>>;
  getNewLessonInformation(): Promise<IApiResponse<IGetNewLessonDependenciesResponse>>;
  getAssetMetaData(assetVersionId: number): Promise<any>;
  updateAssetMetaData(metaData: any): Promise<any>;
  updateAssetMetaDataInitial(metaData: any): Promise<any>;
  getFilteredAssets(
    filters: IGetFilteredAssetsRequest,
    config: AxiosRequestConfig,
  ): Promise<IApiResponse<IGetFilteredAssetsResponse>>;
  getLessonInfo(input: IOpenLessonRequest): Promise<IApiResponse<IOpenLessonResponse>>;
  getLessonPages(input: IGetLessonPagesRequest): Promise<IApiResponse<IGetLessonPagesResponse>>;
  generateAudio(request: IGenerateAudioRequest): Promise<IApiResponse<IUploadAssetResponse>>;
  createNewLessonFromCLASSCOFileWithMetadata(
    request: FormData,
  ): Promise<IApiResponse<ICreateNewLessonWithMetadataResponse>>;
  getSupportedTranslationLanguages(): Promise<IApiResponse<ISupportedTranslationLanguageResponse>>;
  getLanguages(): Promise<IApiResponse<ISupportedVoiceLanguageResponse>>;
  getVoices(localeCode: ISupportedVoiceRequest): Promise<IApiResponse<ISupportedVoiceResponse>>;
  translateText(request: IMachineTranslationRequest): Promise<IApiResponse<IMachineTranslationResponse>>;
  detectLanguage(request: IAutoDetectLanguageRequest): Promise<IApiResponse<IAutoDetectLanguageResponse>>;
  getAircraftLiveries(aircraftId: number): Promise<IApiResponse<IGetAircraftLiveryResponse>>;
  downloadAsset(blobPath: string): Promise<IApiResponse<IDownloadAssetResponse>>;
  closeLesson(request: ICloseLessonRequest): Promise<IApiResponse<any>>;
  resetOpenedLessonLog(request: ICloseLessonRequest): Promise<IApiResponse<any>>;
  closeLessonWithBeacon(request: ICloseLessonRequest): Promise<boolean>;
  logIssue(request: ILogErrorRequest): Promise<any>;
}

const genericRepositoryService: IGenericRepositoryService = {
  login: async (): Promise<IApiResponse<ILoginResponse>> => {
    return await httpHandler.get<ILoginResponse>("/api/Login");
  },
  getAllLessons: async (): Promise<IApiResponse<IGetAllLessonsResponse>> => {
    return await httpHandler.get<IGetAllLessonsResponse>("/api/Lesson");
  },
  getAccessibleLessons: async (): Promise<IApiResponse<IGetAllLessonsResponse>> => {
    return await httpHandler.get<IGetAllLessonsResponse>("/api/Lesson/getLessonList");
  },
  createNewLessonWithMetadata: async (
    request: ICreateNewLessonWithMetadataRequest,
  ): Promise<IApiResponse<ICreateNewLessonWithMetadataResponse>> => {
    return await httpHandler.post<ICreateNewLessonWithMetadataRequest, ICreateNewLessonWithMetadataResponse>(
      "/api/CreateLesson",
      request,
    );
  },
  copyLesson: async (request: number): Promise<IApiResponse<ICreateLessonResponse>> => {
    return await httpHandler.get<ICreateLessonResponse>("/api/CloneLesson/" + request);
  },
  openLesson: async (request: IOpenLessonRequest): Promise<IApiResponse<IOpenLessonResponse>> => {
    return await httpHandler.post<IOpenLessonRequest, IOpenLessonResponse>("/api/OpenLesson", request);
  },
  updateLesson: async (request: IUpdateLessonRequest): Promise<IApiResponse<IUpdateLessonResponse>> => {
    return await httpHandler.post<IUpdateLessonRequest, IUpdateLessonResponse>("/api/UpdateLesson", request, {
      timeout: 0,
    });
  },
  addLessonPage: async (request: IGetNewPageRequest): Promise<IApiResponse<IGetNewPageResponse>> => {
    return await httpHandler.post<IGetNewPageRequest, IGetNewPageResponse>("/api/ClonePage", request);
  },
  // deleteLesson: async(request: IDeleteLessonRequest): Promise<IApiResponse<IDeleteLessonResponse>> => {
  //     return await httpHandler.post<IDeleteLessonRequest, IDeleteLessonResponse>("api/DeleteLesson", request);
  // },
  exportLesson: async (request: IExportLessonRequest): Promise<IApiResponse<IExportLessonResponse>> => {
    return await httpHandler.post<IExportLessonRequest, IExportLessonResponse>("/api/LessonExport", request);
  },
  stagePreviewLesson: async (request: any): Promise<IApiResponse<any>> => {
    return await httpHandler.post<any, any>("/api/StagePreview", request);
  },
  blobFetch: async <TResponse>(relativeUrl: string): Promise<IApiResponse<TResponse>> => {
    return await httpHandler.getBlob<TResponse>(blobUrlFormatHelper(relativeUrl));
  },
  blobDetect: async <TResponse>(relativeUrl: string): Promise<IApiResponse<TResponse>> => {
    return await httpHandler.detectBlob<TResponse>(blobUrlFormatHelper(relativeUrl));
  },
  downloadAsset: async (blobPath: string): Promise<IApiResponse<IDownloadAssetResponse>> => {
    return await httpHandler.get<IDownloadAssetResponse>(`/api/Lookup/livery/download/${blobPath}`);
  },
  uploadAsset: async (assetInformation: FormData): Promise<IApiResponse<IUploadAssetResponse>> => {
    return await httpHandler.postMultipart<IUploadAssetResponse>("/api/UploadAsset", assetInformation);
  },
  getAllImages: async (): Promise<IApiResponse<IGetAllImagesResponse>> => {
    return await httpHandler.get<IGetAllImagesResponse>("/api/Lookup/image");
  },
  getAllPdf: async (): Promise<IApiResponse<IGetAllPdfResponse>> => {
    return await httpHandler.get<IGetAllPdfResponse>("/api/Lookup/pdf");
  },
  getAllVideos: async (): Promise<IApiResponse<IGetAllVideosResponse>> => {
    return await httpHandler.get<IGetAllVideosResponse>("/api/Lookup/video");
  },
  getAircraftLessonData: async (): Promise<IApiResponse<IGetAircraftLessonData>> => {
    return await httpHandler.get<IGetAircraftLessonData>("/api/Lookup/aircraft");
  },
  getATAData: async (): Promise<IApiResponse<IGetATAData>> => {
    return await httpHandler.get<IGetATAData>("/api/Lookup/ata");
  },
  getFmsModels: async (id, type): Promise<IApiResponse<IGetFmsModelsResponse>> => {
    let route: string;
    if (type) route = `${type}/${id}`; //example: /api/Lookup/fmsmodel/manufacturer/2 specific lookup
    else route = `${id}`;
    if (id === null) route = ""; // this could change, if  /api/Lookup/fmsmodel/2 becomes depricated by being too unspecific
    return await httpHandler.get<IGetFmsModelsResponse>(`/api/Lookup/fmsmodel/${route}`);
  },
  getAssetFromCollection: async (collectionAssetVersionId: number): Promise<IApiResponse<ICollectionAssetResponse>> => {
    return await httpHandler.get<ICollectionAssetResponse>(`/api/Lookup/assetcollection/${collectionAssetVersionId}`);
  },
  getAssetMetaData: async (assetVersionId: number): Promise<any> => {
    return await httpHandler.get<any>(`/api/asset/${assetVersionId}`);
  },
  updateAssetMetaData: async (metaData: any): Promise<any> => {
    return await httpHandler.post<any, any>(`/api/asset/update`, metaData);
  },
  updateAssetMetaDataInitial: async (metaData: any): Promise<any> => {
    return await httpHandler.post<any, any>(`/api/asset/updateinitial`, metaData);
  },
  getLessonTypes: async (): Promise<IApiResponse<IGetLessonTypes>> => {
    return await httpHandler.get<IGetLessonTypes>("/api/Lookup/lessontype");
  },
  getLessonStyle: async (): Promise<IApiResponse<IGetLessonStyle>> => {
    return await httpHandler.get<IGetLessonStyle>("/api/Lookup/lessonstyle");
  },
  getLessonModes: async (): Promise<IApiResponse<IGetLessonModes>> => {
    return await httpHandler.get<IGetLessonModes>("/api/Lookup/lessonmode");
  },
  getThreeDModel: async (id, type): Promise<IApiResponse<IGetThreeDModel>> => {
    let route: string;
    if (type) route = `${type}/${id}`; //example: /api/Lookup/threedmodel/manufacturer/2 specific lookup
    else route = `${id}`;
    if (id === null) route = ""; // this could change, if  /api/Lookup/threedmodel/2 becomes depricated by being too unspecific
    return await httpHandler.get<IGetThreeDModel>(`/api/Lookup/ThreeDModel/${route}`);
  },
  getWalkaroundModels: async (id, type): Promise<IApiResponse<IGetWalkaroundModels>> => {
    let route: string;
    if (type) route = `${type}/${id}`; //example: /api/Lookup/threedmodel/manufacturer/2 specific lookup
    else route = `${id}`;
    if (id === null) route = ""; // this could change, if  /api/Lookup/threedmodel/2 becomes depricated by being too unspecific
    return await httpHandler.get<IGetThreeDModel>(`/api/Lookup/walkaround/${route}`);
  },

  getCabinModels: async (id, type): Promise<IApiResponse<IGetCabinModels>> => {
    let route: string;
    if (type) route = `${type}/${id}`; //example: /api/Lookup/threedmodel/manufacturer/2 specific lookup
    else route = `${id}`;
    if (id === null) route = "";
    return await httpHandler.get<IGetCabinModels>(`/api/Lookup/cabin/${route}`);
  },
  getNewLessonInformation: async (): Promise<IApiResponse<IGetNewLessonDependenciesResponse>> => {
    return await httpHandler.get<IGetNewLessonDependenciesResponse>("/api/CreateLesson");
  },
  getFilteredAssets: async (request, axiosConfig): Promise<IApiResponse<IGetFilteredAssetsResponse>> => {
    return await httpHandler.post<IGetFilteredAssetsRequest, IGetFilteredAssetsResponse>(
      "/api/Asset/Filter",
      request,
      axiosConfig,
    );
  },
  getLessonInfo: async (lessonVersionId): Promise<IApiResponse<IOpenLessonResponse>> => {
    return await httpHandler.post<IOpenLessonRequest, IOpenLessonResponse>("/api/GetLessonInfo", lessonVersionId);
  },
  getLessonPages: async (getLessonPagesRequest): Promise<IApiResponse<IGetLessonPagesResponse>> => {
    return await httpHandler.post<IGetLessonPagesRequest, IGetLessonPagesResponse>(
      "/api/Lesson/GetLessonPages",
      getLessonPagesRequest,
    );
  },
  generateAudio: async (audioRequest): Promise<IApiResponse<IUploadAssetResponse>> => {
    return await httpHandler.post<IGenerateAudioRequest, IUploadAssetResponse>("/api/tts/generateAudio", audioRequest);
  },
  getLanguages: async (): Promise<IApiResponse<ISupportedVoiceLanguageResponse>> => {
    return await httpHandler.get<ISupportedVoiceLanguageResponse>("/api/tts/getLanguages");
  },
  getVoices: async (localeCode): Promise<IApiResponse<ISupportedVoiceResponse>> => {
    return await httpHandler.post<ISupportedVoiceRequest, ISupportedVoiceResponse>("/api/tts/getVoices", localeCode);
  },
  createNewLessonFromFileWithMetadata: async (
    request: FormData,
  ): Promise<IApiResponse<ICreateNewLessonWithMetadataResponse>> => {
    return await httpHandler.post<FormData, ICreateNewLessonWithMetadataResponse>("/api/CreateLessonFromFile", request);
  },
  createNewLessonFromCLASSCOFileWithMetadata: async (
    request: FormData,
  ): Promise<IApiResponse<ICreateNewLessonWithMetadataResponse>> => {
    return await httpHandler.post<FormData, ICreateNewLessonWithMetadataResponse>(
      "/api/CreateLessonFromCLASSCOFile",
      request,
    );
  },
  getSupportedTranslationLanguages: async (): Promise<IApiResponse<ISupportedTranslationLanguageResponse>> => {
    return await httpHandler.get<ISupportedTranslationLanguageResponse>("/api/translations/getSupportedLanguages");
  },
  translateText: async (request: IMachineTranslationRequest): Promise<IApiResponse<IMachineTranslationResponse>> => {
    return await httpHandler.post<IMachineTranslationRequest, IMachineTranslationResponse>(
      "/api/translations/translateText",
      request,
    );
  },
  detectLanguage: async (request: IAutoDetectLanguageRequest): Promise<IApiResponse<IAutoDetectLanguageResponse>> => {
    return await httpHandler.post<IAutoDetectLanguageRequest, IAutoDetectLanguageResponse>(
      "/api/translations/autoDetect",
      request,
    );
  },
  getAircraftLiveries: async (aircraftId: number): Promise<IApiResponse<IGetAircraftLiveryResponse>> => {
    return await httpHandler.get<IGetAircraftLiveryResponse>(`/api/Lookup/livery/aircraft/${aircraftId}`);
  },
  closeLesson: async (request: ICloseLessonRequest): Promise<IApiResponse<any>> => {
    return await httpHandler.post<ICloseLessonRequest, any>("/api/CloseLesson", request);
  },
  resetOpenedLessonLog: async (request: ICloseLessonRequest): Promise<IApiResponse<any>> => {
    return await httpHandler.post<ICloseLessonRequest, any>("/api/CloseLesson/ResetOpenedLessonLog", request);
  },
  closeLessonWithBeacon: async (request: ICloseLessonRequest): Promise<boolean> => {
    return await httpHandler.postWithSendBeacon<ICloseLessonRequest>("/api/CloseLesson", request, {
      type: "application/json",
    });
  },
  logIssue: async (request: ILogErrorRequest): Promise<any> => {
    return await httpHandler.post<ILogErrorRequest, any>("/api/LogError", request);
  },
};

export default genericRepositoryService;
