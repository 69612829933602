import React, { useEffect, useMemo, useRef } from "react";

import useDrag from "../../../../hooks/useDrag";
// import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { ReactComponent as ScrubBar } from "./TimeIndicator.svg";
import "../SequenceLengthIndicator.css";
import { useDomRef } from "../../../../hooks/useDomRef";

import {
  useTimeline,
  clippedSpaceFromUnitSpace,
  scaledSpaceToUnitSpace,
  clippedSpaceToUnitSpace,
  useTimelineDispatch,
} from "../../../../contexts/TimelineProvider/TimelineProvider";
import { useGlobalAudioState } from "../../../../contexts/GlobalAudioProvider/GlobalAudioProvider";
import { OBJECT_ROW_HEIGHT, TOP_STRIP_HEIGHT, WAVE_HEIGHT } from "../../TimelinePanel";
import { useObjectsState } from "../../../../contexts/ObjectsProvider";
import { useLessonPagesState } from "../../../../contexts/LessonPagesProvider/LessonPagesProvider";

const RENDER_OUT_OF_VIEW_X = -10000;
const coverWidth = 1000;

export default function Scrubber() {
  const [ref, setRef] = useDomRef<any>();
  const { audioLength, audioOnPageExists } = useGlobalAudioState();
  const [timeline] = useTimeline();
  const setTimeline = useTimelineDispatch();
  const [isDragging] = useDragBulge(ref);
  const {
    clippedSpace,
    widthInPx,
    heightInPx,
    scaledSpace: { leftPadding },
    sequenceLength,
    sequencedObjectsAmount,
    scrubbingCurrentTime,
  } = timeline;

  const position = clippedSpaceFromUnitSpace(scrubbingCurrentTime, leftPadding, clippedSpace, widthInPx);
  const draggerHeight = OBJECT_ROW_HEIGHT * sequencedObjectsAmount;
  const top = `14px`;
  const objectsState = useObjectsState();
  const isFreeFormPoly = objectsState.selectedObjects[0]?.type === "freeFormPoly";
  const { selectedPanel } = useLessonPagesState();

  return (
    <>
      <ScrubBar
        ref={setRef}
        style={{
          top,
          // height: draggerHeight,
          position: "absolute",
          pointerEvents: isFreeFormPoly && selectedPanel === "advanced" ? "none" : "all",
          zIndex: 13,
          transform: `translateX(calc(${position}px - 50%))`,
        }}
      />
    </>
  );
}
function useDragBulge(node: HTMLDivElement | null): [boolean] {
  const [timeline, setTimeline] = useTimeline();

  const gestureHandlers = useMemo<Parameters<typeof useDrag>[1]>(() => {
    return {
      debugName: "LengthIndicator/useDragBulge",
      lockCSSCursorTo: "ew-resize",
      onDragStart() {
        setTimeline({
          type: "SET_USING_SCRUB",
          payload: true,
        });
        const initalPosition = clippedSpaceFromUnitSpace(
          timeline.scrubbingCurrentTime,
          timeline.scaledSpace.leftPadding,
          timeline.clippedSpace,
          timeline.widthInPx,
        );
        return {
          onDrag(dx) {
            const currentPosition = initalPosition + dx;
            setTimeline({
              type: "SET_SCRUBBING_CURRENT_TIME",
              payload: currentPosition,
            });
          },
          onDragEnd() {
            setTimeline({
              type: "SET_USING_SCRUB",
              payload: false,
            });
          }
        };
      },
    };
  }, [timeline.scrubbingCurrentTime, timeline.scaledSpace.leftPadding, timeline.clippedSpace, timeline.widthInPx]);

  return useDrag(node, gestureHandlers);
  // useLockFrameStampPosition(isDragging, -1)
}
