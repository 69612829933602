import React, { createRef } from "react";
import { handleKeyPress, handleKeyUp } from "../../ObjectPropertyBox/functions/PropertyBoxFunctions";
import { AnnotationPropType } from "../Annotation";
import HighlightAnnotation from "../hooks/HighlightAnnotation";

const AnnotationDashedLine = (props: AnnotationPropType) => {
  const { annotation, index, kp, markSelected, objectIsInTimeline } = props;
  const svgRef: React.RefObject<SVGSVGElement> = createRef<SVGSVGElement>();

  const isDisplayed = typeof annotation.isDisplayed === "undefined" || annotation.isDisplayed;
  const opacity = objectIsInTimeline ? 1 : isDisplayed ? 1 : 0.5;

  const display = () => {
    const rotateDeg: number | undefined = annotation?.transform?.rotate ? annotation?.transform?.rotate : 0;
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="https://www.w3.org/1999/xlink"
        id={`dashedLine-${index}`}
        tabIndex={0}
        name="dashedLine"
        className="annotation"
        version="1.1"
        // viewBox={`0 0 auto 20`}
        height={`20`}
        preserveAspectRatio="xMinYMid meet"
        opacity={opacity}
        // width={annotation.width}
        // height={annotation.height}
        style={{
          top: `${annotation.top.toFixed(2)}%`,
          left: `${annotation.left.toFixed(2)}%`,
          width: `${annotation.width.toFixed(2)}%`,
          zIndex: annotation.zIndex,
          transform: `rotate(${rotateDeg?.toFixed(2)}deg)`,
        }}
        onMouseDown={(e: React.MouseEvent<SVGSVGElement>) => markSelected(e.currentTarget, index, "annotations")}
        onKeyDown={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyPress(e, kp)}
        onKeyUp={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyUp(e, kp)}
        ref={svgRef}
      >
        <HighlightAnnotation annotation={annotation} />

        <line
          className="annotation-arrow-arm"
          x1="0"
          x2={`100%`}
          y1={`50%`}
          y2={`50%`}
          style={{
            fill: `${annotation.borderColor || "white"}`,
            stroke: `${annotation.borderColor || "white"}`,
            strokeLinecap: "butt",
            strokeDasharray: `12 9`,
            strokeWidth: `${annotation.strokeWidth || "4"}`,
          }}
        />
      </svg>
    );
    // }
  };

  return <React.Fragment>{display()}</React.Fragment>;
};

export default AnnotationDashedLine;
