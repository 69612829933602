import React from "react";
import { BaseWYSIWYGType } from "../BaseWYSIWYG";
import blobHelper from "../../../../blobUrlFormatHelper";

const FPG = (props: BaseWYSIWYGType) => {
  const { pageManifest } = props;
  const imageName = blobHelper(pageManifest.pageImage[0].imagePath);

  return (
    <div className="contentBox">
      <div className="full-page-graphic-container">
        <div className="img-aspect-container">
          <div className="img-aspect-container-inside">
            <div className="img-aspect-centering">
              <img src={imageName} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FPG;
