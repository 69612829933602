import { AbstractTooltip as Tooltip } from "../../../ToolTip/ToolTip";
import React, { useContext, useEffect } from "react";
import classes from "../../../../utils/HTML/classes";
import { LessonPageActionsContext, PageContext } from "../../../../routes/builderContexts";
import { useAudioManagerStore } from "../../../../contexts/PageAudioManager";

export interface PageAutoAdvanceProps {
  disabled?: boolean;
}

export const PageAutoAdvance = ({ disabled = false }: PageAutoAdvanceProps) => {
  const { handleRightSide } = useContext(LessonPageActionsContext);
  const { page } = useContext(PageContext);
  const audiosLength = useAudioManagerStore((state) => state.narrationAudios.length);

  const handleAutoAdvance = () => () => {
    handleRightSide("shouldAutoPageForward");
  };

  /**
   * Business rule: If there are no audios anymore and the page auto advance was
   *  enabled, disable it and set to OFF(false)
   */
  useEffect(() => {
    if (page.shouldAutoPageForward === true && audiosLength === 0) {
      handleRightSide("shouldAutoPageForward");
    }
  }, [page.shouldAutoPageForward, audiosLength]);

  return (
    <div className={classes("pageEditorActionListerRow", { "disabled-action": disabled })}>
      <div
        className={classes("pageListerActionButton auto-advance-p", {
          "auto-advance-p--disabled": disabled,
        })}
        data-tooltip-id="Auto Advance"
      >
        <p>Page Auto Advance</p>
        <div className="toggle toggle-switch">
          <input
            type="checkbox"
            className="toggle-checkbox"
            id="toggle-switch"
            checked={page.shouldAutoPageForward}
            onChange={handleAutoAdvance()}
            disabled={disabled}
          />
          <label htmlFor="toggle-switch" className="toggle-btn">
            <span className="toggle-feature" data-label-on="on" data-label-off="off"></span>
          </label>
        </div>
      </div>
      <Tooltip id={"Auto Advance"} />
    </div>
  );
};
