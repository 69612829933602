import React, { ReactElement, useState } from "react";
import ActionSelect from "../../../../components/ActionSelect/ActionSelect";
import { AbstractTooltip as Tooltip } from "../../../../components/ToolTip/ToolTip";
interface conditionProps {
  cellList: string[];
  displayCellList: string[];
  cdu: string;
  destinationList: string[];
  keyList: string[];
  condition: any;
  index: number;
  buttonIsEnabled: boolean;
  deleteButton: string;
  removeCondition: (index: number) => void;
  mappedElementChangeHandler: (name: string, value: string, index: number) => void;
  highlightCellOptions: (display: boolean) => string[];
  modalCellOptions: (display: boolean) => string[];
  dropDownOptions: (display: boolean) => string[];
}
const ConditionMap = (props: conditionProps) => {
  const [conditionTypes] = useState<string[]>(
    props.cdu.includes("787-8") || props.cdu.includes("a220") || props.cdu.includes("a350")
      ? ["", "textAttribute", "cellAttribute"]
      : ["", "textAttribute"],
  );
  const [displayConditionTypes] = useState<string[]>(
    props.cdu.includes("787-8") || props.cdu.includes("a220") || props.cdu.includes("a350")
      ? ["Not Selected", "Text Present Condition", "Highlight Condition"]
      : ["Not Selected", "Text Present Condition"],
  );

  const [placeToCheckValues] = useState<string[]>(
    props.destinationList.map((destination, index) => {
      return destination;
    }),
  );
  const [displayPlaceToCheck] = useState<string[]>(
    props.destinationList.map((destination, index) => {
      return destination.replace(/_/g, " ");
    }),
  );

  const operatorList = (): string[] => {
    return props.condition.type !== "cellAttribute"
      ? props.condition.type === "cellAttribute" || props.index === 0
        ? ["", "isEqual"]
        : ["", "isEqual", "orEqual"]
      : props.condition.type === "cellAttribute" || props.index === 0
      ? ["", "isEqual", "isHighlighted"]
      : ["", "isEqual", "orEqual", "isHighlighted"];
  };
  const displayOperators = (): string[] => {
    return props.condition.type !== "cellAttribute"
      ? props.condition.type === "cellAttribute" || props.index === 0
        ? ["Select", "Equals"]
        : ["Select", "Equals", "Or Equals"]
      : props.condition.type === "cellAttribute" || props.index === 0
      ? ["Select", "Equals", "Highlighted Cell"]
      : ["Select", "Equals", "Or Equals", "Highlighted Cell"];
  };
  return (
    <div className="condition-map-container">
      <div className="condition-map-field-container">
        <div className="condition-field">
          <p>Condition Type:</p>
          {/* <select
                    className="condition-element icon-Select"
                    disabled={!props.buttonIsEnabled} 
                    name="type"
                    value={props.buttonIsEnabled ? (props.condition.type) : ("")}
                    // onChange={(event: any) => {props.mappedElementChangeHandler(event,props.index)}}
                >
                    <option value={""} disabled>Not Selected</option>
                    <option value={"textAttribute"}>Text Present Condition</option>
      
                    {props.cdu.includes("787-8") || props.cdu.includes("a220") ? <option value={"cellAttribute"}>Highlight Condition</option> : <></>}
                 
                </select> */}
          <ActionSelect
            action={props.buttonIsEnabled ? displayConditionTypes[conditionTypes.indexOf(props.condition.type)] : ""}
            options={conditionTypes}
            highlightColor="#293D54"
            defaultBackground="#ffffff"
            defaulTextColor="#000000"
            highlightTextColor="#ffffff"
            selectOption={(value: string) => {
              props.mappedElementChangeHandler("type", value, props.index);
            }}
            disabledOptions={[""]}
            displayValues={displayConditionTypes}
            parentClass={"spacedInput page-objective-input condition-element"}
            parentId={"sequenceCriteria"}
            optionWrapperClass={"pageObjective-select-options-container"}
            optionClass={"criteria-select-option"}
          />
        </div>
        <div className="condition-field" hidden={props.condition.type === "cellAttribute"}>
          <p>Place:</p>
          {/* <select
                className="condition-element icon-Select" 
                disabled={!props.buttonIsEnabled} 
                // onChange={(event: any) => {props.mappedElementChangeHandler(event,props.index)}}
                name="placeToCheck" 
                value={props.buttonIsEnabled ? (props.condition.placeToCheck) : ("Scratchpad")}
            >
              {props.destinationList.map((destination,index) => (
                <option key={index} value={destination}>{destination.replace(/_/g," ")}</option>
              ))}
              {props.modalCellOptions()}
            </select> */}
          <ActionSelect
            action={
              props.buttonIsEnabled && props.condition.placeToCheck.length
                ? props.condition.placeToCheck.replace(/_/g, " ")
                : "Select"
            }
            options={props.cellList}
            highlightColor="#293D54"
            defaultBackground="#ffffff"
            defaulTextColor="#000000"
            highlightTextColor="#ffffff"
            selectOption={(value: string) => {
              props.mappedElementChangeHandler("placeToCheck", value, props.index);
            }}
            disabledOptions={[""]}
            displayValues={props.displayCellList}
            parentClass={"spacedInput page-objective-input condition-element"}
            parentId={"sequenceCriteria"}
            optionWrapperClass={"pageObjective-select-options-container"}
            optionClass={"criteria-select-option"}
          />
        </div>
        <div className="condition-field">
          <p>Operator:</p>
          {/* <select
              className="condition-element icon-Select" 
              disabled={!props.buttonIsEnabled} 
              name="operator" 
              // onChange={(event: any) => {props.mappedElementChangeHandler(event,props.index)}}
              value={props.buttonIsEnabled ? (props.condition.operator) : props.condition.type === "cellAttribute" ?   ("isHighlighted") : ("isEqual")}
            >
              <option value="" disabled>Select</option>
              <option value="isEqual">Equals</option>
              <option value="orEqual" hidden={props.condition.type === "cellAttribute" || props.index === 0}>Or Equals</option>
              <option value="isHighlighted" hidden={props.condition.type !== "cellAttribute"}>Highlighted Cell</option>
            </select> */}
          <ActionSelect
            action={
              props.buttonIsEnabled
                ? displayOperators()[operatorList().indexOf(props.condition.operator)]
                : props.condition.type === "cellAttribute"
                ? "Highlighted Cell"
                : "Equals"
            }
            options={operatorList()}
            highlightColor="#293D54"
            defaultBackground="#ffffff"
            defaulTextColor="#000000"
            highlightTextColor="#ffffff"
            selectOption={(value: string) => {
              props.mappedElementChangeHandler("operator", value, props.index);
            }}
            disabledOptions={[""]}
            displayValues={displayOperators()}
            parentClass={"spacedInput page-objective-input condition-element"}
            parentId={"sequenceCriteria"}
            optionWrapperClass={"pageObjective-select-options-container"}
            optionClass={"criteria-select-option"}
          />
        </div>
        <div className="condition-field">
          {props.condition.type === "cellAttribute" ? (
            <>
              {/* 
                  <select 
                    className="condition-element icon-Select"
                    name="valueToCheck" 
                    value={props.buttonIsEnabled ? (props.condition.valueToCheck) : (" ")} 
                    // onChange={(event)=>{props.mappedElementChangeHandler(event,props.index)}} 
                  >
                    <option value="" disabled>Select</option>
                    <option value=" ">Nothing</option>
             
                  </select>    */}
              <p>Key That is Highlighted: </p>
              <ActionSelect
                action={
                  props.buttonIsEnabled
                    ? props.condition.valueToCheck === " "
                      ? "Nothing"
                      : props.condition.valueToCheck.replace(/_/g, " ")
                    : "Nothing"
                }
                options={["", " "].concat(props.cellList)}
                highlightColor="#293D54"
                defaultBackground="#ffffff"
                defaulTextColor="#000000"
                highlightTextColor="#ffffff"
                selectOption={(value: string) => {
                  props.mappedElementChangeHandler("valueToCheck", value, props.index);
                }}
                disabledOptions={[""]}
                displayValues={["Select", "Nothing"].concat(props.displayCellList)}
                parentClass={"spacedInput page-objective-input condition-element"}
                parentId={"sequenceCriteria"}
                optionWrapperClass={"pageObjective-select-options-container"}
                optionClass={"criteria-select-option"}
              />
            </>
          ) : (
            <>
              <p>Value:</p>
              <input
                className="condition-element"
                style={{ borderRadius: ".3rem", border: "none" }}
                disabled={!props.buttonIsEnabled}
                placeholder="enter a value"
                onChange={(event: any) => {
                  props.mappedElementChangeHandler("valueToCheck", event.target.value, props.index);
                }}
                name="valueToCheck"
                value={props.buttonIsEnabled ? props.condition.valueToCheck : ""}
              />
            </>
          )}
        </div>
      </div>
      <div
        className="remove-condition-box"
        onClick={() => {
          props.removeCondition(props.index);
        }}
        data-tooltip-id="remove fms condition"
      >
        <Tooltip id="remove fms condition" />
        <figure className="remove-condition-icon-figure shaded">
          <img className="removeConditionButton" src={props.deleteButton} alt="delete"></img>
        </figure>
      </div>
    </div>
  );
};

export default ConditionMap;
