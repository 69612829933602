import React from "react";
import ILessonPageDto from "../../models/ILessonPageDto";

type ShowStopperType = {
  lessonMeta: any;
  lessonPages: ILessonPageDto[];
  resumeOpenLesson(lessonPages: ILessonPageDto[], lessonMeta: any): Promise<void>;
  annotationUpdate?: boolean;
};

const StopOpenLesson = (props: ShowStopperType) => {
  const { lessonMeta, lessonPages, resumeOpenLesson, annotationUpdate } = props;

  function handleContinue() {
    resumeOpenLesson(lessonPages, lessonMeta);
  }

  const showMessage = () => {
    if (annotationUpdate) {
      return (
        <p
          style={{
            fontWeight: "bold",
            marginLeft: "10%",
            marginRight: "10%",
            textAlign: "center",
          }}
        >
          Please visit Cockpit and Walk Around pages and open each step in the Interactive Graphic Activity to apply new
          software updates.
        </p>
      );
    }

    return <p>Updates have been made to some lesson pages.</p>;
  };

  return (
    <React.Fragment>
      <div className="show-stopper-cover">
        <div className="show-stopper-container">
          <div className="show-stopper-header">
            <span>Lesson Data Updates</span>
          </div>
          <div className="show-stopper-body">
            <p className="warning-header">
              <span className="icon-warning-page-icon"></span>WARNING
            </p>
            {showMessage()}
            <p>Press "Continue" to review the results and continue editing.</p>
            <p>Press "Exit" to abandon the changes and leave the lesson.</p>
            <p>Reviewing the lesson and leaving without saving will discard any changes or updates.</p>
          </div>
          <div className="show-stopper-footer">
            <button className="show-stopper-exit" onClick={() => window.close()}>
              Exit
            </button>
            <button className="show-stopper-continue" onClick={() => handleContinue()}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StopOpenLesson;
