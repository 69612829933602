import ILessonPageDto from "../models/ILessonPageDto";
import { cloneDeep, forEach, has, forIn, isEqual } from "lodash";
import { IBasePage } from "../pageTypes/BasicPage_Player/components/IBasePage";
import { initialFrame } from "../pageTypes/BasicPage_Player/components/BaseFreeForm/assets/helpers/basePageHelpers";
import { IFmsPageManifest } from "../pageTypes/FMS_Player/Interfaces/IFmsPageManifest";
import * as pageTypeConstant from "../components/constants/pageTypes";
import { IIGA, IIGAHotSpot } from "../components/IGA/models/IIGA";
import { blankHotSpot } from "../components/IGA/helpers/initialSteps";
import { CURRENT_TEXTBOX_VERSION } from "../utils/Versioning/basePagesVersioning";

export const builderHelper = (lessonPages: ILessonPageDto[]) => {
  const newLessonPages: ILessonPageDto[] = cloneDeep(lessonPages);

  const validateImageAssetId = () => {
    forEach(newLessonPages, (page: ILessonPageDto, index: number) => {
      const updatedManifest: any = page.pageManifest;

      //hacked change
      // if(updatedManifest.pageImage === undefined || updatedManifest.pageImage === null)
      //   updatedManifest = {};

      if (updatedManifest.pageImage) {
        forEach(updatedManifest.pageImage, (image: any, jindex: number) => {
          if (typeof image.assetVersionId !== "number") {
            const splitString = image.imagePath.split("/");

            updatedManifest.pageImage[jindex].assetVersionId = parseInt(splitString[4]);
            newLessonPages[index].pageManifest = updatedManifest;
            newLessonPages[index].pageManifestIsDirty = true;
          }
        });
      }
    });
  };

  const validateManifestText = () => {
    newLessonPages?.forEach((page: ILessonPageDto, index: number) => {
      if (page.pagePlayerType === pageTypeConstant.BASIC_PAGE && page.pageManifest.basePageType !== "freeForm") {
        const updatedManifest: IBasePage = cloneDeep(page.pageManifest);

        if (updatedManifest.textBlock.length <= 0) {
          updatedManifest.textBlock.push({
            text: "Enter Text Here...",
            lineHeight: "normal",
            version: CURRENT_TEXTBOX_VERSION,
            type: "textBlock",
            ...initialFrame,
          });
          newLessonPages[index].pageManifest = updatedManifest;
          newLessonPages[index].pageManifestIsDirty = true;
        } else {
          forEach(updatedManifest.textBlock, (block, jIndex) => {
            if (block.text && typeof block.text !== "string") {
              updatedManifest.textBlock[jIndex].text = block.text.text;
              newLessonPages[index].pageManifest = updatedManifest;
              newLessonPages[index].pageManifestIsDirty = true;
            } else if (typeof block === "string") {
              updatedManifest.textBlock[jIndex] = {
                text: `${block}`,
                lineHeight: "normal",
                version: CURRENT_TEXTBOX_VERSION,
                type: "textBlock",
                ...initialFrame,
              };
              newLessonPages[index].pageManifest = updatedManifest;
              newLessonPages[index].pageManifestIsDirty = true;
            } else {
              return;
            }
          });
        }
      }
    });
  };

  const updateFMSPageAttempts = () => {
    newLessonPages?.forEach((page: ILessonPageDto, index: number) => {
      if (page.pagePlayerType === pageTypeConstant.FMS_PAGE) {
        const updatedManifest: IFmsPageManifest = cloneDeep(page.pageManifest);
        if (
          typeof page.pageManifest?.pageAttempts === "number" ||
          !page.pageManifest?.pageAttempts?.perform ||
          !page.pageManifest.pageAttempts.practice
        ) {
          updatedManifest.pageAttempts = { practice: 5, perform: 5 };
          newLessonPages[index].pageManifest = updatedManifest;
          newLessonPages[index].pageManifestIsDirty = true;
        }
      }
    });
  };

  const fixPageManifestAudio = () => {
    function fixAudio(page: ILessonPageDto, index: number) {
      if (page.pageManifest.Audio?.length < 3) {
        //fix it and give it 3 indexes.
        let baseAudio: any = {
          File: null,
          FileMD5: "",
          NarratorText: "",
          ParentVersion: "",
          Version: "",
          assetName: "",
        };

        if (page.pageManifest.Audio[0]) {
          baseAudio = page.pageManifest.Audio[0];
          newLessonPages[index].pageManifest.Audio[1] = baseAudio;
          newLessonPages[index].pageManifest.Audio[2] = baseAudio;
        } else {
          newLessonPages[index].pageManifest.Audio[0] = baseAudio;
          newLessonPages[index].pageManifest.Audio[1] = baseAudio;
          newLessonPages[index].pageManifest.Audio[2] = baseAudio;
        }
        newLessonPages[index].pageManifestIsDirty = true;
      }
    }
    newLessonPages?.forEach((page, index) => {
      switch (page.pagePlayerType) {
        case pageTypeConstant.TITLE_PAGE:
        case pageTypeConstant.QUIZ_PAGE:
        case pageTypeConstant.SCORE_PAGE:
        case pageTypeConstant.EXIT_PAGE:
          break;
        case pageTypeConstant.BASIC_PAGE:
          if (page.pageManifest.basePageType === "warning") {
            //noop
            break;
          } else {
            fixAudio(page, index);
          }
          break;
        default:
          fixAudio(page, index);
          break;
      }
    });
  };

  validateImageAssetId();
  validateManifestText();
  updateFMSPageAttempts();
  fixPageManifestAudio();

  return newLessonPages;
};

export async function IGADataUpdate(lessonPages: ILessonPageDto[]) {
  const newLessonPages: ILessonPageDto[] = cloneDeep(lessonPages);
  forEach(newLessonPages, async (page: ILessonPageDto, index: number) => {
    const manifest: any = page.pageManifest;
    let changed = false;
    if (has(manifest, "IGSObject")) {
      if (!Array.isArray(manifest.IGSObject)) {
        manifest.IGSObject = await convertIGSObjetToArray(manifest.IGSObject);
        if (!changed) changed = true;
      }

      forEach(manifest.IGSObject, (IGA: any, igaIndex: number) => {
        if (IGA.stepType === 1) {
          if (Array.isArray(IGA.images)) {
            IGA.images = IGA.images[0];
            if (has(IGA.images, "image")) {
              IGA.images.path = IGA.images.image;
              //validate if images.image still exists. Delete if does
            }
          }

          if (typeof IGA.images === "string") {
            const splitHotSpot = IGA.images.split(",");
            //convert string to object
            manifest.IGSObject[igaIndex].images.imageHotSpot = {
              left: parseFloat(parseFloat(splitHotSpot[0]).toFixed(2)),
              top: parseFloat(parseFloat(splitHotSpot[1]).toFixed(2)),
              right: parseFloat(parseFloat(splitHotSpot[2]).toFixed(2)),
              bottom: parseFloat(parseFloat(splitHotSpot[3]).toFixed(2)),
            };
            //does math to convert offsets to coordinate points
            newLessonPages[index].pageManifest.IGSObject[igaIndex].images.imageHotSpot = convertOffset(
              manifest.IGSObject[igaIndex].images.imageHotSpot,
            );
            if (isEqual(manifest.IGSObject[igaIndex].images.imageHotSpot, blankHotSpot)) {
              newLessonPages[index].pageManifest.IGSObject[igaIndex].images.imageHotSpot = {
                left: 0,
                top: 0,
                right: 400,
                bottom: 400,
              };
            }
            if (!changed) changed = true;
          }

          if (typeof IGA.images?.imageHotSpot === "string") {
            const splitHotSpot = IGA.images?.imageHotSpot.split(",");
            manifest.IGSObject[igaIndex].images.imageHotSpot = {
              left: parseFloat(parseFloat(splitHotSpot[0]).toFixed(2)),
              top: parseFloat(parseFloat(splitHotSpot[1]).toFixed(2)),
              right: parseFloat(parseFloat(splitHotSpot[2]).toFixed(2)),
              bottom: parseFloat(parseFloat(splitHotSpot[3]).toFixed(2)),
            };
            // newLessonPages[index].pageManifest.IGSObject[igaIndex].images.imageHotSpot = convertOffset(manifest.IGSObject[igaIndex].images.imageHotSpot);
            // if(_.isEqual(manifest.IGSObject[igaIndex].images.imageHotSpot, blankHotSpot)) {
            //   newLessonPages[index].pageManifest.IGSObject[igaIndex].images.imageHotSpot = {
            //     left: 0,
            //     top: 0,
            //     right: 400,
            //     bottom: 400
            //   }
            // }
            // if(!changed) changed = true;
          }

          if (has(IGA.images, "0")) {
            let newHotSpot: {
              left: number;
              top: number;
              right: number;
              bottom: number;
            } = { left: 0, top: 0, right: 0, bottom: 0 };
            if (typeof IGA.images["0"].imageHotSpot === "string") {
              const splitHotSpot = IGA.images["0"].imageHotSpot.split(",");
              newHotSpot = {
                left: parseFloat(parseFloat(splitHotSpot[0]).toFixed(2)),
                top: parseFloat(parseFloat(splitHotSpot[1]).toFixed(2)),
                right: parseFloat(parseFloat(splitHotSpot[2]).toFixed(2)),
                bottom: parseFloat(parseFloat(splitHotSpot[3]).toFixed(2)),
              };
            } else {
              newHotSpot = IGA.images["0"].imageHotSpot as IIGAHotSpot;
            }
            // newHotSpot = convertOffset(newHotSpot);
            newLessonPages[index].pageManifest.IGSObject[igaIndex].images = {
              path: IGA.images["0"].image,
              assetVersionId: IGA.images["0"].assetVersionId,
              imageHotSpot: newHotSpot,
            };
            if (isEqual(newHotSpot, blankHotSpot)) {
              newLessonPages[index].pageManifest.IGSObject[igaIndex].images.imageHotSpot = {
                left: 0,
                top: 0,
                right: 400,
                bottom: 400,
              };
            }
            if (!changed) changed = true;
          }

          if (typeof IGA.images?.imageHotSpot === "object" && !has(IGA, "version")) {
            //need to change images.image to images.path

            if (IGA.images.image) IGA.images.path = IGA.images.images;
            newLessonPages[index].pageManifest.IGSObject[igaIndex].images.imageHotSpot = convertOffset(
              IGA.images.imageHotSpot,
            );
            // newLessonPages[index].pageManifest.IGSObject[igaIndex].images.imageDimensions = null;
            newLessonPages[index].pageManifest.IGSObject[igaIndex].version = 0.1;
            if (isEqual(newLessonPages[index].pageManifest.IGSObject[igaIndex].images.imageHotSpot, blankHotSpot)) {
              newLessonPages[index].pageManifest.IGSObject[igaIndex].images.imageHotSpot = {
                left: 0,
                top: 0,
                right: 400,
                bottom: 400,
              };
            }
            if (!changed) changed = true;
          }

          if (newLessonPages[index].pageManifest.IGSObject[igaIndex].images.imageHotSpot) {
            if (
              manifest.IGSObject[igaIndex].images.imageHotSpot.top >
              manifest.IGSObject[igaIndex].images.imageHotSpot.bottom
            ) {
              // const swapped: {larger: number, smaller: number} = performSwap(manifest.IGSObject[igaIndex].images.imageHotSpot.top, manifest.IGSObject[igaIndex].images.imageHotSpot.bottom);
              newLessonPages[index].pageManifest.IGSObject[igaIndex].images.imageHotSpot = {
                ...manifest.IGSObject[igaIndex].images.imageHotSpot,
                top: manifest.IGSObject[igaIndex].images.imageHotSpot.bottom,
                bottom: manifest.IGSObject[igaIndex].images.imageHotSpot.top,
              };
              // manifest.IGSObject[igaIndex].images.imageHotSpot = {
              //   ...manifest.IGSObject[igaIndex].images.imageHotSpot,
              //   top: manifest.IGSObject[igaIndex].images.imageHotSpot.bottom,
              //   bottom: manifest.IGSObject[igaIndex].images.imageHotSpot.top
              // }
              if (!changed) changed = true;
            }

            if (
              manifest.IGSObject[igaIndex].images.imageHotSpot.left >
              manifest.IGSObject[igaIndex].images.imageHotSpot.right
            ) {
              // const swapped = performSwap(manifest.IGSObject[igaIndex].images.imageHotSpot.left, manifest.IGSObject[igaIndex].images.imageHotSpot.right);
              newLessonPages[index].pageManifest.IGSObject[igaIndex].images.imageHotSpot = {
                ...manifest.IGSObject[igaIndex].images.imageHotSpot,
                left: manifest.IGSObject[igaIndex].images.imageHotSpot.right,
                right: manifest.IGSObject[igaIndex].images.imageHotSpot.left,
              };
              // manifest.IGSObject[igaIndex].images.imageHotSpot = {
              //   ...manifest.IGSObject[igaIndex].images.imageHotSpot,
              //   left: manifest.IGSObject[igaIndex].images.imageHotSpot.right,
              //   right: manifest.IGSObject[igaIndex].images.imageHotSpot.left
              // }
              if (!changed) changed = true;
            }
          }

          if (
            has(IGA.images, "imageDimensions") &&
            IGA.images.imageDimensions !== null &&
            (!has(IGA, "version") || IGA.version === 0.1)
          ) {
            (newLessonPages[index].pageManifest.IGSObject[igaIndex] as IIGA).version = 1.0;
            // manifest.IGSObject[igaIndex].version = 1.0;
            changed = true;
          }
        }
      });
    }

    if (changed) {
      newLessonPages[index].pageManifest = cloneDeep(manifest);
      // newLessonPages[index].lessonPageIsDirty = true;
      newLessonPages[index].pageManifestIsDirty = true;
    }
  });
  return await newLessonPages;
}

async function convertIGSObjetToArray(IGA: any) {
  const tempArray: any = [];
  forIn(IGA, (object: any) => {
    tempArray.push(object);
  });
  return await tempArray;
}

function convertOffset(hotspot: IIGAHotSpot) {
  let largest = 0;
  let smallest = 0;

  if (hotspot.top > hotspot.bottom) {
    hotspot.bottom += hotspot.top;
    // largest = hotspot.top;
    // smallest = hotspot.bottom;
  } else {
    hotspot.bottom += hotspot.top;
    // largest = hotspot.bottom;
    // smallest = hotspot.top;
  }

  // hotspot.bottom = largest + smallest;
  // hotspot.top = hotspot.bottom - smallest;

  largest = 0;
  smallest = 0;

  if (hotspot.left > hotspot.right) {
    const right = hotspot.right;
    hotspot.right += hotspot.left;
    // hotspot.left = right;
    // largest = hotspot.left;
    // smallest = hotspot.right;
  } else {
    hotspot.right += hotspot.left;
  }

  // hotspot.right = largest + smallest;
  // hotspot.left = hotspot.right - smallest;
  // if(hotspot.top > hotspot.bottom) {
  //   hotspot.bottom += hotspot.top;
  // }

  // if(hotspot.left > hotspot.right) {
  //   hotspot.right += hotspot.left
  // }
  return hotspot;
}
