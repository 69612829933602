import { IUploadAssetResponse } from "../../models/IUploadAssetResponse";
import { StatefulAsyncMethod, StatefulSyncMethod } from "../../types/StatefulMethod";

export type TTSLanguages = Record<string, string>;
export type TTSVoices = Record<string, string>;
export enum TextToSpeechActionsType {
  /**
   * Open/Close TTS Modal
   */
  SET_OPEN,
  /**
   * Keep track of last generated audio
   */
  SET_AUDIO,
  /**
   * Keep track of last loaded languages [caching]
   */
  SET_LANGUAGES,
  /**
   * Keep track of last loaded Voices [caching]
   */
  SET_VOICES,
  /**
   * Keep track if any of the internal services are loading
   */
  SET_LOADING,
  /**
   * Tell if the service is busy converting the text to speech
   */
  SET_GENERATING,
  /**
   * If there is any error
   */
  SET_ERROR,
  /**
   * Reset state
   */
  RESET,
}

export interface TextToSpeechState {
  [key: string]: unknown;

  open: boolean;
  audio: IUploadAssetResponse | null;
  languages: TTSLanguages | null;
  voices: TTSVoices | null;
  error: string | number | undefined;
  isGenerating: boolean;
  isLoading: boolean;
}

export type SetOpen = {
  type: TextToSpeechActionsType.SET_OPEN;
  payload: {
    open: boolean;
  };
};

export type SetAudio = {
  type: TextToSpeechActionsType.SET_AUDIO;
  payload: {
    audio: TextToSpeechState["audio"];
  };
};

export type SetLanguages = {
  type: TextToSpeechActionsType.SET_LANGUAGES;
  payload: {
    languages: TextToSpeechState["languages"];
  };
};

export type SetVoices = {
  type: TextToSpeechActionsType.SET_VOICES;
  payload: {
    voices: TextToSpeechState["voices"];
  };
};

export type SetError = {
  type: TextToSpeechActionsType.SET_ERROR;
  payload: {
    error: TextToSpeechState["error"];
  };
};

export type SetGenerating = {
  type: TextToSpeechActionsType.SET_GENERATING;
  payload: {
    isGenerating: boolean;
  };
};

export type SetLoading = {
  type: TextToSpeechActionsType.SET_LOADING;
  payload: {
    isLoading: boolean;
  };
};

export type Reset = {
  type: TextToSpeechActionsType.RESET;
  payload: Partial<TextToSpeechState>;
};

export type TextToSpeechAction =
  | SetOpen
  | SetAudio
  | SetLanguages
  | SetVoices
  | SetError
  | SetGenerating
  | SetLoading
  | Reset;

export type TTSStatefulSyncMethod<TResult = void, TParams = undefined> = StatefulSyncMethod<
  TextToSpeechState,
  TextToSpeechAction,
  TResult,
  TParams
>;

export type TTSStatefulAsyncMethod<TResult = void, TParams = undefined> = StatefulAsyncMethod<
  TextToSpeechState,
  TextToSpeechAction,
  TResult,
  TParams
>;
