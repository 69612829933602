import BasePageIcon from "../../assets/icons/ld-basic-page-left-icon.png";
import FMSSelectionIcon from "../../assets/icons/ld-fms-selection-page-icon.png";
import QuizSelectionIcon from "../../assets/icons/ld-quiz-page-icon.png";
import ThreeDSelectIcon from "../../assets/icons/ld-3d-page-selection-page-icon.png";
import { BASIC_PAGE, FMS_PAGE, QUIZ_PAGE, ThreeD_PAGE, WALK_AROUND, CABIN } from "../constants/pageTypes";
import { ReactComponent as FMS } from "../../assets/icons/NewPageSelector/new-fms-icon.svg";
import { ReactComponent as ThreeD } from "../../assets/icons/NewPageSelector/3d-page-layout-icon.svg";
import { ReactComponent as Quiz } from "../../assets/icons/NewPageSelector/quiz-page-layout-icon.svg";
import { ReactComponent as Base } from "../../assets/icons/NewPageSelector/base-page-image-left-layout-icon.svg";
import { ReactComponent as Walkaround } from "../../assets/icons/NewPageSelector/walk-around-add-page-icon.svg";
import { ReactComponent as Cabin } from "../../assets/icons/NewPageSelector/cabin-add-page-icon.svg";
import { ISelectIconType } from "./ISelectIcon";

export interface IPageSelectIcon extends ISelectIconType {
  pageType: string;
}

export const pageSelectIconInfo: IPageSelectIcon[] = [
  {
    path: BasePageIcon,
    caption: "Basic Page",
    pageType: BASIC_PAGE,
    icon: Base,
  },
  {
    path: ThreeDSelectIcon,
    caption: "Cockpit Procedures",
    pageType: ThreeD_PAGE,
    icon: ThreeD,
  },
  {
    path: "",
    caption: "FMS Page",
    pageType: FMS_PAGE,
    icon: FMS,
  },
  {
    path: QuizSelectionIcon,
    caption: "Quiz Page",
    pageType: QUIZ_PAGE,
    icon: Quiz,
  },
  {
    caption: "Walk Around Page",
    pageType: WALK_AROUND,
    icon: Walkaround,
  },
  {
    caption: "Cabin",
    pageType: CABIN,
    icon: Cabin,
  },
];
