import _ from "lodash";
import { ALL_ANNOTATION_TYPES } from "../../components/Annotation/Annotation";
import { IAnnotation } from "../../components/Annotation/models/IAnnotation";
import { ISymbolV2 } from "../../components/Symbol/models/ISymbol";
import {
  IBasePageVideoV2,
  IBasicPageImage,
  IBasicPageImageV2,
  IBasicPageSCORM,
  IBasicPageScormV2,
  IBasicPageTable,
  IBasicPageTextBlock,
  IBasicPageTextBlockV2,
} from "../../pageTypes/BasicPage_Player/components/IBasePage";
import { CURRENT_TEXTBOX_VERSION } from "../../utils/Versioning/basePagesVersioning";

export class ListOfObjects {
  fullList: any[];
  pageImage: IBasicPageImageV2[];
  textBlock: IBasicPageTextBlockV2[];
  tables: IBasicPageTable[];
  annotations: IAnnotation[];
  symbols: ISymbolV2[];
  scorm: IBasicPageScormV2[];
  video: IBasePageVideoV2;

  protected minZ: number;

  constructor() {
    this.fullList = [];
    this.pageImage = [];
    this.textBlock = [];
    this.tables = [];
    this.annotations = [];
    this.symbols = [];
    this.scorm = [];
    this.video = {
      assetVersionId: 0,
      path: "",
      type: "video",
      height: 0,
      width: 0,
      left: 0,
      top: 0,
      zIndex: 0,
      objectId: (0).toString(),
    };

    //private variables
    this.minZ = 12;
  }

  public getList() {
    return this.fullList;
  }

  protected setFullList(list: any[]) {
    this.fullList = _.cloneDeep(list);
  }

  protected setImageList(list: IBasicPageImageV2[]) {
    this.pageImage = list;
  }

  protected setTextBlockList(list: IBasicPageTextBlockV2[]) {
    this.textBlock = list;
  }

  protected setScormList(list: IBasicPageScormV2[]) {
    this.scorm = list;
  }

  protected compileImageList(pageImage: IBasicPageImage[] | IBasicPageImageV2[]) {
    const tempList: Array<IBasicPageImageV2> = [];
    _.forEach(pageImage, (image) => {
      if (_.has(image, "attributes")) {
        const newImage: IBasicPageImageV2 = {
          imagePath: (image as IBasicPageImage).imagePath,
          assetVersionId: (image as IBasicPageImage).assetVersionId,
          type: "pageImage",
          ...(image as IBasicPageImage).attributes,
        };

        tempList.push(newImage);
      } else {
        tempList.push(image as IBasicPageImageV2);
      }
    });
    return tempList;
  }

  protected compileTextBoxList(textBlock: IBasicPageTextBlock[] | IBasicPageTextBlockV2[]) {
    const tempList: Array<IBasicPageTextBlockV2> = [];
    _.forEach(textBlock, (block) => {
      if (_.has(block, "attributes")) {
        const newBlock: IBasicPageTextBlockV2 = {
          text: (block as IBasicPageTextBlock).text,
          lineHeight: "normal",
          version: CURRENT_TEXTBOX_VERSION,
          type: "textBlock",
          ...(block as IBasicPageTextBlock).attributes,
        };
        // this.fullList.push(newBlock);
        tempList.push(newBlock);
      } else {
        // this.fullList.push(block as IBasicPageTextBlockV2);
        tempList.push(block as IBasicPageTextBlockV2);
      }
    });
    return tempList;
  }

  protected compileScormList(scorm: IBasicPageSCORM[] | IBasicPageScormV2[]) {
    const tempList: Array<IBasicPageScormV2> = [];
    _.forEach(scorm, (el) => {
      if (_.has(el, "attributes")) {
        const newScorm: IBasicPageScormV2 = {
          blobPath: "",
          assetVersionId: 0,
          type: "SCORM",
          ...(el as IBasicPageSCORM).attributes,
          objectId: (0).toString(),
        };
        tempList.push(newScorm);
      } else {
        tempList.push(el as IBasicPageScormV2);
      }
    });
    return tempList;
  }

  public countMajorObjects(
    textBlock: IBasicPageTextBlockV2[],
    pageImage: IBasicPageImageV2[],
    tables?: IBasicPageTable[],
  ) {
    const textCount: number = textBlock.length;
    const imageCount: number = pageImage.length;
    let tableCount = 0;
    tables ? (tableCount = tables.length) : (tableCount = 0);
    const total: number = textCount + imageCount + tableCount;
    return total;
  }

  public sendSelectedBack(selected: any) {
    const newList = this.mapZ(selected);
    this.fullList = _.cloneDeep(newList);
    this.filterLists();
    return newList;
  }

  protected filterLists() {
    this.pageImage = this.filterPageImage();
    this.textBlock = this.filterTextBox();
    // this.tables = await this.filterTable();
    this.annotations = this.filterAnnotation();
    this.symbols = this.filterSymbol();
    this.scorm = this.filterSCORM();
    this.video = _.find(this.fullList, ["type", "video"]);
  }

  protected mapZ(selected: any) {
    this.updateDeprecatedZ(this.fullList);
    const tempList = _.cloneDeep(this.fullList);
    _.forEach(tempList, (item, index: number) => {
      const isEqual = _.isEqual(item, selected);
      if (item.zIndex <= selected.zIndex && !isEqual) {
        tempList[index].zIndex += 1;
      }

      if (isEqual) {
        tempList[index].zIndex = this.minZ;
      }
    });

    return tempList;
  }

  protected updateDeprecatedZ(list: any[]) {
    const tempList = _.cloneDeep(list);
    const found = _.find(tempList, (item) => {
      return item.zIndex < 12;
    });
    if (!found) {
      this.fullList = _.cloneDeep(tempList);
      return;
    }
    _.forEach(tempList, (item, index) => {
      if (item.zIndex < 12 || _.isEqual(item, found)) {
        tempList[index].zIndex += 11;
      }
    });
    this.updateDeprecatedZ(tempList);
  }

  public filterPageImage() {
    const tempList = _.filter(this.fullList, (element: any) => {
      return element.type === "pageImage";
    });
    return tempList;
  }

  public filterTextBox() {
    const tempList = _.filter(this.fullList, (element: any) => {
      return element.type === "textBlock";
    });
    return tempList;
  }

  public filterAnnotation() {
    return this.fullList.filter((element) => {
      return ALL_ANNOTATION_TYPES.includes(element?.type);
    });
  }

  public filterSymbol() {
    const tempList: ISymbolV2[] = [];
    _.forEach(this.fullList, (element) => {
      if (element.type === "symbol") {
        tempList.push(element);
      }
    });
    return tempList;
  }

  public filterSCORM() {
    const tempList: IBasicPageScormV2[] = [];
    _.forEach(this.fullList, (element) => {
      if (element.type === "SCORM") {
        tempList.push(element);
      }
    });
    return tempList;
  }

  public getReferenceObjectList(refAnnotations?: IAnnotation[], refSymbols?: ISymbolV2[]) {
    let refObjects: any[] = [];
    if ((!refAnnotations || refAnnotations.length === 0) && (!refSymbols || refSymbols.length === 0)) {
      refObjects = [];
    } else if (refAnnotations && refAnnotations.length > 0 && (!refSymbols || refSymbols.length === 0)) {
      refObjects = refAnnotations;
    } else if ((!refAnnotations || refAnnotations.length === 0) && refSymbols && refSymbols.length > 0) {
      refObjects = refSymbols;
    } else {
      refObjects = _.concat(refAnnotations as any, refSymbols);
    }
    this.fullList = refObjects;
    return refObjects;
  }

  public changeTheZ(newNode: any, originalNode: any) {
    if (newNode.zIndex > originalNode.zIndex) {
      this.moveSelectedZHigher(newNode, originalNode);
    } else {
      this.moveSelectedZLower(newNode, originalNode);
    }
    // await this.updateSelected(newNode, originalNode);
    this.filterLists();

    return this.fullList;
  }

  protected moveSelectedZHigher(newNode: any, originalNode: any) {
    this.fullList.forEach((item: IBasicPageImageV2 | IBasicPageTextBlockV2, index: number) => {
      if (
        (item as IBasicPageImageV2).zIndex > (originalNode.zIndex as number) &&
        (item as IBasicPageImageV2).zIndex <= (newNode.zIndex as number)
      ) {
        this.fullList[index].zIndex--;
      }
      if (_.isEqual(item, originalNode)) {
        this.fullList[index] = _.cloneDeep(newNode);
      }
    });
    return this.fullList;
  }

  protected moveSelectedZLower(newNode: any, originalNode: any) {
    this.fullList.forEach((item: IBasicPageImageV2 | IBasicPageTextBlockV2, index: number) => {
      if (item.zIndex < (originalNode.zIndex as number) && item.zIndex >= (newNode.zIndex as number)) {
        this.fullList[index].zIndex++;
      }
      if (_.isEqual(item, originalNode)) {
        this.fullList[index] = _.cloneDeep(newNode);
      }
    });
    return this.fullList;
  }

  protected async updateSelected(newNode: any, originalNode: any) {
    const copy = _.cloneDeep(this.fullList);
    _.forEach(copy, (item, index: number) => {
      if (_.isEqual(item, originalNode)) {
        copy[index] = _.cloneDeep(newNode);
      }
    });
    this.fullList = _.cloneDeep(copy);
  }
}
