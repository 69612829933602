import React from "react";

interface Props {
  children?: React.ReactNode;
}

const ImagesContainer = ({ children }: Props) => {
  return <div className="image-list-container">{children}</div>;
};

export default React.memo(ImagesContainer);
