import React, { PropsWithChildren, createContext, useState, useContext, useReducer } from "react";
import { cloneDeep } from "lodash";

interface WysiwygState {
  objectList: Set<any>;
  currentSelectedObject: any;
}

const initialState: WysiwygState = {
  objectList: new Set(),
  currentSelectedObject: null,
};

const wysiwygState = createContext<any>(initialState);
const wysiwygDispatch = createContext<any>({});

const wysiwygReducer = (state: WysiwygState, action: any) => {
  switch (action.type) {
    case "SET_CURRENT_SELECTED_OBJECT": {
      return { ...state, currentSelectedObject: action.payload };
    }
    case "SET_OBJECT_LIST": {
      return { ...state, objectList: action.payload };
    }
    default:
      return state;
  }
};

export function WysiwygProvider({ children }: PropsWithChildren<any>) {
  const [state, dispatch] = useReducer(wysiwygReducer, initialState); // TODO use reducer
  return (
    <wysiwygDispatch.Provider value={dispatch}>
      <wysiwygState.Provider value={state}>{children}</wysiwygState.Provider>
    </wysiwygDispatch.Provider>
  );
}

export function useWysiwygDispatch() {
  const ctx = useContext(wysiwygDispatch);
  if (ctx === undefined) {
    throw new Error("Wrap component in Wysiwyg Provider");
  }
  return ctx;
}
export function useWysiwygState() {
  const ctx = useContext(wysiwygState);
  if (ctx === undefined) {
    throw new Error("Wrap component in Wysiwyg Provider");
  }
  return ctx;
}
