import React from "react";
import lodash from "lodash";
import { incrementAdder } from "../../components/NarratorDrawer/utils/narratorUtils";
import { useGlobalAudioDispatch } from "../GlobalAudioProvider/GlobalAudioProvider";

const initialState = {
  demoTabNarratorText: [] as string[],
  practiceTabNarratorText: [] as string[],
  performTabNarratorText: [] as string[],
  allTabsNarratorText: [] as string[],
  currentSlectedTab: "all",
  pageManifestCopy: null as any,
  pageManifest: null as any,
  selectedNarration: 0,
  currentPullout: "narration", // or timeline
};
const NarratorDrawerStateContext = React.createContext<typeof initialState | undefined>(undefined);
const NarratorDrawerDispatchContext = React.createContext<React.Dispatch<{ type: string; payload: any }> | undefined>(
  undefined,
);
function indexSelector(index: number, selectedNarration: number, audioArray: any[]) {
  const newIndex = selectedNarration * 3 + index;
  if (audioArray[newIndex]) {
    return newIndex;
  }
  return index;
}
function changeTab(state: any, action: { type: string; payload: "all" | "demo" | "prac" | "perf" }) {
  return { ...state, currentSlectedTab: action.payload };
}

function updateDemoNarratorText(state: any, action: { type: string; payload: string }) {
  const audioCopy = state.pageManifestCopy.Audio.map((x: any) => ({ ...x }));
  //don't judge me there was some weird things happening here
  const audioIndex = (i: number) => indexSelector(i, state.selectedNarration, audioCopy ?? []);
  const text = lodash.cloneDeep(state.performTabNarratorText);
  text[state.selectedNarration] = action.payload;
  audioCopy[audioIndex(0)].NarratorText = action.payload;

  return {
    ...state,
    demoTabNarratorText: text ?? [],
    pageManifestCopy: { ...state.pageManifestCopy, Audio: audioCopy },
  };
}

function addAlternateAudio(state: any) {
  const audio = lodash.cloneDeep(state.pageManifestCopy.Audio);
  for (let i = 0; i < 3; ++i) {
    audio.push({
      Version: null,
      NarratorText: "",
      File: null,
    });
  }
  return { ...state, pageManifestCopy: { Audio: audio } };
}

function updatePracticeNarratorText(state: any, action: { type: string; payload: string }) {
  const audioCopy = state.pageManifestCopy.Audio.map((x: any) => ({ ...x }));
  const audioIndex = (i: number) => indexSelector(i, state.selectedNarration, audioCopy ?? []);
  const text = lodash.cloneDeep(state.performTabNarratorText);
  text[state.selectedNarration] = action.payload;

  audioCopy[audioIndex(1)].NarratorText = action.payload;

  return {
    ...state,
    practiceTabNarratorText: text ?? [],
    pageManifestCopy: { ...state.pageManifestCopy, Audio: audioCopy },
  };
}
function updatePerformNarratorText(state: any, action: { type: string; payload: string }) {
  let audioCopy = state.pageManifestCopy.Audio.map((x: any) => ({ ...x }));
  const text = lodash.cloneDeep(state.performTabNarratorText);
  text[state.selectedNarration] = action.payload;
  const audioIndex = (i: number) => indexSelector(i, state.selectedNarration, audioCopy ?? []);
  audioCopy = audioCopy.map((x: any, index: number) => {
    if (index === audioIndex(2)) {
      return {
        ...x,
        NarratorText: action.payload,
      };
    }
    return x;
  });

  return {
    ...state,
    performTabNarratorText: text,
    pageManifestCopy: { ...state.pageManifestCopy, Audio: audioCopy },
  };
}
function updateAllNarratorText(state: any, action: { type: string; payload: string }) {
  const audioIndex = (i: number) => indexSelector(i, state.selectedNarration, state.pageManifestCopy?.Audio ?? []);
  const audio = lodash.cloneDeep(state.pageManifestCopy.Audio);
  audio[audioIndex(0)].NarratorText = action.payload;
  audio[audioIndex(1)].NarratorText = action.payload;
  audio[audioIndex(2)].NarratorText = action.payload;
  if (audio) {
    // let demo = [audio[0].NarratorText, audio[3].NarratorText];
    // let prac = [audio[1].NarratorText, audio[4].NarratorText];
    // let perf = [audio[2].NarratorText, audio[5].NarratorText];
    const narrTextArr = audio.map((x: any) => x.NarratorText);
    const demo = incrementAdder(0, narrTextArr);
    const prac = incrementAdder(1, narrTextArr);
    const perf = incrementAdder(2, narrTextArr);
    return {
      ...state,
      demoTabNarratorText: demo ?? [],
      practiceTabNarratorText: prac ?? [],
      performTabNarratorText: perf ?? [],
      pageManifestCopy: { ...state.pageManifestCopy, Audio: audio },
    };
  }
  return {
    ...state,
    demoTabNarratorText: [],
    practiceTabNarratorText: [],
    performTabNarratorText: [],
    pageManifestCopy: { ...state.pageManifestCopy, Audio: audio },
  };
}

// function updateAllAudio(state: any, action: { type: string; payload: string }) {
//   const audioIndex = (i: number) => indexSelector(i, state.selectedNarration, state.pageManifestCopy?.Audio ?? [])
//   let audio = lodash.cloneDeep(state.pageManifestCopy.Audio)
//   audio[audioIndex(0)].File = action.payload;
//   audio[audioIndex(1)].File = action.payload;
//   audio[audioIndex(2)].File = action.payload;
//     return {
//     ...state,
//     pageManifestCopy: { ...state.pageManifestCopy, Audio: audio }
//   }
// }
function resetLocalTabText(state: any) {
  // const audioIndex = (i: number) => indexSelector(i, state.selectedNarration, state.pageManifestCopy?.Audio ?? [])
  const audio = lodash.cloneDeep(state?.pageManifestCopy?.Audio);
  if (audio) {
    // for (let i = 0; i < audio.length; i++) {
    //   audio[i] = state?.pageManifestCopy?.Audio?.[audioIndex(0)]?.NarratorText
    // }
    const narrTextArr = audio.map((x: any) => x?.NarratorText);
    const demo = incrementAdder(0, narrTextArr);
    const prac = incrementAdder(1, narrTextArr);
    const perf = incrementAdder(2, narrTextArr);
    return {
      ...state,
      demoTabNarratorText: demo ?? [],
      practiceTabNarratorText: prac ?? [],
      performTabNarratorText: perf ?? [],
    };
  }

  return {
    ...state,
    demoTabNarratorText: [],
    practiceTabNarratorText: [],
    performTabNarratorText: [],
  };
}

function updatePageManifestCopy(state: any, action: { type: string; payload: any }) {
  return {
    ...state,
    pageManifestCopy: action.payload,
  };
}
function updatePageManifest(state: any, action: { type: string; payload: any }) {
  return {
    ...state,
    pageManifest: action.payload,
  };
}

function changeSelectedNarration(state: any, action: { type: string; payload: any }) {
  return {
    ...state,
    selectedNarration: action.payload,
  };
}

const narratorDrawerReducer = (state: any, action: any) => {
  switch (action.type) {
    case "CHANGE_TAB":
      return changeTab(state, action);
    case "UPDATE_DEMO_TEXT":
      return updateDemoNarratorText(state, action);
    case "UPDATE_PRACTICE_TEXT":
      return updatePracticeNarratorText(state, action);
    case "UPDATE_PERFORM_TEXT":
      return updatePerformNarratorText(state, action);
    case "UPDATE_ALL_TEXT":
      return updateAllNarratorText(state, action);
    case "UPDATE_PAGE_MANIFEST_COPY":
      return updatePageManifestCopy(state, action);
    case "UPDATE_PAGE_MANIFEST":
      return updatePageManifest(state, action);
    case "RESET_INTERNAL_TABS":
      return resetLocalTabText(state);
    case "ADD_ALTERNATE_AUDIO":
      return addAlternateAudio(state);
    case "CHANGE_SELECTED_NARRATION":
      return changeSelectedNarration(state, action);
    case "SET_SELECTED_PULLOUT": {
      return {
        ...state,
        currentPullout: action.payload,
      };
    }
    default:
      return state;
  }
};

const NarratorDrawerProvider = ({
  children,
  page: { pageManifest },
}: {
  children: React.ReactChild | JSX.IntrinsicElements;
  page: any;
}) => {
  const [state, dispatch] = React.useReducer(narratorDrawerReducer, initialState);
  const effectRan = React.useRef(0);
  const globalAudioDipatch = useGlobalAudioDispatch();
  React.useEffect(() => {
    effectRan.current += 1;
    if (pageManifest && "Audio" in pageManifest) {
      // dispatch({type: 'UPDATE_DEMO_TEXT', payload: pageManifest.Audio[0].NarratorText})
      // dispatch({type: 'UPDATE_PRACTICE_TEXT', payload: pageManifest.Audio[1].NarratorText})
      // dispatch({type: 'UPDATE_PERFORM_TEXT', payload: pageManifest.Audio[2].NarratorText})
      if (pageManifest.Audio.length < 3) {
        dispatch({ type: "CHANGE_SELECTED_NARRATION", payload: 0 });
        globalAudioDipatch({
          type: "SET_SELECTED_NARRATION_INDEX",
          payload: 0,
        });
      }
      if (lodash.isEqual(pageManifest, state.pageManifestCopy)) {
      } else {
        dispatch({ type: "UPDATE_PAGE_MANIFEST_COPY", payload: pageManifest });
        dispatch({ type: "UPDATE_PAGE_MANIFEST", payload: pageManifest });
        dispatch({ type: "RESET_INTERNAL_TABS", payload: null });
      }
      //   if(lodash.isEqual(pageManifest?.Audio, )
    } else {
      // dispatch({type: 'UPDATE_PERFORM_TEXT', payload: ''})
    }
    return () => {};
  }, [pageManifest]);

  return (
    <NarratorDrawerStateContext.Provider value={state}>
      <NarratorDrawerDispatchContext.Provider value={dispatch}>{children}</NarratorDrawerDispatchContext.Provider>
    </NarratorDrawerStateContext.Provider>
  );
};

const useNarratorDrawerState = () => {
  const ctx = React.useContext(NarratorDrawerStateContext);
  if (ctx === undefined) throw new Error("You must call useNarratorDrawerState inside a narratorDrawer Provider.");

  return ctx;
};

const useNarratorDrawerDispatch = () => {
  const ctx = React.useContext(NarratorDrawerDispatchContext);
  if (ctx === undefined) throw new Error("You must call useNarratorDrawerState inside a narratorDrawer Provider.");

  return ctx;
};

export { NarratorDrawerProvider, useNarratorDrawerState, useNarratorDrawerDispatch };
