import React from "react";
import { SVGAnnotationProps } from "../index";
import { HighlightAnnotation } from "../HighlightAnnotation";

export function SVGILSLocalizer({
  onMouseDown,
  objectId,
  y,
  x,
  width,
  height,
  isDisplayed,
  zIndex,
  borderColor,
  strokeWidth,
  backgroundColor,
  opacity,
  rotate,
}: // transform,
SVGAnnotationProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12.7 23.53"
      key={objectId}
      id={`symbol-${objectId}`}
      data-objectid={objectId}
      tabIndex={0}
      className="symbol target"
      name="symbol"
      onMouseDown={(e: React.MouseEvent<SVGSVGElement>) => {
        onMouseDown(e, objectId);
      }}
      // onKeyDown={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyPress(e, kp)}
      // onKeyUp={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyUp(e, kp)}
      preserveAspectRatio="xMidYMid meet"
      style={{
        // top: `${y}px`,
        // left: `${x}px`,
        width: `${width}px`,
        height: `${height}px`,
        zIndex: zIndex,
        transform: `translate(${x}px, ${y}px) rotate(${rotate ?? 0}deg)`,
        opacity: isDisplayed ? opacity : 0.5,
        filter: isDisplayed ? "none" : "grayScale(100%)",
        // `rotate(${attributes?.transform?.rotate}deg) scaleX(${attributes?.transform?.scaleX}) scaleY(${attributes?.transform?.scaleY}) matrix3d(${attributes?.transform?.matrix3d})`
      }}
    >
      <HighlightAnnotation objectId={objectId} />
      <path
        // className="ils_localizer_1"
        stroke={`${borderColor || "white"}`}
        fill={`${backgroundColor || "transparent"}`}
        strokeWidth={`${strokeWidth / 10 || 0.1}`}
        d="M 5.0799999,0.5 H 7.75 V 23.030001 H 5.0799999 Z"
      />
      {/* <path
        // className="ils_localizer_2"
        stroke={`${borderColor || 'white'}`}
        fill={`${backgroundColor || 'transparent'}`}
        strokeWidth={`${strokeWidth || .1}`}
        d="M 3.73,11.65 A 1.6900001,1.6900001 0 0 1 2.04,13.34 1.6900001,1.6900001 0 0 1 0.3499999,11.65 1.6900001,1.6900001 0 0 1 2.04,9.9599996 1.6900001,1.6900001 0 0 1 3.73,11.65"
      />
      <path
        // className="ils_localizer_2"
        stroke={`${borderColor || 'white'}`}
        fill={`${backgroundColor || 'transparent'}`}
        strokeWidth={`${strokeWidth || .1}`}
        d="M 12.35,11.65 A 1.6900001,1.6900001 0 0 1 10.66,13.34 1.6900001,1.6900001 0 0 1 8.9699998,11.65 1.6900001,1.6900001 0 0 1 10.66,9.9599996 1.6900001,1.6900001 0 0 1 12.35,11.65"
      /> */}
    </svg>
  );
}
