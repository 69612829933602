import React, { useEffect } from "react";

import {
  useGLTF,
  useProgress
} from "@react-three/drei"

const Cockpit = (props) => {
  
  const modelPath = props.modelPath + "/Cockpit.gltf";
  
  const { scene } = useGLTF(modelPath);
  const { progress } = useProgress();
  
  useEffect(() => {
    if(progress === 100) {
      props.callBack();
    }
  }, [progress])

  return <primitive object={scene} />
};

export default Cockpit;
