import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { IAssetManagementContext, AssetContext } from "../../routes/builderContexts";
import selectorArrow from "../../assets/icons/selector-arrow-icon.png";
import UploaderOptionFields from "./UploaderOptionFields";
import { IUploaderContext, UploaderContext } from "./uploaderContext";
import IAsset from "../../models/IAsset";
import blobHelper from "../blobUrlFormatHelper";

const ImageUploader: React.FC = (): JSX.Element => {
  const assetContext: IAssetManagementContext = useContext<IAssetManagementContext>(AssetContext);
  const uploaderContext: IUploaderContext = useContext<IUploaderContext>(UploaderContext);
  const tableRows = () => {
    return (
      <React.Fragment>
        <div className="filename-header">File Name</div>
        <div className="description-header">Description</div>
        {uploaderContext.imagesList.map((image: IAsset, index: number) => {
          return (
            <React.Fragment key={index}>
              <div
                className={`selector-row ${uploaderContext.selectedImage === image.assetVersionId ? "selected" : ""}`}
                id={`${image.assetVersionId}`}
                onClick={(e: any) => uploaderContext.handleSelected(e)}
              >
                <div className="selector-thumb">
                  <img src={blobHelper(image.blobPath)} alt={image.name} />
                </div>
                <div className="selector-filename">{image.name}</div>
                <div className="selector-description">{image.description}</div>
              </div>
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      <p>Select image file from our library or upload a new image.</p>
      <div
        className="img-loader-body"
        style={{
          pointerEvents: uploaderContext.uploadProcessing ? "none" : "auto",
        }}
      >
        <div>
          {assetContext.assetIndex < 0 ? (
            <></>
          ) : (
            <Button
              type="button"
              className="image-loader-button spaced"
              onClick={() => uploaderContext.handleSelectImageClick()}
            >
              Select Image
            </Button>
          )}
          <Button
            type="button"
            className="image-loader-button spaced"
            onClick={() => uploaderContext.handleUploadClick()}
          >
            Upload Image
          </Button>
          {!uploaderContext.isFileSelectShown ? (
            <></>
          ) : (
            <>
              <input
                className="form-control-file"
                type="file"
                ref={uploaderContext.rawFile}
                accept="image/png, image/jpeg, image/jpg, image/gif"
                onChange={uploaderContext.manageFile}
              ></input>
              {uploaderContext.nameSplit.length > 0 ? (
                <small>Your file name has been updated to {uploaderContext.nameSplit}</small>
              ) : (
                <></>
              )}
              {!uploaderContext.isGoodFilename && uploaderContext.isFilePresent ? (
                <small className="upload-error">{uploaderContext.statusMessage}</small>
              ) : (
                <></>
              )}
              <div style={{ margin: "1rem 0" }}>
                <label htmlFor="description">
                  Description: <small className="required">*Required</small>
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter a description"
                  id="description"
                  name="description"
                  value={uploaderContext.description}
                  onChange={uploaderContext.handleChange}
                  required
                />
              </div>
              <img
                src={selectorArrow}
                alt=""
                className={`option-arrow ${!uploaderContext.isOptionsShown ? "" : "rotated"}`}
                onClick={() => uploaderContext.setIsOptionsShown(!uploaderContext.isOptionsShown)}
              />
            </>
          )}
        </div>

        {uploaderContext.isCollapsed ? (
          <></>
        ) : (
          <React.Fragment>
            <div className="form-group img-selector">{tableRows()}</div>
          </React.Fragment>
        )}
        {!uploaderContext.isOptionsShown ? <></> : <UploaderOptionFields />}
      </div>
    </React.Fragment>
  );
};

export default ImageUploader;
