import React from "react";
import { Frame } from "./Frame";
import { ObjectActionsType, useObjectsDispatch } from "../../../../../contexts/ObjectsProvider";
import { Frame as FrameType } from "../../../../../types";
import { AnimatedMetaVariable, useMetaVariableStore } from "../../../../../lib/SmartObject/store";

export function FramesC({
  frames,
  start,
  end,
  objectId,
}: {
  frames: FrameType[] | AnimatedMetaVariable;
  start: number;
  end: number | null;
  objectId?: string;
}) {
  const objectsDispatch = useObjectsDispatch();
  const updateAnimatedMetaVariableTimestamp = useMetaVariableStore((s) => s.updateAnimatedMetaVariableTimestamp);

  if ("metaVariableKey" in frames) {
    return (
      <>
        {frames.oneshot?.map((frame, idx) => (
          <Frame
            key={idx}
            start={start}
            end={end}
            frame={frame}
            onDragEnd={({ timestamp }) => {
              console.log("on drag end animated meta variable", timestamp, frame.metadata);
              if (timestamp !== undefined) {
                updateAnimatedMetaVariableTimestamp(frames.metaVariableKey, frame.timestamp, timestamp, false);
              }
            }}
          />
        ))}
        {frames.interpolated?.map((frame, idx) => (
          <Frame
            key={idx}
            start={start}
            end={end}
            frame={frame}
            onDragEnd={({ timestamp }) => {
              console.log("on drag end animated meta variable", timestamp, frame.timestamp);
              if (timestamp !== undefined) {
                updateAnimatedMetaVariableTimestamp(frames.metaVariableKey, frame.timestamp, timestamp, true);
              }
            }}
          />
        ))}
      </>
    );
  }

  return (
    <>
      {frames.map((frame: FrameType) => (
        <Frame
          key={frame.id}
          start={start}
          end={end}
          frame={frame}
          onDragEnd={({ timestamp }) => {
            // update the object with the new frame
            if (objectId) {
              objectsDispatch({
                type: ObjectActionsType.UPDATE_OBJECT_FRAME,
                payload: {
                  frame: {
                    id: frame.id,
                    timestamp: timestamp ?? frame.timestamp,
                  },
                  objectId,
                },
              });
            }
          }}
        />
      ))}
    </>
  );
}

// forward ref of the Frames component
export const FramesFR = React.forwardRef<
  HTMLDivElement,
  {
    frames: FrameType[] | AnimatedMetaVariable;
    start: number;
    end: number | null;
    objectId?: string;
  }
>((props, ref) => {
  return (
    <div
      className="frames"
      ref={ref}
      style={{
        pointerEvents: "none",
        width: "100%",
        height: "100%",
      }}
    >
      <FramesC {...props} />
    </div>
  );
});
FramesFR.displayName = "FramesFR";
