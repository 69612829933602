import React from "react";
import { connect } from "react-redux";

type props = {
  curtainSetting: boolean;
};

const MagicCover = (props: props) => {
  if (props.curtainSetting === true) {
    return (
      <div className="sceneCover">
        <div className="loading-scene"> Loading 3d scene .... </div>
      </div>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state: any) => {
  return { curtainSetting: state.Current3dPage.curtain };
};
export default connect(mapStateToProps, {})(MagicCover);
