import React, { useContext } from "react";
import { Interweave } from "interweave";
import { IFmsContext, FmsContext } from "../../Interfaces/fmsContext";
const CDUModalColumns = (props: any) => {
  const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);
  const clickHandler = (event: React.MouseEvent, id: string): void => {
    event.preventDefault();
    if (fmsContext.cursorSelected) {
      fmsContext.findCursorObject(id);
    } else {
      props.accessCduLineInformation(event.currentTarget.id);
    }
  };
  return (
    <>
      {props.columnValues ? (
        Object.keys(props.columnValues).map((col: string, index: number) => (
          <foreignObject
            className={"cell-display"}
            id={col}
            x={props.columnData.xCoordinate}
            y={props.columnData.yCoordinate + props.columnData.rowHeight * index}
            key={index}
            height={props.columnData.rowHeight}
            width={props.columnData.width}
            stroke="red"
            strokeWidth="2"
            fill="none"
            pointerEvents=""
            onClick={(event: any) => {
              clickHandler(event, col);
            }}
          >
            <div
              className="displayDiv"
              style={{
                height: props.columnData.rowHeight,
                width: props.columnData.width,
                whiteSpace: "pre",
                color: "#ffffff",
                textTransform: "uppercase",
                border: fmsContext.gridBorder,
                fontSize: "16px",
                padding: "0 auto",
              }}
            >
              <Interweave content={props.columnValues[col]} />
            </div>
          </foreignObject>
        ))
      ) : (
        <></>
      )}
      {props.columnValues ? (
        <g
          onClick={() => {
            props.addModalRow(props.columnName);
          }}
        >
          <rect
            x={props.columnData.xCoordinate}
            y={props.columnData.yCoordinate + props.columnData.rowHeight * Object.keys(props.columnValues).length}
            height={props.columnData.rowHeight}
            width={props.columnData.width}
            strokeWidth="2"
            stroke="white"
            strokeDasharray="2 4"
          />
          <text
            x={props.columnData.xCoordinate + props.columnData.width / 2 - 6}
            y={
              props.columnData.yCoordinate +
              props.columnData.rowHeight / 2 +
              props.columnData.rowHeight * Object.keys(props.columnValues).length +
              4
            }
            strokeWidth="2"
            stroke="white"
          >
            +
          </text>
        </g>
      ) : (
        <></>
      )}
    </>
  );
};
export default CDUModalColumns;
