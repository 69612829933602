import React from "react";

interface Props {
  message: string;
}
export const InfoPanel = (props: Props) => {
  return (
    <div className="assetManager-metadata-editor-infopanel">
      <p>{props.message}</p>{" "}
    </div>
  );
};
