import React, { ReactElement } from "react";
import { PropertyConfig } from "./types";
import { PropertiesPanelField } from "./PropertiesPanelField";

import "./index.css";

export interface PropertiesPanelProps<T> {
  identifier: string;
  data: T;
  config: PropertyConfig<T>;
  onChange: (identifier: string, value: unknown, data: T) => void;
  actionsSlot?: ReactElement;
}

export type PropertiesPanelComponent = <TData>(props: PropertiesPanelProps<TData>) => ReactElement;

export const PropertiesPanel: PropertiesPanelComponent = ({ identifier, data, config, onChange, actionsSlot }) => {
  return (
    <div className="properties-panel">
      <div className="properties-fields">
        {config
          .filter((fieldConfig) => {
            if (fieldConfig?.visible) {
              return fieldConfig.visible(data);
            } else {
              return true;
            }
          })
          .map((fieldConfig) => (
            <PropertiesPanelField<typeof data>
              field={fieldConfig}
              data={data}
              key={`panel-${identifier}-field-${fieldConfig.identifier}`}
              onChange={(value: unknown) => onChange(fieldConfig.identifier, value, data)}
            />
          ))}
      </div>
      {actionsSlot && <div className="properties-actions">{actionsSlot}</div>}
    </div>
  );
};
