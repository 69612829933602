import _ from "lodash";
import "./fmsAssets/fmsStyles.css";
import React, { useState, useEffect, SetStateAction, RefObject, useRef, Dispatch, useContext } from "react";
import blobHelper from "../../../../components/blobUrlFormatHelper";
import {
  CPAT_LESSON_MODE_INSTRUCTIONAL,
  CPAT_LESSON_MODE_PRACTICE,
  CPAT_LESSON_MODE_TEST,
} from "../../helpers/lessonmodetypes";

import SmartObjective from "../../components/SmartObjective/SmartObjective";
import { IFmsPageManifest, IFMSReferenceImage } from "../../../../pageTypes/FMS_Player/Interfaces/IFmsPageManifest";
import FMSReferenceImage from "./components/FMSReferenceImage";
import { ISuccessCriteria } from "./models/IFMS";
import { ICduImage } from "../../../../pageTypes/FMS_Player/Interfaces/ICdu";
import FMSReferenceImageModal from "./components/FMSReferenceImageModal";
//import { editPage } from '../../../../store/actions/actions';
import { IPageContext, PageContext } from "../../../../routes/builderContexts";
import ClassicLayout from "../FMS_Player/components/CDULayouts/ClassicLayout";
import StandardLayout from "../FMS_Player/components/CDULayouts/StandardLayout";
import WideCDULayout from "../FMS_Player/components/CDULayouts/WideCduLayout";
import EnablingObjective from "../../components/SmartObjective/components/EnablingObective";

export type instructionsType = {
  id: number;
  text: string;
};

type FMSWYSIWYGType = {
  cduPath: string;
  enablingObjective: string;
  enablingObjectiveTitle: string;
  image: ICduImage;
  pageManifest: IFmsPageManifest;
  mode: number;
};

const FMSWYSIWYG = (props: FMSWYSIWYGType): JSX.Element => {
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);

  const { cduPath, mode, pageManifest } = props;
  const fmsRef: RefObject<HTMLDivElement> = useRef(null);
  const version = 20210312; //keep consistent with FMS designer component
  const criteriaArray = _.cloneDeep(pageManifest.successCriteria);
  // const functionKeys = pageManifest.function;
  const pageAttempts = pageManifest.pageAttempts;

  const [criteriaIndex, setCriteriaIndex] = useState(0);
  // const [helperText, setHelperText] = useState("");
  const [isHelperShown, setIsHelperShown] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [maxAttempts, setMaxAttempts] = useState(-1);
  const [stepInstructions, setStepInstructions]: [
    instructionsType[],
    React.Dispatch<SetStateAction<instructionsType[]>>,
  ] = useState<instructionsType[]>([]);
  const [fmsDims, setFMSDims]: [
    { height: number; width: number },
    Dispatch<SetStateAction<{ height: number; width: number }>>,
  ] = useState<{ height: number; width: number }>({ height: 100, width: 100 });
  const [isReferenceShown, setIsReferenceShown]: [boolean, Dispatch<SetStateAction<boolean>>] =
    useState<boolean>(false);
  const [hasPageImage, setHasPageImage] = useState<boolean>(false);
  const [newPageImageId, setNewPageImageId] = useState<number>(-1);
  //expand current cdu page to emcompass json
  const CPaTLessonMode = () => {
    switch (mode) {
      case 0:
        return CPAT_LESSON_MODE_INSTRUCTIONAL;
      case 1:
        return CPAT_LESSON_MODE_PRACTICE;
      case 2:
        return CPAT_LESSON_MODE_TEST;
      default:
        return CPAT_LESSON_MODE_INSTRUCTIONAL;
    }
  };

  useEffect(() => {
    populateInstructionList();
    setMaxAttemptsMode();
    setIsLoading(false);
    pageManifest.pageImage ? setHasPageImage(true) : setHasPageImage(false);
    pageManifest.pageImage && pageManifest.pageImage[0]
      ? setNewPageImageId(pageManifest.pageImage[0].assetVersionId)
      : setNewPageImageId(-1);
  }, []);

  useEffect(() => {
    if (fmsRef.current) {
      const dims = fmsRef.current?.getBoundingClientRect();
      setFMSDims({ height: dims?.height, width: dims?.width });
    }
  }, [isLoading, fmsRef.current]);
  useEffect(() => {
    if (
      !hasPageImage &&
      pageManifest.pageImage &&
      pageManifest.pageImage.length > 0 &&
      !isLoading &&
      getFMSRatio()! > 79
    ) {
      setHasPageImage(true);
      setIsReferenceShown(true);
    }
    if (hasPageImage) {
      if (
        pageManifest.pageImage &&
        pageManifest.pageImage[0] &&
        newPageImageId !== pageManifest.pageImage[0].assetVersionId &&
        getFMSRatio()! > 79
      ) {
        setIsReferenceShown(true);
        setNewPageImageId(pageManifest.pageImage[0].assetVersionId);
      }
    }
  }, [pageManifest.pageImage]);

  const validateInstructionList = (array: any) => {
    if (!_.isEmpty(array)) {
      _.forEach(array, (el: ISuccessCriteria, index: number) => {
        if (typeof el.instructions === "string") {
          array[index].instructions = { id: index, text: el.instructions };
        }
      });
    }
    return array;
  };

  const populateInstructionList = () => {
    if (!_.isEmpty(criteriaArray)) {
      const array = validateInstructionList(criteriaArray);
      const instructions: any = _.map(array, (criteria, index) => {
        const str = criteria.instructions.text.replace(/<[^>]*>/g, "");
        criteria.instructions = { id: index, text: str };
        return criteria.instructions;
      });
      setStepInstructions(instructions);
    }
  };

  const setMaxAttemptsMode = () => {
    switch (CPaTLessonMode()) {
      case CPAT_LESSON_MODE_TEST:
        setMaxAttempts(pageAttempts.perform);
        break;
      case CPAT_LESSON_MODE_PRACTICE:
      default:
        setMaxAttempts(pageAttempts.practice);
        break;
    }
  };

  const getWidth = () => {
    let perWidth = 40;

    if (props.image) {
      const percent = props.image.width / props.image.height;
      const someDims = _.cloneDeep(fmsDims);
      if (fmsRef.current?.parentElement)
        perWidth = ((fmsDims.height * percent) / fmsRef.current?.parentElement?.getBoundingClientRect().width) * 100;
      someDims.width = fmsDims.height * percent;
      if (!_.isEqual(someDims, fmsDims)) {
        setFMSDims(someDims);
      }
      return perWidth;
    }
    return perWidth;
  };

  const displayReferenceImageModal = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    setIsReferenceShown(true);
  };

  const removeImage = (e: any, assetVersionId: number, index: number) => {
    e.stopPropagation();
    if (pageManifest.pageImage) {
      pageContext.updatePageAsset(null, assetVersionId);
      pageManifest.pageImage.splice(index, 1);
      pageContext.updatePageManifest(pageManifest);
    }
  };
  const getFMSRatio = () => {
    let percent = 78.9;
    if (props.image) {
      percent = (props.image.width / props.image.height) * 100;
    }
    return percent;
  };

  if (isLoading && !fmsRef.current) {
    return <div>Loading</div>;
  } else {
    return pageManifest.version && pageManifest.version > 20210223 ? (
      getFMSRatio()! > 79 ? (
        <WideCDULayout
          cduPath={cduPath}
          image={props.image}
          pageManifest={pageManifest}
          mode={mode}
          fmsRef={fmsRef}
          criteriaIndex={criteriaIndex}
          getWidth={getWidth}
          displayReferenceImageModal={displayReferenceImageModal}
          removeImage={removeImage}
          setIsHelperShown={setIsHelperShown}
          setIsReferenceShown={setIsReferenceShown}
          isReferenceShown={isReferenceShown}
          fmsDims={fmsDims}
          stepInstructions={stepInstructions}
          version={pageManifest.version}
          newPageImageId={newPageImageId}
          setNewPageImageId={setNewPageImageId}
          getFMSRatio={getFMSRatio}
        />
      ) : (
        <StandardLayout
          cduPath={cduPath}
          image={props.image}
          pageManifest={pageManifest}
          mode={mode}
          fmsRef={fmsRef}
          criteriaIndex={criteriaIndex}
          getWidth={getWidth}
          displayReferenceImageModal={displayReferenceImageModal}
          removeImage={removeImage}
          setIsHelperShown={setIsHelperShown}
          setIsReferenceShown={setIsReferenceShown}
          isReferenceShown={isReferenceShown}
          fmsDims={fmsDims}
          stepInstructions={stepInstructions}
          version={pageManifest.version}
          newPageImageId={newPageImageId}
          setNewPageImageId={setNewPageImageId}
          getFMSRatio={getFMSRatio}
        />
      )
    ) : (
      <ClassicLayout
        cduPath={cduPath}
        image={props.image}
        pageManifest={pageManifest}
        mode={mode}
        fmsRef={fmsRef}
        criteriaIndex={criteriaIndex}
        getWidth={getWidth}
        displayReferenceImageModal={displayReferenceImageModal}
        removeImage={removeImage}
        setIsHelperShown={setIsHelperShown}
        setIsReferenceShown={setIsReferenceShown}
        isReferenceShown={isReferenceShown}
        fmsDims={fmsDims}
        stepInstructions={stepInstructions}
        version={pageManifest.version}
        newPageImageId={newPageImageId}
        setNewPageImageId={setNewPageImageId}
        getFMSRatio={getFMSRatio}
      />
    );
  }
};

export default FMSWYSIWYG;
