import _ from "lodash";
import { IAnnotation } from "../../../../../components/Annotation/models/IAnnotation";
import { IBasicPageTextBlockV2 } from "../../IBasePage";
import {
  QUILL_BLOCK_CLASS_ATTRIBUTOR_NAME,
  QUILL_BLOCK_CLASS_NAME,
  QUILL_INLINE_CLASS_ATTRIBUTOR_NAME,
} from "../../../../../components/richTextEditor/NEWrichTextEditor";

export function interweaveHtmlAndPlainTextToQuillHtmlBridgeFromatting(
  list: IBasicPageTextBlockV2[],
): IBasicPageTextBlockV2[] {
  //edited list is created here and mutated below but there are several sections that are  sometimes using block and
  // some using editedList, and some even the list variable there needs to be more consistency a.k.a. use editedList throughout
  const editedList: IBasicPageTextBlockV2[] = _.cloneDeep(list);
  _.forEach(list, (block, index) => {
    /* if the text block version is absent and for whatever reason undefined than
     *  we do want to run the formatting on a text block that was initially created with quill
     * the reason being that since the html text was create by quill there is no reason to fromat it to quill again
     * and all new text boxes will have versions on them
     */
    if (!block.version) {
      let styleString = `white-space: normal;`;
      if (block?.fontColor && block?.fontColor.length > 0) {
        styleString = `color: ${block?.fontColor};`;
      }

      if (block?.fontStyle && block?.fontStyle.length > 0) {
        styleString = styleString + `font-style: ${block?.fontStyle};`;
      }

      if (block?.fontWeight && block?.fontWeight !== "normal") {
        styleString = styleString + `font-weight: ${block?.fontWeight};`;
      }
      if (!list[index]?.text?.includes('data-wrappingspan="true"')) {
        editedList[index].text = `<span data-wrappingspan="true" style="${styleString}">${block.text}</span>`;
      }
      if (block?.textDecoration && block?.textDecoration === "underline") {
        editedList[index].text = `<u style="color: ${block?.fontColor}">${editedList[index].text}</u>`;
      }
      if (/\r|\n/.exec(editedList[index].text)) {
        editedList[index].text = editedList[index].text.replace(/\r?\n/g, "<br>");
      }
      const parser = new DOMParser();
      const dom = parser.parseFromString(editedList[index].text, "text/html");
      const spanTags = dom.querySelectorAll('span[data-wrappingspan="true"] > span:not([data-wrappingspan="true"])');
      if (editedList[index].text.includes("<br>")) {
        let spanTag: Element | undefined;
        for (const spanRef of spanTags.values()) {
          // let wrappedSpan = spanRef.getAttribute('data-wrappingspan');
          for (const spanChildNode of spanRef.childNodes.values()) {
            if (spanChildNode.nodeName === "#text") {
              spanTag = spanRef;
              break;
            }
          }
          if (spanTag) {
            break;
          }
        }
        if (spanTag === undefined) {
          const spanTags = dom.querySelectorAll('span[data-wrappingspan="true"]');
          for (const spanRef of spanTags.values()) {
            // let wrappedSpan = spanRef.getAttribute('data-wrappingspan');
            for (const spanChildNode of spanRef.childNodes.values()) {
              if (spanChildNode.nodeName === "#text") {
                spanTag = spanRef;
                break;
              }
            }
            if (spanTag) {
              break;
            }
          }
        }
        if (spanTag && spanTag?.childNodes) {
          const nodeArray = Array.from(spanTag.childNodes);
          const filteredBrArray = nodeArray.filter((node) => {
            //removes on br in a sequence or if only one present
            if (node.nodeName === "BR" && node.previousSibling?.nodeName !== "BR") {
              return false;
            } else {
              return true;
            }
          });

          // eslint-disable-next-line array-callback-return
          const textToNode = filteredBrArray.map((node) => {
            if (node.nodeName === "#text") {
              if (node.textContent) {
                const pTagBreak = dom.createElement("p");
                const spanStyles = spanTag!.getAttribute("style");
                if (spanStyles) {
                  pTagBreak.setAttribute("style", spanStyles);
                }
                pTagBreak.innerText = node.textContent;
                return pTagBreak;
              }
            } else if (node.nodeName === "BR") {
              const pTagBreak = dom.createElement("p");
              pTagBreak.innerHTML = "<br>";
              return pTagBreak;
            } else {
              return node;
            }
          });
          spanTag.innerHTML = "";
          for (let i = 0; i < textToNode.length; i++) {
            if (textToNode[i]?.nodeName === "P") {
            }
            spanTag.append(textToNode[i]!);
          }
        }
        editedList[index].text = dom.body.innerHTML;
      }

      const body = dom.body;
      const ulTags = body.querySelectorAll("ul");
      if (ulTags.length > 0) {
        ulTags.forEach((node) => {
          const liList = node.querySelectorAll("li");
          const newOl = dom.createElement("ol");
          liList.forEach((node) => {
            node.setAttribute("data-list", "bullet");
            const isQlUi = dom.querySelector(".ql-ui");
            if (isQlUi === null) {
              const bulletSpan = dom.createElement("span");
              bulletSpan.setAttribute("class", "ql-ui");
              bulletSpan.setAttribute("contenteditable", "false");
              node.prepend(bulletSpan);
            }
            newOl.append(node);
          });
          node.replaceWith(newOl);
        });
      }
      const regex = /(ql-align-.)\w+/g;
      if (editedList[index]?.textAlign) {
        // an old lesson comes by and has the text align prop on it by the end of this if statement the textAlign should be set and saved to undefined
        if (_.includes(body.innerHTML, "ql-align-")) {
          body.innerHTML = _.replace(body.innerHTML, regex, `ql-align-${block?.textAlign}`);
        } else if (editedList[index]?.textAlign !== "left") {
          // left is the default so there is no need to accommodate for it
          const pTags = dom.querySelectorAll("p");
          if (pTags.length > 0) {
            //in this loop we assign the correct alignment to all of the tags in question quill will interperet them
            pTags.forEach((pElement) => {
              pElement.classList.add(`ql-align-${editedList[index].textAlign}`);
            });
          } else {
            // this is for a case that there is only one span tag with some text content and we need to align the text within
            // we do this by wrapping the container data-wrappingspan with an aligned p tag quill then interperets it correct
            const wrapSpan = dom.querySelector('span[data-wrappingspan="true"]');
            if (wrapSpan) {
              const pTag = dom.createElement("p");
              pTag.classList.add(`ql-align-${editedList[index].textAlign}`);
              pTag.append(wrapSpan);
              body.innerHTML = pTag.outerHTML;
            }
          }
        }
        editedList[index].textAlign = undefined;
      }
      editedList[index].text = body.innerHTML;
      editedList[index] = {
        ...editedList[index],
        fontWeight: undefined,
        fontStyle: "",
        textDecoration: "",
        fontColor: "",
      };
    }
  });

  return editedList;
}
// avoiding further span tags is very easy if a flag that tags the label as 'clean' or 'dirty' is used is easy
// cleaning up existing bloated labels, however, is going to be harder
// possible algorithm to do this:
// split the content string using the tag that is known to be added each time
// The last element of this array of string will be the text content without the leading extra tags, but includes all of the closing ones closing one
// The length of this array -1 is the number of extraneous span tags present in the content; this can be used to remove the extra closing tags
// get a substring that starts from the index of the first '<', and ends at the length of the string minus the number of tags multiplied by the length of each tag
// str = str.substring(str.indexOf('<', str.length - (numberOfClosingTags * closingTag.length) ) )
// just in case, sanitize the string using the DOMParser, and convert back to string
// this process is probably expensive, so it's worthwile avoiding if we can help it
// with some sort of flag

function removeExtraSpanTags(labelText: string, openingTag: string, closingTag: string): string {
  // split the text by the opening tag
  // the contents that are after all the opening tags will be at the last index of the array
  const splitArr = labelText.split(openingTag);
  // the number of extra tags is the length of the array -1 since the last element is our text block
  const spanTagPairCount = splitArr.length - 1;
  // the contents with the extra trailing closing tags
  const dirtyContents = splitArr[splitArr.length - 1];
  // if there's no span tags, return early
  if (spanTagPairCount === 0) {
    return dirtyContents;
  }
  const cleanString = new DOMParser().parseFromString(
    // using a DOMParser to make sure result is valid html and any invalid tags are ignored
    dirtyContents.substring(
      dirtyContents.indexOf("<"), // substring with our desired content starts at the first index of '<' and ends at the
      dirtyContents.length - spanTagPairCount * closingTag.length,
    ), // length of the string - the number of extra tags * the length of an individual tag
    "text/html",
  ).body.innerHTML;
  return cleanString;
}

export function convertLabelTextFormat(annotations: IAnnotation[]) {
  const copy: IAnnotation[] = _.cloneDeep(annotations);
  _.forEach(annotations, async (annotation, index) => {
    if (annotation.type === "label" && annotation.text) {
      annotation.text = updateTextAngleBrackets(annotation.text);

      let styleString = `white-space: normal;`;
      if (annotation.fontColor && annotation.fontColor.length > 0) {
        styleString = `color: ${annotation.fontColor};`;
      }

      if (annotation.fontStyle && annotation.fontStyle.length > 0) {
        styleString = styleString + `font-style: ${annotation.fontStyle};`;
      }

      if (annotation.fontWeight && annotation.fontWeight !== "normal") {
        styleString = styleString + `font-weight: ${annotation.fontWeight};`;
      }
      const cleanString = removeExtraSpanTags(annotation.text, `<span style="${styleString}">`, "</span>");
      copy[index].text = `<span style="${styleString}">${cleanString}</span>`;

      if (annotation.textDecoration && annotation.textDecoration === "underline") {
        copy[index].text = `<u style="color: ${annotation.fontColor}">${copy[index].text}</u>`;
      }

      const regex = /(ql-align-.)\w+/g;
      if (_.has(annotation, "textAlign") && annotation.textAlign !== "left" && !_.isUndefined(annotation.textAlign)) {
        if (_.includes(annotation.text, "ql-align-")) {
          copy[index].text = _.replace(annotation.text as string, regex, `ql-align-${annotation.textAlign}`);
        } else {
          copy[index].text = `<p class='ql-align-${annotation.textAlign}'>${copy[index].text}</p>`;
        }
        copy[index].textAlign = undefined;
      }

      copy[index] = {
        ...copy[index],
        fontWeight: undefined,
        fontStyle: "",
        textDecoration: "",
        fontColor: "",
      };
    }
  });
  return copy;
}

export function borderColorConversion(annotations: IAnnotation[]) {
  const newAnnotations = _.cloneDeep(annotations);
  _.forEach(annotations, (annotation, index) => {
    if (annotation.type === "label" && annotation.borderColor === "red") {
      newAnnotations[index].borderColor = "transparent";
    }
  });
  return newAnnotations;
}

export function updateTextAngleBrackets(text: string) {
  let substring: string = text;
  const str1: string[] = text.split(">");
  if (str1 && str1.length === 1) {
    str1[0] = _.replace(str1[0], str1[0], _.escape(str1[0]));
    substring = str1.join();
  } else {
    _.forEach(str1, (indString, index: number) => {
      let darn = "";
      if (_.split(indString, "<").length > 2) {
        const newArr = _.split(indString, "</");
        _.forEach(newArr, (item, index: number) => {
          newArr[index] = _.replace(item, item, _.escape(item));
        });
        darn = newArr.join("</");
        str1[index] = darn;
      }
      substring = str1.join(">");
    });
  }

  return substring;
}

export function textBoxVersionBridge(oldHtml: string) {
  // going from version n/a to version 1 of text boxes (lineHeight update v1)
  //vn/a had 5 font-sizes and they are in em units. representing ['0.5em', '0.7em', '1em', '1.5em', '2em']
  //as an inline style so it will look like this
  //from: <p> <span style="font-size: 0.5em"> Text </span> </p>
  // to: <p class="standard-blot-block ql-custom-size-block-tiny"> <span class="ql-custom-size-inline-tiny" > Text </span> </p>
  const parser = new DOMParser();
  const doc = parser.parseFromString(oldHtml, "text/html");
  const classEndMapping = new Map();
  const TINY_IN_EM = "0.5em";
  const SMALL_IN_EM = "0.7em";
  const NORMAL_IN_EM = "1em";
  const LARGE_IN_EM = "1.5em";
  const HUGE_IN_EM = "2em";
  const TINY_IN_REM = "0.5rem";
  const SMALL_IN_REM = "0.7rem";
  const NORMAL_IN_REM = "1rem";
  const LARGE_IN_REM = "1.5rem";
  const HUGE_IN_REM = "2rem";
  classEndMapping.set(TINY_IN_EM, "-tiny");
  classEndMapping.set(SMALL_IN_EM, "-small");
  classEndMapping.set(NORMAL_IN_EM, "-normal");
  classEndMapping.set(LARGE_IN_EM, "-large");
  classEndMapping.set(HUGE_IN_EM, "-huge");
  classEndMapping.set(TINY_IN_REM, "-tiny");
  classEndMapping.set(SMALL_IN_REM, "-small");
  classEndMapping.set(NORMAL_IN_REM, "-normal");
  classEndMapping.set(LARGE_IN_REM, "-large");
  classEndMapping.set(HUGE_IN_REM, "-huge");

  function updateBoxAbstraction(elementTag: string, fontSize: string) {
    const list = doc.querySelectorAll<HTMLElement>(`${elementTag}[style*="font-size: ${fontSize}"]`);
    if (list) {
      //equivalent class for 0.5em is tiny
      findElementsRemoveStyleAddClass(
        list,
        "font-size",
        [QUILL_INLINE_CLASS_ATTRIBUTOR_NAME + classEndMapping.get(fontSize)],
        [QUILL_BLOCK_CLASS_NAME, QUILL_BLOCK_CLASS_ATTRIBUTOR_NAME + classEndMapping.get(fontSize)],
      );
    }
  }

  updateBoxAbstraction("span", TINY_IN_EM);
  updateBoxAbstraction("span", SMALL_IN_EM);
  updateBoxAbstraction("span", NORMAL_IN_EM);
  updateBoxAbstraction("span", LARGE_IN_EM);
  updateBoxAbstraction("span", HUGE_IN_EM);
  updateBoxAbstraction("span", TINY_IN_REM);
  updateBoxAbstraction("span", SMALL_IN_REM);
  updateBoxAbstraction("span", NORMAL_IN_REM);
  updateBoxAbstraction("span", LARGE_IN_REM);
  updateBoxAbstraction("span", HUGE_IN_REM);
  updateBoxAbstraction("strong", TINY_IN_EM);
  updateBoxAbstraction("strong", SMALL_IN_EM);
  updateBoxAbstraction("strong", NORMAL_IN_EM);
  updateBoxAbstraction("strong", LARGE_IN_EM);
  updateBoxAbstraction("strong", HUGE_IN_EM);
  updateBoxAbstraction("strong", TINY_IN_REM);
  updateBoxAbstraction("strong", SMALL_IN_REM);
  updateBoxAbstraction("strong", NORMAL_IN_REM);
  updateBoxAbstraction("strong", LARGE_IN_REM);
  updateBoxAbstraction("strong", HUGE_IN_REM);
  updateBoxAbstraction("em", TINY_IN_EM);
  updateBoxAbstraction("em", SMALL_IN_EM);
  updateBoxAbstraction("em", NORMAL_IN_EM);
  updateBoxAbstraction("em", LARGE_IN_EM);
  updateBoxAbstraction("em", HUGE_IN_EM);
  updateBoxAbstraction("em", TINY_IN_REM);
  updateBoxAbstraction("em", SMALL_IN_REM);
  updateBoxAbstraction("em", NORMAL_IN_REM);
  updateBoxAbstraction("em", LARGE_IN_REM);
  updateBoxAbstraction("em", HUGE_IN_REM);
  updateBoxAbstraction("u", TINY_IN_EM);
  updateBoxAbstraction("u", SMALL_IN_EM);
  updateBoxAbstraction("u", NORMAL_IN_EM);
  updateBoxAbstraction("u", LARGE_IN_EM);
  updateBoxAbstraction("u", HUGE_IN_EM);
  updateBoxAbstraction("u", TINY_IN_REM);
  updateBoxAbstraction("u", SMALL_IN_REM);
  updateBoxAbstraction("u", NORMAL_IN_REM);
  updateBoxAbstraction("u", LARGE_IN_REM);
  updateBoxAbstraction("u", HUGE_IN_REM);

  return doc.body.innerHTML;
}

function findElementsRemoveStyleAddClass(
  elementList: NodeListOf<HTMLElement>,
  inlineStyleToRemove: string,
  inlineClassNameToAdd: string[],
  blockClassNamesToAdd: string[],
) {
  if (elementList && elementList.length) {
    elementList.forEach((el) => {
      el.style.removeProperty(inlineStyleToRemove);
      el.classList.add(...inlineClassNameToAdd);
      findClosestParentAndAddClass(el, "p", blockClassNamesToAdd);
    });
  }
}
function findClosestParentAndAddClass(el: HTMLElement, parentSelector: string, className: string[]) {
  const parentElement = el.closest(parentSelector);
  if (parentElement) {
    parentElement.classList.add(...className);
  } else {
    console.warn(
      "CAUTION",
      "could not find parent selector: ",
      parentSelector,
      "el tag name: ",
      el.tagName,
      "el class list: ",
      el.classList,
    );
  }
}
