import React, { useEffect, useState } from "react";
import RichTextEditor from "../../../../components/richTextEditor/richTextEditor";

type InstructionsType = {
  // displayText: string,
  handleInstructionsChange: (string: string) => void;
  displayInstructions: () => string;
  criteriaInstructions: string;
  successMatch: string[];
  selectedKey: string;
  criteriaType: string;
};

const CriteriaInstructions = (props: InstructionsType) => {
  const { displayInstructions } = props;
  const [displayColor, setDisplayColor] = useState("placeholder Text");
  //const [defaultRteValue, setDefaultRteValue] = useState("")

  useEffect(() => {
    if (
      props.criteriaInstructions.replace(/[^a-zA-Z0-9 -]*/gm, "") === "" ||
      props.criteriaInstructions.replace(/[^a-zA-Z0-9 -]*/gm, "") === displayInstructions()
    ) {
      props.handleInstructionsChange(displayInstructions());
      //setDefaultRteValue(displayInstructions())
    }
  }, []);
  useEffect(() => {
    //leaving these logs for easier debugging, often times these checks can fail by a character length of 1 and its really annoying to figure out where the break happens

    if (props.criteriaType === "sequential") {
      //sequential or "Key Press" tasks have to modify the selected key to convert the name into a more readable format EX: enterKey becomes Enter so the check needs to be different
      const generatedMatchValidator: boolean =
        props.criteriaInstructions.replace(/[^a-zA-Z0-9 -]*/gm, "").length ===
        displayInstructions().replace(/[^a-zA-Z0-9 -]*/gm, "").length -
          props.selectedKey
            .replace(/Key/gm, "")
            .replace(/([A-Z])/gm, " $1")
            .trim().length;
      //the first key press will populate fine but the next key presses will have a length difference of one I don't know why as of 12/11/2020, but checking for +1 works for now
      const generatedMatchWithDifferenceOfOneValidator: boolean =
        props.criteriaInstructions.replace(/[^a-zA-Z0-9 -]*/gm, "").length + 1 ===
        displayInstructions().replace(/[^a-zA-Z0-9 -]*/gm, "").length -
          props.selectedKey
            .replace(/Key/gm, "")
            .replace(/([A-Z])/gm, " $1")
            .trim().length;
      if ((props.selectedKey.length > 0 && generatedMatchValidator) || props.successMatch[0] === "") {
        props.handleInstructionsChange(displayInstructions());
        //setDefaultRteValue(displayInstructions())
      } else if (generatedMatchWithDifferenceOfOneValidator) {
        props.handleInstructionsChange(displayInstructions());
      }
    } else {
      if (
        (props.selectedKey.length > 0 &&
          props.criteriaInstructions.replace(/[^a-zA-Z0-9 -]*/gm, "").length ===
            displayInstructions().replace(/[^a-zA-Z0-9 -]*/gm, "").length -
              props.selectedKey
                .replace(/Key/gm, "")
                .replace(/([A-Z])/gm, " $1")
                .trim().length) ||
        props.successMatch[0] === ""
      ) {
        props.handleInstructionsChange(displayInstructions());
        //setDefaultRteValue(displayInstructions())
      }
    }
  }, [props.selectedKey]);
  useEffect(() => {
    //when the type changes but the user has not customized the instructions the text needs to change
    const scracthPadDefaultDescriptionValidator =
      props.criteriaInstructions.includes("Enter") && props.criteriaInstructions.includes("into the scratchpad");
    const keyPressDefaultDescriptionValidator =
      props.criteriaInstructions.includes("Select the") && props.criteriaInstructions.includes("key");
    if (
      (props.successMatch[0] === "" || props.successMatch.length === 0) &&
      (scracthPadDefaultDescriptionValidator || keyPressDefaultDescriptionValidator)
    ) {
      props.handleInstructionsChange(displayInstructions());
    }
  }, [props.criteriaType]);

  return (
    <React.Fragment>
      <div className="col-12 criteriaContainer instructions-container">
        <p style={{ fontWeight: "bold" }}>Task Instructions:</p>
        <RichTextEditor
          displayText={props.criteriaInstructions}
          assignNewText={props.handleInstructionsChange}
          placeholder={"Enter Instructions Here..."}
          module={[]}
          fontSize={["1rem", "1.5rem", "2rem", "2.5rem"]}
        />
      </div>
    </React.Fragment>
  );
};

export default CriteriaInstructions;
