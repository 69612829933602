import _ from "lodash";
import React from "react";
import Annotation, { AnnotationType } from "../../../Annotation/Annotation";
import EmptyImage from "../../../EmptyImage";
import { IMoveableProps } from "../../../Moveable/models/IMoveableProps";
import MoveableClass from "../../../Moveable/MoveableClass";
import { handleKeyPress } from "../../../ObjectPropertyBox/functions/PropertyBoxFunctions";
import Symbol from "../../../Symbol/Symbol";
import { ISymbolV2 } from "../../../Symbol/models/ISymbol";
import { IIGAGraphicChoice } from "../../models/IIGA";
import IGAGraphicQuizImage from "./IGAGraphicQuizImage";
// import IGAGQAddAnswer from './IGAGQAddAnswer'; //no longer used

type GraphicQuizType = {
  annotationProps: AnnotationType;
  question: string;
  choiceCount: number;
  choices: IIGAGraphicChoice[];
  containerRef: React.RefObject<HTMLDivElement>;
  moveableProps: IMoveableProps;
  addGraphicChoice: () => void;
  addGraphicQuizImage: (index: number) => void;
  markAsCorrect: (e: React.MouseEvent<HTMLDivElement>, index: number) => void;
  removeChoice: (e: React.MouseEvent<HTMLButtonElement>, index: number) => void;
  handleQuestionEntry: (string: string) => void;
  updatedManifest: any;
  pageContext: any;
  currentStep: number;
  hsIndex: number;
};

const IGAGraphicQuiz = (props: GraphicQuizType) => {
  const {
    annotationProps,
    question,
    choices,
    choiceCount,
    containerRef,
    moveableProps,
    addGraphicChoice,
    addGraphicQuizImage,
    handleQuestionEntry,
    markAsCorrect,
    removeChoice,
    hsIndex,
  } = props;
  const newChoices: IIGAGraphicChoice[] = _.cloneDeep(choices);
  const displayText = "Click Here to Select or Upload an Image.";
  const handleQuestion = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    handleQuestionEntry(e.target.value);
  };

  const displayAnswers = () => {
    if (newChoices.length === 5) {
      return (
        <React.Fragment>
          <div className="answers-5-top">
            {choices.map((choice, index) => {
              if (index <= 2) {
                return (
                  // <div key={index} className={`iga-graphic-quiz-box${choice.isCorrect ? ' selected': ''}`} >
                  <div
                    className={`iga-graphic-quiz-box${choice.isCorrect ? " selected" : ""}`}
                    key={index}
                    onClick={() => addGraphicQuizImage(index)}
                  >
                    {!_.has(choice, "image") || choice.image?.path?.length < 1 ? (
                      <div className="iga-graphic-quiz-select-container">
                        <div key={index} className="empty-image">
                          <EmptyImage imageOnly={true} displayText={displayText} />
                        </div>
                        {/* {choices.length > 2 ? <button id="icon-remove-iga-quiz-graphic" onClick={(e: React.MouseEvent<HTMLButtonElement>) => removeChoice(e, index)}><span className="icon-remove-no-background"></span></button> : <></>} */}
                      </div>
                    ) : (
                      <IGAGraphicQuizImage
                        imagePath={choice.image.path}
                        index={index}
                        isCorrect={choice.isCorrect}
                        markAsCorrect={markAsCorrect}
                        addGraphicQuizImage={addGraphicQuizImage}
                        updatedManifest={props.updatedManifest}
                        pageContext={props.pageContext}
                        currentStep={props.currentStep}
                        hsIndex={hsIndex}
                      />
                    )}
                  </div>
                  // </div>
                );
              }
            })}
          </div>
          <div className="answers-5-bottom">
            {choices.map((choice, index) => {
              if (index >= 3 && index <= 4) {
                return (
                  // <div key={index} className={`iga-graphic-quiz-box${choice.isCorrect ? ' selected': ''}`} >
                  <div
                    className={`iga-graphic-quiz-box${choice.isCorrect ? " selected" : ""}`}
                    key={index}
                    onClick={() => addGraphicQuizImage(index)}
                  >
                    {!_.has(choice, "image") || choice.image?.path?.length < 1 ? (
                      <div className="iga-graphic-quiz-select-container">
                        <div key={index} className="empty-image">
                          <EmptyImage imageOnly={true} displayText={displayText} />
                        </div>
                        {/* {choices.length > 2 ? <button id="icon-remove-iga-quiz-graphic" onClick={(e: React.MouseEvent<HTMLButtonElement>) => removeChoice(e, index)}><span className="icon-remove-no-background"></span></button> : <></>} */}
                      </div>
                    ) : (
                      <IGAGraphicQuizImage
                        imagePath={choice.image.path}
                        index={index}
                        isCorrect={choice.isCorrect}
                        markAsCorrect={markAsCorrect}
                        addGraphicQuizImage={addGraphicQuizImage}
                        updatedManifest={props.updatedManifest}
                        pageContext={props.pageContext}
                        currentStep={props.currentStep}
                        hsIndex={hsIndex}
                      />
                    )}
                  </div>
                  // </div>
                );
              }
            })}
          </div>
        </React.Fragment>
      );
    } else {
      return newChoices?.map((choice, index) => {
        return (
          <div
            className={`iga-graphic-quiz-box${choice.isCorrect ? " selected" : ""}`}
            key={index}
            onClick={() => addGraphicQuizImage(index)}
          >
            {!_.has(choice, "image") || choice.image?.path?.length < 1 ? (
              <div className="iga-graphic-quiz-select-container">
                <div key={index} className="empty-image">
                  <EmptyImage imageOnly={true} displayText={displayText} />
                </div>
                {/* {choices.length > 2 ? <button id="icon-remove-iga-quiz-graphic" onClick={(e: React.MouseEvent<HTMLButtonElement>) => removeChoice(e, index)}><span className="icon-remove-no-background"></span></button> : <></>} */}
              </div>
            ) : (
              <IGAGraphicQuizImage
                isCorrect={choice.isCorrect}
                imagePath={choice.image.path}
                index={index}
                key={index}
                addGraphicQuizImage={addGraphicQuizImage}
                markAsCorrect={markAsCorrect}
                updatedManifest={props.updatedManifest}
                pageContext={props.pageContext}
                currentStep={props.currentStep}
                hsIndex={hsIndex}
              />
            )}
          </div>
        );
      });
    }

    // if(renderedImages?.length < 3) {
    //   renderedImages.splice(2, 0, <IGAGQAddAnswer key={2} addGraphicChoice={addGraphicChoice} />)
    // }
  };

  return (
    <React.Fragment>
      <div className="iga-question-container">
        <label htmlFor="iga-question">Question:</label>
        <textarea value={question} className="iga-question" name="iga-question" onChange={(e) => handleQuestion(e)} />
      </div>
      <div>
        <span>Select the Correct Answer:</span>
      </div>
      <div className="iga-graphic-quiz-wrapper" id="iga-graphic-wrapper">
        <div ref={containerRef} className={`iga-multiImageBox these-choices-${choiceCount}`}>
          {displayAnswers()}
        </div>
        {annotationProps.annotations && annotationProps.annotations.length > 0 ? (
          <Annotation {...annotationProps} />
        ) : (
          <></>
        )}
        {annotationProps.symbols && annotationProps.symbols.length > 0 ? (
          <Symbol
            kp={annotationProps.kp}
            symbols={annotationProps.symbols as ISymbolV2[]}
            handleKeyPress={handleKeyPress}
            markSelected={annotationProps.markSelected}
          />
        ) : (
          <></>
        )}
        {moveableProps.target ? <MoveableClass {...moveableProps} /> : <></>}
      </div>
    </React.Fragment>
  );
};

export default IGAGraphicQuiz;
