import ILessonPageDto from "../../models/ILessonPageDto";
import lodash from "lodash";
import { createLogger } from "../../utils";

const log = createLogger("bugJar");
const checkIfItisAnyOfStandardPages = (pagePlayerType: string): boolean => {
  switch (pagePlayerType) {
    case "Base":
    case "Quiz":
    case "ThreeDModel":
    case "FMS":
    case "Walkaround":
    case "Cabin":
      return false;
    default:
      return true;
  }
};

const bugJar = (lsnPgs?: ILessonPageDto[]) => {
  // mrLogger('Bug Jar')
  const lessonPagesCopy: ILessonPageDto[] | undefined = [...(lsnPgs ?? [])].map((lp) => Object.assign(lp));
  if (lessonPagesCopy && lessonPagesCopy.length > 0) {
    let alarm = false;
    const firstPages = lessonPagesCopy.filter((e, i) => i <= 3);
    if (firstPages[0].name !== "Title Page") {
      alarm = true;
    } else if (firstPages[1].name !== "Introduction Page") {
      alarm = true;
    } else if (firstPages[2].name !== "Objective Page") {
      alarm = true;
    } else if (firstPages[3].name !== "Warning Page") {
      alarm = true;
    }
    /////////////////////////////////////////////////////////////////////////////////
    const content = lessonPagesCopy.filter((e, i, a) => i >= 4 && i <= a.length - 4);
    if (content.length > 0) {
      const alarm = false;
      content.forEach((element: ILessonPageDto, index: number) => {
        if (checkIfItisAnyOfStandardPages(element.pagePlayerType)) {
          log("Standard Pages have leaked into the content pages", { lsnPgs });
        }
        switch (element.name) {
          case "Score Page":
          case "Conclusion Page":
          case "Exit Page":
            log("pages in content have been renamed to last pages", { lsnPgs });
          default:
        }

        if (element.order !== index + 4) {
          log("Orders are muckked up", { lsnPgs });
        }
      });
    }

    ////////////////////////////////////////////////////////////////////////////////
    const lastPages = lessonPagesCopy.filter((e, i, a) => i > a.length - 4);

    lastPages.forEach((element: ILessonPageDto, index: number) => {
      // check if any of the last elements have the isClientSideNew set to true
      if (element.isClientSideNewPage) {
        log("The last 3 pages have client side is new page for some reason");
      }
    });
    if (lastPages[0].name !== "Score Page") {
      alarm = true;
    } else if (lastPages[1].name !== "Conclusion Page") {
      alarm = true;
    } else if (lastPages[2].name !== "Exit Page") {
      alarm = true;
    }
    const fullLessonPages = [...firstPages, ...content, ...lastPages];
    if (alarm) {
      log("ALARM SPRUNG", {
        firstPages,
        content,
        lastPages,
        fullLessonPages,
        lsnPgs,
      });
      // alert(
      //     "You are Seeing this because a trap was sprung for the bug I am trying to catch, and you have just triggered it by trying to save something other than the default to the last or first pages, as a result I will not allow it to save so we can find this bug. I ask that you remember what you just did and if possible to call Austin P.. A debugger will follow...."
      // );
    }
    return alarm;
  }
};
export default bugJar;
