import React, { ReactNode, useEffect } from "react";
import { BaseModalProps } from "../BaseModal/types";
import { BaseModal } from "../BaseModal";

const DEFAULT_CONFIRM_LABEL = "OK";
const DEFAULT_CANCEL_LABEL = "Cancel";
export interface ConfirmationModalProps extends BaseModalProps {
  message: ReactNode;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}
export const ConfirmationModal = ({
  message,
  confirmButtonLabel = DEFAULT_CONFIRM_LABEL,
  cancelButtonLabel = DEFAULT_CANCEL_LABEL,
  onConfirm,
  onCancel,
  ...modalProps
}: ConfirmationModalProps) => {
  useEffect(() => {
    const listener = (keyEvent: KeyboardEvent) => {
      if (!modalProps.open) return;

      if (keyEvent.key === "Escape") {
        onCancel?.();
      } else if (keyEvent.key === "Enter") {
        onConfirm?.();
      }
    };

    document.addEventListener("keydown", listener);

    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [modalProps.open, onCancel, onConfirm]);

  return (
    <BaseModal
      {...modalProps}
      onClose={onCancel}
      slots={{
        bottomActions: (
          <>
            <button className="cpat-button cpat-button--label cpat-button--reverse" onClick={onCancel}>
              {cancelButtonLabel}
            </button>
            <button className="cpat-button cpat-button--label" onClick={onConfirm}>
              {confirmButtonLabel}
            </button>
          </>
        ),
      }}
    >
      <p>{message}</p>
    </BaseModal>
  );
};
