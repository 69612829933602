import React from "react";
import { Interweave } from "interweave";
import { BaseWYSIWYGType } from "../BaseWYSIWYG";
import blobHelper from "../../../../blobUrlFormatHelper";

const DGSR = (props: BaseWYSIWYGType) => {
  const { pageManifest } = props;
  const imageName1 = blobHelper(pageManifest.pageImage[0].imagePath);
  const imageName2 = blobHelper(pageManifest.pageImage[1].imagePath);

  return (
    <div className="contentBox">
      <div className="split-graphic-row">
        <div className="split-graphic-container-img wysiwyg">
          <div className="img-aspect-container">
            <div className="img-aspect-container-inside">
              <div className="img-aspect-centering">
                <img src={imageName1} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="wysiwyg split-graphic-container">
          <div className="block text1">
            <Interweave content={pageManifest.textBlock[0].text} />
          </div>
        </div>
      </div>
      <div className="split-graphic-row">
        <div className="wysiwyg split-graphic-container">
          <div className="block text1">
            <Interweave content={pageManifest.textBlock[1].text} />
          </div>
        </div>
        <div className="wysiwyg split-graphic-container-img">
          <div className="img-aspect-container">
            <div className="img-aspect-container-inside">
              <div className="img-aspect-centering">
                <img src={imageName2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DGSR;
