const createFormDataInMbChunks = (requestFormData: FormData, file: File): void => {
  const bytesInMb = 1000000;
  let appendIndexer = 0;
  let currentLocationIndex = 0;

  const fileName = (): string => {
    if (file.name.indexOf(" ") >= 0) {
      return file.name.split(" ").join("_").toLowerCase();
    } else {
      return file.name.toLowerCase();
    }
  };
  for (; currentLocationIndex + bytesInMb <= file.size; currentLocationIndex += bytesInMb, appendIndexer++) {
    requestFormData.append(
      appendIndexer.toString(),
      file.slice(currentLocationIndex, currentLocationIndex + bytesInMb, file.type),
      fileName(),
    );
  }

  if (currentLocationIndex < file.size) {
    requestFormData.append(
      appendIndexer.toString(),
      file.slice(currentLocationIndex, file.size, file.type),
      fileName(),
    );
  }
};

export default createFormDataInMbChunks;
