import React from "react";
import { PanelProperty } from "../types";
import classes from "../../../utils/HTML/classes";
import { Toggle } from "rsuite";

export type CheckboxPropertyProps = PanelProperty<unknown> & {
  value: boolean;
  onChange: (value: boolean) => void;
};

export const CheckboxProperty = ({ value, onChange, readOnly, disabled, className }: CheckboxPropertyProps) => {
  const handleInputChange = (checked: boolean) => {
    onChange?.(checked);
  };

  return (
    <div className={classes("property-field-checkbox", className)}>
      <Toggle disabled={disabled} readOnly={readOnly} defaultChecked={value ?? false} onChange={handleInputChange} />
    </div>
  );
};
