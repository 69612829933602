import React from "react";
import { AbstractTooltip as Tooltip } from "../../ToolTip/ToolTip";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../../contexts/ObjectsProvider";
import { ReactComponent as CopyIcon } from "../../../assets/Copy-White.svg";

const CopyButton = () => {
  const { selectedObjects } = useObjectsState();
  const objectsDispatch = useObjectsDispatch();
  return (
    <button
      className="wysiwyg-toolbar-btn"
      id="copy"
      onClick={() => {
        objectsDispatch({
          type: ObjectActionsType.COPY_OBJECTS,
        });
      }}
      data-tooltip-id="Copy"
    >
      <Tooltip id="Copy" />
      <CopyIcon />
      {/* <span className="icon-copy"></span> */}
    </button>
  );
};

export default CopyButton;
