///////////////////////
///////////////////////
//////////////////////
////////DEPRECATED////////
///////////////////////
///////////////////////
//////////////////////
import _ from "lodash";
import { BoundType } from "../../react-moveable";
import { CustomPageElement } from "../../../pageTypes/BasicPage_Player/components/BaseFreeForm/classes/CustomPageElement";
import { ISymbolV2 } from "../models/ISymbol";
import ISymbol from "../SymbolsThumbnail/models/ISymbol";

export class ClassSymbol extends CustomPageElement {
  protected bounds: BoundType;
  protected index: number;
  public symbols: ISymbolV2[];
  fullList: any;

  constructor(index: number, bounds: BoundType, groupList: ISymbolV2[], name: string, fullList: any, objectId: number) {
    super(groupList, `symbol${objectId}`);
    this.newObject = {
      type: "symbol",
      // name: name,
      ...this.newObject,
      objectId: `symbol${objectId}`,
      isDisplayed: true,
    };
    this.symbols = groupList;

    this.bounds = bounds;
    this.index = index;
    this.fullList = fullList;
    this.newObject.name = name;
  }

  add() {
    const position = this.setInitialPosition(this.symbols, this.index, this.bounds);
    this.newObject.top = position.top;
    this.newObject.left = position.left;
    if (!_.isEmpty(this.fullList)) {
      this.newObject.zIndex = this.findMaxZIndex(this.fullList);
      // this.newObject.name = `${this.newObject.name}-${this.index}`
    }

    if (!this.newObject.zIndex) {
      this.newObject.zIndex = 12;
    }
    return this.newObject;
  }

  public validateSymbolWithoutNames() {
    const list: ISymbolV2[] = _.cloneDeep(this.symbols);
    _.forEach(list, (item, index: number) => {
      if (!item.name || item.name === "") {
        list[index].name = `symbol-${index}`;
      }
    });
    this.symbols = list;
    return this.symbols;
  }

  public validateSymbolWithDuplicateNames() {
    const list: ISymbolV2[] = _.cloneDeep(this.symbols);
    _.forEach(list, (item, index: number) => {
      _.forEach(list, (el, jindex: number) => {
        if (index !== jindex && item.name === el.name) {
          list[jindex].name = `${el.name}-${jindex}`;
        }
      });
    });
    this.symbols = list;
    return list;
  }

  public convertSymbolIfHasStyleAttribute() {
    _.forEach(this.groupList as ISymbolV2[], (item, index: number) => {
      if (_.has(item, "style")) {
        this.groupList[index] = {
          ...(item as unknown as ISymbol).style,
          type: "symbol",
        } as ISymbolV2;
        this.groupList[index].name = item.name;
      }
      if (!this.groupList[index].hasOwnProperty("isDisplayed"))
        this.groupList[index].isDisplayed = this.newObject.isDisplayed;
      if (!this.groupList[index].hasOwnProperty("objectId")) this.groupList[index].objectId = this.newObject.objectId;
    });
    return this.groupList as ISymbolV2[];
  }
}
