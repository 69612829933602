import { manufacturerFleetVariantDtos } from "../../../../models/IAsset";
import { MetadataEditorActionType } from "./actionTypes";
import { errorState, MetadataEditorState, Tag } from "./interfaces";
import { MetadataEditorAction } from "./actions";

export const emptyDropdown: manufacturerFleetVariantDtos = {
  manufacturerId: -1,
  fleetId: -1,
  variantId: -1,
  manufacturerName: "",
  fleetName: "",
  variantName: "",
};

const noError: errorState = {
  hasError: false,
  errorMessage: "",
  errorType: "NO_ERROR",
};
const emptyDescriptionError: errorState = {
  hasError: true,
  errorMessage: "The asset's description cannot be empty",
  errorType: "EMPTY_DESCRIPTION",
};

const emptyTagError: errorState = {
  hasError: true,
  errorMessage: "Tags cannot have empty names or descriptions",
  errorType: "EMPTY_TAG",
};

export const initialState: MetadataEditorState = {
  description: "",
  readonly: true,
  tags: [],
  validSubmission: false,
  manFleetVariantDto: emptyDropdown,
  dataHasChanged: false,
  errorState: emptyDescriptionError,
  assetIsLibrary: false,
  assetIsArchived: false,
};

function validateTags(tags: Tag[], description: string): errorState {
  if (tags.some((x) => x.tagKey.trim().length === 0 || x.tagValue.trim().length === 0)) return emptyTagError;
  if (description.trim().length === 0) return emptyDescriptionError;
  else return noError;
}
export const reducer = (state: MetadataEditorState, action: MetadataEditorAction): MetadataEditorState => {
  switch (action.type) {
    case MetadataEditorActionType.SET_INITIAL_DESCRIPTION:
      return {
        ...state,
        description: action.payload,
      };
    case MetadataEditorActionType.SET_INITIAL_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case MetadataEditorActionType.CHANGE_DESCRIPTION:
      return {
        ...state,
        description: action.payload,
        errorState: validateTags(state.tags, action.payload),
        dataHasChanged: true,
      };
    case MetadataEditorActionType.CHANGE_MODE:
      return {
        ...state,
        dataHasChanged: false,
        readonly: action.payload,
      };
    case MetadataEditorActionType.SET_INITIAL_TAGS:
      return {
        ...state,
        tags: action.payload,
      };
    case MetadataEditorActionType.REMOVE_TAG_BY_ID: {
      const newTags = state.tags.filter((x) => x.id !== action.payload);
      return {
        ...state,
        tags: newTags,
        errorState: validateTags(newTags, state.description),
        dataHasChanged: true,
      };
    }

    case MetadataEditorActionType.ADD_TAG: {
      const maxId = state.tags.length > 0 ? 1 + Math.max(...state.tags.map((x) => x.id)) : 0;
      const newTag = { ...action.payload, id: maxId, isPreExistingTag: false };
      return {
        ...state,
        tags: [...state.tags, newTag],
        errorState: emptyTagError,
        dataHasChanged: true,
      };
    }

    case MetadataEditorActionType.EDIT_TAG: {
      const { id, content, field } = action.payload;
      const newTags = state.tags.map((x) => (x.id !== id ? x : { ...x, [field]: content }));

      return {
        ...state,
        tags: newTags,
        errorState: validateTags(newTags, state.description),
        dataHasChanged: true,
      };
    }
    case MetadataEditorActionType.SET_MAN_FLEET_VAR_DTO:
      return {
        ...state,
        manFleetVariantDto: action.payload,
      };

    case MetadataEditorActionType.CLEAR_METADATA:
      return {
        ...state,
        tags: [],
        manFleetVariantDto: { ...emptyDropdown },
        description: "",
        assetIsLibrary: false,
        dataHasChanged: false,
        assetIsArchived: false,
      };
    case MetadataEditorActionType.SET_DATA_CHANGED:
      return {
        ...state,
        dataHasChanged: action.payload,
      };
    case MetadataEditorActionType.RESET_DATA_CHANGED:
      return {
        ...state,
        dataHasChanged: false,
      };
    case MetadataEditorActionType.SET_ERROR_STATE:
      return {
        ...state,
        errorState: action.payload,
      };
    case MetadataEditorActionType.SET_ASSET_IS_LIBRARY:
      return {
        ...state,
        assetIsLibrary: action.payload,
        dataHasChanged: true,
      };
    case MetadataEditorActionType.SET_ASSET_IS_ARCHIVED:
      return {
        ...state,
        dataHasChanged: true,
        assetIsArchived: action.payload,
        // assetIsLibrary: action.payload ? false : state.assetIsLibrary, // turn isLibrary flag off is asset is set as archived
      };
    default:
      throw new Error("Case not implemented");
  }
};
