import React, { ChangeEvent } from "react";
import { IPBRefs, IPropertyBox } from "../models/IObjectPropertyBox";
import { PropertyBoxType } from "../ObjectPropertyBox";

type RotationInputType = {
  moveRef: any;
  pbRefs: IPBRefs;
  properties: IPropertyBox;
  pbProps: PropertyBoxType;
  handleRotationChange: (value: string, props: PropertyBoxType, moveRef: any) => void;
  disabled?: boolean;
  elementType: string;
};

const RotationInput = ({
  moveRef,
  pbRefs,
  pbProps,
  properties,
  handleRotationChange,
  disabled,
  elementType,
}: RotationInputType) => {
  return (
    <section
      className={
        elementType === "pageImage" || elementType === "video" || elementType === "pageScormObject"
          ? "property-box-rotation-section"
          : "property-box-rotation-section-non-image"
      }
    >
      <label htmlFor="degrees-property">
        Rotation (Degrees):
        <input
          ref={pbRefs.opbDegreesRef}
          disabled={Boolean(disabled)}
          type="number"
          name="degrees-property"
          className="number-property"
          value={properties?.rotation?.toFixed(2) || "0.00"}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleRotationChange(e.currentTarget.value, pbProps, moveRef)}
        />
      </label>
    </section>
  );
};

export default RotationInput;
