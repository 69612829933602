import React from "react";
import { useLessonSettingsState, useLessonSettingsDispatch } from "../LessonSettingsContext/LessonSettingsProvider";
import * as LessonDataTypes from "../../../contexts/LessonDataProvider";
import { ReactComponent as Check } from "../../../assets/icons/Settings/check-box.svg";
import { ReactComponent as CheckChecked } from "../../../assets/icons/Settings/check-box-checked.svg";
import { ReactComponent as RemoveIcon } from "../../../assets/icons/Common/remove-tag-icon.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/AssetManager/search-bar-icon.svg";
import { ReactComponent as OpenArrow } from "../../../assets/icons/Settings/drop-down-icon.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/Settings/add-new-tag.svg";
import { IAtaNumberDto } from "../../../models/IGetATAData";
// import LessonSettings from '../LessonSettings'
interface Props {
  lessonData: LessonDataTypes.LessonData;
  isCpatUser: boolean;
}
const ExtraOptionsSettings = (props: Props) => {
  const settingsState = useLessonSettingsState();
  const settingsDispatch = useLessonSettingsDispatch();

  const [ataToggle, setAtaToggle] = React.useState(false);
  const [tagsToggle, setTagsToggle] = React.useState(false);
  const [ipToggle, setIpToggle] = React.useState(false);
  const [tailoredForToggle, setTailoredForToggle] = React.useState(false);
  const [ipFilter, setIpFilter] = React.useState("");
  const [ipOptionsVisible, setIpOptionsVisible] = React.useState(false);
  const [tailoredForFilter, setTailoredForFilter] = React.useState("");
  const handleAddAta = (ata: IAtaNumberDto) => () => {
    const currentAtas: IAtaNumberDto[] = [...settingsState.lessonMetaDataLocalCopy.ataNumberDtos];
    let deleteIndex = -1;
    currentAtas.forEach((a, index) => {
      if (a.ataNumberId === ata.ataNumberId) {
        deleteIndex = index;
      }
    });
    if (deleteIndex === -1) {
      currentAtas.push(ata);
    } else {
      currentAtas.splice(deleteIndex, 1);
    }

    settingsDispatch({
      type: "UPDATE_LESSON_META_DATA_LOCAL_COPY",
      payload: { ataNumberDtos: currentAtas },
    });
  };

  const selectedAta = (ata: IAtaNumberDto) => {
    const currentAtas: IAtaNumberDto[] = [...settingsState.lessonMetaDataLocalCopy.ataNumberDtos];
    const selected = currentAtas.find((a) => {
      return ata.ataNumberId === a.ataNumberId;
    });
    return !!selected;
  };

  const handleTagsChange = (e: React.ChangeEvent<HTMLInputElement>, mode: string, index: number) => {
    const currentTags = [...settingsState.lessonMetaDataLocalCopy.lessonTags];

    switch (mode) {
      case "name":
        currentTags[index].tagKey = e.target.value;
        settingsDispatch({
          type: "UPDATE_LESSON_META_DATA_LOCAL_COPY",
          payload: { lessonTags: currentTags },
        });

        break;
      case "value":
        currentTags[index].tagValue = e.target.value;
        settingsDispatch({
          type: "UPDATE_LESSON_META_DATA_LOCAL_COPY",
          payload: { lessonTags: currentTags },
        });

        break;
      default:
        return null;
    }
  };

  const handleIpFiltering = () => {
    return props.lessonData.lmss
      .filter((lms: any) => {
        return lms.name.includes(ipFilter) && lms.isDefaultCopyrightOwner === false;
      })
      .map((lms: any) => {
        return (
          <div key={"qazwsx" + lms.name}>
            <p
              title={lms.name}
              onClick={(e) => {
                setIpFilter(lms.name);
                setIpOptionsVisible(false);
              }}
            >
              {lms.name}
            </p>
          </div>
        );
      });
  };

  const handleAddIp = () => {
    const lms = props.lessonData.lmss.find((lms: any) => {
      return lms.name === ipFilter;
    });
    // currentIpOwners.forEach((lms, index) => {

    // })

    if (lms) {
      const currentIpOwners = [...settingsState.lessonMetaDataLocalCopy.additionalCopyrightHolders];
      const isAlreadyInList = currentIpOwners.find((llms) => {
        return llms.lmsKey === lms.lmsKey;
      });

      if (isAlreadyInList) {
        //do nothing
      } else {
        setIpOptionsVisible(false);
        setIpFilter("");
        settingsDispatch({
          type: "UPDATE_LESSON_META_DATA_LOCAL_COPY",
          payload: { additionalCopyrightHolders: [...currentIpOwners, lms] },
        });
      }
    }
  };

  const canAddIp = () => {
    const lms = props.lessonData.lmss.find((lms: any) => {
      return lms.name === ipFilter;
    });
    if (lms) {
      const currentIpOwners = [...props.lessonData.lmss];
      const isAlreadyInList = currentIpOwners.find((llms) => {
        return llms.lmsKey === lms.lmsKey;
      });
      return !!isAlreadyInList;
    } else {
      return false;
    }
  };

  const handleRemoveIp = (lms: any) => () => {
    const currentIpOwners = [...settingsState.lessonMetaDataLocalCopy.additionalCopyrightHolders];
    let deleteIndex = -1;
    currentIpOwners.forEach((llms, index) => {
      if (llms.lmsKey === lms.lmsKey) {
        deleteIndex = index;
      }
    });
    currentIpOwners.splice(deleteIndex, 1);

    settingsDispatch({
      type: "UPDATE_LESSON_META_DATA_LOCAL_COPY",
      payload: { additionalCopyrightHolders: [...currentIpOwners] },
    });
  };

  const handleTailoredForClick = (lms: any) => () => {
    const currentTailoredFor = [...settingsState.lessonMetaDataLocalCopy.customTailoredForLmss];
    let deleteIndex = -1;
    currentTailoredFor.forEach((llms, index) => {
      if (lms.lmsKey === llms.lmsKey) {
        deleteIndex = index;
      }
    });
    if (deleteIndex !== -1) {
      //delete
      currentTailoredFor.splice(deleteIndex, 1);
    } else {
      currentTailoredFor.push({ name: lms.name, lmsKey: lms.lmsKey });
    }
    settingsDispatch({
      type: "UPDATE_LESSON_META_DATA_LOCAL_COPY",
      payload: { customTailoredForLmss: [...currentTailoredFor] },
    });
  };

  const selectedTailoredForLms = (lmsKey: number) => {
    /**
     * List of currently chosen, in lesson tailored for Lms Keys used for checking if the lms in UI is in that list
     */
    const currentTailoredFor: number[] = settingsState.lessonMetaDataLocalCopy.customTailoredForLmss.map((llms) => {
      return llms.lmsKey;
    });

    return currentTailoredFor.includes(lmsKey);
  };

  const handleCpatIpClick = () => {
    if (props.isCpatUser) {
      settingsDispatch({
        type: "UPDATE_LESSON_META_DATA_LOCAL_COPY",
        payload: {
          isCpatCopyHolder: !settingsState.lessonMetaDataLocalCopy.isCpatCopyHolder,
        },
      });
    }
  };
  return (
    <div className="extraOptions-settings">
      <div className="settings-ata-container">
        <div className="settings-ata-toggle">
          <div className="settings-ata-title">ATA Number</div>
          <div
            className="settings-ata-open"
            onClick={() => {
              setAtaToggle(!ataToggle);
            }}
          >
            {ataToggle ? <p>Close</p> : <p>Open</p>}
            <OpenArrow style={ataToggle ? { transform: "rotate(180deg)" } : {}} />
          </div>
        </div>
        {ataToggle && (
          <div className="settings-ata-content">
            <div className="settings-ata-grid">
              {props.lessonData.ataNumbers.map((ata) => {
                return (
                  <div key={"lsac" + ata.ataNumberId} className="ata-cell" onClick={handleAddAta(ata)}>
                    <div className="ata-check-box">{selectedAta(ata) ? <CheckChecked /> : <Check />}</div>
                    <div className="ata-text">
                      <p title={ata.description}>{`${ata.ataIdentifier} - ${ata.description}`}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>

      <div className="settings-tags-container">
        <div className="settings-tags-toggle">
          <div className="settings-tags-title">Tags</div>
          <div
            className="settings-tags-open"
            onClick={() => {
              setTagsToggle(!tagsToggle);
            }}
          >
            {tagsToggle ? <p>Close</p> : <p>Open</p>}
            <OpenArrow style={tagsToggle ? { transform: "rotate(180deg)" } : {}} />
          </div>
        </div>
        {tagsToggle && (
          <div className="settings-tags-content">
            {settingsState.lessonMetaDataLocalCopy.lessonTags.map((lt, index) => {
              return (
                <div key={"lstm" + index} className="tags-map">
                  <div className="tags-name-container">
                    <p>Name: </p>
                    <input
                      autoComplete="off"
                      onChange={(e) => {
                        handleTagsChange(e, "name", index);
                      }}
                      value={settingsState.lessonMetaDataLocalCopy.lessonTags[index].tagKey}
                      type="text"
                      placeholder="Enter Tag Name"
                    />
                  </div>
                  <div className="tags-value-container">
                    <p>Value: </p>
                    <input
                      onChange={(e) => {
                        handleTagsChange(e, "value", index);
                      }}
                      value={settingsState.lessonMetaDataLocalCopy.lessonTags[index].tagValue}
                      type="text"
                      placeholder="Enter Tag Value"
                    />
                  </div>
                  <div
                    className="tag-remove"
                    onClick={() => {
                      const currentTags = [...settingsState.lessonMetaDataLocalCopy.lessonTags];
                      currentTags.splice(index, 1);

                      settingsDispatch({
                        type: "UPDATE_LESSON_META_DATA_LOCAL_COPY",
                        payload: { lessonTags: currentTags },
                      });
                    }}
                  >
                    <RemoveIcon />
                  </div>
                </div>
              );
            })}

            <div
              className="tags-add"
              onClick={() => {
                settingsDispatch({
                  type: "UPDATE_LESSON_META_DATA_LOCAL_COPY",
                  payload: {
                    lessonTags: [...settingsState.lessonMetaDataLocalCopy.lessonTags, { tagKey: "", tagValue: "" }],
                  },
                });
              }}
            >
              <PlusIcon />
            </div>
          </div>
        )}
      </div>

      <div className="settings-ip-owner-container">
        <div className="settings-ip-owner-toggle">
          <div className="settings-ip-owner-title">Intellectual Property Owner</div>
          <div
            className="settings-ip-owner-open"
            onClick={() => {
              setIpToggle(!ipToggle);
            }}
          >
            {ipToggle ? <p>Close</p> : <p>Open</p>}
            <OpenArrow style={ipToggle ? { transform: "rotate(180deg)" } : {}} />
          </div>
        </div>
        {ipToggle && (
          <div className="settings-ip-owner-content">
            <div>
              {settingsState.lessonMetaDataLocalCopy.isCpatCopyHolder ? (
                <CheckChecked onClick={handleCpatIpClick} className={props.isCpatUser ? "isCPaTEnabled" : ""} />
              ) : (
                <Check onClick={handleCpatIpClick} />
              )}
              <p>CPaT</p>
            </div>
            <div className="ip-cpat-checkbox-container"></div>
            <div className="ip-adding-container">
              <div className="ip-input-select-container">
                <input
                  type="text"
                  placeholder="Enter Organization Here"
                  onFocus={() => {
                    setIpOptionsVisible(true);
                  }}
                  value={ipFilter}
                  onChange={(e) => {
                    setIpFilter(e.target.value);
                  }}
                />
                <div className="ip-select-options" style={ipOptionsVisible ? {} : { display: "none" }}>
                  {handleIpFiltering().length === 0 ? (
                    <div>
                      <p>No Results Found</p>
                    </div>
                  ) : (
                    handleIpFiltering()
                  )}
                </div>
              </div>
              <button onClick={handleAddIp} disabled={!canAddIp()}>
                Add
              </button>
            </div>

            <div
              onClick={() => {
                setIpOptionsVisible(false);
              }}
            >
              {settingsState.lessonMetaDataLocalCopy.additionalCopyrightHolders
                .filter((lms) => !lms.isDefaultCopyrightOwner)
                .map((lms, index) => {
                  return (
                    <div key={"lsip" + lms.lmsKey} className="ip-owner-box">
                      <p>{`${index + 1}. ${lms.name}`}</p>
                      <RemoveIcon onClick={handleRemoveIp(lms)} />
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>

      {props.isCpatUser && (
        <div className="settings-tailoredFor-container">
          <div className="settings-tailoredFor-toggle">
            <div className="settings-tailoredFor-title">Tailored For</div>
            <div
              className="settings-tailoredFor-open"
              onClick={() => {
                setTailoredForToggle(!tailoredForToggle);
              }}
            >
              {tailoredForToggle ? <p>Close</p> : <p>Open</p>}
              <OpenArrow style={tailoredForToggle ? { transform: "rotate(180deg)" } : {}} />
            </div>
          </div>
          {tailoredForToggle && (
            <div className="settings-tailoredFor-content">
              <div className="tailoredFor-header">
                <div className="settings-whiteTail">
                  {settingsState.lessonMetaDataLocalCopy.isLessonWhiteTail ? (
                    <CheckChecked
                      onClick={() => {
                        settingsDispatch({
                          type: "UPDATE_LESSON_META_DATA_LOCAL_COPY",
                          payload: { isLessonWhiteTail: false },
                        });
                      }}
                    />
                  ) : (
                    <Check
                      onClick={() => {
                        settingsDispatch({
                          type: "UPDATE_LESSON_META_DATA_LOCAL_COPY",
                          payload: { isLessonWhiteTail: true },
                        });
                      }}
                    />
                  )}
                  <p>White Tail</p>
                </div>
                <div className="tailoredFor-search">
                  <input
                    type="text"
                    onChange={(e) => {
                      setTailoredForFilter(e.target.value);
                    }}
                  />
                  <div>
                    <SearchIcon />
                  </div>
                </div>
              </div>
              <div className="tailoredFor-options">
                {/**Map */}
                {props.lessonData.lmss
                  .filter((lms: any) => lms.name.includes(tailoredForFilter))
                  .map((lms: any, index: number) => {
                    return (
                      <div key={"lstfk" + index} className="tailoredFor-lms-cell" onClick={handleTailoredForClick(lms)}>
                        {selectedTailoredForLms(lms.lmsKey) ? <CheckChecked /> : <Check />}
                        <p title={lms.name}>{lms.name}</p>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ExtraOptionsSettings;
