import { useState } from "react";
import { nanoid } from "../../../lib/nanoId";
import { useLessonSettingsDispatch, useLessonSettingsState } from "../LessonSettingsContext/LessonSettingsProvider";

export const Objectives = () => {
  const [objectiveText, setObjectiveText] = useState("");
  const settingsState = useLessonSettingsState();
  const settingsDispatch = useLessonSettingsDispatch();

  const handleAddObjective = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const name = objectiveText.trim();
    if (!name) return;

    const objectives = [...(settingsState.lessonMetaDataLocalCopy.objectives ?? []), { name, objectiveId: nanoid() }];

    settingsDispatch({
      type: "UPDATE_LESSON_META_DATA_LOCAL_COPY",
      payload: { objectives: objectives },
    });

    setObjectiveText("");
  };

  const handleRemoveObjective = (id: string | number | null | undefined) => {
    if (typeof id === undefined || typeof id === null) return;
    const objectives = settingsState.lessonMetaDataLocalCopy.objectives.filter((o) => o.objectiveId !== id);
    const objectivesToDelete = settingsState.lessonMetaDataLocalCopy.ObjectivesToDelete ?? [];
    const newObjectivesToDelete = [...objectivesToDelete];

    if (typeof id === "number") {
      newObjectivesToDelete.push(id);
    }

    settingsDispatch({
      type: "UPDATE_LESSON_META_DATA_LOCAL_COPY",
      payload: { objectives: objectives, ObjectivesToDelete: newObjectivesToDelete },
    });
  };

  return (
    <div className="lessonSettings-objectives">
      <h4>Add New Lesson Objectives</h4>
      <form onSubmit={handleAddObjective}>
        <label htmlFor="objective-name">Objective</label>
        <input
          id="objective-name"
          required
          type="text"
          value={objectiveText}
          onChange={(e) => setObjectiveText(e.target.value)}
          placeholder="Enter Keyword"
        />
        <button type="submit">
          <svg
            width="26px"
            height="26px"
            viewBox="0 0 26 26"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <title>Add</title>
            <g id="New-Settings-Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Group-20">
                <rect className="svg-icon-bg" x="0" y="0" width="25.7616815" height="25.7616815" rx="2.36889025"></rect>
                <g id="Group-5" transform="translate(4.441669, 5.330003)" fill="#FFFFFF">
                  <rect
                    id="Rectangle"
                    x="6.30041123"
                    y="0"
                    width="4.20027415"
                    height="14.7209608"
                    rx="1.18444513"
                  ></rect>
                  <path
                    d="M7.32023022,-0.515033616 L9.48086639,-0.515033616 C10.1350174,-0.515033616 10.6653115,0.0152605291 10.6653115,0.669411509 L10.6653115,14.0515493 C10.6653115,14.7057003 10.1350174,15.2359945 9.48086639,15.2359945 L7.32023022,15.2359945 C6.66607925,15.2359945 6.1357851,14.7057003 6.1357851,14.0515493 L6.1357851,0.669411509 C6.1357851,0.0152605291 6.66607925,-0.515033616 7.32023022,-0.515033616 Z"
                    id="Rectangle-Copy"
                    transform="translate(8.400548, 7.360480) rotate(-90.000000) translate(-8.400548, -7.360480) "
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </button>
      </form>
      <h4>Added Lesson Objectives</h4>
      {!!settingsState.lessonMetaDataLocalCopy.objectives && (
        <ul>
          {settingsState.lessonMetaDataLocalCopy.objectives.map((obj, i) => {
            return (
              <li key={i}>
                <span>{obj.name}</span>
                <button
                  onClick={() => {
                    handleRemoveObjective(obj.objectiveId);
                  }}
                >
                  <svg
                    width="17px"
                    height="17px"
                    viewBox="0 0 17 17"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <title>Remove</title>
                    <g id="New-Settings-references" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g id="Tags-when-uploading-1" transform="translate(-5297.000000, -505.000000)" stroke="#FFFFFF">
                        <g id="Group-28" transform="translate(5297.000000, 505.000000)">
                          <circle className="svg-icon-bg" cx="8.5" cy="8.5" r="8"></circle>
                          <g
                            id="Group-13-Copy-10"
                            transform="translate(5.000000, 5.000000)"
                            strokeLinecap="square"
                            strokeWidth="2"
                          >
                            <line x1="0.291666667" y1="0.291666667" x2="6.70833333" y2="6.70833333" id="Line-4"></line>
                            <line
                              x1="0.291666667"
                              y1="0.291666667"
                              x2="6.70833333"
                              y2="6.70833333"
                              id="Line-4-Copy"
                              transform="translate(3.500000, 3.500000) scale(-1, 1) translate(-3.500000, -3.500000) "
                            ></line>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
