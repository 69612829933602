import "./Symbols.css";
import React from "react";
import { getSymbol } from "./helpers/getSymbol";
import ISymbol from "./models/ISymbol";

type SymbolsType = {
  imgRect: DOMRect;
  largeCDU: boolean;
  symbols: ISymbol[];
};

const Symbols = (props: SymbolsType) => {
  const { symbols } = props;

  const display = () => {
    return symbols.map((symbol: ISymbol, index: number) => {
      return getSymbol(index, symbol);
    });
  };

  return <React.Fragment>{display()}</React.Fragment>;
};

export default Symbols;
