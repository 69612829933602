import React from "react";
import { SymbolComponentType } from "../helpers/getSymbol";

const ExpandedCompass = (props: SymbolComponentType) => {
  const { index, attributes, transformStr } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52.94 18.04"
      key={index}
      id={`symbol-${index}`}
      tabIndex={0}
      className="symbol target"
      name="symbol"
      preserveAspectRatio="xMidYMid meet"
      style={{
        top: `${attributes.top}%`,
        left: `${attributes.left}%`,
        width: `${attributes.width}%`,
        height: `${attributes.height}%`,
        zIndex: attributes.zIndex,
        // transform:`rotate(${attributes?.transform?.rotate}deg) scaleX(${attributes?.transform?.scaleX}) scaleY(${attributes?.transform?.scaleY}) matrix3d(${attributes?.transform?.matrix3d})`
        transform: transformStr,
      }}
    >
      <path
        // className="expanded_compass_1"
        stroke={`${attributes.borderColor || "white"}`}
        fill={"transparent"}
        strokeWidth={`${attributes.lineWidth || 0.1}`}
        d="M 0.2,7.65 3.4,6.28 10.71,3.62 18.57,1.87 25.72,0.92 33.7,0.5 41.56,1 l 4.2,0.46 7.06,1.7"
      />
      <path
        // className="expanded_compass_2"
        stroke={`${attributes.borderColor || "white"}`}
        fill={"transparent"}
        strokeWidth={`${attributes.lineWidth || 0.1}`}
        d="m 48.209999,6.9000001 1,-4.6100001"
      />
      <path
        // className="expanded_compass_2"
        stroke={`${attributes.borderColor || "white"}`}
        fill={"transparent"}
        strokeWidth={`${attributes.lineWidth || 0.1}`}
        d="M 41.139999,3.45 41.400002,1.24"
      />
      <path
        // className="expanded_compass_2"
        stroke={`${attributes.borderColor || "white"}`}
        fill={"transparent"}
        strokeWidth={`${attributes.lineWidth || 0.1}`}
        d="M 33.610001,0.66000003 33.59,5.4200001"
      />
      <path
        // className="expanded_compass_2"
        stroke={`${attributes.borderColor || "white"}`}
        fill={"transparent"}
        strokeWidth={`${attributes.lineWidth || 0.1}`}
        d="M 25.719999,0.92000002 26.02,3.3900001"
      />
      <path
        // className="expanded_compass_2"
        stroke={`${attributes.borderColor || "white"}`}
        fill={"transparent"}
        strokeWidth={`${attributes.lineWidth || 0.1}`}
        d="m 18.27,2.05 1.05,4.7300002"
      />
      <path
        // className="expanded_compass_2"
        stroke={`${attributes.borderColor || "white"}`}
        fill={"transparent"}
        strokeWidth={`${attributes.lineWidth || 0.1}`}
        d="m 10.58,3.6500001 0.9,2.6999998"
      />
      <path
        // className="expanded_compass_2"
        stroke={`${attributes.borderColor || "white"}`}
        fill={"transparent"}
        strokeWidth={`${attributes.lineWidth || 0.1}`}
        d="M 3.25,6.4000001 5.1900001,11.01"
      />
      <g className="expanded_compass_3" transform="translate(44.01 12.02) rotate(10.14)">
        <path
          stroke={`${attributes.borderColor || "white"}`}
          fill={`${attributes?.borderColor || "white"}`}
          strokeWidth={`${attributes.lineWidth || 0.1}`}
          id="path865"
          d="M 3.3875001,0 H 0.86875001 V -0.47500001 H 1.8375 V -3.5937501 H 0.86875001 v -0.425 q 0.19687499,0 0.42187499,-0.03125 0.225,-0.034375 0.340625,-0.096875 0.14375,-0.078125 0.225,-0.196875 0.084375,-0.121875 0.096875,-0.325 H 2.4375 v 4.19375009 h 0.9500001 z"
        />
        <path
          id="path867"
          stroke={`${attributes.borderColor || "white"}`}
          fill={`${attributes?.borderColor || "white"}`}
          strokeWidth={`${attributes.lineWidth || 0.1}`}
          d="m 7.6906253,-1.478125 q 0,0.325 -0.11875,0.62187499 -0.11875,0.296875 -0.325,0.5 -0.225,0.21875001 -0.5375,0.33750001 -0.309375,0.115625 -0.71875,0.115625 -0.38125,0 -0.734375,-0.08125 Q 4.9031253,-0.0625 4.6593753,-0.175 v -0.65937501 h 0.04375 q 0.25625,0.1625 0.6,0.278125 0.34375,0.1125 0.675,0.1125 0.221875,0 0.428125,-0.0625 0.209375,-0.0625 0.371875,-0.21875 0.1375,-0.134375 0.20625,-0.32187499 0.071875,-0.1875 0.071875,-0.434375 0,-0.240625 -0.084375,-0.40625 -0.08125,-0.165625 -0.228125,-0.265625 -0.1625,-0.11875 -0.396875,-0.165625 -0.23125,-0.05 -0.51875,-0.05 -0.275,0 -0.53125,0.0375 -0.253125,0.0375 -0.4375,0.075 v -2.3968751 h 2.8 v 0.546875 H 5.4625003 V -2.86875 q 0.134375,-0.0125 0.275,-0.01875 0.140625,-0.00625 0.24375,-0.00625 0.378125,0 0.6625,0.065625 0.284375,0.0625 0.521875,0.225 0.25,0.171875 0.3875,0.44375 0.1375,0.271875 0.1375,0.68125 z"
        />
      </g>
      <g className="expanded_compass_3" transform="translate(4.04 16.54) rotate(-20.36)">
        <path
          id="path870"
          stroke={`${attributes.borderColor || "white"}`}
          fill={`${attributes?.borderColor || "white"}`}
          strokeWidth={`${attributes.lineWidth || 0.1}`}
          d="M 3.3875001,0 H 0.86875001 V -0.47500001 H 1.8375 V -3.5937501 H 0.86875001 v -0.425 q 0.19687499,0 0.42187499,-0.03125 0.225,-0.034375 0.340625,-0.096875 0.14375,-0.078125 0.225,-0.196875 0.084375,-0.121875 0.096875,-0.325 H 2.4375 v 4.19375009 h 0.9500001 z"
        />
        <path
          id="path872"
          stroke={`${attributes.borderColor || "white"}`}
          fill={`${attributes?.borderColor || "white"}`}
          strokeWidth={`${attributes.lineWidth || 0.1}`}
          d="m 7.7281253,0 h -3.15 v -0.65312501 q 0.328125,-0.28125 0.65625,-0.56249999 0.33125,-0.28125 0.615625,-0.559375 0.6,-0.58125 0.821875,-0.921875 0.221875,-0.34375 0.221875,-0.7406251 0,-0.3625 -0.240625,-0.565625 -0.2375,-0.20625 -0.665625,-0.20625 -0.284375,0 -0.615625,0.1 -0.33125,0.1 -0.646875,0.30625 h -0.03125 v -0.65625 q 0.221875,-0.109375 0.590625,-0.2 0.371875,-0.090625 0.71875,-0.090625 0.715625,0 1.121875,0.346875 0.40625,0.34375 0.40625,0.934375 0,0.2656251 -0.06875,0.4968751 -0.065625,0.228125 -0.196875,0.434375 -0.121875,0.19375 -0.2875,0.38125 -0.1625,0.1875 -0.396875,0.415625 -0.334375,0.328125 -0.690625,0.6375 -0.35625,0.30624999 -0.665625,0.56874999 h 2.503125 z"
        />
      </g>
    </svg>
  );
};

export default ExpandedCompass;
