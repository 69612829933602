import React from "react";
import { SymbolComponentType } from "../helpers/getSymbol";

const TrackLineRangeScale = (props: SymbolComponentType) => {
  const { index, attributes, transformStr } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 4.61 25.66"
      key={index}
      id={`symbol-${index}`}
      tabIndex={0}
      className="symbol target"
      name="symbol"
      preserveAspectRatio="xMidYMid meet"
      style={{
        top: `${attributes.top}%`,
        left: `${attributes.left}%`,
        width: `${attributes.width}%`,
        height: `${attributes.height}%`,
        zIndex: attributes.zIndex,
        // transform:`rotate(${attributes?.transform?.rotate}deg) scaleX(${attributes?.transform?.scaleX}) scaleY(${attributes?.transform?.scaleY}) matrix3d(${attributes?.transform?.matrix3d})`
        transform: transformStr,
      }}
    >
      <path
        // className="track_line_range_scale"
        stroke={`${attributes.borderColor || "white"}`}
        fill={`${attributes?.backgroundColor || "transparent"}`}
        strokeWidth={`${attributes.lineWidth || 0.1}`}
        d="M 2.3050001,24.666083 V 0.00608282"
      />
      <path
        // className="track_line_range_scale"
        stroke={`${attributes.borderColor || "white"}`}
        fill={`${attributes?.backgroundColor || "transparent"}`}
        // strokeWidth={`${attributes.lineWidth || .1}`}
        d="m -0.02625,4.3975246 h 4.615"
      />
      <path
        id="rect8-2"
        d="M 0.005,12.745638 H 4.62"
        // className="track_line_range_scale"
        stroke={`${attributes.borderColor || "white"}`}
        fill={`${attributes?.backgroundColor || "transparent"}`}
        // strokeWidth={`${attributes.lineWidth || .1}`}
      />
      <path
        id="rect8-1"
        d="m -0.0575,21.09375 h 4.615"
        // className="track_line_range_scale"
        stroke={`${attributes.borderColor || "white"}`}
        fill={`${attributes?.backgroundColor || "transparent"}`}
      />
    </svg>
  );
};

export default TrackLineRangeScale;
