import React, { useState } from "react";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/AssetManager/drop-down-icon.svg";
import styles from "./PageCompletionHeader.module.css";
interface PBMenuProps {
  title: string;
  children?: React.ReactNode;
}
const PBMenuHeader = (props: PBMenuProps) => {
  const { title, children } = props;
  const [showDropDown, setShowDropDown] = useState(false);

  return (
    <div className="property-box-page-completion">
      <div
        className="separator"
        style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        onClick={() => setShowDropDown(!showDropDown)}
      >
        <div className="separator-title">{title}</div>
        <ArrowIcon className={!showDropDown ? styles.dropdownArrowActive : styles.dropdownArrowNotActive} />
      </div>
      {showDropDown && children}
    </div>
  );
};

export default PBMenuHeader;
