import { ImageBlockTypeV2 } from "../../models/ICustomPage";
import { IBasicPageAttributes, IBasicPageTable, IBasicPageTextBlock } from "../../../IBasePage";

export const initialFrame: IBasicPageAttributes = {
  width: 15,
  height: 15,
  left: 10,
  top: 10,
  transform: {
    rotate: 0,
    scale: [1, 1],
  },
  translate: [0, 0],
  zIndex: 1,
  fontColor: "ivory",
  fontStyle: "normal",
  fontWeight: "normal",
  textDecoration: "none",
  textAlign: undefined,
  backgroundColor: "transparent",
  objectId: (0).toString(),
};

export const newTextBlock: IBasicPageTextBlock = {
  text: "Text",
  attributes: initialFrame,
};

export const newImageBlock: ImageBlockTypeV2 = {
  imagePath: "cpat/generic/images/13/13/FED878B1C46AEA11817D60F26251769D/cpatbrandedairplane.png",
  assetVersionId: 13,
  width: 15,
  height: 15,
  left: 10,
  top: 10,
  zIndex: 12,
  objectId: (0).toString(),
};

export const newTable: IBasicPageTable = {
  width: 20,
  height: 20,
  left: 25,
  top: 25,
  borderColor: "white",
  shadowColor: "black",
  fontColor: "white",
  backgroundColor: "transparent",
  cols: [50, 50],
  rows: [50, 50],
  lineWidth: 3,
  cells: [
    {
      id: 101,
      C: 1,
      R: 1,
      Cspan: 1,
      Rspan: 1,
      text: "<p>  </p>",
    },
    {
      id: 201,
      C: 2,
      R: 1,
      Cspan: 1,
      Rspan: 1,
      text: "<p>  </p>",
    },
    {
      id: 102,
      C: 1,
      R: 2,
      Cspan: 1,
      Rspan: 1,
      text: "<p>  </p>",
    },
    {
      id: 202,
      C: 2,
      R: 2,
      Cspan: 1,
      Rspan: 1,
      text: "<p>  </p>",
    },
  ],
  zIndex: 52,
};
