import React from "react";
import { AnnotationPropType } from "../AnnotationThumbnail";

const AnnotationSquare = (props: AnnotationPropType) => {
  const { annotation, index, ratio } = props;

  return (
    <svg
      // viewBox={`0 0 100 auto`}
      width="100"
      id={`square-${index}`}
      tabIndex={0}
      name="square"
      className="annotation"
      style={{
        top: `${annotation.top.toFixed(2)}%`,
        left: `${annotation.left.toFixed(2)}%`,
        width: `${annotation.width.toFixed(2)}%`,
        height: `${annotation.height.toFixed(2)}%`,
        zIndex: annotation.zIndex,
        transform: `rotate(${annotation?.transform?.rotate?.toFixed(2)}deg)`,
      }}
    >
      <rect
        x=".5%"
        y=".5%"
        height="98.5%"
        width="99%"
        stroke={`${annotation.borderColor || "white"}`}
        fill={`${annotation.backgroundColor || "transparent"}`}
        strokeWidth={`${annotation.strokeWidth * ratio || 4 * ratio}`}
      ></rect>
    </svg>
  );
};

export default AnnotationSquare;
