import React from "react";
import * as LessonMetaData from "../../../contexts/LessonMetaDataProvider";
import AssetManagerWrapper from "../../AssetManager/AssetManagerWrapper";
import { CurrentSelectedAsset } from "../../AssetManager/AssetManager.types";
import lodash from "lodash";
import { ReactComponent as TrashIcon } from "../../../assets/icons/Common/remove-page-icon.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/Settings/add-new-tag.svg";
import { ReactComponent as CheckChecked } from "../../../assets/icons/Settings/check-box-checked.svg";
import { ReactComponent as Check } from "../../../assets/icons/Settings/check-box.svg";
import { useLessonSettingsState, useLessonSettingsDispatch } from "../LessonSettingsContext/LessonSettingsProvider";
// import * as LessonSettingsTypes from '../LessonSettings.types'
import EditableContainer from "../../PageLister/EditableContainer";
const ReferencesSettings = (props: any) => {
  const settingsState = useLessonSettingsState();
  const settingsDispatch = useLessonSettingsDispatch();

  const handlePlus = async (e: any) => {
    const reference: CurrentSelectedAsset = (await AssetManagerWrapper.show(
      props.setAssetManagerController,
      "References",
    )) as CurrentSelectedAsset;
    const x: LessonMetaData.LessonReference = {
      assetVersionId: reference.assetId as number,
      assetTypeName: reference.assetTypeName as string,
      blobPath: reference.blobPath as string,
      description: reference.description as string,
      name: reference.name as string,
      lessonModeIds: [1, 2],
      referenceTabName: reference.description as string,
    };
    settingsDispatch({
      type: "UPDATE_LESSON_META_DATA_LOCAL_COPY",
      payload: {
        lessonReferences: [...settingsState.lessonMetaDataLocalCopy.lessonReferences, x],
      },
    });
  };

  const handleRemoveReference = (ref: LessonMetaData.LessonReference) => () => {
    settingsDispatch({ type: "REMOVE_LESSON_REFERENCE", payload: ref });
  };

  const handleLessonModeClick = (mode: number, ref: LessonMetaData.LessonReference, index: number) => () => {
    const lessonReferencesCopy = lodash.cloneDeep(settingsState.lessonMetaDataLocalCopy.lessonReferences);
    const inQuestion = lessonReferencesCopy[index].lessonModeIds;

    if (inQuestion && inQuestion.includes(mode) && inQuestion.length > 1) {
      inQuestion?.splice(inQuestion.indexOf(mode), 1);
    } else if (inQuestion && !inQuestion.includes(mode)) {
      inQuestion?.push(mode);
    }
    settingsDispatch({
      type: "UPDATE_LESSON_META_DATA_LOCAL_COPY",
      payload: {
        lessonReferences: [...lessonReferencesCopy],
      },
    });
  };
  function updateTabName(tabName: string, index: number) {
    const lessonReferencesCopy = lodash.cloneDeep(settingsState.lessonMetaDataLocalCopy.lessonReferences);
    lessonReferencesCopy[index].referenceTabName = tabName;
    settingsDispatch({
      type: "UPDATE_LESSON_META_DATA_LOCAL_COPY",
      payload: {
        lessonReferences: [...lessonReferencesCopy],
      },
    });
  }
  const placeHolder = "Enter Tab Name...";
  function emptyTabCheck(tabName: string | null | undefined) {
    if (tabName === "") return placeHolder;
    else if (tabName === null) {
      return placeHolder;
    }
    return tabName;
  }
  return (
    <div className="references-settings">
      <p>Manage references or add a new one from following list.</p>
      <div className="reference-row-container">
        <div className="references-item-header-container">
          <p className="references-item-header">Tab Name</p>
          <p className="references-item-header">File Name</p>
          <p className="references-item-header">Enable References</p>
          <p className="references-item-header">File Type</p>
          <p className="references-item-header"></p>
        </div>
        {settingsState.lessonMetaDataLocalCopy.lessonReferences.map((ref, index) => {
          return (
            <div key={"lslrk" + ref.assetVersionId + ref.name} className="reference-item">
              <EditableContainer
                updateName={updateTabName}
                name={emptyTabCheck(ref.referenceTabName) === placeHolder ? "" : ref.referenceTabName}
                currentIndex={index}
                className={
                  emptyTabCheck(ref.referenceTabName) === placeHolder
                    ? "references-item-data disabled-text"
                    : "references-item-data"
                }
                tag="p"
                charLimit={14}
              >
                {emptyTabCheck(ref.referenceTabName)}
              </EditableContainer>
              <p className="references-item-data">{ref.name}</p>
              <div className="references-item-data">
                <div className="lesson-references-mode">
                  {settingsState.lessonMetaDataLocalCopy.lessonReferences[index].lessonModeIds?.includes(1) ? (
                    <CheckChecked onClick={handleLessonModeClick(1, ref, index)} />
                  ) : (
                    <Check onClick={handleLessonModeClick(1, ref, index)} />
                  )}
                  <p>Demo</p>
                </div>
                <div className="lesson-references-mode">
                  {settingsState.lessonMetaDataLocalCopy.lessonReferences[index].lessonModeIds?.includes(2) ? (
                    <CheckChecked onClick={handleLessonModeClick(2, ref, index)} />
                  ) : (
                    <Check onClick={handleLessonModeClick(2, ref, index)} />
                  )}
                  <p>Practice</p>
                </div>
                <div className="lesson-references-mode">
                  {settingsState.lessonMetaDataLocalCopy.lessonReferences[index].lessonModeIds?.includes(3) ? (
                    <CheckChecked onClick={handleLessonModeClick(3, ref, index)} />
                  ) : (
                    <Check onClick={handleLessonModeClick(3, ref, index)} />
                  )}
                  <p>Perform</p>
                </div>
              </div>
              <p className="references-item-data">{ref.assetTypeName}</p>
              <div className="references-item-data ref-remove-icon">
                <TrashIcon onClick={handleRemoveReference(ref)} />
              </div>
            </div>
          );
        })}
      </div>
      <div>
        {settingsState.lessonMetaDataLocalCopy.lessonReferences.length < 2 ? (
          <PlusIcon onClick={handlePlus} />
        ) : (
          <p>Max References Limit</p>
        )}
      </div>
    </div>
  );
};

export default ReferencesSettings;
