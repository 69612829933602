import React from "react";
import blobUrlFormatHelper from "../../../../../components/blobUrlFormatHelper";
import { handleKeyUp } from "../../../../../components/ObjectPropertyBox/functions/PropertyBoxFunctions";
import { VideoPropType } from "../models/ICustomPage";
import {
  InteractivityHotspotActionTypes,
  useInteracitvityHotspotDispatch,
} from "../../../../../contexts/InteractivityHotspotProvider";
import { useObjectsDispatch, ObjectActionsType } from "../../../../../contexts/ObjectsProvider";
import { useObjectPosition } from "../../../../../hooks/useObjectPosition";
const { SET_CURRENT_HOTSPOT } = InteractivityHotspotActionTypes;

const BaseFFVideo = (props: VideoPropType) => {
  const { blobUrl, ffElement, kp, handleVideoClick, handleKeyPress, loadCustomPageVideo, selectTarget } = props;
  const hotspotsDispatch = useInteracitvityHotspotDispatch();
  const objectsDispatch = useObjectsDispatch();
  const objectId = ffElement?.objectId;
  const {
    position: [x, y],
    size: [w, h],
  } = useObjectPosition(objectId, ffElement?.top, ffElement?.left, ffElement?.width, ffElement?.height);
  const transformString = `translate(${x}px, ${y}px`;
  return (
    <div
      data-objectid={objectId}
      className={`${blobUrl ? "" : "empty-video"} target ff-video`}
      onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        hotspotsDispatch({
          type: SET_CURRENT_HOTSPOT,
          payload: null,
        });
        if (!objectId) return;
        let type = ObjectActionsType.SET_SELECTED_OBJECT;
        if (e.ctrlKey) {
          type = ObjectActionsType.ADD_SELECTED_OBJECT;
        }
        objectsDispatch({
          type,
          payload: { objectId },
        });
      }}
      onDoubleClick={(e: React.MouseEvent<HTMLDivElement>) => handleVideoClick(e)}
      tabIndex={0}
      style={{
        width: `${w}px`,
        height: `${h}px`,
        zIndex: ffElement?.zIndex,
        transform: transformString,
        color: `${ffElement?.fontColor}`,
        fontStyle: `${ffElement?.fontStyle}`,
        fontWeight: ffElement?.fontWeight,
        textDecoration: `${ffElement?.textDecoration}`,
        backgroundColor: `${ffElement?.backgroundColor}`,
        border: `${ffElement?.borderColor}`,
        boxShadow: `${ffElement?.shadowColor}`,
        opacity: ffElement.isDisplayed ? ffElement.opacity : 0.5,
        filter: ffElement.isDisplayed ? "none" : "grayScale(100%)",
      }}
    >
      {blobUrl && (
        <video
          id={`ff-video-main`}
          controls
          controlsList={"nodownload"}
          className="target ff-video"
          disablePictureInPicture
          loop={false}
          onLoadedData={(e: React.SyntheticEvent<HTMLVideoElement>) => loadCustomPageVideo(e, 0)}
          tabIndex={0}
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <source src={blobUrlFormatHelper(blobUrl)} type="video/mp4" />
        </video>
      )}
      <span className="icon-Video-icon"></span>
    </div>
  );
};

export default BaseFFVideo;
