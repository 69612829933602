import _ from "lodash";
import { ISymbolV2 } from "../../Symbol/models/ISymbol";
import { IAnnotation } from "../models/IAnnotation";

export function findGroupIndex(target: HTMLElement | SVGSVGElement) {
  const id = target.id.split("-");
  const index: number = parseInt(id[id.length - 1]);
  return index;
}

export function validateAnnotationName(node: IAnnotation, index: number) {
  if (!_.has(node, "name") || node.name?.length === 0) {
    return `${node.type} ${index}`;
  }
  return node.name;
}

export function validateSymbolName(symbol: ISymbolV2, index: number) {
  if (symbol && (!_.has(symbol, "name") || symbol.name.length < 0)) {
    return `${symbol.name} ${index}`;
  }
  return symbol.name;
}

export function getDirections(target: HTMLElement | SVGSVGElement) {
  if (target) {
    const name: string | null = target.getAttribute("name");

    if (
      name === "arrow" ||
      name === "lineArrow" ||
      name === "solidLine" ||
      name === "dashedLine" ||
      _.startsWith(target.id, "label")
    ) {
      return ["e", "w"];
    } else {
      return ["nw", "n", "ne", "w", "e", "sw", "s", "se"];
    }
  }
  return ["nw", "n", "ne", "w", "e", "sw", "s", "se"];
}
