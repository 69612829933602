let initalState = {
  model: "",
  role: "Captain",
  condition: "InAir",
  curtain: true,
  stepModal: false,
  stepSystemDisplay: false,
  openStepResponsePanel: "none",
  displayStyle: "none",
  hotspot: ["0,0,0,0"],
  builderStep: 0,
  chooseStep: true,
  addStep: false,
  currentStep: 1,
  steps: 1,
  move: false,
  moveTarget: { x: 0, y: 8.5, z: -150 },
  StepGraphic: [],
  stepResponse: [],
  custom: {},
  CustomeHotSpot: {
    key: "active",
    position: [35, 9, 38.5],
    rotation: [0, 0, 0],
    geometry: [30, 30, 30],
  },
  walkAroundHS1: {
    key: "active",
    position: [30, 8, 30],
    rotation: [0, 0, 0],
    geometry: [1, 32, 32],
    scale: [1, 1, 1],
    isSphere: true,
  },
  proximityPosition: {
    x: 0,
    y: 0,
    z: 0,
  },
  HotSpotList: [
    {
      key: "active",
      position: [0, 8, 0],
      rotation: [0, 0, 0],
      geometry: [1, 32, 32],
      scale: [1, 1, 1],
      isSphere: true,
      proximityRange: 50,
      proximityPosition: {
        x: 0,
        y: 0,
        z: 0,
      },
      IGSObject: [],
    },
  ],
  currentHsData: {
    position: [0, 7, 0],
    rotation: [0, 0, 0],
    scale: [1, 1, 1],
    isSphere: false,
  },

  walkAroundBuildGrid: true,
  Audio: [
    {
      File: null,
      Version: null,
      ParentVersion: null,
      FileMD5: null,
      NarratorText: null,
      Pronunciation: null,
    },
  ],
  Manifest: {},
  stepNumber: 0,
  stepObject: {
    stepType: 0,
    images: [
      {
        image: "cpat/generic/images/19/19/57E6CC11F46AEA11817D60F26251769D/blank.png",
        assetVersionId: 19,
        imageHotSpot: "0,0,0,0",
      },
    ],
    isTerminal: false,
    taskDescription: "What to do",
    question: "What to ask",
    choices: [
      {
        text: "answer",
        isCorrect: true,
      },
    ],
  },
  isIGS: false,
  IGSObject: [],
  openHsLister: false,
};

// ...state,
// stepObject: {
//     ...state.stepObject,
//     images: {
//         ...state.stepObject.images,
//         [action.index]: {
//             ...state.stepObject.images[action.index],
//             imageHotSpot:action.payload

export const CPaT3dPageCurrentReducer = (state = initalState, action) => {
  if (action.type === "DISPLAY_STEP_SYSTEM") {
    return {
      ...state,
      stepSystemDisplay: action.payload,
    };
  }
  if (action.type === "DISPLAY_CHOOSE_STEP") {
    return {
      ...state,
      chooseStep: action.payload,
    };
  }
  if (action.type === "DISPLAY_ADD_STEP") {
    return {
      ...state,
      addStep: action.payload,
    };
  }
  if (action.type === "DISPLAY_STEP_RESPONSE_PANEL") {
    return {
      ...state,
      openStepResponsePanel: action.payload,
    };
  }
  if (action.type === "DISPLAY_STYLE") {
    return {
      ...state,
      displayStyle: action.payload,
    };
  }
  if (action.type === "STORED_CURRENT_STEP") {
    return {
      ...state,
      currentStep: action.payload,
    };
  }
  if (action.type === "SET_STEP_RESPONSE_1") {
    return {
      ...state,
      stepResponse: {
        ...state.stepResponse,
        one: action.payload,
      },
    };
  }

  if (action.type === "SET_STEP_RESPONSE_2") {
    return {
      ...state,
      stepResponse: {
        ...state.stepResponse,
        two: action.payload,
      },
    };
  }

  if (action.type === "SET_STEP_RESPONSE_3") {
    return {
      ...state,
      stepResponse: {
        ...state.stepResponse,
        three: action.payload,
      },
    };
  }
  if (action.type === "SET_MODEL") {
    return {
      ...state,
      model: action.payload,
    };
  }
  if (action.type === "SET_CURTAIN") {
    return {
      ...state,
      curtain: action.payload,
    };
  }
  if (action.type === "HIDE_MODAL") {
    return {
      ...state,
      stepModal: action.payload,
    };
  }
  if (action.type === "SET_ROLE") {
    return {
      ...state,
      role: action.payload,
    };
  }
  if (action.type === "SET_CUSTOM") {
    return {
      ...state,
      custom: action.payload,
    };
  }
  if (action.type === "SET_HOT_SPOT") {
    return {
      ...state,
      CustomeHotSpot: action.payload,
    };
  }
  if (action.type === "SET_WA_HOT_SPOT") {
    return {
      ...state,
      walkAroundHS1: action.payload,
    };
  }
  if (action.type === "SET_CONDITION") {
    return {
      ...state,
      condition: action.payload,
    };
  }
  if (action.type === "WA_BUILD_GRID") {
    return {
      ...state,
      walkAroundBuildGrid: action.payload,
    };
  }
  if (action.type === "SET_MANIFEST") {
    return {
      ...state,
      Manifest: action.payload,
    };
  }
  if (action.type === "SET_STEP_BUILDER_STEP") {
    return {
      ...state,
      builderStep: action.payload,
    };
  }
  if (action.type === "SET_STEP_OBJECT") {
    return {
      ...state,
      stepObject: action.payload,
    };
  }
  if (action.type === "SET_STEP_HOT_SPOT") {
    return {
      ...state,
      stepObject: {
        ...state.stepObject,
        images: {
          ...state.stepObject.images,
          [action.imageIndex]: {
            ...state.stepObject.images[action.imageIndex],
            imageHotSpot: action.payload,
          },
        },
      },
    };
  }

  if (action.type === "SET_STEP_IMAGE") {
    return {
      ...state,
      stepObject: {
        ...state.stepObject,
        images: {
          ...state.stepObject.images,
          [action.imageIndex]: {
            ...state.stepObject.images[action.imageIndex],
            image: action.imagePath,
            assetVersionId: action.assetVersionId,
          },
        },
      },
    };
  }
  if (action.type === "SET_ALL_STEP_IMAGES") {
    return {
      ...state,
      stepObject: {
        ...state.stepObject,
        images: action.payload,
      },
    };
  }
  if (action.type === "RESET_STEP_IMAGES") {
    return {
      ...state,
      stepObject: {
        ...state.stepObject,
        images: [
          {
            image: "cpat/generic/images/19/19/57E6CC11F46AEA11817D60F26251769D/blank.png",
            assetVersionId: 19,
            imageHotSpot: "0,0,0,0",
          },
        ],
      },
    };
  }
  if (action.type === "SET_STEP_TYPE") {
    return {
      ...state,
      stepObject: {
        ...state.stepObject,
        stepType: action.payload,
      },
    };
  }
  if (action.type === "SET_IS_TERMINAL") {
    return {
      ...state,
      stepObject: {
        ...state.stepObject,
        isTerminal: action.payload,
      },
    };
  }
  if (action.type === "SET_TASK_DESC") {
    return {
      ...state,
      stepObject: {
        ...state.stepObject,
        taskDescription: action.payload,
      },
    };
  }
  if (action.type === "SET_QUESTION2") {
    return {
      ...state,
      stepObject: {
        ...state.stepObject,
        question: action.payload,
      },
    };
  }
  if (action.type === "SET_CHOICES") {
    return {
      ...state,
      stepObject: {
        ...state.stepObject,
        choices: action.payload,
      },
    };
  }
  if (action.type === "SET_CHOICES") {
    return {
      ...state,
      stepObject: {
        ...state.stepObject,
        choices: action.payload,
      },
    };
  }
  if (action.type === "SET_IGSO") {
    return {
      ...state,
      IGSObject: action.payload,
    };
  }
  if (action.type === "SET_STEP_NUMBER") {
    return {
      ...state,
      stepNumber: action.payload,
    };
  }
  if (action.type === "SET_IS_IGS") {
    return {
      ...state,
      isIGS: action.payload,
    };
  }
  if (action.type === "SET_MOVE") {
    return {
      ...state,
      move: action.payload,
    };
  }
  if (action.type === "SET_MOVE_TARGET") {
    return {
      ...state,
      moveTarget: action.payload,
    };
  }

  if (action.type === "SET_HS_LIST") {
    return {
      ...state,
      HotSpotList: action.payload,
    };
  }

  if (action.type === "SET_CURRENT_HSDATA") {
    return {
      ...state,
      currentHsData: action.payload,
    };
  }
  if (action.type === "SET_HSLISTER_OPEN") {
    return {
      ...state,
      openHsLister: action.payload,
    };
  }
  return state;
};

export default CPaT3dPageCurrentReducer;
