import ILessonPageDto from "../../../models/ILessonPageDto";
export const checkPageTypeForVersion = (pageType: string, lessonPages: ILessonPageDto[]) => {
  const currentVersion = 20210312;
  let returnVersion = 0;
  lessonPages.forEach((page, index) => {
    if (page.pagePlayerType === pageType && page.pageManifest.version) {
      returnVersion = page.pageManifest.version;
    }
  });
  if (returnVersion === 0) {
    returnVersion = currentVersion;
  }
  //loop lesson pages looking for a selected pagetype with a version
  return returnVersion;
};
