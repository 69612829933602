import React from "react";
import "./AssetManager.styles.css";
import Portal from "../Portal/Portal";
import AssetManagerWrapper from "./AssetManagerWrapper";
// import FilterListItem from "./AssetLibrary/AssetLibrary"
import { AssetManagerProvider } from "./AssetManagerContext/AssetManagerProvider";

const AssetManager = React.memo((props: any) => {
  //Wrapper Prevents Bug with Switching state in same Component Causing portal to build again, Consider Modifying Portal component to be better
  // const [assetLibraryMemory, setAssetLibraryMemory] = useState();
  return (
    <AssetManagerProvider>
      {props.assetManagerController.isOpen && (
        <Portal>
          <AssetManagerWrapper {...props} />
        </Portal>
      )}
    </AssetManagerProvider>
  );
});

export default AssetManager;
