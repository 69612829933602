import TimelinePanel from "../../panels/TimelinePanel/TimelinePanel";
import React, { useState } from "react";
import classes from "../../utils/HTML/classes";

import { ReactComponent as ChevronLeftIcon } from "../../assets/icons/Common/chevron-left.svg";

import "./index.css";
import { useLessonPage } from "../../contexts/LessonPagesProvider/LessonPagesProvider";
import { pageHasNarration } from "./utils";

export const TimelineDrawer = () => {
  const [open, setOpen] = useState(false);
  const { pageManifest, currentLessonPage } = useLessonPage();

  if (!currentLessonPage || !pageManifest) {
    return null;
  }

  if (!pageHasNarration(currentLessonPage.pagePlayerType, pageManifest.basePageType)) {
    return null;
  }

  return (
    <div className={classes("timeline-drawer-wrapper", { "timeline-drawer-wrapper--open": open })}>
      <div className="timeline-drawer-handle" onClick={() => setOpen(!open)}>
        <ChevronLeftIcon />
        <p>Timeline</p>
      </div>
      <div className="timeline-drawer-content">
        <TimelinePanel />
      </div>
    </div>
  );
};
