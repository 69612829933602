import "./AnnotationTextMenu.css";
import _ from "lodash";
import React, { useEffect, useRef, useContext } from "react";
import AlignButtons from "../../WYSIWYGToolbar/components/AlignButtons";
import FontColorButton from "../../WYSIWYGToolbar/components/FontColorButton";
import FontStyleButtons from "../../WYSIWYGToolbar/components/FontStyleButtons";
import TextHighlightButton from "../../WYSIWYGToolbar/components/TextHighlightButton";
import FontSizeButton from "../../WYSIWYGToolbar/components/FontSizeButton";
import { colors as standard, bgColors as standardHighlight } from "./constants";
import buttons from "./helpers/buttons";
import ListButtons from "../../WYSIWYGToolbar/components/ListButtons";
import { BasePageTextBlockVersion } from "../../../utils/Versioning/basePagesVersioning";

import { ILessonAdditionalSettingsContext, LessonAdditionalSettingsContext } from "../../../routes/builderContexts";

import { ReactComponent as ClearFormatting } from "../../../assets/icons/Common/remove-formatting-white.svg";
// import { IBasicPageAttributes } from '../../../pageTypes/BasicPage_Player/components/IBasePage';
// import ISymbolStyle from '../../Symbol/models/ISymbolStyle';
// import { FormatType, IAnnotation } from '../models/IAnnotation';
// import FillButton from '../../WYSIWYGToolbar/components/FillButton';

type TextMenuType = {
  bgColors?: string[];
  fontColors?: string[];
  isLabel?: boolean;
  hide?: boolean;
  formats?: Array<string>;
  position?: { top: number; left: number };
  version?: BasePageTextBlockVersion | string;
};

const AnnotationTextMenu = (props: TextMenuType) => {
  const { bgColors, fontColors, formats } = props;
  const highlightRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const canDisplay: boolean =
    _.includes(formats, "bold") || _.includes(formats, "italic") || _.includes(formats, "underline");

  const currentColors = useContext<ILessonAdditionalSettingsContext>(LessonAdditionalSettingsContext)?.getColorsList(
    standard,
  );

  useEffect(() => {
    buttons();
  });

  function displayFontColorBtn() {
    if ((!_.isEmpty(formats) && _.includes(formats, "color")) || _.isEmpty(formats)) {
      //return <FontColorButton colors={fontColors ? fontColors : standard} />
      return <FontColorButton colors={fontColors ? fontColors : currentColors} />;
    }
  }

  function displayFontFaceBtns() {
    if ((!_.isEmpty(formats) && canDisplay) || _.isEmpty(formats)) {
      return <FontStyleButtons />;
    }
  }

  function displayAlignBtns() {
    if ((!_.isEmpty(formats) && _.includes(formats, "align")) || _.isEmpty(formats)) {
      return <AlignButtons />;
    }
  }

  function displayHighlightBtn() {
    if ((!_.isEmpty(formats) && _.includes(formats, "background")) || _.isEmpty(formats)) {
      return <TextHighlightButton colors={bgColors ? bgColors : standardHighlight} highlightRef={highlightRef} />;
    }
  }

  function displayFontSizeBtn() {
    if ((!_.isEmpty(formats) && _.includes(formats, "size")) || _.isEmpty(formats)) {
      return <FontSizeButton version={props.version} />;
    }
  }

  function displayListBtns() {
    if (!_.isEmpty(formats) && _.includes(formats, "list")) {
      return <ListButtons />;
    }
  }

  return (
    <div
      className="text-format-menu"
      id="text-menu"
      onMouseDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
      style={{
        display: props.hide ? "none" : "flex",
      }}
    >
      {displayFontSizeBtn()}
      {displayFontColorBtn()}
      {displayHighlightBtn()}
      {displayFontFaceBtns()}
      {displayAlignBtns()}
      {displayListBtns()}
      {!props?.isLabel && (
        <button className="ql-clearFormat">
          {/* <ClearFormatting  title='Remove Formatting: (Shift + Alt + F) removes all formatting'/> */}
        </button>
      )}
    </div>
  );
};

export default AnnotationTextMenu;
