import React from "react";
import { PanelProperty } from "../types";

import classes from "../../../utils/HTML/classes";
import { AudioPlayer } from "../../../components/AudioPlayer";
import { getFilename } from "../../../utils/Strings/getFilename";

export type AudioPropertyProps = PanelProperty<unknown> & {
  value: string;
};

export const AudioProperty = ({ value, className, readOnly, disabled }: AudioPropertyProps) => {
  return (
    <div className={classes("property-field-audio", className)} style={{ display: "flex", gap: "8px" }}>
      <input
        className="property-field-audio-input"
        type="text"
        defaultValue={getFilename(value)}
        readOnly={readOnly}
        disabled={disabled}
      />
      <AudioPlayer src={value} autoPlay={false} />
    </div>
  );
};
