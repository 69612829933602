import React from "react";
import { Modal } from "react-bootstrap";
import "./noPermissionsLockoutModal.css";

type NoPermissionsLockoutModalProps = {
  userDoesNotHavePermissions: boolean;
};

const NoPermissionsLockoutModal: React.FC<NoPermissionsLockoutModalProps> = (
  properties: NoPermissionsLockoutModalProps,
) => {
  return (
    <Modal show={properties.userDoesNotHavePermissions} size="lg">
      <Modal.Header>
        <Modal.Title>You do not have permission to create a lesson</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className="no-permission-modal-body-margin">Please contact your administrator.</h5>
      </Modal.Body>
    </Modal>
  );
};

export default NoPermissionsLockoutModal;
