import "../../pageTypes/BasicPage_Player/components/BaseFreeForm/FreeForm.css";
import "../richTextEditor/richTextEditor.css";
import "./IGA.css";

import _ from "lodash";
import React, { Dispatch, RefObject, SetStateAction, useContext, useEffect, useRef, useState } from "react";
// import { AssetContext, IAssetManagementContext} from '../../routes/builderContexts';
// import IGACloseConfirmation from './components/IGACloseConfirmation';
import IGADeleteStepConfirmation from "./components/IGADeleteStepConfirmation";
import IGAGraphicQuiz from "./components/IGAGraphicQuiz/IGAGraphicQuiz";
import IGAInteractiveGraphic from "./components/IGAInteractiveGraphic/IGAInteractiveGraphic";
import IGAStandardQuiz from "./components/IGAStandardQuiz/IGAStandardQuiz";
import IGASteps from "./components/IGASteps";
import StepTypeSelector from "./components/StepTypeSelector";
import { blankDOMRect, blankHotSpot, graphicQuizStep, interactiveStep, standardQuizStep } from "./helpers/initialSteps";
import { IIGA, IIGAGraphicChoice, IIGAHotSpot, IIGAImageHotspot, IIGATextChoice } from "./models/IIGA";
import AssetManager from "../AssetManager";
import { IPageContext, PageContext } from "../../routes/builderContexts";
import PageTypeCrawler from "../../services/Utils/PageTypeCrawler/PageTypeCrawler";
import IGSInteractive from "./components/IGAInteractiveGraphic/IGSInteractive";
import { annotationsToAdd, shouldMaintainRatio } from "../Annotation/AnnotationFunctions/AnnotationFunctions";
import { IAnnotation } from "../Annotation/models/IAnnotation";
import { IAnnotationState } from "../Annotation/models/IAnnotationState";
import { BoundType } from "../react-moveable";
import * as pbFunctions from "../ObjectPropertyBox/functions/PropertyBoxFunctions";
import { emptyPropertyBox } from "../ObjectPropertyBox/models/emptyPropertyBox";
import { IPBRefs, IPropertyBox } from "../ObjectPropertyBox/models/IObjectPropertyBox";
import { AnnotationType } from "../Annotation/Annotation";
import { updateLabel } from "../Annotation/AnnotationFunctions/AnnotationLabelFunctions";
import { IKeyPressHandler } from "../ObjectPropertyBox/models/IKeyPressHandler";
import {
  getDirections,
  validateAnnotationName,
  validateSymbolName,
} from "../Annotation/AnnotationFunctions/AnnotationSelectionFunctions";
import { IMoveableProps } from "../Moveable/models/IMoveableProps";
import IGAAnnotationMenuBar from "./components/IGAAnnotationMenuBar/IGAAnnotationMenuBar";
import { ISymbolV2 } from "../Symbol/models/ISymbol";
import ObjectPropertyBox from "../ObjectPropertyBox/ObjectPropertyBox";
import { ElementTypes } from "../../pageTypes/BasicPage_Player/components/IBasePage";
import IGAGraphicQuizLayoutButton from "./components/IGAGraphicQuiz/IGAGraphicQuizLayoutButton";
import { IGAObjectList } from "../../classes/ObjectList/IGAObjectList";
import { ClassSymbol } from "../Symbol/classes/ClassSymbol";
import { Annotation } from "../Annotation/classes/AnnotationClass";

type IGAType = {
  assetManagerProps: any;
  currentIGA: IIGA[];
  handleClose: () => void;
  handleSave: (IGAObject: IIGA[]) => void;
};

const IGA = (props: IGAType) => {
  const { currentIGA, handleClose } = props;
  // const assetContext: IAssetManagementContext = useContext<IAssetManagementContext>(AssetContext);
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const containerRef: RefObject<HTMLDivElement> = useRef(null);
  const imgRef: React.RefObject<HTMLImageElement> = useRef(null);
  const moveRef: React.RefObject<any> = useRef(null);
  const pbRefs: IPBRefs = {
    opbLeftRef: useRef(null),
    opbTopRef: useRef(null),
    opbDegreesRef: useRef(null),
    opbWidthRef: useRef(null),
    opbHeightRef: useRef(null),
    obpZindex: useRef(null),
  };
  const updatedManifest: any = _.cloneDeep(pageContext.pageManifest);
  // let IGAObject: IIGA[] = _.cloneDeep(currentIGA)
  const [popUpWarning, setPopUpWarning] = React.useState(false);
  const [assetManagerCopy, setAssetManagerCopy]: [any, React.Dispatch<SetStateAction<any>>] = useState<any>(
    _.cloneDeep(props.assetManagerProps),
  );
  const [assetType, setAssetType]: [number, React.Dispatch<SetStateAction<number>>] = useState<number>(-1);
  const [bounds, setBounds]: [BoundType, Dispatch<SetStateAction<BoundType>>] = useState<BoundType>({
    top: 0,
    bottom: 394,
    left: 0,
    right: 795,
  });
  const [currentStep, setCurrentStep]: [number, React.Dispatch<SetStateAction<number>>] = useState<number>(0);
  const [directions, setDirections]: [string[], Dispatch<SetStateAction<string[]>>] = useState([
    "n",
    "nw",
    "ne",
    "s",
    "se",
    "sw",
    "e",
    "w",
  ]);
  const [editableIndex, setEditableIndex]: [number, Dispatch<SetStateAction<number>>] = useState<number>(-1);
  const [elementType, setElementType]: [ElementTypes, Dispatch<SetStateAction<ElementTypes>>] =
    useState<ElementTypes>("annotations");
  const [IGAObject, setIGAObject]: [IIGA[], React.Dispatch<SetStateAction<IIGA[]>>] = useState<IIGA[]>([
    interactiveStep,
  ]);
  const [graphicQuizIndex, setGraphicQuizIndex]: [number, React.Dispatch<SetStateAction<number>>] =
    useState<number>(-1);
  const [updatedSteps, setUpdatedSteps]: [string[], React.Dispatch<SetStateAction<string[]>>] = useState<string[]>([]);
  const [hotSpot, setHotSpot]: [IIGAHotSpot, Dispatch<SetStateAction<IIGAHotSpot>>] =
    useState<IIGAHotSpot>(blankHotSpot);
  // const [isConfirmationShown, setIsConfirmationShown]: [boolean, React.Dispatch<SetStateAction<boolean>>] = useState<boolean>(false);
  const [isDeleteConfirmShown, setIsDeleteConfirmShown]: [boolean, React.Dispatch<SetStateAction<boolean>>] =
    useState<boolean>(false);
  const [igaUpdateNotification, setIgaUpdateNotification]: [boolean, React.Dispatch<SetStateAction<boolean>>] =
    useState<boolean>(false);
  const [isDragging, setIsDragging]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false);
  const [isHotSpotActive, setIsHotSpotActive]: [boolean, React.Dispatch<SetStateAction<boolean>>] =
    useState<boolean>(false);
  const [isMenuShown, setIsMenuShown]: [boolean, React.Dispatch<SetStateAction<boolean>>] = useState<boolean>(false);
  const [isPropertyBoxShown, setIsPropertyBoxShown]: [boolean, React.Dispatch<SetStateAction<boolean>>] =
    useState<boolean>(false);
  const [isRotating, setIsRotating]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false);
  const [isResizing, setIsResizing]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false);
  const [allStepsCleared, setAllStepsCleared]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(true);
  const [isTypeSelectorShown, setIsTypeSelectorShown]: [boolean, React.Dispatch<SetStateAction<boolean>>] =
    useState<boolean>(false);
  const [nodeToUpdate, setNodeToUpdate]: [
    IAnnotation | ISymbolV2 | undefined,
    Dispatch<SetStateAction<IAnnotation | ISymbolV2 | undefined>>,
  ] = useState<IAnnotation | ISymbolV2 | undefined>();
  const [pageDims, setPageDims]: [DOMRect | null | undefined, Dispatch<SetStateAction<DOMRect | null | undefined>>] =
    useState<DOMRect | null | undefined>();
  const [propertyBox, setPropertyBox]: [IPropertyBox, Dispatch<SetStateAction<IPropertyBox>>] =
    useState<IPropertyBox>(emptyPropertyBox);
  const [selectedAnswer, setSelectedAnswer]: [number, React.Dispatch<SetStateAction<number>>] = useState<number>(-1);
  const [target, setTarget]: [any, Dispatch<SetStateAction<any>>] = useState<any>();
  const currentVersion = 1.0;
  const maxAllowedSteps = 8;
  const maxGraphicChoices = 6;
  const maxStandardChoices = 5;
  const interactive = 1;
  const standard = 2;
  const graphical = 3;
  // let updatedManifest.IGSObject: IIGA[] = _.cloneDeep(IGAObject);
  let tempNode: IAnnotation | ISymbolV2 | undefined = _.cloneDeep(nodeToUpdate);
  // let assetManagerCopy = _.cloneDeep(props.assetManagerProps);

  const annotationsState: IAnnotationState = {
    annotations: updatedManifest.IGSObject[currentStep]?.annotations as IAnnotation[],
    bounds: bounds,
    editableIndex: editableIndex,
    pageDims: pageDims,
    tempNode: tempNode,
    selectedAnnotation: updatedManifest.IGSObject[currentStep]?.annotations
      ? (updatedManifest.IGSObject[currentStep]?.annotations as IAnnotation[])[editableIndex]
      : undefined,
    symbols: updatedManifest.IGSObject[currentStep]?.symbols as ISymbolV2[],
    setNodeToUpdate,
  };

  const keyPressHandlers: IKeyPressHandler = {
    props: {
      isDisplayed: true,
      elementType: elementType,
      moveRef: moveRef.current,
      nodeToUpdate: nodeToUpdate,
      pageDims: pageDims ? pageDims : (document.querySelector("#designBoxWrapper")?.getBoundingClientRect() as DOMRect),
      pbRefs: pbRefs,
      properties: propertyBox,
      shouldMaintainRatio: shouldMaintainRatio(target),
      target: target,
      changePropertyBox,
      updateAttributes,
      updateZindex,
    },
    changePropertyBox,
    removeSelected,
    setTarget,
    shouldMaintainRatio,
    updateAttributes,
  };

  const annotationProps: AnnotationType = {
    annotations: updatedManifest.IGSObject[currentStep]?.annotations,
    editableIndex: editableIndex,
    isMenuShown: isMenuShown,
    kp: keyPressHandlers,
    target: target,
    handleFormatChange: handleFormatChange,
    // "handlePasteText": handlePasteText,
    markSelected: markSelected,
    setEditableIndex: setEditableIndex,
    setElementType: setElementType,
    setIsMenuShown: setIsMenuShown,
    setNodeToUpdate: setNodeToUpdate,
    setTarget: setTarget,
    symbols: updatedManifest.IGSObject[currentStep]?.symbols,
    updateLabel: changeLabel,
  };

  const moveableProps: IMoveableProps = {
    bounds: bounds,
    canResize: true,
    directions: directions,
    isMovable: true,
    moveRef: moveRef,
    nodeToUpdate: nodeToUpdate as IAnnotation,
    pageDims: pageDims as DOMRect,
    propertyBox: propertyBox,
    shouldMaintainRatio: shouldMaintainRatio(target),
    target: target,
    endActions: endActions,
    setIsDragging: setIsDragging,
    setIsResizing: setIsResizing,
    setIsRotating: setIsRotating,
  };

  useEffect(() => {
    const manifestCopy = _.cloneDeep(updatedManifest);

    function produceIGA() {
      setIGAObject(currentIGA);
      if (
        currentIGA[currentStep] &&
        currentIGA[currentStep].stepType === 1 &&
        currentIGA[currentStep].images?.path !== ""
      ) {
        setIsHotSpotActive(true);
      } else {
        setIsHotSpotActive(false);
      }
      if (IGAObject[currentStep].stepType === 1) {
        setHotSpot((IGAObject[currentStep].images as IIGAImageHotspot).imageHotSpot as IIGAHotSpot);
      }
    }

    checkForIgaUpdateNotification();

    convertSymbolsIfHaveStyleAttributes();
    produceIGA();

    if (!_.isEqual(updatedManifest, manifestCopy)) {
      pageContext.updatePageManifest(updatedManifest);
    }
  }, []);

  function checkForIgaUpdateNotification() {
    let needsUpdate = false;
    for (const v in updatedManifest.IGSObject) {
      if (
        !updatedManifest.IGSObject[v].aspectRatio &&
        ((updatedManifest.IGSObject[v].annotations && updatedManifest.IGSObject[v].annotations!.length > 0) ||
          (updatedManifest.IGSObject[v].stepType === 1 &&
            updatedManifest.IGSObject[v].images.imageHotSpot &&
            updatedManifest.IGSObject[v].images.path))
      ) {
        needsUpdate = true;
      }
    }

    setIgaUpdateNotification(needsUpdate);
  }

  useEffect(addAnnotationNames, []);

  useEffect(() => {
    checkForIgaUpdateNotification();
    if (isTypeSelectorShown && isHotSpotActive) {
      setIsHotSpotActive(false);
    }
  }, [isTypeSelectorShown]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (timeout!) clearTimeout(timeout!);
    timeout = setTimeout(() => {
      setPopUpWarning(false);
    }, 1500);
    return () => {
      clearTimeout(timeout);
    };
  }, [popUpWarning]);

  useEffect(() => {
    const size: DOMRect = containerRef.current?.getBoundingClientRect() as DOMRect;
    if (size) {
      const tempBounds: BoundType = {
        left: 0,
        right: size.width,
        top: 0,
        bottom: size.height,
      };
      setBounds(tempBounds);
      setPageDims(size);
      //reset selectable objects
      setTarget(null);
      setEditableIndex(-1);
      setNodeToUpdate(undefined);
    }
  }, [containerRef.current]);

  useEffect(() => {
    const currentIGA: IIGA = pageContext.pageManifest?.IGSObject[currentStep];
    if (elementType === "annotations" && pageContext.pageManifest?.IGSObject[currentStep]?.annotations?.length > 0) {
      const lastAdded: number = currentIGA?.annotations ? currentIGA?.annotations?.length - 1 : -1;
      const addedID: string =
        lastAdded > -1 ? `${(currentIGA?.annotations as IAnnotation[])[lastAdded].type}-${lastAdded}` : "";
      const addedTarget: HTMLElement | SVGSVGElement = document.querySelector(`#${addedID}`) as
        | HTMLElement
        | SVGSVGElement;
      if (addedTarget) {
        addedTarget.focus();
        const dirs: string[] = getDirections(addedTarget);
        tempNode = (currentIGA.annotations as IAnnotation[])[lastAdded];
        tempNode.name = validateAnnotationName(tempNode, lastAdded);
        pbFunctions.createPropertyBox(tempNode, pageDims as DOMRect, setPropertyBox);
        setDirections(dirs);
        setIsPropertyBoxShown(true);
        setNodeToUpdate(tempNode);
        setEditableIndex(lastAdded);
        setTarget(addedTarget);
        if (!_.isEqual(tempNode, (currentIGA.annotations as IAnnotation[])[lastAdded])) {
          (updatedManifest.IGSObject[currentStep].annotations as IAnnotation[])[lastAdded] = _.cloneDeep(tempNode);
          pageContext.updatePageManifest(updatedManifest);
        }
      }
    }
  }, [pageContext.pageManifest?.IGSObject[currentStep]?.annotations?.length]);

  useEffect(() => {
    const currentIGA: IIGA = updatedManifest.IGSObject[currentStep];
    if (elementType === "symbols" && pageContext.pageManifest?.IGSObject[currentStep]?.symbols?.length > 0) {
      const lastAdded: number = pageContext.pageManifest?.IGSObject[currentStep]?.symbols?.length - 1;
      const addedID = `symbol-${lastAdded}`;
      const addedTarget: HTMLElement | SVGSVGElement = document.querySelector(`#${addedID}`) as
        | HTMLElement
        | SVGSVGElement;
      if (addedTarget) {
        addedTarget.focus();
        tempNode = (currentIGA.symbols as ISymbolV2[])[lastAdded];
        if (currentIGA.symbols)
          tempNode.name = validateSymbolName((currentIGA.symbols as ISymbolV2[])[lastAdded], lastAdded);
        pbFunctions.createPropertyBox(tempNode, pageDims as DOMRect, setPropertyBox);
        setIsPropertyBoxShown(true);
        setNodeToUpdate(tempNode);
        setEditableIndex(lastAdded);
        setTarget(addedTarget);
        if (!_.isEqual(tempNode, (currentIGA.symbols as ISymbolV2[])[lastAdded])) {
          (updatedManifest.IGSObject[currentStep].symbols as ISymbolV2[])[lastAdded] = _.cloneDeep(tempNode);
          pageContext.updatePageManifest(updatedManifest);
        }
      }
    }
  }, [pageContext.pageManifest?.IGSObject[currentStep]?.symbols?.length]);

  useEffect(() => {
    //checks if target is set. Shows property box if set
    if (_.isNull(target) || _.isUndefined(target) || isHotSpotActive) {
      setIsPropertyBoxShown(false);
    } else {
      setIsPropertyBoxShown(true);
    }
  }, [isHotSpotActive, target]);

  useEffect(() => {
    checkForIgaUpdateNotification();
  }, [pageContext.pageManifest?.IGSObject[currentStep]]);

  function addAnnotationNames() {
    _.forEach(updatedManifest.IGSObject as IIGA[], async (step, index: number) => {
      if (step.annotations && step.annotations.length > 0) {
        const notations: IAnnotation[] = new Annotation(
          step.annotations,
          "",
          -1,
          {},
          null,
          0,
        ).validateAnnotationNames();
        updatedManifest.IGSObject[index].annotations = _.cloneDeep(notations);
      }
    });
    pageContext.updatePageManifest(updatedManifest);
  }

  function convertSymbolsIfHaveStyleAttributes() {
    _.forEach(currentIGA, (step, index) => {
      if (step.symbols && (step.symbols as ISymbolV2[]).length > 0) {
        const symbol = new ClassSymbol(-1, {}, step.symbols, "", [], pageContext.getObjectIncrementor());
        const newSymbols = symbol.convertSymbolIfHasStyleAttribute();
        updatedManifest.IGSObject[index].symbols = _.cloneDeep(newSymbols);
      }
    });
  }

  const handleStepTypeSelect = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    const typeNum: number = parseInt(e.currentTarget.id);
    switch (typeNum) {
      case 1:
        addInteractive();
        break;
      case 2:
        addStandardQuiz();
        break;
      case 3:
        selectGraphicQuiz();
        break;
      default:
        break;
    }
    setTarget(null);
    setElementType("annotations");
    setEditableIndex(-1);
  };

  const handleQuestionEntry = (value: string) => {
    updatedManifest.IGSObject[currentStep].question = value;
    updatedManifest.IGSObject = _.cloneDeep(updatedManifest.IGSObject);
    setIGAObject(updatedManifest.IGSObject);
    pageContext.updatePageManifest(updatedManifest);
  };

  const handleCloseButtonPushed = (isChanged: boolean) => {
    // if(isChanged) {
    //   setIsConfirmationShown(true);
    // }else {
    handleClose();
    // }
  };

  const confirmClose = () => {
    // setIsConfirmationShown(false);
    handleClose();
  };

  const cancelDelete = () => {
    setIsDeleteConfirmShown(false);
  };

  const confirmDeleteStep = () => {
    if (updatedManifest.IGSObject.length > 1) {
      updatedManifest.IGSObject.splice(currentStep, 1);
      // copy.splice(currentStep, 1);
    } else {
      updatedManifest.IGSObject[currentStep].stepType = 0;
      // copy[currentStep].stepType = 0;
    }
    removeAsset();
    if (currentStep > updatedManifest.IGSObject.length - 1) setCurrentStep(currentStep - 1);
    setIGAObject(updatedManifest.IGSObject);
    setIsDeleteConfirmShown(false);
    pageContext.updatePageManifest(updatedManifest);
  };

  const removeAsset = () => {
    let assetToRemove = 0;
    switch (IGAObject[currentStep].stepType) {
      case interactive:
      case standard:
        assetToRemove = IGAObject[currentStep].images?.assetVersionId as number;
        pageContext.updatePageAsset(null, assetToRemove);
        break;
      case graphical:
        _.forEach(IGAObject[currentStep].choices as IIGAGraphicChoice[], (choice, index) => {
          assetToRemove = choice.image.assetVersionId as number;
          pageContext.updatePageAsset(null, assetToRemove);
        });
        break;
      default:
        break;
    }
  };

  const createNewSetAssetManagerController = (controller: { isOpen: boolean; mode: string }) => {
    const copy = _.cloneDeep(assetManagerCopy);
    copy.assetManagerController = controller;
    if (!controller.isOpen) {
      setAssetType(-1);
    }
    setAssetManagerCopy(copy);
  };

  const handleImageAssociation = (image: { assetId: number; blobPath: string }) => {
    let assetToRemove: number | undefined;
    switch (IGAObject[currentStep].stepType) {
      case 1:
      case 2:
        assetToRemove = updatedManifest.IGSObject[currentStep].images?.assetVersionId;
        updatedManifest.IGSObject[currentStep].images = {
          assetVersionId: image.assetId,
          path: image.blobPath,
          imageDimensions: blankDOMRect,
        };
        break;
      case 3:
        assetToRemove = (updatedManifest.IGSObject[currentStep].choices[graphicQuizIndex] as IIGAGraphicChoice).image
          .assetVersionId;
        updatedManifest.IGSObject[currentStep].choices[graphicQuizIndex].image = {
          assetVersionId: image.assetId,
          path: image.blobPath,
        };
        // (updatedManifest.IGSObject[currentStep].choices[graphicQuizIndex] as IIGAGraphicChoice).image  = {assetVersionId: image.assetId, path: image.blobPath};
        break;
    }
    setIGAObject(updatedManifest.IGSObject);
    // updatedManifest.IGSObject = _.cloneDeep(updatedManifest.IGSObject);
    const ids = PageTypeCrawler.getAllAssetIds(pageContext.pageType, updatedManifest);
    if (ids && ids.includes(assetToRemove!)) {
      assetToRemove = undefined;
    }
    pageContext.updatePageAsset(image.assetId, assetToRemove || null);
    pageContext.updatePageManifest(updatedManifest);
  };

  const handleStepSelect = (index: number) => {
    if (updatedManifest.IGSObject[index].stepType === 1) {
      if (updatedManifest.IGSObject[index].images.path === "") {
        setIsHotSpotActive(false);
      } else {
        setIsHotSpotActive(true);
      }
      setHotSpot((updatedManifest.IGSObject[index].images as IIGAImageHotspot).imageHotSpot as IIGAHotSpot);
    } else {
      setIsHotSpotActive(false);
    }
    if (editableIndex > -1) {
      setTarget(null);
      setNodeToUpdate(undefined);
      setEditableIndex(-1);
    }
    setCurrentStep(index);
    checkForIgaUpdateNotification();
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  function addInteractive() {
    // let copy = _.cloneDeep(IGAObject)
    if (updatedManifest.IGSObject[currentStep].stepType === 0) {
      updatedManifest.IGSObject[currentStep] = interactiveStep;
    } else if (updatedManifest.IGSObject && updatedManifest.IGSObject.length < maxAllowedSteps) {
      updatedManifest.IGSObject.push(interactiveStep);
      setCurrentStep(updatedManifest.IGSObject.length - 1);
    } else {
      return;
    }
    setIGAObject(updatedManifest.IGSObject);
    setIsTypeSelectorShown(false);
    setHotSpot(blankHotSpot);
    setIsHotSpotActive(false);
    pageContext.updatePageManifest(updatedManifest);
  }

  const addInteractiveGraphic = () => {
    if (!isHotSpotActive) {
      const copy = _.cloneDeep(assetManagerCopy);
      copy.assetManagerController = { isOpen: true, mode: "ImagesOnly" };
      copy.setAssetManagerController = () => createNewSetAssetManagerController({ isOpen: false, mode: "" });
      setAssetType(4);
      setAssetManagerCopy(copy);
    }
  };

  function addOrUPdateImageDims(imgDims: DOMRect) {
    //|| !_.isEqual(imgDims, (IGAObject[currentStep].images as IIGAImageHotspot).imageDimensions)
    if (
      (IGAObject[currentStep].version && (IGAObject[currentStep].version as number) >= currentVersion) ||
      (IGAObject[currentStep].images as IIGAImageHotspot)?.imageDimensions?.height === 0 ||
      (IGAObject[currentStep].images as IIGAImageHotspot)?.imageDimensions?.width === 0 ||
      !(IGAObject[currentStep].images as IIGAImageHotspot)?.imageDimensions
    ) {
      (updatedManifest.IGSObject[currentStep].images as IIGAImageHotspot).imageDimensions = imgDims?.toJSON();
    }
    setIGAObject(updatedManifest.IGSObject);
    // updatedManifest.IGSObject = _.cloneDeep(updatedManifest.IGSObject);
    pageContext.updatePageManifest(updatedManifest);
  }

  const handleTaskChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    updatedManifest.IGSObject[currentStep].taskDescription = e.currentTarget.value;
    setIGAObject(updatedManifest.IGSObject);
    pageContext.updatePageManifest(updatedManifest);
  };

  const handleHotspotChange = (hotspot: IIGAHotSpot, childDims: DOMRect) => {
    if (childDims.height !== (IGAObject[currentStep].images as IIGAImageHotspot)?.imageDimensions?.height) {
      (updatedManifest.IGSObject[currentStep].images as IIGAImageHotspot).imageDimensions = childDims?.toJSON();
    }

    if (hotspot.top > hotspot.bottom) {
      const { large, small } = performSwap(hotspot.top, hotspot.bottom);
      hotspot.top = small;
      hotspot.bottom = large;
    }

    if (hotspot.left > hotspot.right) {
      const { large, small } = performSwap(hotspot.left, hotspot.right);
      hotspot.left = small;
      hotspot.right = large;
    }

    (updatedManifest.IGSObject[currentStep].images as IIGAImageHotspot).imageHotSpot = hotspot;
    updatedManifest.IGSObject[currentStep].version = currentVersion;
    setIGAObject(updatedManifest.IGSObject);
    pageContext.updatePageManifest(updatedManifest);
  };

  const performSwap = (larger: number, smaller: number) => {
    let newSmall: number = smaller;
    let newLarge: number = larger;
    const temp: number = newSmall;
    newSmall = newLarge;
    newLarge = temp;
    return { large: newSmall, small: newLarge };
  };

  const displayAddOrEdit = () => {
    if (updatedManifest.IGSObject[currentStep].stepType === 1) {
      if (
        !updatedManifest.IGSObject[currentStep].images ||
        updatedManifest.IGSObject[currentStep].images?.path?.length === 0
      ) {
        return <></>;
      } else if (isHotSpotActive) {
        return (
          <button id="close-hotspot-btn" onClick={() => setIsHotSpotActive(false)}>
            <span>Edit Image</span>
          </button>
        );
      } else {
        return (
          <button id="add-hotspot-btn" onClick={() => setIsHotSpotActive(true)}>
            <span className="icon-Add-icon">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
            </span>
            <span>Add Hotspot</span>
          </button>
        );
      }
    }
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const addStandardQuiz = () => {
    // let copy = _.cloneDeep(IGAObject);
    if (updatedManifest.IGSObject[currentStep].stepType === 0) {
      updatedManifest.IGSObject[currentStep] = standardQuizStep;
    } else if (IGAObject && IGAObject.length < maxAllowedSteps) {
      updatedManifest.IGSObject.push(standardQuizStep);
      setCurrentStep(updatedManifest.IGSObject.length - 1);
    } else {
      return;
    }
    setIGAObject(updatedManifest.IGSObject);
    setIsTypeSelectorShown(false);
    // setTarget(null);
    // setEditableIndex(-1);
    pageContext.updatePageManifest(updatedManifest);
  };

  const addStandardQuizGraphic = async () => {
    // let image: any = await AssetManagerWrapper.show(assetContext.setAssetManagerController, "References");
    // let newImage: {path: string, assetVersionId: number, imageHotSpot: null} = {path: image.blobPath, assetVersionId: image.assetId, imageHotSpot: null};
    // updatedManifest.IGSObject[currentStep].images = newImage;
    // setIGAObject(updatedManifest.IGSObject);
    const copy = _.cloneDeep(assetManagerCopy);
    copy.assetManagerController = { isOpen: true, mode: "ImagesOnly" };
    copy.setAssetManagerController = () => createNewSetAssetManagerController({ isOpen: false, mode: "" });
    setAssetType(4);
    setAssetManagerCopy(copy);
  };

  const markStandardCorrect = (index: number) => {
    _.forEach(updatedManifest.IGSObject[currentStep].choices as IIGATextChoice[], (choice, cIndex) => {
      if (index !== cIndex && choice.isCorrect) {
        updatedManifest.IGSObject[currentStep].choices[cIndex].isCorrect = false;
      }
    });

    updatedManifest.IGSObject[currentStep].choices[index].isCorrect = true;
    updatedManifest.IGSObject = _.cloneDeep(updatedManifest.IGSObject);
    pageContext.updatePageManifest(updatedManifest);
    setSelectedAnswer(index);
    setIGAObject(updatedManifest.IGSObject);
  };

  const handleAnswerChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
    index: number,
  ) => {
    e.preventDefault();
    let input = e.currentTarget.value;
    if (input.length > 60) {
      input = input.substr(0, 60);
      //trigger portal
      setPopUpWarning(true);
    }

    const object: IIGATextChoice = _.cloneDeep(updatedManifest.IGSObject[currentStep].choices[index]) as IIGATextChoice;
    object.text = input;
    updatedManifest.IGSObject[currentStep].choices[index] = _.cloneDeep(object);
    updatedManifest.IGSObject = _.cloneDeep(updatedManifest.IGSObject);
    setIGAObject(updatedManifest.IGSObject);
    pageContext.updatePageManifest(updatedManifest);
  };

  const addQuizAnswer = () => {
    const choices: IIGATextChoice[] = _.cloneDeep(IGAObject[currentStep].choices) as IIGATextChoice[];
    choices.push({ text: "", isCorrect: false });
    updatedManifest.IGSObject[currentStep].choices = _.cloneDeep(choices);
    updatedManifest.IGSObject = _.cloneDeep(updatedManifest.IGSObject);
    pageContext.updatePageManifest(updatedManifest);
    setIGAObject(updatedManifest.IGSObject);
  };

  const removeAnswer = () => {
    const choices: IIGATextChoice[] = _.cloneDeep(IGAObject[currentStep].choices) as IIGATextChoice[];
    if (selectedAnswer < 0) {
      choices.splice(choices.length - 1, 1);
    } else {
      choices.splice(selectedAnswer, 1);
    }
    updatedManifest.IGSObject[currentStep].choices = choices;
    updatedManifest.IGSObject = _.cloneDeep(updatedManifest.IGSObject);
    pageContext.updatePageManifest(updatedManifest);
    setIGAObject(updatedManifest.IGSObject);
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const selectGraphicQuiz = () => {
    if (updatedManifest.IGSObject[currentStep].stepType === 0) {
      updatedManifest.IGSObject[currentStep] = graphicQuizStep;
    } else if (updatedManifest.IGSObject && updatedManifest.IGSObject.length < maxAllowedSteps) {
      updatedManifest.IGSObject.push(graphicQuizStep);
      setCurrentStep(updatedManifest.IGSObject.length - 1);
    } else {
      return;
    }

    _.forEach(updatedManifest.IGSObject[currentStep]?.choices as IIGAGraphicChoice[], (choice, index: number) => {
      const object: IIGAGraphicChoice[] = updatedManifest.IGSObject[currentStep].choices as IIGAGraphicChoice[];
      if (!_.has(choice, "image")) {
        object[index].image = { path: "", assetVersionId: 0 };
        updatedManifest.IGSObject[currentStep].choices = _.cloneDeep(object);
      }
    });
    setIGAObject(updatedManifest.IGSObject);
    setIsTypeSelectorShown(false);
    pageContext.updatePageManifest(updatedManifest);
  };

  const addGraphicChoice = () => {
    if (updatedManifest.IGSObject[currentStep].choices.length >= maxGraphicChoices) {
      return;
    } else {
      const object: IIGAGraphicChoice[] = updatedManifest.IGSObject[currentStep].choices as IIGAGraphicChoice[];
      object.push({ image: { path: "", assetVersionId: 0 }, isCorrect: false });
      updatedManifest.IGSObject[currentStep].choices = _.cloneDeep(object);
      setIGAObject(updatedManifest.IGSObject);
      // updatedManifest.IGSObject = _.cloneDeep(updatedManifest.IGSObject);
      pageContext.updatePageManifest(updatedManifest);
    }
  };

  const removeGraphicChoice = (e: React.MouseEvent<HTMLButtonElement>, index: number) => {
    e.stopPropagation();
    updatedManifest.IGSObject[currentStep].choices.splice(index, 1);
    setIGAObject(updatedManifest.IGSObject);
    // updatedManifest.IGSObject = _.cloneDeep(updatedManifest.IGSObject);
    pageContext.updatePageManifest(updatedManifest);
  };

  const addGraphicQuizImage = (index: number) => {
    const copy = _.cloneDeep(assetManagerCopy);
    copy.assetManagerController = { isOpen: true, mode: "ImagesOnly" };
    copy.setAssetManagerController = () => createNewSetAssetManagerController({ isOpen: false, mode: "" });
    setAssetType(4);
    setAssetManagerCopy(copy);
    setGraphicQuizIndex(index);
  };

  const markAsCorrect = (e: React.MouseEvent<HTMLDivElement>, index: number) => {
    e.stopPropagation();
    _.forEach(updatedManifest.IGSObject[currentStep].choices as IIGAGraphicChoice[], (choice, cIndex) => {
      if (index !== cIndex && choice.isCorrect) {
        updatedManifest.IGSObject[currentStep].choices[cIndex].isCorrect = false;
      }
    });

    updatedManifest.IGSObject[currentStep].choices[index].isCorrect = true;
    // updatedManifest.IGSObject = _.cloneDeep(updatedManifest.IGSObject);
    setIGAObject(updatedManifest.IGSObject);
    pageContext.updatePageManifest(updatedManifest);
  };

  function changeGraphicQuizLayout(number: number) {
    const thisStep: IIGA = updatedManifest.IGSObject[currentStep];
    const diff: number = number - thisStep.choices.length;

    if (diff === 0) {
      return;
    } else if (diff > 0) {
      for (let i = 0; i < diff; i++) {
        (thisStep.choices as IIGAGraphicChoice[]).push({
          image: { path: "", assetVersionId: 0 },
          isCorrect: false,
        });
      }
    } else {
      thisStep.choices.splice(number, thisStep.choices.length - 1);
    }
    updatedManifest.IGSObject[currentStep] = _.cloneDeep(thisStep);
    setIGAObject(updatedManifest.IGSObject);
    pageContext.updatePageManifest(updatedManifest);
  }

  function getGraphicQuizChoices() {
    const thisStep: IIGA = updatedManifest.IGSObject[currentStep];
    if (thisStep.stepType === graphical && thisStep.choices.length > 0) {
      return thisStep.choices.length;
    }
    return 2;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const annotationMenuProps = {
    attributes: nodeToUpdate as IAnnotation | ISymbolV2,
    editableIndex: editableIndex,
    elementType: elementType,
    showFontIcons: false,
    addNewAnnotation: addNewAnnotation,
    addSymbolOLD: addNewSymbol,
    handleFormatChange: handleFormatChange,
    removeElement: removeSelected,
    sendToBack: sendToBack,
  };

  function displayAnnotationMenu() {
    const thisIGA: IIGA = IGAObject[currentStep];
    if (
      (thisIGA.stepType === 1 && thisIGA?.images && thisIGA?.images?.path?.length > 0 && !isHotSpotActive) ||
      (thisIGA.stepType === 2 && thisIGA.images && thisIGA.images.path.length > 0) ||
      (thisIGA.stepType === 3 && _.some(thisIGA.choices, "image"))
    ) {
      return <IGAAnnotationMenuBar {...annotationMenuProps} />;
    }
    return <></>;
  }

  function addNewAnnotation(type: string, addFunction: any) {
    const thisIGA: IIGA = _.cloneDeep(updatedManifest.IGSObject[currentStep]);
    const objectList = new IGAObjectList(thisIGA.annotations, thisIGA.symbols);
    let incrementor = pageContext.getObjectIncrementor();
    const annotation: IAnnotation = annotationsToAdd[addFunction](
      objectList.annotations,
      type,
      editableIndex,
      bounds,
      objectList.fullList,
      incrementor,
      pageDims,
    );
    if (annotation.type === "label") annotation.width = thisIGA.stepType === standard ? 55 : 28;
    if (!_.has(thisIGA, "annotations")) {
      thisIGA.annotations = [annotation];
    } else {
      thisIGA.annotations?.push(annotation);
    }
    updatedManifest.IGSObject[currentStep] = _.cloneDeep(thisIGA);
    setEditableIndex(-1);
    setElementType("annotations");
    updatedManifest.objectIncrementor = ++incrementor;
    pageContext.updatePageManifest(updatedManifest);
  }

  function addNewSymbol(name: string, addFunction: any) {
    const thisIGA: IIGA = _.cloneDeep(updatedManifest.IGSObject[currentStep]);
    const objectList = new IGAObjectList(thisIGA.annotations, thisIGA.symbols);
    let incrementor = pageContext.getObjectIncrementor();
    const symbol = new ClassSymbol(
      editableIndex,
      bounds,
      objectList.symbols as ISymbolV2[],
      name,
      objectList.fullList,
      incrementor,
    );
    const added = symbol.add();

    if (!_.has(thisIGA, "symbols")) {
      thisIGA.symbols = [];
      annotationsState.symbols = [];
    }
    // const symbol: ISymbolV2 = annotationsToAdd[addFunction](name, annotationsState);
    (thisIGA.symbols as ISymbolV2[]).push(added);
    annotationsState.symbols.push(added);
    updatedManifest.IGSObject[currentStep] = _.cloneDeep(thisIGA);
    setEditableIndex(-1);
    setElementType("symbols");
    setNodeToUpdate(added);
    updatedManifest.objectIncrementor = ++incrementor;
    pageContext.updatePageManifest(updatedManifest);
  }

  function handleFormatChange(e: React.MouseEvent<HTMLButtonElement>, funct: any, value: any, keyValue: any) {
    const newAttributes = funct(nodeToUpdate, value, keyValue);
    updateAttributes(newAttributes);
  }

  function changeLabel(text: string, scrollWidth: number, scrollHeight: number) {
    const annotations = updateLabel(text, annotationsState, scrollHeight);
    updatedManifest.IGSObject[currentStep].annotations = _.cloneDeep(annotations);
    pageContext.updatePageManifest(updatedManifest);
  }

  function changePropertyBox(properties: IPropertyBox) {
    const temp: IPropertyBox = _.cloneDeep(properties);

    if (!_.isEqual(temp, propertyBox)) {
      setPropertyBox(temp);
    }
  }

  function updateAttributes(attributes: IAnnotation | ISymbolV2) {
    if (elementType === "annotations") {
      (updatedManifest.IGSObject[currentStep].annotations as IAnnotation[])[editableIndex] = attributes as IAnnotation;
    } else {
      (updatedManifest.IGSObject[currentStep].symbols as ISymbolV2[])[editableIndex] = attributes as ISymbolV2;
    }
    setNodeToUpdate(attributes);
    pageContext.updatePageManifest(updatedManifest);
  }

  async function updateZindex(value: string) {
    const list: IGAObjectList = new IGAObjectList(currentIGA[currentStep].annotations, currentIGA[currentStep].symbols);
    const zValue: number = parseInt(value);
    const newNode: IAnnotation | ISymbolV2 = _.cloneDeep(nodeToUpdate as IAnnotation | ISymbolV2);
    newNode.zIndex = zValue;
    const properties: IPropertyBox = _.cloneDeep(propertyBox);
    properties.zIndex = zValue;

    await list.changeTheZ(newNode, nodeToUpdate);
    updatedManifest.IGSObject[currentStep].annotations = list.annotations;
    updatedManifest.IGSObject[currentStep].symbols = list.symbols;
    setNodeToUpdate(newNode);
    setPropertyBox(properties);
    pageContext.updatePageManifest(updatedManifest);
  }

  function removeSelected() {
    // let annotations: IAnnotation[] = _.cloneDeep(IGAObject[currentStep].annotations) as IAnnotation[];
    (
      updatedManifest.IGSObject[currentStep][elementType as "annotations" | "symbols"] as IAnnotation[] | ISymbolV2[]
    ).splice(editableIndex, 1);
    // updatedManifest.IGSObject[currentStep].annotations = _.cloneDeep(annotations);
    setTarget(null);
    setEditableIndex(-1);
    setNodeToUpdate(undefined);
    pageContext.updatePageManifest(updatedManifest);
  }

  //#region [rgba(0,255,255,0.1)]
  function markSelected(target: HTMLElement | SVGSVGElement, index: number, type: ElementTypes) {
    // e.currentTarget.focus();
    // const targ = e.currentTarget.className === "ff-text-div-2" || e.currentTarget.className === "label2Div" ? e.currentTarget.parentElement : e.currentTarget;
    const currentIGA: IIGA = updatedManifest.IGSObject[currentStep];
    if (!isDragging && !isResizing && !isRotating && target) {
      const list = new IGAObjectList(currentIGA.annotations, currentIGA.symbols);
      tempNode = list.getNodeToEdit(index, type);
      if (type === "symbols")
        list.getSelectedSymbolHeight(target as SVGSVGElement, pageDims as DOMRect, tempNode as ISymbolV2);
      const dirs: string[] = getDirections(target);
      setEditableIndex(index);
      setNodeToUpdate(tempNode);
      setDirections(dirs);
      setElementType(type);
      setTarget(target);
      pbFunctions.createPropertyBox(tempNode, pageDims as DOMRect, setPropertyBox);
      setIsPropertyBoxShown(true);
      if (hasTempNodeChanged(index, type)) {
        updatedManifest.IGSObject[currentStep][type][index] = _.cloneDeep(tempNode as IAnnotation);
        pageContext.updatePageManifest(updatedManifest);
      }
    }
  }

  function hasTempNodeChanged(index: number, type: ElementTypes): boolean {
    return !_.isEqual(tempNode, updatedManifest.IGSObject[currentStep][type][index]);
  }

  //#endregion

  function endActions(target: HTMLElement | SVGElement, tempNode: any, e: MouseEvent) {
    e.stopPropagation();
    target.style.zIndex = (nodeToUpdate as IAnnotation).zIndex.toString();
    if (isDragging) setIsDragging(false);
    if (isRotating) setIsRotating(false);
    if (isResizing) setIsResizing(false);
    updateAttributes(tempNode);
    pbFunctions.createPropertyBox(tempNode, pageDims as DOMRect, setPropertyBox);
  }

  async function sendToBack() {
    const currentIGA: IIGA = updatedManifest.IGSObject[currentStep];
    const list = new IGAObjectList(currentIGA.annotations, currentIGA.symbols);
    const element: "annotations" | "symbols" = elementType as "annotations" | "symbols";
    if (
      editableIndex > -1 &&
      currentIGA &&
      currentIGA[element] &&
      (currentIGA[element] as IAnnotation[] | ISymbolV2[])[editableIndex]
    ) {
      const selected = (currentIGA[element] as IAnnotation[] | ISymbolV2[])[editableIndex];
      await list.sendSelectedBack(selected);
      if (currentIGA.annotations) updatedManifest.IGSObject[currentStep].annotations = list.annotations;
      if (currentIGA.symbols) updatedManifest.IGSObject[currentStep].symbols = list.symbols;
    }
    // const lowestZ: number = 112;
    // if(currentIGA.annotations) { currentIGA.annotations = sendAnnotationToBottomZ(nodeToUpdate as IAnnotation, currentIGA.annotations as IAnnotation[])}
    // if(currentIGA.symbols) { currentIGA.symbols = sendSymbolToBottomZ(nodeToUpdate as ISymbol, currentIGA.symbols as ISymbolV2[])}
    // if(nodeToUpdate) nodeToUpdate.zIndex = lowestZ;
    // (currentIGA.annotations as IAnnotation[])[editableIndex].zIndex = lowestZ;
    updatedManifest.IGSObject[currentStep] = _.cloneDeep(currentIGA);
    pageContext.updatePageManifest(updatedManifest);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const displayStepsOrSelector = () => {
    if (isTypeSelectorShown || (IGAObject.length === 1 && IGAObject[0].stepType === 0)) {
      return <StepTypeSelector handleStepTypeSelect={handleStepTypeSelect} />;
    } else {
      return (
        <React.Fragment>
          <IGASteps
            currentStep={currentStep}
            IGAObject={IGAObject}
            maxAllowedSteps={maxAllowedSteps}
            setIsDeleteConfirmShown={setIsDeleteConfirmShown}
            handleStepSelect={handleStepSelect}
            setIsTypeSelectorShown={setIsTypeSelectorShown}
            updatedManifest={updatedManifest}
          />
          <div className="iga-annotation-menu wysiwyg-toolbar">{displayAnnotationMenu()}</div>
        </React.Fragment>
      );
    }
  };

  const displayIGAorIGS = () => {
    const interactiveProps = {
      annotationProps: annotationProps,
      containerRef: containerRef,
      currentIGAStep: IGAObject[currentStep],
      currentImagePath: IGAObject[currentStep].images?.path,
      currentStep: currentStep,
      imgRef: imgRef,
      isHotSpotActive: isHotSpotActive,
      moveableProps: moveableProps,
      question: IGAObject[currentStep].taskDescription,
      addInteractiveGraphic: addInteractiveGraphic,
      addOrUPdateImageDims: addOrUPdateImageDims,
      handleHotspotChange: handleHotspotChange,
      handleTaskChange: handleTaskChange,
      setHotSpot: setHotSpot,
    };
    //image dimension checking is a result of import tool data validation
    if (_.has(IGAObject[currentStep], "version") && (IGAObject[currentStep]?.version as number) >= currentVersion) {
      return (
        <IGAInteractiveGraphic
          {...interactiveProps}
          hotSpot={hotSpot}
          setIsHotSpotActive={setIsHotSpotActive}
          updatedManifest={updatedManifest}
          pageContext={pageContext}
          currentStep={currentStep}
        />
      );
    } else {
      return <IGSInteractive {...interactiveProps} currentStep={currentStep} />;
    }
  };

  const displayCurrentIGA = () => {
    switch (updatedManifest.IGSObject[currentStep].stepType) {
      case 1:
        return <div className={`iga-content-container interactive-graphic-container`}>{displayIGAorIGS()}</div>;
      case 2:
        return (
          <div className="iga-standard-quiz-wrapper">
            <div className={`iga-content-container iga-single-graphic`} id="iga-graphic-wrapper">
              <IGAStandardQuiz
                annotationProps={annotationProps}
                choices={IGAObject[currentStep].choices as IIGATextChoice[]}
                containerRef={containerRef}
                currentImagePath={IGAObject[currentStep].images?.path}
                maxStandardChoices={maxStandardChoices}
                moveableProps={moveableProps}
                question={IGAObject[currentStep].question}
                addQuizAnswer={addQuizAnswer}
                addStandardQuizGraphic={addStandardQuizGraphic}
                handleAnswerChange={handleAnswerChange}
                handleQuestionEntry={handleQuestionEntry}
                markStandardCorrect={markStandardCorrect}
                removeAnswer={removeAnswer}
                setSelectedAnswer={setSelectedAnswer}
                warning={popUpWarning}
                updatedManifest={updatedManifest}
                pageContext={pageContext}
                currentStep={currentStep}
              />
            </div>
          </div>
        );
      case 3:
        return (
          <div className={`iga-content-container iga-graphic-quiz-container`}>
            <IGAGraphicQuiz
              annotationProps={annotationProps}
              choiceCount={updatedManifest.IGSObject[currentStep]?.choices.length}
              choices={updatedManifest.IGSObject[currentStep]?.choices as IIGAGraphicChoice[]}
              containerRef={containerRef}
              moveableProps={moveableProps}
              question={updatedManifest.IGSObject[currentStep]?.question}
              addGraphicChoice={addGraphicChoice}
              addGraphicQuizImage={addGraphicQuizImage}
              handleQuestionEntry={handleQuestionEntry}
              markAsCorrect={markAsCorrect}
              removeChoice={removeGraphicChoice}
              updatedManifest={updatedManifest}
              pageContext={pageContext}
              currentStep={currentStep}
            />
          </div>
        );
      default:
        //need default screen to show choose a type
        return;
    }
  };

  const showIgaNotification = () => {
    if (igaUpdateNotification && !isTypeSelectorShown) {
      return (
        <div className={"iga-notification-alert"}>Please click the red steps to auto-apply new software updates.</div>
      );
    }
  };

  return (
    <React.Fragment>
      <div className={`iga-stepForm`}>
        <div className="iga-stepForm-container">
          <div className="iga-stepHeader">
            <button className="formCloser" onClick={() => handleCloseButtonPushed(!_.isEqual(IGAObject, currentIGA))}>
              <span className="icon-remove-icon-white">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                <span className="path4"></span>
              </span>
            </button>
            <div className="stepLabel"> Interactive Graphic Activity</div>
          </div>
          <div id="step-selector-row">
            {showIgaNotification()}
            {displayStepsOrSelector()}
            <hr />
          </div>
          {displayCurrentIGA()}
          <div className="iga-stepFooter">
            {/* {saveBtn} */}
            {displayAddOrEdit()}
            {updatedManifest.IGSObject[currentStep].stepType === graphical ? (
              <IGAGraphicQuizLayoutButton
                selected={getGraphicQuizChoices()}
                changeGraphicQuizLayout={changeGraphicQuizLayout}
              />
            ) : (
              <></>
            )}
            <button onClick={() => confirmClose()}>Close</button>
            {/* <button onClick={() => handleCloseButtonPushed(!_.isEqual(IGAObject, currentIGA))}>Close</button> */}
            {/* <button onClick={() => handleSave(IGAObject)}>Save</button> */}
          </div>
          {/* {openSaveCheck ? <SaveCheck /> : null} */}
        </div>
      </div>
      {/* {isConfirmationShown ? <IGACloseConfirmation confirmClose={confirmClose} setIsConfirmationShown={setIsConfirmationShown}/> : <></>} */}
      {isDeleteConfirmShown ? (
        <IGADeleteStepConfirmation cancelDelete={cancelDelete} confirmDeleteStep={confirmDeleteStep} />
      ) : (
        <></>
      )}
      {assetManagerCopy && assetManagerCopy.assetManagerController.isOpen ? (
        <AssetManager {...assetManagerCopy} IGAAssetType={assetType} getIGAImage={handleImageAssociation} />
      ) : (
        <></>
      )}

      {/* </div> */}
      {isPropertyBoxShown ? <ObjectPropertyBox {...keyPressHandlers.props} /> : <></>}
    </React.Fragment>
  );
};

export default IGA;
