import React from "react";

const ListButtons = () => {
  return (
    <React.Fragment>
      <button className="ql-list wysiwyg-toolbar-btn" value="bullet">
        <span className="icon-text-bullet-points-icon"></span>
      </button>
      <button className="ql-list wysiwyg-toolbar-btn" value="ordered">
        <span className="icon-text-numbers-icon"></span>
      </button>
    </React.Fragment>
  );
};

export default ListButtons;
