import React, { useEffect, useState } from "react";
import FileCard from "./FileCard";
//import leftArrow from "../../../assets/icons/pagination-arrow-left.svg";
//import rightArrow from "../../../assets/icons/pagination-arrow-right.svg";
import { ReactComponent as LeftArrowIcon } from "../../../assets/icons/AssetManager/pagination-arrow-left.svg";
import { ReactComponent as RightArrowIcon } from "../../../assets/icons/AssetManager/pagination-arrow-right.svg";
//pagination-arrow-left

interface fileListProps {
  newFiles: any;
  uploadedAssets: any;
  pageList: number[];
  setSelectedID: (value: number) => void;
  updateAssetMetadata: (newMetaData: any, index: number) => void;
  selectedId: number;
  saveStatusMessage: string;
  saveStatusColor: string;
  assetReRender: boolean;
  saveAssetErrorMessage: string;
  getFileType: (name: string) => number;
  allowLibraryItem: boolean;
}
const FileList = (props: fileListProps) => {
  //const [fileUrls, setFileUrls] = useState<any>([]);
  //const [parentFiles, setParentFiles] = useState([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  //const fileReader = new FileReader();
  // useEffect(()=>{

  // },[props.newFiles]);

  const swapPage = (number: number) => {
    const page = currentPage + number;
    setCurrentPage(page);
  };
  const cardMap = () => {
    return props.assetReRender ? (
      <></>
    ) : (
      props.newFiles.map((file: any, index: number) =>
        currentPage === 0 ? (
          index === 0 || index === 1 ? (
            <FileCard
              key={index}
              fileIndex={index}
              file={file}
              setSelectedID={props.setSelectedID}
              selectedId={props.selectedId}
              updateAssetMetadata={props.updateAssetMetadata}
              saveAssetErrorMessage={props.saveAssetErrorMessage}
              getFileType={props.getFileType}
              allowLibraryItem={props.allowLibraryItem}
            />
          ) : (
            <></>
          )
        ) : index === currentPage * 2 || index === currentPage * 2 + 1 ? (
          <FileCard
            key={index}
            fileIndex={index}
            file={file}
            setSelectedID={props.setSelectedID}
            selectedId={props.selectedId}
            updateAssetMetadata={props.updateAssetMetadata}
            saveAssetErrorMessage={props.saveAssetErrorMessage}
            getFileType={props.getFileType}
            allowLibraryItem={props.allowLibraryItem}
          />
        ) : (
          <></>
        ),
      )
    );
  };
  const pageMap = () => {
    return props.pageList.map((page, index) =>
      index === 0 ? (
        props.pageList.length > 5 && currentPage >= 5 ? (
          <React.Fragment key={page + index}>
            <button
              className={currentPage === index ? "upload-page" : "upload-off-page"}
              key={index}
              onClick={() => {
                setCurrentPage(page);
              }}
            >
              {page + 1}
            </button>
            <p style={{ margin: "0.4rem 0 0 0" }}> &#8230; </p>
          </React.Fragment>
        ) : (
          <button
            className={currentPage === index ? "upload-page" : "upload-off-page"}
            key={index}
            onClick={() => {
              setCurrentPage(page);
            }}
          >
            {page + 1}
          </button>
        )
      ) : index <= 5 && currentPage < 4 ? (
        <button
          className={currentPage === index ? "upload-page" : "upload-off-page"}
          key={index}
          onClick={() => {
            setCurrentPage(page);
          }}
        >
          {page + 1}
        </button>
      ) : page > currentPage - 2 && page < currentPage + 2 ? ( //need ... for page 1
        <button
          className={currentPage === index ? "upload-page" : "upload-off-page"}
          key={index}
          onClick={() => {
            setCurrentPage(page);
          }}
        >
          {page + 1}
        </button>
      ) : index === props.pageList.length - 1 && props.pageList.length > 3 ? (
        <React.Fragment key={page + index}>
          <p> &#8230; </p>
          <button
            className={currentPage === index ? "upload-page" : "upload-off-page"}
            key={index}
            onClick={() => {
              setCurrentPage(page);
            }}
          >
            {page + 1}
          </button>
        </React.Fragment>
      ) : (
        <></>
      ),
    );
  };
  return (
    <div className="uploader-list">
      <div className="uploaded-header">
        <p style={{ margin: "0 0 0 1%" }}>
          <strong>Uploaded Files</strong>
        </p>
      </div>
      <div className="upload-card-list">
        {cardMap()}
        <p className={"z-2 save-status-message"} style={{ color: props.saveStatusColor }}>
          <strong>{props.saveStatusMessage}</strong>
        </p>
      </div>
      {props.newFiles.length > 2 ? (
        <div className="upload-page-list">
          <LeftArrowIcon
            className={"left-asset-page-arrow"}
            onClick={() => {
              if (currentPage !== 0) {
                swapPage(-1);
              }
            }}
          />

          {pageMap()}

          <RightArrowIcon
            className={"right-asset-page-arrow"}
            onClick={() => {
              if (currentPage !== props.pageList.length - 1) {
                swapPage(1);
              }
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default FileList;
