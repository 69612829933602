import React from "react";
import BaseTextOnly from "./BaseTextOnlyCenter";
import { IRTEModule } from "../BasePageDesigner";
import { RangeStatic } from "quill";

type BaseProps = {
  moduleRTE: IRTEModule[];
  quillRef: React.RefObject<any>;
  placeholderText: string;
  textBlockIndex: number;
  updatedManifest: any;
  assignNewText: (newText: string) => void;
  handleSelectionChange: (range: RangeStatic) => void;
};

const BaseWarning: React.FC<BaseProps> = (props: BaseProps) => {
  return (
    <React.Fragment>
      <div id="warning-wrapper">
        <div className="warning-header-container">
          <p className="warning-header">
            <span className="icon-warning-page-icon"></span>WARNING
          </p>
          <p id="warning-disclaimer">
            This lesson is set to Perform Mode. A lesson in Perform Mode is scored and users will not be able to go back
            or access the Table of Contents.
          </p>
        </div>
        <div className="warning">
          <BaseTextOnly
            assignNewText={props.assignNewText}
            moduleRTE={props.moduleRTE}
            quillRef={props.quillRef}
            placeholderText={props.placeholderText}
            textBlockIndex={props.textBlockIndex}
            updatedManifest={props.updatedManifest}
            handleSelectionChange={props.handleSelectionChange}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default BaseWarning;
