import type { Frame } from "../../types";
import { interpolateFrame } from "./interpolateFrame";

export function getFramesNearCurrentTime(frames: Frame[] | undefined, currentTime: number) {
  if (!frames) return null;
  if (frames.length === 0) return null;
  //TODO ensure frames are sorted before here
  const sortedFrames = [...frames].sort((a: any, b: any) => a.timestamp - b.timestamp);
  const framesBehind = [];
  const framesAhead = [];
  for (let i = 0; i < sortedFrames.length; i++) {
    if (sortedFrames[i].timestamp <= currentTime) {
      framesBehind.push(sortedFrames[i]);
    } else {
      framesAhead.push(sortedFrames[i]);
    }
  }

  /**  we pass in the two frames to compare, but either could be missing a property the other has, we need to handle this case
   *
   * frame 1 {
   *  timestamp: 0,
   *  x: 15,
   *  y: 12,
   *  }
   * frame 2 {
   * timestamp: 1000,
   * x: 20,
   * y: undefined, -> this is the case we need to handle, we need to go and find the next frame that has a y value and use it, for the interploation result of this frame, incurrs a performance cost, we can avoid the cost by interpolating between frame 1 and frame 3, but we need to handle the case where frame 3 is missing a property
   * }
   * frame 3 {
   * timestamp: 2000,
   * x: undefined,
   * y: 30,
   *
   *
   * now we want to find the y value at 0500
   *
   *  we need to interpolate between frame 1 and frame 2, but we need to handle the case where frame 2 is missing a property
   *  you can not interpolate between two points if one of the points is missing a property so we need to grab that property from the next frame that has it
   *
   *
   */
  const interpolatedFrame = interpolateFrame(framesBehind, framesAhead, currentTime);

  return interpolatedFrame;
}
