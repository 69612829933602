import React, { useState, useEffect, useContext, useRef } from "react";
import spinner from "../../../assets/icons/loading-sequence-blue-icon.svg";
import basicThumb from "../../../assets/icons/image-to-be-uploaded.svg";
import basicPdf from "../../../assets/icons/AssetManager/uploading-file-pdf-icon.svg";
import basicVid from "../../../assets/icons/AssetManager/uploading-file-video-icon.svg";
import brokenImage from "../../../assets/icons/AssetManager/broken-image-icon.svg";
import { ReactComponent as AddIcon } from "../../../assets/icons/add-tag-icon.svg";
import removeIcon from "../../../assets/icons/Common/remove-tag-icon.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/HeaderIcons/drop-down.svg";
//add-tag-icon
import UploaderOptionFields from "./UploaderOptionFields";
import blobUrlFormatHelper from "../../blobUrlFormatHelper";
//import {IUploadManagerContext, UploadManagerContext, AircraftForLesson, fileListObject,uploadedFileObject, IAssetMetaData } from "../interfaces/uploaderInterfaces";
import { replaceInvalidChars } from "../../../utils/Strings";
import lodash, { includes } from "lodash";
import { IUploadManagerContext, UploadManagerContext, IAssetMetaData, ITag } from "../interfaces/uploaderInterfaces";
import { useAssetManagerDispatch } from "../AssetManagerContext/AssetManagerProvider";
import { UploaderContext } from "../../UploaderModal/uploaderContext";
import { fileTooBigCheckMessage, IsFileTooBig } from "../AssetManagerUtils/UploaderFunctions";
interface fileDisplayprops {
  file: any;
  fileIndex: number;
  setSelectedID: (value: number) => void;
  updateAssetMetadata: (newMetaData: any, index: number) => void;
  selectedId: number;
  saveAssetErrorMessage: string;
  getFileType: (name: string) => number;
  allowLibraryItem: boolean;
}
/**
 * 
  assetVersionId: number,
  manufacturerId: string[],
  aircraftId: number[],
  aircraftFamilyId: string[],
  configurationId: string[],
  name: string,
  description: string,
  isLibrary: boolean,
  tags: string[]
 */
const FileCard = (props: fileDisplayprops) => {
  const uploadManagerContext: IUploadManagerContext = useContext<IUploadManagerContext>(UploadManagerContext);
  //const {assetLibraryDisplayedAssetType,assetLibraryFilters,retrievedData,currentSelectedAsset} = useAssetManagerState();
  const newFiles = uploadManagerContext.newFiles;
  const assetManagerDispatch = useAssetManagerDispatch();
  const [optionsShown, setOptionsShown] = useState<boolean>(false);
  //const [tagList, setTagList] = useState<any>([]);
  const [descriptionError, setDescriptionError] = useState<string>("");
  const [sizeTooBigError, setSizeTooBigError] = useState<string>("");
  const [assetSelected, setAssetSelected] = useState<boolean>(false);
  const [thumbnail, changeThumbnail] = useState<any>(basicThumb);
  const [uploadData, setUploadData] = useState<IAssetMetaData>({
    assetVersionId: -2,
    manufacturerId: [-1],
    aircraftId: [-1],
    aircraftFamilyId: [-1],
    configurationId: [],
    name: "",
    description: "",
    isLibrary: false,
    LmsKey: null,
    tags: [],
  });
  const fileCardRef = useRef<HTMLDivElement>(null);

  //need to differentiate assets
  const addTag = () => {
    const dataClone = lodash.cloneDeep(uploadData);
    if (dataClone.tags) {
      dataClone.tags.push({ key: "" });
      setUploadData(dataClone);
    }
  };
  const removeTag = (tagIndex: number) => {
    if (uploadData.assetVersionId !== -2) {
      const dataClone = lodash.cloneDeep(uploadData);
      const newTags = dataClone.tags.filter((tag: any, index: number) => {
        return index !== tagIndex;
      });
      dataClone.tags = newTags;
      updateUploadData("tags", dataClone.tags);
    }
  };
  const tagDropDown = () => {
    if (uploadData.assetVersionId !== -2) {
      const dataClone = lodash.cloneDeep(uploadData);
      if (dataClone.tags && dataClone.tags.length === 0) {
        dataClone.tags.push({ key: "" });
        updateUploadData("tags", dataClone.tags);
      }
      setOptionsShown(!optionsShown);
    }
  };
  const getThumbURL = () => {
    const fileName = props.file.file.name.toLowerCase();

    switch (fileName) {
      case fileName.includes(".pdf"): {
        changeThumbnail(basicPdf);
        break;
      }
      case fileName.includes(".mp4"): {
        changeThumbnail(basicVid);
        break;
      }
      case fileName.includes(".mp3"): {
        changeThumbnail(basicThumb);
        break;
      }
      default: {
        if (!props.file.uploading && lodash.find(newFiles, { managerId: props.file.managerId })) {
          //changeThumbnail(blobUrlFormatHelper(lodash.find(newFiles, { managerId: props.file.managerId }).blobPath))
          const typeId = props.getFileType(props.file.file.name);
          if (typeId === -1) {
            changeThumbnail(brokenImage);
          } else {
            changeThumbnail(URL.createObjectURL(props.file.file) || brokenImage);
          }
        } else {
          changeThumbnail(URL.createObjectURL(props.file.file) || brokenImage);
        }
        break;
      }
    }
  };

  const changeTagValue = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const badstuff = /[><\u2012\u2013\u2014()@#$%&*!!':;/"]/g;
    if (uploadData.assetVersionId !== -2) {
      if (badstuff.test(event.target.value)) {
        setDescriptionError("*Please add supported characters (=,-,_,.)");
      } else {
        setDescriptionError("");
      }
      const dataClone = lodash.cloneDeep(uploadData);
      const tagKey = Object.keys(dataClone.tags[index]);
      dataClone.tags[index][tagKey[0]] = event.target.value.replace(badstuff, "");
      updateUploadData("tags", dataClone.tags);
    }
  };
  const changeTagKey = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const badstuff = /[><\u2012\u2013\u2014()@#$%&*!!':;/"]/g;
    if (uploadData.assetVersionId !== -2) {
      if (badstuff.test(event.target.value)) {
        setDescriptionError("*Please add supported characters (=,-,_,.)");
      } else {
        setDescriptionError("");
      }
      const dataClone = lodash.cloneDeep(uploadData);
      const tagKey = Object.keys(dataClone.tags[index]);
      const tagValue = dataClone.tags[index][tagKey[0]];
      const newVarName = event.target.value.replace(badstuff, "");
      dataClone.tags[index] = { [newVarName]: tagValue };
      updateUploadData("tags", dataClone.tags);
    }
  };

  const mouseOverEvent = () => {
    if (props.selectedId === -1) {
      props.setSelectedID(props.file.managerId);
    }
  };
  const mouseOutEvent = () => {
    if (props.selectedId === props.file.managerId && assetSelected === false) {
      props.setSelectedID(-1);
    }
  };
  const updateUploadData = (name: string, value: string | string[] | number | number[] | boolean | ITag[]) => {
    const asset = lodash.find(newFiles, { managerId: props.file.managerId });
    const indexValue = lodash.findIndex(newFiles, asset);
    const newData = uploadData;
    if (newData.assetVersionId !== -2) {
      newData[name] = value;
      setUploadData(newData);
      props.updateAssetMetadata(newData, indexValue);
    }
  };
  const inputTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const badstuff = /[><\u2012\u2013\u2014()@#$%&*!!':;/"]/g;
    if (badstuff.test(event.target.value)) {
      event.target.value = event.target.value.replace(badstuff, "");
      setDescriptionError("*Please add supported characters (=,-,_,.)");
    } else {
      setDescriptionError("");
    }
    if (event.target.value === "") {
      setDescriptionError("*The description cannot be empty");
    }

    updateUploadData(event.target.name, event.target.value.replace(badstuff, ""));
  };
  const metaDataTextFieldChange = (name: string, value: string) => {
    if (value !== "None" && value !== "-1" && value !== null && value !== "") {
      updateUploadData(name, [value.length && typeof value !== "string" ? parseInt(value[0]) : parseInt(value)]);
    } else {
      updateUploadData(name, []);
    }
  };
  const inputSelectFieldChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    if (
      event.target.value !== "None" &&
      event.target.value !== "-1" &&
      event.target.value !== null &&
      event.target.value !== ""
    ) {
      updateUploadData(event.target.name, [
        event.target.value.length && typeof event.target.value !== "string"
          ? parseInt(event.target.value[0])
          : parseInt(event.target.value),
      ]);
    } else {
      updateUploadData(event.target.name, []);
    }
  };
  const inputCheckBoxChange = (event: React.MouseEvent) => {
    const checkValue = !uploadData.isLibrary;
    updateUploadData("isLibrary", checkValue);
  };

  const imageThumbnail = () => {
    return (
      <span className="uploader-tumbnail-contrainer">
        <img src={thumbnail} className="img-upload-thumbnail" alt=""></img>
      </span>
    );
  };

  useEffect(() => {
    getThumbURL();
    if (IsFileTooBig(props.file.file)) setSizeTooBigError(fileTooBigCheckMessage(props.file.file));

    const getUploadData = () => {
      if (props.file.uploading === false && uploadData.assetVersionId === -2) {
        const assetMetaData = lodash.find(newFiles, {
          managerId: props.file.managerId,
        });
        if (
          assetMetaData &&
          uploadManagerContext.tailoredForLms &&
          uploadManagerContext.tailoredForLms.length === 0 &&
          uploadManagerContext.isCpatUser
        ) {
          //assetMetaData.dataBaseObject.isLibrary = false; // this caused
          getThumbURL();
          setUploadData(assetMetaData.dataBaseObject);
        } else if (assetMetaData) {
          setUploadData(assetMetaData.dataBaseObject);
        }
      }
    };

    if (props.file.uploading === false && uploadManagerContext.newFiles.length) {
      getUploadData();
    }

    const selectFileId = (e: any) => {
      if (fileCardRef.current && fileCardRef?.current?.contains(e.target)) {
        // inside click
        if (props.selectedId === -1 || props.selectedId !== props.file.managerId) {
          props.setSelectedID(props.file.managerId);
        }
        return;
      }
      // outside click
      if (props.selectedId === props.file.managerId) {
        props.setSelectedID(-1);
      }
    };
    // add when mounted
    document.addEventListener("mousedown", selectFileId);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", selectFileId);
    };
  }, []);
  useEffect(() => {
    const getUploadData = () => {
      if (props.file.uploading === true && uploadData.assetVersionId === -2) {
        const assetMetaData = lodash.find(newFiles, {
          managerId: props.file.managerId,
        });
        if (
          assetMetaData &&
          uploadManagerContext.tailoredForLms &&
          uploadManagerContext.tailoredForLms.length === 0 &&
          uploadManagerContext.isCpatUser
        ) {
          if (!assetMetaData.metaDataIsDirty) {
            assetMetaData.dataBaseObject.isLibrary = true;
          }
          getThumbURL();
          setUploadData(assetMetaData.dataBaseObject);
        } else if (assetMetaData) {
          setUploadData(assetMetaData.dataBaseObject);
        }
      }
    };

    if (props.file.uploading === true && uploadManagerContext.newFiles.length) {
      getUploadData();
    }
  }, [props.file.uploading, uploadManagerContext.newFiles.length]);
  useEffect(() => {
    if (props.file.updateFailed) {
      if (props.saveAssetErrorMessage === "*Some files can't be saved due to a file issue, please try again later.") {
        changeThumbnail(brokenImage);
      }
    }
  }, [props.file.updateFailed, props.saveAssetErrorMessage]);

  const fileMessage = () => {
    if (descriptionError.length > 0) {
      return (
        <div style={{ width: "100%", margin: 0, padding: 0 }}>
          {props.selectedId === props.file.managerId ? (
            <p
              style={{
                color: "#EF9B9B",
                fontStyle: "italic",
                margin: "0",
                marginLeft: "20%",
              }}
            >
              {descriptionError}
            </p>
          ) : (
            <p
              style={{
                color: "#DF1515",
                fontStyle: "italic",
                margin: "0",
                marginLeft: "20%",
              }}
            >
              {descriptionError}
            </p>
          )}
        </div>
      );
    }
    if (sizeTooBigError.length > 0) {
      return (
        <div style={{ width: "100%", margin: 0, padding: 0 }}>
          {props.selectedId === props.file.managerId ? (
            <p
              style={{
                color: "#EF9B9B",
                fontWeight: "bold",
                margin: "0",
                marginLeft: "20%",
              }}
            >
              {sizeTooBigError}
            </p>
          ) : (
            <p
              style={{
                color: "#DF1515",
                fontWeight: "bold",
                margin: "0",
                marginLeft: "20%",
              }}
            >
              {sizeTooBigError}
            </p>
          )}
        </div>
      );
    }
    return null;
  };
  return (
    <div
      className={
        props.file.uploading
          ? "z-1 file-list-element-uploading"
          : props.selectedId === props.file.managerId
          ? `z-1 selected-file file-list-element ${
              lodash.find(uploadManagerContext.newFiles, {
                managerId: props.file.managerId,
              })?.updateFailed
                ? "half-opacity"
                : ""
            }`
          : `z-1 file-list-element ${
              lodash.find(uploadManagerContext.newFiles, {
                managerId: props.file.managerId,
              })?.updateFailed
                ? "half-opacity"
                : ""
            }`
      }
      onMouseOver={mouseOverEvent}
      onMouseOut={mouseOutEvent}
      ref={fileCardRef}
    >
      {!props.file.uploading ? <></> : <img src={spinner} className="spinner z-2" alt="" />}
      <div className="file-card-body">
        <div className="remove-asset-container">
          <img
            src={removeIcon}
            className="remove-asset-button"
            onClick={() => {
              uploadManagerContext.removeAsset(props.file.managerId);
            }}
          />
        </div>
        <div className="file-card-image-container">
          {uploadManagerContext.isCpatUser === false ||
          uploadManagerContext.isCpatUser === undefined ||
          !props.allowLibraryItem ? (
            <div className="non-cpat-placeholder"></div>
          ) : (
            <>
              <div className="share-asset-container">
                <span className="asset-check-container" onClick={inputCheckBoxChange}>
                  <input className="asset-check-input" type="checkBox" checked={uploadData.isLibrary}></input>
                  <span className="asset-cursor-checkmark shaded"></span>
                </span>
                <p className="share-label-uploader">Share</p>
              </div>
            </>
          )}
          {imageThumbnail()}
        </div>
        <div className="file-card-meta-container">
          {uploadData.assetVersionId !== -2 ? (
            <>
              <p style={{ marginBottom: "0" }}>Metadata:</p>
              <UploaderOptionFields
                inputSelectFieldChange={inputSelectFieldChange}
                metaDataTextFieldChange={metaDataTextFieldChange}
                updateUploadData={updateUploadData}
                assetMetaData={uploadData}
              />
            </>
          ) : (
            <>
              <div className="meta-placeholder-div"></div>
            </>
          )}

          <div className="description-field">
            <label className="asset-name-label">
              File Name:
              <br />
              <input
                disabled
                className="file-card-file-name"
                placeholder={"Enter File Name"}
                defaultValue={
                  uploadData.assetVersionId === -2
                    ? replaceInvalidChars(props.file.file.name)
                    : replaceInvalidChars(uploadData.name)
                }
                onChange={inputTextFieldChange}
              ></input>
            </label>

            <label className="asset-description-label">
              Description:
              <br />
              <input
                name="description"
                className={descriptionError.length > 0 ? "file-card-description-error" : "file-card-description"}
                placeholder={"Enter File Desctiption"}
                maxLength={299}
                value={uploadData.assetVersionId === -2 ? props.file.file.name : uploadData.description}
                onChange={inputTextFieldChange}
              ></input>
            </label>
          </div>
        </div>
      </div>
      {fileMessage()}

      <div className="asset-card-tags-bar">
        <p className="asset-tags-header">Asset Tags</p>
        <button
          className="asset-tags-button"
          onClick={tagDropDown}
          style={{
            transform: optionsShown ? "rotate(180deg)" : "rotate(0deg)",
          }}
        >
          <ArrowIcon
            className={props.selectedId === props.file.managerId ? "selected-uploader-arrow" : "uploader-tag-arrow"}
          />
        </button>
      </div>

      {optionsShown ? (
        <div>
          <div className="tag-container">
            {uploadData.tags?.map((tag: any, index: number) => (
              <div className="tag-input" key={index}>
                <label className="tag-label">
                  Tag Name:
                  <input
                    //disabled
                    name="key"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      changeTagKey(event, index);
                    }}
                    value={Object.keys(tag)[0] || ""}
                    className={descriptionError.length > 0 ? "tag-input-field error-field " : "tag-input-field"}
                  ></input>
                </label>
                <label className="tag-label">
                  Description:
                  <input
                    name="value"
                    className={descriptionError.length > 0 ? "tag-input-field error-field " : "tag-input-field"}
                    value={tag[Object.keys(tag)[0]] || ""}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      changeTagValue(event, index);
                    }}
                  ></input>
                </label>
                <img
                  src={removeIcon}
                  className="remove-tag-button"
                  onClick={() => {
                    removeTag(index);
                  }}
                />
              </div>
            ))}
            <div className="add-button-div">
              <AddIcon className="add-tag-button" onClick={addTag} />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default FileCard;
