import React from "react";

interface Props {
  disabled: boolean;
  checked: boolean;
  onClick: (args: any) => void;
  description: string;
}
export const Checkbox = ({ disabled, onClick, checked, description }: Props) => {
  return (
    <div>
      <input type="checkbox" disabled={disabled} checked={checked} onClick={onClick} />
      <p className={disabled ? "disabled" : ""}>{description}</p>
    </div>
  );
};
