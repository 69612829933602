import React, { useLayoutEffect } from "react";
import Moveable from "../react-moveable";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../contexts/ObjectsProvider";
import { useTimeline } from "../../contexts/TimelineProvider/TimelineProvider";
import {
  SelectedObjectActionTypes,
  useSelectedObjectDispatch,
} from "../../contexts/SelectedObjectProvider/SelectedObjectProvider";
import { getClipObject } from "./lib/ables/cropFunctions";
import { createLogger } from "../../utils";
import { useMovableElementsPlaneState } from "../../contexts/MovableElementsPlaneProvider";
import CropRotatable from "./lib/ables/CropRotatable";
import CropResizable from "./lib/ables/CropResizable";
import CropScalable from "./lib/ables/CropScalable";

const log = createLogger("AbstractMoveable", { color: "blue" });
const targetIdMap = new WeakMap();

export function AbstractMoveableCropping({ pageDims }: any) {
  const objectsState = useObjectsState();
  const { viewportDOMElementHeight, viewportDOMElementWidth } = useMovableElementsPlaneState();
  const objectsDispatch = useObjectsDispatch();
  const selectedObjectDispatch = useSelectedObjectDispatch();
  const [tl] = useTimeline();
  // mapping objects, expensive. consider memoizing
  const guides = objectsState.objectList.map((object) => {
    return `[data-objectid="${object.objectId}"]`;
  });
  const selected = objectsState.selectedObjects.map((object) => {
    return `[data-objectid="${object.objectId}"]`;
  });
  const moveableRef = React.useRef<any>(null);

  useLayoutEffect(() => {
    if (moveableRef.current) {
      moveableRef.current.updateRect();
    }
  });

  const hasClipCoordinates = (object: any) => {
    if (object && ("clipPath" in object || "clipPathString" in object)) {
      if (object.clipPathString) {
        return true;
      } else if (object.clipPath) {
        return !Object.values(object.clipPath).every((value) => value === 0);
      }
    }
    return false;
  };
  const isCroppedImage = hasClipCoordinates(objectsState.selectedObjects[0]);
  const className = (isCroppedImage: boolean, isDisplayed: boolean, grayBorderIsOn: boolean) => {
    return (
      `target ${isCroppedImage && !objectsState.isCropping ? "moveable-control-clipped" : ""}` +
      `${typeof isDisplayed === "undefined" || isDisplayed ? "" : "target-not-displayed"} ${grayBorderIsOn}`
    );
  };
  const renderDirections =
    objectsState.selectedObjects[0]?.type?.includes("Arrow") || objectsState.selectedObjects[0]?.type?.includes("Line")
      ? ["e", "w"]
      : ["nw", "n", "ne", "e", "sw", "s", "se", "w"];
  const focusedElement = document.activeElement?.id?.toString();
  const propertyBoxInput = focusedElement?.id;
  return (
    <Moveable
      target={selected}
      ref={(ref) => {
        moveableRef.current = ref;
        if (ref) {
          objectsDispatch({
            type: ObjectActionsType.SET_MOVEABLE_OBJECT_REF,
            moveableRef: ref,
          });
        }
      }}
      renderDirections={renderDirections}
      checkInput
      resizable={!isCroppedImage}
      throttleResize={0}
      draggable={true}
      scalable={false}
      rotatable={
        objectsState.selectedObjects[0]?.type !== "SCORM" && objectsState.selectedObjects[0]?.type !== "video"
          ? true
          : false
      }
      snappable={true}
      throttleResize={0}
      elementGuidelines={guides}
      triggerAblesSimultaneously={false}
      clipTargetBounds
      clippable={objectsState.isCropping}
      // cropresizable={isCroppedImage}
      cropscalable={isCroppedImage}
      ables={isCroppedImage ? [CropResizable, CropRotatable] : []}
      props={
        {
          // cropresizable: isCroppedImage,
        }
      }
      clipRelative
      keepRatio={objectsState.selectedObjects[0]?.lockAspectRatio ?? false}
      originRelative={true}
      // originDraggable={true}
      stopPropagation={true}
      className={className(isCroppedImage, true, false)}
      onRenderStart={({ inputEvent }) => {
        if (inputEvent) {
          inputEvent.stopPropagation();
        }
      }}
      // onDragOrigin={({ target, transformOrigin, afterTransform, datas }) => {
      //   target.style.transformOrigin = transformOrigin;
      //   target.style.transform = afterTransform;
      //   datas.transformOrigin = transformOrigin;
      // }}
      // onDragOriginEnd={({ target, datas }) => {
      //   if (!target.dataset.objectid) return;
      //
      //   objectsDispatch({
      //     type: ObjectActionsType.UPDATE_TRANSFORM_ORIGIN,
      //     payload: {
      //       transformOrigin: datas.transformOrigin,
      //       objectId: target.dataset.objectid,
      //     },
      //   });
      // }}
      onRotateStart={({ target, clientX, clientY, datas }) => {
        // setFixedPosition([1500, 648]);
      }}
      onRotate={({ target, rotate, transform, datas }) => {
        datas.rotation = rotate;
        target.style.transform = transform;
        selectedObjectDispatch({
          type: SelectedObjectActionTypes.SET_ROTATION,
          payload: rotate,
        });
      }}
      onRotateEnd={({ datas }) => {
        if (typeof datas.rotation === "number") {
          objectsDispatch({
            type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
            payload: {
              time: tl.scrubbingCurrentTime,
              rotation: datas.rotation,
            },
          });
        }
      }}
      onResizeStart={({ target, datas, inputEvent }) => {
        datas.width = parseFloat(target.style.width);
        datas.height = parseFloat(target.style.height);
      }}
      onResize={({ target, width, height, drag, datas, delta }) => {
        target.style.width = `${width}px`;
        target.style.height = `${height}px`;
        target.style.transform = drag.transform;
        const beforeTranslate = drag.beforeTranslate;
        datas.left = beforeTranslate[0];
        datas.top = beforeTranslate[1];
        datas.width = width;
        datas.height = height;
        selectedObjectDispatch({
          type: SelectedObjectActionTypes.SET_X,
          payload: beforeTranslate[0],
        });
        selectedObjectDispatch({
          type: SelectedObjectActionTypes.SET_Y,
          payload: beforeTranslate[1],
        });
        selectedObjectDispatch({
          type: SelectedObjectActionTypes.SET_WIDTH,
          payload: width,
        });
        selectedObjectDispatch({
          type: SelectedObjectActionTypes.SET_HEIGHT,
          payload: height,
        });

        /**
         * LEGACY STUFF
         */
        /**
         * Triangle
         */
        if (target.firstChild?.nodeName === "polygon" && target.getAttribute("name") === "triangle") {
          target.firstChild.attributes.points.nodeValue = `4,${target.clientHeight - 4} ${
            (target.clientWidth - 2) / 2
          },4 ${target.clientWidth - 4},${target.clientHeight - 4}`;
        }
        /**
         * Ellipse
         */
        if ((target as any).firstChild.nodeName === "ellipse") {
          (target as any).firstChild.attributes.cx.nodeValue = `${target.clientWidth / 2}`;
          (target as any).firstChild.attributes.cy.nodeValue = `${target.clientHeight / 2}`;
          (target as any).firstChild.attributes.rx.nodeValue = `${target.clientWidth / 2 - 3}`;
          (target as any).firstChild.attributes.ry.nodeValue = `${target.clientHeight / 2 - 3}`;
        }
      }}
      onResizeEnd={({ datas }) => {
        const pixelX = datas.left;
        const pixelY = datas.top;
        const pixelWidth = datas.width;
        const pixelHeight = datas.height;

        if (typeof pixelX === "number") {
          objectsDispatch({
            type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
            payload: {
              time: tl.scrubbingCurrentTime,
              x: pixelX,
            },
          });
        }
        log("pixelY", pixelY, "datas.deltaY", datas.deltaY);
        if (typeof pixelY === "number") {
          objectsDispatch({
            type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
            payload: {
              time: tl.scrubbingCurrentTime,
              y: pixelY,
            },
          });
        }
        if (typeof pixelWidth === "number") {
          const percentWidth = (pixelWidth / (viewportDOMElementWidth ?? 1)) * 100;
          objectsDispatch({
            type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
            payload: {
              time: tl.scrubbingCurrentTime,
              width: percentWidth,
            },
          });
        }
        if (typeof pixelHeight === "number") {
          const percentHeight = (pixelHeight / (viewportDOMElementHeight ?? 1)) * 100;
          objectsDispatch({
            type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
            payload: {
              time: tl.scrubbingCurrentTime,
              height: percentHeight,
            },
          });
        }
      }}
      onDragStart={({ target, datas }) => {
        datas.deltaX = 0;
        datas.deltaY = 0;
      }}
      onDrag={({ target, datas, transform, beforeTranslate, delta }) => {
        datas.deltaX += delta[0];
        datas.deltaY += delta[1];
        target.style.transform = transform;
        datas.left = beforeTranslate[0];
        datas.top = beforeTranslate[1];
        selectedObjectDispatch({
          type: SelectedObjectActionTypes.SET_X,
          payload: datas.left,
        });
        selectedObjectDispatch({
          type: SelectedObjectActionTypes.SET_Y,
          payload: datas.top,
        });
      }}
      onDragEnd={({ target, datas }) => {
        const pixelX = datas.left;
        const pixelY = datas.top;
        if (typeof pixelX === "number" && datas.deltaX !== 0) {
          objectsDispatch({
            type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
            payload: {
              time: tl.scrubbingCurrentTime,
              x: pixelX,
            },
          });
        }
        if (typeof pixelY === "number" && datas.deltaY !== 0) {
          objectsDispatch({
            type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
            payload: {
              time: tl.scrubbingCurrentTime,
              y: pixelY,
            },
          });
        }
      }}
      onDragGroupStart={({ inputEvent, datas, targets }) => {
        inputEvent.stopPropagation();
        targets.forEach((target) => {
          targetIdMap.set(target, {});
        });
      }}
      onDragGroup={({ events, datas }) => {
        events.forEach(({ target, beforeTranslate, transform }) => {
          targetIdMap.get(target).left = beforeTranslate[0];
          targetIdMap.get(target).top = beforeTranslate[1];
          target.style.transform = transform;
        });
      }}
      onDragGroupEnd={({ datas, targets }) => {
        targets.forEach((target) => {
          const { left, top } = targetIdMap.get(target);
          objectsDispatch({
            type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
            payload: {
              time: tl.scrubbingCurrentTime,
              x: left,
              y: top,
              objectId: target.getAttribute("data-objectid") ?? undefined,
            },
          });
        });
      }}
      onClickGroup={({ targets, targetIndex }) => {
        const targetObject = objectsState.selectedObjects.find((object) => object.domRef === targets[targetIndex]);
        if (targetObject) {
          objectsDispatch({
            type: ObjectActionsType.SET_SELECTED_OBJECT,
            payload: {
              objectId: targetObject.objectId,
              domRef: targetObject.domRef,
            },
          });
        }
      }}
      onClipStart={({ inputEvent }) => {
        inputEvent.stopPropagation();
      }}
      onClip={({ target, clipStyles, clipStyle, datas }) => {
        target.style.clipPath = clipStyle;
        const { width, height } = target.getBoundingClientRect();
        const cO = getClipObject(clipStyles, width, height);
        datas.clipObject = cO;
        datas.clipStyle = clipStyle;
        const moveableState = moveableRef.current.moveable.state;
      }}
      onClipEnd={({ datas }) => {
        objectsDispatch({
          type: ObjectActionsType.SET_OBJECT_CLIP_PATH,
          payload: {
            clipPath: datas.clipObject,
            clipPathString: datas.clipStyle,
            objectId: objectsState.selectedObjects[0].objectId,
          },
        });
      }}
    />
  );
}
