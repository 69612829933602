import { IInteractiveAudio } from "../../models/IInteractiveAudio";

export enum EAudioManagerMode {
  SINGLE,
  SEQUENCE,
}

export enum EAudioManagerStates {
  PLAYING,
  PAUSED,
  STOPPED,
}

export enum EAudioManagerEvents {
  // global events
  TICK = "tick",
  UPDATE = "update",
  LOADING_START = "loading_start",
  LOADING_FINISH = "loading_finish",
  // single audio related events
  AUDIO_ADD = "audio_add",
  AUDIO_DELETE = "audio_delete",
  AUDIO_LOAD_START = "audio_load_start",
  AUDIO_LOAD_FINISH = "audio_load_finish",
  // state events
  PLAY = "play",
  PAUSE = "pause",
  RESUME = "resume",
  STOP = "stop",
  // single track state events
  TRACK_START = "track_start",
  TRACK_STOP = "track_stop",
}

export type EmitTickEvent = (eventType: EAudioManagerEvents.TICK, currentTime: number) => void;
export type EmitUpdateEvent = (eventType: EAudioManagerEvents.UPDATE, audios: IInteractiveAudio[]) => void;
export type EmitPlayEvent = (eventType: EAudioManagerEvents.PLAY) => void;
export type EmitPauseEvent = (eventType: EAudioManagerEvents.PAUSE) => void;
export type EmitResumeEvent = (eventType: EAudioManagerEvents.RESUME) => void;
export type EmitStopEvent = (eventType: EAudioManagerEvents.STOP) => void;

export type EmitAudioManagerEvent =
  | EmitPauseEvent
  | EmitPlayEvent
  | EmitTickEvent
  | EmitUpdateEvent
  | EmitStopEvent
  | EmitResumeEvent;

export type AudioManagerEvent = EAudioManagerEvents;

/**
 * Added to global manager, even if not playing
 */
export interface AudioSource extends Pick<IInteractiveAudio, "objectId" | "input"> {
  filePath?: string;
  buffer: AudioBuffer;
  source?: AudioBufferSourceNode;
}

export type LoadableAudio = Pick<IInteractiveAudio, "objectId" | "input"> & {
  start: number;
  end?: number;
  duration?: number;
  buffer?: AudioBuffer;
};

export type LoadedAudio = Pick<IInteractiveAudio, "objectId" | "input"> & {
  start: number;
  end: number;
  duration: number;
  buffer: AudioBuffer;
};

/**
 * The version of the audio config after being loaded
 */
export interface Audio extends AudioSource {
  start: number;
  duration: number;
  end: number;
}

export type AudioSequence = {
  objectId: string;
  start: number;
  end?: number;
};

export type ScheduledAudioSequence = Audio[];

export type AudioInputTypes = string | File | Blob;

export interface ExportedAudio {
  blob: Blob;
  url: string;
  element: HTMLAudioElement;
}

// Method Arguments

export type PlayMethod =
  | ((audioId: string, startTime?: number) => void)
  | ((audioSequence: ScheduledAudioSequence, startTime?: number) => void);
