import React, { useState } from "react";
import removeIcon from "../../../../assets/icons/FMSIcons/Remove-gray.svg";
interface CriteriaKeyProps {
  index: number;
  keyName: string;
  openKeyMenu: (event: React.MouseEvent<HTMLDivElement>) => void;
  clearKeyMatch: (indexValue: number) => void;
}
const CriteriaKeyButton = (props: CriteriaKeyProps) => {
  const [hovering, setHovering] = useState(false);
  return (
    <span className="key-match-span">
      <button
        key={props.index}
        value={props.keyName}
        className="editor-key shaded"
        title={props.keyName}
        style={{ backgroundColor: hovering ? "#A8B8C8" : "#4B4F58" }}
      >
        <div
          onMouseOver={() => {
            setHovering(true);
          }}
          onMouseLeave={() => {
            setHovering(false);
          }}
          onClick={props.openKeyMenu}
          id={props.keyName}
          className="editor-key-text"
        >
          {props.keyName}
        </div>
        <figure
          className="remove-key-button"
          onClick={() => {
            props.clearKeyMatch(props.index);
          }}
        >
          <img src={removeIcon} className="remove-criteria-key" />
          {/* <span className="icon-remove-icon-white">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                    </span> */}
        </figure>
      </button>
    </span>
  );
};

export default CriteriaKeyButton;
