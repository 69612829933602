import { ReactComponent as FrameSVG } from "../../../../../../panels/TimelinePanel/Right/ObjectRow/Frames/FilledKeyframe.svg";
import styles from "../AdvancedPanel.module.css";

interface TimelineButtonProps {
  active: boolean;
  onClick?: () => void;
}

export function TimelineButton({ active = false, onClick }: TimelineButtonProps) {
  const className = active ? styles.active : styles.inactive;

  return (
    <button className={`${styles.frameWrapper}`} onClick={onClick}>
      <FrameSVG className={className} />
    </button>
  );
}
