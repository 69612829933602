import { StyleHTMLAttributes } from "react";

export const recordToSelect = (record: Record<string, string>, defaultOption?: string) => {
  return [
    {
      value: "",
      label: defaultOption ?? "Please select...",
    },
  ].concat(
    Object.entries(record).map(([key, value]) => ({
      value: key,
      label: value,
    })),
  );
};

export const getSpeedRateValue = (prosodyRate: number): string => {
  return ["0.50", "0.66", "0.84", "1.00", "1.33", "1.67", "2.00"][prosodyRate];
};

export const wrapSelectionWithColor = (source: string, selection: string, type: "old" | "new" = "new"): string => {
  let newSource = source;

  if (newSource.includes(selection)) {
    newSource = newSource.replace(
      selection,
      `<span class="cpat-selection cpat-selection--${type}">${selection}</span>`,
    );
  }

  return newSource;
};
