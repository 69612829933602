import React from "react";
import { SymbolComponentType } from "../helpers/getSymbol";

const CurrentHeadingPointer = (props: SymbolComponentType) => {
  const { index, attributes, transformStr } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8.56 7.34"
      key={index}
      id={`symbol-${index}`}
      tabIndex={0}
      className="symbol target"
      name="symbol"
      preserveAspectRatio="xMidYMid meet"
      style={{
        top: `${attributes.top}%`,
        left: `${attributes.left}%`,
        width: `${attributes.width}%`,
        height: `${attributes.height}%`,
        zIndex: attributes.zIndex,
        // transform:`rotate(${attributes?.transform?.rotate}deg) scaleX(${attributes?.transform?.scaleX}) scaleY(${attributes?.transform?.scaleY}) matrix3d(${attributes?.transform?.matrix3d})`
        transform: transformStr,
      }}
    >
      <path
        stroke={`${attributes.borderColor || "white"}`}
        fill={`${attributes?.backgroundColor || "transparent"}`}
        strokeWidth={`${attributes.lineWidth || 0.1}`}
        // className="heading_pointer"
        d="M 0.88,0.5 H 7.69 L 4.34,6.35 Z"
      />
    </svg>
  );
};

export default CurrentHeadingPointer;
