import React from "react";

const AnnotationCircleLetter = () => {
  return (
    <React.Fragment>
      <span className="icon-gray-circle-a"></span>
    </React.Fragment>
  );
};

export default AnnotationCircleLetter;
