import stringU from "./Strings";
export interface IStringUtils {
  replaceInvalidChars: (value: string) => string;
  lowerCaseExtension: (value: string) => string | undefined;
}
export interface ILessonDesignerUtils {
  stringU: IStringUtils;
}

export function simpleDebounce(delay: number) {
  let timer: number | null = null;
  return (fn: Function) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = window.setTimeout(() => {
      fn();
      timer = null;
    }, delay);
  };
}
const Utils: ILessonDesignerUtils = {
  stringU,
};

export function isNumber(value: any): value is number {
  return typeof value === "number";
}

export function getSVGPadding(strokeWidth: number, width: number, height: number) {
  const dimension = Math.min(width, height);
  return Math.max(strokeWidth, dimension * 0.05);
}

export function fallbackValues(...args: any[]) {
  return args.find((arg) => arg !== undefined);
}

export function objectOpacityFromRules(value = 1, visible = true, isInTime = true, isGhost = false) {
  /**
   * image is not in the timeline and the `ghost mode`
   * is activated opacity should be set to 25%
   */
  if (isGhost && !isInTime) {
    return 0.25;
  }

  /**
   * image is in the timeline and the `visible` mode is unticked
   * this changes the image in black and white, opacity should stay
   * at 100%
   */
  if (isInTime && !visible) {
    return 1;
  }

  /**
   * return value from the property panel or
   * default to 100%
   */
  return value ?? 1;
}

export default Utils;
export { stringU };
export { createLogger } from "./Logging";
export { parseStringToNumber, validation } from "./Validation";
export { conversion } from "./Conversion";
export const emptyArray: any[] = [];
