import React from "react";
import MetadataEditorContext from "./MetaDataEditorContext/MetadataEditorContext";
import MetadataEditor from "./MetadataEditor";
import ReactDOM from "react-dom";
import Asset from "./interfaces/Asset";
import ILessonMetaRequest from "../../models/ILessonMetaRequest";

interface Props {
  assets?: Asset[];
  showModal: boolean;
  lessonMeta: ILessonMetaRequest;
}
const EditorWrapper = ({ showModal, lessonMeta }: Props) => {
  return ReactDOM.createPortal(
    <div className="editor-backdrop">
      <MetadataEditorContext>{<MetadataEditor showModal={showModal} lessonMeta={lessonMeta} />}</MetadataEditorContext>
    </div>,
    document.getElementById("frontportal")!,
  );
};

export default React.memo(EditorWrapper);
