import TextOnlyIcon from "../../assets/icons/ld-basic-page-text-only-icon.png";
import TextListIcon from "../../assets/icons/ld-basic-page-text-list-icon.png";
import SplitTextLeftIcon from "../../assets/icons/ld-basic-page-split-icon.png";
import SplitTextRightIcon from "../../assets/icons/ld-basic-page-split-right.png";
import FullPageGraphicIcon from "../../assets/icons/ld-basic-page-image-only-icon.png";
import SingleGraphicCenterIcon from "../../assets/icons/ld-basic-page-center-icon.png";
import SingleGraphicLeftIcon from "../../assets/icons/ld-basic-page-left-icon.png";
import SingleGraphicRightIcon from "../../assets/icons/ld-basic-page-right-icon.png";
import FreeFormGraphic, { ReactComponent as FreeFormIcon } from "../../assets/icons/custom-page-layout-icon.svg";

//newBasePages
import { ReactComponent as SingleGraphicCenterTextAboveSVG } from "../../assets/icons/base-page-text-graphic.svg";
import SingleGraphicCenterTextAbove from "../../assets/icons/base-page-text-graphic.svg";
import DoubleGraphicSplitVertical, {
  ReactComponent as DoubleGraphicSplitVerticalSVG,
} from "../../assets/icons/base-page-graphic-graphic-horizontal.svg";

import DoubleGraphicSplitHorizontal, {
  ReactComponent as DoubleGraphicSplitHorizontalSVG,
} from "../../assets/icons/base-page-2-images.svg";

import DoubleGraphicSplitTextBelow, {
  ReactComponent as DoubleGraphicSplitTextBelowSVG,
} from "../../assets/icons/base-page-image-text-image-text.svg";

import DoubleGraphicSplitTextAbove, {
  ReactComponent as DoubleGraphicSplitTextAboveSVG,
} from "../../assets/icons/base-page-2-images-text.svg";

import TrippleGraphicSplitLeft, {
  ReactComponent as TrippleGraphicSplitLeftSVG,
} from "../../assets/icons/base-page-3-images-left.svg";

import TrippleGraphicSplitRight, {
  ReactComponent as TrippleGraphicSplitRightSVG,
} from "../../assets/icons/base-page-3-images-right.svg";

import TrippleGraphicSplitTop, {
  ReactComponent as TrippleGraphicSplitTopSVG,
} from "../../assets/icons/base-page-long-image-bottom.svg";

import TrippleGraphicSplitBottom, {
  ReactComponent as TrippleGraphicSplitBottomSVG,
} from "../../assets/icons/base-page-long-image-top.svg";

import FourGraphicSplit, { ReactComponent as FourGraphicSplitSVG } from "../../assets/icons/base-page-4-images.svg";

import FourGraphicSplitTextBelow, {
  ReactComponent as FourGraphicSplitTextBelowSVG,
} from "../../assets/icons/base-page-4-images-text.svg";

import { ReactComponent as SplitTextLeftIconSVG } from "../../assets/icons/NewPageSelector/base-page-dual-layout-icon.svg";
import { ReactComponent as TextOnlyIconSVG } from "../../assets/icons/NewPageSelector/base-page-text-only-layout-icon.svg";
import { ReactComponent as SingleGraphicCenterIconSVG } from "../../assets/icons/NewPageSelector/base-page-vertical-layout-icon.svg";
import { ReactComponent as SingleGraphicLeftIconSVG } from "../../assets/icons/NewPageSelector/base-page-image-left-layout-icon.svg";
import { ReactComponent as SingleGraphicRightIconSVG } from "../../assets/icons/NewPageSelector/base-page-image-right-layout-icon.svg";
import { ReactComponent as FullPageGraphicIconSVG } from "../../assets/icons/NewPageSelector/base-page-image-only-layout-icon.svg";

import { ISelectIconType } from "./ISelectIcon";

export interface IBasePageIconType extends ISelectIconType {
  basePageType: string;
}

//{path: string, caption: string, basePageType: string}[]

export const basePageIconInfo: IBasePageIconType[] = [
  {
    path: FreeFormGraphic,
    caption: "Custom Basic Page",
    basePageType: "freeForm",
    component: FreeFormIcon,
  },
  {
    path: TextOnlyIcon,
    caption: "Text Only",
    basePageType: "textOnly",
    component: TextOnlyIconSVG,
  },
  // {
  //   "path": TextListIcon,
  //   "caption": "Text List",
  //   "basePageType": "textList"
  // },
  {
    path: SplitTextLeftIcon,
    caption: "Split Text Left",
    basePageType: "doubleGraphicsSplit",
    component: SplitTextLeftIconSVG,
  },
  {
    path: SplitTextRightIcon,
    caption: "Split Text Right",
    basePageType: "doubleGraphicSplitRight",
    component: SplitTextLeftIconSVG,
  },
  {
    path: FullPageGraphicIcon,
    caption: "Single Graphic Full Page",
    basePageType: "fullPageGraphic",
    component: FullPageGraphicIconSVG,
  },
  {
    path: SingleGraphicCenterIcon,
    caption: "Single Graphic Center",
    basePageType: "singleGraphicCenter",
    component: SingleGraphicCenterIconSVG,
  },
  {
    path: SingleGraphicCenterTextAbove,
    caption: "Graphic Text Above",
    basePageType: "singleGraphicCenter2",
    component: SingleGraphicCenterTextAboveSVG,
  },
  {
    path: SingleGraphicLeftIcon,
    caption: "Single Graphic Left",
    basePageType: "singleGraphicLeft",
    component: SingleGraphicLeftIconSVG,
  },
  {
    path: SingleGraphicRightIcon,
    caption: "Single Graphic Right",
    basePageType: "singleGraphicRight",
    component: SingleGraphicRightIconSVG,
  },
  {
    path: DoubleGraphicSplitHorizontal,
    caption: "Double Graphic Horizontal",
    basePageType: "doubleGraphicSplitHoriz",
    component: DoubleGraphicSplitHorizontalSVG,
  },
  {
    path: DoubleGraphicSplitVertical,
    caption: "Double Graphic Vertical",
    basePageType: "doubleGraphicSplitVert",
    component: DoubleGraphicSplitVerticalSVG,
  },
  // {
  //   path: DoubleGraphicSplitTextBelow,
  //   caption: "Double Graphic Text Below",
  //   basePageType: "doubleGraphicSplitHorizTextBelow",
  //   component: DoubleGraphicSplitTextBelowSVG,
  // },
  // {
  //   path: DoubleGraphicSplitTextAbove,
  //   caption: "Double Graphic Text Above",
  //   basePageType: "doubleGraphicSplitHorizTextAbove",
  //   component: DoubleGraphicSplitTextAboveSVG,
  // },
  // {
  //   path: TrippleGraphicSplitLeft,
  //   caption: "Tripple Graphic Left",
  //   basePageType: "trippleGraphicSplitLeft",
  //   component: TrippleGraphicSplitLeftSVG,
  // },
  // {
  //   path: TrippleGraphicSplitRight,
  //   caption: "Tripple Graphic Right",
  //   basePageType: "trippleGraphicSplitRight",
  //   component: TrippleGraphicSplitRightSVG,
  // },
  {
    path: TrippleGraphicSplitTop,
    caption: "Triple Graphic Top",
    basePageType: "trippleGraphicSplitTop",
    component: TrippleGraphicSplitTopSVG,
  },
  {
    path: TrippleGraphicSplitBottom,
    caption: "Triple Graphic Bottom",
    basePageType: "trippleGraphicSplitBottom",
    component: TrippleGraphicSplitBottomSVG,
  },

  // {
  //   path: FourGraphicSplit,
  //   caption: "Four Graphics Split",
  //   basePageType: "fourGraphicSplit",
  //   component: FourGraphicSplitSVG,
  // },
  // {
  //   path: FourGraphicSplitTextBelow,
  //   caption: "Four Graphics Text",
  //   basePageType: "fourGraphicSplitText",
  //   component: FourGraphicSplitTextBelowSVG,
  // },
];
