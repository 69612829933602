import _ from "lodash";
import React, { ChangeEvent, SyntheticEvent, useCallback, useReducer, useState } from "react";
import { IPBRefs } from "../models/IObjectPropertyBox";
import InputErrorMessage from "./InputErrorMessage";

type ZIndexType = {
  pbRefs: IPBRefs;
  zIndex: number;
  updateZindex(value: string): void;
  disabled?: boolean;
};

const min = 12;
const max = 225;

const ZIndexInput = ({ pbRefs, zIndex, updateZindex, disabled }: ZIndexType) => {
  const [errorMessage, setErrorMessage]: [string | null, React.Dispatch<React.SetStateAction<string | null>>] =
    useState<string | null>(null);
  const [savedZ, setSavedZ]: [number, React.Dispatch<any>] = useReducer(getSavedZ, zIndex);
  const debounce = useCallback(_.debounce(showMessageIfInvalidValue, 500), [zIndex]);
  const minErrorMessage = `Value must be greater than ${min}`;
  const maxErrorMessage = `Value must be less than ${max}`;

  function getSavedZ(savedZ: number, action: { zIndex: number }) {
    if (action.zIndex > max || action.zIndex < min) {
      return savedZ;
    } else {
      return action.zIndex;
    }
  }

  function showMessageIfInvalidValue(value: number) {
    if (value < min) {
      setErrorMessage(minErrorMessage);
    } else if (value > max) {
      setErrorMessage(maxErrorMessage);
    } else {
      setErrorMessage(null);
    }
  }

  function changeTheNumbers(e: React.ChangeEvent) {
    const value = (e.currentTarget as HTMLInputElement).value;
    setSavedZ({ zIndex: parseInt(value) });
    updateZindex(value);
    debounce(parseInt(value));
  }

  function leaveInput(value: number) {
    if (value > max || value < min) {
      showMessageIfInvalidValue(value);
      updateZindex(savedZ.toString());
      pbRefs.obpZindex.current?.focus();
    }
  }

  return (
    <React.Fragment>
      <div style={{ width: "95px" }}>
        <label htmlFor="z-property">
          Z:
          <input
            ref={pbRefs.obpZindex}
            disabled={disabled}
            type="number"
            name="z-property"
            className="number-property z-property"
            min={min}
            max={max}
            value={zIndex}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              changeTheNumbers(e);
            }}
            // onInput={(e: SyntheticEvent<HTMLInputElement>) => debounce(parseInt(e.currentTarget.value))}
            onBlur={(e: SyntheticEvent<HTMLInputElement>) => leaveInput(parseInt(e.currentTarget.value))}
          />
          <InputErrorMessage errorMessage={errorMessage as string} />
        </label>
        {/* {errorMessage && <InputErrorMessage errorMessage={errorMessage} />} */}
      </div>
    </React.Fragment>
  );
};

export default ZIndexInput;
