import React, { useEffect, useState } from "react";
import useDebounce from "../../../hooks/useDebounce";
import { HotSpot3D } from "../hotSpotEditor/types";
import styles from "./HotspotLister.module.css";
import { ReactComponent as EditIcon } from "../../../assets/icons/HotspotLister/hotspot_position.svg";
import { ReactComponent as RemoveIcon } from "../../../assets/icons/FMSIcons/Remove.svg";
import { ReactComponent as ImageIcon } from "../../../assets/icons/HotspotLister/interactive_activity.svg";
import { ReactComponent as DragIcon } from "../../../assets/icons/HotspotLister/move-row.svg";
import { AbstractTooltip as Tooltip } from "../../../components/ToolTip/ToolTip";

type props = {
  hotspot: HotSpot3D;
  selectHotspot: () => void;
  selected: boolean;
  handleDrag: (e: React.DragEvent<HTMLDivElement>) => void;
  handleDrop: (e: React.DragEvent<HTMLDivElement>) => void;
  openIGA: () => void;
  openEditor: () => void;
  replaceHotspot: (newHS: HotSpot3D, i: number) => void;
  removeHotspot: (index: number) => void;
  hsAreOrdered: boolean;
  allowedToDelete: boolean;
};

const SingleHSDisplay = ({
  hotspot,
  selectHotspot,
  selected,
  handleDrag,
  handleDrop,
  openEditor,
  openIGA,
  replaceHotspot,
  removeHotspot,
  hsAreOrdered,
  allowedToDelete,
}: props) => {
  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;
    const hs = { ...hotspot, [name]: checked };
    replaceHotspot(hs, hs.order);
  };
  const [selectedTab, setSelectedTab] = useState<"whatToDo" | "failResponse">("whatToDo");
  const [whatToDoText, setWhaToDoText] = useState<string>(hotspot.whatToDo);
  const [failResponseText, setFailedResponseText] = useState<string>(hotspot.failResponse);
  const [hotspotName, setHotspotName] = useState<string>(hotspot.name);
  const [editHsName, setEditHsName] = useState<boolean>(false);

  // debouced values to not update manifest each keystroke
  const debouncedWTD = useDebounce(whatToDoText, 250);
  const debouncedFR = useDebounce(failResponseText, 250);
  const debouncedName = useDebounce(hotspotName, 250);

  useEffect(() => {
    const hs: HotSpot3D = {
      ...hotspot,
      whatToDo: debouncedWTD,
      failResponse: debouncedFR,
      name: debouncedName,
    };
    replaceHotspot(hs, hs.order);
  }, [debouncedWTD, debouncedFR, debouncedName]);

  useEffect(() => {
    if (!selected) setEditHsName(false);
  }, [selected]);

  return (
    <>
      <div
        id={hotspot.id}
        className={`${styles.hotspotRow} ${selected ? styles.rowHighLighted : ""}`}
        onClick={() => selectHotspot()}
      >
        <div className={styles.hotspotOrder} id={hotspot.id}>
          <div>
            <div
              /* id is necessary for dragging */
              className={styles.hotspotOrderDragDiv}
              draggable={hsAreOrdered}
              id={hotspot.id}
              onDrop={(e) => handleDrop(e)}
              onDragStart={(e) => handleDrag(e)}
              onDragOver={(e) => e.preventDefault()}
            >
              <DragIcon />
            </div>
          </div>
          <div>
            <p>{hsAreOrdered && hotspot.order}</p>
          </div>
        </div>
        <div className={styles.hotspotRowName} onClick={() => setEditHsName(selected)}>
          {!editHsName && (
            <p data-tooltip-id={hotspot.id}>
              <Tooltip id={hotspot.id} content={hotspot.name} />
              {hotspot.name}
            </p>
          )}
          {editHsName && (
            <textarea
              maxLength={150}
              rows={3}
              onBlur={() => setEditHsName(false)}
              onChange={(e) => setHotspotName(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setHotspotName(e.currentTarget.value);
                  setEditHsName(false);
                }
              }}
              value={hotspotName}
            ></textarea>
          )}
        </div>
        <div className={styles.hotspotRowCheckboxes}>
          <input
            className={styles.hotspotRowCheckRow}
            type="checkbox"
            name={"remainAfterCompletion"}
            onChange={(e) => handleCheckbox(e)}
            checked={hotspot.remainAfterCompletion}
          ></input>
          <input
            className={styles.hotspotRowCheckRow}
            type="checkbox"
            name={"isScorable"}
            onChange={(e) => handleCheckbox(e)}
            checked={hotspot.isScorable}
          ></input>
          <input
            className={styles.hotspotRowCheckRow}
            type="checkbox"
            name={"isRequired"}
            onChange={(e) => handleCheckbox(e)}
            checked={hotspot.isRequired}
          ></input>
        </div>
        <div className={styles.hotspotRowButtonsContainer}>
          <div className={styles.hotspotRowButton}>
            <EditIcon
              onClick={() => {
                selectHotspot();
                openEditor();
              }}
            >
              Open editor
            </EditIcon>
          </div>
          <div className={styles.hotspotRowButton}>
            <ImageIcon onClick={openIGA}>Open IGA</ImageIcon>
          </div>
          <div className={allowedToDelete ? "" : styles.disabledBtn}>
            <RemoveIcon onClick={() => removeHotspot(hotspot.order)} className="wysiwyg-toolbar-btn" id="remove" />
          </div>
        </div>
      </div>
      {selected && (
        <div className={styles.hotspotTextContainer}>
          <div className={styles.textContainerTabs}>
            <div
              className={`${styles.tab} ${selectedTab === "whatToDo" ? styles.selectedTab : ""}`}
              onClick={() => setSelectedTab("whatToDo")}
            >
              What to do?
            </div>
            {/*
                        <div className={`${styles.tab} ${selectedTab === "failResponse" ? styles.selectedTab : ''}`} onClick={() => setSelectedTab("failResponse")}>
                            Fail response
                        </div>
                        */}
          </div>
          {selectedTab === "whatToDo" && (
            <textarea
              name="whatToDo"
              value={whatToDoText}
              placeholder="Enter text..."
              onChange={(e) => setWhaToDoText(e.target.value)}
            ></textarea>
          )}
          {selectedTab === "failResponse" && (
            <textarea
              name="failResponse"
              placeholder="Enter text..."
              value={failResponseText}
              onChange={(e) => setFailedResponseText(e.target.value)}
            ></textarea>
          )}
        </div>
      )}
    </>
  );
};

export default SingleHSDisplay;
