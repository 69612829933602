import React, { useContext } from "react";
import { PagesManagementContext } from "../../routes/builderContexts";
import { ActiveIcon, NotActiveIcon, unavailableIcon } from "./DrawerSVGs";

export default function PageListerPanel({ currentSelected, updateArray, lessonPages, selectedPage }) {
  const pagesManagementContext = useContext(PagesManagementContext);

  const lessonModeNames = ["Demo", "Practice", "Perform"];
  let lessonModes = pagesManagementContext.lessonModes;
  let pageIndex = currentSelected - 4;
  let autoAdvance = lessonPages[pageIndex].shouldAutoPageForward;
  let scorable = lessonPages[pageIndex].isScorable;
  let lessonModeIds = lessonPages[pageIndex].lessonModeIds;

  const canScore = () => {
    if (lessonPages[pageIndex].pagePlayerType === "Base") {
      return false;
    } else {
      return true;
    }
  };

  const checkIfBasePageHasAudio = () => {
    if (lessonPages[pageIndex].pagePlayerType === "Base" || lessonPages[pageIndex].pagePlayerType === "") {
      if (lessonPages[pageIndex].pageManifest.Audio) {
        if (lessonPages[pageIndex].pageManifest.Audio.length > 0) {
          if (lessonPages[pageIndex].pageManifest.Audio[0].File) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  function lessonModeChecked(id) {
    return lessonModeIds.includes(id);
  }

  let possibleLessonModes = lessonModes.map((e) => e.lessonMode);
  function controller(e) {
    e.persist();
    console.dir(e.target);
    switch (e.target.name) {
      case "autoAdvance":
        lessonPages[pageIndex].shouldAutoPageForward = !lessonPages[pageIndex].shouldAutoPageForward;
        break;
      case "scorable":
        lessonPages[pageIndex].isScorable = !lessonPages[pageIndex].isScorable;
        break;
      default:
        if (possibleLessonModes.includes(e.target.name)) {
          lessonPages[pageIndex].lessonModeIds = [1, 2, 3];
        }
        break;
    }
    lessonPages[pageIndex].lessonPageIsDirty = true;
    updateArray(lessonPages);
  }
  return (
    // <div style={{borderBottom: "1px solid rgb(148, 156, 170)", padding: "5% 0", width: "100%"}}>
    <React.Fragment>
      <div className="lessonModes">
        <React.Fragment>
          {lessonModes.map((el, i) => {
            return (
              <div key={i} className={`option-${el.lessonMode.charAt(0)}`}>
                <div name={el.lessonMode}>
                  {lessonModeChecked(el.lessonModeId) ? (
                    <div>
                      <ActiveIcon />
                    </div>
                  ) : (
                    <div>{NotActiveIcon}</div>
                  )}
                </div>
              </div>
            );
          })}
        </React.Fragment>
      </div>
      <div className="autoReviewContainer">
        <div className="autoAdvance panel-settings">
          <div className="innerPanel">
            <div
              className="lesson-names"
              style={{
                color: checkIfBasePageHasAudio()
                  ? selectedPage
                    ? "#FFFFFF"
                    : "var(--template-color)"
                  : selectedPage
                  ? "#A5A7AB"
                  : "#D2D3D5",
                fontWeight: "normal",
              }}
            >
              Auto Advance:
            </div>
            {autoAdvance ? (
              <div>
                <ActiveIcon />
              </div>
            ) : checkIfBasePageHasAudio() ? (
              <div>{NotActiveIcon}</div>
            ) : selectedPage ? (
              <div>{unavailableIcon("#A5A7AB")}</div>
            ) : (
              <div>{unavailableIcon("#D2D3D5")}</div>
            )}
          </div>
        </div>
        <div className="reviewAidLink panel-settings">
          <div className="innerPanel">
            <div
              className="lesson-names"
              style={{
                color: canScore()
                  ? selectedPage
                    ? "#FFFFFF"
                    : "var(--template-color)"
                  : selectedPage
                  ? "#A5A7AB"
                  : "#D2D3D5",
                fontWeight: "normal",
              }}
            >
              Scorable:
            </div>
            {scorable ? (
              <div>
                <ActiveIcon />
              </div>
            ) : canScore() ? (
              <div>{NotActiveIcon}</div>
            ) : selectedPage ? (
              <div>{unavailableIcon("#A5A7AB")}</div>
            ) : (
              <div>{unavailableIcon("#D2D3D5")}</div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
    // </div>
  );
}
