import _ from "lodash";
import { BoundType } from "../../react-moveable";
import { CustomPageElement } from "../../../pageTypes/BasicPage_Player/components/BaseFreeForm/classes/CustomPageElement";
import { IAnnotation } from "../models/IAnnotation";

export class Annotation extends CustomPageElement {
  type: string;
  index: number;
  bounds: BoundType;
  fullList: any;
  annotations: IAnnotation[];

  constructor(
    annotations: IAnnotation[],
    type: string,
    index: number,
    bounds: BoundType,
    fullList: any,
    objectId: number,
  ) {
    super(annotations, `${type}${objectId}`);
    this.annotations = annotations;
    this.newObject = {
      type: type,
      ...this.newObject,
    };
    this.type = type;
    this.index = index;
    this.bounds = bounds;
    this.fullList = fullList;
  }

  add() {
    const position = this.setInitialPosition(this.fullList, this.index, this.bounds);
    this.newObject.top = position.top;
    this.newObject.left = position.left;
    this.newObject.isDisplayed = true;
    this.newObject.displayName = this.newObject.objectId;

    if (!_.isEmpty(this.fullList)) {
      this.newObject.zIndex = this.findMaxZIndex(this.fullList) + 1;
    }
    return this.newObject;
  }

  public validateAnnotationNames() {
    this.nameAnnotationsWithoutNames();
    this.updateDuplicateNames();
    this.annotations.forEach((ann) => {
      if (typeof ann.objectId === "number") {
        ann.objectId = (ann.objectId! as any).toString();
      }
    });
    return this.annotations;
  }

  protected nameAnnotationsWithoutNames() {
    const list: IAnnotation[] = _.cloneDeep(this.annotations);
    _.forEach(list, (annotation, index: number) => {
      if (!annotation.name || annotation.name === "") {
        this.annotations[index].name = `${annotation.type}-${index}`;
      }
    });
  }

  protected updateDuplicateNames() {
    // const list: IAnnotation[] = _.cloneDeep(this.annotations);
    _.forEach(this.annotations, (annotation, index: number) => {
      _.forEach(this.annotations, (notation, jindex: number) => {
        if (index !== jindex && annotation.name === notation.name) {
          this.annotations[index].name = `${annotation.name}-${index}`;
        }
      });
    });
    // return list;
  }
}
