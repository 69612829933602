import _ from "lodash";
import { IAnnotation } from "../../../../../components/Annotation/models/IAnnotation";
import { ISymbolV2 } from "../../../../../components/Symbol/models/ISymbol";
import { IBasicPageAttributes, IBasicPageImageV2, IBasicPageTextBlockV2 } from "../../IBasePage";

// const pageContext: IPageContext = useContext<IPageContext>(PageContext);

export function evaluateZPosition(
  type: string,
  node: IBasicPageAttributes | IAnnotation | ISymbolV2,
  originalNode: IBasicPageAttributes | IAnnotation | ISymbolV2,
  manifest: any,
  editableIndex: number,
) {
  const list = getAttributeList(type, manifest);
  const pm = _.cloneDeep(manifest);
  let newList: any;
  if (!_.isNull(list) && list?.length > 0) {
    if ((node.zIndex as number) > (originalNode.zIndex as number)) {
      switch (type) {
        case "annotations":
          newList = moveAnnotationHigher(list, node as IAnnotation, originalNode as IAnnotation);
          newList[editableIndex] = _.cloneDeep(node);
          pm.annotations = _.cloneDeep(newList);
          break;
        case "pageImage":
          newList = moveFFZhigher(list, node as IBasicPageAttributes, originalNode as IBasicPageAttributes);
          newList[editableIndex] = _.cloneDeep(node);
          pm.pageImage = _.cloneDeep(newList);
          break;
        case "textBlock":
          newList = moveFFZhigher(list, node as IBasicPageAttributes, originalNode as IBasicPageAttributes);
          newList[editableIndex] = _.cloneDeep(node);
          pm.textBlock = _.cloneDeep(newList);
          break;
        case "symbols":
          newList = moveSymbolsHigher(list, node as ISymbolV2, originalNode as ISymbolV2) as ISymbolV2[];
          newList[editableIndex] = _.cloneDeep(node);
          pm.symbols = _.cloneDeep(newList);
          break;
        default:
          newList = null;
          break;
      }
    } else {
      //node.zIndex < originalNode.zIndex
      switch (type) {
        case "annotations":
          newList = moveAnnotationLower(list, node as IAnnotation, originalNode as IAnnotation);
          newList[editableIndex] = _.cloneDeep(node);
          pm.annotations = _.cloneDeep(newList);
          break;
        case "pageImage":
          newList = moveFFZlower(list, node as IBasicPageAttributes, originalNode as IBasicPageAttributes);
          newList[editableIndex] = _.cloneDeep(node);
          pm.pageImage = _.cloneDeep(newList);
          break;
        case "textBlock":
          newList = moveFFZlower(list, node as IBasicPageAttributes, originalNode as IBasicPageAttributes);
          newList[editableIndex] = _.cloneDeep(node);
          pm.textBlock = _.cloneDeep(newList);
          break;
        case "symbols":
          newList = moveSymbolsLower(list, node as ISymbolV2, originalNode as ISymbolV2) as ISymbolV2[];
          newList[editableIndex] = _.cloneDeep(node);
          pm.symbols = _.cloneDeep(newList);
          break;
        default:
          newList = null;
          break;
      }
    }
    return _.cloneDeep(pm);
  } else {
    return null;
  }
}

function getAttributeList(type: string, manifest: any) {
  switch (type) {
    case "annotations":
      return _.cloneDeep(manifest.annotations);
    case "pageImage":
      return manifest.pageImage;
    case "textBlock":
      return _.cloneDeep(manifest.textBlock);
    case "symbols":
      return manifest.symbols;
    case "refAnnotations":
      return manifest.refAnnotations;
    case "refSymbols":
      return manifest.refSymbols;
    default:
      return;
  }
}

function moveFFZhigher(
  list: IBasicPageImageV2[] | IBasicPageTextBlockV2[],
  node: IBasicPageAttributes,
  originalNode: IBasicPageAttributes,
) {
  list.forEach((item: IBasicPageImageV2 | IBasicPageTextBlockV2, index: number) => {
    if (
      (item as IBasicPageImageV2).zIndex > (originalNode.zIndex as number) &&
      (item as IBasicPageImageV2).zIndex <= (node.zIndex as number)
    ) {
      list[index as number].zIndex--;
    }
  });
  return list;
}

function moveFFZlower(
  list: IBasicPageImageV2[] | IBasicPageTextBlockV2[],
  node: IBasicPageAttributes,
  originalNode: IBasicPageAttributes,
) {
  list.forEach((item: IBasicPageImageV2 | IBasicPageTextBlockV2, index: number) => {
    if (item.zIndex < (originalNode.zIndex as number) && item.zIndex >= (node.zIndex as number)) {
      list[index].zIndex++;
    }
  });
  return list;
}

function moveAnnotationHigher(list: IAnnotation[], node: IAnnotation, originalNode: IAnnotation) {
  _.forEach(list, (item, index) => {
    if (item.zIndex > originalNode.zIndex && item.zIndex <= node.zIndex) {
      list[index].zIndex--;
    }
  });

  return list;
}

function moveAnnotationLower(list: IAnnotation[], node: IAnnotation, originalNode: IAnnotation) {
  _.forEach(list, (item, index) => {
    if (item.zIndex < originalNode.zIndex && item.zIndex >= node.zIndex) {
      list[index].zIndex++;
    }
  });
  return list;
}

function moveSymbolsHigher(list: ISymbolV2[], node: ISymbolV2, originalNode: ISymbolV2) {
  _.forEach(list, (item, index) => {
    if (
      (item.zIndex as number) >= (originalNode.zIndex as number) &&
      (item.zIndex as number) <= (node.zIndex as number)
    ) {
      (list[index].zIndex as number) -= 1;
    }
  });
  return list;
}

function moveSymbolsLower(list: ISymbolV2[], node: ISymbolV2, originalNode: ISymbolV2) {
  _.forEach(list, (item, index) => {
    if (
      (item.zIndex as number) <= (originalNode.zIndex as number) &&
      (item.zIndex as number) >= (node.zIndex as number)
    ) {
      (list[index].zIndex as number) += 1;
    }
  });
  return list;
}
