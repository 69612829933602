import React from "react";

type EmptyImageType = {
  displayText: string;
  showUploadText?: boolean;
  imageOnly?: boolean;
};

const EmptyImage = (props: EmptyImageType) => {
  return (
    <React.Fragment>
      <p id="image-upload-icons">
        <span className="icon-insert-image-icon"></span>
        {!props.imageOnly ? (
          <React.Fragment>
            <span className="icon-divider-graphic"></span>
            <span className="icon-Video-icon"></span>
          </React.Fragment>
        ) : (
          <></>
        )}
      </p>
      {!props.showUploadText ? <p id="fms-image-upload-text">{props.displayText}</p> : <></>}
    </React.Fragment>
  );
};

export default EmptyImage;
