import React, { useContext } from "react";
import FmsDropDown from "./fmsDropDown";
import { Modal, Button } from "react-bootstrap";
import {
  IPageContext,
  PageContext,
  LessonPageActionsContext,
  ILessonPageActionsContext,
} from "../../../routes/builderContexts";
// import { fmsCDU } from "../../../models/IAsset";
// import { initialCDU } from "../../../pageTypes/FMS_Player/fmsDesigner";
import { FMSObject } from "../../../routes/builder";

interface fmsSelectorProps {
  pageNumber: number;
  showModal: boolean;
  setCurrentFMSObject: (object: FMSObject) => void;
  setIsFMSSelectorShown: (boolean: boolean) => void;
}

const SelectFmsType = (props: fmsSelectorProps) => {
  const pagesContext: IPageContext = useContext<IPageContext>(PageContext); //cant use page context will need to reafactor fms designer for that
  const lessonPageActions: ILessonPageActionsContext = useContext<ILessonPageActionsContext>(LessonPageActionsContext);
  // const [fmsObject, setFmsObject]: [IFMSObject, (React.Dispatch<React.SetStateAction<IFMSObject>>)]= useState<IFMSObject>({cdu: "", cduPath: "", assetVersionId: -1, cduObject: initialCDU})

  // const closeModal = () => {
  //     props.setShowModal(false);
  //     //sends fms blobpath to fmsPlayer
  // }

  const setCduJson = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    // event.preventDefault();
    // lessonPageActions.setFmsObject(fmsObject);
    // pagesContext.updatePageAsset(fmsObject.assetVersionId, null);
    pagesContext.addNewPageAndDisplay(pagesContext.currentlyDisplayedPageIndex, "FMS", "New FMS Page");
    props.setIsFMSSelectorShown(false);
  };

  return (
    <>
      <Modal show={props.showModal} animation={false}>
        <Modal.Header>
          <Modal.Title>Select FMS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <FmsDropDown setFmsData={setFmsObject}/> */}
          <FmsDropDown setCurrentFMSObject={props.setCurrentFMSObject} pageNumber={props.pageNumber} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="default-button-colors"
            onClick={setCduJson}
            disabled={lessonPageActions.fmsObject.assetVersionId === -1}
          >
            Select
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default SelectFmsType;
