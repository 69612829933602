import React from "react";
import blobUrlFormatHelper from "../../../components/blobUrlFormatHelper";
interface cellHighlightProps {
  //cellGroups: any;
  highlightedCell: string;
  cduAssets: any[];
  cduPath: string;
  cduModel: string;
  cellLines: any;
  rectObjects: any;
}
const CDUCellHighlights = (props: cellHighlightProps) => {
  //need to take selector LEFT/RIGHT from cdu assets
  //can highlight keys dont need to worry about MKP here
  const getSelectorImage = (keyName: string) => {
    let selectorAsset = "";
    if (keyName.includes("LSKR")) {
      selectorAsset = filtercduAssets("selectorRight");
    } else {
      selectorAsset = filtercduAssets("selectorLeft");
    }

    return selectorAsset.length ? blobUrlFormatHelper(props.cduPath + selectorAsset) : "";
  };
  const filtercduAssets = (screenSide: string) => {
    const returnAsset = props.cduAssets.filter((asset) => {
      return asset.id === screenSide;
    });
    return returnAsset[0] ? returnAsset[0].href : "";
  };
  const findLSKCoords = (keyName: string, rectVariable: string) => {
    //different for LSK
    if (keyName && rectVariable) {
      const returnRect = props.rectObjects.filter((rect: any) => {
        return rect.id === keyName;
      });
      if (returnRect[0]) {
        switch (rectVariable) {
          case "xCoordinate":
            return keyName.includes("LSKR") ? returnRect[0][rectVariable] - 111 : returnRect[0][rectVariable] - 5;
          case "yCoordinate":
            return returnRect[0][rectVariable] - 2;
          case "height":
            return returnRect[0][rectVariable] + 4;
          default:
            return returnRect[0][rectVariable];
        }
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };
  const findRectCoords = (keyName: string, rectVariable: string) => {
    const returnRect = props.rectObjects.filter((rect: any) => {
      return rect.id === keyName;
    });

    return returnRect[0] ? returnRect[0][rectVariable] : "";
  };

  return (
    <>
      {!props.cduAssets || !props.cduAssets.length ? (
        <></>
      ) : props.highlightedCell.length ? (
        <>
          {props.highlightedCell.includes("LSK") ? (
            <image
              href={getSelectorImage(props.highlightedCell)}
              x={findLSKCoords(props.highlightedCell, "xCoordinate")}
              y={findLSKCoords(props.highlightedCell, "yCoordinate")}
              width={"140px"}
              height={findLSKCoords(props.highlightedCell, "height")}
              preserveAspectRatio="none"
            />
          ) : (
            <rect
              stroke="#FF00FF"
              strokeWidth="1"
              fill="none"
              x={findRectCoords(props.highlightedCell, "xCoordinate")}
              y={findRectCoords(props.highlightedCell, "yCoordinate")}
              width={findRectCoords(props.highlightedCell, "width")}
              height={findRectCoords(props.highlightedCell, "height")}
            />
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default CDUCellHighlights;
