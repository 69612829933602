import React, { useState } from "react";
import { ReactComponent as ArrowIcon } from "../../../../assets/icons/HeaderIcons/drop-down.svg";
import { IGraphicQuiz } from "../../models/IIGA";

const IGAGraphicQuizLayoutButton = (props: IGraphicQuiz) => {
  const { selected, changeGraphicQuizLayout } = props;
  const [isLayoutMenuShown, setIsLayoutMenuShown]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] =
    useState<boolean>(false);
  const size2 = 2;
  const size3 = 3;
  const size4 = 4;
  const size5 = 5;
  const size6 = 6;

  function displayMenu() {
    return (
      <React.Fragment>
        <div
          className="layout-change-icon-btn"
          onClick={() => {
            if (selected !== size2) {
              changeGraphicQuizLayout(size2);
              setIsLayoutMenuShown(false);
            }
          }}
        >
          <span className="icon-answers-2">
            <span className={`path1${selected === size2 ? " selected" : ""}`}></span>
            <span className="path2"></span>
            <span className="path3"></span>
          </span>
        </div>

        <div
          className="layout-change-icon-btn"
          onClick={() => {
            if (selected !== size3) {
              changeGraphicQuizLayout(size3);
              setIsLayoutMenuShown(false);
            }
          }}
        >
          <span className="icon-anwers-3">
            <span className={`path1${selected === size3 ? " selected" : ""}`}></span>
            <span className="path2"></span>
            <span className="path3"></span>
            <span className="path4"></span>
          </span>
        </div>

        <div
          className="layout-change-icon-btn"
          onClick={() => {
            if (selected !== size4) {
              changeGraphicQuizLayout(size4);
              setIsLayoutMenuShown(false);
            }
          }}
        >
          <span className="icon-anwers-4">
            <span className={`path1${selected === size4 ? " selected" : ""}`}></span>
            <span className="path2"></span>
            <span className="path3"></span>
            <span className="path4"></span>
            <span className="path5"></span>
          </span>
        </div>

        <div
          className="layout-change-icon-btn"
          onClick={() => {
            if (selected !== size5) {
              changeGraphicQuizLayout(size5);
              setIsLayoutMenuShown(false);
            }
          }}
        >
          <span className="icon-anwers-5">
            <span className={`path1${selected === size5 ? " selected" : ""}`}></span>
            <span className="path2"></span>
            <span className="path3"></span>
            <span className="path4"></span>
            <span className="path5"></span>
            <span className="path6"></span>
          </span>
        </div>

        <div
          className="layout-change-icon-btn"
          onClick={() => {
            if (selected !== size6) {
              changeGraphicQuizLayout(size6);
              setIsLayoutMenuShown(false);
            }
          }}
        >
          <span className="icon-answers-6">
            <span className={`path1${selected === size6 ? " selected" : ""}`}></span>
            <span className="path2"></span>
            <span className="path3"></span>
            <span className="path4"></span>
            <span className="path5"></span>
            <span className="path6"></span>
            <span className="path7"></span>
          </span>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="layout-btn-container">
        <button
          className={`change-layout-btn${isLayoutMenuShown ? " open" : ""}`}
          onClick={() => setIsLayoutMenuShown(!isLayoutMenuShown)}
        >
          Change Layout
          <ArrowIcon className={`header-preview-svg${isLayoutMenuShown ? " point-left" : ""}`} />
        </button>
        {isLayoutMenuShown ? <div className="layout-icon-container">{displayMenu()}</div> : <></>}
      </div>
    </React.Fragment>
  );
};

export default IGAGraphicQuizLayoutButton;
