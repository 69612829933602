import React, { useEffect, useRef, useState } from "react";
import { handleKeyUp, handleKeyPress } from "../../ObjectPropertyBox/functions/PropertyBoxFunctions";
import { AnnotationPropType } from "../Annotation";
import AnnotationTextMenu from "../AnnotationTextMenu/AnnotationTextMenu";
import RTE, { Quill } from "react-quill";
import { Interweave } from "interweave";
import { BASE_PAGES_QUILL_FONT_SIZE_WHITELIST, OLD_EM_FONT_WHITELIST } from "../AnnotationTextMenu/constants";
import { adjustLabelHeight } from "../AnnotationFunctions/AnnotationLabelFunctions";
import { ElementTypes } from "../../../pageTypes/BasicPage_Player/components/IBasePage";
import _ from "lodash";
import { updateTextAngleBrackets } from "../../../pageTypes/BasicPage_Player/components/BaseFreeForm/functions/formatConversion";
import { RangeStatic } from "quill";
import { HighlightAnnotation } from "../../Annotations/HighlightAnnotation";

type LabelType = AnnotationPropType & {
  editableIndex: number;
  isActive: boolean;
  isMenuShown: boolean;
  handleFormatChange(e: any, funct: any, value: any, keyValue: any): void;
  // refFunc: (element: HTMLDivElement | null, index: number) => void,
  setEditableIndex(number: number): void;
  setElementType(value: ElementTypes): void;
  setIsActive(boolean: boolean): void;
  setIsMenuShown(boolean: boolean): void;
  setNodeToUpdate(obj: any): void;
  setTarget: (val: any) => void;
  updateLabel: (text: string, scrollWidth: number, scrollHeight: number) => void;
};

const AnnotationLabel = (props: LabelType) => {
  const {
    annotation,
    editableIndex,
    index,
    isActive,
    isMenuShown,
    kp,
    handleFormatChange,
    markSelected,
    setEditableIndex,
    setElementType,
    setIsActive,
    setIsMenuShown,
    setNodeToUpdate,
    setTarget,
    updateLabel,
  } = props;
  const [selectedFontSize, setSelectedFontSize]: [string, React.Dispatch<React.SetStateAction<string>>] =
    useState<string>("1em");
  const quillRef: React.RefObject<any> = useRef<any>(null);
  const annotationRef = React.useRef<HTMLDivElement>(null);
  const formats: Array<string> = [
    "background",
    "bold",
    "color",
    "font",
    "italic",
    "size",
    "underline",
    "indent",
    "align",
  ];
  const placeHolderCSS =
    "color: rgba(0,0,0,0.6); font-style: italic; pointer-events: none; padding-left: calc(.25rem + 2px);";

  const placeholderString = `<span style="${placeHolderCSS}">Enter Text Here...</span>`;

  const isDisplayed = typeof annotation.isDisplayed === "undefined" || annotation.isDisplayed;

  useEffect(() => {
    const sizeStyle = Quill.import("attributors/style/size");
    sizeStyle.whitelist = [...BASE_PAGES_QUILL_FONT_SIZE_WHITELIST, ...OLD_EM_FONT_WHITELIST];
    Quill.register(sizeStyle, true);
  }, []);

  useEffect(() => {
    if (!isMenuShown && annotationRef.current) {
      (annotationRef.current as HTMLDivElement).style.zIndex = `${annotation.zIndex}`;
    }
  }, [isActive]);

  function handleTextChange(text: string, delta: any, source: string) {
    const scrollWidth = 17;
    let scrollHeight = 28;
    if (text === "<p><br></p>") text = "";
    //Scroll width commented out temporarily due to lack of a solution to grow label with font size change
    // if(quillRef.current?.editingArea?.scrollWidth) {
    //   scrollWidth = adjustLabelWidth(quillRef.current.editingArea.scrollWidth, kp.props.pageDims as DOMRect);
    //   scrollWidth = scrollWidth > annotation.width ? scrollWidth : annotation.width;
    // }

    if (quillRef.current?.editingArea?.scrollHeight >= scrollHeight) {
      scrollHeight = adjustLabelHeight(quillRef.current.editingArea.scrollHeight, kp.props.pageDims as DOMRect);
    }
    updateLabel(text, scrollWidth, scrollHeight);
  }

  const doTextClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    // setIsActive(false);
    setEditableIndex(index);
    setElementType("annotations");
    setNodeToUpdate(annotation);
    setTarget(null);
    setIsMenuShown(true);
    setIsActive(true);
    (e.currentTarget.parentElement as HTMLDivElement).style.outline =
      annotation.borderColor && annotation.borderColor !== "transparent"
        ? `${annotation.strokeWidth}px solid ${annotation.borderColor}`
        : "2px #ADFF2F dashed";
    (e.currentTarget.parentElement as HTMLDivElement).style.zIndex = "501";
  };

  const handleLabel2Click = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsMenuShown(false);
    setIsActive(false);
    markSelected(e.currentTarget.parentElement as HTMLDivElement, index, "annotations");
  };

  function handleHoverIn(e: React.SyntheticEvent<HTMLDivElement>) {
    if (isActive || isMenuShown) {
      return;
    }
    (e.currentTarget.parentElement as HTMLDivElement).style.zIndex = "499";
    // setIsHovering(true);
  }

  function handleHoverOut(e: React.SyntheticEvent<HTMLDivElement>) {
    if (!isActive && !isMenuShown) {
      if (index !== editableIndex) {
        (e.currentTarget.parentElement as HTMLDivElement).style.zIndex = `${annotation.zIndex}`;
      }
    }
  }

  function handleSelectionChange(range: RangeStatic) {
    if (range !== null) {
      const attr = quillRef.current.editor.getFormat(range.index, range.length);
      if (_.has(attr, "size")) {
        setSelectedFontSize(attr.size);
      } else {
        setSelectedFontSize("1em");
      }
    }
  }

  const display = () => {
    const rotate = annotation?.transform?.rotate ? annotation?.transform?.rotate : 0;
    // const scaleX = annotation?.transform?.scaleX;
    // const scaleY = annotation?.transform?.scaleY;
    // const matrix = annotation?.transform?.matrix3d;

    return (
      <React.Fragment>
        <div
          // ref={element => refFunc(element, index)}
          id={`label-${index}`}
          ref={annotationRef}
          className="annotation label"
          style={{
            top: `${annotation.top}%`,
            left: `${annotation.left}%`,
            width: `${annotation.width}%`,
            zIndex: annotation.zIndex,
            transform: `rotate(${rotate}deg)`,
            backgroundColor: `${annotation.backgroundColor}`,
            outline: annotation.borderColor ? `${annotation.strokeWidth}px solid ${annotation.borderColor}` : "none",
            // textAlign: annotation.textAlign
            opacity: isDisplayed ? "100%" : "50%",
            overflow: "visible",
            padding: "0px",
          }}
        >
          {isMenuShown ? (
            <AnnotationTextMenu
              isLabel={true}
              formats={formats}
              selectedFontSize={selectedFontSize}
              handleFormatChange={handleFormatChange}
              setSelectedFontSize={setSelectedFontSize}
            />
          ) : (
            <></>
          )}
          <div
            className="annotation-label annotation-label-input"
            style={{ width: `${annotation.text === "" ? "100%" : ""}` }}
            onClick={doTextClick}
            onMouseEnter={(e: React.SyntheticEvent<HTMLDivElement>) => handleHoverIn(e)}
            onMouseLeave={(e: React.SyntheticEvent<HTMLDivElement>) => handleHoverOut(e)}
          >
            {isMenuShown && isActive ? (
              <React.Fragment>
                <RTE
                  ref={quillRef}
                  // index={index}
                  defaultValue={annotation.text}
                  onChange={handleTextChange}
                  placeholder={"Enter Text Here..."}
                  modules={{ toolbar: "#text-menu" }}
                  formats={formats}
                  onChangeSelection={handleSelectionChange}
                  preserveWhitespace
                />
              </React.Fragment>
            ) : (
              <Interweave
                content={
                  annotation.text === "" ? `${placeholderString}` : updateTextAngleBrackets(annotation.text as string)
                }
                tagName="div"
                allowAttributes
                allowElements
              />
            )}
          </div>
          <div
            className="label2Div"
            onMouseDown={handleLabel2Click}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => handleKeyPress(e, kp)}
            onKeyUp={(e: React.KeyboardEvent<HTMLDivElement>) => handleKeyUp(e, kp)}
            tabIndex={0}
            style={{ top: "-10px", left: "-10px", position: "absolute" }}
          />
        </div>
      </React.Fragment>
    );
  };

  return <React.Fragment>{display()}</React.Fragment>;
};

export default AnnotationLabel;
