import React, { useEffect, useState, useContext } from "react";
import RichTextEditor from "../../../components/richTextEditor/NEWrichTextEditor";
import { IRTEModule } from "../BasePageDesigner";
import { IBasePage } from "./IBasePage";
import { PageContext, IPageContext } from "../../../routes/builderContexts";
import _ from "lodash";

type BaseProps = {
  moduleRTE: IRTEModule[];
  placeholderText: string;
  textBlockIndex: number;
  updatedManifest: IBasePage;
  assignNewText: (newText: string) => void;
  displayImage: (imageIndex: number) => JSX.Element;
  handleImageDivClick: (index: number) => void;
  setTextBlockIndex: (index: number) => void;
};

const BaseFourGraphicSplitText: React.FC<BaseProps> = (props: any) => {
  const [isLoading, setIsLoading]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState<boolean>(true);

  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const updatedManifest: any = _.cloneDeep(pageContext.pageManifest);

  if (!pageContext.pageManifest.pageImage[2]) {
    updatedManifest.pageImage[2] = _.cloneDeep(pageContext.pageManifest.pageImage[1]);
    updatedManifest.pageImage[3] = _.cloneDeep(pageContext.pageManifest.pageImage[1]);
    pageContext.updatePageManifest(updatedManifest);
  }

  useEffect(() => {
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <></>;
  } else {
    return (
      <React.Fragment>
        <div className="basePageFGST-container">
          <div className="split-graphic-row">
            <div
              className="split-graphic-container-img"
              onClick={() => props.handleImageDivClick(0)}
              id="0"
              style={{
                marginTop: "0px",
                marginRight: "13px",
                marginBottom: "14px",
                marginLeft: "13px",
              }}
            >
              <div className="img-aspect-container split">
                <div className="img-aspect-container-inside">
                  <div className="img-aspect-centering">{props.displayImage(0)}</div>
                </div>
              </div>
            </div>
            <div
              className="split-graphic-container-img"
              onClick={() => props.handleImageDivClick(1)}
              id="1"
              style={{
                marginTop: "0px",
                marginRight: "13px",
                marginBottom: "14px",
                marginLeft: "13px",
              }}
            >
              <div className="img-aspect-container split">
                <div className="img-aspect-container-inside">
                  <div className="img-aspect-centering">{props.displayImage(1)}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="split-graphic-row">
            <div
              className="split-graphic-container-img"
              onClick={() => props.handleImageDivClick(2)}
              id="2"
              style={{
                marginTop: "0px",
                marginRight: "13px",
                marginBottom: "13px",
                marginLeft: "12px",
              }}
            >
              <div className="img-aspect-container split">
                <div className="img-aspect-container-inside">
                  <div className="img-aspect-centering">{props.displayImage(2)}</div>
                </div>
              </div>
            </div>
            <div
              className="split-graphic-container-img"
              onClick={() => props.handleImageDivClick(3)}
              id="3"
              style={{
                marginTop: "0px",
                marginRight: "13px",
                marginBottom: "13px",
                marginLeft: "12px",
              }}
            >
              <div className="img-aspect-container split">
                <div className="img-aspect-container-inside">
                  <div className="img-aspect-centering">{props.displayImage(3)}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="block basePageFGST-text-container">
            <RichTextEditor
              displayText={props.updatedManifest.textBlock[0].text}
              assignNewText={props.assignNewText}
              placeholder={props.placeholderText}
              module={props.moduleRTE}
              quillRef={props.quillRef}
              handleSelectionChange={props.handleSelectionChange}
              theme="bubble"
              version={props.updatedManifest.textBlock[0].version}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
};

export default BaseFourGraphicSplitText;
