import React, { useState } from "react";
import RichTextEditor from "../../../components/richTextEditor/NEWrichTextEditor";
import { IRTEModule } from "../BasePageDesigner";
import { IBasePage } from "./IBasePage";
import { basePageTextBoxWrapperVersioning } from "../../../utils/Versioning/basePagesVersioning";

type BaseProps = {
  moduleRTE: IRTEModule[];
  placeholderText: string;
  textBlockIndex: number;
  updatedManifest: IBasePage;
  assignNewText: (newText: string) => void;
  displayImage: (imageIndex: number) => JSX.Element;
  handleImageDivClick: (index: number) => void;
  setTextBlockIndex: (index: number) => void;
};

const BaseDoubleGraphicSplit: React.FC<BaseProps> = (props: any) => {
  const [isActiveString, setIsActiveString]: [string, React.Dispatch<React.SetStateAction<string>>] =
    useState("string");
  const first = "first";
  const second = "second";
  const versionString1 = props.updatedManifest.textBlock[0].version;
  const versionString2 = props.updatedManifest.textBlock[1].version;

  function handleSectionClick(index: number, active: string) {
    setIsActiveString(active);
    props.setTextBlockIndex(index);
  }

  return (
    <React.Fragment>
      <div className="split-graphic-row">
        <div
          className={"split-graphic-container" + basePageTextBoxWrapperVersioning(versionString1)}
          onClick={() => handleSectionClick(0, first)}
          style={{
            marginTop: "0px",
            marginRight: "12px",
            marginBottom: "13px",
            marginLeft: "13px",
          }}
        >
          <RichTextEditor
            displayText={props.updatedManifest.textBlock[0].text}
            assignNewText={props.assignNewText}
            placeholder={props.placeholderText}
            module={props.moduleRTE}
            quillRef={props.quillRef}
            handleSelectionChange={props.handleSelectionChange()}
            version={versionString1}
            theme="bubble"
            readOnly={isActiveString !== first}
          />
        </div>
        <div
          className="split-graphic-container-img"
          onClick={() => props.handleImageDivClick(0)}
          id="1"
          style={{
            marginTop: "0px",
            marginRight: "13px",
            marginBottom: "14px",
            marginLeft: "13px",
          }}
        >
          <div className="img-aspect-container split">
            <div className="img-aspect-container-inside">
              <div className="img-aspect-centering">{props.displayImage(0)}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="split-graphic-row">
        <div
          className="split-graphic-container-img"
          onClick={() => props.handleImageDivClick(1)}
          id="0"
          style={{
            marginTop: "0px",
            marginRight: "13px",
            marginBottom: "13px",
            marginLeft: "12px",
          }}
        >
          <div className="img-aspect-container split">
            <div className="img-aspect-container-inside">
              <div className="img-aspect-centering">{props.displayImage(1)}</div>
            </div>
          </div>
        </div>
        <div
          className={"split-graphic-container" + basePageTextBoxWrapperVersioning(versionString2)}
          onClick={() => handleSectionClick(1, second)}
          // onBlur={() => {props.setTextBlockIndex(0); setIsActiveString("")}}
          style={{
            marginTop: "0px",
            marginRight: "13px",
            marginBottom: "13px",
            marginLeft: "12px",
          }}
        >
          <RichTextEditor
            displayText={props.updatedManifest.textBlock[1].text}
            assignNewText={props.assignNewText}
            placeholder={props.placeholderText}
            module={props.moduleRTE}
            quillRef={props.quillRef}
            version={versionString2}
            theme="bubble"
            readOnly={isActiveString !== second}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default BaseDoubleGraphicSplit;
