import "./tables.css";

import { useTablesDataDispatch, useTablesDataState } from "./TablesDataProvider";
import { Interweave } from "interweave";
import React, { PropsWithChildren, useEffect, useRef } from "react";
import { ITable } from "./ITable";

interface cellProps {
  table: ITable;
  // version: any
}

function Cells(props: cellProps) {
  const TablesDispatch = useTablesDataDispatch();
  const TablesState = useTablesDataState();
  const cells = props.table.cells;
  const timerRef = useRef<{
    timeout: null | NodeJS.Timeout;
    isDouble: boolean;
  }>({
    timeout: null,
    isDouble: false,
  });

  interface cellClickProps {
    column: any;
    row: any;
    index: any;
    event: any;
  }

  // {} = timerRef.current

  function cellOnClick(props: cellClickProps) {
    if (timerRef.current.isDouble === true) {
      if (timerRef.current.timeout) {
        clearTimeout(timerRef.current.timeout);

        cellDoubleClick(props);
        // run double click logic
      }
      timerRef.current.isDouble = false;
    } else {
      // first click
      timerRef.current.timeout = setTimeout(() => {
        if (TablesState.selectedCell !== undefined && props.index !== TablesState.selectedCell.index) {
          TablesDispatch({
            action: "setSelectedCell",
            payload: [props.column[props.index], props.row, props.index],
          });
        } else if (TablesState.selectedCell?.index !== props.index) {
          TablesDispatch({ action: "setSelectedCell", payload: [undefined] });
          // props.event.stopPropagation();
        } else {
          // props.event.stopPropagation();
        }
        timerRef.current.isDouble = false;
      }, 250);
      timerRef.current.isDouble = true;
    }
    // props.event.stopPropagation();
  }

  function cellDoubleClick(props: cellClickProps) {
    if (TablesState.selectedCell !== undefined) {
      TablesDispatch({ action: "setSelectedCell", payload: [undefined] });
    } else if (TablesState.selectedCell?.index === props.index) {
      props.event.stopPropagation();
    } else {
      TablesDispatch({
        action: "setSelectedCell",
        payload: [props.column[props.index], props.row, props.index],
      });
      props.event.stopPropagation();
    }
    // props.event.stopPropagation();
  }

  function Cell(cell: any, index: number) {
    const row = Math.floor(index / props.table.columns.length);
    const column = buildArray(props.table.cells.length, props.table.columns.length);

    // useEffect(() => {

    // }, [TablesState.selectedTableData]);

    /// needs some maths to determine correct column

    function buildArray(length: number, maxIndexValue: number) {
      const arr = new Array(length);

      for (let a = 0; a < length; a++) {
        arr[a] = a % maxIndexValue;
      }

      return arr;
    }

    return (
      <div
        key={index}
        className={"custom-table-cell " + index + ` column-${column[index]}` + ` row-${row}`}
        style={{ border: props.table.border }}
        onClick={
          TablesState.selectedCell?.index !== index
            ? (event) => {
                cellOnClick({
                  column: column,
                  row: row,
                  index: index,
                  event: event,
                });
              }
            : () => {}
        }
        onMouseDown={
          TablesState.selectedTable !== undefined
            ? (e) => {
                e.stopPropagation();
              }
            : () => {}
        }
        // onMouseDown={(e)=>{e.stopPropagation()}}
        // onMouseDown={()=>{console.log("select Table")}}
        // onDoubleClick={(event)=>{cellDoubleClick({ column: column, row: row, index: index, event: event });}}
        // style={{fontSize:"12px", display:"flex", justifyContent:"center", flexDirection:"column", textAlign:"center"}}
      >
        <div
          key={index}
          className={"ff-text"}
          // onMouseDown={()=>{console.log("select Table")}}
        >
          {TablesState.selectedCell?.index !== index ? (
            <Interweave
              key={cell.index}
              content={cell.content === "" || cell.content === "<p><br></p>" ? "" : cell.content}
              allowElements
            />
          ) : // <div key={index}>{cell.content}</div>
          null}
        </div>
      </div>
    );
  }

  return <>{cells.map(Cell)}</>;
}

export default Cells;
