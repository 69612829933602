import React, { useRef, useEffect } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import {
  OrbitControls,
  TransformControls
} from "@react-three/drei"
import "../../CPaT3d_Player/components/hotSpotEditor/hsEditor.css";
import VisualHotspot from "../components/hotspot";

const DetectionRadius = (props) => {
  const { proximityRange, newHSref, detectRad } = props;

  return newHSref.current ? (
    <mesh ref={detectRad}>
      <cylinderGeometry attach="geometry" args={[proximityRange, proximityRange, 0.25, 64]} />
      <meshBasicMaterial attach="material" color={"green"} transparent opacity={props.isVisible ? ".25" : "0"} />
    </mesh>
  ) : null;
};

const Controls = (props) => {
  const nudge = 0.25;
  const { camera } = useThree();
  const camControls = useRef();
  const detectRad = useRef();
  const { proximityRange, proximityOffset, editMode } = props;

  const newHSref = useRef({rotation: [0,0,0], position: [0,0,0], isSphere: true, scale: [1,1,1], geometry: [1,32,32]});
  const sentHSref = useRef({rotation: [0,0,0], position: [0,0,0], isSphere: true, scale: [1,1,1], geometry: [1,32,32]});

  useEffect(() => {
    if(camControls.current){
      camControls.current.listenToKeyEvents(window);
    }
  }, [camControls.current])

  useEffect(() => {
    camera.position.set(15, 15, 30);
    camera.rotation.set(0, 9, 0);
    camera.far = 5000;
    camera.near = 0.01;
    camera.fov = 75;
    newHSref.current.scale.set(1, 1, 1);
    newHSref.current.position.set(25, 9, 25);
    newHSref.current.rotation.set(0, 0, 0);
    newHSref.current.isSphere = true;

    if (props.currentHotSpot) {
      if (
        props.currentHotSpot.geometry[0] > 0 &&
        props.currentHotSpot.geometry[1] > 0 &&
        props.currentHotSpot.geometry[2] > 0
      ) {
        newHSref.current.position.set(
          props.currentHotSpot.position[0],
          props.currentHotSpot.position[1],
          props.currentHotSpot.position[2],
        );
        if (props.currentHotSpot.isSphere === undefined || props.currentHotSpot.isSphere === null) {
          newHSref.current.isSphere = true;
        } else {
          newHSref.current.isSphere = props.isSphere;
        }

        if (props.currentHotSpot.scale === undefined || props.currentHotSpot.scale === null) {
          newHSref.current.scale.set(1, 1, 1);
        } else {
          newHSref.current.scale.set(
            props.currentHotSpot.scale[0],
            props.currentHotSpot.scale[1],
            props.currentHotSpot.scale[2],
          );
        }

        if (props.currentHotSpot.rotation === undefined || props.currentHotSpot.rotation === null) {
          newHSref.current.rotation.set(0, 0, 0);
        } else {
          newHSref.current.rotation.set(
            props.currentHotSpot.rotation[0],
            props.currentHotSpot.rotation[1],
            props.currentHotSpot.rotation[2],
          );
        }
      }
    }

    return () => {
      props.returnNewHotSpot({
        key: "active",
        position: [sentHSref.current.position.x, sentHSref.current.position.y, sentHSref.current.position.z],
        geometry: [sentHSref.current.scale.x, 32, 32],
        rotation: [sentHSref.current.rotation.x, sentHSref.current.rotation.y, sentHSref.current.rotation.z],
        scale: [sentHSref.current.scale.x, sentHSref.current.scale.y, sentHSref.current.scale.z],
        isSphere: sentHSref.current.isSphere,
      });
    };

  }, []);

  useEffect(() => {
    props.returnProximityOffset(proximityOffset);
  }, [proximityRange, proximityOffset]);

  const increaseSize = () => {
    newHSref.current.scale.x += nudge;
    newHSref.current.scale.y += nudge;
    newHSref.current.scale.z += nudge;
  };
  const decreaseSize = () => {
    if (newHSref.current.scale.x > 0.26 && newHSref.current.scale.y > 0.26 && newHSref.current.scale.z > 0.26) {
      newHSref.current.scale.x -= nudge;
      newHSref.current.scale.y -= nudge;
      newHSref.current.scale.z -= nudge;
    }
  };

  const increaseWidth = () => {
    newHSref.current.scale.x += nudge;
  };
  const decreaseWidth = () => {
    if (newHSref.current.scale.x > 0.26) {
      newHSref.current.scale.x -= nudge;
    }
  };
  const increaseHeight = () => {
    newHSref.current.scale.y += nudge;
  };
  const decreaseHeight = () => {
    if (newHSref.current.scale.y > 0.26) {
      newHSref.current.scale.y -= nudge;
    }
  };
  const increaseLength = () => {
    newHSref.current.scale.z += nudge;
  };
  const decreaseLength = () => {
    if (newHSref.current.scale.z > 0.26) {
      newHSref.current.scale.z -= nudge;
    }
  };
  const resetSize = () => {
    newHSref.current.scale.x = 1;
    newHSref.current.scale.y = 1;
    newHSref.current.scale.z = 1;
  };
  const moveUp = () => {
    newHSref.current.position.y += nudge;
  };
  const moveDown = () => {
    newHSref.current.position.y -= nudge;
  };

  const handleKeyPress = (event) => {
    if (event) {
      event.preventDefault();
      switch (event.key.toLowerCase()) {
        case "q": {
          moveUp();
          break;
        }
        case "z": {
          moveDown();
          break;
        }
        case "+": {
          increaseSize();
          break;
        }
        case "-": {
          decreaseSize();
          break;
        }
        case "w": {
          increaseHeight();
          break;
        }
        case "x": {
          decreaseHeight();
          break;
        }
        case "a": {
          decreaseWidth();
          break;
        }
        case "d": {
          increaseWidth();
          break;
        }
        case "e": {
          increaseLength();
          break;
        }
        case "v": {
          decreaseLength();
          break;
        }
        case "c": {
          resetSize();
          break;
        }
        default: {
          break;
        }
      }
      sentHSref.current = newHSref.current;
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  // keeps the camera focused on the hotspot, allowing for right-click dragging
  useFrame(({ camera }) => {
    camera.updateProjectionMatrix(void (
        camControls.current.target = newHSref.current.position,
        detectRad.current.position.set(newHSref.current.position.x - proximityOffset.x, .3, newHSref.current.position.z - proximityOffset.z),
        camera.lookAt(newHSref.current.position),
        camControls.current.update()
    ))
  });

  useEffect(() => {
    sentHSref.current = newHSref.current;
  }, [newHSref.current.position])

  if (!(newHSref.current === undefined)) {
    newHSref.current.isSphere = props.isSphere;
  }

  return (
    <>
      <OrbitControls
        ref={camControls}
        makeDefault
        enableDamping={false}
        screenSpacePanning={false}
        onChange={() => {
          newHSref.current.position.y = newHSref.current.position.y;
        }}
      />
      <TransformControls 
        onChange={(e) => {
          if(e.target.dragging) {
            sentHSref.current = newHSref.current;
          }
        }}
        mode={editMode}
        object={newHSref}
      />
      <VisualHotspot hovered={false} isSphere={props.isSphere} meshRef={newHSref} />
      <DetectionRadius proximityRange={proximityRange} newHSref={newHSref} detectRad={detectRad} isVisible={true} />
    </>
  );
};

export default Controls;
