import React from "react";
import { Interweave } from "interweave";

type EnablingObjectiveType = {
  enablingObjective: string;
  labelClass: string;
  title: string;
};

const EnablingObjective = (props: EnablingObjectiveType) => {
  const { enablingObjective, labelClass, title } = props;
  return (
    <React.Fragment>
      <div id="terminal-objective-panel">
        <div id="terminal-objective-header">
          <span className={labelClass}>{title.length > 0 ? title : "Enabling Objective:"}</span>
        </div>
        <div id="terminal-objective-content">
          <Interweave content={enablingObjective} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default EnablingObjective;
