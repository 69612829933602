import React, { useEffect } from "react";
import {
  useGLTF,
  useProgress
} from "@react-three/drei"

const AtGate = (props) => {
  const gatePath = "/api/Asset/cpat/generic/3dmodels/conditions/CPatGate/Skybox.gltf";

  const { scene } = useGLTF(gatePath);
  const { progress } = useProgress();
  
  scene.scale.set(1.2,1.2,1.2);
  
  useEffect(() => {
    if(progress === 100) {
      props.callBack();
    }
  }, [progress])

  return <primitive object={scene} />
};

export default AtGate;
