import React from "react";
import blobHelper from "../../../blobUrlFormatHelper";

type GraphicQuizType = {
  imagePath: string;
  index: number;
  isCorrect: boolean;
  addGraphicQuizImage: (index: number) => void;
  markAsCorrect: (e: React.MouseEvent<HTMLDivElement>, index: number) => void;
  updatedManifest: any;
  pageContext: any;
  currentStep: number;
};

const IGAGraphicQuizImage = (props: GraphicQuizType) => {
  function updateImage(index: number, pageManifest: any, pageContext: any, igsIndex?: number) {
    const quizImageContainer = document.getElementById("quiz-image-container");
    const imageAspectContainer = document.getElementById("img-aspect-container-0");
    const igaInteractiveGraphicWrapper = document.getElementById("iga-graphic-wrapper");
    const igaStandardGraphicContainer = document.getElementById("iga-standard-quiz-graphic-container");
    let quizImageContainerRect: any;

    const quizImage = document.getElementById("quiz-image-" + index);

    if (quizImageContainer) {
      quizImageContainerRect = quizImageContainer.getBoundingClientRect();
    }

    if (imageAspectContainer) {
      quizImageContainerRect = imageAspectContainer.getBoundingClientRect();
    }

    if (igaInteractiveGraphicWrapper) {
      quizImageContainerRect = igaInteractiveGraphicWrapper.getBoundingClientRect();
    }

    if (igaStandardGraphicContainer) {
      quizImageContainerRect = igaStandardGraphicContainer.getBoundingClientRect();
    }

    if (quizImage) {
      const quizImageRect = quizImage.getBoundingClientRect();

      if (pageManifest.image) {
        pageManifest.image[index].rawData = {
          pixelHeight: quizImageRect.height,
          pixelWidth: quizImageRect.width,
          pixelTop: quizImageRect.top,
          pixelLeft: quizImageRect.left,
        };

        pageManifest.image[index].version = 1;
        pageContext.updatePageManifest(pageManifest);
      }

      if (pageManifest.IGSObject) {
        if (pageManifest.IGSObject[igsIndex as number].images) {
          pageManifest.IGSObject[igsIndex as number].images.rawData = {
            pixelHeight: quizImageRect.height,
            pixelWidth: quizImageRect.width,
            pixelTop: quizImageRect.top - quizImageContainerRect.top,
            pixelLeft: quizImageRect.left - quizImageContainerRect.left,
          };
          pageManifest.IGSObject[igsIndex as number].aspectRatio =
            quizImageContainerRect.width / quizImageContainerRect.height;
          pageManifest.IGSObject[igsIndex as number].images.version = 1;
        }

        if (!pageManifest.IGSObject[igsIndex as number].images && pageManifest.IGSObject[igsIndex as number].choices) {
          pageManifest.IGSObject[igsIndex as number].choices[index].rawData = {
            pixelHeight: quizImageRect.height,
            pixelWidth: quizImageRect.width,
            pixelTop: quizImageRect.top - quizImageContainerRect.top,
            pixelLeft: quizImageRect.left - quizImageContainerRect.left,
          };
          pageManifest.IGSObject[igsIndex as number].aspectRatio =
            quizImageContainerRect.width / quizImageContainerRect.height;
          pageManifest.IGSObject[igsIndex as number].choices[index].version = 1;
        }
      }

      updatePageManifestDimensionPercentages(index, pageManifest, pageContext, igsIndex, quizImageContainerRect);
    }
  }

  function updatePageManifestDimensionPercentages(
    index: number,
    pageManifest: any,
    pageContext: any,
    igsIndex?: number,
    quizImageContainer?: any,
  ) {
    if (pageManifest.image) {
      // image pixel percent from top of pagePlayerArea
      pageManifest.image[index].top = Number(
        ((pageManifest.image[index].rawData!.pixelTop / quizImageContainer.height) * 100).toFixed(2),
      );
      // image pixel percent from left of pagePlayerArea
      pageManifest.image[index].left = Number(
        ((pageManifest.image[index].rawData!.pixelLeft / quizImageContainer.width) * 100).toFixed(2),
      );
      // image pixel percent height relative to pagePlayerArea
      pageManifest.image[index].height = Number(
        ((pageManifest.image[index].rawData!.pixelHeight / quizImageContainer.height) * 100).toFixed(2),
      );
      // image pixel percent width relative to pagePlayerArea
      pageManifest.image[index].width = Number(
        ((pageManifest.image[index].rawData!.pixelWidth / quizImageContainer.width) * 100).toFixed(2),
      );
    }

    if (pageManifest.IGSObject) {
      if (pageManifest.IGSObject[igsIndex as number].images) {
        // image pixel percent from top of pagePlayerArea
        pageManifest.IGSObject[igsIndex as number].images.top = Number(
          (
            (pageManifest.IGSObject[igsIndex as number].images.rawData!.pixelTop / quizImageContainer.height) *
            100
          ).toFixed(2),
        );
        // image pixel percent from left of pagePlayerArea
        pageManifest.IGSObject[igsIndex as number].images.left = Number(
          (
            (pageManifest.IGSObject[igsIndex as number].images.rawData!.pixelLeft / quizImageContainer.width) *
            100
          ).toFixed(2),
        );
        // image pixel percent height relative to pagePlayerArea
        pageManifest.IGSObject[igsIndex as number].images.height = Number(
          (
            (pageManifest.IGSObject[igsIndex as number].images.rawData!.pixelHeight / quizImageContainer.height) *
            100
          ).toFixed(2),
        );
        // image pixel percent width relative to pagePlayerArea
        pageManifest.IGSObject[igsIndex as number].images.width = Number(
          (
            (pageManifest.IGSObject[igsIndex as number].images.rawData!.pixelWidth / quizImageContainer.width) *
            100
          ).toFixed(2),
        );
      }

      if (!pageManifest.IGSObject[igsIndex as number].images && pageManifest.IGSObject[igsIndex as number].choices) {
        // image pixel percent from top of pagePlayerArea
        pageManifest.IGSObject[igsIndex as number].choices[index].top = Number(
          (
            (pageManifest.IGSObject[igsIndex as number].choices[index].rawData!.pixelTop / quizImageContainer.height) *
            100
          ).toFixed(2),
        );
        // image pixel percent from left of pagePlayerArea
        pageManifest.IGSObject[igsIndex as number].choices[index].left = Number(
          (
            (pageManifest.IGSObject[igsIndex as number].choices[index].rawData!.pixelLeft / quizImageContainer.width) *
            100
          ).toFixed(2),
        );
        // image pixel percent height relative to pagePlayerArea
        pageManifest.IGSObject[igsIndex as number].choices[index].height = Number(
          (
            (pageManifest.IGSObject[igsIndex as number].choices[index].rawData!.pixelHeight /
              quizImageContainer.height) *
            100
          ).toFixed(2),
        );
        // image pixel percent width relative to pagePlayerArea
        pageManifest.IGSObject[igsIndex as number].choices[index].width = Number(
          (
            (pageManifest.IGSObject[igsIndex as number].choices[index].rawData!.pixelWidth / quizImageContainer.width) *
            100
          ).toFixed(2),
        );
      }
    }

    pageContext.updatePageManifest(pageManifest);
  }

  const handleImageLoad = () => {
    updateImage(props.index, props.updatedManifest, props.pageContext, props.currentStep);
  };

  return (
    <React.Fragment>
      <div className="iga-graphic-quiz-select-container" onClick={() => props.addGraphicQuizImage(props.index)}>
        <div className="img-aspect-container">
          <div className="img-aspect-container-inside">
            <div className="img-aspect-centering">
              <img
                className="ppqImage2"
                src={blobHelper(props.imagePath)}
                alt=""
                id={"quiz-image-" + props.index}
                onLoad={() => handleImageLoad()}
              />
            </div>
          </div>
        </div>
        {/* {props.canDelete ? <button id="icon-remove-iga-quiz-graphic" onClick={(e: React.MouseEvent<HTMLButtonElement>) => props.removeChoice(e, props.index)}><span className="icon-remove-no-background"></span></button> : <></>} */}
      </div>
      {props.isCorrect ? (
        <div className="ppqIconContainer">
          <span className="icon-radio-button-text-answers-selected">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
            <span className="path4"></span>
          </span>
        </div>
      ) : (
        <div className="ppqIconContainer">
          <span
            className="icon-radio-button-text-answers-not-selected"
            onClick={(e: React.MouseEvent<HTMLDivElement>) => props.markAsCorrect(e, props.index)}
          ></span>
        </div>
      )}
    </React.Fragment>
  );
};

export default IGAGraphicQuizImage;
