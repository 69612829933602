import { useInteracitvityHotspotState } from "../../contexts/InteractivityHotspotProvider";

interface Props {
  objectId: string;
  id?: string;
  width?: number;
  height?: number;
}
export function HighlightAnnotation({ objectId, id, width, height }: Props) {
  const hotspotState = useInteracitvityHotspotState();
  const targets = hotspotState?.currentlySelectedHotspot?.interactivityBuilderAction?.level.getAllTargetIdsInActions();
  const isHighlighted = targets !== undefined && targets.has(objectId);
  return isHighlighted ? <HightlightRect id={id ? id : "glow-rect"} width={width} height={height} /> : null;
}
function HightlightRect({ id, width, height }: Partial<Props>) {
  return (
    <rect
      fill="none"
      width={width ? `${width}%` : "100%"}
      height={height ? `${height}%` : "100%"}
      strokeWidth="4"
      id={id}
      style={{
        zIndex: 1000,
      }}
    ></rect>
  );
}
