import React from "react";
import { SymbolComponentType } from "../helpers/getSymbol";

const CurrentTrack = (props: SymbolComponentType) => {
  const { index, attributes, transformStr } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19.1 11.82"
      key={index}
      id={`symbol-${index}`}
      tabIndex={0}
      className="symbol target"
      name="symbol"
      preserveAspectRatio="xMidYMid meet"
      style={{
        top: `${attributes.top}%`,
        left: `${attributes.left}%`,
        width: `${attributes.width}%`,
        height: `${attributes.height}%`,
        zIndex: attributes.zIndex,
        // transform:`rotate(${attributes?.transform?.rotate}deg) scaleX(${attributes?.transform?.scaleX}) scaleY(${attributes?.transform?.scaleY}) matrix3d(${attributes?.transform?.matrix3d})`
        transform: transformStr,
      }}
    >
      <path
        // className="current_track"
        stroke={`${attributes.borderColor || "white"}`}
        fill={"transparent"}
        strokeWidth={`${attributes.lineWidth || 0.5}`}
        d="M 9.14,7.8855214 V 11.57 H 9.6141313 V 7.9080696 M 18.27,0 V 7.94 H 0.87 V 0.02"
      />
    </svg>
  );
};

export default CurrentTrack;
