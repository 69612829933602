import React from "react";
import { handleKeyUp, handleKeyPress } from "../../ObjectPropertyBox/functions/PropertyBoxFunctions";
import { AnnotationPropType } from "../Annotation";
import HighlightAnnotation from "../hooks/HighlightAnnotation";

const AnnotationRoundedSquare = (props: AnnotationPropType) => {
  const { annotation, index, kp, markSelected, objectIsInTimeline } = props;
  const isDisplayed = typeof annotation.isDisplayed === "undefined" || annotation.isDisplayed;
  const opacity = objectIsInTimeline ? 1 : isDisplayed ? 1 : 0.5;

  return (
    <svg
      // viewBox={`0 0 100 auto`}
      width="100"
      id={`roundedSquare-${index}`}
      tabIndex={0}
      name="roundedSquare"
      className="annotation"
      onMouseDown={(e: React.MouseEvent<SVGSVGElement>) => markSelected(e.currentTarget, index, "annotations")}
      onKeyDown={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyPress(e, kp)}
      onKeyUp={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyUp(e, kp)}
      // preserveAspectRatio="xMinYMin meet"
      opacity={opacity}
      style={{
        top: `${annotation.top}%`,
        left: `${annotation.left}%`,
        width: `${annotation.width}%`,
        height: `${annotation.height}%`,
        zIndex: annotation.zIndex,
        transform: `rotate(${annotation?.transform?.rotate ? annotation?.transform?.rotate?.toFixed(2) : 0}deg)`,
      }}
    >
      <rect
        x=".5%"
        y=".5%"
        height="98.5%"
        width="99%"
        stroke={`${annotation.borderColor || "white"}`}
        fill={`${annotation.backgroundColor || "transparent"}`}
        strokeWidth={`${annotation.strokeWidth || "4"}`}
        rx="6"
      ></rect>
      <HighlightAnnotation annotation={annotation} id="glow-rect-for-square" />
    </svg>
  );
};

export default AnnotationRoundedSquare;
