import React, { createRef, Dispatch, SetStateAction, useEffect, useState, useRef } from "react";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/HeaderIcons/drop-down.svg";
import AnnotationMenu from "../../Annotation/AnnotationMenu/AnnotationMenu";
import { useSelectedObjectDispatch } from "../../../contexts/SelectedObjectProvider/SelectedObjectProvider";
import { useTablesDataDispatch } from "../../Tables/TablesDataProvider";
import { AbstractTooltip as Tooltip } from "../../ToolTip/ToolTip";
type SelectorType = {
  ref?: React.RefObject<HTMLButtonElement>;
  addNewAnnotation: (value: string, funcName: any) => void;
  addSymbolOLD: (name: string, funcNam: any) => void;
};

const AnnotationButton = (props: SelectorType) => {
  const { addNewAnnotation, addSymbolOLD } = props;
  const btnRef: React.Ref<HTMLDivElement> = createRef<HTMLDivElement>();
  const menuRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const [isAnnotationMenuShown, setIsAnnotationMenuShown]: [boolean, Dispatch<SetStateAction<boolean>>] =
    useState<boolean>(false);
  const [isSymbolsMenuShown, setIsSymbolsMenuShown]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] =
    useState<boolean>(false);
  // const [menuPosition, setMenuPosition]: [{}, Dispatch<SetStateAction<{}>>] = useState({});
  const tablesDispatch = useTablesDataDispatch();
  const selectedObjectDispatch = useSelectedObjectDispatch();

  useEffect(() => {
    const clickOutside = (e: MouseEvent) => {
      setIsAnnotationMenuShown(false);
    };
    document.addEventListener("click", clickOutside);

    // setMenuPosition(style);
    return () => document.removeEventListener("click", clickOutside);
  }, [isAnnotationMenuShown]);

  function clickAnnotationIcon(type: string, funcName: any) {
    // if (funcName === "addSymbol") {
    //   addNewSymbol(type, funcName);
    // } else {
    addNewAnnotation(type, funcName);
    // }
    setIsAnnotationMenuShown(false);
    setIsSymbolsMenuShown(false);
  }

  return (
    <div className="annotation-btn-container">
      <div
        ref={btnRef}
        id="annotation-btn"
        className="wysiwyg-toolbar-btn"
        onClick={(e) => {
          e.stopPropagation();
          if (tablesDispatch) {
            tablesDispatch({
              action: "setSelectedTable",
              payload: [undefined],
            });
          }
          selectedObjectDispatch({ type: "SET_OBJECT_ID", payload: null });
          setIsAnnotationMenuShown(!isAnnotationMenuShown);
        }}
        data-tooltip-id="AddAnnotation"
      >
        <Tooltip id="AddAnnotation" />
        <span>Annotations</span>
        <ArrowIcon
          style={isAnnotationMenuShown ? { transform: "rotate(180deg)" } : {}}
          className="header-preview-svg"
        />
      </div>
      {isAnnotationMenuShown ? (
        <AnnotationMenu
          menuRef={menuRef}
          addNewAnnotation={clickAnnotationIcon}
          addSymbolOLD={addSymbolOLD}
          isSymbolsMenuShown={isSymbolsMenuShown}
          setIsSymbolsMenuShown={setIsSymbolsMenuShown}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AnnotationButton;
