import "./tables.css";
import _ from "lodash";
import React, { useContext, useEffect, useRef } from "react";
import AlignButtons from "../../components/WYSIWYGToolbar/components/AlignButtons";
import FontColorButton from "../../components/WYSIWYGToolbar/components/FontColorButton";
import FontStyleButtons from "../../components/WYSIWYGToolbar/components/FontStyleButtons";
import TextHighlightButton from "../../components/WYSIWYGToolbar/components/TextHighlightButton";
import FontSizeButton from "../../components/WYSIWYGToolbar/components/FontSizeButton";
import {
  colors,
  colors as standard,
  bgColors as standardHighlight,
} from "../../components/Annotation/AnnotationTextMenu/constants";
import { useTablesDataDispatch, useTablesDataState } from "./TablesDataProvider";
// import { colors as standard, bgColors as standardHighlight } from './constants';
// import buttons from './helpers/buttons';
// import ListButtons from '../../WYSIWYGToolbar/components/ListButtons';
// import { BasePageTextBlockVersion } from '../../../utils/Versioning/basePagesVersioning';
import { useObjectsState } from "../../contexts/ObjectsProvider";
import { ILessonAdditionalSettingsContext, LessonAdditionalSettingsContext } from "../../routes/builderContexts";
// import { IBasicPageAttributes } from '../../../pageTypes/BasicPage_Player/components/IBasePage';
// import ISymbolStyle from '../../Symbol/models/ISymbolStyle';
// import { FormatType, IAnnotation } from '../models/IAnnotation';
// import FillButton from '../../WYSIWYGToolbar/components/FillButton';

const StandardFormats = [
  "background",
  "bold",
  "color",
  "font",
  "italic",
  "link",
  "size",
  "strike",
  "script",
  "underline",
  "blockquote",
  "header",
  "indent",
  "list",
  "align",
  "direction",
  "image",
  "video",
];

type TextMenuType = {
  // attributes: IAnnotation | IBasicPageAttributes | ISymbolStyle,
  bgColors?: string[];
  fontColors?: string[];
  isLabel?: boolean;
  formats?: Array<string>;
  position?: { top: number; left: number };
  selectedFontSize?: string;
  objectId?: string;
  // handleFormatChange(e: any, funct: any, color: any, keyValue: any): void;
  setSelectedFontSize?: React.Dispatch<React.SetStateAction<string>>;
  // version: any
  style: React.CSSProperties;
};

const TableRTEMenu = (props: TextMenuType) => {
  const { bgColors, fontColors, formats, objectId } = props;
  const highlightRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const canDisplay: boolean =
    _.includes(formats, "bold") || _.includes(formats, "italic") || _.includes(formats, "underline");
  const currentColors = useContext<ILessonAdditionalSettingsContext>(LessonAdditionalSettingsContext)?.getColorsList(
    colors,
  );

  const TablesDispatch = useTablesDataDispatch();
  const TablesState = useTablesDataState();
  const objectsState = useObjectsState();

  return (
    <div
      className="text-format-menu"
      id={"tables-rte-menu-" + objectId}
      onMouseDown={(e) => e.stopPropagation()}
      onMouseUp={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
      style={{
        display:
          TablesState.selectedCell !== undefined && objectsState.selectedObjectId[0] === props.objectId
            ? "flex"
            : "none",
        ...props.style,
      }}
    >
      <FontSizeButton version="1.1.2" />
      <FontColorButton colors={fontColors ? fontColors : currentColors} />
      <TextHighlightButton colors={bgColors ? bgColors : currentColors} highlightRef={highlightRef} />
      <FontStyleButtons />
      <AlignButtons />
    </div>
  );
  // } else {
  //   return null;
  // }
};

export default TableRTEMenu;
