import React, { useState, useContext, useEffect } from "react";
//import { IUploaderContext, UploaderContext } from '../../UploaderModal/uploadManagerContext';
import { IUploadManagerContext, UploadManagerContext } from "../interfaces/uploaderInterfaces";
import { IAssetManagementContext, AssetContext } from "../../../routes/builderContexts";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/HeaderIcons/drop-down.svg";
import ActionSelect from "../../ActionSelect/ActionSelect";
//import upArrow from '../../../assets/icons/ld-lesson-data-up-arrow.png'
//import downArrow from '../../../assets/icons/ld-lesson-data-down-arrow.png'
import lodash from "lodash";
// import { loadavg } from 'os';
interface uploadOptionProps {
  inputSelectFieldChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  updateUploadData: (name: string, value: any) => void;
  metaDataTextFieldChange: (name: string, value: string) => void;
  assetMetaData: any;
}

const UploaderOptionFields = (props: uploadOptionProps): JSX.Element => {
  const assetContext: IAssetManagementContext = useContext<IAssetManagementContext>(AssetContext);
  //const uploadManagerContext: IUploaderContext = useContext<IUploaderContext>(UploaderContext);
  const uploadManagerContext: IUploadManagerContext = useContext<IUploadManagerContext>(UploadManagerContext);

  const { aircrafts } = assetContext.lessonDataSettings.aircrafts;
  const manufacturers: any = [];
  const [ataData, setATAData] = useState({
    showing: false,
    ataNumbers: assetContext.lessonMetaData.ataNumberDtos.map((el: any, i: number) => el.ataNumberId),
  });
  const [aircraftData, setAircraftData] = useState(
    //aircraft data is stored as string accesed via the index and ID values from aircrafts array and manufacturers list defined further down
    {
      manufacturer: "", //manufacturerName
      fleet: "", //aircraftFamilyName
      variant: "", //aircraftName
      configuration: "", //configurationName
      showing: false,
    },
  );
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (aircraftData) {
      uploadManagerContext.setAtaIds(ataData.ataNumbers);
      uploadManagerContext.setAircraftIds({
        aircraftId: findAirplane("aircraftId"),
        aircraftFamilyId: findAirplane("aircraftFamilyId"),
        manufacturerId: findAirplane("manufacturerId"),
      });
    }
  }, [aircraftData, ataData]);

  useEffect(() => {
    setAircraftToLesson(true);
  }, []);

  function findAirplane(flag: string) {
    if (aircraftData) {
      if (aircraftData.manufacturer === "")
        if (flag === "name") return "Generic";
        else return null;
      else if (aircraftData.manufacturer && aircraftData.fleet === "") {
        if (flag === "manufacturerId") {
          const manufacturerId = aircrafts.find((el: any, index: number) => {
            return el.manufacturerName === aircraftData.manufacturer;
          })["manufacturerId"];
          return manufacturerId;
        } else if (flag === "name") {
          const manufacturerId = aircrafts.find((el: any, index: number) => {
            return el.manufacturerName === aircraftData.manufacturer;
          })["manufacturerName"];
          return manufacturerId;
        } else return null;
      } else if (aircraftData.manufacturer && aircraftData.fleet && aircraftData.variant === "") {
        if (flag === "aircraftFamilyId") {
          const aircraftFamilyId = aircrafts.find((el: any, index: number) => {
            return el.manufacturerName === aircraftData.manufacturer && el.aircraftFamilyName === aircraftData.fleet;
          })["aircraftFamilyId"];
          return aircraftFamilyId;
        } else if (flag === "name") {
          const manufacturerId = aircrafts.find((el: any, index: number) => {
            return el.manufacturerName === aircraftData.manufacturer;
          })["manufacturerName"];

          const aircraftFamilyId = aircrafts.find((el: any, index: number) => {
            return el.manufacturerName === aircraftData.manufacturer && el.aircraftFamilyName === aircraftData.fleet;
          })["aircraftFamilyName"];
          return `${manufacturerId}  ${aircraftFamilyId}`;
        } else return null;
      } else {
        if (flag === "aircraftId") {
          const aircraftId = aircrafts?.filter((el: any, index: number) => {
            return (
              el.manufacturerName === aircraftData.manufacturer &&
              el.aircraftFamilyName === aircraftData.fleet &&
              el.aircraftName === aircraftData.variant
            );
          });
          return aircraftId && aircraftId[0] && aircraftId[0]["aircraftId"] ? aircraftId[0]["aircraftId"] : -1;
        } else if (flag === "name") {
          const manufacturerId = aircrafts.find((el: any, index: number) => {
            return el.manufacturerName === aircraftData.manufacturer;
          })["manufacturerName"];

          const aircraftFamilyId = aircrafts.find((el: any, index: number) => {
            return el.manufacturerName === aircraftData.manufacturer && el.aircraftFamilyName === aircraftData.fleet;
          })["aircraftFamilyName"];
          const aircraftId = aircrafts.filter((el: any, index: number) => {
            return (
              el.manufacturerName === aircraftData.manufacturer &&
              el.aircraftFamilyName === aircraftData.fleet &&
              el.aircraftName === aircraftData.variant
            );
          })[0]["aircraftName"];
          return `${manufacturerId} ${aircraftFamilyId} ${aircraftId}`;
        } else return null;
      }
    } else {
      return null;
    }
  }
  function setAircraftToLesson(toggle: boolean) {
    if (toggle && aircrafts) {
      const checkM = (el: any) => el.manufacturerId === assetContext.lessonMetaData.manufacturerId;
      const checkA = (el: any) => el.aircraftId === assetContext.lessonMetaData.aircraftId;
      const checkF = (el: any) => el.aircraftFamilyId === assetContext.lessonMetaData.aircraftFamilyId;

      const x = aircrafts
        .filter((el: any, i: number) => checkM(el) || checkM(el) || checkM(el))

        .map((el: any, i: number) => ({
          manufacturer: checkM(el) ? el.manufacturerName : "", //manufacturerName
          fleet: checkF(el) ? el.aircraftFamilyName : "", //aircraftFamilyName
          variant: checkA(el) ? el.aircraftName : "", //aircraftName
          configuration: el.configuration, //configurationName
          showing: true,
        }))[0];

      if (x) {
        setAircraftData(x);
      } else {
        setAircraftData({
          manufacturer: "", //manufacturerName
          fleet: "", //aircraftFamilyName
          variant: "", //aircraftName
          configuration: "", //configurationName
          showing: false,
        });
      }
    } else {
      setAircraftData({
        ...aircraftData,
        manufacturer: "", //manufacturerName
        fleet: "", //aircraftFamilyName
        variant: "", //aircraftName
        configuration: "", //configurationName
      });
    }
  }

  function makeManufacturersList() {
    for (let i = 0; i < aircrafts.length; i++) {
      const manufacturer = aircrafts[i].manufacturerName;
      if (!manufacturers.includes(manufacturer)) {
        manufacturers.push(manufacturer);
      }
    }
    return;
  }

  makeManufacturersList();
  function populateManufacturerFleets(manufacturer: any) {
    const fleets: any[] = [];
    const o = {};
    for (let i = 0; i < aircrafts.length; i++) {
      const fleet = aircrafts[i].aircraftFamilyName;
      if (aircrafts[i].manufacturerName === manufacturer && !fleets.includes(fleet) && fleet !== null) {
        Object.defineProperty(o, fleet, {
          value: populateFleetVariants(fleet),
          enumerable: true,
        });
      }
      fleets.push(fleet);
    }
    return o;
  }

  function populateFleetVariants(fleet: any) {
    const variants = [];
    for (let i = 0; i < aircrafts.length; i++) {
      if (aircrafts[i].aircraftFamilyName === fleet) variants.push(aircrafts[i].aircraftName);
    }
    return { variants };
  }
  const manufacturerList = createAirplaneObjectsWithFields();
  function createAirplaneObjectsWithFields() {
    const manufacturerList: any = {};
    for (let i = 0; i < manufacturers.length; i++) {
      manufacturerList[manufacturers[i]] = {
        fleets: populateManufacturerFleets(manufacturers[i]),
        //configurations: populateManufacturerConfigurations(manufacturers[i]),
      };
    }
    return manufacturerList;
  }
  // function handleATA(e:any) {
  //   let { value } = e.target;
  //   if (e.target.checked) {
  //     setATAData({ ...ataData, ataNumbers: [...ataData.ataNumbers, parseInt(value)] });
  //   } else {
  //     let x = [...ataData.ataNumbers];
  //     x.splice(x.indexOf(parseInt(value)), 1);
  //     setATAData({ ...ataData, ataNumbers: [...x] });

  //   }
  // }

  // function returnAtaNumbers(): number[]{
  //  return ataData.ataNumbers.map((el:any,index:number) => {
  //    return ataNumbers.find((el2:any, index:number) => {
  //     return el2.ataNumberId === el
  //     }).ataIdentifier
  //   })
  // }
  // const changeManufacturer = (event: any) => {
  //   //e.persist();
  //   const eventString = event.target.options[event.target.selectedIndex].text
  //
  //   let newFamilyID = eventString !== "None" ?
  //     lodash.find(assetContext.lessonDataSettings.aircrafts.aircrafts, {aircraftFamilyName: Object.keys(manufacturerList[eventString]?.fleets)[0]})?.aircraftFamilyId || -1
  //     :
  //     ""
  //   let newAircraftID = eventString !== "None" ?
  //     lodash.find(assetContext.lessonDataSettings.aircrafts.aircrafts, {aircraftName: manufacturerList[eventString]?.fleets[Object.keys(manufacturerList[eventString]?.fleets)[0]].variants[0]})?.aircraftId || -1
  //     :
  //     ""
  //   if(eventString === 'None'){
  //     setAircraftData({
  //       ...aircraftData,
  //       variant: '',
  //       fleet: '',
  //       manufacturer: '',
  //     })
  //     props.updateUploadData("aircraftFamilyId",[]);
  //     props.updateUploadData("aircraftId",[]);
  //   }
  //   else {
  //     setAircraftData({
  //       ...aircraftData,
  //       variant: manufacturerList[eventString]?.fleets[Object.keys(manufacturerList[eventString]?.fleets)[0]].variants[0],
  //       fleet: Object.keys(manufacturerList[eventString]?.fleets)[0],
  //       manufacturer: eventString,
  //     });

  //     props.updateUploadData("aircraftFamilyId",[newFamilyID.length ? newFamilyID[0] : newFamilyID]);
  //     props.updateUploadData("aircraftId",[newAircraftID.length ? newAircraftID[0] : newAircraftID]);
  //   }
  //   props.inputSelectFieldChange(event)

  //

  // }

  const changeMetaManufacturer = (value: string) => {
    //e.persist();
    const eventString = value; //getting id needs string

    if (eventString === "None") {
      setAircraftData({
        ...aircraftData,
        variant: eventString,
        fleet: eventString,
        manufacturer: eventString,
      });
      props.updateUploadData("aircraftFamilyId", [
        lodash.find(aircrafts, {
          aircraftFamilyName: "None",
          manufacturerName: eventString,
        }).aircraftFamilyId,
      ]);
      props.updateUploadData("aircraftId", [
        lodash.find(aircrafts, {
          aircraftName: "None",
          manufacturerName: eventString,
        }).aircraftId,
      ]);
    } else {
      const aircraftCopy = lodash.cloneDeep(aircraftData);

      aircraftCopy.variant = " ";
      aircraftCopy.fleet = " ";
      aircraftCopy.manufacturer = eventString;
      setAircraftData(aircraftCopy);
      //props.updateUploadData("aircraftFamilyId",[newFamilyID.length ? newFamilyID[0] : newFamilyID]);
      //props.updateUploadData("aircraftId",[newAircraftID.length ? newAircraftID[0] : newAircraftID]);
    }
    if (props.assetMetaData.aircraftFamilyId[0] && props.assetMetaData.aircraftFamilyId[0] !== " ") {
      changeFleetMetaData("None", eventString);
    }
    props.metaDataTextFieldChange(
      "manufacturerId",
      lodash.find(assetContext.lessonDataSettings.aircrafts.aircrafts, {
        manufacturerName: eventString,
      }).manufacturerId,
    );
  };
  // const changeFleet = (e: any) => {
  //   const eventString = e.target.options[e.target.selectedIndex].text
  //   let newAircraftID = eventString !== "None"?
  //     [lodash.find(assetContext.lessonDataSettings.aircrafts.aircrafts, {aircraftName: manufacturerList[aircraftData.manufacturer]?.fleets[eventString]?.variants[0]})?.aircraftId || -1]
  //     :
  //     []
  //   if(eventString === 'None'){
  //     setAircraftData({
  //       ...aircraftData,
  //       fleet: '',
  //       variant: '',
  //     })
  //     props.updateUploadData("aircraftId",[]);
  //   }
  //   else {
  //     setAircraftData({
  //       ...aircraftData,
  //       fleet: eventString ,
  //       variant: manufacturerList[aircraftData.manufacturer].fleets[eventString].variants[0],
  //     });
  //     props.updateUploadData("aircraftId",[newAircraftID.length ? newAircraftID[0] : newAircraftID]);
  //   }
  //   props.inputSelectFieldChange(e);

  //
  // }
  const changeFleetMetaData = (value: string, manufacturer: string) => {
    const newAircraftID =
      value !== "None"
        ? [
            lodash.find(assetContext.lessonDataSettings.aircrafts.aircrafts, {
              aircraftName: manufacturerList[aircraftData.manufacturer]?.fleets[value]?.variants[0],
            })?.aircraftId || -1,
          ]
        : [];
    if (value === "None") {
      setAircraftData({
        ...aircraftData,
        manufacturer: manufacturer,
        fleet: value,
        variant: value,
      });
      props.updateUploadData("aircraftId", [
        lodash.find(aircrafts, {
          aircraftName: "None",
          aircraftFamilyName: value,
        }).aircraftId,
      ]);
    } else {
      setAircraftData({
        ...aircraftData,
        fleet: value,
        variant: " ",
      });
      const aircraftCopy = lodash.cloneDeep(aircraftData);

      aircraftCopy.variant = " ";
      aircraftCopy.fleet = value;
      setAircraftData(aircraftCopy);
      //props.updateUploadData("aircraftId",[newAircraftID.length ? newAircraftID[0] : newAircraftID]);
    }
    //props.inputSelectFieldChange(e);
    props.metaDataTextFieldChange(
      "aircraftFamilyId",
      lodash.find(assetContext.lessonDataSettings.aircrafts.aircrafts, {
        aircraftFamilyName: value,
      }).aircraftFamilyId,
    );
    if (props.assetMetaData.aircraftId[0] && props.assetMetaData.aircraftId[0] !== " ") {
      changeAircraftIdMetaData("None");
    }
  };
  const changeAircraftIdMetaData = (value: string) => {
    if (value === "None") {
      props.metaDataTextFieldChange(
        "aircraftId",
        lodash.find(assetContext.lessonDataSettings.aircrafts.aircrafts, {
          aircraftName: value,
          aircraftFamilyId: props.assetMetaData.aircraftFamilyId[0],
        }).aircraftId,
      );
    }

    props.metaDataTextFieldChange(
      "aircraftId",
      lodash.find(assetContext.lessonDataSettings.aircrafts.aircrafts, {
        aircraftName: value,
        aircraftFamilyId: props.assetMetaData.aircraftFamilyId[0],
      }).aircraftId,
    );
  };
  useEffect(() => {
    if (props.assetMetaData) {
      const newAircraftData = lodash.cloneDeep(aircraftData);
      if (props.assetMetaData.manufacturerId.length > 0 && props.assetMetaData.manufacturerId[0] !== "-1") {
        //Object.keys(manufacturerList[aircraftData.manufacturer].fleets

        newAircraftData.manufacturer = lodash.find(aircrafts, {
          manufacturerId: parseInt(props.assetMetaData.manufacturerId[0]),
        }).manufacturerName;
      }
      if (props.assetMetaData.aircraftFamilyId > 0 && props.assetMetaData.aircraftFamilyId[0] !== "-1") {
        newAircraftData.fleet = lodash.find(aircrafts, {
          aircraftFamilyId: parseInt(props.assetMetaData.aircraftFamilyId[0]),
        }).aircraftFamilyName;
      }
      if (props.assetMetaData.aircraftId > 0 && props.assetMetaData.aircraftId[0] !== -1) {
        newAircraftData.variant = lodash.find(aircrafts, {
          aircraftId: parseInt(props.assetMetaData.aircraftId[0]),
        }).aircraftName;
      }
      setAircraftData(newAircraftData);
      setLoading(true);
    }
  }, []);
  const populateField = () => {
    const keys: string[] = Object.keys(manufacturerList[aircraftData.manufacturer].fleets).map((el, index, array) => {
      return el !== "None" ? el : "";
    });

    return keys;
  };
  return (
    <React.Fragment>
      <div className="meta-data-container" style={{ marginBottom: "10px" }}>
        {loading === true ? (
          <div className="upload-card-settings">
            <div className="aircraft-settings-container" style={{ width: "100%" }}>
              <div className="uploader-card-row">
                {/* <select
                value={props.assetMetaData.manufacturerId[0] ? lodash.find(aircrafts, {manufacturerId: props.assetMetaData.manufacturerId[0]}).manufacturerName : lodash.find(aircrafts, {manufacturerName: "None"})?.manufacturerName || -1}
                name="manufacturerId"
                className="card-select"
                onChange={(e) => {
                  changeManufacturer(e)
                }}>
                { manufacturers.map((el:any, index:any) => (<option value={lodash.find(assetContext.lessonDataSettings.aircrafts.aircrafts, {manufacturerName: el}).manufacturerId} key={el}>{el}</option>))}
                <option value={-1} hidden={lodash.find(aircrafts, {manufacturerName: "None"}) ? true : false}>None</option>
              </select> */}
                <ActionSelect
                  action={
                    props.assetMetaData.manufacturerId[0]
                      ? lodash.find(aircrafts, {
                          manufacturerId: props.assetMetaData.manufacturerId[0],
                        }).manufacturerName
                      : "Choose"
                  }
                  options={["Choose"]
                    .concat(
                      manufacturers.map((el: any, index: any) => {
                        return (
                          el !== "None" &&
                          lodash.find(assetContext.lessonDataSettings.aircrafts.aircrafts, { manufacturerName: el })
                            .manufacturerName
                        );
                      }),
                    )
                    .concat(["None"])}
                  highlightColor="var(--buttons-color)"
                  defaultBackground="#ffffff"
                  defaulTextColor="var(--cpat-gray)"
                  highlightTextColor="#ffffff"
                  selectOption={changeMetaManufacturer}
                  disabledOptions={["Choose"]}
                  displayValues={["Choose"]
                    .concat(
                      manufacturers.map((el: any, index: any) => {
                        return (
                          el !== "None" &&
                          lodash.find(assetContext.lessonDataSettings.aircrafts.aircrafts, { manufacturerName: el })
                            .manufacturerName
                        );
                      }),
                    )
                    .concat(["None"])}
                  parentClass={"meta-data-input"}
                  parentId={"meta-data-select"}
                  optionWrapperClass={"aircraft-option-container"}
                  optionClass={"aircraft-option"}
                  arrowClass={"meta-data-arrow"}
                />
              </div>
              <div className="uploader-card-row">
                {/* <select
                value={props.assetMetaData.aircraftFamilyId[0] ? props.assetMetaData.aircraftFamilyId[0] : -1 }
                className="card-select"
                name="aircraftFamilyId"
                disabled={!props.assetMetaData.manufacturerId[0] || props.assetMetaData.manufacturerId[0] === lodash.find(aircrafts, {manufacturerName: "None"})?.manufacturerId || props.assetMetaData.manufacturerId[0] === -1 }
                onChange={(e) => {
                  changeFleet(e)
                }}>
                {aircraftData.manufacturer? Object.keys(manufacturerList[aircraftData.manufacturer].fleets).map((el, index, array) => (
                  <option value={lodash.find(assetContext.lessonDataSettings.aircrafts.aircrafts, {aircraftFamilyName: el}).aircraftFamilyId} key={index}>
                    {el}
                  </option>)
                  
                ) : (<></>)}
                <option value={-1}>None</option>
              </select> */}
                <ActionSelect
                  action={
                    props.assetMetaData.aircraftFamilyId[0]
                      ? lodash.find(aircrafts, {
                          aircraftFamilyId: props.assetMetaData.aircraftFamilyId[0],
                        }).aircraftFamilyName
                      : "Choose"
                  }
                  options={aircraftData.manufacturer ? ["Choose"].concat(populateField()).concat(["None"]) : ["Choose"]}
                  highlightColor="var(--buttons-color)"
                  defaultBackground="#ffffff"
                  defaulTextColor="var(--cpat-gray)"
                  highlightTextColor="#ffffff"
                  selectOption={(value: string) => {
                    changeFleetMetaData(value, aircraftData.manufacturer);
                  }}
                  disabledOptions={["Choose"]}
                  displayValues={
                    aircraftData.manufacturer
                      ? ["Choose"].concat(
                          Object.keys(manufacturerList[aircraftData.manufacturer].fleets)
                            .map((el, index, array) => {
                              return el !== "None" ? el : "";
                            })
                            .concat(["None"]),
                        )
                      : ["Choose"]
                  }
                  parentClass={"meta-data-input"}
                  parentId={
                    !props.assetMetaData.manufacturerId[0] ||
                    props.assetMetaData.manufacturerId[0] ===
                      lodash.find(aircrafts, { manufacturerName: "None" })?.manufacturerId ||
                    props.assetMetaData.manufacturerId[0] === -1
                      ? "disabled-option"
                      : "meta-data-select"
                  }
                  optionWrapperClass={"aircraft-option-container"}
                  optionClass={"aircraft-option"}
                  arrowClass={"meta-data-arrow"}
                />
              </div>
              <div className="uploader-card-row">
                {/* <select
              value={props.assetMetaData.aircraftId[0] ? props.assetMetaData.aircraftId[0] : -1}
              className="card-select"
              name="aircraftId"
              disabled={props.assetMetaData.aircraftFamilyId[0] === -1 || !props.assetMetaData.aircraftFamilyId[0] || !props.assetMetaData.manufacturerId[0] || props.assetMetaData.manufacturerId[0] === lodash.find(aircrafts, {manufacturerName: "None"})?.manufacturerId || props.assetMetaData.manufacturerId[0] === -1 }
              onChange={(e) => {
                
                props.inputSelectFieldChange(e)
               
               
              }}>
              {aircraftData && aircraftData.fleet ? manufacturerList[aircraftData.manufacturer].fleets[aircraftData.fleet].variants.map((el:any, index: number, array:any[]) => (
                <option value={lodash.find(assetContext.lessonDataSettings.aircrafts.aircrafts, {aircraftName: el}) ? lodash.find(assetContext.lessonDataSettings.aircrafts.aircrafts, {aircraftName: el}).aircraftId : ""} key={index}>
                  {array.length === 0 ? "n/a" : el}
                </option>
              )): (<></>)}
                <option value={-1}>None</option>
            </select> */}
                <ActionSelect
                  action={
                    props.assetMetaData.aircraftId[0]
                      ? lodash.find(aircrafts, {
                          aircraftId: props.assetMetaData.aircraftId[0],
                        }).aircraftName
                      : "Choose"
                  }
                  options={
                    aircraftData &&
                    aircraftData.fleet &&
                    manufacturerList[aircraftData.manufacturer] &&
                    manufacturerList[aircraftData.manufacturer].fleets
                      ? ["Choose"].concat(
                          manufacturerList[aircraftData.manufacturer].fleets[aircraftData.fleet]?.variants
                            .map((el: any, index: number, array: any[]) => {
                              return el !== "None" ? el : "";
                            })
                            .concat(["None"]),
                        )
                      : ["None"]
                  }
                  highlightColor="var(--buttons-color)"
                  defaultBackground="#ffffff"
                  defaulTextColor="var(--cpat-gray)"
                  highlightTextColor="#ffffff"
                  selectOption={changeAircraftIdMetaData}
                  disabledOptions={["Choose"]}
                  displayValues={
                    aircraftData &&
                    aircraftData.fleet &&
                    manufacturerList[aircraftData.manufacturer] &&
                    manufacturerList[aircraftData.manufacturer].fleets
                      ? ["Choose"].concat(
                          manufacturerList[aircraftData.manufacturer].fleets[aircraftData.fleet]?.variants
                            .map((el: any, index: number, array: any[]) => {
                              return el !== "None" ? el : "";
                            })
                            .concat(["None"]),
                        )
                      : ["None"]
                  }
                  parentClass={"meta-data-input"}
                  parentId={
                    props.assetMetaData.aircraftFamilyId[0] === -1 ||
                    !props.assetMetaData.aircraftFamilyId[0] ||
                    !props.assetMetaData.manufacturerId[0] ||
                    props.assetMetaData.manufacturerId[0] ===
                      lodash.find(aircrafts, { manufacturerName: "None" })?.manufacturerId ||
                    props.assetMetaData.manufacturerId[0] === -1
                      ? "disabled-option"
                      : "meta-data-select"
                  }
                  optionWrapperClass={"aircraft-option-container"}
                  optionClass={"aircraft-option"}
                  arrowClass={"meta-data-arrow"}
                />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className="" style={{ marginBottom: "10px" }}></div>
      {/* <div className="col-12" style={{ marginBottom: "10px" }}>
        <input className="form-control" type="text" placeholder="Enter a Aircraft Placement IDs as a comma separated list (optional)" id="aircraftPlacementIds" value={uploadManagerContext.aircraftPlacementIds} onChange={uploadManagerContext.handleChange} />
      </div>
      <div className="col-12" style={{ marginBottom: "10px" }}>
        <input className="form-control" type="text" placeholder="Enter a custom Version name (optional)" id="versionName" value={uploadManagerContext.versionName} onChange={uploadManagerContext.handleChange} />
      </div>
      <div className="col-12" style={{ marginBottom: "10px" }}>
        <input className="form-control" type="number" placeholder="Enter the ID of client who this asset is being tailored for (optional)" id="tailoredForLmsKey" value={uploadManagerContext.tailoredForLmsKey} min={0} onChange={uploadManagerContext.handleChange} />
      </div> */}
    </React.Fragment>
  );
};

export default UploaderOptionFields;
