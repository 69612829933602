const SceneLights = ( { env } ) => {
    const lightArray = {
        "cabin": [
            {
                "intensity": 0.75,
                "color": 0xffffff,
                "lightType": "ambient"
            }
        ],
        "walkaround": [
            {
                "intensity": 0.75,
                "position": {
                    "x": -1,
                    "y": 0,
                    "z": 0
                },
                "castShadow": false,
                "color": 0xffffff,
                "lightType": "directional"
            },
            {
                "intensity": 0.75,
                "position": {
                    "x": 1,
                    "y": 0,
                    "z": 0
                },
                "castShadow": false,
                "color": 0xffffff,
                "lightType": "directional"
            },
            {
                "intensity": 0.5,
                "position": {
                    "x": 0,
                    "y": -1,
                    "z": 0
                },
                "castShadow": false,
                "color": 0xffffff,
                "lightType": "directional"
            },
            {
                "intensity": 0.75,
                "position": {
                    "x": 0,
                    "y": 1,
                    "z": 0
                },
                "castShadow": true,
                "color": 0xffffff,
                "lightType": "directional"
            },
            {
                "intensity": 0.75,
                "position": {
                    "x": 0,
                    "y": 0,
                    "z": 1
                },
                "castShadow": false,
                "color": 0xffffff,
                "lightType": "directional"
            },
            {
                "intensity": 0.75,
                "position": {
                    "x": 0,
                    "y": 0,
                    "z": -1
                },
                "castShadow": false,
                "color": 0xffffff,
                "lightType": "directional"
            }
        ],
        "cockpit": [

        ]
    }

    const SpawnLights = ({ lightArray }) => {

        return lightArray.map((light, _) => {
            switch(light.lightType) {
                case "directional":
                    return (
                      <directionalLight
                        key={_}
                        position={[light.position.x, light.position.y, light.position.z]}
                        castShadow={light.castShadow}
                        intensity={light.intensity}
                        color={light.color} // light.color
                      />
                    )
                case "point":
                    return (
                      <pointLight
                        key={_}
                        position={[light.position.x, light.position.y, light.position.z]}
                        castShadow={light.castShadow}
                        intensity={light.intensity}
                        color={light.color}
                      />
                    )
                case "ambient":
                    return (
                      <ambientLight
                        key={_}
                        intensity={light.intensity}
                        color={light.color}
                      />
                    )
                case "hemisphere":
                case "spot":
                case "rect":
                default:
                    break;
            }
        })
    }

    return <SpawnLights lightArray={lightArray[env]} />
}
export default SceneLights;
