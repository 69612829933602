import React, { useEffect, useState, useContext } from "react";
import { keyEditorProps, buttonObject } from "../../Interfaces/keyDataInterfaces";
import { IPageContext, PageContext, IAssetManagementContext, AssetContext } from "../../../../routes/builderContexts";
import { IFmsPageManifest } from "../../Interfaces/IFmsPageManifest";
import { IFmsContext, FmsContext } from "../../Interfaces/fmsContext";
import CduLightEditor from "./CduLightEditor";
import blankFunctionKeys from "../../assets/blankFunctionKeyes.json";
import blankAlphaNumericKeyes from "../../assets/blankAlphaNumericKeyes.json";
import addButton, { ReactComponent as AddButtonComponent } from "../../../../assets/icons/Add-gray.svg";
import deleteButton from "../../../../assets/icons/FMSIcons/Remove.svg";
import { AbstractTooltip as Tooltip } from "../../../../components/ToolTip/ToolTip";
import RichTextEditor from "../../../../components/richTextEditor/richTextEditor";
import ActionSelect from "../../../../components/ActionSelect/ActionSelect";
import ConditionMap from "./ConditionMap";
import lodash from "lodash";
import "./keyDataEditor.css";
// import EmptyImage from '../../../../components/EmptyImage'
import blobUrlFormatHelper from "../../../../components/blobUrlFormatHelper";
// import { IHoldingDisplayProps } from '../../../FMS_Player/components/CDUHoldingDisplay'

const KeyDataEditor: React.FC<keyEditorProps> = (props: keyEditorProps) => {
  const pagesContext: IPageContext = useContext<IPageContext>(PageContext);
  const pageManifest: IFmsPageManifest = pagesContext.pageManifest as IFmsPageManifest;
  const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);

  const [buttonIsEnabled, flagButton] = useState<boolean>(false); // determines whether to enable/disable the button for the lesson
  //const [groupIsEnabled, flagGroup] = useState<boolean>(props.validateButtonGroup(props.keyName));
  const [currentButtonData, setButtonData] = useState<buttonObject[]>(pageManifest.actions[props.keyName]); //stores button array object before updating to pagemanifest
  const [buttonHasCondition, setCondition] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [buttonFunctions, setButtonFunctions] = useState<string[]>([]);
  const [userFriendlyFunctionName, setUserFriendlyFunctionName] = useState<string[]>([]);

  const [cellList, setCellList] = useState<string[]>([]);
  const [displayCellList, setDisplayCellList] = useState<string[]>([]);
  const [destinationCells, setDestinationCells] = useState<string[]>([]);
  const [displayDestinationCells, setDisplayDestinationCells] = useState<string[]>([]);
  // const [cellHighlightList, setCellHighlightList] = useState<string[]>([]);
  // const [displayCellHighlightList, setDisplayCellHighlightList] = useState<string[]>([]);
  const imgPath: string | undefined = blobUrlFormatHelper(
    pageManifest?.holdingDisplay?.[fmsContext?.imageToDisplayIndex]?.imagePath,
  );
  const imgList = pageManifest.holdingDisplay
    ? pageManifest.holdingDisplay.map((object, index) => {
        return pageManifest.holdingDisplay[index].imagePath;
      })
    : [];
  const displayImgList = pageManifest.holdingDisplay
    ? pageManifest.holdingDisplay.map((object, index) => {
        return "Holding Image " + (index + 1);
      })
    : [];

  // const [imageToDisplayIndex, setImageToDisplayIndex] = useState(0)
  // const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);
  // const [imgList, setImgList] = useState<IHoldingDisplayProps[]>([])

  const keyManagerKeys = ["knob", "enterKey", "cursorControlPad", "cursorSelectRight", "cursorSelectLeft"];
  const assetContext: IAssetManagementContext = useContext<IAssetManagementContext>(AssetContext);
  // const pageContext:

  // const triggerUploadModal = (e: React.MouseEvent<HTMLDivElement>, imageIndex: number) => {
  //     e.preventDefault();
  //     assetContext.setAssetIndex(imageIndex);
  //     assetContext.setAssetTypeId(4);
  //     // assetContext.showBlobUploader(true);
  //     assetContext.setAssetManagerController({isOpen: true, mode: "ImagesOnly"});
  // }
  const quillBar = [{ align: "" }, { align: "center" }, { align: "right" }, { font: [false, "monospace"] }];
  const quillFonts = ["1rem", "0.9rem", "0.8rem"];
  const quillFormats = [
    "background",
    "bold",
    "color",
    "font",
    "italic",
    "size",
    "strike",
    "script",
    "underline",
    "header",
    "indent",
    "align",
    "direction",
  ];
  useEffect(() => {
    if (props.fromTask) {
      fmsContext.setCriteriaIndex(props.taskIndex);
    }
  }, []);
  useEffect(() => {
    fmsContext.setHighlightFrom("key");
    getButtonData(); //when the user selects a new button when the component is mounted the button object needs to update also
    checkIfEnabled();
    //flagGroup(props.validateButtonGroup(props.keyName));
    checkForCondition();
    validateCduPage();
    determineValidKeyFunctions();
    highlightSelected();
    setCellList(highlightCellOptions(false));
    setDisplayCellList(highlightCellOptions(true));
    setDestinationCells(
      props.destinationList
        .map((dest, index) => {
          return dest;
        })
        .concat(modalCellOptions(false))
        .concat(dropDownOptions(false)),
    );
    setDisplayDestinationCells(
      props.destinationList
        .map((dest, index) => {
          return dest.replace(/_/g, " ").replace("LSK1", "LSK");
        })
        .filter((dest) => dest !== null)
        .filter((dest) => dest !== "INIT")
        .filter((dest) => dest !== "EXEC")
        .filter((dest) => dest !== "LKEY")
        .filter((dest) => dest !== "CALL")
        .filter((dest) => dest !== "FAIL")
        .filter((dest) => dest !== "MSG")
        .filter((dest) => dest !== "OFST")
        .concat(modalCellOptions(true))
        .concat(dropDownOptions(true)),
    );
    // setCellHighlightList(highlightCellOptions(false))
    // setDisplayCellHighlightList(["Remove highlight"].concat(highlightCellOptions(true)))
    // setImgList(pageManifest.holdingDisplay)
    if (pageManifest.pageLayouts && pageManifest.pageLayouts.cursorKey !== props.keyName) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  }, [props.keyName]);

  useEffect(() => {
    pageManifest.actions[props.keyName] = currentButtonData;
    pagesContext.updatePageManifest(pageManifest);
  }, [currentButtonData]);
  useEffect(() => {
    setCellList(highlightCellOptions(false));
    setDisplayCellList(highlightCellOptions(true));
    setDestinationCells(
      props.destinationList
        .map((dest, index) => {
          return dest;
        })
        .filter((dest) => dest !== null)
        .filter((dest) => dest !== "INIT")
        .filter((dest) => dest !== "EXEC")
        .filter((dest) => dest !== "LKEY")
        .filter((dest) => dest !== "CALL")
        .filter((dest) => dest !== "FAIL")
        .filter((dest) => dest !== "MSG")
        .filter((dest) => dest !== "OFST")
        .filter((dest) => dest !== "REQUEST")
        .filter((dest) => dest !== "POS")
        .concat(modalCellOptions(false))
        .concat(dropDownOptions(false)),
    );
    setDisplayDestinationCells(
      props.destinationList
        .map((dest, index) => {
          return dest.replace(/_/g, " ").replace("LSK1", "LSK");
        })
        .filter((dest) => dest !== null)
        .filter((dest) => dest !== "INIT")
        .filter((dest) => dest !== "EXEC")
        .filter((dest) => dest !== "LKEY")
        .filter((dest) => dest !== "CALL")
        .filter((dest) => dest !== "FAIL")
        .filter((dest) => dest !== "MSG")
        .filter((dest) => dest !== "OFST")
        .filter((dest) => dest !== "REQUEST")
        .filter((dest) => dest !== "POS")
        .concat(modalCellOptions(true))
        .concat(dropDownOptions(true)),
    );
    // setCellHighlightList(highlightCellOptions(false))
    // setDisplayCellHighlightList(["Remove highlight"].concat(highlightCellOptions(true)))
  }, [fmsContext.showCduModal]);

  const highlightSelected = () => {
    let selectedKey = props.keyName;

    switch (props.keyName) {
      case "+": {
        selectedKey = "plus/minus";
        break;
      }
      case ".": {
        selectedKey = "decimal";
        break;
      }
    }
    fmsContext.setHighlights([selectedKey]);
  };

  const determineValidKeyFunctions = () => {
    //switch(currentButtonData.)
    if (props.keyName.length === 1 || props.keyName === "SpaceKey") {
      setButtonFunctions(["appendToScratchpad"]);
      setUserFriendlyFunctionName(["Append To Scratchpad"]);
    } else {
      switch (props.keyName) {
        case "ClearKey":
          setButtonFunctions(["clearKeyHandler"]); //changeCduPage
          setUserFriendlyFunctionName(["Clear Scratchpad"]);
          break;
        case "cursorControlPad":
        case "cursorSelectRight":
        case "cursorSelectLeft":
        case "directAccess":
        case "enterKey":
        case "execKey":
        case "knob":
          if (pageManifest.cdu.includes("a220")) {
            setButtonFunctions(["changeCDUCell", "highlightCduCell", "cduKeyPress"]); //changeCduPage
            setUserFriendlyFunctionName(["Change CDU Cell", "Highlight Cell", "CDU Key Press"]);
          } else if (pageManifest.cdu.includes("a350")) {
            setButtonFunctions(["changeCDUCell", "highlightCduCell", "cduKeyPress"]); //changeCduPage
            setUserFriendlyFunctionName(["Change CDU Cell", "Highlight Cell", "CDU Key Press"]);
          } else if (pageManifest.cdu.includes("72-600")) {
            setButtonFunctions(["changeCDUCell"]); //changeCduPage
            setUserFriendlyFunctionName(["Change CDU Cell"]);
          } else {
            setButtonFunctions(["changeCDUCell", "highlightCduCell"]); //changeCduPage
            setUserFriendlyFunctionName(["Change CDU Cell", "Highlight Cell"]);
          }

          break;
        default: {
          if (
            pageManifest.cdu.includes("145") ||
            (pageManifest.cdu.includes("q400") &&
              pageManifest.holdingDisplay !== undefined &&
              pageManifest.holdingDisplay.length > 0)
          ) {
            setButtonFunctions(["changeCDUCell", "changeHoldingImage"]); //changeCduPage
            setUserFriendlyFunctionName(["Change CDU Cell", "Change Holding Image"]);
          } else {
            setButtonFunctions(["changeCDUCell"]); //changeCduPage
            setUserFriendlyFunctionName(["Change CDU Cell"]);
          }
          break;
        }
      }
    }
  };

  const checkIfEnabled = () => {
    //checks on load if button is enabled SHOULD ENABLE IS DISABLED
    let keyName = "";
    switch (props.keyName) {
      case "decimal": {
        keyName = ".";
        break;
      } //special cases for decimal and plus/minus keys having different names between CDU manifest and page manifest
      case "plus/minus": {
        keyName = "+";
        break;
      }
      default: {
        keyName = props.keyName;
        break;
      }
    }
    if (pageManifest.actions[keyName] && pageManifest.actions[keyName].length) {
      flagButton(true);
    } else {
      if (props.fromTask) {
        toggleButtonFunctionality();
        flagButton(true);
      } else {
        flagButton(false);
      }
    }
  };

  const getButtonData = () => {
    //updates button object based on KeyName
    // if(pageManifest.actions[props.keyName] && pageManifest.actions[props.keyName][props.index] && buttonIsEnabled && !pageManifest.actions[props.keyName][props.index].parameter.highlightCduCell){
    //     pageManifest.actions[props.keyName][props.index].parameter["highlightCduCell"] = {keyName: ""}
    // }
    setButtonData(pageManifest.actions[props.keyName]);
  };

  const checkForCondition = () => {
    //checks to see if button had a condition
    if (pageManifest.actions[props.keyName]) {
      pageManifest.actions[props.keyName][0]
        ? pageManifest.actions[props.keyName][0].parameter
          ? pageManifest.actions[props.keyName][0].parameter.changeCDUCell.destination.length
            ? setCondition(true)
            : setCondition(false)
          : setCondition(false)
        : setCondition(false);
    }
  };

  // const setConditionVariables = (name: string, value: string) => { //generic way of updating the conditions object array (will need to check index at somepoint)
  //     const alteredStateButtonData: buttonObject[] = [...currentButtonData];
  //         alteredStateButtonData[props.index] = {
  //             ...alteredStateButtonData[props.index],
  //             conditions: [{
  //             ...alteredStateButtonData[props.index].conditions[0],
  //             [name]: value
  //             }]
  //         };

  //         setButtonData(alteredStateButtonData);
  // }
  const setIndexedConditionVariables = (name: string, value: string, index: number) => {
    const alteredStateButtonData: buttonObject[] = lodash.cloneDeep(currentButtonData);
    alteredStateButtonData[props.index].conditions[index][name] = value;

    setButtonData(alteredStateButtonData);
  };
  const addCondition = () => {
    const alteredStateButtonData: buttonObject[] = [...currentButtonData];
    const newCondition = {
      type: "textAttribute",
      valueToCheck: "",
      placeToCheck: "Scratchpad",
      operator: "isEqual",
    };
    alteredStateButtonData[props.index].conditions
      ? alteredStateButtonData[props.index].conditions.push(newCondition)
      : (alteredStateButtonData[props.index].conditions = [newCondition]);
    setButtonData(alteredStateButtonData);
  };
  const removeCondition = (conditionIndex: number) => {
    if (conditionIndex === 0 && currentButtonData[props.index].conditions.length === 1) {
      removeAllConditions();
    } else {
      const alteredStateButtonData: buttonObject[] = [...currentButtonData];
      const newCondtions = alteredStateButtonData[props.index].conditions.filter((condition, index) => {
        return index !== conditionIndex;
      });
      alteredStateButtonData[props.index].conditions = newCondtions;

      setButtonData(alteredStateButtonData);
    }
  };
  const setParameterCDUCellVariables = (name: string, value: string | string[]) => {
    //modifies values within changeCDUCell function

    const alteredStateButtonData: buttonObject[] = [...lodash.cloneDeep(currentButtonData)];
    if (alteredStateButtonData[props.index] && alteredStateButtonData[props.index].parameter) {
      if (alteredStateButtonData[props.index].parameter !== undefined) {
        /*this works but typescript errors out syaing parameter is possibly undefined despite being in a block where it cannot possibly be undefined 
                    - fixed as of 8/18 but keeping comment if error returns
                */
        alteredStateButtonData[props.index] = {
          ...alteredStateButtonData[props.index],
          parameter: {
            ...alteredStateButtonData[props.index].parameter,
            changeCDUCell: {
              ...alteredStateButtonData[props.index].parameter?.changeCDUCell,
              [name]: value,
            },
          },
        };
        setButtonData(alteredStateButtonData);
      }
    }
  };

  const selectDestinationField = (name: string, value: string) => {
    const alteredStateButtonData: buttonObject[] = [...currentButtonData];
    const tempDestinations = alteredStateButtonData[props.index].parameter.changeCDUCell.destination;
    if (tempDestinations) {
      alteredStateButtonData[props.index].parameter.changeCDUCell.destination[parseInt(name)] = value;
      setButtonData(alteredStateButtonData);
    }
  };

  const setButtonFunction = (value: string) => {
    //sets the buttons function

    const alteredStateButtonData = pageManifest.actions[props.keyName];
    alteredStateButtonData[props.index].function = value;

    setButtonData(alteredStateButtonData);
  };
  // const selectElementChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {  //functions to handle inputs for conditions array
  //     event.preventDefault();
  //     if(currentButtonData[props.index]){
  //         setConditionVariables(event.target.name, event.target.value);
  //     }
  // }

  // const inputElementCHangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     event.preventDefault();
  //     setConditionVariables(event.target.name, event.target.value);
  // }
  // //functions for inputs that affect the parameter object within function keyes

  // const selectParameterField = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //     event.preventDefault()
  //     setParameterCDUCellVariables(event.target.name, event.target.value);

  // }
  // const inputParameterArrayField = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     //THIS IS BREAKING FOR SOME REASON - fixed but function is now depricated
  //     //event.preventDefault();
  //     let destVals = currentButtonData[props.index].parameter.changeCDUCell.destinationValues;
  //     const inputValueUpper = event.target.value.toUpperCase();
  //     if(destVals){
  //         destVals[parseInt(event.target.name)] = inputValueUpper;
  //         setParameterCDUCellVariables("destinationValues", destVals);
  //     }
  //     //setParameterCDUCellVariables(event.target.name, inputValueUpper);
  // }

  const rteInput = (newText: string, index: number) => {
    const alteredStateButtonData = [...currentButtonData];
    alteredStateButtonData[props.index].parameter.changeCDUCell.destinationValues[index] = newText;
    setButtonData(alteredStateButtonData);
  };
  // const rteNewSourceInput = (newText: string) => {
  //     const alteredStateButtonData = [...currentButtonData];
  //     alteredStateButtonData[props.index].parameter.changeCDUCell.newSourceValue = newText;
  //     setButtonData(alteredStateButtonData);
  // }

  // const inputParameterStringField = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     event.preventDefault();
  //     setParameterCDUCellVariables(event.target.name, event.target.value.toUpperCase());
  // }

  //for handling button enabling/disable
  // const toggleGrouping = () => {
  //     props.toggleButtonGroup(props.keyName, !groupIsEnabled);
  // }

  const toggleButtonFunctionality = () => {
    //when a button is enabled it sets it to a template json and an empty array if diabled
    const noScratchpadBlankFunctionKeys = lodash.cloneDeep(blankFunctionKeys);
    noScratchpadBlankFunctionKeys[0].parameter.changeCDUCell.destination[0] = "Title";
    noScratchpadBlankFunctionKeys[0].conditions[0].placeToCheck = "Title";

    if (props.keyName.length === 1 || props.keyName === "SpaceKey") {
      if (!buttonIsEnabled) {
        setButtonData(JSON.parse(JSON.stringify([...blankAlphaNumericKeyes])));
      } else {
        let alteredStateButtonData = [...currentButtonData];
        alteredStateButtonData = [];
        setButtonData(alteredStateButtonData);
      }
    } else if (props.keyName === "ClearKey") {
      const alteredStateButtonData = JSON.parse(JSON.stringify([...blankAlphaNumericKeyes]));
      alteredStateButtonData[props.index].function = "clearKeyHandler";

      setButtonData(alteredStateButtonData);
    } else {
      if (!buttonIsEnabled) {
        setButtonData(
          pageManifest.cdu.includes("145")
            ? JSON.parse(JSON.stringify([...noScratchpadBlankFunctionKeys]))
            : JSON.parse(JSON.stringify([...blankFunctionKeys])),
        );
      } else {
        setButtonData([]);
        if (props.disabledKeyRemoval) {
          props.disabledKeyRemoval();
        }
      }
    }
  };
  const addCellDestination = () => {
    const tempButtonData = [...currentButtonData];

    if (tempButtonData[props.index].parameter.changeCDUCell.destination.length) {
      tempButtonData[props.index].parameter.changeCDUCell.destination.push("Scratchpad");
      tempButtonData[props.index].parameter.changeCDUCell.destinationValues.push("");
    } else {
      tempButtonData[props.index].parameter.changeCDUCell.destination = ["Scratchpad"];
      tempButtonData[props.index].parameter.changeCDUCell.destinationValues = [""];
    }

    setButtonData(tempButtonData);
  };

  const mappedElementChangeHandler = (name: string, value: string, index: number) => {
    if (currentButtonData[props.index]) {
      if (
        name === "type" &&
        value === "cellAttribute" &&
        currentButtonData[props.index].conditions[index].valueToCheck === ""
      ) {
        const tempButtonData = [...currentButtonData];
        //let conditionClone = currentButtonData[props.index].conditions[index];
        tempButtonData[props.index].conditions[index].type = "cellAttribute";
        tempButtonData[props.index].conditions[index].valueToCheck = " ";
        setButtonData(tempButtonData);
      } else {
        setIndexedConditionVariables(name, value, index);
      }
    }
  };
  const removeCellDestination = (name: any) => {
    //event.preventDefault();
    const tempButtonData = [...currentButtonData];
    let newDestinationValues: string[] | undefined = [""];
    let newDestinations: string[] | undefined = [""];

    newDestinations = tempButtonData[props.index]?.parameter?.changeCDUCell.destination.filter((dest, index) => {
      return index !== parseInt(name);
    });
    newDestinationValues = tempButtonData[props.index]?.parameter?.changeCDUCell.destinationValues.filter(
      (deVal, index) => {
        return index !== parseInt(name);
      },
    );
    if (newDestinationValues !== undefined && newDestinations !== undefined) {
      tempButtonData[props.index].parameter.changeCDUCell.destination = newDestinations;
      tempButtonData[props.index].parameter.changeCDUCell.destinationValues = newDestinationValues;
      setButtonData(tempButtonData);
      //setParameterCDUCellVariables("destinationValues", newDestinationValues)
      //setParameterCDUCellVariables("destination", newDestinations);
    }
  };
  const removeFirstDestination = () => {
    const tempButtonData = [...currentButtonData];
    let tempDestinations = currentButtonData[props.index]?.parameter?.changeCDUCell.destinationValues;
    if (tempDestinations !== undefined) {
      tempDestinations = [];
      tempButtonData[props.index].parameter.changeCDUCell.destination = tempDestinations;
      tempButtonData[props.index].parameter.changeCDUCell.destinationValues = tempDestinations;

      setButtonData(tempButtonData);
    }
  };
  const validateCduPage = () => {
    // let pageDictionary = Object.entries(pageManifest.pages);
  };
  const setKeyNameField = (value: string) => {
    const tempButtonData = [...currentButtonData];
    if (tempButtonData[props.index].parameter.highlightCduCell) {
      tempButtonData[props.index].parameter.highlightCduCell.keyName = value;
      setButtonData(tempButtonData);
    }
  };
  const setCduKeyNameField = (value: string) => {
    const tempButtonData = [...currentButtonData];
    if (tempButtonData[props.index].parameter.cduKeyPress) {
      tempButtonData[props.index].parameter.cduKeyPress.keyName = value;
      setButtonData(tempButtonData);
    }
  };
  const removeAllConditions = () => {
    const tempButtonData = [...currentButtonData];

    tempButtonData[props.index].conditions = [];
    setCondition(false);
    setButtonData(tempButtonData);
  };

  const keyIsEnabled = () => {
    return (
      <div className="dataCheckContainer" hidden={keyManagerKeys.includes(props.keyName)}>
        <span
          className="check-container fms-key-enable-checkbox "
          onClick={(event: React.MouseEvent) => {
            event.preventDefault();
            event.stopPropagation();
            toggleButtonFunctionality();
            flagButton(!buttonIsEnabled);
          }}
          hidden={keyManagerKeys.includes(props.keyName)}
        >
          <input className="fms-check-input" type="checkbox" checked={buttonIsEnabled} />
          <span className="cursor-checkmark shaded"></span>
        </span>
        <p className="is-enabled">Enable</p>
      </div>
    );
  };

  const setKeyHoldingImage = (value: any) => {
    // function pathMatch(path:string){
    //   return( path === value)
    // }

    fmsContext.setImageToDisplayIndex(imgList.findIndex((x) => x === value));
    // setImageToDisplayIndex(pageManifest.holdingDisplay.indexOf(value))

    pageManifest.actions[props.keyName][0].parameter.imagePath = value;
    pagesContext.updatePageManifest(pageManifest);
  };

  const actionsDropdown = () => {
    return (
      <label hidden={!buttonIsEnabled} className="actions-label">
        <strong className="actions-strong">Actions:</strong>
        <br />
        {/* <select disabled={!buttonIsEnabled || props.keyName === "ClearKey"} name="function" value={buttonIsEnabled ? currentButtonData[props.index].function : "appendToScratchpad"}> */}
        {/* {buttonFunctions.map((option, index) => (
                        <option key={index} value={option}>
                            {userFriendlyFunctionName[index]}
                        </option>
                    ))} */}
        <ActionSelect
          action={
            buttonIsEnabled &&
            currentButtonData[props.index] &&
            currentButtonData[props.index].function &&
            userFriendlyFunctionName.length > 0 &&
            buttonFunctions.length > 0
              ? userFriendlyFunctionName[buttonFunctions.indexOf(currentButtonData[props.index].function) || 0]
              : "Append To Scratchpad"
          }
          options={buttonFunctions.map((option, index) => {
            return option;
          })}
          highlightColor="#293D54"
          defaultBackground="#ffffff"
          defaulTextColor="#000000"
          highlightTextColor="#ffffff"
          selectOption={setButtonFunction}
          disabledOptions={
            !buttonIsEnabled || props.keyName === "ClearKey"
              ? buttonFunctions.map((option, index) => {
                  return option;
                })
              : []
          }
          displayValues={userFriendlyFunctionName.map((option, index) => {
            return option;
          })}
          parentClass={"spacedInput page-objective-input key-action-select"}
          parentId={"sequenceCriteria"}
          optionWrapperClass={"pageObjective-select-options-container"}
          optionClass={"criteria-select-option"}
        />
        {/* </select> */}
      </label>
    );
  };

  const keyHasCursor = () => {
    /**
       * <div className="dataCheckContainer" hidden={keyManagerKeys.includes(props.keyName)}>
            <span className="check-container fms-key-enable-checkbox " onClick={(event: React.MouseEvent) => {event.preventDefault(); event.stopPropagation(); toggleButtonFunctionality(); flagButton(!buttonIsEnabled); debugger; }} hidden={keyManagerKeys.includes(props.keyName)}>
              <input className="fms-check-input" type="checkbox" checked={buttonIsEnabled} />
              <span className="cursor-checkmark shaded"></span>
          </span>
          <p className="is-enabled">is Enabled</p>       
        </div>
       */

    if (
      pageManifest.cdu.includes("787") &&
      (props.keyName.includes("LSK") ||
        (pageManifest.cdu.includes("787") && fmsContext.currentCDU.groupings.function.hasOwnProperty(props.keyName)))
    ) {
      return (
        <div className="dataCheckContainer" hidden={keyManagerKeys.includes(props.keyName)}>
          <span
            className="check-container fms-key-enable-checkbox "
            onClick={(event: React.MouseEvent) => {
              event.preventDefault();
              event.stopPropagation();
              setCursorKey();
              setChecked(!checked);
            }}
          >
            <input
              className="fms-check-input"
              type="checkbox"
              checked={pageManifest.pageLayouts.cursorKey === props.keyName}
            />
            <span className="cursor-checkmark shaded"></span>
          </span>
          <p className="is-enabled">has Cursor</p>
        </div>
      );
    }
  };

  const setCursorKey = () => {
    if (checked === false) {
      pageManifest.pageLayouts.cursorKey = props.keyName;
      // removeCursor();
      // addCursor();
    } else {
      pageManifest.pageLayouts.cursorKey = pageManifest.cdu.includes("a220") ? "fms1" : "";
      // removeCursor();
    }
    pagesContext.updatePageManifest(pageManifest);
  };

  // const keyStyle = () => {
  //     if (pageManifest.cdu.includes("cdu") && (props.keyName.includes("LSK") || (fmsContext.currentCDU.groupings.function.hasOwnProperty(props.keyName)))) {
  //       return (
  //         <div className="dataCheckContainer">
  //           Style:<select><option>Cyan</option><option>Gray</option></select>
  //         </div>
  //       );
  //     }
  // };

  const keyDataEditorOptions = () => {
    return (
      <div className="dataCheck">
        {keyIsEnabled()}
        {keyHasCursor()}
        {keyOpensModal()}
      </div>
    );
  };
  const modalCellOptions = (display: boolean): string[] => {
    const cellOptions: string[] = [];
    if (
      (pageManifest.cdu.includes("a220") || pageManifest.cdu.includes("a350")) &&
      pageManifest.pageLayouts[pageManifest.bootPage] &&
      pageManifest.pageLayouts[pageManifest.bootPage].modals &&
      pageManifest.pageLayouts[pageManifest.bootPage].modals[fmsContext.currentModal.modalName] &&
      fmsContext.currentModal
    ) {
      Object.keys(pageManifest.pageLayouts[pageManifest.bootPage].modals[fmsContext.currentModal.modalName]).map(
        (fields: any, index: number) => {
          Object.keys(
            pageManifest.pageLayouts[pageManifest.bootPage].modals[fmsContext.currentModal.modalName][fields],
          ).map((dataCells: any, index: number) => {
            cellOptions.push(display ? dataCells.replace(/_/g, " ") : dataCells);
          });
        },
      );
    }

    return lodash.uniq(cellOptions);
  };
  const dropDownOptions = (display: boolean): string[] => {
    const cellOptions: string[] = [];
    if (
      (pageManifest.cdu.includes("a220") || pageManifest.cdu.includes("a350")) &&
      pageManifest.pageLayouts[pageManifest.bootPage] &&
      pageManifest.pageLayouts[pageManifest.bootPage].dropDownCells
    ) {
      Object.keys(pageManifest.pageLayouts[pageManifest.bootPage].dropDownCells).map((menu: string, index: number) => {
        Object.keys(pageManifest.pageLayouts[pageManifest.bootPage].dropDownCells[menu]).map(
          (cells: string, index: number) => {
            cellOptions.push(display ? cells.replace(/_/g, " ") : cells);
          },
        );
      });
    }
    return lodash.uniq(cellOptions);
  };
  const highlightCellOptions = (display: boolean): string[] => {
    const cellOptions: string[] = [];
    if (pageManifest.cdu.includes("a220") || pageManifest.cdu.includes("a350")) {
      fmsContext.currentModal.rectangularObjects &&
        fmsContext.currentModal.rectangularObjects.map((rect: any, index: number) => {
          cellOptions.push(display ? rect.cduKeyName.replace(/_/g, " ") : rect.cduKeyName);
        });
      fmsContext.currentCDUPageData.rectangularObjects.map((rect: any, index: number) => {
        cellOptions.push(display ? rect.cduKeyName.replace(/_/g, " ") : rect.cduKeyName);
      });
      fmsContext.currentCDUPageData.foreignObjects.map((cell: any, index: number) => {
        cellOptions.push(display ? cell.cduKeyName.replace(/_/g, " ") : cell.cduKeyName);
      });
      const modalCells = modalCellOptions(display);
      const dropdownCells = dropDownOptions(display);
      modalCells.map((cell) => {
        cellOptions.push(display ? cell.replace(/_/g, " ") : cell);
      });
      //cellOptions.concat(modalCells);
      dropdownCells.map((cell) => {
        cellOptions.push(display ? cell.replace(/_/g, " ") : cell);
      });
      //cellOptions.concat(dropdownCells)
      return lodash.uniq(cellOptions);
    } else {
      fmsContext.keyList.map((keyName, index) => {
        if (keyName.includes("LSKR") || keyName.includes("LSKL") || keyName.includes("Key")) {
          cellOptions.push(display ? keyName.replace(/_/g, " ") : keyName);
        }
      });
      return lodash.uniq(cellOptions);
    }
    // return cellOptions
  };
  const pageTemplateKeyList = (display: boolean): string[] => {
    const cellOptions: string[] = [];
    if (pageManifest.cdu.includes("a220") || pageManifest.cdu.includes("a350")) {
      fmsContext.currentCDUPageData &&
        fmsContext.currentCDUPageData.rectangularObjects.map((rect: any, index: number) => {
          cellOptions.push(display ? rect.cduKeyName.replace(/_/g, " ") : rect.cduKeyName);
        });
      if (
        fmsContext.currentModal &&
        pageManifest.pageLayouts[pageManifest.bootPage].modals &&
        pageManifest.pageLayouts[pageManifest.bootPage].modals[fmsContext.currentModal.modalName]
      ) {
        fmsContext.currentModal.rectangularObjects.map((rect: any, index: number) => {
          cellOptions.push(display ? rect.cduKeyName.replace(/_/g, " ") : rect.cduKeyName);
        });
      }
    }

    return lodash.uniq(cellOptions);
  };
  const keyOpensModal = () => {
    /***************************************************************************************
     **  The direct access key on the A220 needs to open the Direct access menu           **
     ** if more keys need this functionality we will need to look into modularizing but   **
     ** for the time being, this is the only key that needs this checkbox                 **
     ***************************************************************************************/

    const openModalFromKeyEditor = () => {
      if (fmsContext.currentModal.modalName === "DIRECTACCESSKEY") {
        fmsContext.setShowCduModal(false);
        fmsContext.setCurrentModal({});
      } else {
        fmsContext.fetchCduModal("DIRECTACCESSKEY");
      }
    };
    return (
      <>
        {pageManifest.cdu.includes("a220") && props.keyName === "directAccess" ? (
          <div className="openKeyModal-container">
            {/* <input type="checkbox" onChange={openModalFromKeyEditor} checked={fmsContext.showCduModal === true  && fmsContext.currentModal.modalName === "DIRECTACCESSKEY"}> */}
            <span
              className="check-container fms-key-enable-checkbox "
              onClick={(event: React.MouseEvent) => {
                event.preventDefault();
                openModalFromKeyEditor();
              }}
              hidden={keyManagerKeys.includes(props.keyName)}
            >
              <input
                className="fms-check-input"
                type="checkbox"
                checked={fmsContext.showCduModal === true && fmsContext.currentModal.modalName === "DIRECTACCESSKEY"}
              />
              <span className="cursor-checkmark shaded"></span>
            </span>

            {/* </input> */}
            <p className="openMenu-label">Open Menu</p>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };
  useEffect(() => {
    return () => {
      fmsContext.setHighlightFrom("");
      fmsContext.setHighlights([]);
    };
  }, []);

  return (
    <div className="editor-text padding-all-sides-10">
      <div className="row">
        <div className="col-12">
          <p className="strong-header">
            <strong>Key Configuration</strong>
          </p>
          <p className="instructions-deletebutton key-header" hidden={keyManagerKeys.includes(props.keyName)}>
            <strong>{props.keyName}</strong>
          </p>
          <div className="dataCheck">{keyDataEditorOptions()}</div>
        </div>
      </div>
      <div className="row">
        <div className="col-12" style={{ paddingLeft: "1.5%" }}>
          {actionsDropdown()}
        </div>
        <div className="col-12" style={{ paddingLeft: "1.5%" }}>
          <hr className="white-hr" />
          {buttonIsEnabled ? (
            currentButtonData[props.index]?.conditions.length > 0 &&
            currentButtonData[props.index].conditions[0] &&
            currentButtonData[0].parameter ? (
              <>
                <p>
                  <strong>Conditions</strong>
                </p>

                <ConditionMap
                  cdu={pageManifest.cdu}
                  buttonIsEnabled={buttonIsEnabled}
                  condition={currentButtonData[props.index].conditions[0]}
                  index={0}
                  deleteButton={deleteButton}
                  keyList={fmsContext.keyList}
                  destinationList={props.destinationList}
                  mappedElementChangeHandler={mappedElementChangeHandler}
                  removeCondition={removeCondition}
                  highlightCellOptions={highlightCellOptions}
                  modalCellOptions={modalCellOptions}
                  dropDownOptions={dropDownOptions}
                  cellList={destinationCells}
                  displayCellList={destinationCells}
                />
                <hr className="condition-hr" />
                {currentButtonData[props.index]?.conditions.length > 1 ? (
                  currentButtonData[props.index].conditions.map((condition, conditionIndex) =>
                    conditionIndex > 0 ? (
                      <>
                        <ConditionMap
                          cdu={pageManifest.cdu}
                          key={conditionIndex}
                          buttonIsEnabled={buttonIsEnabled}
                          condition={condition}
                          index={conditionIndex}
                          keyList={fmsContext.keyList}
                          destinationList={props.destinationList}
                          deleteButton={deleteButton}
                          mappedElementChangeHandler={mappedElementChangeHandler}
                          removeCondition={removeCondition}
                          highlightCellOptions={highlightCellOptions}
                          modalCellOptions={modalCellOptions}
                          dropDownOptions={dropDownOptions}
                          cellList={destinationCells}
                          displayCellList={destinationCells}
                        />
                        <hr className="condition-hr" />
                      </>
                    ) : (
                      <></>
                    ),
                  )
                ) : (
                  <></>
                )}

                <div className="col-12 centered-div">
                  <figure
                    className="add-icon-figure shaded"
                    onClick={addCondition}
                    data-tooltip-id="add first fms condition"
                  >
                    <Tooltip id="add first fms condition" />
                    <img className="addDestinationButton" src={addButton}></img>
                  </figure>
                </div>
                <hr className="white-hr" />
                <div className="row">
                  {currentButtonData[props.index].parameter ? (
                    <div className="col-12 ">
                      {currentButtonData[props.index].function === "changeCDUCell" ? (
                        <div>
                          <p style={{ margin: "2% 0" }}>
                            <strong>
                              Cells/Fields To Update
                              <br />
                            </strong>
                          </p>
                          {currentButtonData[props.index].parameter.changeCDUCell.destination[0] ? (
                            <>
                              <div className="dest-menu-container">
                                <div className="remove-dest-container">
                                  <figure
                                    className="remove-dest-figure shaded"
                                    onClick={() => {
                                      currentButtonData[props.index].parameter.changeCDUCell.destination.length > 1
                                        ? removeCellDestination(`${0}`)
                                        : removeFirstDestination();
                                    }}
                                  >
                                    <img className="removeDestinationButton" src={deleteButton}></img>
                                  </figure>
                                </div>
                                <ActionSelect
                                  action={currentButtonData[props.index].parameter.changeCDUCell.destination[0]}
                                  options={destinationCells}
                                  highlightColor="#293D54"
                                  defaultBackground="#ffffff"
                                  defaulTextColor="#000000"
                                  highlightTextColor="#ffffff"
                                  selectOption={(value: string) => {
                                    selectDestinationField("0", value);
                                  }}
                                  disabledOptions={
                                    !buttonIsEnabled || props.keyName === "ClearKey" ? destinationCells : []
                                  }
                                  displayValues={displayDestinationCells}
                                  parentClass={"spacedInput page-objective-input key-action-select destination-select"}
                                  parentId={"sequenceCriteria"}
                                  optionWrapperClass={"pageObjective-select-options-container"}
                                  optionClass={"criteria-select-option"}
                                />
                              </div>
                              <div className="rte-container">
                                <RichTextEditor
                                  displayText={
                                    currentButtonData[props.index].parameter.changeCDUCell.destinationValues[0]
                                  }
                                  assignNewText={(newText: string) => {
                                    rteInput(newText, 0);
                                  }}
                                  placeholder={""}
                                  module={quillBar}
                                  fontSize={quillFonts}
                                  pageFormats={quillFormats}
                                />
                                <hr className="condition-hr" />
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {currentButtonData[props.index]?.parameter?.changeCDUCell.destination.length > 1 ? (
                            <>
                              {currentButtonData[props.index]?.parameter?.changeCDUCell.destination.map(
                                (destination, index) => (
                                  <div key={index}>
                                    {index > 0 ? (
                                      <>
                                        <div className="dest-menu-container">
                                          {/* <hr className="condition-hr"/> */}
                                          <div className="remove-dest-container">
                                            <figure
                                              className="remove-dest-figure shaded"
                                              onClick={() => {
                                                removeCellDestination(`${index}`);
                                              }}
                                            >
                                              <img className="removeDestinationButton" src={deleteButton}></img>
                                            </figure>
                                          </div>
                                          {currentButtonData[props.index]?.parameter?.changeCDUCell.destination[
                                            index
                                          ] ? (
                                            <ActionSelect
                                              action={destination}
                                              options={destinationCells}
                                              highlightColor="#293D54"
                                              defaultBackground="#ffffff"
                                              defaulTextColor="#000000"
                                              highlightTextColor="#ffffff"
                                              selectOption={(value: string) => {
                                                selectDestinationField(`${index}`, value);
                                              }}
                                              disabledOptions={
                                                !buttonIsEnabled || props.keyName === "ClearKey" ? destinationCells : []
                                              }
                                              displayValues={displayDestinationCells}
                                              parentClass={
                                                "spacedInput page-objective-input key-action-select destination-select"
                                              }
                                              parentId={"sequenceCriteria"}
                                              optionWrapperClass={"pageObjective-select-options-container "}
                                              optionClass={"criteria-select-option"}
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                        <div className="rte-container">
                                          {currentButtonData[props.index].parameter.changeCDUCell.destinationValues[
                                            index
                                          ] ||
                                          currentButtonData[props.index].parameter.changeCDUCell.destinationValues[
                                            index
                                          ] === "" ? (
                                            <RichTextEditor
                                              displayText={
                                                currentButtonData[props.index].parameter.changeCDUCell
                                                  .destinationValues[index]
                                              }
                                              assignNewText={(newText: string) => {
                                                rteInput(newText, index);
                                              }}
                                              placeholder={""}
                                              module={quillBar}
                                              fontSize={quillFonts}
                                              pageFormats={quillFormats}
                                            />
                                          ) : (
                                            <></>
                                          )}
                                          <hr className="condition-hr" />
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                ),
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          <div className="col-12 centered-div">
                            <figure className="add-icon-figure shaded" onClick={addCellDestination}>
                              <img className="addDestinationButton" src={addButton}></img>
                            </figure>
                          </div>
                        </div>
                      ) : (
                        <>
                          {currentButtonData[props.index].function === "highlightCduCell" ? (
                            <>
                              <label>
                                Highlighted Key
                                <ActionSelect
                                  action={
                                    currentButtonData[props.index].parameter.highlightCduCell.keyName === " "
                                      ? "Remove Highlight"
                                      : currentButtonData[props.index].parameter.highlightCduCell.keyName
                                  }
                                  options={cellList}
                                  highlightColor="#293D54"
                                  defaultBackground="#ffffff"
                                  defaulTextColor="#000000"
                                  highlightTextColor="#ffffff"
                                  selectOption={(value: string) => {
                                    setKeyNameField(value);
                                  }}
                                  disabledOptions={[]}
                                  displayValues={displayCellList}
                                  parentClass={"spacedInput page-objective-input key-action-select"}
                                  parentId={"sequenceCriteria"}
                                  optionWrapperClass={"pageObjective-select-options-container"}
                                  optionClass={"criteria-select-option"}
                                />
                              </label>
                            </>
                          ) : (
                            <></>
                          )}
                          {currentButtonData[props.index].function === "cduKeyPress" ? (
                            <>
                              <label>
                                Selected Button
                                <ActionSelect
                                  action={
                                    currentButtonData[props.index].parameter.cduKeyPress.keyName
                                      ? currentButtonData[props.index].parameter.cduKeyPress.keyName
                                      : "Remove Button"
                                  }
                                  options={[""].concat(pageTemplateKeyList(false))}
                                  highlightColor="#293D54"
                                  defaultBackground="#ffffff"
                                  defaulTextColor="#000000"
                                  highlightTextColor="#ffffff"
                                  selectOption={(value: string) => {
                                    setCduKeyNameField(value);
                                  }}
                                  disabledOptions={[]}
                                  displayValues={["Remove Button"].concat(pageTemplateKeyList(true))}
                                  parentClass={"spacedInput page-objective-input key-action-select cdu-key-press"}
                                  parentId={"sequenceCriteria"}
                                  optionWrapperClass={"pageObjective-select-options-container cdu-key-press-options"}
                                  optionClass={"criteria-select-option"}
                                />
                              </label>
                            </>
                          ) : (
                            <></>
                          )}
                          {currentButtonData[props.index].function === "changeHoldingImage" &&
                          pageManifest.holdingDisplay !== undefined &&
                          pageManifest.holdingDisplay.length > 0 ? (
                            <div>
                              <p style={{ margin: "2% 0" }}>
                                <strong>
                                  Choose Image To Update
                                  <br />
                                </strong>
                              </p>
                              {/* {console.log(pageManifest.holdingDisplay, imageToDisplayIndex)} */}
                              <div className="holding-task-edit">
                                <ActionSelect
                                  action={displayImgList[fmsContext.imageToDisplayIndex]}
                                  options={imgList}
                                  highlightColor="#293D54"
                                  defaultBackground="#ffffff"
                                  defaulTextColor="#000000"
                                  highlightTextColor="#ffffff"
                                  selectOption={(value: string) => {
                                    setKeyHoldingImage(value);
                                  }}
                                  disabledOptions={[]}
                                  displayValues={displayImgList}
                                  parentClass={"spacedInput page-objective-input key-action-select cdu-key-press"}
                                  parentId={"sequenceCriteria"}
                                  optionWrapperClass={"pageObjective-select-options-container cdu-key-press-options"}
                                  optionClass={"criteria-select-option"}
                                />
                                <div className="holding-task-edit-fms-holding-img-container">
                                  <img className="fms-holding-img" src={imgPath}></img>
                                  {/* <img className="add-holding-img" src={addButton}></img> */}
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            ) : (
              <>
                <p>Add Conditon</p>
                <div
                  className="centered-div first-condition-container"
                  style={{ width: "100%" }}
                  data-tooltip-id="add first fms condition"
                >
                  <figure
                    id={"add-first-fms-condition"}
                    className={
                      props.keyName === "ClearKey" || props.keyName.length === 1 || props.keyName === "SpaceKey"
                        ? "add-first-condition-figure shaded  disabled-button"
                        : "add-first-condition-figure shaded "
                    }
                    onClick={() => {
                      addCondition();
                      setCondition(!buttonHasCondition);
                    }}
                  >
                    <button
                      id={"add-first-fms-condition"}
                      className="rowElement bottomButton"
                      disabled={
                        props.keyName === "ClearKey" || props.keyName.length === 1 || props.keyName === "SpaceKey"
                      }
                    >
                      <AddButtonComponent id={"add-first-fms-condition"} />
                    </button>
                  </figure>
                </div>
              </>
            )
          ) : (
            <></>
          )}
          {/* {actionEditorOptions()} */}
        </div>
        {buttonIsEnabled ? <CduLightEditor cduKeyName={props.keyName} fromTask={props.fromTask} /> : <></>}

        {/* <CursorControlDataEditor keyName={props.keyName} destinationList={fmsContext.keyList}/> */}
      </div>
    </div>
  );
};

export default KeyDataEditor;
