import { FieldTypes, PanelProperty } from "./types";
import { TextProperty } from "./components/TextProperty";
import { ElementType } from "react";
import { TextareaProperty } from "./components/TextareaProperty";
import { AudioProperty } from "./components/AudioProperty";
import { CheckboxProperty } from "./components/CheckboxProperty";

export const typeToComponentMap: Record<string, ElementType> = {
  [FieldTypes.TEXT]: TextProperty,
  [FieldTypes.TEXTAREA]: TextareaProperty,
  [FieldTypes.AUDIO]: AudioProperty,
  [FieldTypes.CHECKBOX]: CheckboxProperty,
};

export const defaultFieldProperties = <T>(data: PanelProperty<T>): PanelProperty<T> => {
  return {
    readOnly: false,
    disabled: false,
    visible: () => true,
    ...data,
  };
};
