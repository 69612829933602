import AddColumnButton from "./AddColumnButton";
import AddRowButton from "./AddRowButton";
import RowHeightControls from "./RowHeightControls";
import ColumnWidthControls from "./ColumnWidthControls";
import { useObjectsState } from "../../contexts/ObjectsProvider";

function EditTableMenu() {
  const objectsState = useObjectsState();

  // if objectsState.tables has the selectedObjectId, then render the menu
  return objectsState.tables.some((table) => table.objectId === objectsState.selectedObjectId[0]) ? (
    <div id="custom-table" className="flex-row">
      <AddRowButton />
      <AddColumnButton />
      {/* <ColumnWidthControls/> */}
      <RowHeightControls />
      <ColumnWidthControls />
    </div>
  ) : (
    <></>
  );
}

export default EditTableMenu;
