import React from "react";
import { bgColors } from "../../Annotation/AnnotationTextMenu/constants";
import { FormatButtonType } from "../WYSIWYGToolbar";
import ColorPicker from "./ColorPicker";
import { AbstractTooltip as Tooltip } from "../../ToolTip/ToolTip";
const FillButton = (props: FormatButtonType) => {
  const { handleFormatChange } = props;
  const [isShown, setIsShown]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] =
    React.useState<boolean>(false);
  return (
    <React.Fragment>
      <div className="toolbar-btn-container">
        <button
          className="wysiwyg-toolbar-btn"
          id="fill"
          onClick={() => setIsShown(!isShown)}
          data-tooltip-id="FillColor"
        >
          <Tooltip id="FillColor" />
          <span className="icon-Fill_Option_1"></span>
        </button>
        {isShown ? (
          <ColorPicker
            isShown={isShown}
            keyValue={"backgroundColor"}
            handleFormatChange={handleFormatChange}
            setIsShown={setIsShown}
            colors={bgColors}
          />
        ) : (
          <></>
        )}
      </div>
    </React.Fragment>
  );
};

export default FillButton;
