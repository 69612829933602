import "./QuizWYSIWYG.css";
import _ from "lodash";
import React, { useState } from "react";
import IQuizManifest from "./IQuizPlayer";

type QuizWYSIWYGType = {
  imageSource: string;
  pageManifest: IQuizManifest;
};

const QuizWYSIWYG = (props: QuizWYSIWYGType) => {
  const { imageSource, pageManifest } = props;

  const [showCorrectAnswer]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState<boolean>(false);
  const [selectedIndex]: [number, React.Dispatch<React.SetStateAction<number>>] = useState<number>(-1);

  const displayAnswers = (): JSX.Element => {
    return (
      <React.Fragment>
        <h3 className="QuizquestionCss">{pageManifest.question.text}</h3>
        <div className="QuizanswerStyle-container">
          {pageManifest.choices.map((choice: any, index: number) => {
            return (
              <div
                key={index}
                className={showCorrectAnswer && choice.isCorrect ? "correct-answer-border" : "QuizanswerStyle"}
              >
                <input type="radio" name="answer" id={`answer-${index}`} checked={index === selectedIndex} readOnly />
                <label htmlFor="answer" className="answer-label">
                  {choice.text}
                </label>
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="Quizcenter">
        <div className="quiz-image">
          <div className="img-aspect-container">
            <div className="img-aspect-container-inside">
              <div className="img-aspect-centering">
                <img src={props.imageSource} className="Quizimg" alt="null" />
              </div>
            </div>
          </div>
        </div>
        <div className="Quizbasic">
          {displayAnswers()}
          <button id="quizSubmitButton" className="Quizsubmit">
            Submit
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default QuizWYSIWYG;
