import { useState } from "react";

export type UseModalStateReturnType = {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  onClose: () => void;
};

export const useModalState = (): UseModalStateReturnType => {
  const [show, setOpen] = useState(false);

  const handleOnClose = () => {
    setOpen(false);
  };

  return {
    isOpen: show,
    setOpen: setOpen,
    onClose: handleOnClose,
  };
};
