import { useTimeline } from "../contexts/TimelineProvider/TimelineProvider";
import { useAnimatedObject } from "./useAnimatedObject";

export function useObjectIsInTime(objectId?: string) {
  const animatedObjectId = useAnimatedObject(objectId);
  const [{ scrubbingCurrentTime, sequenceLength }] = useTimeline();
  if (!animatedObjectId) return true;
  const start = animatedObjectId.start;
  const end = animatedObjectId.end;
  const isInTime = scrubbingCurrentTime >= start && scrubbingCurrentTime <= (end ?? sequenceLength);
  return isInTime;
}
