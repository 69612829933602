import * as stringReplace from "./stringReplace";
import { IStringUtils } from "../";
import { CSSProperties } from "react";
const stringU: IStringUtils = {
  ...stringReplace,
};
const { replaceInvalidChars, lowerCaseExtension } = stringReplace;
export { replaceInvalidChars, lowerCaseExtension };
export default stringU;

export const kebabToCamelCase = (str: string) => {
  return str.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
};

export const styleStringToObject = (str: string | undefined, extraProperties?: CSSProperties): CSSProperties => {
  if (!str) return {} as CSSProperties;

  const styleObject = Object.fromEntries(
    str
      .split(";")
      .filter(Boolean)
      .map((style) => style.split(":").map((s) => s.trim()))
      .map(([key, value]) => [kebabToCamelCase(key), value]),
  );

  return { ...styleObject, ...extraProperties } as CSSProperties;
};
