import "../../components/PageActionLister/PageEditorActionLister.css";
import _ from "lodash";
import React, { Dispatch, SetStateAction, useContext, useState, useEffect } from "react";
import { IPageContext, PageContext } from "../../routes/builderContexts";
import ILessonPageDto from "../../models/ILessonPageDto";
import { BASIC_PAGE, FMS_PAGE, ThreeD_PAGE, WALK_AROUND, CABIN } from "../../components/constants/pageTypes";
import IQuizManifest from "./IQuizInterfaces";
import QuizRandomizer from "./QuizActionButtons/QuizRandomizer";
import QuizReviewSelect from "./QuizActionButtons/QuizReviewSelect";
import { AbstractTooltip as Tooltip } from "../../components/ToolTip/ToolTip";
type PropTypes = {
  currentIndex: number;
  lessonPages: ILessonPageDto[];
};

const QuizActionLister: React.FC<PropTypes> = (props: PropTypes) => {
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const [isDisabled, setIsDisabled]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState<boolean>(true);
  const [isLinkOn, setIsLinkOn]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState<boolean>(false);
  const [isRandomOn, setIsRandomOn]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(
    pageContext.pageManifest.randomize,
  );
  const updatedManifest: IQuizManifest = _.cloneDeep(pageContext.pageManifest);
  const basePageArray: JSX.Element[] = [];

  useEffect(() => {
    checkBasePagesExist();
    showToggleOnLoad();
    // checkRandomizer();
  }, []);

  useEffect(() => {
    showToggleOnLoad();
  }, [pageContext.currentlyDisplayedPageIndex]);

  useEffect(() => {
    setIsRandomOn(updatedManifest.randomize);
  }, [updatedManifest.randomize]);

  // const checkRandomizer = () => {
  //   if(!updatedManifest.randomize) {
  //     setIsRandomOn(updatedManifest.randomize);
  //   }
  // }

  const showToggleOnLoad = () => {
    if (!pageContext?.helperReference) {
      setIsLinkOn(false);
    } else if (pageContext?.helperReference > 3) {
      setIsLinkOn(true);
    }
  };

  const checkBasePagesExist = (): void => {
    let count = 0;
    props.lessonPages.forEach((page, index) => {
      if (
        (page.pagePlayerType === BASIC_PAGE ||
          page.pagePlayerType === ThreeD_PAGE ||
          page.pagePlayerType === FMS_PAGE ||
          page.pagePlayerType === WALK_AROUND ||
          page.pagePlayerType === CABIN) &&
        page.order > 3 &&
        page.order < props.currentIndex
      ) {
        count++;
      }
    });
    if (count > 0) {
      setIsDisabled(false);
    }
  };

  const listBasePages = (): JSX.Element[] => {
    props.lessonPages.forEach((page, index) => {
      if (
        (page.pagePlayerType === BASIC_PAGE ||
          page.pagePlayerType === ThreeD_PAGE ||
          page.pagePlayerType === FMS_PAGE ||
          page.pagePlayerType === WALK_AROUND ||
          page.pagePlayerType === CABIN) &&
        page.order > 3 &&
        page.order < props.currentIndex
      ) {
        basePageArray.push(
          <option key={index} value={page.pageVersionId}>
            {page.order + 1}. {page.name}
          </option>,
        );
      }
    });

    if (_.isEmpty(basePageArray)) {
      basePageArray.push(
        <option value={-1} key={-1}>
          No Pages to Select
        </option>,
      );
    }

    return basePageArray;
  };

  // const handleAttemptsSelect = (e: any) => {
  //   updatedManifest.question.maxAttempts = e.target.value;
  //   pageContext.updatePageManifest(updatedManifest);
  // }

  const handleChange = (e: any): void => {
    let pageOrder: number;
    setIsLinkOn(e.target.checked);
    if (!e.target.checked) {
      pageContext.updateHelperReference(null); //this should be null
    } else {
      if (!pageContext.helperReference) {
        const firstPage: ILessonPageDto | undefined = _.find(props.lessonPages, (page) => {
          return (
            (page.pagePlayerType === BASIC_PAGE ||
              page.pagePlayerType === FMS_PAGE ||
              page.pagePlayerType === ThreeD_PAGE ||
              page.pagePlayerType === WALK_AROUND ||
              page.pagePlayerType === CABIN) &&
            page.order > 3 &&
            page.order < props.currentIndex
          );
        });

        if (!firstPage) {
          pageOrder = -1;
        } else {
          pageOrder = firstPage?.order;
        }
        pageContext.updateHelperReference(firstPage?.pageVersionId as number);
        handleReviewPageSelect(null, pageOrder);
      }
    }
  };

  const handleRandomChange = (e: any): void => {
    setIsRandomOn(e.target.checked);
    updatedManifest.randomize = e.target.checked;
    pageContext.updatePageManifest(updatedManifest);
  };

  const handleReviewPageSelect = (e: React.ChangeEvent<HTMLInputElement> | null, pageOrder?: number) => {
    let page: ILessonPageDto;
    page = props.lessonPages.filter((el: ILessonPageDto, index: number) => {
      if (e) return el.pageVersionId === +e.target.value;
      else if (pageOrder) return el.order === pageOrder;
      else return;
    })[0];

    pageContext.updateHelperReference(+page.pageVersionId);
  };

  // const iterateMaxAttempts = (): JSX.Element[] => {
  //   const maxAttempts: number = pageContext.pageManifest.choices.length;
  //   let optionArray: JSX.Element[] = [];
  //     if(maxAttempts > 0) {
  //         for(let i = 1; i <= maxAttempts; i++) {
  //             optionArray.push(<option value={i} key={i}  >{i}</option>);
  //         }
  //     }
  //     return optionArray;
  // }

  return (
    <React.Fragment>
      {/* <div className="pageEditorActionListerRow">
          <QuizAttemptsSelect attempts={updatedManifest.question.maxAttempts} handleAttemptsSelect={handleAttemptsSelect} iterateMaxAttempts={iterateMaxAttempts}/>
        </div> */}
      <div className="pageEditorActionListerRow" data-tooltip-id="Review Link">
        <Tooltip id="Review Link" />
        <QuizReviewSelect
          isDisabled={isDisabled}
          isLinkOn={isLinkOn}
          handleChange={handleChange}
          handleReviewPageSelect={handleReviewPageSelect}
          listBasePages={listBasePages}
          lessonPages={props.lessonPages}
        />
      </div>
      <div className="pageEditorActionListerRow" data-tooltip-id="Randomize Answers">
        <Tooltip id="Randomize Answers" />
        <QuizRandomizer isRandomOn={isRandomOn} handleRandomChange={handleRandomChange} />
      </div>
    </React.Fragment>
  );
};

export default QuizActionLister;
