import React, { useContext, useEffect, useState } from "react";
import { useLessonData } from "../../contexts/LessonDataProvider";
import { ManufacturerList } from "../../contexts/utils/createFormattedAircrafts";
import MetadataState from "./interfaces/MetadataState";
import { EditorContext } from "./MetaDataEditorContext/MetadataEditorContext";
import {
  getFleetsFromManufacturerList,
  getFleetIdFromManufacturerList,
  getManufacturerId,
  getVariantsFromFleet,
  getVariantIdFromFleet,
} from "./utils/mapManufacturers";
import { MetadataEditorActions } from "./MetaDataEditorContext/MetadataEditorActions";
import Asset from "./interfaces/Asset";
import AssetDataPreview from "./AssetDataPreview";

const NONE_MANUFACTURER_ID = 5;
const NONE_FLEET_ID = 11;
const NONE_VARIANT_ID = 60;

interface Props {
  selectedAsset: Asset | undefined;
  showAssetPreview: boolean;
  children?: React.ReactNode;
  isCpat: boolean;
  validSubmission: boolean;
}

const Sidebar = ({ showAssetPreview, selectedAsset, children, validSubmission }: Props) => {
  const manufacturerList: ManufacturerList = useLessonData({
    formattedAircrafts: true,
  })[1];
  const { dispatch } = useContext(EditorContext);
  const [selectedManufacturer, setSelectedManufacturer] = useState("");
  const [selectedFleet, setSelectedFleet] = useState("");
  const [selectedVariant, setSelectedVariant] = useState("");

  useEffect(() => {
    setSelectedFleet("");
    setSelectedVariant("");
  }, [selectedManufacturer]);

  useEffect(() => {
    if (selectedFleet === "None") {
      const newData: MetadataState = {
        manufacturerId: getManufacturerId(selectedManufacturer, manufacturerList).manufacturerId,
        fleetId: getFleetIdFromManufacturerList(selectedManufacturer, selectedFleet, manufacturerList),
        variantId: getVariantIdFromFleet(manufacturerList[selectedManufacturer], selectedFleet, "None"),
      };
      dispatch({
        type: MetadataEditorActions.SELECT_METADATA,
        payload: newData,
      });
    }
    if (selectedManufacturer === "None") {
      setSelectedFleet("");
      dispatch({
        type: MetadataEditorActions.SELECT_METADATA,
        payload: {
          manufacturerId: NONE_MANUFACTURER_ID,
          fleetId: NONE_FLEET_ID,
          variantId: NONE_VARIANT_ID,
        },
      });
    }
  }, [selectedManufacturer, selectedFleet, selectedVariant]);

  const handleSelectManufacturer = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedManufacturer(e.target.value);
    const newData = getManufacturerId(e.target.value, manufacturerList);
    dispatch({
      type: MetadataEditorActions.SELECT_METADATA,
      payload: newData,
    });
  };

  const handleSelectFleet = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedFleet(e.target.value);
    if (e.target.value === "None") return; // 'None' case is handled in useEffect above
    const selectedFleetId = getFleetIdFromManufacturerList(selectedManufacturer, e.target.value, manufacturerList);
    dispatch({
      type: MetadataEditorActions.SELECT_METADATA,
      payload: { fleetId: selectedFleetId, variantId: undefined },
    });
  };

  const handleSelectVariant = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedVariant(e.target.value);
    const selectedVariantId = getVariantIdFromFleet(
      manufacturerList[selectedManufacturer],
      selectedFleet,
      e.target.value,
    );

    dispatch({
      type: MetadataEditorActions.SELECT_METADATA,
      payload: { variantId: selectedVariantId },
    });
  };

  return (
    <div className="metadata-editor-sidebar">
      {showAssetPreview && selectedAsset && (
        <AssetDataPreview imageUrl={selectedAsset.blobPath} asset={selectedAsset} />
      )}

      <div className="metadata-editor-sidebar-assets-data">
        <p>
          <span id="metadata-editor-warning"> * </span>Asset Data:
        </p>
        <div className="metadata-editor-sidebar-assets-drowpdowns">
          <div className="image-metadata-editor-tag-dropdown">
            <select value={selectedManufacturer} onChange={(e) => handleSelectManufacturer(e)}>
              <option hidden> Choose </option>
              {Object.keys(manufacturerList).map((manufacturer) => (
                <option
                  key={manufacturer}
                  value={manufacturer}
                  onClick={(e) => setSelectedManufacturer(e.currentTarget.value)}
                >
                  {manufacturer}
                </option>
              ))}
            </select>
          </div>

          <div className="image-metadata-editor-tag-dropdown">
            <select
              disabled={selectedManufacturer === "" || selectedManufacturer == "None"}
              onChange={(e) => handleSelectFleet(e)}
            >
              <option hidden>{selectedManufacturer === "None" ? "None" : "Choose"}</option>
              {selectedManufacturer !== "" &&
                getFleetsFromManufacturerList(selectedManufacturer, manufacturerList)?.map((fleet: any) => (
                  <option key={fleet}>{fleet}</option>
                ))}
            </select>
          </div>

          <div className="image-metadata-editor-tag-dropdown">
            <select disabled={selectedFleet === "" || selectedFleet == "None"} onChange={(e) => handleSelectVariant(e)}>
              <option hidden>{selectedManufacturer === "None" || selectedFleet == "None" ? "None" : "Choose"}</option>
              {selectedFleet !== "" &&
                getVariantsFromFleet(selectedFleet, manufacturerList[selectedManufacturer])?.map((variant) => (
                  <option key={variant}> {variant}</option>
                ))}
            </select>
          </div>
        </div>
        {!validSubmission && (
          <p className="metadata-state-warning" id="metadata-editor-warning">
            * Images without data cannot be added to the Asset Library. Please continue with image data assignment.
          </p>
        )}
      </div>
      {children}
    </div>
  );
};

export default Sidebar;
