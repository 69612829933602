import React from "react";
import Portal from "../../Portal/Portal";
import "./DeleteConfirmation.styles.css";
import { ReactComponent as ExitIcon } from "../../../assets/icons/Common/remove-tag-icon.svg";
let resolve: (value?: unknown) => void;
function DeleteConfirmation(props: any) {
  function handleCancel() {
    props.setDeletePageConfirmationModal(false);
    resolve(false);
  }

  function handleConfirm() {
    props.setDeletePageConfirmationModal(false);
    resolve(true);
  }

  return (
    <React.Fragment>
      {props.deletePageConfirmationModal && (
        <Portal path={"delete-confirm-container"}>
          <div className="delete-confirm-modal">
            <div className="delete-confirm-header">
              <p>Remove Page</p>
              <ExitIcon onClick={handleCancel} />
            </div>
            <div className="delete-confirm-text">
              <p>
                All the changes that have been made will be lost. Are you sure that you would like to remove this page?
              </p>
            </div>
            <div className="delete-confirm-buttons-container">
              <button className="main-modal-button" onClick={handleCancel}>
                Cancel
              </button>
              <button className="main-modal-button" onClick={handleConfirm}>
                Confirm
              </button>
            </div>
          </div>
        </Portal>
      )}
    </React.Fragment>
  );
}

DeleteConfirmation.show = (props: any) => {
  props.setDeletePageConfirmationModal(true);
  return new Promise((res, rej) => {
    resolve = res;
  });
};

export default DeleteConfirmation;
