import React, { useMemo, useState } from "react";
import { ReactComponent as XIcon } from "../../../../assets/icons/x-Icon.svg";
import { ReactComponent as ArrowIcon } from "../../../../assets/icons/AssetManager/drop-down-icon.svg";
import { handleAddingNewDefaultTasks, ListedObject } from "./ListedObject";
import { ReactComponent as PlusButton } from "../../../../assets/icons/AssetManager/add-gray-version-icon.svg";
import {
  InteractivityHotspotActionTypes,
  useInteracitvityHotspotDispatch,
} from "../../../../contexts/InteractivityHotspotProvider";
import { Action, Task } from "../../../../lib/interactivity";
import styles from "./PageCompletionHeader.module.css";
import useSettingsToggle, { DEFAULT_SETTING_TOGGLE_PERMISSIONS } from "../../../../hooks/useSettingsToggle";
import {
  LessonPagesActions,
  useLessonPagesDispatch,
} from "../../../../contexts/LessonPagesProvider/LessonPagesProvider";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../../../contexts/ObjectsProvider";
import { HotspotNextClick } from "../../../../types";

export const NON_TARGETABLE_BY_HOTSPOT_OBJECTS = ["SCORM", "video", "hotspot"];
export function ClickGroup({
  hotspot,
  clickIndex,
  objectId,
  onClickDelete,
}: {
  hotspot: HotspotNextClick;
  clickIndex: number;
  objectId: string;
  onClickDelete: () => void;
}) {
  const [visible, setVisible] = useState(true);
  const [settings] = useSettingsToggle(DEFAULT_SETTING_TOGGLE_PERMISSIONS);
  const { objectList } = useObjectsState();
  const objectsDispatch = useObjectsDispatch();
  const alreadyUsedIds = useMemo(() => {
    return hotspot.targets?.map((target) => {
      return target.objectId;
    });
  }, [hotspot.targets]);

  return (
    <div className="click-container-next-step" key={clickIndex}>
      <div className="click-container-next-step-header">
        <XIcon
          style={{ opacity: clickIndex !== 0 ? 1 : 0 }}
          pointerEvents={clickIndex !== 0 ? "all" : "none"}
          onClick={() => {
            // remove all tasks and if not the original hotspot remove whole hotspot click
            onClickDelete();
          }}
        />

        <p>Click - {clickIndex + 1}</p>

        <ArrowIcon
          className={visible ? styles.dropdownArrowActive : styles.dropdownArrowNotActive}
          onClick={() => {
            // ui stuff
            setVisible(!visible);
            // setShowDropDown(!showDropDown);
          }}
        />
      </div>
      {settings.hotspotsOnTimeline && (
        <div className={styles.hotspotActionInput}>
          <label className={true ? styles.inputLabel : styles.inputLabelDisabled}>Resume Timeline After Click</label>
          <input
            type="checkbox"
            checked={Boolean(hotspot.resumeOnClick)}
            onChange={() => {
              objectsDispatch({
                type: ObjectActionsType.UPDATE_HOTSPOT,
                payload: {
                  objectId,
                  clickIndex,
                  hotspot: {
                    resumeOnClick: !hotspot.resumeOnClick,
                  },
                },
              });
            }}
          ></input>
        </div>
      )}
      <>
        {visible && (
          <div className="objects-targets-list">
            {hotspot.targets?.map((target, index) => {
              return (
                <ListedObject
                  target={target}
                  key={index}
                  clickIndex={clickIndex}
                  hotspotObjectId={objectId}
                  alreadyUsedIds={alreadyUsedIds}
                  onTargetChange={(targetId) => {
                    // check if the target is already in the list
                    const targetAlreadyInList = hotspot.targets.find((t) => t.objectId === targetId);
                    if (targetAlreadyInList) {
                      return;
                    }
                    // update the target
                    const newTargets = hotspot.targets.map((t, index) => {
                      if (target.objectId === t.objectId) {
                        return {
                          ...t,
                          objectId: targetId,
                          tasks: t.tasks.map((task) => {
                            return {
                              ...task,
                              targetId,
                            };
                          }),
                        };
                      }
                      return t;
                    });
                    objectsDispatch({
                      type: ObjectActionsType.UPDATE_HOTSPOT,
                      payload: {
                        objectId,
                        clickIndex,
                        hotspot: {
                          targets: newTargets,
                        },
                      },
                    });
                  }}
                  onDeleteTarget={(targetId) => {
                    // delete the target
                    const newTargets = hotspot.targets.filter((t) => {
                      return t.objectId !== targetId;
                    });
                    objectsDispatch({
                      type: ObjectActionsType.UPDATE_HOTSPOT,
                      payload: {
                        objectId,
                        clickIndex,
                        hotspot: {
                          targets: newTargets,
                        },
                      },
                    });
                  }}
                />
              );
            })}
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            padding: "5px",
          }}
        >
          <div
            className="add-new-object-button"
            onClick={() => {
              // add a new target
              const newTargets = [...hotspot.targets];
              const allTargetIds = newTargets.map((target) => {
                return target.objectId;
              });
              const filteredList = objectList.filter((obj) => {
                if (NON_TARGETABLE_BY_HOTSPOT_OBJECTS.includes(obj.type)) {
                  return false;
                }

                if (alreadyUsedIds.includes(obj.objectId)) {
                  return false;
                }

                return true;
              });
              const newTargetId = filteredList.find((obj) => {
                return !allTargetIds.includes(obj.objectId);
              })?.objectId;
              if (!newTargetId) {
                return;
              }

              newTargets.push({
                objectId: newTargetId,
                tasks: [
                  {
                    actionValue: 0,
                    mappedAction: "ROTATE",
                    targetId: newTargetId,
                    actionValueUnit: "deg",
                  },
                  {
                    actionValue: true,
                    mappedAction: "SHOW/HIDE",
                    targetId: newTargetId,
                    actionValueUnit: null,
                  },
                ],
              });

              objectsDispatch({
                type: ObjectActionsType.UPDATE_HOTSPOT,
                payload: {
                  objectId,
                  clickIndex,
                  hotspot: {
                    targets: newTargets,
                  },
                },
              });
            }}
          >
            <PlusButton className="add-click-interactivity-button" />
            <p>Add Object</p>
          </div>
        </div>
      </>
    </div>
  );

  // return <div className="click-group">

  // </div>
}
