import React, { useEffect, useState } from "react";
import { ICduRectangularButton } from "../Interfaces/ICdu";

export interface ICduButtonGroupings {
  cduButtonGroupings: ICduRectangularButton[];
  highlightGroups: string[];
}

const CduButtonGroupings: React.FC<ICduButtonGroupings> = (properties: ICduButtonGroupings): any => {
  const [highlightedGroups, setHighlights] = useState<string[]>([]);
  const [strokeArray, setStroke] = useState<string[]>([]);

  useEffect(() => {
    populateStrokes();
  }, [properties.cduButtonGroupings]);

  useEffect(() => {
    setHighlights(properties.highlightGroups);
  }, [properties.highlightGroups]);

  useEffect(() => {
    populateStrokes();
  }, [highlightedGroups]);

  const isHighlighted = (keyName: string): string => {
    if (highlightedGroups.includes(keyName)) {
      return "#FFFF00";
    } else {
      return " ";
    }
  };

  const populateStrokes = () => {
    const unsetStrokes: string[] = [];
    properties.cduButtonGroupings.forEach((buttonGroup) => {
      unsetStrokes.push(isHighlighted(buttonGroup.id));
    });
    setStroke(unsetStrokes);
  };

  return properties.cduButtonGroupings.map((rectObject: ICduRectangularButton, key: number) => {
    return (
      <rect
        key={key}
        x={rectObject.xCoordinate}
        y={rectObject.yCoordinate}
        width={rectObject.width}
        height={rectObject.height}
        fill="none"
        stroke={strokeArray[key]}
        strokeWidth="2"
        pointerEvents="none"
        id={rectObject.id}
      />
    );
  });
};

export default CduButtonGroupings;
