import React from "react";
// import _ from 'lodash';
import removeIcon from "../../assets/icons/ld-remove-criteria-icon.png";
import { ReactComponent as TrashIcon } from "../../assets/icons/Common/remove-page-icon.svg";
import IQuizManifest from "./IQuizInterfaces";

type RemoveType = {
  updatedManifest: IQuizManifest;
  choiceKey: number;
  removeChoice: (key: number) => void;
};

const QuizRemoveChoice: React.FC<RemoveType> = (props) => {
  // const updatedManifest: any = _.cloneDeep(useContext<IPageContext>(PageContext).pageManifest);

  if (props.updatedManifest.choices.length <= 2) {
    return null;
  } else {
    return <TrashIcon onClick={() => props.removeChoice(props.choiceKey)} className="remove-choice-icon" />;
  }
};

export default QuizRemoveChoice;
