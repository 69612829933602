import React from "react";
import { handleKeyUp, handleKeyPress } from "../../ObjectPropertyBox/functions/PropertyBoxFunctions";
import { AnnotationPropType } from "../Annotation";
import HighlightAnnotation from "../hooks/HighlightAnnotation";
import { useObjectsDispatch, ObjectActionsType } from "../../../contexts/ObjectsProvider";
import { useInterpolatedFrame } from "../../../hooks/useInterpolatedFrame";

const AnnotationPointArrow = (props: AnnotationPropType) => {
  const { annotation, index, kp, markSelected, objectIsInTimeline } = props;
  const isDisplayed = typeof annotation.isDisplayed === "undefined" || annotation.isDisplayed;
  const opacity = objectIsInTimeline ? 1 : isDisplayed ? 1 : 0.5;
  const objectsDispatch = useObjectsDispatch();
  const interpolatedFrame = useInterpolatedFrame(annotation.objectId);
  const transformX = interpolatedFrame?.x;
  const transformY = interpolatedFrame?.y;
  const rotateDeg: number | undefined = annotation?.transform?.rotate ? annotation?.transform?.rotate : 0;
  const style = {
    // top: `${annotation.top.toFixed(2)}%`,
    // left: `${annotation.left.toFixed(2)}%`,
    transform: `translate(${transformX}px, ${transformY}px)`,
    rotate: `${rotateDeg}deg`,
    width: `${annotation.width.toFixed(2)}%`,
    zIndex: annotation.zIndex,
  };
  let point1 = 18;
  let point1b = 10;
  let point2 = 3;
  const point2b = 18;
  let point3b = 25;
  let lineStart = 7;
  if (annotation.strokeWidth) {
    point2 = annotation.strokeWidth;
    point1 = 12 + annotation.strokeWidth;
    point1b = 12 - annotation.strokeWidth * 0.5;
    point3b = 23 + annotation.strokeWidth * 0.5;
    switch (annotation.strokeWidth) {
      case 1:
        lineStart = 2;
        break;
      case 2:
        lineStart = 4;
        break;
      case 3:
        lineStart = 6;
        break;
      case 4:
        lineStart = 8;
        break;
      case 5:
        lineStart = 10;
        break;
      case 6:
        lineStart = 10;
        break;
      case 7:
        lineStart = 12;
        break;
      case 8:
        lineStart = 14;
        break;
      case 9:
        lineStart = 16;
        break;

      default:
        lineStart = 6;
        break;
    }
    // if (annotation.strokeWidth < 4) { lineStart = 4 } else { if (lineStart > 3 && lineStart < 6) { lineStart = 6 } else { lineStart = 8 } }
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="https://www.w3.org/1999/xlink"
      data-objectid={annotation.objectId}
      id={`lineArrow-${index}`}
      tabIndex={0}
      name="pointArrow"
      className="annotation"
      version="1.1"
      // viewBox={`0 0 auto 20`}
      height={`36`}
      preserveAspectRatio="xMinYMid meet"
      opacity={opacity}
      style={style}
      onMouseDown={(e: React.MouseEvent<SVGSVGElement>) => {
        e.stopPropagation();
        if (!annotation.objectId) return;
        let type = ObjectActionsType.SET_SELECTED_OBJECT;
        if (e.ctrlKey) {
          type = ObjectActionsType.ADD_SELECTED_OBJECT;
        }
        objectsDispatch({
          type,
          payload: { objectId: annotation.objectId },
        });
        markSelected(e.currentTarget, index, "annotations");
      }}
      onKeyDown={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyPress(e, kp)}
      onKeyUp={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyUp(e, kp)}
    >
      <HighlightAnnotation annotation={annotation} />
      <svg height="100%">
        <polyline
          id={`pointPoly-${index}`}
          // points={`20,3 4,10, 20,17`}
          points={`${point1}, ${point1b} ${point2},${point2b} ${point1},${point3b}`}
          stroke={`${annotation.borderColor || "white"}`}
          fill={`transparent`}
          strokeWidth={`${annotation.strokeWidth || "4"}`}
          strokeLinejoin="round"
          strokeLinecap="round"
        />
      </svg>
      <line
        className="annotation-arrow-arm"
        x1={`${lineStart}`}
        x2={`100%`}
        y1={`50%`}
        y2={`50%`}
        style={{
          fill: `${annotation.borderColor || "white"}`,
          stroke: `${annotation.borderColor || "white"}`,
          strokeLinecap: "butt",
          strokeWidth: `${annotation.strokeWidth || "4"}`,
        }}
      />
    </svg>
  );
};

export default AnnotationPointArrow;
