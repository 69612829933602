import React from "react";
import { parseSemanticVersioning } from "../../utils/Versioning/versioning";
import { useTablesDataDispatch } from "../Tables/TablesDataProvider";

import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../contexts/ObjectsProvider";
import { useObjectPosition } from "../../hooks/useObjectPosition";
import { useObjectIsInTime } from "../../hooks/useObjectIsInTime";

export function isAtLeastVersion_1_2_0(version: string | undefined) {
  if (!version) return false;
  const result = parseSemanticVersioning(version);
  if (!result) {
    return false;
    //unversioned hotspot
  } else {
    const [major, minor, patch] = result;
    //update to hotspots version: 1.2.0
    if (major >= 1 && minor >= 2 && patch >= 0) {
      return true;
    }
  }
}
export default function InteractivityHotspots() {
  const { hotspots } = useObjectsState();
  return (
    <>
      {hotspots.map((hotspot, i) => {
        return <Hotspot hotspot={hotspot} key={i} />;
      })}
    </>
  );
}

function Hotspot({ hotspot }: any) {
  const {
    position: [x, y],
    size: [width, height],
  } = useObjectPosition(hotspot.objectId, hotspot.top, hotspot.left, hotspot.width, hotspot.height);
  const tablesDispatch = useTablesDataDispatch();
  const objectsDispatch = useObjectsDispatch();
  const isInTimeline = useObjectIsInTime(hotspot.objectId);

  return (
    <div
      data-objectid={hotspot.objectId}
      className={
        // hotspotstate in the future could easily be the
        // movable plane object including all movable objects
        "hs-tar"
      }
      key={hotspot.objectId}
      // className={'hs-tar'}
      onMouseDown={(e) => {
        e.persist();
        e.stopPropagation();
        tablesDispatch({
          action: "setSelectedTable",
          payload: [undefined],
        });
        objectsDispatch({
          type: ObjectActionsType.SET_SELECTED_OBJECT,
          payload: {
            objectId: hotspot.objectId,
          },
        });
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={{
        transform: `translate(${x}px, ${y}px)`,
        height: height,
        width: width,
        zIndex: 501,
        opacity: isInTimeline ? 1 : 0,
      }}
    ></div>
  );
}
