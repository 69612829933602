import React from "react";
import { useParams } from "react-router-dom";
import Builder from "../routes/builder";
import Preview from "../routes/preview";
import Delete from "../routes/delete";

export default function Lesson(props: any) {
  // var { path } = useRouteMatch();
  const { method, lessonId } = useParams() as any;

  let main: JSX.Element = <div />;

  switch (method) {
    case "new":
      main = <Builder lessonId={undefined} />;
      break;
    case "edit":
      main = <Builder lessonId={lessonId} />;
      break;
    case "preview":
      main = <Preview lessonId={lessonId} />;
      break;
    case "delete":
      main = <Delete lessonId={lessonId} />;
      break;
    default:
      break;
  }

  return <div className="main-app-resize-container">{main}</div>;
}
