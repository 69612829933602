import React from "react";

const AnnotationMenuLineIcon = () => {
  return (
    <React.Fragment>
      <span className="icon-line"></span>
    </React.Fragment>
  );
};

export default AnnotationMenuLineIcon;
