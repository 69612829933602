import React, { useContext, useState, useEffect } from "react";
import PageListerPanel from "./PageListerPanel";
import { clamp } from "lodash";
import { animated, useSprings, useSpring, config } from "react-spring";
import { useDrag } from "@use-gesture/react";
import EditableContainer from "./EditableContainer";
// import { PagesManagementContext, IPagesManagementContext } from "../../routes/builderContexts";
// import _ from "lodash";
// import insertionLogo from "../../assets/icons/ld-page-lister-cpat-icon.png";

import { LessonPageActionsContext } from "../../routes/builderContexts";
import { LessonPagesActions, useLessonPagesDispatch } from "../../contexts/LessonPagesProvider/LessonPagesProvider";
// import { ILessonPageActionsContext } from "../../routes/builderContexts";

const MOVEMENT_CONSTANT = 127;
const startingIndex = 4;
let selectedPreveiwMode = 1; // needs to be updated lessonPageActions.previewMode 1 = demo, inst 2 = practice 3 = perform, text
// const [pageOrdersPageNumber, setpageOrdersPageNumber] = useState({});

function addDirty(x, y) {
  x.forEach((element, index) => {
    if (element.order !== index + startingIndex) {
      element.lessonPageIsDirty = true;
    }
  });
  return x;
}
function reArrangeArray(arrayToArrange, arrayWithValuesAsNewIndexes) {
  let newlyReorderedArray = [];

  for (let i = 0; i <= arrayWithValuesAsNewIndexes.length; i++) {
    for (let j = 0; j < arrayToArrange.length; j++) {
      if (arrayToArrange[j].uniquePageListerID === arrayWithValuesAsNewIndexes[i]) {
        newlyReorderedArray.push(arrayToArrange[j]);
      }
    }
  }

  return addDirty(newlyReorderedArray, arrayWithValuesAsNewIndexes);
}

function move(array, moveIndex, toIndex) {
  let itemRemovedArray = [...array.slice(0, moveIndex), ...array.slice(moveIndex + 1, array.length)];
  return [
    ...itemRemovedArray.slice(0, toIndex),
    array[moveIndex],
    ...itemRemovedArray.slice(toIndex, itemRemovedArray.length),
  ];
}

const PageListerHelper = ({ pagesManagementContext, lessonPages, updateArray }) => {
  //const [pagePullout, setSettingsOn] = useState(false);
  const { pagePullout, currentlyDisplayedPageIndex } = pagesManagementContext;
  const lessonPageDispatch = useLessonPagesDispatch();
  // const [lessonPagesCopy, setlessonPagesCopy] = useState(_.cloneDeep(lessonPages));
  const [saveOrder, setSaveOrder] = useState(
    lessonPages
      ? lessonPages.map((el, index) => {
          el.order = index + startingIndex; //setting fresh orders
          return index;
        })
      : [],
  );
  // const [currentSelectedPage, setCurrentSelectedPage] = useState(0);
  const [pagesIconSprings, setpagesIconSprings] = useSprings(
    lessonPages.length,
    springObjectReturner(
      lessonPages.map((el, index) => {
        el.order = index + startingIndex; //setting fresh orders
        return el.uniquePageListerID;
      }),
    ),
    [lessonPages.length],
  );

  const [pageNumbering, setPageNumbering] = useState({});
  const lessonPageActions = useContext(LessonPageActionsContext);
  if (lessonPageActions.previewMode === "Perform") {
    selectedPreveiwMode = 3;
  } else if (lessonPageActions.previewMode === "Demo") {
    selectedPreveiwMode = 1;
  } else if (lessonPageActions.previewMode === "Practice") {
    selectedPreveiwMode = 2;
  }

  // const order = useRef(lessonPages ? (lessonPages.map((el, index) => {
  //   el.order = index + startingIndex; //setting fresh orders
  //   return index;})): []);

  let order = [...saveOrder];
  let pageOrdersPageNumber = {};

  if (lessonPages.length < order.length) {
    for (var i = 0; i < lessonPages.length; i++) {
      order = lessonPages.map((el, index) => {
        //I can probably refactor this so it pushes to order instead of re-arranging
        el.order = index + startingIndex; //setting fresh orders
        el.uniquePageListerID = index;
        return el.uniquePageListerID;
      });
    }
  }
  useEffect(() => {
    if (lessonPages.length > 0) {
      if (lessonPages.length >= saveOrder.length) {
        //ADD PAGE

        order = lessonPages.map((el, index) => {
          //I can probably refactor this so it pushes to order instead of re-arranging
          el.order = index + startingIndex; //setting fresh orders
          el.uniquePageListerID = index;
          return el.uniquePageListerID;
        });

        setSaveOrder(order);
        setpagesIconSprings(springObjectReturner(order));
      } else {
        //REMOVE PAGE
        setSaveOrder(order);
        setpagesIconSprings(springObjectReturner(order));
      }
    }
  }, [lessonPages.length]);

  function springObjectReturner(
    orderParam,
    down,
    originalIndex,
    indexOfSelectedRow,
    y,

    currentRowHovered,
    active,
  ) {
    return function (index) {
      if (!pagePullout) {
        if (down && index === originalIndex) {
          return {
            config: { friction: 35, tension: 487, mass: 1, precisions: 0.01 },
            y: indexOfSelectedRow * MOVEMENT_CONSTANT + y,
            scale: 1.0222,
            zIndex: "1",
            shadow: 15,
            immediate: (n) => {
              return n === "y" || n === "zIndex";
            },
          };
        } else {
          return {
            config: { friction: 35, tension: 487, mass: 1, precisions: 0.01 },
            y: orderParam.indexOf(index) * MOVEMENT_CONSTANT,
            scale: 1,
            zIndex: "0",
            shadow: 1,
            immediate: false,
            onRest: (zzz) => {
              let modRow = currentRowHovered;
              if (currentRowHovered >= orderParam.length) {
                modRow = orderParam.length - 1;
              }
              let optimize = false;
              if (y >= 64 || y <= -64) {
                optimize = true;
              }
              if (index === originalIndex) {
                if (saveOrder !== orderParam && modRow + startingIndex === false) {
                  console.log("modRow1", modRow);
                  console.log("originalIndex", originalIndex);
                  handler(orderParam, modRow + startingIndex, saveOrder.indexOf(originalIndex) + startingIndex);
                }

                // let zzCheck = zzz.y >= 64 || zzz.y <= -64
                if (optimize) {
                  // pagesManagementContext.switchCurrentlyDisplayedPage(modRow + startingIndex);
                  console.log("modRow2", modRow);
                  console.log("originalIndex", originalIndex);
                  console.log("SAVE ORDER", saveOrder);
                  handler(orderParam, modRow + startingIndex, saveOrder.indexOf(originalIndex) + startingIndex);
                  y = 0;
                }
              }
            },
          };
        }
      } else {
        //
        if (down && index === originalIndex) {
          return {
            config: { friction: 35, tension: 487, mass: 1, precisions: 0.01 },
            y: indexOfSelectedRow * MOVEMENT_CONSTANT + y,
            scale: 1,
            zIndex: "1",
            shadow: 15,
            immediate: (n) => {
              return n === "y" || n === "zIndex";
            },
          };
        } else {
          return {
            config: { friction: 35, tension: 487, mass: 1, precisions: 0.01 },
            y: orderParam.indexOf(index) * MOVEMENT_CONSTANT,
            scale: 1,
            zIndex: "0",
            shadow: 1,
            immediate: false,
            onRest: (zzz) => {
              let optimize = false;

              if (y >= 64 || y <= -64) {
                optimize = true;
              }
              if (index === originalIndex) {
                // let zzCheck = zzz.y >= 64 || zzz.y <= -64
                if (optimize) {
                  let modRow = currentRowHovered;

                  if (currentRowHovered >= orderParam.length) {
                    modRow = orderParam.length - 1;
                  }

                  // pagesManagementContext.switchCurrentlyDisplayedPage(modRow + startingIndex);
                  handler(orderParam, modRow + startingIndex, saveOrder.indexOf(originalIndex) + startingIndex);
                  y = 0;
                }
              }
            },
          };
        }
      }
    };
  }

  function normalizeArray(newOrderOfIndexes, pageToGoTo, pageIndex) {
    let orderedArray = reArrangeArray(lessonPages, newOrderOfIndexes);
    lessonPageDispatch({ type: LessonPagesActions.UPDATE_PAGE_ORDER, payload: { pageToGoTo, pageIndex } });

    orderedArray.map((el, index) => {
      el.order = index + startingIndex;
      return el;
    });
    setSaveOrder(order);
    updateArray(orderedArray, pageToGoTo);
  }

  const handler = (x, pageToGoTo, pageIndex) => {
    if (x === null && x === undefined) {
      //
    } else {
      normalizeArray(x, pageToGoTo, pageIndex);
    }
  };

  const sliderSpring = useSpring({
    config: config.default,
    width: pagePullout ? 553 : 200,
    opacity: pagePullout ? 1 : 0,
    x: pagePullout ? 262 : 0, //width of the page box
  });

  const bind = useDrag(({ args: [originalIndex], down, active, movement: [, movementy] }) => {
    const indexOfSelectedRow = order.indexOf(originalIndex);
    const currentRowHovered = clamp(
      Math.round((indexOfSelectedRow * MOVEMENT_CONSTANT + movementy) / MOVEMENT_CONSTANT),
      0,
      lessonPages.length,
    );
    const newOrder = move(order, indexOfSelectedRow, currentRowHovered);
    setpagesIconSprings(
      springObjectReturner(newOrder, down, originalIndex, indexOfSelectedRow, movementy, currentRowHovered, active),
    );
    if (!down) {
      order = newOrder;
    }
  });

  function updateName(newName, currentIndex) {
    lessonPages[currentIndex - startingIndex].name = newName;
    lessonPages[currentIndex - startingIndex].lessonPageIsDirty = true;
    updateArray(lessonPages);
  }

  function getName(springIndex) {
    return lessonPages.filter((el, i) => {
      return el.uniquePageListerID === springIndex;
    })[0].name;
  }

  function currentPage(springIndex) {
    return lessonPages
      .filter((el, i) => {
        return el.uniquePageListerID === springIndex;
      })
      .map((e, i) => e.order)[0];
  }

  function playerType(si) {
    return lessonPages.filter((el) => {
      return el.uniquePageListerID === si;
    })[0].pagePlayerType;
  }

  function handleRightClick(event) {
    console.log("hello there We have just now right clicked");
  }

  useEffect(() => {
    let i = 3;
    let introPage = pagesManagementContext.lessonPages[1];
    let objectivesPage = pagesManagementContext.lessonPages[2];

    if (!introPage.lessonModeIds.includes(selectedPreveiwMode)) {
      i = i - 1;
    }
    if (!objectivesPage.lessonModeIds.includes(selectedPreveiwMode)) {
      i = i - 1;
    }
    if (selectedPreveiwMode === 3) {
      i = i + 1;
    }

    for (const index in lessonPages) {
      if (lessonPages[index].lessonModeIds.includes(selectedPreveiwMode)) {
        i++;
        pageOrdersPageNumber[lessonPages[index].order] = i;
      } else {
        pageOrdersPageNumber[lessonPages[index].order] = 0;
      }
      setPageNumbering(pageOrdersPageNumber);
    }
  }, [saveOrder, lessonPages.map((page) => page.lessonModeIds.join("")).join(""), selectedPreveiwMode]);

  function pageNumbers(i) {
    let output =
      pageNumbering[order.indexOf(i) + startingIndex] !== 0 ? (
        <div className="mode-page-numbers-col">{pageNumbering[order.indexOf(i) + startingIndex]}</div>
      ) : (
        <div className="mode-page-numbers-col"></div>
      );
    return output;
  }

  return (
    <React.Fragment>
      <div
        onContextMenu={handleRightClick}
        className="side-panel-container"
        style={{ height: `${MOVEMENT_CONSTANT * lessonPages.length}px` }}
      >
        <animated.div
          style={
            {
              // width: sliderSpring.width.interpolate(width => {
              //   return `${width}px`;
              // })
            }
          }
          className="pages-icons-container"
        >
          {pagesIconSprings.map(({ zIndex, shadow, y, scale }, i) => {
            return (
              <animated.div
                ///replace here
                onPointerUpCapture={() => {
                  pagesManagementContext.switchCurrentlyDisplayedPage(currentPage(i));
                }}
                className={`${
                  order.indexOf(i) === currentlyDisplayedPageIndex - startingIndex
                    ? pagePullout
                      ? "lesson-box selected"
                      : "lesson-box selected-full"
                    : "lesson-box"
                }`}
                {...bind(i)}
                key={i}
                style={{
                  zIndex,
                  boxShadow: shadow.interpolate((s) => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`),
                  transform: y.interpolate((y) => `translate3d(0px,${y}px,0px)`),
                  scale,
                }}
              >
                <div className={`pageBox-container page-lister-${playerType(i)}`}>
                  <div className={`thumbnail thumbnail-container-${playerType(i)}`}></div>
                  <p>{`${order.indexOf(i) + 1 + startingIndex}. `}</p>
                  <EditableContainer
                    updateName={updateName}
                    currentIndex={currentPage(i)}
                    name={getName(i)}
                    className="page-title"
                  >
                    {getName(i)}
                  </EditableContainer>
                  {/*order.indexOf(i) === currentlyDisplayedPageIndex - startingIndex && <div className='insertionPoint'><img src={insertionLogo} /></div>*/}
                </div>

                {pagePullout && (
                  <animated.div
                    style={{
                      transform: sliderSpring.x.interpolate((x) => `translate3d(${x}px,-100%, -1px)`),
                      opacity: sliderSpring.opacity
                        // .interpolate({
                        //   range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 0.90, 1],
                        //   output: [0, 0, 0, 0, 0, 0, 0,0, 1]
                        // })
                        .interpolate((opacity) => opacity),
                    }}
                    className={`info-panel ${i}`}
                  >
                    <div className="page-row">
                      {pageNumbers(i)}
                      <PageListerPanel
                        updateArray={updateArray}
                        lessonPages={lessonPages}
                        currentSelected={currentPage(i)}
                        selectedPage={order.indexOf(i) === currentlyDisplayedPageIndex - startingIndex}
                      />
                    </div>
                  </animated.div>
                )}
              </animated.div>
            );
          })}
        </animated.div>
      </div>
    </React.Fragment>
  );
};

export default PageListerHelper;
