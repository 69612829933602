import React, { Dispatch, SetStateAction, KeyboardEvent, useEffect, useRef, useState, MouseEvent } from "react";
import { handleKeyPress, handleKeyUp } from "../../ObjectPropertyBox/functions/PropertyBoxFunctions";
import { AnnotationPropType } from "../Annotation";
import HighlightAnnotation from "../hooks/HighlightAnnotation";

const AnnotationCircle = (props: AnnotationPropType) => {
  const { annotation, index, kp, markSelected, objectIsInTimeline } = props;
  const circleRef: React.RefObject<SVGSVGElement> = useRef<SVGSVGElement>(null);
  const [bounds, setBounds]: [
    { height: number; width: number },
    Dispatch<SetStateAction<{ height: number; width: number }>>,
  ] = useState<{ height: number; width: number }>({ height: 0, width: 0 });
  const [isLoading, setIsLoading]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(true);

  useEffect(() => {
    function handleLoad() {
      setIsLoading(false);
    }

    handleLoad();
  }, []);

  useEffect(() => {
    const makeBounds = () => {
      if (circleRef.current) {
        const newBounds: { height: number; width: number } = {
          height: (circleRef.current?.getBoundingClientRect() as DOMRect)?.height,
          width: (circleRef.current?.getBoundingClientRect() as DOMRect)?.width,
        };
        if (!isLoading) {
          circleRef.current.style.transform = `rotate(${
            annotation?.transform?.rotate ? annotation?.transform?.rotate?.toFixed(2) : 0
          }deg)`;
          setBounds(newBounds);
        }
      }
    };

    makeBounds();

    // return () => { newBounds = {height: 0, width: 0}}
  }, [isLoading]);

  useEffect(() => {
    window.addEventListener("resize", checkResize);
    return () => {
      window.removeEventListener("resize", checkResize);
    };
  }, []);

  function checkResize() {
    const thisSVG: any = document.getElementById(`circle-${index}`) as any;
    if (thisSVG) {
      thisSVG.firstChild.attributes.cx.nodeValue = thisSVG.clientWidth / 2;
      thisSVG.firstChild.attributes.cy.nodeValue = thisSVG.clientHeight / 2;
      thisSVG.firstChild.attributes.rx.nodeValue = thisSVG.clientWidth / 2 - 3;
      thisSVG.firstChild.attributes.ry.nodeValue = thisSVG.clientHeight / 2 - 3;
    }
  }

  const isDisplayed = typeof annotation.isDisplayed === "undefined" || annotation.isDisplayed;
  const opacity = objectIsInTimeline ? 1 : isDisplayed ? 1 : 0.5;

  return (
    <svg
      // viewBox='0 0 100 auto'
      width="100"
      id={`circle-${index}`}
      name="circle"
      className="annotation"
      onMouseDown={(e: MouseEvent<SVGSVGElement>) => markSelected(e.currentTarget, index, "annotations")}
      onKeyDown={(e: KeyboardEvent<SVGSVGElement>) => handleKeyPress(e, kp)}
      onKeyUp={(e: KeyboardEvent<SVGSVGElement>) => handleKeyUp(e, kp)}
      preserveAspectRatio="xMinYMin meet"
      tabIndex={0}
      opacity={opacity}
      style={{
        top: `${annotation.top.toFixed(2)}%`,
        left: `${annotation.left.toFixed(2)}%`,
        width: `${annotation.width.toFixed(2)}%`,
        height: `${annotation.height.toFixed(2)}%`,
        zIndex: annotation.zIndex,
        // transform:`rotate(${annotation?.transform?.rotate?.toFixed(2)}deg)`
      }}
      ref={circleRef}
    >
      <HighlightAnnotation annotation={annotation} />

      {bounds.height > 0 ? (
        <ellipse
          cx={`${bounds.width / 2}`}
          cy={`${bounds.height / 2}`}
          rx={`${bounds.width / 2 - 3}`}
          ry={`${bounds.height / 2 - 3}`}
          stroke={`${annotation?.borderColor}` || "white"}
          fill={`${annotation?.backgroundColor}` || "transparent"}
          strokeWidth={`${annotation.strokeWidth || "4"}`}
        ></ellipse>
      ) : (
        <></>
      )}
    </svg>
  );
};

export default AnnotationCircle;
