import _ from "lodash";
import { IAnnotation } from "../models/IAnnotation";
import { IAnnotationState } from "../models/IAnnotationState";

export function handleAnnotationZ(aState: IAnnotationState) {
  const lowestZ = 113;
  let maxZ = lowestZ;
  const annotationsArray = _.cloneDeep(aState.annotations);
  if (annotationsArray && annotationsArray.length > 0) {
    _.forEach(annotationsArray, (annotation: IAnnotation, index: number) => {
      if (annotation.zIndex < lowestZ) {
        annotationsArray[index].zIndex = lowestZ;
      } else if (annotation.zIndex > maxZ) {
        maxZ = annotation.zIndex;
      } else {
        annotationsArray[index].zIndex = lowestZ;
      }
    });
  }
  return _.cloneDeep(annotationsArray);
}
