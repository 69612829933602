import React, { useState, useEffect, useContext } from "react";
import {
  IPageContext,
  PageContext,
  IAssetManagementContext,
  AssetContext,
  LessonPageActionsContext,
  ILessonPageActionsContext,
} from "../../../routes/builderContexts";
import { IUploaderContext, UploaderContext } from "../uploaderContext";
import { IFmsPageManifest } from "../../../pageTypes/FMS_Player/Interfaces/IFmsPageManifest";

import AudioControls from "./audioControls";
import "./audioControls.css";
import "../uploadModal.css";

const AudioUploader = () => {
  const pagesContext: IPageContext = useContext<IPageContext>(PageContext); //data and context for page manifest
  const pageManifest: IFmsPageManifest = pagesContext.pageManifest as IFmsPageManifest;
  // const assetContext: IAssetManagementContext = useContext<IAssetManagementContext>(AssetContext);
  const uploaderContext: IUploaderContext = useContext<IUploaderContext>(UploaderContext);
  const lessonPageActionsContext: ILessonPageActionsContext =
    useContext<ILessonPageActionsContext>(LessonPageActionsContext);
  const [fileName, setFileName] = useState<string>("");
  const [deleteCondition, setDeleteCondition] = useState<string>("");
  const [statusClass, setStatusClass] = useState<string>("text-danger");

  let modeIndex = 0;
  if (lessonPageActionsContext.selectedEditorLessonModes === 2) {
    modeIndex = 1;
  } else if (lessonPageActionsContext.selectedEditorLessonModes === 3) {
    modeIndex = 2;
  }

  const textAreaChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    //changes narration text
    uploaderContext.setNarratorText(event.target.value);
  };

  const deleteSelectedAudio = () => {
    //removes object from array
    uploaderContext.removeAudio();
    setStatusClass("text-success");
    setFileName("");
    uploaderContext.setUploadSuccess(false);
    uploaderContext.setDeleteWarning(false);
  };

  const changeFileHandlder = () => {
    uploaderContext.rawFile.current?.click();
  };

  const fileNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (uploaderContext.rawFile.current?.files) {
      if (uploaderContext.rawFile.current?.files[0]) {
        uploaderContext.manageFile(event);
        setFileName(uploaderContext.rawFile.current?.files[0].name.toLowerCase());
        if (uploaderContext.selectedAudio.File) {
          triggerWarning("change");

          //do more here
        }
      }
    }
  };
  const resetFile = () => {
    if (uploaderContext.rawFile.current) {
      if (uploaderContext.selectedAudio.File && uploaderContext.selectedAudio.File !== null) {
        uploaderContext.resetAudio();
        setFileName(
          uploaderContext.selectedAudio.File.substring(
            uploaderContext.selectedAudio.File.lastIndexOf("/") + 1,
            uploaderContext.selectedAudio.File.length,
          ),
        );
      }
    }
  };
  const triggerWarning = (condition: string) => {
    uploaderContext.setDeleteWarning(true);
    setDeleteCondition(condition);
  };

  const fileRemovalApproved = () => {
    switch (deleteCondition) {
      case "remove":
        return deleteSelectedAudio();
      case "change":
        uploaderContext.setUploadSuccess(false);
        return uploaderContext.setDeleteWarning(false);
      case "text":
        return removeNarratorText();
      default:
        break;
    }
  };

  const fileRemovalDeclined = () => {
    switch (deleteCondition) {
      case "change":
        return resetFile();
      default:
        return uploaderContext.setDeleteWarning(false);
    }
  };

  const removeNarratorText = () => {
    uploaderContext.setNarratorText("");
    uploaderContext.setDeleteWarning(false);
  };

  useEffect(() => {
    const instantiateStateVariables = () => {
      uploaderContext.createAudioPlaceholder(pageManifest.Audio, modeIndex);

      if (pageManifest.Audio[modeIndex]) {
        if (pageManifest.Audio[modeIndex].File) {
          setFileName(
            pageManifest.Audio[modeIndex].File.substring(
              pageManifest.Audio[modeIndex].File.lastIndexOf("/") + 1,
              pageManifest.Audio[modeIndex].File.length,
            ),
          );
        }
        uploaderContext.defineAudioDescription();
        uploaderContext.setSelectedAudio(pageManifest.Audio[modeIndex]);
        uploaderContext.setNarratorText(pageManifest.Audio[modeIndex].NarratorText);
      }
    };

    instantiateStateVariables();

    return () => {
      uploaderContext.setDescription("");
      //uploaderContext.saveNarratorText(uploaderContext.narratorText);
    };
  }, []);

  useEffect(() => {
    if (uploaderContext.uploadSuccess) {
      uploaderContext.setStatusMessage("File Uploaded");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploaderContext.uploadSuccess]);

  useEffect(() => {
    const checkStatus = () => {
      if (uploaderContext.isGoodFilename && uploaderContext.isFilePresent) {
        uploaderContext.setStatusMessage("");
      }
      if (uploaderContext.nameSplit.length > 0 && uploaderContext.nameSplit !== fileName) {
        uploaderContext.setStatusMessage(
          "Your file name has been updated to " + uploaderContext.nameSplit.toLowerCase(),
        );
        setStatusClass("modalText");
        setFileName(uploaderContext.nameSplit.toLowerCase());
      }
    };
    checkStatus();
  }, [uploaderContext.nameSplit]);
  useEffect(() => {
    if (
      uploaderContext.statusMessage.includes("Error") ||
      uploaderContext.statusMessage.includes("Warning") ||
      uploaderContext.statusMessage.includes("Please")
    ) {
      setStatusClass("upload-error");
    } else {
      setStatusClass("text-success font-italic");
    }
  }, [uploaderContext.statusMessage]);

  return (
    <div
      className="row padding-all-sides-10 one-hundred-percent-height instructions"
      style={{
        pointerEvents: uploaderContext.uploadProcessing ? "none" : "auto",
      }}
    >
      <>
        <div className="col-12">
          <h3 className="modalText centered">
            {modeIndex === 0 ? "Demo Mode" : modeIndex === 1 ? "Practice Mode" : "Perform Mode"} Audio Manager
          </h3>
          <p className={statusClass}>{uploaderContext.statusMessage}</p>
          <div className="col-12">
            <div className="blobInput">
              <button className="audioButton" onClick={changeFileHandlder}>
                {uploaderContext.selectedAudio.File ? "Change" : "Choose"}
              </button>
              <input className="form-control-file" disabled value={fileName}></input>
              <button
                className="audioButton"
                onClick={() => {
                  triggerWarning("remove");
                }}
                disabled={uploaderContext.selectedAudio?.File || uploaderContext.uploadProcessing ? false : true}
              >
                Remove
              </button>
            </div>
            <input
              className="form-control-file"
              type="file"
              ref={uploaderContext.rawFile}
              hidden={true}
              onChange={fileNameChange}
              accept="audio/mp3"
            ></input>
          </div>

          {uploaderContext.deleteWarning ? (
            <div className="audioWarning">
              <p className="text-danger">
                <strong>
                  WARNING: This action will remove the current{" "}
                  {deleteCondition === "text" ? "NARRATOR TEXT" : "AUDIO DATA"} CONTINUE?
                </strong>
              </p>
              <button className="audioButton" onClick={fileRemovalDeclined}>
                Cancel
              </button>
              <button className="audioButton" onClick={fileRemovalApproved}>
                Confirm
              </button>
            </div>
          ) : (
            <>
              {uploaderContext.selectedAudio?.File &&
              uploaderContext.selectedAudio?.File !== "" &&
              fileName.replace(/ /g, "_").toLowerCase() ===
                pageManifest.Audio[modeIndex].File.substring(
                  pageManifest.Audio[modeIndex].File.lastIndexOf("/") + 1,
                  pageManifest.Audio[modeIndex].File.length,
                ) ? (
                <AudioControls audioFile={uploaderContext.selectedAudio.File} />
              ) : (
                <></>
              )}
            </>
          )}
        </div>
        <div className="col-12 narratorBox">
          <label className="modalText">
            <div className="deleteButtonContainer">
              <h3 className="boxText">
                Narration Text
                <br />
              </h3>
            </div>
            <textarea
              rows={10}
              cols={45}
              className="narratorTextField"
              name="NarratorText"
              onChange={textAreaChangeHandler}
              value={uploaderContext.narratorText}
            ></textarea>
          </label>
        </div>
      </>
    </div>
  );
};

export default AudioUploader;
