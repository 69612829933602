import _ from "lodash";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
// import InteractionHelper from '../../../../pageTypes/CPaT3d_Player/components/IGS/interactionHelper';
import EmptyImage from "../../../EmptyImage";
import { IIGA, IIGAHotSpot, IIGAImageHotspot } from "../../models/IIGA";
import blobHelper from "../../../blobUrlFormatHelper";
// import { blankHotSpot } from '../../helpers/initialSteps';
// import HotSpotGenerator from '../HotSpotGenerator/HotSpotGenerator';
import HotSpotCanvas from "../HotSpotGenerator/HotSpotCanvas";
import Annotation, { AnnotationType } from "../../../Annotation/Annotation";
import MoveableClass from "../../../Moveable/MoveableClass";
import { IMoveableProps } from "../../../Moveable/models/IMoveableProps";
import Symbol from "../../../Symbol/Symbol";
import { ISymbolV2 } from "../../../Symbol/models/ISymbol";
import { handleKeyPress } from "../../../ObjectPropertyBox/functions/PropertyBoxFunctions";

type InteractiveType = {
  annotationProps: AnnotationType;
  containerRef: React.RefObject<HTMLDivElement>;
  currentIGAStep: IIGA;
  currentImagePath?: string;
  currentStep: number;
  hotSpot: IIGAHotSpot;
  imgRef: React.RefObject<HTMLImageElement>;
  isHotSpotActive: boolean;
  moveableProps: IMoveableProps;
  question: string;
  addInteractiveGraphic: (e: React.MouseEvent<HTMLDivElement>) => void;
  addOrUPdateImageDims: (imgDims: DOMRect) => void;
  handleHotspotChange: (value: IIGAHotSpot, childDims: DOMRect) => void;
  handleTaskChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  setHotSpot: (hotSpot: IIGAHotSpot) => void;
  setIsHotSpotActive: (boolean: boolean) => void;
  updatedManifest: any;
  pageContext: any;
};

const IGAInteractiveGraphic = (props: InteractiveType) => {
  const {
    annotationProps,
    containerRef,
    currentIGAStep,
    currentImagePath,
    currentStep,
    hotSpot,
    imgRef,
    isHotSpotActive,
    moveableProps,
    question,
    addInteractiveGraphic,
    addOrUPdateImageDims,
    handleHotspotChange,
    handleTaskChange,
    setHotSpot,
    setIsHotSpotActive,
  } = props;
  // const imgRef: React.RefObject<HTMLImageElement> = useRef(null);
  const [childDims, setChildDims]: [DOMRect | undefined, Dispatch<SetStateAction<DOMRect | undefined>>] = useState<
    DOMRect | undefined
  >();
  const [isLoading, setIsLoading]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(true);
  const interactiveType = 1;
  const currentVersion = 1;

  useEffect(() => {
    if (!isLoading) {
      window.addEventListener("resize", resizeCanvas);
    }

    return () => {
      window.removeEventListener("resize", resizeCanvas);
    };
  }, [isLoading]);

  useEffect(() => {
    const imgRect: DOMRect = imgRef.current?.getBoundingClientRect() as DOMRect;
    if (blobHelper(currentImagePath as string) === imgRef.current?.getAttribute("src")) {
      if (!_.isEqual(childDims, imgRect)) {
        setChildDims(imgRect);
        adjustHotSpot(imgRect);
      }
    }

    if (currentIGAStep.stepType === interactiveType && (currentIGAStep.images?.path.length as number) > 0) {
      setIsHotSpotActive(true);
    }

    if (imgRect) {
      validatePageDims(imgRect);
      adjustHotSpot(imgRect);
    }

    const canv = document.getElementById("iga-canvas") as HTMLElement;
    if (canv) {
      canv.style.width = `${imgRef.current?.getBoundingClientRect().width.toString()}px`;
      canv.style.height = `${imgRef.current?.getBoundingClientRect().height.toString()}px`;
    }

    updateImage(0, props.updatedManifest, props.pageContext, currentStep);
  }, [currentStep]);

  function validatePageDims(imgRect: DOMRect) {
    if (
      (!_.has(currentIGAStep.images, "imageDimensions") ||
        !(currentIGAStep.images as IIGAImageHotspot).imageDimensions ||
        (currentIGAStep.images as IIGAImageHotspot).imageDimensions.height === 0 ||
        (currentIGAStep.images as IIGAImageHotspot).imageDimensions.width === 0 ||
        !(currentIGAStep.images as IIGAImageHotspot).imageDimensions) &&
      currentIGAStep.stepType === 1 &&
      _.has(currentIGAStep, "version")
    ) {
      addOrUPdateImageDims(imgRect);
    }
  }

  const resizeCanvas = () => {
    const canv = document.getElementById("iga-canvas") as HTMLElement;
    if (canv) {
      canv.style.width = `${imgRef.current?.getBoundingClientRect().width.toString()}px`;
      canv.style.height = `${imgRef.current?.getBoundingClientRect().height.toString()}px`;
    }
  };

  const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const imgRect: DOMRect = e.currentTarget.getBoundingClientRect();
    if (_.has(currentIGAStep.images, "imageHotSpot")) {
      adjustHotSpot(imgRect);
    }
    // if(blobHelper(currentImagePath as string) !== e.currentTarget.getAttribute('src')) {
    validatePageDims(imgRect);
    // }

    // setIsHotSpotActive(true);
    setChildDims(imgRect);
    setIsLoading(false);
    updateImage(0, props.updatedManifest, props.pageContext, currentStep);
  };

  const adjustHotSpot = (imgRect: DOMRect) => {
    const newHotSpot: IIGAHotSpot = (currentIGAStep.images as IIGAImageHotspot)?.imageHotSpot as IIGAHotSpot;
    let adjustedHotSpot = { left: 0, right: 0, top: 0, bottom: 0 };

    if (_.has(currentIGAStep.images, "imageHotSpot") && _.has(currentIGAStep, "version")) {
      const ratio: { height: number; width: number } = {
        height: imgRect?.height / (currentIGAStep.images as IIGAImageHotspot)?.imageDimensions?.height,
        width: imgRect?.width / (currentIGAStep.images as IIGAImageHotspot)?.imageDimensions?.width,
      };

      adjustedHotSpot = {
        left: newHotSpot?.left * ratio.width,
        top: newHotSpot?.top * ratio.height,
        right: newHotSpot?.right * ratio.width,
        bottom: newHotSpot?.bottom * ratio.height,
      };
    } else {
      adjustedHotSpot = newHotSpot;
    }
    setHotSpot(adjustedHotSpot);
  };

  useEffect(() => {
    function handleLoad() {
      setIsLoading(false);
    }

    if (isLoading) {
      handleLoad();
    }

    return () => {
      if (!isLoading) {
        setIsLoading(true);
      }
    };
  }, [isLoading, currentIGAStep]);

  const displayImageContainer = () => {
    if (!currentImagePath || currentImagePath?.length === 0) {
      const displayText = "Click Here to Add an Image";
      return <EmptyImage displayText={displayText} imageOnly={true} />;
    } else {
      return (
        <React.Fragment>
          <div className="img-aspect-container">
            <div className="aspect-container-inside">
              <div className="aspect-centering">
                {/* {isHotSpotActive === true ? <><InteractionHelper imageOne={blobHelper(currentImagePath)} currentStepObject={currentIGAStep} currentHotSpot={currentIGAStep.images?.imageHotSpot} handleHotspotChange={handleHotspotChange} /> </> : <> */}
                {isHotSpotActive ? (
                  <React.Fragment>
                    {!isLoading && childDims ? (
                      <HotSpotCanvas
                        // currentStepObject={currentIGAStep}
                        childDims={childDims as DOMRect}
                        currentHotSpot={hotSpot}
                        currentStep={currentIGAStep}
                        handleHotspotChange={handleHotspotChange}
                        setHotSpot={setHotSpot}
                      />
                    ) : (
                      <></>
                    )}
                    <img
                      ref={imgRef}
                      className="iga-interactive-graphic-img"
                      id={"quiz-image-0"}
                      src={blobHelper(currentImagePath)}
                      alt=""
                      onLoad={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleImageLoad(e)}
                    />
                  </React.Fragment>
                ) : (
                  <img
                    ref={imgRef}
                    className="iga-interactive-graphic-img"
                    src={blobHelper(currentImagePath)}
                    alt=""
                    id={"quiz-image-0"}
                    onLoad={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleImageLoad(e)}
                  />
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  };

  function updateImage(index: number, pageManifest: any, pageContext: any, igsIndex?: number) {
    const quizImageContainer = document.getElementById("quiz-image-container");
    const imageAspectContainer = document.getElementById("img-aspect-container-0");
    const igaInteractiveGraphicWrapper = document.getElementById("iga-graphic-wrapper");
    const igaStandardGraphicContainer = document.getElementById("iga-standard-quiz-graphic-container");
    let quizImageContainerRect: any;

    const quizImage = document.getElementById("quiz-image-" + index);

    if (quizImageContainer) {
      quizImageContainerRect = quizImageContainer.getBoundingClientRect();
    }

    if (imageAspectContainer) {
      quizImageContainerRect = imageAspectContainer.getBoundingClientRect();
    }

    if (igaInteractiveGraphicWrapper) {
      quizImageContainerRect = igaInteractiveGraphicWrapper.getBoundingClientRect();
    }

    if (igaStandardGraphicContainer) {
      quizImageContainerRect = igaStandardGraphicContainer.getBoundingClientRect();
    }

    if (quizImage) {
      const quizImageRect = quizImage.getBoundingClientRect();

      if (pageManifest.image) {
        pageManifest.image[index].rawData = {
          pixelHeight: quizImageRect.height,
          pixelWidth: quizImageRect.width,
          pixelTop: quizImageRect.top,
          pixelLeft: quizImageRect.left,
        };

        pageManifest.image[index].version = 1;
        pageContext.updatePageManifest(pageManifest);
      }

      if (pageManifest.IGSObject) {
        if (pageManifest.IGSObject[igsIndex as number].images) {
          pageManifest.IGSObject[igsIndex as number].images.rawData = {
            pixelHeight: quizImageRect.height,
            pixelWidth: quizImageRect.width,
            pixelTop: quizImageRect.top - quizImageContainerRect.top,
            pixelLeft: quizImageRect.left - quizImageContainerRect.left,
          };
          pageManifest.IGSObject[igsIndex as number].aspectRatio =
            quizImageContainerRect.width / quizImageContainerRect.height;
          pageManifest.IGSObject[igsIndex as number].images.version = 1;
        }

        if (!pageManifest.IGSObject[igsIndex as number].images && pageManifest.IGSObject[igsIndex as number].choices) {
          pageManifest.IGSObject[igsIndex as number].choices[index].rawData = {
            pixelHeight: quizImageRect.height,
            pixelWidth: quizImageRect.width,
            pixelTop: quizImageRect.top - quizImageContainerRect.top,
            pixelLeft: quizImageRect.left - quizImageContainerRect.left,
          };
          pageManifest.IGSObject[igsIndex as number].aspectRatio =
            quizImageContainerRect.width / quizImageContainerRect.height;
          pageManifest.IGSObject[igsIndex as number].choices[index].version = 1;
        }
      }

      updatePageManifestDimensionPercentages(index, pageManifest, pageContext, igsIndex, quizImageContainerRect);
    }
  }

  function updatePageManifestDimensionPercentages(
    index: number,
    pageManifest: any,
    pageContext: any,
    igsIndex?: number,
    quizImageContainer?: any,
  ) {
    if (pageManifest.image) {
      // image pixel percent from top of pagePlayerArea
      pageManifest.image[index].top = Number(
        ((pageManifest.image[index].rawData!.pixelTop / quizImageContainer.height) * 100).toFixed(2),
      );
      // image pixel percent from left of pagePlayerArea
      pageManifest.image[index].left = Number(
        ((pageManifest.image[index].rawData!.pixelLeft / quizImageContainer.width) * 100).toFixed(2),
      );
      // image pixel percent height relative to pagePlayerArea
      pageManifest.image[index].height = Number(
        ((pageManifest.image[index].rawData!.pixelHeight / quizImageContainer.height) * 100).toFixed(2),
      );
      // image pixel percent width relative to pagePlayerArea
      pageManifest.image[index].width = Number(
        ((pageManifest.image[index].rawData!.pixelWidth / quizImageContainer.width) * 100).toFixed(2),
      );
    }

    if (pageManifest.IGSObject) {
      if (pageManifest.IGSObject[igsIndex as number].images) {
        // image pixel percent from top of pagePlayerArea
        pageManifest.IGSObject[igsIndex as number].images.top = Number(
          (
            (pageManifest.IGSObject[igsIndex as number].images.rawData!.pixelTop / quizImageContainer.height) *
            100
          ).toFixed(2),
        );
        // image pixel percent from left of pagePlayerArea
        pageManifest.IGSObject[igsIndex as number].images.left = Number(
          (
            (pageManifest.IGSObject[igsIndex as number].images.rawData!.pixelLeft / quizImageContainer.width) *
            100
          ).toFixed(2),
        );
        // image pixel percent height relative to pagePlayerArea
        pageManifest.IGSObject[igsIndex as number].images.height = Number(
          (
            (pageManifest.IGSObject[igsIndex as number].images.rawData!.pixelHeight / quizImageContainer.height) *
            100
          ).toFixed(2),
        );
        // image pixel percent width relative to pagePlayerArea
        pageManifest.IGSObject[igsIndex as number].images.width = Number(
          (
            (pageManifest.IGSObject[igsIndex as number].images.rawData!.pixelWidth / quizImageContainer.width) *
            100
          ).toFixed(2),
        );
      }

      if (!pageManifest.IGSObject[igsIndex as number].images && pageManifest.IGSObject[igsIndex as number].choices) {
        // image pixel percent from top of pagePlayerArea
        pageManifest.IGSObject[igsIndex as number].choices[index].top = Number(
          (
            (pageManifest.IGSObject[igsIndex as number].choices[index].rawData!.pixelTop / quizImageContainer.height) *
            100
          ).toFixed(2),
        );
        // image pixel percent from left of pagePlayerArea
        pageManifest.IGSObject[igsIndex as number].choices[index].left = Number(
          (
            (pageManifest.IGSObject[igsIndex as number].choices[index].rawData!.pixelLeft / quizImageContainer.width) *
            100
          ).toFixed(2),
        );
        // image pixel percent height relative to pagePlayerArea
        pageManifest.IGSObject[igsIndex as number].choices[index].height = Number(
          (
            (pageManifest.IGSObject[igsIndex as number].choices[index].rawData!.pixelHeight /
              quizImageContainer.height) *
            100
          ).toFixed(2),
        );
        // image pixel percent width relative to pagePlayerArea
        pageManifest.IGSObject[igsIndex as number].choices[index].width = Number(
          (
            (pageManifest.IGSObject[igsIndex as number].choices[index].rawData!.pixelWidth / quizImageContainer.width) *
            100
          ).toFixed(2),
        );
      }
    }

    pageContext.updatePageManifest(pageManifest);
  }

  return (
    <React.Fragment>
      <div className="iga-interactive-task-container">
        <label htmlFor="iga-task-description">Task Description:</label>
        <textarea
          id="iga-task-description"
          name="iga-task-description"
          value={question}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleTaskChange(e)}
        ></textarea>
        {/* {displayAddOrEdit()} */}
      </div>
      <div ref={containerRef} className="iga-interactive-graphic-wrapper" id="iga-graphic-wrapper">
        <div
          className={`iga-interactive-graphic-container${isHotSpotActive ? "" : " interactive-hovering"}`}
          onClick={(e: React.MouseEvent<HTMLDivElement>) => addInteractiveGraphic(e)}
        >
          {displayImageContainer()}
        </div>
        {isHotSpotActive ? (
          <></>
        ) : (
          <React.Fragment>
            {annotationProps.annotations && annotationProps.annotations.length > 0 ? (
              <Annotation {...annotationProps} />
            ) : (
              <></>
            )}
            {annotationProps.symbols && annotationProps.symbols.length > 0 ? (
              <Symbol
                kp={annotationProps.kp}
                symbols={annotationProps.symbols as ISymbolV2[]}
                handleKeyPress={handleKeyPress}
                markSelected={annotationProps.markSelected}
              />
            ) : (
              <></>
            )}
            {moveableProps.target ? <MoveableClass {...moveableProps} /> : <></>}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default IGAInteractiveGraphic;
