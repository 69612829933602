import "./SmartObjective.css";
import React, { SetStateAction, useState, useEffect } from "react";
import EnablingObjective from "./components/EnablingObective";
import WhatToDo from "./components/WhatToDo";
import {
  CPAT_LESSON_MODE_INSTRUCTIONAL,
  CPAT_LESSON_MODE_PRACTICE,
  CPAT_LESSON_MODE_UNKNOWN,
  CPAT_LESSON_MODE_TEST,
} from "../../helpers/lessonmodetypes";
import { Interweave } from "interweave";
import { instructionsType } from "../../pageTypes/FMS_Player/FMSWYSIWYG";
import _ from "lodash";

type SmartObjectiveType = {
  listIndex: number;
  enablingObjective: string;
  enablingObjectiveTitle: string;
  feedback?: string;
  isFeedbackShown?: boolean;
  lessonMode: string;
  list: instructionsType[];
  disableHint?: boolean;
  WTDTitle?: string | undefined;
  setIsFeedbackShown?: (boolean: boolean) => void;
};

const SmartObjective = (props: SmartObjectiveType): JSX.Element => {
  const { listIndex, enablingObjectiveTitle, lessonMode, list, enablingObjective, disableHint, WTDTitle } = props;
  const listRefs: any = () => {
    if (!list || list.length <= 0) {
      return;
    } else {
      list.reduce((acc, value) => {
        //@ts-ignore
        acc[value.id] = React.createRef();
        return acc;
      });
    }
  };

  const [hintIndex, setHintIndex]: [number, React.Dispatch<SetStateAction<number>>] = useState<number>(-1);
  const noInstructions = "Switch to Edit Mode to Modify Instructions";

  useEffect(() => {
    if (listRefs && listRefs[listIndex]) {
      listRefs[listIndex].current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [listIndex]);

  const displayWhatToDo = () => {
    switch (lessonMode) {
      case CPAT_LESSON_MODE_INSTRUCTIONAL:
        return showDemoModeWTDs();
      case CPAT_LESSON_MODE_PRACTICE:
      case CPAT_LESSON_MODE_UNKNOWN:
      default:
        return showPracticeModeWTDs();
      case CPAT_LESSON_MODE_TEST:
        return;
    }
  };

  const showDemoModeWTDs = () => {
    if (!_.isEmpty(list)) {
      return list.map((item: instructionsType, index: number) => {
        let criteriaClass = "";
        if (index < listIndex) {
          criteriaClass = "previous-criteria";
        } else if (index === listIndex) {
          criteriaClass = "current-criteria";
        } else {
          criteriaClass = "";
        }
        return (
          <li key={index} ref={listRefs[item.id]} className={criteriaClass}>
            <Interweave content={item.text.length > 0 ? item.text : noInstructions} />
          </li>
        );
      });
    }
  };

  const showPracticeModeWTDs = () => {
    if (!_.isEmpty(list)) {
      return list.map((item: instructionsType, index: number) => {
        if (index === listIndex) {
          return (
            <li key={index} className="current-criteria">
              {hintIndex === listIndex ? (
                `${item.text.length > 0 ? item.text : noInstructions}`
              ) : (
                <button onClick={() => setHintIndex(listIndex)} disabled={disableHint}>
                  HINT
                </button>
              )}
            </li>
          );
        } else if (index < listIndex) {
          return (
            <li key={index} ref={listRefs[item.id]} className="previous-criteria">
              <Interweave content={item.text.length > 0 ? item.text : noInstructions} />
            </li>
          );
        } else {
          return <></>;
        }
      });
    }
  };

  const getLabelFontSize = (title: string | undefined) => {
    if (title && title.length >= 30) {
      return "eot-hmax";
    } else if (title && title.length < 30 && title.length >= 25) {
      return "eot-h25";
    } else if (title && title.length < 25 && title.length >= 21) {
      return "eot-h21";
    } else if (title && title.length < 21 && title.length >= 18) {
      return "eot-h18";
    } else if (title && title.length < 18 && title.length >= 16) {
      return "eot-h16";
    } else {
      return "";
    }
  };

  return (
    <React.Fragment>
      <div id="smart-objective-container-Wide">
        <EnablingObjective
          enablingObjective={enablingObjective}
          title={enablingObjectiveTitle}
          labelClass={getLabelFontSize(enablingObjectiveTitle)}
        />
        <WhatToDo WTDTitle={WTDTitle} displayWhatToDo={displayWhatToDo} labelClass={getLabelFontSize(WTDTitle)} />
      </div>
    </React.Fragment>
  );
};

export default SmartObjective;
