import React, { CSSProperties, PropsWithChildren } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { toolTipText } from "./ToolTipText";

export interface TooltipProps {
  title: string;
  className?: string;
  style?: CSSProperties;
}

export function AbstractTooltip({ id, content }: { id: string; content?: string }) {
  content = content ?? toolTipText.find((t) => t.id === id)?.text ?? "";
  return <ReactTooltip id={id} content={content} delayHide={100} />;
}

export const Tooltip = ({ title, className, style, children }: PropsWithChildren<TooltipProps>) => {
  return (
    <span data-tooltip-id={title} className={className} style={style}>
      {children}
      <AbstractTooltip id={title} content={title} />
    </span>
  );
};
