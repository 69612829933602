import IAsset from "../../../models/IAsset";
import { CurrentSelectedAsset } from "../AssetManager.types";
import { MetadataEditorState, Tag } from "./state";
import { MFVDropDown } from "../../../hooks/useMFVDropdown";
import { stringU } from "../../../utils";

export function mapStateToIAsset(
  selectedAsset: CurrentSelectedAsset,
  state: MetadataEditorState,
  mfvDropdown: MFVDropDown,
): IAsset {
  const assetToReplace: IAsset = {
    assetVersionId: selectedAsset.assetId,
    blobPath: selectedAsset.blobPath,
    name: selectedAsset.name ?? "",
    description: state.description,
    displayVersion: "",
    assetTypeName: selectedAsset.assetTypeName ?? "",
    isCollection: false,
    isLibraryItem: state.assetIsLibrary ?? false,
    isArchived: state.assetIsArchived,
    manufacturerFleetVariantDtos: [
      {
        manufacturerId: mfvDropdown.currentManufacturer.id,
        manufacturerName: mfvDropdown.currentManufacturer.name,
        fleetId: mfvDropdown.currentFleet?.id ?? -1,
        fleetName: mfvDropdown.currentFleet?.name ?? "",
        variantName: mfvDropdown.currentVariant?.name ?? "",
        variantId: mfvDropdown.currentVariant?.id ?? -1,
      },
    ],
    tags: state.tags.map((x) => {
      return { key: x.tagKey, value: x.tagValue };
    }),
  };

  return assetToReplace;
}
export function sanitizeString(input: string) {
  return input.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "_");
}
export function replaceAsset(currentAssets: IAsset[], assetToReplace: IAsset) {
  const index = currentAssets.map((x) => x.assetVersionId).indexOf(assetToReplace.assetVersionId);
  return currentAssets.map((asset, i) => {
    return i === index ? assetToReplace : asset;
  });
}

export function sanitizeTags(tags: Tag[]) {
  return tags
    .filter((tag) => tag.tagKey.trim().length !== 0 && tag.tagValue.trim().length !== 0)
    .map((tag) => {
      return {
        //[sanitizeString(tag.tagKey)]:sanitizeString(tag.tagValue),
        [tag.tagKey]: tag.tagValue,
      };
    });
}

export function getUpdateRequestFromState(
  selectedAsset: CurrentSelectedAsset,
  state: MetadataEditorState,
  mfvDropdown: MFVDropDown,
) {
  const { description, tags, assetIsLibrary, assetIsArchived } = state;
  const manufacturerId = mfvDropdown.currentManufacturer.id;
  const fleetId = mfvDropdown.currentFleet?.id;
  const variantId = mfvDropdown.currentVariant?.id;
  return {
    assetVersionId: selectedAsset.assetId,
    manufacturerId: manufacturerId ? [manufacturerId] : [],
    aircraftFamilyId: fleetId ? [fleetId] : [],
    aircraftId: variantId ? [variantId] : [],
    //description: sanitizeString(description),
    description: description,
    tags: sanitizeTags(tags),
    isLibrary: assetIsLibrary,
    isArchived: assetIsArchived,
  };
}
