import React, { useContext } from "react";
import { IFmsPageManifest } from "../../../../../pageTypes/FMS_Player/Interfaces/IFmsPageManifest";
import { IAssetManagementContext, AssetContext } from "../../../../../routes/builderContexts";
import AddReferenceImage from "./AddReferenceImage";
import ReferenceImage from "./ReferenceImage";
import blobHelper from "../../../../blobUrlFormatHelper";
// import ReferenceImageAdjust from './ReferenceImageAdjust';
// import AnnotationThumbnail from '../../../../Annotation/AnnotationsThumbnail/AnnotationThumbnail'

type ReferenceImageType = {
  pageManifest: IFmsPageManifest;
  largeCduModel: boolean;
  cduHeigth: number;
  cduWidth: number;
  displayReferenceImageModal: (e: React.MouseEvent<HTMLSpanElement>) => void;
  removeImage: (e: any, assetVersionId: number, index: number) => void;
  gridLayout: boolean;
};

const FMSReferenceImage = (props: ReferenceImageType) => {
  const assetContext: IAssetManagementContext = useContext<IAssetManagementContext>(AssetContext);
  // const fmsRef: RefObject<HTMLDivElement> = useRef(null);
  const triggerUploadModal = (e: React.MouseEvent<HTMLDivElement>, imageIndex: number) => {
    e.preventDefault();
    assetContext.setAssetIndex(imageIndex);
    assetContext.setAssetTypeId(4);
    // assetContext.showBlobUploader(true);
    assetContext.setAssetManagerController({
      isOpen: true,
      mode: "ImagesOnly",
    });
  };

  if (
    (!props.pageManifest.pageImage ||
      !props.pageManifest.pageImage[0] ||
      props.pageManifest.pageImage[0].imagePath.length === 0) &&
    !props.largeCduModel
  ) {
    return <AddReferenceImage isLargeCDU={props.largeCduModel} triggerUploadModal={triggerUploadModal} />;
  } else {
    const img = new Image();
    img.src =
      props.pageManifest.pageImage && props.pageManifest.pageImage[0]
        ? blobHelper(props.pageManifest.pageImage[0].imagePath)
        : blobHelper("");
    return (
      <ReferenceImage
        largeCDU={props.largeCduModel}
        pageManifest={props.pageManifest}
        displayReferenceImageModal={props.displayReferenceImageModal}
        triggerUploadModal={triggerUploadModal}
        refImage={img}
        removeImage={props.removeImage}
      />
    );
  }
};

export default FMSReferenceImage;
