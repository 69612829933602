import React, { useRef, useEffect, useState } from "react";
import { extend, useThree } from "@react-three/fiber";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

extend({ OrbitControls });

const Controls = (props) => {
  // var moving = props.move
  // const [autoMove, setAutoMove] = useState(true)
  const controls = useRef();
  const { camera, gl } = useThree();
  camera.position.set(15, 8.5, 85);
  // var camPos = 0
  useEffect(() => {
    controls.current.target.set(0, 9, 0);
    // camera.position.set(0, 15, 120.1);
    camera.position.set(15, 8.5, 85);
    // camera.position.set(0, 15, 61);
    camera.far = 5000;
    camera.near = 0.01;
    camera.fov = 75;
  });
  // }, [])

  return <orbitControls ref={controls} args={[camera, gl.domElement]} />;
};

export default Controls;
