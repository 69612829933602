import React, { useEffect, useState } from "react";
import { ReactComponent as SelectedCheckbox } from "../../assets/icons/checkmark-selected.svg";
import { ReactComponent as UnSelectedCheckbox } from "../../assets/icons/checkmark-not-selected.svg";
import { ReactComponent as ExitIcon } from "../../assets/icons/Common/remove-tag-icon.svg";

interface Props {
  selectedImages: number;
  handleSelectAll: (isChecked: boolean) => void;
  submitted: boolean;
  numberOfAssets: number;
  handleShareAssets: () => void;
  shareAssets: boolean;
  isCpat: boolean;
}
const Header = ({
  selectedImages,
  handleSelectAll,
  submitted,
  numberOfAssets,
  shareAssets,
  handleShareAssets,
  isCpat,
}: Props) => {
  const [checked, setChecked] = useState(false);

  const handleSelectAllImages = () => {
    setChecked(!checked);
    handleSelectAll(checked);
  };
  useEffect(() => {
    setChecked(submitted);
  }, [submitted]);

  return (
    <>
      <div className="image-metadata-editor-header">
        <div className="image-metadata-editor-header-grid">
          <div className="image-metadata-editor-header-title">
            Check images to Upload
            <ExitIcon
              className="close-editor-btn"
              onClick={() => {
                document.getElementById("frontportal")!.setAttribute("style", "display:none");
              }}
            ></ExitIcon>
            <div style={{ border: "solid rgb(84,194,38) 2px", height: 0 }}></div>
          </div>

          <div className="image-metadata-editor-explainer"></div>
          <div className="metadata-editor-header-asset-assetcount">
            <p>Total Assets {numberOfAssets}</p>
          </div>
          <div style={{ margin: 0 }} className="metadata-editor-header-asset-select">
            <div
              className={checked ? "metadata-editor-checkbox-selected" : "metadata-editor-checkbox-unselected"}
              onClick={() => {
                handleSelectAllImages();
              }}
            >
              {checked ? <SelectedCheckbox /> : <UnSelectedCheckbox />}
            </div>
            <div className="select-all-images-text">Select all images</div>
          </div>
        </div>

        <div>
          {selectedImages === 0 && <div>No selected assets</div>}
          {selectedImages > 0 && <div> Selected Assets: {selectedImages} </div>}
          <div
            style={{
              marginTop: "8.5px",
              border: "solid rgb(228,229,231) 0.5px",
              height: 0,
            }}
          ></div>
          <div className="metadata-editor-header-checkboxes-div">
            <div style={{ display: "grid", gridTemplateColumns: "0.3fr 1fr" }}>
              {isCpat && (
                <>
                  <div
                    onClick={handleShareAssets}
                    className={
                      shareAssets ? "metadata-editor-checkbox-selected" : "metadata-editor-checkbox-unselected"
                    }
                  >
                    {shareAssets ? <SelectedCheckbox /> : <UnSelectedCheckbox />}
                  </div>
                  <p>Share to all LMS's</p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
