import React from "react";
import { ElementTypes } from "../../../pageTypes/BasicPage_Player/components/IBasePage";
import HighlightAnnotation from "../../Annotation/hooks/HighlightAnnotation";
import { handleKeyUp } from "../../ObjectPropertyBox/functions/PropertyBoxFunctions";
import { SymbolComponentType } from "../helpers/getSymbol";

const GlideslopePointer = (props: SymbolComponentType) => {
  const { index, attributes, kp, transformStr, handleKeyPress, markSelected, objectIsInTimeline } = props;
  const symbols: ElementTypes = "symbols";
  const isDisplayed = typeof attributes.isDisplayed === "undefined" || attributes.isDisplayed;
  const opacity = objectIsInTimeline ? 1 : isDisplayed ? 1 : 0.5;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 4.43 25.2"
      key={index}
      id={`symbol-${index}`}
      tabIndex={0}
      className="symbol target"
      name="symbol"
      onMouseDown={(e: React.MouseEvent<SVGSVGElement>) => markSelected(e.currentTarget, index, symbols)}
      onKeyDown={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyPress(e, kp)}
      onKeyUp={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyUp(e, kp)}
      preserveAspectRatio="xMidYMid meet"
      style={{
        top: `${attributes.top}%`,
        left: `${attributes.left}%`,
        width: `${attributes.width}%`,
        height: `${attributes.height}%`,
        zIndex: attributes.zIndex,
        transform: transformStr,
        // `rotate(${attributes?.transform?.rotate}deg) scaleX(${attributes?.transform?.scaleX}) scaleY(${attributes?.transform?.scaleY}) matrix3d(${attributes?.transform?.matrix3d})`
        opacity,
      }}
    >
      <path
        // className="glideslope_1"
        stroke={`${attributes.borderColor || "white"}`}
        fill={`${attributes?.backgroundColor || "transparent"}`}
        strokeWidth={`${attributes.lineWidth || 0.1}`}
        d="M 3.26,1.36 A 1.01,1.01 0 0 1 2.25,2.37 1.01,1.01 0 0 1 1.24,1.36 1.01,1.01 0 0 1 2.25,0.35000002 1.01,1.01 0 0 1 3.26,1.36"
      />
      <path
        // className="glideslope_1"
        stroke={`${attributes.borderColor || "white"}`}
        fill={`${attributes?.backgroundColor || "transparent"}`}
        strokeWidth={`${attributes.lineWidth || 0.1}`}
        d="m 3.26,7.1599998 a 1.01,1.01 0 0 1 -1.01,1.01 1.01,1.01 0 0 1 -1.01,-1.01 1.01,1.01 0 0 1 1.01,-1.0099999 1.01,1.01 0 0 1 1.01,1.0099999"
      />
      <path
        // className="glideslope_1"
        stroke={`${attributes.borderColor || "white"}`}
        fill={`${attributes?.backgroundColor || "transparent"}`}
        strokeWidth={`${attributes.lineWidth || 0.1}`}
        d="m 3.26,18.110001 a 1.01,1.01 0 0 1 -1.01,1.01 1.01,1.01 0 0 1 -1.01,-1.01 1.01,1.01 0 0 1 1.01,-1.01 1.01,1.01 0 0 1 1.01,1.01"
      />
      <path
        // className="glideslope_1"
        stroke={`${attributes.borderColor || "white"}`}
        fill={`${attributes?.backgroundColor || "transparent"}`}
        strokeWidth={`${attributes.lineWidth || 0.1}`}
        d="M 3.23,23.84 A 1.01,1.01 0 0 1 2.22,24.85 1.01,1.01 0 0 1 1.21,23.84 1.01,1.01 0 0 1 2.22,22.83 1.01,1.01 0 0 1 3.23,23.84"
      />
      <path
        // className="glideslope_1"
        stroke={`${attributes.borderColor || "white"}`}
        fill={`${attributes?.backgroundColor || "transparent"}`}
        strokeWidth={`${attributes.lineWidth || 0.1}`}
        d="M 0,12.691936 H 4.4299999"
      />
      {/* <path
        // className="glideslope_1"
        stroke={`${attributes.borderColor || 'white'}`}
        fill={`${attributes?.backgroundColor || 'transparent'}`}
        strokeWidth={`${attributes.lineWidth || .1}`}
        d="M 2.25,9.81 3.78,12.68 2.25,15.46 0.69,12.64 Z"
      /> */}
      <HighlightAnnotation annotation={attributes as any} id={"glow-rect-for-symbols"} />
    </svg>
  );
};

export default GlideslopePointer;
