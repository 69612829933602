// import React, { useState, useEffect } from "react";
// import { UseTablesDataDispatch, UseTablesDataState } from "./TablesDataProvider";
import { ReactComponent as ArrowIcon } from "../../assets/icons/HeaderIcons/drop-down.svg";
import InsertRowMenu from "./InsertRowMenu";
import RemoveRowMenu from "./RemoveRowMenu";
import React, { useState } from "react";
import MenuNewTableSize from "./MenuNewTableSize";
import { useTablesDataDispatch, useTablesDataState } from "./TablesDataProvider";

function AddRowButton() {
  const [isSizeMenuOpen, setIsSizeMenuOpen] = useState(false);
  const [isSizeMenu2Open, setIsSizeMenu2Open] = useState(false);
  // const [isInsert]

  const TablesDispatch = useTablesDataDispatch();
  const TablesState = useTablesDataState();

  return TablesState.selectedRow !== undefined ? (
    <div className="flex-row">
      <button
        className="wysiwyg-toolbar-btn"
        id="table-btn"
        onClick={() => {
          setIsSizeMenuOpen(!isSizeMenuOpen);
        }}
      >
        Insert
        <ArrowIcon style={isSizeMenuOpen ? { transform: "rotate(180deg)" } : {}} className="header-preview-svg" />
      </button>
      <InsertRowMenu isMenuOpen={isSizeMenuOpen} setIsMenuOpen={setIsSizeMenuOpen} />
      <button
        className="wysiwyg-toolbar-btn"
        id="table-btn"
        onClick={() => {
          setIsSizeMenu2Open(!isSizeMenu2Open);
        }}
      >
        Remove
        <ArrowIcon style={isSizeMenu2Open ? { transform: "rotate(180deg)" } : {}} className="header-preview-svg" />
      </button>
      <RemoveRowMenu isMenuOpen={isSizeMenu2Open} setIsMenuOpen={setIsSizeMenu2Open} />
    </div>
  ) : (
    <></>
  );
}

export default AddRowButton;
