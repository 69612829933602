import "../fmsDesigner.css";
import React, { useContext, useEffect, useState } from "react";
import {
  IPageContext,
  PageContext,
  LessonPageActionsContext,
  ILessonPageActionsContext,
} from "../../../routes/builderContexts";
import { IFmsPageManifest } from "../Interfaces/IFmsPageManifest";
import _, { sortedLastIndex, before, forEach, initial, update } from "lodash";
import { IFmsContext, FmsContext } from "../Interfaces/fmsContext";
import GraphicStateSelectionMenu from "./GraphicStateSelectionMenu";
import CursorSelectOptions from "./CursorSelectOptions";
import HoldingDisplayOptions from "./HoldingDisplayOptions";
import PageTemplateOptions from "./PageTemplateOptions";
import { CDUScreenObjects, CDUScreenObjectsSelect } from "./CDUScreenObjects";

const FMSLayoutOptions: React.FC = () => {
  const pagesContext: IPageContext = useContext<IPageContext>(PageContext);
  const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);
  const pageManifest: IFmsPageManifest = pagesContext.pageManifest as IFmsPageManifest;
  const lessonPageActionsContext: ILessonPageActionsContext =
    useContext<ILessonPageActionsContext>(LessonPageActionsContext);
  const pageTemplates = lessonPageActionsContext.fmsObject.cduObject.pageTemplates;
  const [selectedGraphic, setSelectedGraphic] = useState("");

  useEffect(() => {}, [selectedGraphic]);

  const getEditableGraphicsList = () => {
    const editableGraphicsList: any = [];
    function checkForChangeImage(rectangularObject: any, index: number) {
      if (rectangularObject?.actions[0]?.function === "changeImage") {
        editableGraphicsList.push(rectangularObject.id);
      }
    }

    fmsContext.currentCDUPageData.rectangularObjects.forEach(checkForChangeImage);
    return editableGraphicsList;
  };

  const getSelectedGraphicStates = () => {
    let graphicStates: any = [];
    function getStates(rectangularObject: any, index: number) {
      if (rectangularObject.id === selectedGraphic) {
        graphicStates = rectangularObject.actions[0].parameter.changeImage.assets;
      }
    }
    fmsContext.currentCDUPageData.rectangularObjects.forEach(getStates);
    return graphicStates;
  };

  const selectGraphic = (value: string) => {
    setSelectedGraphic(value);
  };

  const selectState = (value: string) => {
    // setSelectedGraphic(value);
  };

  return (
    <React.Fragment>
      <div className="row">
        <div
          className=""
          style={{
            borderRadius: "3px 3px 0 0",
            width: "30%",
            minWidth: "fit-content",
            backgroundColor: "white",
            marginInline: "2.5%",
          }}
        >
          <strong style={{ marginInline: "10px", color: "#293D54" }}>Page Template Settings</strong>
        </div>
      </div>
      <div className="row">
        <div
          style={{
            border: "1px solid white",
            borderRadius: "0px 5px 5px 5px",
            width: "95%",
            marginInline: "2.5%",
          }}
        >
          <PageTemplateOptions />
          <HoldingDisplayOptions />
          <GraphicStateSelectionMenu />
          <CursorSelectOptions />
          <CDUScreenObjectsSelect />
        </div>
      </div>
    </React.Fragment>
  );
};

export default FMSLayoutOptions;
