import React, { useState } from "react";
import axios from "axios";

export default function Delete(props: any) {
  const lessonVersionId: number = props.lessonId; // This is actually lesson version Id.... didn't change upstream stuff yet...

  const [message, setMessage] = useState("Push the button to delete Lesson version " + lessonVersionId);
  const [isDone, setDone] = useState(false);

  const onDeleteComplete = (r: any) => {
    if (r.data.isSuccess) {
      setMessage("Lesson version " + lessonVersionId + " deleting... successful");
    } else {
      setMessage("Lesson version " + lessonVersionId + " deleting... failed. " + r.data.errorMessage);
    }
  };

  let button = (
    <button
      className="tempbtn3"
      onClick={async () => {
        // let request = {LessonVersionId : props.lessonVersionId};
        setMessage("Deleting lesson version " + lessonVersionId);
        const response = await axios.get<any>("/api/DeleteLesson/" + lessonVersionId);

        setDone(true);
        onDeleteComplete(response);
      }}
    >
      Delete!
    </button>
  );
  if (isDone) button = <div />;

  return (
    <div>
      <p>{message}</p>
      <p>{button}</p>
    </div>
  );
}
