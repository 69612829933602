import _ from "lodash";
import React, { useEffect, useState, useRef, useContext } from "react";
import ReactQuill, { Quill } from "react-quill";
// import './richTextEditor.css';
import "./fmsRichTextEditor.css";
import boxedIcon from "../../../src/assets/icons/FMSIcons/BoxIcon.svg";
import { IFmsPageManifest } from "../../../src/pageTypes/FMS_Player/Interfaces/IFmsPageManifest";
import { PageContext, IPageContext } from "../../../src/routes/builderContexts";
import { IFmsContext, FmsContext } from "../../../src/pageTypes/FMS_Player/Interfaces/fmsContext";

interface rteProps {
  displayText: string;
  placeholder: string;
  module: any;
  assignNewText: (newText: string) => void;
  fontSize?: string[];
  pageFormats?: string[];
}

interface quillToolbar {
  toolbar: {
    container: (
      | string[]
      | {
          color?: string[];
          background?: string[];
          size?: string[];
          align?: string;
        }[]
    )[];
    handlers: { boxedHighlight: () => void };
  };
}

type DeltaOperation = {
  insert?: any;
  delete?: number;
  retain?: number;
} & OptionalAttributes;

interface StringMap {
  [key: string]: any;
}

interface OptionalAttributes {
  attributes?: StringMap;
}

interface DeltaStatic {
  ops?: DeltaOperation[];
  retain(length: number, attributes?: StringMap): DeltaStatic;
  delete(length: number): DeltaStatic;
  filter(predicate: (op: DeltaOperation) => boolean): DeltaOperation[];
  forEach(predicate: (op: DeltaOperation) => void): void;
  insert(text: any, attributes?: StringMap): DeltaStatic;
  map<T>(predicate: (op: DeltaOperation) => T): T[];
  partition(predicate: (op: DeltaOperation) => boolean): [DeltaOperation[], DeltaOperation[]];
  reduce<T>(predicate: (acc: T, curr: DeltaOperation, idx: number, arr: DeltaOperation[]) => T, initial: T): T;
  chop(): DeltaStatic;
  length(): number;
  slice(start?: number, end?: number): DeltaStatic;
  compose(other: DeltaStatic): DeltaStatic;
  concat(other: DeltaStatic): DeltaStatic;
  diff(other: DeltaStatic, index?: number): DeltaStatic;
  eachLine(predicate: (line: DeltaStatic, attributes: StringMap, idx: number) => any, newline?: string): DeltaStatic;
  transform(index: number, priority?: boolean): number;
  transform(other: DeltaStatic, priority: boolean): DeltaStatic;
  transformPosition(index: number, priority?: boolean): number;
}

interface format {
  spanwrapper: string;
}

const Parchment = Quill.import("parchment");
const Delta = Quill.import("delta");
const icons = Quill.import("ui/icons");
icons["spanwrapper"] = '<img src="' + boxedIcon + '">';
icons["boxedHighlight"] = "BOX";

const StandardFormats = [
  "background",
  "bold",
  "color",
  "font",
  "italic",
  "link",
  "size",
  "strike",
  "script",
  "underline",
  "blockquote",
  "header",
  "indent",
  "list",
  "align",
  "direction",
  "image",
  "video",
  "spanwrapper",
];
const RichTextEditor = (props: rteProps) => {
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const pageManifest: IFmsPageManifest = pageContext.pageManifest as IFmsPageManifest;
  const quillComponent = useRef<ReactQuill>(null);
  const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);

  const AttributorRegister = () => {
    const SpanWrapper = new Parchment.Attributor.Class("spanwrapper", "span", {
      scope: Parchment.Scope.INLINE,
    });
    Quill.register(SpanWrapper, true);
    // Quill.
  };

  const spanWrapperFunction = () => {
    const editor = quillComponent.current?.getEditor();
    const range = editor?.getSelection();
    if (range !== null && range !== undefined && range.length > 0) {
      const format: any = editor?.getFormat(range.index, range.length);
      if (!format?.hasOwnProperty("spanwrapper")) {
        editor?.format("spanwrapper", "wrapper");
      } else {
        // editor ?.removeFormat(range.index, range.length)
        const content = editor?.getContents();
        const textArray = editor?.getText().split("");
        const selectedContent = editor?.getContents(range.index, range.length);
        const selectedText = editor?.getText(range.index, range.length);
        const selectedTextArray = selectedText?.split("");
        const newContent = _.cloneDeep(content);
        const rangeIndex = range.index;
        const rangeLength = range.length;
        let indexInOp: any = 0;
        let opWithSelection: any = null;
        let indexOfOpWithSelection: any = null;
        let indexOfStringInOp: any = null;
        // find op with selection by looping through ops and adding op.index.length to indexInOp until indexInOp >= range.index
        // then set opWithSelection to that op

        newContent?.ops?.forEach((op: any, index: number) => {
          if (indexInOp <= rangeIndex || indexInOp === 0) {
            if (op?.insert?.length) {
              if (indexInOp <= rangeIndex || indexInOp === 0) {
                indexInOp += op?.insert?.length;
              }
              if (indexInOp >= rangeIndex + 1) {
                indexOfStringInOp = rangeIndex - (indexInOp - op?.insert?.length);
                indexOfOpWithSelection = index;
                opWithSelection = op;
                //exit loop
                return;
              }
            }
          }
        });

        newContent?.ops?.forEach((op: any, index: number) => {
          const selectionStartIndex: any = rangeIndex;
          const selectionRangeLength: any = rangeLength;

          //remove spanwrapper attribute from selected text

          // find selected text in correct op.insert using range.index and range.length
          // split op.insert into new ops based on selected text

          if (index === indexOfOpWithSelection) {
            //split op.insert indexofStringInOp to indexofStringInOp + rangeLength and any other text after and before
            const newStringParts = [];
            const selection = opWithSelection?.insert?.substring(
              indexOfStringInOp,
              indexOfStringInOp + selectionRangeLength,
            );
            const stringBeforeSelection = opWithSelection?.insert?.substring(0, indexOfStringInOp);
            const stringAfterSelection = opWithSelection?.insert?.substring(indexOfStringInOp + selectionRangeLength);
            let indexOfSelectionInNewStringParts: any = null;
            if (stringBeforeSelection?.length > 0) {
              newStringParts.push(stringBeforeSelection);
            }
            if (selection?.length > 0) {
              newStringParts.push(selection);
              indexOfSelectionInNewStringParts = newStringParts.length - 1;
            }
            if (stringAfterSelection?.length > 0) {
              newStringParts.push(stringAfterSelection);
            }

            //add selected test to new string parts at index 1
            // newStringParts.splice(1, 0, selectedText);
            let newOps: any = newStringParts.map((part: string, index: number) => {
              if (part.length > 0) {
                const noWrapperAttribute = { ...op.attributes };
                delete noWrapperAttribute.spanwrapper;

                if (op.attributes?.spanwrapper) {
                  return {
                    insert: part,
                    attributes:
                      index === indexOfSelectionInNewStringParts ? { ...noWrapperAttribute } : { ...op.attributes },
                  };
                }
              }
            });
            // remove old op and add new ops

            newOps = newOps.filter((op: any) => op !== undefined);
            newContent?.ops?.splice(index, 1, ...newOps);
          }
        });

        //based on range add new op without spanwrapper and insert selected text

        if (newContent && textArray) {
          // editor ?.updateContents(newContent)
          editor?.setContents(newContent, "user");
          editor?.setSelection(range);
          // assignRawInput(text, newContent, "api")
        }
      }
    }
  };

  const quillModules = {
    toolbar: {
      container: [
        ["bold", "italic", "underline"],
        [
          {
            color: [
              "#FFFF00",
              "#FF0000",
              "#FFC0CB",
              "#00FF00",
              "#808080",
              "#00FFFF",
              "#A8B8C8",
              "#FFBF00",
              "#FF9900",
              "#FF00FF",
              "#00B500",
              "#969DA6",
              "#00CCCC",
              "#0000FF",
              "#F2DB18",
              "#CC0000",
              "#990099",
              "#72BE44",
              "#DADBDD",
              "#0091FF",
              "#283D55",
              "#CC7722",
              "#7C0A02",
              "#5E36FF",
              "#9FFF0A",
              "#4B4F58",
              "#FFFFFF",
              "#000000",
            ],
          },
        ],
        [
          {
            background: [
              "#FFFF00",
              "#FF0000",
              "#FFC0CB",
              "#00FF00",
              "#808080",
              "#00FFFF",
              "#A8B8C8",
              "#FFBF00",
              "#FF9900",
              "#FF00FF",
              "#00B500",
              "#969DA6",
              "#00CCCC",
              "#0000FF",
              "#F2DB18",
              "#CC0000",
              "#990099",
              "#72BE44",
              "#DADBDD",
              "#0091FF",
              "#283D55",
              "#CC7722",
              "#7C0A02",
              "#5E36FF",
              "#9FFF0A",
              "#4B4F58",
              "#FFFFFF",
              "#000000",
              "transparent",
            ],
          },
        ],
        [
          {
            size: props.fontSize ? props.fontSize : ["1rem", "1.5rem", "2rem"],
          },
        ],
        [],
      ],
      handlers: { spanwrapper: spanWrapperFunction },
    },
  };
  //adds boxedHighlight button to toolbar for e190 and e175 fms and e145

  if (
    (pageManifest.cdu === "e190_cdu.json" ||
      pageManifest.cdu === "q400_cdu.json" ||
      pageManifest.cdu === "e175_cdu.json" ||
      pageManifest.cdu === "e145_cdu.json") &&
    document.getElementById("cduSingleLineEditor") !== null
  ) {
    quillModules.toolbar.container.splice(1, 0, ["spanwrapper"]);
  }

  //in order to preserve html styling instructions needs to be set into a state varible with the whitelist
  const [loadedText, setloadedText] = useState({
    rawInput: "",
    loadedText: "",
  });
  const [isLoading, setIsLoading]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState<boolean>(true);
  const [quillToolbarCopy, setQuillToolbarCopy]: [any, React.Dispatch<React.SetStateAction<any>>] =
    useState<any>(quillModules);

  useEffect(() => {
    AttributorRegister();
    const SizeStyle = Quill.import("attributors/style/size");
    if (props.fontSize) {
      SizeStyle.whitelist = props.fontSize;
    } else {
      SizeStyle.whitelist = ["1rem", "1.5rem", "2rem"];
    }
    Quill.register(SizeStyle, true);
    setloadedText({
      ...loadedText,
      rawInput: props.displayText,
    });
  }, []);

  //due to the interaction of setState and quill the user input needs to be tracked in a 'rawInput' which is then set into loadedText so it can be added to the page manifest
  useEffect(() => {
    const tempToolbar = quillModules;
    tempToolbar.toolbar.container.splice(tempToolbar.toolbar.container.length, 1, props.module);
    if (props.displayText !== undefined) {
      setFormattedString(props.displayText); ///////// THIS is causing a crash on a350 when opening a modal after selecting more than one cdu
    }
    setloadedText({
      rawInput: props.displayText,
      loadedText: props.displayText,
    });
    setQuillToolbarCopy(tempToolbar);
    const editor = quillComponent.current?.getEditor();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (props.displayText !== loadedText.rawInput) {
      loadedText.rawInput = props.displayText;
      setloadedText(loadedText);
    }
  }, [props.displayText]);

  useEffect(() => {
    const quill = quillComponent.current?.getEditor();
  }, [quillComponent.current]);

  const setFormattedString = (text: string) => {
    //assigns text to json : setFormattedString
    const formattedString = formatUnicodeWithinString(text);
    setloadedText({
      ...loadedText,
      rawInput: formattedString,
    });
  };

  const assignRawInput = (newText: string, delta: any, source: string) => {
    const boxedEdit = delta.ops.some(
      (v: any) =>
        // v?.attributes?.spanwrapper
        "attributes" in v && "spanwrapper" in v?.attributes,
    );
    if (source === "user") {
      const formattedString = formatUnicodeWithinString(newText);
      setloadedText({
        ...loadedText,
        rawInput: formattedString,
      });
      props.assignNewText(formattedString);
    } else if (source === "api" && boxedEdit) {
      props.assignNewText(newText);
    }
  };

  const formatUnicodeWithinString = (inputString: string): string => {
    //recursivly iterates through a string and converts unicode characters
    if (
      inputString.length > 0 &&
      inputString.includes("\\u") &&
      inputString.replace(/<[^>]*>?/gm, "").length - 5 > inputString.replace(/<[^>]*>?/gm, "").indexOf("\\u")
    ) {
      //unicode detected
      const testvar = inputString.slice(inputString.indexOf("\\u"), inputString.indexOf("\\u") + 6); //slices out the unicode then formats it now it needs to be put back into the string at position
      const formattedCode = String.fromCharCode(parseInt(testvar.slice(2, 6), 16));

      //creates a new string by splicing out the unicode and placing the unicode character in its place
      // let convertedInput = [inputString.slice(0, inputString.indexOf("\\u")), formattedCode, inputString.slice(inputString.indexOf("\\u") + 6, inputString.length - 1)].join("");
      const convertedInput = [
        inputString.slice(0, inputString.indexOf("\\u")),
        formattedCode,
        inputString.slice(inputString.indexOf("\\u") + 6, inputString.length),
      ].join("");

      if (convertedInput.includes("\\u")) {
        //if there are still unformatted codes the function executes again
        return formatUnicodeWithinString(convertedInput);
      } else {
        return convertedInput;
      }
    } else {
      return inputString;
    }
  };

  //create react function component

  function FocusQuill() {
    const editor = quillComponent.current?.getEditor();
    if (editor) {
      editor.focus();
      editor.setSelection(editor.getLength(), 0);
    }
    return <></>;
  }

  useEffect(() => {
    //focuses on the editor when the page loads
    FocusQuill();
  }, [isLoading]);

  if (isLoading) {
    return <></>;
  } else {
    return (
      // <div id="new-quill-editor" >

      // auto focus on the editor

      <React.Fragment>
        <ReactQuill
          ref={quillComponent}
          theme="snow"
          defaultValue={loadedText.loadedText}
          value={loadedText.rawInput}
          className="max-height-one-hundred-percent heighBox"
          onChange={assignRawInput}
          // style={{color: "#ffffff"}}
          formats={props.pageFormats ? props.pageFormats : StandardFormats}
          preserveWhitespace
          modules={quillToolbarCopy}
        />
      </React.Fragment>
      // </div>
    );
  }
};
export default RichTextEditor;
