import React, { useState, useContext } from "react";
import { ICduImage } from "../../../pageTypes/FMS_Player/Interfaces/ICdu";
// import genericRepositoryService from '../../../services/genericRepositoryService';
// import { IGetFmsModelsResponse } from "../../../models/IGetFmsModelsResponse";
// import IApiResponse from '../../../models/IApiResponse';
import { LessonPageActionsContext, ILessonPageActionsContext } from "../../../routes/builderContexts"; //need for cdu path
import blobUrlFormatHelper from "../../blobUrlFormatHelper";
import IAsset from "../../../models/IAsset";
import { FMSObject } from "../../../routes/builder";
// import CduPageEditor from "../../../pageTypes/FMS_Player/components/cduPageEditor";

interface fmsDropDownProps {
  lessonSettings?: boolean;
  pageNumber: number;
  setCurrentFMSObject: (object: FMSObject) => void;
  // setFmsData: (value: IFMSObject) => void;
}
const FmsDropDown: React.FC<fmsDropDownProps> = (props: fmsDropDownProps) => {
  const lessonPageActions: ILessonPageActionsContext = useContext<ILessonPageActionsContext>(LessonPageActionsContext);

  // const [modelName, setModelName] = useState<string>("");
  // const [fmsModelList, setFmsModelList] = useState<IAsset[]>([]);
  // const [selectedFms, setSelectedFms] = useState<IAsset>({
  //     assetVersionId: -1,
  //     blobPath: "",
  //     name: "",
  //     description: "",
  //     displayVersion: "",
  //     assetTypeName: "",
  //     isCollection: false,
  //     parentVersionId: -1
  // });
  // const [cduImage, setCduImage] = useState<ICduImage>();

  const displayList = () => {
    return lessonPageActions.fmsCdus.map((cdu, index) => {
      return (
        <div
          className="fms-select-row"
          onClick={() =>
            props.setCurrentFMSObject({
              cdu: cdu.cduName ? cdu.cduName : "",
              cduPath: cdu.blobPath,
              assetVersionId: cdu.assetVersionId,
              cduObject: cdu.cduObject,
            })
          }
        >
          <svg className="image-fluid selector-fms-svg" style={{ marginLeft: "10%" }}>
            <g>
              <image height="100%" width="100%" href={blobUrlFormatHelper(`${cdu.imagePath}`)} />
            </g>
          </svg>
          <div className="fms-selector-description-container">
            <div className="">{cdu.name}</div>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <div className="fms-select-container" style={{ textAlign: "center" }}>
        {displayList()}
      </div>
    </>
  );
};

export default FmsDropDown;
