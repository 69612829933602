import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { IFmsPageManifest, IFMSReferenceImage } from "../../../../../pageTypes/FMS_Player/Interfaces/IFmsPageManifest";
import blobHelper from "../../../../blobUrlFormatHelper";
import AnnotationThumbnail from "../../../../Annotation/AnnotationsThumbnail/AnnotationThumbnail";
import SymbolsThumbnail from "../../../../Symbol/SymbolsThumbnail/SymbolsThumbnail";
import _ from "lodash";

type ImageType = {
  largeCDU: boolean;
  pageManifest: IFmsPageManifest;
  refImage: any;
  displayReferenceImageModal: (e: React.MouseEvent<HTMLSpanElement>) => void;
  removeImage: (e: any, assetVersionId: number, index: number) => void;
  triggerUploadModal: (e: React.MouseEvent<HTMLDivElement>, val: number) => void;
};

const ReferenceImage = (props: ImageType) => {
  const { pageManifest } = props;

  const thumbRef: React.RefObject<HTMLImageElement> = React.useRef<HTMLImageElement>(null);
  const [imgRect, setImageRect]: [DOMRect | undefined, React.Dispatch<React.SetStateAction<DOMRect | undefined>>] =
    React.useState<DOMRect | undefined>();
  const [loading, setLoading]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = React.useState<boolean>(true);
  const [ratio, setRatio]: [number, Dispatch<SetStateAction<number>>] = useState<number>(0.5012);
  const [tabOrThumbnailBoolean, setTabOrThumbnail] = React.useState<boolean>(false);
  const pageImage = pageManifest.pageImage;
  const imgHeight: number | undefined =
    pageImage !== undefined && pageImage.length > 0
      ? (pageManifest.pageImage as IFMSReferenceImage[])[0].height
        ? (pageManifest.pageImage as IFMSReferenceImage[])[0].height
        : undefined
      : undefined;
  // const imgHeight: number | undefined = _.has((pageManifest.pageImage as IFMSReferenceImage[])[0], 'height') ? (pageManifest.pageImage as IFMSReferenceImage[])[0].height : undefined;
  // var img = new Image();
  // img.src = blobHelper(props.pageManifest.pageImage[0].imagePath);

  const handleLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setImageRect(rect);
    setLoading(false);
  };

  useEffect(() => {
    setTabOrThumbnail(props.largeCDU);
  }, []);

  useEffect(() => {
    if (!loading && thumbRef.current && imgHeight) {
      const temp: number = thumbRef.current.getBoundingClientRect().height / imgHeight;
      setRatio(temp);
    }
  }, [loading, thumbRef.current, imgHeight]);

  return (
    <div className="fms-img-container">
      {tabOrThumbnailBoolean ? (
        <div className="img-tab-container">
          <div
            className="img-tab-container-inside"
            onClick={(e: React.MouseEvent<HTMLDivElement>) => props.triggerUploadModal(e, 0)}
          >
            <div className="fms-img-label-container">
              <p className="img-tab-label">Navigation Display</p>
            </div>

            <span
              className="icon-expand-image-icon"
              onClick={(e: React.MouseEvent<HTMLSpanElement>) => props.displayReferenceImageModal(e)}
            >
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
              <span className="path4"></span>
            </span>
          </div>
        </div>
      ) : (
        <div className="fms-image-upload-container">
          <div className="fms-image-upload">
            <div id="remove-fms-reference">
              <div
                id="remove-fms-reference-btn"
                onClick={(e: any) =>
                  props.removeImage(e, (props.pageManifest.pageImage as IFMSReferenceImage[])[0].assetVersionId, 0)
                }
              >
                <span className="icon-remove-no-background"></span>
              </div>
            </div>
            <div className="img-aspect-container">
              <div className="img-aspect-container-inside">
                <div className="img-aspect-centering">
                  <div className="fms-img-padding">
                    <div id="fms-thumbnail-img">
                      {props.pageManifest?.pageImage ? (
                        <img
                          ref={thumbRef}
                          className="ref-img"
                          id="fms-thumb-img"
                          src={blobHelper((props.pageManifest?.pageImage as IFMSReferenceImage[])[0]?.imagePath)}
                          alt="FMS Reference Image"
                          onLoad={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleLoad(e)}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    {props.pageManifest.refAnnotations && !loading ? (
                      <AnnotationThumbnail
                        annotations={props.pageManifest.refAnnotations}
                        imgRect={imgRect as DOMRect}
                        largeCDU={props.largeCDU}
                        ratio={ratio}
                      />
                    ) : null}
                    {props.pageManifest.refSymbols && !loading ? (
                      <SymbolsThumbnail
                        symbols={props.pageManifest.refSymbols}
                        imgRect={imgRect as DOMRect}
                        largeCDU={props.largeCDU}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
              <span
                className="expandIcon icon-expand-image-icon"
                onClick={(e: React.MouseEvent<HTMLSpanElement>) => props.displayReferenceImageModal(e)}
              >
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                <span className="path4"></span>
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReferenceImage;
