import _ from "lodash";
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { handleKeyPress, handleKeyUp } from "../../ObjectPropertyBox/functions/PropertyBoxFunctions";
import { AnnotationPropType } from "../Annotation";
import HighlightAnnotation from "../hooks/HighlightAnnotation";

const AnnotationTriangle = (props: AnnotationPropType) => {
  const { annotation, index, kp, markSelected, objectIsInTimeline } = props;
  const triangleRef: React.RefObject<SVGSVGElement> = useRef<SVGSVGElement>(null);
  const [bounds, setBounds]: [
    { height: number; width: number },
    Dispatch<SetStateAction<{ height: number; width: number }>>,
  ] = useState<{ height: number; width: number }>({ height: 0, width: 0 });
  const [isLoading, setIsLoading]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(true);
  const isDisplayed = typeof annotation.isDisplayed === "undefined" || annotation.isDisplayed;
  const opacity = objectIsInTimeline ? 1 : isDisplayed ? 1 : 0.5;

  useEffect(() => {
    function handleLoad() {
      setIsLoading(false);
    }

    handleLoad();
  }, []);

  useEffect(() => {
    const makeBounds = () => {
      if (triangleRef.current) {
        const rect = triangleRef.current?.getBoundingClientRect();
        if (!_.isEqual(bounds, { height: rect.height, width: rect.width })) {
          const newBounds: { height: number; width: number } = {
            height: rect.height,
            width: rect.width,
          };
          if (!isLoading)
            triangleRef.current.style.transform = `rotate(${
              annotation?.transform?.rotate ? annotation?.transform?.rotate?.toFixed(2) : 0
            }deg)`;
          setBounds(newBounds);
        }
      }
    };

    if (!isLoading) {
      makeBounds();
    }

    // return () => { newBounds = {height: 0, width: 0}}
  }, [isLoading]);

  useEffect(() => {
    window.addEventListener("resize", checkResize);
    return () => {
      window.removeEventListener("resize", checkResize);
    };
  }, []);

  function checkResize() {
    const thisSVG: any = document.getElementById(`triangle-${index}`) as any;
    if (thisSVG && thisSVG.clientHeight > 0) {
      thisSVG.firstChild.attributes.points.nodeValue = `4,${thisSVG.clientHeight - 4} ${thisSVG.clientWidth / 2},4 ${
        thisSVG.clientWidth - 4
      },${thisSVG.clientHeight - 4}`;
    }
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // viewBox={`0 0 auto ${100 / ratio.height}`}
      height={`100`}
      id={`triangle-${index}`}
      tabIndex={0}
      name="triangle"
      className="annotation"
      onMouseDown={(e: React.MouseEvent<SVGSVGElement>) => markSelected(e.currentTarget, index, "annotations")}
      onKeyDown={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyPress(e, kp)}
      onKeyUp={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyUp(e, kp)}
      preserveAspectRatio="xMinYMin meet"
      opacity={opacity}
      style={{
        top: `${annotation.top.toFixed(2)}%`,
        left: `${annotation.left.toFixed(2)}%`,
        width: `${annotation.width.toFixed(2)}%`,
        height: `${annotation.height.toFixed(2)}%`,
        zIndex: annotation.zIndex,
      }}
      ref={triangleRef}
    >
      {bounds.height > 0 ? (
        <polygon
          id="triangle-points"
          points={`${4},${bounds?.height - 4 || 0} 
            ${(bounds?.width - 2 || 0) / 2},4 
            ${bounds?.width - 4 || 0},${bounds?.height - 4 || 0}`}
          stroke={`${annotation.borderColor || "white"}`}
          fill={`${annotation.backgroundColor || "transparent"}`}
          strokeWidth={`${annotation.strokeWidth || "4"}`}
        />
      ) : (
        <></>
      )}
      <HighlightAnnotation annotation={annotation} />
    </svg>
  );
};

export default AnnotationTriangle;
