import React, { useContext, useEffect, useState } from "react";
import lodash from "lodash";
import { IFmsContext, FmsContext } from "../Interfaces/fmsContext";
import { IPageContext, PageContext, IAssetManagementContext, AssetContext } from "../../../routes/builderContexts";
import { IFmsPageManifest } from "../Interfaces/IFmsPageManifest";
import blobUrlFormatHelper from "../../../components/blobUrlFormatHelper";
import EmptyImage from "../../../components/EmptyImage";

import { ReactComponent as UploadPlusIcon } from "../../../assets/icons/AssetManager/add-gray-version-icon.svg";
import { ReactComponent as RemovePageIcon } from "../../../assets/icons/Common/remove-page-icon.svg";
import {
  modalComponentProps,
  removeIconProps,
} from "../../../../src/components/WYSIWYG/pageTypes/FMS_Player/components/FMSModalComponents/interfaces/FmsModalInterfaces";
import addButton from "../../../assets/icons/Add-gray.svg";
import { setisIGS } from "../../../store/actions/actions";

// import blobHelper from '../../../../blobUrlFormatHelper';

export interface IHoldingDisplayProps {
  imagePath: string;
  assetVersionId: number;
  parentVersion: number;
}

const CDUHoldingDisplay = (props: any) => {
  //in development
  const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);
  const pagesContext: IPageContext = useContext<IPageContext>(PageContext);
  const pageManifest: IFmsPageManifest = pagesContext.pageManifest as IFmsPageManifest;
  const assetContext: IAssetManagementContext = useContext<IAssetManagementContext>(AssetContext);
  const [imageToDisplayIndex, setImageToDisplayIndex] = useState(0);
  const [isRemove, setIsRemove] = useState(false);
  const imgPath: string | undefined = blobUrlFormatHelper(
    pageManifest?.holdingDisplay?.[imageToDisplayIndex]?.imagePath,
  );

  useEffect(() => {
    if (!isRemove && pageManifest.holdingDisplay !== undefined && pageManifest.holdingDisplay.length > 0) {
      if (pageManifest.holdingDisplay.length !== 1) {
        setImageToDisplayIndex(
          imageToDisplayIndex < pageManifest.holdingDisplay.length - 1
            ? pageManifest.holdingDisplay.length - 1
            : imageToDisplayIndex + 1,
        );
      }
    }
    setIsRemove(false);
  }, [pageManifest.holdingDisplay?.length]);

  const triggerUploadModal = (e: React.MouseEvent<HTMLDivElement>, imageIndex: number) => {
    e.preventDefault();
    assetContext.setAssetIndex(imageIndex);
    assetContext.setAssetTypeId(4);
    assetContext.setAssetManagerController({
      isOpen: true,
      mode: "ImagesOnly",
    });
  };

  const removeImage = (e: any, assetVersionId: number, index: number) => {
    setIsRemove(true);

    if (imageToDisplayIndex !== 0) {
      const newIndex = fmsContext.imageToDisplayIndex - 1;
      setImageToDisplayIndex(imageToDisplayIndex - 1);
      fmsContext.setImageToDisplayIndex(newIndex);
    }
    if (pageManifest.holdingDisplay) {
      pagesContext.updatePageAsset(null, assetVersionId);
      pageManifest.holdingDisplay.splice(index, 1);
      pagesContext.updatePageManifest(pageManifest);
    }

    // if(pageManifest.holdingDisplay.length === 0){
    //     delete pageManifest.holdingDisplay
    // }
  };

  const ImageList = () => {
    return pageManifest.holdingDisplay.map((object, index) => {
      return (
        <React.Fragment key={index}>
          <button
            className={index === imageToDisplayIndex ? "holding-active-graphic" : "holding-inactive-graphic"}
            key={index}
            onClick={() => setImageToDisplayIndex(index)}
          >
            {index + 1}
          </button>
        </React.Fragment>
      );
    });
  };

  const AddAnotherImage = () => {
    return (
      <div
        className="add-remove-hg"
        onClick={(e: React.MouseEvent<HTMLDivElement>) => triggerUploadModal(e, pageManifest.holdingDisplay.length)}
      >
        {/* <UploadPlusIcon style={{height:"25px", width:"25px"}} /> */}
        <span style={{ fontSize: "Large" }}>+</span>
        {/* <button></button> */}
      </div>
    );
  };

  const RemoveIcon = (props: removeIconProps) => {
    return (
      <div
        id="remove-fms-holding-modal-btn"
        onClick={(e: any) => removeImage(e, props.assetVersionId, imageToDisplayIndex)}
      >
        {/* <span className="remove-fms-image-modal-button">Remove Image</span> */}
        {/* <div className="header-icon"> */}

        <RemovePageIcon style={{ height: "25px", width: "25px" }} />
        {/* </div> */}
      </div>
    );
  };

  const style = pageManifest.cdu.includes("q400")
    ? { x: "191", y: "95", width: "239", height: "200" }
    : { x: "200", y: "98", width: "200", height: "160" };

  return fmsContext.holdingDisplayEditorShown &&
    (pageManifest.cdu.includes("145") || pageManifest.cdu.includes("q400")) ? (
    <foreignObject className="holding-pattern-upload" style={style}>
      {/* {pageManifest.holdingDisplay.length === 0 ? */}
      {/* {(!pageManifest.holdingDisplay && (pageManifest.holdingDisplay as any).length === 0 ) ? */}
      {!(pageManifest.holdingDisplay as any).length ? (
        <div
          className="fms-image-upload-holding"
          onClick={(e: React.MouseEvent<HTMLDivElement>) => triggerUploadModal(e, 0)}
        >
          <EmptyImage showUploadText={false} displayText="Click here to select or upload an image." imageOnly={true} />
        </div>
      ) : (
        <>
          <div
            className="fms-holding-img-container"
            onDoubleClick={(e: React.MouseEvent<HTMLDivElement>) => triggerUploadModal(e, imageToDisplayIndex)}
          >
            <img className="fms-holding-img" src={imgPath}></img>
            {/* {imageToDisplayIndex} */}
          </div>
          <div className="holdingDisplayGUI">
            {ImageList()}
            <RemoveIcon removeImage={props.removeImage} assetVersionId={props.assetVersionId} index={0} />
            {AddAnotherImage()}
          </div>
        </>
      )}
    </foreignObject>
  ) : (
    <></>
  );
};

export default CDUHoldingDisplay;
