import { createContext, PropsWithChildren, ReactNode, useContext, useRef, useState } from "react";
import { useModalState } from "../../hooks/useModalState";
import { ConfirmationModal } from "../../components/Modals/ConfirmationModal";
import { ModalSize } from "../../components/Modals/BaseModal/types";

export type ConfirmationParams = {
  title: string;
  message: ReactNode;
};

interface ConfirmationState {
  confirm: (params: ConfirmationParams) => Promise<boolean>;
}

const ConfirmationContext = createContext<ConfirmationState>({} as ConfirmationState);

export function ConfirmationProvider({ children }: PropsWithChildren<unknown>) {
  const { setOpen, isOpen, onClose } = useModalState();
  const [content, setContent] = useState<ConfirmationParams>({ title: "", message: "" });
  const resolverReference = useRef<(confirm: boolean) => void>();

  const handleConfirm = ({ title, message }: ConfirmationParams) => {
    setContent({
      title,
      message,
    });
    setOpen(true);

    return new Promise<boolean>((resolve) => {
      resolverReference.current = resolve;
    });
  };

  const context: ConfirmationState = {
    confirm: handleConfirm,
  };

  const onConfirm = () => {
    resolverReference?.current?.(true);
    onClose();
  };

  const onCancel = () => {
    resolverReference?.current?.(false);
    onClose();
  };

  return (
    <ConfirmationContext.Provider value={context}>
      {children}
      <ConfirmationModal
        size={ModalSize.XS}
        title={content.title}
        message={content.message}
        open={isOpen}
        clickOutside={false}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    </ConfirmationContext.Provider>
  );
}

export const useConfirmation = (): ConfirmationState => useContext(ConfirmationContext);
