import React from "react";

// import HighlightAnnotation from "../hooks/HighlightAnnotation";
import { SVGAnnotationProps } from "../index";
import { useObjectsState } from "../../../contexts/ObjectsProvider";
import { HighlightAnnotation } from "../HighlightAnnotation";

export function SVGSquareNumber({
  x,
  y,
  objectId,
  onMouseDown,
  isDisplayed,
  rotate,
  height,
  width,
  zIndex,
  opacity,
  borderColor,
  strokeWidth,
  backgroundColor,
  fontColor,
}: SVGAnnotationProps & {
  fontColor?: string;
}) {
  const { annotations } = useObjectsState();

  const circleLetterAnnotations = annotations.filter((annotation: any) => annotation.type === "squareNumber");
  const index = circleLetterAnnotations.findIndex((annotation: any) => annotation.objectId === objectId);
  const transformString = `translate(${x}px, ${y}px) rotate(${rotate ?? 0}deg)`;

  return (
    <svg
      viewBox="0 0 100 100"
      id={`squareNumber-${objectId}`}
      data-objectid={objectId}
      className="annotation"
      name="squareNumber"
      onMouseDown={(e: React.MouseEvent<SVGSVGElement>) => {
        onMouseDown(e, objectId);
      }}
      preserveAspectRatio="xMinYMin meet"
      tabIndex={0}
      style={{
        width: `${width}px`,
        height: `${height}px`,
        zIndex: zIndex,
        transform: transformString,
        opacity: isDisplayed ? opacity : 0.5,
        filter: isDisplayed ? "none" : "grayScale(100%)",
      }}
    >
      <rect
        x="1.5"
        y="1.5"
        height="97"
        width="97"
        stroke={`${borderColor ? borderColor : "white"}`}
        strokeWidth={`${strokeWidth || "3"}`}
        fill={`${backgroundColor ? backgroundColor : "#4B4F58"}`}
      ></rect>
      <HighlightAnnotation objectId={objectId} id="glow-rect-for-square" />

      <text
        // style={{transform: 'translate(-2px, -1px)'}}
        className="step-text"
        x="50"
        y="50"
        dy="26.5"
        dx="0.0em"
        textAnchor="middle"
        fill={`${fontColor ? fontColor : "white"}`}
      >
        {index + 1}
      </text>
    </svg>
  );
}
