const AnnotationPentagon = () => {
  return (
    <svg width="27" height="22" viewBox="0 0 27 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2.68945" y="1.84462" width="16.5815" height="16.5815" rx="1" stroke="white" strokeWidth="2" />
      <circle cx="2.53385" cy="2.53385" r="2.28385" fill="#4B4F58" stroke="white" strokeWidth="0.5" />
      <circle cx="2.53385" cy="18.5815" r="2.28385" fill="#4B4F58" stroke="white" strokeWidth="0.5" />
      <circle cx="19.4245" cy="18.5815" r="2.28385" fill="#4B4F58" stroke="white" strokeWidth="0.5" />
      <circle cx="19.4245" cy="2.53385" r="2.28385" fill="#4B4F58" stroke="white" strokeWidth="0.5" />
      <circle cx="19.4245" cy="10.1354" r="2.28385" fill="#4B4F58" stroke="white" strokeWidth="0.5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4255 10.6177L25.3508 9.47657L23.8882 7.77291L26.1404 5.80035L25.1847 4.68706L22.9131 6.63717L21.4506 4.93353L19.4255 10.6177Z"
        fill="white"
        stroke="#4B4F58"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default AnnotationPentagon;
