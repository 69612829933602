import React from "react";
import { AbstractTooltip as Tooltip } from "../../ToolTip/ToolTip";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../../contexts/ObjectsProvider";
import { ReactComponent as PasteIcon } from "../../../assets/Paste-White.svg";

const PasteButton = () => {
  const { selectedObjects } = useObjectsState();
  const objectsDispatch = useObjectsDispatch();
  return (
    <button
      className="wysiwyg-toolbar-btn"
      id="paste"
      onClick={() => {
        objectsDispatch({
          type: ObjectActionsType.PASTE_OBJECTS,
        });
      }}
      data-tooltip-id="Paste"
    >
      <Tooltip id="Paste" />
      <PasteIcon />
      {/* <span className="icon-paste"></span> */}
    </button>
  );
};

export default PasteButton;
