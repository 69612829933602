import React, { useContext } from "react";
import UploaderOptionFields from "./UploaderOptionFields";
import { IUploaderContext, UploaderContext } from "./uploaderContext";

const BlobUploader = (): JSX.Element => {
  const uploaderContext: IUploaderContext = useContext<IUploaderContext>(UploaderContext);
  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12" style={{ marginBottom: "10px" }}>
            <input className="form-control-file" type="file" ref={uploaderContext.rawFile}></input>
          </div>
          <UploaderOptionFields />
        </div>
      </div>
    </React.Fragment>
  );
};

export default BlobUploader;
