import React, { useState, useEffect, useRef } from "react";
// import { IPageContext, PageContext } from "../../routes/builderContexts";
import { ReactComponent as DropDownIcon } from "../../assets/icons/FMSIcons/Dropdown.svg";
// import { IFmsPageManifest } from "../../pageTypes/FMS_Player/Interfaces/IFmsPageManifest";
import "./actionSelect.css";
interface actionSelectProps {
  action: string;
  options: string[];
  highlightColor: string;
  highlightTextColor: string;
  disabledOptions: string[];
  defaultBackground: string;
  defaulTextColor: string;
  displayValues: string[];
  selectOption: (value: string) => void;
  parentClass: string;
  parentId: string;
  optionWrapperClass: string;
  optionClass: string;
  arrowClass?: string;
}
const CriteriaActionSelect = (props: actionSelectProps) => {
  //changing the highlight of a select element is "impossible" since its handled by the OS apparently so I made my own

  // const pagesContext: IPageContext = useContext<IPageContext>(PageContext); //data and context for page manifest
  // const pageManifest: IFmsPageManifest = pagesContext.pageManifest as IFmsPageManifest;
  const dropDown = useRef<HTMLDivElement>(null);
  const [displayOptions, setDisplayOptions] = useState<boolean>(false);
  const [hoveringElement, setHoveringElement] = useState<string>("");
  const [inFocus, setInFocus] = useState<boolean>(false);
  const hoveringArrow = {
    // right: "90%",
    cursor: "pointer",
    marginRight: "1%",
    height: "8px",
    marginTop: "4%",
  };
  const arrow = {
    // right: "90%",
    cursor: "initial",
    marginRight: "1%",
    height: "8px",
    marginTop: "4%",
  };
  useEffect(() => {
    const handleClick = (e: any) => {
      if (dropDown.current && dropDown?.current?.contains(e.target)) {
        // inside click
        setInFocus(true);
        return;
      }
      // outside click
      setInFocus(false);
      setDisplayOptions(false);
      setHoveringElement("");
    };
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);
  const closeClick = () => {
    setDisplayOptions(false);
    setHoveringElement("");
  };
  return (
    <div
      ref={dropDown}
      className={
        inFocus
          ? props.parentClass + " action-select-wrapper-div focus-select-wrapper-div"
          : props.parentClass + " action-select-wrapper-div"
      }
      id={props.parentId}
      onFocus={() => {
        setInFocus(true);
      }}
      onBlur={() => {
        setDisplayOptions(false);
        setHoveringElement("");
        setInFocus(false);
      }}
    >
      <span className="action-select-span" style={{ width: "100%" }}>
        <span
          className="current-options-tag"
          onClick={(event: any) => {
            event.preventDefault();
            displayOptions ? closeClick() : setDisplayOptions(!displayOptions);
          }}
        >
          {props.action}
        </span>
        <DropDownIcon
          className={"drop-arrow " + props.arrowClass}
          onClick={(event: any) => {
            event.preventDefault();
            setDisplayOptions(!displayOptions);
          }}
          onMouseEnter={() => {
            setHoveringElement("arrow");
          }}
          onMouseLeave={() => {
            setHoveringElement("");
          }}
          style={hoveringElement === "arrow" ? hoveringArrow : arrow}
        />
      </span>

      {displayOptions ? (
        <div className={props.optionWrapperClass + " action-select-options-container"}>
          {props.options.map((option, index) => (
            <p
              className={props.optionClass}
              key={option + index}
              // id={option}
              onMouseEnter={() => {
                if (!props.disabledOptions.includes(option)) {
                  setHoveringElement(option);
                }
              }}
              onMouseLeave={() => {
                setHoveringElement("");
              }}
              onClick={(event: any) => {
                event.preventDefault();
                if (!props.disabledOptions.includes(option) && props.action !== props.displayValues[index]) {
                  props.selectOption(option);
                  setDisplayOptions(false);
                }
              }}
              style={{
                width: "100%",
                backgroundColor: hoveringElement === option ? props.highlightColor : props.defaultBackground,
                color: props.disabledOptions.includes(option)
                  ? "gray"
                  : hoveringElement === option
                  ? props.highlightTextColor
                  : props.defaulTextColor,
                cursor: props.disabledOptions.includes(option) ? "default" : "pointer",
                userSelect: "none",
                paddingLeft: "5px",
              }}
            >
              {props.displayValues[index]}
            </p>
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default CriteriaActionSelect;
