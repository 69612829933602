import { IAnnotation } from "../models/IAnnotation";
import { IAnnotationState } from "../models/IAnnotationState";

export function updateLabel(text: string, aState: IAnnotationState, scrollHeight: number) {
  (aState.tempNode as IAnnotation).text = text;
  (aState.tempNode as IAnnotation).height = scrollHeight;
  if (aState.annotations) {
    // aState.annotations[aState.editableIndex] = handleWidthChange(e.currentTarget);
    aState.annotations[aState.editableIndex] = aState.tempNode as IAnnotation;
    aState.setNodeToUpdate(aState.tempNode as IAnnotation);
    // pageContext.updatePageManifest(pageManifest);
  }
  return aState.annotations;
}

export function adjustLabelWidth(scrollWidth: number, pageDims: DOMRect) {
  if (scrollWidth && pageDims) {
    const width = (scrollWidth / pageDims?.width) * 100;
    // pageManifest.annotations[editableIndex].width = width + 1;
    return width + 1;
  }
  return scrollWidth;
}

export function adjustLabelHeight(scrollHeight: number, pageDims: Partial<DOMRect>) {
  if (scrollHeight && pageDims) {
    const height = (scrollHeight / pageDims!.height) * 100;
    // pageManifest.annotations[editableIndex].height = height;
    return height + 0.75;
  }
  return scrollHeight;
}
