import React, { useEffect, useState } from "react";
import "./SelectCabinModal.styles.css";
import "./../CognitiveServices/inputPicker.css";
import { Modal } from "react-bootstrap";
import { ReactComponent as ArrowDown } from "../../../assets/icons/HeaderIcons/arrow-gray-down.svg";

import InputPicker from "rsuite/InputPicker";

type DataItemType = {
  value: string;
  label: string;
  children?: Array<DataItemType>; // property value is the value of childrenKey
  groupBy?: string;
};

type ItemDataType = {
  label?: string | React.ReactNode;
  value?: string | number;
  groupBy?: string;
  parent?: ItemDataType;
  children?: ItemDataType[];
  loading?: boolean;
};

const SelectCabinModal = (props: any) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [models, setModels] = useState<DataItemType[]>([{ value: "", label: "" }]);
  // const [isSelected, setIsSelected] = useState<boolean>(false);

  useEffect(() => {
    modelSelector();
  }, [props.cabinSelectorShowing]);

  React.useEffect(() => {
    if (props.cabinModels.length > 0) {
      props.setCurrentCabinObject({
        modelSet: props.cabinModels[selectedIndex].blobPath,
        role: "Captain",
        assetVersionId: props.cabinModels[selectedIndex].assetVersionId,
      });
    }
  }, [selectedIndex, props.cabinSelectorShowing]);

  const handleCancel = () => {
    props.setCabinSelectorShowing(false);
  };

  const handleSelect = () => {
    // if(isSelected){
    //     if(props.cabinModels.length > 0){
    //         props.setCurrentCabinObject({modelSet: props.cabinModels[selectedIndex].blobPath, role: "Captain", assetVersionId:props.cabinModels[selectedIndex].assetVersionId})
    //     }
    //
    // }
    props.addNewPageAndDisplay(props.currentlyDisplayedPageIndex + 1, "Cabin", "Cabin");
    handleCancel();
  };

  const modelSelector = () => {
    const models = props.cabinModels.map((model: any, index: number) => {
      return {
        value: index,
        label: model.name,
      };
    });

    setModels(models);
  };

  function handleOnChange(e: number) {
    setSelectedIndex(e);
    // setIsSelected(true);
  }

  const ShowModelSelector = () => {
    const myProps = {
      caretComponent: ArrowDown,
    };

    return (
      <InputPicker
        {...myProps}
        data={models}
        appearance="default"
        placeholder="Select a Cabin"
        labelKey={"label"}
        valueKey={"value"}
        onChange={(e) => {
          handleOnChange(e);
        }}
        size={"sm"}
        cleanable={false}
        className={"model-dropdown"}
        menuMaxHeight={150}
      />
    );
  };

  return (
    <>
      {props.cabinSelectorShowing && (
        <Modal
          show={props.cabinSelectorShowing}
          onHide={handleCancel}
          dialogClassName={"cabin-modal"}
          backdrop={"static"}
          keyboard={false}
          bsPrefix={"cabin"}
        >
          <Modal.Header closeButton>
            <Modal.Title className={"cabin-title"}>
              <p>Cabin Model</p>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body bsPrefix={"cabin-body"}>
            <label className={"text-area-label"}>Select a model to continue:</label>
            <ShowModelSelector />
          </Modal.Body>

          <Modal.Footer>
            <div className="cabin-modal-buttons">
              <button onClick={handleCancel}>Cancel</button>
              <button onClick={handleSelect}>Confirm</button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default SelectCabinModal;
