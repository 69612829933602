import _ from "lodash";
import React from "react";
import { ElementTypes } from "../../../../pageTypes/BasicPage_Player/components/IBasePage";
import { IAnnotation } from "../../../Annotation/models/IAnnotation";
import { ISymbolV2 } from "../../../Symbol/models/ISymbol";
// import AlignButtons from '../../../WYSIWYGToolbar/components/AlignButtons';
import AnnotationButton from "../../../WYSIWYGToolbar/components/AnnotationButton";
import AnnotationFontColorBtn from "../../../WYSIWYGToolbar/components/AnnotationFontColorBtn";
import BorderColorButton from "../../../WYSIWYGToolbar/components/BorderColorButton";
import DeleteButton from "../../../WYSIWYGToolbar/components/DeleteButton";
import FillButton from "../../../WYSIWYGToolbar/components/FillButton";
// import FontColorButton from '../../../WYSIWYGToolbar/components/FontColorButton';
// import FontStyleButtons from '../../../WYSIWYGToolbar/components/FontStyleButtons';
import LineWidthButton from "../../../WYSIWYGToolbar/components/LineWidthButton";
import SendToBack from "../../../WYSIWYGToolbar/components/SendToBackButton";

type IGAAnnotationMenuType = {
  attributes: IAnnotation | ISymbolV2;
  editableIndex: number;
  elementType: ElementTypes;
  addNewAnnotation(type: string, addFunction: any): void;
  addSymbolOLD(name: string, addFunction: any): void;
  handleFormatChange(e: any, funct: any, value: any, keyValue: any): void;
  removeElement(): void;
  sendToBack(): void;
};

const IGAAnnotationMenuBar = (props: IGAAnnotationMenuType) => {
  const {
    attributes,
    editableIndex,
    elementType,
    addNewAnnotation,
    addSymbolOLD,
    handleFormatChange,
    removeElement,
    sendToBack,
  } = props;
  const fontAnnotation: string[] = ["circledNumber", "circledLetter", "squareNumber", "squareLetter"];
  const lineAnnotation: string[] = ["fillArrow", "lineArrow", "solidLine", "dashedLine"];
  const canShowFontColorBtn: boolean = attributes && _.includes(fontAnnotation, (attributes as IAnnotation).type);
  const canShowFillColorBtn = !_.includes(lineAnnotation, (attributes as IAnnotation)?.type);

  return (
    <React.Fragment>
      {/* {attributes && (attributes as IAnnotation).type === "label" ?
        <React.Fragment>
          <FontStyleButtons attributes={attributes as IAnnotation} handleFormatChange={handleFormatChange} /> 
          <AlignButtons attributes={attributes as IAnnotation} handleFormatChange={handleFormatChange} />
        </React.Fragment>
       : <></>
      } */}
      {canShowFontColorBtn && editableIndex >= 0 ? (
        <AnnotationFontColorBtn handleFormatChange={handleFormatChange} />
      ) : (
        <></>
      )}
      {editableIndex >= 0 ? <BorderColorButton handleFormatChange={handleFormatChange} /> : <></>}
      {editableIndex >= 0 && canShowFillColorBtn ? <FillButton handleFormatChange={handleFormatChange} /> : <></>}
      {elementType === "annotations" && editableIndex >= 0 && attributes ? (
        <LineWidthButton attributes={attributes as IAnnotation} handleFormatChange={handleFormatChange} />
      ) : (
        <></>
      )}
      {0 <= editableIndex ? <SendToBack sendToBack={sendToBack} /> : <></>}
      <AnnotationButton addNewAnnotation={addNewAnnotation} addSymbolOLD={addSymbolOLD} />
      {0 <= editableIndex ? <DeleteButton removeElement={removeElement} /> : <></>}
    </React.Fragment>
  );
};

export default IGAAnnotationMenuBar;
