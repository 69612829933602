import React, { useState, useEffect } from "react";
import { useTablesDataDispatch, useTablesDataState } from "./TablesDataProvider";
import MenuNewTableSize from "./MenuNewTableSize";
import { ReactComponent as InsertBottom } from "./assets/insert-bottom.svg";
import { ReactComponent as InsertAbove } from "./assets/insert-top.svg";
import { ReactComponent as InsertLeft } from "./assets/insert-left.svg";
import { ReactComponent as InsertRight } from "./assets/insert-right.svg";
import { ObjectActionsType, useObjectsDispatch } from "../../contexts/ObjectsProvider";

type InsertColumnMenu = {
  isMenuOpen: boolean;
  setIsMenuOpen(boolean: boolean): void;
};

function InsertColumnMenu(props: InsertColumnMenu) {
  const TablesDispatch = useTablesDataDispatch();
  const TablesState = useTablesDataDispatch();
  const objectsDispatch = useObjectsDispatch();

  useEffect(() => {
    function clickOutsideMenu(e: MouseEvent) {
      props.setIsMenuOpen(false);
      document.removeEventListener("click", clickOutsideMenu);
    }
    if (props.isMenuOpen) {
      document.addEventListener("click", clickOutsideMenu);
    }
  }, [props.isMenuOpen]);

  return props.isMenuOpen ? (
    <div className="table-inset-menu-body">
      {/* <div className="table-inset-menu-body"> */}

      <div
        onClick={() => {
          // props.setIsMenuOpen(false);
          // TablesDispatch({ action: "insertColumn", payload: ["before"] });
          objectsDispatch({
            type: ObjectActionsType.UPDATE_TABLE,
            payload: { type: "insertColumn", value: "before", selection: TablesState.selectedColumn },
          });
        }}
      >
        <InsertLeft></InsertLeft> Insert Left
      </div>
      <div
        onClick={() => {
          // props.setIsMenuOpen(false);
          // TablesDispatch({ action: "insertColumn", payload: ["after"] });
          objectsDispatch({
            type: ObjectActionsType.UPDATE_TABLE,
            payload: { type: "insertColumn", value: "after", selection: TablesState.selectedColumn },
          });
        }}
      >
        <InsertRight></InsertRight> Insert Right
      </div>
      {/* </div> */}
    </div>
  ) : null;
}

export default InsertColumnMenu;
