import { useInteracitvityHotspotState } from "../../../contexts/InteractivityHotspotProvider";
import { IAnnotation } from "../models/IAnnotation";
import React from "react";
import HightlightRect from "../assets/HightlightRect";
import { AllPartialBut } from "../../../types";

interface Props {
  annotation: AllPartialBut<IAnnotation, "isDisplayed">;
  id?: string;
  width?: number;
  height?: number;
}
export default function AnnotationHighlight({ annotation, id, width, height }: Props) {
  const hotspotState = useInteracitvityHotspotState();
  const targets = hotspotState?.currentlySelectedHotspot?.interactivityBuilderAction?.level.getAllTargetIdsInActions();
  const isHighlighted = targets !== undefined && targets.has(annotation?.objectId ?? "");
  return isHighlighted ? <HightlightRect id={id ? id : "glow-rect"} width={width} height={height} /> : <></>;
}
