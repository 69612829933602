import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import "./machineTranslation.css";
import { ReactComponent as CopyTextIcon } from "../../../../assets/icons/copy-text.svg";
import genericRepositoryService from "../../../../services/genericRepositoryService";
import { IPageContext, PageContext } from "../../../../routes/builderContexts";
import { ReactComponent as TranslateIcon } from "../../../../assets/icons/NarratorTab/translate-icon.svg";
import InputPicker from "rsuite/InputPicker";
import "../inputPicker.css";
import { IMachineTranslationRequest } from "../../../../models/IMachineTranslationRequest";
import { IAutoDetectLanguageRequest } from "../../../../models/IAutoDetectLanguageRequest";
import { ReactComponent as ArrowDown } from "../../../../assets/icons/HeaderIcons/arrow-gray-down.svg";
import { AbstractTooltip as Tooltip } from "../../../ToolTip/ToolTip";

type DataItemType = {
  value: string;
  label: string;
  children?: Array<DataItemType>; // property value is the value of childrenKey
  groupBy?: string;
};

type MachineTranslationProps = {
  pageManifest: any;
  index: number;
  alternate: boolean;
};

type Language = {
  code: string;
  name: string;
  direction: string;
};

const MachineTranslation = (props: MachineTranslationProps) => {
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const [show, setShow] = useState<boolean>(false);
  const [hasText, setHasText] = useState<boolean | null>(null);
  const [fromLanguage, setFromLanguage] = useState<string>("");
  const [toLanguage, setToLanguage] = useState<string>("");
  const [translatedText, setTranslatedText] = useState<string>("");
  const [detectedLanguage, setDetectedLanguage] = useState<string>("");
  const [supportedLanguages, setSupportedLanguages] = useState<DataItemType[]>();
  const [languages, setLanguages] = useState<Language[]>();
  const [toDirection, setToDirection] = useState<string>("ltr");
  const [fromDirection, setFromDirection] = useState<string>("ltr");

  const textToTranslate = useRef<HTMLTextAreaElement>(null);

  const autoDetectLanguage = async () => {
    const request: IAutoDetectLanguageRequest = {
      textToDetectLanguage: narrationText,
    };

    if (narrationText !== "") {
      setHasText(true);
    }

    const response = await genericRepositoryService.detectLanguage(request);

    if (response.data.errorMessage) {
      setDetectedLanguage("");
      setFromLanguage("");
    }

    if (response.data.detectedLanguage) {
      setFromLanguage(response.data.detectedLanguage);
      setDetectedLanguage(response.data.detectedLanguage);
    }

    if (
      props.pageManifest &&
      "Audio" in props.pageManifest &&
      props.pageManifest.Audio !== undefined &&
      props.pageManifest.Audio[props.index] &&
      props.pageManifest.Audio[props.index].LanguageDirection
    ) {
      setFromDirection(props.pageManifest.Audio[props.index].LanguageDirection);
    }
  };

  const handleShow = async () => {
    await autoDetectLanguage().then(() => {
      setShow(true);
    });
  };

  const handleClose = () => {
    setShow(false);
    resetModal();
  };

  // set Modal state back to default
  function resetModal() {
    setFromLanguage("");
    setToLanguage("");
    setTranslatedText("");
    setDetectedLanguage("");
    setHasText(null);
  }

  let tabIndex = 0;

  useEffect(() => {
    getLanguages().then(() => {});
  }, []);

  const narrationText =
    props.pageManifest &&
    "Audio" in props.pageManifest &&
    props.pageManifest.Audio !== undefined &&
    props.pageManifest.Audio[props.index] &&
    props.pageManifest.Audio[props.index].NarratorText
      ? props.pageManifest.Audio[props.index].NarratorText
      : "";

  // because narrator-tab-name vs props.index is weird when you have alternate audio
  switch (props.index) {
    case 0:
    case 3:
      tabIndex = 0;
      break;
    case 1:
    case 4:
      tabIndex = 1;
      break;
    case 2:
    case 5:
      tabIndex = 2;
      break;
  }

  const tab = document.getElementsByClassName("narrator-tab-name")[tabIndex];

  const getLanguages = async () => {
    await genericRepositoryService.getSupportedTranslationLanguages().then((result) => {
      const languages = [];

      for (const v in result.data.supportedTranslationLanguages) {
        const obj = {
          value: result.data.supportedTranslationLanguages[v]["code"],
          label: result.data.supportedTranslationLanguages[v]["name"],
        };
        languages.push(obj);
      }
      setLanguages(result.data.supportedTranslationLanguages);
      setSupportedLanguages(languages);
    });
  };

  function handleFromLanguageChange(value: string) {
    for (const v in languages) {
      if (languages[v as unknown as number]["code"] === value) {
        setToDirection(languages[v as unknown as number]["direction"]);
        setFromLanguage(value);
        return;
      }
    }
    setFromLanguage("");
  }

  function handleToLanguageChange(value: string) {
    for (const v in languages) {
      if (languages[v as unknown as number]["code"] === value) {
        setToLanguage(value);
        return;
      }
    }

    setToLanguage("");
  }

  const myProps = {
    caretComponent: ArrowDown,
  };

  function showFromLanguageSelect() {
    if (show) {
      if (supportedLanguages) {
        supportedLanguages.sort((a, b) => (a.label > b.label ? 1 : -1));

        return (
          <InputPicker
            {...myProps}
            data={supportedLanguages}
            defaultValue={detectedLanguage}
            appearance="default"
            placeholder="Select a Language"
            labelKey={"label"}
            valueKey={"value"}
            size={"sm"}
            cleanable={false}
            className={"language-dropdown"}
            onChange={(e) => {
              handleFromLanguageChange(e as string);
            }}
            menuMaxHeight={150}
          />
        );
      }
    }
  }

  function showToLanguageSelect() {
    if (show) {
      if (supportedLanguages) {
        supportedLanguages.sort((a, b) => (a.label > b.label ? 1 : -1));

        return (
          <InputPicker
            {...myProps}
            data={supportedLanguages}
            appearance="default"
            placeholder="Select a Language"
            labelKey={"label"}
            valueKey={"value"}
            size={"sm"}
            cleanable={false}
            className={"language-dropdown"}
            onChange={(e) => {
              handleToLanguageChange(e as string);
            }}
            menuMaxHeight={150}
          />
        );
      }
    }
  }

  function translateButton(showButton: boolean) {
    if (show) {
      if (showButton) {
        return (
          <div className={"translate-button-container"}>
            <button
              className={"translate-button"}
              onClick={translateText}
              disabled={!(toLanguage && fromLanguage && hasText)}
            >
              Translate
            </button>
          </div>
        );
      }
    }
  }

  const translateText = async () => {
    const text =
      textToTranslate && textToTranslate.current && textToTranslate.current.value ? textToTranslate.current.value : "";

    const request: IMachineTranslationRequest = {
      fromLanguageCode: fromLanguage,
      toLanguageCode: toLanguage,
      textToTranslate: text,
    };

    const response = await genericRepositoryService.translateText(request);

    setTranslatedText(response.data.translatedText);

    for (const v in languages) {
      if (languages[v as unknown as number]["code"] === toLanguage) {
        setToDirection(languages[v as unknown as number]["direction"]);
      }
    }
  };

  function showLanguageSelector(className: string, label: string) {
    let selector: JSX.Element | undefined;

    if (label === "To") {
      selector = showToLanguageSelect();
    } else {
      selector = showFromLanguageSelect();
    }

    return (
      <div className={className}>
        <div className={"label-container"}>
          <label className={"text-area-label"}>{label}:</label>
        </div>
        <div className={"language-selection-container"}>
          <div className={"language-selector"}>{selector}</div>
        </div>
        {label === "To" ? translateButton(true) : ""}
      </div>
    );
  }

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(translatedText);
  };

  function handleFromTextChange(text: string) {
    if (text) {
      setHasText(true);
    }

    if (!text) {
      setHasText(false);
    }
  }

  function handleToTextChange(text: string) {
    setTranslatedText(text);
  }

  function saveTranslationToNarrator() {
    // if it's All Modes, then set all modes
    if (tab.innerHTML === "All Modes") {
      for (let i = 0; i < 3; i++) {
        props.pageManifest.Audio[i].NarratorText = translatedText;
        props.pageManifest.Audio[i].LanguageDirection = toDirection;
      }
    } else {
      // set for specific modes
      props.pageManifest.Audio[props.index].NarratorText = translatedText;
      props.pageManifest.Audio[props.index].LanguageDirection = toDirection;
    }

    pageContext.updatePageManifest(props.pageManifest);

    handleClose();
    resetModal();
  }

  function saveTranslationToAlternative() {
    // the way narrator drawer works, we have to set a "true" index for alternate narrations
    let realIndex = 0;

    switch (props.index) {
      case 0:
      case 3:
        realIndex = 3;
        break;
      case 1:
      case 4:
        realIndex = 4;
        break;
      case 2:
      case 5:
        realIndex = 5;
        break;
    }

    // if it's All Modes, then set all modes
    if (tab.innerHTML === "All Modes") {
      for (let i = 3; i < 6; i++) {
        props.pageManifest.Audio[i].NarratorText = translatedText;
        props.pageManifest.Audio[i].LanguageDirection = toDirection;
      }
    } else {
      // set for specific modes
      props.pageManifest.Audio[realIndex].NarratorText = translatedText;
      props.pageManifest.Audio[realIndex].LanguageDirection = toDirection;
    }

    pageContext.updatePageManifest(props.pageManifest);

    handleClose();
    resetModal();
  }

  function showAlternativeSave() {
    if (props.alternate) {
      return (
        <button onClick={saveTranslationToAlternative} className={"alternative-save-button"}>
          Save as Alternative
        </button>
      );
    }
  }

  const horizontalLine = () => {
    return <hr className={"hr-style"} />;
  };

  return (
    <>
      <button className="translation-modal-button" data-tooltip-id="Translations" onClick={handleShow}>
        <TranslateIcon />
      </button>
      <Tooltip id={"Translations"} />

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName={"translation-modal"}
        backdrop={"static"}
        keyboard={false}
        bsPrefix={"mt"}
      >
        <Modal.Header closeButton>
          <Modal.Title className={"translation-title"}>
            <p>Translation</p>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body
          bsPrefix={"mt-body"}
          style={{
            backgroundColor: "#f5f5f5",
          }}
        >
          {showLanguageSelector("from-language-selector", "From")}

          <textarea
            ref={textToTranslate}
            className={["col-sm-12", fromDirection, "from-text-area"].join(" ")}
            defaultValue={narrationText}
            onChange={(e) => {
              handleFromTextChange(e.target.value);
            }}
          />

          {horizontalLine()}

          {showLanguageSelector("to-language-selector", "To")}

          <textarea
            className={["col-sm-12", toDirection, "to-text-area"].join(" ")}
            defaultValue={translatedText}
            onChange={(e) => {
              handleToTextChange(e.target.value);
            }}
          />

          <div className={"copy-button-container"} data-tooltip-id="Translations">
            <button className={"copy-text-button"}>
              <CopyTextIcon onClick={copyToClipboard} />
            </button>
          </div>
          <Tooltip id={"Translations"} />
        </Modal.Body>

        <Modal.Footer
          style={{
            backgroundColor: "#f5f5f5",
            alignItems: "center",
            justifyContent: "flex-end",
            height: "60px",
          }}
        >
          <div className="translation-modal-buttons">
            <button onClick={handleClose}>Cancel</button>
            <button disabled={props.alternate} onClick={saveTranslationToNarrator}>
              Save
            </button>
            {showAlternativeSave()}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MachineTranslation;
