import React from "react";
import AnnotationSquareLetter from "./AnnotationSquareLetter";

const AnnotationSquareNumber = () => {
  return (
    <React.Fragment>
      <span className="icon-square-number"></span>
    </React.Fragment>
  );
};

export default AnnotationSquareNumber;
