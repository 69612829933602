import { CSSProperties, useMemo } from "react";
import { kebabToCamelCase } from "../../../../utils/Strings";

export const useSmartObjectStyles = (str: string | undefined, extraProperties?: CSSProperties) => {
  const styles = useMemo(() => {
    if (!str && extraProperties) {
      return extraProperties;
    } else if (!str) {
      return {};
    }

    const styleObject = Object.fromEntries(
      str
        .split(";")
        .filter(Boolean)
        .map((style) => style.split(":").map((s) => s.trim()))
        .map(([key, value]) => [kebabToCamelCase(key), value]),
    );

    if (!extraProperties) {
      return styleObject;
    }

    return {
      ...styleObject,
      ...extraProperties,
    };
  }, [str, extraProperties]);

  return styles as CSSProperties;
};
