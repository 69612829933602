import React, { useState, useEffect, useRef } from "react";
import * as THREE from "three";
import { GLTFLoader, GLTF } from "three/examples/jsm/loaders/GLTFLoader.js";
import { CabinProps } from "./types";

import {
  useGLTF,
  useProgress
} from "@react-three/drei"

const Cabin = (props: CabinProps) => {

  THREE.Cache.enabled = false;
  const cabinPath = props.modelPath + "/Cabin.gltf";
  const { scene } = useGLTF(cabinPath)
  const { progress } = useProgress();

  useEffect(() => {
    if(progress === 100) {
      props.callBack();
    }
  }, [progress])

  useEffect(() => {
    if (!scene) return;
    // these are arbitrary offsets that 'feel right'
    const xOffset = 2.5;
    const zOffset = 4;
    const yOffset = 1;
    const bounds = new THREE.Box3().setFromObject(scene);
    //if(!props.setBoundaries) return;
    props.setBoundaries({
      max: {
        x: bounds.max.x - xOffset,
        y: bounds.max.y - yOffset,
        z: bounds.max.z - zOffset,
      },
      min: {
        x: bounds.min.x + xOffset,
        y: bounds.min.y + 2 /*(yOffset/2)*/,
        z: bounds.min.z + zOffset,
      },
    });
  }, [scene]);

  return <primitive object={scene} />
  
  // const stageManager = new THREE.LoadingManager();
  // stageManager.onProgress = function (url, itemsLoaded, itemsTotal) {
  //   if (itemsLoaded === itemsTotal) {
  //     props.callBack();
  //   }
  // };
  // // stageManager.onError = function (url) { console.log('There was an error loading ' + url); };
  

  // const [cabinObject, setCabinObject] = useState<GLTF | null>(null);
  // const mounted = useRef(false);

  // useEffect(() => {
  //   mounted.current = true;
  //   if (!mounted.current) return;
  //   const cabinLoader = new GLTFLoader(stageManager);
  //   cabinLoader.load(cabinPath, setCabinObject);
  //   return () => {
  //     mounted.current = false;
  //   };
  // }, [cabinPath]);

  // useEffect(() => {
  //   if (!cabinObject) return;
  //   // these are arbitrary offsets that 'feel right'
  //   const xOffset = 2.5;
  //   const zOffset = 4;
  //   const yOffset = 1;
  //   const bounds = new THREE.Box3().setFromObject(cabinObject.scene);
  //   //if(!props.setBoundaries) return;
  //   props.setBoundaries({
  //     max: {
  //       x: bounds.max.x - xOffset,
  //       y: bounds.max.y - yOffset,
  //       z: bounds.max.z - zOffset,
  //     },
  //     min: {
  //       x: bounds.min.x + xOffset,
  //       y: bounds.min.y + 2 /*(yOffset/2)*/,
  //       z: bounds.min.z + zOffset,
  //     },
  //   });
  // }, [cabinObject]);

  // return cabinObject ? <primitive object={cabinObject.scene} /> : null;
};

export default Cabin;
