import _ from "lodash";
import { IBasicPageAttributes } from "../../../pageTypes/BasicPage_Player/components/IBasePage";
import { IAnnotation } from "../../Annotation/models/IAnnotation";
import ISymbolStyle from "../../Symbol/models/ISymbolStyle";

type FormatFunctionType = {
  makeFontBold(
    attributes: IAnnotation | IBasicPageAttributes | ISymbolStyle,
  ): IAnnotation | IBasicPageAttributes | ISymbolStyle;
  makeFontItalic(
    attributes: IAnnotation | IBasicPageAttributes | ISymbolStyle,
  ): IAnnotation | IBasicPageAttributes | ISymbolStyle;
  makeFontUnderline(
    attributes: IAnnotation | IBasicPageAttributes | ISymbolStyle,
  ): IAnnotation | IBasicPageAttributes | ISymbolStyle;
  changeAlignment(
    attributes: IAnnotation | IBasicPageAttributes | ISymbolStyle,
    color: any,
    type: "left" | "center" | "right",
  ): IAnnotation | IBasicPageAttributes | ISymbolStyle;
  changeColor(
    attributes: IAnnotation | IBasicPageAttributes | ISymbolStyle,
    color: string,
    type: "fontColor" | "backgroundColor" | "borderColor",
  ): IAnnotation | IBasicPageAttributes | ISymbolStyle;
  changeStrokeWidth(
    attributes: IAnnotation | IBasicPageAttributes | ISymbolStyle,
    type: string,
    strokeWidth: number,
  ): IAnnotation | IBasicPageAttributes | ISymbolStyle;
  // updateAnnotations(pageManifest: any, editableIndex: number, attributes: IAnnotation): void;
};

export const formatFunctions: FormatFunctionType = {
  makeFontBold(attributes: IAnnotation | IBasicPageAttributes | ISymbolStyle) {
    const tempAttributes: IAnnotation | IBasicPageAttributes | ISymbolStyle = _.cloneDeep(attributes);
    tempAttributes.fontWeight = attributes.fontWeight === "bold" ? "normal" : "bold";
    return tempAttributes;
  },

  makeFontItalic(attributes: IAnnotation | IBasicPageAttributes | ISymbolStyle) {
    const tempAttributes: IAnnotation | IBasicPageAttributes | ISymbolStyle = _.cloneDeep(attributes);
    tempAttributes.fontStyle = attributes.fontStyle === "italic" ? "normal" : "italic";
    return tempAttributes;
  },

  makeFontUnderline(attributes: IAnnotation | IBasicPageAttributes | ISymbolStyle) {
    const tempAttributes: IAnnotation | IBasicPageAttributes | ISymbolStyle = _.cloneDeep(attributes);
    tempAttributes.textDecoration = attributes.textDecoration === "underline" ? "none" : "underline";
    return tempAttributes;
  },

  changeAlignment(
    attributes: IAnnotation | IBasicPageAttributes | ISymbolStyle,
    alignment: any,
    type: "left" | "center" | "right",
  ) {
    const tempAttributes: IAnnotation | IBasicPageAttributes | ISymbolStyle = _.cloneDeep(attributes);
    tempAttributes.textAlign = attributes.textAlign === alignment ? "left" : alignment;
    return tempAttributes;
  },

  changeColor(
    attributes: IAnnotation | IBasicPageAttributes | ISymbolStyle,
    color: string,
    type: "fontColor" | "backgroundColor" | "borderColor",
  ) {
    const tempAttributes: IAnnotation | IBasicPageAttributes | ISymbolStyle = _.cloneDeep(attributes);
    tempAttributes[type] = color;
    if (!attributes.strokeWidth && type === "borderColor") tempAttributes.strokeWidth = 4;
    return tempAttributes;
  },

  changeStrokeWidth(
    attributes: IAnnotation | IBasicPageAttributes | ISymbolStyle,
    type: "strokeWidth",
    strokeWidthValue: number,
  ) {
    const tempAttributes: IAnnotation | IBasicPageAttributes | ISymbolStyle = _.cloneDeep(attributes);
    if (attributes?.strokeWidth === undefined || attributes?.strokeWidth === null) {
      attributes.strokeWidth = 4;
    } else {
      attributes.strokeWidth = attributes?.strokeWidth;
    }
    tempAttributes[type] = strokeWidthValue;
    return tempAttributes;
  },
};
