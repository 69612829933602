export default function buttons() {
  function switchHighlightBtn() {
    const doc = document.querySelector("span.ql-background .ql-picker-label");
    if (doc && doc.firstChild) {
      doc.removeChild(doc.firstChild);
      doc.classList.add("icon-text-highlight-1");
    }
  }

  function switchFontColorBtn() {
    const doc = document.querySelector("span.ql-color .ql-picker-label");
    if (doc && doc.firstChild) {
      doc.removeChild(doc.firstChild);
      doc.classList.add("icon-font-color-icon");
    }
  }

  function switchBoldBtn() {
    const doc = document.querySelector("button#bold");
    if (doc && doc.firstChild) {
      doc.removeChild(doc.firstChild);
      doc.classList.add("icon-bold-icon");
    }
  }

  function switchItalicBtn() {
    const doc = document.querySelector("button.ql-italic");
    if (doc && doc.firstChild) {
      doc.removeChild(doc.firstChild);
      doc.classList.add("icon-italic-icon");
    }
  }

  function switchUnderlineBtn() {
    const doc = document.querySelector("button.ql-underline");
    if (doc && doc.firstChild) {
      doc.removeChild(doc.firstChild);
      doc.classList.add("icon-underline-icon");
    }
  }

  function switchLeftAlignBtn() {
    const doc = document.querySelector("button#left.ql-align");
    if (doc && doc.firstChild) {
      doc.removeChild(doc.firstChild);
      doc.classList.add("icon-text-align-left-icon");
    }
  }

  function switchCenterAlignBtn() {
    const doc = document.querySelector("button#center.ql-align");
    if (doc && doc.firstChild) {
      doc.removeChild(doc.firstChild);
      doc.classList.add("icon-text-align-center-icon");
    }
  }

  function switchRightAlignBtn() {
    const doc = document.querySelector("button#right.ql-align");
    if (doc && doc.firstChild) {
      doc.removeChild(doc.firstChild);
      doc.classList.add("icon-text-align-right-icons");
    }
  }

  function switchListBtns() {
    const bullet = document.querySelector("button.ql-list[value='bullet']");
    const number = document.querySelector("button.ql-list[value='ordered']");
    if (bullet && bullet.firstChild) {
      bullet.removeChild(bullet.firstChild);
      bullet.classList.add("icon-text-bullet-points-icon");
    }

    if (number && number.firstChild) {
      number.removeChild(number.firstChild);
      number.classList.add("icon-text-numbers-icon");
    }
  }

  switchBoldBtn();
  switchFontColorBtn();
  switchHighlightBtn();
  switchItalicBtn();
  switchUnderlineBtn();
  switchLeftAlignBtn();
  switchCenterAlignBtn();
  switchRightAlignBtn();
  switchListBtns();
}
