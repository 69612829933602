import React from "react";
import ISymbol from "../models/ISymbol";
import AircraftSymbol from "../components/AircraftSymbol";
import AircraftSymbol1 from "../components/AircraftSymbol1";
import CoursePointer1 from "../components/CoursePointer1";
import CoursePointer2 from "../components/CoursePointer2";
import CoursePointer3 from "../components/CoursePointer3";
import CoursePointer4 from "../components/CoursePointer4";
import CurrentHeadingPointer from "../components/CurrentHeadingPointer";
import CurrentTrack from "../components/CurrentTrack";
import DistanceSymbol from "../components/DistanceSymbol";
import DMETACAN from "../components/DMETACAN";
import ExpandedCompass from "../components/ExpandedCompass";
import GlideslopePointer from "../components/GlideslopePointer";
import HeadingBug from "../components/HeadingBug";
import ILSLocalizer from "../components/ILSLocalizer";
import LineM from "../components/LineM";
import TrackLineRangeScale from "../components/TrackLineRangeScale";
import VOR from "../components/VOR";
import VOR1PointerHead from "../components/VOR1PointerHead";
import VOR1PointerTail from "../components/VOR1PointerTail";
import VOR2PointerHead from "../components/VOR2PointerHead";
import VOR2PointerTail from "../components/VOR2PointerTail";
import VORTAC from "../components/VORTAC";
import ISymbolStyle from "../models/ISymbolStyle";

export type SymbolComponentType = {
  index: number;
  attributes: ISymbolStyle;
  transformStr: string;
};

export function getSymbol(index: number, currentSymbol: ISymbol) {
  let transformStr = `${currentSymbol.transform?.rotate ? `rotate(${currentSymbol.transform.rotate}deg)` : ""}
      ${currentSymbol.transform?.scaleX ? currentSymbol.transform.scaleX : ""} 
      ${currentSymbol.transform?.scaleY ? currentSymbol.transform.scaleY : ""} 
      ${currentSymbol.transform?.matrix3d ? currentSymbol.transform.matrix3d : ""}
    `;
  // return str;
  transformStr = transformStr.trim();
  const symbolComponents: Record<string, JSX.Element> = {
    aircraft: <AircraftSymbol index={index} attributes={currentSymbol} transformStr={transformStr} key={index} />,
    aircraft_1: <AircraftSymbol1 index={index} attributes={currentSymbol} transformStr={transformStr} key={index} />,
    current_heading: (
      <CurrentHeadingPointer index={index} attributes={currentSymbol} transformStr={transformStr} key={index} />
    ),
    current_track: <CurrentTrack index={index} attributes={currentSymbol} transformStr={transformStr} key={index} />,
    distance: <DistanceSymbol index={index} attributes={currentSymbol} transformStr={transformStr} key={index} />,
    dme_tacan: <DMETACAN index={index} attributes={currentSymbol} transformStr={transformStr} key={index} />,
    expanded_compass: (
      <ExpandedCompass index={index} attributes={currentSymbol} transformStr={transformStr} key={index} />
    ),
    glideslope: <GlideslopePointer index={index} attributes={currentSymbol} transformStr={transformStr} key={index} />,
    ils_localizer: <ILSLocalizer index={index} attributes={currentSymbol} transformStr={transformStr} key={index} />,
    line_m: <LineM index={index} attributes={currentSymbol} transformStr={transformStr} key={index} />,
    course_pointer_1: (
      <CoursePointer1 index={index} attributes={currentSymbol} transformStr={transformStr} key={index} />
    ),
    course_pointer_2: (
      <CoursePointer2 index={index} attributes={currentSymbol} transformStr={transformStr} key={index} />
    ),
    course_pointer_3: (
      <CoursePointer3 index={index} attributes={currentSymbol} transformStr={transformStr} key={index} />
    ),
    course_pointer_4: (
      <CoursePointer4 index={index} attributes={currentSymbol} transformStr={transformStr} key={index} />
    ),
    heading_bug: <HeadingBug index={index} attributes={currentSymbol} transformStr={transformStr} key={index} />,
    track_line_range: (
      <TrackLineRangeScale index={index} attributes={currentSymbol} transformStr={transformStr} key={index} />
    ),
    vor_1_head: <VOR1PointerHead index={index} attributes={currentSymbol} transformStr={transformStr} key={index} />,
    vor_1_tail: <VOR1PointerTail index={index} attributes={currentSymbol} transformStr={transformStr} key={index} />,
    vor_2_head: <VOR2PointerHead index={index} attributes={currentSymbol} transformStr={transformStr} key={index} />,
    vor_2_tail: <VOR2PointerTail index={index} attributes={currentSymbol} transformStr={transformStr} key={index} />,
    vor: <VOR index={index} attributes={currentSymbol} transformStr={transformStr} key={index} />,
    vortac: <VORTAC index={index} attributes={currentSymbol} transformStr={transformStr} key={index} />,
  };

  const returnSymbol = symbolComponents[currentSymbol.name];
  return returnSymbol;
}
