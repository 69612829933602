import "./BaseExitPage.css";
import _ from "lodash";
import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  IPageContext,
  PageContext,
  ILessonPageActionsContext,
  LessonPageActionsContext,
  IAssetManagementContext,
  AssetContext,
} from "../../routes/builderContexts";
import blobHelper from "../../components/blobUrlFormatHelper";
import ILessonMetaData from "../../models/ILessonMetaRequest";
import BaseExitEditable from "./components/BaseExitEditable";
import BaseExitNonEditable from "./components/BaseExitNonEditable";
import { IBasicPageImageAttributes, ITitlePageAttributes } from "../BasicPage_Player/components/IBasePage";

type BaseProps = {
  key: number;
  lessonMetaData: ILessonMetaData;
  setLessonMetaData: (data: ILessonMetaData) => void;
};

export interface IExitPageAsset {
  imagePath: string;
  assetVersionId: number;
  parentVersionId: number;
}

export interface IExitPageLogoAsset {
  imagePath: string;
  assetVersionId: number;
  attributes: ITitlePageAttributes;
}

export interface IExitPageManifest {
  [key: string]: string | IExitPageAsset | IExitPageLogoAsset;
  comment: string;
  AirplaneOverlay: IExitPageAsset;
  Background: IExitPageAsset;
  BrandedLogo: IExitPageLogoAsset;
}

const BaseExitPage: React.FC<BaseProps> = (props: BaseProps) => {
  const lessonPageActionsContext: ILessonPageActionsContext =
    useContext<ILessonPageActionsContext>(LessonPageActionsContext);
  const assetContext: IAssetManagementContext = useContext<IAssetManagementContext>(AssetContext);
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const updatedManifest: any = _.cloneDeep(pageContext.pageManifest);

  const owningLMS: number = useSelector(({ authorizedState }: any) => authorizedState.lmsKey);
  const isCPaTUser: boolean = useSelector(({ authorizedState }: any) => authorizedState.isCpatUser);

  const airplaneoverlay = updatedManifest
    ? blobHelper(updatedManifest.AirplaneOverlay.imagePath)
      ? blobHelper(updatedManifest.AirplaneOverlay.imagePath)
      : blobHelper("cpat/generic/images/cpatbrandedairplane.png")
    : "";
  const bg = blobHelper(updatedManifest.Background.imagePath)
    ? blobHelper(updatedManifest.Background.imagePath)
    : blobHelper("cpat/generic/images/14/14/698239BDC46AEA11817D60F26251769D/air-atmosphere-blue-blue-sky-314726.jpg");
  const logo = updatedManifest
    ? blobHelper(updatedManifest.BrandedLogo.imagePath)
      ? blobHelper(updatedManifest.BrandedLogo.imagePath)
      : blobHelper("cpat/generic/images/cpatlogo.png")
    : "";
  //const holders = props.lessonMetaData.additionalCopyrightHolders;
  useEffect(() => {
    lessonPageActionsContext.setLessonPageActions([]);
  }, []);

  const handleImageClick = (index: number) => {
    assetContext.setAssetIndex(index);
    assetContext.setAssetTypeId(4);
    assetContext.setAssetManagerController({
      isOpen: true,
      mode: "ImagesOnly",
    });
  };

  if (props.lessonMetaData === undefined) {
    return null;
  } else {
    /*if((holders && holders.length > 0 && _.find(holders, holder => {return holder.lmsKey === owningLMS})) || isCPaTUser) {*/
    return (
      <BaseExitEditable
        airplaneoverlay={airplaneoverlay}
        bg={bg}
        isCPaTUser={isCPaTUser}
        lessonMetaData={props.lessonMetaData}
        logo={logo}
        handleImageClick={handleImageClick}
      />
    );
  } /*else {
    return (
      <BaseExitNonEditable 
        airplaneoverlay={airplaneoverlay}
        bg={bg}
        lessonMetaData={props.lessonMetaData}
        logo={logo}
      />
      
    )
  }*/
};

export default BaseExitPage;
