import { BaseModalProps } from "./types";
import { ReactElement } from "react";
export type FooterSlotProps = Pick<BaseModalProps, "slots">;

export const Footer = ({ slots }: FooterSlotProps) => {
  if (slots?.footer) {
    return slots?.footer as ReactElement;
  }

  return (
    <div className="cpat-modal-footer">
      <div className="cpat-modal-actions">{slots?.bottomActions}</div>
    </div>
  );
};
