import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import "./SelectAircraftLiveryModal.css";
import AspectRatioContainer from "../../../services/Utils/AspectRatioContainer";
import * as uploaderFunctions from "../../AssetManager/AssetManagerUtils/UploaderFunctions";
import { IPageContext, PageContext } from "../../../routes/builderContexts";

export interface UploadAircraftLiveryModalProps {
  setShowModals: Dispatch<SetStateAction<{ select: boolean; upload: boolean }>>;
  showModals: { select: boolean; upload: boolean };
  liveryFileToUpload: HTMLInputElement;
  showUploadModal: boolean;
  handleSelectCancel: () => void;
  lessonMetaData: any;
  currentlyDisplayedPageIndex: any;
  pages: any;
  saveLesson: any;
}

const UploadLiveryModal = (props: UploadAircraftLiveryModalProps) => {
  const [description, setDescription] = useState<string>("");
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);

  const HandleCancel = () => {
    props.setShowModals({
      upload: false,
      select: true,
    });
  };

  const HandleConfirm = () => {
    if (props.liveryFileToUpload.files) {
      const lessonData = {
        aircraftPlacementIds: null, //keep null
        ataIds: null, //not needed just keep null
        aircraftId: props.lessonMetaData.aircraftId, //keep null
        manufacturerId: null,
        versionName: "",
        aircraftFamilyId: null,
        tailoredForLmsKey: null,
        description: description ? (description as string) : props.liveryFileToUpload.files[0].name,
        uploadForLmsKey: null,
      };
      uploaderFunctions
        .fileUploadProcess(props.liveryFileToUpload.files[0], lessonData, 12)
        .then(async (uploadResult) => {
          if (uploadResult && uploadResult.data) {
            props.pages[props.currentlyDisplayedPageIndex].pageManifest.modelSet.liveryBlobPath =
              uploadResult.data.blobPath;
            pageContext.updatePageManifest(props.pages[props.currentlyDisplayedPageIndex].pageManifest);

            await ApplySelectedLivery(uploadResult.data.assetId);
          }
        });
      props.setShowModals({
        select: false,
        upload: false,
      });
      props.handleSelectCancel();
    }
  };

  const ApplySelectedLivery = async (assetVersionId: number) => {
    props.lessonMetaData.additionalSettings.clientCustomizations = {
      waLivery: {
        waLiveryAssetVersionId: assetVersionId,
        waModelAssetVersionId: props.pages[props.currentlyDisplayedPageIndex].pageManifest.modelSet.assetVersionId,
      },
    };

    props.saveLesson(props.pages, props.lessonMetaData);
  };

  const LiveryPreview = () => {
    if (props.liveryFileToUpload.files) {
      const filePath = URL.createObjectURL(props.liveryFileToUpload.files[0]);

      return (
        <div className={"livery-preview-image"}>
          <div className={"relative-container"}>
            <div className={"absolute-container"}>
              <img
                id={"selected-livery-preview"}
                src={filePath}
                height={"100%"}
                width={"100%"}
                style={{ position: "absolute" }}
                alt={"Custom Livery Preview"}
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className={"livery-preview-image"}>
        <AspectRatioContainer
          height={100}
          width={100}
          src={""}
          additionalStyle={{ position: "absolute" }}
          onLoadError={() => {}}
        />
      </div>
    );
  };

  function handleTextInputs(event: React.FocusEvent<HTMLInputElement>) {
    setDescription(event.target.value);
  }

  const LiveryInfo = () => {
    if (props.showUploadModal) {
      if (props.liveryFileToUpload.files) {
        const blobPathInfo = props.liveryFileToUpload.files[0].name.split("/");
        const liveryName = blobPathInfo[blobPathInfo.length - 1];
        return (
          <div className={"livery-preview-info"}>
            <div>
              File Name:
              <br />
              <input disabled className="file-card-file-name" defaultValue={liveryName} />
            </div>
            <div>
              Description:
              <br />
              <input
                onBlur={(e) => {
                  handleTextInputs(e);
                }}
                className="file-card-file-name"
                defaultValue={liveryName}
              />
            </div>
          </div>
        );
      }
    }

    return null;
  };

  return (
    <Modal
      show={props.showUploadModal}
      dialogClassName={"livery-preview-modal"}
      backdrop={"static"}
      keyboard={false}
      bsPrefix={"livery-preview"}
      onHide={HandleCancel}
    >
      <Modal.Header closeButton>
        <Modal.Title className={"livery-preview-title"}>
          <p>Aircraft Livery</p>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body bsPrefix={"livery-preview-body"}>
        <LiveryPreview />
        <LiveryInfo />
      </Modal.Body>

      <Modal.Footer bsPrefix={"livery-preview-footer"}>
        <div className="livery-preview-modal-buttons">
          <button onClick={HandleCancel}>Cancel</button>
          <button onClick={HandleConfirm}>Confirm</button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadLiveryModal;
