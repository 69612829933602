import React, { useRef, useMemo, useEffect } from "react";
import * as THREE from "three";

const hsParams = {
  square: {
    lineWidth: 2,
    lineHeight: 2,
    dashSize: 0.02,
    gapSize: 0.01,
  },
  sphere: {
    lineWidth: 100,
    dashSize: 0.005,
    gapSize: 0.005,
  },
};
type VisualHotspotProps = {
  meshRef: React.Ref<React.ReactNode> | undefined;
  isSphere: boolean;
  hovered: boolean;
};
function VisualHotspot(props: VisualHotspotProps) {
  const lineRef = useRef<THREE.Line>();
  const { meshRef, isSphere, hovered } = props;
  const lineParams = isSphere ? hsParams.sphere : hsParams.square;
  const { dashSize, gapSize } = lineParams;
  const cubeGeometry = useMemo(() => new THREE.BoxGeometry(1, 1, 1), []);
  const sphereGeometry = useMemo(() => new THREE.SphereGeometry(1, 32, 32), []);
  const alias = isSphere ? sphereGeometry : cubeGeometry;

  useEffect(() => {
    let isUnMounted = false;
    if (!lineRef.current || isUnMounted || isSphere) return;
    lineRef.current.computeLineDistances(); // this is necessary to get the dashed lines
    return () => {
      isUnMounted = true;
    };
  }, [lineRef.current, isSphere]);

  return (
    <>
      <mesh ref={meshRef} geometry={alias}>
        {!isSphere && (
          <lineSegments ref={lineRef} rotation={[0, 0, 0]} renderOrder={1}>
            <edgesGeometry attach="geometry" args={[cubeGeometry]} />
            <lineDashedMaterial dashSize={dashSize} gapSize={gapSize} attach="material" color={"#90EE90"} />
          </lineSegments>
        )}
        <meshPhysicalMaterial attach="material" color={"#7cb342"} transparent opacity={hovered ? 0.85 : 0.35} />
      </mesh>
    </>
  );
}

export default VisualHotspot;
