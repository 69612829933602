import { MouseEventHandler, PropsWithChildren, ReactNode, useState } from "react";
import classes from "../../../../utils/HTML/classes";

import "./index.css";

export interface PageActionProps {
  title: string;
  icon?: ReactNode;
  isCollapsible?: boolean;
  defaultOpen?: boolean;
  controls?: ReactNode; // To add controls like a toggle/button in the action header
  onClick?: () => void;
}

export const PageAction = ({
  children,
  title,
  icon,
  isCollapsible = false,
  defaultOpen = false,
  onClick,
}: PropsWithChildren<PageActionProps>) => {
  const [open, setOpen] = useState<boolean>(defaultOpen);

  const handlePageActionClick: MouseEventHandler = (event) => {
    if (isCollapsible) {
      event.preventDefault();
      event.stopPropagation();

      setOpen(!open);
    } else {
      onClick?.();
    }
  };

  return (
    <div
      className={classes("page-action--wrapper", {
        "page-action--clickable": !!onClick || isCollapsible,
        "page-action--open": open,
      })}
    >
      <button className="page-action--button" onClick={handlePageActionClick}>
        {icon && <div className="page-action--icon"></div>}
        <h5 className="page-action--title">{title}</h5>
      </button>
      {open && <div className="page-action--collapse">{children}</div>}
    </div>
  );
};
