import "./Symbol.css";
import _ from "lodash";
import React from "react";
import { ISymbolV2 } from "./models/ISymbol";
import { getSymbol } from "./helpers/getSymbol";
import { IKeyPressHandler } from "../ObjectPropertyBox/models/IKeyPressHandler";
import { ElementTypes } from "../../pageTypes/BasicPage_Player/components/IBasePage";

type SymbolType = {
  kp: IKeyPressHandler;
  symbols: ISymbolV2[];
  pageManifest?: any;
  handleKeyPress: (e: React.KeyboardEvent<HTMLDivElement | SVGSVGElement>, kp: IKeyPressHandler) => void;
  markSelected: (target: HTMLDivElement | SVGSVGElement, index: number, type: ElementTypes) => void;
};

const Symbol = (props: SymbolType) => {
  const { kp, symbols, handleKeyPress, markSelected, pageManifest } = props;
  const displaySymbols = () => {
    return symbols.map((pmSymbol: ISymbolV2, index: number) => {
      const objectIsInTimeline = !!props.pageManifest?.timeline?.animatedObjects?.find((ao: any) => {
        return ao.id === pmSymbol?.objectId;
      });
      return getSymbol(index, kp, pmSymbol, handleKeyPress, markSelected, objectIsInTimeline);
    });
  };

  /////////////////////////////////////////////////////////////////////////////
  // Create Symbol Context
  /////////////////////////////////////////////////////////////////////////////

  // const symbolContext = (): ISymbolContext => {
  //   return {
  //     symbolStyle: symbols[editableIndex].style,
  //     index: index,
  //     handleKeyPress: props.handleKeyPress,
  //     markSelected: props.markSelected
  //   }
  // }

  return <React.Fragment>{displaySymbols()}</React.Fragment>;
};

export default Symbol;
