let cachedSourceMap: any;
let basicConsumer: any;
const CHROME_IE_STACK_REGEXP = /^\s*at .*(\S+:\d+|\(native\))/m;
export const logHistory: any[] = [];
declare global {
  interface Window {
    DEBUG?: boolean;
    sourceMap?: {
      SourceMapConsumer: any;
    };
  }
}
export const createLogger = function (
  namespace: string,
  options?: {
    background?: string;
    color?: string;
    silence?: boolean;
    pretty?: boolean;
    captureObjects?: boolean;
  },
) {
  function logWithConsumer(consumer: any, args: any[]) {
    const parsed = parseV8OrIE(new Error())[1];
    const x = consumer.originalPositionFor({
      line: +parsed.lineNumber!,
      column: +parsed.columnNumber!,
    });
    const suffix = ` (${x.source}:${x.line}:${x.column})`;
    if (!options?.silence) {
      console.log(...args, suffix);
    }
  }

  function logWithoutConsumer(args: any[]) {
    if (!options?.silence) {
      const newArgs = [];
      for (let i = 0; i < args.length; i++) {
        const arg = args[i];

        try {
          if (typeof arg === "object") {
            if (options?.captureObjects) {
              newArgs.push(JSON.stringify(arg, null, 2));
              continue;
            }
          }
        } catch (e) {}

        newArgs.push(arg);
      }
      console.log(...newArgs);
    }
  }

  function log(...args: any) {
    const prefix = options?.background || options?.color ? `%c${namespace}\n\n` : `${namespace} --> \n\n`;

    const style =
      options?.background || options?.color
        ? `background: ${options.background ?? "black"}; color: ${options.color ?? "white"};`
        : "";

    const logArgs = [prefix];

    if (style) {
      logArgs.push(style);
    }

    logArgs.push(...args);

    logHistory.push({ namespace, content: args });

    if (logHistory.length > 10) {
      logHistory.shift();
    }

    if (basicConsumer) {
      logWithConsumer(basicConsumer, logArgs);
    } else if (cachedSourceMap && !basicConsumer) {
      //@ts-ignore
      window.sourceMap.SourceMapConsumer.fromSourceMap(cachedSourceMap).then((consumer: any) => {
        basicConsumer = consumer;
        logWithConsumer(basicConsumer, logArgs);
      });
    } else {
      logWithoutConsumer(logArgs);
    }
  }

  if (!cachedSourceMap && !loading) {
    setupSources();
  }

  return log;
};

const sourceMapURL = "https://" + window.location.host + "/static/js/main.chunk.js.map";
let loading = false;

function setupSources() {
  if (!window.sourceMap?.SourceMapConsumer) return;
  if (cachedSourceMap) return;
  if (loading) return;
  loading = true;
  fetch(sourceMapURL)
    .then((res) => res.text())
    .then((text) => {
      console.log("LOADED CACHE SOURCE MAP", sourceMapURL);
      cachedSourceMap = text;
      loading = false;
    })
    .catch((err) => {
      console.error(err);
    });
}

// modified from stacktrace.js/error-stack-parser
function parseV8OrIE(error: Error) {
  const filtered = error.stack!.split("\n").filter(function (line) {
    return !!line.match(CHROME_IE_STACK_REGEXP);
  });

  return filtered.map(function (line) {
    if (line.indexOf("(eval ") > -1) {
      // Throw away eval information until we implement stacktrace.js/stackframe#8
      line = line.replace(/eval code/g, "eval").replace(/(\(eval at [^()]*)|(,.*$)/g, "");
    }
    let sanitizedLine = line
      .replace(/^\s+/, "")
      .replace(/\(eval code/g, "(")
      .replace(/^.*?\s+/, "");

    // capture and preseve the parenthesized location "(/foo/my bar.js:12:87)" in
    // case it has spaces in it, as the string is split on \s+ later on
    const location = sanitizedLine.match(/ (\(.+\)$)/);

    // remove the parenthesized location from the line, if it was matched
    sanitizedLine = location ? sanitizedLine.replace(location[0], "") : sanitizedLine;

    // if a location was matched, pass it to extractLocation() otherwise pass all sanitizedLine
    // because this line doesn't have function name
    const locationParts = extractLocation(location ? location[1] : sanitizedLine);

    return {
      lineNumber: locationParts[1],
      columnNumber: locationParts[2],
    };
  });
}
// from stacktrace.js/error-stack-parser
function extractLocation(urlLike: string) {
  // Fail-fast but return locations like "(native)"
  if (urlLike.indexOf(":") === -1) {
    return [urlLike];
  }

  const regExp = /(.+?)(?::(\d+))?(?::(\d+))?$/;
  const parts = regExp.exec(urlLike.replace(/[()]/g, ""));
  return [parts![1], parts![2] || undefined, parts![3] || undefined];
}

export function prettyLog(obj: any) {
  const keys = Object.keys(obj);
  const color = "color: #fff; background: #000;";
  const color2 = "color: #000; background: #fff;";
}
