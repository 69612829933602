import React from "react";
import { SymbolComponentType } from "../helpers/getSymbol";

const AircraftSymbol1 = (props: SymbolComponentType) => {
  const { index, attributes, transformStr } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18.246406 21.190274"
      key={index}
      id={`symbol-${index}`}
      tabIndex={0}
      className="symbol target"
      name="symbol"
      preserveAspectRatio="xMidYMid meet"
      style={{
        top: `${attributes.top}%`,
        left: `${attributes.left}%`,
        width: `${attributes.width}%`,
        height: `${attributes.height}%`,
        zIndex: attributes.zIndex,
        // transform:`rotate(${attributes?.transform?.rotate}deg) scaleX(${attributes?.transform?.scaleX}) scaleY(${attributes?.transform?.scaleY}) matrix3d(${attributes?.transform?.matrix3d})`
        transform: transformStr,
      }}
    >
      <path
        stroke={`${attributes.borderColor || "white"}`}
        fill={`${attributes?.backgroundColor || "transparent"}`}
        strokeWidth={`${attributes.lineWidth || 0.1}`}
        // className="aircraft_symbol_1"
        d="m 0,0 0.78,3.62 2.68,3.54 -2,10 1.14,1.37 2.85,-6.45 0.88,-0.76 3.72,4.84 v 2 l 1.16,1.69 1.54,-2.91 L 16,16.21 14.7,14.63 12.81,14.11 9.14,9.2 l 1,-0.64 7,-1 -1,-1.47 L 5.93,5.29 3.27,1.75 Z"
      />
    </svg>
  );
};

export default AircraftSymbol1;
