import { PAGE_TYPES } from "../../../components/constants/pageTypes";
import { ITitlePageManifest, TitlePageManifestAssetCollector } from "../../../pageTypes/Title_Player/BaseTitlePage";
import {
  IQuizPageManifest,
  QuizAsset,
  QuizPageManifestAssetCollector,
} from "../../../pageTypes/Quiz_Player/Quiz_Editor";

import { basePageManifestAssetCollector } from "../../../pageTypes/BasicPage_Player/BasePageDesigner";
import { threeDPageManifestAssetCollector } from "../../../pageTypes/CPaT3d_Player/CPaT3d_Player";
import { walkaroundPageManifestAssetCollector } from "../../../pageTypes/WalkAround/WalkAroundPlayer";
import { cabinPageManifestAssetCollector } from "../../../pageTypes/Cabin/CabinPlayer";
import { fmsPageManifestAssetCollector } from "../../../pageTypes/FMS_Player/fmsDesigner";

import ILessonPageDto from "../../../models/ILessonPageDto";
import { IBasePage } from "../../../pageTypes/BasicPage_Player/components/IBasePage";
import { isEqual } from "lodash";
import asset from "../../../components/ImageMetadataEditor/interfaces/Asset";
import { IInteractiveAudio, NarrationAudio, OneShotAudio } from "../../../models/IInteractiveAudio";

type CrawlingFormats = "reconcile";
export class IDandPath {
  assetVersionId: number | string;
  blobPath: string;
  constructor(assetVersionId: number | string, blobPath: string) {
    if (typeof assetVersionId === "number" && !isNaN(assetVersionId)) {
      this.assetVersionId = assetVersionId;
    } else {
      this.assetVersionId = 0;
    }

    this.blobPath = blobPath;
  }
}
export class ReconciledPageAssets {
  pageVersionId: number;
  pageReconcileAssetLinks: IDandPath[] = [];
  constructor(pageVersionId: number) {
    this.pageVersionId = pageVersionId;
  }
  public addAssets(pageReconcileAssetLink: IDandPath[]) {
    this.pageReconcileAssetLinks = [...this.pageReconcileAssetLinks, ...pageReconcileAssetLink];
  }
}

const PageTypeCrawler = {
  // all the assets gathered by this crawler can be overriden in the crawlType
  // function that is down below if they are submitted in a lesson update request;
  // if you are trying to add a crawler for a particular
  // page type, you need to extend the crawlType function with your crawler for assets
  // in this new page type to be correctly associated. Time spent trying to extend this crawler
  // is probably wasted
  getAllAssetIds(pageType: (typeof PAGE_TYPES)[number] | string, pageManifest: any) {
    switch (pageType) {
      case "ThreeDModel":
        return crawlCockpitPageManifest(pageManifest).filter(
          (assetVersionId: number | null | number[] | undefined) => assetVersionId ?? false,
        );
      case "Walkaround":
        return crawlCockpitPageManifest(pageManifest).filter(
          (assetVersionId: number | null | number[] | undefined) => assetVersionId ?? false,
        );
      case "Cabin":
        return crawlCabinPageManifest(pageManifest).filter(
          (assetVersionId: number | null | number[] | undefined) => assetVersionId ?? false,
        );
      default:
        return false;
    }
  },
};

const crawlCabinPageManifest = (pageManifest: any) => {
  let audioIds: number[] = [];
  let imageIds: number[] = [];
  if (pageManifest) {
    if ("Audio" in pageManifest) {
      audioIds = crawlAudioArray(pageManifest.Audio).map((audio) => {
        return audio.assetVersionId;
      });
    } else console.warn("No Audio in Manifest");

    if ("HotSpotList" in pageManifest) {
      pageManifest.HotSpotList.forEach((hotspot: any) => {
        const imgs: number[] = hotspot.IGSObject.map((igsItem: any) => {
          const avids: any[] = [];
          if ("images" in igsItem) {
            avids.push(igsItem.images?.assetVersionId);
          }
          if ("choices" in igsItem) {
            avids.push(
              igsItem.choices.map((choiceItem: any) => {
                const id = choiceItem?.image?.assetVersionId;
                if (id) {
                  return choiceItem?.image?.assetVersionId;
                } else return null;
              }),
            );
          }
          return avids;
        }).flat(2);
        imageIds = [...imageIds, ...imgs];
      });
      imageIds = [...new Set(imageIds)];
    } else if ("StepGraphic" in pageManifest && "choices" in pageManifest) {
      const imgs = pageManifest.StepGraphic.map((stepGraphicItem: any) => {
        return typeof stepGraphicItem.assetVersionId === "string"
          ? (+stepGraphicItem.assetVersionId as number)
          : (stepGraphicItem.assetVersionId as number);
      });
      const imgs2 = pageManifest.choices.map((choicesItem: any) => {
        return typeof choicesItem.assetVersionId === "string"
          ? (+choicesItem.assetVersionId as number)
          : (choicesItem.assetVersionId as number);
      });
      imageIds = [...new Set([...imgs, ...imgs2])];
    }
    const modelSetAVID = +pageManifest?.modelSet?.assetVersionId;
    return [...audioIds, ...imageIds, modelSetAVID];
  } else return [];
};

const crawlCockpitPageManifest = (pageManifest: any) => {
  let audioIds: number[] = [];
  let imageIds: number[] = [];
  if (pageManifest) {
    if ("Audio" in pageManifest) {
      audioIds = crawlAudioArray(pageManifest.Audio).map((audio) => {
        return audio.assetVersionId;
      });
    } else console.warn("No Audio in Manifest");
    if ("IGSObject" in pageManifest) {
      const imgs: number[] = pageManifest.IGSObject.map((igsItem: any) => {
        const avids: any[] = [];
        if ("images" in igsItem) {
          avids.push(igsItem.images?.assetVersionId);
        }
        if ("choices" in igsItem) {
          avids.push(
            igsItem.choices.map((choiceItem: any) => {
              const id = choiceItem?.image?.assetVersionId;
              if (id) {
                return choiceItem?.image?.assetVersionId;
              } else return null;
            }),
          );
        }

        return avids;
      }).flat(2);

      imageIds = [...new Set(imgs)];
    } else if ("StepGraphic" in pageManifest && "choices" in pageManifest) {
      const imgs = pageManifest.StepGraphic.map((stepGraphicItem: any) => {
        return typeof stepGraphicItem.assetVersionId === "string"
          ? (+stepGraphicItem.assetVersionId as number)
          : (stepGraphicItem.assetVersionId as number);
      });
      const imgs2 = pageManifest.choices.map((choicesItem: any) => {
        return typeof choicesItem.assetVersionId === "string"
          ? (+choicesItem.assetVersionId as number)
          : (choicesItem.assetVersionId as number);
      });
      imageIds = [...new Set([...imgs, ...imgs2])];
    }
    const modelSetAVID = +pageManifest?.modelSet?.assetVersionId;
    return [...audioIds, ...imageIds, modelSetAVID];
  } else return [];
};

export const crawlAudioArray = (audioArray: any[]) => {
  //loop and acquire unique assetVersionId's from Audio Array
  const x: any[] = [];
  audioArray.forEach((audioObj, index) => {
    if (audioObj.File) {
      // set the Version to zero instead of skipping if falsy
      const assetPack = new IDandPath(parseInt(audioObj.Version) || 0, audioObj.File);
      x.push(assetPack);
    }
  });

  return x;
};

export const crawlAudios = (audios?: IInteractiveAudio[]) => {
  return (
    audios
      ?.filter((audio: IInteractiveAudio) => !!audio.input)
      ?.map((audio: IInteractiveAudio) => new IDandPath(audio.objectId, audio.input as string)) ?? []
  );
};

const findKey = (value: any, knownKeys: string[]) => {
  const bingo = knownKeys.filter((key) => key in value);

  for (let i = 0; i < bingo.length; i++) {
    if (value[bingo[i]]) {
      return bingo[i];
    }
  }
  return "";
};

/**
 * @description taking any array or object as first argument, it will recursively cycle through the entire data structure 
   targeting a combination of two keys in the object. the keys passed in as string[ ]'s as arguments to define what combinations
   to look for.
 * @param obj any object or array 
 * @param knownBlobPathKeys first set of keys possible
 * @param knownVersionIdKeys second set of keys possible
 * @returns a list of assets in the format [ {assetVersionId: number, blobPath: string}, ... ]
 */
export function recursiveCrawler(obj: any, knownBlobPathKeys: string[], knownVersionIdKeys: string[]) {
  const pageAssets: IDandPath[] = [];
  if (obj && findKey(obj, knownVersionIdKeys) && findKey(obj, knownBlobPathKeys)) {
    pageAssets.push(new IDandPath(+obj[findKey(obj, knownVersionIdKeys)], obj[findKey(obj, knownBlobPathKeys)]));
  }
  const recurse = (obj: any) => {
    if (obj) {
      // if not null
      for (const [key, value] of Object.entries(obj)) {
        // loop through the object (arrays are objects too (; )
        //[[key, value], [key, value]]
        if (typeof value === "string" || typeof value === "boolean" || typeof value === "number") {
          // no op
        } else if (Array.isArray(value)) {
          // value is object array, just re run the recursion since we wont have what we want in an array
          recurse(value);
        } else if (typeof value === "object") {
          // inside an object object we might find what we want.
          const o = value as any;
          if (o && findKey(o, knownVersionIdKeys) && findKey(o, knownBlobPathKeys)) {
            pageAssets.push(new IDandPath(+o[findKey(o, knownVersionIdKeys)], o[findKey(o, knownBlobPathKeys)]));
          }
          recurse(value);
        }
      }
    }
  };
  recurse(obj);
  return pageAssets;
}

function compareIdsAndBlobs(arr1: any[], arr2: any[]) {
  let isAllThere = true;
  const map1 = arr1.map((value: any) => value.blobPath);
  const map2 = arr2.map((value: any) => value.blobPath);
  if (map1.length >= map2.length) {
    for (let i = 0; i < map1.length; i++) {
      if (map1[i]) {
        const temp = map2.includes(map1[i]);
        if (!temp) {
          isAllThere = false;
        }
      }
    }
  } else {
    for (let i = 0; i < map2.length; i++) {
      if (map2[i]) {
        const temp = map1.includes(map2[i]);
        if (!temp) {
          isAllThere = false;
        }
      }
    }
  }
  return isAllThere;
}

export const reconcileFunctionsTargeted = {
  TITLE: TitlePageManifestAssetCollector,
  QUIZ: QuizPageManifestAssetCollector,
  BASE: basePageManifestAssetCollector,
  THREED: threeDPageManifestAssetCollector,
  WALKAROUND: walkaroundPageManifestAssetCollector,
  CABIN: cabinPageManifestAssetCollector,
  FMS: fmsPageManifestAssetCollector,
  DEFAULT: () => [],
};

function crawlType(pagePlayerType: string) {
  switch (pagePlayerType) {
    case "Title":
    case "Exit":
      return [reconcileFunctionsTargeted.TITLE];
    case "Quiz":
      return [reconcileFunctionsTargeted.QUIZ];
    case "Base":
      return [reconcileFunctionsTargeted.BASE];
    case "ThreeDModel":
      return [reconcileFunctionsTargeted.THREED];
    case "Walkaround":
      return [reconcileFunctionsTargeted.WALKAROUND];
    case "Cabin":
      return [reconcileFunctionsTargeted.CABIN];
    case "FMS":
      return [reconcileFunctionsTargeted.FMS];
    default:
      return [reconcileFunctionsTargeted.DEFAULT];
  }
}

function reconciledPageAssetsCleanup(element: ReconciledPageAssets) {
  element.pageReconcileAssetLinks = element.pageReconcileAssetLinks.filter(({ blobPath }) => !!blobPath);
  return element;
}
export function crawl(lp: ILessonPageDto[]) {
  if (lp?.length) {
    const pagesArray: any[] = [];
    for (let i = 0; i < lp.length; i++) {
      if (lp[i]?.pageManifest) {
        const { pageManifest, pagePlayerType, pageVersionId } = lp[i];
        const [crawler] = crawlType(pagePlayerType);
        const reconciledPageAssetsList = new ReconciledPageAssets(pageVersionId);
        const extractedResources = crawler(pageManifest);
        reconciledPageAssetsList.addAssets(extractedResources);
        pagesArray.push(reconciledPageAssetsList);
      }
    }
    return pagesArray.map(reconciledPageAssetsCleanup);
  }
  return [];
}

export default PageTypeCrawler;
