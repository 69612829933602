import React, { useContext } from "react";
import { IForeignObject } from "../Interfaces/ICdu";
import { Interweave } from "interweave";
import { IFmsContext, FmsContext } from "../Interfaces/fmsContext";
import { PageContext, IPageContext } from "../../../routes/builderContexts";

export interface ICduDisplayProps {
  cduPage: Record<string, string>;
  cduLinesInformation: IForeignObject[];
  highlightGroups: string[];
  accessCduLineInformation: (id: string) => void;
  fontSizeBase: string;
  assignFmsKeypad: any;
}
const CduDisplay: React.FC<ICduDisplayProps> = (props: ICduDisplayProps): any => {
  //const [highlightedBorder, setHighlight] = useState(false)

  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);
  const CDU = pageContext.pageManifest.cdu.slice(0, -5);
  const fontSizeBase = props.fontSizeBase;
  const bootPage = pageContext.pageManifest.bootPage;
  const clickHandler = (event: React.MouseEvent, id: string): void => {
    event.preventDefault();
    if (fmsContext.cursorSelected && !fmsContext.showCduModal) {
      fmsContext.findCursorObject(id);
    } else if (fmsContext.showCduModal === false) {
      props.accessCduLineInformation(event.currentTarget.id);
    }
  };
  const isAddToDoTask = (cduLineInformation: any) => {
    // document.getElementById("AddToDoTask") ? // review choose between cduSingleLineEditor and addKeyPress Task
    // () => {props.assignFmsKeypad(cduLineInformation.id)}
    // :
    // (event: React.MouseEvent) => {clickHandler(event, cduLineInformation.cduKeyName)}
  };
  return props.cduLinesInformation.map((cduLineInformation: IForeignObject, key: number) => {
    const these = "acft-num,messages,tmpy,fms-num,mode";

    const objectHasOffset =
      pageContext.pageManifest.pageLayouts[bootPage]?.objectOffsets?.[cduLineInformation.id] !== undefined
        ? true
        : false;
    const objectOffset = objectHasOffset
      ? pageContext.pageManifest.pageLayouts[bootPage]?.objectOffsets?.[cduLineInformation.id].position
      : null;
    const cellHasOutline =
      pageContext.pageManifest.pageLayouts[bootPage]?.cellFormating?.cellOutlines?.[cduLineInformation.id]
        ?.outlineShown !== undefined
        ? pageContext.pageManifest.pageLayouts[bootPage]?.cellFormating?.cellOutlines?.[cduLineInformation.id]
            ?.outlineShown
        : cduLineInformation.outline !== undefined
        ? cduLineInformation.outline
        : false;
    const cellHasBackground =
      pageContext.pageManifest.pageLayouts[bootPage]?.cellFormating?.cellBackgrounds?.[cduLineInformation.id]
        ?.backgroundShown !== undefined
        ? pageContext.pageManifest.pageLayouts[bootPage]?.cellFormating?.cellBackgrounds?.[cduLineInformation.id]
            ?.backgroundShown
        : false;
    const cellBackground =
      pageContext.pageManifest.pageLayouts[bootPage]?.cellFormating?.cellBackgrounds?.[cduLineInformation.id]
        ?.backgroundColor;

    return (
      <foreignObject
        key={key}
        // className={`cell-display ${cduLineInformation.class} ${props.highlightGroups.includes(cduLineInformation.id) ? "highlightedElement" : ""} ${CDU}`}
        className={
          CDU.includes("350")
            ? `cell-display ${cduLineInformation.class} ${CDU}`
            : `cell-display ${cduLineInformation.class} ${
                props.highlightGroups.includes(cduLineInformation.id) ? "highlightedElement" : ""
              } ${CDU}`
        }
        id={cduLineInformation.id}
        x={objectHasOffset ? objectOffset.xCoordinate : cduLineInformation.xCoordinate}
        y={objectHasOffset ? objectOffset.yCoordinate : cduLineInformation.yCoordinate}
        width={objectHasOffset ? objectOffset.width : cduLineInformation.width}
        height={objectHasOffset ? objectOffset.height : cduLineInformation.height}
        stroke={"grey"}
        fill="none"
        pointerEvents={fmsContext.showCduModal ? "none" : "all"}
        dominantBaseline="text-bottom"
        // onClick={() => isAddToDoTask(cduLineInformation)}
        onClick={
          document.getElementById("AddToDoTask")
            ? () => {
                props.assignFmsKeypad(cduLineInformation.id);
              }
            : (event: React.MouseEvent) => {
                clickHandler(event, cduLineInformation.cduKeyName);
              }
        }
        alignmentBaseline="baseline"
      >
        {CDU.includes("350") ? (
          <div
            style={{
              height: objectHasOffset ? objectOffset.height : cduLineInformation.height,
              width: objectHasOffset ? objectOffset.width : cduLineInformation.width,
              color: "#ffffff",
              outline: props.highlightGroups.includes(cduLineInformation.id)
                ? "rgb(255, 255, 0) solid 2px"
                : cellHasOutline
                ? "rgb(90, 90, 90) solid 2px"
                : "",
              // outline: cellHasOutline ? props.highlightGroups.includes(cduLineInformation.id) ? "rgb(255, 255, 0) solid 2px" : "rgb(90, 90, 90) solid 2px" : "",
              whiteSpace: "pre",
              textTransform: "uppercase",
              backgroundColor: cellHasBackground ? cellBackground : "",
              fontSize: fontSizeBase,
              padding: "0 auto",
              zIndex: 10,
              position: "absolute",
            }}
          ></div>
        ) : (
          <></>
        )}

        <div
          className="displayDiv"
          style={{
            height: objectHasOffset ? objectOffset.height : cduLineInformation.height,
            width: objectHasOffset ? objectOffset.width : cduLineInformation.width,
            color: "#ffffff",
            outline: fmsContext.gridBorder,
            whiteSpace: "pre",
            textTransform: "uppercase",
            fontSize: fontSizeBase,
            padding: "0 auto",
            zIndex: 10,
            position: CDU.includes("350") ? "absolute" : "unset",
            // verticalAlign:"bottom"
          }}
        >
          <Interweave content={props.cduPage[cduLineInformation.id]} />
        </div>
      </foreignObject>
    );
  });
};

export default CduDisplay;
