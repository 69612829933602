import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import "../CPaT3d_Player/CPaT3d_Player.css";
import PageScene from "./scene/scene";
import PagePresentation from "./components/hud";
import { PageContext, LessonPageActionsContext } from "../../routes/builderContexts";
import {
  setisIGS,
  setAllStepImages,
  setIGSOs,
  setWAHotSpot,
  resetStepImages,
  setStepImage,
  setStepNumber,
  setTaskDesc,
  setIsTerminal,
  setStepType,
  setQuestion2,
  setChoices,
  setCondition,
  displayStepSystem,
  displayChooseStep,
  displayAddStep,
  displayStepResponsePanel,
  setdisplayStyle,
  setModel,
  setRole,
  setCustom,
  storedCurrentStep,
  setCurtain,
} from "../../store/actions/actions";
import * as THREE from "three";
import HSEditor from "./hotSpotEditor/presentation";
import PageTypeCrawler, { IDandPath, crawlAudioArray } from "../../services/Utils/PageTypeCrawler/PageTypeCrawler";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow-icon.svg";

const WalkAroundPlayer = (props) => {
  THREE.Cache.enabled = true;
  const pageContext = useContext(PageContext);
  const updatedManifest = pageContext.pageManifest;
  const manifestModel = updatedManifest.modelSet.modelSet;
  const role = updatedManifest.role;
  //const modelSetPath = "/api/Asset/" + manifestModel
  //const modelSetPath = "/api/PassThru/cpat/lesson/lessonpreview/7266.preview/" + manifestModel
  const modelSetPath =
    "/api/PassThru/cpat/lesson/lessonpreview/" + props.lessonMetaData.lessonVersionId + ".preview/" + manifestModel;
  const [reloadModel, setReloadModel] = useState(props.isSaved);

  let WAHotSpot = props.currentHotSpot;

  if (updatedManifest.walkAroundHS1) {
    WAHotSpot = updatedManifest.walkAroundHS1;
    if (WAHotSpot.isSphere === undefined || WAHotSpot.isSphere === null) {
      WAHotSpot.isSphere = true;
    }
    if (WAHotSpot.rotation === null || WAHotSpot.rotation === undefined) {
      WAHotSpot.rotation = [0, 0, 0];
    }
    if (WAHotSpot.scale === null || WAHotSpot.scale === undefined) {
      WAHotSpot.scale = [WAHotSpot.geometry[0], WAHotSpot.geometry[0], WAHotSpot.geometry[0]];
    }
  }
  let hsData = WAHotSpot;

  if (updatedManifest.StepGraphic === null || updatedManifest.StepGraphic === undefined) {
    let newStepGraphic = [
      {
        image: "cpat/generic/images/19/19/57E6CC11F46AEA11817D60F26251769D/blank.png",
        assetVersionId: "19",
      },
    ];
    updatedManifest.StepGraphic = newStepGraphic;
    pageContext.updatePageManifest(updatedManifest);
  }

  if (updatedManifest.CustomeHotSpot === null || updatedManifest.CustomeHotSpot === undefined) {
    updatedManifest.CustomeHotSpot = WAHotSpot;
    pageContext.updatePageManifest(updatedManifest);
  }

  useEffect(() => {
    setReloadModel(props.isSaved);
  }, [props.isSaved]);

  const lessonPageActionsContext = useContext(LessonPageActionsContext);

  const [deepEditorMode, setDeepEditorMode] = useState("normal");
  const [buttonText, setButtonText] = useState("Hot Spot Editor");
  const [proximityRange, setProximityRange] = useState(updatedManifest.proximityRange ?? 50);
  const [proximityOffset, setProximityOffset] = useState(updatedManifest.proximityOffset ?? { x: 0, y: 0, z: 0 });
  const [isSphere, setIsSphere] = useState(true);

  useEffect(() => {
    lessonPageActionsContext.setLessonPageActions([]);
  }, []);

  useEffect(() => {
    if (proximityRange === updatedManifest.proximityRange) {
      return;
    } else {
      if (proximityRange) {
        updatedManifest.proximityRange = proximityRange;
        updatedManifest.ActiveHotSpot = props.currentHotSpot.key;
        updatedManifest.CustomeHotSpot = props.currentHotSpot;
        // updatedManifest.walkAroundHS1 = props.currentHotSpot
        pageContext.updatePageManifest(updatedManifest);
      }
    }
  }, [proximityRange, props.currentHotSpot]);

  useEffect(() => {
    if (!proximityOffset) return;
    updatedManifest.proximityOffset = proximityOffset;
    pageContext.updatePageManifest(updatedManifest);
  }, [proximityOffset, props.currentHotSpot]);

  useEffect(() => {
    props.displayStepSystem(false);
    props.displayChooseStep(true);
    props.displayAddStep(false);
    props.displayStepResponsePanel("none");
    props.setdisplayStyle("none");
    props.storedCurrentStep(1);
    props.setModel(modelSetPath);
    props.setRole(role);
    props.setWAHotSpot(hsData);
    props.setCurtain(true);
    setIsSphere(hsData.isSphere ? true : hsData.isSphere);
    if (updatedManifest.condition) {
      let conditionSetting = updatedManifest.condition;
      props.setCondition(conditionSetting);
    } else {
      props.setCondition("AtGate");
    }
    clearSlate();
  }, []);

  useEffect(() => {
    const assetVersionIds = PageTypeCrawler.getAllAssetIds(pageContext.page.pagePlayerType, updatedManifest);
    if (assetVersionIds) {
      pageContext.setPageAssetIds(assetVersionIds);
    }
  }, [pageContext.currentlyDisplayedPageIndex]);

  const clearSlate = () => {
    if (updatedManifest.IGSObject) {
      props.setIGSOs(updatedManifest.IGSObject);
      props.setStepNumber(0);
      props.setAllStepImages(updatedManifest.IGSObject[0].images);
      props.setTaskDesc(updatedManifest.IGSObject[0].taskDescription);
      props.setIsTerminal(false);
      props.setStepType(updatedManifest.IGSObject[0].stepType);
      props.setQuestion2(updatedManifest.IGSObject[0].question);
      props.setChoices(updatedManifest.IGSObject[0].choices);
    } else {
      props.setIGSOs([]);
      props.setCurtain(true);
      props.setStepNumber(0);
      props.resetStepImages();
      props.setTaskDesc("What to do:");
      props.setIsTerminal(false);
      props.setStepType(0);
      props.setQuestion2("What to ask");
      props.setChoices([
        {
          text: "answer",
          isCorrect: false,
        },
      ]);
    }
  };

  const hotSpotEditor = () => {
    if (deepEditorMode === "normal") {
      props.setCurtain(true);
      setDeepEditorMode("hsEditor");
      setButtonText("Save and Exit Hot Spot Editor");
    } else {
      props.setCurtain(true);
      setDeepEditorMode("normal");
      setButtonText("Hot Spot Editor");
    }
  };

  const handleIGASave = (IGAObject) => {
    updatedManifest.IGSObject = _.cloneDeep(IGAObject);
    pageContext.updatePageManifest(updatedManifest);
  };

  const [isVisible, setIsVisible] = useState(true);

  const handleVisible = () => {
    var tempVis = false;
    if (isVisible === true) {
      tempVis = false;
    } else {
      tempVis = true;
    }
    setIsVisible(tempVis);
  };

  const RangeSlider = () => {
    const [slider, setSlider] = useState({
      max: 75,
      min: 5,
      value: proximityRange,
      displayValue: proximityRange,
    });

    return (
      <div className="range-slider-container">
        <label id="range-slider-title">Range Size: {slider.displayValue}</label>
        <input
          type="range"
          className="sliderStyle"
          min={slider.min}
          max={slider.max}
          step={0.5}
          defaultValue={slider.displayValue}
          onChange={(e) => {
            setSlider({
              ...slider,
              displayValue: parseFloat(e.target.value),
            });
          }}
          onMouseUp={(e) => {
            setSlider({ ...slider, value: parseFloat(e.target.value) });
            setProximityRange(parseFloat(e.target.value));
          }}
        ></input>
      </div>
    );
  };

  const ProximityOffsetEditor = (props) => {
    const { proximityOffset, setProximityOffset } = props;
    const handleArrowClick = (coord, direction) => {
      setProximityOffset({
        ...proximityOffset,
        [coord]: proximityOffset[coord] + direction,
      });
    };
    return (
      <div className="proxOffWrap">
        <label id="proxOffWrap-label">Position:</label>
        <div className="proxOffInput">
          <label> Aircraft Left/Right:</label>
          <div onClick={() => handleArrowClick("x", 1)}>
            <ArrowIcon />
          </div>
          <label className="proxOffStyle">{proximityOffset.x}</label>
          <div onClick={() => handleArrowClick("x", -1)}>
            <ArrowIcon style={{ transform: "rotate(-180deg)" }} />
          </div>
        </div>
        <div className="proxOffInput">
          <label>Aft/Forward:</label>
          <div onClick={() => handleArrowClick("z", 1)}>
            <ArrowIcon style={{ transform: "rotate(90deg)" }} />
          </div>
          <label className="proxOffStyle">{proximityOffset.z} </label>
          <div onClick={() => handleArrowClick("z", -1)}>
            <ArrowIcon style={{ transform: "rotate(-90deg)" }} />
          </div>
        </div>
      </div>
    );
  };
  //*/

  const ProxEditor = () => {
    return (
      <div className="proxControl">
        <RangeSlider />
        <ProximityOffsetEditor proximityOffset={proximityOffset} setProximityOffset={setProximityOffset} />
      </div>
    );
  };

  var passedIGSObject = null;

  const setBlankIGA = () => {
    updatedManifest.IGSObject = [
      {
        stepType: 0,
        isTerminal: false,
        taskDescription: "What to do",
        question: "What to ask",
        choices: [
          {
            // text: "answer",
            isCorrect: false,
          },
        ],
        images: {
          // image: "cpat/generic/images/19/19/57E6CC11F46AEA11817D60F26251769D/blank.png",
          // assetVersionId: 19,
          // imageHotSpot: "0,0,0,0"
        },
      },
    ];
    pageContext.updatePageManifest(updatedManifest);
    passedIGSObject = updatedManifest.IGSObject;
    props.setIGSOs(passedIGSObject);
  };

  if (updatedManifest.IGSObject) {
    passedIGSObject = updatedManifest.IGSObject;
    props.setIGSOs(passedIGSObject);
  } else {
    setBlankIGA();
  }

  const HotSpotEditor = () => {
    return (
      <div className="deepEditorSelect" onClick={() => hotSpotEditor()}>
        {" "}
        {buttonText}{" "}
      </div>
    );
  };

  switch (deepEditorMode) {
    case "normal":
      return (
        <React.Fragment>
          <PageScene
            blobPath={updatedManifest.modelSet.liveryBlobPath}
            isSphere={isSphere}
            setIsSphere={setIsSphere}
            currentHotSpot={updatedManifest.walkAroundHS1}
            passedIGSObject={passedIGSObject}
            modelSetPath={modelSetPath}
            currentIGA={updatedManifest.IGSObject}
            assetManagerProps={props.assetManagerProps}
            handleSave={handleIGASave}
            proximityData={{
              proximityRange: updatedManifest.proximityRange,
              proximityOffset: updatedManifest.proximityOffset,
            }}
          />
          <div className="relative-container walkaround-normal-grid">
            {props.displayStyle !== "block" ? (
              <HotSpotEditor mode={setDeepEditorMode} modelSetPath={modelSetPath} />
            ) : (
              <div />
            )}
            <PagePresentation passedIGSObject={passedIGSObject} />
          </div>
        </React.Fragment>
      );
    case "hsEditor":
      return (
        <React.Fragment>
          <HSEditor
            isSphere={isSphere}
            setIsSphere={setIsSphere}
            currentHotSpot={updatedManifest.walkAroundHS1}
            hotSpotEditor={HotSpotEditor}
            proxEditor={ProxEditor}
            proximityRange={proximityRange}
            proximityOffset={proximityOffset}
            isVisible={isVisible}
          />
        </React.Fragment>
      );

    default:
      return (
        <React.Fragment>
          <PageScene passedIGSObject={passedIGSObject} currentHotSpot={updatedManifest.walkAroundHS1} />
          <PagePresentation passedIGSObject={passedIGSObject} />
          <HotSpotEditor />
        </React.Fragment>
      );
  }
};

const mapStateToProps = (state) => {
  return {
    currentHotSpot: state.Current3dPage.walkAroundHS1,
    displayStyle: state.Current3dPage.displayStyle,
  };
};

export default connect(mapStateToProps, {
  setisIGS,
  setAllStepImages,
  setIGSOs,
  setWAHotSpot,
  resetStepImages,
  setStepImage,
  setStepNumber,
  setTaskDesc,
  setIsTerminal,
  setStepType,
  setQuestion2,
  setChoices,
  setCondition,
  displayStepSystem,
  displayChooseStep,
  displayAddStep,
  displayStepResponsePanel,
  setdisplayStyle,
  setModel,
  setRole,
  setCustom,
  storedCurrentStep,
  setCurtain,
})(WalkAroundPlayer);

export function walkaroundPageManifestAssetCollector(pageManifest) {
  const assets = [];
  for (const [key, value] of Object.entries(pageManifest)) {
    if (key === "IGSObject") {
      value.forEach((igs) => {
        if ("choices" in igs) {
          const { choices } = igs;
          choices.forEach((choice) => {
            if ("image" in choice) {
              const { image } = choice;
              if ("assetVersionId" in image && "path" in image) {
                const { assetVersionId, path } = image;
                assets.push(new IDandPath(+assetVersionId, path));
              }
            }
          });
        }
        if ("images" in igs) {
          const { images } = igs;
          if ("assetVersionId" in images && "path" in images) {
            const { assetVersionId, path } = images;
            assets.push(new IDandPath(+assetVersionId, path));
          }
        }
      });
    } else if (key === "StepGraphic") {
      value.forEach((z) => {
        if ("assetVersionId" in z && "image" in z) {
          const { assetVersionId, image } = z;
          assets.push(new IDandPath(+assetVersionId, image));
        }
      });
    } else if (key === "background") {
      if ("assetVersionId" in value && "path" in value) {
        const { assetVersionId, path } = value;
        assets.push(new IDandPath(+assetVersionId, path));
      }
    } else if (key === "choices") {
      value.forEach((o) => {
        if ("assetVersionId" in o && "image" in o) {
          const { assetVersionId, image } = o;
          assets.push(new IDandPath(+assetVersionId, image));
        }
      });
    } else if (key === "modelSet") {
      if ("assetVersionId" in value && "modelSet" in value) {
        const { assetVersionId, modelSet } = value;
        assets.push(new IDandPath(+assetVersionId, modelSet));
      }
    } else if (key === "Audio") {
      assets.push(...crawlAudioArray(value));
    }
  }
  return assets;
}
