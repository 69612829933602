import React, { useEffect, useRef } from "react";
import { useTablesDataDispatch, useTablesDataState } from "./TablesDataProvider";
import TablesRTE from "./TablesRTE";
import { useObjectsState } from "../../contexts/ObjectsProvider";

interface TableControlProps {}
const ALPHABET_ARRAY = Array.from("abcdefghijklmnopqrstuvwxyz".toUpperCase());

function TableControls(props: any) {
  const tablesState = useTablesDataState();
  const objectsState = useObjectsState();

  function ControlTabs() {
    const controls = ["top", "bottom", "left", "right"];
    const selectedObject = objectsState.selectedObjects[0];
    return (
      <>
        {controls.map((side, index) => {
          const controlSide = "table-controls-" + side;
          // let widthTabs = new Array (TablesState.selectedTableData?.columns.length-1).fill(1)
          // let columns = `${tablesState.selectedTableData?.columns}`;
          let columns = `${selectedObject?.columns}`;
          // let rows = `${tablesState.selectedTableData?.rows}`;
          let rows = `${selectedObject?.rows}`;
          columns = columns.replaceAll(",", "");
          rows = rows.replaceAll(",", "");

          if (side === "top" || side === "bottom") {
            return (
              <div key={index} className={controlSide + " " + side} style={{ gridTemplateColumns: columns }}>
                {objectsState.selectedObjects[0]?.columns?.map((value: any, index: number) => (
                  <ColumnSelectTabs index={index} />
                ))}
              </div>
            );
          } else {
            return (
              <div key={index} className={controlSide + " " + side} style={{ gridTemplateRows: rows }}>
                {objectsState.selectedObjects[0]?.rows?.map((value: any, index: number) => (
                  <RowSelectTab index={index} />
                ))}
              </div>
            );
          }
        })}
      </>
    );
  }
  const tableControlShow = objectsState.selectedObjectId[0] === props.id;
  if (tableControlShow) {
    return (
      <>
        <div className={"table-controls"}>
          <ControlTabs />
          <CellSelection quillRef={props.quillRef} editTableCellText={props.editTableCellText} objectId={props.id} />
        </div>
      </>
    );
  } else {
    return null;
  }
}

export default TableControls;

function ColumnSelectTabs({ index }: any) {
  const tablesDispatch = useTablesDataDispatch();
  const objectsState = useObjectsState();
  const width = objectsState.selectedObjects[0]?.columns[index];
  return (
    <div
      className="table-col-select-tab"
      key={index}
      style={{ width: width }}
      onClick={(e) => {
        tablesDispatch({
          action: "setSelectedColumn",
          payload: [index],
        });
        e.stopPropagation();
      }}
    >
      {ALPHABET_ARRAY[index]}
    </div>
  );
}

function RowSelectTab({ index }: any) {
  const tablesDispatch = useTablesDataDispatch();
  const objectsState = useObjectsState();
  const height = objectsState.selectedObjects[0]?.rows[index];
  return (
    <div
      className="table-row-select-tab"
      style={{ height: height }}
      onClick={(e) => {
        tablesDispatch({
          action: "setSelectedRow",
          payload: [index],
        });
        e.stopPropagation();
      }}
    >
      {index}
    </div>
  );
}

function CellSelection(props: any) {
  const tablesState = useTablesDataState();
  const objectsState = useObjectsState();
  const quillRef: React.RefObject<any> = useRef<any>(null);

  let columns = objectsState.selectedObjects[0]?.columns?.toString();
  let rows = objectsState.selectedObjects[0]?.rows?.toString();
  columns = columns ? columns.replaceAll(",", "") : undefined;
  rows = rows ? rows.replaceAll(",", "") : undefined;

  let gridColumn = undefined;
  let gridRow = undefined;
  let gridColumnEnd = undefined;
  let gridRowEnd = undefined;
  const display =
    tablesState.selectedCell === undefined &&
    tablesState.selectedColumn === undefined &&
    tablesState.selectedRow === undefined
      ? "none"
      : "unset";
  const position = "relative";

  if (tablesState.selectedCell !== undefined) {
    gridColumn = tablesState.selectedCell.column;
    gridRow = tablesState.selectedCell.row;
    gridColumnEnd = tablesState.selectedCell.column;
    gridRowEnd = tablesState.selectedCell.row;
  } else {
    gridColumn = tablesState.selectedColumn !== undefined ? tablesState.selectedColumn + 1 : 1;
    gridRow = tablesState.selectedRow !== undefined ? tablesState.selectedRow + 1 : 1;
    gridRowEnd =
      tablesState.selectedColumn !== undefined
        ? objectsState.selectedObjects[0]?.rows?.length
          ? objectsState.selectedObjects[0]?.rows?.length + 1
          : undefined
        : undefined;
    gridColumnEnd =
      tablesState.selectedRow !== undefined
        ? objectsState.selectedObjects[0]?.columns?.length
          ? objectsState.selectedObjects[0]?.columns?.length + 1
          : undefined
        : undefined;
  }

  const selectedCellsStyle = {
    display: display,
    gridColumn: gridColumn,
    gridRow: gridRow,
    gridColumnEnd: gridColumnEnd,
    gridRowEnd: gridRowEnd,
    // boxSizing: border-Box,
    border: "2px solid #66db18",
  };

  //
  const text = objectsState.selectedObjects?.[0]?.cells?.[tablesState?.selectedCell?.index]?.content ?? "no text";
  return (
    <div
      className="selected-cells-grid"
      style={{
        display: "grid",
        gridTemplateColumns: columns,
        gridTemplateRows: rows,
      }}
    >
      <div
        className="selected-cells"
        id="dragBox"
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
        style={{
          ...selectedCellsStyle,
          position: "relative",
          // pointerEvents: 'none',
        }}
      >
        {typeof tablesState.selectedCell?.index === "number" && (
          <TablesRTE
            key={tablesState.selectedCell?.index}
            quillRef={quillRef}
            version={"1.2.0"}
            displayText={text}
            objectId={props.objectId}
            theme="snow"
            cellIndex={tablesState.selectedCell?.index}
            // onFocus={() => {
            //   quillRef.current?.focus();
            // }}
          />
        )}
      </div>
    </div>
  );
}
