import React from "react";

interface DescriptionFieldProps {
  description: string;
  setDescription: (description: string) => void;
  readonly: boolean;
}

export const DescriptionField = ({ description, setDescription, readonly }: DescriptionFieldProps) => {
  return (
    <div className={`assetLibrary-metadata-editor-description ${readonly ? "disabled" : "enabled"}`}>
      <p> File Description</p>
      <textarea
        disabled={readonly}
        value={description}
        maxLength={300}
        onChange={(e) => {
          setDescription(e.target.value);
        }}
      ></textarea>
    </div>
  );
};

export default DescriptionField;
