/* eslint-disable @typescript-eslint/no-unused-vars */
import { SVGProps, useEffect } from "react";
import {
  SmartObjectCircle,
  SmartObjectComponent,
  SmartObjectG,
  SmartObjectLine,
  SmartObjectPolygon,
  SmartObjectSvgRendererProps,
} from "../";
import { useMetaVariableStore } from "../../../store";
import { getSmartObjectMetaVariableKey } from "../../../utils";
import { useShouldRender } from "../useShouldRender";
import { SVG_SMART_OBJECT_TYPES } from "../../..";
import { useSmartObjectStyles } from "../useSmartObjectStyles";
import styles from "../svg.module.css";
import { useLessonPagesState } from "../../../../../contexts/LessonPagesProvider/LessonPagesProvider";
import { useObjectsState } from "../../../../../contexts/ObjectsProvider";
import { useAdvancedPanelTimeline } from "../AdvancedPanel/useAdvancedPanelTimeline";
import { useTimeline } from "../../../../../contexts/TimelineProvider/TimelineProvider";

/**
 *
 * This renderer should only handle two SVG_SMART_OBJECT_TYPES
 * IMAGE_SWAP which should be the first element on the tree that its sent to this component
 * SWAP_VALUE all the possible values of the image swap
 * the rest of the element should be "normal" svg tags
 */
export function SmartObjectSvgImageSwapRenderer({
  element,
  objectId,
  parentId,
  className,
  onClick,
}: SmartObjectSvgRendererProps) {
  const { children, TYPE, GROUP_ID, VALUE, HOTSPOT_ID, RATE, type, ...rest } = element;

  if (TYPE === SVG_SMART_OBJECT_TYPES.IMAGE_SWAP) {
    return <SmartObjectImageSwap element={element} objectId={objectId} />;
  }

  if (TYPE === SVG_SMART_OBJECT_TYPES.SWAP_VALUE) {
    return <SmartObjectSwapValue element={element} objectId={objectId} className={className} />;
  }

  switch (type) {
    case "g": {
      return <SmartObjectG className={className} element={element} objectId={objectId} parentId={parentId} />;
    }
    case "image": {
      return (
        <SmartObjectImage
          className={className}
          element={element}
          objectId={objectId}
          parentId={parentId}
          onClick={onClick}
        />
      );
    }
    case "circle": {
      return <SmartObjectCircle className={className} element={element} objectId={objectId} />;
    }
    case "line": {
      return <SmartObjectLine className={className} element={element} objectId={objectId} />;
    }
    case "polygon": {
      return <SmartObjectPolygon className={className} element={element} objectId={objectId} />;
    }
    default: {
      console.warn("SmartObjectSvgImageSwapRenderer: type not handled", type);
      return null;
    }
  }
}

export function SmartObjectImageSwap({ element, objectId }: SmartObjectComponent) {
  const { selectedObjects } = useObjectsState();
  const { selectedPanel } = useLessonPagesState();
  const { children, TYPE, GROUP_ID, VALUE, HOTSPOT_ID, RATE, type, ...rest } = element;
  const key = getSmartObjectMetaVariableKey(objectId, GROUP_ID);
  const value = useMetaVariableStore((s) => s.metaVariables[key]);
  const data = useMetaVariableStore((s) => s.metaVariablesData[key]);
  const toggleNext = useMetaVariableStore((s) => s.toggleNext);
  const isSelectedObject = selectedObjects[0]?.objectId === objectId;
  const isAnimating = data?.isAnimating !== undefined && data?.isAnimating !== false;

  useEffect(() => {
    if (key && data?.isAnimating) {
      const rate = data.rate || 500;
      const interval = setInterval(() => {
        toggleNext(key, true);
      }, rate);

      return () => {
        clearInterval(interval);
      };
    }
  }, [data?.isAnimating, data?.rate]);

  if (value === "hide") return null;

  const props = rest as SVGProps<SVGGElement>;
  /**
   * We only want to show the highlight/glow
   * When the image swap belongs to the selected object
   * and is not animating
   */
  const className = selectedPanel === "advanced" && isSelectedObject && !isAnimating ? styles.shadow : "";

  return (
    <g {...props} className={className}>
      {children?.map((item, idx) => (
        <SmartObjectSvgImageSwapRenderer className={className} key={idx} element={item} objectId={objectId} />
      ))}
    </g>
  );
}

export function SmartObjectSwapValue({ element, objectId, className }: SmartObjectComponent) {
  const { children, TYPE, GROUP_ID, VALUE, HOTSPOT_ID, RATE, type, ...rest } = element;
  const shouldRender = useShouldRender(objectId, GROUP_ID, TYPE as SVG_SMART_OBJECT_TYPES, VALUE, HOTSPOT_ID);
  const key = getSmartObjectMetaVariableKey(objectId, GROUP_ID);
  const toggleNext = useMetaVariableStore((s) => s.toggleNext);
  const { selectedPanel } = useLessonPagesState();
  const [tl] = useTimeline();
  const currentTime = tl?.scrubbingCurrentTime;
  const upsertAnimatedMetaVariable = useMetaVariableStore((s) => s.upsertAnimatedMetaVariable);
  const { isObjectInTimeline } = useAdvancedPanelTimeline(key);

  if (!shouldRender) return null;

  const onClick = () => {
    if (selectedPanel !== "advanced") return;
    const newValue = toggleNext(key, true);
    if (isObjectInTimeline && objectId) {
      upsertAnimatedMetaVariable(key, currentTime, newValue as string, objectId);
    }
  };

  if (type === "g") {
    const props = rest as SVGProps<SVGGElement>;

    return (
      <g {...props} className={className}>
        {children?.map((item, idx) => (
          <SmartObjectSvgImageSwapRenderer
            className={className}
            key={idx}
            element={item}
            objectId={objectId}
            onClick={onClick}
          />
        ))}
      </g>
    );
  }

  if (type === "image") {
    return <SmartObjectImage className={className} element={element} objectId={objectId} onClick={onClick} />;
  }

  if (type === "use") {
    return <SmartObjectUse className={className} element={element} objectId={objectId} onClick={onClick} />;
  }

  console.warn("SmartObjectSwapValue: type not handled", type);

  return null;
}

export function SmartObjectImage({ element, objectId, className, onClick }: SmartObjectComponent) {
  const { children, TYPE, GROUP_ID, VALUE, ...rest } = element;
  const { selectedPanel } = useLessonPagesState();
  const shouldRender = useShouldRender(objectId, GROUP_ID, TYPE as SVG_SMART_OBJECT_TYPES, VALUE);
  const extraStyles = onClick && selectedPanel === "advanced" ? { cursor: "pointer" } : {};
  const style = useSmartObjectStyles(element.style, extraStyles);

  if (!shouldRender) return null;

  const imageProps = rest as SVGProps<SVGImageElement> & { "xlink:href": string };

  return (
    <image
      className={className}
      {...imageProps}
      xlinkHref={imageProps["xlink:href"]}
      style={style}
      onClick={onClick}
    ></image>
  );
}

export function SmartObjectUse({ element, objectId, className, onClick }: SmartObjectComponent) {
  const { children, TYPE, GROUP_ID, VALUE, ...rest } = element;
  const { selectedPanel } = useLessonPagesState();
  const shouldRender = useShouldRender(objectId, GROUP_ID, TYPE as SVG_SMART_OBJECT_TYPES, VALUE);
  const extraStyles = onClick && selectedPanel === "advanced" ? { cursor: "pointer" } : {};
  const style = useSmartObjectStyles(element.style, extraStyles);

  if (!shouldRender) return null;

  const useProps = rest as SVGProps<SVGUseElement> & { "xlink:href": string };

  return (
    <use className={className} {...useProps} xlinkHref={useProps["xlink:href"]} style={style} onClick={onClick}></use>
  );
}
