import React from "react";
import { ElementTypes } from "../../../pageTypes/BasicPage_Player/components/IBasePage";
import HighlightAnnotation from "../../Annotation/hooks/HighlightAnnotation";
import { handleKeyUp } from "../../ObjectPropertyBox/functions/PropertyBoxFunctions";
import { SymbolComponentType } from "../helpers/getSymbol";

const VORTAC = (props: SymbolComponentType) => {
  const { index, attributes, kp, transformStr, handleKeyPress, markSelected, objectIsInTimeline } = props;
  const symbols: ElementTypes = "symbols";
  const isDisplayed = typeof attributes.isDisplayed === "undefined" || attributes.isDisplayed;
  const opacity = objectIsInTimeline ? 1 : isDisplayed ? 1 : 0.5;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10.17 9.16"
      key={index}
      id={`symbol-${index}`}
      className="symbol target"
      name="symbol"
      tabIndex={0}
      onMouseDown={(e: React.MouseEvent<SVGSVGElement>) => markSelected(e.currentTarget, index, symbols)}
      onKeyDown={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyPress(e, kp)}
      onKeyUp={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyUp(e, kp)}
      preserveAspectRatio="xMidYMid meet"
      style={{
        top: `${attributes.top}%`,
        left: `${attributes.left}%`,
        width: `${attributes.width}%`,
        height: `${attributes.height}%`,
        zIndex: attributes.zIndex,
        transform: transformStr,
        // `rotate(${attributes?.transform?.rotate}deg) scaleX(${attributes?.transform?.scaleX}) scaleY(${attributes?.transform?.scaleY}) matrix3d(${attributes?.transform?.matrix3d})`
        opacity,
      }}
    >
      <path
        // className="vortac"
        stroke={`${attributes.borderColor || "white"}`}
        fill={`${attributes?.backgroundColor || "transparent"}`}
        strokeWidth={`${attributes.lineWidth || 0.1}`}
        d="M 3.63,8.91 V 6.31 L 2.22,3.99 0.33,2.76 1.64,0.35 3.68,1.57 H 6.49 L 8.53,0.35 9.84,2.76 7.95,3.99 6.54,6.31 v 2.6 z"
      />
      <path
        // className="vortac"
        stroke={`${attributes.borderColor || "white"}`}
        fill={`${attributes?.backgroundColor || "transparent"}`}
        strokeWidth={`${attributes.lineWidth || 0.1}`}
        d="m 6.4899998,1.58 1.46,2.41"
      />
      <path
        // className="vortac"
        stroke={`${attributes.borderColor || "white"}`}
        fill={`${attributes?.backgroundColor || "transparent"}`}
        strokeWidth={`${attributes.lineWidth || 0.1}`}
        d="M 2.22,3.99 3.6800001,1.58"
      />
      <path
        // className="vortac"
        stroke={`${attributes.borderColor || "white"}`}
        fill={`${attributes?.backgroundColor || "transparent"}`}
        strokeWidth={`${attributes.lineWidth || 0.1}`}
        d="M 3.6300001,6.3099999 H 6.54"
      />
      <HighlightAnnotation annotation={attributes as any} id={"glow-rect-for-symbols"} />
    </svg>
  );
};

export default VORTAC;
