import React, { useContext, useEffect, useState } from "react";
import { animated, useSpring, config } from "react-spring";
import {
  PagesManagementContext,
  IPagesManagementContext,
  LessonPageActionsContext,
} from "../../routes/builderContexts";
import { ActiveIcon, NotActiveIcon, unavailableIcon } from "./DrawerSVGs";

interface IStandardlisterProps {
  pagePullout: boolean;
  switchPage: (i: any, flag: any) => void;
  flagDirection: string;
  index: number;
  name: string;
  modes: number[];
}
interface IStandardPanelProps {
  index: number;
  modes: number[];
  name: string;
  flagDirection: string;
}

const StandardPageListerPanel = (props: IStandardPanelProps) => {
  const pagesManagementContext: IPagesManagementContext = useContext(PagesManagementContext);
  const { lessonPages, lessonModes } = pagesManagementContext;

  const lessonModeNames = ["Demo", "Practice", "Perform"];
  const classOptions = ["I", "P", "T"];
  function selectVOrX(id: number) {
    let index = props.index;
    if (props.flagDirection === "end") {
      index = pagesManagementContext.lessonPages.length - 3 + props.index;
    }
    const lessonModeIds = lessonPages[index].lessonModeIds;
    return lessonModeIds.includes(id);
  }

  function isWarningOrScorePage(name: string) {
    switch (name) {
      case "Warning Page":
        return true;
      case "Score Page":
        return true;
      default:
        return false;
    }
  }

  return (
    <div className="lessonModes">
      <React.Fragment>
        {lessonModes.map((el, i) => {
          return (
            <div key={i} className={`option-${classOptions[i]}`}>
              <div>
                {selectVOrX(el.lessonModeId) ? (
                  <div>
                    <ActiveIcon />
                  </div>
                ) : !isWarningOrScorePage(props.name) ? (
                  <div>{NotActiveIcon}</div>
                ) : (
                  <div>{unavailableIcon("#ABB2BA")}</div>
                )}
              </div>
            </div>
          );
        })}
      </React.Fragment>
    </div>
  );
};

const StandardPageLister = (props: IStandardlisterProps) => {
  const pagesManagementContext = useContext(PagesManagementContext);
  const numPages = pagesManagementContext.lessonPages.length;
  const lessonPages = pagesManagementContext.lessonPages;
  const lessonPageActions = useContext(LessonPageActionsContext);
  let selectedPreviewMode = 0;
  const [pageNumbering, setPageNumbering]: any = useState({});
  const pageOrderPageNumber: any = {};

  if (lessonPageActions.previewMode === "Perform") {
    selectedPreviewMode = 3;
  } else if (lessonPageActions.previewMode === "Demo") {
    selectedPreviewMode = 1;
  } else if (lessonPageActions.previewMode === "Practice") {
    selectedPreviewMode = 2;
  }

  const sliderSpring = useSpring({
    config: config.default,
    width: props.pagePullout ? 450 : 200,
    opacity: props.pagePullout ? 1 : 0,
    x: props.pagePullout ? 262 : 0,
  });

  useEffect(() => {
    let pageNumber = 1;
    for (let i = 0; i < lessonPages.length; i++) {
      if (lessonPages[i].lessonModeIds.includes(selectedPreviewMode)) {
        pageOrderPageNumber[i] = pageNumber;
        pageNumber++;
      } else {
        pageOrderPageNumber[i] = 0;
      }
    }
    setPageNumbering(pageOrderPageNumber);
  }, [lessonPages.map((page) => page.lessonModeIds.join("")).join(""), selectedPreviewMode]);

  function pageNumbers(index: number, flagDirection: string) {
    let pageNumber = 0;
    if (flagDirection === "start") {
      pageNumber = pageNumbering[index];
    } else {
      pageNumber = pageNumbering[lessonPages.length - 3 + index];
    }
    const output =
      pageNumber !== 0 ? (
        <div className="mode-page-numbers-col"> {pageNumber} </div>
      ) : (
        <div className="mode-page-numbers-col"></div>
      );
    return output;
  }

  if (props.flagDirection === "title") {
    return (
      <React.Fragment>
        <div
          style={{
            height: "50px",
          }}
          className="pages-icons-container lesson-box"
        >
          <div className="lessonPages-TitleTab">
            <p>Pages</p>
            <p>{numPages}</p>
          </div>

          {props.pagePullout && (
            <animated.div
              style={{
                transform: sliderSpring.x.interpolate((x) => `translate3d(${x}px,-100%, -1px)`),
                opacity: sliderSpring.opacity.interpolate((opacity) => opacity),
              }}
              className="title-pop-out"
            >
              <div>
                <div style={{ fontSize: "0.85rem" }}>Pg No.</div>
                <p>Demo</p>
                <p>Practice</p>
                <p>Perform</p>
              </div>
            </animated.div>
          )}
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div
          style={{
            height: "50px",
          }}
          className="pages-icons-container lesson-box"
        >
          <div className={`pageBox-container`}>
            <div
              key={props.index}
              //replace here
              onPointerUpCapture={() => props.switchPage(props.index, props.flagDirection)}
              className={
                props.flagDirection === "start"
                  ? `standard-pages${
                      pagesManagementContext.currentlyDisplayedPageIndex === props.index ? " selected" : ""
                    }`
                  : `standard-pages${
                      pagesManagementContext.currentlyDisplayedPageIndex ===
                      pagesManagementContext.lessonPages.length - 3 + props.index
                        ? " selected"
                        : ""
                    }`
              }
              style={{
                borderRight: !props.pagePullout
                  ? props.flagDirection === "start"
                    ? pagesManagementContext.currentlyDisplayedPageIndex === props.index
                      ? "var(--highlighted-color) 3px solid"
                      : "none"
                    : pagesManagementContext.currentlyDisplayedPageIndex ===
                      pagesManagementContext.lessonPages.length - 3 + props.index
                    ? "var(--highlighted-color) 3px solid"
                    : "none"
                  : "none",
              }}
            >
              {props.name}
            </div>
          </div>
          {props.pagePullout && (
            <animated.div
              style={{
                transform: sliderSpring.x.interpolate((x) => `translate3d(${x}px,-100%, -1px)`),
                opacity: sliderSpring.opacity.interpolate((opacity) => opacity),
              }}
              //replace here
              onPointerUpCapture={() => props.switchPage(props.index, props.flagDirection)}
              className={
                props.flagDirection === "start"
                  ? `info-panel${
                      pagesManagementContext.currentlyDisplayedPageIndex === props.index ? " standard-selected" : ""
                    }`
                  : `info-panel${
                      pagesManagementContext.currentlyDisplayedPageIndex ===
                      pagesManagementContext.lessonPages.length - 3 + props.index
                        ? " standard-selected"
                        : ""
                    }`
              }
            >
              <div className="page-row">
                {pageNumbers(props.index, props.flagDirection)}
                <StandardPageListerPanel
                  index={props.index}
                  modes={props.modes}
                  name={props.name}
                  flagDirection={props.flagDirection}
                />
              </div>
            </animated.div>
          )}
        </div>
      </React.Fragment>
    );
  }
};

export default StandardPageLister;
