import React, { useState, useEffect, useContext } from "react";
import { IFmsContext, FmsContext } from "../../Interfaces/fmsContext";
import { IPageContext, PageContext } from "../../../../routes/builderContexts";
import { IFmsPageManifest } from "../../Interfaces/IFmsPageManifest";
interface cduLightEditorProps {
  lightName?: string;
  cduKeyName: string;
  fromTask: boolean;
}

const CduLightEditor = (props: cduLightEditorProps) => {
  //needs to enable based on success criteria
  const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);
  const pagesContext: IPageContext = useContext<IPageContext>(PageContext); //for reading success criteria
  const pageManifest: IFmsPageManifest = pagesContext.pageManifest as IFmsPageManifest;
  //const [lightCriteria, setLightCriteria] = useState<number | null>(null);
  const [lightList, setLightList] = useState<string[]>([]);
  useEffect(() => {
    const populateCduLights = () => {
      const lightNameArray: string[] = [];
      fmsContext.currentCDU.lightObjects.map((light: any) => {
        lightNameArray.push(light.id);
      });
      setLightList(lightNameArray);
    };
    populateCduLights();
  }, []);

  //need to be able to tell if the light is already selected
  const findFirstCriteria = (lightName: string) => {
    if (pageManifest.lights[lightName].criteriaIndex === null) {
      pageManifest.successCriteria.map((criteria, index) => {
        let iterationCounter = 0;
        if (criteria.type === "sequential") {
          if (criteria.successMatch.indexOf(props.cduKeyName) !== -1 && iterationCounter === 0) {
            pageManifest.lights[lightName].criteriaIndex = index;
            iterationCounter++;
          }
        }
      });
    }
  };
  const setCduKey = (lightName: string, cduKeyName: string) => {
    if (!pageManifest.lights[lightName]["cduKey"].includes(cduKeyName)) {
      pageManifest.lights[lightName]["cduKey"].push(cduKeyName);
      findFirstCriteria(lightName);
    } else {
      const keyNames = pageManifest.lights[lightName]["cduKey"].filter((key: string) => {
        return key !== cduKeyName;
      });
      if (keyNames.length === 0) {
        pageManifest.lights[lightName].criteriaIndex = null;
      }
      pageManifest.lights[lightName]["cduKey"] = keyNames;
    }

    pagesContext.updatePageManifest(pageManifest);
  };

  const processLightData = (lightName: string) => {
    if (pageManifest.lights[lightName].cduKey !== props.cduKeyName) {
      findFirstCriteria(lightName);
      setCduKey(lightName, props.cduKeyName);
    } else {
      pageManifest.lights[lightName]["criteriaIndex"] = null;
      setCduKey(lightName, "");
    }
  };
  const TaskToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      pushTask();
    } else {
      filterTask();
    }
  };
  const pushTask = () => {
    if (pageManifest.lights) {
      lightList.map((light, index) => {
        if (pageManifest.lights[light].cduKey.includes(props.cduKeyName)) {
          if (pageManifest.lights[light].toggleIndex) {
            pageManifest.lights[light].toggleIndex.push(fmsContext.criteriaIndex);
          } else {
            pageManifest.lights[light]["toggleIndex"] = [fmsContext.criteriaIndex];
          }
        }
      });
    }
    pagesContext.updatePageManifest(pageManifest);
  };
  const filterTask = () => {
    lightList.map((light, index) => {
      if (
        pageManifest.lights[light] &&
        pageManifest.lights[light].toggleIndex &&
        pageManifest.lights[light].cduKey.includes(props.cduKeyName)
      ) {
        pageManifest.lights[light].toggleIndex = pageManifest.lights[light].toggleIndex.filter((task: number) => {
          return task !== fmsContext.criteriaIndex;
        });
      }
    });

    pagesContext.updatePageManifest(pageManifest);
  };
  const isTaskToggleChecked = (): boolean => {
    let checked = false;
    lightList.map((light, index) => {
      if (
        pageManifest?.lights[light]?.toggleIndex &&
        pageManifest?.lights[light]?.toggleIndex.includes(fmsContext.criteriaIndex)
      ) {
        checked = true;
      }
    });
    return checked;
  };

  // if(lightList.length > 0){
  return fmsContext.currentCDU.lightObjects.length > 0 ? (
    <div className="padding-all-sides-10" style={{ color: "#ffffff", marginTop: "5%", width: "100%" }}>
      <p>
        <strong>Toggle CDU Lights</strong>
      </p>
      <div className="light-container">
        {lightList.map((light, index) => {
          const styleMenuButton = {
            border: pageManifest?.lights[light]?.cduKey.includes(props.cduKeyName)
              ? "1px solid #ffffff"
              : "1px solid #ffffff",
            borderRadius: "5px",

            margin: "1%",
          };
          return (
            <button
              className={
                pageManifest?.lights[light]?.cduKey.includes(props.cduKeyName)
                  ? "selected-lightButton shaded"
                  : "lightButton shaded"
              }
              key={index}
              onClick={() => {
                processLightData(light);
              }}
              style={styleMenuButton}
            >
              {light}
            </button>
          );
        })}

        {props.fromTask ? (
          // && !pageManifest.cdu.includes("72-600") ?
          <label>
            Toggle only on selected Task's
            <input
              style={{ marginLeft: "0.2%" }}
              type="checkbox"
              onChange={TaskToggle}
              checked={isTaskToggleChecked()}
            ></input>
          </label>
        ) : (
          <></>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};
export default CduLightEditor;
