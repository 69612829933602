import EventEmitter from "eventemitter3";

export type WaveSurferOptions = {
  /** Required: an HTML element or selector where the waveform will be rendered */
  container: HTMLElement | string;
  /** The height of the waveform in pixels, or "auto" to fill the container height */
  height?: number | "auto";
  /** The width of the waveform in pixels or any CSS value; defaults to 100% */
  width?: number | string;
  /** The color of the waveform */
  waveColor?: string | string[] | CanvasGradient;
  /** If set, the waveform will be rendered with bars like this: ▁ ▂ ▇ ▃ ▅ ▂ */
  barWidth?: number;
  /** Spacing between bars in pixels */
  barGap?: number;
  /** Rounded borders for bars */
  barRadius?: number;
  /** A vertical scaling factor for the waveform */
  barHeight?: number;
  /** Vertical bar alignment */
  barAlign?: "top" | "bottom";
  /** Minimum pixels per second of audio (i.e. the zoom level) */
  minPxPerSec?: number;
  /** Stretch the waveform to fill the container, true by default */
  fillParent?: boolean;
  /** Pre-computed audio data, arrays of floats for each channel */
  peaks?: Array<Float32Array | number[]>;
  /** Pre-computed audio duration in seconds */
  duration?: number;
  /** Use an existing media element instead of creating one */
  media?: HTMLMediaElement;
  /** Hide the scrollbar */
  hideScrollbar?: boolean;
  /** Render each audio channel as a separate waveform */
  splitChannels?: Partial<WaveSurferOptions>[];
  /** Stretch the waveform to the full height */
  normalize?: boolean;
};

const defaultOptions = {
  waveColor: "#999",
  minPxPerSec: 0,
  fillParent: true,
};

type RendererEvents = {
  scroll: [relativeStart: number, relativeEnd: number];
  render: [];
  rendered: [];
};

class Renderer extends EventEmitter<RendererEvents> {
  private static MAX_CANVAS_WIDTH = 4000;
  private parent: HTMLElement;
  private container: HTMLElement;
  private scrollContainer: HTMLElement;
  private wrapper: HTMLElement;
  private canvasWrapper: HTMLElement;
  private timeouts: Array<() => void> = [];
  private isScrollable = false;
  private audioData: AudioBuffer | null = null;
  private resizeObserver: ResizeObserver | null = null;
  private lastContainerWidth = 0;
  options: WaveSurferOptions;

  constructor(options: WaveSurferOptions, audioElement?: HTMLElement) {
    super();

    this.options = { ...defaultOptions, ...options };

    const parent = this.parentFromOptionsContainer(options.container);
    this.parent = parent;

    const [div, shadow] = this.initHtml();
    parent.appendChild(div);
    this.container = div;
    this.scrollContainer = shadow.querySelector(".scroll") as HTMLElement;
    this.wrapper = shadow.querySelector(".wrapper") as HTMLElement;
    this.canvasWrapper = shadow.querySelector(".canvases") as HTMLElement;

    if (audioElement) {
      shadow.appendChild(audioElement);
    }
  }

  private parentFromOptionsContainer(container: WaveSurferOptions["container"]) {
    let parent;
    if (typeof container === "string") {
      parent = document.querySelector(container) satisfies HTMLElement | null;
    } else if (container instanceof HTMLElement) {
      parent = container;
    }

    if (!parent) {
      throw new Error("Container not found");
    }

    return parent;
  }

  private onContainerResize() {
    const width = this.parent.clientWidth;
    if (width === this.lastContainerWidth && this.options.height !== "auto") return;
    this.lastContainerWidth = width;
    this.reRender();
  }

  private getHeight(optionsHeight?: WaveSurferOptions["height"]): number {
    const defaultHeight = 128;
    if (optionsHeight == null) return defaultHeight;
    if (!isNaN(Number(optionsHeight))) return Number(optionsHeight);
    if (optionsHeight === "auto") return this.parent.clientHeight || defaultHeight;
    return defaultHeight;
  }

  private initHtml(): [HTMLElement, ShadowRoot] {
    const div = document.createElement("div");
    const shadow = div.attachShadow({ mode: "open" });

    shadow.innerHTML = `
      <style>
        :host {
          user-select: none;
          min-width: 1px;
        }
        :host audio {
          display: block;
          width: 100%;
        }
        :host .scroll {
          overflow-x: auto;
          overflow-y: hidden;
          width: 100%;
          position: relative;
        }
        :host .noScrollbar {
          scrollbar-color: unset;
          /*scrollbar-color: transparent;*/
          scrollbar-width: none;
        }
        :host .noScrollbar::-webkit-scrollbar {
          display: none;
          -webkit-appearance: none;
        }
        :host .wrapper {
          position: relative;
          overflow: visible;
          z-index: 2;
        }
        :host .canvases {
          min-height: ${this.getHeight(this.options.height)}px;
        }
        :host .canvases > div {
          position: relative;
        }
        :host canvas {
          display: block;
          position: absolute;
          top: 0;
          image-rendering: pixelated;
        }
      </style>

      <div class="scroll" part="scroll">
        <div class="wrapper" part="wrapper">
          <div class="canvases"></div>
        </div>
      </div>
    `;

    return [div, shadow];
  }

  /** Wavesurfer itself calls this method. Do not call it manually. */
  setOptions(options: WaveSurferOptions) {
    if (this.options.container !== options.container) {
      const newParent = this.parentFromOptionsContainer(options.container);
      newParent.appendChild(this.container);

      this.parent = newParent;
    }

    this.options = options;

    // Re-render the waveform
    this.reRender();
  }

  getWrapper(): HTMLElement {
    return this.wrapper;
  }

  getScroll(): number {
    return this.scrollContainer.scrollLeft;
  }

  private setScroll(pixels: number) {
    this.scrollContainer.scrollLeft = pixels;
  }

  setScrollPercentage(percent: number) {
    const { scrollWidth } = this.scrollContainer;
    const scrollStart = scrollWidth * percent;
    this.setScroll(scrollStart);
  }

  destroy() {
    this.container.remove();
    this.resizeObserver?.disconnect();
  }

  private createDelay(delayMs = 10): () => Promise<void> {
    let timeout: ReturnType<typeof setTimeout> | undefined;
    let reject: (() => void) | undefined;

    const onClear = () => {
      if (timeout) clearTimeout(timeout);
      if (reject) reject();
    };

    this.timeouts.push(onClear);

    return () => {
      return new Promise((resolveFn, rejectFn) => {
        onClear();
        reject = rejectFn;
        timeout = setTimeout(() => {
          timeout = undefined;
          reject = undefined;
          resolveFn();
        }, delayMs);
      });
    };
  }

  // Convert array of color values to linear gradient
  private convertColorValues(color?: WaveSurferOptions["waveColor"]): string | CanvasGradient {
    if (!Array.isArray(color)) return color || "";
    if (color.length < 2) return color[0] || "";

    const canvasElement = document.createElement("canvas");
    const ctx = canvasElement.getContext("2d") as CanvasRenderingContext2D;
    const gradientHeight = canvasElement.height * (window.devicePixelRatio || 1);
    const gradient = ctx.createLinearGradient(0, 0, 0, gradientHeight);

    const colorStopPercentage = 1 / (color.length - 1);
    color.forEach((color, index) => {
      const offset = index * colorStopPercentage;
      gradient.addColorStop(offset, color);
    });

    return gradient;
  }

  private renderLineWaveform(
    channelData: Array<Float32Array | number[]>,
    _options: WaveSurferOptions,
    ctx: CanvasRenderingContext2D,
    vScale: number,
  ) {
    const drawChannel = (index: number) => {
      const channel = channelData[index] || channelData[0];
      const length = channel.length;
      const { height } = ctx.canvas;
      const halfHeight = height / 2;
      const hScale = ctx.canvas.width / length;

      ctx.moveTo(0, halfHeight);

      let prevX = 0;
      let max = 0;
      for (let i = 0; i <= length; i++) {
        const x = Math.round(i * hScale);

        if (x > prevX) {
          const h = Math.round(max * halfHeight * vScale) || 1;
          const y = halfHeight + h * (index === 0 ? -1 : 1);
          ctx.lineTo(prevX, y);
          prevX = x;
          max = 0;
        }

        const value = Math.abs(channel[i] || 0);
        if (value > max) max = value;
      }

      ctx.lineTo(prevX, halfHeight);
    };

    ctx.beginPath();

    drawChannel(0);
    drawChannel(1);

    ctx.fill();
    ctx.closePath();
  }

  private renderWaveform(
    channelData: Array<Float32Array | number[]>,
    options: WaveSurferOptions,
    ctx: CanvasRenderingContext2D,
  ) {
    ctx.fillStyle = this.convertColorValues(options.waveColor);

    // Vertical scaling
    let vScale = options.barHeight || 1;
    if (options.normalize) {
      const max = Array.from(channelData[0]).reduce((max, value) => Math.max(max, Math.abs(value)), 0);
      vScale = max ? 1 / max : 1;
    }

    // Render waveform as a polyline
    this.renderLineWaveform(channelData, options, ctx, vScale);
  }

  private renderSingleCanvas(
    channelData: Array<Float32Array | number[]>,
    options: WaveSurferOptions,
    width: number,
    height: number,
    start: number,
    end: number,
    canvasContainer: HTMLElement,
  ) {
    const pixelRatio = window.devicePixelRatio || 1;
    const canvas = document.createElement("canvas");
    const length = channelData[0].length;
    canvas.width = Math.round((width * (end - start)) / length);
    canvas.height = height * pixelRatio;
    canvas.style.width = `${Math.floor(canvas.width / pixelRatio)}px`;
    canvas.style.height = `${height}px`;
    canvas.style.left = `${Math.floor((start * width) / pixelRatio / length)}px`;
    canvasContainer.appendChild(canvas);

    const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

    this.renderWaveform(
      channelData.map((channel) => channel.slice(start, end)),
      options,
      ctx,
    );
  }

  private async renderChannel(
    channelData: Array<Float32Array | number[]>,
    options: WaveSurferOptions,
    width: number,
  ): Promise<void> {
    // A container for canvases
    const canvasContainer = document.createElement("div");
    const height = this.getHeight(options.height);
    canvasContainer.style.height = `${height}px`;
    this.canvasWrapper.style.minHeight = `${height}px`;
    this.canvasWrapper.appendChild(canvasContainer);

    // A container for progress canvases
    const dataLength = channelData[0].length;

    // Draw a portion of the waveform from start peak to end peak
    const draw = (start: number, end: number) => {
      this.renderSingleCanvas(
        channelData,
        options,
        width,
        height,
        Math.max(0, start),
        Math.min(end, dataLength),
        canvasContainer,
      );
    };

    // Draw the entire waveform
    if (!this.isScrollable) {
      draw(0, dataLength);
      return;
    }

    // Determine the currently visible part of the waveform
    const { scrollLeft, scrollWidth, clientWidth } = this.scrollContainer;
    const scale = dataLength / scrollWidth;

    let viewportWidth = Math.min(Renderer.MAX_CANVAS_WIDTH, clientWidth);

    // Adjust width to avoid gaps between canvases when using bars
    if (options.barWidth || options.barGap) {
      const barWidth = options.barWidth || 0.5;
      const barGap = options.barGap || barWidth / 2;
      const totalBarWidth = barWidth + barGap;
      if (viewportWidth % totalBarWidth !== 0) {
        viewportWidth = Math.floor(viewportWidth / totalBarWidth) * totalBarWidth;
      }
    }

    const start = Math.floor(Math.abs(scrollLeft) * scale);
    const end = Math.floor(start + viewportWidth * scale);
    const viewportLen = end - start;

    if (viewportLen <= 0) {
      return;
    }

    // Draw the visible part of the waveform
    draw(start, end);

    // Draw the waveform in chunks equal to the size of the viewport, starting from the position of the viewport
    await Promise.all([
      // Draw the chunks to the left of the viewport
      (async () => {
        if (start === 0) return;
        const delay = this.createDelay();
        for (let i = start; i >= 0; i -= viewportLen) {
          await delay();
          draw(Math.max(0, i - viewportLen), i);
        }
      })(),
      // Draw the chunks to the right of the viewport
      (async () => {
        if (end === dataLength) return;
        const delay = this.createDelay();
        for (let i = end; i < dataLength; i += viewportLen) {
          await delay();
          draw(i, Math.min(dataLength, i + viewportLen));
        }
      })(),
    ]);
  }

  async render(audioData: AudioBuffer) {
    // Clear previous timeouts
    this.timeouts.forEach((clear) => clear());
    this.timeouts = [];

    // Clear the canvases
    this.canvasWrapper.innerHTML = "";

    // Determine the width of the waveform
    const pixelRatio = window.devicePixelRatio || 1;
    const parentWidth = this.scrollContainer.clientWidth;
    const scrollWidth = Math.ceil(audioData.duration * (this.options.minPxPerSec || 0));

    // Whether the container should scroll
    this.isScrollable = scrollWidth > parentWidth;
    const useParentWidth = true;
    // Width of the waveform in pixels
    const width = parentWidth * pixelRatio;

    // Set the width of the wrapper
    this.wrapper.style.width = useParentWidth ? "100%" : `${scrollWidth}px`;

    // Set additional styles
    this.scrollContainer.style.overflowX = this.isScrollable ? "auto" : "hidden";
    this.scrollContainer.classList.toggle("noScrollbar", !!this.options.hideScrollbar);

    this.audioData = audioData;

    this.emit("render");

    // Render the waveform
    try {
      if (this.options.splitChannels) {
        // Render a waveform for each channel
        await Promise.all(
          Array.from({ length: audioData.numberOfChannels }).map((_, i) => {
            const options = { ...this.options, ...this.options.splitChannels?.[i] };
            return this.renderChannel([audioData.getChannelData(i)], options, width);
          }),
        );
      } else {
        // Render a single waveform for the first two channels (left and right)
        const channels = [audioData.getChannelData(0)];
        if (audioData.numberOfChannels > 1) channels.push(audioData.getChannelData(1));
        await this.renderChannel(channels, this.options, width);
      }
    } catch {
      // Render cancelled due to another render
      return;
    }

    this.emit("rendered");
  }

  reRender() {
    // Return if the waveform has not been rendered yet
    if (!this.audioData) return;

    // Re-render the waveform
    this.render(this.audioData);
  }

  zoom(minPxPerSec: number) {
    this.options.minPxPerSec = minPxPerSec;
    this.reRender();
  }

  async exportImage(format: string, quality: number, type: "dataURL" | "blob"): Promise<string[] | Blob[]> {
    const canvases = this.canvasWrapper.querySelectorAll("canvas");
    if (!canvases.length) {
      throw new Error("No waveform data");
    }

    // Data URLs
    if (type === "dataURL") {
      const images = Array.from(canvases).map((canvas) => canvas.toDataURL(format, quality));
      return Promise.resolve(images);
    }

    // Blobs
    return Promise.all(
      Array.from(canvases).map((canvas) => {
        return new Promise<Blob>((resolve, reject) => {
          canvas.toBlob(
            (blob) => {
              blob ? resolve(blob) : reject(new Error("Could not export image"));
            },
            format,
            quality,
          );
        });
      }),
    );
  }
}

export default Renderer;
