import { BaseAudioState } from "../../contexts/PageAudioManager/types";
import { NarrationAudio, OneShotAudio } from "../../models/IInteractiveAudio";
import { audioApiToBlob, audioManifestPropertiesWhitelist } from "../../panels/TimelinePanel/helpers";

export const prepareAudiosToManifest = (audios: BaseAudioState<OneShotAudio | NarrationAudio>[]) => {
  return audios.map((audio) => {
    // Reduce the amount of data save into the manifest
    // Audio string goes back from API to Blob mode
    // FIXME: If we ever support a different asset url that's not blob storage this will fall apart. (many other pieces too)
    return { ...audioManifestPropertiesWhitelist(audio), input: audioApiToBlob(audio.input as string) };
  });
};
