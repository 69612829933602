import React, { useEffect, useState } from "react";

interface CustomDropDownProps {
  value: string;
  title: string;
  options: string[];
  className?: string;
  styles?: { [key: string]: string };
  onChange: (value: string) => void;
  readonly: boolean;
}
export const CustomDropDown = ({
  title,
  options,
  className,
  styles,
  readonly,
  onChange,
  value,
}: CustomDropDownProps) => {
  const [dropDownValue, setDropDownValue] = useState<string>(value);
  useEffect(() => {
    setDropDownValue(value);
  }, [readonly, value]);

  return (
    <div className={`${className ?? ""}`} style={{ ...styles }}>
      <label htmlFor="header">{title}</label>
      <select
        className={`assetManager-tag-dropdown ${readonly ? "disabled " : "enabled"}`}
        value={dropDownValue}
        disabled={readonly}
        onChange={(e) => {
          setDropDownValue(e.target.value);
          onChange(e.target.value);
        }}
      >
        <option hidden key={value}>
          {value}
        </option>
        {options.map((option, i) => {
          return <option key={i}>{option}</option>;
        })}
      </select>
    </div>
  );
};

export default CustomDropDown;
