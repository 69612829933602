import React from "react";
import { SymbolComponentType } from "../helpers/getSymbol";

const VOR1PointerHead = (props: SymbolComponentType) => {
  const { index, attributes, transformStr } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 5.73 16.92"
      key={index}
      id={`symbol-${index}`}
      tabIndex={0}
      className="symbol target"
      name="symbol"
      preserveAspectRatio="xMidYMid meet"
      style={{
        top: `${attributes.top}%`,
        left: `${attributes.left}%`,
        width: `${attributes.width}%`,
        height: `${attributes.height}%`,
        zIndex: attributes.zIndex,
        // transform:`rotate(${attributes?.transform?.rotate}deg) scaleX(${attributes?.transform?.scaleX}) scaleY(${attributes?.transform?.scaleY}) matrix3d(${attributes?.transform?.matrix3d})`
        transform: transformStr,
      }}
    >
      <path
        // className="vor_1_pointer_head"
        stroke={`${attributes.borderColor || "white"}`}
        fill={"transparent"}
        strokeWidth={`${attributes.lineWidth || 0.1}`}
        d="M 0.8,2.44 2.9,0.48 4.88,2.44"
      />
      <path
        // className="vor_1_pointer_head"
        stroke={`${attributes.borderColor || "white"}`}
        fill={`${attributes?.backgroundColor || "transparent"}`}
        strokeWidth={`${attributes.lineWidth || 0.1}`}
        d="M 2.9000001,0.49000001 2.8399999,16.57"
      />
      <path
        // className="vor_1_pointer_head"
        stroke={`${attributes.borderColor || "white"}`}
        fill={`${attributes?.backgroundColor || "transparent"}`}
        strokeWidth={`${attributes.lineWidth || 0.1}`}
        d="M 0,16.57 H 5.73"
      />
    </svg>
  );
};

export default VOR1PointerHead;
